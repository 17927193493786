import privateInstance from "../../_helper/axios";

async function getCourse(course_id) {
  return await privateInstance
    .get(`/courses/${course_id}`)
    .then((resp) => resp.data);
}

export const coursesService = {
  getCourse,
};
