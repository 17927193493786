import classNames from "classnames/bind";
import React from "react";
import { connect } from "react-redux";
import styles from "./ClassroomCardSummary.module.scss";

const cx = classNames.bind(styles);

const ClassroomCardSummary = ({
  is_maker_period,
  round_forever,
  is_revision_period,
  isGovIntro,
  isExpired,
  remaining_days,
  maker_progress_rate,
  progress_rate,
  is_target_retry,
}) => {
  const getRemainTimes = () => {
    const units = [
      { unit: "주" },
      { unit: "일", max: 7 },
      { unit: "시간", max: 24 },
      { unit: "분", max: 60 },
    ];
    const firstIndex = remaining_days.findIndex((unit) => parseInt(unit) > 0);
    const secondIndex = firstIndex + 1;
    let [copiedFirstValue, copiedSecondValue] = remaining_days
      .slice(firstIndex, firstIndex + 2)
      .map((value) => parseInt(value));

    if (units[secondIndex]) {
      const maximumSecondValue = units[secondIndex].max;

      copiedSecondValue++;
      if (copiedSecondValue >= maximumSecondValue) {
        copiedFirstValue++;
        copiedSecondValue -= maximumSecondValue;
      }

      return (
        <>
          <b>{copiedFirstValue}</b> {units[firstIndex].unit} +{" "}
          <b>{copiedSecondValue}</b>
          {units[secondIndex].unit}
        </>
      );
    } else {
      return (
        <>
          <b>0</b> 시간 + <b>{copiedFirstValue}</b> {units[firstIndex].unit}
        </>
      );
    }
  };

  return (
    <div className={cx("course-summary")}>
      <div className={cx("course-remain")}>
        {is_maker_period && (
          <p className={cx("course-remain-title")}>남은 만들기 주간</p>
        )}
        {!is_maker_period && (
          <p
            className={
              is_revision_period && !round_forever
                ? cx("course-remain-title", is_target_retry ? "" : "red")
                : cx("course-remain-title")
            }
          >
            {is_target_retry
              ? "남은 수강 기간"
              : is_revision_period && !round_forever
              ? "복습 기간"
              : isGovIntro
              ? "수강 기간"
              : "남은 수강 기간"}
          </p>
        )}
        {
          <span
            className={
              isGovIntro
                ? cx(
                    "course-remain-detail",
                    `${isExpired ? "--isExpired" : ""}`
                  )
                : cx(
                    "course-remain-detail",
                    "course-gov-not",
                    `${isExpired ? "--isExpired" : ""}`
                  )
            }
          >
            {isExpired ? (
              "종료"
            ) : (
              <>
                {round_forever || parseInt(remaining_days[0]) > 300 ? (
                  <b>{isGovIntro ? <small>최종합류까지</small> : "평생"}</b>
                ) : (
                  <>{getRemainTimes()}</>
                )}
              </>
            )}
          </span>
        }
      </div>
      <div className={cx("course-progress")}>
        {isGovIntro ? (
          ""
        ) : (
          <p className={cx("course-progress-title")}>진도율</p>
        )}
        {isGovIntro ? (
          ""
        ) : (
          <span className={cx("course-progress-detail")}>
            <b>
              {getProgress(
                is_maker_period ? maker_progress_rate : progress_rate
              )}
            </b>
            %
          </span>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

const connectedClassroomCardSummary = connect(
  mapStateToProps,
  {}
)(ClassroomCardSummary);
export { connectedClassroomCardSummary as ClassroomCardSummary };

// utils
export function getProgress(progress_rate) {
  return `${Math.round(progress_rate * 1000) / 10}`;
}
