export const RCMD_FREE_COURSE = {
  RCMD_FREE_COURSE_REQUEST: "RCMD_FREE_COURSE_REQUEST",
  RCMD_FREE_COURSE_SUCCESS: "RCMD_FREE_COURSE_SUCCESS",
  RCMD_FREE_COURSE_FAILURE: "RCMD_FREE_COURSE_FAILURE",
};

export const RCMD_FREE_COURSE_POST = {
  RCMD_FREE_COURSE_POST_REQUEST: "RCMD_FREE_COURSE_POST_REQUEST",
};

export const RCMD_COURSE_GENERAL = {
  RCMD_COURSE_GENERAL_REQUEST: "RCMD_COURSE_GENERAL_REQUEST",
  RCMD_COURSE_GENERAL_SUCCESS: "RCMD_COURSE_GENERAL_SUCCESS",
  RCMD_COURSE_GENERAL_FAILURE: "RCMD_COURSE_GENERAL_FAILURE",
};

export const RCMD_SURVEY = {
  RCMD_SURVEY_REQUEST: "RCMD_SURVEY_REQUEST",
  RCMD_SURVEY_SUCCESS: "RCMD_SURVEY_SUCCESS",
  RCMD_COURSE_FAILURE: "RCMD_SURVEY_FAILURE",
};
