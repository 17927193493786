import { useEffect, useState } from "react";

export const useQuestionState = (questionsList) => {
  const [questionsState, setQuestionsState] = useState([]);
  useEffect(() => {
    setQuestionsState(
      questionsList.map((question) => ({
        id: question.id,
        title: question.title,
        type: question.type,
        selectedIndex: null,
        additionalAnswer: "",
        options: question.options,
      }))
    );
  }, [questionsList]);

  return [questionsState, setQuestionsState];
};
