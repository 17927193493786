import React, { useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./CodeSnippets.module.scss";
import { Skeleton } from "antd";
import { enrolledActions } from "../../../_state/actions";
import { Message } from "../Message";
import { sendLog } from "../../../businesslogics/logging";

const cx = classNames.bind(styles);

function CodeSnippets({ codeSnippets, isDual, enrolled, enrolledDetail }) {
  return (
    <>
      {!codeSnippets.loaded && <Skeleton />}
      {codeSnippets.loaded && codeSnippets.data.length > 0 && (
        <div className={cx("codeSnippetsContainer", `${isDual ? "dual" : ""}`)}>
          {codeSnippets.data.map((codeSnippet, idx) => {
            return (
              <CodeSnippet
                key={idx}
                codeSnippet={codeSnippet}
                enrolled={enrolled}
                enrolledDetail={enrolledDetail}
              />
            );
          })}
        </div>
      )}
      {codeSnippets.loaded && codeSnippets.data.length === 0 && (
        <div className={cx("no-code-snippet")}>
          <img src={`/assets/images/lecture/no_code_snippet.png`} />
        </div>
      )}
    </>
  );
}

const CodeSnippet = ({ codeSnippet, enrolled, enrolledDetail }) => {
  const [isHover, setIsHover] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const handleMouseOver = (e) => {
    setIsHover(true);
  };
  const handleMouseLeave = (e) => {
    setIsHover(false);
  };
  return (
    <>
      {(
        <div
          className={isHover ? cx("codeSnippet", "hover") : cx("codeSnippet")}
          onMouseLeave={(e) => {
            handleMouseLeave(e);
          }}
          onMouseOver={(e) => {
            handleMouseOver(e);
          }}
          onClick={() => {
            sendLog(
              "scc_lecturePage_click_button",
              {
                ...enrolled.data,
                ...enrolledDetail.data,
                button_text: codeSnippet.title,
                button_name: "코드스니펫",
              },
              ""
            );
            copyToClipboard(codeSnippet.code);
            setIsClicked(true);
            setTimeout(() => {
              setIsClicked(false);
            }, 2000);
          }}
        >
          {isHover ? "복사하기" : codeSnippet.title}
          {isClicked && (
            <Message
              type={"success"}
              content={"코드 복사가 완료되었어요!"}
              start={0}
              duration={1.5}
            />
          )}
        </div>
      )}
    </>
  );
};

const copyToClipboard = async (code) => {
  try {
    await navigator.clipboard.writeText(code).then(
      (success) => {
        // message.success(
        //   {
        //     content: "코드 복사가 완료되었어요!",
        //     className: cx("sparta-success"),
        //     style: {
        //       marginTop: "10px",
        //     },
        //     duration: 1,
        //   }
        //   // 1
        // );
      },
      (error) => {}
    );
  } catch (err) {
    console.log(err);
  }
};

function mapStateToProps(state) {
  const { authentication, enrolled, enrolledDetail, enrolledDetails } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetail,
    enrolledDetails,
  };
}

const connectedCodeSnippets = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
})(CodeSnippets);
export { connectedCodeSnippets as CodeSnippets };
