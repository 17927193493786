import React from "react";
import classNames from "classnames/bind";
import styles from "./ClassPackageGuide.module.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

function ClassPackageGuide() {
  return (
    <a
      className={cx("package_guide")}
      // onClick={() => go_to_package_guide()}
      href={"https://teamsparta.notion.site/c02427de15034202840ad4c2089cd6f2"}
      target={"_blank"}
    >
      <img src={`/assets/icons/ic_finger_pointer.png`} alt="" />
      <span className={cx("package_guide_link")}>패키지 안내</span>
    </a>
  );
}

function mapStateToProps(state) {
  return {};
}

const conncetedClassPackageGuide = connect(
  mapStateToProps,
  {}
)(ClassPackageGuide);
export { conncetedClassPackageGuide as ClassPackageGuide };
