import React, { useState } from "react";
import * as S from "./MobileLecReport.style";
import privateInstance from "../../../_helper/axios";
import { LecMenuBottomSheet } from "../../LecturePage/LecMenu/LecMenuBottomSheet/LecMenuBottomSheet";
import { history } from "../../../../_helpers";
import { sendLog } from "../../../businesslogics/logging";
import { useSelector } from "react-redux";
import { useOnlineTheme } from "../../../queries/pirates";
import { PIRATES } from "../../../../_consts";
import { parseUserAgent } from "../../../utils/ErrorReport/userAgent";
import { formattedErrorTime } from "../../../utils/ErrorReport/errorReport";
import { ErrorMoment } from "../../../components/organisms/ErrorReport/ErrorMoment";

const reportTypeList = [
  { label: "영상 내용 오류", value: "영상 오류" },
  { label: "강의 자료 오류", value: "강의자료 오류" },
  { label: "코드스니펫 오류", value: "코드스니펫 오류" },
  { label: "기타 (제안 등)", value: "기타" },
];

const reportScoreList = [
  {
    label: "1점- 있으면 좋을 정도의 기능 제안",
    value: "1점- 있으면 좋을 정도의 기능 제안",
  },
  {
    label: "2점- 강의 진행에는 불편함이 없는 옥의 티",
    value: "2점- 강의 진행에는 불편함이 없는 옥의 티",
  },
  {
    label: "3점- 강의 진행이 가능하나 약간 불편함",
    value: "3점- 강의 진행이 가능하나 약간 불편함",
  },
  {
    label: "4점- 강의 진행이 거의 불가할 정도로 불편함",
    value: "4점- 강의 진행이 거의 불가할 정도로 불편함",
  },
  {
    label: "5점- 강의 진행이 완전히 불가함",
    value: "5점- 강의 진행이 완전히 불가함",
  },
];

export const MobileLecReport = ({ enrolledDetail, user, setReportState }) => {
  const [openedSelectSheet, setOpenedSelectSheet] = useState(null);
  const [openedSelectList, setOpenedSelectList] = useState([]);
  const [type, setType] = useState("");
  const [score, setScore] = useState("");
  const [comment, setComment] = useState("");
  const lecture_v2 = useSelector((state) => state.lecture_v2);
  const enrolled = useSelector((state) => state.enrolled);
  const course = useSelector((state) => state.course);
  const theme = useOnlineTheme();
  const initialMinute =
    parseInt(
      localStorage.getItem(`videoProgress_${lecture_v2.data.lecture_id}`) || 0
    ) / 60;
  const initialSecond =
    parseInt(
      localStorage.getItem(`videoProgress_${lecture_v2.data.lecture_id}`) || 0
    ) % 60;
  const [errorMinute, setErrorMinute] = useState(initialMinute);
  const [errorSecond, setErrorSecond] = useState(initialSecond);
  const handleOpenSelect = (category) => {
    if (openedSelectSheet) {
      setOpenedSelectSheet(null);
      setOpenedSelectList([]);
    } else {
      const getSelectList = (sheet) => {
        switch (sheet) {
          case "종류":
            return reportTypeList;
          case "불편 점수":
            return reportScoreList;
          default:
            return [];
        }
      };

      setOpenedSelectSheet(category);
      setOpenedSelectList(getSelectList(category));
    }
  };

  const handleSelect = (target) => {
    if (target.innerText !== undefined) {
      switch (openedSelectSheet) {
        case "종류":
          setType(target.innerText);
          break;
        case "불편 점수":
          setScore(target.innerText);
          break;
        default:
          return null;
      }
    }
  };

  const handleSubmitReport = () => {
    if (type === "" || comment === "") {
      return;
    }
    const userAgent = parseUserAgent(window.navigator.userAgent);
    const { enrolled_id, enrolled_detail_id, lecture_id } = enrolledDetail.data;
    const { _id: user_id, name, email, phone } = user;
    const data = {
      is_gov: course.data.is_gov,
      type: type,
      score: parseInt(score.match(/\d+/)[0]),
      score_string: score,
      comment: comment,
      errorTime: formattedErrorTime(type, errorMinute, errorSecond),
      user_id: user_id,
      os: userAgent.os,
      browser: userAgent.browser,
      name: name,
      email: email,
      phone: phone,
      enrolled_id: enrolled_id,
      enrolled_detail_id: enrolled_detail_id,
      lecture_id: lecture_id,
    };
    return privateInstance.post(`/report/lectures`, data).then((resp) => {
      const result = resp.data;
      if (result.ok) {
        sendLog("scc_lecturePage_report_completed", {
          ...enrolled.data,
          ...enrolledDetail.data,
          enrolled_id: enrolled_id,
          course_title: course.data?.title,
          week: enrolledDetail.data?.week,
          week_order: enrolledDetail.data?.week_order,
          type: type,
        });
        alert("소중한 제보 감사합니다. 빠른 시일 내로 해결하겠습니다.");
        history.goBack();
      }
    });
  };

  const getSvgColor = () => {
    switch (theme.data) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#81898F";
    }
  };
  return (
    <>
      {!!openedSelectSheet && (
        <LecMenuBottomSheet
          defaultValue={openedSelectSheet}
          optionList={openedSelectList}
          openFlag={!!openedSelectSheet}
          handleOpenFunction={handleOpenSelect}
          handleSelectFunction={handleSelect}
        />
      )}
      <S.ReportWrapper theme={theme.data}>
        <S.ReportTitle theme={theme.data}>강의 내용 오류 제보</S.ReportTitle>
        <S.ReportSubTitle theme={theme.data}>종류</S.ReportSubTitle>
        <S.ReportSelectBox
          theme={theme.data}
          onClick={() => handleOpenSelect("종류")}
        >
          <S.ReportSelectedValue theme={theme.data} isActive={type !== ""}>
            {type === "" ? "종류를 선택해주세요." : type}
          </S.ReportSelectedValue>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5.61872 7.71331C5.27701 7.3716 4.72299 7.3716 4.38128 7.71331C4.03957 8.05502 4.03957 8.60904 4.38128 8.95075L5.61872 7.71331ZM10 13.332L9.38128 13.9507C9.72299 14.2925 10.277 14.2925 10.6187 13.9507L10 13.332ZM15.6187 8.95075C15.9604 8.60904 15.9604 8.05502 15.6187 7.71331C15.277 7.3716 14.723 7.3716 14.3813 7.71331L15.6187 8.95075ZM4.38128 8.95075L9.38128 13.9507L10.6187 12.7133L5.61872 7.71331L4.38128 8.95075ZM10.6187 13.9507L15.6187 8.95075L14.3813 7.71331L9.38128 12.7133L10.6187 13.9507Z"
              fill={getSvgColor()}
            />
          </svg>
        </S.ReportSelectBox>
        <ErrorMoment
          type={type}
          theme={theme}
          errorMinute={errorMinute}
          errorSecond={errorSecond}
          enrolledDetail={enrolledDetail}
          setErrorMinute={setErrorMinute}
          setErrorSecond={setErrorSecond}
        />
        <S.ReportSubTitle theme={theme.data}>불편 점수</S.ReportSubTitle>
        <S.ReportSelectBox
          theme={theme.data}
          onClick={() => handleOpenSelect("불편 점수")}
        >
          <S.ReportSelectedValue theme={theme.data} isActive={score !== ""}>
            {score === "" ? "어느 정도의 불편함을 겪고 계신가요?" : score}
          </S.ReportSelectedValue>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5.61872 7.71331C5.27701 7.3716 4.72299 7.3716 4.38128 7.71331C4.03957 8.05502 4.03957 8.60904 4.38128 8.95075L5.61872 7.71331ZM10 13.332L9.38128 13.9507C9.72299 14.2925 10.277 14.2925 10.6187 13.9507L10 13.332ZM15.6187 8.95075C15.9604 8.60904 15.9604 8.05502 15.6187 7.71331C15.277 7.3716 14.723 7.3716 14.3813 7.71331L15.6187 8.95075ZM4.38128 8.95075L9.38128 13.9507L10.6187 12.7133L5.61872 7.71331L4.38128 8.95075ZM10.6187 13.9507L15.6187 8.95075L14.3813 7.71331L9.38128 12.7133L10.6187 13.9507Z"
              fill={getSvgColor()}
            />
          </svg>
        </S.ReportSelectBox>
        <S.ReportSubTitle theme={theme.data}>내용</S.ReportSubTitle>
        <S.ReportContentTextarea
          theme={theme.data}
          placeholder={
            "어떤 오류가 있는지 자세하게 작성해 주시면 빠른 시일 내로 해결하겠습니다."
          }
          onChange={(e) => setComment(e.target.value)}
        />
      </S.ReportWrapper>
      <S.ReportSubmitButtonWrapper>
        <S.BackToErrorTypeSelectorButton
          onClick={() => setReportState("SELECT_TYPE")}
        >
          이전으로
        </S.BackToErrorTypeSelectorButton>
        <S.ReportSubmitButton
          onClick={() => handleSubmitReport()}
          isActive={type !== "" && score !== "" && comment !== ""}
        >
          제보하기
        </S.ReportSubmitButton>
      </S.ReportSubmitButtonWrapper>
    </>
  );
};
