import { courseSkills } from "../../pages/FinishPage/FinishPageSkillData";
import { sendLog } from "../logging";

export const getCourseSkills = (coursesId) => {
  if (!coursesId || typeof courseSkills[coursesId] === "undefined") {
    return courseSkills["default"].map(({ skillName, imagePath }) => {
      return <img width={"74px"} src={imagePath} alt={skillName + "skill"} />;
    });
  }

  return courseSkills[coursesId].map(({ skillName, imagePath }) => {
    return <img width={"74px"} src={imagePath} alt={skillName + "skill"} />;
  });
};

export const getMedalImagePath = (repurchase, free) => {
  let isRepurchase =
    typeof repurchase === "boolean" ? repurchase : repurchase === "true";
  let isFree = typeof free === "boolean" ? free : free === "true";

  if (isRepurchase && isFree) {
    // 재구매 + 무료
    return "/assets/images/finish/medal_repurchase_free.png";
  } else if (isRepurchase && !isFree) {
    // 재구매 + 유료
    return "/assets/images/finish/medal_repurchase_pay.png";
  } else if (!isRepurchase && isFree) {
    // 첫구매 + 무료
    return "/assets/images/finish/medal_first_free.png";
  } else if (!isRepurchase && !isFree) {
    // 첫구매 + 유료
    return "/assets/images/finish/medal_first_pay.png";
  }
};

export const getUsernameTitle = (repurchase, piratesCourseTitle = null) => {
  const generatePiratesTitle = () => {
    const track = piratesCourseTitle.split("트랙")[0];
    const level = piratesCourseTitle.split("Lv.")[1].split(" ")[0];
    return `${track}트랙 Lv.${level}을 정복한`;
  };

  const generateDefaultTitle = () => {
    let isRepurchase =
      typeof repurchase === "boolean" ? repurchase : repurchase === "true";
    return !isRepurchase ? "위대한 첫 걸음을 뗀" : "자라나는 새싹 코딩러";
  };

  return piratesCourseTitle
    ? generatePiratesTitle(piratesCourseTitle)
    : generateDefaultTitle(repurchase);
};

export const getStudyTimes = (time) => {
  if (typeof time === "undefined") return;
  const totalPlayTime = parseInt(time);
  const hour = parseInt(totalPlayTime / 60);
  const min = totalPlayTime - hour * 60;
  return (
    <>
      {hour !== 0 && (
        <div style={{ display: "inline-block" }}>
          <span>{hour}</span>시간
        </div>
      )}
      <div style={{ display: "inline-block" }}>
        <span>{min}</span>분
      </div>
    </>
  );
};

export const isCoustomUserNameTitle = (courseId) => {
  const GPT = "6420ea5f7e6e4c8f5d0af5d0";
  return [GPT].includes(courseId);
};

export const getCustomUserNameTitle = (courseId, name) => {
  const GPT = "6420ea5f7e6e4c8f5d0af5d0";
  const SQLD = "6433b6aab8d11cc10a00b294";
  // eslint-disable-next-line default-case
  switch (courseId) {
    case GPT:
      return (
        <h1>
          ChatGPT 세계에 <br />
          입문하신 {name} 님
        </h1>
      );
    case SQLD:
      return (
        <h1>
          SQL 활용 전문가가 된<br />
          {name} 님
        </h1>
      );
  }
};

export const sendLogAction = (btnText, enrolled, nextStep) => {
  sendLog(
    "scc_courseRcmdPage_click_button",
    {
      button_text: btnText,
      course_title: enrolled?.data?.courses.title || "",
      next_href: "",
      next_page: "",
      target_Course_title: "",
      target_brand: nextStep,
      ...enrolled.data,
    },
    "",
    false
  );
};

export const onClickShareKko = (shareUrl, name, enrolled) => {
  sendLogAction("카카오톡", enrolled);
  window.Kakao.Link.sendCustom({
    templateId: 84624,
    templateArgs: {
      url: shareUrl,
      name,
    },
  });
};

export const onClickShareLink = async (userName, url, enrolled) => {
  try {
    await window.navigator.share({
      title: `[속보] ${userName}님이 나몰래 코딩강의를 완주해...`, // 공유될 제목
      text: "친구가 코딩 완주 메달을 얻었어요! 나도 받을 수 있는지 확인해보세요.", // 공유될 설명
      url: url, // 공유될 URL
      files: [], // 공유할 파일 배열
    });
    console.log("공유성공");
    sendLogAction("공유하기", enrolled);
  } catch (e) {
    console.log("공유실패: ", e);
  }
};

export const copyToClipboard = async (user, enrolled) => {
  try {
    const url = `${window.location.href}?f_name=${user.name}&f_uid=${user._id}`;
    await navigator.clipboard.writeText(encodeURI(url));
    alert("내용이 복사되었으니, 지금 바로 카톡방에 공유해보세요!");
    sendLogAction("공유하기", enrolled);
  } catch (err) {
    console.log("공유실패: ", err);
  }
};

export const onClickDownArrow = (thresholdRef) => {
  window.scroll({
    top: thresholdRef.current.getBoundingClientRect().height,
    behavior: "smooth",
  });
};
