import React from 'react';
import classNames from "classnames/bind";
import {connect} from "react-redux";
import styles from "./FriendsBanner.module.scss";

const cx = classNames.bind(styles);

function FriendsBanner() {
    return (
        <div className={cx('bg')}>
            <div className={cx('friend-banner')}>
                <div className={cx('friend-banner__content')}>
                    <div className={cx('friend-banner__content')}>
                        <span className={cx('friend-banner__content__title')}>
                            {`잠깐, 팀 스파르타의 친구이신가요?
                            5만원 할인 혜택, 당신을 위해 준비했어요!`}
                        </span>
                        <img className={cx('friend-banner__content__img')}
                             src={('/assets/emoji/rtan-wink.png')} alt="르탄이 윙크"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {}
}

const connectedFriendsBanner = connect(mapStateToProps, {})(FriendsBanner);
export {connectedFriendsBanner as FriendsBanner}