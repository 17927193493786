import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./CourseRcmdGeneral.module.scss";
import { rcmdActions } from "../../_state/actions";
import { history, params } from "../../../_helpers";

const cx = classNames.bind(styles);

function CourseRcmdGeneral({ user, rcmdCourses, getRcmdCourses }) {
  const { enrolled_id, selectedValue } = params();
  const isMissing = selectedValue === "beginner";
  useEffect(() => {
    if (!rcmdCourses.loaded) getRcmdCourses(enrolled_id);
  }, []);
  return (
    <div className={cx("course_rcmd__wrapper")}>
      <div className={cx("course_rcmd__header")}>
        <h2 className={cx("course_rcmd__header__sub")}>
          {isMissing ? "뭘 해야할 지 모르겠는" : "경쟁력을 갖추고 싶은"}{" "}
          {user.name}님을 위한 추천
        </h2>
        <h1 className={cx("course_rcmd__header__main")}>
          {!isMissing ? "같은 목표를 가진 분들이" : "다른 수강생이 선택한"}
          <br />{" "}
          {!isMissing ? "이런 강의를 선택했어요!" : "강의부터 시작해보세요!"}
        </h1>
        <p className={cx("course_rcmd__header__desc")}>
          <b>{user.name}님</b>의 끊임없는 성장을 위해
          <br />
          <strong>추천과목 전용 할인쿠폰</strong>을 발급해 드렸어요!
        </p>
        <div className={cx("course_rcmd__card__wrapper")}>
          {rcmdCourses.loaded &&
            rcmdCourses.data.cards.map((card, idx) => {
              return (
                <>
                  <div className={cx("course_rcmd__card")}>
                    <img
                      src={`/assets/images/secretstore/ic_dc_${
                        idx > 0 ? "1" : "0"
                      }.png`}
                      alt={"ic_badge"}
                      className={cx("course_rcmd__card__badge")}
                    />
                    <p className={cx("course_rcmd__card__rank")}>{idx + 1}위</p>
                    <div className={cx("course_rcmd__card__body")}>
                      <img
                        alt={"course_thumb"}
                        src={`https://static.spartacodingclub.kr/static/${card.thumb}`}
                      />
                      <p>{card.desc}</p>
                    </div>
                    <button
                      className={cx("course_rcmd__card__cta")}
                      onClick={() => {
                        window.open(`https://spartacodingclub.kr${card.url}`);
                      }}
                    >
                      수업 보러가기
                    </button>
                  </div>
                </>
              );
            })}
        </div>
        <p className={cx("course_rcmd__notice")}>
          * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
        </p>
      </div>
      <div
        className={cx("course_rcmd__body")}
        style={{
          "--bg-image": `url(${"/assets/images/secretstore/bg_rcmd_general.png"}`,
        }}
      >
        <p className={cx("course_rcmd__bonus__label")}>보너스 쿠폰</p>
        <h2 className={cx("course_rcmd__bonus__header")}>
          듣고 싶은 수업이 없다면?
          <br /> 전과목 할인 쿠폰!
        </h2>
        <p className={cx("course_rcmd__bonus__sub")}>
          다른 수업을 선택하고 싶은 {user.name}님을 위한
          <br /> 전과목 할인쿠폰을 발급해드렸어요!
        </p>
        <div className={cx("course_rcmd__bonus__card")}>
          <img
            className={cx("course_rcmd__bonus__card__img")}
            alt={"ic_rtan_money"}
            src={"/assets/images/secretstore/ic_rtan_money.png"}
          />
          <div className={cx("course_rcmd__bonus__card__detail")}>
            <p className={cx("course_rcmd__bonus__card__detail__title")}>
              완주자 전용 전과목 할인쿠폰
            </p>
            <h3 className={cx("course_rcmd__bonus__card__detail__amount")}>
              30,000원
            </h3>
          </div>
        </div>
        <button
          className={cx("course_rcmd__bonus__cta")}
          onClick={() => {
            window.open("https://spartacodingclub.kr/catalog/featured/summary");
          }}
        >
          전체 수업 둘러보기
        </button>
        <p className={cx("course_rcmd__bonus__notice")}>
          * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
        </p>
        <div className={cx("page__outro__title")}>
          <img
            className={cx("page__outro__title__img")}
            src={"/assets/emoji/emoji_question.png"}
            alt=""
          />
          <div className={cx("page__outro__title__main")}>
            그래도 어떤 강의를 들어야 할지
            <br /> 모르겠다면, 문의주세요!
          </div>
          <div className={cx("page__outro__title__sub")}>
            {user.name}님의 편이 되어 함께 고민해드릴게요.
          </div>
        </div>
        <div
          className={cx("page__outro__cta")}
          onClick={() => {
            window.open(
              `https://spartacodingclub.channel.io/support-bots/30162`
            );
          }}
        >
          문의 신청하기
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, rcmdCourses } = state;
  const { user } = authentication;
  return { user, rcmdCourses };
}

const connectedCourseRcmdGeneral = connect(mapStateToProps, {
  getRcmdCourses: rcmdActions.getRcmdCourses,
})(CourseRcmdGeneral);
export { connectedCourseRcmdGeneral as CourseRcmdGeneral };
