import privateInstance from "../../_helper/axios";

async function getEnrolledSummary() {
  return await privateInstance
    .get(`/enrolleds/summary`)
    .then((resp) => resp.data);
}

async function getEnrolledFinishSummary(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/summary`)
    .then((resp) => resp.data);
}

export const enrolledSummaryService = {
  getEnrolledSummary,
  getEnrolledFinishSummary,
};
