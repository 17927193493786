import { useEffect } from "react";

export const useGetPlayerUrl = (lecture_v2) => {
  const VTT_URL = lecture_v2.loaded
    ? `https://video.spartacodingclub.kr/vod/${lecture_v2.data["classification"]}/${lecture_v2.data["vod_key"]}/${lecture_v2.data["version"]}/subtitles/${lecture_v2.data["lecture_id"]}.vtt`
    : undefined;
  const MP4_URL = lecture_v2.loaded
    ? `https://video.spartacodingclub.kr/vod/${lecture_v2.data["classification"]}/${lecture_v2.data["vod_key"]}/${lecture_v2.data["version"]}/lectures/${lecture_v2.data["lecture_id"]}.mp4`
    : undefined;
  const M3U8_URL = lecture_v2.loaded
    ? `https://video.spartacodingclub.kr/vod/${lecture_v2.data["classification"]}/${lecture_v2.data["vod_key"]}/${lecture_v2.data["version"]}/lectures/${lecture_v2.data["lecture_id"]}.m3u8`
    : undefined;
  const M3U8_URL_ENCRYPTED = lecture_v2.loaded
    ? `https://video.spartacodingclub.kr/vod/${lecture_v2.data["classification"]}/${lecture_v2.data["vod_key"]}/${lecture_v2.data["version"]}/lectures/${lecture_v2.data["lecture_id"]}_encrypted.m3u8`
    : undefined;
  const POST_URL = lecture_v2.loaded
    ? `https://video.spartacodingclub.kr/vod/${lecture_v2.data["classification"]}/${lecture_v2.data["vod_key"]}/${lecture_v2.data["version"]}/thumbs/${lecture_v2.data["lecture_id"]}.png`
    : undefined; // thumbnail
  const LICENSE_SERVER_URL = lecture_v2.loaded
    ? "https://drm.spartacodingclub.kr/license/"
    : undefined;
  const HLS_FREATURE_FLAG = 3;
  const urlObject = {
    vttUrl: VTT_URL,
    mp4Url: MP4_URL,
    m3u8Url: M3U8_URL,
    m3u8UrlEncrypted: M3U8_URL_ENCRYPTED,
    postUrl: POST_URL,
    licenseServerUrl: LICENSE_SERVER_URL,
    hlsFreatureFlag: HLS_FREATURE_FLAG,
  };
  return urlObject;
};
