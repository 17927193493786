import React from "react";
import classNames from "classnames/bind";
import styles from "./PaymentDeliveryCheckModal.module.scss";

const cx = classNames.bind(styles);

function PaymentDeliveryCheckModal({
  visible,
  handleVisible,
  cta,
  handleCloseModal,
  isBackgroundClickable = true,
  deliveryInfo,
  startPayment,
  isPaying,
}) {
  function closeModal() {
    handleVisible(false);
    if (handleCloseModal) {
      handleCloseModal();
    }
    document.body.style.overflow = "auto";
  }
  const {
    name,
    phone0,
    phone1,
    phone2,
    postCode,
    address,
    detailAddress,
    memo,
  } = deliveryInfo;
  const { checkPlatform } = window;
  const platform = checkPlatform();
  const isMobile = platform === "mobile";
  const { btn_text, btnStyleName, handleCTAClick } = cta || {};

  return (
    <>
      <div
        className={cx("bg", `${visible ? "--active" : ""}`)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return isBackgroundClickable ? closeModal() : "";
        }}
      />
      <div className={cx(`modal`, `${visible ? "--active--flex" : ""}`)}>
        <div className={cx("header")}>
          <div className={cx("header-title")}>정보 확인</div>
          <div className={cx("header-desc")}>
            아래의 배송 정보로 굿즈를 배송해드릴 예정입니다. <br />
            작성된 내용에 오류가 없는지 다시 한 번 확인해 주세요!
          </div>
        </div>
        <div className={cx("body")}>
          <div className={cx("row")}>
            <div className={cx("body-title")}>수령인</div>
            <div className={cx("body-content")}>{name}</div>
          </div>
          <div className={cx("row")}>
            <div className={cx("body-title")}>연락처</div>
            <div className={cx("body-content")}>
              {phone0}-{phone1}-{phone2}
            </div>
          </div>
          <div className={cx("row")}>
            <div className={cx("body-title")}>배송지 주소</div>
            <div className={cx("body-content")}>
              ({postCode}) {address}
              <br />
              {detailAddress}
            </div>
          </div>
          <div className={cx("row")}>
            <div className={cx("body-title")}>배송 메모</div>
            <div
              className={cx("body-content", `${memo === "" ? "--non" : ""}`)}
            >
              {memo === "" ? "없음" : memo}
            </div>
          </div>
        </div>
        <div className={cx("ctaContainer")}>
          <div className={cx("cta", "confirm")} onClick={startPayment}>
            {isPaying ? "처리중" : "확인"}
          </div>
          <div className={cx("cta", "back")} onClick={closeModal}>
            뒤로가기
          </div>
        </div>
      </div>
    </>
  );
}

export { PaymentDeliveryCheckModal };
