import { useSelector } from "react-redux";
import { getPopularArticleData } from "../../pages/CompleteRecommendPage/getCompleteRecommendData";

export const usePopularArticles = (courseId) => {
  const { user } = useSelector((state) => state.authentication);

  const articles = getPopularArticleData({
    courseId,
    birthyear: parseInt(user.birthyear),
  });

  return articles;
};
