import styled from "@emotion/styled";
import { zIndexGnb } from "../../../styles/variables";
import { breakpoints } from "../../../../_styles/device";
import { neutralDay } from "@teamsparta/design-system";

export const TopWrapper = styled.div`
  z-index: ${zIndexGnb + 1};
  display: flex;
  top: 0;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  align-items: center;

  border-bottom: ${neutralDay.gray20} 1px solid;
  background-color: white;
  position: inherit;
  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;
export const MainWrapper = styled.div`
  z-index: ${zIndexGnb};
  display: flex;
  flex-direction: column;
  background-color: white;
  //height: 60px;
  top: 42px;
  width: 100%;
  position: inherit;
  border-bottom: ${neutralDay.gray20} 1px solid;
  // 기존 페이지의 style_old.css override 방지용
  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 42px;
  padding: 0 24px;
  margin: 13px auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  min-width: ${breakpoints.tablet}px;
`;

export const InnerContainerBottom = styled(InnerContainer)`
  margin: 9px auto;
`;

export const CursorWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 6px;
`;
export const ColumnContainer = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 14px;
  background: #c7d2d8;
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
`;

export const LogoImg = styled.img`
  width: 100px;
  height: 34px;
`;
