import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100vw;
  height: 84px;
  z-index: 10;
  left: 0;
  bottom: 0;

  background-color: #ffffff;

  ${Device.Desktop} {
    display: none;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
`;

export const Btn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 159px;
  height: 60px;

  background: #e8344e;
  border-radius: 8px;
  cursor: pointer;
`;

export const BtnYellow = styled(Btn)`
  background: #fae301;
`;

export const BtnText = styled.span`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: center;
  gap: 6px;
  color: #ffffff;
`;

export const BtnTextColor = styled(BtnText)`
  color: #141617;
`;
