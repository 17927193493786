import * as S from "./FinishRecommend.style";

const FinishRecommendContent = ({ category, value, detail }) => {
  return (
    <S.ContentContainer>
      <S.CourseOverviewCategory>{category}</S.CourseOverviewCategory>
      <S.CourseOverviewValue>{value}</S.CourseOverviewValue>
      <S.CourseOverviewDetail>{detail}</S.CourseOverviewDetail>
    </S.ContentContainer>
  );
};

export default FinishRecommendContent;
