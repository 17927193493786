import { config } from "../../config";
import { authHeader, getCurrentUrl, logout } from "../../_helpers";
import axios from "axios";

const API_VERSION = "v2";
const baseURL = `${config.apiUrl}/${API_VERSION}`;
axios.defaults.baseURL = baseURL;
const loggingOut = {};

const privateInstance = axios.create({
  baseURL,
  timeout: 100000,
  headers: Object.assign({
    "Content-Type": "application/json",
    "X-CLIENT-KEY": "sparta",
  }),
});

let requestCache = {};
privateInstance.interceptors.request.use(
  function (config) {
    if (!config.headers.Authorization) {
      config.headers = Object.assign(
        { "Content-Type": "application/json" },
        authHeader()
      );
    }
    if (
      JSON.stringify(config) === JSON.stringify(requestCache) &&
      !config?.skipDuplicatedCheck
    ) {
      const skipXHRError = new Error("skip");
      skipXHRError.isSkipXHR = true;
      skipXHRError.request = config;
      console.log("%c[DEBUG] duplicated request", "color: #AAAAAA");
      throw skipXHRError;
    } else {
      requestCache = Object.assign({}, config);
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

privateInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.isSkipXHR) {
    } else {
      const { status } = error.response;
      if (status === 401) {
        if (!loggingOut.is) {
          const errorMessage = error.response.data
            ? error.response.data.message
            : "로그인이 만료되었습니다.\n다시 로그인해주시기 바랍니다.";
          loggingOut.is = true;
          // Todo: 로그아웃 문제 해결
          logout(getCurrentUrl());
        }
      } else if (status === 400) {
        if (error.response.data?.message) {
          alert(error.response.data?.message);
          window.location.href = `/`;
        }
      }
    }

    return Promise.reject(error);
  }
);

export default privateInstance;
