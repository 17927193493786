import {Button, Modal} from "antd";
import React from "react";

export const BeenLoginedNoticeModal = (loggingIn, user, next) => {
    return <Modal
        title="이미 로그인이 되어있습니다."
        visible={!loggingIn && user != undefined && next == undefined}
        closable={false}
        footer={[
            <Button
                key="ok"
                type="primary"
                onClick={() =>
                    (window.location.href = "https://spartacodingclub.kr")
                }
            >
                확인
            </Button>,
        ]}
    >
        <p>홈으로 이동합니다.</p>
    </Modal>
}