import styled from "styled-components";
import { PIRATES } from "../../../../_consts";

export const QuestionNumber = styled.div`
  height: 21px;
  font-family: Pretendard;
  font-style: normal;
  color: #e8344e;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  justify-content: start;
`;

export const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 100%;
  left: 16px;
  top: 525px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#f6f9fa";
    }
  }};

  @media (min-width: 500px) {
    padding: 30px 16px;
    background: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#222425";
        default:
          return "#f6f9fa";
      }
    }};
  }
`;
export const QuestionTitle = styled.div`
  height: 21px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  margin-bottom: 32px;
`;

export const QuestionSelectBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 326px;
  justify-content: space-between;
  padding: 0px 16px;
  position: relative;
  align-items: flex-start;
  margin: 0 auto;
`;

export const QuestionSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const QuestionSelectDescription = styled.div`
  text-align: center;
  font-family: "Pretendard";
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#B4BFC6";
      default:
        return "#81898f";
    }
  }};
  padding-top: 6px;
`;

export const QuestionSelectBox = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 48px;
  line-height: 46px;
  height: 46px;
  left: 16px;
  top: 0px;
  background: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return isChecked ? "#EEF3F6" : "#2B2D2E";
      default:
        return isChecked ? "#E8344E" : "#FFFFFF";
    }
  }};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return "#9DA7AE";
      default:
        return isChecked ? "#E8344E" : "#D7E0E6";
    }
  }};
  z-index: 1;

  :hover {
    background: ${({ isChecked, theme }) => {
      switch (theme) {
        case PIRATES:
          return isChecked ? null : "#5F666B";
        default:
          return isChecked ? null : "#F2F6F8";
      }
    }};
  }

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return isChecked ? "#2B2D2E" : "#EEF3F6";
      default:
        return isChecked ? "#FFFFFF" : "#3A3E41";
    }
  }};
`;

export const QuestionLine = styled.div`
  width: 270px;
  height: 1px;
  left: 25px;
  top: 22px;
  background: #d7e0e6;
  position: absolute;
`;
export const SubQuestionTextArea = styled.textarea`
  padding: 18px 16px;
  resize: none;
  gap: 12px;
  height: 120px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#d7e0e6";
      }
    }};
  border-radius: 8px;
  margin-top: 16px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#eef3f6";
      default:
        return "rgba(0,0,0,.65)";
    }
  }};

  &::placeholder {
    color: #c7c9cb;

    &.pirates {
      color: #81898f;
    }
  }
`;
