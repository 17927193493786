import styled from "@emotion/styled";
import {
  mBody2,
  mBody4,
  mHeader2,
  neutralDay,
  wBody2,
  wTitle1,
  wTitle5,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const EvaluationIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin: 0 auto;
  max-width: 772px;
  width: 100%;
  white-space: pre-wrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }
`;

export const EvaluationIntroMainTitle = styled.h1`
  ${wTitle1};
  color: ${neutralDay.gray100};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    align-self: stretch;
    color: ${neutralDay.gray100};
  }
`;

export const EvaluationIntroDescription = styled.h5`
  ${wTitle5};
  color: ${neutralDay.gray70};
  text-align: center;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: ${neutralDay.gray70};
    text-align: left;
  }
`;

export const EvaluationIntroContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EvaluationIntroContentBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const EvaluationIntroContentText = styled.h2`
  ${wBody2};
  color: ${neutralDay.gray80};
  letter-spacing: 0px;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody4};
    color: ${neutralDay.gray80};
  }
`;

export const GrayRectangle = styled.div`
  width: 1px;
  height: 12px;
  background: ${neutralDay.gray30};
`;
