import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Navbar.module.scss";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions/enrolled.actions";
import { usersActions } from "../../../_state/actions";
import { NavComponent } from "../NavComponent";
import { history } from "../../../../_helpers";
import { numberWithCommas } from "../../../_helper";
import { roundsActions } from "../../../_state/actions/rounds.actions";
import { keyword2icon } from "../../../assets/icons/keyword2icon";
import { sendLog } from "../../../businesslogics/logging";
import { getLastUrl } from "../../../utils/windowFunction";

const cx = classNames.bind(styles);
const keyword2icons = keyword2icon;

function Navbar({
  match,
  user,
  enrolled,
  points,
  enrolleds,
  getEnrolleds,
  getPoints,
  getRoundWeeks,
}) {
  // 사용자가 선택한 나와의 약속 날짜
  const [logoText, setLogoText] = useState("SPARTA!");
  const [courseLogoPath, setCourseLogoPath] = useState(
    `/source/src/assets/icons/course/ic_course_web.png`
  );

  const location = getLastUrl();

  useEffect(() => {
    if (enrolled.loaded) {
      const { courses } = enrolled.data;
      getPoints();
      getEnrolleds();

      const ic_idx =
        courses.keyword in keyword2icons
          ? keyword2icons[courses.keyword]
          : keyword2icons["web"];
      setCourseLogoPath(`/assets/icons/course/ic_course_${ic_idx}.png`);
    }
  }, [enrolled]);

  function handleLogoMouseOver() {
    setLogoText("SPARTA!!!");
  }

  function onMouseLeave() {
    setLogoText("SPARTA!");
  }

  return (
    <div className={cx("navbar-wrapper")}>
      <div className={cx("navbar-container")}>
        <div
          className={cx("navbar-container__logo")}
          onClick={() => {
            location &&
              sendLog(
                `scc_${location}Page_click_button`,
                {
                  ...enrolled.data,
                  button_text: "내 강의실",
                  next_page: "classroomPage",
                  details: `${location}Page에서 classroomPage로 이동`,
                },
                ""
              );
            history.push("/");
          }}
        >
          <img
            className={cx("navbar-container__logo__img")}
            src={`/assets/icons/logo-web.svg`}
          />
        </div>
        <div className={cx("navbar-container__contents")}>
          {points.loaded && (
            <NavComponent
              summary={{
                summary_icon: `/assets/icons/ic_moneybag.png`,
                summary_text: `${numberWithCommas(points.data.point)}원`,
              }}
              header={{
                header_title: "내 포인트",
              }}
              content={
                <div className={cx("point-component")}>
                  <img
                    className={cx("point-component__img")}
                    src={"/assets/icons/ic_coin_stack.svg"}
                    alt=""
                  />
                  <span className={cx("point-component__text")}>
                    {numberWithCommas(points.data.point)}원
                  </span>
                </div>
              }
              cta={{
                handleCTAClick: handleCTAPoint,
                btn_text: "내 포인트 보러가기",
              }}
            />
          )}
          {enrolled.loaded && enrolleds.loaded && (
            <NavComponent
              summary={{
                summary_icon: courseLogoPath,
                summary_text: enrolled.data.courses.title,
              }}
              header={{
                header_title: "내 강의",
              }}
              content={
                <div>
                  {enrolleds.data.map((erd, idx) => {
                    const enrolled_id = erd.enrolled_id;
                    const round_id = erd.round_id;
                    const ic_idx =
                      erd.courses.keyword in keyword2icons
                        ? keyword2icons[erd.courses.keyword]
                        : keyword2icons["web"];
                    return (
                      <div
                        className={cx("erd-component")}
                        key={idx}
                        onClick={() => {
                          history.push(
                            `/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`
                          );
                          getRoundWeeks(enrolled_id);
                        }}
                      >
                        <img
                          className={cx("erd-component__img")}
                          src={`/assets/icons/course/ic_course_${ic_idx}.png`}
                          alt=""
                        />
                        <span className={cx("erd-component__text")}>
                          {erd.courses.title} {erd.rounds.round}
                        </span>
                      </div>
                    );
                  })}
                </div>
              }
              cta={{
                handleCTAClick: handleCTAEnrolleds,
                btn_text: "다른 강의 보러가기",
              }}
            />
          )}
          {user && (
            <NavComponent
              summary={{
                summary_icon: `/assets/images/profile/rtan_01.png`,
                summary_text: "나의 수업",
              }}
              header={{
                is_hidden: true,
              }}
              content={
                <div className={cx("profile-component")}>
                  <div
                    className={cx("profile-component__el--active")}
                    onClick={() => {
                      history.push("/classroom");
                    }}
                  >
                    내 강의실
                  </div>
                  <div
                    className={cx("profile-component__el")}
                    onClick={() => {
                      history.push("/mypage");
                    }}
                  >
                    마이페이지
                  </div>
                  <div
                    className={cx("profile-component__el")}
                    onClick={() => {
                      sendLog(
                        "scc_profileIcon_dropdown_rcmdFriend",
                        {
                          button_text: "친구초대하기",
                        },
                        "",
                        true
                      );
                      history.push("/rcmds");
                    }}
                  >
                    친구초대하기
                  </div>
                </div>
              }
              cta={{
                is_hidden: true,
              }}
              pos={"right"}
            />
          )}
        </div>
        <div className={cx("navbar-container-wrapper")}>
          <div
            className={cx("navbar-container__classroom")}
            onClick={() => {
              history.push("/classroom");
            }}
          >
            내 강의실
          </div>
          <div
            className={cx("navbar-container__mypage")}
            onClick={() => {
              history.push("/mypage");
            }}
          >
            마이페이지
          </div>
        </div>
      </div>
    </div>
  );
}

const handleCTAPoint = () => {
  history.push("/mypage/mypoint");
};

const handleCTAEnrolleds = () => {
  window.open("/", "_blank");
};

function mapStateToProps(state) {
  const { authentication, points, enrolled, enrolleds } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    points,
    enrolled,
    enrolleds,
  };
}

const connectedNavbar = connect(mapStateToProps, {
  getEnrolleds: enrolledActions.getEnrolleds,
  getPoints: usersActions.getPoints,
  getRoundWeeks: enrolledActions.getRoundWeeks,
  setRound: roundsActions.setRound,
  setEnrolled: enrolledActions.setEnrolled,
})(Navbar);
export { connectedNavbar as Navbar };
