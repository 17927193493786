import { useQuery } from "react-query";

import privateInstanceV3 from "../../../_helper/axiosV3";

export const getMaterials = async (enrolled_id, week) => {
  const response = await privateInstanceV3.get(
    `/learning-resources/enrollments/${enrolled_id}/week/${week}/materials`
  );
  return response.data;
};

export const useMaterials = (enrolled_id, week) => {
  return useQuery(
    ["materials", enrolled_id, week],
    async () => {
      return await getMaterials(enrolled_id, week);
    },
    {
      onSuccess: (data) => {
        console.log("onSuccess");
      },
      onError: (error) => {
        console.log("onError");
      },
      enabled: !!enrolled_id && !!week,
    }
  );
};
