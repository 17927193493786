import * as S from "./question.style";

const Question = ({ index, title }) => {
  return (
    <S.Container>
      <S.Index>Q{index + 1}.</S.Index>
      <S.Title>{title}</S.Title>
    </S.Container>
  );
};

export default Question;
