import { useParams } from "react-router-dom";
import {
  useEnrolled,
  useEnrolledDetail,
  useEnrolledProgress,
} from "./useProductName";
import { sendLog } from "../businesslogics/logging";
import { useMyHomeworks } from "../businesslogics/OrderedEnrollmentContents/repository/useMyHomeworks";
import { useSelector } from "react-redux";

export const useLoadLogData = () => {
  const { enrolled_id, detail_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const enrolledDetail = useEnrolledDetail(enrolled_id, detail_id);
  const enrolledProgress = useEnrolledProgress(enrolled_id);
  const playbackSpeed = localStorage.getItem("playbackSpeedV3");
  const isCaptionOn = localStorage.getItem("captionV3");

  return {
    ...enrolled.data,
    ...enrolledDetail.data,
    progress: enrolledProgress.data,
    subtitle_status: isCaptionOn === "true" ? "on" : "off",
    speed_value: playbackSpeed,
  };
};

export const useLoadHomeworkLogData = () => {
  const { enrolled_id, enrolled_homework_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const homeworks = useMyHomeworks(enrolled_homework_id);
  const enrolledProgress = useEnrolledProgress(enrolled_id);
  const enrolledsHomeworkDetails = useSelector(
    (state) => state.enrolledsHomeworkDetails
  );
  return {
    ...enrolled.data,
    ...homeworks.data,
    progress: enrolledProgress?.data,
    submitted_answer: enrolledsHomeworkDetails,
  };
};

export const useLogHomeworkCompleted = () => {
  const logData = useLoadHomeworkLogData();
  return () => {
    sendLog("scc_homework_completed", logData);
  };
};

export const useLogLectureCompleted = () => {
  const logData = useLoadLogData();
  return () => {
    sendLog("scc_lecture_completed", logData);
  };
};
