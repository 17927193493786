import React, { useState } from "react";
import styles from "./SpalandCheckinPageStamp.module.scss";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { spalandActions } from "../../_state/actions";
import { sendLog } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

const SpalandCheckinPageStamp = ({
  dateOfday,
  spalandDates,
  postSpalandDateAttendance,
}) => {
  // 표시해야하는 날짜정보
  let dateInstance = new Date(dateOfday);
  let date = dateInstance.getDate();
  let dayOfWeek = dateInstance.getDay() === 0 ? 6 : dateInstance.getDay() - 1;
  const [isHover, setIsHover] = useState(false);
  const isCodingDay =
    spalandDates[dayOfWeek] && spalandDates[dayOfWeek].is_coding_day;
  function attendSpaland() {
    if (new Date().getDate() < date) {
      alert("아직 참석하려면 멀었어요!");
    } else if (new Date().getDate() > date) {
      alert("출석일자가 지났습니다!");
    } else if (spalandDates[dayOfWeek] && spalandDates[dayOfWeek].attended) {
      alert("이미 출석체크 하셨네요!");
    } else {
      postSpalandDateAttendance().then((res) => {
        if (res.ok) {
          sendLog("scc_spaland_completed", {
            enrolled_id: spalandDates[dayOfWeek]?.enrolled_id,
          });
          alert("완료되었습니다!");
          window.location.reload();
        }
      });
    }
  }

  return (
    <div
      className={cx(
        "stampContainer",
        `${dateInstance.getDay() === new Date().getDay() ? "--isToday" : ""}`
      )}
      onClick={() => {
        attendSpaland();
      }}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {dateInstance.getDay() === new Date().getDay() && (
        <div className={cx("stampContainer__help_text_container")}>
          <img src={`/v2/assets/icons/ic_arrow_top.svg`} alt={"ic_arrow_top"} />
          <span>
            {" "}
            {spalandDates[dayOfWeek] && spalandDates[dayOfWeek].attended
              ? "완료!"
              : "클릭!"}
          </span>
        </div>
      )}
      {isCodingDay && (
        <div className={cx("stampContainer__coding_day_container")}>
          코딩하는 날
        </div>
      )}
      {spalandDates[dayOfWeek] !== undefined && (
        <img
          className={cx(
            "stampContainer__img",
            `${isCodingDay ? "--isCodingDay" : ""}`
          )}
          src={`/v2/assets/icons/spaland${
            isCodingDay ? "_codingday" : ""
          }_checkin_${
            spalandDates[dayOfWeek].attended
              ? "checkedin"
              : isHover
              ? "hover"
              : "default"
          }_stamp.svg`}
          alt={"ic_stamp"}
        />
      )}
      <span
        className={cx(
          "stampContainer__text",
          `${
            spalandDates[dayOfWeek] && spalandDates[dayOfWeek].attended
              ? "--attended"
              : ""
          }`
        )}
      >
        {date}
      </span>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

const connectedSpalandCheckinPageStamp = connect(mapStateToProps, {
  postSpalandDateAttendance: spalandActions.postSpalandDateAttendance,
})(SpalandCheckinPageStamp);
export { connectedSpalandCheckinPageStamp as SpalandCheckinPageStamp };
