import { Info1Line } from "@teamsparta/design-system";
import * as S from "./Ga4Modal.style";

export const Ga4Modal = ({ isShow, closeModal }) => {
  const isEventEnd = new Date() > new Date("2023-07-03");

  return (
    isShow && (
      <S.ModalWrapper>
        <S.Container>
          <S.Title>
            <Info1Line size={20} color={"#B4BFC6"} /> GA4 강의 관련 안내
          </S.Title>
          {isEventEnd ? (
            <S.SubTitle>
              원활한 실습 진행을 위해
              <br />
              <b>Webflow 유료 계정 구매가 필요합니다.</b>
              <br />
              수강신청에 참고 부탁드립니다.
            </S.SubTitle>
          ) : (
            <S.SubTitle>
              본 강의의 원활한 실습 진행을 위해
              <br /> Webflow 유료 구매가 필요합니다. (월 29$){" "}
            </S.SubTitle>
          )}

          {isEventEnd ? (
            <S.BenefitContainer>
              <S.BenefitElement>
                <S.BenefitIndex>1</S.BenefitIndex>
                <S.BenefitText>
                  수강신청 후 Webflow 계정 구입 (월 29$)
                </S.BenefitText>
              </S.BenefitElement>
              <S.BenefitElement>
                <S.BenefitIndex>2</S.BenefitIndex>
                <S.BenefitText>
                  Webflow를 활용해 실제 웹사이트
                  <br />
                  데이터로 실습 진행
                </S.BenefitText>
              </S.BenefitElement>
              <S.BenefitElement>
                <S.BenefitIndex>3</S.BenefitIndex>
                <S.BenefitText>
                  실습 종료 후 자유롭게 Webflow 계정 해지
                </S.BenefitText>
              </S.BenefitElement>
            </S.BenefitContainer>
          ) : (
            <S.BenefitContainer>
              <S.BenefitTitle>
                Webflow 구매 지원금 이벤트{" "}
                <S.BenefitSubTitle>{`(~7/2)`}</S.BenefitSubTitle>
              </S.BenefitTitle>
              <S.BenefitElement>
                <S.BenefitIndex>1</S.BenefitIndex>
                <S.BenefitText>7/2까지 GA4 강의 수강 신청하고,</S.BenefitText>
              </S.BenefitElement>
              <S.BenefitElement>
                <S.BenefitIndex>2</S.BenefitIndex>
                <S.BenefitText>수강기간 내 완주하면</S.BenefitText>
              </S.BenefitElement>
              <S.BenefitElement>
                <S.BenefitIndex>3</S.BenefitIndex>
                <S.BenefitText>
                  수강기간 종료 후
                  <S.BenefitTextRed> 30,000원 환급!</S.BenefitTextRed>
                </S.BenefitText>
              </S.BenefitElement>
            </S.BenefitContainer>
          )}

          {!isEventEnd && (
            <S.NoticeContainer>
              <S.Notice>
                본 강의는 실제 웹사이트 데이터로 실습을 진행하기 위해 Webflow
                유료 계정이 필요합니다.{" "}
              </S.Notice>
              <S.Notice>
                관련해 Webflow 구매 지원금 이벤트를 진행하고 있으니 수강 신청에
                참고 부탁드립니다.
              </S.Notice>
            </S.NoticeContainer>
          )}

          <S.MobileButton onClick={closeModal}>확인했어요</S.MobileButton>
        </S.Container>

        <S.Background />
      </S.ModalWrapper>
    )
  );
};
