import { CLASSROOM_CARDS, EXPIRED_CLASSROOM_CARDS } from "../constants";

const initState = {
  loaded: false,
};

export function classroomCards(state = initState, action) {
  switch (action.type) {
    case CLASSROOM_CARDS.CLASSROOM_CARDS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case CLASSROOM_CARDS.CLASSROOM_CARDS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case CLASSROOM_CARDS.CLASSROOM_CARDS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function expiredClassroomCards(state = initState, action) {
  switch (action.type) {
    case EXPIRED_CLASSROOM_CARDS.EXPIRED_CLASSROOM_CARDS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case EXPIRED_CLASSROOM_CARDS.EXPIRED_CLASSROOM_CARDS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.data.context,
      });
    case EXPIRED_CLASSROOM_CARDS.EXPIRED_CLASSROOM_CARDS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
