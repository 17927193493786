import styled from "@emotion/styled";
import { DisplayResolution } from "../../../../../_styles/device";
import {
  additional,
  mBody2,
  mTitle1,
  neutralDay,
  wBody2,
  wTitle2,
} from "@teamsparta/design-system";

export const ContentContainer = styled.div`
  display: flex;
  gap: 16px;

  ${DisplayResolution.TabletAndDesktop} {
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const Title = styled.h3`
  ${mTitle1}
  color: ${neutralDay.gray100};

  > b {
    ${mTitle1}
    color: ${additional.orange100};
  }

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle2};
    color: ${neutralDay.gray100};

    > b {
      ${wTitle2};
      color: ${additional.orange100};
    }
  }
`;

export const SubTitle = styled.p`
  ${mBody2}
  color: ${neutralDay.gray80};
  margin-bottom: 20px;

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
  }
`;

export const NbCardImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${neutralDay.gray5};

  ${DisplayResolution.TabletAndDesktop} {
    width: 52px;
    height: auto;
    border-radius: 8px;
  }
`;
