import { HOMEWORKS } from "../constants";
import { homeworksService } from "../services";

function getMyHomeworks(enrolled_id, enrolleds_homeworks_id) {
  function request() {
    return { type: HOMEWORKS.GET_HOMEWORKS_REQUEST };
  }

  function success(response) {
    return { type: HOMEWORKS.GET_HOMEWORKS_SUCCESS, response };
  }

  function failure(error) {
    return { type: HOMEWORKS.GET_HOMEWORKS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    homeworksService.getMyHomeworks(enrolled_id, enrolleds_homeworks_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getHomeworkAnswer(enrolled_homework_id) {
  function request() {
    return { type: HOMEWORKS.GET_HOMEWORK_ANSWER_REQUEST };
  }

  function success(response) {
    return { type: HOMEWORKS.GET_HOMEWORK_ANSWER_SUCCESS, response };
  }

  function failure(error) {
    return { type: HOMEWORKS.GET_HOMEWORK_ANSWER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    homeworksService.getHomeworkAnswer(enrolled_homework_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const homeworksActions = {
  getMyHomeworks,
  getHomeworkAnswer,
};
