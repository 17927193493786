import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./CheckinCards.module.scss";
import { connect } from "react-redux";
import privateInstance from "../../../_helper/axios";
import { CheckinCard } from "./CheckinCard";
import { checkinActions } from "../../../_state/actions";

const cx = classNames.bind(styles);

function CheckinCards({ allCheckins, getCheckins }) {
  const [offset, setOffset] = useState(1);
  const [checkinList, setCheckinList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (!isFetching) return;
    getCheckinList();
  }, [isFetching]);

  useEffect(() => {
    if (allCheckins && allCheckins.loaded) {
      setIsFetching(false);
      setOffset(2);
      setCheckinList([...allCheckins.data.checkins]);
    }
  }, [allCheckins]);

  const getCheckinList = async () => {
    await privateInstance
      .get("/checkins/" + `?offset=${offset}`)
      .then((resp) => {
        const old_checkins = checkinList;
        const new_checkins = resp.data.context.checkins;
        setCheckinList([...old_checkins, ...new_checkins]);
        setOffset(offset + 1);
        setIsFetching(false);
      });
  };

  const handleScroll = (e) => {
    if (document.getElementById("checkinList") === null) {
      return;
    }
    let offsetHeight = document.getElementById("checkinList").offsetHeight;
    let loadTrigger =
      offsetHeight - (e.target.body.offsetHeight + window.pageYOffset);
    if (loadTrigger < 30) {
      setIsFetching(true);
    }
  };
  //window.addEventListener('scroll', handleScroll);
  return (
    <div id="checkinList" className={cx("checkin-Cards-wrap")}>
      {checkinList.map((checkin, idx) => {
        return <CheckinCard checkin={checkin} key={idx} isHero={false} />;
      })}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, allCheckins } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    allCheckins,
  };
}

const connectedCheckinCards = connect(mapStateToProps, {
  getCheckins: checkinActions.getCheckins,
})(CheckinCards);
export { connectedCheckinCards as CheckinCards };
