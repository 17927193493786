// PrivateRoute.js
import React from "react";
import { Route } from "react-router-dom";
import { useLoginV2Required } from "./auth";
import { consoleLogForDev } from "../utils/consoleLog";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useLoginV2Required();
  consoleLogForDev(true, "🙏 isAuthenticated", isAuthenticated);
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <></>)}
    />
  );
};

export default PrivateRoute;
