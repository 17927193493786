import React from "react";

export const getQuestions = (round_id) => {
  const RADIO = "radio";
  const CHECK = "check";
  const TEXT = "text";

  switch (round_id) {
    // GS 1기
    case "6321990669961b3cd531c7b9":
    // BCG 1기
    case "6323f3f7f73093c2dc04a136":
    case "6323f4472ab8b7e94e7900c5":
    case "6323f65e3e771e475b4a3119":
      return [
        {
          type: RADIO,
          content: "본 교육의 커리큘럼 및 내용 구성은 어떠셨나요?",
        },
        {
          type: RADIO,
          content: "본 교육의 즉문즉답은 만족스러우셨나요?",
        },
        {
          type: RADIO,
          content:
            "교육 수강 이 후, 디지털 관련 회의 또는 프로젝트에 참여하게 된다면\n코딩, 웹개발 등의 주요 용어 및 개념을 이해할 수 있으신가요?",
        },
        {
          type: RADIO,
          content: "본 교육이 내 미래에 도움이 될 것 같다고 생각하시나요?",
        },
        {
          type: RADIO,
          content:
            "회사에서 이번 과정과 같은 교육을 지속적으로 진행하면\n참가할 의사가 있으신가요?",
        },
        {
          type: CHECK,
          content:
            "본 교육을 주변의 동료나 친구에게 추천할 의향이 얼마나 되시나요?",
        },
        {
          type: TEXT,
          content:
            "더 나은 교육을 위해서 필요한 점이나, 이런게 있었으면 좋았을텐데 하는 아쉬웠던 점이 있나요?",
          placeholder: "무엇이든 말씀해주세요 강의개선에 큰 도움이 됩니다.",
        },
        {
          type: TEXT,
          content:
            "본 교육 중에서 마음에 들었고, 계속 강화해주었으면 하는 좋은 점은 무엇이었나요?",
          placeholder:
            "무엇이든 말씀해주세요! 저희는 더  만족스러운 강의가 될 수 있도록 준비해놓을게요.",
        },
        {
          type: TEXT,
          content:
            "이번 교육에 대해 다하지 못한 이야기가 있다면 자유롭게 남겨주세요 (선택)",
          placeholder: "소중한 의견 감사드립니다. :)",
          isOptional: true,
        },
      ];

    default:
      return [
        {
          type: RADIO,
          content: "본 교육의 커리큘럼 및 내용 구성은 어떠셨나요?",
        },
        {
          type: RADIO,
          content: "본 교육의 즉문즉답은 만족스러우셨나요?",
        },
        {
          type: RADIO,
          content: "본 교육에서 진행되는 게더타운스터디는 만족스러우셨나요?",
        },
        {
          type: RADIO,
          content: "본 교육이 실무에 얼마나 도움이 되었나요?",
        },
        {
          type: CHECK,
          content:
            "본 교육을 주변의 동료나 친구에게 추천할 의향이 얼마나 되시나요?",
        },
        {
          type: TEXT,
          content:
            "더 나은 교육을 위해서 필요한 점이나, 이런게 있었으면 좋았을텐데 하는 아쉬웠던 점이 있나요?",
          placeholder: "무엇이든 말씀해주세요 강의개선에 큰 도움이 됩니다.",
        },
        {
          type: TEXT,
          content:
            "본 교육 중에서 마음에 들었고, 계속 강화해주었으면 하는 좋은 점은 무엇이었나요?",
          placeholder:
            "무엇이든 말씀해주세요! 저희는 더  만족스러운 강의가 될 수 있도록 준비해놓을게요.",
        },
        {
          type: TEXT,
          content:
            "이번 교육에 대해 다하지 못한 이야기가 있다면 자유롭게 남겨주세요 (선택)",
          placeholder: "소중한 의견 감사드립니다. :)",
          isOptional: true,
        },
      ];
  }
};
