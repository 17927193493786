import styled from "@emotion/styled";

const BenefitCards = ({ children }) => {
  return <Container>{children}</Container>;
};

export default BenefitCards;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
`;
