import { BROADCAST_LIST } from "../constants/broadcast.constants";
import { broadcastService } from "../services";

function getBroadcastList() {
  function request() {
    return { type: BROADCAST_LIST.BROADCAST_LIST_REQUEST };
  }

  function success(response) {
    return { type: BROADCAST_LIST.BROADCAST_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: BROADCAST_LIST.BROADCAST_LIST_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    broadcastService.getBroadcastList().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetBroadcastList = () => {
  return getBroadcastList();
};

export const broadcastActions = {
  getBroadcastList,
};
