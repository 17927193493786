const { amplitude } = window;

// 무료특강 course_id
export const CODING_101 = "6178b82938d078df2971b63f"; // 1시간만에 끝내는 직장인 코딩 용어 해설
export const DEVELOPER_101 = "625963fa9d5b4ee7f14b61d9"; // [무료 패키지] 개발자 취업 준비의 모든 것
export const MYPROFILE = "62a739bfc826b9902f9d9ad1"; // 나만의 프로필 링크 페이지 만들기
export const CHANG = "632bec62293ed67329e02c62"; // IT 창업, 실패 확률 줄이기
export const TEENAGER = "6334259bd6566f2cb23ec7f7"; // 중고등학생 코딩교육 준비 전략
export const CHATGPT = "6420ea5f7e6e4c8f5d0af5d0"; // ChatGPT로 10분만에 웹사이트 만들기
export const HR = "632ad50b2b63868e4030eb5b"; // 교육담당자를 위한 DT교육 설계의 모든 것
export const BUCKETLIST = "637dc32b33db1e53a14a6d29"; // 2023 나만의 버킷리스트 만들기
export const MOMENTUM = "6358e2f6d5e61148f86b3267"; // 나만의 동기부여 홈화면 만들기
export const PHOTOS = "63d5e477263dfb150c1c3c94"; // 인생사진 쏙쏙 코딩네컷
export const PRAC_SQLD = "6433b6aab8d11cc10a00b294"; //"실전 "SQLD 자격증 대비반
export const NBSQL = "616d4349460cf1cf6f9579ec"; // [왕초보] 엑셀보다 쉬운, SQL
export const NBSQL_NEW = "64b0a1fcea9f1009fa5f8cf8"; // [왕초보] 엑셀보다 쉽고 빠른 SQL
export const ONLINESQL = "5f51cba7d76b11f9244d1111"; // 엑셀보다 쉬운 SQL
export const ONLINE_WEB = "5f0ae408765dae0006002816"; // 웹개발 종합반
export const GOV_WEB = "60a5ee42bf272bef32a58fb9"; // [왕초보] 비개발자를 위한, 웹개발 종합반 (프로그래밍 실무, 풀스택)
export const NBFREE = "642540848a9def2d6766b27a"; // 내일배움카드 발급부터 활용의 모든 것

export const NOCODE = "642c2dfff6dfefee6dc47bfb"; // 코드 없이 AI로 웹사이트 하나가 뚝딱
export const NBFIGMA = "64b0a20ed0eafff0cc6a2743"; // [왕초보] PPT보다 쉬운 피그마
export const NBNOCODE = "64b0a2237be3bd117c4ec29a"; // [왕초보] 노코드로 완성도 높은 웹사이트 만들기
export const CODINGTEST = "64e32bb1fd878f27f5acae5f"; //1시간만에 정복하는 코딩테스트 합격법
export const MOONWISH = "64efe93e3c4f28c927faf0bf"; //코딩으로 보름달에 소원 보내기
export const AI_VIRTUAL = "64939ff350f4c9159ab204d8"; // AI로 나만의 버추얼 캐릭터 만들기
export const SQL_NEW = "64956fe61e7a5e8c1b9c6e50" // 엑셀보다 쉽고 빠른 SQL

// 배너 이미지, 링크 세트
export const banners = {
  GPT_클론: {
    image: "/assets/images/banners/banner_aside_clone.png",
    url: "https://online.spartacodingclub.kr/payment/64269c728a9def2d676781b5",
    title: "clone",
  },
  GPT_업무자동화: {
    image: "/assets/images/banners/banner_aside_automation.png",
    url: "https://online.spartacodingclub.kr/payment/6426a044e09a454370af1d3c",
    title: "automation",
  },
  GPT_데이터: {
    image: "/assets/images/banners/banner_aside_gptData.png",
    url: "https://online.spartacodingclub.kr/payment/6426a0fe8a9def2d67678479",
    title: "gpt_data",
  },
  내배단: {
    image: "/assets/images/banners/banner_aside_nb.png",
    url: "https://spartacodingclub.kr/nb",
    title: "nb",
  },

  항해99: {
    image: "/assets/images/banners/banner_aside_hh99.png",
    url: "https://hanghae99.spartacodingclub.kr/",
    title: "hanghae99",
  },

  스파르타온라인: {
    image: "/assets/images/banners/banner_aside_online.png",
    url: "https://spartacodingclub.kr/catalog",
    title: "online",
  },
  창: {
    image: "/assets/images/banners/banner_aside_chang.png",
    url: "https://chang.spartacodingclub.kr/",
    title: "chang",
  },
  띵동코딩: {
    image: "/assets/images/banners/banner_aside_ddingdong_youth.png",
    url: "https://ddingdong.spartacodingclub.kr/",
    title: "ddingdong",
  },
  기업교육: {
    image: "/assets/images/banners/banner_aside_b2b.png",
    url: "https://spartacodingclub.kr/b2b",
    title: "b2b",
  },
  sw캠프: {
    image: "/assets/images/banners/banner_aside_swcamp.png",
    url: "https://swcamp.spartacodingclub.kr/",
    title: "swcamp",
  },
  메이킹챌린지: {
    image: "/assets/images/banners/banner_aside_makingChallenge.png",
    url: "https://sparta-makingchallenge.oopy.io/",
    title: "makingchallenge",
  },
  웹개발플러스: {
    image: "/assets/images/banners/banner_aside_webPlus.png",
    url: "https://spartacodingclub.kr/online/webplus",
    buttonTitle: "웹개발 플러스 보러가기",
  },
  새해이벤트: {
    image: "/assets/images/banners/banner_aside_newyear.png",
    url: "https://spartacodingclub.kr/newyear",
    buttonTitle: "이벤트 보러가기",
  },
  SQLD: {
    image: "/assets/images/banners/banner_aside_sqld.png",
    url: "https://certificate-sqld.oopy.io/",
    title: "sqld",
    buttonTitle: "자세히 보러가기",
  },
  SQLD_진도표: {
    image: "/assets/images/banners/banner_aside_sqld_progress.png",
    url: "https://www.notion.so/995e8e8e94b147d0a082e7ff25fce0e0",
    title: "sqldprogress",
    buttonTitle: "수강미션 보러가기",
  },
  해적단: {
    image: "/assets/images/banners/banner_aside_pirates.png",
    url: "https://spartacodingclub.kr/pirates",
    title: "pirates",
    buttonTitle: "해적단 코스 보러 가기",
  },
  해적단전체: {
    image: "/assets/images/banners/banner_aside_pirates_full.png",
    url: `https://spartacodingclub.kr/catalog?category=${encodeURIComponent(
      "해적단 코스"
    )}`,
    title: "pirates-full",
    buttonTitle: "해적단 코스 전체 보기",
  },
  내배캠: {
    image: "/assets/images/banners/banner_aside_nbcamp.png",
    url: "https://nbcamp.spartacodingclub.kr/",
  },
  내배단무료강의: {
    image: "/assets/images/banners/banner_aside_freecourse_nb.png",
    url: "https://spartacodingclub.kr/nb",
  },
  노코드_메인: {
    image: "/assets/images/banners/banner_aside_nocodemain.png",
    url: "https://spartacodingclub.kr/catalog?category=노코드",
  },
  부트캠프_할인_이벤트: {
    image: "/assets/images/banners/banner_aside_bootcamp.png",
    url: `https://sparta-bootcamp-event.oopy.io`,
    title: "스파르타 수강생 대상 부트캠프 할인 이벤트",
    buttonTitle: "할인가 보러 가기",
  },
  알고리즘: {
    image: "/assets/images/banners/banner_aside_algorithm.png",
    url: "https://spartacodingclub.kr/online/algorithm",
    title: "algorithm",
    buttonTitle: "자세히 보러가기",
  },
};

export const getBannerProducts = (isGov, courseId, round) => {
  const isNotNormalOnline = (obj_round) => {
    return (
      obj_round.is_b2b ||
      obj_round.is_hanghae ||
      obj_round.is_chang ||
      obj_round.is_swc
    );
  };

  if (isGov) {
    switch (courseId) {
      case NBSQL:
        return ["SQLD"];
      case NBSQL_NEW:
        return ["SQLD"];
      case NBNOCODE:
        return ["sw캠프"];
      case NBFIGMA:
        return ["sw캠프"];
      default:
        return ["내배캠"];
    }
  } else {
    switch (courseId) {
      case NOCODE:
        return ["노코드_메인", "내배단무료강의"];
      case CHATGPT:
        return ["내배단무료강의"];
      case NBFREE:
        return ["내배단무료강의", "내배캠"];
      case ONLINESQL:
        return ["SQLD"];
      case SQL_NEW:
        return ["SQLD"];
      case CODING_101:
        return ["내배단무료강의", "내배캠"];
      case DEVELOPER_101:
        return ["내배캠"];
      case PHOTOS:
        return ["내배단무료강의", "내배캠"];
      case MYPROFILE:
        return ["내배단무료강의"];
      case CHANG:
        return ["창"];
      case TEENAGER:
        return ["띵동코딩"];
      case HR:
        return ["기업교육"];
      case BUCKETLIST:
        return ["내배단무료강의"];
      case MOMENTUM:
        return ["내배단무료강의"];
      case PRAC_SQLD:
        return ["SQLD_진도표"];
      case CODINGTEST:
        return ["알고리즘", "내배캠"];
      case MOONWISH:
        return ["내배캠"];
      case AI_VIRTUAL:
        return ["내배캠"];
      default:
        return ["내배단무료강의", "내배캠"];
    }
  }
};
