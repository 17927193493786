import { SideBarTabs } from "../../../models/SideBar/SideBarCondition";
import { useParams } from "react-router-dom";
import { useSyllabus } from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import * as S from "../../../pages/LecturePageV3/SideBar/SideBar.style";

export const SideBarContent = ({ lectureId, summary, selectedTab }) => {
  const params = useParams();
  const syllabus = useSyllabus();

  return (
    <S.SideBarContentContainer>
      {SideBarTabs.filter((tab) => tab.name === selectedTab)?.map((tab) => {
        const TabContents = tab.component;
        if (tab.name === "강의 목차") {
          return syllabus?.map((week) => (
            <TabContents enrolledWeek={week} key={week.week} />
          ));
        }
        return (
          <TabContents
            enrolledHomeworkId={params?.enrolled_homework_id}
            summary={summary}
            lectureId={lectureId}
          />
        );
      })}
    </S.SideBarContentContainer>
  );
};
