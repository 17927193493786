import React from "react";
import { connect } from "react-redux";
import { Nav } from "../_components";
import { getIsAuthenticated } from "../_selectors";
import { useGetHrdLink } from "../v2/_state/v3/hrdLink2";

const NavContainer = (props) => {
  return <Nav {...props} />;
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { user } = authentication || {};

  return {
    user,
    isAuthenticated: getIsAuthenticated(state),
  };
};

const connectedPostContainer = connect(mapStateToProps)(NavContainer);
export { connectedPostContainer as NavContainer };
