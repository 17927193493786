import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";

export const selectWrapper = styled.div`
  position: fixed;
  top: -72px;
  z-index: 30;
  width: 100%;
  height: calc(100% + 72px);
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  background-color: rgba(0, 0, 0, 0.64);
`;

export const selectBottomSheet = styled.div`
  width: 100%;
  max-height: 420px;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0 8px 20px;
  overflow: scroll;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#fff";
    }
  }};
`;

export const mySelectLabel = styled.div`
  display: flex;
  padding-top: 25px;
  margin-left: 12px;
  margin-bottom: 8px;
  height: 60px;
  width: 85%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  outline: 0 none;
  font-size: 20px;
  font-weight: 700;
  font-family: Pretendard;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
`;

export const myOptionList = styled.ul`
  z-index: 29;
  left: 0;
  width: 100%;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "black";
    }
  }};
  list-style-type: none;
  transition: 0.3s ease-in;
`;

export const myOptionItem = styled.li`
  transition: 0.1s;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 14.5px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#3a3e41";
    }
  }};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;

  &:hover,
  &:active {
    background: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "rgba(255, 108, 122, 0.4)";
        default:
          return "#fff1f4";
      }
    }};
  }

  &:last-child {
    border-bottom: 0 none;
  }
`;
