import React from "react";
import { PIRATES } from "../../../../../_consts";

export const ArrowIcon = ({ direction, theme }) => {
  const color = theme === PIRATES ? "#9DA7AE" : "#81898F";
  if (direction === "up") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M17.7929 14.7071C18.1834 15.0976 18.8166 15.0976 19.2071 14.7071C19.5976 14.3166 19.5976 13.6834 19.2071 13.2929L17.7929 14.7071ZM12.5 8L13.2071 7.29289C12.8166 6.90237 12.1834 6.90237 11.7929 7.29289L12.5 8ZM5.79289 13.2929C5.40237 13.6834 5.40237 14.3166 5.79289 14.7071C6.18342 15.0976 6.81658 15.0976 7.20711 14.7071L5.79289 13.2929ZM19.2071 13.2929L13.2071 7.29289L11.7929 8.70711L17.7929 14.7071L19.2071 13.2929ZM11.7929 7.29289L5.79289 13.2929L7.20711 14.7071L13.2071 8.70711L11.7929 7.29289Z"
          fill={color}
        />
      </svg>
    );
  }
  if (direction === "down") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M7.20711 9.29289C6.81658 8.90237 6.18342 8.90237 5.79289 9.29289C5.40237 9.68342 5.40237 10.3166 5.79289 10.7071L7.20711 9.29289ZM12.5 16L11.7929 16.7071C12.1834 17.0976 12.8166 17.0976 13.2071 16.7071L12.5 16ZM19.2071 10.7071C19.5976 10.3166 19.5976 9.68342 19.2071 9.29289C18.8166 8.90237 18.1834 8.90237 17.7929 9.29289L19.2071 10.7071ZM5.79289 10.7071L11.7929 16.7071L13.2071 15.2929L7.20711 9.29289L5.79289 10.7071ZM13.2071 16.7071L19.2071 10.7071L17.7929 9.29289L11.7929 15.2929L13.2071 16.7071Z"
          fill={color}
        />
      </svg>
    );
  }
};

export const PlayIcon = ({ theme }) => {
  const color = theme === PIRATES ? "#5F666B" : "#B4BFC6";
  const path = theme === PIRATES ? "#222425" : "white";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <circle
        cx="8.5"
        cy="8.5"
        r="6"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M7.16699 6.76763V10.2324C7.16699 10.6318 7.61206 10.87 7.94434 10.6485L10.543 8.91607C10.8398 8.71815 10.8398 8.28193 10.543 8.08402L7.94434 6.35161C7.61206 6.13009 7.16699 6.36829 7.16699 6.76763Z"
        fill={path}
      />
    </svg>
  );
};

export const PlayTimeIcon = ({ theme }) => {
  const color = theme === PIRATES ? "#5F666B" : "#B4BFC6";
  const path = theme === PIRATES ? "#222425" : "white";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <circle
        cx="8.5"
        cy="8.5"
        r="6"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8.5 5.67163V9.16673"
        stroke={path}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 9.16663L11.1667 10.5"
        stroke={path}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.66602 8L5.99935 11.3333L13.3327 4"
        stroke="#FF6C7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckedNotDone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.66602 8.5L5.99935 11.8333L13.3327 4.5"
        stroke="#B4BFC6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NowPlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M6 4.8737V12.1263C6 12.286 6.17803 12.3813 6.31094 12.2927L11.7504 8.66641C11.8691 8.58725 11.8691 8.41275 11.7504 8.33359L6.31094 4.70729C6.17803 4.61869 6 4.71396 6 4.8737Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ClipCopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 6V5C8 3.89543 8.89543 3 10 3H18C19.1046 3 20 3.89543 20 5V15C20 16.1046 19.1046 17 18 17H17"
        stroke="#141617"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="7"
        width="12"
        height="14"
        rx="2"
        stroke="#141617"
        strokeWidth="2"
      />
    </svg>
  );
};

export const LecNoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 14.75V18.25C18 19.2125 17.2125 20 16.25 20H5.75C4.7875 20 4 19.2125 4 18.25V7.75C4 6.7875 4.7875 6 5.75 6H9.25"
        stroke="#141617"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4H20V10"
        stroke="#141617"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 4L12 12"
        stroke="#141617"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
