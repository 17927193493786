import * as S from "./QuestionAndAnswer.style";
import { HomeworkQuestion } from "../../../molecules/HomeworkV3/HomeworkQuestion/HomeworkQuestion";
import { HomeworkAnswer } from "../../../molecules/HomeworkV3/HomeworkAnswer/HomeworkAnswer";
import React from "react";

export const QuestionAndAnswer = ({ homeworkIndex, homeworkDetail }) => {
  return (
    <S.QuestionAndAnswerWrapper>
      <HomeworkQuestion homeworkDetail={homeworkDetail} />
      <HomeworkAnswer homeworkIndex={homeworkIndex} />
    </S.QuestionAndAnswerWrapper>
  );
};
