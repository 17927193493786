import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { params } from "../../../_helpers";
import styles from "./SharePage.module.scss";
import ShareRcmdPage from "./ShareRcmdPage";
import Lottie from "lottie-react";
import arrow from "../FinishPage/down-arrows.json";
import {
  getCourseSkills,
  getMedalImagePath,
  getStudyTimes,
  getUsernameTitle,
} from "../../businesslogics/MedalImage";
import { AllClassCards } from "../../components/organisms/RcmdClassCard/RcmdClassCardData";

const cx = classNames.bind(styles);

const SharePage = () => {
  const param = params();
  const { coursesId, time, repurchase, free, user, uid } = param;
  const [showArrow, setShowArrow] = useState(true);
  const { title, isPirates } = AllClassCards[coursesId];

  const scrollHandler = () => {
    if (window.scrollY > window.innerHeight / 3) {
      setShowArrow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  return (
    <div className={cx("share_page__unified_container")}>
      <div
        className={cx("share_page__container", `--${"achiev"}`)}
        style={{
          "--bg-image": `url(https://media4.giphy.com/media/rGg9aM4uTvUgKGVkaC/giphy.gif?cid=790b7611037869f7373db44f7a40443a017dbb1f8603f5fb&rid=giphy.gif&ct=s)`,
        }}
      >
        <div className={cx("share_page__wrapper")}>
          <div className={cx("share_page__header")}>
            <div>
              <span>완주를 축하합니다!</span>

              <h1>
                {getUsernameTitle(repurchase, isPirates ? title : null)}
                <br />
                {user} 님
              </h1>
            </div>
            <img src={getMedalImagePath(repurchase, free)} alt="" />
          </div>

          <div className={cx("share_page_medal__container")}>
            <div className={cx("share_page_medal__box")}>
              <div className={cx("share_page_medal__box-innerbox")}>
                <p className={cx("share_page_medal__box-title")}>공부시간</p>
                <div>{getStudyTimes(time)}</div>
              </div>

              <div className={cx("share_page_medal__box-innerbox")}>
                <p className={cx("share_page_medal__box-title")}>배운 스킬</p>
                <p>{getCourseSkills(coursesId)}</p>
              </div>
            </div>
          </div>
          {showArrow && (
            <Lottie className={cx("share_page__arrow")} animationData={arrow} />
          )}
        </div>
      </div>
      <div className={cx("share_page__next_step__container")}>
        <ShareRcmdPage userName={user} coursesId={coursesId} uid={uid} />
      </div>
    </div>
  );
};

export default SharePage;
