import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./LecPlayer.module.scss";
import { featureActions } from "../../../_state/actions";
import Player from "@vimeo/player";
import { useTrack } from "@hackler/react-sdk";
import { SendBufferingLog } from "../../../businesslogics/logging";

const cx = classNames.bind(styles);

function LecPlayer({
  isDual,
  is_cf_possible,
  getFeatureFlags,
  featureFlags,
  enrolledDetail,
  user,
  link,
  lecture_v2,
  isHomeWork,
  enrolled_homework_id,
}) {
  let classification;
  let lecture_id;
  let vod_key;
  let version;
  if (!isHomeWork) {
    classification = lecture_v2.data["classification"];
    lecture_id = lecture_v2.data["lecture_id"];
    vod_key = lecture_v2.data["vod_key"];
    version = lecture_v2.data["version"];
  }

  const [playerType, setPlayerType] = useState("mp4");
  const videoTag = useRef();
  const vimeoRef = useRef();
  const hkEventRef = useRef();
  const CF_FEATURE_KEY = 1;

  /* mp4를 소스가 s3에 제대로 등록되어 있지 않을 시 vimeo로 호스팅 전환 */
  const convertToVimeo = () => {
    if (vod_key === "special_teenager") {
      return;
    }
    setPlayerType("vimeo");

    /* Slack으로 영상이 변경되었다고 보고 */
    // const { enrolled_id, enrolled_detail_id } = enrolledDetail.data;
    // const { _id: user_id, name, email, phone } = user;
    // const error_data = {
    //   type: "영상",
    //   comment:
    //     "mp4 파일이 제대로 등록되어 있지 않아 vimeo로 호스팅 변경되었습니다.",
    //   user_id: user_id,
    //   name: name,
    //   email: email,
    //   phone: phone,
    //   enrolled_id: enrolled_id,
    //   enrolled_detail_id: enrolled_detail_id,
    //   lecture_id: lecture_id,
    // };
    // privateInstance.post("/report/hosting", error_data).then((resp) => {
    //   const result = resp.data;
    //   if (result.ok) {
    //     console.log("리포트가 제대로 전달되었습니다.");
    //   }
    // });
  };

  const track = useTrack();
  const isMP4 = link.includes("vod");
  useEffect(() => {
    !isMP4 && convertToVimeo();
    /* MP4 Player 환경 세팅 */
    if (videoTag.current) {
      videoTag.current.oncontextmenu = function (e) {
        e.preventDefault();
      };
    }
    if (!featureFlags.loaded) getFeatureFlags();
  }, []);

  let lastBufferStarted = null;

  const onBufferEnd = function () {
    if (!lastBufferStarted) {
      return;
    }
    const diff = new Date() - new Date(lastBufferStarted);
    lastBufferStarted = null;

    const network = navigator.connection;
    const matchPlatform = (ua) => {
      return (
        /(ipad)/.exec(ua) ||
        /(ipod)/.exec(ua) ||
        /(windows phone)/.exec(ua) ||
        /(iphone)/.exec(ua) ||
        /(kindle)/.exec(ua) ||
        /(silk)/.exec(ua) ||
        /(android)/.exec(ua) ||
        /(win)/.exec(ua) ||
        /(mac)/.exec(ua) ||
        /(linux)/.exec(ua) ||
        /(cros)/.exec(ua) ||
        /(playbook)/.exec(ua) ||
        /(bb)/.exec(ua) ||
        /(blackberry)/.exec(ua) ||
        []
      );
    };
    const ua = navigator.userAgent;
    const platform = matchPlatform(ua);

    const data = {
      key: hkEventRef.current,
      value: diff,
      lecture_id: lecture_id,
      version: version,
      vod_key: vod_key,
      network: {
        onchange: network.onchange,
        effectiveType: network.effectiveType,
        rtt: network.rtt,
        downlink: network.downlink,
        saveData: network.saveData,
        platform: platform,
      },
    };
    SendBufferingLog("lecture_buffered_s3", data, null);
  };
  const onBufferStart = function () {
    lastBufferStarted = new Date();
  };

  useEffect(() => {
    const isUnderLatencyMeasure =
      featureFlags.loaded && is_cf_possible && version && vod_key;

    if (isUnderLatencyMeasure) {
      hkEventRef.current = featureFlags.data.flags[CF_FEATURE_KEY]
        ? "lecture_buffered_s3"
        : "lecture_buffered_vimeo";

      setPlayerType("mp4");
    }

    /* vimeo player 환경 세팅 */
    if (vimeoRef.current) {
      const player = new Player(vimeoRef.current);

      player.on("play", function () {
        console.log("Played the video");
      });

      player.on("error", () => {
        // vimeo 링크가 잘못되었다. 라거나 그런거를 슬랙 알림으로 보낼 수 있어야 한다.
      });

      player.getVideoTitle().then(function (title) {
        console.log("title:", title);
      });

      if (isUnderLatencyMeasure) {
        player.on("bufferend", onBufferEnd);
        player.on("bufferstart", onBufferStart);
      }
    }
  }, [featureFlags.loaded]);

  return (
    <div
      style={{
        backgroundColor: isHomeWork ? "#e9ecef" : "black",
      }}
    >
      <div className={cx("lec-player", `${isDual ? "dual" : ""}`)}>
        {playerType == "mp4" ? (
          <div className={cx("lec-player__iframe-div")}>
            <video
              id="video_player"
              className={cx("lec-player__iframe-div__iframe")}
              controls
              poster={`https://video.spartacodingclub.kr/${link}/thumbs.png`}
              controlsList="nodownload"
              preload="metadata"
              onWaiting={onBufferStart}
              onCanPlay={onBufferEnd}
              crossOrigin="anonymous"
              ref={videoTag}
              onError={() => {
                // convertToVimeo();
              }}
              onContextMenuCapture={(e) => {
                e.preventDefault();
                return false;
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <source
                src={`https://video.spartacodingclub.kr/${link}/video.mp4`}
                type="video/mp4"
              />
              <track
                label="자막"
                kind="subtitles"
                srcLang="kr"
                src={`https://video.spartacodingclub.kr/${link}/subtitles.vtt`}
                default
              />
            </video>
          </div>
        ) : (
          <div className={cx("lec-player__iframe-div")}>
            <iframe
              ref={vimeoRef}
              className={cx("lec-player__iframe-div__iframe")}
              src={`https://player.vimeo.com/video/${link}`}
              frameBorder="0"
              onLoad={() => {
                // this.setVimeoLog();
              }}
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { featureFlags } = state;
  return { featureFlags };
}

const connectedLecPlayer = connect(mapStateToProps, {
  getFeatureFlags: featureActions.getFeatureFlags,
})(LecPlayer);
export { connectedLecPlayer as LecPlayer };
