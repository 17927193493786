import * as S from "./evaluationSummary.style";
import {
  additional,
  ArrowChevronRight,
  neutralDay,
} from "@teamsparta/design-system";
import { useMyEvaluations } from "../../../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { useGetEvaluationResultData } from "../../../../hooks/evaluation/useEvaluationData";
import { useParams } from "react-router-dom";
import { useIsMobile } from "../../../../hooks/device";
import { history } from "../../../../../_helpers";

export const EvaluationSummary = () => {
  const isMobile = useIsMobile();
  const { enrolled_id, enrolled_evaluation_id } = useParams();
  const { data: evaluations } = useMyEvaluations(enrolled_evaluation_id);
  const { totalScore, correctCount, incorrectCount, week, isRetryable } =
    useGetEvaluationResultData(evaluations);

  function getCommentText() {
    if (totalScore >= 90) {
      return `${week}주차를 완벽히 이해하셨군요! 이 기세로 ${
        week + 1
      }주차도 열심히 공부해봐요.`;
    } else if (totalScore >= 50) {
      return "잘하셨어요! 틀린 문제를 다시 한 번 확인해볼까요?";
    } else {
      return `아쉽게도 ${incorrectCount}개를 틀리셨어요. 해설을 확인하고 복습해볼까요?`;
    }
  }

  return (
    <S.EvaluationSummaryContainer>
      <S.EvaluationSummaryTitleBox>
        <S.EvaluationSummaryUpperTitle>
          {week}주차 테스트
        </S.EvaluationSummaryUpperTitle>
        <S.EvaluationSummaryMiddleTitle>
          채점 결과
        </S.EvaluationSummaryMiddleTitle>
        <S.EvaluationSummaryUnderTitle>
          {getCommentText()}
        </S.EvaluationSummaryUnderTitle>
      </S.EvaluationSummaryTitleBox>
      {isRetryable && (
        <S.EvaluationRetryableContainer
          onClick={() =>
            history.push(
              `/enrolleds/${enrolled_id}/evaluations/${enrolled_evaluation_id}`
            )
          }
        >
          <S.EvaluationRetryableText>다시 풀어 보기</S.EvaluationRetryableText>
          <ArrowChevronRight
            size={isMobile ? 16 : 20}
            color={additional.blue100}
          />
        </S.EvaluationRetryableContainer>
      )}
      <S.EvaluationSummaryBoardContainer>
        <S.EvaluationSummaryBoard
          borderColor={neutralDay.gray30}
          backgroundColor={neutralDay.white}
        >
          <S.EvaluationSummaryUnderTitle color={neutralDay.gray80}>
            점수
          </S.EvaluationSummaryUnderTitle>
          <S.EvaluationSummaryUpperTitle color={neutralDay.gray100}>
            {totalScore}점
          </S.EvaluationSummaryUpperTitle>
        </S.EvaluationSummaryBoard>
        {isMobile ? (
          <S.EvaluationSummaryBoardWrapper>
            <S.EvaluationSummaryBoard
              borderColor={neutralDay.gray20}
              backgroundColor={neutralDay.gray0}
            >
              <S.Icon src="/images/evaluation/correct.png" />
              <S.EvaluationSummaryText>
                {correctCount}개
              </S.EvaluationSummaryText>
            </S.EvaluationSummaryBoard>
            <S.EvaluationSummaryBoard
              borderColor={neutralDay.gray20}
              backgroundColor={neutralDay.gray0}
            >
              <S.Icon src="/images/evaluation/incorrect.png" />
              <S.EvaluationSummaryText>
                {incorrectCount}개
              </S.EvaluationSummaryText>
            </S.EvaluationSummaryBoard>
          </S.EvaluationSummaryBoardWrapper>
        ) : (
          <>
            <S.EvaluationSummaryBoard
              borderColor={neutralDay.gray20}
              backgroundColor={neutralDay.gray0}
            >
              <S.Icon src="/images/evaluation/correct.png" />
              <S.EvaluationSummaryText>
                {correctCount}개
              </S.EvaluationSummaryText>
            </S.EvaluationSummaryBoard>
            <S.EvaluationSummaryBoard
              borderColor={neutralDay.gray20}
              backgroundColor={neutralDay.gray0}
            >
              <S.Icon src="/images/evaluation/incorrect.png" />
              <S.EvaluationSummaryText>
                {incorrectCount}개
              </S.EvaluationSummaryText>
            </S.EvaluationSummaryBoard>
          </>
        )}
      </S.EvaluationSummaryBoardContainer>
    </S.EvaluationSummaryContainer>
  );
};
