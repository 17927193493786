import { action } from "./action";
import {
  ACTIVE_ENROLLEDS,
  ENROLLED_CONSTANT,
  ENROLLED_DETAIL,
  ENROLLED_DETAIL_DONE,
  ENROLLED_DETAIL_REVIEW,
  ENROLLED_DETAILS,
  ENROLLED_EXPIRED,
  ENROLLED_EXPIRED_SET,
  ENROLLED_HAS_SEEN_OT,
  ENROLLED_MAKER,
  ENROLLED_PERIOD,
  ENROLLED_PLAYTIME,
  ENROLLED_PROGRESS,
  ENROLLED_RANK,
  ENROLLED_REIMBURSED,
  ENROLLED_ROUND_WEEKS,
  ENROLLED_SET,
  ENROLLEDS,
  STUDYPLANS,
} from "../constants";
import { enrolledService } from "../services";

function getRoundWeeks(enrolled_id) {
  function request() {
    return { type: ENROLLED_ROUND_WEEKS.ENROLLED_ROUND_WEEKS_REQUEST };
  }

  function success(response) {
    return {
      type: ENROLLED_ROUND_WEEKS.ENROLLED_ROUND_WEEKS_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return { type: ENROLLED_ROUND_WEEKS.ENROLLED_ROUND_WEEKS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getRoundWeeks(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetRoundWeeks = (enrolled_id) => {
  return getRoundWeeks(enrolled_id);
};

function getEnrolleds() {
  function request() {
    return { type: ENROLLEDS.ENROLLEDS_REQUEST };
  }

  function success(response) {
    return { type: ENROLLEDS.ENROLLEDS_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLEDS.ENROLLEDS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolleds().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getActiveEnrolleds() {
  function request() {
    return { type: ACTIVE_ENROLLEDS.ACTIVE_ENROLLEDS_REQUEST };
  }

  function success(response) {
    return { type: ACTIVE_ENROLLEDS.ACTIVE_ENROLLEDS_SUCCESS, response };
  }

  function failure(error) {
    return { type: ACTIVE_ENROLLEDS.ACTIVE_ENROLLEDS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getActiveEnrolleds().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getStudyplans(enrolled_id) {
  function request() {
    return { type: STUDYPLANS.STUDYPLANS_REQUEST };
  }

  function success(response) {
    return { type: STUDYPLANS.STUDYPLANS_SUCCESS, response };
  }

  function failure(error) {
    return { type: STUDYPLANS.STUDYPLANS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getStudyplans(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function setStudyplans(enrolled_id, data) {
  function request() {
    return { type: STUDYPLANS.STUDYPLANS_SET_REQUEST };
  }

  function success(response) {
    return { type: STUDYPLANS.STUDYPLANS_SET_SUCCESS, response };
  }

  function failure(error) {
    return { type: STUDYPLANS.STUDYPLANS_SET_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.setStudyplans(enrolled_id, data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export function setEnrolled(enrolled_id) {
  function request() {
    return { type: ENROLLED_SET.ENROLLED_SET_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_SET.ENROLLED_SET_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_SET.ENROLLED_SET_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.setEnrolled(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolled(enrolled_id) {
  if (enrolled_id === undefined) return;
  function request() {
    return { type: ENROLLED_SET.ENROLLED_SET_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_SET.ENROLLED_SET_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_SET.ENROLLED_SET_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.setEnrolled(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetEnrolled = (enrolled_id) => {
  if (enrolled_id === undefined) return;
  return getEnrolled(enrolled_id);
};

function getExpired(enrolled_id) {
  function request() {
    return { type: ENROLLED_EXPIRED.ENROLLED_EXPIRED_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_EXPIRED.ENROLLED_EXPIRED_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_EXPIRED.ENROLLED_EXPIRED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getExpired(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetExpired = (enrolled_id) => {
  return getExpired(enrolled_id);
};

function setExpired(enrolled_id, data) {
  function request() {
    return { type: ENROLLED_EXPIRED_SET.ENROLLED_EXPIRED_SET_REQUEST };
  }

  function success(response) {
    return {
      type: ENROLLED_EXPIRED_SET.ENROLLED_EXPIRED_SET_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return { type: ENROLLED_EXPIRED_SET.ENROLLED_EXPIRED_SET_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.setExpired(enrolled_id, data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledPlaytime(enrolled_id) {
  function request() {
    return { type: ENROLLED_PLAYTIME.ENROLLED_PLAYTIME_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_PLAYTIME.ENROLLED_PLAYTIME_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_PLAYTIME.ENROLLED_PLAYTIME_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getPlaytime(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledReimbursed(enrolled_id) {
  function request() {
    return { type: ENROLLED_REIMBURSED.ENROLLED_REIMBURSED_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_REIMBURSED.ENROLLED_REIMBURSED_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_REIMBURSED.ENROLLED_REIMBURSED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledReimbursed(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledProgress(enrolled_id) {
  function request() {
    return { type: ENROLLED_PROGRESS.ENROLLED_PROGRESS_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_PROGRESS.ENROLLED_PROGRESS_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_PROGRESS.ENROLLED_PROGRESS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getProgress(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetEnrolledProgress = (enrolled_id) => {
  return getEnrolledProgress(enrolled_id);
};

function getEnrolledPeriod(enrolled_id) {
  function request() {
    return { type: ENROLLED_PERIOD.ENROLLED_PERIOD_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_PERIOD.ENROLLED_PERIOD_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_PERIOD.ENROLLED_PERIOD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getPeriod(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledRank(enrolled_id) {
  function request() {
    return { type: ENROLLED_RANK.ENROLLED_RANK_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_RANK.ENROLLED_RANK_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_RANK.ENROLLED_RANK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getRank(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledDetail(enrolled_id, detail_id) {
  function request() {
    return { type: ENROLLED_DETAIL.ENROLLED_DETAIL_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_DETAIL.ENROLLED_DETAIL_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_DETAIL.ENROLLED_DETAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledDetail(enrolled_id, detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetEnrolledDetail = (enrolled_id, detail_id) => {
  if (!detail_id) return;
  return getEnrolledDetail(enrolled_id, detail_id);
};

function getEnrolledDetails(enrolled_id) {
  function request() {
    return { type: ENROLLED_DETAILS.ENROLLED_DETAILS_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_DETAILS.ENROLLED_DETAILS_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_DETAILS.ENROLLED_DETAILS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledDetails(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}
export const useGetEnrolledDetails = (enrolled_id) => {
  return getEnrolledDetails(enrolled_id);
};

function doEnrolledDetail(enrolled_id, detail_id) {
  function request() {
    return { type: ENROLLED_DETAIL_DONE.ENROLLED_DETAIL_DONE_REQUEST };
  }

  function success(response) {
    return {
      type: ENROLLED_DETAIL_DONE.ENROLLED_DETAIL_DONE_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return { type: ENROLLED_DETAIL_DONE.ENROLLED_DETAIL_DONE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.doEnrolledDetail(enrolled_id, detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function submitDetailReview(enrolled_id, detail_id, data) {
  function request() {
    return { type: ENROLLED_DETAIL_REVIEW.ENROLLED_DETAIL_REVIEW_REQUEST };
  }

  function success(response) {
    return {
      type: ENROLLED_DETAIL_REVIEW.ENROLLED_DETAIL_REVIEW_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return {
      type: ENROLLED_DETAIL_REVIEW.ENROLLED_DETAIL_REVIEW_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.submitDetailReview(enrolled_id, detail_id, data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledBadges(enrolled_id) {
  function request() {
    return { type: ENROLLED_CONSTANT.BADGES_USERS_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_CONSTANT.BADGES_USERS_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_CONSTANT.BADGES_USERS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledBadges(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getDetailObstacle(enrolled_id, enrolled_detail_id) {
  function request() {
    return { type: ENROLLED_CONSTANT.DETAIL_OBSTACLE_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_CONSTANT.DETAIL_OBSTACLE_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_CONSTANT.DETAIL_OBSTACLE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getDetailObstacle(enrolled_id, enrolled_detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getDetailPresent(enrolled_id, enrolled_detail_id) {
  function request() {
    return { type: ENROLLED_CONSTANT.DETAIL_PRESENT_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_CONSTANT.DETAIL_PRESENT_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_CONSTANT.DETAIL_PRESENT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getDetailPresent(enrolled_id, enrolled_detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postDetailPresent(enrolled_id, enrolled_detail_id, data) {
  function request() {
    return { type: ENROLLED_CONSTANT.SAVE_PRESENT_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return enrolledService.postDetailPresent(
      enrolled_id,
      enrolled_detail_id,
      data
    );
  };
}

function getSalecall(enrolled_id) {
  function request() {
    return { type: ENROLLED_CONSTANT.SALESCALL_GET_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_CONSTANT.SALESCALL_GET_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_CONSTANT.SALESCALL_GET_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getSalecall(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledDoneCount(enrolled_id) {
  function request() {
    return { type: ENROLLED_CONSTANT.ERD_DONE_COUNT_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_CONSTANT.ERD_DONE_COUNT_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_CONSTANT.ERD_DONE_COUNT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledDoneCount(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetEnrolledDoneCount = (enrolled_id) => {
  return getEnrolledDoneCount(enrolled_id);
};

function initEnrolledDoneCount() {
  function request() {
    return { type: ENROLLED_CONSTANT.ERD_DONE_COUNT_INIT };
  }
  return (dispatch) => {
    dispatch(request());
  };
}

function postSalescall(enrolled_id, data) {
  function request() {
    return { type: ENROLLED_CONSTANT.SALESCALL_POST_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return enrolledService.postSalescall(enrolled_id, data);
  };
}

function getMaker(enrolled_id) {
  function request() {
    return { type: ENROLLED_MAKER.ENROLLED_MAKER_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_MAKER.ENROLLED_MAKER_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_MAKER.ENROLLED_MAKER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledService.getMaker(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postHasSeenOrientation(enrolled_id) {
  return action(enrolledService.postHasSeenOrientation, ENROLLED_HAS_SEEN_OT, [
    enrolled_id,
  ]);
}

function getEnrolledFinishSurvey(enrolled_id) {
  function request() {
    return { type: ENROLLED_CONSTANT.SURVEY_GET_REQUEST };
  }
  function success(response) {
    return { type: ENROLLED_CONSTANT.SURVEY_GET_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_CONSTANT.SURVEY_GET_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledFinishSurvey(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postEnrolledFinishSurvey(enrolled_id, data) {
  function request() {
    return { type: ENROLLED_CONSTANT.SURVEY_POST_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return enrolledService.postErnolledFinishSurvey(enrolled_id, data);
  };
}

export const enrolledActions = {
  getRoundWeeks,
  getEnrolleds,
  getActiveEnrolleds,
  getStudyplans,
  setEnrolled,
  setStudyplans,
  setExpired,
  getExpired,
  getMaker,
  getEnrolledPlaytime,
  getEnrolledReimbursed,
  getEnrolledProgress,
  getEnrolledPeriod,
  getEnrolledRank,
  getEnrolledDetail,
  getEnrolledDetails,
  doEnrolledDetail,
  submitDetailReview,
  getEnrolledBadges,
  getDetailObstacle,
  getDetailPresent,
  postDetailPresent,
  getSalecall,
  postSalescall,
  getEnrolledDoneCount,
  getEnrolledFinishSurvey,
  postEnrolledFinishSurvey,
  postHasSeenOrientation,
  initEnrolledDoneCount,
};
