import * as S from "./listSelector.style";
import { SelectorSvg } from "./selector.svg";

const ListSelector = ({ options, answer, setAnswer, questionId }) => {
  return (
    <S.Container>
      {options.map((option) => (
        <S.Option
          key={option}
          isActive={answer === option}
          onClick={() => {
            setAnswer(questionId, option);
          }}
        >
          <SelectorSvg isActive={answer === option} />
          {option}
        </S.Option>
      ))}
    </S.Container>
  );
};

export default ListSelector;
