import React from "react";
import classNames from "classnames/bind";
import styles from "./LoginPage.module.scss";
import { courseActions, initEnvironment, logActions } from "../../_actions";
import { history, params } from "../../_helpers";
import { config } from "../../config";
import { connect } from "react-redux";
import { sendLog, setCountry } from "../../v2/businesslogics/logging";
import { usersActions } from "../../v2/_state/actions";
import { KakaoLoginBtn } from "../../v2/components/molecules/KakaoLoginBtn";
import { WelcomeMsg } from "../../v2/components/molecules/WelcomeMsg";
import { BeenLoginedNoticeModal } from "../../v2/components/molecules/BeenLoginedNoticeModal";
import { LoginForm } from "../../v2/components/organisms/LoginForm/LoginForm";
import { SignupForm } from "../../v2/components/organisms/LoginForm/SignupForm";
import SignupHeader from "../../v2/components/molecules/SignupHeader/SignupHeader";
import * as amplitude from "@amplitude/analytics-browser";
import LoginPageV2 from "../../v2/pages/LoginPageV2/LoginPageV2";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);
const validateMessages = {
  required: "필수 입력창입니다.",
  types: {
    email: "이메일 양식을 확인해주세요.",
    phone: "전화번호 양식을 확인해주세요.",
    password: "비밀번호는 최대 6자 이상, 숫자와 영문자를 모두 포함해야 합니다.",
  },
};

const setMetaTitle = (title) => {
  document.title = `${title}`;
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", `${title}`);
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      email,
      phone,
      country_code,
      name,
      gender,
      birthyear,
      birthday,
      next,
      from,
    } = params();
    this.state = {
      isPurchasing: next?.includes("payment"),
      isPurchasingGovIntro: next?.includes(
        "%2Fpayment%2F6115cea3e73913245c9538e0"
      ),
      isLoginPage: history.location.pathname === "/login",
      isSignupPage: ["/signup", "/signup_kko"].includes(
        history.location.pathname
      ),
      checkedAccountExist: history.location.pathname === "/signup_kko",
      emailShow: ["/signup", "/signup_kko"].includes(history.location.pathname),
      isKkoSignup: history.location.pathname === "/signup_kko",
      isLoading: false,
      errorMsg: undefined,
      log_sent: false,
      serviceAgreement: false,
      privacyAgreement: false,
      marketingAgreement: false,
      email: email ? email.replace("%40", "@") : "",
      phone: phone || "",
      country_code:
        country_code && country_code !== "82" ? "+" + country_code : "",
      name: name || "",
      birthYear: birthyear || "",
      // 카카오 회원가입시 4자리 birthday 를 제공받지만, 이메일 회원가입시 month / date 를 나누어 관리하므로 slice 처리함
      birthMonth: birthday?.slice(0, 2) || "",
      birthDate: birthday?.slice(2, 4) || "",
      gender: gender || "",
      from: from,
    };
    history.listen(() => {
      this.resetState();
    });
    this.checkUserLoggined();
    this.formDiv = React.createRef();
  }

  async checkUserLoggined() {
    const { user } = this.props;
    const { next } = params(); // pe: payment experiment, 결제AB 테스트
    if (user && Object.keys(user).length !== 0) {
      amplitude.setUserId(user._id);
      this.setMetaTag(user._id);
      const nextUrl = next ? decodeURIComponent(next) : "/classroom";
      if (/^https?:\/\//i.test(nextUrl)) {
        window.location = nextUrl;
      } else {
        history.replace(nextUrl);
      }
    }
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    setMetaTitle("로그인 - 스파르타코딩클럽");

    const { deviceType } = this.props.environment;
    this.setState({ isMobile: deviceType === "mobile" });
    this.resetState();
    setCountry();
    const { next } = params();

    const { comkt, from } = params();

    if (comkt) {
      this.setState({ comkt, from });
    }
    if (comkt === "alpaco") {
      const { email } = params();
      if (email) {
        this.setState({ isLoading: true });
        this.props.validateAlpaco(email);
      }
    }

    this.formDiv.current?.addEventListener("click", (e) => {
      if (!["SPAN", "A", "BUTTON"].includes(e?.srcElement?.tagName)) return;
      if (e?.path?.[0]?.innerText == null) return;
      if (window.location.pathname.includes("login")) {
        sendLog("scc_loginPage_click_button", {
          button_text: e.path[0].innerText,
        });
      } else if (window.location.pathname.includes("signup")) {
        sendLog("scc_signupPage_click_button", {
          button_text: e.path[0].innerText,
        });
      }
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching
    ) {
      this.setState({
        isLoading: false,
      });
      if (this.props.error) {
        console.log(this.props.error);
        this.setState({
          errorMsg: this.props.error,
        });
      }
    }
    if (this.isLoadedInReducer(prevProps, this.props, "isValidAlpaco")) {
      this.handleAlpacoValidate();
    }
  }

  componentWillUnmount() {
    setMetaTitle("스파르타코딩클럽");
  }

  resetState() {
    this.setState({
      isLoginPage: history.location.pathname === "/login",
      isSignupPage: ["/signup", "/signup_kko"].includes(
        history.location.pathname
      ),
      checkedAccountExist: history.location.pathname === "/signup_kko",
      emailShow: ["/signup", "/signup_kko"].includes(history.location.pathname),
      isKkoSignup: history.location.pathname === "/signup_kko",
      isLoading: false,
      errorMsg: undefined,
    });
  }

  _userTracking = () => {};

  render() {
    const { next, errMsg } = params();
    const nextDecoded = decodeURIComponent(next);
    const { user, loggingIn } = this.props;
    const {
      isLoginPage,
      isSignupPage,
      isMobile,
      checkedAccountExist,
      isLoading,
      errorMsg,
      emailShow,
      isKkoSignup,
      comkt,
      from,
    } = this.state;
    const isPurchasing = this.state.isPurchasing;
    const isPurchasingGovIntro = this.state.isPurchasingGovIntro;
    const logoSrc = `/assets/icons/logo-${isMobile ? "mobile" : "web"}.svg`;
    const rtanSrc = isSignupPage
      ? `/assets/images/login/welcome-hi.png`
      : `/assets/icons/rtan/rtan-web-welcome.png`;
    const nbLogoSrc = `/assets/icons/logo-nb-${
      isMobile ? "mobile" : "web"
    }.png`;
    const nbRtanSrc = isSignupPage
      ? `/assets/images/login/welcome-hi.png`
      : `/assets/icons/rtan/rtan-nb-web-welcome.png`;
    const eventImgSrc = "/assets/icons/rtan/hyoneung.png";
    const login_bg_path = "/assets/images/login/login_bg.png";
    const login_nb_bg_path_b = "/assets/images/login/login_nb_bg_b.jpg";
    const buttonText = this.buttonText;
    const comkt_name = this.coMktName;
    const nextUrl = {
      swc: nextDecoded?.includes(config.swcUrl),
      nbc: nextDecoded?.includes("https://nbcamp.spartacodingclub.kr"),
      hanghae: nextDecoded?.includes("https://hanghae99.spartacodingclub.kr"),
      chang: nextDecoded?.includes("https://chang.spartacodingclub.kr"),
      ddingdong: nextDecoded?.includes(config.ddingdongUrl),
      intellipick: nextDecoded
        ?.replace("/login", "")
        ?.includes(config.intellipickUrl),
      enterprise: nextDecoded?.includes(config.enterpriseUrl),
    };

    const otherServiceSignup =
      nextUrl.swc ||
      nextUrl.hanghae ||
      nextUrl.chang ||
      nextUrl.ddingdong ||
      nextUrl.intellipick ||
      nextUrl.nbc ||
      nextUrl.enterprise;

    const retVal = (isGov) => {
      return (
        <>
          {comkt == "alpaco" ? (
            <div className={cx("wrapper")}>
              {errMsg && alert(errMsg)}
              <div
                className={
                  isSignupPage
                    ? cx("pageContainer", "signup")
                    : cx("pageContainer")
                }
              >
                <div className={cx("leftContainer")}>
                  <div className={cx("leftContentWrapper")}>
                    <div className={cx("contentWrapper")}>
                      {isSignupPage && otherServiceSignup ? (
                        <SignupHeader nextUrl={nextUrl} />
                      ) : (
                        <>
                          <div className={cx("logo")}>
                            <img
                              src={isGov ? nbLogoSrc : logoSrc}
                              alt="ic_mycourse"
                            />
                          </div>
                          <div className={cx("" + "rtan_welcome")}>
                            {next?.includes("hyoneung") ? (
                              <img
                                src={eventImgSrc}
                                alt="hyoneung"
                                style={{
                                  width: "120px",
                                  maxWidth: "120px",
                                }}
                              />
                            ) : (
                              <img
                                src={isGov ? nbRtanSrc : rtanSrc}
                                alt="ic_rtan"
                              />
                            )}
                          </div>
                          <WelcomeMsg
                            cx={cx}
                            isLoginPage={isLoginPage}
                            isPurchasing={isPurchasing}
                            checkedAccountExist={checkedAccountExist}
                            comkt_name={comkt_name}
                            isSignupPage={isSignupPage}
                            comkt={comkt}
                            isMobile={isMobile}
                            from={from}
                            isPurchasingGovIntro={!!isGov}
                          />
                        </>
                      )}
                      <div className={cx("formWrapper")} ref={this.formDiv}>
                        {/* 시작하기 세트 */}
                        {comkt !== "hanium" &&
                          !isSignupPage &&
                          !isKkoSignup && (
                            <>
                              <KakaoLoginBtn
                                className={cx("kkoLogin")}
                                isPurchasingGovIntro={isGov}
                              />
                              <div className={cx("start-email")}>
                                <span
                                  onClick={() =>
                                    this.setState({ emailShow: true })
                                  }
                                >
                                  {isGov
                                    ? "이메일로 로그인"
                                    : "이메일로 시작하기"}
                                </span>
                              </div>
                            </>
                          )}

                        {/* 폼이 섞여있음 - 한이음 제외 로그인 및 카카오 회원가입 및 회원가입 */}
                        {emailShow && (
                          <>
                            {isLoginPage && (
                              <LoginForm
                                cx={cx}
                                checkedAccountExist={checkedAccountExist}
                                msgContainer={validateMessages}
                                errorMsg={errorMsg}
                                isLoading={isLoading}
                                setState={this.setState.bind(this)}
                                _userTracking={this._userTracking.bind(this)}
                              />
                            )}
                            {isSignupPage && (
                              <SignupForm
                                cx={cx}
                                setState={this.setState.bind(this)}
                                userInfo={{
                                  email: this.state.email,
                                  phone: this.state.phone,
                                  name: this.state.name,
                                  country_code: this.state.country_code,
                                  birthYear: this.state.birthYear,
                                  birthMonth: this.state.birthMonth,
                                  birthDate: this.state.birthDate,
                                  gender: this.state.gender,
                                }}
                                validateMessages={validateMessages}
                                isLoading={isLoading}
                                isKkoSignup={isKkoSignup}
                                errorMsg={errorMsg}
                                marketingAgreement={
                                  this.state.marketingAgreement
                                }
                                serviceAgreement={this.state.serviceAgreement}
                                privacyAgreement={this.state.privacyAgreement}
                                buttonText={buttonText}
                                isHanium={comkt === "hanium"}
                                _userTracking={this._userTracking.bind(this)}
                                isOtherServiceSignup={otherServiceSignup}
                                nextUrl={nextUrl}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!isSignupPage && (
                  <div
                    className={
                      isGov ? cx("rightNbContainer") : cx("rightContainer")
                    }
                    style={{
                      "--bg-image": `url(${
                        isGov ? login_nb_bg_path_b : login_bg_path
                      })`,
                    }}
                  />
                )}
              </div>
              {!isMobile && <FooterV2 />}
              <BeenLoginedNoticeModal
                loggingIn={loggingIn}
                user={user}
                next={next}
              />
            </div>
          ) : (
            <LoginPageV2 isSignupPage={isSignupPage}></LoginPageV2>
          )}
        </>
      );
    };

    if (isPurchasingGovIntro) {
      return retVal.bind(this)(isPurchasingGovIntro);
    }
    return retVal.bind(this)();
  }

  setMetaTag = (user_id) => {
    const meta = document.createElement("meta");
    meta.name = "user_id";
    meta.content = user_id;
    document.getElementsByTagName("head")[0].appendChild(meta);
  };

  isLoadedInReducer(prev, curr, nameOfState) {
    return !prev[nameOfState].loaded && curr[nameOfState].loaded;
  }

  handleAlpacoValidate() {
    if (this.props.isValidAlpaco.data) {
      const { email } = params();
      this.props.loginAlpaco({ email: email });
    } else {
      alert("해당 e-mail로 가입된 정보가 없습니다.");
      // history.push(`/login?next=${params().next}`);
    }
  }

  get coMktName() {
    return {
      miniintern: "미니인턴",
      hakdokman: "학독만",
      alpaco: "현대그룹x알파코",
      hanium: "한이음",
    }[this.state.comkt];
  }

  get buttonText() {
    if (this.state.isSignupPage) {
      const { next } = params();
      const decoded = decodeURIComponent(next);
      if (this.state.isKkoSignup && decoded?.includes("https://intellipick")) {
        return "회원가입 완료하기";
      }
      return this.state.isPurchasing ? "동의하고 진행하기" : "가입하기";
    } else {
      return "다음";
    }
  }
}

function mapStateToProps(state) {
  const { course, environment, isValidAlpaco, logCrmAB } = state;
  const { loggingIn, user, isFetching, error } = state.authentication;

  return {
    loggingIn,
    user,
    isFetching,
    error,
    course,
    environment,
    isValidAlpaco,
    logCrmAB,
  };
}

const connectedLoginPage = connect(mapStateToProps, {
  getCourse: courseActions.getCourse,
  _initEnvironment: initEnvironment,
  validateAlpaco: usersActions.validateAlpaco,
  loginAlpaco: usersActions.loginAlpaco,
  getLogCrmAB: logActions.getLogCrmAB,
})(LoginPage);
export { connectedLoginPage as LoginPage };
