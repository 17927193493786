export const keyword2icon = {
  web: "web",
  webplus: "webplus",
  makers: "makers",
  app: "app",
  app_plus: "app_plus",
  sql: "sql",
  gram_py: "python",
  ml_basic: "ml_basic",
  dl: "dl",
  react: "react",
  react_plus: "react_plus",
  spring: "spring",
  spring_plus: "spring_plus",
  al: "al",
  node: "node",
  node_plus: "node_plus",
  c_plus_plus: "c_plus_plus",
  data: "data",
  java: "java",
  js: "js",
  package_test: "package_test",
  seol: "seol",
  stock: "stock",
  xmas: "xmas",
  family: "family",
  free: "python",
  pyds: "python",
  game: "game",
  django: "django",
  aws: "aws",
  aws_free: "aws",
  docker: "docker",
  career: "career",
  git: "git",
  ios: "ios",
};
