import { getCookie, setCookie } from "../v2/_helper";

export const authHeader = () => {
  // return authorization header with jwt token
  const token = getCookie("token");
  const user_token = getCookie("user_token");
  return {
    Authorization: token ? "Bearer " + token : "Bearer " + user_token,
  };
};

export const authHeaderInit = authHeader();

export function setUserinfo(user) {
  // user_info for Facebook Pixel
  // phone number must include country code without + sign and leading zeros
  let { phone, country_code } = user;
  if (country_code === "") {
    country_code = "+82";
  }
  country_code = country_code.replace("+", "");

  if (phone[0] === "0") {
    phone = phone.slice(1);
  }
  let phone_full = country_code + phone;
  setCookie("user_info", `${user.email}&${phone_full}`, 365);
  setCookie("user_created_at", new Date(user.created_at).toISOString(), 365);
}

const JSON2CookieFormat = (target) => {
  let result = [];
  for (const [key, val] of Object.entries(target)) {
    result.push(`${key}=${encodeURIComponent(val)}`);
  }
  return result.join("&");
};

// export function setUserinfo(user) {
//   // user_info for Facebook Pixel
//   // phone number must include country code without + sign and leading zeros
//   let { phone, country_code } = user;
//   if (country_code === "") {
//     country_code = "+82";
//   }
//   country_code = country_code.replace("+", "");
//
//   if (phone[0] === "0") {
//     phone = phone.slice(1);
//   }
//   let phone_full = country_code + phone;
//   setCookie(
//       "userinfo",
//       JSON2CookieFormat({
//         _id: user._id,
//         name: user.name,
//         phone: user.phone,
//         email: user.email,
//         country_code: user.country_code,
//         birthday: `${user.birthyear}${user.birthday}`,
//       }),
//       365
//   );
//   setCookie("user_token", user.token, 7);
//   setCookie("user_id", user._id, 365);
//   setCookie("user_info", `${user.email}&${phone_full}`, 365);
//   setCookie("user_created_at", new Date(user.created_at).toISOString(), 365);
// }
