import styled from "styled-components";
import { Device } from "../../../_styles/device";

export const MyPageWrapper = styled.div``;
export const MyPageContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  ${Device.Desktop} {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 0 150px 20px;
  }
`;

export const SectionWrapper = styled.div`
  ${Device.Desktop} {
    margin-top: 120px;
    margin-left: 56px;
    width: 894px;
  }
  ${Device.Desktop} {
    margin-top: 0;
  }
`;
