export const getBannerData = (type, isMobile = null) => {
  switch (type) {
    case "credit":
      return {
        bannerImg: "/assets/images/roadmap/credit_banner_rtan.png",
        ctaLink: "https://bit.ly/스터디바로가기",
      };
    case "sons":
      return {
        bannerImg: "/assets/images/roadmap/banner_rtan.png",
        ctaLink: "https://bit.ly/스터디바로가기",
      };
    case "timer":
      return {
        bannerImg: `/assets/images/banners/hip_roadmap_timer_bg`,
        ctaLink: false,
      };
    default:
      return;
  }
};

// 정부 심사를 위해 임시로 만들어 놓은 것이고, 아래의 getSonsMaterialLink의 하드 코딩 되어 있는 부분 무조건 개선해야 함!!! 필쑤!!!!!!!!!!
const getNbSimsaCourseMaterialLink = (course_id) => {
  const simsaCourseIds = [
    "64af9cbb47cba538dd738f98", // [왕초보] 코딩이 처음이어도 쉽게 배우는 웹개발 A to Z
    "64b0a1deee312645c8c21d0f", // [왕초보] ChatGPT로 웹개발 완전정복
    "64b0a1fcea9f1009fa5f8cf8", // [왕초보] 엑셀보다 쉽고 빠른 SQL
    "64b0a23c417100590a97e152", // [개발자 입문] 웹개발의 봄, Spring
    "64b0a29582283d4f64bf2a1b", // [개발자 입문] JAVA 문법 종합반
    "64b0a2a5bde5da49ee88c67d", // [개발자 입문] JavaScript 문법 종합반
    "64b0a268ece46950663cdfe0", // [개발자 입문] C# 문법 종합반
    "64b0a2879df74fd244ad61c3", // [개발자 입문] Kotlin 문법종합반
    "64b0a2764e782eb0944f7bd3", // [개발자 입문] TS 문법종합반
    "64b0a24f96b451a33c4d7ea9", // [개발자 필수] 한번 들으면 평생 써먹는 알고리즘, 취업부터 실무까지!
    "64b8fd2dcf24f51ef0f67878", // [개발자 심화] 이미지 처리로 시작하는 딥러닝
    "64b8fd2dcf24f51ef0f678e9", // [개발자 필수] 가장 쉽게 배우는 머신러닝
    "64b0a20ed0eafff0cc6a2743", // [왕초보] PPT보다 쉬운 피그마
    "64b0a2237be3bd117c4ec29a", // [왕초보] 노코드로 완성도 있는 웹사이트 만들기
    "64b687dc6e951768d4879e70", // [왕초보] ChatGPT 300% 활용하기
  ];
  if (simsaCourseIds.includes(course_id)) {
    return "https://teamsparta.notion.site/95a0da2ac5064b8d94b0801fd9fdfc38?pvs=4";
  }
  return null;
};

export const getSonsMaterialLink = (course_id) => {
  let link = {
    "60a5ee42bf272bef32a58fb9":
      "https://teamsparta.notion.site/381a47d6fb464e02b8c7dcc56ba3acec",
    "616d434a460cf1cf6f957a3d":
      "https://teamsparta.notion.site/b6e2655de168481988fbb5758dcde5f6",
    "616d4349460cf1cf6f9579ec":
      "https://teamsparta.notion.site/SQL-69c3298e4f2d4f41baefa1b8ea279054",
    "621f025f431611290aa5929d":
      "https://teamsparta.notion.site/95a0da2ac5064b8d94b0801fd9fdfc38",
    "62f09eebfeb2d564bacb0262":
      "https://teamsparta.notion.site/3b85fc70db5645d7b6bd8c7f66cb343b",
    "62f09f2af3ce1804901b3f5c":
      "https://teamsparta.notion.site/26b91246c9fc49ddb6ea9572d0359032",
    "63ca5b43dfbe98f4c8ef20bf":
      "https://www.notion.so/teamsparta/aa23e40e04364b86a5349fa27d90b20b?pvs=4",
    "63ca26845b3bac30b29bf2a3":
      "https://www.notion.so/teamsparta/60abf6daf33a48aa937a6482b0b81f69?pvs=4",
  }[course_id];

  if (!link) {
    link = getNbSimsaCourseMaterialLink(course_id);
  }

  if (link) {
    return link;
  }
  return "https://teamsparta.notion.site/b0637d0f3f3848d1a9c1624b080cd962";
};

export const getImgIncludeCtaData = (isGov, isGovIntro, isTrial, isMobile) => {
  let ctaLink = "";
  let bannerImg = ``;

  if (isGovIntro) {
    if (isMobile === true) {
      bannerImg =
        "/assets/images/banners/banner_credit_intro_step_by_step_m.png";
      ctaLink = null;
    } else {
      bannerImg = "/assets/images/banners/banner_credit_intro_step_by_step.png";
      ctaLink = "https://www.hrd.go.kr/hrdp/ps/ppsvo/PPSVO0200L.do";
    }
  } else if (isTrial) {
    ctaLink = "https://spartacodingclub.kr/event/2022/01";
    bannerImg = `/assets/images/banners/roadmap_promotion_banner.png`;
  } else {
    ctaLink =
      "https://gather.town/app/mPa2eFC9XwV08LuY/%EC%BD%94%EB%94%A9%EC%8A%A4%ED%8C%8C%EB%9E%9C%EB%93%9C";
    bannerImg = `/assets/images/banners/spa_banner.png`;
  }
  return {
    bannerImg,
    ctaLink,
  };
};
