import * as S from "./kdcPaymentWarn.style";

export const KdcPaymentWarn = () => {
  return (
    <S.MobileContainer>
      <S.Container>
        <S.DesktopOnly>
          <DesktopInfoCircle />
        </S.DesktopOnly>
        <S.MobileOnly>
          <MobileInfoCircle />
        </S.MobileOnly>
        <S.WarnText>
          현재 결제 시도 중인 강의는 국비지원 강의입니다. 국비지원 강의는 본인
          명의의 <b>내일배움카드</b>로만 결제 가능합니다.
        </S.WarnText>
      </S.Container>
    </S.MobileContainer>
  );
};

const MobileInfoCircle = () => {
  return (
    <S.MobileInfoCircleContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.9998 1.30005C4.2995 1.30005 1.2998 4.29974 1.2998 8.00005C1.2998 11.7004 4.2995 14.7 7.9998 14.7C11.7001 14.7 14.6998 11.7004 14.6998 8.00005C14.6998 4.29974 11.7001 1.30005 7.9998 1.30005ZM8.6665 5.16683C8.6665 5.53502 8.36803 5.8335 7.99984 5.8335C7.63165 5.8335 7.33317 5.53502 7.33317 5.16683C7.33317 4.79864 7.63165 4.50016 7.99984 4.50016C8.36803 4.50016 8.6665 4.79864 8.6665 5.16683ZM7.39985 10.8666C7.39985 11.1979 7.66848 11.4666 7.99985 11.4666C8.33122 11.4666 8.59985 11.1979 8.59985 10.8666L8.59985 7.09322C8.59985 6.76185 8.33122 6.49322 7.99985 6.49322C7.66848 6.49322 7.39985 6.76185 7.39985 7.09322V10.8666Z"
          fill="#FF7005"
        />
      </svg>
    </S.MobileInfoCircleContainer>
  );
};

const DesktopInfoCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM10.8333 6.45842C10.8333 6.91865 10.4602 7.29175 9.99992 7.29175C9.53968 7.29175 9.16659 6.91865 9.16659 6.45841C9.16659 5.99818 9.53968 5.62508 9.99992 5.62508C10.4602 5.62508 10.8333 5.99818 10.8333 6.45842ZM9.25 13.5833C9.25 13.9975 9.58579 14.3333 10 14.3333C10.4142 14.3333 10.75 13.9975 10.75 13.5833L10.75 8.86658C10.75 8.45237 10.4142 8.11658 10 8.11658C9.58579 8.11658 9.25 8.45237 9.25 8.86658L9.25 13.5833Z"
        fill="#FF7005"
      />
    </svg>
  );
};
