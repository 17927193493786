import { roundsService } from "../services";
import { ROUND_SET, ROUND_TEST } from "../constants/rounds.constant";
import { action } from "./action";

function setRound(round_id) {
  function request() {
    return { type: ROUND_SET.ROUND_SET_REQUEST };
  }
  function success(response) {
    return { type: ROUND_SET.ROUND_SET_SUCCESS, response };
  }
  function failure(error) {
    return { type: ROUND_SET.ROUND_SET_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    roundsService.getRound(round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetRound = (round_id) => {
  return setRound(round_id);
};

export const roundsActions = {
  setRound,
  // getRoundTest,
};
