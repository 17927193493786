import styled from "@emotion/styled";
import { device, DisplayResolution } from "../../_styles/device.js";
import { neutralDay, mCaption2 } from "@teamsparta/design-system";
export const BusinessInfo = styled.section``;

export const InfoDropDown = styled.h4`
  ${mCaption2};
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${neutralDay.gray60};
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  letter-spacing: 0;
`;

export const InfoDropDownIcon = styled.img`
  width: 18px;
  height: 18px;
  transform: ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0deg)")};

  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const InfoDetail = styled.dl`
  ${mCaption2};
  font-size: 12px;
  color: ${neutralDay.gray60};
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  ${DisplayResolution.TabletAndDesktop} {
    display: flex;
    width: 700px;
  }
`;

export const InfoDetailLine = styled.div`
  display: flex;
  flex-direction: row; // 기본적으로 세로 정렬
  gap: 4px;
  @media ${device.desktop} {
    flex-direction: row; // 데스크탑 뷰에서만 가로 정렬
  }
`;

export const InfoDetailLineTitle = styled.dt`
  line-height: 20px;
`;

export const InfoDetailLineValue = styled.dd`
  line-height: 20px;
`;

export const Divider = styled.span`
  display: flex;
  line-height: 20px;
  margin: 0;
`;
export const InfoDetailLink = styled.a`
  font-size: 12px;
  line-height: 20px;
  &:hover {
    color: ${neutralDay.gray60};
  }
`;
