import styled from "styled-components";
import { device } from "../../../../_styles/device";

export const MobileWrapper = styled.div`
  height: calc(100vh);
  overflow-y: scroll;
  position: relative;
`;

export const VideoLoadingWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  background-color: #3a3e41;
  position: relative;
`;

export const LoadingGif = styled.img`
  width: 36px;
  height: 42px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  @media ${device.tablet} {
    width: 59px;
    height: 72px;
  }
`;
