import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CourseRecommendation } from "../../components/organisms/CourseRecommendation";
import { ImportFrom } from "../../components/organisms/CourseRecommendation/CourseRecommendation";
import classNames from "classnames/bind";
import styles from "./SecretStorePage.module.scss";
import { Select } from "../../components/molecules/Select";
import { Timeclock } from "../../components/organisms/Timeclock";
import { addDays, getCookie, SECRET_STORE, toIsoString } from "../../_helper";
import { Modal } from "../../components/organisms/Modal/Modal";
import { coursesActions } from "../../_state/actions/courses.actions";
import { enrolledActions } from "../../_state/actions/enrolled.actions";

const cx = classNames.bind(styles);

function SecretStorePage({
  match,
  user,
  courseV2,
  getCourse,

  postSalescall,
}) {
  const { course_id, enrolled_id } = match.params;
  const secret_store = getCookie(SECRET_STORE);
  const endDate = toIsoString(addDays(secret_store, 1));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");

  useEffect(() => {
    if (!courseV2.loaded) {
      getCourse(course_id);
    } else {
      setCourseTitle(courseV2.data.title);
    }
  }, [courseV2.loaded]);

  return (
    <div className={cx("page")}>
      <div className={cx("page__intro")}>
        <div
          className={cx("page__intro__header")}
          style={{
            "--bg-image": `url(${("/assets/images/secretstore/bg_secret_store_2.png")})`,
          }}
        >
          <div className={cx("page__intro__header__top")}>
            <img
              className={cx("page__intro__header__top__img")}
              src={("/assets/emoji/emoji_quiet.png")}
              alt="emoji quiet"
            />
            <div className={cx("page__intro__header__top__main")}>
              완강한 여러분만 입장할 수 있는 그 곳-
            </div>
            <div className={cx("page__intro__header__top__sub")}>
              스파르타 비밀의 상점에 오신 것을 환영해요!
            </div>
          </div>
          <div className={cx("page__intro__header__body")}>
            <img
              className={cx("page__intro__header__body__img")}
              src={("/assets/images/characters/rtan_secret_store.png")}
              alt="rtan happy"
            />
            <div className={cx("page__intro__header__body__sub")}>
              단 24시간 동안만 열린다구~!
            </div>
            <div className={cx("page__intro__header__body__clock")}>
              <Timeclock endDate={endDate} type={"clock"} />
            </div>
          </div>
          <div className={cx("page__intro__header__bottom")}>
            <span className={cx("page__intro__header__bottom__span")}>
              <span className={cx("page__intro__header__bottom__span--big")}>
                비밀의 상점
              </span>
              이 무엇인가요?
            </span>
            <svg
              className={cx("page__intro__header__bottom__img")}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="9"
              fill="none"
              viewBox="0 0 14 9"
            >
              <path
                fill="#000000"
                d="M1.623 0l5.285 5.342L12.192 0l1.623 1.653-6.907 6.982L0 1.653 1.623 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={cx("page__desc")}>
        <img
          className={cx("page__desc__img")}
          src={("/assets/images/secretstore/store_benefit.png")}
          alt=""
        />
        <img
          className={cx("page__desc__emoji")}
          src={("/assets/emoji/emoji_finger_down.png")}
          alt=""
        />
      </div>
      <div className={cx("page__banner")}>
        <div className={cx("page__banner__title")}>
          총 8만 원 SAVE
          <img
            className={cx("page__banner__title__img")}
            src={("/assets/emoji/emoji_monkey.png")}
            alt=""
          />
        </div>
        <div className={cx("page__banner__sub")}>완주자니까, 할인에 할인!</div>
      </div>
      <div className={cx("divider")} />
      <CourseRecommendation
        type={ImportFrom.SECRET_STORE}
        course_id={course_id}
        header={
          <div className={cx("reco-header")}>
            <div className={cx("reco-header__title")}>
              다음은 어떤 강의를 들으면 좋을까요?
            </div>
            <div className={cx("reco-header__sub")}>
              {courseTitle} 완주한 분들이 다음으로 선택한 강의를 추천드려요.
            </div>
          </div>
        }
      />
      <div className={cx("divider")} />
      <div className={cx("page__outro")}>
        <div className={cx("page__outro__title")}>
          <img
            className={cx("page__outro__title__img")}
            src={("/assets/emoji/emoji_question.png")}
            alt=""
          />
          <div className={cx("page__outro__title__main")}>
            그래도 어떤 강의를 들어야 할지 모르겠다면, 문의주세요!
          </div>
          <div className={cx("page__outro__title__sub")}>
            {user.name}님의 편이 되어 함께 고민해드릴게요.
          </div>
        </div>
        <div
          className={cx("page__outro__cta")}
          onClick={() => {
            window.open(
              `https://spartacodingclub.channel.io/support-bots/30162`
            );
          }}
        >
          문의 신청하기
        </div>
        {isModalVisible && (
          <Modal
            visible={isModalVisible}
            handleVisible={setIsModalVisible}
            header={"상담 신청이 완료되었어요!"}
            content={
              <div className={cx("modal-content")}>
                담당자가 연락드릴게요
                <img
                  className={cx("modal-content__img")}
                  src={("/assets/emoji/emoji_wink.png")}
                  alt="emoji wink"
                />
              </div>
            }
            cta={{
              btn_text: "기다릴게요!",
              handleCTAClick: () => {},
            }}
          />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, courseV2, salesCall } = state;
  const { user } = authentication;
  return {
    user,
    courseV2,
    salesCall,
  };
}

const connectedSecretStorePage = connect(mapStateToProps, {
  getCourse: coursesActions.getCourse,
  getSalecall: enrolledActions.getSalecall,
  postSalescall: enrolledActions.postSalescall,
})(SecretStorePage);
export { connectedSecretStorePage as SecretStorePage };
