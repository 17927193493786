import { HANGHAE_ORIENTATION } from "../constants";
import { hanghaeOrientationInvitationService } from "../services";

function postHanghaeOrientationInvitation(name, phone, email, ot_date) {
  function request() {
    return { type: HANGHAE_ORIENTATION.HANGHAE_ORIENTATION_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return hanghaeOrientationInvitationService.postHanghaeOrientationInvitation(
      name,
      phone,
      email,
      ot_date
    );
  };
}

export const hanghaeOrientationInvitationActions = {
  postHanghaeOrientationInvitation,
};
