import React from "react";
import classNames from "classnames/bind";
import { connect, useSelector } from "react-redux";
import styles from "./CourseRcmdHanghae.module.scss";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

function CourseRcmdHanghae({ user, course }) {
  const enrolled = useSelector((state) => state.enrolled);
  return (
    <div
      className={cx("course_rcmd__wrapper")}
      style={{
        "--bg-image": `url(${"/assets/images/finish/bg_hanghae.png"}`,
      }}
    >
      <div className={cx("course_rcmd__header")}>
        <h1>
          개발자로 취업하고 싶은
          <br />
          {user.name}님을 위한 추천 코스
        </h1>
      </div>
      <div className={cx("course_rcmd__body")}>
        <img
          src={"/assets/images/finish/logo_hanghae.png"}
          alt={"logo_hanghae"}
        />
        <h2 className={cx("course_rcmd__title")}>
          비전공자 개발자 취업 부트캠프
        </h2>
        <div className={cx("course_rcmd__label")}>
          <span>취업률 92.86%</span>
          <span>비전공자 비율 77.4%</span>
        </div>
        <div className={cx("course_rcmd__detail")}>
          <p>
            개발자 취업 고민,
            <br /> 항해99에서 해답을 찾으세요
          </p>
          <ul>
            <li>개발자로 취업을 희망하는 문과 출신 비전공자</li>
            <li>실무 프로젝트 경험이 없는 대학생</li>
            <li>독학, 국비교육을 들어도 여전히 막막한 취준생</li>
          </ul>
        </div>
      </div>
      <div className={cx("course_rcmd__footer")}>
        <button
          onClick={() => {
            sendLogActionRcmdPage(
              "hanghae",
              "hanghae",
              "cta",
              "자세히 알아보기",
              "https://hanghae99.spartacodingclub.kr",
              "mainPage",
              enrolled.data
            );
            window.open("https://hanghae99.spartacodingclub.kr/");
          }}
        >
          자세히 알아보기
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdHanghae = connect(
  mapStateToProps,
  {}
)(CourseRcmdHanghae);
export { connectedCourseRcmdHanghae as CourseRcmdHanghae };
