import {
  ENROLLED_HAS_SEEN_OT,
  ENROLLED_MAKER,
  ENROLLED_ROUND_WEEKS,
  ENROLLEDS,
  GovConstants,
  STUDYPLANS,
} from "../constants";
import {
  ENROLLED_DETAIL,
  ENROLLED_DETAIL_DONE,
  ENROLLED_DETAILS,
  ENROLLED_EXPIRED,
  ENROLLED_EXPIRED_SET,
  ACTIVE_ENROLLEDS,
  ENROLLED_PERIOD,
  ENROLLED_PLAYTIME,
  ENROLLED_PROGRESS,
  ENROLLED_RANK,
  ENROLLED_SET,
  ENROLLED_CONSTANT,
  ENROLLED_REIMBURSED,
} from "../constants/enrolled.constant";
import { reducer } from "./reducer";
import { govService } from "../../../_services/gov.service";
import { gfsDeadlineConstants } from "../../../_constants/gov.constants";

const initState = {
  loaded: false,
};

export function roundWeeks(state = initState, action) {
  switch (action.type) {
    case ENROLLED_ROUND_WEEKS.ENROLLED_ROUND_WEEKS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_ROUND_WEEKS.ENROLLED_ROUND_WEEKS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case ENROLLED_ROUND_WEEKS.ENROLLED_ROUND_WEEKS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolleds(state = initState, action) {
  switch (action.type) {
    case ENROLLEDS.ENROLLEDS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLEDS.ENROLLEDS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLEDS.ENROLLEDS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function activeEnrolleds(state = initState, action) {
  switch (action.type) {
    case ACTIVE_ENROLLEDS.ACTIVE_ENROLLEDS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ACTIVE_ENROLLEDS.ACTIVE_ENROLLEDS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ACTIVE_ENROLLEDS.ACTIVE_ENROLLEDS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function studyplans(state = initState, action) {
  switch (action.type) {
    case STUDYPLANS.STUDYPLANS_REQUEST:
    case STUDYPLANS.STUDYPLANS_SET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case STUDYPLANS.STUDYPLANS_SUCCESS:
    case STUDYPLANS.STUDYPLANS_SET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case STUDYPLANS.STUDYPLANS_FAILURE:
    case STUDYPLANS.STUDYPLANS_SET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolled(state = initState, action) {
  switch (action.type) {
    case ENROLLED_SET.ENROLLED_SET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_SET.ENROLLED_SET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_SET.ENROLLED_SET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function expired(state = initState, action) {
  switch (action.type) {
    case ENROLLED_EXPIRED.ENROLLED_EXPIRED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_EXPIRED.ENROLLED_EXPIRED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_EXPIRED.ENROLLED_EXPIRED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_EXPIRED_SET.ENROLLED_EXPIRED_SET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_EXPIRED_SET.ENROLLED_EXPIRED_SET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_EXPIRED_SET.ENROLLED_EXPIRED_SET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledPlaytime(state = initState, action) {
  switch (action.type) {
    case ENROLLED_PLAYTIME.ENROLLED_PLAYTIME_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_PLAYTIME.ENROLLED_PLAYTIME_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_PLAYTIME.ENROLLED_PLAYTIME_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledReimbursed(state = initState, action) {
  switch (action.type) {
    case ENROLLED_REIMBURSED.ENROLLED_REIMBURSED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_REIMBURSED.ENROLLED_REIMBURSED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_REIMBURSED.ENROLLED_REIMBURSED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledPeriod(state = initState, action) {
  switch (action.type) {
    case ENROLLED_PERIOD.ENROLLED_PERIOD_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_PERIOD.ENROLLED_PERIOD_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_PERIOD.ENROLLED_PERIOD_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledProgress(state = initState, action) {
  switch (action.type) {
    case ENROLLED_PROGRESS.ENROLLED_PROGRESS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_PROGRESS.ENROLLED_PROGRESS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_PROGRESS.ENROLLED_PROGRESS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledRanks(state = initState, action) {
  switch (action.type) {
    case ENROLLED_RANK.ENROLLED_RANK_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_RANK.ENROLLED_RANK_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_RANK.ENROLLED_RANK_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledDetail(state = initState, action) {
  switch (action.type) {
    case ENROLLED_DETAIL.ENROLLED_DETAIL_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_DETAIL.ENROLLED_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_DETAIL.ENROLLED_DETAIL_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledDetails(state = initState, action) {
  switch (action.type) {
    case ENROLLED_DETAILS.ENROLLED_DETAILS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
        loading: true,
      });
    case ENROLLED_DETAILS.ENROLLED_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        data: action.response.context,
      });
    case ENROLLED_DETAILS.ENROLLED_DETAILS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
        loading: false,
      });
    default:
      return state;
  }
}

export function doneEnrolledDetail(state = initState, action) {
  switch (action.type) {
    case ENROLLED_DETAIL_DONE.ENROLLED_DETAIL_DONE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_DETAIL_DONE.ENROLLED_DETAIL_DONE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_DETAIL_DONE.ENROLLED_DETAIL_DONE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledBadges(state = initState, action) {
  switch (action.type) {
    case ENROLLED_CONSTANT.BADGES_USERS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.BADGES_USERS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_CONSTANT.BADGES_USERS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledDetailObstacle(state = initState, action) {
  switch (action.type) {
    case ENROLLED_CONSTANT.DETAIL_OBSTACLE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.DETAIL_OBSTACLE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_CONSTANT.DETAIL_OBSTACLE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function isDetailPresent(state = initState, action) {
  switch (action.type) {
    case ENROLLED_CONSTANT.DETAIL_PRESENT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.DETAIL_PRESENT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_CONSTANT.DETAIL_PRESENT_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function salesCall(state = initState, action) {
  switch (action.type) {
    case ENROLLED_CONSTANT.SALESCALL_GET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.SALESCALL_GET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_CONSTANT.SALESCALL_GET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledDoneCount(state = initState, action) {
  switch (action.type) {
    case ENROLLED_CONSTANT.ERD_DONE_COUNT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.ERD_DONE_COUNT_INIT:
      return Object.assign({}, initState, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.ERD_DONE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_CONSTANT.ERD_DONE_COUNT_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledMaker(state = initState, action) {
  switch (action.type) {
    case ENROLLED_MAKER.ENROLLED_MAKER_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_MAKER.ENROLLED_MAKER_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_MAKER.ENROLLED_MAKER_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledSurvey(state = initState, action) {
  switch (action.type) {
    case ENROLLED_CONSTANT.SURVEY_GET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_CONSTANT.SURVEY_GET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_CONSTANT.SURVEY_GET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function hasSeenOrientation(state = initState, action) {
  return reducer(state, action, ENROLLED_HAS_SEEN_OT);
}
