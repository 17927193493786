export const gfsDeadlineConstants = {
  REQUEST: "GFS_DEADLINE_REQUEST",
  SUCCESS: "GFS_DEADLINE_SUCCESS",
  FAILURE: "GFS_DEADLINE_FAILURE",
};

export const gfsIsCardReceivedConstants = {
  REQUEST: "GFS_CARD_REQUEST",
  SUCCESS: "GFS_CARD_SUCCESS",
  FAILURE: "GFS_CARD_FAILURE",
};

export const govCompletionMilestoneConstants = {
  REQUEST: "GOV_MILESTONE_REQUEST",
  SUCCESS: "GOV_MILESTONE_SUCCESS",
  FAILURE: "GOV_MILESTONE_FAILURE",
};
