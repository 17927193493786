export const handleHotkeys = (
  event,
  playerRef,
  setIsFullscreen,
  setIsPlaying,
  setVolume,
  setIsMuted,
  setPlaybackSpeedRate
) => {
  //엔터키
  if (event.which === 13) {
    if (playerRef.current.isFullscreen()) {
      playerRef.current.exitFullscreen();
      setIsFullscreen(false);
    } else {
      playerRef.current.requestFullscreen();
      setIsFullscreen(true);
    }
  }
  //스페이스바
  if (event.which === 32) {
    if (playerRef.current.paused()) {
      event.preventDefault();
      playerRef.current?.play();
      setIsPlaying(true);
    } else {
      event.preventDefault();
      playerRef.current.pause();
      setIsPlaying(false);
    }
  }
  //up키
  if (event.which === 38) {
    playerRef.current.volume(playerRef.current.volume() + 0.1);
    setVolume(playerRef.current.volume());
    if (playerRef.current.volume() > 0) {
      setIsMuted(false);
    }
  }
  //down키
  if (event.which === 40) {
    event.preventDefault();
    playerRef.current.volume(playerRef.current.volume() - 0.1);
    setVolume(playerRef.current.volume());
    if (playerRef.current.volume() === 0) {
      setIsMuted(true);
    }
  }
  //left키
  if (event.which === 37) {
    playerRef.current.currentTime(playerRef.current.currentTime() - 10);
  }
  //right키
  if (event.which === 39) {
    playerRef.current.currentTime(playerRef.current.currentTime() + 10);
  }
  //x키로 재생속도 제어
  if (event.which == 88) {
    const player = playerRef.current;
    if (player && player.playbackRate) {
      // Access the playbackRate property safely
      let currentPlaybackRate = player.playbackRate();

      const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

      const currentIndex = playbackRates.indexOf(currentPlaybackRate);
      let nextIndex = currentIndex + 1;

      if (nextIndex >= playbackRates.length) {
        nextIndex = 0;
      }
      const newPlaybackRate = playbackRates[nextIndex];
      player.playbackRate(newPlaybackRate);
      localStorage.setItem("playbackSpeedV3", newPlaybackRate);
      setPlaybackSpeedRate(newPlaybackRate);
    }
  }
};
