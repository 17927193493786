import React from "react";
import classNames from "classnames/bind";
import styles from "./PaymentNoticeContainer.module.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

const PaymentNoticeContainer = () => {
  return (
    <section className={cx("sec", "methods")}>
      <div className={cx("info-header")}>
        <div className={cx("info-header-text")}>
          <h2>주의사항</h2>
        </div>
      </div>
      <div className={cx("info-desc")}>
        반드시 <span className={cx("warning")}>발급받으신 내일배움카드</span>로
        결제해주세요. 타 카드 결제 시{" "}
        <span className={cx("underline")}>수강등록이 불가능</span>
        합니다.
      </div>
    </section>
  );
};
function mapStateToProps(state) {
  return {};
}
const connectedPaymentNoticeContainer = connect(
  mapStateToProps,
  {}
)(PaymentNoticeContainer);

export { connectedPaymentNoticeContainer as PaymentNoticeContainer };
