import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./CourseRcmdB.module.scss";
import { abtestActions } from "../../_state/actions";
import { Channeltalk } from "../../../_components/Channeltalk";

const cx = classNames.bind(styles);

const CourseRcmdForDeveloper = ({ user, sendHackleLog }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    sendHackleLog({
      action: "spc_courseRcmdPage_view",
      course_id: "625963fa9d5b4ee7f14b61d9",
      course_name: "[무료 패키지] 개발자 취업 준비의 모든 것",
    });
  }, []);

  useEffect(() => {
    if (window.checkPlatform() === "mobile") {
      setIsMobile(true);
    }
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);
  function logic() {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight &&
      !isScrolled
    ) {
      sendHackleLog({ action: "spc_courseRcmd_scroll_90", scroll_90: true });
      setIsScrolled((isScrolled) => !isScrolled);
    }
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logic);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logic);
    };
  });

  const classCards = [
    {
      course_id: "5f8315459328d9a2909acb52",
      mainTitle: "Spring 기초반",
      title1: "백엔드 개발자가 되기 위한 필수",
      title2: "대표 프레임워크 Spring 입문!",
      tag1: "백엔드 입문",
      tag2: "포트폴리오",
      img: "finish_5",
      url: "spring",
    },
    {
      course_id: "5f83153e410f5ac76429585d",
      mainTitle: "리액트 기초반",
      title1: "프론트엔드 개발자 되기 위한 필수",
      title2: "요즘 대세 리액트를 배워보세요!",
      tag1: "프론트 입문",
      tag2: "포트폴리오",
      img: "finish_6",
      url: "react",
    },
    {
      course_id: "5f8bc3e83cff77cc2abeb181",
      mainTitle: "알고보면 알기쉬운 알고리즘",
      title1: "코딩테스트 실전 대비 코스",
      title2: "핵심 개념부터 기출 풀이까지!",
      tag1: "코테 대비",
      tag2: "실전노하우",
      img: "finish_7",
      url: "algo",
    },
  ];

  return (
    <>
      <div className={cx("topContainer")}>
        <div className={cx("topWrapper")}>
          <div className={cx("topHeader")}>
            <img
              className={cx("firstImg")}
              src={"/assets/images/finish/finish1.png"}
              alt={"course_rcmd_free"}
            />
            <div className={cx("headerTitle")}>
              <strong>개발자로 취업하고 싶은 {user.name}님</strong>
              을 위한 추천
              <br />
              <h2>맞춤 강의를 준비했어요!</h2>
            </div>
            <div className={cx("headerFooter")}>
              {user.name}님의 끊임없는 성장을 위해
              {isMobile && <br />}
              <strong> 추천과목 전용 할인쿠폰</strong>을 발급해 드렸어요!
            </div>
          </div>
          <div className={cx("topContents")}>
            {classCards.map((classCard, idx) => {
              return (
                <div className={cx("classCard")}>
                  <div
                    className={cx(
                      "classCard__header",
                      idx == 0 ? "colored" : ""
                    )}
                  >
                    {idx + 1}위
                  </div>
                  <div className={cx("classCard__title")}>
                    {classCard.title1}
                    <br />
                    {classCard.title2}
                  </div>
                  <div className={cx("classCard__tags")}>
                    #{classCard.tag1}
                    <>&nbsp;</> <>&nbsp;</> <>&nbsp;</>#{classCard.tag2}
                  </div>
                  <img
                    className={cx("classCard__img")}
                    src={`/assets/images/finish/${classCard.img}.png`}
                    alt={"course_rcmd_free"}
                  />
                  <div className={cx("btn_wrapper")}>
                    <button
                      onClick={() => {
                        sendHackleLog({
                          action: "spc_courseRcmdPage_click_button",
                          button_name: "수업 보러가기",
                          index: idx + 1,
                          course_id: classCard.course_id,
                          course_title: classCard.mainTitle,
                        });
                        window.open(
                          `https://spartacodingclub.kr/online/${classCard.url}`
                        );
                      }}
                    >
                      수업 보러가기
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <p className={cx("topNotice")}>
            * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
          </p>
        </div>
      </div>
      {isMobile ? (
        <img
          style={{ width: "100%" }}
          src={"/assets/images/finish/finish5_mobile.png"}
        />
      ) : (
        <img
          style={{ width: "100%" }}
          src={"/assets/images/finish/finish5.png"}
        />
      )}

      <div className={cx("couponContainer")}>
        <div className={cx("coupon__header")}>보너스 쿠폰</div>
        <div className={cx("coupon__title")}>
          듣고 싶은 수업이 없다면? <strong>전과목 할인 쿠폰!</strong>
        </div>
        <div className={cx("coupon__subtitle")}>
          곽진님을 위한 전과목 할인쿠폰을 발급해드렸어요!
        </div>
        <img
          className={cx("coupon__img")}
          src={"/assets/images/finish/finish6.png"}
        />
        <div className={cx("coupon__footer")}>
          * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
        </div>
        <button
          onClick={() => {
            sendHackleLog({
              action: "spc_courseRcmdPage_click_button",
              button_name: "전체 수업 둘러보기",
            });
            window.open("https://spartacodingclub.kr/catalog/featured/summary");
          }}
        >
          전체 수업 둘러보기
        </button>
      </div>
      <div className={cx("qaContainer")}>
        <img src={"/assets/images/finish/finish7.png"} />
        <h1>그래도 어떤 강의 들을지 모르겠다면, 문의주세요!</h1>
        <h2>{user.name}님의 편이 되어 함께 고민해드릴게요.</h2>

        <div className={cx("qaContainer__footer")}>
          코딩 전문 매니저가 상시 대기 중!
          <img src={"/assets/images/finish/finish8.png"} />
        </div>
        <div className={cx("triangleDown")}></div>
        <button
          id="ch-button"
          onClick={() => {
            sendHackleLog({
              action: "spc_courseRcmdPage_click_button",
              button_name: "내게 꼭 맞는 강의 추천받기",
            });
          }}
        >
          내게 꼭 맞는 강의 추천받기
          <Channeltalk />
        </button>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}
const connectedCourseRcmdForDeveloper = connect(mapStateToProps, {
  sendHackleLog: abtestActions.sendHackleLog,
})(CourseRcmdForDeveloper);

export { connectedCourseRcmdForDeveloper as CourseRcmdForDeveloper };
