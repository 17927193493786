import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { history } from "../../../_helpers";
import styles from "./HanghaeOrientationInvitationPage.module.scss";
import { hanghaeOrientationInvitationActions } from "../../_state/actions";
import { sendLog } from "../../businesslogics/logging";
import { HanghaeOrientationFloatingCTA } from "../../../_components/HanghaeOrientationFloatingCTA";

const cx = classNames.bind(styles);

function HanghaeOrientationInvitationPage({
  user,
  postHanghpostHanghaeOrientationInvitation,
}) {
  const questionListRef = useRef(null);
  const [idx, setIdx] = useState(0);
  const [otDate, setOtDate] = useState("");
  const questions = [
    {
      question: "항해99의 실전 프로젝트는 다른 부트캠프와 뭐가 다른가요?",
      answer:
        "항해99는 수강생이 '스스로 성장하는 개발자'로 거듭나게 하는 실전 프로젝트를 진행합니다.\n개발자의 성장 동력은 자기 회고, '트러블 슈팅'입니다. 항해99에서는 멘토와 트러블 슈팅을 직접 진행하는데, 가장 중요하게 확인하는 것은... (후략)",
    },
    {
      question: "항해99는 어떻게 다른 회사로의 취업을 지원하나요?",
      answer:
        "항해99는 수료생들의 취업과 그 이후를 관리하기 위해 개발자 채용 플랫폼, Port99를 운영하고 있습니다.\n항해99 수료생들은 Port99를 다음 3가지 방법으로 이용하실 수 있습니다... (후략)",
    },
    {
      question:
        "항해99는 엄청 힘든 부트캠프라던데, 합류 전에 준비해야 할 게 있을까요?",
      answer:
        "항해99 수료생 중 많은 분들은 기존에 개발 경험이 없으셨던 분들입니다. 이것이 어떻게 가능할까요?\n항해99는 출항 전에 충분한 학습을 하실 수 있도록 사전 스터디를 모아드리고 지원합니다... (후략)",
    },
  ];
  useEffect(() => {
    if (questionListRef.current) {
      questionListRef.current.addEventListener("scroll", function () {
        addQuestionIntersectionObserver();
      });
    }
  }, [questionListRef.current]);

  function addQuestionIntersectionObserver() {
    const io = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry, idx) => {
          const isIntersecting = entry.isIntersecting;
          if (isIntersecting) {
            setIdx(idx);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [0.9],
      }
    );
    Array.from(questionListRef.current.children).forEach((question) => {
      io.observe(question);
    });
  }

  useEffect(() => {
    sendLog("scc_courseRcmdPage_view", {});
    setOtDate(document.querySelector("#hanghae_orientation__date").innerText);
  }, []);

  return (
    <>
      <div className={cx("course_rcmd__wrapper")}>
        <div className={cx("course_rcmd__header")}>
          <img
            src={"/assets/images/rcmd/hanghae_invitation.png"}
            alt={"hanghae_orientation_invitation"}
          />
          <h1>개발자로 취업하고 싶은 {user.name}님을 위한 추천 코스</h1>
          <div className={cx("course_rcmd__title")}>
            <img
              src={"/assets/images/finish/logo_hanghae.png"}
              alt={"logo_hanghae"}
            />
            <h2>사전설명회 초대장</h2>
          </div>
        </div>
        <div className={cx("course_rcmd__body")}>
          <div className={cx("course_rcmd__banner")}>
            <div className={cx("course_rcmd__banner___context")}>
              <img
                src={"/assets/images/rcmd/resume.png"}
                alt={"hanghae_orientation_invitation"}
              />
              <div className={cx("course_rcmd__banner___text")}>
                <span>온라인 참석자 전원,</span>
                <p>
                  <span>개발자 이력서 예시</span>와 <br />
                  <span>이력서 작성 가이드라인</span> 제공!
                </p>
              </div>
            </div>
          </div>
          <div className={cx("course_rcmd__detail")}>
            <div className={cx("course_rcmd__body___title")}>
              개발자 취업, 고민 많으셨죠?
              <br /> '실시간'으로 해결해드립니다.
            </div>
            <div className={cx("course_rcmd__detail___cto")}>
              <img
                src={"/assets/images/rcmd/hanghae_ceo.png"}
                alt={"hanghae_cto"}
              />
              <div className={cx("course_rcmd__detail___pr")}>
                <h2>스파르타코딩클럽 이범규 대표</h2>
                <ul>
                  <li>현) 팀스파르타 대표</li>
                  <li>전) 우아한형제들 Server Engineer</li>
                  <li>전) 본엔젤스 벤처파트너스 심사역</li>
                  <li>KAIST 산업 및 시스템공학과 졸업</li>
                </ul>
              </div>
            </div>
          </div>
          <hr className={cx("course_rcmd__hr")} />
          <div className={cx("course_rcmd__questions")}>
            <div className={cx("course_rcmd__body___title")}>
              사전설명회에서 항해99에 대한 <br /> 모든 궁금증을 해소하세요.
            </div>
            <div
              className={cx("course_rcmd__questions___cards")}
              ref={questionListRef}
            >
              {questions.map((question, idx) => {
                return (
                  <div
                    className={cx("course_rcmd__questions___card")}
                    key={idx}
                  >
                    <span>Q.</span>
                    <p>{question.question}</p>
                    <div className={cx("course_rcmd__questions___answer")}>
                      {question.answer}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={cx("course_rcmd__questions___dots")}>
              {questions.map((dot, key) => {
                return (
                  <div
                    key={key}
                    className={cx(
                      key === idx
                        ? "course_rcmd__questions___shown"
                        : "course_rcmd__questions___hidden"
                    )}
                  />
                );
              })}
            </div>
          </div>
          <hr className={cx("course_rcmd__hr")} />
          <div className={cx("course_rcmd__schedule")}>
            <div className={cx("course_rcmd__body___title")}>
              사전설명회 상세 일정
            </div>
            <div className={cx("course_rcmd__schedule___box")}>
              <div className={cx("course_rcmd__schedule___category")}>
                <div
                  className={cx("course_rcmd__schedule___category____title")}
                >
                  일시
                </div>
                <p id="hanghae_orientation__date">
                  8월 22일 월요일 오후 8시~9시
                </p>
              </div>
              <div className={cx("course_rcmd__schedule___category")}>
                <div
                  className={cx("course_rcmd__schedule___category____title")}
                >
                  장소
                </div>
                <p>
                  온라인 <span>*링크는 추후에 안내드립니다.</span>
                </p>
              </div>
              <div className={cx("course_rcmd__schedule___section")}>
                <div className={cx("course_rcmd__schedule___section____title")}>
                  1부
                </div>
                <p>
                  항해99 출신 개발자는 왜 현업에서 <br /> 인정받을까?
                </p>
              </div>
              <div className={cx("course_rcmd__schedule___section")}>
                <div className={cx("course_rcmd__schedule___section____title")}>
                  2부
                </div>
                <p>개발자 취업, 무엇이든 물어보세요!</p>
              </div>
            </div>
            <div className={cx("course_rcmd__footer")}>
              <button
                onClick={() => {
                  sendLog("hh_orientation_scc", {
                    online_user_id: user._id,
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                  });
                  postHanghpostHanghaeOrientationInvitation(
                    user.name,
                    user.phone,
                    user.email,
                    document.querySelector("#hanghae_orientation__date")
                      .innerText
                  ).then((res) => {
                    if (res.ok) {
                      history.push(`/course/rcmd/hanghae`);
                    } else {
                      alert(
                        "항해 사전설명회를 신청하는 데에 문제가 발생했습니다!"
                      );
                    }
                  });
                }}
              >
                사전설명회 신청하기
              </button>
              <p>
                *신청서 제출을{" "}
                <span
                  onClick={() =>
                    window.open(
                      "https://teamsparta.notion.site/3-f2e359d2a710447db3036ff2a3156869"
                    )
                  }
                >
                  개인정보처리방침 및 제3자 제공
                </span>
                에
                <br />
                동의한 것으로 간주합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <HanghaeOrientationFloatingCTA otDate={otDate} />
    </>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdHanghaeOrientation = connect(mapStateToProps, {
  postHanghpostHanghaeOrientationInvitation:
    hanghaeOrientationInvitationActions.postHanghaeOrientationInvitation,
})(HanghaeOrientationInvitationPage);
export { connectedCourseRcmdHanghaeOrientation as HanghaeOrientationInvitationPage };
