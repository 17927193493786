import styled from "@emotion/styled";
import {
  neutralDay,
  mBody2,
  mBody3,
  mCaption2,
  mTitle2,
  scc,
  wBody1,
  wTitle2,
} from "@teamsparta/design-system";

export const ModalWrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.6;
`;

export const Container = styled.div`
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;

  width: 328px;

  background: #ffffff;

  box-shadow: 0px 8px 24px rgba(95, 102, 107, 0.12);
  border-radius: 12px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 6.5px;
  ${wTitle2};
  margin-bottom: 24px;
`;

export const SubTitle = styled.div`
  ${mBody2};
  color: ${neutralDay.gray90};
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
`;
export const BenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 288px;

  background: #f6f9fa;

  border: 1px solid #e4ebf0;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const BenefitTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${mTitle2};
`;

export const BenefitSubTitle = styled.div`
  ${mBody3};
  color: ${neutralDay.gray80};
`;

export const BenefitElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
`;

export const BenefitIndex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;

  width: 20px;
  height: 20px;

  background: #e4ebf0;
  border-radius: 35px;
  ${mCaption2};
  font-weight: 500;

  color: ${neutralDay.gray80};
`;

export const BenefitText = styled.div`
  ${mBody3};
  color: ${neutralDay.gray90};
  font-weight: 600;
`;

export const BenefitTextRed = styled.span`
  ${mBody3};
  color: ${neutralDay.gray90};
  font-weight: 600;
  color: ${scc.red100};
`;

export const NoticeContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 288px;
  position: relative;
  padding-left: 16px;
  margin-bottom: 32px;
`;

export const Notice = styled.li`
  ${mCaption2};
  font-weight: 500;
  color: ${neutralDay.gray80};
  word-break: keep-all;
  &::before {
    content: "•";
    display: inline-block;
    width: 1em;
    font-size: 20px;

    position: absolute;
    left: 3px;
  }
`;

export const MobileButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 288px;
  height: 48px;

  background: #e8344e;
  border-radius: 8px;
  ${wBody1};
  color: ${neutralDay.white};
  cursor: pointer;
`;
