import { BEST_CHECKINS, CHECKINS } from "../constants";
import { checkinService } from "../services/checkin.service";

function getBestCheckins() {
  function request() {
    return { type: BEST_CHECKINS.BEST_CHECKINS_REQUEST };
  }

  function success(response) {
    return { type: BEST_CHECKINS.BEST_CHECKINS_SUCCESS, response };
  }

  function failure(error) {
    return { type: BEST_CHECKINS.BEST_CHECKINS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    checkinService.getBestCheckins().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getCheckins(offset) {
  function request() {
    return { type: CHECKINS.CHECKINS_REQUEST };
  }

  function success(response) {
    return { type: CHECKINS.CHECKINS_SUCCESS, response };
  }

  function failure(error) {
    return { type: CHECKINS.CHECKINS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    checkinService.getCheckins(offset).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getIsCheckinCreated() {
  function request() {
    return { type: CHECKINS.CHECKINS_CREATED_REQUEST };
  }

  function success(response) {
    return { type: CHECKINS.CHECKINS_CREATED_SUCCESS, response };
  }

  function failure(error) {
    return { type: CHECKINS.CHECKINS_CREATED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    checkinService.getIsCheckinCreated().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const checkinActions = {
  getBestCheckins,
  getCheckins,
  getIsCheckinCreated,
};
