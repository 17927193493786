import privateInstance from "../../_helper/axios";

async function getWIL(enrolleds_id, week) {
  return await privateInstance
    .get(`/wil/${enrolleds_id}/${week}`)
    .then((resp) => resp.data);
}

async function postWIL(enrolleds_id, week, url) {
  return await privateInstance
    .post(`/wil/${enrolleds_id}/${week}`, { url })
    .then((resp) => resp.data);
}

export const wilService = {
  getWIL,
  postWIL,
};
