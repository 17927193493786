export const size = {
  mobile: 320,
  breakPoint: 499,
  tablet: 768,
  desktop: 1024,
  gnbDesktop: 1096,
};

export const device = {
  mobile: `(min-width: ${size.mobile}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  breakPoint: `(min-width: ${size.breakPoint}px)`,
  gnbDesktop: `(min-width: ${size.gnbDesktop}px)`,
  lessThanDesktop: `(max-width: ${size.desktop - 1}px)`,
};

export const deviceCorrect = {
  mobile: `(max-width: ${size.tablet}px)`,
  tablet: `(max-width: ${size.desktop}px) and (min-width: ${size.tablet}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};

export const Device = {
  Mobile: `@media ${device.mobile} `,
  WiderThanLargeMobile: `@media (min-width: ${size.breakPoint}px)`,
  Tablet: `@media ${device.tablet}`,
  Desktop: `@media ${device.desktop}`,
  LessThanDesktop: `@media ${device.lessThanDesktop}`,
};

/** 태블릿 뷰까지 포함한 최신(23.8.23) 디자인 시스템*/
export const breakpoints = {
  /** 각 기기의 최소값임*/
  mobile: 320,
  miniTablet: 768,
  tablet: 820,
  miniDesktop: 1024, //footer v2에서 필요(23.9.7)
  desktop: 1200,
};

export const DisplayResolution = {
  Mobile: `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${
    breakpoints.miniTablet - 1
  }px)`,
  MiniTablet: `@media (min-width: ${
    breakpoints.miniTablet
  }px) and (max-width: ${breakpoints.tablet - 1}px)`,
  Tablet: `@media (min-width: ${breakpoints.tablet}px) and (max-width: ${
    breakpoints.miniDesktop - 1
  }px)`,
  MiniDesktop: `@media (min-width: ${
    breakpoints.miniDesktop
  }px) and (max-width: ${breakpoints.desktop - 1}px)`,

  Desktop: `@media (min-width: ${breakpoints.desktop}px)`,

  // 두개 이상의 단위를 포괄하는 경우
  MobileAndMiniTablet: `@media(min-width: ${
    breakpoints.mobile
  }px) and (max-width: ${breakpoints.tablet - 1}px)`,
  MiniDesktopAndDesktop: `@media(min-width: ${breakpoints.miniDesktop}px)`,

  TabletAndDesktop: `@media (min-width: ${breakpoints.tablet}px)`, // desktop 디자인은 tablet 디자인에서 좌우 여백만 추가됨
};
