import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { RcmdClassCard } from "../../components/organisms/RcmdClassCard/RcmdClassCard";
import { workersClassCardsNb } from "../../components/organisms/RcmdClassCard/RcmdClassCardData";
import * as S from "./CourseRcmdNb.style";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

function CourseRcmdNb({ user, course }) {
  const [isMobile, setIsMobile] = useState(false);
  const enrolled = useSelector((state) => state.enrolled);

  useEffect(() => {
    if (window.checkPlatform() === "mobile") {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <S.IntroDiv isMobile={isMobile}>
        <S.Title>
          코딩을 더 배우고 싶은
          <br />
          {user.name} 님을 위한 추천 코스
        </S.Title>
        <S.Logo src={"/assets/icons/logo-고용노동부.png"}></S.Logo>
        <S.SubName>100% 환급 과정</S.SubName>
        <S.BlackBigText>스파르타 국비지원</S.BlackBigText>
        <S.SubText>40만원대 강의를 4만원으로, 최대 두 과목까지</S.SubText>
        <S.TagsContainer>
          <S.Tag>국비지원</S.Tag>
          <S.Tag>100% 환급</S.Tag>
          <S.Tag>온라인 강의</S.Tag>
        </S.TagsContainer>
        <S.BlackSmallText>일주일 2시간이면 코딩 왕초보 탈출!</S.BlackSmallText>
        <S.BlackSmallUl>
          <S.BlackSmallLi>
            <S.Dot />
            누적수강생 약 13,500명, 압도적 1위
          </S.BlackSmallLi>
          <S.BlackSmallLi>
            <S.Dot />
            80% 이상 수강 시 전액 환급
          </S.BlackSmallLi>
          <S.BlackSmallLi>
            <S.Dot />
            환급률 92.7%, 만족도 97.9%
          </S.BlackSmallLi>
        </S.BlackSmallUl>
        <S.Cta
          onClick={() => {
            sendLogActionRcmdPage(
              "nb",
              "nb",
              "cta",
              "국비지원 안내받기",
              "https://spartacodingclub.kr/nb",
              "mainPage",
              enrolled.data
            );
            window.open(`${process.env.REACT_APP_SCC_URL}/nb/guide`);
          }}
        >
          국비지원 안내받기
        </S.Cta>
      </S.IntroDiv>
      <S.RcmdDiv>
        <S.RcmdTitle>
          스파르타 국비지원
          <br />
          인기 강의 TOP3
        </S.RcmdTitle>
        <S.CardsContainer>
          {workersClassCardsNb.map((classCard, idx) => (
            <RcmdClassCard
              classCard={classCard}
              isShared={false}
              userName={user?.name}
              uid={user?._id}
              coursesId={course?._id}
              key={classCard.course_id}
              noCta={true}
              isRcmdNb={true}
            />
          ))}
        </S.CardsContainer>
      </S.RcmdDiv>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdNb = connect(mapStateToProps, {})(CourseRcmdNb);
export { connectedCourseRcmdNb as CourseRcmdNb };
