import React, { useEffect } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../../_state/actions";
import classNames from "classnames/bind";
import styles from "./LecMenuContents.module.scss";
import { CodeSnippets } from "../../../../components/organisms/CodeSnippets";
import { lectureMenuComponents } from "../constants";
import { UserSettings } from "../../../../components/organisms/UserSettings";
import { HrdLinkContainer } from "../../Hrd/HrdLinkContainer";

const cx = classNames.bind(styles);

function LecMenuContents({
  selectedMenu,
  setSelectedMenu,
  isDual,
  setIsDual,
  codeSnippets,
  isGovIntro,
  enrolledDetail,
}) {
  useEffect(() => {
    if (!isDual) {
      setSelectedMenu(1);
    } else {
      setSelectedMenu(-1);
    }
  }, [isDual]);
  const showContainer = isDual
    ? selectedMenu === 1 || selectedMenu === 6
    : true;
  const contentsWrapper = {
    1:
      isGovIntro && enrolledDetail.data?.week === 2 ? (
        <HrdLinkContainer week={enrolledDetail.data?.week} />
      ) : (
        <CodeSnippets isDual={isDual} codeSnippets={codeSnippets} />
      ),
    6: <UserSettings isDual={isDual} setIsDual={setIsDual} />,
  };
  return (
    <>
      {showContainer && isDual && (
        <div
          onClick={() => {
            setSelectedMenu(-1);
          }}
          className={cx("bg")}
        />
      )}
      {showContainer && (
        <div
          className={cx("LecMenuContentsContainer", `${isDual ? "dual" : ""}`)}
          style={selectedMenu === 1 ? { top: "90px" } : { bottom: "20px" }}
        >
          {isDual && (
            <>
              <img
                onClick={() => {
                  setSelectedMenu(-1);
                }}
                src={`/assets/icons/lecture/ic_close_dual.svg`}
              />
              <span>{lectureMenuComponents[selectedMenu].title}</span>
            </>
          )}
          {contentsWrapper[selectedMenu]}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolledDetails, enrolledDetail, enrolled } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetail,
    enrolledDetails,
  };
}

const connectedLecMenuContents = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
})(LecMenuContents);
export { connectedLecMenuContents as LecMenuContents };
