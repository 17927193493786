import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";

import styles from "./ShareRcmdPage.module.scss";
import { RcmdClassCard } from "../../components/organisms/RcmdClassCard/RcmdClassCard";
import {
  AllClassCards,
  workersClassCards,
} from "../../components/organisms/RcmdClassCard/RcmdClassCardData";
import { CourseRcmdPirates } from "../CourseRcmdPage/CourseRcmdPirates";

const cx = classNames.bind(styles);

export default function ShareRcmdPage({ userName, coursesId, uid }) {
  const [showBestClass, setShowBestClass] = useState(false);
  const classCard = AllClassCards[coursesId];

  useEffect(() => {
    if (typeof classCard.monthly === "undefined") return;
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    if (year !== classCard.monthly.year || month !== classCard.monthly.month) {
      setShowBestClass(true);
    }
  }, []);

  return (
    <>
      {showBestClass && (
        <div className={cx("share_rcmd_page__wrapper")}>
          <div className={cx("share_rcmd_page___header")}>
            <h1 className={cx("share_rcmd_page__header-title", `--${"best"}`)}>
              스파르타코딩클럽
              <br />
              인기 강의 Top 3
            </h1>

            <p className={cx("share_rcmd__header__desc")}>
              친구가 선물해준 50,000면 할인쿠폰으로
              <br />
              부담없이 코딩을 시작해보세요!
            </p>

            <div
              className={cx("share_rcmd_online__card__wrapper", `--${"best"}`)}
            >
              {workersClassCards.map((classCard) => {
                if (classCard.course_id === "62973372bf6e0e4f799fc76e") return;
                return (
                  <RcmdClassCard
                    classCard={classCard}
                    isShared={true}
                    coursesId={coursesId}
                    userName={userName}
                    uid={uid}
                    key={classCard.course_id}
                    isRcmd={true}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {classCard.isPirates && <CourseRcmdPirates fName={userName} fId={uid} />}
      {!showBestClass && !classCard.isPirates && (
        <div className={cx("share_rcmd_page__wrapper")}>
          <div className={cx("share_rcmd_page___header")}>
            <h1 className={cx("share_rcmd_page__header-title")}>
              {userName} 님이 완주한 <br /> 코딩 강의
            </h1>
            <div className={cx("share_rcmd_online__card__wrapper")}>
              <RcmdClassCard
                classCard={classCard}
                isShared={true}
                coursesId={coursesId}
                userName={userName}
                uid={uid}
                isMonthly={showBestClass}
                isRcmd={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
