import { mypageConstants } from "../_constants";
import { mypageService } from "../_services";

function getMypage() {
  function request() {
    return { type: mypageConstants.MYPAGE_REQUEST };
  }

  function success(response) {
    return { type: mypageConstants.MYPAGE_SUCCESS, response };
  }

  function failure(error) {
    return { type: mypageConstants.MYPAGE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    mypageService.getMypage().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const mypageActions = {
  getMypage,
};
