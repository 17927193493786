import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiLectureNavInfoUrl = (round_id) => {
  return `${baseUrl}/rounds/${round_id}`;
};

export const getApiDashboardUrl = (round_id) => {
  return `${baseUrl}/rounds/${round_id}/dashboard`;
};

export const getApiCoursesInPkgUrl = (pkg_id) => {
  return `${baseUrl}/courses/${pkg_id}/courses`;
};
