import { sendLog } from "../logging";
import { createEventId } from "../../../_helpers";

export const sendBtnClickLog = async (btnText, user) => {
  const eventID = createEventId();
  await sendLog(
    "scc_rcmdFriendPage_click_button",
    {
      user_id: user._id,
      button_text: btnText,
    },
    eventID,
    true
  );
};

export const copyToClipboard = async (user, logText) => {
  try {
    let url = `https://spartacodingclub.kr/online/special/bucketlist?f_name=${user.name}&f_uid=${user._id}`;
    await navigator.clipboard.writeText(encodeURI(url));
    await sendBtnClickLog(logText, user);
    alert("링크가 복사되었으니, 지금 친구에게 공유해보세요!");
  } catch (err) {
    console.log(err);
  }
};

export const shareKko = async (user, logText) => {
  window.Kakao.Link.sendCustom({
    templateId: 87891,
    templateArgs: { name: user.name, _id: user._id },
  });
  await sendBtnClickLog(logText, user);
};
