import { useState } from "react";
import * as S from "./B2bAdminSatisfactionQuestion.style";
import { useOnlineTheme } from "../../../queries/pirates";
import { SatisfactionQuestionTypeEnum } from "../../../models/B2bAdminNPS";

export const B2bAdminNPSQuestion = ({
  type,
  questionTitle,
  questionNumber,
  size,
  onChangeScore,
  onChangeText,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const handleClickQuestion = (selectScore) => {
    setSelectedOption(selectScore);
    onChangeScore(selectScore);
  };
  const theme = useOnlineTheme();
  return (
    <S.QuestionBox theme={theme.data}>
      <S.QuestionNumber>Q{questionNumber}.</S.QuestionNumber>
      <S.QuestionTitle theme={theme.data}>{questionTitle}</S.QuestionTitle>
      {type === SatisfactionQuestionTypeEnum.SCORE && (
        <S.QuestionSelectBoxContainer>
          <S.QuestionLine />
          {[...Array(size).keys()].map((score, idx) => {
            return (
              <S.QuestionSelectContainer key={idx}>
                <S.QuestionSelectBox
                  theme={theme.data}
                  key={"강의 콘텐츠 만족도" + idx}
                  isChecked={selectedOption === score + 1}
                  onClick={() => handleClickQuestion(score + 1)}
                >
                  {score + 1}
                </S.QuestionSelectBox>
                {score + 1 === 1 && (
                  <S.QuestionSelectDescription theme={theme.data}>
                    매우 불만족
                  </S.QuestionSelectDescription>
                )}
                {score + 1 === size && (
                  <S.QuestionSelectDescription theme={theme.data}>
                    매우 만족
                  </S.QuestionSelectDescription>
                )}
              </S.QuestionSelectContainer>
            );
          })}
        </S.QuestionSelectBoxContainer>
      )}
      {type === SatisfactionQuestionTypeEnum.SUBJECTIVE && (
        <S.SubQuestionTextArea
          theme={theme.data}
          placeholder="이유는 무엇인가요?"
          onChange={(e) => onChangeText(e.target.value)}
        />
      )}
    </S.QuestionBox>
  );
};
