import React, { Component } from "react";
import { connect } from "react-redux";
import { retrospectiveActions, userActions } from "../../_actions";
import classNames from "classnames/bind";
import styles from "./RetrospectivePage.module.scss";
import instance from "../../_helpers/axios";
import { createEventId, history } from "../../_helpers";
import { sendLog } from "../../v2/businesslogics/logging";
import { NavContainer } from "../../_containers";
import {
  getCookie,
  SECRET_STORE,
  setCookie,
  toIsoString,
} from "../../v2/_helper";
import { secretstoreActions } from "../../v2/_state/actions";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);

class RetrospectivePage extends Component {
  state = {
    enrolled_id: this.props.match.params.enrolled_id,
    url: "",
  };

  componentDidMount() {
    const { enrolled_id } = this.state;
    this.props.getRetrospective(enrolled_id);
    this.props.getIsSecretStoreAllowed(enrolled_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.retrospective.loaded && this.props.retrospective.loaded) {
      this.setState({
        url: this.props.retrospective.data.url,
      });
    }

    if (
      !prevProps.isSecretStoreAllowed.loaded &&
      this.props.isSecretStoreAllowed.loaded
    ) {
      const { is_allowed_secret_store, course_id } =
        this.props.isSecretStoreAllowed.data;

      if (is_allowed_secret_store) {
        const { enrolled_id } = this.state;
        const secret_store = getCookie(SECRET_STORE);
        if (!secret_store) {
          const secret_end_date = toIsoString(new Date());
          setCookie(SECRET_STORE, secret_end_date, 1);
        }
        const popupURL = `/secretstore/${course_id}/${enrolled_id}`;
        const popupTitle = "비밀의 상점 - 스파르타코딩클럽";
        this.openRequestedPopup(popupURL, popupTitle);
      }
    }
  }

  openRequestedPopup = (url, title) => {
    var windowFeatures =
      "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
    // const windowFeatures = "location=yes,resizable=yes,scrollbars=yes,status=yes";
    window.open(url, title, windowFeatures);
  };

  copyToClipboard = async () => {
    try {
      const { user } = this.props;
      const eventID = createEventId();
      let url = `https://spartacodingclub.kr/?f_name=${user.name}&f_uid=${user._id}`;
      await navigator.clipboard.writeText(encodeURI(url));
      alert("내용이 복사되었으니, 지금 바로 카톡방에 공유해보세요!");
      await sendLog("copy_from_retro", {}, eventID);
    } catch (err) {
      console.log(err);
    }
  };

  handleUrl = (e) => {
    this.setState({
      url: e.target.value.trim(),
    });
  };

  goMyPage = () => {
    window.location.href = "/mypage";
  };

  handleSubmit = async () => {
    const { url, enrolled_id } = this.state;
    const data = { url };
    if (!url.includes("https://") && !url.includes("http://")) {
      alert("올바른 주소를 입력해주세요.");
      return;
    }
    await instance
      .post(`/enrolleds/${enrolled_id}/retrospectives`, data)
      .then((resp) => {
        const { data } = resp;
        alert(data.msg);
        if (data.ok) {
          history.push("/mypage");
        }
      });
  };

  render() {
    const { user } = this.props;
    const { url } = this.state;
    const { checkPlatform } = window;
    const course_title = this.props.retrospective.data
      ? this.props.retrospective.data.course.title
      : "";
    const isMobile = checkPlatform() === "mobile";
    return (
      <div>
        <NavContainer />
        {user && this.props.retrospective.loaded && (
          <div className={cx("retro-container")}>
            <div className={cx("retro-bg-gray")}>
              <div className={cx("retro-head")}>
                <div className={cx("head-intro")}>
                  스파르타와 함께한 코딩 공부는 어떠셨나요?
                </div>
                <div className={cx("head-body")}>완주 회고를 남겨보세요!</div>
                <div className={cx("head-outro")}>
                  블로그에 스파르타코딩클럽 수강 회고를 쓰고,
                  {isMobile && <br />}
                  해당 url을 제출해주세요.
                  <br /> 개인 블로그가 없다면{" "}
                  <a href="https://spartacodingclub.kr/community/wil/all">
                    스파르타 커뮤니티
                  </a>
                  에 남겨주셔도 좋아요.
                </div>
              </div>
              <div className={cx("submit-group")}>
                <input
                  value={url}
                  className={cx("url")}
                  type="text"
                  onChange={this.handleUrl}
                  placeholder={`작성한 게시글의 url을 넣어주세요.`}
                />
                <div
                  className={cx("submit")}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  제출
                </div>
              </div>
              <div className={cx("retro-desc-group")}>
                <div className={cx("retro-desc")}>
                  {user.name} 님에게는 공부를 마무리하는 의미가,
                  <br />
                  코딩 공부를 망설이고 있는 분들께 큰 도움이 될 거에요!
                </div>
                <div className={cx("retro-desc")}>
                  많은 현업 개발자들이 실제로 이렇게 회고를 한답니다!
                </div>
                <img
                  className={cx("retro-image")}
                  src={"/assets/images/retrospective/retro.svg"}
                  alt="retro-img"
                />
              </div>
            </div>
            <div className={cx("howto-write")}>
              <div className={cx("guide")}>
                어떤 내용을 써야할 지 고민된다면,
                <br />
                아래 내용을 담아보시면 어떨까요?
              </div>
              <div className={cx("row")}>
                <div className={cx("step")}>
                  <img
                    className={cx("retro-step")}
                    src={"/assets/images/retrospective/retro_step_1.svg"}
                    alt="retro-img"
                  />
                  <div className={cx("step-text")}>
                    <div className={cx("step-title")}>결과물 공유</div>
                    <div className={cx("step-desc")}>
                      본인이 완성한 프로젝트
                      <br />
                      녹화 영상 또는 이미지 캡처
                    </div>
                  </div>
                </div>
                <div className={cx("step")}>
                  <img
                    className={cx("retro-step")}
                    src={"/assets/images/retrospective/retro_step_2.svg"}
                    alt="retro-img"
                  />
                  <div className={cx("step-text")}>
                    <div className={cx("step-title")}>수강 목적</div>
                    <div className={cx("step-desc")}>
                      코딩을 공부하는 이유와
                      <br />
                      스파르타코딩클럽 수업을 들은 이유
                    </div>
                  </div>
                </div>
                <div className={cx("step")}>
                  <img
                    className={cx("retro-step", "large")}
                    src={"/assets/images/retrospective/retro_step_3.svg"}
                    alt="retro-img"
                  />
                  <div className={cx("step-text", "large")}>
                    <div className={cx("step-title")}>배운 점 / 잘한 점</div>
                    <div className={cx("step-desc")}>
                      수업을 통해
                      <br />
                      배운 점과 잘한 점
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx("row")}>
                <div className={cx("step")}>
                  <img
                    className={cx("retro-step")}
                    src={"/assets/images/retrospective/retro_step_4.svg"}
                    alt="retro-img"
                  />
                  <div className={cx("step-text")}>
                    <div className={cx("step-title")}>향후 목표</div>
                    <div className={cx("step-desc")}>
                      앞으로 더 발전하고 싶은 점과
                      <br />
                      계획 및 목표
                    </div>
                  </div>
                </div>
                <div className={cx("step")}>
                  <img
                    className={cx("retro-step")}
                    src={"/assets/images/retrospective/retro_step_5.svg"}
                    alt="retro-img"
                  />
                  <div className={cx("step-text")}>
                    <div className={cx("step-title")}>소감</div>
                    <div className={cx("step-desc")}>
                      스파르타코딩클럽에서 코딩을
                      <br />
                      공부한 소감
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx("bg-darkblue")}>
              <div className={cx("hashtag-header")}>
                {/*<div className={cx("hashtag-header-desc")}>*/}
                {/*  잠깐! 스파르타의 작은 부탁이 있어요*/}
                {/*</div>*/}
                <div className={cx("hashtag-header-title")}>
                  회고에 아래 3가지 해시태그를 추가해주세요!
                </div>
              </div>
              <div className={cx("hashtags")}>
                <div className={cx("hashtag")}>#스파르타코딩클럽후기</div>
                <div className={cx("hashtag")}>#코딩 강의</div>
                <div className={cx("hashtag")}>
                  #{course_title.split(" ").join("")}
                </div>
              </div>
              <div className={cx("hashtag-present")}></div>
            </div>
            <div className={cx("bg-gray")}>
              <div className={cx("rcmd-content")}>
                <div className={cx("rcmd-intro")}>
                  후기를 쓸 때 나만의 링크를
                  {isMobile && <br />}
                  넣으면 어떨까요?
                </div>
                <div className={cx("rcmd-intro")}>친구가 신청할 때마다</div>
                <div className={cx("rcmd-title")}>친구도 나도 5만 원 지급</div>
              </div>
            </div>
            <div className={cx("howto")}>
              <div className={cx("steps")}>
                <div className={cx("step")}>
                  <div className={cx("step-number")}>1</div>
                  <div className={cx("step-title")}>
                    나만의 할인 링크 복사하고
                    <br />
                    후기에 올리기
                  </div>
                  <div className={cx("step-desc")}>
                    카카오톡, SNS, 블로그 등으로
                    <br />
                    공유해보아요
                  </div>
                  <div
                    className={cx("step-cta")}
                    onClick={this.copyToClipboard}
                  >
                    나만의 할인 링크 복사하기
                  </div>
                </div>
                <div className={cx("step")}>
                  <div className={cx("step-number")}>2</div>
                  <div className={cx("step-title", "el")}>
                    공유한 링크를 통해
                    <br />
                    친구가 결제하면?
                  </div>
                  <div className={cx("step-title", "el")}>
                    친구들은{" "}
                    <span className={cx("highlight")}>5만 원 할인</span>,<br />
                    나도 친구들이 수강할 때마다
                    <br />
                    <span className={cx("highlight")}>5만 원 현금</span> get!
                  </div>
                </div>
              </div>
            </div>
            <div className={cx("rcmd-outro")}>
              친구는 <span className={cx("highlight")}>무제한 추천</span>할 수
              있으니
              <br />
              부지런히 추천하고 무료로 수강해보세요!
            </div>
            <div className={cx("retro-submit")}>
              <div className={cx("title")}>회고 제출하기</div>
              <div className={cx("submit-group")}>
                <input
                  value={url}
                  onChange={this.handleUrl}
                  className={cx("url")}
                  type="text"
                  placeholder={`작성한 게시글의 url을 넣어주세요.`}
                />
                <div
                  className={cx("submit")}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  제출하기
                </div>
              </div>
            </div>
            <div className={cx("hrdiv")}></div>
            <div className={cx("caution")}>
              <p className={cx("notice")}>유의사항</p>
              <p>
                • 해당 이벤트는 [마이페이지]에서 다시 참여할 수 있습니다.
                <br />
                • 친구가 수강신청한 강의가 개강한 다음 달에 [추천한 친구의 수 *
                5만 원]이 일괄 지급됩니다.
                <br />
                • 친구 추천을 통해 신청한 강의가 환불된 경우, 지급 대상에
                포함되지 않습니다.
                <br />
                • 단, '특강'은 본 이벤트 적용 대상 수업에서 제외됩니다.
                <br />
                • 회고는 마케팅에 활용될 수 있습니다.
                <br />• 블로그 등 업로드 시 소정의 수수료를 받을 수 있다는
                내용을 함께 적어주세요.{" "}
                <a
                  target="_blank"
                  href="https://www.ftc.go.kr/solution/skin/doc.html?fn=80f9d496feb4859659a87cabe883796eb59358d97b767f3b5f0bd6853d5c895e&rs=/fileupload/data/result//news/report/2020/"
                  className={cx("outlink")}
                >
                  관련 안내
                </a>
              </p>
            </div>
          </div>
        )}
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, retrospective, isSecretStoreAllowed } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    retrospective,
    isSecretStoreAllowed,
  };
}

const connectedRetrospectivePage = connect(mapStateToProps, {
  loginWithToken: userActions.loginWithToken,
  refreshInfo: userActions.refreshInfo,
  getRetrospective: retrospectiveActions.getRetrospective,
  getIsSecretStoreAllowed: secretstoreActions.getIsSecretStoreAllowed,
})(RetrospectivePage);
export { connectedRetrospectivePage as RetrospectivePage };
