import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoadmapMonsterDashboard.module.scss";
import { enrolledActions } from "../../../_state/actions";

const cx = classNames.bind(styles);

function RoadmapMonsterDashboard({ user, enrolled, expired, getExpired }) {
  useEffect(() => {
    if (enrolled.loaded && !expired.loaded) {
      getExpired(enrolled.data.enrolled_id);
    }
  }, [enrolled]);
  const getProgressRate = () => {
    const weekTerm = expired.data.rcmd.rcmded_week - expired.data.achv.week;
    const approximatedLecCount =
      expired.data.achv.week_tot + 10 * (weekTerm - 1);
    return 25 + expired.data.achv.week_done / approximatedLecCount;
  };
  const { enrolled_id, round_id } = enrolled.data;
  return (
    <div
      className={cx("roadmap-monster-dashboard-container")}
      style={{
        backgroundImage: `url(${"/assets/images/roadmap/bg_roadmap_monster_dashboard.gif"})`,
      }}
    >
      <a
        href={`/enrolleds/${enrolled_id}/rounds/${round_id}/rank`}
        className={cx("go-roadmap-button")}
      >
        동료들은 어디쯤 왔을까?
        <img
          className={cx("go-roadmap-button__icon")}
          src={`/images/roadmap/next_icon_white.svg`}
          alt={"레이스 페이지"}
        />
      </a>
      {expired.loaded && (
        <div className={cx("roadmap-monster-dashboard-container__versus")}>
          <div
            style={{ width: `${getProgressRate()}%` }}
            className={cx("roadmap-monster-dashboard-container__versus__left")}
          >
            {expired.data.achv.week_done === 0
              ? "시작 전 😇"
              : `${expired.data.achv.week}주차 ${expired.data.achv.week_done}강`}
          </div>
          <div
            className={cx(
              "roadmap-monster-dashboard-container__versus__divider"
            )}
          >
            VS
          </div>
          <div
            className={cx("roadmap-monster-dashboard-container__versus__right")}
          >
            {expired.data.rcmd.rcmded_week}주차 1강
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, expired, enrolled } = state;
  const { user } = authentication;
  return { user, authentication, expired, enrolled };
}

const connectedRoadmapMonsterDashboard = connect(mapStateToProps, {
  getExpired: enrolledActions.getExpired,
})(RoadmapMonsterDashboard);
export { connectedRoadmapMonsterDashboard as RoadmapMonsterDashboard };
