import { EvaluationGnb } from "../../components/organisms/Evalutaion/EvaluationGnb/EvaluationGnb";
import { useAtom } from "jotai";
import { isLectureListOpen } from "../../stores/homeworkPageV3Atom";
import { EvaluationSummary } from "../../components/organisms/Evalutaion/EvaluationSummary/EvaluationSummary";
import * as S from "./evaluationResultPage.style";
import React, { useEffect, useState } from "react";
import { useHandlePlayerCss } from "../LecturePageV3/useInitialize";
import { EvaluationDetails } from "../../components/organisms/Evalutaion/EvaluationDetails/EvaluationDetails";
import { HomeworkHelpButton } from "../../components/molecules/HomeworkV3/HomeworkHelpButton/HomeworkHelpButton";
import { useIsMobile } from "../../hooks/device";
import { SideBar } from "../LecturePageV3/SideBar/SideBar";
import { useCourseData } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { history } from "../../../_helpers";
import { useParams } from "react-router-dom";
import { useMyEvaluations } from "../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { useGetEvaluationResultData } from "../../hooks/evaluation/useEvaluationData";
import { useScrollReset } from "../../hooks/common/useScrollReset";

export const EvaluationResultPage = () => {
  const isMobile = useIsMobile();
  const [isOpenList] = useAtom(isLectureListOpen);
  const { enrolled_id, enrolled_evaluation_id } = useParams();
  const [, setOpenBuffer] = useState(false);
  const course = useCourseData();
  const { data: evaluations } = useMyEvaluations(enrolled_evaluation_id);
  const { done } = useGetEvaluationResultData(evaluations);
  useScrollReset();

  useEffect(() => {
    if (evaluations && !done) {
      const targetUrl = `/enrolleds/${enrolled_id}/evaluations/${enrolled_evaluation_id}`;
      history.push(targetUrl);
    }
  }, [evaluations, done]);

  useHandlePlayerCss(setOpenBuffer);
  return (
    <>
      <EvaluationGnb isOpenList={isOpenList} />
      <S.EvaluationResultPageContainer isOpenList={isOpenList}>
        <S.HomeworkLeftSide isOpenList={isOpenList} isMobile={isMobile}>
          <EvaluationSummary />
          <EvaluationDetails />
          <S.HelpButtonWrapper>
            <HomeworkHelpButton
              type={"evaluation"}
              href={`https://spartacodingclub.kr/community/fastqna/${course.data?.keyword}`}
            />
          </S.HelpButtonWrapper>
        </S.HomeworkLeftSide>
        <SideBar />
      </S.EvaluationResultPageContainer>
    </>
  );
};
