import styled from "@emotion/styled";
import { mBody1, mCaption1, neutralDay, scc } from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../../_styles/device";

export const GnbMobileWrapper = styled.div`
  display: none;
  ${DisplayResolution.MobileAndMiniTablet} {
    display: flex;
    min-height: 56px;
    height: fit-content;
    padding: 0 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: ${neutralDay.white};
  }
`;

export const IconContainer = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
`;

export const Title = styled.div`
  ${mBody1};
  color: #000000;
  text-align: center;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 75%;
  }
`;

export const SubTitle = styled.div`
  ${mCaption1};
  color: ${scc.red100};
  text-align: center;
`;
