import CategoryNameWithIcon from "../atoms/CategoryNameWithIcon";
import { Camp, Classroom, Sparta, ThumbUp } from "../svgs";
import CourseCards from "../templates/CourseCards";
import CourseCard from "../atoms/CourseCard";
import { courseCard, productThumbnails } from "../recommend.model";
import * as S from "../recommend.style";
import React from "react";
import { useIsMobile } from "../../../../hooks/device";
import Description from "../atoms/Description";
import ProductThumbnail from "../atoms/ProductThumbnail";
import { DesktopOnlyBr } from "../../../../components/organisms/RcmdFreePage/Benefit/Benefit.style";
import { useSelector } from "react-redux";

const Anything = () => {
  const enrolled = useSelector((state) => state.enrolled);
  const isCodingTest = enrolled?.data?.course_id === "64e32bb1fd878f27f5acae5f";
  return (
    <S.Section>
      <CategoryNameWithIcon icon={<Classroom />}>
        가볍게 코딩을 시작하는
        <br />
        입문 강의 2종
      </CategoryNameWithIcon>
      <CourseCards>
        <CourseCard
          cardData={isCodingTest ? courseCard.algo : courseCard.ChatGPT300}
        />
        <CourseCard
          cardData={isCodingTest ? courseCard.python : courseCard.webBasic}
        />
      </CourseCards>
    </S.Section>
  );
};

const Hanghae = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <S.Section>
        <CategoryNameWithIcon icon={<Classroom />}>
          개발 지식의 깊이를 더해줄
          <br />
          심화 강의 2종
        </CategoryNameWithIcon>
        <CourseCards>
          <CourseCard cardData={courseCard.spring} />
          <CourseCard cardData={courseCard.algo} />
        </CourseCards>
      </S.Section>
      <S.Section>
        <CategoryNameWithIcon icon={<Camp />}>
          {new Date() >= new Date(2023, 6, 1) ? (
            <>
              단기간에 취업/이직까지,
              <br />
              개발자 부트캠프 항해99
            </>
          ) : (
            <>
              개발자 부트캠프 항해99,
              <br />
              온라인 강의 수강생 한정 할인
            </>
          )}
          {!isMobile && (
            <Description>{productThumbnails.hanghae.description}</Description>
          )}
        </CategoryNameWithIcon>
        <ProductThumbnail thumbnailData={productThumbnails.hanghae} />
        {isMobile && (
          <Description>{productThumbnails.hanghae.description}</Description>
        )}
      </S.Section>
    </>
  );
};

const Online = () => (
  <S.Section isDesktopColumn={true}>
    <CategoryNameWithIcon icon={<Classroom />}>
      재미있는 결과물을 만드는
      <br />
      실습 강의 3종
    </CategoryNameWithIcon>
    <CourseCards>
      <CourseCard cardData={courseCard.webBasic} />
      <CourseCard cardData={courseCard.app} />
      <CourseCard cardData={courseCard.game} />
    </CourseCards>
  </S.Section>
);

const Chang = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <S.Section>
        <CategoryNameWithIcon icon={<Classroom />}>
          재미있는 결과물을 만드는
          <br />
          실습 패키지
        </CategoryNameWithIcon>
        <CourseCard cardData={courseCard.pkgNocode} />
      </S.Section>
      <S.Section>
        <CategoryNameWithIcon icon={<Camp />}>
          평일에는 직장인, <DesktopOnlyBr />
          주말에는 창업가로
          <br />
          부트캠프 {`{창}`}
          {!isMobile && (
            <Description>
              단 3개월, 예비 창업가로 거듭날 수 있습니다. 회사를 다니면서
              창업팀을 만들고, 시드 투차 유치의 기회도 누리세요.
            </Description>
          )}
        </CategoryNameWithIcon>
        <ProductThumbnail thumbnailData={productThumbnails.chang} />
        {isMobile && (
          <Description>
            단 3개월, 예비 창업가로 거듭날 수 있습니다. 회사를 다니면서 창업팀을
            만들고, 시드 투차 유치의 기회도 누리세요.
          </Description>
        )}
      </S.Section>
    </>
  );
};

const Swcamp = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <S.Section>
        <CategoryNameWithIcon icon={<Classroom />}>
          IT 기술로 업무 역량 강화!
          <br />
          데이터 분석 강의 2종
        </CategoryNameWithIcon>
        <CourseCards>
          <CourseCard cardData={courseCard.sql} />
          <CourseCard cardData={courseCard.data_v2} />
        </CourseCards>
      </S.Section>
    </>
  );
};

const Nb = () => {
  const isMobile = useIsMobile();
  return (
    <S.Section>
      <CategoryNameWithIcon icon={<Classroom />}>
        스파르타코딩클럽의
        <br />
        국비지원 코딩교육
        {!isMobile && (
          <Description>{productThumbnails.nb.description}</Description>
        )}
      </CategoryNameWithIcon>
      <ProductThumbnail thumbnailData={productThumbnails.nb} />
      {isMobile && (
        <Description>{productThumbnails.nb.description}</Description>
      )}
    </S.Section>
  );
};

const B2b = () => {
  const isMobile = useIsMobile();
  return (
    <S.Section>
      <CategoryNameWithIcon icon={<Sparta />}>
        임직원 DX 교육
        <br />
        스파르타와 함께 하세요.
        {!isMobile && (
          <Description>{productThumbnails.b2b.description}</Description>
        )}
      </CategoryNameWithIcon>
      <ProductThumbnail thumbnailData={productThumbnails.b2b} />
      {isMobile && (
        <Description>{productThumbnails.b2b.description}</Description>
      )}
    </S.Section>
  );
};

const Ddingdong = () => {
  const isMobile = useIsMobile();
  return (
    <S.Section>
      <CategoryNameWithIcon icon={<Classroom />}>
        놀면서 핵심을 배우는
        <br />
        아이 맞춤 커리큘럼
        {!isMobile && (
          <Description>{productThumbnails.ddingdong.description}</Description>
        )}
      </CategoryNameWithIcon>
      <ProductThumbnail thumbnailData={productThumbnails.ddingdong} />
      {isMobile && (
        <Description>{productThumbnails.ddingdong.description}</Description>
      )}
    </S.Section>
  );
};

export const Nbc_promote = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <S.Section>
        <CategoryNameWithIcon icon={<ThumbUp />}>
          전액 지원 부트캠프에서
          <br />
          개발자로 취업하세요!
        </CategoryNameWithIcon>
        <CourseCards>
          <CourseCard cardData={courseCard.nbc_web_frontend} />
          <CourseCard cardData={courseCard.nbc_web_backend} />
        </CourseCards>
      </S.Section>
      <S.Section>
        <S.CourseDesc>
          <CategoryNameWithIcon icon={<Camp />}>
            <>
              2000만원 지원으로
              <br />
              개발자 취업까지
            </>
          </CategoryNameWithIcon>
          <p>{productThumbnails.nbc.description}</p>
        </S.CourseDesc>
        <ProductThumbnail thumbnailData={productThumbnails.nbc} />
        {isMobile && (
          <Description>{productThumbnails.nbc.description}</Description>
        )}
      </S.Section>
    </>
  );
};

const Contents = {
  anything: <Anything />,
  // hanghae: <Hanghae />, // 내배캠 부스트를 위해 무료 강의에서 항해로 넘기던 모수를 내배캠으로 넘길 수 있도록 작업 (종료 후 항해로 원복 / 종료 시점은 미정)
  hanghae: <Nbc_promote />,
  online: <Online />,
  chang: <Chang />,
  swcamp: <Swcamp />,
  nb: <Nb />,
  b2b: <B2b />,
  ddingdong: <Ddingdong />,
};

export default Contents;
