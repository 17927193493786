import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 200px;
  border-radius: 500px;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.1);
  }
  background-color: #fff;
  opacity: ${(props) => (props.disable ? "0.2" : "1")};
  cursor: ${(props) => (props.disable ? "default" : "pointer")};
`;

export const ActionMainText = styled.div`
  font-size: 28px;
`;

export const ActionSubText = styled.div`
  font-size: 16px;
`;
