import { useEffect } from "react";
import { useRecommendTrack } from "./useRecommendTrack";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useGetEnrolled } from "../../queries/homeworkV3";

export const useRecommendPageView = (enrolledId) => {
  const { data: enrolledData, isLoading: isEnrolledLoading } =
    useGetEnrolled(enrolledId);

  const { course_id, courses, tutors } = enrolledData?.context || {};

  const { brand } = useRecommendTrack(course_id) || {};

  useEffect(() => {
    if (isEnrolledLoading) return;
    sendCPLog("scc_courseRcmdPage_view", {
      course_id: course_id,
      course_title: courses.title,
      tutor: tutors.name,
      brand: brand,
    });
  }, [isEnrolledLoading]);
};
