export const normalQuestions = [
  {
    value: "그냥 코딩을 배워두고 싶어요",
    text: "그냥 코딩을 배워두고 싶어요",
  },
  {
    value: "코딩을 업무에 적극적으로 활용하고 싶어요",
    text: "코딩을 업무에 적극적으로 활용하고 싶어요",
  },
  {
    value: "PM으로 커리어 전환하고 싶어요",
    text: "PM으로 커리어 전환하고 싶어요",
  },
  {
    value: "내일배움카드로 개발자 취업하고 싶어요",
    text: "개발자로 취업하고 싶어요",
  },
];

export const changQuestions = [
  {
    value: "단기간 내 반드시 창업을 하고 싶어요",
    text: "단기간 내 반드시 창업을 하고 싶어요",
  },
  {
    value: "당장 창업 생각은 없지만, 창업의 사이클을 경험해보고 싶어요",
    text: "당장 창업 생각은 없지만, 창업의 사이클을 경험해보고 싶어요",
  },
  {
    value: "무엇보다 개발 역량을 먼저 기르고 싶어요",
    text: "무엇보다 개발 역량을 먼저 기르고 싶어요",
  },
];

export const hrQuestions = [
  {
    value: "다양한 기업 코딩교육 정보를 얻고 싶어요",
    text: "다양한 기업 코딩교육 정보를 얻고 싶어요",
  },
  {
    value: "임직원을 위한 효과적인 DX교육을 설계하고 싶어요",
    text: "임직원을 위한 효과적인 DX교육을 설계하고 싶어요",
  },
  {
    value: "회사를 데이터 기반의 문화로 바꾸고 싶어요",
    text: "회사를 데이터 기반의 문화로 바꾸고 싶어요",
  },
  {
    value: "개발자들의 역량 향상을 위한 교육을 알고 싶어요",
    text: "개발자들의 역량 향상을 위한 교육을 알고 싶어요",
  },
];

export const youthQuestions = [
  {
    value: "코딩 포트폴리오를 어떻게 만들어야 하는지 알고 싶어요",
    text: "코딩 포트폴리오를 어떻게 만들어야 하는지 알고 싶어요",
  },
  {
    value: "입시에 코딩을 활용하는 방법을 알고 싶어요",
    text: "입시에 코딩을 활용하는 방법을 알고 싶어요",
  },
  {
    value: "코딩에 대한 교육시장의 정보를 얻고 싶어요",
    text: "코딩에 대한 교육시장의 정보를 얻고 싶어요",
  },
  {
    value: "중고등학생에게 가장 적합한 코딩 교육이 뭔지 알고 싶어요",
    text: "중고등학생에게 가장 적합한 코딩 교육이 뭔지 알고 싶어요",
  },
];
export const nbFreeQuestions = [
  {
    value: "잘 모르겠어요, 추천해주세요",
    text: "잘 모르겠어요, 추천해주세요",
  },
  {
    value: "내일배움카드로 코딩을 배우고 싶어요",
    text: "내일배움카드로 코딩을 배우고 싶어요",
  },
  {
    value: "내일배움카드로 개발자 취업하고 싶어요",
    text: "내일배움카드로 개발자 취업하고 싶어요",
  },
];

export const courseRcmdQuestions = [
  {
    value: "그냥 코딩을 배워두고 싶어요",
    text: "그냥 코딩을 배워두고 싶어요",
    isRequired: true,
  },
  {
    value: "코딩을 업무에 적극적으로 활용하고 싶어요",
    text: "코딩을 업무에 적극적으로 활용하고 싶어요",
    isRequired: true,
  },
  {
    value: "PM으로 커리어 전환하고 싶어요",
    text: "PM으로 커리어 전환하고 싶어요",
  },
  {
    value: "내일배움카드로 개발자 취업하고 싶어요",
    text: "개발자로 취업하고 싶어요",
    isRequired: true,
  },
];

export const courseRcmdBrand = {
  "그냥 코딩을 배워두고 싶어요": "online",
  "코딩을 업무에 적극적으로 활용하고 싶어요": "online",
  "내일배움카드로 개발자 취업하고 싶어요": "nbcamp",
  "잘 모르겠어요, 추천해주세요": "nb",
  "내일배움카드로 코딩을 배우고 싶어요": "nb",
  "PM으로 커리어 전환하고 싶어요": "swcamp",
  "개발자로 취업하고 싶어요": "hanghae",
  "IT 창업을 하고 싶어요": "chang",
};
