import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useRecommendBannerData } from "../../../hooks/sideBar/useRecommendBannerData";
import * as S from "./CampaignBanner.style";
import { useSelector } from "react-redux";

const CampaignBanner = ({ courseId }) => {
  const { data: course } = useSelector((state) => state.course);

  const { bannerImgSrc, nextHref, log } = useRecommendBannerData({
    courseId: courseId,
    courseTitle: course.title,
  });

  const handleBannerClick = () => {
    window.open(nextHref, "_blank");
    sendCPLog(log.title, log.data);
  };

  if (!courseId) return null;

  return (
    <S.CSRCampaignBannerImage
      onClick={handleBannerClick}
      src={bannerImgSrc}
      alt="banner"
    />
  );
};

export default CampaignBanner;
