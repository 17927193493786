import styled from "styled-components";
import { neutralDay, scc } from "@teamsparta/design-system";

export const OpenContainer = styled.div`
  display: inline-flex;
  padding: 12px 10px 12px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-color: ${scc.red100};
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  width: 46px;
  height: 66px;
  position: fixed;
  top: 120px;
  right: 0;
  z-index: 3;
  border-top: 1px solid ${scc.red75};
  border-bottom: 1px solid ${scc.red75};
  border-left: 1px solid ${scc.red75};
  background: ${neutralDay.white};

  /* Shadow/card(grayBG)/gray70 */
  box-shadow: 0 8px 24px 0 rgba(129, 137, 143, 0.12);
`;

export const IndexIcon = styled.div`
  width: 24px;
  height: 24px;
`;

export const OpenTitle = styled.div`
  /* Web/Caption2_400 wCaption classname 안먹음*/
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */

  color: ${neutralDay.gray90};
  text-align: center;
`;
