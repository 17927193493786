import React, { useEffect } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./PaymentDeliveryInformation.module.scss";

const cx = classNames.bind(styles);

const PaymentDeliveryInformation = ({
  deliveryInfo,
  setDeliveryInfo,
  setDeliveryChecked,
  setIsOpenPost,
  gift,
}) => {
  const {
    name,
    phone0,
    phone1,
    phone2,
    postCode,
    address,
    detailAddress,
    memo,
  } = deliveryInfo;

  const onChangeHandler = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    if (name.substring(0, 5) === "phone") {
      value = value.substring(0, 4);
    }
    setDeliveryInfo({ ...deliveryInfo, [name]: value });
  };

  useEffect(() => {
    let flag = true;
    Object.entries(deliveryInfo).map((obj) => {
      const [key, value] = obj;
      if (key === "memo") {
        return null;
      }
      if (value === "") {
        flag = false;
      }
    });
    if (flag) setDeliveryChecked(true);
  }, [deliveryInfo]);

  return (
    <section data-testid="delivery-form" className={cx("delivery-info")}>
      <h2>배송 정보</h2>
      {gift === "iPad" ? (
        <div class={cx("description")}>
          iPad를 배송받을 주소를 입력해 주세요! <br />
          결제일로부터 평균 2주 이내 iPad를 보내드릴 예정이에요.
        </div>
      ) : (
        <div className={cx("description")}>
          실물 교재를 배송받을 주소를 입력해 주세요! <br />
          정보 오기입 시 반송된 물품에 대해서는 재배송이 불가합니다.
        </div>
      )}

      <div className={cx("rows")}>
        <div className={cx("row")}>
          <div className={cx("row-title")}> 수령인</div>
          <div className={cx("input-container")}>
            <input value={name} name="name" onChange={onChangeHandler} />
          </div>
        </div>
        <div className={cx("row")}>
          <div className={cx("row-title")}> 연락처</div>
          <div className={cx("phone-input-container")}>
            <input
              className={cx("phone")}
              value={phone0}
              name="phone0"
              onChange={onChangeHandler}
              type="number"
            />
            -
            <input
              className={cx("phone")}
              value={phone1}
              name="phone1"
              onChange={onChangeHandler}
              type="number"
            />
            -
            <input
              className={cx("phone")}
              value={phone2}
              name="phone2"
              onChange={onChangeHandler}
              type="number"
            />
          </div>
        </div>
        <div className={cx("row")}>
          <div className={cx("row-title")}> 배송지 주소</div>
          <div className={cx("input-container")}>
            <div className={cx("search-container")}>
              <input
                className={cx("address-num")}
                value={postCode}
                name="postCode"
                onChange={onChangeHandler}
                type="number"
              />
              <button
                className={cx("search-address")}
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  setIsOpenPost(true);
                }}
              >
                주소 검색
              </button>
            </div>
            <div className={cx("address")}>
              <input
                value={address}
                name="address"
                onChange={onChangeHandler}
              />
              <input
                value={detailAddress}
                name="detailAddress"
                onChange={onChangeHandler}
                placeholder="세부 주소를 입력해 주세요."
              />
            </div>
          </div>
        </div>
        <div className={cx("row")}>
          <div className={cx("row-title")}> 배송 메모</div>
          <div className={cx("input-container")}>
            <input
              className={cx("memo")}
              value={memo}
              name="memo"
              onChange={onChangeHandler}
              placeholder="요청사항을 입력해 주세요. (선택)"
            />
            {/* <div className={cx("input-desc")}>
              *추가 모집 200명의 오프라인 굿즈는 스파르톤이 종료된 이후 배송될
              수 있습니다.
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  return {};
}
const connectedPaymentDeliveryInformation = connect(
  mapStateToProps,
  {}
)(PaymentDeliveryInformation);

export { connectedPaymentDeliveryInformation as PaymentDeliveryInformation };
