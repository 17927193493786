import { connect, useSelector } from "react-redux";
import { enrolledActions, usersActions } from "../../_state/actions";
import React, { useEffect } from "react";
import { LectureDesktop } from "./LectureDesktop/LectureDesktop";
import { LectureMobile } from "./LectureMobile/LectureMobile";
import { useNbIntroModalData } from "./useNbModal";
import NbIntroModal from "./NbIntroModal/NbIntroModal";
import { useAtom } from "jotai";
import { modalTypeAtom } from "../../stores/lectureModals";
import { MarketingSecondModal } from "../RoadmapPage/GptModal/GptModal";
import { useMarketingModalData } from "./useMarketingModal";
import {
  useCheckExpiredEnrolled,
  useTrackLastSeenLecture,
} from "../../queries/lecture";
import { useLectureStartAndEndLog } from "../../hooks/useVisibilityLog";
import { useInitializeLectureAll } from "../../businesslogics/OrderedEnrollmentContents/usecase/Initializer";
import { LoadingRtan } from "../../components/organisms/LoadingRtan/LoadingRtan";
import { useGetStartTimeRef } from "../../hooks/lecture";
import { VerificationModal } from "../../components/organisms/Modal/VerificationModal/VerificationModal";
import { useGetEnrolled, useGetEnrolledDetail } from "../../queries/homeworkV3";
import { useParams } from "react-router-dom";

const LecturePageV3 = ({ environment, user, match, setMarketingAgreement }) => {
  const isInit = useInitializeLectureAll();
  const isDesktop = environment.deviceType !== "mobile";
  const { enrolled_id: enrolledId, detail_id } = useParams();
  const { data: enrolledData } = useGetEnrolled(enrolledId);
  const { data: enrolledDetail } = useGetEnrolledDetail(enrolledId, detail_id);
  const round = useSelector((state) => state.round);
  const {
    showMarketingModal,
    setShowMarketingModal,
    marketingModalData,
    handleModalClose,
  } = useMarketingModalData(enrolledDetail, user.name);
  const nbIntroModals = useNbIntroModalData(round, enrolledDetail);
  const [visibleModal, setVisibleModal] = useAtom(modalTypeAtom);
  const startTimeRef = useGetStartTimeRef();
  useLectureStartAndEndLog();
  useTrackLastSeenLecture(match, user, "lecture", startTimeRef);
  useCheckExpiredEnrolled(match);

  // 뒤로가기 시에 모달 초기화
  useEffect(() => {
    window.addEventListener("popstate", () => {
      setVisibleModal("");
    });

    return () => {
      window.removeEventListener("popstate", () => {
        setVisibleModal("");
      });
    };
  }, []);

  return (
    <>
      {!isInit && <LoadingRtan loadingText={""} />}
      <VerificationModal />
      {showMarketingModal && (
        <MarketingSecondModal
          handleVisible={setShowMarketingModal}
          data={marketingModalData}
          handleCloseModal={handleModalClose}
          setMarketingAgreement={setMarketingAgreement}
        />
      )}
      {visibleModal === "nbIntro" && (
        <NbIntroModal
          handleVisible={setVisibleModal}
          data={nbIntroModals.modalData}
        />
      )}
      {isDesktop ? (
        <LectureDesktop
          isInit={isInit}
          course={enrolledData?.context?.courses}
          enrolledDetail={enrolledDetail?.context}
        />
      ) : (
        <LectureMobile isInit={isInit} />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { environment, authentication, hasDoneDailyAuth } = state;
  const { user } = authentication;
  return {
    environment,
    authentication,
    user,
    hasDoneDailyAuth,
  };
}

const connectedLecturePageV2 = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  setMarketingAgreement: usersActions.setMarketingAgreement,
})(LecturePageV3);

export { connectedLecturePageV2 as LecturePageV3 };
