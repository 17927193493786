import React from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classNames from "classnames/bind";
import styles from "./Nav.scss";
import { userActions } from "../_actions";
import { createEventId, history, params } from "../_helpers";
import { sendLog } from "../v2/businesslogics/logging";
import { getCookie } from "../v2/_helper";

const cx = classNames.bind(styles);
const LOGO_TYPE = {
  LOGO: 1,
  BACK: 2,
  OFF_HOME: 3,
};

class NavEn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      logoType: LOGO_TYPE.OFF_HOME,
      logoText: "SPARTA!",
    };
  }

  componentDidMount() {
    if (
      history.location.pathname === "/login" ||
      history.location.pathname === "/signup" ||
      history.location.pathname === "/payment" ||
      history.location.pathname === "/payment/done"
    ) {
      this.setState({
        logoType: LOGO_TYPE.BACK,
      });
    } else {
      this.setState({
        logoType: LOGO_TYPE.OFF_HOME,
      });
    }
    const { user } = this.props;
    let token = getCookie("token");
    if (token && !user) {
      this.props.loginWithToken(token);
      if (params().is_new) {
        const eventID = createEventId();
        sendLog("complete_registration", {}, eventID);
      }
    }
  }

  handleClick = (e) => {
    if (e.key === "logo") {
      return;
    }
    this.setState({
      current: e.key,
    });
  };

  logout() {
    const { user } = this.props;
    if (user) {
      this.props.logout();
      window.location.href = "/en/login?next=classroom";
    }
  }

  handleLogoMouseOver = () => {
    this.setState({
      logoText: "SPARTA!!!",
    });
  };
  onMouseLeave = () => {
    this.setState({
      logoText: "SPARTA!",
    });
  };

  handleLogoClick = () => {
    window.location.href =
      "https://online.spartacodingclub.kr/en/login?next=classroom";
  };

  render() {
    const { checkPlatform } = window;
    const platform = checkPlatform();
    const { user } = this.props;
    const { logoType } = this.state;
    return (
      <div className={cx("navBg")}>
        <Menu
          className={cx("navContainer")}
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
        >
          <Menu.Item className={cx("logo")} key="logo">
            {logoType === LOGO_TYPE.OFF_HOME && (
              <div
                className={cx("logoWrapper")}
                // onClick={this.handleLogoClick}
              >
                <div
                  className={cx("scc-logo")}
                  style={{
                    "--bg-image": `url(${"/assets/images/logo.png"}`,
                  }}
                />
                <div
                  id="sparta-logo-title"
                  className={cx("pixel-font")}
                  onMouseOver={this.handleLogoMouseOver}
                  onMouseLeave={this.onMouseLeave}
                >
                  {this.state.logoText}
                </div>
              </div>
            )}

            {logoType !== LOGO_TYPE.OFF_HOME &&
              platform === "mobile" &&
              logoType === LOGO_TYPE.LOGO && (
                <a className={cx("logoImg")}>
                  <img
                    src={require("../assets/images/logo.png")}
                    alt="SPARTA LOGO"
                  />
                </a>
              )}
            {logoType !== LOGO_TYPE.OFF_HOME &&
              platform === "pc" &&
              logoType === LOGO_TYPE.LOGO && (
                <a className={cx("logoImg")}>
                  <img
                    src={require("../assets/images/logo_pc.png")}
                    alt="SPARTA LOGO"
                  />
                </a>
              )}
            {logoType !== LOGO_TYPE.OFF_HOME && logoType === LOGO_TYPE.BACK && (
              <a onClick={() => history.goBack()}>
                <ArrowLeftOutlined className={cx("btnBack")} />
              </a>
            )}
          </Menu.Item>
          {platform === "pc" &&
            user &&
            user.name &&
            history.location.pathname !== "/mypage" && (
              <Menu.Item
                className={cx("menuitems")}
                key="setting:logout"
                onClick={() => this.logout()}
              >
                Log Out
              </Menu.Item>
            )}
          {user && user.name && (
            <Menu.Item className={cx("menuitems")}>
              <a href="/en/classroom">My Classroom</a>
            </Menu.Item>
          )}
          {platform === "pc" && !user && (
            <Menu.Item className={cx("menuitems")}>
              <a href="/en/login?next=classroom">Log in</a>
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedNavEn = connect(mapStateToProps, {
  logout: userActions.logout,
  loginWithToken: userActions.loginWithToken,
})(NavEn);
export { connectedNavEn as NavEn };
