import classNames from "classnames/bind";
import styles from "./RcmdClassCard.module.scss";
import { sendLogActionRcmdPage } from "../../../businesslogics/logging";
import {
  addRcmd,
  rcmdClassCardNextUrl,
} from "../../../businesslogics/RcmdPage";

const cx = classNames.bind(styles);

export const RcmdClassCard = ({
  classCard,
  isShared,
  userName,
  uid,
  coursesId,
  noCta = false,
  isRcmd = false,
  isRcmdNb = false,
}) => {
  const isGPT = coursesId === "6420ea5f7e6e4c8f5d0af5d0";
  const { title, tags, img, url, desc, isBest, isFree, course_id } = classCard;
  const buttonText = isFree
    ? "선착순 무료 신청하기"
    : isGPT
    ? "사전 구매하기"
    : "수업 보러가기";
  let nextHref = rcmdClassCardNextUrl(title, url, course_id);
  if (isRcmd) {
    nextHref = addRcmd(nextHref, userName, uid);
  }

  const goRcmdCourse = () => {
    if (isRcmdNb) {
      sendLogActionRcmdPage(
        "내일배움단",
        title,
        "cta",
        buttonText,
        nextHref,
        "수업탐색"
      );
    } else {
      sendLogActionRcmdPage(
        "online",
        title,
        "cta",
        buttonText,
        nextHref,
        "수업탐색"
      );
    }

    window.open(nextHref);
  };

  const handleImageError = (e) => {
    e.target.src = `https://cloudfront.spartacodingclub.kr/media/thumb/rcmdclasscard/${img}.png`;
  };

  return (
    <div
      className={cx("class_card__wrapper", noCta && "--pointer")}
      onClick={() => {
        if (noCta) goRcmdCourse();
      }}
    >
      <div className={cx("class_card__img__wrapper")}>
        <img
          src={`assets/images/finish/hexcard/${img}.png`}
          alt="classCard_thumb"
          onError={handleImageError}
        />
      </div>
      <div className={cx("class_card__content__container")}>
        <div className={cx("class_card__tag__container")}>
          {isBest && <div className={cx("class_card__tag-best")}>Best</div>}
          {tags?.map((tag) => {
            return <div className={cx("class_card__tag")}>{tag}</div>;
          })}
        </div>
        <div className={cx("class_card-title")}>{title}</div>
        <div className={cx("class_card-body")}>{desc}</div>
        {!noCta && (
          <div className={cx("class_card__cta__container")}>
            {!isShared && (
              <img
                src={`assets/images/finish/hexcard/${
                  isGPT ? "chatGPT_tooltip" : "coupon"
                }.png`}
                alt=""
              />
            )}
            <button
              className={cx("class_card__cta", isShared && `--${"share"}`)}
              onClick={goRcmdCourse}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
