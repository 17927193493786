import styles from './B2bNPSTextContainer.module.scss'
import classNames from "classnames/bind";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

const cx = classNames.bind(styles);

function B2bNPSTextContainer({question, index, placeHolder, handleChange}) {
  const [splitStrings, setSplitStrings] = useState([]);

  useEffect(() => {
    let stringArrays = question.split(',')
    setSplitStrings(stringArrays)
  }, [])

  return (
    <div className={cx("B2bNPSTextWrapper")}>
      <div className={cx("B2bNPSTextNumber")}>
        Q{index + 1}
      </div>
      <div className={cx("B2bNPSTextQuestion")}>
        {splitStrings[0]}<br/>{splitStrings[1]}
      </div>
      <div className={cx("B2bNPSTextButtons")}>
        <textarea className={cx("B2bNPSTextArea")} placeholder={placeHolder} onChange={(e) => handleChange(e, index)}/>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  const {authentication, enrolled} = state;
  const {user} = authentication;
  return {user, enrolled};
}

const connectedB2bNPSTextContainer = connect(mapStateToProps, {})(B2bNPSTextContainer);
export {connectedB2bNPSTextContainer as B2bNPSTextContainer};