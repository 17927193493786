import React from "react";
import styled from "@emotion/styled";
import { DisplayResolution } from "../../../../_styles/device";

interface OnlyDesktopProps {
  children: React.ReactNode;
}

interface OnlyMobileProps {
  children: React.ReactNode;
}

export const OnlyDesktop = ({ children }: OnlyDesktopProps) => {
  return <DesktopContainer>{children}</DesktopContainer>;
};

export const OnlyMobile = ({ children }: OnlyMobileProps) => {
  return <MobileContainer>{children}</MobileContainer>;
};

const MobileContainer = styled.div`
  display: block;
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;
const DesktopContainer = styled.div`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    display: block;
  }
`;
