import { PAYMENT_CONSTANT } from "../constants";
import { paymentService } from "../services/payment.service";

function getPaymentOption(course_id, round_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.OPTION_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.OPTION_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.OPTION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentOptions(course_id, round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getPaymentReinforced(course_id, round_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.REINFORCED_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.REINFORCED_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.REINFORCED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentReinforced(course_id, round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getPaymentReinforcedByUser() {
  function request() {
    return { type: PAYMENT_CONSTANT.REINFORCED_BY_USER_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.REINFORCED_BY_USER_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.REINFORCED_BY_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentReinforcedByUser().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getPaymentAlert() {
  function request() {
    return { type: PAYMENT_CONSTANT.ALERT_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.ALERT_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.ALERT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentAlert().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getIsPaymentCounselTarget(course_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.GET_COUNSEL_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.GET_COUNSEL_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.GET_COUNSEL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getIsPaymentCounselTarget(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postPaymentCounsel(course_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.POST_COUNSEL_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.POST_COUNSEL_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.POST_COUNSEL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.postPaymentCounsel(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getIsNomadCouponTarget(course_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.GET_COUPON_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.GET_COUPON_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.GET_COUPON_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getIsNomadCouponTarget(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postNomadCoupon(course_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.POST_COUPON_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.POST_COUPON_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.POST_COUPON_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService
      .postNomadCoupon(course_id)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch();
  };
}

function getPaymentReviews(course_id) {
  function request() {
    return { type: PAYMENT_CONSTANT.GET_REVIEWS_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.GET_REVIEWS_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.GET_REVIEWS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentReviews(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getPaymentVBank(order_no) {
  function request() {
    return { type: PAYMENT_CONSTANT.GET_VBANK_REQUEST };
  }

  function success(response) {
    return { type: PAYMENT_CONSTANT.GET_VBANK_SUCCESS, response };
  }

  function failure(error) {
    return { type: PAYMENT_CONSTANT.GET_VBANK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentVBank(order_no).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const paymentActionsV2 = {
  getPaymentOption,
  getPaymentReinforced,
  getPaymentReinforcedByUser,
  getPaymentAlert,
  getIsPaymentCounselTarget,
  postPaymentCounsel,
  getIsNomadCouponTarget,
  postNomadCoupon,
  getPaymentReviews,
  getPaymentVBank,
};
