import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mCaption2,
  mHeader1,
  neutralDay,
  scc,
  wBody1,
  wBody2,
  wTitle1,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../_styles/device";

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
`;

export const QuestionBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  ${DisplayResolution.TabletAndDesktop} {
    gap: 40px;
  }
`;

export const QuestionTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const QuestionNumber = styled.div`
  ${mBody1};
  color: ${scc.red100};
  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    color: ${scc.red100};
  }
`;

export const QuestionTitle = styled.div`
  ${mBody1};
  color: ${neutralDay.gray100};
  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    color: ${neutralDay.gray100};
  }
`;

export const SelectionListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

export const SelectionItem = styled.div`
  display: flex;
  height: 48px;
  padding: 13px 10px;
  justify-content: start;
  width: 100%;

  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${scc.red100}`
      : `1px solid ${neutralDay.gray30}`};
  background-color: ${(props) =>
    props.isSelected ? `${scc.red15}` : `${neutralDay.white}`};
  cursor: pointer;
`;

export const SelectionText = styled.div`
  ${mBody1};
  color: ${(props) =>
    props.isSelected ? `${scc.red100}` : `${neutralDay.gray90}`};
  line-height: 22px;
`;

export const QuestionSatisfactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
`;

export const FivePointsContainer = styled.div`
  display: flex;
  width: 328px;
  height: 70px;
  position: relative;
  padding: 0 16px;
  flex-direction: column;
`;

export const FivePoints = styled.div`
  width: 288px;
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  z-index: 1;
`;

export const Point = styled.div`
  width: 48px;
  height: 46px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isSelected ? `${scc.red100}` : `${neutralDay.white}`};
  border: 1px solid ${neutralDay.gray30};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 24px;
  gap: 10px;
`;

export const PointText = styled.div`
  ${mBody1};
  color: ${(props) =>
    props.isSelected ? `${neutralDay.white}` : `${neutralDay.gray90}`};
  line-height: 21px;
`;

export const FivePointsLine = styled.div`
  display: flex;
  width: 270px;
  height: 1px;
  background-color: ${neutralDay.gray30};
  position: absolute;
  left: 25px;
  top: 22px;
`;

export const PointLeftText = styled.div`
  ${mCaption2};
  color: ${neutralDay.gray70};
  line-height: 18px;
  position: absolute;
  bottom: 0;
  left: 12px;
`;

export const PointCenterText = styled.div`
  ${mCaption2};
  color: ${neutralDay.gray70};
  line-height: 18px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const PointRightText = styled.div`
  ${mCaption2};
  color: ${neutralDay.gray70};
  line-height: 18px;
  position: absolute;
  bottom: 0;
  right: 18px;
`;

export const NpsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: start;
  padding: 40px 16px 44px;
  background: ${neutralDay.white};
  border-bottom: 1px solid ${neutralDay.gray30};
  ${DisplayResolution.TabletAndDesktop} {
    padding: 48px 30px 44px;
    align-items: center;
    border-radius: 16px;
    border-bottom: none;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  }
`;

export const HeaderBadge = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: ${neutralDay.white};
  background: ${neutralDay.gray100};
  padding: 4px 10px 5px 10px;
  border-radius: 40px;
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  ${mHeader1};
  color: ${neutralDay.gray100};
  line-height: 33px;
  white-space: pre-wrap;
  ${DisplayResolution.TabletAndDesktop} {
    text-align: center;
    ${wTitle1};
    color: ${neutralDay.gray100};
    line-height: 33px;
  }
`;

export const HeaderDescription = styled.div`
  ${mBody2};
  color: ${neutralDay.gray70};
  line-height: 22px;
  ${DisplayResolution.TabletAndDesktop} {
    text-align: center;
    ${wBody2};
    color: ${neutralDay.gray70};
    line-height: 24px;
  }
`;

export const QuestionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  //width: 100%;
  padding: 40px 16px;
  background: ${neutralDay.gray0};
  ${DisplayResolution.TabletAndDesktop} {
    gap: 60px;
  }
`;

export const PageWrapper = styled.div`
  background: ${neutralDay.gray0};
  padding: 56px 0 40px;
  ${DisplayResolution.TabletAndDesktop} {
    padding: calc(80px + 62px) 0 80px;
  }
`;

export const WidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${DisplayResolution.TabletAndDesktop} {
    gap: 60px;
    width: 100%;
    max-width: 588px;
    margin: 0 auto;
  }
`;

export const GnbMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: ${neutralDay.white};
  z-index: 2;
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const GnbTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GnbCourseTitle = styled.div`
  ${mBody1};
  color: #000;
  line-height: 22px;
`;

export const GnbWeekTitle = styled.div`
  ${mCaption2};
  color: ${scc.red100};
  line-height: 18px;
`;

export const GnbEmpty = styled.div`
  width: 24px;
  height: 24px;
`;

export const GnbDesktopWrapper = styled.div`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 24px;
    background: ${neutralDay.white};
    border-bottom: 1px solid ${neutralDay.gray40};
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }
`;

export const GnbDesktopLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const GnbDesktopTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

export const GnbDesktopCourseTitle = styled.div`
  ${wBody1};
  color: #000;
  line-height: 25px;
`;

export const GnbDesktopDivider = styled.div`
  width: 1px;
  height: 10px;
  background: ${neutralDay.gray50};
`;

export const GnbDesktopWeekTitle = styled.div`
  ${wBody1};
  color: ${scc.red100};
  line-height: 25px;
`;

export const DesktopSubmitCta = styled.div`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px 16px;
    align-self: stretch;
    gap: 4px;
    align-items: center;
    border-radius: 8px;
    background: ${scc.red100};
    opacity: ${(props) => (props.isClickable ? 1 : 0.3)};
    cursor: ${(props) => (props.isClickable ? "pointer" : "not-allowed")};
  }
`;

export const DesktopSubmitCtaText = styled.div`
  ${wBody1};
  color: ${neutralDay.white};
  line-height: 26px;
`;

export const MobileSubmitCtaWrapper = styled.div`
  display: flex;
  background: ${neutralDay.white};
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;

  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;
