import { useEffect, useRef } from "react";
import videojs from "video.js";
import { usePlaybackSpeed } from "../stores/playerAtom";

export const usePlaybackSpeedControl = (videoRef) => {
  const [playbackSpeed, setPlaybackSpeedRate] = usePlaybackSpeed();
  const playerRef = useRef(null);

  const speedOptions = [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5];

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    playerRef.current = videojs(videoRef.current);
    const player = playerRef.current;
    player.ready(() => {
      const localPlaybackSpeed = parseFloat(
        localStorage.getItem("playbackSpeedV3") || 1
      );
      player.on("loadedmetadata", () => {
        player.playbackRate(localPlaybackSpeed);
        setPlaybackSpeedRate(localPlaybackSpeed);
      });
    });
    return () => {
      player.dispose();
    };
  }, [videoRef]);

  const onSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value) || 1;
    playerRef.current?.playbackRate(newSpeed);
    setPlaybackSpeedRate(newSpeed);
    localStorage.setItem("playbackSpeedV3", newSpeed);
  };

  return {
    playbackSpeed,
    speedOptions,
    onSpeedChange,
  };
};
