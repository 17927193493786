export const ENROLLED_ROUND_WEEKS = {
  ENROLLED_ROUND_WEEKS_REQUEST: "ENROLLED_ROUND_WEEKS_REQUEST",
  ENROLLED_ROUND_WEEKS_SUCCESS: "ENROLLED_ROUND_WEEKS_SUCCESS",
  ENROLLED_ROUND_WEEKS_FAILURE: "ENROLLED_ROUND_WEEKS_FAILURE",
};

export const ENROLLEDS = {
  ENROLLEDS_REQUEST: "ENROLLEDS_REQUEST",
  ENROLLEDS_SUCCESS: "ENROLLEDS_SUCCESS",
  ENROLLEDS_FAILURE: "ENROLLEDS_FAILURE",
};

export const ACTIVE_ENROLLEDS = {
  ACTIVE_ENROLLEDS_REQUEST: "ACTIVE_ENROLLEDS_REQUEST",
  ACTIVE_ENROLLEDS_SUCCESS: "ACTIVE_ENROLLEDS_SUCCESS",
  ACTIVE_ENROLLEDS_FAILURE: "ACTIVE_ENROLLEDS_FAILURE",
};

export const STUDYPLANS = {
  STUDYPLANS_REQUEST: "STUDYPLANS_REQUEST",
  STUDYPLANS_SUCCESS: "STUDYPLANS_SUCCESS",
  STUDYPLANS_FAILURE: "STUDYPLANS_FAILURE",

  STUDYPLANS_SET_REQUEST: "STUDYPLANS_SET_REQUEST",
  STUDYPLANS_SET_SUCCESS: "STUDYPLANS_SET_SUCCESS",
  STUDYPLANS_SET_FAILURE: "STUDYPLANS_SET_FAILURE",
};

export const ENROLLED_SET = {
  ENROLLED_SET_REQUEST: "ENROLLED_SET_REQUEST",
  ENROLLED_SET_SUCCESS: "ENROLLED_SET_SUCCESS",
  ENROLLED_SET_FAILURE: "ENROLLED_SET_FAILURE",
};

export const ENROLLED_EXPIRED = {
  ENROLLED_EXPIRED_REQUEST: "ENROLLED_EXPIRED_REQUEST",
  ENROLLED_EXPIRED_SUCCESS: "ENROLLED_EXPIRED_SUCCESS",
  ENROLLED_EXPIRED_FAILURE: "ENROLLED_EXPIRED_FAILURE",
};

export const ENROLLED_EXPIRED_SET = {
  ENROLLED_EXPIRED_SET_REQUEST: "ENROLLED_EXPIRED_SET_REQUEST",
  ENROLLED_EXPIRED_SET_SUCCESS: "ENROLLED_EXPIRED_SET_SUCCESS",
  ENROLLED_EXPIRED_SET_FAILURE: "ENROLLED_EXPIRED_SET_FAILURE",
};

export const ENROLLED_PLAYTIME = {
  ENROLLED_PLAYTIME_REQUEST: "ENROLLED_PLAYTIME_REQUEST",
  ENROLLED_PLAYTIME_SUCCESS: "ENROLLED_PLAYTIME_SUCCESS",
  ENROLLED_PLAYTIME_FAILURE: "ENROLLED_PLAYTIME_FAILURE",
};

export const ENROLLED_REIMBURSED = {
  ENROLLED_REIMBURSED_REQUEST: "ENROLLED_REIMBURSED_REQUEST",
  ENROLLED_REIMBURSED_SUCCESS: "ENROLLED_REIMBURSED_SUCCESS",
  ENROLLED_REIMBURSED_FAILURE: "ENROLLED_REIMBURSED_FAILURE",
};

export const ENROLLED_PERIOD = {
  ENROLLED_PERIOD_REQUEST: "ENROLLED_PERIOD_REQUEST",
  ENROLLED_PERIOD_SUCCESS: "ENROLLED_PERIOD_SUCCESS",
  ENROLLED_PERIOD_FAILURE: "ENROLLED_PERIOD_FAILURE",
};

export const ENROLLED_PROGRESS = {
  ENROLLED_PROGRESS_REQUEST: "ENROLLED_PROGRESS_REQUEST",
  ENROLLED_PROGRESS_SUCCESS: "ENROLLED_PROGRESS_SUCCESS",
  ENROLLED_PROGRESS_FAILURE: "ENROLLED_PROGRESS_FAILURE",
};

export const ENROLLED_RANK = {
  ENROLLED_RANK_REQUEST: "ENROLLED_RANK_REQUEST",
  ENROLLED_RANK_SUCCESS: "ENROLLED_RANK_SUCCESS",
  ENROLLED_RANK_FAILURE: "ENROLLED_RANK_FAILURE",
};

export const ENROLLED_DETAIL = {
  ENROLLED_DETAIL_REQUEST: "ENROLLED_DETAIL_REQUEST",
  ENROLLED_DETAIL_SUCCESS: "ENROLLED_DETAIL_SUCCESS",
  ENROLLED_DETAIL_FAILURE: "ENROLLED_DETAIL_FAILURE",
};

export const ENROLLED_DETAILS = {
  ENROLLED_DETAILS_REQUEST: "ENROLLED_DETAILS_REQUEST",
  ENROLLED_DETAILS_SUCCESS: "ENROLLED_DETAILS_SUCCESS",
  ENROLLED_DETAILS_FAILURE: "ENROLLED_DETAILS_FAILURE",
};

export const ENROLLED_DETAIL_DONE = {
  ENROLLED_DETAIL_DONE_REQUEST: "ENROLLED_DETAIL_DONE_REQUEST",
  ENROLLED_DETAIL_DONE_SUCCESS: "ENROLLED_DETAIL_DONE_SUCCESS",
  ENROLLED_DETAIL_DONE_FAILURE: "ENROLLED_DETAIL_DONE_FAILURE",
};

export const ENROLLED_DETAIL_REVIEW = {
  ENROLLED_DETAIL_REVIEW_REQUEST: "ENROLLED_DETAIL_REVIEW_REQUEST",
  ENROLLED_DETAIL_REVIEW_SUCCESS: "ENROLLED_DETAIL_REVIEW_SUCCESS",
  ENROLLED_DETAIL_REVIEW_FAILURE: "ENROLLED_DETAIL_REVIEW_FAILURE",
};

export const ENROLLED_MAKER = {
  ENROLLED_MAKER_REQUEST: "ENROLLED_MAKER_REQUEST",
  ENROLLED_MAKER_SUCCESS: "ENROLLED_MAKER_SUCCESS",
  ENROLLED_MAKER_FAILURE: "ENROLLED_MAKER_FAILURE",
};

export const ENROLLED_CONSTANT = {
  BADGES_USERS_REQUEST: "BADGES_USERS_REQUEST",
  BADGES_USERS_SUCCESS: "BADGES_USERS_SUCCESS",
  BADGES_USERS_FAILURE: "BADGES_USERS_FAILURE",

  DETAIL_OBSTACLE_REQUEST: "DETAIL_OBSTACLE_REQUEST",
  DETAIL_OBSTACLE_SUCCESS: "DETAIL_OBSTACLE_SUCCESS",
  DETAIL_OBSTACLE_FAILURE: "DETAIL_OBSTACLE_FAILURE",

  DETAIL_PRESENT_REQUEST: "DETAIL_PRESENT_REQUEST",
  DETAIL_PRESENT_SUCCESS: "DETAIL_PRESENT_SUCCESS",
  DETAIL_PRESENT_FAILURE: "DETAIL_PRESENT_FAILURE",

  SAVE_PRESENT_REQUEST: "SAVE_PRESENT_REQUEST",
  SAVE_PRESENT_SUCCESS: "SAVE_PRESENT_SUCCESS",
  SAVE_PRESENT_FAILURE: "SAVE_PRESENT_FAILURE",

  SALESCALL_GET_REQUEST: "SALESCALL_GET_REQUEST",
  SALESCALL_GET_SUCCESS: "SALESCALL_GET_SUCCESS",
  SALESCALL_GET_FAILURE: "SALESCALL_GET_FAILURE",

  SALESCALL_POST_REQUEST: "SALESCALL_POST_REQUEST",
  SALESCALL_POST_SUCCESS: "SALESCALL_POST_SUCCESS",
  SALESCALL_POST_FAILURE: "SALESCALL_POST_FAILURE",

  SURVEY_GET_REQUEST: "SURVEY_GET_REQUEST",
  SURVEY_GET_SUCCESS: "SURVEY_GET_SUCCESS",
  SURVEY_GET_FAILURE: "SURVEY_GET_FAILURE",

  SURVEY_POST_REQUEST: "SURVEY_POST_REQUEST",
  SURVEY_POST_SUCCESS: "SURVEY_POST_SUCCESS",
  SURVEY_POST_FAILURE: "SURVEY_POST_FAILURE",

  ERD_DONE_COUNT_REQUEST: "ERD_DONE_COUNT_REQUEST",
  ERD_DONE_COUNT_SUCCESS: "ERD_DONE_COUNT_SUCCESS",
  ERD_DONE_COUNT_FAILURE: "ERD_DONE_COUNT_FAILURE",
  ERD_DONE_COUNT_INIT: "ERD_DONE_COUNT_INIT",
};

export const ENROLLED_HAS_SEEN_OT = {
  REQUEST: "GOV_MILESTONE_REQUEST",
  SUCCESS: "GOV_MILESTONE_SUCCESS",
  FAILURE: "GOV_MILESTONE_FAILURE",
};
