import privateInstance from "../../_helper/axios";
import { getDeviceId } from "../../businesslogics/logging";

async function getOrder(course_id) {
  const device_id = getDeviceId();
  return await privateInstance
    .get(`/payment/orders/${course_id}?device_id=${device_id}`)
    .then((resp) => resp.data);
}

export const orderService = {
  getOrder,
};
