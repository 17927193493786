import React, { useRef, useState } from "react";
import * as S from "./congrats.style";
import { getMedalImagePath } from "../../../businesslogics/MedalImage";
import {
  logCtaClick,
  onClickSaveImage,
  useEnrolledDone,
} from "../../../hooks/nps/congrats";

const Congrats = ({
  isHidden,
  downloadRef,
  realRef,
  user,
  enrolled,
  isRepurchase,
  enrolledPlaytime,
}) => {
  const enrolledDones = useEnrolledDone();
  const [isWaitingCertificate, setIsWaitingCertificate] = useState(false);

  return (
    <S.Wrapper ref={isHidden ? downloadRef : realRef} isHidden={isHidden}>
      <S.Container isHidden={isHidden}>
        <S.Title isHidden={isHidden}>
          {user.name} 님, 강의 완강을
          <br />
          진심으로 축하해요!
        </S.Title>
        <S.MedalImage src={getMedalImagePath(isRepurchase, true)} />
        {!isHidden && (
          <>
            <S.ShareContainer>
              <S.Cta
                onClick={() => {
                  onClickSaveImage(downloadRef, enrolled);
                  logCtaClick("메달 사진 저장하기");
                }}
                isMain={true}
              >
                메달 사진 저장하기
              </S.Cta>
            </S.ShareContainer>
            <S.Desc>
              * 수료증 다운로드 : 마이페이지 > 수강증/수료증 메뉴 확인
            </S.Desc>
          </>
        )}
      </S.Container>
    </S.Wrapper>
  );
};

const CongratsWithHiddenImageDownloadComponent = ({
  user,
  enrolled,
  isRepurchase,
}) => {
  const downloadRef = useRef(null);
  const realRef = useRef(null);
  return (
    <>
      {/* 다운로드할 이미지를 그려내는 고정 크기 hidden 컨테이너 */}
      <S.HiddenWrapper>
        <Congrats
          user={user}
          enrolled={enrolled}
          isRepurchase={isRepurchase}
          isHidden={true}
          downloadRef={downloadRef}
        />
      </S.HiddenWrapper>

      {/* 유저가 실제로 인터렉션하는 진짜 컨테이너 */}
      <Congrats
        user={user}
        enrolled={enrolled}
        isRepurchase={isRepurchase}
        downloadRef={downloadRef}
        realRef={realRef}
      />
    </>
  );
};

export default CongratsWithHiddenImageDownloadComponent;
