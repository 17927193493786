import { useIsMobile } from "../../../hooks/device";
import { PIRATES } from "../../../../_consts";

export const TooltipBg = ({ theme }) => {
  const bgColor = theme === PIRATES ? "#222425" : "#FFFFFF";
  const isMobile = useIsMobile();
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="220"
      height="70"
      viewBox="0 0 220 70"
      fill="none"
    >
      <mask id="path-1-inside-1_7501_325378" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8V54C0 58.4183 3.58173 62 8.00001 62H103.418L108.386 68.7923C109.185 69.8849 110.815 69.8849 111.614 68.7923L116.582 62H212C216.418 62 220 58.4183 220 54V8C220 3.58172 216.418 0 212 0H8Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8V54C0 58.4183 3.58173 62 8.00001 62H103.418L108.386 68.7923C109.185 69.8849 110.815 69.8849 111.614 68.7923L116.582 62H212C216.418 62 220 58.4183 220 54V8C220 3.58172 216.418 0 212 0H8Z"
        fill={bgColor}
      />
      <path
        d="M103.418 62L104.629 61.1145L104.18 60.5H103.418V62ZM108.386 68.7923L107.175 69.6778H107.175L108.386 68.7923ZM111.614 68.7923L110.404 67.9069V67.9069L111.614 68.7923ZM116.582 62V60.5H115.82L115.371 61.1145L116.582 62ZM1.5 8C1.5 4.41015 4.41015 1.5 8 1.5V-1.5C2.75329 -1.5 -1.5 2.75329 -1.5 8H1.5ZM1.5 54V8H-1.5V54H1.5ZM8.00001 60.5C4.41015 60.5 1.5 57.5898 1.5 54H-1.5C-1.5 59.2467 2.7533 63.5 8.00001 63.5V60.5ZM103.418 60.5H8.00001V63.5H103.418V60.5ZM109.596 67.9069L104.629 61.1145L102.208 62.8855L107.175 69.6778L109.596 67.9069ZM110.404 67.9069C110.204 68.18 109.796 68.18 109.596 67.9069L107.175 69.6778C108.573 71.5897 111.427 71.5897 112.825 69.6778L110.404 67.9069ZM115.371 61.1145L110.404 67.9069L112.825 69.6778L117.792 62.8855L115.371 61.1145ZM212 60.5H116.582V63.5H212V60.5ZM218.5 54C218.5 57.5899 215.59 60.5 212 60.5V63.5C217.247 63.5 221.5 59.2467 221.5 54H218.5ZM218.5 8V54H221.5V8H218.5ZM212 1.5C215.59 1.5 218.5 4.41015 218.5 8H221.5C221.5 2.75329 217.247 -1.5 212 -1.5V1.5ZM8 1.5H212V-1.5H8V1.5Z"
        fill="#FF6C7A"
        mask="url(#path-1-inside-1_7501_325378)"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="220"
      height="70"
      viewBox="0 0 220 70"
      fill="none"
    >
      <mask id="path-1-inside-1_7467_325503" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.614 1.20734C110.815 0.114806 109.185 0.114808 108.386 1.20734L103.418 8.00001H8C3.58172 8.00001 0 11.5817 0 16V62C0 66.4183 3.58173 70 8.00001 70H212C216.418 70 220 66.4183 220 62V16C220 11.5817 216.418 8.00001 212 8.00001H116.582L111.614 1.20734Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.614 1.20734C110.815 0.114806 109.185 0.114808 108.386 1.20734L103.418 8.00001H8C3.58172 8.00001 0 11.5817 0 16V62C0 66.4183 3.58173 70 8.00001 70H212C216.418 70 220 66.4183 220 62V16C220 11.5817 216.418 8.00001 212 8.00001H116.582L111.614 1.20734Z"
        fill={bgColor}
      />
      <path
        d="M108.386 1.20734L109.596 2.09279V2.09279L108.386 1.20734ZM111.614 1.20734L112.825 0.321884L112.825 0.321882L111.614 1.20734ZM103.418 8.00001V9.50001H104.179L104.629 8.88546L103.418 8.00001ZM116.582 8.00001L115.371 8.88546L115.821 9.50001H116.582V8.00001ZM109.596 2.09279C109.796 1.81966 110.204 1.81965 110.404 2.09279L112.825 0.321882C111.427 -1.59004 108.573 -1.59004 107.175 0.32189L109.596 2.09279ZM104.629 8.88546L109.596 2.09279L107.175 0.321885L102.207 7.11456L104.629 8.88546ZM8 9.50001H103.418V6.50001H8V9.50001ZM1.5 16C1.5 12.4102 4.41015 9.50001 8 9.50001V6.50001C2.75329 6.50001 -1.5 10.7533 -1.5 16H1.5ZM1.5 62V16H-1.5V62H1.5ZM8.00001 68.5C4.41015 68.5 1.5 65.5899 1.5 62H-1.5C-1.5 67.2467 2.7533 71.5 8.00001 71.5V68.5ZM212 68.5H8.00001V71.5H212V68.5ZM218.5 62C218.5 65.5899 215.59 68.5 212 68.5V71.5C217.247 71.5 221.5 67.2467 221.5 62H218.5ZM218.5 16V62H221.5V16H218.5ZM212 9.50001C215.59 9.50001 218.5 12.4102 218.5 16H221.5C221.5 10.7533 217.247 6.50001 212 6.50001V9.50001ZM116.582 9.50001H212V6.50001H116.582V9.50001ZM110.404 2.09279L115.371 8.88546L117.793 7.11456L112.825 0.321884L110.404 2.09279Z"
        fill="#FF6C7A"
        mask="url(#path-1-inside-1_7467_325503)"
      />
    </svg>
  );
};
