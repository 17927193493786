import { useEffect, useRef } from "react";

const useTimerRefVer2 = (
  strDday,
  deps,
  format = "regular",
  isAccent = false,
  color = "#000000",
  onTimeChange = null
) => {
  const timerRef = useRef();
  let interval;

  const clearTimer = () => {
    clearInterval(interval);
  };

  useEffect(() => {
    if (!timerRef.current) {
      return;
    }
    interval = setInterval(setTimer, 1000);
    return clearTimer;
  }, [...deps, timerRef.current]);

  return timerRef;

  function setTimer() {
    if (onTimeChange) {
      onTimeChange();
    }

    if (isAccent) {
      color = "#e8344e";
    }
    const now = new Date();
    const dday = new Date(strDday);
    const days = (dday - now) / 1000 / 60 / 60 / 24;
    const daysRound = Math.floor(days).toString();
    const hours = (dday - now) / 1000 / 60 / 60 - 24 * daysRound;
    const hoursRound = Math.floor(hours).toString();
    const minutes =
      (dday - now) / 1000 / 60 - 60 * hoursRound - 60 * 24 * daysRound;
    const minutesRound = Math.floor(minutes).toString();
    const seconds =
      (dday - now) / 1000 -
      60 * 60 * hoursRound -
      60 * minutesRound -
      60 * 60 * 24 * daysRound;
    const secondsRound = Math.round(seconds).toString();

    if (timerRef.current) {
      if (format === "korean") {
        timerRef.current.innerHTML = `<span style="color: ${color}"><b style="color: ${color}">${hoursRound.padStart(
          2,
          "0"
        )}</span>시간 <span style="color: ${color}">${minutesRound.padStart(
          2,
          "0"
        )}</span>분 <span style="color: ${color}">${secondsRound.padStart(
          2,
          "0"
        )}초</b></span>`;
      } else if (format === "minute") {
        timerRef.current.innerHTML = `<span style="color: ${color}">${minutesRound.padStart(
          2,
          "0"
        )}</span>:<span style="color: ${color}">${secondsRound.padStart(
          2,
          "0"
        )}</b></span>`;
      } else {
        timerRef.current.innerHTML = `<span style="color: ${color}"><b style="color: ${color}">${daysRound}</span>일 <span style="color: ${color}">${hoursRound.padStart(
          2,
          "0"
        )}</span>:<span style="color: ${color}">${minutesRound.padStart(
          2,
          "0"
        )}</b></span>:<span style="color: ${color}">${secondsRound.padStart(
          2,
          "0"
        )}</b></span>`;
      }
    }
  }
};

export default useTimerRefVer2;
