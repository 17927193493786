import React, { useRef } from "react";
import { Form, message, Row } from "antd";
import { EmailItem } from "./EmailItem";
import { PasswordItem } from "./PasswordItem";
import { BtnItem } from "./BtnItem";
import { Link } from "react-router-dom";
import { handleResponse, history, params } from "../../../../_helpers";
import { config } from "../../../../config";
import { connect } from "react-redux";
import { userActions } from "../../../../_actions";

const LoginForm = ({
  cx,
  checkedAccountExist,
  msgContainer,
  errorMsg,
  isLoading,
  setState,
  _userTracking,
  login,
}) => {
  const ref = useRef();
  const setCheckAccountExist = (values) => {
    const { next } = params();
    fetch(
      `${config.apiUrl}/v1/auth/login?email=${values.email.toLowerCase()}`,
      { method: "GET" }
    )
      .then(handleResponse)
      .then((data) => {
        setState({ isLoading: false });
        if (data.is_exist) {
          setState({
            checkedAccountExist: true,
          });
        } else {
          setState({ email: values.email });
          history.push(`/signup${next ? "?next=" + next : ""}`);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleLoginSubmit = async (values) => {
    _userTracking();
    setState({ isLoading: true });
    if (checkedAccountExist) {
      login(values.email, values.password, params().next);
    } else {
      setCheckAccountExist(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="login"
      onFinish={handleLoginSubmit}
      onFinishFailed={onFinishFailed}
      validateMessages={msgContainer}
      ref={ref}
    >
      <EmailItem
        className={cx("form-item")}
        onChange={() => setState({ checkedAccountExist: false })}
      />
      {checkedAccountExist && (
        <PasswordItem
          cx={cx("form-item")}
          messages={msgContainer}
          unsetErrorMsg={() => setState({ errorMsg: undefined })}
          inputEmail={ref.current.getFieldValue("email")}
        />
      )}
      {errorMsg && <p className={cx("errorMsg")}>{errorMsg}</p>}
      <BtnItem
        cx={cx}
        buttonText={isLoading ? "진행 중입니다" : "다음"}
        isSignupPage={false}
      />
      <Row className={cx("accountEditLinkWrapper")}>
        {checkedAccountExist ? (
          <Link
            to={`/accounts/edit?email=${ref.current.getFieldValue("email")}`}
          >
            비밀번호를 잊으셨나요?
          </Link>
        ) : (
          <Link to={`/accounts/read`}>이메일이 기억나지 않아요</Link>
        )}
      </Row>
    </Form>
  );
};

const connectedLoginForm = connect(() => ({}), {
  login: userActions.login,
})(LoginForm);

export { connectedLoginForm as LoginForm };
