export const couponSets = [
  {
    name: "알고리즘 강의 할인 쿠폰",
    amount: 100000,
    notices: ["발급 후 5일 이내 사용 가능", "알고리즘 강의 한정 사용 가능"],
    couponIds: ["64ddcee8bfaf0357a2e04145"],
    targetCourseId: "64e32bb1fd878f27f5acae5f",
  },
  {
    name: "완강 기념 전과목 할인 쿠폰 2장",
    amount: 30000,
    notices: ["발급 후 7일 이내 사용 가능"],
    couponIds: ["61f358dda66e916fd1d391a8", "6486cbe6662e5d19a5e1eeb5"],
  },
  {
    name: "완강 기념 전과목 할인 쿠폰 3장",
    amount: 50000,
    notices: ["발급 후 7일 이내 사용 가능", "20만원 이상 결제 시 사용 가능"],
    couponIds: [
      "636a1e65ecea75cc6c520c82",
      "6486cc24ae67f77aebeb0223",
      "6486cc3dfde63e779ca02798",
    ],
  },
];
