export const BEST_CHECKINS = {
  BEST_CHECKINS_REQUEST: "BEST_CHECKINS_REQUEST",
  BEST_CHECKINS_SUCCESS: "BEST_CHECKINS_SUCCESS",
  BEST_CHECKINS_FAILURE: "BEST_CHECKINS_FAILURE",
};

export const CHECKINS = {
  CHECKINS_REQUEST: "CHECKINS_REQUEST",
  CHECKINS_SUCCESS: "CHECKINS_SUCCESS",
  CHECKINS_FAILURE: "CHECKINS_FAILURE",

  CHECKINS_CREATED_REQUEST: "CHECKINS_CREATED_REQUEST",
  CHECKINS_CREATED_SUCCESS: "CHECKINS_CREATED_SUCCESS",
  CHECKINS_CREATED_FAILURE: "CHECKINS_CREATED_FAILURE",
};
