import { useEffect, useRef } from "react";
import {
  resetMOTP,
  useCheckDailyGovAuthV2,
  useCheckPhoneVerification,
} from "../../queries/auth/auth";
import { PhoneVerification_API_URL } from "../../_state/constants";
import { useAtom } from "jotai";
import { isNeedRestMOTP } from "../../stores/varificationReset";

export const usePhoneVerification = (round_id, user, productType) => {
  let popupWindow = null;
  const formRef = useRef(null);
  const [isNeedReset, setIsNeedReset] = useAtom(isNeedRestMOTP);
  const { _id: userAgentPk, name: userName, phone: userTel } = user || {};

  const {
    isSuccess: isDailyGovAuthAccessSuccess,
    data: isDailyGovAuthRequired,
    refetch: refetchDailyAuth,
  } = useCheckDailyGovAuthV2(round_id);

  const {
    isLoading: isPhoneVerificationLoading,
    isSuccess: isPhoneVerificationSuccess,
    data: phoneVerification,
    refetch: refetchPhoneVerification,
  } = useCheckPhoneVerification();

  useEffect(() => {
    if (isPhoneVerificationSuccess) {
      window.addEventListener("message", handleReceiveMessage, false);
    }

    return () => {
      window.removeEventListener("message", handleReceiveMessage, false);
    };
  }, [isPhoneVerificationSuccess]);

  function fnPopup() {
    if (formRef.current) {
      popupWindow = window.open(
        "",
        "popupChk",
        "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      );
      formRef.current.action = PhoneVerification_API_URL;
      formRef.current.target = "popupChk";
      formRef.current.submit();
    }
  }

  async function handleReceiveMessage(event) {
    let data;
    if (event.source !== formRef.current) return;

    try {
      data = JSON.parse(event.data);
      if ("ok" in data) {
        if (data.ok) {
          await alert("휴대폰 본인 인증에 성공하셨습니다.");
          if (isNeedReset) {
            resetMOTP({ userAgentPk, userName, userTel, productType });
            setIsNeedReset(false);
          }
          await refetchPhoneVerification();
          await refetchDailyAuth();
          if (popupWindow) {
            popupWindow.close();
          }
        } else {
          alert("휴대폰 본인 인증에 실패했습니다.\n다시 시도해주세요.");
        }
      }
    } catch (ex) {
      console.log("error: ", ex);
    }
  }
  return {
    fnPopup,
    isDailyGovAuthAccessSuccess,
    phoneVerification,
    refetchPhoneVerification,
    refetchDailyAuth,
    isDailyGovAuthRequired,
    isPhoneVerificationLoading,
    formRef,
  };
};
