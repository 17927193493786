import React from "react";
import { config } from "../../../config";
import { API_VERSION } from "../../../_urls";
import { getDeviceId } from "../../businesslogics/logging";
import { params } from "../../../_helpers";

export const KakaoLoginBtn = ({ className, isPurchasingGovIntro }) => {
  const loginWithKakao = async (auto = false) => {
    const baseUrl = `${config.apiUrl}/${API_VERSION}`;
    const redirectUri = `${baseUrl}/oauth/kakao/login`;
    const device_id = getDeviceId();

    let state = Object.assign(params(), { device_id });
    let settings = state
      ? { redirectUri, state: JSON.stringify(state) }
      : { redirectUri };
    let Kakao = window.Kakao;
    if (!window.location.href.includes("https://online.spartacodingclub.kr")) {
      alert(JSON.stringify(settings));
    }
    Kakao.Auth.authorize(settings);
  };

  return (
    <div className={className}>
      <button
        onClick={() => {
          loginWithKakao();
        }}
      >
        <img alt={"카카오로그인"} src={`/assets/icons/ic_kko.svg`} />
        {isPurchasingGovIntro
          ? "카카오로 1초만에 로그인"
          : "카카오로 1초만에 시작하기"}
      </button>
    </div>
  );
};
