import React from "react";
import { connect } from "react-redux";
import { LecNavListWeek } from "../LecNavListWeek";
import classNames from "classnames/bind";
import styles from "./LecNavList.module.scss";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function LecNavList({ enrolled, enrolledDetails }) {
  const theme = useOnlineTheme();
  return (
    <div className={cx("lec-nav-list", theme.data)}>
      {enrolledDetails.loaded &&
        enrolledDetails.data.weeks.map((details_week, idx) => {
          return <LecNavListWeek key={idx} details_week={details_week} />;
        })}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolledDetails, enrolled } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetails,
  };
}

const connectedLecNavList = connect(mapStateToProps, {})(LecNavList);
export { connectedLecNavList as LecNavList };
