import styled from "@emotion/styled";
import { DisplayResolution } from "../../../../_styles/device";

export const Banners = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${DisplayResolution.TabletAndDesktop} {
    gap: 32px;
    margin-bottom: 40px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  background: #000;
  border-radius: 24px;
  padding: 32px 0;
  text-align: center;
  max-width: 89%;
  margin: auto;
  background-image: url("${({ bg }) => bg.mobile}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 259px;
  ${DisplayResolution.TabletAndDesktop} {
    background-image: url("${({ bg }) => bg.desktop}");
    flex-direction: row;
    height: 97px;
    text-align: left;
    padding: 0 45px;
    max-width: 100%;
  }
`;
export const LeftColumn = styled.div`
  height: fit-content;
  margin: 0 auto;
  color: #ffffff;
  line-height: 1;
  font-weight: 400;
  letter-spacing: -0.6px;
  font-size: 18px;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  pointer-events: none;
  ${DisplayResolution.TabletAndDesktop} {
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
    margin: auto 0;
    font-size: 20px;
  }
`;
export const Cta = styled.button`
  height: 48px;
  background: white;
  color: #000;
  width: 149px;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
  flex-shrink: 0;
  font-family: "Spoqa Han Sans Neo", "sans-serif";

  ${DisplayResolution.TabletAndDesktop} {
    height: 58px;
    width: 174px;
    font-size: 18px;
    float: right;
  }
`;
