import React from "react";
import { Form, Input } from "antd";

export const EmailItem = ({ className, onChange, email, confirmRequired }) => {
  const changeTrim = (e) => {
    return e.target.value.trim();
  };
  return (
    <Form.Item
      label="이메일"
      name="email"
      className={className}
      onChange={changeTrim}
      getValueFromEvent={changeTrim}
      rules={[
        {
          required: true,
        },
        {
          type: "email",
        },
      ]}
      initialValue={email || ""}
    >
      <Input
        placeholder={"이메일을 입력해주세요."}
        autoComplete="new-password"
        onChange={onChange}
      />
      {/*<button>인증 재발송</button>*/}
      {/*<input />*/}
      {/*<button>확인</button>*/}
    </Form.Item>
  );
};
