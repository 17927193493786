import { useState, useEffect } from "react";
import {useGetIsChatbotCourse} from "../../queries/aichat";

export const useIsChatbotCourse = (courseId) => {
  const [isChatbotCourse, setIsChatbotCourse] = useState(null);
  const { data: isChatbotCourseData } = useGetIsChatbotCourse(courseId);

  useEffect(() => {
    setIsChatbotCourse(isChatbotCourseData?.data);
  }, [isChatbotCourseData]);

  return isChatbotCourse;
};
