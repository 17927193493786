import React from "react";
import classNames from "classnames/bind";
import styles from "../HanghaeOrientationFloatingCTA/HanghaeOrientationFloatingCTA.module.scss";
import { connect } from "react-redux";
import { hanghaeOrientationInvitationActions } from "../../v2/_state/actions";
import { history } from "../../_helpers";
import { sendLog } from "../../v2/businesslogics/logging";

const cx = classNames.bind(styles);

function HanghaeOrientationFloatingCTA({
  user,
  postHanghpostHanghaeOrientationInvitation,
  otDate,
}) {
  return (
    <div className={cx("floating")}>
      <div className={cx("floating_context")}>
        <p>
          개발자 취업, 고민 많으셨죠? <br />
          <span>항해99 사전설명회로 초대합니다.</span>
        </p>
        <button
          onClick={() => {
            sendLog("hh_orientation_scc", {
              online_user_id: user._id,
              name: user.name,
              email: user.email,
              phone: user.phone,
            });
            postHanghpostHanghaeOrientationInvitation(
              user.name,
              user.phone,
              user.email,
              otDate
            ).then((res) => {
              if (res.ok) {
                history.push(`/course/rcmd/hanghae`);
              } else {
                alert("항해 사전설명회를 신청하는 데에 문제가 발생했습니다!");
              }
            });
          }}
        >
          사전설명회 신청하기
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedHanghaeOrientationFloatingCTA = connect(mapStateToProps, {
  postHanghpostHanghaeOrientationInvitation:
    hanghaeOrientationInvitationActions.postHanghaeOrientationInvitation,
})(HanghaeOrientationFloatingCTA);
export { connectedHanghaeOrientationFloatingCTA as HanghaeOrientationFloatingCTA };
