import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoundWeekWIL.module.scss";
import { history } from "../../../../_helpers";
import { GOV_DONE_DAILY_LIMIT } from "../../../_helper";
import { sendLog } from "../../../businesslogics/logging";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function RoundWeekWIL({
  week,
  enrolled,
  expired,
  chapter_title,
  enrolled_details,
  wil,
  homework,
  round,
  isSmall,
  enrolledDoneCount,
  isLecture,
}) {
  const all_lecture_done = enrolled_details.reduce((acc, cur, i) => {
    return acc && cur.done;
  }, true);

  const [isActiveWeek, setIsActiveWeek] = useState(false);
  const [isNotAllowedNext, setIsNotAllowedNext] = useState(false);

  useEffect(() => {
    if (!expired.loaded) {
      return;
    }
    setIsActiveWeek(expired.data.achv.week === week);
  }, [expired.loaded]);

  useEffect(() => {
    // enrolled 는 상위 컴포넌트에서 로딩 완료되었음을 전제로,
    // 정부 과목일 경우 9개 이상 수강할 수 없다.
    const {
      rounds: { is_seen_restricted },
    } = enrolled.data;
    if (!enrolledDoneCount.loaded) {
      return;
    }
    // 진도표에서는 GOV_DONE_DAILY_LIMIT + 1, 강의화면에서는 GOV_DONE_DAILY_LIMIT
    // 강의화면에서는 '지금 보고 있는 것'까지 수강하고 있기 때문
    setIsNotAllowedNext(
      is_seen_restricted && enrolledDoneCount.data >= GOV_DONE_DAILY_LIMIT + 1
    );
  }, [enrolledDoneCount.loaded]);

  function goWIL() {
    const { enrolled_id } = enrolled.data;
    if (isNotAllowedNext) {
      alert(`하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1}강까지 들을 수 있어요!`);
      return;
    }
    if (!all_lecture_done) {
      alert("이번 주차의 모든 강의를 수강해주세요!");
    } else if (
      !homework.every((hwk) => {
        return hwk.done;
      })
    ) {
      alert("이번 주차의 숙제를 제출해주세요!");
    } else {
      history.push(`/enrolleds/${enrolled_id}/ewil/${week}`);
    }
  }

  const theme = useOnlineTheme();

  return (
    <div
      onClick={() => {
        sendLog(
          isLecture
            ? "scc_lecturePage_click_list"
            : "scc_roadmapPage_click_list",
          {
            ...enrolled.data,
            title: `${chapter_title} 개발일지 쓰기`,
            button_name: "강의 목차 중 숙제 개발일지 버튼",
          },
          ""
        );
        goWIL();
      }}
      className={cx(
        "round-week-wil",
        theme.data,
        `${isSmall ? "--small-padding" : ""}`
      )}
    >
      <div
        className={cx(
          "round-week-wil__title",
          theme.data,
          `${isSmall ? "--small-width" : ""}`
        )}
      >
        {chapter_title} 개발일지 쓰러가기
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled, expired, enrolledDoneCount } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    expired,
    enrolledDoneCount,
  };
}

const connectedRoundWeekWIL = connect(mapStateToProps, {})(RoundWeekWIL);
export { connectedRoundWeekWIL as RoundWeekWIL };
