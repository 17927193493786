import React, { useEffect, useState } from "react";
import { useOnlineTheme } from "../../queries/pirates";
import styles from "./B2bAdminNPSPage.module.scss";
import classNames from "classnames/bind";
import { LecMenuComponent } from "../LecturePage/LecMenu/LecMenuComponent";
import { lectureMenuComponents } from "../LecturePage/LecMenu/constants";
import { BroadcastContainer } from "../LecturePage/BroadcastContainer";
import { LecNav } from "../LecturePage/LecNav";
import { connect } from "react-redux";
import { enrolledActions } from "../../_state/actions";
import { coursesActions } from "../../_state/actions/courses.actions";
import { useGetB2bAdminNpsQuestions } from "../../queries/b2bAdminNps";
import { B2bAdminNPSQuestion } from "../../components/molecules/B2bAdminNPS/B2bAdminNPSQuestion";
import { MainNPSFooter } from "../B2bNPSPage/B2bNPSPage.style";
import { RcmdQuestion } from "../NPSPage/QuestionBox";
import { B2bAdminNPSNextBtn } from "../../components/atoms/B2bAdminNPS/B2bAdminNPSNextBtn";
import { B2bAdminNPSLongNextBtn } from "../../components/atoms/B2bAdminNPS/B2bAdminNPSLongNextBtn";
import { useIsMobile } from "../../hooks/device";
import { useEnrolled } from "../../hooks/useProductName";

const cx = classNames.bind(styles);

function B2bAdminNPSPage({
  match,
  user,
  roundWeeks,
  getRoundWeeks,
  courseV2,
  getCourse,
}) {
  const { enrolled_id } = match.params;
  const enrolled = useEnrolled(enrolled_id);
  const theme = useOnlineTheme();
  const isMobile = useIsMobile();
  const { data: questions } = useGetB2bAdminNpsQuestions(
    enrolled?.data?.round_id
  );
  const [npsResultData, setNpsResultData] = useState([]);

  useEffect(() => {
    if (questions && questions.length > 0) {
      const newNpsResultData = questions.map((question) => ({
        questionId: question.id,
        onlineUserId: user._id,
        subjectiveAnswer: "",
        objectiveAnswer: "",
        score: null,
      }));
      setNpsResultData(newNpsResultData);
    }
  }, [questions]);

  const handleUpdateScore = (questionId, newScore) => {
    setNpsResultData(
      npsResultData.map((data) =>
        data.questionId === questionId ? { ...data, score: newScore } : data
      )
    );
  };

  const handleSubjectiveAnswer = (questionId, newText) => {
    setNpsResultData(
      npsResultData.map((data) =>
        data.questionId === questionId
          ? { ...data, subjectiveAnswer: newText }
          : data
      )
    );
  };

  useEffect(() => {
    if (!courseV2.loaded && enrolled.loaded) getCourse(enrolled.data.course_id);
  }, [enrolled.loaded]);

  useEffect(() => {
    if (!roundWeeks.loaded) {
      getRoundWeeks(enrolled_id);
    }
  }, [roundWeeks.loaded]);

  return (
    <div>
      <div>
        <div className={cx("nps-page", theme.data, "__mainNPS")}>
          <div className={cx("nps-page__nav")}>
            <div className={cx("nps-page__nav__bg", theme.data)} />
            <LecMenuComponent
              component={lectureMenuComponents[0]}
              idx={0}
              isDual={true}
              isNav={true}
            />
            <BroadcastContainer />
            {/*<LearnTimeContainer />*/}
            <LecNav
              isDual={true}
              title={`강의 설문 제출하기`}
              clickable={false}
            />
            {enrolled.loaded && courseV2.loaded && !isMobile && (
              <B2bAdminNPSNextBtn
                npsResultData={npsResultData}
                enrolled={enrolled}
              />
            )}
          </div>
          <div className={cx("nps-page__mainNPS__background", theme.data)}>
            <div className={cx("nps-page__content", "__mainNPS")}>
              {courseV2.loaded && (
                <div className={cx("satisfaction-container")}>
                  {/* 먼저 'satisfaction' 타입의 질문을 찾아 RcmdQuestion 렌더링 */}
                  {questions
                    ?.filter((question) => question.type === "satisfaction")
                    .map((question, index) => (
                      <RcmdQuestion
                        key={question.id}
                        name={user.name}
                        questionTitle={question.question}
                        questionSubTitle1={`수업에 대한 ${user.name}님의 의견을 들려주세요.\n강의 개선에 큰 도움이 됩니다.😊`}
                        size={11}
                        onChange={(score) =>
                          handleUpdateScore(question.id, score)
                        }
                      />
                    ))}

                  {/* 나머지 질문들을 B2bAdminNPSQuestion 컴포넌트로 렌더링 */}
                  {questions
                    ?.filter((question) => question.type !== "satisfaction")
                    .map((question, index) => (
                      <B2bAdminNPSQuestion
                        type={question.type}
                        key={question.id}
                        questionNumber={index + 1}
                        questionTitle={question.question}
                        size={5}
                        onChangeScore={(score) =>
                          handleUpdateScore(question.id, score)
                        }
                        onChangeText={(text) =>
                          handleSubjectiveAnswer(question.id, text)
                        }
                      />
                    ))}
                </div>
              )}

              {enrolled.loaded && courseV2.loaded && (
                <MainNPSFooter isDesktop={!isMobile} theme={theme.data}>
                  <B2bAdminNPSLongNextBtn
                    npsResultData={npsResultData}
                    enrolled={enrolled}
                  />
                </MainNPSFooter>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    roundWeeks,
    enrolled,
    courseV2,
    prequestionOfEnrolled,
    enrolledDetails,
  } = state;
  const { user } = authentication;
  return {
    user,
    roundWeeks,
    enrolled,
    courseV2,
    prequestionOfEnrolled,
    enrolledDetails,
  };
}

const connectedB2bAdminNPSPage = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getCourse: coursesActions.getCourse,
})(B2bAdminNPSPage);
export { connectedB2bAdminNPSPage as B2bAdminNPSPage };
