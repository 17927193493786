import * as aws from "aws-sdk";

export class S3Service {
  constructor() {
    this.s3 = new aws.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
  }

  getDownloadUrl(key, fileName) {
    return this.s3.getSignedUrl("getObject", {
      Bucket: process.env.REACT_APP_AWS_S3_VOD_BUCKET,
      Key: key,
      Expires: 3600,
      ResponseContentDisposition: `attachment; filename="${fileName}"`,
    });
  }

  downloadS3Service(key, fileName) {
    const downloadUrl = this.getDownloadUrl(key, fileName);
    window.open(downloadUrl);
  }

  downloadHomeworkDetailFile(id, fileExt, filename) {
    try {
      this.downloadS3Service(
        `homework_detail/${id}.${fileExt}`,
        encodeURI(`${filename}.${fileExt}`)
      );
    } catch (e) {
      alert(
        "첨부파일 다운로드에 실패했습니다. 파일을 업로드했는지 확인해주세요"
      );
    }
  }
}
