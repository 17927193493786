import styled from "@emotion/styled";
import { PIRATES } from "../../../../../_consts";
import { wBody1, wTitle2 } from "@teamsparta/design-system";

export const ErrorReportContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorReportTitle = styled.div`
  font-family: "Pretendard";
  text-align: center;

  font-weight: 600;
  font-size: 20px;

  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};

  margin-bottom: 24px;
`;

export const ErrorReportSubTitle = styled.div`
  font-family: "Pretendard";

  font-weight: 600;
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  line-height: 150%;

  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#5f666b";
    }
  }};
  margin-bottom: 12px;
`;
export const ErrorReportInputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px;
  width: ${({ isMobile }) => (isMobile ? "100%" : "484px")};
  height: ${({ isMobile }) => (isMobile ? "56px" : "45px")};
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: 452px center;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};

  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#dbdde0";
      }
    }};
  border-radius: 8px;

  font-family: "Pretendard";
  font-weight: 500;
  font-size: 14px;

  color: ${({ theme, isDefault }) => {
    switch (theme) {
      case PIRATES:
        return isDefault ? "#81898F" : "#EEF3F6";
      default:
        return "#141617";
    }
  }};

  &:focus {
    border: 1px solid
      ${({ theme }) => {
        switch (theme) {
          case PIRATES:
            return "#5F666B";
          default:
            return "#dbdde0";
        }
      }};
  }
`;

export const ErrorReportInput = styled.input`
  box-sizing: border-box;
  display: flex;
  border: none;
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? "30px" : "25px")};
  padding: 0;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ErrorReportTextarea = styled.textarea`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 10px;
  gap: 12px;

  width: 484px;
  height: 90px;

  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "inherit";
    }
  }};
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#d7e0e6";
      }
    }};
  border-radius: 8px;

  resize: none;

  margin-bottom: 24px;

  &::placeholder {
    color: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "#b4bfc6";
      }
    }};
  }
`;

export const ErrorReportSubmitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BackToErrorTypeSelectorButton = styled.div`
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 140px;
  cursor: pointer;
  ${wBody1};

  border-radius: 8px;
  background: var(--gray-gray-30-day, #d7e0e6);
`;

export const ErrorReportSubmitBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 4px;
  width: 328px;
  height: 56px;

  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  border-radius: 8px;

  font-family: "Pretendard";

  font-weight: 600;
  font-size: 16px;

  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#ffffff";
    }
  }};
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
`;

export const ErrorReportSelectBox = styled.select`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding: 12px 10px;
  background-image: url(${({ theme }) => {
    let color;
    switch (theme) {
      case PIRATES:
        color = "white";
        break;
      default:
        color = "%2381898F";
        break;
    }
    return `"data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.61872 8.21331C5.27701 7.8716 4.72299 7.8716 4.38128 8.21331C4.03957 8.55502 4.03957 9.10904 4.38128 9.45075L5.61872 8.21331ZM10 13.832L9.38128 14.4507C9.72299 14.7925 10.277 14.7925 10.6187 14.4507L10 13.832ZM15.6187 9.45075C15.9604 9.10904 15.9604 8.55502 15.6187 8.21331C15.277 7.8716 14.723 7.8716 14.3813 8.21331L15.6187 9.45075ZM4.38128 9.45075L9.38128 14.4507L10.6187 13.2133L5.61872 8.21331L4.38128 9.45075ZM10.6187 14.4507L15.6187 9.45075L14.3813 8.21331L9.38128 13.2133L10.6187 14.4507Z' fill='${color}'/%3E%3C/svg%3E%0A"`;
  }});
  width: 484px;
  height: 45px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: 452px center;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};

  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#dbdde0";
      }
    }};
  border-radius: 8px;

  font-family: "Pretendard";
  font-weight: 500;
  font-size: 14px;

  color: ${({ theme, isDefault }) => {
    switch (theme) {
      case PIRATES:
        return isDefault ? "#81898F" : "#EEF3F6";
      default:
        return isDefault ? "#a9a9a9" : "#141617";
    }
  }};

  &:focus {
    border: 1px solid
      ${({ theme }) => {
        switch (theme) {
          case PIRATES:
            return "#5F666B";
          default:
            return "#dbdde0";
        }
      }};
  }
`;
