import styled from "@emotion/styled";
import {
  additional,
  mBody1,
  mBody2,
  mBody3,
  mHeader2,
  neutralDay,
  wBody1,
  wBody2,
  wBody3,
  wTitle1,
  wTitle4,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../_styles/device";

export const RecommendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${neutralDay.white};
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  width: 460px;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 28px 20px;
    border-radius: 16px 16px 0 0;
    gap: 24px;
  }
`;

export const RecommendTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  ${DisplayResolution.MobileAndMiniTablet} {
    gap: 6px;
  }
`;

export const RecommendSubtitle = styled.div`
  ${wTitle4};
  line-height: 29px;
  color: ${neutralDay.gray70};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    line-height: 22px;
    color: ${neutralDay.gray70};
  }
`;

export const RecommendTitle = styled.div`
  ${wTitle1};
  line-height: 34px;
  color: ${neutralDay.gray100};
  white-space: pre-wrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    line-height: 28px;
    color: ${neutralDay.gray100};
  }
`;

export const RecommendContent = styled.img`
  width: 100%;
`;

export const RecommendCtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const RecommendBadCta = styled.div`
  ${wBody1};
  line-height: 56px;
  color: ${neutralDay.gray90};
  cursor: pointer;
  width: 192px;
  height: 56px;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  text-align: center;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${wBody1};
    line-height: 56px;
    color: ${neutralDay.gray90};
    align-self: stretch;
  }
`;

export const RecommendGoodCta = styled(RecommendBadCta)`
  background: ${neutralDay.gray100};
  color: ${neutralDay.white};
  border: none;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${wBody1};
    line-height: 56px;
    color: ${neutralDay.white};
  }
`;

export const NTWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${neutralDay.white};
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  width: 460px;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 28px 20px;
    border-radius: 16px 16px 0 0;
    gap: 24px;
  }
`;

export const NTTitle = styled.div`
  ${wTitle1};
  line-height: 34px;
  color: ${neutralDay.gray100};
  white-space: pre-wrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    line-height: 28px;
    color: ${neutralDay.gray100};
  }
`;

export const NTOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${DisplayResolution.MobileAndMiniTablet} {
  }
`;

export const NTOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 14px 8px;
  background-color: ${(props) =>
    props.isActive ? neutralDay.gray0 : neutralDay.white};
  align-self: stretch;
  border-radius: 8px;
  ${DisplayResolution.MobileAndMiniTablet} {
  }
`;

export const NTOptionText = styled.div`
  ${wBody2};
  line-height: 26px;
  color: ${neutralDay.gray80};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    line-height: 22px;
    color: ${neutralDay.gray80};
  }
`;

export const NTCta = styled.div`
  ${wTitle4};
  line-height: 56px;
  color: ${neutralDay.white};
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  height: 56px;
  background-color: ${neutralDay.gray100};
  text-align: center;
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    line-height: 56px;
    color: ${neutralDay.white};
  }
`;

export const GoodbyeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${neutralDay.white};
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  width: 460px;
  position: relative;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 28px 20px;
    border-radius: 16px 16px 0 0;
    gap: 24px;
  }
`;

export const GoodbyeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  ${DisplayResolution.MobileAndMiniTablet} {
    gap: 6px;
  }
`;

export const GoodbyeTitle = styled.div`
  ${wTitle1};
  line-height: 34px;
  color: ${neutralDay.gray100};
  white-space: pre-wrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    line-height: 28px;
    color: ${neutralDay.gray100};
  }
`;

export const GoodbyeSubtitle = styled.div`
  ${wBody2};
  line-height: 26px;
  color: ${neutralDay.gray80};
  white-space: pre-wrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    line-height: 22px;
    color: ${neutralDay.gray80};
  }
`;

export const GoodbyeImage = styled.img`
  width: 100%;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  align-self: stretch;
  padding: 10px 12px;
  background-color: ${additional.yellow10};
  border-radius: 8px;
  border: 1px solid ${additional.orange100};
  cursor: pointer;
  margin-bottom: 50px;
  margin-top: -20px;
  ${DisplayResolution.MobileAndMiniTablet} {
    gap: 8px;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 38px;
    margin-top: -30px;
  }
`;

export const BannerText = styled.div`
  ${wBody3};
  line-height: 22px;
  color: ${neutralDay.gray80};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody3};
    line-height: 21px;
    color: ${neutralDay.gray80};
  }
`;
