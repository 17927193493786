export const CODE_SNIPPETS = {
  CODE_SNIPPETS_REQUEST: "CODE_SNIPPETS_REQUEST",
  CODE_SNIPPETS_SUCCESS: "CODE_SNIPPETS_SUCCESS",
  CODE_SNIPPETS_FAILURE: "CODE_SNIPPETS_FAILURE",
};

export const MATERIALS = {
  MATERIALS_REQUEST: "MATERIALS_REQUEST",
  MATERIALS_SUCCESS: "MATERIALS_SUCCESS",
  MATERIALS_FAILURE: "MATERIALS_FAILURE",
};

export const LECTURE_V2 = {
  LECTURE_V2_REQUEST: "LECTURE_V2_REQUEST",
  LECTURE_V2_SUCCESS: "LECTURE_V2_SUCCESS",
  LECTURE_V2_FAILURE: "LECTURE_V2_FAILURE",
};

export const COMMON_LECTURE = {
  COMMON_LECTURE_REQUEST: "COMMON_LECTURE_REQUEST",
  COMMON_LECTURE_SUCCESS: "COMMON_LECTURE_SUCCESS",
  COMMON_LECTURE_FAILURE: "COMMON_LECTURE_FAILURE",
};
