import { PIRATES } from "../../../../_consts";

export const ClockSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#B4BFC6" : "black";
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.325 0 0.5 3.825 0.5 8.5C0.5 13.175 4.325 17 9 17C13.675 17 17.5 13.175 17.5 8.5C17.5 3.825 13.675 0 9 0ZM12.57 12.07L8.15 9.35V4.25H9.425V8.67L13.25 10.965L12.57 12.07Z"
        fill={color}
      />
    </svg>
  );
};

export const DueDateSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#B4BFC6" : "black";
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7 10.7368H9V7.15789H10.7V10.7368ZM10.7 14.3158H9V12.5263H10.7V14.3158ZM0.5 17H19.2L9.85 0L0.5 17Z"
        fill={color}
      />
    </svg>
  );
};

export const OutlineSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "black";
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6619 0.0589662L7.65974 -0.00012207L9.18364 1.46233L4.75047 5.71674L5.7664 6.69171L10.1996 2.4373L11.7235 3.89975L11.6619 0.0589662ZM9.24184 9.73461V5.32582L10.5604 4.06041V10C10.5604 10.5523 10.1127 11 9.56041 11H1.20019C0.64791 11 0.200195 10.5523 0.200195 10V2.05755C0.200195 1.50526 0.647911 1.05755 1.2002 1.05755H7.27391L5.95534 2.32295H1.51877V9.73461H9.24184Z"
        fill={color}
      />
    </svg>
  );
};

export const ToggleSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "black";
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77172 0.174561L7.05603 5.51585L12.3403 0.174563L13.9636 1.82699L7.05603 8.80906L0.148437 1.82698L1.77172 0.174561Z"
        fill={color}
      />
    </svg>
  );
};
