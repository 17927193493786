import { spalandService } from "../services";
import { SPALAND_DATE, SPALAND_DATE_ATTENDANCE } from "../constants";

function getSpalandDates() {
  function request() {
    return { type: SPALAND_DATE.SPALAND_DATE_REQUEST };
  }

  function success(response) {
    return { type: SPALAND_DATE.SPALAND_DATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: SPALAND_DATE.SPALAND_DATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    spalandService.getSpalandDates().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getSpalandDatesByEnrolled(enrolled_id) {
  function request() {
    return { type: SPALAND_DATE.SPALAND_DATE_REQUEST };
  }

  function success(response) {
    return { type: SPALAND_DATE.SPALAND_DATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: SPALAND_DATE.SPALAND_DATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    spalandService.getSpalandDatesByEnrolled(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postSpalandDateAttendance() {
  function request() {
    return { type: SPALAND_DATE_ATTENDANCE.SPALAND_DATE_ATTENDANCE_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return spalandService.postSpalandDateAttendance();
  };
}

export const spalandActions = {
  getSpalandDatesByEnrolled,
  getSpalandDates,
  postSpalandDateAttendance,
};
