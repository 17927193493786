import * as S from "./HomeworkHelpButton.style";
import {
  ArrowChevronRight,
  ContentsNote,
  ContentsPlayCircle,
  InfoHelp,
  neutralDay,
} from "@teamsparta/design-system";
import React from "react";
import { useLogHomeworkClick } from "../../../../hooks/homework/useLogHomeworkClick";

export const HomeworkHelpButton = ({ type, href }) => {
  const logHomeworkClickButton = useLogHomeworkClick();
  let buttonText, subtitleText, Icon;
  switch (type) {
    case "video":
      buttonText = "숙제 해설 영상 바로가기";
      subtitleText = "숙제 풀이가 어렵다면?";
      Icon = ContentsPlayCircle;
      break;
    case "material":
      buttonText = "강의 자료 바로가기";
      subtitleText = "강의 내용이 기억나지 않는다면?";
      Icon = ContentsNote;
      break;
    case "evaluation":
      buttonText = "튜터에게 물어보세요";
      subtitleText = "해설을 봐도 모르겠다면?";
      Icon = InfoHelp;
      break;
    default:
      buttonText = "강의 자료 바로가기";
      subtitleText = "강의 내용이 기억나지 않는다면?";
      Icon = ContentsNote;
  }

  return (
    <S.HomeworkHelpButton
      href={href}
      target="_blank"
      onClick={() => logHomeworkClickButton(buttonText, href)}
    >
      <S.IconAndTitleWrapper>
        <S.IconContainer>
          <Icon size={24} style="line" color={`${neutralDay.gray80}`} />
        </S.IconContainer>
        <S.TitleWrapper>
          <S.SubTitle>{subtitleText}</S.SubTitle>
          <S.Title>{buttonText}</S.Title>
        </S.TitleWrapper>
      </S.IconAndTitleWrapper>
      <S.ConnectionIconContainer>
        <ArrowChevronRight size={24} color={`${neutralDay.gray90}`} />
      </S.ConnectionIconContainer>
    </S.HomeworkHelpButton>
  );
};
