import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as S from "../FreeFinishPage/Recommend/recommend.style";
import { Nbc_promote } from "../FreeFinishPage/Recommend/Contents";
function CourseRcmdNBcamp({ user, course }) {
  // const [isMobile, setIsMobile] = useState(false);
  //
  // useEffect(() => {
  //   if (window.checkPlatform() === "mobile") {
  //     setIsMobile(true);
  //   }
  // }, []);
  return (
    <S.Container>
      <S.Title>
        개발자로 취업하고 싶다면 <br />
        내일배움캠프는 어떠세요?
      </S.Title>
      <S.Contents><Nbc_promote/></S.Contents>
      <S.MoreContainer>
        마음에 드는 강의가 없으신가요?
        <S.MoreButton
          onClick={() => {
            window.open(`${process.env.REACT_APP_SCC_URL}/catalog`);
          }}
        >
          더 많은 강의 보러 가기
        </S.MoreButton>
      </S.MoreContainer>
    </S.Container>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdNBcamp = connect(
  mapStateToProps,
  {}
)(CourseRcmdNBcamp);
export { connectedCourseRcmdNBcamp as CourseRcmdNBcampVer2 };
