import styled from "@emotion/styled";
import { Device } from "../../../../_styles/device";
import {
  mBody3,
  mHeader1,
  neutralDay,
  wBody2,
  wBody3,
  wHeader2,
} from "@teamsparta/design-system";
import { fontPretendard } from "../../../styles/font";

export const Container = styled.div`
  padding: 60px 16px;

  ${Device.Desktop} {
    padding: 100px 0;
    width: 796px;
    margin: auto;
  }
`;

export const Title = styled.div`
  ${mHeader1};
  color: ${neutralDay.gray100};
  margin-bottom: 40px;
  white-space: pre-wrap;
  ${Device.Desktop} {
    ${wHeader2};
    color: ${neutralDay.gray100};
    margin-bottom: 80px;
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${Device.Desktop} {
    gap: 80px;
  }
`;

export const Section = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  ${Device.Desktop} {
    display: flex;
    gap: 30px;
    flex-direction: ${({ isDesktopColumn }) =>
      isDesktopColumn ? "column" : "row"};
  }
`;

export const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  ${mBody3};
  color: ${neutralDay.gray70};
  ${Device.Desktop} {
    margin-top: 120px;
    ${wBody3};
    color: ${neutralDay.gray70};
  }
`;

export const MoreButton = styled.div`
  cursor: pointer;
  font-family: ${fontPretendard};
  font-weight: 600;
  font-size: 12px;
  padding: 0 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${neutralDay.gray100};
  width: fit-content;
  margin-top: 12px;
  background: ${neutralDay.gray10};
  border-radius: 4px;
  height: 32px;
  ${Device.Desktop} {
    margin-top: 16px;
    font-size: 13px;
    padding: 0 12px;
    height: 36px;
  }
`;

export const CourseDesc = styled.div`
  p {
    margin: 0;
    color: ${neutralDay.gray70};
    display: none;
  }
  ${Device.Desktop} {
    p {
      ${wBody2};
      color: ${neutralDay.gray70};
      margin-top: 12px;
      display: block;
    }
  }
`;
