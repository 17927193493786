import * as S from "../../../HomeworkV3/HomeworkGnb/Mobile/HomeworkGnbMobile.style";
import {
  ActionMessage,
  ArrowChevronLeft,
  neutralDay,
} from "@teamsparta/design-system";
import { LectureMobileFloating } from "../../../../../pages/LecturePageV3/LectureMobileFloating/LectureMobileFloating";
import React from "react";
import { useParams } from "react-router-dom";
import { useEnrolled } from "../../../../../hooks/useProductName";
import { useNavigateToRoadmap } from "../../../../../hooks/useNavigateToRoadmap";
import {
  useNavigateToNextContent,
  useNextContentCtaText,
  useSyllabusUnit,
} from "../../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useAtom } from "jotai";
import {
  enrolledEvaluationDetailsAtom,
  isLoadingEvaluationPageAtom,
} from "../../../../../stores/enrolledEvaluationDetails";
import { usePostMyEvaluations } from "../../../../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { PAGE_TYPE } from "../../../../../_state/constants/evaluation.constants";
import { EvaluationMobileFloating } from "../../../../atoms/Evaluation/EvaluationMobileFloating";
import { useCheckAllAnswersSubmitted } from "../../../../../hooks/evaluation/useEvaluationData";

export const EvaluationGnbMobile = ({
  qnaLink,
  week,
  pageType,
  setIsLoading,
}) => {
  const navigateToRoadmap = useNavigateToRoadmap();
  const navigateToNext = useNavigateToNextContent();
  const { enrolled_id, enrolled_evaluation_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const { data: enrolledData } = enrolled;
  const [enrolledEvaluationDetails] = useAtom(enrolledEvaluationDetailsAtom);
  const postMyEvaluations = usePostMyEvaluations(
    enrolled_evaluation_id,
    enrolled_id
  );
  const ctaText = useNextContentCtaText(
    false,
    true,
    pageType === PAGE_TYPE.RESULT_PAGE ? "다음으로" : "채점하기"
  );

  const areAllAnswersSubmitted = useCheckAllAnswersSubmitted(
    enrolledEvaluationDetails
  );

  const handleSubmitEvaluation = () => {
    if (pageType === PAGE_TYPE.RESULT_PAGE) {
      navigateToNext();
    } else {
      setIsLoading(true);
      setTimeout(() => {
        postMyEvaluations.mutate(enrolledEvaluationDetails);
        setIsLoading(false);
      }, 2000);
    }
  };
  return (
    <>
      <S.GnbMobileWrapper>
        <S.IconContainer
          onClick={() => {
            navigateToRoadmap();
          }}
        >
          <ArrowChevronLeft size={24} color={neutralDay.gray90} />
        </S.IconContainer>
        <S.TitleWrapper>
          <S.Title>{enrolledData?.courses?.title}</S.Title>
          <S.SubTitle>{week}주차</S.SubTitle>
        </S.TitleWrapper>
        <S.IconContainer
          onClick={() => {
            window.open(qnaLink);
          }}
        >
          <ActionMessage
            size={24}
            style={"line"}
            color={`${neutralDay.gray100}`}
          />
        </S.IconContainer>
      </S.GnbMobileWrapper>
      <EvaluationMobileFloating
        handleClickCta={handleSubmitEvaluation}
        isActive={areAllAnswersSubmitted || pageType === PAGE_TYPE.RESULT_PAGE}
        ctaText={ctaText}
      />
    </>
  );
};
