import React from "react";
import { TimeTooltip } from "./CurrentTimeTooltip.style";
import { formatTime } from "../../hooks/utils";

export const CurrentTimeTooltip = ({
  currentTime,
  duration,
  progressBarRef,
}) => {
  const calculateTooltipPosition = () => {
    if (!progressBarRef.current) return {};

    const progressBar = progressBarRef.current;
    const percentage = (currentTime / duration) * 100;
    const left = (progressBar.offsetWidth * percentage) / 100;
    return { left: `${left}px` };
  };

  const tooltipStyle = calculateTooltipPosition();

  return (
    <TimeTooltip style={tooltipStyle}>{formatTime(currentTime)}</TimeTooltip>
  );
};
