import privateInstanceAi from "../../_helper/axiosAi";
import { config } from "../../../config";
import { useQuery } from "react-query";
import React from "react";

// 지금 수강 중인 강의가 Chatbot을 제공하는지 조회
export const getIsChatbotCourse = async (course_id) => {
  const response = await privateInstanceAi.get(
    `/ai/provideAiChat?course_id=${course_id}`
  );
  return response.data;
};

export const useGetIsChatbotCourse = (course_id) => {
  return useQuery(
    ["chatbotCourse", course_id],
    async () => {
      return await getIsChatbotCourse(course_id);
    },
    {
      enabled: !!course_id,
    }
  );
};

// 저장된 채팅 메세지 가져오기
export const getAiMessage = async (enrolled_id) => {
  const response = await privateInstanceAi.get(
    `/ai/chats?enrolled_id=${enrolled_id}`
  );
  return response.data;
};

export const useGetAiMessage = (enrolled_id) => {
  return useQuery(
    ["ai_message", enrolled_id],
    () => {
      return getAiMessage(enrolled_id);
    },
    {
      enabled: !!enrolled_id,
    }
  );
};

export const getAiChatEventSource = (
  message,
  enrolled_id,
  detail_id,
  enrolleds_homework_id,
  handleReciveEvent,
  handleCloseEvent
) => {
  const encodeMessage = encodeURIComponent(message);
  const newEventSource = new EventSource(
    `${config.aiApiUrl}` +
      `/ai/chat/stream?message=${encodeMessage}&enrolled_id=${enrolled_id}&enrolleds_detail_id=${detail_id}&enrolleds_homework_id=${enrolleds_homework_id}`
  );

  let reponse = "";
  // 서버에서 이벤트가 도착할 때마다 실행됩니다.
  newEventSource.onmessage = (event) => {
    reponse = reponse + event.data;
    handleReciveEvent(reponse);
  };

  // 이벤트 연결이 끝나거나 에러가 발생하면 실행합니다.
  newEventSource.onerror = (event) => {
    handleCloseEvent();
    newEventSource.close();
  };
  return newEventSource;
};

export const getAiQuizEventSource = (
  enrolled_id,
  detail_id,
  enrolledHomeworkId,
  handleReciveEvent,
  handleCloseEvent
) => {
  const newEventSource = new EventSource(
    `${config.aiApiUrl}/ai/quiz/stream?&enrolled_id=${enrolled_id}&enrolleds_detail_id=${detail_id}&enrolleds_homework_id=${enrolledHomeworkId}`
  );

  let reponse = "";
  // 서버에서 이벤트가 도착할 때마다 실 퀴즈에 대해서 아래 처럼 대답해줘행됩니다.
  newEventSource.onmessage = (event) => {
    reponse = reponse + event.data;
    handleReciveEvent(reponse);
  };

  // 이벤트 연결이 끝나거나 에러가 발생하면 실행합니다.
  newEventSource.onerror = (event) => {
    handleCloseEvent();
    newEventSource.close();
  };

  return newEventSource;
};
export const getAiQuizAnswerEventSource = (
  quiz,
  answer,
  enrolled_id,
  enrolled_detail_id,
  handleReciveEvent,
  handleCloseEvent
) => {
  const newEventSource = new EventSource(
    `${config.aiApiUrl}/ai/quiz/answer/stream?&quiz=${quiz}&answer=${answer}&enrolled_id=${enrolled_id}&enrolled_detail_id=${enrolled_detail_id}`
  );

  let reponse = "";
  // 서버에서 이벤트가 도착할 때마다 실 퀴즈에 대해서 아래 처럼 대답해줘행됩니다.
  newEventSource.onmessage = (event) => {
    reponse = reponse + event.data;
    handleReciveEvent(reponse);
  };

  // 이벤트 연결이 끝나거나 에러가 발생하면 실행합니다.
  newEventSource.onerror = (event) => {
    handleCloseEvent();
    newEventSource.close();
  };

  return newEventSource;
};

export const waitFlag = (flag, second) => {
  return new Promise((resolve) => {
    const checkInterval = setInterval(() => {
      if (flag) {
        clearInterval(checkInterval);
        resolve();
      }
    }, second);
  });
};
