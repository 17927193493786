import styled from "@emotion/styled";
// import { device } from "../../_styles/device.js";
export const Background = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 36px;
  border-radius: 100px;
  background-color: #f1f3f5;
  position: relative;
`;
export const Text = styled.div`
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.96px;
  text-align: left;
  color: black;
`;
// 강조 span 스타일
export const Bold = styled.span`
  margin: 0 5.7px 0 0;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1.68px;
  text-align: center;
  color: black;
`;

export const ProfileIconContainer = styled.div`
  position: absolute;
  top: -18px;
  right: 0;
  background-color: white;
  border-radius: 100px;
`;
export const ProfileIcon = styled.img`
  margin-left: -5px;
  width: 32px;
  height: 32px;
`;
