import styled from "styled-components";

export const Section = styled.section`
  font-family: "Pretendard";
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 16px;
  @media (min-width: 499px) {
    padding: 120px 0 140px;
  }
`;

export const Header = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
  @media (min-width: 499px) {
    gap: 24px;
    margin-bottom: 100px;
  }
`;

export const Title = styled.div`
  width: fit-content;
  font-weight: 700;
  line-height: 150%;
  color: #141617;
  font-size: 24px;
  white-space: pre-line;
  @media (min-width: 499px) {
    font-size: 40px;
    white-space: nowrap;
  }
`;

export const TitleDescription = styled.div`
  width: fit-content;
  font-weight: 400;
  line-height: 150%;
  color: #81898f;
  font-size: 14px;
  white-space: pre-wrap;
  @media (min-width: 499px) {
    font-size: 18px;
    white-space: nowrap;
  }
`;

export const HeroDiv = styled.div`
  font-family: "Pretendard";
  max-width: 100vw;
  padding: 60px 16px;
  display: flex;
  justify-content: center;
  color: black;
  @media (min-width: 499px) {
    padding: 60px 0;
  }
`;

export const HeroContainer = styled.div`
  width: 100%;
  max-width: 1230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 499px) {
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1230px;
  }
`;

export const HeroTextDiv = styled.div`
  margin: auto 0;
  text-align: center;
  @media (min-width: 499px) {
    margin-bottom: 40px;
    display: inline-block;
    width: 580px;
    margin-left: 20px;
    text-align: left;
  }
`;

export const MediumHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-top: 8px;
  color: #81898f;
  margin-bottom: 40px;
  @media (min-width: 499px) {
    margin-top: 16px;
    font-size: 18px;
    margin-bottom: 0;
  }
`;

export const LargeHeader = styled.div`
  font-family: "Pretendard";
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;

  @media (min-width: 499px) {
    font-size: 40px;
    word-break: break-word;
    overflow-wrap: break-word;
  }
`;

export const ButtonDiv = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 499px) {
    flex-direction: row;
    margin-top: 82px;
  }
`;

export const B2bButton = styled.a`
  border-radius: 8px;
  border: solid 2px #e8344e;
  background-color: #e8344e;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #f2f2f2;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 16px;
  margin-top: 80px;
  @media (min-width: 499px) {
    width: 200px;
    margin-top: 0;
    font-size: 16px;
  }
`;

export const HeroVideoWrapper = styled.div`
  width: 100%;
  height: calc((100vw - 16px) * 220 / 328);
  @media (min-width: 499px) {
    width: 570px;
    height: 385px;
  }
`;
export const HeroVideo = styled.video`
  width: 100%;
  height: 100%;
`;

export const Banner = styled.div`
  background: #141617;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-bottom: 60px;
  @media (min-width: 499px) {
    flex-direction: row;
    justify-content: center;
    padding-bottom: 0;
  }
`;

export const BannerThumbnail = styled.img`
  width: 100vw;
  height: 72vw;
  margin-top: -2px;
  @media (min-width: 499px) {
    width: 325px;
    height: 182px;
  }
`;

export const BannerText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 499px) {
    text-align: left;
  }
`;

export const BannerSubTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #d7e0e6;
  @media (min-width: 499px) {
  }
`;

export const BannerTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  color: #ffffff;
  @media (min-width: 499px) {
    br {
      display: none;
    }
  }
`;

export const LogosContainer = styled.div`
  margin-bottom: 48px;
  @media (min-width: 499px) {
    width: 996px;
    margin: 0 auto 140px;
  }
`;

export const Logos = styled.img`
  width: 100vw;
  height: 47vw;
  @media (min-width: 499px) {
    width: 996px;
    height: 455px;
  }
`;

export const LogosRow = styled.div`
  display: flex;
`;

export const Reviews = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 60px;
  @media (min-width: 499px) {
    flex-direction: row;
    width: fit-content;
    height: 434px;
  }
`;

export const Review = styled.div`
  width: 100%;
  height: fit-content;
  padding: 32px 20px 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 18.4615px 46.1539px rgba(0, 0, 0, 0.08);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 28px;
  @media (min-width: 499px) {
    padding: 52px 40px 40px;
    width: 588px;
    height: 100%;
    justify-content: space-between;
  }
`;

export const ReviewUpperRow = styled.div``;

export const ReviewTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0 12px;
  white-space: pre-line;
  @media (min-width: 499px) {
    white-space: nowrap;
    font-size: 24px;
    margin: 16px 0;
  }
`;

export const ReviewContents = styled.div`
  color: #81898f;
  font-weight: 400;
  font-size: 14px;
  @media (min-width: 499px) {
    font-size: 18px;
  }
`;

export const ReviewAuthor = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const ReviewLogo = styled.img`
  width: 48px;
  height: 48px;
  @media (min-width: 499px) {
    width: 58px;
    height: 58px;
  }
`;

export const ReviewBio = styled.div`
  font-weight: 400;
  color: #81898f;
  font-size: 12px;
  @media (min-width: 499px) {
    font-size: 16px;
  }
`;

export const Wrapper = styled.div`
  bottom: 0;
  position: fixed;
  transition: 0.4s ease;
  padding: 10px 30px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -4px 20px 0 rgb(0 0 0 / 8%);
`;

export const FloagingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 499px) {
    max-width: 1230px;
    margin: auto;
  }
`;

export const FloatingDivText = styled.div`
  @media (min-width: 499px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #838689;
  }
`;

export const FloatingDivTextSpan = styled.span`
  color: #1c1d1e;
  font-weight: 600;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  @media (min-width: 499px) {
    font-size: 16px;
    margin-bottom: 2px;
    white-space: unset;
    text-align: unset;
  }
`;

export const FloatingDivIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const FloatingBtnContainer = styled.div``;

export const FloatingBtn = styled.a`
  width: 160px;
  height: 56px;
  cursor: pointer;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #e8344e;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 499px) {
    width: 320px;
    height: 60px;
    padding: 20px 0;
  }
`;
