import { useAllCategoriesV2 } from "../../queries/catalog/hooks";

export const useDropDownList = () => {
  const allCategoriesV2 = useAllCategoriesV2();

  return allCategoriesV2.data?.map((category) => {
    return {
      key: `catalog${category.title}`,
      name: category.title,
      href: `/catalog?selectedKeyword=${category?.title}`,
      brand: category.title,
      isSub: true,
    };
  });
};
