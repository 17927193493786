import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Message.module.scss";

const cx = classNames.bind(styles);

function Message({ type, content, start, duration }) {
  /**
   * type: (str) info / success/ warning
   * content: (str)
   * start: (int) show after start seconds
   * duration: (int) hide after duration seconds
   */
  const [isVisible, setIsVisible] = useState(false);
  const [msgType, setMsgType] = useState("info");

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, start * 1000);

    setTimeout(() => {
      setIsVisible(false);
    }, (start + duration) * 1000);

    const requiredType = type.toLowerCase();
    if (requiredType === "info") {
      setMsgType("info");
    } else if (requiredType === "warning") {
      setMsgType("warning");
    } else if (requiredType === "success") {
      setMsgType("success");
    }
  }, []);

  return (
    <div className={cx("bg")}>
      <div
        className={cx(
          "message",
          `--${msgType}`,
          `${isVisible ? "--visible" : "--hidden"}`
        )}
      >
        <span className={cx("message__span")}>{content}</span>
      </div>
    </div>
  );
}

export { Message };
