import privateInstance from "../../_helper/axios";

async function getRound(round_id) {
  return await privateInstance
    .get(`/rounds/${round_id}`)
    .then((resp) => resp.data);
}

async function getRoundTest(roundId) {
  const res = await privateInstance.get(`rounds/${roundId}/test`);
  return res.data?.context;
}

export const roundsService = {
  getRound,
  getRoundTest,
};
