import React, {useEffect, useRef, useState} from "react";
import { useSelector} from "react-redux";
import styled from "styled-components";
import {config} from "../../../config";
import {useDailyRecord} from "../../hooks/nbcamp/useGovDailyRecord";
import {getCookie} from "../../_helper";


const FileDownload = ({actionPath, queryParams, onDownloadCompleted}) => {
    const formRef = useRef(null)
    const getFormInputs = () => {
        if (queryParams === undefined) {
            return null;
        }

        return Object.keys(queryParams).map((name, index) => {
            return (
                <input
                    key={index}
                    name={name}
                    style={{display: "none"}}
                    value={queryParams[name]}
                />
            );
        });
    }

    useEffect(() => {
        formRef.current.submit()
        onDownloadCompleted()
    }, [])

    return <form
        ref={formRef}
        action={actionPath}
        style={{display: "none"}}
        method={"get"}
    >
        {getFormInputs()}
    </form>
}


export const useDevice = () => {
    return useSelector((state) => state.environment.deviceType)
}

const useUser = () => {
    return useSelector(state => state.authentication.user);
}

const UserTable = ({table}) => {
    const widths = [null, null, null, null, "44px", "44px", "44px"]
    return <Table>
        <tr height={"90px"}>
            {table.columns.map((column, idx) => <TableHeader className={"low-column"}>{column}</TableHeader>)}
        </tr>
        {table.rows.map(row => (
                <tr>
                    {row.map((item, idx) => (
                        <TableData width={widths[idx]}>
                            {item}
                        </TableData>
                    ))}
                </tr>
            )
        )}
    </Table>
}

// startDate와 endDate의 달은 반드시 일치한다.
const MonthCalendar = ({startDate, endDate, attendRecords, setDetail}) => {
    const monthlyRecords = attendRecords
        .filter(
            record => +record.date.split("T")[0].split("-")[1] === startDate.getMonth() + 1
        );

    const dailyRecordsMap = monthlyRecords
        .reduce(
            (before, record) => (
                {...before, [+record.date.split("T")[0].split("-")[2]]: record}
            ), {}
        )

    const days = [];
    const dateCursor = new Date(startDate.getTime());
    while (dateCursor <= endDate) {
        days.push(
            {
                day: dateCursor.getDay(),
                date: dateCursor.getDate(),
            }
        )
        dateCursor.setDate(dateCursor.getDate() + 1);
    }

    const wasHoliday = record => record?.was_attended === "휴일";
    const koreanDay = ["일", "월", "화", "수", "목", "금", "토"]
    const symbolMap = {"출석": "○", "출석인정": "○", "결석": "X", "미달": "△", "공가": "□", "휴일": " "}
    const month = startDate.getMonth() + 1;
    return <Table>
        <tr>
            <TableHeader colSpan={days.length} scope="colgroup">{month}월</TableHeader>
        </tr>
        <tr>
            {days.map(day => <TableHeader wasHoliday={wasHoliday(dailyRecordsMap[+day.date])}
                                          scope="col">{day.date}</TableHeader>)}
        </tr>
        <tr>
            {days.map(day => <TableHeader wasHoliday={wasHoliday(dailyRecordsMap[+day.date])}
                                          scope="col">{koreanDay[day.day]}</TableHeader>)}
        </tr>
        <tr>
            {days.map(day => <TableData scope="col" onMouseEnter={() => {
                setDetail(dailyRecordsMap[+day.date])
            }
            }>
                {symbolMap[dailyRecordsMap[+day.date]?.was_attended]}
            </TableData>)}
        </tr>
    </Table>
}


const DateInput = ({text, inputX, setX}) => {
    return <DateInputWrapper>
        <label htmlFor="startDate">{text}</label>
        <input type="date" defaultValue={"asdf"} value={inputX} onInput={e => setX(e.target.value)}/>
    </DateInputWrapper>
}

const FileDownBtn = ({actionPath, queryParams}) => {
    const [down, setDown] = useState(false);
    return (
        <DownloadBtn onClick={() => setDown(true)}>
            다운로드
            {down && <FileDownload queryParams={queryParams} actionPath={actionPath}
                                   onDownloadCompleted={() => setDown(false)}/>}
        </DownloadBtn>
    )
}

export function NBCampAttendPage({}) {
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const [input, setInput] = useState(oneMonthAgo.toISOString().split("T")[0]);
    const [input2, setInput2] = useState(now.toISOString().split("T")[0]);
    const [recordDetail, setRecordDetail] = useState({})
    const [calendar, setCalendar] = useState([])
    const device = useDevice();
    const user = useUser();
    const userId = user?._id || getCookie('user_id');
    let [govDailyRecord, setDailyRecord] = useDailyRecord(userId);

    useEffect(() => {
        if (userId) {
            setDailyRecord(input, input2)
        }
    }, [userId]);

    useEffect(() => {
        refresh();
    }, [])

    if (device !== "pc") {
        alert("PC에서만 접근 가능합니다.");
        window.location.href = "/classroom";
        return;
    }


    const refresh = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!input || !input2) {
            alert("날짜를 입력해주세요")
            return;
        }
        const inputDate = input.split("-");
        const inputDate2 = input2.split("-");
        const startDate = new Date(+inputDate[0], inputDate[1] - 1, +inputDate[2]);
        const endDate = new Date(+inputDate2[0], inputDate2[1] - 1, +inputDate2[2]);

        if (startDate.getTime() > endDate.getTime()) {
            alert("종료일은 시작일 이후여야합니다.")
            return;
        }

        if (((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) > 31) {
            alert("검색 가능 구간은 최대 31일입니다.")
            return;
        }

        if (startDate.getMonth() === endDate.getMonth()) {
            setCalendar([[startDate, endDate]]);
            setDailyRecord(input, input2);
            return;
        }

        const seperatingDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        const seperatingDayR = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        setRecordDetail({})
        setCalendar([[startDate, seperatingDay], [seperatingDayR, endDate]]);
        setDailyRecord(input, input2);
    }

    if (!govDailyRecord) {
        return <></>
    }

    const table = {
        columns: ["성명", "주민등록번호", "훈련생 유형", "훈련생 상태", "훈련일수", "출석일수", "결석일수"],
        rows: [[
            govDailyRecord.name,
            govDailyRecord.regNo,
            govDailyRecord.type,
            govDailyRecord.status,
            govDailyRecord.trainingDayCount,
            govDailyRecord.attendDayCount,
            govDailyRecord.missingDayCount
        ]]
    }

    return <div>
        <DetailTitle style={{marginTop: "40px", marginBottom: "40px"}}>출석표</DetailTitle>
        <FuntionContainer>
            <FilterWrapper>
                <DateInput text={"시작일: "} setX={setInput} inputX={input}/>
                <DateInput text={"종료일: "} setX={setInput2} inputX={input2}/>
                <button onClick={refresh}>검색</button>
            </FilterWrapper>
            <FileDownBtn queryParams={{start: input, end: input2}}
                         actionPath={config.apiUrl + `/v2/gov/camp/records/${user._id}/excel`}/>
        </FuntionContainer>
        <TableWrapper>
            <TableContainer>
                <UserTable table={table}/>
                {calendar.map(month => <MonthCalendar startDate={month[0]} endDate={month[1]}
                                                      attendRecords={govDailyRecord.attendRecords}
                                                      setDetail={setRecordDetail}/>)}
            </TableContainer>
        </TableWrapper>
        {recordDetail && Object.keys(recordDetail).length !== 0 &&
        <DetailTableWrapper>
            <DetailTitle>출석 기록 상세</DetailTitle>
            <DetailTitle>훈련일자: {recordDetail.date.split("T")[0]}</DetailTitle>
            <Table>
                <tr height={"20px"}>
                    <TableHeader className={"low-column"} style={{minWidth: "40px"}}>
                        행동
                    </TableHeader>
                    <TableHeader className={"low-column"} style={{minWidth: "180px"}}>
                        시간
                    </TableHeader>
                    <TableHeader className={"low-column"} style={{minWidth: "180px"}}>
                        사유
                    </TableHeader>
                </tr>
                {recordDetail.record_details.filter(detail => detail.rtype !== "중간점검").map(detail => {
                    return <tr>
                        <TableData>{detail.rtype}</TableData>
                        <TableData>
                            {detail.created_at.split("T")[1]}
                        </TableData>
                        <TableData>
                            {detail.reason}
                        </TableData>
                    </tr>
                })}
            </Table>
        </DetailTableWrapper>}
    </div>
}

const DateInputWrapper = styled.div`
  margin-right: 20px;
`

const FuntionContainer = styled.div`
  width: 1232px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const FilterWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const DownloadBtn = styled.button`
  background-color: #2b333f;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
`

const TableWrapper = styled.div`
  width: 1242px;
  margin: auto;
  margin-bottom: 40px;
`

const TableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const Table = styled.table`
  border: 1px solid #444444;
  border-collapse: collapse;
  margin-top: 20px;
`

const DetailTableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TableHeader = styled.th`
  background-color: #f1f1f1;
  text-align: center;
  border: 1px solid #444444;
  padding: 4px;
  color: ${(props) => props.wasHoliday ? "#ff0000" : "#000000"}
`

const TableData = styled.th`
  text-align: center;
  border: 1px solid #444444;
  padding: 4px;
`

const DetailTitle = styled.h4`
  text-align: center;
`