import * as S from "./HomeworkAnswer.style";
import React, { useEffect, useState } from "react";
import { FileUploadAnswer } from "./FileUploadAnswer/FileUploadAnswer";
import { TextAnswer } from "./TextAnswer/TextAnswer";
import { SelectAnswer } from "./SelectAnswer/SelectAnswer";
import { useAtom } from "jotai";
import { enrolledHomeworkDetailsAtom } from "../../../../stores/enrolledHomeworkDetails";
import {
  useHandleEnrolledHomeworkDetails,
  useHomeworkData,
} from "../../../../hooks/homeworkV3/useHomeworkData";
import { useParams } from "react-router-dom";
import { ContentsLockClose, NavigationClose } from "@teamsparta/design-system";
import { useModalAndBottomSheet } from "../../../organisms/Modal/ModalBottomSheetWrapper";
import { HintMarkdownRoot } from "../HomeworkNotion/homeworkNotion.style";
import { convertHtmlStringPipeline } from "../HomeworkNotion/HomeworkNotion";
import { useMutateHintOpened } from "../../../../queries/homeworkV3";
import { useLogHomeworkClickHint } from "../../../../hooks/homework/useLogHomeworkClick";

const initializeAnswer = (
  setAnswer,
  enrolledHomeworkDetails,
  homeworkIndex
) => {
  setAnswer("");
  if (enrolledHomeworkDetails.length > 0) {
    enrolledHomeworkDetails.map((enrolledHomeworkDetail, index) => {
      if (
        index === homeworkIndex &&
        enrolledHomeworkDetail.submitted_answer !== ""
      ) {
        setAnswer(enrolledHomeworkDetail.submitted_answer);
      }
    });
  }
};

const HintContent = ({ onClickClose, hintContent, hintLevel }) => {
  const parse = require("html-react-parser");
  const convertedString = convertHtmlStringPipeline(hintContent);
  const parsedString = parse(convertedString);
  return (
    <S.HintContentWrapper>
      <S.HintContentCloseButton onClick={onClickClose}>
        <NavigationClose size={24} style={"fill"} color={"#9da7ae"} />
      </S.HintContentCloseButton>
      <S.HintContentTitle>힌트{hintLevel}</S.HintContentTitle>
      <S.HintContentArea>
        <HintMarkdownRoot>{parsedString}</HintMarkdownRoot>
      </S.HintContentArea>
    </S.HintContentWrapper>
  );
};

const HintCta = ({ hint, checkPossibleToOpenHint }) => {
  const isDisabled = !hint.opened;
  const [Wrapper, toggle] = useModalAndBottomSheet();
  const { enrolled_homework_id } = useParams();
  const logHomeworkHintClick = useLogHomeworkClickHint();
  const mutateHintOpened = useMutateHintOpened(enrolled_homework_id);
  const openHint = () => {
    logHomeworkHintClick(hint.level, hint.opened, 0);
    const isPossibleToOpen = checkPossibleToOpenHint(hint);
    if (isPossibleToOpen) {
      toggle();
      mutateHintOpened.mutate(hint.level + 1);
    } else {
      alert("이전 힌트를 모두 확인 하셔야 합니다.");
    }
  };
  return (
    <>
      <S.HintCta isDisabled={isDisabled} onClick={openHint}>
        {isDisabled ? (
          <ContentsLockClose size={16} style={"fill"} color={"#3a3e41"} />
        ) : (
          hint.level
        )}
      </S.HintCta>
      <Wrapper>
        <HintContent
          onClickClose={() => toggle()}
          hintContent={hint.content}
          hintLevel={hint.level}
        />
      </Wrapper>
    </>
  );
};

const HomeworkHint = ({ homeworkHints }) => {
  const checkPossibleToOpenHint = (hint) => {
    const allHints = homeworkHints;
    const hintIndex = allHints.findIndex((item) => item.level === hint.level);
    return allHints[hintIndex].opened;
  };
  return (
    <S.HintContainer>
      <S.HintTitle>숙제 힌트</S.HintTitle>
      <S.HintCtaContainer>
        {homeworkHints.map((hint, index) => {
          return (
            <HintCta
              key={index}
              hint={hint}
              checkPossibleToOpenHint={checkPossibleToOpenHint}
            />
          );
        })}
      </S.HintCtaContainer>
    </S.HintContainer>
  );
};

export const HomeworkAnswer = ({ homeworkIndex }) => {
  const { enrolled_homework_id } = useParams();
  const { type, status, isFeedbackRequired } =
    useHomeworkData(enrolled_homework_id);
  const [answer, setAnswer] = useState("");
  const [enrolledHomeworkDetails] = useAtom(enrolledHomeworkDetailsAtom);
  const { updateEnrolledHomeworkDetail } = useHandleEnrolledHomeworkDetails();

  useEffect(() => {
    if (enrolledHomeworkDetails && enrolledHomeworkDetails[homeworkIndex]) {
      initializeAnswer(setAnswer, enrolledHomeworkDetails, homeworkIndex);
    }
  }, [enrolledHomeworkDetails]);

  const onUpdateSubmittedTextAnswer = (code) => {
    updateEnrolledHomeworkDetail(homeworkIndex, {
      ...enrolledHomeworkDetails[homeworkIndex],
      language: type[homeworkIndex],
      submitted_answer: code,
    });
  };

  const hints = enrolledHomeworkDetails[homeworkIndex]?.hints ?? [];
  const isRenderHints = hints.length > 0;

  return (
    <S.AnswerContainer>
      <S.AnswerTag>답변</S.AnswerTag>
      {type[homeworkIndex] === "file" && (
        <FileUploadAnswer
          status={status}
          homeworkIndex={homeworkIndex}
          isFeedbackRequired={isFeedbackRequired}
        />
      )}
      {["week0_check", "nb_free"].includes(type[homeworkIndex]) && (
        <SelectAnswer
          answer={answer}
          setAnswer={setAnswer}
          onUpdateSubmittedAnswer={onUpdateSubmittedTextAnswer}
        />
      )}
      {!["file", "week0_check", "nb_free"].includes(type[homeworkIndex]) && (
        <TextAnswer
          status={status}
          answer={answer}
          setAnswer={setAnswer}
          onUpdateSubmittedAnswer={onUpdateSubmittedTextAnswer}
        />
      )}
      {isRenderHints && (
        <HomeworkHint
          homeworkIndex={homeworkIndex}
          homeworkHints={enrolledHomeworkDetails[homeworkIndex].hints}
        />
      )}
    </S.AnswerContainer>
  );
};
