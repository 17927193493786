import styled from "@emotion/styled";

const CourseCards = ({ children }) => {
  return <Container>{children}</Container>;
};

export default CourseCards;

const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;
