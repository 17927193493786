import { config } from "../config";
import { handleResponse, authHeader, privateFetch } from "../_helpers";

function getRetrospective(enrolled_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/enrolleds/${enrolled_id}/retrospectives`;
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

export const retrospectiveService = {
  getRetrospective,
};
