import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions";
import { LecNavList } from "../LecNavList";
import classNames from "classnames/bind";
import styles from "./LecNav.module.scss";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function LecNav({
  enrolled,
  enrolledDetail,
  enrolledDetails,
  expired,
  forceUpdate,
  getEnrolledDetails,
  getExpired,
  title,
  isGovIntro,
  clickable = true,
}) {
  const [isListOpen, setIsListOpen] = useState(false);
  const theme = useOnlineTheme();

  useEffect(() => {
    if (!enrolled.loaded) {
      return;
    }
    const { enrolled_id } = enrolled.data;
    if (!enrolledDetails.loaded) {
      getEnrolledDetails(enrolled_id);
    }
    getExpired(enrolled_id);
    setIsListOpen(false);
  }, [enrolled, forceUpdate]);

  if (isGovIntro) {
    return (
      <div className={cx("lec-nav", isGovIntro ? "lec-nav__intro" : "")}>
        {title && (
          <div className={cx("lec-nav__title", "lec-nav__title__intro")}>
            <div className={cx("lec-nav__title__text")}>{title}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={cx("lec-nav")}>
      {title && (
        <div
          className={cx(
            "lec-nav__title",
            theme.data,
            clickable ? "" : "lec-nav__title__not-clickable"
          )}
          onClick={() => {
            if (clickable) {
              setIsListOpen(!isListOpen);
            }
          }}
        >
          {enrolledDetail.loaded &&
            (enrolledDetail.data.week > 5 ? (
              <div className={cx("lec-nav__title__order", theme.data)}>
                {enrolledDetail.data.week}-{enrolledDetail.data.week_order}
              </div>
            ) : (
              <div className={cx("lec-nav__title__order", theme.data)}>
                {enrolledDetail.data.week}-{enrolledDetail.data.week_order}
              </div>
            ))}
          <div className={cx("lec-nav__title__text", theme.data)}>{title}</div>
          {clickable && (
            <div
              className={cx(
                "lec-nav__title__bg",
                theme.data,
                `${isListOpen ? "--rotate" : ""}`
              )}
            >
              <svg
                className={cx("lec-nav__title__bg__img")}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="9"
                fill="none"
                viewBox="0 0 14 9"
              >
                <path
                  fill="#000"
                  d="M1.623 0l5.285 5.342L12.192 0l1.623 1.653-6.907 6.982L0 1.653 1.623 0z"
                />
              </svg>
            </div>
          )}
        </div>
      )}
      {enrolledDetails.loaded && isListOpen && (
        <div className={cx("lec-nav__list")}>
          <div
            className={cx("lec-nav__list__bg")}
            onClick={() => {
              setIsListOpen(false);
            }}
          ></div>
          <LecNavList />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolledDetails, enrolledDetail, enrolled, expired } =
    state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetail,
    enrolledDetails,
    expired,
  };
}

const connectedLecNav = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getExpired: enrolledActions.getExpired,
})(LecNav);
export { connectedLecNav as LecNav };
