import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./ClassroomCards.module.scss";
import { connect } from "react-redux";
import { classroomActions } from "../../../_state/actions/classroom.actions";
import { ClassroomCard } from "./ClassroomCard";

const cx = classNames.bind(styles);

function ClassroomCards({
  classroomCards,
  expiredClassroomCards,
  getClassroomCards,
  getExpiredClassroomCards,
}) {
  useEffect(() => {
    getClassroomCards();
    if (!expiredClassroomCards.loaded) {
      getExpiredClassroomCards();
    }
  }, []);

  const platform = checkPlatform();
  return (
    <div className={cx("classroom-cards-wrap")}>
      {expiredClassroomCards.loaded &&
        expiredClassroomCards.data.full_enrolled_info_list.map(
          (classroomCard, idx) => {
            if (classroomCard.course?.is_temp_package) {
              return null;
            }
            return (
              <ClassroomCard
                isExpired={true}
                classroomCard={classroomCard}
                key={idx}
              />
            );
          }
        )}
      {classroomCards.loaded &&
        classroomCards.data.full_enrolled_info_list.map(
          (classroomCard, idx) => {
            if (classroomCard.course?.is_temp_package) {
              return null;
            }
            return <ClassroomCard classroomCard={classroomCard} key={idx} />;
          }
        )}
      {!classroomCards.loaded && platform !== "mobile" && (
        <img
          style={{ width: "100%" }}
          className={cx("skeleton")}
          src={`/assets/images/classroom/classroom_web_skeleton.png`}
        />
      )}
      {!classroomCards.loaded && platform === "mobile" && (
        <img
          style={{ width: "100%" }}
          className={cx("skeleton")}
          src={`/assets/images/classroom/classroom_mo_skeleton.png`}
        />
      )}
    </div>
  );
}

export function checkPlatform() {
  let ua = window.navigator.userAgent;
  ua = ua.toLowerCase();
  var platform = {};
  var matched = {};
  var userPlatform = "pc";
  var platform_match =
    /(ipad)/.exec(ua) ||
    /(ipod)/.exec(ua) ||
    /(windows phone)/.exec(ua) ||
    /(iphone)/.exec(ua) ||
    /(kindle)/.exec(ua) ||
    /(silk)/.exec(ua) ||
    /(android)/.exec(ua) ||
    /(win)/.exec(ua) ||
    /(mac)/.exec(ua) ||
    /(linux)/.exec(ua) ||
    /(cros)/.exec(ua) ||
    /(playbook)/.exec(ua) ||
    /(bb)/.exec(ua) ||
    /(blackberry)/.exec(ua) ||
    [];

  matched.platform = platform_match[0] || "";

  if (matched.platform) {
    platform[matched.platform] = true;
  }

  if (
    platform.android ||
    platform.bb ||
    platform.blackberry ||
    platform.ipad ||
    platform.iphone ||
    platform.ipod ||
    platform.kindle ||
    platform.playbook ||
    platform.silk ||
    platform["windows phone"]
  ) {
    userPlatform = "mobile";
  }

  if (platform.cros || platform.mac || platform.linux || platform.win) {
    userPlatform = "pc";
  }

  return userPlatform;
}

function mapStateToProps(state) {
  const { authentication, classroomCards, expiredClassroomCards } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    classroomCards,
    expiredClassroomCards,
  };
}

const connectedClassroomCards = connect(mapStateToProps, {
  getClassroomCards: classroomActions.getClassroomCards,
  getExpiredClassroomCards: classroomActions.getExpiredClassroomCards,
})(ClassroomCards);

export { connectedClassroomCards as ClassroomCards };
