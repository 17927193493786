import { useEffect, useMemo, useRef } from "react";
import videojs from "video.js";
import "videojs-hotkeys";
import {
  useIsControlBarActive,
  useIsFullscreen,
  useIsPlaying,
  useMute,
  usePlaybackSpeed,
  useVolume,
} from "../stores/playerAtom";
import { getPlayerV3Options } from "./playerOptions";
import { convertToMp4_not_source, onBufferEnd } from "../playerV3";
import { deviceType } from "mobile-device-detect";
import { useSelector } from "react-redux";
import { useUser } from "../../../../LecturePageV3/useInitialize";

export const usePlayerCoreControl = ({
  videoRef,
  lecture_v2,
  playerType,
  onTimeUpdate = () => {},
  is_waiting = false,
  onPlay = () => {},
}) => {
  const user = useUser();
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useIsPlaying();
  const [, setIsFullscreen] = useIsFullscreen(false);
  const [, setVolume] = useVolume(1);
  const [, setIsMuted] = useMute(false);
  const [, setIsControlBarActive] = useIsControlBarActive(false);
  const [, setPlaybackSpeedRate] = usePlaybackSpeed(1);
  const nowPlayingRef = useRef(false);
  const isMobile = deviceType === "mobile"; //useIsMobile()사용시 웹버전에서 에러발생

  const { videoJsOptions, MP4URL, vttSource, is_mp4 } = getPlayerV3Options({
    lecture_v2,
    playerType,
    isMobile,
    playerRef,
    setIsFullscreen,
    setIsPlaying,
    setVolume,
    setIsMuted,
    setPlaybackSpeedRate,
  });
  let lastBufferStarted = null;
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    playerRef.current = videojs(videoRef.current, videoJsOptions, () => {
      console.log("playerV3 is ready");
    });

    const player = playerRef.current;
    player.ready(() => {
      const storedProgress = localStorage.getItem(
        `videoProgress_${lecture_v2.data.lecture_id}`
      );
      if (storedProgress) {
        player.currentTime(storedProgress);
      }
    });

    player.on("ended", () => {
      setIsPlaying(false);
      nowPlayingRef.current = false;
      localStorage.setItem(`videoProgress_${lecture_v2.data.lecture_id}`, 0); // 영상이 끝나면 플레이타임을 0으로 설정
    });

    player.on("error", () => {
      console.log("----error----");
      setIsPlaying(false);
      if (playerType === "V2") {
        convertToMp4_not_source(
          player,
          MP4URL,
          enrolledDetail,
          user,
          lecture_v2
        );
      }
    });
    player.on("play", () => {
      onPlay();
      nowPlayingRef.current = true;
      setIsPlaying(true);
    });
    player.on("pause", () => {
      nowPlayingRef.current = false;
      setIsPlaying(false);
      localStorage.setItem(
        `videoProgress_${lecture_v2.data.lecture_id}`,
        player.currentTime()
      );
    });

    player.on("timeupdate", () => {
      localStorage.setItem(
        `videoProgress_${lecture_v2.data.lecture_id}`,
        player.currentTime()
      );
      let lectureViewPercentage = player.currentTime() / player.duration();
      onTimeUpdate(lectureViewPercentage, nowPlayingRef.current);
    });

    player.on("mouseover", () => setIsControlBarActive(true));
    player.on("mouseout", () => setIsControlBarActive(false));
    player.on("canplay", () => {
      is_waiting = false;
      onBufferEnd(lastBufferStarted, lecture_v2, is_mp4);
    });

    return () => {
      setIsPlaying(false);
      if (playerRef.current) {
        player.dispose();
        playerRef.current.off("play");
        playerRef.current.off("pause");
        playerRef.current.off("timeupdate");
        playerRef.current.off("mouseover");
        playerRef.current.off("mouseout");
        playerRef.current.off("canplay");
      }
    };
  }, [videoRef]);

  const togglePlay = (e) => {
    if (playerRef.current?.paused()) {
      playerRef.current?.play();
    } else {
      playerRef.current.pause();
    }
    e.stopPropagation();
  };

  const playButtonSrc = useMemo(() => {
    if (isPlaying) {
      return "../../../assets/smallstopbtn.svg";
    } else {
      return "../../../assets/smallplaybtn.svg";
    }
  }, [isPlaying]);

  return {
    playButtonSrc,
    togglePlay,
    vttSource,
  };
};
