import { useQuery } from "react-query";
import { homeworksService, lectureService } from "../../../_state/services";

const homeworkCacheTime = 1000 * 60 * 60 * 24; // 24 hours

export const useMyHomeworks = (enrolledsHomeworksId) => {
  return useQuery(
    ["myHomeworks", enrolledsHomeworksId],
    async () => {
      return await homeworksService.getMyHomeworks(enrolledsHomeworksId);
    },
    {
      enabled: !!enrolledsHomeworksId,
      staleTime: homeworkCacheTime,
    }
  );
};

export const useMaterialByEnrolled = (enrolledId, homeworkWeek) => {
  return useQuery(
    ["materialByEnrolled", enrolledId, homeworkWeek],
    async () => {
      return await lectureService.getMaterialByEnrolled(
        enrolledId,
        homeworkWeek
      );
    }
  );
};

export const useHomeworkAnswer = (homeworkId) => {
  return useQuery(
    ["homeworkAnswer", homeworkId],
    async () => {
      return await homeworksService.getHomeworkAnswer(homeworkId);
    },
    {
      enabled: !!homeworkId,
      staleTime: homeworkCacheTime,
    }
  );
};
