import styled from "@emotion/styled";
import {
  neutralDay,
  mBody1,
  mCaption2,
  mTitle2,
  wBody1,
  wBody3,
  wTitle1,
  wTitle2,
} from "@teamsparta/design-system";
import { device, DisplayResolution } from "../../../../_styles/device";
import { keyframes, css } from "@emotion/react";

export const SectionTitle = styled.div`
  ${wTitle1};
  font-weight: 700;
  color: #3a3e41;
  margin-bottom: 24px;
  @media ${device.mobile} {
    font-size: 18px;
  }
  @media ${device.breakPoint} {
    font-size: 18px;
  }
  @media ${device.desktop} {
    font-size: 24px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #d7e0e6;
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);
  background: #fff;
  margin-bottom: 50px;

  @media ${device.mobile} {
    width: 360px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
  }
  @media ${device.breakPoint} {
    width: 100%;
    height: 133px;
    flex-direction: row;
    padding: 24px 30px;
    justify-content: space-between;
    align-items: center;
  }
  @media ${device.desktop} {
    flex-direction: row;
    width: 100%;
    height: 133px;
    padding: 24px 30px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const NextLectureThumbnail = styled.div`
  width: ${({ isNextHomework }) => (isNextHomework ? "32px" : "164px")};
  height: ${({ isNextHomework }) => (isNextHomework ? "32px" : "91px")};
  border-radius: 8px;
  position: relative;
  border: 1px solid ${neutralDay.gray30};
  margin-right: 7px;

  @media ${device.mobile} {
    width: 115.75px;
    height: 64.376px;
    flex-shrink: 0;
  }
  @media ${device.breakPoint} {
    width: 164px;
    height: 91px;
    flex-shrink: 0;
  }
`;

export const LectureTime = styled.div`
  ${mCaption2};
  font-weight: 500;
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: inline-flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid ${neutralDay.gray90};
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
`;

export const TitleContainer = styled.div`
  @media ${device.mobile} {
    width: 170px;
  }
  @media ${device.breakPoint} {
    display: flex;
    width: 358px;
    gap: 6px;
  }
  @media ${device.desktop} {
    width: ${({ homeWorkWeek }) => (homeWorkWeek === null ? "400px" : "358px")};
    gap: 6px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
`;

export const LectureWeek = styled.div`
  ${wBody3};
  color: #5f666b;
  line-height: 160%;
`;

const slide = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;

export const AnimatedText = styled.div`
  white-space: nowrap;
  display: inline-block;

  ${(props) =>
    props.shouldAnimate &&
    css`
      animation: ${slide} 15s linear infinite;
    `}

  & > span {
    padding-right: 100px; // Optional: add some space between the two texts
  }
`;

export const HomeworkTitle = styled.div`
  ${wTitle2};
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #3a3e41;
  line-height: 140%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;

  ${DisplayResolution.MobileAndMiniTablet} {
    ${mTitle2};
  }
`;

export const LectureTitle = styled.div`
  ${wTitle2};
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #3a3e41;
  line-height: 140%;
  overflow: hidden;
  position: relative;

  ${(props) =>
    props.shouldAnimate &&
    css`
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 16px;
        z-index: 2;
      }

      &::before {
        left: 0;
        background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
      }

      &::after {
        right: 0;
        background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
      }
    `}
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mTitle2};
  }
`;

export const ButtonContainer = styled.div`
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #e8344e;
  color: #fff;
  text-align: center;
  cursor: pointer;

  @media ${device.mobile} {
    ${mBody1};
    color: #fff;
    width: 100%;
    font-size: 14px;
    display: flex;
    height: 44px;
    padding: 11.5px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
  @media ${device.breakPoint} {
    ${wBody1};
    width: 200px;
    margin: auto 0;
    font-size: 16px;
    display: flex;
    height: 56px;
    padding: 16px 20px;
    color: #fff;
  }
  @media ${device.desktop} {
  }
`;

export const HomeWorkWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 30px;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  border-radius: 24px;
  display: flex;
  border: 1px solid #d7e0e6;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);
  margin-bottom: 50px;

  @media ${device.mobile} {
    width: 360px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-right: 50px;
  }
  @media ${device.breakPoint} {
    width: 100%;
    height: 104px;
    flex-direction: row;
    padding: 24px 30px;
    justify-content: space-between;
    align-items: center;
  }
  @media ${device.desktop} {
    flex-direction: row;
    width: 100%;
    height: 104px;
    padding: 24px 30px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HomeWorkImageAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NextHomeWorkThumbnail = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin: auto 20px auto 0;
`;
