import { useGetPlayerUrl } from "../../pages/LecturePage/LecPlayer/LecPlayerV2/useGetPlayer";
import { useEffect } from "react";

export const useVideoUrlByLectureId = (lecture_v2, getLectureV2, lectureId) => {
  const videoUrl = useGetPlayerUrl(lecture_v2).mp4Url;
  useEffect(() => {
    getLectureV2(lectureId);
  }, [lectureId]);
  return videoUrl;
};
