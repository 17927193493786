import React, { Component } from "react";
import { config } from "../../config";
import {
  TreeSelect,
  Modal,
  Rate,
  Input,
  Button,
  Skeleton,
  message,
  Tooltip,
} from "antd";
import classNames from "classnames/bind";
import styles from "./LectureNav.module.scss";
import { history } from "../../_helpers";
import { connect } from "react-redux";
import {
  codeSnippetActions,
  homeworkActions,
  lectureActions,
  lectureNoteActions,
  pointActions,
} from "../../_actions";
import { getApiLectureNavInfoUrl, getHomeworkUrl } from "../../_urls/index";
import { getAnswerUrl, getLectureUrl } from "../../_urls/lectures";
import {
  getApiCreateCheerupUrl,
  getApiCreateRatingUrl,
  getPostAuthOptions,
  getGETAuthOptions,
} from "../../_urls";
import { lecture, lectureNav } from "../../_reducers/lecture.reducer";
import { CustomModal } from "../CustomModal";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";

const cx = classNames.bind(styles);
const { TreeNode } = TreeSelect;

class LectureNavEn extends Component {
  state = {
    isInfoLoaded: false,
    isLecture: false,
    isHomework: false,
    isAnswer: false,
    lectures: [],
    modalRating: true,
    modalRatingVisible: false,
    modalRatingText: "이번 강의는 어떠셨나요?",
    modalRatingConfirmLoading: false,
    modalOkText: "다음",
    value: 5.0,
    difficulty: 0.0,
    fun: 0.0,
    cheerup: "",
    treasureBoxTooltipShow: false,
    isDifficultyChecked: false,
    isFunChecked: false,
  };

  componentDidMount() {
    // url 입력으로 들어온 경우
    const { round_id, lecture_id, homework_id, homework_lecture_id } =
      this.props.data;
    this.props.getLectureNavInfo(round_id);
    this.setState({
      isLecture: lecture_id !== undefined,
      isHomework: homework_id !== undefined,
      isAnswer: homework_lecture_id !== undefined,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lectureNav.loaded === false && this.props.lectureNav.loaded) {
      this.setState({
        isInfoLoaded: true,
        isDifficultyChecked: false,
        isFunChecked: false,
      });
    }
  }

  _getFilledNumber = (number) => {
    number = parseInt(number);
    return number > 9 ? number : `0${number}`;
  };

  _getLectureComponent = (week, lectureList) => {
    let { done_week } = this.props.lectureNav.data;
    let result = [];
    for (const [idx, lec] of lectureList.entries()) {
      let isHomework = lec["_id"].includes("homework");
      let number;
      if (isHomework) {
        number = "HW -";
      } else {
        number = this._getFilledNumber(idx + 1) + ".";
      }

      if (lec["week"] > done_week + 1) {
        result.push(
          <TreeNode
            className={cx("navFontSizeContainer")}
            disabled={true}
            key={lec["_id"]}
            value={lec["_id"]}
            title={`${number} ${lec["title"]}`}
          />
        );
      } else {
        result.push(
          <TreeNode
            className={cx("navFontSizeContainer")}
            disabled={!(lec["seen"] && lec.is_allowed)}
            key={lec["_id"]}
            value={lec["_id"]}
            title={`${number} ${lec["title"]}`}
          />
        );
      }
    }
    return result;
  };

  _getLecturesComponentList = () => {
    const { isLecture } = this.state;
    if (
      !this.props.lectureNav.loaded ||
      (isLecture && !this.props.lecture.loaded)
    ) {
      return <div />;
    }
    let { done_week, lectures } = this.props.lectureNav.data;
    const { course_id } = isLecture
      ? this.props.lecture.data
      : this.props.homework.data;
    let is_free = false;
    let is_forever = false;
    if (this.props.lecture.data) {
      let { context } = this.props.lecture.data;
      is_free = context.is_free;
      is_forever = context.is_forever;
    }
    let result = [];
    let i = 100;
    for (const [week, lectureList] of Object.entries(lectures)) {
      let font_color =
        week > done_week + 1 || lectureList[0].is_allowed === false
          ? "#dddddd"
          : "#13C2C2";
      let lecComp = this._getLectureComponent(week, lectureList);
      result.push(
        <TreeNode
          key={i}
          className={cx("navFontSizeContainer")}
          selectable={false}
          value={i}
          title={<b style={{ color: font_color }}>Chap.{week}</b>}
        >
          {lecComp}
        </TreeNode>
      );
      i++;
    }
    return result;
  };

  onChange = (next_element_id) => {
    this.props.handleNavStatus(true);
    const round_id = this.props.data.round_id;
    if (next_element_id.includes("homework")) {
      const homework_id = next_element_id.split("/")[1];
      let chosenHomeworkUrl = getHomeworkUrl(round_id, homework_id);
      this.props.getHomeworkItem(round_id, homework_id);
      history.push("/en" + chosenHomeworkUrl);
    } else {
      let chosenLectureUrl = getLectureUrl(round_id, next_element_id);
      this._refreshComponents(round_id, next_element_id);
      history.push("/en" + chosenLectureUrl);
    }
  };

  _goPrevLecture = () => {
    const { isLecture, isHomework, isAnswer } = this.state;
    const { round_id } = this.props.data;
    let chosenUrl = "";

    // 강의 화면을 보고 있는 경우
    if (isLecture) {
      const { prev_id } = this.props.lecture.data;
      if (prev_id === null) {
        alert("앗, 처음 강의에요!");
        return;
      }

      if (prev_id.includes("homework")) {
        // 이전 페이지가 숙제인 경우
        const homework_id = prev_id.split("/")[1];
        chosenUrl = getHomeworkUrl(round_id, homework_id);
        this.props.getHomeworkItem(round_id, homework_id);
      } else {
        // 이전 페이지가 강의인 경우
        // this.props.getLecture(round_id, prev_id);
        chosenUrl = getLectureUrl(round_id, prev_id);
        this._refreshComponents(round_id, prev_id);
      }
      // 숙제 화면을 보고 있는 경우
    } else if (isHomework) {
      const { prev_id } = this.props.homework.data;
      if (prev_id.includes("lectures")) {
        // 이전 페이지가 강의인 경우
        const lecture_id = prev_id.split("/")[1];
        // this.props.getLecture(round_id, lecture_id);
        this._refreshComponents(round_id, lecture_id);
        chosenUrl = getLectureUrl(round_id, lecture_id);
      } else {
        // 이전 페이지가 숙제인 경우
        this.props.getHomeworkItem(round_id, prev_id);
        chosenUrl = getHomeworkUrl(round_id, prev_id);
      }
    }

    history.push(chosenUrl);
  };

  _goNextLecture = () => {
    const { isLecture, isHomework, isAnswer } = this.state;
    const { round_id } = this.props.data;
    let next_id;
    if (isLecture) {
      next_id = this.props.lecture.data.next_id;
    } else if (isHomework) {
      let chosenUrl = "";
      next_id = this.props.homework.data.next_id;
      if (this.props.homework.isDone === false) {
        alert("앗, 이번 주차 숙제를 마무리해주세요!");
        return;
      }

      let { week, course_title } = this.props.homework.data;

      if (next_id.includes("lectures")) {
        const lecture_id = next_id.split("/")[1];
        // this.props.getLecture(round_id, lecture_id);
        chosenUrl = getLectureUrl(round_id, lecture_id);
      } else {
        this.props.getHomeworkItem(round_id, next_id);
        chosenUrl = getHomeworkUrl(round_id, next_id);
      }

      history.push(chosenUrl);
      return;
    }
    if (next_id === undefined) {
      alert("앗, 마지막 강의에요!\n완강을 축하드려요!");
      history.push("/classroom");
      return;
    }
    this._showModal();
  };

  _showModal = () => {
    const { round_id, lecture_id, homework_id } = this.props.data;
    const { cheerup_written, rating_written } = this.props.lecture.data;

    if (rating_written) {
      // 바로 다음 강의로 넘어간다.
      const { next_id } = this.props.lecture.data;
      this.setState({
        modalRating: true,
        modalRatingVisible: false,
        modalRatingConfirmLoading: false,
        cheerup: "",
      });
      if (next_id.includes("homework")) {
        const homework_id = next_id.split("/")[1];
        let chosenHomeworkUrl = getHomeworkUrl(round_id, homework_id);
        this.props.getHomeworkItem(round_id, homework_id);
        history.push(chosenHomeworkUrl);
      } else {
        this._refreshComponents(round_id, next_id);
        let chosenLectureUrl = getLectureUrl(round_id, next_id);
        history.push(chosenLectureUrl);
      }
    } else {
      this.setState({
        modalRatingVisible: true,
        value: 0.0,
        difficulty: 0.0,
        fun: 0.0,
      });
    }
  };

  _showModalRating = () => {
    // setTimeout(function () {
    //     // document.getElementById('modal-rating').style.display = "flex";
    //     // document.getElementById('modal-cheerup').style.display = "none";
    // }, 2000);
  };

  _showModalCheerup = () => {
    document.getElementById("modal-rating").style.display = "none";
    document.getElementById("modal-cheerup").style.display = "flex";
  };

  _refreshComponents = (round_id, lecture_id) => {
    this.props.getLectureNavInfo(round_id);
    this.props.getLecture(round_id, lecture_id);
    this.props.getCodeSnippet(round_id, lecture_id);
    this.props.getPointInfo();
  };

  _showSuccessMessage = () => {
    const { points } = this.state;
    if (points === undefined || points.review_done === undefined) {
      return;
    }
    message.success({
      content: `+1 보물상자, +${points.review_done} 포인트 적립 완료!`,
      className: "custom-class",
      style: {
        position: "absolute",
        top: "7px",
        right: "40px",
      },
    });
  };

  handleOk = (isHomework = false) => {
    this.setState({
      modalRatingConfirmLoading: true,
    });

    const { round_id, lecture_id } = this.props.data;

    if (this.state.modalRating) {
      const { isDifficultyChecked, isFunChecked } = this.state;
      if (isFunChecked === false || isDifficultyChecked === false) {
        alert("꿀잼지수와 어렵지수를 매겨주세요.");
        return;
      }

      let data = {
        refresh_token: this.props.user.refresh,
        difficulty: this.state.difficulty,
        fun: this.state.fun,
        cheerup: this.state.cheerup,
      };
      let endpoint = getApiCreateRatingUrl(round_id, lecture_id);
      let requestOptions = getPostAuthOptions(data);
      fetch(endpoint, requestOptions)
        .then((resp) => resp.json())
        .then((result) => {
          if (result.ok) {
            const { next_id } = this.props.lecture.data;

            let chosenUrl;
            if (next_id.includes("homework")) {
              const homework_id = next_id.split("/")[1];
              chosenUrl = getHomeworkUrl(round_id, homework_id);
              this.props.getHomeworkItem(round_id, homework_id);
            } else {
              // this.props.getLecture(round_id, next_id);
              chosenUrl = getLectureUrl(round_id, next_id);
              this._refreshComponents(round_id, next_id);
            }

            this._showSuccessMessage();

            history.push(chosenUrl);
            this.setState({
              modalRating: true,
              modalRatingVisible: false,
              modalRatingConfirmLoading: false,
              cheerup: "",
            });

            if (next_id.includes("homework") === false) {
              this._showModalRating();
            }
          } else {
            alert("오류가 발생했습니다. 다시 시도해주세요.");
          }
        });
      // let data = {
      //     'refresh_token': this.props.user.refresh,
      //     'difficulty': this.state.difficulty,
      // };
      // let endpoint = getApiCreateRatingUrl(course_id, lecture_id);
      // let requestOptions = getPostAuthOptions(data);
      // fetch(endpoint, requestOptions)
      //     .then(resp => resp.json())
      //     .then(result => {
      //         console.log(result);
      //         if (result.ok) {
      //             this._showModalCheerup();
      //             this.setState({
      //                 modalRating: false,
      //                 modalRatingConfirmLoading: false,
      //                 modalOkText: '다음 강의로 넘어가기',
      //             });
      //         } else {
      //             alert('오류가 발생했습니다. 다시 시도해주세요.');
      //         }
      //     });
    } else {
      let data = {
        fun: this.state.fun,
      };
      let endpoint = getApiCreateCheerupUrl(round_id, lecture_id);
      let requestOptions = getPostAuthOptions(data);
      fetch(endpoint, requestOptions)
        .then((resp) => resp.json())
        .then((result) => {
          if (result.ok) {
            const { next_id } = this.props.lecture.data;

            let chosenUrl;
            if (next_id.includes("homework")) {
              const homework_id = next_id.split("/")[1];
              chosenUrl = getHomeworkUrl(round_id, homework_id);
            } else {
              // this.props.getLecture(round_id, next_id);
              chosenUrl = getLectureUrl(round_id, next_id);
              this._refreshComponents(round_id, next_id);
            }
            // let chosenLectureUrl = getLectureUrl(round_id, next_id);
            history.push(chosenUrl);
            this.setState({
              modalRating: true,
              modalRatingVisible: false,
              modalRatingConfirmLoading: false,
              cheerup: "",
            });

            if (next_id.includes("homework") === false) {
              this._showModalRating();
            }
          } else {
            alert("오류가 발생했습니다. 다시 시도해주세요.");
          }
        });
    }
  };

  handleCancel = () => {
    this.setState({
      modalRatingVisible: false,
    });
  };

  handleDifficultyChange = (difficulty) => {
    this.setState({
      difficulty: difficulty,
      isDifficultyChecked: true,
    });
  };

  handleFunChange = (fun) => {
    this.setState({
      fun: fun,
      isFunChecked: true,
    });
  };

  // handleRatingChange = value => {
  //     this.setState({value});
  // };

  handleCheerupChange = (e) => {
    this.setState({ cheerup: e.target.value });
  };

  handleModalVisible = (isVisible) => {
    this.setState({
      modalRatingVisible: isVisible,
    });
  };

  _handleMouseOver = () => {
    this.setState({
      treasureBoxTooltipShow: true,
    });
  };

  _handleMouseLeave = () => {
    this.setState({
      treasureBoxTooltipShow: false,
    });
  };

  render() {
    const { user, lectureNav } = this.props;
    const { homework_id, lecture_id } = this.props.data;
    const selected =
      homework_id !== undefined ? `homeworks/${homework_id}` : lecture_id;
    const {
      modalRatingVisible,
      difficulty,
      fun,
      cheerup,
      isInfoLoaded,
      isLecture,
      isHomework,
    } = this.state;
    let lectureList = this._getLecturesComponentList();

    if (
      isInfoLoaded === false ||
      (isLecture && this.props.lecture.data === undefined) ||
      lectureNav.loaded === false
    ) {
      return <Skeleton.Input style={{ width: 500 }} active={true} />;
    }
    const rating_written =
      this.props.lecture.data !== undefined
        ? this.props.lecture.data.rating_written
        : false;
    const { points } = this.state;

    const number = isHomework
      ? "숙제 -"
      : this._getFilledNumber(this.props.lecture.data.week_order + ".");
    const navTitle = isHomework
      ? `${number} ${this.props.homework.data.title}`
      : `${number}. ${this.props.lecture.data.title}`;
    let is_free = false;
    let is_forever = false;
    if (this.props.lecture.data) {
      let { context } = this.props.lecture.data;
      is_free = context.is_free;
      is_forever = context.is_forever;
    }
    return (
      <div>
        <ul className={"ant-pagination"} unselectable={"unselectable"}>
          <li className={`ant-pagination-item ${cx("treeselect-li-width")}`}>
            <TreeSelect
              showSearch={false}
              showArrow={true}
              style={{ width: "100%", height: 40 }}
              // value={<span><MenuOutlined/>&nbsp;&nbsp;&nbsp;&nbsp;<span
              //     style={{verticalAlign: "0.125em"}}>{navTitle}</span></span>}
              value={selected}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="수강할 강의를 선택해주세요"
              // allowClear
              treeDefaultExpandAll={false}
              treeDefaultExpandedKeys={[selected]}
              onChange={this.onChange}
              className={cx("navFontSizeContainer")}
            >
              {lectureList}
            </TreeSelect>
          </li>
        </ul>
        <CustomModal
          visible={modalRatingVisible}
          close={false}
          handleModalVisible={this.handleModalVisible}
          body={
            <div className={cx("modalBodyContainer")}>
              <div className={cx("modalScoreContainer", "flexColumnContainer")}>
                <h4>{user.name}님, 이번 강의는 몇 점인가요?</h4>
                {points && (
                  <div className={cx("reviewDonePointContainer")}>
                    {points.review_done}포인트
                  </div>
                )}
                <div className={cx("individualScoreContainer")}>
                  <img src={"/assets/images/roadmap/ic_fun_honey.png"} alt="" />
                  꿀잼지수
                  <Rate
                    allowHalf
                    defaultValue={0.0}
                    onChange={this.handleFunChange}
                    value={fun}
                  />
                  <span style={{ fontSize: 16, width: 10 }}>&nbsp;</span>
                </div>
                <div className={cx("individualScoreContainer")}>
                  <img
                    src={"/assets/images/roadmap/ic_difficult_tears.png"}
                    alt=""
                  />
                  어렵지수
                  <Rate
                    allowHalf
                    defaultValue={0.0}
                    onChange={this.handleDifficultyChange}
                    value={difficulty}
                  />
                  <span style={{ fontSize: 16, width: 10 }}>
                    {/*<Tooltip placement="topLeft" title="전혀 어렵지 않았다면 패스!" arrowPointAtCenter>*/}
                    {/*    <QuestionCircleOutlined />*/}
                    {/*</Tooltip>*/}
                  </span>
                </div>
              </div>
              <div
                className={cx("modalOpinionContainer", "flexColumnContainer")}
              >
                <h4>의견을 들려주세요👀</h4>
                <p>
                  {user.name}님께 이번 강의는 어떠셨나요?
                  <br />
                  {user.name}님의 의견은 강의 개선에 큰 도움이 됩니다.
                </p>
                <Input.TextArea
                  rows={4}
                  onChange={this.handleCheerupChange}
                  value={cheerup}
                  placeholder={
                    "선택사항입니다. 건의하고 싶으신 사항이 있는 경우 적어주세요."
                  }
                />
              </div>
              <div className={cx("buttonContainer")}>
                <Button onClick={this.handleOk}>다음 강의 보러가기</Button>
              </div>
            </div>
          }
        />
        {this.props.lecture.data && !is_forever && (
          <Tooltip
            visible={this.state.treasureBoxTooltipShow}
            title={`Available until February 28 6PM`}
            placement={"bottomLeft"}
            overlayStyle={{ fontSize: 12 }}
          >
            <div
              className={cx("lectureDonePointContainer")}
              onMouseOver={this._handleMouseOver}
              onMouseLeave={this._handleMouseLeave}
            >
              Due: Today
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, lecture, homework, lectureNav } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    lecture,
    homework,
    lectureNav,
  };
}

const connectedLectureNavEn = connect(mapStateToProps, {
  getLecture: lectureActions.getLecture,
  getLectureNavInfo: lectureActions.getLectureNavInfo,
  getCodeSnippet: codeSnippetActions.getCodeSnippet,
  getHomeworkItem: homeworkActions.getHomeworkItem,
  getPointInfo: pointActions.getPointInfo,
})(LectureNavEn);
export { connectedLectureNavEn as LectureNavEn };
