import { commingsoonConstants } from "../_constants";

const initState = {
  loaded: false,
  data: [],
};

export function commingsoons(state = initState, action) {
  switch (action.type) {
    case commingsoonConstants.COMMINGSOON_LIST_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case commingsoonConstants.COMMINGSOON_LIST_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.commingsoons,
      });
    case commingsoonConstants.COMMINGSOON_LIST_FAILURE:
      if (!action.error.contains("JSON")) {
        alert(action.error);
      }
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
