import * as S from "./nps.style";
import { ArrowChevronRight } from "@teamsparta/design-system";
import { useNextContentCtaText } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

export const DesktopSubmitCta = ({ ctaText, onClickCta, isClickable }) => {
  return (
    <S.DesktopSubmitCta
      onClick={(e) => {
        isClickable ? onClickCta() : e.stopPropagation();
      }}
      isClickable={isClickable}
    >
      <S.DesktopSubmitCtaText>{ctaText}</S.DesktopSubmitCtaText>
      <ArrowChevronRight size={20} color={"#fff"} />
    </S.DesktopSubmitCta>
  );
};
