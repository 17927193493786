import { config } from "../config";
import { API_VERSION } from "./utils";

export const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiPaymentInfoUrl = (course_id) => {
  return `${baseUrl}/courses/${course_id}/price`;
};

export const getStartPaymentUrl = (course_id) => {
  return `${baseUrl}/payment/start/${course_id}`;
};

export const getZeropayUrl = (course_id, order_no) => {
  return `${baseUrl}/payment/zeropay/${course_id}/${order_no}`;
};

export const getPayFreemiumUrl = (course_id) => {
  return `${baseUrl}/payment/start/${course_id}/free`;
};

export const getStartPaymentNoLoginUrl = (course_id) => {
  return `${baseUrl}/payment/start/${course_id}/noauth`;
};

export const getApiPrequestionInfoUrl = (course_id, round_id) => {
  return `${baseUrl}/payment/prequestion/${course_id}/${round_id}`;
};

export const getApiPrequestionOfEnrolled = (enrolled_id) => {
  return `${baseUrl}/prequestion/enrolled/${enrolled_id}`;
};
