import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Switch } from "antd";
import { usersActions } from "../../../../_state/actions";

function MarketingAgreement({
  marketingAgreement,
  setMarketingAgreement,
  getMarketingAgreement,
}) {
  useEffect(() => {
    if (!marketingAgreement.loaded) {
      getMarketingAgreement();
    }
  }, []);
  return (
    <div>
      <Switch
        checked={
          marketingAgreement.loaded
            ? marketingAgreement.data.marketingAgreement
            : true
        }
        onClick={() => {
          setMarketingAgreement({
            marketingAgreement: !marketingAgreement.data.marketingAgreement,
          });
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, marketingAgreement } = state;
  const { user } = state.authentication;
  return {
    authentication,
    user,
    marketingAgreement,
  };
}

const connectedMarketingAgreement = connect(mapStateToProps, {
  getMarketingAgreement: usersActions.getMarketingAgreement,
  setMarketingAgreement: usersActions.setMarketingAgreement,
})(MarketingAgreement);
export { connectedMarketingAgreement as MarketingAgreement };
