import styled from "@emotion/styled";

export const TimeTooltip = styled.div`
  position: absolute;
  top: -30px; /* Adjust this value to control the distance from the progress bar */
  left: 0;
  transform: translateX(-50%);
  background-color: #e8344e;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
`;
