import styled from "@emotion/styled";
import {
  mBody4,
  neutralDay,
  scc,
  wBody3,
  wBody4,
  wCaption2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const ToastWrapper = styled.div`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  z-index: 10;
  max-width: 772px;
  height: fit-content;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${scc.red50};
  background: ${scc.red10};
  position: fixed;
  top: 112px;
  left: ${({ isOpenList }) => (isOpenList ? "36%" : "49%")};
  transform: translateX(-50%);
  box-shadow: 0 8px 24px 0 rgba(95, 102, 107, 0.12);
  ${DisplayResolution.MobileAndMiniTablet} {
    width: calc(100% - 32px);
    left: 0;
    transform: translateX(0);
    padding: 16px;
    top: 66px;
    margin: 16px;
    align-items: flex-start;
  }
  ${DisplayResolution.Tablet} {
    width: ${({ isOpenList }) =>
      isOpenList ? "calc(75% - 32px)" : "calc(100% - 32px)"};
    padding: 16px;
    top: 66px;
    margin: 16px;
  }
  ${DisplayResolution.MiniDesktopAndDesktop} {
    width: ${({ isOpenList }) =>
      isOpenList ? "calc(75% - 32px)" : "calc(100% - 32px)"};
    padding: 16px;
    top: 66px;
    margin: 16px;
  }
`;

export const CountDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  ${DisplayResolution.MobileAndMiniTablet} {
    align-items: flex-start;
    width: 90%;
  }
`;

export const CircleContainer = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${DisplayResolution.MobileAndMiniTablet} {
    padding-top: 2px;
  }
`;

export const CircleBackground = styled.circle`
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
`;

export const CircleProgress = styled.circle`
  fill: none;
  stroke: ${scc.red100};
  stroke-width: 2px;
  stroke-dasharray: 75px; // Approx circumference of the circle (radius 50px)
  stroke-dashoffset: ${({ percentage }) => `${75 - (75 * percentage) / 10}px`};
  transition: stroke-dashoffset 1s linear;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const CountDownText = styled.text`
  ${wCaption2};
  fill: ${scc.red100};
  color: ${scc.red100};
  text-anchor: middle;
  dominant-baseline: middle;
  font-weight: 600;
  transform: translateY(1px);
`;

export const ToastMessage = styled.div`
  ${wBody4};
  color: ${neutralDay.gray90};
  & > span {
    ${wBody3};
    color: ${scc.red100};
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    color: ${neutralDay.gray90};
    & > span {
      ${mBody4};
      color: ${scc.red100};
    }
  }
`;

export const CloseButton = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    padding-top: 2px;
  }
`;
