export const AssetPath = "/source/src/assets";

export const getRangeOfNumber = (n, start = 0) => {
  let range = [];
  for (let i = start; i <= n; i++) range.push(i);
  return range;
};

export const padZero = (dateUnit) => {
  if (dateUnit < 10) {
    return "0" + dateUnit;
  } else {
    return dateUnit;
  }
};

export const urlEncode = (data) => {
  const urlEncodedKeys = Object.keys(data).map(
    (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
  );
  return urlEncodedKeys.join("&");
};
export const getCurrentFormattedDate = () => {
  const date = new Date();

  const YYYY = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
  const DD = String(date.getDate()).padStart(2, "0");
  const HH24 = String(date.getHours()).padStart(2, "0");
  const MI = String(date.getMinutes()).padStart(2, "0");
  const SS = String(date.getSeconds()).padStart(2, "0");

  return `${YYYY}-${MM}-${DD} ${HH24}:${MI}:${SS}`;
};

export const isMOTPInputValid = (inputOTP) => /^\d{6}$/.test(inputOTP);
