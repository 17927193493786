import React, { useEffect } from "react";
import { connect } from "react-redux";
import { history } from "../../_helpers";
import { getCookie } from "../../v2/_helper";
import { params } from "../../v2/_helper";
import { usersActions } from "../../v2/_state/actions";
import { consoleLogForDev } from "../../v2/utils/consoleLog";

function TokenPage({ user, loginWithToken }) {
  useEffect(() => {
    let token = getCookie("token");
    if (user) {
      consoleLogForDev(true, "🙏 tokenPage - params().next", params().next);
      const nextUrl = decodeURIComponent(params().next);
      if (/^https?:\/\//i.test(nextUrl)) {
        window.location = nextUrl;
      } else {
        history.replace(nextUrl);
      }
    } else if (token) {
      loginWithToken(token);
    }
  }, [user]);
  return <div></div>;
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedTokenPage = connect(mapStateToProps, {
  loginWithToken: usersActions.loginWithToken,
})(TokenPage);
export { connectedTokenPage as TokenPage };
