import { useParams } from "react-router-dom";
import { useEnrolledDetail } from "../../hooks/useProductName";
import { useEffect, useState } from "react";
import { lectureTimeConvertor } from "../../_helper";

export const useCheckCurrentPlayerTime = () => {
  const { enrolled_id, detail_id } = useParams();
  const enrolledDetail = useEnrolledDetail(enrolled_id, detail_id);
  const [currentTime, getLectureProgress] =
    useCheckCurrentPlayerTimeWithEnrolledDetail(enrolledDetail);
  return [currentTime, getLectureProgress];
};

export const useCheckCurrentPlayerTimeWithEnrolledDetail = (enrolledDetail) => {
  const enrolledDetailData = enrolledDetail?.data;
  const lectureId = enrolledDetailData?.lecture_id;
  const [currentTime, setCurrentTime] = useState("00:00");
  const getLectureProgress = () => {
    if (lectureId) {
      const currentPlayerTime = localStorage.getItem(
        `videoProgress_${lectureId}`
      );
      if (currentPlayerTime) {
        const convertedTime = lectureTimeConvertor(parseInt(currentPlayerTime));
        setCurrentTime(convertedTime);
      }
    }
  };
  return [currentTime, getLectureProgress];
};

export const handleFeedbackTooltipSeenCount = () => {
  const feedbackTooltipSeenCount = +localStorage.getItem(
    "feedbackTooltipSeenCount"
  );
  const setFeedbackTooltipSeenCount = (targetCount) => {
    if (targetCount) {
      localStorage.setItem("feedbackTooltipSeenCount", `${targetCount}`);
    }
  };
  return [feedbackTooltipSeenCount, setFeedbackTooltipSeenCount];
};
