import * as S from "./present.style";
import { useSelector } from "react-redux";
import { DownloadLine, neutralDay } from "@teamsparta/design-system";
import { useState } from "react";
import { useIssueCoupons } from "../../../hooks/finish";
import { couponSets } from "./present.model";
import { logOnClickIssueCoupon } from "../../../hooks/finish/present";

const CouponSet = ({ couponSet, onClickCoupon }) => {
  return (
    <S.Coupon>
      <S.CouponInfoContainer>
        <S.CouponContentContainer>
          <S.CouponName>{couponSet.name}</S.CouponName>
          <S.CouponAmount>{couponSet.amount.toLocaleString()}원</S.CouponAmount>
        </S.CouponContentContainer>
        <S.CouponNotices>
          {couponSet.notices.map((notice) => {
            return <S.CouponNotice>- {notice}</S.CouponNotice>;
          })}
        </S.CouponNotices>
      </S.CouponInfoContainer>
      <S.CouponCutLine />
      <S.CouponButton
        onClick={() => {
          onClickCoupon(couponSet);
        }}
      >
        {<DownloadLine size={24} color={neutralDay.white} />}
      </S.CouponButton>
    </S.Coupon>
  );
};

const SpecialCouponSet = ({ couponSet, onClickCoupon }) => {
  return (
    <S.SpecialCouponWrapper>
      <S.SpecialCoupon>
        <S.ShineWrapper>
          <S.SpecialCouponShine />
        </S.ShineWrapper>
        <S.CouponInfoContainer>
          <S.CouponContentContainer>
            <S.SpecialCouponName>{couponSet.name}</S.SpecialCouponName>
            <S.SpecialCouponAmount>
              {couponSet.amount.toLocaleString()}원
            </S.SpecialCouponAmount>
          </S.CouponContentContainer>
          <S.CouponNotices>
            {couponSet.notices.map((notice) => {
              return <S.SpecialCouponNotice>- {notice}</S.SpecialCouponNotice>;
            })}
          </S.CouponNotices>
        </S.CouponInfoContainer>
        <S.SpecialCouponTooltip
          src={"/v2/assets/images/rcmd/special_coupon_tooltip.png"}
        />
        <S.SpecialCouponBadge
          src={"/v2/assets/images/rcmd/special_coupon_badge.png"}
        />
        <S.CouponRightBlur />
        <S.CouponButton
          onClick={() => {
            onClickCoupon(couponSet);
          }}
        >
          {<DownloadLine size={24} color={neutralDay.white} />}
        </S.CouponButton>
      </S.SpecialCoupon>
    </S.SpecialCouponWrapper>
  );
};

const Coupons = ({ onClickCoupon, currentCourseId }) => {
  return (
    <S.Coupons>
      {/*<S.Rtan src={`/v2/assets/rtan/rtan_cheering.png`} />*/}
      {couponSets.map((couponSet) => {
        if (
          couponSet.name === "알고리즘 강의 할인 쿠폰" &&
          couponSet.targetCourseId !== currentCourseId
        )
          return null;
        if (couponSet.targetCourseId === currentCourseId) {
          return (
            <SpecialCouponSet
              onClickCoupon={onClickCoupon}
              couponSet={couponSet}
            />
          );
        }
        return (
          <CouponSet onClickCoupon={onClickCoupon} couponSet={couponSet} />
        );
      })}
    </S.Coupons>
  );
};

const FreeFinishPagePresent = () => {
  const { user } = useSelector((state) => state.authentication);
  const enrolled = useSelector((state) => state.enrolled);
  const currentCourseId = enrolled?.data?.course_id;
  const [toastMessage, setToastMessage] = useState(null);
  const [isShowToast, setIsShowToast] = useState(false);
  const issueCoupons = useIssueCoupons(setToastMessage, setIsShowToast);

  return (
    <S.Container>
      <S.Toast isShow={!!isShowToast}>{toastMessage}</S.Toast>
      <S.Title>
        짠! {user.name} 님께 드리는
        <br />
        완강 선물이에요.
      </S.Title>
      <S.SubTitle>축하의 마음을 담아 푸짐한 쿠폰을 준비했어요.</S.SubTitle>
      <Coupons
        onClickCoupon={(couponSet) => {
          issueCoupons.mutate({
            userId: user._id,
            couponIds: couponSet.couponIds,
            setToastMessage,
          });
          logOnClickIssueCoupon(enrolled, couponSet);
        }}
        currentCourseId={currentCourseId}
      />
    </S.Container>
  );
};

export default FreeFinishPagePresent;
