import { certConstants } from "../_constants/certs.constants";
import { certService } from "../_services/certs.service";

function getCertInfo() {
  function request() {
    return { type: certConstants.CERT_REQUEST };
  }

  function success(response) {
    return { type: certConstants.CERT_SUCCESS, response };
  }

  function failure(error) {
    return { type: certConstants.CERT_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    certService.getCertInfo().then(
      (response) => {
        console.log(response);
        dispatch(success(response));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error));
      }
    );
  };
}

export const certActions = {
  getCertInfo,
};
