import { HOMEWORKS } from "../constants";

const initState = {
  loaded: false,
};

export function homeworks(state = initState, action) {
  switch (action.type) {
    case HOMEWORKS.GET_HOMEWORKS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case HOMEWORKS.GET_HOMEWORKS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case HOMEWORKS.GET_HOMEWORKS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function homeworkAnswer(state = initState, action) {
  switch (action.type) {
    case HOMEWORKS.GET_HOMEWORK_ANSWER_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case HOMEWORKS.GET_HOMEWORK_ANSWER_SUCCESS:
      // window.location.reload();
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case HOMEWORKS.GET_HOMEWORK_ANSWER_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
        error: action.error,
      });
    default:
      return state;
  }
}
