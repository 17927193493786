import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiCheckinInfoUrl = (round_id, offset = 0, num) => {
  if (offset === 0) {
    return `${baseUrl}/rounds/${round_id}/checkins`;
  } else {
    return `${baseUrl}/rounds/${round_id}/checkins?offset=${offset}&num=${num}`;
  }
};

export const getApiCheckinLikeUrl = (round_id, checkin_id) => {
  return `${baseUrl}/rounds/${round_id}/checkins/${checkin_id}`;
};

export const getApiCheckoutInfoUrl = (round_id, offset = 0, num) => {
  if (offset === 0) {
    return `${baseUrl}/rounds/${round_id}/checkouts`;
  } else {
    return `${baseUrl}/rounds/${round_id}/checkouts?offset=${offset}&num=${num}`;
  }
};

export const getApiCheckoutLikeUrl = (round_id, checkin_id) => {
  return `${baseUrl}/rounds/${round_id}/checkouts/${checkin_id}`;
};

export const getApiCheckinSkipUrl = (round_id) => {
  return `${baseUrl}/rounds/${round_id}/checkins/skip`;
};

export const getApiCheckoutSkipUrl = (round_id) => {
  return `${baseUrl}/rounds/${round_id}/checkouts/skip`;
};
