import React from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classNames from "classnames/bind";
import styles from "./Nav.scss";
import { initEnvironment, userActions } from "../_actions";
import "./Nav.css";
import { createEventId, history, params } from "../_helpers";
import { sendLog } from "../v2/businesslogics/logging";
import { getCookie } from "../v2/_helper";

const cx = classNames.bind(styles);
const LOGO_TYPE = {
  LOGO: 1,
  BACK: 2,
  OFF_HOME: 3,
};

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      logoType: LOGO_TYPE.OFF_HOME,
      logoText: "SPARTA!",
    };
    const { _initEnvironment } = props;
    _initEnvironment();
  }

  componentDidMount() {
    const { deviceType } = this.props.environment;
    this.setState({
      isMobile: deviceType === "mobile",
    });
    if (
      history.location.pathname === "/login" ||
      history.location.pathname === "/signup" ||
      history.location.pathname === "/payment" ||
      history.location.pathname === "/payment/done"
    ) {
      this.setState({
        logoType: LOGO_TYPE.BACK,
      });
    } else {
      this.setState({
        logoType: LOGO_TYPE.OFF_HOME,
      });
    }
    const { user } = this.props;
    let token = getCookie("token");
    if (token && !user) {
      this.props.loginWithToken(token);
      if (params().is_new) {
        const eventID = createEventId();
        sendLog("complete_registration", {}, eventID);
      }
    }
  }

  handleClick = (e) => {
    if (e.key === "logo") {
      return;
    }
    this.setState({
      current: e.key,
    });
  };

  logout() {
    const { user } = this.props;
    if (user) {
      this.props.logout();
      window.location.href = "https://spartacodingclub.kr";
    }
  }

  handleLogoMouseOver = () => {
    this.setState({
      logoText: "SPARTA!!!",
    });
  };
  onMouseLeave = () => {
    this.setState({
      logoText: "SPARTA!",
    });
  };

  handleLogoClick = () => {
    sendLog(
      "scc_classroomPage_click_button",
      {
        button_text: "메인",
        next_page: "mainPage",
        details: "내 강의실 navBar 스파르타 로고",
      },
      "",
      true
    );
    window.location.href = process.env.REACT_APP_SCC_URL;
  };

  render() {
    const { checkPlatform } = window;
    const platform = checkPlatform();
    const { user } = this.props;
    const { logoType, isMobile } = this.state;
    const logoSrc = isMobile ? "logo-mobile" : "logo-web";
    const nextPathname =
      "/login?next=" + encodeURIComponent(document.location.pathname);
    return (
      <div className={cx("navBg", this.props.className)}>
        <Menu
          className={cx("navContainer")}
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
        >
          <Menu.Item className={cx("logo")} key="logo">
            {logoType === LOGO_TYPE.OFF_HOME && (
              <img
                src={`/assets/icons/${logoSrc}.svg`}
                onClick={this.handleLogoClick}
                onMouseOver={this.handleLogoMouseOver}
                onMouseLeave={this.onMouseLeave}
              />
            )}

            {logoType !== LOGO_TYPE.OFF_HOME &&
              platform === "mobile" &&
              logoType === LOGO_TYPE.LOGO && (
                <a className={cx("logoImg")} href="/">
                  <img
                    src={require("../assets/images/logo.png")}
                    alt="SPARTA LOGO"
                  />
                </a>
              )}
            {logoType !== LOGO_TYPE.OFF_HOME &&
              platform === "pc" &&
              logoType === LOGO_TYPE.LOGO && (
                <a className={cx("logoImg")} href="/">
                  <img
                    src={require("../assets/images/logo_pc.png")}
                    alt="SPARTA LOGO"
                  />
                </a>
              )}
            {logoType !== LOGO_TYPE.OFF_HOME && logoType === LOGO_TYPE.BACK && (
              <a onClick={() => history.goBack()}>
                <ArrowLeftOutlined className={cx("btnBack")} />
              </a>
            )}
          </Menu.Item>
          {/*{(platform === 'pc') && (user && user.name) && (history.location.pathname !== '/mypage') &&*/}
          {/*<Menu.Item className={cx('menuitems')} key='setting:logout' onClick={() => this.logout()}>*/}
          {/*    로그아웃*/}
          {/*</Menu.Item>*/}
          {/*}*/}
          {user &&
            user.name &&
            history.location.pathname === "/classroom" &&
            !window.location.pathname.includes("/payment") && (
              <>
                <Menu.Item className={cx("menuitems")}>
                  <a
                    href="/mypage"
                    onClick={() => {
                      sendLog(
                        "scc_classroomPage_click_button",
                        {
                          button_text: "마이페이지",
                          next_page: "mypagePage",
                          details: "classroom 페이지 navBar 마이페이지 버튼",
                        },
                        "",
                        true
                      );
                    }}
                  >
                    마이페이지
                  </a>
                </Menu.Item>
                <Menu.Item className={cx("menuitems_isActive")}>
                  <a
                    href="/classroom"
                    onClick={() => {
                      sendLog(
                        "scc_classroomPage_click_button",
                        {
                          button_text: "내 강의실",
                          next_page: "classroomPage",
                          details: "classroom 페이지 navBar 내 강의실 버튼",
                        },
                        "",
                        true
                      );
                    }}
                  >
                    내 강의실
                  </a>
                </Menu.Item>
              </>
            )}
          {user &&
            user.name &&
            history.location.pathname !== "/mypage" &&
            history.location.pathname !== "/classroom" &&
            !window.location.pathname.includes("/payment") && (
              <>
                <Menu.Item className={cx("menuitems")}>
                  <a href="/mypage">마이페이지</a>
                </Menu.Item>
                <Menu.Item className={cx("menuitems")}>
                  <a href="/classroom">내 강의실</a>
                </Menu.Item>
              </>
            )}
          {user &&
            user.name &&
            history.location.pathname === "/mypage" &&
            !window.location.pathname.includes("/payment") && (
              <>
                <Menu.Item className={cx("menuitems_isActive")}>
                  <a href="/mypage">마이페이지</a>
                </Menu.Item>
                <Menu.Item className={cx("menuitems")}>
                  <a href="/classroom">내 강의실</a>
                </Menu.Item>
              </>
            )}
          {platform === "pc" && !user && (
            <Menu.Item className={cx("menuitems")}>
              <a href={nextPathname}>로그인</a>
            </Menu.Item>
          )}
          {/*{(!user) &&*/}
          {/*    <Menu.Item className={cx('menuitems')} >*/}
          {/*        <a href="https://spartacodingclub.kr">*/}
          {/*            스파르타 홈*/}
          {/*        </a>*/}
          {/*    </Menu.Item>*/}
          {/*}*/}
        </Menu>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, environment } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    environment,
  };
}

const connectedNav = connect(mapStateToProps, {
  logout: userActions.logout,
  loginWithToken: userActions.loginWithToken,
  _initEnvironment: initEnvironment,
})(Nav);
export { connectedNav as Nav };
