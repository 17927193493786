import { config } from "../config";
import { handleResponse, authHeader, privateFetch } from "../_helpers";

function getRankInfo(round_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/rounds/${round_id}/rank`;
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

export const rankService = {
  getRankInfo,
};
