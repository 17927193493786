import React from "react";
import classNames from "classnames/bind";
import styles from "./RoadmapBasicComponent.module.scss";

const cx = classNames.bind(styles);

function RoadmapBasicComponent({ title, type, data, isForever }) {
  return (
    <div
      className={cx(
        "roadmap-basic-component",
        `${type === "plain" ? "--plain-text" : ""}`
      )}
    >
      <div
        className={cx(
          "roadmap-basic-component__bg",
          `${type === "plain" ? "--plain-text" : ""}`
        )}
      >
        <div className={cx("roadmap-basic-component__bg__title")}>{title}</div>
        <div className={cx("roadmap-basic-component__bg__content")}>
          {type === "date" && (
            <>
              {data.forever && (
                <>
                  <span
                    className={cx("roadmap-basic-component__bg__content--big")}
                  >
                    평생!
                  </span>
                </>
              )}
              {!data.forever && (
                <>
                  <span
                    className={cx("roadmap-basic-component__bg__content--big")}
                  >
                    {data.week}
                  </span>
                  {data.units.week}
                  &nbsp;+&nbsp;
                  <span
                    className={cx("roadmap-basic-component__bg__content--big")}
                  >
                    {data.day}
                  </span>
                  {data.units.day}
                </>
              )}
            </>
          )}
          {type === "percent" && (
            <>
              <span className={cx("roadmap-basic-component__bg__content--big")}>
                {data.percent}
              </span>
              {data.units.percent}
            </>
          )}
          {type === "plain" && (
            <div className={cx("roadmap-basic-component__bg__content__text")}>
              {data.content}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { RoadmapBasicComponent };
