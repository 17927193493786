import styled from "@emotion/styled";
import {
  mBody1,
  mBody3,
  mHeader2,
  mTitle2,
  neutralDay,
  scc,
  wBody3,
  wTitle1,
  wTitle2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px 16px;
  gap: 40px;
  flex-direction: column;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    padding: 60px 0px 80px 0px;
    width: 772px;
    gap: 80px;
    flex-direction: row;
  }
`;

export const PromoteTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 50%;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    gap: 12px;
  }
`;

export const PromoteTitle = styled.div`
  width: 100%;
  ${mTitle2};
  color: ${scc.red75};
`;

export const PromoteContent = styled.div`
  width: 100%;
  white-space: pre-line;
  ${mHeader2};
  color: ${neutralDay.white};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wTitle1};
    color: ${neutralDay.white};
  }
`;

export const PromoteCaution = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: ${neutralDay.gray50};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${neutralDay.gray50};
  }
`;

export const PromoteCard = styled.div`
  flex: 1 1 50%;
  box-sizing: border-box;
  display: flex;
  padding: 32px 20px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid ${neutralDay.gray90};
  background: ${neutralDay.gray95};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
`;

export const PromoteTrackImgWrapper = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const CardCaption = styled.div`
  ${wBody3};
  color: ${neutralDay.white};
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  padding: 4px 10px;
  border-radius: 100px;
  background: rgba(20, 22, 23, 0.4);
`;

export const PromoteTrackImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const PromoteTrackTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const PromoteTrackCaption = styled.p`
  ${mBody1};
  color: ${neutralDay.gray50};
  margin: 0;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wBody3}
    color: ${neutralDay.gray50};
  }
`;

export const PromoteTrackTitle = styled.p`
  ${mHeader2};
  color: ${neutralDay.white};
  margin: 0;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wTitle2};
    color: ${neutralDay.white};
  }
`;

export const MoreAboutBootcampButton = styled.button`
  ${mTitle2};
  cursor: pointer;
  border: none;
  color: #fff;
  display: flex;
  height: 54px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: ${scc.red100};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${mTitle2};
    color: #fff;
  }
`;
