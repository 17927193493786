import styled from "@emotion/styled";
import { LECTURE_GNB_HEIGHT } from "../../../../LecturePageV3/variables";

export const TotalWrapper = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.isDesktop ? "38.5%" : "55.5%")};
  position: absolute;
  display: flex;
  overflow: hidden;
  top: ${LECTURE_GNB_HEIGHT}px;
`;

export const PlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .video-js {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;
