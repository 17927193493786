const RoadMap = {
  title: "내 진도표",
  src: "ic_logo",
};
const codeSnippet = {
  title: "코드스니펫",
  src: "ic_code_snippet",
};
const material = {
  title: "강의자료",
  src: "ic_material",
};
const qna = {
  title: "즉문즉답",
  src: "ic_qna",
};
const report = {
  title: "오류 제보",
  src: "ic_report",
};
const faq = {
  title: "FAQ",
  src: "ic_faq",
};
const settings = {
  title: "수강환경 설정",
  src: "ic_settings",
};

export const lectureMenuComponents = [
  RoadMap,
  codeSnippet,
  material,
  qna,
  report,
  faq,
  settings,
];
