import React, { Component } from "react";
import classNames from "classnames/bind";
import { DashboardEn } from "../../_components/Roadmaps";
import { NavContainerEn } from "../../_containers";
import { getPostAuthOptions } from "../../_urls/index";
import styles from "./RoadmapPage.module.scss";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import { dashboardActions, userActions } from "../../_actions";
import { handleResponse, privateFetch } from "../../_helpers";
import { getApiAccount, getApiStudyplan } from "../../_urls/accounts.api";
import { RoadmapLectureEn } from "../../_components/Roadmaps/RoadmapLecture";
import { RoadmapHomeworkEn } from "../../_components/Roadmaps/RoadmapHomework";

const cx = classNames.bind(styles);
const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

class RoadmapPageEn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course_id: this.props.match.params.course_id,
      weeks: [],
      modalVisible: false,
      isSending: false,
      phone1: "",
      phone2: "",
      phone3: "",
      name1: "",
      name2: "",
      name3: "",
      edit_plan: false,
      visible_studyplan: true,
      studyplan_done: false,
      studyplan: {
        0: {
          time: null,
          checked: false,
        },
        1: {
          time: null,
          checked: false,
        },
        2: {
          time: null,
          checked: false,
        },
        3: {
          time: null,
          checked: false,
        },
        4: {
          time: null,
          checked: false,
        },
        5: {
          time: null,
          checked: false,
        },
        6: {
          time: null,
          checked: false,
        },
        cnt: 0,
      },
      expDetailShow: false,
      treasureModalVisible: false,
      name_confirmed: false,
      lEModalVisible: true,
      subsetVisible: true,
    };
  }

  componentDidMount() {
    const round_id = this.props.match.params.round_id;
    this.props.getDashboard(round_id);
    this.props.refreshInfo();

    const { checkPlatform } = window;
    const isMobile = checkPlatform() === "mobile";
    this.setState({
      isMobile,
      forever_height: isMobile ? 363 : 384,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.authentication.isFetching &&
      !this.props.authentication.isFetching &&
      this.props.authentication.loggedIn
    ) {
      this.setState({
        name_confirmed: this.props.authentication.user.name_confirmed,
        new_name: this.props.authentication.user.name,
      });
    }

    if (prevProps.dashboard.loaded === false && this.props.dashboard.loaded) {
      const { studyplans, studyplan_prize_done } = this.props.dashboard.data;
      if (!studyplans) {
        return;
      }

      // 기존에 설정된 나와의 약속이 있으면 state를 업데이트한다.
      let { studyplan } = this.state;
      studyplan.cnt = 0;
      for (const plan of studyplans) {
        studyplan[plan.day].checked = true;
        studyplan.cnt += 1;
        if (document.getElementById(days[plan.day])) {
          document.getElementById(days[plan.day]).checked = true;
        }
      }

      this.setState({
        studyplan,
      });

      if (studyplan_prize_done) {
        return;
      }

      // 당일이면 보물상자가 뜬다.
      const today = new Date().getDay();
      for (const plan of studyplans) {
        if (plan.day === today) {
          this.setState({
            treasureModalVisible: true,
          });
        }
      }
    }
  }

  showModal = () => {
    let { modalVisible } = this.state;
    this.setState({ modalVisible: !modalVisible });
  };

  handleModalRatingVisible = (isVisible) => {
    this.setState({
      modalVisible: isVisible,
    });
  };

  handleLEModalVisible = (isVisible) => {
    this.setState({
      lEModalVisible: isVisible,
    });
  };

  handlePhoneChange = (e) => {
    this.fillHyphen(e.target.value, e.target.id);
  };

  handleNameChange = (e) => {
    let id = e.target.id;
    let name = e.target.value.trim();
    if (id === "name1") {
      this.setState({
        name1: name,
      });
    } else if (id === "name2") {
      this.setState({
        name2: name,
      });
    } else if (id === "name3") {
      this.setState({
        name3: name,
      });
    }
  };

  _numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  fillHyphen = (phone, id) => {
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }
    if (id === "phone1") {
      this.setState({
        phone1: phone,
      });
    } else if (id === "phone2") {
      this.setState({
        phone2: phone,
      });
    } else if (id === "phone3") {
      this.setState({
        phone3: phone,
      });
    }
  };

  validatePhone = (phone) => {
    const re = /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/;
    return re.test(phone);
  };

  comfirmName = () => {
    const { name } = this.props.user;
    const { new_name } = this.state;
    if (
      (!new_name || new_name === "") &&
      document.getElementById("input-confirm").value !== name
    ) {
      alert("실명을 입력해주세요.");
      return;
    }

    this.setState({
      isSending: true,
    });
    const endpoint = getApiAccount();
    const options = getPostAuthOptions({ new_name });
    privateFetch(endpoint, options)
      .then(handleResponse)
      .then((resp) => {
        if (resp.ok) {
          this.props.refreshInfo();
          this.setState({
            isSending: false,
          });
        }
      });
  };

  createStudyplan = () => {
    let { studyplan, studyplan_done, course_id } = this.state;
    if (studyplan_done === false) {
      alert("공부할 날짜 2일을 선택해주세요!");
      return;
    }
    let studyplans = [];
    for (const [k, v] of Object.entries(studyplan)) {
      if (v.checked) {
        studyplans.push({
          day: parseInt(k),
        });
      }
    }

    this.setState({
      isSending: true,
    });

    const data = { studyplans };
    const endpoint = getApiStudyplan(course_id);
    const options = getPostAuthOptions(data);
    fetch(endpoint, options)
      .then(handleResponse)
      .then(
        (resp) => {
          this.setState({
            isSending: false,
            visible_studyplan: false,
            edit_plan: false,
          });
          if (resp.ok) {
            alert("'나와의 약속' 설정이 완료되었습니다!");
            this.props.getDashboard(this.state.course_id);
          }
        },
        (error) => {
          alert(error);
          this.setState({
            isSending: false,
            visible_studyplan: false,
          });
          return { ok: false };
        }
      );
  };

  checkStudyDay = (e) => {
    let day = e.target.value;
    let checked = e.target.checked;
    let { studyplan } = this.state;
    let { cnt } = studyplan;
    let studyplan_done = false;
    studyplan[day].checked = checked;
    studyplan.cnt = checked ? cnt + 1 : cnt - 1;

    if (studyplan.cnt > 2) {
      studyplan.cnt = 2;
      studyplan[day].checked = false;
      e.target.checked = false;
      alert("2일까지만 선택할 수 있어요!");
      return;
    } else if (studyplan.cnt == 2) {
      studyplan_done = true;
    }

    this.setState({
      studyplan,
      studyplan_done,
    });
  };

  _setName = (e) => {
    this.setState({
      new_name: e.target.value,
    });
  };

  closeTreasureBox = () => {
    this.setState({
      treasureModalVisible: false,
    });
  };

  showPlan = () => {
    this.setState({
      edit_plan: true,
    });
  };

  handleSubsetVisible = () => {
    this.setState((prevState) => {
      return {
        subsetVisible: !prevState.subsetVisible,
      };
    });
  };

  render() {
    const { dashboard } = this.props;

    const {
      modalVisible,
      edit_plan,
      studyplan_done,
      visible_studyplan,
      expDetailShow,
      treasureModalVisible,
      name_confirmed,
      subsetVisible,
      isMobile,
      forever_height,
    } = this.state;
    if (!dashboard.loaded || dashboard.data === undefined) {
      return <Skeleton />;
    }
    if (!this.props.user) {
      return <Skeleton />;
    }
    const { studyplans, is_free, round_id, is_forever } = dashboard.data;
    const weeks = dashboard.data["lecture_details"];
    const course_id = this.props.match.params.course_id;
    let data = { round_id, studyplans };

    return (
      <div className={cx("wrapper")}>
        <NavContainerEn />
        <div className={cx("roadmapPageContainer")}>
          <DashboardEn data={data} showPlan={this.showPlan} />
          {weeks &&
            weeks.map((week, idx) => {
              // 이전 주차 수업 수강 모두 완료 && 이전 주차 숙제 완료
              const isWeekAllowed =
                weeks.slice(0, idx).reduce((acc, cur, i) => {
                  return acc && cur.lecture.info.is_done;
                }, true) &&
                (idx === 0 ||
                  weeks[idx - 1].homeworks.reduce((acc, cur, i) => {
                    return acc & cur.done;
                  }, true)) &&
                week.lecture.info.is_allowed;
              return (
                <div key={idx} className={cx("weekContentWrapper")}>
                  <RoadmapLectureEn
                    data={week.lecture}
                    course_id={course_id}
                    is_free={is_free}
                    round_id={round_id}
                    is_forever={is_forever}
                    is_week_allowed={isWeekAllowed}
                  />
                  {week.homeworks.map((homework, homework_idx) => {
                    const isHomeworkAllowed =
                      week.lecture.info.is_done &&
                      week.lecture.info.is_allowed &&
                      week.homeworks
                        .slice(0, homework_idx)
                        .reduce((acc, cur, i) => {
                          return acc & cur.done;
                        }, true);
                    return (
                      <RoadmapHomeworkEn
                        week={idx + 1}
                        key={homework_idx}
                        data={homework}
                        course_id={course_id}
                        is_free={is_free}
                        round_id={round_id}
                        is_homework_allowed={isHomeworkAllowed}
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, dashboard } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    dashboard,
  };
};

const connectedRoadmapPageEn = connect(mapStateToProps, {
  getDashboard: dashboardActions.getDashboard,
  refreshInfo: userActions.refreshInfo,
})(RoadmapPageEn);
export { connectedRoadmapPageEn as RoadmapPageEn };
