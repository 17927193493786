import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LecMenuComponent } from "../LecturePage/LecMenu/LecMenuComponent";
import { lectureMenuComponents } from "../LecturePage/LecMenu/constants";
import { BroadcastContainer } from "../LecturePage/BroadcastContainer";
import { enrolledActions, wilActions } from "../../_state/actions";
import { npsActions } from "../../_state/actions/nps.actions";
import { coursesActions } from "../../_state/actions/courses.actions";
import { sendLog } from "../../businesslogics/logging";
import classNames from "classnames/bind";
import styles from "./WILPage.module.scss";
import * as S from "./WilPage.style";
import { RightToggleSvg } from "./WilPage.svg";
import { useOnlineTheme } from "../../queries/pirates";
import { useLogWilPageClick } from "../../hooks/homework/useLogHomeworkClick";
import { useParams } from "react-router-dom";

const cx = classNames.bind(styles);

function WILPage({}) {
  const { enrolled_id } = useParams();
  const theme = useOnlineTheme();

  useEffect(() => {
    sendLog("scc_wilPage_view", enrolled_id);
  }, []);
  const logClickWilPageButton = useLogWilPageClick();

  return (
    <S.Page theme={theme.data}>
      {
        <>
          <div className={cx("wil-page__nav")}>
            <div className={cx("wil-page__nav__bg", theme.data)} />
            <LecMenuComponent
              component={lectureMenuComponents[0]}
              idx={0}
              isDual={false}
              isNav={true}
            />
            <BroadcastContainer />
          </div>

          <S.Contents>
            <S.Instruction>
              <S.InstructionTitle theme={theme.data}>
                개발일지를 작성해 보세요.
              </S.InstructionTitle>
              <S.InstructionDesc theme={theme.data}>
                이번 주차에 내가 배운 내용을 글로 기록하는 것이에요.
                <br />
                자신이 알고 있는 내용을 글로 정리하면 배운 내용을 다시 복습하게
                되고, 기억에도 더 오래 남아요. 기록하는 습관을 만들면 현업에서
                글로 소통해야 할 때도 도움이 될 거예요.
              </S.InstructionDesc>
              <S.InstructionCta
                theme={theme.data}
                target={"_blank"}
                href={"https://spartacodingclub.kr/community/wil/all"}
                onClick={() => {
                  logClickWilPageButton(
                    "개발일지 작성하기",
                    "https://spartacodingclub.kr/community/wil/all"
                  );
                }}
              >
                개발일지 작성하기
              </S.InstructionCta>
            </S.Instruction>
            <S.CardsContainer>
              <S.ExampleHeader theme={theme.data}>
                어떻게 작성해야할지 막막하다면 아래 예시를 확인하세요!
              </S.ExampleHeader>
              <S.Cards>
                <S.Card
                  theme={theme.data}
                  target={"_blank"}
                  href={
                    "https://spartacodingclub.kr/community/freeboard/all/63bec3a44e4fcbc2a1493799/%EC%8A%A4%ED%8C%8C%EB%A5%B4%ED%83%80%20%EC%BD%94%EB%94%A9%ED%81%B4%EB%9F%BD%20SQL%204%EC%A3%BC%EC%B0%A8%20%EA%B0%9C%EB%B0%9C%EC%9D%BC%EC%A7%80%20%26%20%EA%B0%95%EC%9D%98%EB%85%B8%ED%8A%B8"
                  }
                >
                  <S.CardHeader>
                    <S.Rtan src={`/assets/images/profile/rtan_thumb_24.png`} />
                    <S.HeaderContent>
                      <S.Name theme={theme.data}>김승환</S.Name>
                      <S.Date theme={theme.data}>2023년 1월 11일</S.Date>
                    </S.HeaderContent>
                  </S.CardHeader>
                  <S.CardTitle theme={theme.data}>
                    스파르타 코딩클럽 SQL 4주차 개발일지 & 강의노트
                  </S.CardTitle>
                  <S.CardDetail theme={theme.data}>
                    스파르타코딩클럽 SQL 4주차로 드디어 완강을 했습니다. 4주간의
                    기간이지만, 3주정도만에 다 강의를 들을 수
                    있었는데요.스파르타코딩클럽 SQL 4주차에서는 기존에 배웠던
                    쿼리문들을 활용해서 계속 연결해서 새로운 데이터들을 뽑아내는
                    문제풀이를 하면서 학습을 진행했습니다.
                  </S.CardDetail>
                  <S.CardBoard theme={theme.data}>
                    자유게시판 > 개발일지
                  </S.CardBoard>
                </S.Card>
                <S.Card
                  theme={theme.data}
                  target={"_blank"}
                  href={
                    "https://spartacodingclub.kr/community/freeboard/all/63be2a354e4fcbc2a140fe2e/%5B%EC%8A%A4%ED%8C%8C%EB%A5%B4%ED%83%80%EC%BD%94%EB%94%A9%ED%81%B4%EB%9F%BD%5D%201%EC%9D%BC%EC%B0%A8"
                  }
                >
                  <S.CardHeader>
                    <S.Rtan src={`/assets/images/profile/rtan_thumb_10.png`} />
                    <S.HeaderContent>
                      <S.Name theme={theme.data}>오윤석</S.Name>
                      <S.Date theme={theme.data}>2023년 1월 11일</S.Date>
                    </S.HeaderContent>
                  </S.CardHeader>
                  <S.CardTitle theme={theme.data}>
                    [스파르타코딩클럽] 1일차
                  </S.CardTitle>
                  <S.CardDetail theme={theme.data}>
                    코딩이란 무엇이고, 왜 배워야 하고, 어떻게 배워야 하는지에
                    대한 소개를 통해 일상에 익숙하지 않아 접근하기 어렵고
                    중요하다는 것을 알면서 등한시하는 코딩학습에 대한 가벼운
                    워밍업을 진행하였다. 그 중에서도 코딩을 쉽게 배울 수 있다는
                    Python을 활용한 교육이 진행되었고, 바로 코딩을 진행하기 보단
                    코딩을 위한 구조(분해, 패턴화, 추상화, 알고리즘)을 알아보고
                    구글을 통한 Python 코드 작성이 가능한 Colab을 소개받았다.
                  </S.CardDetail>
                  <S.CardBoard theme={theme.data}>
                    자유게시판 > 개발일지
                  </S.CardBoard>
                </S.Card>
                <S.Card
                  theme={theme.data}
                  target={"_blank"}
                  href={
                    "https://spartacodingclub.kr/community/freeboard/all/63bf70ed0af1989842d13496/%EC%9B%B9%EA%B0%9C%EB%B0%9C%20%EC%A2%85%ED%95%A9%EB%B0%98%202%EC%A3%BC%EC%B0%A8%20%EA%B0%9C%EB%B0%9C%EC%9D%BC%EC%A7%80"
                  }
                >
                  <S.CardHeader>
                    <S.Rtan src={`/assets/images/profile/rtan_thumb_08.png`} />
                    <S.HeaderContent>
                      <S.Name theme={theme.data}>김보성</S.Name>
                      <S.Date theme={theme.data}>2023년 1월 12일</S.Date>
                    </S.HeaderContent>
                  </S.CardHeader>
                  <S.CardTitle theme={theme.data}>
                    웹개발 종합반 2주차 개발일지
                  </S.CardTitle>
                  <S.CardDetail theme={theme.data}>
                    JQuery란 HTML 요소를 편리하게 조작할 수 있는 Javascript를
                    미리 작성해 둔 라이브러리 -> 미리 짜둔 코드를 가져오는
                    것이기 때문에 임포트 필수! &lt;script
                    src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"&gt;&lt;/script&gt;
                  </S.CardDetail>
                  <S.CardBoard theme={theme.data}>
                    자유게시판 > 개발일지
                  </S.CardBoard>
                </S.Card>
                <S.ShowMore
                  theme={theme.data}
                  target={"_blank"}
                  href={"https://spartacodingclub.kr/community/wil/all"}
                >
                  더 보기 <RightToggleSvg />
                </S.ShowMore>
              </S.Cards>
            </S.CardsContainer>
          </S.Contents>
        </>
      }
    </S.Page>
  );
}

function mapStateToProps(state) {
  const { roundWeeks, courseV2, enrolled, enrolledProgress } = state;
  return { roundWeeks, courseV2, enrolled, enrolledProgress };
}

const connectedWILPage = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getWIL: wilActions.getWIL,
  getCourse: coursesActions.getCourse,
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getNPS: npsActions.getNPS,
  getEnrolledProgress: enrolledActions.getEnrolledProgress,
})(WILPage);
export { connectedWILPage as WILPage };
