import { userConstants } from "../_constants";
import { userService } from "../_services";
import { history } from "../_helpers";
import { sendLog } from "../v2/businesslogics/logging";
import * as amplitude from "@amplitude/analytics-browser";

function login(username, password, next = undefined) {
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        logSignIn(user);
        if (next !== undefined) {
          const nextUrl = decodeURIComponent(next);
          if (/^http(s)?:\/\//i.test(nextUrl)) {
            window.location = nextUrl;
          } else {
            history.replace(decodeURIComponent(next));
          }
        } else {
          history.replace("/");
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function loginWithToken(token) {
  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    userService.loginWithToken(token).then(
      (user) => {
        console.log("v1 signintoken");
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function logout(next) {
  userService.logout(next);
  return { type: userConstants.LOGOUT };
}

async function logSignIn(user) {
  amplitude.setUserId(user._id);
  await sendLog("scc_login_completed", { method: "email" });
}

async function logSignup(email, kko_id, nexturltext) {
  let subdomain = "";
  switch (nexturltext) {
    case "swc":
      subdomain = "swc";
      break;
    case "nbc":
      subdomain = "nbc";
      break;
    case "hanghae":
      subdomain = "hh";
      break;
    case "chang":
      subdomain = "ch";
      break;
    case "ddingdong":
      subdomain = "dd";
      break;
    case "intellipick":
      subdomain = "intellipick";
    default:
      subdomain = "scc";
  }
  let currentUrl = window.location.href;
  if (currentUrl.includes("hyoneung")) {
    await sendLog("scc_event_hyoneung_signup_completed", {});
  } else {
    await sendLog(`${subdomain}_signup_completed`, {
      method: kko_id ? "kakao" : "email",
    });
  }
}

function signup({
  name,
  email,
  password,
  phone,
  country_code = "",
  next = undefined,
  school = "",
  region = "",
  address = "",
  gender = "",
  birthYear = "",
  birthDay = "",
  marketing_agreement,
  kko_id,
  track = "",
  nexturltext = "",
}) {
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request({ email }));
    userService
      .signup(
        name,
        email,
        password,
        phone,
        country_code,
        school,
        region,
        address,
        gender,
        birthYear,
        birthDay,
        marketing_agreement,
        kko_id,
        track,
        nexturltext
      )
      .then(
        (user) => {
          dispatch(success(user));
          dispatch(login(email, password, next));
          logSignup(email, kko_id, nexturltext);
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
}

function refresh() {
  function request() {
    return { type: userConstants.REFRESH_REQUEST };
  }

  function success() {
    return { type: userConstants.REFRESH_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.REFRESH_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    return new Promise((resolve, reject) => {
      userService.refresh().then(
        (data) => {
          dispatch(success());

          resolve(data);
        },
        (error) => {
          // userService.logout()
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
}

function refreshInfo() {
  function request() {
    return { type: userConstants.INFO_REFRESH_REQUEST };
  }

  function success(user) {
    return { type: userConstants.INFO_REFRESH_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.INFO_REFRESH_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    userService.refreshInfo().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const userActions = {
  login,
  logout,
  signup,
  refresh,
  loginWithToken,
  refreshInfo,
};
