import { useEnrolled } from "./useProductName";
import { useParams } from "react-router-dom";
import { history } from "../../_helpers";

export const useNavigateToRoadmap = () => {
  const { enrolled_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const { data: enrolledData } = enrolled;
  const roundId = enrolledData?.round_id;
  const endPoint = `/enrolleds/${enrolled_id}/rounds/${roundId}/roadmap`;
  return () => history.push(endPoint);
};
