import { config } from "../config";
import { handleResponse, authHeader } from "../_helpers";
import {
  getApiCheckinInfoUrl,
  getApiPriceByNumberUrl,
  getGETAuthOptions,
  getGETOptions,
} from "../_urls";

function getPriceByNumber(course_id) {
  const endpoint = getApiPriceByNumberUrl(course_id);
  const options = getGETOptions();
  return fetch(endpoint, options).then(handleResponse);
}

export const priceByNumberService = {
  getPriceByNumber,
};
