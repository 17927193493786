import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoadmapLecture.module.scss";
import {
  Card,
  Collapse,
  Input,
  Button,
  Tooltip,
  Modal,
  Row,
  Col,
  Rate,
} from "antd";
import {
  YoutubeFilled,
  ClockCircleFilled,
  FileTextFilled,
  UpOutlined,
  DownOutlined,
  ClockCircleOutlined,
  LockFilled,
} from "@ant-design/icons";
import { history } from "../../../_helpers";
import "./RoadmapLecture.antdesign.custom.css";
import SelectOutlined from "@ant-design/icons/lib/icons/SelectOutlined";
import UnlockFilled from "@ant-design/icons/lib/icons/UnlockFilled";
import { RoadmapHomework } from "../RoadmapHomework";

const { Panel } = Collapse;
const cx = classNames.bind(styles);

class RoadmapLecture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      comments: ["우와!", "아자!", "최고!"],
      tooltipVisible: false,
      cur: "",
    };
  }

  toHHMMSS(secs) {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  }

  toHHMM(secs) {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return hours > 0 ? `${hours}시간 ${minutes}분` : `${minutes}분`;
  }

  _goToLecture(lecture_id, isAllowed) {
    if (isAllowed === false) {
      return;
    }
    const round_id = this.props.round_id;
    history.push(`/rounds/${round_id}/lectures/${lecture_id}`);
  }

  componentDidMount() {}

  changePannel(isOpen) {
    this.setState({ isOpen: !isOpen });
  }

  _showMaterial = (e) => {
    const { material } = this.props.data.info;
    let win = window.open(material, "_blank");
    win.focus();
    e.stopPropagation();
  };

  _getComment = () => {
    let length = this.state.comments.length;
    let idx = Math.floor(Math.random() * length);
    return this.state.comments[idx];
  };

  handleMouseOver = (cur) => {
    this.setState({
      cur,
      tooltipVisible: true,
    });
  };

  handleMouseLeave = (cur) => {
    this.setState({
      cur,
      tooltipVisible: false,
    });
  };

  render() {
    let { isOpen } = this.state;
    const { info, details } = this.props.data;
    const { end_date, is_b } = info;
    let ed = new Date(end_date);
    ed.setDate(ed.getDate() - 1);
    const { is_week_allowed, is_free, is_forever, is_seasonal } = this.props;

    return (
      <div className={"RoadmapLectureContainer"}>
        <div
          className={
            is_week_allowed
              ? cx("roadmapLectureWrapper")
              : cx("roadmapLectureWrapper", "disabled")
          }
        >
          <Collapse
            className={cx("roadmapLectureCollapse")}
            ghost
            defaultActiveKey={is_forever ? [1] : ""}
            onChange={() => this.changePannel(isOpen)}
          >
            <Panel
              collapsible={false}
              className={cx("panel")}
              showArrow={false}
              header={
                <div>
                  <div className={cx("header")}>
                    {!is_forever && (
                      <>
                        <div className={cx("left")}>
                          {info.week}
                          {is_free || is_seasonal ? "일" : "주"}차
                        </div>
                        <div className={cx("right")}>
                          {ed.getMonth() + 1}월{ed.getDate()}일까지 수강 가능
                        </div>
                      </>
                    )}
                  </div>
                  <div className={cx("body")}>
                    <Row className={cx("RoadmapLectureRow")} align="middle">
                      <Col span={12} className={cx("weekInfoWrapper")}>
                        <div className={cx("title")}>{info.title}</div>
                        <div className={cx("info")}>
                          <span className={cx("iconContainer")}>
                            <YoutubeFilled style={{ color: "#adb5bd" }} />
                            {details.length}강
                          </span>
                          <span className={cx("iconContainer")}>
                            <ClockCircleFilled style={{ color: "#adb5bd" }} />
                            {this.toHHMM(info.week_playtime)}
                          </span>
                          {is_week_allowed ? (
                            <span
                              className={cx("iconContainer", "material")}
                              onClick={this._showMaterial}
                            >
                              <div className={cx("materialContainer")}>
                                <FileTextFilled style={{ color: "#adb5bd" }} />
                                강의자료 &nbsp;
                                <img
                                  className={cx("outlinkImage")}
                                  src={"/assets/icons/outlink.png"}
                                  alt=""
                                />
                              </div>
                            </span>
                          ) : (
                            <span>
                              <div className={cx("materialContainer")}>
                                <FileTextFilled style={{ color: "#adb5bd" }} />
                                강의자료 &nbsp;
                                <img
                                  className={cx("outlinkImage")}
                                  src={"/assets/icons/outlink.png"}
                                  alt=""
                                />
                              </div>
                            </span>
                          )}
                        </div>
                        {!is_forever && (
                          <div className={cx("openCollapse")}>
                            {isOpen && (
                              <Fragment>
                                <span>목록 닫기</span>
                                <span className={cx("iconContainer")}>
                                  <UpOutlined />
                                </span>
                              </Fragment>
                            )}
                            {!isOpen && (
                              <Fragment>
                                <span>목록 열기</span>

                                <span className={cx("iconContainer")}>
                                  <DownOutlined />
                                </span>
                              </Fragment>
                            )}
                          </div>
                        )}
                      </Col>
                      <Col className={cx("scoreWrapper")} span={4.5}>
                        <div />
                      </Col>

                      <Col
                        className={cx("whoStudyWithWrapper")}
                        span={6.5}
                        offset={0.5}
                      >
                        {info.seen_eds > 0 && (
                          <React.Fragment>
                            <div>{info.seen_person} 님 외</div>
                            <div>
                              <strong>{info.seen_eds - 1}</strong> 명이 듣고
                              있어요. {this._getComment()} 👏
                            </div>
                          </React.Fragment>
                        )}
                        {info.seen_eds === 0 && info.never_done && (
                          <React.Fragment>
                            <div>아직 아무도 없어요.</div>
                            <div>가장 먼저 들으러 가볼까요? 😎</div>
                          </React.Fragment>
                        )}
                        {info.seen_eds === 0 &&
                          info.never_done === false &&
                          info.im_done && (
                            <React.Fragment>
                              <div>와, 대단해요!</div>
                              <div>이미 다 들었어요 👏</div>
                            </React.Fragment>
                          )}
                        {info.seen_eds === 0 &&
                          info.never_done === false &&
                          info.im_done === false && (
                            <React.Fragment>
                              <div>벌써 다 들은 사람이 있네요?</div>
                              <div>우리도 얼른 달려가볼까요? 👩‍💻</div>
                            </React.Fragment>
                          )}
                      </Col>
                    </Row>
                  </div>
                </div>
              }
              key="1"
            >
              {details.map((lecture, idx) => {
                // 0부터 직전 item 까지 모두 done인 경우에만 클릭 가능
                let isAllowed;
                if (idx === 0) {
                  isAllowed = lecture.seen;
                } else {
                  isAllowed = details.slice(0, idx).reduce((acc, cur, i) => {
                    return acc && cur.done;
                  }, true);
                }
                isAllowed = isAllowed && lecture.is_allowed;

                return (
                  <div
                    key={lecture._id}
                    className={
                      isAllowed
                        ? cx("lectureContentWrapper")
                        : cx("lectureContentWrapper", "disabled")
                    }
                    onMouseOver={() => {
                      this.handleMouseOver(lecture._id);
                    }}
                    onMouseLeave={() => {
                      this.handleMouseLeave(lecture._id);
                    }}
                    onClick={() => this._goToLecture(lecture._id, isAllowed)}
                  >
                    <Tooltip
                      visible={
                        !isAllowed &&
                        this.state.tooltipVisible &&
                        lecture._id === this.state.cur
                      }
                      title={`앗,이전 강의 수강을 먼저 마무리해주세요!😊`}
                      placement={"bottomLeft"}
                      overlayStyle={{ fontSize: 12 }}
                    >
                      <Row align="middle">
                        <Col span={3} className={cx("order")}>
                          {!is_forever
                            ? `${lecture.week}-${idx + 1}`
                            : idx > 9
                            ? `2-${idx - 9}`
                            : `${lecture.week}-${idx + 1}`}
                        </Col>
                        <Col span={9} className={cx("title")}>
                          {lecture.title}
                        </Col>
                        <Col span={3} className={cx("time")}>
                          <ClockCircleOutlined style={{ color: "#adb5bd" }} />
                          {this.toHHMMSS(lecture.playtime)}
                        </Col>
                        <Col className={cx("score")} span={4}></Col>
                        <Col className={cx("score")} span={4}>
                          <span>
                            <Rate disabled defaultValue={lecture.fun} />
                          </span>
                        </Col>
                        {!isAllowed && (
                          <Col span={1}>
                            <LockFilled className={cx("lock")} />
                          </Col>
                        )}
                        {isAllowed && (
                          <Col span={1}>
                            <UnlockFilled className={cx("lock")} />
                          </Col>
                        )}
                      </Row>
                    </Tooltip>
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedRoadmapLecture = connect(mapStateToProps, {})(RoadmapLecture);
export { connectedRoadmapLecture as RoadmapLecture };
