import { useQuery } from "react-query";
import privateInstance from "../../_helper/axios";
import { useEffect, useState } from "react";

const getFundingCourses = async () => {
  return await privateInstance
    .get(`/courses/funding/status/true`)
    .then((resp) => resp.data.context);
};

export const useGetFundingCourses = () => {
  return useQuery("fundingCourses", () => getFundingCourses(), {
    refetchOnWindowFocus: false,
  });
};

export const useGetFundingCourseIds = () => {
  const [fundingCourseIds, setFundingCourseIds] = useState([]);
  const fundingCourses = useGetFundingCourses();
  useEffect(() => {
    if (fundingCourses.isSuccess && fundingCourses.data) {
      setFundingCourseIds(fundingCourses.data.map((course) => course._id));
    }
  }, [fundingCourses.isSuccess]);
  return fundingCourseIds;
};

export const useGetBlockingCourses = () => {
  const [blockingCourses, setBlockingCourses] = useState([]);
  const fundingCourses = useGetFundingCourses();
  useEffect(() => {
    if (fundingCourses.isSuccess) {
      const fundingCourseIds = fundingCourses.data.map((course) => course._id);
      setBlockingCourses([...fundingCourseIds]);
    }
  }, [fundingCourses.isSuccess]);
  return blockingCourses;
};
