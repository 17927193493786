import { getCookie } from "../../_helper";
import {
  CLASS_TME,
  EVAL_CD,
  EVAL_TYPE,
  MOTP_API_URL,
} from "../../_state/constants";
import { getCurrentFormattedDate, urlEncode } from "../../../_helpers";
import { handleMOTPResponse } from "./MOTPResponseHandling";

export const validateMOTP = async ({
  courseId,
  roundId,
  productType,
  user,
  isUserIPSuccess,
  userIp,
  inputOTP,
  setInputOTP,
  setIsNeedReset,
  setIsOTP,
  refetchDailyAuth,
}) => {
  const govCreditCourseId = courseId;
  const govCreditRoundId = roundId;
  const sessionId = getCookie("device_id") ?? ""; // deviceId
  const agentId = productType === "KDT" ? "teamsparta1" : "teamsparta";
  const exIp = isUserIPSuccess ? userIp : "";
  const { _id: userId, name: userName, phone: userPhone } = user;

  try {
    const mOtpAuthRequestData = {
      USER_NM: userName,
      USER_TEL: userPhone,
      OTPNO: inputOTP,
      AGTID: agentId,
      USRID: userId,
      SESSIONID: sessionId,
      EXIP: exIp,
      COURSE_AGENT_PK: govCreditCourseId,
      CLASS_AGENT_PK: govCreditRoundId,
      EVAL_CD: EVAL_CD,
      EVAL_TYPE: EVAL_TYPE,
      CLASS_TME: CLASS_TME,
      USRDT: getCurrentFormattedDate(),
    };

    if (
      !userName ||
      !userPhone ||
      !inputOTP ||
      !userId ||
      !govCreditCourseId ||
      !govCreditRoundId
    ) {
      alert("요청 데이터가 누락되어 실패하였습니다.");
      window.location.reload();
      return;
    }
    const response = await fetch(MOTP_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" }, // hrd 에서 정해진 포맷
      body: urlEncode(mOtpAuthRequestData),
    });

    const respData = await response.json();
    await handleMOTPResponse(
      respData,
      mOtpAuthRequestData,
      userId,
      productType,
      setInputOTP,
      setIsNeedReset,
      setIsOTP,
      refetchDailyAuth
    );
  } catch (error) {
    alert("mOTP 인증에 실패했습니다. 다른 인증 수단을 시도해주세요.");
    setInputOTP("");
    console.error("Failed to validate mOTP:", error);
  }
};
