import * as S from "./KDCKDT.style";
import { useIsDesktop, useUser } from "../../LecturePageV3/useInitialize";
import { ArrowChevronRightSmall } from "@teamsparta/design-system";
import { history } from "../../../../_helpers";
import { useNavigateToRoadmap } from "../../../hooks/useNavigateToRoadmap";
import { useIsMobile } from "../../../hooks/device";
import { logOnClickCta } from "../../../hooks/finish/recommend";
import { useParams } from "react-router-dom";
import { useEnrolled } from "../../../hooks/useProductName";

const KDCKDTdesktop = ({ onClickKDT, onClickKDC, onClickNCS }) => {
  const user = useUser();
  const userName = user?.name;
  const navigateToRoadmap = useNavigateToRoadmap();
  return (
    <S.Container>
      <S.Title>
        {userName}님,
        <br />
        강의 완주를 축하드려요!
      </S.Title>
      <S.RecommendWrapper>
        <S.RecommendImage
          src={"/v2/assets/images/kdtrcmd/kdt_kdc_recommend.png"}
        />
        <S.LeftButtonWrapper>
          <S.Button onClick={onClickKDT}>0원으로 취업하기</S.Button>
        </S.LeftButtonWrapper>
        <S.RightButtonWrapper>
          <S.Button onClick={onClickKDC}>0원으로 강의듣기</S.Button>
        </S.RightButtonWrapper>
      </S.RecommendWrapper>
      {/*<S.BannerWrapper>*/}
      {/*  <S.RecommendBanner*/}
      {/*    src={"/v2/assets/images/kdtrcmd/kdt_kdc_banner.png"}*/}
      {/*  />*/}
      {/*  <S.SmallButtonWrapper>*/}
      {/*    <S.SmallButton onClick={onClickNCS}>*/}
      {/*      <S.SmallButtonText>NCS 수료증 다운로드</S.SmallButtonText>*/}
      {/*      <ArrowChevronRightSmall size={16} color={"#141617"} />*/}
      {/*    </S.SmallButton>*/}
      {/*  </S.SmallButtonWrapper>*/}
      {/*</S.BannerWrapper>*/}
      <S.RoadmapButton onClick={navigateToRoadmap}>
        강의목차로 돌아가기
      </S.RoadmapButton>
    </S.Container>
  );
};

const KDCKDTmobile = ({ onClickKDT, onClickKDC, onClickNCS }) => {
  const user = useUser();
  const userName = user?.name;
  const navigateToRoadmap = useNavigateToRoadmap();
  return (
    <S.MobileContainer>
      <S.MobileTitle>
        {userName}님,
        <br />
        강의 완주를 축하드려요!
      </S.MobileTitle>
      <S.MobileRecommendWrapper>
        <S.MobileRecommendImage
          src={"/v2/assets/images/kdtrcmd/kdt_kdc_recommend_mo.png"}
        />
        <S.MobileTopButtonWrapper>
          <S.MobileButton onClick={onClickKDT}>0원으로 취업하기</S.MobileButton>
        </S.MobileTopButtonWrapper>
        <S.MobileBottomButtonWrapper>
          <S.MobileButton onClick={onClickKDC}>0원으로 강의듣기</S.MobileButton>
        </S.MobileBottomButtonWrapper>
      </S.MobileRecommendWrapper>
      {/*<S.MobileBannerWrapper>*/}
      {/*  <S.MobileBannerImage*/}
      {/*    src={"/v2/assets/images/kdtrcmd/kdt_kdc_banner_mo.png"}*/}
      {/*  />*/}
      {/*  <S.MobileSmallButtonWrapper>*/}
      {/*    <S.MobileSmallButton onClick={onClickNCS}>*/}
      {/*      <S.MobileSmallButtonText>*/}
      {/*        NCS 수료증 다운로드*/}
      {/*      </S.MobileSmallButtonText>*/}
      {/*      <ArrowChevronRightSmall size={16} color={"#141617"} />*/}
      {/*    </S.MobileSmallButton>*/}
      {/*  </S.MobileSmallButtonWrapper>*/}
      {/*</S.MobileBannerWrapper>*/}
      <S.MobileRoadmapButton onClick={navigateToRoadmap}>
        강의목차로 돌아가기
      </S.MobileRoadmapButton>
    </S.MobileContainer>
  );
};

export const KDCKDT = () => {
  const isMobile = useIsMobile();
  const { enrolled_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const pageUrl = window.location.href;
  const pageTitle = "KDT 추천 완료 페이지";
  const onClickKDT = () => {
    window.open("https://nbcamp.spartacodingclub.kr");
    logOnClickCta(enrolled, "0원으로 취업하기", pageUrl, pageTitle);
  };
  const onClickKDC = () => {
    window.open("https://spartacodingclub.kr/nb");
    logOnClickCta(enrolled, "0원으로 강의듣기", pageUrl, pageTitle);
  };
  const onClickNCS = () => {
    history.push("/mypage/mycertificate");
    logOnClickCta(enrolled, "NCS 수료증 다운로드", pageUrl, pageTitle);
  };
  return isMobile ? (
    <KDCKDTmobile
      onClickKDT={onClickKDT}
      onClickKDC={onClickKDC}
      onClickNCS={onClickNCS}
    />
  ) : (
    <KDCKDTdesktop
      onClickKDT={onClickKDT}
      onClickKDC={onClickKDC}
      onClickNCS={onClickNCS}
    />
  );
};
