import styled from "@emotion/styled";
import {
  additional,
  mBody1,
  mBody4,
  neutralDay,
  wBody1,
  wBody2,
} from "@teamsparta/design-system";
import { StatusEnum } from "../../../_state/constants";
import { DisplayResolution } from "../../../../_styles/device";

export const FeedbackNoticeContainer = styled.div`
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 24px;
  }
  width: 100%;
  max-width: 772px;
  padding: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  border-radius: 20px;
  margin: 0 auto;
  background: ${({ status }) => {
    switch (status) {
      case StatusEnum.RESOLVED:
        return "#E9FBED";
      case StatusEnum.ACCEPTED:
        return "#FFF4ED";
      case StatusEnum.SUBMITTED:
        return "#EEF3F6";
      default:
        return "#E9FBED";
    }
  }};
  display: ${({ status }) => {
    switch (status) {
      case StatusEnum.REJECTED:
      case null:
        return "none";
      default:
        return "flex";
    }
  }};
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
`;

export const StatusExplanation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`;

export const StatusExplanationTitle = styled.div`
  ${wBody1};
  color: ${neutralDay.gray90};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${neutralDay.gray90};
  }
`;

export const StatusExplanationContent = styled.div`
  ${wBody2};
  color: ${neutralDay.gray80};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody4};
    color: ${neutralDay.gray80};
  }
`;

export const GoToFeedbackPageCta = styled.span`
  ${wBody1};
  color: ${additional.blue100};
  text-align: center;
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  align-items: center;
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${additional.blue100};
    padding: 0;
  }
`;
