export const courseConstants = {
  // COURSE LIST
  COURSE_LIST_REQUEST: "COURSE_LIST_REQUEST",
  COURSE_LIST_SUCCESS: "COURSE_LIST_SUCCESS",
  COURSE_LIST_FAILURE: "COURSE_LIST_FAILURE",

  // COURSE
  COURSE_REQUEST: "COURSE_REQUEST",
  COURSE_SUCCESS: "COURSE_SUCCESS",
  COURSE_FAILURE: "COURSE_FAILURE",

  // COURSE
  GOV_COURSE_REQUEST: "GOV_COURSE_REQUEST",
  GOV_COURSE_SUCCESS: "GOV_COURSE_SUCCESS",
  GOV_COURSE_FAILURE: "GOV_COURSE_FAILURE",

  // COURSE BY USER ID
  COURSE_BY_USERID_REQUEST: "COURSE_BY_USERID_REQUEST",
  COURSE_BY_USERID_SUCCESS: "COURSE_BY_USERID_SUCCESS",
  COURSE_BY_USERID_FAILURE: "COURSE_BY_USERID_FAILURE",

  // COURSES BY PKG ID
  COURSE_BY_PKG_ID_REQUEST: "COURSE_BY_PKG_ID_REQUEST",
  COURSE_BY_PKG_ID_SUCCESS: "COURSE_BY_PKG_ID_SUCCESS",
  COURSE_BY_PKG_ID_FAILURE: "COURSE_BY_PKG_ID_FAILURE",
};
