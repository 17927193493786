import { ORDERS_CONSTANT } from "../constants";
import { orderService } from "../services";

function getOrder(course_id) {
  function request() {
    return { type: ORDERS_CONSTANT.ORDERS_REQUEST };
  }
  function success(response) {
    return { type: ORDERS_CONSTANT.ORDERS_SUCCESS, response };
  }
  function failure(error) {
    return { type: ORDERS_CONSTANT.ORDERS_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    orderService.getOrder(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const ordersActions = {
  getOrder,
};
