import { useIsMobile } from "../../../../hooks/device";
import { neutralDay, MyClassLine } from "@teamsparta/design-system";

export const Classroom = () => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <MyClassLine size={24} color={neutralDay.gray90} />
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 26.6665H24"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
      <rect
        x="5.33331"
        y="5.3335"
        width="21.3333"
        height="16"
        rx="2.66667"
        stroke="#3A3E41"
        strokeWidth="2.66667"
      />
      <path
        d="M14 11.2028V15.4638C14 15.9875 14.576 16.3067 15.02 16.0292L18.4288 13.8987C18.8466 13.6376 18.8466 13.0291 18.4288 12.768L15.02 10.6375C14.576 10.36 14 10.6792 14 11.2028Z"
        fill="#3A3E41"
      />
    </svg>
  );
};

export const Camp = () => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={isMobile ? "24" : "32"}
      height={isMobile ? "24" : "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6154 5.3335L4 26.6668H28L11.3846 5.3335"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="13.3333"
        y="20"
        width="5.33333"
        height="8"
        rx="2.66667"
        fill="#3A3E41"
      />
    </svg>
  );
};

export const Sparta = () => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={isMobile ? "24" : "32"}
      height={isMobile ? "24" : "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 21.3333L2.66667 15.9999L8 10.6666"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 10.6667L29.3333 16.0001L24 21.3334"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 6.66675L12 25.3334"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ThumbUp = () => {
  const isMobile = useIsMobile();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        x="4"
        y="13.3333"
        width="2.66667"
        height="12"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.9309C12 12.6833 12.4374 11.4751 13.2362 10.5166L18.3944 4.32672C18.5669 4.1197 18.8225 4 19.092 4V4C19.5935 4 20 4.40654 20 4.90803V9.33333C20 10.8061 21.1939 12 22.6667 12H26.6667C27.403 12 28 12.597 28 13.3333V17.8261C28 18.3732 27.8317 18.9071 27.518 19.3553L24.1295 24.1959C23.6305 24.9088 22.8151 25.3333 21.9449 25.3333H13.3333C12.597 25.3333 12 24.7364 12 24V13.9309Z"
        stroke="#3A3E41"
        strokeWidth="2.66667"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Calendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="2.6665"
        y="3.33333"
        width="10.6667"
        height="10.6667"
        rx="1.33333"
        stroke="#81898F"
        strokeWidth="1.5"
      />
      <path
        d="M2.6665 6.66667L13.3332 6.66667"
        stroke="#81898F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.3335 2V3.33333"
        stroke="#81898F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.6665 2V3.33333"
        stroke="#81898F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
