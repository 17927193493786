import React, {Component} from 'react';
import {connect} from "react-redux";
import {codeSnippetActions} from "../../_actions";
import {Card} from 'antd';
import style from './CodeSnippet.scss';
import classNames from "classnames/bind";
import styles from "../Footer/Footer.module.scss";

const cx = classNames.bind(styles);

class CodeSnippet extends Component {

    componentDidMount() {
        const {round_id, lecture_id} = this.props.data;
        this.props.getCodeSnippet(round_id, lecture_id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.lecture_id !== this.props.data.lecture_id) {
            const {round_id, lecture_id} = this.props.data;
            this.props.getCodeSnippet(round_id, lecture_id);
        }
    }

    _copyToClipboard = async code => {
        try {
            await navigator.clipboard.writeText(code)
                .then(
                    success => {
                        alert('코드 복사가 완료되었습니다.');
                    },
                    error => {

                    });
        } catch (err) {
            console.log(err);
        }

    };

    _createSnippetCards = () => {
        if (this.props.codeSnippet.loaded) {
            let snippetComponents = [];
            const {snippets} = this.props.codeSnippet.data;
            for (let i = 0; i < snippets.length; i++) {
                let sp = snippets[i];
                snippetComponents.push(
                    <a key={i} onClick={(e) => {
                        e.preventDefault();
                        this._copyToClipboard(sp.code);
                    }}>
                        <div key={i} className={cx('codeContainer')}>
                            <div className={cx('codeTitleContainer')}>
                                <div className={cx('ellipsis')}>
                                    {sp.title}
                                </div>
                            </div>
                            <div className={cx('codeCopyButtonContainer')}>
                                <img className={cx('ic_snippet')} src={('/assets/icons/ic_snippet.png')} alt=""/>
                                {<span className={cx('copytext')}>복사하기</span>}
                            </div>
                        </div>
                    </a>
                );
            }
            return (
                <Card
                    title="< / > 코드스니펫"
                    style={{width: "100%", height: 'calc(53vh - 80px)', overflow: "scroll", border: 'none'}}
                    headStyle={{'backgroundColor': '#DEE2E6'}}
                    bodyStyle={{'padding': 0}}
                >
                    {snippetComponents.length === 0 &&
                    <div style={{padding: 10}}>
                        이번 강의는 코드스니펫이 없습니다.
                    </div>
                    }
                    <div className={cx('snippetsContainer')}>
                        {snippetComponents}
                    </div>
                </Card>
            )
        } else {
            return <div/>
        }
    };

    render() {
        const snippets = this._createSnippetCards();
        return (
            <div className={cx('snippetContainer')}>
                {snippets}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {authentication, codeSnippet} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        codeSnippet
    }
};

const connectedCodeSnippet = connect(mapStateToProps, {
    getCodeSnippet: codeSnippetActions.getCodeSnippet,
})(CodeSnippet);
export {connectedCodeSnippet as CodeSnippet}