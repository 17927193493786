import styled from "@emotion/styled";
import { neutralDay, wBody1 } from "@teamsparta/design-system";
import { keyframes } from "@emotion/css";
import { DisplayResolution } from "../../../../_styles/device";

const mobileSlideUp = keyframes`
  0% {
    transform: translate(0%, 200%);
    opacity: 0.25;
  }
 
  60% {
    transform: translate(0%, 10%);
  }

  80% {
    transform: translate(0%, 2.5%);
  }

  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
`;

const desktopSlideUp = keyframes`
  0% {
    transform: translate(-50%, -40%);
    opacity: 0.5;
  }

  50% {
    transform: translate(-50%, -47%);
  }
 
  75% {
    transform: translate(-50%, -48.5%);
  }

  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const desktopSlideDown = keyframes`
    0% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
  
    100% {
        transform: translate(-50%, -40%);
        opacity: 0.5;
    }
  `;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 21;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  border-radius: 16px 16px 0 0;
  position: fixed;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background-color: ${neutralDay.gray90};
  box-shadow: 0px 20px 24px 0px rgba(20, 22, 23, 0.24);
  animation: ${mobileSlideUp} 0.75s ease-out;

  ${DisplayResolution.TabletAndDesktop} {
    top: 50%;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    padding: 0;
    border-radius: 16px;
    background-color: ${neutralDay.gray90};
    animation: ${({ isClosing }) =>
        isClosing ? desktopSlideDown : desktopSlideUp}
      0.3s linear;
  }
`;

export const ConfirmModalBackground = styled(ModalBackground)`
  padding: 0 20px;
`;

export const ConfirmModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  align-self: stretch;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  width: calc(100% - 40px);

  ${DisplayResolution.TabletAndDesktop} {
    padding: 32px;
    width: 460px;
    top: 40px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
  }
`;

export const ConfirmButtonWrapper = styled.div`
  width: 100%;
  ${DisplayResolution.TabletAndDesktop} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const ConfirmButton = styled.button`
  ${wBody1};
  height: 52px;
  display: flex;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  border-radius: 8px;
  background: #141617;
  text-align: center;
  color: ${neutralDay.white};
  cursor: pointer;
  border: none;

  ${DisplayResolution.TabletAndDesktop} {
    width: 100px;
  }
`;
