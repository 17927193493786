import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiCreateRatingUrl = (round_id, lecture_id) => {
  return `${baseUrl}/rounds/${round_id}/lectures/${lecture_id}/ratings`;
};

export const getApiCreateCheerupUrl = (round_id, lecture_id) => {
  return `${baseUrl}/rounds/${round_id}/lectures/${lecture_id}/cheerups`;
};
