import * as S from "./Certificates.style";
import { DownloadIcon, Receipt } from "../Icons/Icons";
import { useState } from "react";
import { getConfirmAndCerti } from "../../../../../hooks/useDownloadCerti";
import { CertificateCardMobile } from "./CertificateCardMobile";
import { sendLog } from "../../../../businesslogics/logging";
import { VoucherSvg } from "./Certificates.svg";
import { useSelector } from "react-redux";
import { addDays, toIsoString } from "../../../../_helper";

export const parsePeriod = (startDate, endDate) => {
  const parseAndReplace = (string) => {
    return string.split("T")[0].replaceAll("-", ".");
  };
  const start = parseAndReplace(startDate);
  const end = parseAndReplace(endDate);
  if (end.split(".")[0] === "2099") {
    return "평생";
  } else {
    return `${start}~${end}`;
  }
};

export const go_linkedIn = (enrolled) => {
  const { course_title, year, month, cert_id, file_link, done } = enrolled;
  if (!done) return;
  sendLog(
    "scc_mypagePage_click_certificate",
    {
      certificate_type: "링크드인",
      course_title: enrolled.course_title,
      course_id: enrolled.course._id,
      round: enrolled.round_title,
      round_id: enrolled.enrolled.round_id,
      is_success: true,
    },
    "",
    true
  );
  window.open(
    `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${course_title}&organizationId=30234270&issueYear=${year}&issueMonth=${month}&certUrl=${file_link}&certId=${cert_id}`
  );
};

export const getReceipt = (enrolled) => {
  const tid = enrolled.tid;
  const receipt_url = enrolled.receipt_url;
  const isTidNotPossible = tid === "" || !tid;
  const isReceiptUrlNotPossible = receipt_url === "" || !receipt_url;
  if (isTidNotPossible && isReceiptUrlNotPossible) return;
  sendLog(
    "scc_mypagePage_click_certificate",
    {
      certificate_type: "영수증",
      course_title: enrolled.course_title,
      course_id: enrolled.course._id,
      round: enrolled.round_title,
      round_id: enrolled.enrolled.round_id,
      is_success: true,
    },
    "",
    true
  );
  if (receipt_url) {
    window.open(receipt_url);
  } else {
    window.open(
      `https://npg.nicepay.co.kr/issue/IssueLoader.do?TID=${tid}&type=0`,
      "_blank"
    );
  }
};

export const CertifiRow = ({ enrolled }) => {
  const [isConfirmWait, setIsConfirmWait] = useState(false);
  const [isCertWait, setIsCertWait] = useState(false);
  return (
    <S.CourseInfoRow>
      {enrolled.done ? (
        <S.CourseStatusTd>
          <S.CourseCompleted>수료</S.CourseCompleted>
        </S.CourseStatusTd>
      ) : enrolled.is_in_period ? (
        <S.CourseStatusTd>
          <S.CourseEnrolled>수강중</S.CourseEnrolled>
        </S.CourseStatusTd>
      ) : (
        <S.CourseStatusTd>
          <S.CourseEnded>미수료</S.CourseEnded>
        </S.CourseStatusTd>
      )}

      <S.CourseTitleTd>
        <S.CourseTitleContainer>
          <S.CourseTitle>{`${enrolled.course_title} ${enrolled.round_title}`}</S.CourseTitle>
          <S.CoursePeriod>
            {parsePeriod(
              enrolled.start_date,
              toIsoString(addDays(enrolled.end_day, -1))
            )}
          </S.CoursePeriod>
          {enrolled.voucher_title && (
            <S.Voucher>
              <VoucherSvg />
              {enrolled.course_title.includes(
                enrolled.voucher_title.split(" ")[0]
              ) || enrolled.voucher_title.includes("뽀개기")
                ? "수강권"
                : enrolled.voucher_title}{" "}
              수강 과목
            </S.Voucher>
          )}
        </S.CourseTitleContainer>
      </S.CourseTitleTd>
      <S.ReceiptTd onClick={() => getReceipt(enrolled)}>
        <S.DownloadContainer
          isActive={!!enrolled.tid?.length || !!enrolled.receipt_url?.length}
        >
          <S.IconWrapper>
            <Receipt />
          </S.IconWrapper>
        </S.DownloadContainer>
      </S.ReceiptTd>
      <S.ReceiptTd>
        <S.DownloadContainer
          isActive={true}
          onClick={() =>
            getConfirmAndCerti(
              enrolled.is_gov ? "nb_confirm" : "confirm",
              isConfirmWait,
              setIsConfirmWait,
              enrolled
            )
          }
        >
          {isConfirmWait ? (
            <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
          ) : (
            <S.IconWrapper>
              <DownloadIcon />
            </S.IconWrapper>
          )}
        </S.DownloadContainer>
      </S.ReceiptTd>
      <S.CertifiTd>
        <S.DownloadIconContainer>
          <S.DownloadContainer
            isActive={enrolled.done}
            onClick={() =>
              getConfirmAndCerti(
                enrolled.is_gov ? "nb_cert" : "new_cert",
                isCertWait,
                setIsCertWait,
                enrolled
              )
            }
          >
            {isCertWait ? (
              <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
            ) : (
              <S.IconWrapper>
                <DownloadIcon />
              </S.IconWrapper>
            )}
          </S.DownloadContainer>
          <S.GetLinkedinContainer
            onClick={() => {
              go_linkedIn(enrolled);
            }}
            isActive={enrolled.done}
          >
            <S.LinkedInImg src={"/v2/assets/icons/linked_icon.png"} />
          </S.GetLinkedinContainer>
        </S.DownloadIconContainer>
      </S.CertifiTd>
    </S.CourseInfoRow>
  );
};

export const Certificates = () => {
  const enrolledDone = useSelector((state) => state.enrolledDone);
  const myEnrolleds = enrolledDone.data.enrolleds;

  return (
    <>
      <S.CertifiDesktopWrapper>
        <table>
          <S.CertifiHeaderRows>
            <S.CertifiCaution colSpan={"2"}>
              * 수강증/수료증 발급은 최대 15초 소요될 수 있습니다.
            </S.CertifiCaution>
            <S.CertifiHeader>영수증</S.CertifiHeader>
            <S.CertifiHeader>수강증</S.CertifiHeader>
            <S.CertificatesHeader>수료증</S.CertificatesHeader>
          </S.CertifiHeaderRows>
          {myEnrolleds.map((enrolled, idx) => {
            return <CertifiRow key={"certifirow" + idx} enrolled={enrolled} />;
          })}
        </table>
      </S.CertifiDesktopWrapper>
      {/**
       모바일과 데스크탑의 경계. 미디어 쿼리로 구분한다.*/}
      <S.CertificateMobileWrapper>
        <S.CertifiMaxwidthWrapper>
          <S.CertifiMobileTitle>수강증/수료증</S.CertifiMobileTitle>
          <S.CertifiMobileSubTitle>
            * 수강증/수료증 발급은 최대 15초 소요될 수 있습니다.
          </S.CertifiMobileSubTitle>
          <S.CertifiCardContainer>
            {myEnrolleds.map((enrolled) => {
              return <CertificateCardMobile enrolled={enrolled} />;
            })}
          </S.CertifiCardContainer>
        </S.CertifiMaxwidthWrapper>
      </S.CertificateMobileWrapper>
    </>
  );
};
