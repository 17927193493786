import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import styles from "./LecMenu.module.scss";
import { enrolledActions, lectureActions } from "../../../_state/actions";
import { lectureMenuComponents } from "./constants";
import classNames from "classnames/bind";
import { LecMenuComponent } from "./LecMenuComponent";
import { LecMenuContents } from "./LecMenuContents";
import { Select } from "../../../components/molecules/Select";
import { Modal } from "../../../components/organisms/Modal/Modal";
import { UserSettings } from "../../../components/organisms/UserSettings";
import privateInstance from "../../../_helper/axios";

const cx = classNames.bind(styles);

function LecMenu({
  enrolled,
  enrolledDetail,
  enrolledDetails,
  getEnrolledDetails,
  material,
  isDual,
  user,
  setIsDual,
  codeSnippets,
  isGovIntro,
}) {
  useEffect(() => {
    if (!enrolled.loaded) {
      return;
    }
    const { enrolled_id } = enrolled.data;
    getEnrolledDetails(enrolled_id);
  }, [enrolled]);

  useEffect(() => {
    if (!enrolled.loaded || !enrolledDetail.loaded) {
      return;
    }
    const { enrolled_id } = enrolled.data;
    const { enrolled_detail_id } = enrolledDetail.data;
  }, [enrolled, enrolledDetail]);

  useEffect(() => {
    setSettingVisible(false);
    if (isDual) {
      setSelectedMenu(-1);
    } else {
      setSelectedMenu(1);
    }
  }, [isDual]);

  const textarea = useRef();
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [reportModalInfo, setReportModalInfo] = useState(null);
  const [settingModalInfo, setSettingModalInfo] = useState(null);
  const [reportVisible, setReportVisible] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false);
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const [reportType, setReportType] = useState("");

  useEffect(() => {
    setReportModalInfo({
      handleVisible: setReportVisible,
      header: "오류 제보하기",
      content: (
        <div className={cx("modal-content")}>
          <h3 className={cx("modal-content__header")}>오류 종류</h3>
          <Select
            defaultValue="---- 선택 ----"
            handleOptionSelected={setReportType}
            options={[
              { name: "영상", value: "영상" },
              { name: "자막", value: "자막" },
              { name: "코드스니펫", value: "코드스니펫" },
              { name: "기타", value: "기타" },
            ]}
          />
          <h3 className={cx("modal-content__header")}>오류 내용</h3>
          <textarea
            className={cx("modal-content__textarea")}
            ref={textarea}
            rows={4}
            placeholder={"자세히 알려주시면 정말 감사하겠습니다 :)"}
          />
        </div>
      ),
      cta: {
        btn_text: "제보하기",
        handleCTAClick: () => {
          if (!textarea.current) return;
          const comment = textarea.current.value;
          if (!comment) {
            alert("내용을 입력해주세요!");
            return;
          }
          const { enrolled_id, enrolled_detail_id, lecture_id } =
            enrolledDetail.data;
          const { _id: user_id, name, email, phone } = user;
          const data = {
            type: reportType,
            comment: comment,
            user_id: user_id,
            name: name,
            email: email,
            phone: phone,
            enrolled_id: enrolled_id,
            enrolled_detail_id: enrolled_detail_id,
            lecture_id: lecture_id,
          };
          return privateInstance.post(`/report/lectures`, data).then((resp) => {
            const result = resp.data;
            if (result.ok) {
              setIsReportSubmitted(true);
            }
          });
        },
      },
    });
  }, []);

  useEffect(() => {
    if (isReportSubmitted) {
      setReportModalInfo({
        handleVisible: setReportVisible,
        header: "감사합니다!",
        img_path: `/assets/images/characters/rtan_love.png`,
        content: (
          <div className={cx("modal-content")}>
            <p>
              오류를 제보해 주셔서 정말 감사합니다!
              <br />
              빠른 시일 내에 반영 후, 메일로 보내드릴게요.
              <br />
              앞으로 더욱 발전하는 스파르타코딩클럽이 될게요!
            </p>
          </div>
        ),
        cta: {
          btn_text: "확인",
          handleCTAClick: () => {
            setReportVisible(false);
            setIsReportSubmitted(false);
          },
        },
      });
    } else {
      setReportModalInfo({
        handleVisible: setReportVisible,
        header: "오류 제보하기",
        content: (
          <div className={cx("modal-content")}>
            <h3 className={cx("modal-content__header")}>오류 종류</h3>
            <Select
              defaultValue="---- 선택 ----"
              handleOptionSelected={setReportType}
              options={[
                { name: "영상", value: "영상" },
                { name: "자막", value: "자막" },
                { name: "코드스니펫", value: "코드스니펫" },
                { name: "기타", value: "기타" },
              ]}
            />
            <h3 className={cx("modal-content__header")}>오류 내용</h3>
            <textarea
              className={cx("modal-content__textarea")}
              ref={textarea}
              rows={4}
              placeholder={"자세히 알려주시면 정말 감사하겠습니다 :)"}
            />
          </div>
        ),
        cta: {
          btn_text: "제보하기",
          handleCTAClick: () => {
            if (!textarea.current) return;
            const comment = textarea.current.value;
            if (!comment) {
              alert("내용을 입력해주세요!");
              return;
            }
            const { enrolled_id, enrolled_detail_id, lecture_id } =
              enrolledDetail.data;
            const { _id: user_id, name, email, phone } = user;
            const data = {
              type: reportType,
              comment: comment,
              user_id: user_id,
              name: name,
              email: email,
              phone: phone,
              enrolled_id: enrolled_id,
              enrolled_detail_id: enrolled_detail_id,
              lecture_id: lecture_id,
            };
            return privateInstance
              .post(`/report/lectures`, data)
              .then((resp) => {
                const result = resp.data;
                if (result.ok) {
                  setIsReportSubmitted(true);
                }
              });
          },
        },
      });
    }
  }, [isReportSubmitted]);

  useEffect(() => {
    setSettingModalInfo({
      handleVisible: setSettingVisible,
      header: "수강환경 설정",
      content: <UserSettings isDual={isDual} setIsDual={setIsDual} />,
      cta: {},
    });
  }, []);

  return (
    <div className={cx("LecMenuWrapper", `${isDual ? "dual" : ""}`)}>
      {material.loaded && (
        <div className={cx("LecMenuContainer")}>
          {lectureMenuComponents
            .filter((component, idx) => {
              if (isGovIntro) {
                return [0, 1, 5, 6].includes(idx);
              } else {
                return true;
              }
            })
            .map((component, idx) => {
              const { faq_url } = material.data;
              if (idx === 0 && !isDual) return <div key={idx} />;
              if (idx === 5 && faq_url === "") return <div key={idx} />;
              if (idx === 4 && !enrolledDetail.loaded) return <div key={idx} />;
              return (
                <LecMenuComponent
                  key={idx}
                  component={component}
                  idx={idx}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  material={material}
                  isDual={isDual}
                  setSettingVisible={setSettingVisible}
                  setReportVisible={setReportVisible}
                />
              );
            })}
        </div>
      )}
      <LecMenuContents
        isDual={isDual}
        setIsDual={setIsDual}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        codeSnippets={codeSnippets}
        isGovIntro={isGovIntro}
        enrolledDetail={enrolledDetail}
      />
      {enrolledDetail.loaded && reportModalInfo && (
        <Modal
          visible={reportVisible}
          handleVisible={reportModalInfo.handleVisible}
          style={{}}
          header={reportModalInfo.header}
          img_path={reportModalInfo.img_path}
          content={reportModalInfo.content}
          cta={reportModalInfo.cta}
        />
      )}
      {settingModalInfo && (
        <Modal
          visible={settingVisible}
          closeBtn={true}
          handleVisible={settingModalInfo.handleVisible}
          style={{}}
          header={settingModalInfo.header}
          img_path={settingModalInfo.img_path}
          content={settingModalInfo.content}
          cta={settingModalInfo.cta}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolledDetails, enrolledDetail, enrolled } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetail,
    enrolledDetails,
  };
}

const connectedLecMenu = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getEnrolledDetail: enrolledActions.getEnrolledDetail,
})(LecMenu);
export { connectedLecMenu as LecMenu };
