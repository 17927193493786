import { npsService } from "../services";

function getNPS(enrolleds_id, week) {
  return (dispatch) => {
    return npsService.getNPS(enrolleds_id, week);
  };
}

function postNPS(enrolleds_id, week, nps) {
  return (dispatch) => {
    return npsService.postNPS(enrolleds_id, week, nps);
  };
}

function postB2bSurvey(enrolled_id, data) {
  return (dispatch) => {
    return npsService.postB2bSurvey(enrolled_id, data);
  };
}

export const npsActions = {
  getNPS,
  postNPS,
  postB2bSurvey,
};
