import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mBody3,
  mHeader2,
  mTitle2,
  neutralDark,
  neutralDay,
  scc,
  wBody1,
  wBody2,
  wBody4,
  wTitle1,
  wTitle4,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const AnswerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 0 0 12px 12px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDark.gray100};
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 20px 16px;
  }
`;

export const AnswerTag = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${neutralDay.white};
  ${wBody1};
  color: ${neutralDay.gray80};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${neutralDay.gray80};
  }
`;

export const AnswerInstructionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const AnswerTitle = styled.div`
  ${wTitle4};
  color: ${neutralDay.gray95};
  align-self: stretch;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mTitle2};
    color: ${neutralDay.gray95};
  }
`;

export const AnswerInstructionContainer = styled.div`
  ${wBody2};
  color: ${neutralDay.gray80};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: ${neutralDay.gray80};
  }
`;

//SubmitFileContainer
export const SubmitFileContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed ${neutralDay.gray50};
  background: ${neutralDay.gray0};
`;

export const SubmitFileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const SubmitFileTitle = styled.div`
  ${wBody1};
  color: ${neutralDay.gray80};
  text-align: center;
`;

export const SubmitFileSubtitle = styled.div`
  ${wBody4};
  color: ${neutralDay.gray60};
`;

export const SubmitFileButton = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 11px 14px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 999px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  box-shadow: 0 8px 24px 0 rgba(129, 137, 143, 0.12);
  cursor: pointer;
`;

export const FileInput = styled.input`
  display: none;
`;

export const SubmitDoneContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${scc.red75};
  background: ${neutralDay.gray0};
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 20px;
  }
`;

export const SubmitDoneTitleWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  ${DisplayResolution.MobileAndMiniTablet} {
    align-items: center;
  }
`;

export const SubmitDoneFileTitle = styled.div`
  ${wBody2};
  text-align: left;
  color: ${neutralDay.gray90};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: ${neutralDay.gray90};
  }
`;

export const CancelSubmitButton = styled.div`
  display: ${({ status, isFeedbackRequired }) =>
    isFeedbackRequired && ["ACCEPTED", "RESOLVED"].includes(status)
      ? "none"
      : "flex"};
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  height: 100%;
`;

export const TextAnswerContainer = styled.textarea`
  display: flex;
  padding: 14px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  ${wBody2};
  justify-content: flex-start;
  min-height: 106px;
  resize: none;
  overflow: auto;
  //focus시 테두리 추가
  &:focus {
    border: 1px solid ${neutralDay.gray90};
  }
  ::placeholder {
    color: ${neutralDay.gray50};
  }
`;

export const SelectAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const SelectBox = styled.label`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
  box-shadow: 0 8px 24px 0 rgba(129, 137, 143, 0.12);
  ${wBody2};
  color: ${neutralDay.gray90};
  cursor: pointer;
`;

export const SelectRadio = styled.input`
  &[type="radio"] {
    accent-color: ${scc.red100};
  }
`;

export const HintContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const HintTitle = styled.div`
  ${wBody4};
  color: ${neutralDay.gray70};
  line-height: 22px;
  cursor: default;
`;

export const HintCtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 6px;
`;

export const HintCta = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: ${({ isDisabled }) => (isDisabled ? "0" : "9.5px 14px")};
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.3" : "1")};
  ${mBody3};
  color: ${neutralDay.gray90};
  line-height: 21px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  &:hover {
    background: ${neutralDay.gray0};
  }
`;

export const HintContentWrapper = styled.div`
  display: flex;
  width: 460px;
  height: 600px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  position: relative;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    height: 80vh;
    padding: 28px 20px;
  }
`;

export const HintContentTitle = styled.div`
  ${wTitle1};
  color: ${neutralDay.gray100};
  line-height: 34px;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    color: ${neutralDay.gray100};
    line-height: 28px;
  }
`;

export const HintContentArea = styled.div`
  display: flex;
  width: 460px;
  height: 502px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 1px solid ${neutralDay.gray20};
  background-color: ${neutralDay.gray5};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: auto;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: calc(80vh - 88px);
  }
`;

export const HintContentCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px;
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    top: 28px;
    right: 20px;
    padding: 0;
  }
`;
