export const QUESTION_TYPES = {
  SCORE: "score",
  LIST: "list",
  TEXT: "text",
};

export const questions = [
  {
    id: "goal",
    title: "앞으로 코딩을 어떻게 활용해보고 싶으신가요?",
    isNecessary: true,
    type: QUESTION_TYPES.LIST,
    options: [
      "구체적인 계획은 없어요.",
      "재밌어서 취미로 삼고 싶어요.",
      "개발자는 아니지만 커리어에 도움이 될 것 같아요.",
      "개발자로 취업/이직하고 싶어요.",
    ],
    alert: "목표를 선택해주세요!",
  },
  {
    id: "npsScore",
    title: "수강하신 강의 콘텐츠는 얼마나 만족하셨나요?",
    isNecessary: true,
    type: QUESTION_TYPES.SCORE,
    alert: "추천 점수를 선택해주세요!",
  },
  {
    id: "goodPoint",
    title: "(선택) 이 강의의 좋은 점은 무엇인가요?",
    type: QUESTION_TYPES.TEXT,
    placeholder:
      "수업을 들으면서 좋았던 점이 있다면 알려주세요!\n" +
      "예) 빠르기, 난이도, 숙제 내용, 영상, ...",
  },
  {
    id: "badPoint",
    title: "(선택) 이 강의의 아쉬운 점은 무엇인가요?",
    type: QUESTION_TYPES.TEXT,
    placeholder:
      "수업을 들으면서 아쉬웠던 점이 있다면 알려주세요!\n" +
      "예) 빠르기, 난이도, 숙제 내용, 영상, ...",
  },
];
