import React, { useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./NPSScroller.module.scss";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./NPSScroller.custom.css";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function NPSScroller({ setNpsScore, setIsRcmdVisible }) {
  const [current, setCurrent] = useState(5);
  const [isStarted, setIsStarted] = useState(false);
  const theme = useOnlineTheme();
  const valToText = () => {
    if (!isStarted) {
      return "원을 움직여 평가하기";
    } else {
      if (current === 1) {
        return "추천하기엔 아쉬워요.";
      } else if (current === 10) {
        return "완전 추천하고 싶어요!";
      } else {
        return "";
      }
    }
  };
  return (
    <div
      className={cx(
        "nps_scroller__wrapper",
        theme.data,
        `${!isStarted ? "--isNotStarted" : ""}`
      )}
    >
      <div className={cx("nps_scroller__current", theme.data)}>
        {!isStarted ? "0점" : current ? `${current} 점` : "1점"}
      </div>
      <div className={cx("nps_scroller__container")}>
        <Slider
          step={1}
          min={1}
          max={10}
          defaultValue={6}
          onChange={(value) => {
            setIsStarted(true);
            setCurrent(value);
            setNpsScore(value);
            setIsRcmdVisible(value >= 9);
          }}
          marks={{ 1: "1 점", 10: "10 점" }}
        />
      </div>
      <div className={cx("nps_scroller__footer", theme.data)}>
        {valToText()}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled } = state;
  const { user } = authentication;
  return { user, enrolled };
}

const connectedNPSScroller = connect(mapStateToProps, {})(NPSScroller);
export { connectedNPSScroller as NPSScroller };
