export const SPALAND_DATE = {
  SPALAND_DATE_REQUEST: "SPALAND_DATE_REQUEST",
  SPALAND_DATE_SUCCESS: "SPALAND_DATE_SUCCESS",
  SPALAND_DATE_FAILURE: "SPALAND_DATE_FAILURE",
};

export const SPALAND_DATE_ATTENDANCE = {
  SPALAND_DATE_ATTENDANCE_REQUEST: "SPALAND_DATE_ATTENDANCE_REQUEST",
  SPALAND_DATE_ATTENDANCE_SUCCESS: "SPALAND_DATE_ATTENDANCE_SUCCESS",
  SPALAND_DATE_ATTENDANCE_FAILURE: "SPALAND_DATE_ATTENDANCE_FAILURE",
};
