import { useQuery } from "react-query";
import {
  getGovUserInfo,
  getIsNeedDailyGovAuth,
  getPhoneVerification,
} from "../../_state/services";
import privateInstanceV3 from "../../_helper/axiosV3";

export const useCheckDailyGovAuthV2 = (round_id) => {
  return useQuery(
    ["govAuth", round_id],
    () => getIsNeedDailyGovAuth(round_id),
    {
      enabled: !!round_id,
      retry: (failureCount, error) => {
        return failureCount < 1;
      },
    }
  );
};

export const useGetGovUsers = (userId) => {
  const shouldFetch = !!userId;
  return useQuery(
    ["govUsers", userId],
    () => {
      if (shouldFetch) {
        return getGovUserInfo();
      } else {
        return Promise.resolve(null);
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: shouldFetch,
    }
  );
};

export const useCheckPhoneVerification = () => {
  return useQuery(["phoneVerification"], () => getPhoneVerification(), {
    // enabled: !!token,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetUserIP = () => {
  return useQuery(["userIP"], () => getUserIP(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
async function getUserIP() {
  return await privateInstanceV3.get(`/user-info/ip`).then((resp) => resp.data);
}

export const recordMOTPResult = (data) => {
  try {
    const response = postRecordMOTPResult(data);
    return response.data;
  } catch (e) {
    return e.response;
  }
};

async function postRecordMOTPResult(data) {
  return await privateInstanceV3
    .post(`/auth/motp-records`, data)
    .then((resp) => resp.data);
}

export const resetMOTP = (data) => {
  try {
    const response = postResetMOTP(data);
    return response.data;
  } catch (e) {
    return e.response;
  }
};

async function postResetMOTP(data) {
  return await privateInstanceV3
    .post(`/auth/motp-reset`, data)
    .then((resp) => resp.data)
    .catch((err) => err.response);
}
