import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Rtanny.module.scss";
import { connect } from "react-redux";
import { CheckinCard } from "../../CheckinCards/CheckinCard";

const cx = classNames.bind(styles);

function Rtanny({ checkin, idx }) {
  const [isHover, setIsHover] = useState(false);
  return (
    <div className={cx("RtannyBody", `${idx}`)}>
      {isHover && (
        <div className={cx("RtannyBody-hover")}>
          <CheckinCard checkin={checkin} />
        </div>
      )}
      <img
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        src={`/assets/images/checkin/run_rtan.gif`}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedRtanny = connect(mapStateToProps, {})(Rtanny);
export { connectedRtanny as Rtanny };
