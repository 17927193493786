// utils
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function getVBankExpiredDate() {
  const date = addDays(new Date(), 1);
  const year = date.getFullYear();
  const month = ("0" + (1 + date.getMonth())).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return year + month + day;
}

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const getYearEndDay = () => {
  const yearEnd = new Date("2023-01-01 00:00:00");
  const now = new Date();
  const timeDelta = +yearEnd - +now;
  const daysLeft = Math.floor(timeDelta / (1000 * 60 * 60 * 24));
  return daysLeft;
};

export const isValidObjectId = (id) => {
  const objectIdPattern = /^[0-9a-fA-F]{24}$/; // Regular expression pattern for ObjectId
  return objectIdPattern.test(id);
};
