import React, { useState, useEffect } from "react";
import styles from "../../../../Pages/LoginPage/BirthItem.module.scss";
import classNames from "classnames/bind";
import { getOriginalAge } from "../../../_helper";

const cx = classNames.bind(styles);
export const BirthItem = ({
  nextUrl,
  birthYear,
  birthMonth,
  birthDate,
  setState,
}) => {
  const [infoText, setInfoText] = useState(
    "*생일에 엄청난 일이 벌어질지도 몰라요!"
  );

  useEffect(() => {
    if (getOriginalAge(birthYear, birthMonth, birthDate) < 14) {
      setInfoText("*만 14세 미만은 가입이 불가능해요!");
    } else if (nextUrl === "intellipick") {
      setInfoText("");
    } else {
      setInfoText("*생일에 엄청난 일이 벌어질지도 몰라요!");
    }
  }, [birthYear, birthMonth, birthDate]);

  const onChangeYear = (e) => {
    setState({ birthYear: e.target.value.slice(0, 4) });
  };

  const onChangeMonth = (e) => {
    setState({ birthMonth: e.target.value.slice(0, 2) });
  };

  const onChangeDate = (e) => {
    setState({ birthDate: e.target.value.slice(0, 2) });
  };
  return (
    <div className={cx("birthInfo__container")}>
      <label className={cx("birthInfo__label")}>생년월일</label>
      <div className={cx("birthInfoInput__wrapper")}>
        <div className={cx("birthInfoInput__item")}>
          <input
            value={birthYear}
            onChange={onChangeYear}
            placeholder={"2000"}
            className={cx("birthInfoInput__year")}
            type={"number"}
            inputMode={"numeric"}
            pattern={"[0-9]*"}
          />
          <span></span>
        </div>
        <div className={cx("birthInfoInput__item")}>
          <input
            value={birthMonth}
            onChange={onChangeMonth}
            placeholder={"12"}
            className={cx("birthInfoInput__month")}
            type={"number"}
            inputMode={"numeric"}
            pattern={"[0-9]*"}
          />
          <span></span>
        </div>
        <div className={cx("birthInfoInput__item")}>
          <input
            value={birthDate}
            onChange={onChangeDate}
            placeholder={"31"}
            className={cx("birthInfoInput__date")}
            type={"number"}
            inputMode={"numeric"}
            pattern={"[0-9]*"}
          />
          <span></span>
        </div>
      </div>
      <p
        className={cx(
          "birthInfo__notice",
          getOriginalAge(birthYear, birthMonth, birthDate) < 14
            ? "--warning"
            : ""
        )}
      >
        {infoText}
      </p>
    </div>
  );
};
