import styled from "@emotion/styled";
import { neutralDay, wBody3 } from "@teamsparta/design-system";

export const NoticeWrapper = styled.div`
  display: flex;
  width: ${({ isRoadmap }) => (isRoadmap ? "282px" : "100%")};
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: ${neutralDay.gray20};
  margin-bottom: ${({ isRoadmap }) => (isRoadmap ? "16px" : "0")};
  margin-top: ${({ isRoadmap }) => (isRoadmap ? "16px" : "0")};
`;

export const NoticeTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const NoticeTitle = styled.div`
  ${wBody3};
  font-weight: 600;
  color: ${neutralDay.gray95};
  text-align: center;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const NoticeContent = styled.div`
  flex: 1 0 0;
  color: ${neutralDay.gray80};
  font-size: 13px;
  font-style: normal;
  line-height: 160%;
`;

export const FoldingButton = styled.button`
  padding: 0 0 0 30px;
  height: 22px;
  border: none;
  border-radius: 8px;
  font-size: 13px;
  font-style: normal;
  line-height: 160%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  background: transparent;
`;

export const Gap = styled.div`
  height: 16px;
`;
