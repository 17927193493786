import classNames from "classnames/bind";
import styles from "./FinishPage.module.scss";
import {
  NBSQL,
  NOCODE,
  ONLINESQL,
} from "../RoadmapPage/RoadmapAsideBanner/model";

const cx = classNames.bind(styles);

export const ImageBanner = ({ course_id }) => {
  const deviceType = window.checkPlatform();
  const isMobile = deviceType === "mobile";

  const bannerData = getBannerData(course_id);

  if (!bannerData) return <></>;

  return (
    <div className={cx("finish_page__nocodeBanner")}>
      <img
        className={cx("finish_page__nocodeImage")}
        src={`/assets/images/banners/${bannerData.thumb}${
          isMobile ? "_mo" : ""
        }.png`}
        onClick={() => {
          window.location.replace(bannerData.link);
        }}
        alt={bannerData.thumb}
      />
    </div>
  );
};

const getBannerData = (course_id) => {
  switch (course_id) {
    case NOCODE:
      return {
        thumb: "banner_finish_nocode",
        link: "https://spartacodingclub.kr/catalog?category=노코드",
      };
    case NBSQL:
    case ONLINESQL:
      return {
        thumb: "banner_finish_sqld",
        link: "https://spartacodingclub.kr/online/sqld",
      };
    default:
      return null;
  }
};
