import { handleResponse } from "../_helpers";
import { getGETOptions } from "../_urls";
import { getLogCrmABUrl } from "../_urls/logs.api";
import { getDeviceId } from "../v2/businesslogics/logging";

function getLogCrmAB(user_id, exp_no) {
  const device_id = getDeviceId();
  const endpoint = getLogCrmABUrl(user_id, device_id, exp_no);
  const options = getGETOptions();
  return fetch(endpoint, options).then(handleResponse);
}

export const logService = {
  getLogCrmAB,
};
