import React, { useEffect, useState } from "react";
import { LectureNavBar } from "../LectureNavBar/LectureNavBar";
import { useAtom } from "jotai";
import { isLectureListOpen } from "../../../stores/homeworkPageV3Atom";
import { LectureTapMenu } from "../LectureTapMenu/LectureTapMenu";
import * as S from "./LectureDesktop.style";
import { LecPlayerV3 } from "../../LecturePage/LecPlayer/LecPlayerV3/LecPlayerV3";
import { useSyllabusUnit } from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { SideBar } from "../SideBar/SideBar";

export const LectureDesktop = ({ isInit, course, enrolledDetail }) => {
  const [playerType, setPlayerType] = useState("V2");
  const isDesktop = window.innerWidth > 1023;
  const [isOpenList] = useAtom(isLectureListOpen);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const syllabusUnit = useSyllabusUnit(enrolledDetail?.course_id);

  return (
    <>
      <S.LectureWrapper>
        {enrolledDetail && course && (
          <S.LectureLeftSide show={isOpenList && isDesktop}>
            <LectureNavBar
              courseTitle={course?.title}
              week={`${enrolledDetail?.week}${syllabusUnit}`}
            />

            <S.VideoLoadingWrapper show={isDesktop} />
            {/* 영상 */}
            {isInit && (
              <LecPlayerV3
                playerType={playerType}
                setPlayerType={setPlayerType}
              />
            )}
            <LectureTapMenu />
          </S.LectureLeftSide>
        )}
        <SideBar
          courseId={enrolledDetail?.course_id}
          lectureId={enrolledDetail?.lecture_id}
        />
      </S.LectureWrapper>
    </>
  );
};
