const allQuestions = [
  {
    id: "question1",
    title: "강의 분량은 어떠셨나요?",
    options: ["적당했어요.", "짧았어요.", "길었어요."],
    type: "quick",
  },
  {
    id: "question2",
    title: "강의 내용을 얼마나 이해하셨나요?",
    options: ["전혀 이해하지 못함", "보통", "전부 이해함"],
    type: "satisfaction",
  },
  {
    id: "question3",
    title: "튜터님의 설명(목소리, 발음, 예시 등)은 얼마나 만족하셨나요?",
    options: ["매우 불만족", "보통", "매우 만족"],
    type: "satisfaction",
  },
  {
    id: "question4",
    title: "강의 영상의 퀄리티(자막, 볼륨, 편집 등)는 얼마나 만족하셨나요?",
    options: ["매우 불만족", "보통", "매우 만족"],
    type: "satisfaction",
  },
  {
    id: "question5",
    title: "이번 주 강의에 얼마나 만족하셨나요?",
    options: ["매우 불만족", "보통", "매우 만족"],
    type: "satisfaction",
  },
  {
    id: "question6",
    title:
      "이 강의가 #{user_name}님의 수강 목적 달성에 얼마나 도움이 되었나요?",
    options: ["전혀 도움되지 않음", "보통", "매우 도움됨"],
    type: "satisfaction",
  },
  {
    id: "question7",
    title: "수강 시작 상태에 해당되는 항목을 선택해 주세요.",
    options: [
      "처음 입문하는 거예요.",
      "유사 콘텐츠로 공부한 경험이 있어요.",
      "참고용으로 학습하는 숙련자예요.",
    ],
    type: "quick",
    additionalQuestion: "(선택) 공부한 콘텐츠를 적어주세요.",
  },
];

export const firstWeekQuestions = [
  allQuestions[6],
  allQuestions[0],
  allQuestions[1],
  allQuestions[2],
  allQuestions[3],
  allQuestions[4],
];

export const commonQuestions = [
  allQuestions[0],
  allQuestions[1],
  allQuestions[2],
  allQuestions[3],
  allQuestions[4],
];

export const lastWeekQuestions = [
  allQuestions[0],
  allQuestions[1],
  allQuestions[2],
  allQuestions[3],
  allQuestions[4],
  allQuestions[5],
];
