import React, { useState } from "react";
import styles from "../../../../Pages/LoginPage/AgreementItem.module.scss";
import classNames from "classnames/bind";
import { history } from "../../../../_helpers";

const cx = classNames.bind(styles);
export const AgreementItem = ({
  setState,
  serviceAgreement,
  marketingAgreement,
  privacyAgreement,
  nextUrl,
}) => {
  const agreementItemNotice = () => {
    if (nextUrl === "swc" || "nbc" || "chang") {
      return "*사전설명회 등 이벤트나 주요 혜택 정보가 있을 때 안내드립니다.";
    } else if (nextUrl === "hanghae") {
      return "*개발자 취업에 도움이 되는 정보가 있을 때 안내드립니다.";
    } else if (nextUrl === "intellipick") {
      return "";
    } else return "* 코딩 공부에 도움이 되는 이벤트 정보를 알려드려요!";
  };
  return (
    <div className={cx("agreement__container")}>
      <div
        data-testid={"agree-all"}
        className={cx(
          `agreement__checkAll__container--${nextUrl}`,
          marketingAgreement && privacyAgreement && serviceAgreement
            ? `--checked`
            : ""
        )}
        onClick={() => {
          const currentStatus =
            marketingAgreement && privacyAgreement && serviceAgreement;
          setState({ marketingAgreement: !currentStatus });
          setState({ serviceAgreement: !currentStatus });
          setState({ privacyAgreement: !currentStatus });
        }}
      >
        <input
          type={"checkbox"}
          checked={marketingAgreement && privacyAgreement && serviceAgreement}
        />
        <span></span>
        <label>전체 약관 동의</label>
      </div>
      <div className={cx("agreement__item__wrapper")}>
        <div
          className={cx(
            "agreement__item",
            serviceAgreement ? `--checked--${nextUrl}` : ""
          )}
        >
          <svg
            onClick={() => {
              setState({ serviceAgreement: !serviceAgreement });
            }}
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.66663 11.8L8.24996 16.3834L18.3333 6.30005"
              stroke="#B4B6B8"
              strokeWidth="1.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className={cx("agreement__item__desc")}>
            <p className={cx("agreement__item__desc__detail")}>
              [필수] 서비스 약관 동의
            </p>
          </div>
          <span
            onClick={() => {
              window.open(
                "https://teamsparta.notion.site/247d57da1322424d8e8c551df21a048e"
              );
            }}
            className={cx("agreement__item__desc__cta")}
          >
            보기
          </span>
        </div>
        <div
          className={cx(
            "agreement__item",
            privacyAgreement ? `--checked--${nextUrl}` : ""
          )}
        >
          <svg
            onClick={() => {
              setState({ privacyAgreement: !privacyAgreement });
            }}
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.66663 11.8L8.24996 16.3834L18.3333 6.30005"
              stroke="#B4B6B8"
              strokeWidth="1.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className={cx("agreement__item__desc")}>
            <p className={cx("agreement__item__desc__detail")}>
              [필수] 개인정보처리방침 및 제3자 제공 동의
            </p>
          </div>
          <span
            onClick={() => {
              window.open(
                "https://teamsparta.notion.site/7b1dc644460946f08bab08b794de685f"
              );
            }}
            className={cx("agreement__item__desc__cta")}
          >
            보기
          </span>
        </div>
        <div
          className={cx(
            "agreement__item",
            marketingAgreement ? `--checked--${nextUrl}` : ""
          )}
        >
          <svg
            onClick={() => {
              setState({ marketingAgreement: !marketingAgreement });
            }}
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.66663 11.8L8.24996 16.3834L18.3333 6.30005"
              stroke="#B4B6B8"
              strokeWidth="1.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div className={cx("agreement__item__desc")}>
            <p className={cx("agreement__item__desc__detail")}>
              [선택] 광고성 정보 수신 동의
            </p>
          </div>
        </div>
        {history.location.search.includes("hyoneung") ? (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <img
              style={{
                width: "24px",
                height: "24px",
              }}
              src="/assets/icons/hyoneung.png"
            />
            <p className={cx("agreement__item__red")}>
              마케팅 정보 수신에 동의해야 ‘코딩의 효능' 이벤트에 참여하실 수
              있어요!
            </p>
          </div>
        ) : (
          <p className={cx("agreement__item__notice")}>
            {agreementItemNotice()}
          </p>
        )}
      </div>
    </div>
  );
};
