import React, { useState } from "react";
import * as S from "./BusinessInfo.style.js";
import { FooterSNS } from "./FooterSNS";

export const BusinessInfo = ({
  infoDropDownText,
  infoDropDownIconImg,
  businessInfoItems,
  footerSNSItems,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <S.BusinessInfo>
      <S.InfoDropDown onClick={toggleShowInfo}>
        {infoDropDownText}&nbsp;
        <S.InfoDropDownIcon
          isActive={showInfo}
          src={infoDropDownIconImg}
          alt={infoDropDownText}
        />
      </S.InfoDropDown>
      <S.InfoDetail show={showInfo}>
        {businessInfoItems.map((item, i) => {
          if (item.href) {
            return (
              <S.InfoDetailLine key={item.value}>
                <S.InfoDetailLink target="_blank" href={item.href}>
                  {item.value}
                </S.InfoDetailLink>
              </S.InfoDetailLine>
            );
          } else {
            return (
              <React.Fragment key={item.value}>
                <S.InfoDetailLine>
                  <S.InfoDetailLineTitle>{item.title}</S.InfoDetailLineTitle>
                  <S.InfoDetailLineValue>{item.value}</S.InfoDetailLineValue>
                  {i < businessInfoItems.length - 1 && (
                    <S.Divider> | </S.Divider>
                  )}
                </S.InfoDetailLine>
              </React.Fragment>
            );
          }
        })}
      </S.InfoDetail>
    </S.BusinessInfo>
  );
};
