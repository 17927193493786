import React from "react";
import { Modal } from "../../../components/organisms/Modal/Modal";
import * as S from "./enterCourseButton.style";

interface EnterCourseButtonProps {
  text: string;
  isModalOpen: boolean;
  onEnter?: () => void;
  modalData?: any;
}

export const EnterCourseButton = ({
  text,
  onEnter,
  modalData,
  isModalOpen,
}: EnterCourseButtonProps) => {
  return (
    <S.ButtonContainer>
      <S.EnterButton
        data-testid="enter-course"
        disabled={!onEnter}
        onClick={() => {
          onEnter?.();
        }}
      >
        {text}
      </S.EnterButton>
      {isModalOpen && <Modal {...modalData} />}
    </S.ButtonContainer>
  );
};
