import React from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoadmapAsideBannerContent.module.scss";
import { sendLog } from "../../../businesslogics/logging";
import { getAmplitudeDeviceId } from "../../../../_helpers/amplitude";

const cx = classNames.bind(styles);

const RoadmapAsideBannerContent = ({ banner, round }) => {
  const openInNewTab = () => {
    let url = new URL(banner.url);
    if (
      !banner.url.includes("spartacodingclub.kr") &&
      !banner.url.includes("127.0.0.1")
    ) {
      url.searchParams.append("deviceId", getAmplitudeDeviceId());
    }
    const newWindow = window.open(url, "noopener,noreferrer");

    sendLog(
      "scc_roadmapPage_click_banner",
      {
        banner_location: "right",
        banner_button_title: banner.buttonTitle ?? "자세히 보러가기",
        round_id: round.data._id,
        round: round.data.round,
      },
      null
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div className={cx("roadmapAsideBannerContent")}>
        <img
          src={banner.image}
          onClick={() => {
            openInNewTab(banner);
          }}
          width={292}
          height={164}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { round, enrolled } = state;
  return { round, enrolled };
}

const connectedRoadmapAsideBannerContent = connect(
  mapStateToProps,
  {}
)(RoadmapAsideBannerContent);
export { connectedRoadmapAsideBannerContent as RoadmapAsideBannerContent };
