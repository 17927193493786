import { history } from "../../../../_helpers";
import privateInstance from "../../../_helper/axios";
import { getISODateAsKoreanWithYear } from "../../RoadmapPage/RoundWeek/utils";

interface IProps {
  cutOffLine: string;
  enrolledId: string;
  roundId: string;
  extensionCourse: any;
  user: any;
}

export const getClassroomModalData = ({
  cutOffLine,
  enrolledId,
  roundId,
  extensionCourse,
  user,
}: IProps) => {
  const retryModalData = {
    header: "앗, 벌써 3일이 지나갔군요!",
    img_path: "/assets/images/objective/run_late.png",
    content: `${
      user.name
    }님\n아쉽게도 무료특강 수강기간이 종료되었군요.\n재수강 신청은 ${getISODateAsKoreanWithYear(
      cutOffLine
    )}까지 가능하답니다!\n재수강 기회는 딱 한번 밖에 없어요!\n\n이번에는 꼭 완주해주실거죠?`,
    cta: {
      btn_text: "네 꼭 완주할게요!",
      handleCTAClick: async () => {
        try {
          await privateInstance.post(`/enrolleds/${enrolledId}/retrytrial`);
          history.push(`/enrolleds/${enrolledId}/rounds/${roundId}/roadmap`);
        } catch (e) {
          console.log(e);
          alert("오류가 발생했습니다. \n에러코드: F2233");
        }
      },
    },
  };

  const extendModalData = {
    header: "앗, 벌써 수강기간이 지나갔군요!",
    img_path: "/assets/images/objective/run_late.png",
    content: `${
      user.name
    }님\n아쉽게도 수강기간이 종료되었네요.\n기강 연장 신청은 일주일 동안 가능하답니다!\n추가 수강기간 ${
      extensionCourse ? Math.floor(extensionCourse.days / 7) : 5
    }주 내에 꼭 완주해보아요.`,
    caution: `수강기간 연장 비용: ${
      extensionCourse ? extensionCourse.display_price / 10000 : 10
    }만원 (포인트 사용 가능)`,
    cta: {
      btn_text: "수강기간 연장하기",
      handleCTAClick: () => {
        history.push(
          `/payment/${
            extensionCourse ? extensionCourse._id : "61512628760514cb872f2a2b"
          }?enrolled_id=${enrolledId}`
        );
      },
    },
  };

  return { retryModalData, extendModalData };
};
