import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./CourseRcmdPage.module.scss";
import { history, params } from "../../../_helpers";
import {
  abtestActions,
  enrolledActions,
  enrolledSummaryActions,
} from "../../_state/actions";
import { sendLog } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

function CourseRcmdPage({
  user,
  enrolledSurvey,
  getEnrolledFinishSurvey,
  postEnrolledFinishSurvey,
  enrolled,
  enrolledDetails,
  setEnrolled,
  getABTestCourseRcmd,
  getEnrolledFinishSummary,
  getEnrolledDetails,
  enrolledFinishSummary,
}) {
  const { enrolled_id } = params();

  const [isTrial, setIsTrial] = useState(false);
  const [isDeveloperIntro, setIsDeveloperIntro] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const course_rcmd_questions = isTrial
    ? [
        {
          title: isDeveloperIntro
            ? "독학으로 개발을 배우고 싶어요."
            : "코딩과 데이터를 배워 경쟁력을 갖추고 싶어요.",
          value: "online",
        },
        { title: "전문성을 가진 개발자로 취업하고 싶어요.", value: "hanghae" },
        { title: "뭘 해야 할 지 아직 잘 모르겠어요.", value: "onlineBeginner" },
      ]
    : [
        {
          title: "코딩과 데이터를 배워 경쟁력을 갖추고 싶어요.",
          value: "online",
        },
        { title: "전문성을 가진 개발자로 취업하고 싶어요.", value: "hanghae" },
        { title: "뭘 해야 할 지 아직 잘 모르겠어요.", value: "onlineBeginner" },
      ];
  const onOptionSelected = (value) => {
    if (selectedValue === value) {
      setSelectedValue(-1);
    } else {
      setSelectedValue(value);
    }
  };

  useEffect(() => {
    if (enrolledFinishSummary.loaded) {
      setIsDeveloperIntro(enrolledFinishSummary.data.is_developer_intro);
      setIsTrial(enrolledFinishSummary.data.is_trial);
    }
  }, [enrolledFinishSummary.loaded]);

  useEffect(() => {
    if (!enrolledFinishSummary.loaded) getEnrolledFinishSummary(enrolled_id);
    if (!enrolled.loaded) setEnrolled(enrolled_id);
    if (!enrolledSurvey.loaded) getEnrolledFinishSurvey(enrolled_id);
    if (!enrolledDetails.loaded) getEnrolledDetails(enrolled_id);
  }, []);

  useEffect(() => {
    if (enrolled.loaded && enrolledDetails.loaded) {
      sendLog("scc_rcmdSurveyPage_view", {
        ...enrolled.data,
        ...enrolledDetails.data,
      });
    }
  }, [enrolled.loaded, enrolledDetails.loaded]);

  return (
    <div className={cx("course_rcmd__wrapper")}>
      <div className={cx("course_rcmd__header")}>
        <img
          src={"/assets/images/finish/ic_course_rcmd_rtan.png"}
          alt={"ic_course_rcmd_rtan"}
        />
        <h1>
          {user.name}님은
          <br /> 어떤 목표를 가지고 계신가요?
        </h1>
        <p>
          코딩으로 <b>이루고 싶은 목표</b>를 알려주세요!
          <br />
          {user.name}님의 <b>목표 달성을 위한 코스</b>를 알려드릴게요.
        </p>
      </div>
      <div className={cx("course_rcmd__body")}>
        {course_rcmd_questions.map((question, idx) => {
          return (
            <div
              className={cx(
                "course_rcmd__question",
                `${question.value === selectedValue ? "--selected" : ""}`
              )}
              onClick={() => {
                onOptionSelected(question.value);
              }}
            >
              <svg
                className={cx("course_rcmd__question__icon")}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11a11 11 0 1 1-22 0 11 11 0 0 1 22 0zm-5.459-4.166a1.03 1.03 0 0 0-1.485.03l-4.775 6.084-2.878-2.879a1.031 1.031 0 0 0-1.457 1.458l3.638 3.64a1.03 1.03 0 0 0 1.483-.028l5.49-6.861a1.031 1.031 0 0 0-.014-1.444h-.002z"
                  fill="#fff"
                />
              </svg>
              <span className={cx("course_rcmd__desc")}>{question.title}</span>
            </div>
          );
        })}
      </div>
      <div className={cx("course_rcmd__footer")}>
        <button
          className={cx(`${selectedValue !== "" ? "--active" : ""}`)}
          onClick={() => {
            if (!isWaiting && selectedValue !== "") {
              setIsWaiting(true);
              postEnrolledFinishSurvey(enrolled_id, {
                vote: selectedValue,
                goal: selectedValue,
              }).then((res) => {
                if (res.ok) {
                  sendLog("scc_rcmdSurvey_completed", {
                    ...enrolled.data,
                    ...enrolledDetails.data,
                    enrolledSurvey: enrolledSurvey.data || {},
                    selectedValue,
                  });
                  window.ChannelIO(
                    "boot",
                    {
                      pluginKey: "74fefd0d-c880-4ec7-b06f-d09eeba19b43",
                      hideChannelButtonOnBoot: true,
                      memberId: user._id,
                      unsubscribed: !user.marketing,
                      profile: {
                        name: user.name,
                        mobileNumber: user.phone,
                        email: user.email,
                        slack_email: user.slack_email,
                        marketing: user.marketing,
                      },
                    },
                    window.ChannelIO("track", "course_rcmd", {
                      select: selectedValue,
                      title: enrolled.data.courses.title,
                      round_id: enrolled.data.round_id,
                    })
                  );

                  if (selectedValue === "online") {
                    if (isTrial) {
                      history.push(
                        `/course/rcmd/A/enrolleds/${enrolled_id}?selectedValue=normal`
                      );
                    } else {
                      history.push(
                        `/course/rcmd/general?enrolled_id=${enrolled_id}&selectedValue=${selectedValue}`
                      );
                    }
                  } else if (selectedValue === "hanghae") {
                    history.push(`/course/rcmd/hanghae`);
                    // history.push(`/course/rcmd/hanghae_orientation/invitation`);
                  } else if (selectedValue === "changLite") {
                    history.push(`/course/rcmd/chang`);
                  } else if (selectedValue === "onlineBeginner") {
                    if (isTrial) {
                      history.push(
                        `/course/rcmd/A/enrolleds/${enrolled_id}?selectedValue=beginner`
                        // `/course/rcmd/A?enrolled_id=${enrolled_id}&selectedValue=beginner`
                      );
                    } else {
                      history.push(
                        `/course/rcmd/general?enrolled_id=${enrolled_id}&selectedValue=${selectedValue}`
                      );
                    }
                  } else {
                    alert("목표를 선택해주세요.");
                    setIsWaiting(false);
                  }
                }
              });
            } else {
              alert("목표를 선택해주세요.");
              setIsWaiting(false);
            }
          }}
        >
          {isWaiting
            ? "처리 중입니다."
            : selectedValue !== ""
            ? "나를 위한 코스 확인하기"
            : "목표를 선택해주세요."}
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    enrolledSurvey,
    enrolledFinishSummary,
    enrolled,
    enrolledDetails,
    abtestCourseRcmd,
  } = state;
  const { user } = authentication;
  return {
    user,
    enrolledSurvey,
    enrolledFinishSummary,
    enrolled,
    enrolledDetails,
    abtestCourseRcmd,
  };
}

const connectedCourseRcmdPage = connect(mapStateToProps, {
  getEnrolledFinishSurvey: enrolledActions.getEnrolledFinishSurvey,
  postEnrolledFinishSurvey: enrolledActions.postEnrolledFinishSurvey,
  getEnrolledFinishSummary: enrolledSummaryActions.getEnrolledFinishSummary,
  setEnrolled: enrolledActions.setEnrolled,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getABTestCourseRcmd: abtestActions.getABTestCourseRcmd,
})(CourseRcmdPage);
export { connectedCourseRcmdPage as CourseRcmdPage };
