const codeSnippetsAnchor = {
  key: "code",
  name: "코드스니펫",
  type: "content",
};

const fastQnaAnchor = {
  key: "fastQna",
  name: "학습 질문",
  type: "outlink",
  href: "https://spartacodingclub.kr/community/fastqna",
};

// const codeCheck = {
//   key: "codeCheck",
//   name: "코드 체크",
//   type: "content",
// };

const lectureMaterialAnchor = {
  key: "material",
  name: "강의 자료",
  type: "content",
};

const reportAnchor = {
  key: "report",
  name: "오류 제보",
  type: "content",
};

const lectureListAnchor = {
  key: "lectureList",
  name: "강의 목차",
  type: "content",
};

export const desktopAnchors = [
  codeSnippetsAnchor,
  //codeCheck,
  fastQnaAnchor,
  lectureMaterialAnchor,
  reportAnchor,
];

export const mobileAnchors = [
  lectureListAnchor,
  codeSnippetsAnchor,
  fastQnaAnchor,
  lectureMaterialAnchor,
];
