import React, { Component } from "react";
import {
  Card,
  Collapse,
  Input,
  Button,
  Tooltip,
  Modal,
  message,
  Skeleton,
} from "antd";
import classNames from "classnames/bind";
import { CaretRightOutlined, QuestionCircleFilled } from "@ant-design/icons";
import styles from "./HomeworkPage.module.scss";
import { LectureNav, LectureNavEn } from "../../_components/LectureNav";
import { connect } from "react-redux";
import { homeworkActions, lectureActions } from "../../_actions";
import Editor from "react-simple-code-editor";
import { history } from "../../_helpers";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-python";
import "prismjs/components/prism-markup";
import { getLectureUrl } from "../../_urls/lectures";
import { getHomeworkUrl } from "../../_urls";
import { NPS, NPSEn } from "../../_components/NPS";

const { Panel } = Collapse;
const { TextArea } = Input;

const cx = classNames.bind(styles);
const languageList = {
  python: languages.python,
  css: languages.css,
  js: languages.js,
  markup: languages.markup,
};

class HomeworkPageEn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round_id: this.props.match.params.round_id,
      homework_id: this.props.match.params.homework_id,
      details: [],
      isDone: false,
      isLoading: false,
      goToNextModalVisible: false,
      editing: false,
      isSubmitButtonPressed: false,
      isNpsShow: false,
      final_review_url: "",
      nav_selected: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { round_id, homework_id } = this.state;
    this.props.getHomeworkItem(round_id, homework_id);
    this.setState({
      isNpsShow: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.homework.loaded && this.props.homework.loaded) {
      this.setState({
        isLoading: false,
        final_review_url: this.props.homework.data.final_review_url,
      });

      if (this.props.homework.error) {
        alert(this.props.homework.error);
      } else if (this.props.homework && this.state.details.length === 0) {
        this.setState({
          details: this.props.homework.data.details,
          isDone: this.props.homework.isDone,
        });
      }

      const { is_nps_done } = this.props.homework.nps;
      if (this.props.homework.isSubmitted) {
        if (is_nps_done === false) {
          this.handleOk();
        } else {
          this.setState({
            goToNextModalVisible: true,
          });
        }
      }
    }
  }

  onEditorChange(order, code) {
    this.setState({
      editing: true,
      details: this.state.details.map((item) => {
        if (order === item.order) {
          let new_item = JSON.parse(JSON.stringify(item));
          new_item.answer = code;
          return { ...item, ...new_item };
        } else {
          return item;
        }
      }),
    });
  }

  onFinalReviewChange = (final_review_url) => {
    this.setState({ final_review_url });
  };

  _showSuccessMessage = () => {
    const { point } = this.props.homework.data;
    message.success({
      content: `+${point} points!`,
      className: "custom-class",
      style: {
        position: "absolute",
        top: "7px",
        right: "40px",
      },
    });
  };

  submitHomework() {
    const { round_id, homework_id, details, final_review_url } = this.state;
    for (let i = 0; i < details.length; i++) {
      if (details[i].answer === "") {
        alert(`Type in your HW code.`);
        return;
      }
    }
    this.setState({
      isLoading: true,
      isSubmitButtonPressed: true,
      goToNextModalVisible: true,
    });
    this.props.postHomeworkItem(
      round_id,
      homework_id,
      details,
      final_review_url
    );
  }

  _setIsNpsShow = (value) => {
    this.setState({
      isNpsShow: value,
    });
  };

  handleNavStatus = (nav_selected) => {
    this.setState({
      nav_selected,
    });
  };

  handleOk = () => {
    if (this.props.homework.isSubmitted) {
      const { isDone } = this.state;
      if (isDone === false) {
        this._showSuccessMessage();
      }

      // NPS 제출 여부에 따라 분기
      const { nps } = this.props.homework;
      if (nps.is_nps_done === false) {
        this.setState({
          isNpsShow: true,
        });
        return;
      }

      // NPS 를 이미 제출했기 때문에 다음 내용으로 이동
      this.setState({
        goToNextModalVisible: false,
        isNpsShow: false,
      });
      const { round_id } = this.state;
      const { next_id, enrolled_id, next_week_allowed, next_start_date } =
        this.props.homework.data;
      if (!next_id) {
        alert(
          "Finally, the course is over.\nCongratulations for your completion! 🏅"
        );
        history.push(`/en/finish?enrolled_id=${enrolled_id}`);
        return;
      }
      if (!next_week_allowed) {
        alert(`다음 주차는 ${next_start_date}부터 수강 가능합니다.`);
        return;
      }
      let chosenUrl;
      if (next_id.includes("lectures")) {
        const lecture_id = next_id.split("/")[1];
        // this.props.getLecture(round_id, lecture_id);
        chosenUrl = getLectureUrl(round_id, lecture_id);
      } else {
        chosenUrl = getHomeworkUrl(round_id, next_id);
        this.props.getHomeworkItem(round_id, next_id);
      }
      history.push("/en" + chosenUrl);
    }
  };

  handleCancel = () => {
    this.setState({
      goToNextModalVisible: false,
    });
  };

  render() {
    const round_id = this.props.match.params.round_id;
    const homework_id = this.props.match.params.homework_id;
    let data = { round_id, homework_id };

    const {
      isDone,
      isLoading,
      goToNextModalVisible,
      isSubmitButtonPressed,
      isNpsShow,
    } = this.state;
    const modalText = this.props.homework.isSubmitted
      ? "Your HW is submitted. Let's move on!"
      : "Please submit your HW before moving on.";
    let details;
    if (this.state.editing) {
      details = this.state.details;
    } else {
      details =
        this.props.homework.data.details === undefined
          ? []
          : this.props.homework.data.details;
    }

    const { homework } = this.props;
    const defaultActiveKey = details.map((detail, idx) => {
      // return detail.answer === '' ? undefined : String(detail.order)
      return String(detail.order);
    });

    const { course_title } = homework.data;
    if (!homework) {
      return <Skeleton />;
    }

    return (
      <div>
        {!(
          homework.isDone === true &&
          this.props.homework.nps.is_nps_done === false
        ) && (
          <div>
            <div className={cx("lectureNavWrapper")}>
              <div className={cx("lectureNavContainer")}>
                <LectureNavEn
                  handleNavStatus={this.handleNavStatus}
                  data={data}
                />
              </div>
            </div>
            {homework.data && (
              <div className={cx("homeworkContainer")}>
                <h3>
                  Chap.{homework.data.week} Homework - {homework.data.title}
                </h3>
                <div className={cx("textContainer")}>
                  {homework.data.material && (
                    <p style={{ fontSize: 16 }}>
                      👉Find information from lecture notes(
                      <a
                        style={{ color: "black", textDecoration: "underline" }}
                        href={homework.data.material["link"]}
                        target="_blank"
                      >
                        link
                      </a>
                      ) 📒
                    </p>
                  )}
                  <p style={{ fontSize: 16 }}>
                    👉Peek at{" "}
                    <a
                      style={{ color: "black", textDecoration: "underline" }}
                      href={homework.data.answer}
                      target="_blank"
                    >
                      the answersheet(link)
                    </a>{" "}
                    if needed 👀
                  </p>
                  {isDone && (
                    <p style={{ fontSize: 16 }}>
                      👉You already submitted your HW. You can edit your HW
                      again.
                    </p>
                  )}
                </div>

                {details.length > 0 && (
                  <Collapse
                    bordered={false}
                    defaultActiveKey={defaultActiveKey}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    {details.map((detail, idx) => {
                      return (
                        <Panel header={detail.description} key={detail.order}>
                          <Editor
                            value={detail.answer}
                            onValueChange={(code) =>
                              this.onEditorChange(detail.order, code)
                            }
                            highlight={(code) =>
                              highlight(code, languageList[detail.language])
                            }
                            padding={10}
                            tabSize={4}
                          />
                        </Panel>
                      );
                    })}
                  </Collapse>
                )}
                <Button
                  className={cx("submitButton")}
                  loading={isLoading}
                  onClick={() => this.submitHomework()}
                >
                  Submit
                </Button>
                <Modal
                  className={cx("modal")}
                  visible={
                    goToNextModalVisible &&
                    isSubmitButtonPressed &&
                    this.props.homework.nps.is_nps_done
                  }
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  okText="Continue"
                  cancelText="Go to roadmap"
                  centered
                  closable={true}
                  cancelButtonProps={{ style: { display: "none" } }}
                >
                  <div>{modalText}</div>
                </Modal>
              </div>
            )}
          </div>
        )}
        {homework.isDone === true &&
          this.props.homework.nps.is_nps_done === false && (
            <NPSEn
              nps={this.props.homework.nps}
              next_id={this.props.homework.data.next_id}
              course_title={course_title}
              _setIsNpsShow={this._setIsNpsShow}
            />
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { homework } = state;
  return {
    homework,
  };
}

const connectedHomeworkPageEn = connect(mapStateToProps, {
  getHomeworkItem: homeworkActions.getHomeworkItem,
  postHomeworkItem: homeworkActions.postHomeworkItem,
  getLecture: lectureActions.getLecture,
})(HomeworkPageEn);
export { connectedHomeworkPageEn as HomeworkPageEn };
