import * as S from "../../../pages/LecturePageV3/SideBar/SideBar.style";
import {
  SideBarTabs,
  useCanRenderSideBarTabs,
} from "../../../models/SideBar/SideBarCondition";
import { useLogLectureSidebarClick } from "../../../hooks/homework/useLogHomeworkClick";
import { NavigationClose } from "@teamsparta/design-system";
import { useAtom } from "jotai";
import { isLectureListOpen } from "../../../stores/homeworkPageV3Atom";

export const SideBarTitleTab = ({ selectedTab, setSelectedTab }) => {
  const isTabVisible = useCanRenderSideBarTabs();
  const logLectureSideBarClick = useLogLectureSidebarClick();
  const [isOpenSideBar, setIsOpenSideBar] = useAtom(isLectureListOpen);

  return (
    <S.SideBarTitleContainer>
      <S.TabTitleContainer>
        {SideBarTabs?.map((tab) => (
          <S.TabTitle
            key={tab.name}
            focus={selectedTab === tab.name}
            visible={isTabVisible(tab.name)}
            onClick={() => {
              logLectureSideBarClick(tab.name);
              setSelectedTab(tab.name);
            }}
          >
            {tab.name}
            {tab.name === "AI 챗봇" && <S.Tag>Beta</S.Tag>}
          </S.TabTitle>
        ))}
      </S.TabTitleContainer>
      <S.SideBarClose
        onClick={() => {
          logLectureSideBarClick("사이드바 닫기");
          setIsOpenSideBar(!isOpenSideBar);
        }}
      >
        <NavigationClose size={20} />
      </S.SideBarClose>
    </S.SideBarTitleContainer>
  );
};
