import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoadmapHeader.module.scss";
import { enrolledActions } from "../../../_state/actions";
import { useOnlineTheme } from "../../../queries/pirates";
import { PIRATES } from "../../../../_consts";

const cx = classNames.bind(styles);

function RoadmapHeader({
  user,
  round,
  enrolled,
  enrolledPlaytime,
  enrolledPeriod,
  enrolledProgress,
  enrolledMaker,
  getMaker,
  getEnrolledPlaytime,
  getEnrolledProgress,
  getEnrolledPeriod,
}) {
  const [titleCopy, setTitleCopy] = useState("");
  useEffect(() => {
    const { enrolled_id } = enrolled.data;
    getEnrolledPlaytime(enrolled_id);
    getEnrolledProgress(enrolled_id);
    getEnrolledPeriod(enrolled_id);
    if (!enrolledMaker.loaded) {
      getMaker(enrolled_id);
    }
  }, [enrolled]);
  const idx = parseInt(Math.random() * cheerups.length);
  const header = `${user.name}님, ${cheerups[idx]}`;
  const theme = useOnlineTheme();

  useEffect(() => {
    if (enrolledProgress.loaded && theme.isSuccess) {
      if (theme.data === PIRATES) {
        setTitleCopy("떠나자, 코딩의 바다 속으로!");
        return;
      }

      const progress_rate = enrolledProgress.data;
      if (progress_rate < 10) {
        setTitleCopy("지금 바로 르탄이와 달려보세요!");
      } else if (progress_rate === 100) {
        setTitleCopy(`르탄이가 무사히 성에 도착했어요. 고마워요!`);
      } else if (progress_rate > 90) {
        setTitleCopy(`성이 코 앞이에요!`);
      } else {
        setTitleCopy(`르탄이가 성에 ${progress_rate}% 만큼 가까워지는중`);
      }
    }
  }, [enrolledProgress.loaded, theme.isSuccess]);

  return (
    <div className={cx("roadmap-header", theme.data)}>
      {!round.data.is_forever && !round.data.is_gov_credit && !round.data.round.includes("항해") ? (
        <>
          <div className={cx("roadmap-header__main")}>{titleCopy}</div>
          <div className={cx("roadmap-header__sub")}>
            {enrolledPeriod.loaded && (
              <div className={cx("roadmap-header__sub__detail")}>
                남은{" "}
                {enrolledMaker.loaded &&
                  enrolledMaker.data.maker_start_date &&
                  "만들기"}{" "}
                기간
                <b>
                  {enrolledPeriod.data.is_forever
                    ? "평생!"
                    : `${enrolledPeriod.data.weeks}주 + ${enrolledPeriod.data.days}일`}
                </b>
              </div>
            )}
            <div className={cx("roadmap-header__sub__splitter")}>|</div>
            {enrolledPlaytime.loaded && (
              <div className={cx("roadmap-header__sub__detail")}>
                이번주 공부 시간
                <b>{enrolledPlaytime.data.week_playtime} 분</b>
              </div>
            )}
          </div>
        </>
      ) : (
        header
      )}
    </div>
  );
}

const cheerups = [
  "오늘도 달려볼까요? 🔥",
  "코딩할 준비 되셨죠? 오늘도 완주까지 빠샤! 👊",
  "일단 여기 오신 것만으로도 잘하셨어요! 🥰",
  "딱 코딩하기 좋은 날씨에요! 🥳",
  "포기는 배추 셀 때나 쓰는 것! 포기란 없어요. 💪",
  "오늘도 유익한 수업이 기다리고 있어요! 😎",
  "오늘은 연속 5개 강의 들을 각! 어서 들으러 가요 😆",
  "천리길도 Hello World 부터랍니다. 😇",
  "완주가 눈앞이에요. 나 Java봐라~ 🏃‍♀️",
  "오늘 하루는 어땠나요?",
  "이제 슬슬 재미가 붙나요?",
  "어디서 타는 냄새 안나요? 제 파이팅이 불타고 있어요!",
  "오늘이 딱 꿀잼 주차!",
  "오늘도 고고싱!",
];

function mapStateToProps(state) {
  const {
    authentication,
    round,
    enrolled,
    enrolledPlaytime,
    enrolledPeriod,
    enrolledProgress,
    enrolledMaker,
  } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    round,
    enrolled,
    enrolledPlaytime,
    enrolledPeriod,
    enrolledProgress,
    enrolledMaker,
  };
}

const connectedRoadmapHeader = connect(mapStateToProps, {
  getEnrolledPlaytime: enrolledActions.getEnrolledPlaytime,
  getEnrolledProgress: enrolledActions.getEnrolledProgress,
  getEnrolledPeriod: enrolledActions.getEnrolledPeriod,
  getMaker: enrolledActions.getMaker,
})(RoadmapHeader);
export { connectedRoadmapHeader as RoadmapHeader };
