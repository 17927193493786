import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./SparthonPage.module.scss";
import { connect } from "react-redux";
import { paymentActions } from "../../../_actions";
import axios from "../../../_helpers/axios";
import SparthonPaymentDone from "../../components/organisms/SparthonPaymentDone/SparthonPaymentDone";
import { sendLog } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

const SparthonPage = ({
  user,
  getPaymentInfoByCourseId,
  paymentInfoByCourseId,
}) => {
  const [isAllowed, setAllowed] = useState(false);

  useEffect(() => {
    getPaymentInfoByCourseId("60d427c0f15b251027b0e7b9", "");
  }, []);

  useEffect(() => {
    if (!paymentInfoByCourseId.loaded) {
      return;
    }
    if (paymentInfoByCourseId.data.context.course.is_registered) {
      setAllowed(true);
    }
  }, [paymentInfoByCourseId]);

  useEffect(() => {
    if (isAllowed) {
      axios
        .post("/coupons/register", { couponCode: "sparthon3" })
        .then((resp) => {
          const { data } = resp;
          console.log(data);
        })
        .catch((error) => {
          if (error.response) {
            console.error(error.response.data.message);
          }
        });
    }
  }, [isAllowed]);

  if (!isAllowed) {
    return <></>;
  }
  sendLog(
    "scc_purchase_completed",
    { course_title: paymentInfoByCourseId.data?.context?.course?.title },
    "",
    true
  );
  return <SparthonPaymentDone name={user.name} />;
};

function mapStateToProps(state) {
  const { authentication, paymentInfoByCourseId } = state;
  const { user } = authentication;
  return { authentication, user, paymentInfoByCourseId };
}
const connectedSparthonPage = connect(mapStateToProps, {
  getPaymentInfoByCourseId: paymentActions.getPaymentInfoByCourseId,
})(SparthonPage);
export { connectedSparthonPage as SparthonPage };
