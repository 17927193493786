import React from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./UserSettings.module.scss";
import privateInstance from "../../../_helper/axios";
import { sendLog } from "../../../businesslogics/logging";

const cx = classNames.bind(styles);

function UserSettings({
  settings,
  setIsDual,
  isDual,
  enrolled,
  enrolledDetail,
}) {
  const changeSettings = (value) => {
    const data = {
      is_dual: value,
    };
    return privateInstance.post(`/users/settings`, data).then((resp) => {
      const result = resp.data;
      if (result.ok) {
        setIsDual(value);
      }
    });
  };
  return (
    <>
      {enrolled.loaded && enrolledDetail.loaded && (
        <div className={cx("UserSettingsContainer")}>
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <td>수강 모드</td>
                <td>
                  {isDual && (
                    <>
                      <span
                        onClick={() => {
                          sendLog(
                            "scc_lecturePage_click_button",
                            {
                              ...enrolled.data,
                              ...enrolledDetail.data,
                              button_text: "싱글",
                              button_name: "수강환경 설정",
                            },
                            ""
                          );
                          changeSettings(false);
                        }}
                      >
                        싱글
                      </span>
                      <span
                        onClick={() => {
                          sendLog(
                            "scc_lecturePage_click_button",
                            {
                              ...enrolled.data,
                              ...enrolledDetail.data,
                              button_text: "듀얼",
                              button_name: "수강환경 설정",
                            },
                            ""
                          );
                          changeSettings(true);
                        }}
                        className={cx("active")}
                      >
                        듀얼
                      </span>
                    </>
                  )}
                  {!isDual && (
                    <>
                      <span
                        onClick={() => {
                          sendLog(
                            "scc_lecturePage_click_button",
                            {
                              ...enrolled.data,
                              ...enrolledDetail.data,
                              button_text: "싱글",
                              button_name: "수강환경 설정",
                            },
                            ""
                          );
                          changeSettings(false);
                        }}
                        className={cx("active")}
                      >
                        싱글
                      </span>
                      <span
                        onClick={() => {
                          sendLog(
                            "scc_lecturePage_click_button",
                            {
                              ...enrolled.data,
                              ...enrolledDetail.data,
                              button_text: "듀얼",
                              button_name: "수강환경 설정",
                            },
                            ""
                          );
                          changeSettings(true);
                        }}
                      >
                        듀얼
                      </span>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    codeSnippets,
    enrolled,
    enrolledDetail,
    enrolledDetails,
  } = state;
  const { user } = authentication;
  return {
    codeSnippets,
    authentication,
    user,
    enrolled,
    enrolledDetail,
    enrolledDetails,
  };
}

const connectedUserSettings = connect(mapStateToProps, {})(UserSettings);
export { connectedUserSettings as UserSettings };
