import { ENROLLED_SUMMARY } from "../constants";
import { enrolledSummaryService } from "../services";

function getEnrolledSummary() {
  function request() {
    return { type: ENROLLED_SUMMARY.ENROLLED_SUMMARY_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_SUMMARY.ENROLLED_SUMMARY_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_SUMMARY.ENROLLED_SUMMARY_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledSummaryService.getEnrolledSummary().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}
function getEnrolledFinishSummary(enrolled_id) {
  function request() {
    return { type: ENROLLED_SUMMARY.ENROLLED_FINISH_SUMMARY_REQUEST };
  }

  function success(response) {
    return { type: ENROLLED_SUMMARY.ENROLLED_FINISH_SUMMARY_SUCCESS, response };
  }

  function failure(error) {
    return { type: ENROLLED_SUMMARY.ENROLLED_FINISH_SUMMARY_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    enrolledSummaryService.getEnrolledFinishSummary(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}
export const enrolledSummaryActions = {
  getEnrolledSummary,
  getEnrolledFinishSummary,
};
