import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./CourseRcmdGeneral.module.scss";
import { abtestActions } from "../../_state/actions";
import { Channeltalk } from "../../../_components/Channeltalk";
import { sendLog } from "../../businesslogics/logging";
import { ReactWebpImage } from "../../../_components/_molecules/ReactWebpImage/ReactWebpImage";

const cx = classNames.bind(styles);

function CourseRcmdB({ user, sendHackleLog, enrolledFinishSummary, course }) {
  const [isDeveloperIntro, setIsDeveloperIntro] = useState(false);
  const isMissing = false;

  useEffect(() => {
    sendLog("scc_courseRcmdPage_view", {});
  }, []);

  useEffect(() => {
    if (enrolledFinishSummary.loaded) {
      setIsDeveloperIntro(enrolledFinishSummary.data.is_developer_intro);
    }
  }, [enrolledFinishSummary.loaded]);

  const [isScrolled, setIsScrolled] = useState(false);
  function logic() {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight &&
      !isScrolled
    ) {
      sendHackleLog({ action: "spc_courseRcmd_scroll_90", scroll_90: true });
      setIsScrolled((isScrolled) => !isScrolled);
    }
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logic);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logic);
    };
  });

  const classCards = [
    {
      course_id: "5f0ae408765dae0006002816",
      mainTitle: "웹개발 종합반",
      title1: "코딩 용어 다음 필수 코스!",
      title2: "비개발자가 꼭 알아야 할 세계",
      tag1: "코딩의 ㅋ부터",
      tag2: "서비스 런칭 까지",
      img: "nb_finish2",
      url: "web",
      desc: "웹개발의 A부터 Z까지! 누구나 쉽고 재밌게 코딩에 입문할 수 있는 수업입니다.",
    },
    isDeveloperIntro
      ? {
          course_id: "5f8315459328d9a2909acb52",
          mainTitle: "Spring 기초반",
          title1: "백엔드 개발자가 되기 위한 필수",
          title2: "대표 프레임워크 Spring 입문!",
          tag1: "백엔드 입문",
          tag2: "포트폴리오",
          img: "nb_finish5",
          url: "spring",
          desc: "백엔드 개발의 대표 프레임워크, Spring으로 웹서비스를 만들어보는 백엔드 개발 기초 수업입니다.",
        }
      : {
          course_id: "5f51cba7d76b11f9244d1111",
          mainTitle: "엑셀보다 쉬운 SQL",
          title1: "SQL, 몰랐을 땐 어떻게 일했지?",
          title2: "데이터 자동화로 일잘러 되기!",
          tag1: "마케터, PM",
          tag2: "남다른 IT 스펙",
          img: "nb_finish3",
          url: "sql",
          desc: "이력서에 'SQL 능숙'이라고 당당하게! 자유자재로 데이터를 뽑아서 쓸 수 있는 수업입니다.",
        },
    isDeveloperIntro
      ? {
          course_id: "5f83153e410f5ac76429585d",
          mainTitle: "리액트 기초반",
          title1: "프론트엔드 개발자 되기 위한 필수",
          title2: "요즘 대세 리액트를 배워보세요!",
          tag1: "프론트 입문",
          tag2: "포트폴리오",
          img: "nb_finish6",
          url: "react",
          desc: "요즘 대세인 리액트 기초 지식을 빠르게 배워 포트폴리오를 완성하는 프론트엔드 개발 기초 수업입니다.",
        }
      : {
          course_id: "5f0ae408765dae0006002817",
          mainTitle: "앱개발 종합반",
          title1: "자주 쓰는 어플, 어떻게 만든거지?",
          title2: "내가 만든 앱도 배포해보자!",
          tag1: "짧고 굵게",
          tag2: "부수입 창출",
          img: "nb_finish4",
          url: "app",
          desc: "앱개발은 이걸로 끝! iOS와 안드로이드 앱을 한번에 만들 수 있는 코딩 입문 수업입니다.",
        },
  ];

  return (
    <div className={cx("course_rcmd__wrapper")}>
      <div className={cx("course_rcmd__header")}>
        <h2 className={cx("course_rcmd__header__sub")}>
          {enrolledFinishSummary.data.goal} {user.name}님을 위한 추천
        </h2>
        <h1 className={cx("course_rcmd__header__main")}>
          같은 목표를 가진 분들이 이런 강의를 선택했어요!
        </h1>
        <p className={cx("course_rcmd__header__desc")}>
          <b>{user.name}님</b>의 끊임없는 성장을 위해
          <br />
          <strong>추천과목 전용 할인쿠폰</strong>을 발급해 드렸어요!
        </p>
        <div className={cx("course_rcmd__card__wrapper")}>
          {classCards.map((classCard, idx) => {
            return (
              <>
                <div className={cx("course_rcmd__card")}>
                  <p
                    className={cx("course_rcmd__card__rank")}
                    style={{ marginBottom: "0px" }}
                  >
                    {idx + 1}위
                  </p>
                  <div className={cx("course_rcmd__card__body")}>
                    <ReactWebpImage
                      src={`/assets/images/finish/${classCard.img}.webp`}
                      fallback={`/assets/images/finish/${classCard.img}.png`}
                      styles={{ width: "272px" }}
                    />

                    <p>{classCard.desc}</p>
                  </div>
                  <button
                    className={cx("course_rcmd__card__cta")}
                    onClick={() => {
                      sendHackleLog({
                        action: "spc_courseRcmdPage_click_button",
                        button_name: "수업 보러가기",
                        index: idx + 1,
                        course_id: classCard.course_id,
                        course_title: classCard.mainTitle,
                      });
                      sendLog(
                        "scc_courseRcmdPage_click_button",
                        {
                          button_text: "수업 보러가기",
                          course_title: course.title,
                          brand: "온라인유료",
                          next_href: `https://spartacodingclub.kr/online/${classCard.url}`,
                          next_page: "courseDetailPage",
                          target_Course_title: classCard.mainTitle,
                        },
                        "",
                        true
                      );
                      window.open(
                        `https://spartacodingclub.kr/online/${classCard.url}`
                      );
                    }}
                  >
                    수업 보러가기
                  </button>
                </div>
              </>
            );
          })}
        </div>
        <p className={cx("course_rcmd__notice")}>
          * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
        </p>
      </div>
      <div
        className={cx("course_rcmd__body")}
        style={{
          "--bg-image": `url(${"/assets/images/secretstore/bg_rcmd_general.png"}`,
        }}
      >
        <p className={cx("course_rcmd__bonus__label")}>보너스 쿠폰</p>
        <h2 className={cx("course_rcmd__bonus__header")}>
          듣고 싶은 수업이 없다면?
          <br /> 전과목 할인 쿠폰!
        </h2>
        <p className={cx("course_rcmd__bonus__sub")}>
          다른 수업을 선택하고 싶은 {user.name}님을 위한
          <br /> 전과목 할인쿠폰을 발급해드렸어요!
        </p>
        <div className={cx("course_rcmd__bonus__card")}>
          <img
            className={cx("course_rcmd__bonus__card__img")}
            alt={"ic_rtan_money"}
            src={"/assets/images/secretstore/ic_rtan_money.png"}
          />
          <div className={cx("course_rcmd__bonus__card__detail")}>
            <p className={cx("course_rcmd__bonus__card__detail__title")}>
              완주자 전용 전과목 할인쿠폰
            </p>
            <h3 className={cx("course_rcmd__bonus__card__detail__amount")}>
              30,000원
            </h3>
          </div>
        </div>
        <button
          className={cx("course_rcmd__bonus__cta")}
          onClick={() => {
            sendHackleLog({
              action: "spc_courseRcmdPage_click_button",
              button_name: "전체 수업 둘러보기",
            });
            window.open("https://spartacodingclub.kr/catalog/featured/summary");
          }}
        >
          전체 수업 둘러보기
        </button>
        <p className={cx("course_rcmd__bonus__notice")}>
          * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
        </p>
        <div className={cx("page__outro__title")}>
          <img
            className={cx("page__outro__title__img")}
            src={"/assets/emoji/emoji_question.png"}
            alt=""
          />
          <div className={cx("page__outro__title__main")}>
            그래도 어떤 강의를 들어야 할지
            <br /> 모르겠다면, 문의주세요!
          </div>
          <div className={cx("page__outro__title__sub")}>
            {user.name}님의 편이 되어 함께 고민해드릴게요.
          </div>
        </div>
        <button
          id="ch-button"
          className={cx("page__outro__cta")}
          onClick={() => {
            sendHackleLog({
              action: "spc_courseRcmdPage_click_button",
              button_name: "내게 꼭 맞는 강의 추천받기",
            });
          }}
        >
          문의 신청하기
          <Channeltalk />
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdB = connect(mapStateToProps, {
  sendHackleLog: abtestActions.sendHackleLog,
})(CourseRcmdB);
export { connectedCourseRcmdB as CourseRcmdB };
