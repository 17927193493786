import * as S from "./homeworkNotion.style.js";
import React, { useState } from "react";
import { useIsMobile } from "../../../../hooks/device";

const DownloadSvg = ({ isMobile }) => {
  if (isMobile) {
    return (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.39941 8.5L8.06608 11.1667L10.7327 8.5"
          stroke="#81898F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06592 11.1668V3.16679"
          stroke="#81898F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M13.3991 11.8332V12.9999C13.3991 13.4601 13.026 13.8332 12.5658 13.8332H3.56576C3.10552 13.8332 2.73242 13.4601 2.73242 12.9999V11.8332"
          stroke="#81898F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.89941 8L8.56608 10.6667L11.2327 8"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.56604 10.6668V2.66679"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.8993 11.3332V12.4999C13.8993 12.9601 13.5262 13.3332 13.066 13.3332H4.066C3.60576 13.3332 3.23267 12.9601 3.23267 12.4999V11.3332"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ChevronDownSvg = ({ isUp, isMobile = false }) => {
  if (isMobile) {
    if (isUp) {
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0659 9.3999L8.06592 5.3999L4.06592 9.3999"
            stroke="#146AFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    return (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.31592 6.66406L8.31592 10.6641L12.3159 6.66406"
          stroke="#146AFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  if (isUp) {
    return (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.566 11.6666L10.566 6.66663L5.56604 11.6666"
          stroke="#146AFF"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56592 8.33333L10.5659 13.3333L15.5659 8.33333"
        stroke="#146AFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export function removePTagsFromLI(htmlString) {
  return htmlString.replace(/<li><p>(.*?)<\/p>/g, "<li>$1");
}

function convertToStrongCode(htmlString) {
  // 정규표현식을 사용하여 **~~** 형태를 <strong>~~</strong>로 변환
  return htmlString.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
}

const removeH2Tags = (htmlString) => {
  return htmlString.replace(/<h2>(.*?)<\/h2>/g, "<span>$1</span>");
};

export const convertHtmlStringPipeline = (htmlString) => {
  return convertToStrongCode(removeH2Tags(removePTagsFromLI(htmlString)));
};

const HomeworkDownloadButton = ({ downloadHomework }) => {
  const isMobile = useIsMobile();
  const ctaText = "첨부파일 다운로드";
  if (isMobile) {
    return (
      <S.DownloadButtonMobile onClick={() => downloadHomework()}>
        <DownloadSvg isMobile={isMobile} />
        <S.DownloadButtonTextMobile>{ctaText}</S.DownloadButtonTextMobile>
      </S.DownloadButtonMobile>
    );
  }
  return (
    <S.DownloadButton onClick={() => downloadHomework()}>
      <DownloadSvg isMobile={isMobile} />
      <S.ButtonText>{ctaText}</S.ButtonText>
    </S.DownloadButton>
  );
};

export const HomeworkNotion = ({
  homeworkDetail,
  handleDownloadHomeworkDetailFile,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useIsMobile();
  const parse = require("html-react-parser");
  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };
  if (homeworkDetail.htmlString) {
    const convertedString = convertHtmlStringPipeline(
      homeworkDetail.htmlString
    );
    const htmlString = parse(convertedString);
    return (
      <S.HomeworkMarkdownRoot>
        <S.HomeworkMarkdownHeader>
          {isMobile ? "< 지시사항 >" : "[지시사항]"}
        </S.HomeworkMarkdownHeader>
        <S.HomeworkMarkdownWrapper isExpanded={isExpanded}>
          <S.ButtonArea>
            {homeworkDetail.file_ext && (
              <HomeworkDownloadButton
                downloadHomework={handleDownloadHomeworkDetailFile}
              />
            )}
          </S.ButtonArea>

          {htmlString}
          <S.ShowMoreButton onClick={toggleContent}>
            <S.ShowMoreText>{isExpanded ? "접기" : "펼쳐보기"}</S.ShowMoreText>
            <ChevronDownSvg isUp={isExpanded} isMobile={isMobile} />
          </S.ShowMoreButton>
        </S.HomeworkMarkdownWrapper>
      </S.HomeworkMarkdownRoot>
    );
  }
  return <>숙제를 불러오는 중...!</>;
};
