import React, { useEffect, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./RoadmapBannerContent.module.scss";
import {
  getBannerData,
  getImgIncludeCtaData,
  getSonsMaterialLink,
} from "./RoadmapBnnerData";
import { sendLog } from "../../../businesslogics/logging";
import * as S from "./RoadmapBannerContent.style";

const cx = classNames.bind(styles);

export const Sparthon = ({ isMobile }) => {
  const bannerData = getBannerData("sparthon", isMobile);

  return (
    <div className={cx("sparthon_banner")}>
      <img className={cx("sparthon_banner_bg")} src={bannerData.bannerImg} />
      <div className={cx("sparthon_banner_contents")}>
        <div className={cx("sparthon_banner_title")}>
          <div className={cx("sparthon_banner_accent")}>
            제3회 스파르톤 추가 모집 중
            <img src={"/assets/emoji/emoji_fire.png"} />
          </div>
          마감까지 얼마 남지 않았어요!
        </div>
        <a
          className={cx("sparthon_banner_cta")}
          href={bannerData.ctaLink}
          target={"_blank"}
        >
          자세히 보기
        </a>
      </div>
    </div>
  );
};

export const BeforeSons = ({ course_id, enrolled }) => {
  const bannerData = getBannerData("sons");
  const sonsMaterialLink = getSonsMaterialLink(course_id);

  return (
    <div
      className={cx("custom-banner-content")}
      style={{ backgroundColor: "#ff8fa0" }}
    >
      <div className={cx("custom-banner-content__title")}>
        <S.Title>스파르타 온라인 스터디! 딱 3시간만 공부하고 갑시다!</S.Title>
        <br />
        <S.Caption>기간 내 완주 가능성 100%</S.Caption>
      </div>
      <div style={{ display: "flex" }}>
        <img
          src={bannerData.bannerImg}
          style={{ height: "45.7px", marginRight: "16px" }}
        />
        <div
          style={{ marginRight: "8px", whiteSpace: "nowrap" }}
          className={cx("custom-banner-content__cta")}
          onClick={() => {
            sendLog(
              "scc_roadmapPage_click_button",
              {
                ...enrolled,
                button_text: "완주 꿀팁",
                next_page: sonsMaterialLink,
                details: "",
              },
              ""
            );
            window.open(sonsMaterialLink, "_blank");
          }}
        >
          완주 꿀팁 🍯
        </div>
        <div
          style={{ whiteSpace: "nowrap" }}
          className={cx("custom-banner-content__cta")}
          onClick={() => {
            sendLog(
              "scc_roadmapPage_click_button",
              {
                ...enrolled,
                button_text: "스터디 참여하기",
                next_page: bannerData.ctaLink,
                details: "",
              },
              ""
            );
            window.open(`${bannerData.ctaLink}`, "_blank");
          }}
        >
          스터디 참여하기
        </div>
      </div>
    </div>
  );
};

export const Sons = ({ course_id, enrolled }) => {
  const bannerData = getBannerData("sons");
  const sonsMaterialLink = getSonsMaterialLink(course_id);
  const studyLink =
    "https://spartacodingclub.kr/community/freeboard/certification";

  return (
    <div
      className={cx("custom-banner-content")}
      style={{ backgroundColor: "#ff8fa0" }}
    >
      <div className={cx("custom-banner-content__title")}>
        <S.Title>주차별 미션에 참여하고, 진도율 100%까지 달려가봅시다!</S.Title>
        <br />
        <S.Caption>기간 내 완주 가능성 100%</S.Caption>
      </div>
      <div style={{ display: "flex" }}>
        <img
          src={bannerData.bannerImg}
          style={{ height: "45.7px", marginRight: "16px" }}
        />
        <div
          style={{ marginRight: "8px", whiteSpace: "nowrap" }}
          className={cx("custom-banner-content__cta")}
          onClick={() => {
            sendLog(
              "scc_roadmapPage_click_button",
              {
                ...enrolled,
                button_text: "미션 참여하기",
                next_page: studyLink,
                details: "",
              },
              ""
            );
            window.open(studyLink, "_blank");
          }}
        >
          미션 참여하기
        </div>
        <div
          style={{ whiteSpace: "nowrap" }}
          className={cx("custom-banner-content__cta")}
          onClick={() => {
            sendLog(
              "scc_roadmapPage_click_button",
              {
                ...enrolled,
                button_text: "완주 꿀팁",
                next_page: sonsMaterialLink,
                details: "",
              },
              ""
            );
            window.open(sonsMaterialLink, "_blank");
          }}
        >
          완주 꿀팁 🍯
        </div>
      </div>
    </div>
  );
};

export const Pirates = () => {
  return (
    <div
      className={cx("custom-banner-content")}
      style={{ backgroundColor: "#F2F6F8" }}
    >
      <S.FlexSpaceBetween>
        <S.FlexSpaceGap gap={16}>
          <img
            src={"/assets/icons/roadmap/ic_banner_pirates.png"}
            style={{ height: "37px" }}
          />
          <S.OneLineTitle>
            <span>해적단 채팅방 </span> 입장하고 다양한 이벤트와 모임에
            참여하세요!
          </S.OneLineTitle>
        </S.FlexSpaceGap>
        <S.FlexSpaceGap gap={16}>
          <S.SquareCta
            isAccent={true}
            onClick={() => {
              sendLog(
                "scc_roadmapPage_click_button",
                {
                  button_text: "채팅방 입장",
                  next_page: "https://open.kakao.com/o/g2osj85e",
                  details: "해적단 배너 오픈카톡 채팅방 입장 버튼",
                },
                ""
              );
              window.open("https://open.kakao.com/o/g2osj85e", "_blank");
            }}
          >
            채팅방 입장
          </S.SquareCta>
          <S.SquareCta
            onClick={() => {
              sendLog(
                "scc_roadmapPage_click_button",
                {
                  button_text: "FAQ",
                  next_page:
                    "https://teamsparta.notion.site/b0637d0f3f3848d1a9c1624b080cd962",
                  details: "해적단 배너 FAQ 버튼",
                },
                ""
              );
              window.open(
                "https://teamsparta.notion.site/b0637d0f3f3848d1a9c1624b080cd962",
                "_blank"
              );
            }}
          >
            FAQ
          </S.SquareCta>
        </S.FlexSpaceGap>
      </S.FlexSpaceBetween>
    </div>
  );
};

export const BeforeCredit = ({ course_id, enrolled }) => {
  const bannerData = getBannerData("credit");
  const sonsMaterialLink = getSonsMaterialLink(course_id);

  return (
    <>
      <div
        className={cx("custom-banner-content")}
        style={{ backgroundColor: "#ffce5b" }}
      >
        <div className={cx("custom-banner-content__title")}>
          <S.Title>스파르타 온라인 스터디! 딱 3시간만 공부하고 갑시다!</S.Title>
          <br />
          <S.Caption>환급 받을 가능성 100%</S.Caption>
        </div>
        <div style={{ display: "flex" }}>
          <img
            src={bannerData.bannerImg}
            style={{ height: "45.7px", marginRight: "16px" }}
          />
          <div
            style={{ marginRight: "8px", whiteSpace: "nowrap" }}
            className={cx("custom-banner-content__cta")}
            onClick={() => {
              sendLog(
                "scc_roadmapPage_click_button",
                {
                  ...enrolled,
                  button_text: "환급 꿀팁",
                  next_page: sonsMaterialLink,
                  details: "",
                },
                ""
              );
              window.open(sonsMaterialLink, "_blank");
            }}
          >
            환급 꿀팁 🍯
          </div>
          <div
            style={{ whiteSpace: "nowrap" }}
            className={cx("custom-banner-content__cta")}
            onClick={() => {
              sendLog(
                "scc_roadmapPage_click_button",
                {
                  ...enrolled,
                  button_text: "스터디 참여하기",
                  next_page: bannerData.ctaLink,
                  details: "",
                },
                ""
              );
              window.open(`${bannerData.ctaLink}`, "_blank");
            }}
          >
            스터디 참여하기
          </div>
        </div>
      </div>
    </>
  );
};

export const Credit = ({ course_id, enrolled }) => {
  const bannerData = getBannerData("credit");
  const sonsMaterialLink = getSonsMaterialLink(course_id);
  const studyLink =
    "https://spartacodingclub.kr/community/freeboard/certification";

  return (
    <>
      <div
        className={cx("custom-banner-content")}
        style={{ backgroundColor: "#ffce5b" }}
      >
        <div className={cx("custom-banner-content__title")}>
          <S.Title>
            주차별 미션에 참여하고, 진도율 100%까지 달려가봅시다!
          </S.Title>
          <br />
          <S.Caption>환급 받을 가능성 100%</S.Caption>
        </div>
        <div style={{ display: "flex" }}>
          <img
            src={bannerData.bannerImg}
            style={{ height: "45.7px", marginRight: "16px" }}
          />
          <div
            style={{ marginRight: "8px", whiteSpace: "nowrap" }}
            className={cx("custom-banner-content__cta")}
            onClick={() => {
              sendLog(
                "scc_roadmapPage_click_button",
                {
                  ...enrolled,
                  button_text: "미션 참여하기",
                  next_page: studyLink,
                  details: "",
                },
                ""
              );
              window.open(studyLink, "_blank");
            }}
          >
            미션 참여하기
          </div>
          <div
            style={{ whiteSpace: "nowrap" }}
            className={cx("custom-banner-content__cta")}
            onClick={() => {
              sendLog(
                "scc_roadmapPage_click_button",
                {
                  ...enrolled,
                  button_text: "환급 꿀팁",
                  next_page: sonsMaterialLink,
                  details: "",
                },
                ""
              );
              window.open(sonsMaterialLink, "_blank");
            }}
          >
            환급 꿀팁 🍯
          </div>
        </div>
      </div>
    </>
  );
};

export const Timer = ({ type, enrolled }) => {
  const courseTitleRef = useRef();
  const timerRef = useRef();

  useEffect(() => {
    if (!enrolled.loaded || !timerRef.current) {
      return;
    }

    timerRef.current.classList.remove(cx("--hidden"));

    setCourseTitle();
    setInterval(setTimer, 1000);
  }, [enrolled.loaded]);

  function setCourseTitle() {
    const course_title = enrolled.data?.courses.title;

    if (courseTitleRef.current) {
      courseTitleRef.current.innerHTML = `${course_title}`;
    }
  }

  function setTimer() {
    const now = new Date();
    const dday = isEventTimeSafe
      ? getEventDday()
      : new Date(enrolled.data?.end_date?.replace(/\s/, "T"));

    const days = isEventTimeSafe ? 0 : (dday - now) / 1000 / 60 / 60 / 24;

    const daysRound = Math.floor(days).toString();

    const hours = (dday - now) / 1000 / 60 / 60 - 24 * daysRound;
    const hoursRound = Math.floor(hours).toString();

    const minutes =
      (dday - now) / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    const minutesRound = Math.floor(minutes).toString();
    const seconds =
      (dday - now) / 1000 -
      24 * 60 * 60 * daysRound -
      60 * 60 * hoursRound -
      60 * minutesRound;
    const secondsRound = Math.round(seconds).toString();

    if (timerRef.current) {
      timerRef.current.innerHTML = `${
        daysRound !== "0" ? `<span>${daysRound.padStart(2, "0")}</span>일 ` : ``
      }<span>${hoursRound.padStart(
        2,
        "0"
      )}</span>시간 <span>${minutesRound.padStart(
        2,
        "0"
      )}</span>분 <span>${secondsRound.padStart(2, "0")}</span>초`;
    }
  }

  function getEventDday() {
    const enroll_created_at = new Date(
      enrolled.data?.created_at?.replace(/\s/, "T")
    );

    return enroll_created_at.setDate(enroll_created_at.getDate() + 2);
  }

  function getIsEventTimeSafe() {
    const now = new Date();
    const dday = getEventDday();

    return now < dday;
  }

  const isEventTimeSafe = getIsEventTimeSafe();

  const bannerData = getBannerData("timer");

  return (
    <div className={cx("custom-banner-content", "timer")}>
      <img
        src={`${bannerData.bannerImg}${isEventTimeSafe ? "" : "_timeover"}.png`}
      />
      <div
        className={cx(
          "custom-banner-content__title",
          "timer",
          `${isEventTimeSafe ? "" : "--timeover"}`
        )}
        ref={courseTitleRef}
      />
      <div
        className={cx(
          "custom-banner-content__timer",
          "timer",
          `${isEventTimeSafe ? "" : "--timeover"}`
        )}
        ref={timerRef}
      />
    </div>
  );
};

export const ImgIncludeCta = ({ isGov, isGovIntro, isTrial, isMobile }) => {
  const bannerData = getImgIncludeCtaData(isGov, isGovIntro, isTrial, isMobile);

  return isGovIntro ? (
    <S.GovIntroBanner>
      <img
        className={cx("banner__img")}
        src={bannerData.bannerImg}
        alt="banner img"
        style={{ width: "100%", height: "auto" }}
      />
      {!isMobile && (
        <S.CtaPosition href={bannerData.ctaLink} target={"_blank"} />
      )}
    </S.GovIntroBanner>
  ) : (
    <div>
      <img
        className={cx("banner__img")}
        src={bannerData.bannerImg}
        alt="banner img"
        style={{ width: "100%", height: "auto" }}
      />
      {!isMobile && (
        <S.CtaPosition href={bannerData.ctaLink} target={"_blank"} />
      )}
    </div>
  );
};
