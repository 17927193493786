import { KDCKDT } from "./KDCKDT/KDCKDT";
import { useLogFinishPageView, useFinish } from "../../hooks/finish";
import { useParams } from "react-router-dom";

const KDTRecommendFinishPage = () => {
  const { enrolled_id } = useParams();
  useFinish(enrolled_id);
  useLogFinishPageView(enrolled_id);

  return <KDCKDT />;
};

export default KDTRecommendFinishPage;
