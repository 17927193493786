import {
  gfsDeadlineConstants,
  gfsIsCardReceivedConstants,
  govCompletionMilestoneConstants,
} from "../_constants/gov.constants";

const initState = {
  loaded: false,
};

export function govCompletionMilestone(state = initState, action) {
  return _reducer(state, action, govCompletionMilestoneConstants);
}

export function gfsDeadline(state = initState, action) {
  return _reducer(state, action, gfsDeadlineConstants);
}

export function gfsIsCardReceived(state = initState, action) {
  return _reducer(state, action, gfsIsCardReceivedConstants);
}

function _reducer(state, action, constants) {
  switch (action.type) {
    case constants.REQUEST:
      return { ...state, loaded: false };
    case constants.SUCCESS:
      return { ...state, loaded: true, data: action.response };
    case constants.FAILURE:
      return { ...state, loaded: false };
    default:
      return state;
  }
}
