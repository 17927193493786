import styled from "styled-components";
import { Device } from "../../../../_styles/device";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  text-align: center;
`;

export const ContentTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0px;
  color: #141617;

  ${Device.Desktop} {
    font-size: 20px;
  }
`;

export const ContentDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContentDetailItem = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0px;
  text-align: left;
  color: #3A3E41;
  
  span {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #E8344E;
  }
  
  ${Device.Desktop} { 
    font-size: 16px;
    span {
      font-size: 16px !important;
    }
  }
`