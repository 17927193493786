import styled from "@emotion/styled";
import {
  mBody1,
  mCaption2,
  neutralDay,
  wBody1,
  wCaption1,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const HomeworkHelpButton = styled.a`
  min-width: 293px;
  max-width: 378px;
  width: 100%;
  display: ${(props) => (props.href ? "flex" : "none")};
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 62px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  gap: 12px;
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    max-width: 100%;
    width: 100%;
  }
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 46px;
  height: 46px;
  padding: 11px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: ${neutralDay.gray5};
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 8px;
    width: 40px;
    height: 40px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.div`
  ${wBody1};
  color: ${neutralDay.gray90};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${neutralDay.gray90};
  }
`;

export const SubTitle = styled.div`
  ${wCaption1};
  color: ${neutralDay.gray70};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mCaption2};
    color: ${neutralDay.gray70};
  }
`;

export const ConnectionIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
