import * as S from "./TapMenuBarMobile.style";
import { mobileAnchors } from "./TapMenuAnchor.medels";
import { useOnlineTheme } from "../../../../queries/pirates";

export const TapMenuBarMobile = ({
  menuHandler,
  currentMenu,
  isFastQnaAvailable,
}) => {
  const theme = useOnlineTheme();
  return (
    <S.TapMenuBarContainer theme={theme.data}>
      {mobileAnchors.map((anchor, idx) => {
        if (anchor.key === "fastQna" && !isFastQnaAvailable) {
          return;
        }
        return (
          <S.TapMenuAnchor
            theme={theme.data}
            isActive={currentMenu === anchor.key}
            onClick={() => menuHandler(anchor)}
          >
            {anchor.name}
            {anchor.type === "outlink" && (
              <S.OutlinkLogo
                theme={theme.data}
                isActive={currentMenu === anchor.key}
              />
            )}
          </S.TapMenuAnchor>
        );
      })}
    </S.TapMenuBarContainer>
  );
};
