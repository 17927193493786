export const recommendReboot = {
  imgSrc: "/assets/thumbnails/hanghae_reboot.webp",
  pageTitle: "항해 취업 리부트 코스 랜딩 페이지",
  pageUrl: "https://hanghae99.spartacodingclub.kr/reboot",
  recommendTitle: "개발자 취업 리부트 코스",
  productDescription: "1년 내 취업 성공 보장!",
  productTitle: "항해99 취업 리부트 코스",
  contentList: [
    {
      category: "모집 대상",
      value: "개발 공부 경힘이 있는 취업 준비생",
    },
    {
      category: "교육비",
      value: "취업 성공 전까지 0원",
    },
  ],
  nextHref: "https://hanghae99.spartacodingclub.kr/reboot",
};

export const recommendSkillUp = {
  imgSrc: "/assets/thumbnails/hanghae_plus.png",
  pageTitle: "항해 플러스 허브페이지",
  pageUrl: "https://hhplus-hub.oopy.io/",
  recommendTitle: "개발자 스킬업 코스",
  productDescription: "현직 웹개발자 10주 몰입 과정",
  productTitle: "항해 플러스",
  contentList: [
    {
      category: "모집 대상",
      value: "경력 3년 이하 주니어 웹개발자",
    },
    {
      category: "교육 방식",
      value: "매주 토요일 온/오프라인 모임",
    },
  ],
  nextHref: "https://bit.ly/4cGuKzw",
};

export const recommendCourses = {
  REBOOT: recommendReboot,
  SKILL_UP: recommendSkillUp,
};
