import React, { useState, useEffect } from 'react'

export const useRefForDimWidth = (ref) => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    if (ref.current) {
      const { current } = ref
      const divWidth = current.offsetWidth
      setWidth(Math.round(divWidth))
    }
  }, [ref])
  return width
}