import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 16px 0;

  width: 100vw;
  ${Device.Desktop} {
    position: relative;
    flex-direction: row;
    justify-content: center;

    width: 100%;
  }
`;

export const DashboardMobileWrapper = styled.div`
  display: block;
  ${Device.Desktop} {
    display: none;
  }
`;

export const HeroHeadContainer = styled.div`
  ${Device.Desktop} {
    display: flex;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 12px;

  width: 100%;
  height: 102px;

  text-align: center;

  margin-bottom: 40px;

  ${Device.Desktop} {
    text-align: initial;
    align-items: flex-start;
  }
`;

export const SubTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #e8344e;
  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const Title = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;

  color: #141617;
  ${Device.Desktop} {
    font-size: 32px;
    width: 357px;
  }
`;
export const HeroImage = styled.img`
  width: 260px;

  ${Device.Desktop} {
    width: 357px;
  }
`;
