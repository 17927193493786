import { ENROLLED_SUMMARY } from "../constants";

const initState = {
  loaded: false,
};

export function enrolledSummary(state = initState, action) {
  switch (action.type) {
    case ENROLLED_SUMMARY.ENROLLED_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_SUMMARY.ENROLLED_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_SUMMARY.ENROLLED_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledFinishSummary(state = initState, action) {
  switch (action.type) {
    case ENROLLED_SUMMARY.ENROLLED_FINISH_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ENROLLED_SUMMARY.ENROLLED_FINISH_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ENROLLED_SUMMARY.ENROLLED_FINISH_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
