import { SPALAND_DATE } from "../constants";

const initState = {
  loaded: false,
};

export function spalandDates(state = initState, action) {
  switch (action.type) {
    case SPALAND_DATE.SPALAND_DATE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case SPALAND_DATE.SPALAND_DATE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case SPALAND_DATE.SPALAND_DATE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
