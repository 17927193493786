import privateInstance from "../../_helper/axios";

async function getRoundUrg(round_id) {
  return await privateInstance
    .get(`/race_group/rounds/${round_id}`)
    .then((resp) => resp.data);
}

async function createRoundUrg(round_id) {
  return await privateInstance
    .post(`/race_group/rounds/${round_id}`)
    .then((resp) => resp.data);
}

async function getUserRankGroupInfo(enrolled_id) {
  return await privateInstance
    .get(`/race_group/enrolled/${enrolled_id}`)
    .then((resp) => resp.data);
}

async function getWeeksAfterStart(enrolled_id) {
  return await privateInstance
    .get(`/race_group/enrolled/${enrolled_id}/weeks`)
    .then((resp) => resp.data);
}

export const UserRaceGroupService = {
  getRoundUrg,
  createRoundUrg,
  getUserRankGroupInfo,
  getWeeksAfterStart,
};
