import React, {Component, Fragment} from "react";
import styles from "./Lecture.module.scss";
import classNames from "classnames/bind";
import {history} from "../../_helpers";
import {connect} from "react-redux";
import {Button, Input, message, Rate, Skeleton, Tooltip} from "antd";
import {codeSnippetActions, homeworkActions, lectureActions, pointActions,} from "../../_actions";
import {getHomeworkUrl, getPostAuthOptions, SLACK_URL} from "../../_urls";
import {CustomModal} from "../CustomModal";
import {getApiUserPointChanceAll, getApiUserPointChanceOnce,} from "../../_urls/accounts.api";
import {getLectureUrl} from "../../_urls/lectures";
import {getApiFreeCourseCTALogUrl} from "../../_urls/logs.api";
import instance from "../../_helpers/axios";
import {sendUserAction, URL_LOG, USER_ACTIONS, USER_PAGES} from "../../v2/businesslogics/logging";
// import Player from '@vimeo/player';

const cx = classNames.bind(styles);

class Lecture extends Component {
  state = {
    isQnaTime: false,
    tooltipShow: false,
    modalVisible: false,
    boxOpened: false,
    pointDelta: 0,

    // 어렵지수, 꿀잼지수 관련 states
    modalRatingVisible: false,
    value: 5.0,
    difficulty: 0.0,
    fun: 0.0,
    cheerup: "",
    isDifficultyChecked: false,
    isFunChecked: false,

    // 강의 관련 정보
    iframeImported: false,
    go_next: false,
  };

  constructor(props) {
    super(props);
    // this._getLectureInfo();
  }

  componentDidMount() {
    const { round_id, lecture_id } = this.props.data;
    this._isSlackOn();
    this.props.getPointInfo();
    this.props.getLecture(round_id, lecture_id);

    this.setState({
      go_next: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data.lecture_id !== this.props.data.lecture_id) {
      if (this.props.nav_selected) {
        this.props.handleNavStatus(false);
      } else {
        const { round_id, lecture_id } = this.props.data;
        this.props.getLecture(round_id, lecture_id);
        this.setState({
          go_next: false,
        });
      }
    }
  }

  _isSlackOn = () => {
    let now = new Date();
    let day = now.getDay();
    let hour = now.getHours();

    let isQnaTime = false;
    if (
      (day === 2 && hour >= 19 && hour < 22) ||
      (day === 6 && hour >= 14 && hour < 17)
    ) {
      isQnaTime = true;
    }

    this.setState({
      isQnaTime,
    });
  };

  _getLectureInfo = () => {
    const { round_id, lecture_id } = this.props.data;
    instance.get(`/rounds/${round_id}/lectures/${lecture_id}`).then((resp) => {
      this.setState({
        lecture: resp.data,
      });
    });
  };

  _getLectureComponent = (lecture) => {
    if (lecture === undefined || lecture === {}) {
      return <div />;
    }
    return (
      <Fragment>
        <div className={cx("lectureWrapper")}>
          <div className={cx("h_iframe")}>
            <iframe
              src={`https://player.vimeo.com/video/${lecture["link"]}`}
              frameBorder="0"
              onLoad={() => {
                this.setVimeoLog();
              }}
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </div>
      </Fragment>
    );
  };

  setVimeoLog = () => {
    let iframe = document.querySelector("iframe");
    let player = new window.Vimeo.Player(iframe);

    const sendVideoLog = this.sendVideoLog;

    player.on("play", function (data) {
      sendVideoLog(data, "play");
    });

    player.on("pause", function (data) {
      sendVideoLog(data, "pause");
    });

    player.on("ended", function (data) {
      sendVideoLog(data, "ended");
    });

    player.on("seeked", function (data) {
      sendVideoLog(data, "seeked");
    });
  };

  sendVideoLog = async (data, status) => {
    if (status === "play" && !data) {
      return;
    }
    const { user } = this.props;
    const {
      course_title,
      course_id,
      round_id,
      week,
      week_order,
      _id: lecture_id,
      title: lecture_title,
    } = this.props.lecture.data;
    const { enrolled_id } = this.props.lectureNav.data.lectures[1][0];
    const reqData = Object.assign(
      {
        course_title,
        course_id,
        round_id,
        week,
        week_order,
        lecture_id,
        lecture_title,
        enrolled_id,
        status,
        coll: "logs_video",
        user_id: user._id,
      },
      data
    );
    await instance.post(URL_LOG, reqData);
  };

  _handleDone = () => {
    this.setState({
      modalVisible: true,
    });
  };

  _handleMouseOver = () => {
    this.setState({
      tooltipShow: true,
    });
  };

  _handleMouseLeave = () => {
    this.setState({
      tooltipShow: false,
    });
  };

  handleModalVisible = (isVisible) => {
    this.setState({
      modalVisible: isVisible,
    });
  };

  _getImagePathTreauserBox = (isOpen) => {
    if (isOpen) {
      return "/assets/images/lecture/box_open_effects.gif";
    } else {
      return "/assets/images/lecture/treasure_box_unopened.png";
    }
  };

  _handleOpen = () => {
    let endpoint = getApiUserPointChanceOnce();
    let options = getPostAuthOptions({});

    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          let pointDelta =
            parseInt(result["point_after"]) - parseInt(result["point_before"]);
          this._openEffect(pointDelta);
        } else {
          alert(result.message);
        }
      });
  };

  _handleOpenAll = () => {
    let endpoint = getApiUserPointChanceAll();
    let options = getPostAuthOptions({});

    this.setState({
      boxOpened: true,
    });
    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          let pointDelta =
            parseInt(result["point_after"]) - parseInt(result["point_before"]);
          this._openAllEffect(pointDelta);
        } else {
          alert(result.message);
        }
      });
  };

  _openEffect = (pointDelta) => {
    this.setState({
      boxOpened: true,
      pointDelta: pointDelta,
    });
    setTimeout(() => {
      this.setState({
        boxOpened: false,
        pointDelta: 0,
      });
      this.props.getPointInfo();
    }, 1000);
  };

  _openAllEffect = (pointDelta) => {
    this.setState({
      boxOpened: true,
      pointDelta: pointDelta,
    });
    setTimeout(() => {
      this.setState({
        boxOpened: false,
        pointDelta: 0,
      });
      this.props.getPointInfo();
    }, 1500);
  };

  _endStudy = () => {
    const { enrolled_id } = this.props.lectureNav.data.lectures[1][0];
    const { round_id } = this.props.data;
    const { week, week_order } = this.props.lecture.data;
    history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
  };

  _continueStudy = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleModalRatingVisible = (isVisible) => {
    this.setState({
      modalRatingVisible: isVisible,
    });
  };

  handleCheerupChange = (e) => {
    this.setState({ cheerup: e.target.value });
  };

  handleDifficultyChange = (difficulty) => {
    this.setState({
      difficulty: difficulty,
      isDifficultyChecked: true,
    });
  };

  handleFunChange = (fun) => {
    this.setState({
      fun: fun,
      isFunChecked: true,
    });
  };

  _refreshComponents = (round_id, lecture_id) => {
    this.props.getLectureNavInfo(round_id);
    this.props.getPointInfo();
  };

  handleOk = (written) => {
    const { round_id, lecture_id } = this.props.data;
    const { isFunChecked } = this.state;
    if (written && isFunChecked === false) {
      alert("별점을 매겨주세요.");
      return;
    }

    let data = written
      ? {
          refresh_token: this.props.user.refresh,
          fun: this.state.fun,
          cheerup: this.state.cheerup,
        }
      : {};

    instance
      .post(`/rounds/${round_id}/lectures/${lecture_id}/ratings`, data)
      .then((resp) => {
        const { next_id } = this.props.lecture.data;
        let chosenUrl;
        if (next_id.includes("homework")) {
          const homework_id = next_id.split("/")[1];
          chosenUrl = getHomeworkUrl(round_id, homework_id);
          this.props.getHomeworkItem(round_id, homework_id);
        } else {
          chosenUrl = getLectureUrl(round_id, next_id);
          this._refreshComponents(round_id, next_id);
        }

        this._showSuccessMessage();

        history.push(chosenUrl);
        this.setState({
          modalRatingVisible: false,
          value: 5.0,
          difficulty: 0.0,
          fun: 0.0,
          cheerup: "",
          isDifficultyChecked: false,
          isFunChecked: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.message !== "skip") {
          alert("너무 빨라요!\n잠시 후 다시 시도해주세요.");
        }
      });
  };

  _goNextLecture = () => {
    if (!this.props.lecture.data) {
      return;
    }
    // 다음 강의로 넘어갈 때 logs_video 쌓기
    let iframe = document.querySelector("iframe");
    let player = new window.Vimeo.Player(iframe);

    const sendVideoLog = this.sendVideoLog;
    player.getCurrentTime().then((seconds) => {
      sendVideoLog({ seconds }, "next");
    });
    this.props.handleNavStatus(false);
    let next_id = this.props.lecture.data.next_id;
    if (!next_id) {
      // alert("마지막 강의에요!\n이제 본 강의를 들으러 가볼까요?");
      history.push(
        `/finish?enrolled_id=${this.props.lecture.data.enrolled_id}`
      );
      return;
    }
    this._showModal();
  };

  _showModal = () => {
    const { round_id } = this.props.data;
    const { rating_written } = this.props.lecture.data;

    sendUserAction(USER_ACTIONS.GO_LECTURE, USER_PAGES.PAGE_LECTURE);

    if (rating_written) {
      // 바로 다음 강의로 넘어간다.
      const { next_id } = this.props.lecture.data;
      this.setState({
        modalRatingVisible: false,
        cheerup: "",
        go_next: true,
      });
      if (next_id.includes("homework")) {
        const homework_id = next_id.split("/")[1];
        let chosenHomeworkUrl = getHomeworkUrl(round_id, homework_id);
        this.props.getHomeworkItem(round_id, homework_id);
        history.push(chosenHomeworkUrl);
      } else {
        this._refreshComponents(round_id, next_id);
        this.props.getLecture(round_id, next_id);
        let chosenLectureUrl = getLectureUrl(round_id, next_id);
        history.push(chosenLectureUrl);
      }
    } else {
      this.setState({
        modalRatingVisible: true,
        value: 0.0,
        difficulty: 0.0,
        fun: 0.0,
        go_next: true,
      });
    }
  };

  _showSuccessMessage = () => {
    const { points } = this.state;
    if (points === undefined || points.review_done === undefined) {
      return;
    }
    message.success({
      content: `+1 보물상자, +${points.review_done} 포인트 적립 완료!`,
      className: "custom-class",
      style: {
        position: "absolute",
        top: "7px",
        right: "40px",
      },
    });
  };

  goSlack = () => {
    const { lecture } = this.props;
    if (lecture.data.context.is_seasonal) {
      window.open(
        "https://spartacodingpackage.slack.com/archives/C01GYDM7R6V",
        "_blank"
      );
    } else {
      window.open(SLACK_URL, "_blank");
    }
  };

  _web = () => {
    const { course_id } = this.props.data;
    let data = {
      cid: course_id,
      cta: "to_web",
      from: "lecture",
    };
    const endpoint = getApiFreeCourseCTALogUrl();
    const options = getPostAuthOptions(data);
    fetch(endpoint, options);
    window.open("https://spartacodingclub.kr/online/web");
  };

  render() {
    const { tooltipShow, modalVisible, boxOpened } = this.state;
    // 어렵지수, 꿀잼지수 관련
    const { modalRatingVisible, fun, cheerup } = this.state;

    const { user } = this.props;
    let lecture = this.props.lecture.data;
    if (lecture === undefined || !this.props.lecture.loaded) {
      return (
        <Skeleton.Input style={{ width: 850, height: 551 }} active={true} />
      );
    }
    let lectureComp = this._getLectureComponent(lecture);
    if (
      this.props.pointInfo.data === undefined ||
      this.props.lectureNav.data === undefined
    ) {
      return (
        <Skeleton.Input style={{ width: 850, height: 551 }} active={true} />
      );
    }
    const { pointInfo } = this.props;
    const { points } = this.props.lectureNav.data;
    const isEmpty = pointInfo.data.chance === 0;
    const customHeight = isEmpty ? 580 : 580;

    const {
      next_id,
      context: { is_free, is_forever },
    } = this.props.lecture.data;
    let next_btn_txt;
    if (is_forever) {
      next_btn_txt = "강의 끝내기";
    } else {
      if (next_id) {
        next_btn_txt = next_id.includes("homework")
          ? "숙제하러 가기"
          : "다음 강의로";
      } else {
        next_btn_txt = "수료증 받기";
      }
    }

    const height = window.innerWidth >= 1280 ? 520 : 460;
    const treasureHeight = window.innerWidth >= 1280 ? 680 : 460;
    const width = window.innerWidth >= 1280 ? 550 : "96%";
    return (
      <div>
        {lectureComp}
        <script src="https://player.vimeo.com/api/player.js" />
        <div className={cx("buttonContainer")}>
          <div className={cx("leftButtonContainer")}>
            <Button className={cx("lectureButton")} onClick={this._handleDone}>
              오늘 공부 끝
            </Button>

            <Tooltip
              overlayStyle={{
                position: "relative",
                fontSize: 12,
                width: 171,
                textAlign: "center",
              }}
              // placement={"bottom"}
              title={
                <span>
                  [오늘 공부 끝] 버튼을 눌러
                  <br />
                  보물상자🎁를 열어보세요!
                </span>
              }
              visible={tooltipShow}
            >
              <div
                className={cx("treasureboxWrapper")}
                onMouseOver={this._handleMouseOver}
                onMouseLeave={this._handleMouseLeave}
              >
                <img src={"/assets/icons/treasurebox_bg.png"} alt="" />
                <div className={cx("numberindicator")}>
                  {pointInfo.data.chance}
                </div>
              </div>
            </Tooltip>
          </div>
          <div className={cx("buttonGroupTop")}>
            <Button
              className={cx("qnaButton")}
              onClick={this._goNextLecture}
              style={{ float: "right" }}
            >
              {next_btn_txt}
            </Button>
            <Button
              className={cx("communityButton")}
              onClick={this.goSlack}
              style={{ float: "right" }}
            >
              즉문즉답
            </Button>
            <div
              className={cx("materialContainer")}
              onClick={() => {
                window.open(this.props.lecture.data.url);
              }}
            >
              강의자료 &nbsp;
              <img
                className={cx("outlinkImage")}
                src={"/assets/icons/outlink.png"}
                alt=""
              />
            </div>
          </div>

          {/*<Tooltip*/}
          {/*    overlayStyle={{position: 'relative', fontSize: 14, width: 330}}*/}
          {/*    // placement={"bottom"}*/}
          {/*    title={`지금은 즉문즉답 시간이 아닙니다. 화요일 오후 7시 - 10시, 토요일 오후 2시 - 5시에 마음껏 질문하세요!`}*/}
          {/*    visible={tooltipShow && !isQnaTime}>*/}
          {/*    <Button className={isQnaTime ? cx('qnaButton') : cx('qnaButton-inactive')}*/}
          {/*            onMouseOver={this._handleMouseOver}*/}
          {/*            onMouseLeave={this._handleMouseLeave}*/}
          {/*            style={{float: "right",}}>*/}
          {/*        <a href={SLACK_URL} target="_blank">즉문즉답</a>*/}
          {/*    </Button>*/}
          {/*</Tooltip>*/}
        </div>
        <CustomModal
          visible={modalVisible}
          close={false}
          handleModalVisible={this.handleModalVisible}
          style={{
            width: width,
            height: treasureHeight,
            backgroundColor: "#F9FBFA",
          }}
          body={
            <div className={cx("modalBodyContainer")}>
              {/* title */}
              <div className={cx("titleContainer")}>
                {!isEmpty && (
                  <Fragment>
                    <h4>{user.name}님의 보물상자를 열어볼까요?</h4>
                    <p>
                      보물상자는 강의를 들을 때마다 획득할 수 있어요.
                      <br />
                    </p>
                  </Fragment>
                )}
                {isEmpty && (
                  <Fragment>
                    <h4>{user.name}님, 보물상자가 없어요!</h4>
                    <p>
                      보물상자는 강의를 들을 때마다 획득할 수 있어요.
                      <br />
                    </p>
                  </Fragment>
                )}
              </div>

              {/* info - 보물상자 수 */}
              <div className={cx("myinfoContainer")}>
                <ul>
                  <li>
                    <div className={cx("labelContainer")}>
                      <img src={"/assets/icons/treasurebox_small.png"} />
                      <span>보물상자</span>
                    </div>
                  </li>
                  <li>
                    <span className={cx("numberContainer")}>
                      {pointInfo.data.chance}
                    </span>
                    개
                  </li>
                </ul>
              </div>

              {/* info - 나의 포인트 */}
              <div className={cx("myinfoContainer")}>
                <ul>
                  <li>
                    <div className={cx("labelContainer")}>
                      <img src={"/assets/icons/coin_small.png"} />
                      <span>포인트</span>
                    </div>
                  </li>
                  <li>
                    <span className={cx("numberContainer")}>
                      {pointInfo.data.point}
                    </span>
                    포인트
                  </li>
                </ul>
              </div>

              {/*더해진 금액 보여주기*/}
              {!isEmpty && (
                <Fragment>
                  {boxOpened && (
                    <div className={cx("addedPointContainer")}>
                      +{this.state.pointDelta}
                    </div>
                  )}

                  {/* 상자 toggle 이미지 */}
                  {boxOpened === false && (
                    <div className={cx("treasureBoxContainer")}>
                      <img
                        onClick={this._handleOpen}
                        src={this._getImagePathTreauserBox(boxOpened)}
                        alt="Treasure Box Opening"
                      />
                    </div>
                  )}
                  {boxOpened && (
                    <div className={cx("treasureBoxContainerOpening")}>
                      <img
                        // onClick={this._handleOpen}
                        src={this._getImagePathTreauserBox(true)}
                        alt="Treasure Box Unopened"
                      />
                    </div>
                  )}
                </Fragment>
              )}
              {isEmpty && (
                <Fragment>
                  <div className={cx("emtpyboxContainer")}>
                    <img
                      onClick={this._handleOpen}
                      src={"/assets/images/lecture/empty_box.png"}
                      alt="Empty"
                    />
                  </div>
                  <div className={cx("buttonContainer")}>
                    <div className={cx("endButtonContainer")}>
                      <Button onClick={this._endStudy}>공부 끝내기</Button>
                    </div>
                    <div className={cx("continueButtonContainer")}>
                      <Button onClick={this._continueStudy}>
                        더 받으러 가기
                      </Button>
                    </div>
                  </div>
                </Fragment>
              )}
              {/*한꺼번에 열기 버튼*/}
              {!isEmpty && boxOpened === false && (
                <div className={cx("buttonContainer")}>
                  <div className={cx("endButtonContainer")}>
                    <Button onClick={this._handleOpenAll}>한꺼번에 열기</Button>
                  </div>
                </div>
              )}
              {!isEmpty && boxOpened && (
                <div className={cx("buttonContainerInactive")}>
                  <Button>여는 중입니다...</Button>
                </div>
              )}
            </div>
          }
        />

        <CustomModal
          visible={modalRatingVisible}
          close={false}
          handleModalVisible={this.handleModalRatingVisible}
          style={{
            width: width,
            height: height,
          }}
          body={
            <div className={cx("modalRatingBodyContainer")}>
              <div className={cx("modalScoreContainer", "flexColumnContainer")}>
                <h4>{user.name}님, 이번 강의는 몇 점인가요?</h4>
                {points && (
                  <div className={cx("reviewDonePointContainer")}>
                    {points.review_done}포인트
                  </div>
                )}
                <div className={cx("individualScoreContainer")}>
                  <Rate
                    allowHalf
                    defaultValue={0.0}
                    onChange={this.handleFunChange}
                    value={fun}
                  />
                </div>
              </div>
              <div
                className={cx("modalOpinionContainer", "flexColumnContainer")}
              >
                <h4>의견을 들려주세요👀</h4>
                <p>
                  {user.name}님께 이번 강의는 어떠셨나요?
                  <br />
                  {user.name}님의 의견은 강의 개선에 큰 도움이 됩니다.
                </p>
                <Input.TextArea
                  rows={4}
                  onChange={this.handleCheerupChange}
                  value={cheerup}
                  placeholder={
                    "선택사항입니다. 건의하고 싶으신 사항이 있는 경우 적어주세요."
                  }
                />
              </div>
              <div className={cx("buttonContainer")}>
                <Button
                  onClick={() => {
                    this.handleOk(true);
                  }}
                >
                  다음 강의 보러가기
                </Button>
              </div>
              <div className={cx("footer")}>
                <span
                  onClick={() => {
                    this.handleOk(false);
                  }}
                >
                  다음 강의로 바로 넘어갈래요
                </span>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, lecture, pointInfo, lectureNav, homework } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    lecture,
    pointInfo,
    lectureNav,
    homework,
  };
}

const connectedLecture = connect(mapStateToProps, {
  getLecture: lectureActions.getLecture,
  getLectureNavInfo: lectureActions.getLectureNavInfo,
  getPointInfo: pointActions.getPointInfo,
  getCodeSnippet: codeSnippetActions.getCodeSnippet,
  getHomeworkItem: homeworkActions.getHomeworkItem,
})(Lecture);
export { connectedLecture as Lecture };
