import React from "react";
import classNames from "classnames/bind";
import styles from "./ClassroomBanner.module.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

function ClassroomBanner({ href, thumbnail }) {
  return (
    <div
      className={cx("classroom_banner__wrapper")}
      onClick={() => {
        window.open(href);
      }}
    >
      <img
        className={cx("classroom_banner__img")}
        src={`/assets/images/classroom/${thumbnail}.png`}
        alt={"img_banner"}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedClassroomBanner = connect(mapStateToProps, {})(ClassroomBanner);
export { connectedClassroomBanner as ClassroomBanner };
