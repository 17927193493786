import React, { useEffect, useState, useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./SpaCharacter.modules.scss";
import { connect } from "react-redux";
import { UserRaceInfoCard } from "../../../../../components/organisms/UserRaceInfoCard";

const cx = classNames.bind(styles);

function SpaCharacter({
  progress,
  weekly_width,
  divWidth,
  y_offset,
  WeeksAfterStart,
}) {
  const [isHover, setIsHover] = useState(false);

  const {
    is_finished,
    week_done_rate,
    course_title,
    user_name,
    week_done,
    at_week,
  } = progress;
  const weekly_progress_rate = week_done_rate * 0.01;
  const spa_width = divWidth * 0.04;
  const x_position =
    weekly_width * (at_week - 1) +
    weekly_width * weekly_progress_rate +
    30 -
    spa_width / 2;
  const style = {
    transform: `translate(${x_position}px, ${y_offset}px)`,
    width: `${spa_width}px`,
  };

  return (
    <div className={cx("SpaCharacterContainer")} style={style}>
      <div className={cx("SpaUserInfoContainer")}>
        {isHover && (
          <UserRaceInfoCard
            is_finished={is_finished}
            course_title={course_title}
            user_name={user_name}
            week_done={week_done}
            at_week={at_week}
          />
        )}
      </div>
      {is_finished ? (
        <img
          src={`/assets/images/codingrace/happy_spa.gif`}
          alt="happy_spa"
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        />
      ) : at_week >= WeeksAfterStart.data.context ? (
        <img
          src={`/assets/images/codingrace/spa.png`}
          alt="spa"
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        />
      ) : (
        <img
          src={`/assets/images/codingrace/spa_dead@3x.png`}
          alt="dead_spa"
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { WeeksAfterStart } = state;
  return { WeeksAfterStart };
}

const connectedSpaCharacter = connect(mapStateToProps, {})(SpaCharacter);
export { connectedSpaCharacter as SpaCharacter };
