import { DisplayResolution } from "../../../../../_styles/device";
import styled from "@emotion/styled";
import { additional, neutralDay } from "@teamsparta/design-system";

export const StatusTag = styled.div`
  ${DisplayResolution.Mobile} {
    display: flex;
    padding: 4px 10px;
    align-items: center;
    gap: 4.492px;
    font-size: 10px;
    width: fit-content;
  }
  white-space: nowrap;
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  color: ${({ type, isThisStatus }) => {
    if (isThisStatus) {
      switch (type) {
        case "피드백 완료":
          return "#0AAA06";
        case "피드백 작성 중":
          return `${additional.orange100}`;
        case "숙제 검토":
          return `${neutralDay.gray80}`;
        default:
          return `${neutralDay.gray80}`;
      }
    } else {
      return `${neutralDay.gray60}`;
    }
  }};
  border: ${({ type, isThisStatus }) => {
    if (isThisStatus) {
      switch (type) {
        case "피드백 완료":
          return "0.749px solid #0AAA06";
        case "피드백 작성 중":
          return `0.749px solid ${additional.orange100}`;
        case "숙제 검토":
          return "0.749px solid rgba(95, 102, 107, 0.50);";
        default:
          return "0.749px solid rgba(95, 102, 107, 0.50);";
      }
    }
  }};
  background: ${({ type, isThisStatus }) => {
    if (isThisStatus) {
      switch (type) {
        case "피드백 완료":
          return "rgba(49, 179, 46, 0.20)";
        case "피드백 작성 중":
          return "rgba(255, 112, 5, 0.14)";
        case "숙제 검토":
          return "rgba(180, 191, 198, 0.30)";
        default:
          return "rgba(180, 191, 198, 0.30)";
      }
    } else {
      return "rgba(215, 224, 230, 0.40)";
    }
  }};

  box-shadow: ${({ type, isThisStatus }) => {
    if (isThisStatus) {
      switch (type) {
        case "피드백 완료":
          return "0px 4.492px 4.492px 0px rgba(10, 170, 6, 0.10)";
        case "피드백 작성 중":
          return "0px 4.492px 4.492px 0px rgba(255, 112, 5, 0.10)";
        case "숙제 검토":
          return "0 4.492px 4.492px 0px rgba(95, 102, 107, 0.10);";
        default:
          return "0 4.492px 4.492px 0px rgba(95, 102, 107, 0.10);";
      }
    }
  }};
`;
