import styled from "@emotion/styled";
import { useState } from "react";
import { useAtom } from "jotai";
import {
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../stores/mobileMyPageTabOpen";
import { CloseSvg, MyPageLogoSVG } from "../molecules/GnbMyPage.svg";
import { sendLog } from "../../../businesslogics/logging";

export const GnbMyPageLogo = () => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMyPageOpen, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  const [, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const isGnbMobile = window.innerWidth <= 820;

  return (
    <LogoWrapper
      onMouseEnter={() => {
        if (!isGnbMobile) setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        if (!isGnbMobile) setIsMouseOver(false);
      }}
      onClickCapture={() => {
        if (isGnbMobile) {
          setIsMouseOver(true);
          setTimeout(() => {
            setIsMouseOver(false);
          }, 100);
        }
      }}
      onClick={(e) => {
        sendLog(
          "scc_gnb_click",
          { button_text: "마이페이지", next_page: "" },
          "",
          true
        );
        setIsNavigatorOpen(false);
        setIsMyPageOpen(!isMyPageOpen);
        e.stopPropagation();
      }}
    >
      {isMyPageOpen && isGnbMobile ? (
        <CloseSvg />
      ) : (
        <MyPageLogoSVG active={isMouseOver} />
      )}
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
