import React from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions";
import classNames from "classnames/bind";
import styles from "./BroadcastListElem.module.scss";

const cx = classNames.bind(styles);

function BroadcastListElem({ data, idx }) {
  const { name, action, seconds } = data;
  return (
    <div
      className={cx("broadcast-list-elem", `${idx % 2 ? "--gray" : "--white"}`)}
    >
      <div className={cx("broadcast-list-elem__img-div")}>
        <img
          className={cx("broadcast-list-elem__img-div__img")}
          src={`/assets/images/profile/rtan_thumb_${getRandomIndex(30)}.png`}
          alt=""
        />
      </div>
      <div className={cx("broadcast-list-elem__content")}>
        <div className={cx("broadcast-list-elem__content__title")}>
          {name} 님이 {action}
        </div>
        <div className={cx("broadcast-list-elem__content__detail")}>
          {normalizeSeconds(seconds)}
        </div>
      </div>
    </div>
  );
}

function getRandomIndex(tot) {
  let idx = parseInt(Math.random() * tot + 1);
  return idx < 10 ? `0${idx}` : idx;
}

function normalizeSeconds(seconds) {
  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  if (parseInt(seconds / DAY) > 0) {
    return `${parseInt(seconds / DAY)}일 전`;
  }
  if (parseInt(seconds / HOUR) > 0) {
    return `${parseInt(seconds / HOUR)}시간 전`;
  }
  if (parseInt(seconds / MINUTE) > 0) {
    return `${parseInt(seconds / MINUTE)}분 전`;
  }
  return `${seconds}초 전`;
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedBroadcastListElem = connect(
  mapStateToProps,
  {}
)(BroadcastListElem);
export { connectedBroadcastListElem as BroadcastListElem };
