import styled from "@emotion/styled";
import { Device } from "../../../../../_styles/device";
import { neutralDay, mBody1, mCaption2, scc } from "@teamsparta/design-system";

export const ScrollWrapper = styled.div`
  width: calc(100vw - 16px);
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  ${Device.Desktop} {
  }
`;

export const ScrollContainer = styled.div`
  width: fit-content;
  padding-right: 16px;
  ${Device.Desktop} {
  }
`;
export const ScoreContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  flex-direction: row-reverse;
  ${Device.Desktop} {
    flex-direction: row;
  }
`;

export const Score = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 46px;
  background: ${({ isActive }) => (isActive ? scc.red100 : neutralDay.white)};
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: ${({ isActive }) =>
    isActive ? scc.red100 : neutralDay.gray30};
  :last-of-type {
    border-radius: 8px 0 0 8px;
    border-width: 1px;
  }
  :first-of-type {
    border-radius: 0 8px 8px 0;
  }
  ${mBody1};
  color: ${({ isActive }) =>
    isActive ? neutralDay.white : neutralDay.gray100};

  ${Device.Desktop} {
    :first-of-type {
      border-radius: 8px 0 0 8px;
      border-width: 1px;
    }
    :last-of-type {
      border-radius: 0 8px 8px 0;
      border-width: 1px 1px 1px 0;
    }
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 6px;
  ${Device.Desktop} {
    flex-direction: row-reverse;
  }
`;

export const Description = styled.div`
  ${mCaption2};
  color: ${neutralDay.gray70};
`;
