import React, { useEffect, useState } from "react";
import * as S from "./Toast.style";
import { useAtom } from "jotai";
import { isToastShow, toastContent } from "../../../../stores/toastMessage";

export const Toast = ({ type, content, start, duration }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [msgType, setMsgType] = useState("info");
  const audio = new Audio("/audios/point-get-audio.mp3");
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
      if (type === "point" && content !== null) {
        audio.play();
      }
    }, start * 1000);

    setTimeout(() => {
      setIsVisible(false);
    }, (start + duration) * 1000);

    const requiredType = type.toLowerCase();
    if (requiredType === "info") {
      setMsgType("info");
    } else if (requiredType === "point") {
      setMsgType("point");
    } else if (requiredType === "copy") {
      setMsgType("copy");
    }
  }, []);
  return (
    <>
      {msgType === "info" ? (
        <S.ToastMsgContainer isVisible={isVisible} isInfo={msgType === "info"}>
          <S.ToastMsg>{content}</S.ToastMsg>
        </S.ToastMsgContainer>
      ) : msgType === "copy" ? (
        <S.CopyMsgContainer isVisible={isVisible}>
          <S.ToastMsg>{content}</S.ToastMsg>
        </S.CopyMsgContainer>
      ) : (
        <S.ToastMsgContainer isVisible={isVisible} isInfo={msgType === "info"}>
          <S.ToastPointIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                fill="#FBBC0F"
                stroke="#FBBC0F"
                strokeWidth="1.75"
              />
              <path
                d="M8.5 6.83398V13.5007"
                stroke="#763C07"
                strokeWidth="1.25"
                strokeLinecap="round"
              />
              <path
                d="M10.5833 6.83398H8.5V11.0007H10.5833C11.9722 11.0007 12.6667 10.1673 12.6667 8.91732C12.6667 7.66732 11.9722 6.83398 10.5833 6.83398Z"
                stroke="#763C07"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </S.ToastPointIcon>{" "}
          <S.ToastImportant>{content}</S.ToastImportant>{" "}
          <S.ToastMsg>포인트가 지급되었어요!</S.ToastMsg>
        </S.ToastMsgContainer>
      )}
    </>
  );
};

export const ToastHub = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isToast] = useAtom(isToastShow);
  const [toastMessage] = useAtom(toastContent);
  useEffect(() => {
    if (isToast) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  }, [isToast]);
  return (
    <S.CopyMsgWrapper>
      {isToast && (
        <S.CopyMsgContainer isVisible={isVisible}>
          <S.ToastMsg>{toastMessage}</S.ToastMsg>
        </S.CopyMsgContainer>
      )}
    </S.CopyMsgWrapper>
  );
};

export const useToastMessage = () => {
  const [, setIsToast] = useAtom(isToastShow);
  const [, setToastMessage] = useAtom(toastContent);
  return (content) => {
    setToastMessage(content);
    setIsToast(true);
    setTimeout(() => {
      setIsToast(false);
    }, 4000);
  };
};
