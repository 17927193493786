import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Select.module.scss";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { sendLog } from "../../../businesslogics/logging";

const cx = classNames.bind(styles);

function Select({
  defaultValue,
  options,
  handleOptionSelected,
  enrolled,
  enrolledDetail,
  setEnrolled,
  getEnrolledDetail,
}) {
  /**
   * defaultValue: 기본 선택값
   * options: [
   *  {
   *    name: '', // 보여줄 이름값
   *    value: '', // 선택값
   *  }
   * ]
   */
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const valNameDict = {
    has_card: "네!",
    no_card: "아니요!",
    waiting_card: "신청 후, 대기중입니다!",
  };
  const displayedValue = Object.keys(valNameDict).includes(selectedValue)
    ? valNameDict[selectedValue]
    : selectedValue;
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!enrolled.loaded) setEnrolled(params.enrolled_id);
    if (!enrolledDetail.loaded)
      getEnrolledDetail(params.enrolled_id, params.detail_id);
  }, []);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={cx("select")}>
      <div
        className={cx("select__selected")}
        onClick={(e) => {
          e.stopPropagation();
          setIsOptionsVisible(!isOptionsVisible);
        }}
      >
        {displayedValue}
        <svg
          className={cx("select__selected__img")}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 9"
        >
          <path
            fill="#000000"
            d="M1.623 0l5.285 5.342L12.192 0l1.623 1.653-6.907 6.982L0 1.653 1.623 0z"
          />
        </svg>
      </div>
      {isOptionsVisible && (
        <div className={cx("select__options")}>
          {options.map((option, idx) => {
            return (
              <div
                key={"option" + idx}
                className={cx(
                  "select__options__option",
                  `${option.name === selectedValue ? "--selected" : ""}`
                )}
                onClick={() => {
                  enrolled.loaded &&
                    enrolledDetail.loaded &&
                    sendLog(
                      "scc_lecturePage_report_completed",
                      {
                        ...enrolled.data,
                        ...enrolledDetail.data,
                        type: option.name,
                      },
                      ""
                    );
                  setIsOptionsVisible(false);
                  setSelectedValue(option.value);
                  handleOptionSelected(option.value);
                }}
              >
                {option.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled, enrolledDetail } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetail,
  };
}

const connectedSelect = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getEnrolledDetail: enrolledActions.getEnrolledDetail,
})(Select);
export { connectedSelect as Select };
