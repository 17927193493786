export const ABTEST_CONSTANT = {
  ENROLLED_REQUEST: "ENROLLED_REQUEST",
  ENROLLED_SUCCESS: "ENROLLED_SUCCESS",
  ENROLLED_FAILURE: "ENROLLED_FAILURE",

  PAYMENT_PAGE_REQUEST: "PAYMENT_PAGE_REQUEST",
  PAYMENT_PAGE_SUCCESS: "PAYMENT_PAGE_SUCCESS",
  PAYMENT_PAGE_FAILURE: "PAYMENT_PAGE_FAILURE",

  KKO_SIGNUP_PAGE_REQUEST: "KAKAO_SIGNUP_PAGE_REQUEST",
  KKO_SIGNUP_PAGE_SUCCESS: "KAKAO_SIGNUP_PAGE_SUCCESS",
  KKO_SIGNUP_PAGE_FAILURE: "KAKAO_SIGNUP_PAGE_FAILURE",

  COURSERCMD_PAGE_REQUEST: "COURSERCMD_PAGE_REQUEST",
  COURSERCMD_PAGE_SUCCESS: "COURSERCMD_PAGE_SUCCESS",
  COURSERCMD_PAGE_FAILURE: "COURSERCMD_PAGE_FAILURE",

  COURSERCMD_LOG_REQUEST: "COURSERCMD_LOG_REQUEST",
};
