import { POINT } from "../constants";
import { pointService } from "../services";

function getPoint() {
  function request() {
    return { type: POINT.POINT_REQUEST };
  }

  function success(response) {
    return { type: POINT.POINT_SUCCESS, response };
  }

  function failure(error) {
    return { type: POINT.POINT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    pointService.getPoint().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetPoint = () => {
  return getPoint();
};

export const pointActions = {
  getPoint,
};
