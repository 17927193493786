import React, {useEffect, useState} from 'react';
import classNames from 'classnames/bind';
import styles from "./CheckinPage.module.scss";
import {connect} from 'react-redux';
import {enrolledActions, userActions} from "../../_state/actions";
import {WelcomeMessage} from "./WelcomeMessage/WelcomeMessage";
import {CheckinFormContainer} from "./CheckinFormContainer";
import {AttendanceMark} from "./AttendanceMark/AttendanceMark"
import {RtannyGround} from "./RtannyGround";
import {CheckinCards} from "./CheckinCards";
import {roundsActions} from "../../_state/actions/rounds.actions";
import {Navbar} from "../../components/organisms/Navbar";
import {HoverGuide} from "./HoverGuide/HoverGuide";


const cx = classNames.bind(styles);

function CheckinPageV2({
                           user,
                           match,
                           round,
                           enrolled,
                           setRound,
                           setEnrolled,
                       }) {
    const {enrolled_id, round_id} = match.params;
    useEffect(() => {
        setRound(round_id);
        setEnrolled(enrolled_id);
    }, [match.params.enrolled_id]);
    return (
        <>
            {
                enrolled.loaded && round.loaded &&
                <>
                    <Navbar/>
                    <div className={cx('CheckinMainContainer')}>
                        <WelcomeMessage/>
                        <AttendanceMark />
                        <CheckinFormContainer enrolled_id={enrolled_id} round_id={round_id}/>
                        <HoverGuide />
                        <RtannyGround/>
                        <CheckinCards/>
                    </div>

                </>
            }
        </>
    )
}

function mapStateToProps(state) {
    const {authentication, round, enrolled} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        round,
        enrolled
    }
}

const connectedCheckinPageV2 = connect(mapStateToProps, {
    setRound: roundsActions.setRound,
    setEnrolled: enrolledActions.setEnrolled,
})(CheckinPageV2);
export {connectedCheckinPageV2 as CheckinPageV2}