import { useMutation } from "react-query";
import { govService } from "../../../_state/services";

export const useDoGovEnrolledDetail = () => {
  return useMutation(
    async ({ enrolledId, enrolledDetailId }) => {
      await govService.doGovEnrolledDetail(enrolledId, enrolledDetailId);
    },
    {
      onSuccess: () => {
        console.log("onSuccess on DoGovEnrolledDetail");
      },
      onError: () => {
        console.log("onError on DoGovEnrolledDetail");
      },
    }
  );
};
