import { useParams } from "react-router-dom";
import { useInitializeLectureWithIds } from "../../../pages/LecturePageV3/useInitialize";
import { useDoGovEnrolledDetail } from "../repository/useDoGovEnrolledDetail";
import { useEffect, useState } from "react";
import {
  useHomeworkAnswer,
  useMyHomeworks,
} from "../repository/useMyHomeworks";
import { useDoGovEnrolledHomework } from "../repository/useDoGovEnrolledHomework";
import { useHomeworksDetails } from "../../../queries/homework";
import { useMakeEnrolledHomeworkDetails } from "./HomeworkDetails";
import { useEnrolled } from "../../../hooks/useProductName";

export const useInitializeLectureAll = () => {
  const { enrolled_id, detail_id } = useParams();
  const [isInitialized, setIsInitialized] = useState(false);
  const isInitializedLecture = useInitializeLectureWithIds(
    enrolled_id,
    detail_id
  );
  const doGovEnrolledDetail = useDoGovEnrolledDetail();
  useEffect(() => {
    if (enrolled_id && detail_id) {
      const mutationData = {
        enrolledId: enrolled_id,
        enrolledDetailId: detail_id,
      };
      doGovEnrolledDetail.mutate(mutationData);
    }
  }, [enrolled_id, detail_id]);
  useEffect(() => {
    if (isInitializedLecture && doGovEnrolledDetail.isSuccess) {
      setIsInitialized(true);
    }
    return () => {
      setIsInitialized(false);
    };
  }, [isInitializedLecture, doGovEnrolledDetail.isSuccess]);
  return isInitialized;
};

export const useInitializeHomeworkAll = () => {
  const { enrolled_id, enrolled_homework_id } = useParams();
  const [isInitialized, setIsInitialized] = useState(false);
  const [homeworkId, setHomeworkId] = useState(null);
  const homeworks = useMyHomeworks(enrolled_homework_id);
  const homeworkAnswer = useHomeworkAnswer(homeworkId);
  const doGovEnrolledHomework = useDoGovEnrolledHomework();
  const homeworkDetails = useMakeEnrolledHomeworkDetails(
    enrolled_id,
    enrolled_homework_id
  );
  const enrolled = useEnrolled(enrolled_id);
  useEffect(() => {
    if (homeworks.isSuccess) {
      const homework = homeworks.data.context?.homework;
      if (homework) {
        setHomeworkId(homework._id);
      }
    }
  }, [homeworks.isSuccess]);
  useEffect(() => {
    if (enrolled_id && enrolled_homework_id) {
      const mutationData = {
        enrolledId: enrolled_id,
        enrolledHomeworkId: enrolled_homework_id,
      };
      doGovEnrolledHomework.mutate(mutationData);
    }
  }, [enrolled_homework_id, enrolled_id]);
  useEffect(() => {
    if (
      homeworks.isSuccess &&
      doGovEnrolledHomework.isSuccess &&
      homeworkDetails.length > 0 &&
      enrolled.data
    ) {
      setIsInitialized(true);
    }
    return () => {
      setIsInitialized(false);
    };
  }, [
    homeworks.isSuccess,
    doGovEnrolledHomework.isSuccess,
    homeworkDetails,
    enrolled.data,
  ]);
  return isInitialized;
};
