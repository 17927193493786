import videojs from "video.js";
import { useEffect, useRef, useState } from "react";
import useThrottle from "../../../../../hooks/useThrottle";
import qualityLevels from "videojs-contrib-quality-levels";
import { useAtom } from "jotai";
import { networkAtom } from "../stores/playerAtom";

const waitingCountThreshold = 2;

export const makeFullLogData = (
  videoData,
  enrolledDetail,
  userId,
  playerType,
  enrolled
) => {
  const courseTitle = enrolled?.data?.courses?.title;
  const fullLogData = {
    ...videoData,
    hosting_type: playerType === "V2" ? "hls" : "mp4",
    playtime: enrolledDetail?.data?.playtime,
    course_id: enrolledDetail?.data?.course_id,
    week: enrolledDetail?.data?.week,
    week_order: enrolledDetail?.data?.week_order,
    lecture_id: enrolledDetail?.data?.lecture_id,
    enrolled_id: enrolledDetail?.data?.enrolled_id,
    user_id: userId,
    course_title: courseTitle,
  };
  return fullLogData;
};

export const useVideoMonitoring = (videoRef, logApiFunction) => {
  const playerRef = useRef(null);

  // Use refs for buffering state and count
  const isBuffering = useRef(false);
  const waitingCount = useRef(0);

  // Network information
  const networkType = useRef(null);
  const rtt = useRef(null);

  // Buffering start time stamp
  const bufferingStartTimestamp = useRef(null);

  const [throttledSendLogs, cancelThrottle] = useThrottle(logApiFunction, 3000);

  const [networkSpeed] = useAtom(networkAtom);

  useEffect(() => {
    if (navigator.connection) {
      networkType.current = navigator.connection.effectiveType;
      rtt.current = navigator.connection.rtt;
    }
  }, []);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (!videojs.getPlugin("qualityLevels")) {
      videojs.registerPlugin("qualityLevels", qualityLevels);
    }

    const player = videojs(videoRef.current);
    playerRef.current = player;
    let actualQuality = null;
    if (playerRef && playerRef.current.qualityLevels()) {
      actualQuality = playerRef.current.qualityLevels();
    }

    player.ready(() => {
      player.on("waiting", () => {
        if (!isBuffering.current) {
          // Buffering just started
          isBuffering.current = true;
          waitingCount.current += 1;
        }
        bufferingStartTimestamp.current = player.currentTime();

        if (waitingCount.current >= waitingCountThreshold) {
          const selectedIndex = actualQuality.selectedIndex_;
          const currentTime = player.currentTime();
          const logData = {
            networkType: networkType.current,
            rtt: rtt.current,
            waitingCount: waitingCount.current,
            selectedQuality: actualQuality?.levels_[selectedIndex],
            buffered_timestamp: currentTime,
            is_skipped: false,
            network_speed: networkSpeed,
          };
          throttledSendLogs(logData);
          waitingCount.current = 0; // Reset count after sending
        }
      });
      // When the video resumes playing, reset the buffering flag
      player.on("canplay", () => {
        isBuffering.current = false;
        const currentPlayerTime = player.currentTime();
        if (
          currentPlayerTime - bufferingStartTimestamp.current > 2 &&
          currentPlayerTime - bufferingStartTimestamp.current < 7
        ) {
          // If the player was buffering for more than 3 seconds, send the log
          const selectedIndex = actualQuality.selectedIndex_;
          const logData = {
            networkType: networkType.current,
            rtt: rtt.current,
            waitingCount: waitingCount.current,
            selectedQuality: actualQuality?.levels_[selectedIndex],
            buffered_timestamp: currentPlayerTime,
            network_speed: networkSpeed,
            is_skipped: true,
          };
          throttledSendLogs(logData);
          waitingCount.current = 0; // Reset count after sending
        }
      });
      player.on("ended", () => {
        isBuffering.current = false;
        waitingCount.current = 0;
      });
      player.on("seeked", () => {
        isBuffering.current = false;
        waitingCount.current = 0;
      });
    });

    return () => {
      cancelThrottle();
    };
  }, [videoRef, networkType, rtt, throttledSendLogs]);
};
