import { config } from "../config";
import { authHeader, handleResponse } from "../_helpers";

function getRcmdDashboard() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  return fetch(`${config.apiUrl}/v1/rcmds/`, requestOptions).then(
    handleResponse
  );
}

export const rcmdDashboardService = {
  getRcmdDashboard,
};
