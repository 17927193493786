import styled, { keyframes } from "styled-components";
import { PIRATES } from "../../../../_consts";
import { LECTURE_GNB_HEIGHT } from "../variables";

export const LectureNavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 24px;
  height: ${LECTURE_GNB_HEIGHT}px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "white";
    }
  }};
  border-bottom: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#3A3E41";
        default:
          return "#c7d2d8";
      }
    }};
  align-items: center;
`;

export const NavLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const NavRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const NavBack = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const NavCourseTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
`;

export const NavCourseTitle = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  max-width: 80%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NavDivider = styled.div`
  width: 1px;
  height: 10px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#81898F";
      default:
        return "#b4bfc6";
    }
  }};
`;

export const NavWeekTitle = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#FF6C7A";
      default:
        return "#e8344e";
    }
  }};
  white-space: nowrap;
`;

export const NavShareCta = styled.div`
  min-width: 120px;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "#d7e0e6";
      }
    }};
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#3A3E41";
      default:
        return "#ffffff";
    }
  }};
  font-family: Pretendard;
  font-weight: 600;

  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  line-height: 48px;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: ${(props) => (props.show ? `visible` : "hidden")};
  position: relative;
  ${({ isABT }) =>
    isABT &&
    `
  @media (max-width: 768px) {
    width: 80%;
    font-size: 12px;
    padding: 0 6px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 14px;
    padding: 0 10px;
  }
  @media (min-width: 1024px) {
    min-width: 155px;
    font-size: 16px;
    padding: 0 0;
  }
    `}
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: .50;
    transform-origin: 50% 50%;
    transform: rotate(10deg) scale(5);
    transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
`;

export const isDoneButtonTitle = styled.div`
  max-width: 152px;
  width: 100%;
  font-family: Pretendard;
  font-weight: 600;
  max-font-size: 16px;
  font-size: 80%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  ${({ isABT }) =>
    isABT &&
    `
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 14px;
    padding: 0 10px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0 22px;
  }
    `}
`;

export const isNotDoneButtonTitle = styled.div`
  opacity: 0;
  animation: ${fadeInAnimation} 0.3s ease-in-out 3s forwards;
  font-family: Pretendard;
  font-weight: 600;
  max-font-size: 14px;
  font-size: 80%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  ${({ isABT }) =>
    isABT &&
    `
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    min-width: 100px;
    font-size: 14px;
    padding: 0 10px;
  }
  @media (min-width: 1024px) {
    min-width: 152px;
    font-size: 16px;
    padding: 0 24px;
    }
    `}
`;

export const BackgroundStamp = styled.img`
  height: 40px;
  width: 40px;
  z-index: 9;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${fadeOutAnimation} 0.3s ease-in-out 2.5s forwards;
`;

export const MiniStamp = styled.img`
  height: 40px;
  width: 40px;
  z-index: 13;
  border-radius: 5px;
  position: absolute;
  top: 10%;
  transform: translate(-50%, -50%);
  animation: ${pulseAnimation} 0.5s 1s forwards,
    ${fadeOutAnimation} 0.3s ease-in-out 2.5s forwards;
  opacity: 0;
`;
export const CountSeen = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #81898f;
  text-align: center;

  max-font-size: 16px;
  font-size: 80%;
  font-family: "Pretendard";
  font-weight: 600;
`;

export const NavShareIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
