import { useEffect, useState } from "react";

export const useSetLastSeenEnrolledData = (lastSeenData, firstLecture) => {
  const [targetLectureId, setTargetLectureId] = useState("");
  const [enrolledDetailId, setEnrolledDetailId] = useState("");
  const [lectureWeek, setLectureWeek] = useState(0);
  const [lectureWeekOrder, setLectureWeekOrder] = useState(0);
  const [lectureTitle, setLectureTitle] = useState("");
  const [lecturePlaytime, setLecturePlaytime] = useState(0);
  useEffect(() => {
    if (lastSeenData.data) {
      setTargetLectureId(lastSeenData.data.lecture_id);
      setEnrolledDetailId(lastSeenData.data.enrolled_detail_id);
      setLectureWeek(lastSeenData.data.week);
      setLectureTitle(lastSeenData.data.title);
      setLecturePlaytime(lastSeenData.data.play_time);
      setLectureWeekOrder(lastSeenData.data.week_order);
      if (lastSeenData.data?.week === null && firstLecture) {
        setTargetLectureId(firstLecture.lecture_id);
        setEnrolledDetailId(firstLecture.enrolled_detail_id);
        setLectureWeek(firstLecture.week);
        setLectureTitle(firstLecture.title);
        setLecturePlaytime(firstLecture.playtime);
        setLectureWeekOrder(firstLecture.week_order);
      }
    }
  }, [lastSeenData.data, firstLecture]);
  return {
    targetLectureId,
    enrolledDetailId,
    lectureWeek,
    lectureTitle,
    lecturePlaytime,
    lectureWeekOrder,
  };
};
