import React from "react";
import PopularArticleCard from "../PopularArticleCard/PopularArticleCard";
import { Article } from "../model/recommendArticles.model";
import { usePopularArticles } from "../../../hooks/completeRecommend/usePopularArticles";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

interface Props {
  courseId: string;
  courseTitle: string;
  article: Article;
}

export const PopularArticleContainer = ({ courseId, courseTitle }: Props) => {
  const articles = usePopularArticles(courseId);

  const handleArticleClick = (
    link: string,
    brand: string,
    articleTitle: string
  ) => {
    if (link) {
      window.open(link, "_blank");
      sendCPLog("scc_courseRcmdPage_click_blog", {
        course_id: courseId,
        course_title: courseTitle,
        title: articleTitle,
        next_href: link,
        brand,
      });
    }
  };

  return (
    <>
      {articles.map(
        ({ articleImgSrc, articleTitle, articleDescription, link, brand }) => (
          <PopularArticleCard
            articleImgSrc={articleImgSrc}
            articleTitle={articleTitle}
            articleDescription={articleDescription}
            link={link}
            onArticleClick={() => handleArticleClick(link, brand, articleTitle)}
          />
        )
      )}
    </>
  );
};
