import privateInstance from "../../_helper/axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import privateInstanceV3 from "../../_helper/axiosV3";

const getMyHomeworkDetails = async (enrolleds_homeworks_id) => {
  const response = await privateInstanceV3.get(
    `/learning-resources/${enrolleds_homeworks_id}/homework_details`
  );
  return response.data;
};

export const useGetHomeworksDetails = (enrolleds_homeworks_id) => {
  return useQuery(
    ["homeworks", enrolleds_homeworks_id],
    () => {
      return getMyHomeworkDetails(enrolleds_homeworks_id);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!enrolleds_homeworks_id,
    }
  );
};
const getEnrolled = async (enrolled_id) => {
  const response = await privateInstance.get(`/enrolleds/${enrolled_id}`);
  return response.data;
};

export const useGetEnrolled = (enrolled_id) => {
  return useQuery(
    ["enrolled", enrolled_id],
    () => {
      return getEnrolled(enrolled_id);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!enrolled_id,
    }
  );
};

async function getEnrolledDetail(enrolled_id, detail_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/details/${detail_id}`)
    .then((resp) => resp.data);
}

export const useGetEnrolledDetail = (enrolled_id, detail_id) => {
  return useQuery(
    ["enrolledDetails", enrolled_id, detail_id],
    () => {
      return getEnrolledDetail(enrolled_id, detail_id);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!enrolled_id,
    }
  );
};

const getCourse = async (course_id) => {
  return await privateInstance.get(`/courses/${course_id}`).then((resp) => {
    return resp.data;
  });
};

export const useGetCourse = (course_id) => {
  return useQuery(
    ["course", course_id],
    () => {
      return getCourse(course_id);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!course_id,
    }
  );
};

const getMaterialByEnrolled = async (enrolled_id, week) => {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/week/${week}/material`)
    .then((resp) => resp.data);
};

export const useGetMaterialByEnrolled = (enrolled_id, week) => {
  return useQuery(
    ["material", enrolled_id, week],
    () => {
      return getMaterialByEnrolled(enrolled_id, week);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!enrolled_id && !!week,
    }
  );
};

const setHomeworkHintOpened = async (enrolleds_homeworks_id, level) => {
  return await privateInstance.put(
    `/homeworks/${enrolleds_homeworks_id}/hint_opened`,
    {
      level: level,
    }
  );
};

export const useMutateHintOpened = (enrolledsHomeworksId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["hintOpened", enrolledsHomeworksId],
    (level) => setHomeworkHintOpened(enrolledsHomeworksId, level),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["myHomeworks", enrolledsHomeworksId]);
      },
    }
  );
};
