import React, { Component } from "react";
import classNames from "classnames/bind";
import styles from "./CustomModal_Reimburse.module.scss";
import { createEventId, history } from "../../_helpers";
import axios from "../../_helpers/axios";
import { connect } from "react-redux";
import { enrolledActions, mypageActions, userActions } from "../../_actions";
import { getCookie, setCookie } from "../../v2/_helper";

const cx = classNames.bind(styles);

class CustomModal_ReimburseEn extends Component {
  /**
   * 필수 props
   * @param props
   * style
   * handleModalVisible
   * body
   * visible
   */
  state = {
    forCash: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { enrolled_id } = this.props;
    if (!enrolled_id) {
      return;
    }
    this.props.getEnrolledStatus(enrolled_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.enrolled_id && this.props.enrolled_id) {
      this.props.getEnrolledStatus(this.props.enrolled_id);
    }
  }

  _handleCloseModal = () => {
    this.props.handleModalVisible(false);
  };

  _forCash = async () => {
    const { enrolled_id } = this.props;
    const { forCash, isMypage } = this.state;
    if (forCash) {
      window.open("https://forms.gle/BMKheCUYw1akmm3D7");
      if (!isMypage) {
        history.push(`/retrospectives/${enrolled_id}`);
      }
      const eventID = createEventId();
      this.getPoint(true);
      await this._sendLog("cash_btn", forCash, eventID);
    } else {
      const eventID = createEventId();
      await this._sendLog("cash_btn", forCash, eventID);
      this.setState({
        forCash: !forCash,
      });
    }
  };

  getPoint = (isCash = false) => {
    const { enrolled_id } = this.props;
    if (!enrolled_id) {
      alert("유효한 수강 과목이 아닙니다. 관리자에게 문의해주시기 바랍니다.");
      return;
    }

    axios
      .post("/enrolleds/finish", { enrolled_id, isCash })
      .then((resp) => {
        const { data } = resp;
        alert(data.msg);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };

  goMypage = () => {
    // alert('수료증 다운로드 버튼');
    // history.push('/en/classroom')
    const { file_link } = this.props.cert_data;
    window.open(`${file_link}`, "_blank");
  };

  goPage = (page) => {
    if (page === "classroom") {
      history.push("/en/classroom");
    } else if (page === "register") {
      window.open("https://bit.ly/3snSGjt", "_blank");
    }
  };

  _getUniqueId = () => {
    let unique_id = getCookie("unique_id");
    if (unique_id && unique_id !== "") {
      return unique_id;
    }
    unique_id = this._genUniqueId();
    setCookie("unique_id", unique_id, 365);
    return unique_id;
  };

  _genUniqueId = (length = 20) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  _sendLog = async (action, forCash = false, eventID) => {
    let ua = window.navigator.userAgent;
    ua = ua.toLowerCase();
    let matched = {};
    const unique_id = this._getUniqueId();
    const referrer = "";
    const url = document.location.href;
    const { user } = this.props;
    let user_id = user ? user._id : "";
    const platform_match =
      /(ipad)/.exec(ua) ||
      /(ipod)/.exec(ua) ||
      /(windows phone)/.exec(ua) ||
      /(iphone)/.exec(ua) ||
      /(kindle)/.exec(ua) ||
      /(silk)/.exec(ua) ||
      /(android)/.exec(ua) ||
      /(win)/.exec(ua) ||
      /(mac)/.exec(ua) ||
      /(linux)/.exec(ua) ||
      /(cros)/.exec(ua) ||
      /(playbook)/.exec(ua) ||
      /(bb)/.exec(ua) ||
      /(blackberry)/.exec(ua) ||
      [];
    const page = forCash ? 1 : 0;

    matched.platform = platform_match[0] || "";
    const data = {
      unique_id,
      user_id,
      referrer,
      url,
      action,
      page,
      platform: matched.platform,
      event_id: eventID,
    };
    await axios.post("/logs/", data);
  };

  goLinkedIn = () => {
    const { course_title, _id: cert_id, file_link } = this.props.cert_data;
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    window.open(
      `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
        course_title
      )}&organizationId=30234270&issueYear=${year}&issueMonth=${month}&certUrl=${file_link}&certId=${cert_id}`
    );
  };

  render() {
    let { visible, style, enrolledStatus, user } = this.props;
    const { forCash } = this.state;
    if (!enrolledStatus.loaded) {
      return <div />;
    }
    // 수료 여부 최종 확인
    const {
      done,
      reimbursable,
      is_free,
      is_fast_finish,
      is_cash_reimbursable,
    } = enrolledStatus.data;
    visible = done ? visible : false;

    // impression
    if (visible && !forCash) {
      const eventID = createEventId();
      this._sendLog("pageview_payback", false, eventID);
    }

    return (
      <div>
        <div
          className={visible ? cx("modal", "active") : cx("modal")}
          onClick={this._handleCloseModal}
        ></div>
        <div
          className={
            visible
              ? is_fast_finish
                ? cx("modal-background", "active", "long")
                : cx("modal-background", "active")
              : cx("modal-background")
          }
          style={{ ...style }}
        >
          <div className={cx("modal-content")}>
            {/*환급신청하기*/}
            <div className={cx("reimburse-main")}>
              {/*이미지&텍스트*/}
              <div className={cx("reimburse-body")}>
                <img
                  className={cx("body-image")}
                  src={"/assets/images/characters/rtan_finish.png"}
                  alt=""
                />
                <h2>Congratulations!</h2>
                <p>
                  We're happy for your completion!😍
                  <br />
                  You can now check out your certificate in your classroom.
                  <br />
                  <br />
                  Are you looking forward to the next bootcamp?
                  <br />
                  Early-bird registration is now available! 🕊️ <br />
                  Keep coding with SpartaCodingClub!💪
                </p>
              </div>
            </div>
            {/*버튼*/}
            <div className={cx("reimburse-buttons")}>
              {/*<button className={cx('cta')} onClick={this.goMypage}>Download Certificate</button>*/}
              {/*<button className={cx('sub-cta')} onClick={this.goLinkedIn}>Add to LinkedIn</button>*/}
              <button
                className={cx("cta")}
                onClick={() => {
                  this.goPage("register");
                }}
              >
                Register Now!
              </button>
              <button
                className={cx("sub-cta")}
                onClick={() => {
                  this.goPage("classroom");
                }}
              >
                Go to My Classroom
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, enrolledStatus } = state;
  const { user } = state.authentication;
  return {
    authentication,
    user,
    enrolledStatus,
  };
}

const connectedCustomModal_ReimburseEn = connect(mapStateToProps, {
  refreshInfo: userActions.refreshInfo,
  logout: userActions.logout,
  loginWithToken: userActions.loginWithToken,
  getMypage: mypageActions.getMypage,
  getEnrolledStatus: enrolledActions.getEnrolledStatus,
})(CustomModal_ReimburseEn);
export { connectedCustomModal_ReimburseEn as CustomModal_ReimburseEn };
