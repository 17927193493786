import * as S from "./recommendNBCamp.style";
import {useDispatch, useSelector} from "react-redux";
import {history, params} from "../../../../_helpers";
import {useGetEnrolled} from "../../../_state/actions";
import {useLogFinishPageView} from "../../../hooks/finish";
import {useEffect} from "react";
import {postEnrolledFinish} from "../../../queries/nps";
import {logOnClickCourse} from "../../../hooks/finish/recommend";
const CourseOverviewContent = ({category, value, detail}) => {
    return (
        <S.CourseOverviewContent>
            <S.CourseOverviewCategory>
            {category}
            </S.CourseOverviewCategory>
            <S.CourseOverviewValue>
            {value}
            </S.CourseOverviewValue>
            <S.CourseOverviewDetail>
            {detail}
            </S.CourseOverviewDetail>
        </S.CourseOverviewContent>
    )
}
const RecommendCourseBox = () => {
    const enrolled = useSelector((state) => state.enrolled);

  return (
    <S.RecommendCourseBox
        onClick={() => {
                const url = 'https://nbcamp.spartacodingclub.kr/'
                window.open(url)
                logOnClickCourse(enrolled, `내일배움캠프 메인페이지`, url);
            }
        }
    >
        <S.RecommendCourseBoxTitle>
            <S.RecommendCourseBoxTitleImg src={`/assets/thumbnails/nbc_title.png`}/>
            <S.RecommendCourseBoxTitleText>
                <S.CourseDescript>개발 비전공자도 5개월 뒤 취업</S.CourseDescript>
                <S.CourseName>내일배움캠프</S.CourseName>
            </S.RecommendCourseBoxTitleText>
        </S.RecommendCourseBoxTitle>
        <S.Hr/>
        <S.CourseOverview>
            <CourseOverviewContent category = "참가 비용" value = "전액 국비 지원"/>
            <CourseOverviewContent category = "모집" value = "트랙별 100명" detail="* 선착순 모집"/>
            <CourseOverviewContent category = "혜택" value = "훈련 장려금 지급"/>
        </S.CourseOverview>
        <S.Cta
        >
            자세히 보기
        </S.Cta>
    </S.RecommendCourseBox>
  );
};

const RecommendNBCamp = () => {
  const { user } = useSelector((state) => state.authentication);

  return (
    <S.Container>
      <S.Title>
        {user.name} 님께 추천드리는
        <br />
        개발자 취업 코스
      </S.Title>
      <RecommendCourseBox/>
    </S.Container>
  );
};

export default RecommendNBCamp;
