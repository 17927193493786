import styled from "styled-components";
import { device, DisplayResolution } from "../../../../_styles/device";
import { PIRATES } from "../../../../_consts";

export const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 400px;
  width: 360px;
`;

export const LecListWeekContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};

  border-bottom: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#2B2D2E";
        default:
          return "#eef3f6";
      }
    }};
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;
`;

export const LecListInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LecListTitle = styled.div`
  font-family: Pretendard;
  font-weight: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "500";
      default:
        return "700";
    }
  }};
  font-size: 15px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  margin-bottom: 5px;
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LecListSubInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const LecListSubInfo = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#5F666B";
      default:
        return "#b4bfc6";
    }
  }};
  align-items: center;
  height: 18px;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const LecListOpenBtn = styled.div`
  width: 24px;
  height: 24px;
  margin: auto 0;

  @media ${device.desktop} {
    transform: ${(props) => (props.isFocus ? "rotate(180deg)" : "none")};
    transition-duration: 0.5s;
  }
`;

export const InfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#eef3f6";
    }
  }};
  border-left: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#141617";
        default:
          return "#eef3f6";
      }
    }};
  border-right: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#141617";
        default:
          return "#eef3f6";
      }
    }};
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  padding: 16px 12px;
  background-color: ${({ nowPlay, theme }) => {
    switch (theme) {
      case PIRATES:
        return nowPlay ? "rgba(255, 108, 122, 0.4)" : "rgba(0,0,0,0)";
      default:
        return nowPlay ? "#FF6C7A" : "#EEF3F6";
    }
  }};
`;

export const InfoTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-shrink: 0;
  max-width: 65%;
`;

export const InfoOrder = styled.div`
  padding-top: 1px;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 13px;
  flex-shrink: 0;
  color: ${({ nowPlay, theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return nowPlay ? "#ffffff" : "#141617";
    }
  }};
`;

export const InfoTitle = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ nowPlay, theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return nowPlay ? "#ffffff" : "#141617";
    }
  }};
`;

export const InfoTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const InfoTime = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 11px;
  color: ${({ nowPlay, theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return nowPlay ? "#ffffff" : "#3a3e41";
    }
  }};
`;

export const InfoCheck = styled.div`
  width: 16px;
  height: 16px;
  margin-top: -1px;
`;

export const InfoOnPlay = styled.div`
  width: 16px;
  height: 16px;
  margin-top: -2px;
`;

export const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#e4ebf0";
    }
  }};
  border-radius: 8px;
`;
