import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const MyVoucherWrapper = styled.div`
  padding: 0 16px;

  ${Device.Desktop} {
    padding: 0;
  }
`;
