import styles from './B2bNPSCheckContainer.module.scss'
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import {connect} from "react-redux";

const cx = classNames.bind(styles);

function B2bNPSCheckContainer({question, index, handleChange}) {
    const [selectChoices, setSelectChoices] = useState([])

    useEffect(() => {
        let howManyChoices = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
        setSelectChoices(howManyChoices);
    },[])

    return (
        <div className={cx("B2bNPSCheckWrapper")}>
            <div className={cx("B2bNPSCheckNumber")}>
                Q{index+1}
            </div>
            <div className={cx("B2bNPSCheckQuestion")}>
                {question}
            </div>
            <div className={cx("B2bNPSCheckButtons")}>
                {selectChoices.map(selectChoice => (
                    <label>
                    <input className={cx("B2bNPSCheckButton")} name={index} value={selectChoice}
                           type={"radio"} onChange={(e) => handleChange(e, index)}/>
                    <span className={cx("B2bNPSCheckSpan")}>{selectChoice}</span>
                    </label>
                    ))}
            </div>
            <div className={cx("B2bNPSCheckPlaceholder")}>
                <div>추천할 의향이 없다</div>
                <div>추천할 의향이 아주 높다</div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
  const { authentication, enrolled } = state;
  const { user } = authentication;
  return { user, enrolled };
}

const connectedB2bNPSCheckContainer = connect(mapStateToProps, {})(B2bNPSCheckContainer);
export { connectedB2bNPSCheckContainer as B2bNPSCheckContainer };