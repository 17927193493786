import React from "react";
import * as S from "./RecordContent.style";

export function RecordContent({ actions }) {
  return (
    <S.Container>
      {actions.map((action, idx) => {
        const { mainText, subText, onClick, disable } = action;
        return (
          <S.Action
            key={idx}
            onClick={disable ? () => {} : onClick}
            disable={disable}
          >
            <S.ActionMainText>{mainText}</S.ActionMainText>
            <S.ActionSubText>{subText}</S.ActionSubText>
          </S.Action>
        );
      })}
    </S.Container>
  );
}
