import { sendCustomDataLog } from "../../../businesslogics/logging";
import { recommendCourses } from "../../../pages/FreeFinishPage/Recommend/recommend.model";

export const logOnClickCourse = (enrolled, pageTitle, pageUrl) => {
  sendCustomDataLog("scc_courseRcmdPage_click_button", {
    course_title: enrolled.data?.courses.title,
    course_id: enrolled.data?.courses._id,
    page_title: pageTitle,
    page_url: pageUrl,
  });
};

export const logOnClickMore = (enrolled, goal) => {
  sendCustomDataLog("scc_courseRcmdPage_click_button", {
    course_title: enrolled.data?.courses.title,
    course_id: enrolled.data?.courses._id,
    post_goal: goal,
    rcmd_courses: recommendCourses[goal],
  });
};

export const logOnClickCta = (enrolled, buttonText, pageUrl, pageTitle) => {
  sendCustomDataLog("scc_courseRcmdPage_click_button", {
    course_title: enrolled.data?.courses.title,
    course_id: enrolled.data?.courses._id,
    button_text: buttonText,
    page_url: pageUrl,
    page_title: pageTitle,
  });
};
