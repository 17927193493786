import privateInstanceV3 from "../../_helper/axiosV3";
export interface EnrolledsEvaluationDetails {
  evaluationsDetailId: string;
  submittedAnswer: string;
}

export interface EvaluationSubmission {
  enrolledsEvaluationsId: string;
  answerData: EnrolledsEvaluationDetails[];
}

async function getMyEvaluations(enrolledsEvaluataionId: string) {
  return await privateInstanceV3
    .get(`/learning-resources/${enrolledsEvaluataionId}/evaluations_detail`)
    .then((resp) => resp.data);
}

async function postMyEvaluations(
  enrolledId: string,
  enrolledsEvaluationId: string,
  enrolledsEvaluationDetails: EnrolledsEvaluationDetails[]
) {
  const data = {
    enrolledsEvaluationsId: enrolledsEvaluationId,
    answerData: enrolledsEvaluationDetails,
  } as EvaluationSubmission;
  return await privateInstanceV3
    .post(
      `progress-management/enrollments/${enrolledId}/evaluations/submit`,
      data
    )
    .then((resp) => resp.data);
}

export const evaluationService = {
  getMyEvaluations,
  postMyEvaluations,
};
