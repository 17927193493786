import { MessageManager, ChatContainer } from "@teamsparta/chat-ui";
import * as S from "./AiChat.style";
import React, { useState, useEffect } from "react";
import { config } from "./config";
import { useParams } from "react-router-dom";
import { RtanBanner } from "./CustomMessage/RtanBanner";
import { EndMessage } from "./CustomMessage/EndMessage";
import { StartMessage } from "./CustomMessage/StartMessage";
import {
  useSendLogChatbotClickButton,
  useSendLogChatbotPost,
} from "../../businesslogics/AiChat/useSendAiChatLog";

import {
  useGetAiMessage,
  getAiChatEventSource,
  getAiQuizEventSource,
  waitFlag,
  getAiQuizAnswerEventSource,
} from "../../queries/aichat";

const AiChat = ({ enrolledHomeworkId }) => {
  let isChatConnected = true;
  const messageManager = MessageManager.getInstance();
  const { enrolled_id, detail_id } = useParams();
  const [eventSource, setEventSource] = useState(null); // SSE 연결을 위한 EventSource 객체
  const [quiz, setQuiz] = useState(null);
  const [chattingMode, setChattingMode] = useState("chat");
  const { data: savedAiMessage } = useGetAiMessage(enrolled_id);
  const sendLogPostChat = useSendLogChatbotPost();
  const sendLogClickButton = useSendLogChatbotClickButton();

  // 챗봇 질문 목록 설정
  config.presetQuestions = [
    {
      title: (
        <span>
          🔥<S.GradationSpan>코딩 퀴즈 풀기</S.GradationSpan>
        </span>
      ),
      style: "highlight",
      handler: () => {
        playQuiz();
      },
    },
    {
      title: "강의 핵심 요약",
      handler: () => {
        requestSummary();
      },
    },
  ];

  const playQuiz = () => {
    // 퀴즈 플레이 버튼 클릭 시 호출되는 함수
    if (chattingMode === "quiz") {
      alert("퀴즈가 이미 진행중입니다.");
      return;
    }

    sendLogClickButton("퀴즈", "코딩 퀴즈 풀기");
    setChattingMode("quiz");
    messageManager.addUserMessage("퀴즈 풀기 도전!🔥");
    messageManager.addPeerMessage("르탄이가 퀴즈를 고르는 중입니다...");
    getAiQuizEventSource(
      enrolled_id,
      detail_id,
      enrolledHomeworkId,
      handleReceiveMessage,
      handleQuizEndMessage
    );
  };
  const requestSummary = async () => {
    if (chattingMode === "quiz") {
      alert("퀴즈가 이미 진행중입니다. 퀴즈의 정답을 입력해주세요.");
      return;
    }
    sendLogClickButton("질문", "강의 핵심 요약");
    const message = "강의 핵심만 요약해줘";
    messageManager.addUserMessage(message);
    messageManager.addPeerMessage("르탄이가 내용을 요약 중입니다. ");
    await sendAndReceiveAiChat(message);
  };

  const sendAndReceiveAiChat = async (message) => {
    // 챗봇에게 메세지를 보내고 답변을 받는 함수
    isChatConnected = true;
    const newEventSource = getAiChatEventSource(
      message,
      enrolled_id,
      detail_id,
      enrolledHomeworkId,
      handleReceiveMessage,
      handleEndMessage
    );
    setEventSource(newEventSource);

    await waitFlag(isChatConnected, 500); // 0.5초 마다 연결이 끊겼는지 확인하고 연결 중이라면 대기한다. -> 연결이 연결되어 있는 동안 채팅을 막기 위해서
  };
  const sendAiQuizAnswer = async (message) => {
    // 퀴즈 정답을 보내고 결과를 받는 함수
    isChatConnected = true;
    const newEventSource = getAiQuizAnswerEventSource(
      quiz,
      message,
      enrolled_id,
      detail_id,
      handleReceiveMessage,
      handleEndMessage
    );
    setEventSource(newEventSource);

    await waitFlag(isChatConnected, 500); // 0.5초 마다 연결이 끊겼는지 확인하고 연결 중이라면 대기한다. -> 연결이 연결되어 있는 동안 채팅을 막기 위해서
  };

  const handleReceiveMessage = (message) => {
    const newMessage = getSplitTextComponent(message);
    messageManager.streamPeerMessage(newMessage);
    setQuiz(message);
  };
  const handleEndMessage = () => {
    messageManager.addPeerMessage(<EndMessage />);
    isChatConnected = false;
  };
  const handleQuizEndMessage = () => {
    isChatConnected = false;
  };
  useEffect(() => {
    return () => {
      // 컴포넌트 언마운트 시 EventSource 연결 종료
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [eventSource]);
  useEffect(() => {
    // 저장된 메세지 목록를 이용해서 메세지를 그린다.
    if (!savedAiMessage) return;
    const initMessage = [
      {
        role: "custom",
        content: <RtanBanner />,
      },
    ];
    savedAiMessage?.map((item) => {
      const message = {
        role: item.role === "assistant" ? "peer" : item.role,
        content: item.content,
        profileSrc: "/assets/images/rtan/rtan_profile.png",
        date: new Date(),
      };
      initMessage.push(message);
    });
    const startMessage = {
      role: "peer",
      content: <StartMessage />,
      date: new Date(),
    };
    initMessage.push(startMessage);
    messageManager.initMessages(initMessage);
  }, [savedAiMessage]);

  const handleSend = async (message) => {
    //  메세지를 보낼 때 마다 호출되는 함수
    if (!message) return;
    if (chattingMode === "quiz") {
      messageManager.addUserMessage(message);
      messageManager.addPeerMessage("르탄봇이 정답을 확인하는 중이에요…");
      await sendAiQuizAnswer(message);
      setChattingMode("chat");
      sendLogPostChat("퀴즈");
    } else {
      messageManager.addUserMessage(message);
      messageManager.addPeerMessage("르탄봇이 답변 중이에요…");
      await sendAndReceiveAiChat(message);
      sendLogPostChat("질문");
    }
  };

  return (
    <S.Container>
      <ChatContainer config={config} handleSendMessage={handleSend} />
    </S.Container>
  );
};

export default AiChat;

const getSplitTextComponent = (text) => {
  const splitText = text.split("<br/>");
  return splitText.map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {index !== splitText.length - 1 && <br />}
    </React.Fragment>
  ));
};
