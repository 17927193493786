import { history } from "../../../_helpers";
import React from "react";

export const WelcomeMsg = (props) => {
  const {
    cx,
    isLoginPage,
    isPurchasing,
    checkedAccountExist,
    comkt_name,
    isSignupPage,
    comkt,
    isMobile,
    from,
    isPurchasingGovIntro,
  } = props;

  if (from === "quiz") {
    return <h3>1초 로그인하고 쿠폰 몽땅 받기</h3>;
  }
  if (history.location.search.includes("hyoneung")) {
    return (
      <h3>
        1초 만에 로그인하고
        <br />
        뚝배기 열어보기
      </h3>
    );
  }
  return (
    <>
      {isLoginPage && !isPurchasing && !checkedAccountExist && !comkt_name && (
        <h3>
          코딩의 세계에
          <br />
          오신 것을 환영합니다 😎
        </h3>
      )}

      {isLoginPage && !isPurchasing && checkedAccountExist && !comkt_name && (
        <h3>
          다시 만나 반가워요!
          <br />
          로그인을 진행합니다.
        </h3>
      )}

      {isLoginPage && !isPurchasing && !checkedAccountExist && comkt_name && (
        <h3>
          {comkt_name}
          <br />
          여러분을 환영합니다😎
        </h3>
      )}

      {isLoginPage && !isPurchasing && checkedAccountExist && comkt_name && (
        <h3>
          {comkt_name}
          <br />
          로그인을 진행합니다.
        </h3>
      )}

      {isLoginPage &&
        isPurchasing &&
        !checkedAccountExist &&
        comkt_name === undefined &&
        !isPurchasingGovIntro && (
          <h3>
            완주까지 함께하는
            <br />
            스파르타코딩클럽
          </h3>
        )}

      {isLoginPage &&
        isPurchasing &&
        !checkedAccountExist &&
        comkt_name === undefined &&
        isPurchasingGovIntro && (
          <h3>
            로그인 후
            <br />
            국비지원 안내가 시작됩니다 🌱
          </h3>
        )}

      {isLoginPage &&
        isPurchasing &&
        !checkedAccountExist &&
        comkt_name === "미니인턴" && (
          <h3>
            반갑습니다!
            <br />
            {comkt_name} 회원이시군요:-)
            <br />
            {comkt_name} 회원만을 위한 할인코드,
            <br /> 잊지마세요!
            <br />
            👉할인코드: miniintern
          </h3>
        )}

      {isLoginPage &&
        isPurchasing &&
        !checkedAccountExist &&
        comkt_name &&
        comkt_name !== "미니인턴" && (
          <h3>
            반갑습니다!
            <br />
            {comkt_name} 회원이시군요:-)
            <br />
            {comkt_name} 회원만을 위한 할인코드,
            <br /> 잊지마세요!
          </h3>
        )}

      {isLoginPage &&
        isPurchasing &&
        !checkedAccountExist &&
        comkt_name &&
        comkt_name !== "미니인턴" && (
          <h3>
            반갑습니다!
            <br />
            {comkt_name} 회원이시군요:-)
            <br />
            {comkt_name} 회원만을 위한 할인코드,
            <br /> 잊지마세요!
          </h3>
        )}

      {isLoginPage && isPurchasing && checkedAccountExist && (
        <h3>
          다시 만나 반가워요!
          <br />
          수강신청을 위한
          <br /> 로그인을 진행할게요.
        </h3>
      )}

      {isSignupPage && !isPurchasing && comkt_name && (
        <div className={cx("hanium-notice")}>
          <img
            className={cx("hanium-notice__img")}
            alt={"ict_logo"}
            src={`/assets/icons/ic_ict_mentoring.png`}
          />
          <h3>
            환영합니다!
            <br />
            ICT멘토링 멘티 여러분,
            <br />
            회원 가입을 진행해주세요.
            <br />
          </h3>
          <p className={cx("hanium-notice__p")}>
            이메일/연락처/이름이
            <br />
            한이음 사이트 가입 정보와 동일해야 합니다.
            <br />
            이미 등록하셨다면?
            <span
              className={cx("hanium-notice__p__span")}
              onClick={() => {
                window.location.href = "/login?next=classroom";
              }}
            >
              로그인 하러가기
            </span>
          </p>
        </div>
      )}
      {!comkt && isSignupPage && (
        <>
          <h3>반갑습니다!</h3>
          <p className={cx("new__signup__sub")}>
            완주까지 함께하는 스파르타코딩클럽
          </p>
        </>
      )}
    </>
  );
};
