export const ArrowDwon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.33203L10 13.332L15 8.33203"
        stroke="#5F666B"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowUp = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 12.5L10 7.5L5 12.5"
        stroke="#5F666B"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Antenna = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 4C14.1705 5.11433 14.6666 6.49955 14.6666 8.00065C14.6666 9.50175 14.1705 10.887 13.3333 12.0013M2.66659 4C1.82937 5.11433 1.33325 6.49955 1.33325 8.00065C1.33325 9.50175 1.82937 10.887 2.66659 12.0013"
        stroke="#E8344E"
        strokeWidth="1.16667"
        strokeLinecap="round"
      />
      <path
        d="M4.53513 6.00066C4.19479 6.589 4 7.27208 4 8.00066C4 8.72923 4.19479 9.41231 4.53513 10.0007M11.4649 10.0007C11.8052 9.41231 12 8.72923 12 8.00066C12 7.27208 11.8052 6.589 11.4649 6.00066"
        stroke="#E8344E"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="8.00066" r="2" fill="#E8344E" />
    </svg>
  );
};

export const Trophy = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.66284"
        y="4.91286"
        width="4.03352"
        height="12"
        transform="rotate(-28.4095 9.66284 4.91286)"
        fill="url(#paint0_linear_102_4784)"
      />
      <rect
        width="4.03352"
        height="12"
        transform="matrix(-0.873082 -0.487573 -0.487573 0.873082 38.3621 4.83105)"
        fill="url(#paint1_linear_102_4784)"
      />
      <rect
        width="4.03352"
        height="13.8634"
        transform="matrix(-0.437538 -0.8992 -0.8992 0.437538 46.5386 15.627)"
        fill="url(#paint2_linear_102_4784)"
      />
      <rect
        x="0.92334"
        y="15.627"
        width="4.03352"
        height="14.3914"
        transform="rotate(-64.0531 0.92334 15.627)"
        fill="url(#paint3_linear_102_4784)"
      />
      <rect
        x="22.1541"
        y="2.76923"
        width="4.03352"
        height="12"
        fill="url(#paint4_linear_102_4784)"
      />
      <path
        d="M32.4404 15.7325L33.5754 15.2797C35.7476 14.4132 38.217 15.4105 39.1781 17.5426V17.5426C40.136 19.6674 39.4151 22.1734 37.4745 23.4644L37.0434 23.7511C33.8272 25.8907 30.0504 27.0322 26.1875 27.0322V27.0322"
        stroke="url(#paint5_linear_102_4784)"
        strokeWidth="2.7021"
      />
      <path
        d="M15.7828 15.7325L14.6477 15.2797C12.4755 14.4132 10.0062 15.4105 9.04501 17.5426V17.5426C8.0871 19.6674 8.80804 22.1734 10.7487 23.4644L11.1797 23.7511C14.396 25.8907 18.1727 27.0322 22.0356 27.0322V27.0322"
        stroke="url(#paint6_linear_102_4784)"
        strokeWidth="2.7021"
      />
      <rect
        x="21.5312"
        y="25.5813"
        width="4.65624"
        height="10.8169"
        fill="url(#paint7_linear_102_4784)"
      />
      <g filter="url(#filter0_i_102_4784)">
        <path
          d="M14.7939 10.9846C13.7743 10.9846 12.9478 11.8112 12.9478 12.8308V16.6059C12.9478 23.0888 17.2578 30.8606 23.8596 30.8606C30.6778 30.8606 34.7714 23.0888 34.7714 16.6059V12.8308C34.7714 11.8112 33.9448 10.9846 32.9252 10.9846H14.7939Z"
          fill="url(#paint8_linear_102_4784)"
        />
      </g>
      <path
        d="M18.1229 34.4519C18.1799 34.1041 18.4804 33.8488 18.8328 33.8488H28.7799C29.1323 33.8488 29.4329 34.1041 29.4899 34.4519L30.9357 43.2762H16.677L18.1229 34.4519Z"
        fill="url(#paint9_linear_102_4784)"
      />
      <path
        d="M15.8391 43.6783C15.8391 42.974 16.4101 42.403 17.1144 42.403H30.5C31.2044 42.403 31.7753 42.974 31.7753 43.6783V43.6783C31.7753 44.3827 31.2044 44.9537 30.5 44.9537H17.1144C16.4101 44.9537 15.8391 44.3827 15.8391 43.6783V43.6783Z"
        fill="#7E4800"
      />
      <path
        d="M25.4169 15.0651H23.4133L21.1553 16.4752V18.3449L23.2125 17.0821H23.266V24.204H25.4169V15.0651Z"
        fill="url(#paint10_linear_102_4784)"
      />
      <defs>
        <filter
          id="filter0_i_102_4784"
          x="12.0247"
          y="8.21539"
          width="22.7468"
          height="22.6452"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.923077" dy="-2.76923" />
          <feGaussianBlur stdDeviation="2.76923" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_102_4784"
          />
        </filter>
        <linearGradient
          id="paint0_linear_102_4784"
          x1="11.6796"
          y1="4.91286"
          x2="11.6796"
          y2="16.9129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF1D9" stop-opacity="0" />
          <stop offset="1" stop-color="#FFEAC5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_102_4784"
          x1="2.01676"
          y1="0"
          x2="2.01676"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF1D9" stop-opacity="0" />
          <stop offset="1" stop-color="#FFEAC5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_102_4784"
          x1="2.01676"
          y1="0"
          x2="2.01676"
          y2="13.8634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF1D9" stop-opacity="0" />
          <stop offset="1" stop-color="#FFEAC5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_102_4784"
          x1="2.9401"
          y1="15.627"
          x2="2.9401"
          y2="30.0184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF1D9" stop-opacity="0" />
          <stop offset="1" stop-color="#FFEAC5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_102_4784"
          x1="24.1708"
          y1="2.76923"
          x2="24.1708"
          y2="14.7692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF1D9" stop-opacity="0" />
          <stop offset="1" stop-color="#FFEAC5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_102_4784"
          x1="33.5132"
          y1="13.7303"
          x2="33.5132"
          y2="27.0322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFAD3B" />
          <stop offset="1" stop-color="#BC7700" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_102_4784"
          x1="14.71"
          y1="13.7303"
          x2="14.71"
          y2="27.0322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFAD3B" />
          <stop offset="1" stop-color="#BC7700" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_102_4784"
          x1="23.8594"
          y1="29.4109"
          x2="23.8594"
          y2="34.5112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC86A" />
          <stop offset="1" stop-color="#FFA407" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_102_4784"
          x1="16.075"
          y1="9.56919"
          x2="31.356"
          y2="30.4855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF3DE" />
          <stop offset="1" stop-color="#FFA200" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_102_4784"
          x1="17.0475"
          y1="38.5625"
          x2="31.3381"
          y2="38.1592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BB8005" />
          <stop offset="0.21875" stop-color="#DCA32F" />
          <stop offset="1" stop-color="#9B5400" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_102_4784"
          x1="24.0752"
          y1="10.204"
          x2="24.0752"
          y2="29.204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFCB71" />
          <stop offset="1" stop-color="#D58600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ThumbDefault = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 12.433C10.5 11.1953 10.9917 10.0083 11.8668 9.13316L13.7323 7.26774C13.9096 7.0904 14.0572 6.88564 14.1693 6.66132L15.5024 3.99519C15.6619 3.6762 15.9329 3.42683 16.264 3.29438V3.29438C17.3181 2.87273 18.4279 3.76596 18.2413 4.88582L17.8581 7.1848C17.6706 8.3095 18.538 9.33333 19.6782 9.33333H22.1667C23.4553 9.33333 24.5 10.378 24.5 11.6667V17.4179C24.5 18.1981 24.1101 18.9266 23.461 19.3593L19.8378 21.7748C19.4545 22.0303 19.0042 22.1667 18.5435 22.1667H10.5V12.433Z"
        stroke="#B4BFC6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 10.5H10.5002V22.1667H5.8335V10.5Z"
        stroke="#B4BFC6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ThumbHover = () => {
  return (
    <svg
      width="28"
      height="43"
      viewBox="0 0 28 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 12.433C10.5 11.1953 10.9917 10.0083 11.8668 9.13317L13.7323 7.26775C13.9096 7.09041 14.0572 6.88564 14.1693 6.66133L15.5024 3.9952C15.6619 3.67621 15.9329 3.42684 16.264 3.29438V3.29438C17.3181 2.87274 18.4279 3.76597 18.2413 4.88583L17.8581 7.18481C17.6706 8.30951 18.538 9.33334 19.6782 9.33334H22.1667C23.4553 9.33334 24.5 10.378 24.5 11.6667V17.4179C24.5 18.1981 24.1101 18.9266 23.461 19.3594L19.8378 21.7748C19.4545 22.0303 19.0042 22.1667 18.5435 22.1667H10.5V12.433Z"
        stroke="#B4BFC6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 10.5H10.4999V22.1667H5.83325V10.5Z"
        stroke="#B4BFC6"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.67956 31.0442H7.5953V33.1954H5.82044V31.6761H1.6768V35.692H5.82044V34.0794H7.5953V36.9972H8.67956V31.0442ZM2.32597 38.9724L2.71616 39.8218C4.125 39.6077 5.20925 38.9344 5.75138 38.0366C6.2797 38.9448 7.3605 39.6077 8.77624 39.8218L9.20442 38.9724C7.41229 38.7203 6.31077 37.7396 6.31768 36.7003V36.424H5.20235V36.7003C5.19544 37.6948 4.05939 38.703 2.32597 38.9724ZM2.75414 34.8218V32.5601H4.75345V34.8218H2.75414ZM17.3429 31.0442H16.2517V33.4889H14.6806V34.3867H16.2517V35.3432H14.6806V36.241H16.2517V39.8633H17.3429V31.0442ZM9.84285 37.3943L10.5542 38.2196C11.5417 37.6775 12.2772 36.607 12.6536 35.3398C13.0507 36.4931 13.7828 37.5014 14.7462 37.9917L15.3919 37.1595C14.0141 36.4689 13.175 34.7735 13.1785 33.3888V32.7949H15.0431V31.9144H10.1813V32.7949H12.0701V33.3888C12.0666 34.9979 11.2379 36.7037 9.84285 37.3943ZM26.6484 37.9054H24.7872V35.6506C25.4709 35.2431 25.8853 34.6215 25.8853 33.8515C25.8853 32.4461 24.4972 31.5173 22.5945 31.5138C20.6919 31.5173 19.3142 32.4461 19.3176 33.8515C19.3142 34.6354 19.7389 35.2673 20.4433 35.6713V37.9054H18.5787V38.7963H26.6484V37.9054ZM20.3812 33.8515C20.3812 32.933 21.272 32.3771 22.5945 32.3805C23.9067 32.3771 24.8183 32.933 24.8183 33.8515C24.8183 34.7562 23.9067 35.3329 22.5945 35.3329C21.272 35.3329 20.3812 34.7562 20.3812 33.8515ZM21.5172 37.9054V36.0684C21.8521 36.1409 22.2113 36.1789 22.5945 36.1789C22.9847 36.1789 23.3508 36.1409 23.6892 36.0684V37.9054H21.5172Z"
        fill="#9DA7AE"
      />
    </svg>
  );
};

export const ThumbClicked = () => {
  return (
    <svg
      width="28"
      height="43"
      viewBox="0 0 28 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 12.433C10.5 11.1953 10.9917 10.0083 11.8668 9.13317L13.7323 7.26775C13.9096 7.09041 14.0572 6.88564 14.1693 6.66133L15.5024 3.9952C15.6619 3.67621 15.9329 3.42684 16.264 3.29438C17.3181 2.87274 18.4279 3.76597 18.2413 4.88583L17.8581 7.18481C17.6706 8.30951 18.538 9.33334 19.6782 9.33334H22.1667C23.4553 9.33334 24.5 10.378 24.5 11.6667V17.4179C24.5 18.1981 24.1101 18.9266 23.461 19.3594L19.8378 21.7748C19.4545 22.0303 19.0042 22.1667 18.5435 22.1667H10.5V12.433Z"
        fill="#FFD600"
        stroke="#FFD600"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 10.5H6.99992V22.1667H5.83325V10.5Z"
        fill="#FFD600"
        stroke="#FFD600"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.67956 31.0442H7.5953V33.1954H5.82044V31.6761H1.6768V35.692H5.82044V34.0794H7.5953V36.9972H8.67956V31.0442ZM2.32597 38.9724L2.71616 39.8218C4.125 39.6077 5.20925 38.9344 5.75138 38.0366C6.2797 38.9448 7.3605 39.6077 8.77624 39.8218L9.20442 38.9724C7.41229 38.7203 6.31077 37.7396 6.31768 36.7003V36.424H5.20235V36.7003C5.19544 37.6948 4.05939 38.703 2.32597 38.9724ZM2.75414 34.8218V32.5601H4.75345V34.8218H2.75414ZM17.3429 31.0442H16.2517V33.4889H14.6806V34.3867H16.2517V35.3432H14.6806V36.241H16.2517V39.8633H17.3429V31.0442ZM9.84285 37.3943L10.5542 38.2196C11.5417 37.6775 12.2772 36.607 12.6536 35.3398C13.0507 36.4931 13.7828 37.5014 14.7462 37.9917L15.3919 37.1595C14.0141 36.4689 13.175 34.7735 13.1785 33.3888V32.7949H15.0431V31.9144H10.1813V32.7949H12.0701V33.3888C12.0666 34.9979 11.2379 36.7037 9.84285 37.3943ZM26.6484 37.9054H24.7872V35.6506C25.4709 35.2431 25.8853 34.6215 25.8853 33.8515C25.8853 32.4461 24.4972 31.5173 22.5945 31.5138C20.6919 31.5173 19.3142 32.4461 19.3176 33.8515C19.3142 34.6354 19.7389 35.2673 20.4433 35.6713V37.9054H18.5787V38.7963H26.6484V37.9054ZM20.3812 33.8515C20.3812 32.933 21.272 32.3771 22.5945 32.3805C23.9067 32.3771 24.8183 32.933 24.8183 33.8515C24.8183 34.7562 23.9067 35.3329 22.5945 35.3329C21.272 35.3329 20.3812 34.7562 20.3812 33.8515ZM21.5172 37.9054V36.0684C21.8521 36.1409 22.2113 36.1789 22.5945 36.1789C22.9847 36.1789 23.3508 36.1409 23.6892 36.0684V37.9054H21.5172Z"
        fill="#FFD600"
      />
    </svg>
  );
};

export const CenterPoint = () => {
  return (
    <svg
      width="2"
      height="3"
      viewBox="0 0 2 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1" cy="1.5" r="1" fill="#B4BFC6" />
    </svg>
  );
};
