import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./ClassCardBtnBottom.module.scss";
import { connect } from "react-redux";
import axios from "../../../../../../_helpers/axios";
import { sendLog } from "../../../../../businesslogics/logging";

const cx = classNames.bind(styles);

function ClassCardBtnBottom({ course }) {
  const [isWaiting, setIsWaiting] = useState(false);

  const download_certi = (link, request_type) => {
    const { is_in_period } = course;
    const { _id: enrolled_id } = course.enrolled;
    if (is_in_period) {
      alert("수료증 다운로드는 기본 수강기간이 종료된 다음날부터 가능합니다.");
      return;
    }
    if (!is_in_period && (link === undefined || link === "")) {
      if (!isWaiting) {
        setIsWaiting(true);
        axios
          .post(
            "/api/cert",
            {
              enrolled_id,
              request_type,
            },
            {
              timeout: 3 * 60 * 1000,
              baseURL: "https://op.spartacodingclub.kr",
            }
          )
          .then((resp) => {
            window.location.href = resp.data.file_link;
          })
          .catch(
            // 수료증 생성 과정에서 오류가 발생하더라도, 일단 축하 환급 신청 모달을 보여준다.
            (error) => {
              // this.setState({
              //     cert_created: true,
              // })
            }
          )
          .finally(() => {
            setIsWaiting(false);
          });
      }
    } else {
      window.location.href = link;
    }
  };
  const go_linkedIn = (course_title, year, month, cert_id, file_link) => {
    window.open(
      `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${course_title}&organizationId=30234270&issueYear=${year}&issueMonth=${month}&certUrl=${file_link}&certId=${cert_id}`
    );
  };
  const hasTest = course.course?.has_completion_test;
  const passedTest = hasTest && course.enrolled?.has_passed_test;

  return (
    <div className={cx("certi_wrapper")}>
      {(!hasTest || passedTest) && course.done && (
        <div
          className={cx("certi_upload_wrapper")}
          onClick={(e) => {
            sendLog(
              "scc_mypagePage_click_button",
              {
                button_text: "수료증",
                course_title: course.course.title,
                enrolled_id: course.enrolled.enrolled_id,
                round: course.enrolled.round,
              },
              "",
              true
            );
            e.preventDefault();
            download_certi("", "new_cert");
          }}
        >
          <img src={`/assets/icons/ic_jpg.svg`} />
          <div className={cx("certi_upload")}>
            {isWaiting ? "생성중.." : "수료증"}
          </div>
        </div>
      )}
      {(!hasTest || passedTest) && course.milestone_done && (
        <div
          className={cx("certi_upload_wrapper")}
          onClick={(e) => {
            e.preventDefault();
            download_certi("", "nb_cert");
          }}
        >
          <img src={`/assets/icons/ic_jpg.svg`} />
          <div className={cx("certi_upload")}>
            {isWaiting ? "생성중.." : "NCS 수료증"}
          </div>
        </div>
      )}
      {!course.is_temp_package && !course.is_gov_trial && (
        <div
          className={cx("certi_upload_wrapper")}
          onClick={() => {
            sendLog(
              "scc_mypagePage_click_button",
              {
                button_text: "수강증",
                course_title: course.course.title,
                enrolled_id: course.enrolled.enrolled_id,
                round: course.enrolled.round,
              },
              "",
              true
            );
            download_certi(
              course.enrolled.file_link,
              course.is_gov ? "nb_confirm" : "confirm"
            );
          }}
        >
          <img src={`/assets/icons/ic_pdf.svg`} />
          <div className={cx("certi_upload")}>
            {isWaiting ? "생성중.." : "수강증"}
          </div>
        </div>
      )}

      {course.done && (
        <div
          className={cx("certi_download_wrapper")}
          onClick={() => {
            sendLog(
              "scc_mypagePage_click_button",
              {
                button_text: "링크드인 업로드",
                course_title: course.course.title,
                enrolled_id: course.enrolled.enrolled_id,
                round: course.enrolled.round,
              },
              "",
              true
            );
            go_linkedIn(
              course.course.title,
              course.enrolled.year,
              course.enrolled.month,
              course.cert_id,
              course.file_link
            );
          }}
        >
          <img src={`/assets/icons/icon_linkedin.png`} />
          <div className={cx("certi_download")}>링크드인 업로드</div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { environment } = state;
  return {
    environment,
  };
}

const connectedClassCardBtnBottom = connect(
  mapStateToProps,
  {}
)(ClassCardBtnBottom);
export { connectedClassCardBtnBottom as ClassCardBtnBottom };
