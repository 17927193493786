import React, { useRef, useState } from "react";
import { QuestionList } from "./Question";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

const getWidthRatio = (q) => {
  return (q.depth / 3) * 100 - 5;
};

export const NbQuizPage = () => {
  const [index, setIndex] = useState(0);
  const [prevIndexList, setPrevIndexList] = useState([]);
  const [nextIndex, setNextIndex] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const result = location.state?.result;
  const [isValid, setIsValid] = useState(false);

  if (isValid === false) {
    if (result !== true) {
      alert("잘못된 접근입니다.");
      history.replace("/mypage");
    } else {
      history.replace(location.pathname, null);
      setIsValid(true);
    }
  }

  const q = QuestionList[index];
  const widthRatio = getWidthRatio(q);
  const click = (e) => {
    let index = e.target.dataset.index;
    if (index !== "gift") {
      index = Number(index);
    }
    setNextIndex(index);
  };
  const checkList = useRef(null);

  const getNext = () => {
    if (q.type === "check") {
      const checkedList = [];
      checkList.current.childNodes.forEach((elem) => {
        const checkBox = elem.querySelector("div");
        if (elem.querySelector("div").classList.contains("checked")) {
          checkedList.push(checkBox.dataset.value);
        }
      });
      if (checkedList.length === 0) {
        alert("선택해주세요");
      } else {
        // const result = checkedList.reduce((a, b) => (a > b ? a : b));
        history.replace("/nb/result", { result: true, hasCard: false });
      }
    } else if (nextIndex === "gift") {
      history.replace("/nb/gift", { result: true });
    } else if (nextIndex !== null) {
      setPrevIndexList([...prevIndexList, index]);
      setIndex(nextIndex);
    } else {
      alert("선택해주세요");
    }
  };

  const getPrev = () => {
    if (prevIndexList.length !== 0) {
      const newPrev = [...prevIndexList];
      const newCur = newPrev.pop();
      setIndex(newCur);
      setPrevIndexList(newPrev);
      setNextIndex(null);
    }
  };
  const isTitleShow = index === 0;
  return (
    <Wrapper className={"selection-wrapper"}>
      <ProgressBar
        widthRatio={widthRatio}
        isLast={q.isLast}
        title={isTitleShow ? <H1>단 10초만에 간편 조회하기</H1> : <></>}
      />
      <Header>
        {q.question}
        {q.isLast && (
          <span>
            {" "}
            <br /> *다중 선택 가능
          </span>
        )}
      </Header>
      {q.type === "check" ? (
        <CheckBoxContainer selections={q.selections} checkList={checkList} />
      ) : (
        <ButtonContainer
          selections={q.selections}
          click={click}
          btnSize={q.btnSize}
        />
      )}
      {q.selections.map((selection, idx) => (
        <div key={selection.descTitle + "selection" + idx}>
          {selection.descTitle && (
            <DescWrapper
              descTitle={selection.descTitle}
              descList={selection.descList}
            />
          )}
        </div>
      ))}
      <FloatingWrapper>
        <WhiteBtn onClick={getPrev}>이전</WhiteBtn>
        <GreenBtn onClick={getNext}>
          {q.isLast ? "결과 보러가기" : "다음"}
        </GreenBtn>
      </FloatingWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 80px;
`;

const H1 = styled.div`
  margin-bottom: 13px;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.05px;
  text-align: center;
  color: #000;
`;

const DescWrapper = ({ descTitle, descList }) => {
  return (
    <Div>
      <Title>
        <img src={"/v2/assets/images/InfoIcon.svg"} alt="" />
        {descTitle}
      </Title>
      <ContentList>
        {descList.map((item, idx) => (
          <ContentItem key={item + idx}>{item}</ContentItem>
        ))}
      </ContentList>
    </Div>
  );
};

const Div = styled.div`
  width: 328px;
  margin: auto;
  margin-top: 16px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 16px 16px 8px;
  background-color: #eef9d9;
`;
const Title = styled.p`
  display: flex;
  align-items: end;
  margin: 0;
  font-family: "Pretendard";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.02px;
  text-align: left;
  color: #2f3031;
`;

const ContentList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 20px;
  width: 296px;
  margin: auto;
`;

const ContentItem = styled.li`
  width: 280px;
  font-family: "Pretendard";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.02px;
  text-align: left;
  color: #2f3031;
`;

const GreenBtn = styled.div`
  cursor: pointer;
  width: 156px;
  height: 52px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  border-radius: 8px;
  background-color: #263628;

  font-family: "Pretendard";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fff;

  flex-grow: unset;
`;

const WhiteBtn = styled.div`
  cursor: pointer;
  width: 156px;
  height: 52px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  border-radius: 8px;
  border: solid 1px #eaebed;
  background-color: #fff;

  font-family: "Pretendard";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(38, 54, 40, 0.6);

  flex-grow: unset;
`;

const FloatingWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #fff 17%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ProgressBar = ({ widthRatio, isLast, title }) => {
  const Inner = () => (
    <div style={{ width: `${widthRatio}%` }} className="inner" />
  );
  return (
    <ProgressWrapper>
      {title}
      <Progress className={"progress-bar"}>
        {isLast && <ComingSoon src={"/v2/assets/images/coming_soon.png"} />}
        <Inner />
      </Progress>
    </ProgressWrapper>
  );
};

const ProgressWrapper = styled.div`
  margin-top: 61px;
`;

const Progress = styled.div`
  border-radius: 24px;
  background-color: #eaebed;
  width: 328px;
  margin: auto;
  height: 8px;
  position: relative;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 24px;
    background-color: #8bc34a;
  }
`;

const ComingSoon = styled.img`
  position: absolute;
  right: 0;
  bottom: 18px;
  width: 148px;
`;

const Container = styled.div`
  margin: auto;
  width: 328px;
  display: flex;
  flex-direction: column;
  align-item: center;
  div {
    margin-bottom: 12px;
  }
`;
const ButtonContainer = ({ selections, click, btnSize }) => {
  const toggleActive = (e) => {
    if (e.currentTarget.classList.contains("btn-container")) {
      e.currentTarget.childNodes.forEach((child) => {
        child.classList.remove("active");
      });
    }

    if (e.target.classList.contains("btn")) {
      e.target.classList.add("active");
    }
  };

  return (
    <Container className={"btn-container"} onClick={toggleActive}>
      {selections.map((selection, idx) => (
        <GrayBtn
          short={btnSize === "short"}
          key={selection.text + String(idx)}
          onClick={click}
          data-index={selection.nextIndex}
          className={"btn"}
        >
          {selection.text}
        </GrayBtn>
      ))}
    </Container>
  );
};

const GrayBtn = styled.div`
  cursor: pointer;
  width: 328px;
  align-self: stretch;
  ${({ short }) => (short ? `height: 52px;` : `height: 80px;`)}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #edefea;

  flex-grow: 0;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #263628;
  &.active {
    background-color: #c9ed84;
  }
`;

const Container2 = styled.div`
  margin: auto;
  width: 328px;
  display: flex;
  flex-direction: column;
  align-item: center;
`;

const CheckList = styled.div`
  width: 328px;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 12px 0;
  border-radius: 8px;
  span {
    white-space: nowrap;
  }
`;

const CheckBoxContainer = ({ selections, checkList }) => {
  return (
    <Container2 className={"btn-container"} ref={checkList}>
      {selections.map((selection, idx) => (
        <CheckList className={"check-list"} key={idx + "checkbox"}>
          <CheckBox value={selection.value} />
          <span>{selection.text}</span>
        </CheckList>
      ))}
    </Container2>
  );
};

const CheckBox = ({ value }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={active ? "checked" : "unChecked"}
      onClick={(e) => {
        setActive(!active);
      }}
      data-value={value}
    >
      {active ? (
        <img src={"/v2/assets/images/Checkbox-c.svg"} alt="" />
      ) : (
        <img src={"/v2/assets/images/Checkbox-u.svg"} alt="" />
      )}
    </div>
  );
};

const Header = styled.h2`
  font-family: "Pretendard";
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.04px;
  color: #000;
  text-align: left;
  width: 328px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;

  span {
    width: 93px;
    height: 18px;
    flex-grow: 0;
    font-family: "Pretendard";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.06px;
    text-align: center;
    color: #263628;
  }
`;
