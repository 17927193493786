import privateInstance from "../../_helper/axios";
import axios from "../../../_helpers/axios"
import { useMutation } from "react-query";
import { useEffect } from "react";
import { sendCustomDataLog } from "../../businesslogics/logging";
import { useSelector } from "react-redux";

export const useLogFinishPageView = () => {
  const enrolled = useSelector((state) => state.enrolled);

  useEffect(() => {
    if (enrolled.loaded) {
      sendCustomDataLog("scc_courseRcmdPage_view", {
        course_title: enrolled.data?.courses.title,
        course_id: enrolled.data?.courses._id,
      });
    }
  }, [enrolled.loaded]);
};

export const useIssueCoupons = (setToastMessage, setIsShowToast) => {
  const popToast = (message) => {
    setToastMessage(message);
    setIsShowToast(true);
    setTimeout(() => {
      setIsShowToast(false);
    }, 3000);
  };

  return useMutation(
    `issueCoupon`,
    ({ userId, couponIds }) => {
      return Promise.all(
        couponIds.map(async (id) => {
          return await privateInstance.post(`/coupon/`, {
            user_id: userId,
            coupon_recipe_id: id,
            expire_on_midnight: true,
          });
        })
      );
    },
    {
      onSuccess: (results) => {
        const getMessage = () => {
          if (results.some((result) => result.data.ok)) {
            return "쿠폰이 마이페이지에 발급되었어요.";
          } else {
            return (
              results.find((result) => !!result.data?.context)?.data?.context ||
              "쿠폰 발급에 실패했습니다."
            );
          }
        };
        popToast(getMessage());
      },
      onError: (error) => {
        popToast(
          error.isSkipXHR
            ? "이미 발급받은 쿠폰입니다."
            : "쿠폰 발급에 실패했습니다."
        );
      },
    }
  );
};
export const useFinish = (enrolled_id) => {
  const finish = useRequestFinish();

  useEffect(() => {
    finish.mutate({ enrolled_id: enrolled_id });
  },[]);
};

async function requestFinish(enrolled_id) {
  return await axios
      .post(`/enrolleds/finish`, enrolled_id)
      .then((resp) => resp.data);
}
export const useRequestFinish = () => {
  return useMutation(
      `finish`,
      async (enrolled_id) => {
        await requestFinish(enrolled_id);
      },
      {
        onSuccess: () => {
        },
        onError: () => {
          console.log("finish error");
        },
      }
  );
};

