import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiLectureInfoUrl = (round_id, lecture_id) => {
  return `${baseUrl}/rounds/${round_id}/lectures/${lecture_id}`;
};

export const getApiLectureDoneUrl = (round_id, lecture_id, user_id) => {
  return `${config.apiUrl}/v1/rounds/${round_id}/lectures/${lecture_id}/done?user_id=${user_id}`;
};
