import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./RtannyCharacter.modules.scss";
import { connect } from "react-redux";
import { UserRaceInfoCard } from "../../../../../components/organisms/UserRaceInfoCard";

const cx = classNames.bind(styles);

function RtannyCharacter({
  progress,
  weekly_width,
  divWidth,
  WeeksAfterStart,
}) {
  const [isHover, setIsHover] = useState(false);
  const {
    is_finished,
    week_done_rate,
    at_week,
    user_name,
    course_title,
    week_done,
  } = progress;
  const weekly_progress_rate = week_done_rate * 0.01;
  const rtan_width = divWidth * 0.05;
  const initial_position = 30 - rtan_width / 2;
  let x_position;
  if (at_week < 2 && week_done_rate === 0) {
    x_position = initial_position;
  } else {
    x_position =
      weekly_width * (at_week - 1) +
      weekly_width * weekly_progress_rate +
      30 -
      rtan_width / 2;
  }
  const style = {
    "--initialX": `${initial_position}px`,
    "--moveX": `${x_position}px`,
    transform: `translate(var(--moveX), 0px)`,
    width: `${rtan_width}px`,
  };

  return (
    <div className={cx("RtannyCharacterContainer")} style={style}>
      <div className={cx("UserInfoContainer")}>
        {isHover && (
          <UserRaceInfoCard
            is_finished={is_finished}
            course_title={course_title}
            user_name={user_name}
            week_done={week_done}
            at_week={at_week}
          />
        )}
      </div>
      {is_finished ? (
        <img
          src={`/assets/images/codingrace/happy_rtan.gif`}
          alt="happy_rtan"
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        />
      ) : at_week >= WeeksAfterStart.data.context ? (
        <img
          src={`/assets/images/codingrace/running_rtanny.gif`}
          alt="running_rtan"
          style={{ transform: "scaleX(-1)" }}
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        />
      ) : (
        <img
          src={`/assets/images/codingrace/rtan_dead@3x.png`}
          alt="dead_rtan"
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { WeeksAfterStart } = state;
  return { WeeksAfterStart };
}

const connectedRtannyCharacter = connect(mapStateToProps, {})(RtannyCharacter);

export { connectedRtannyCharacter as RtannyCharacter };
