import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./AccountEditPage.module.scss";
import classNames from "classnames/bind";
import { NavContainer } from "../../_containers";
import { Button, Form, Input, Modal } from "antd";
import { history, params } from "../../_helpers";
import { getApiAccountInfo } from "../../_urls/accounts.api";
import { getPutOptions } from "../../_urls";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);
const validateMessages = {
  required: "필수 입력창입니다.",
  types: {
    email: "이메일 양식을 확인해주세요.",
    phone: "전화번호 양식을 확인해주세요.",
    password: "비밀번호는 최대 6자 이상이어야 합니다.",
  },
};

class AccountEditPage extends Component {
  formRef = React.createRef();

  state = {
    checkedAccountExist: false,
    isLoading: false,
    alert_msg_title: "",
    modalVisible: false,
    redirect_url: "",
    alert_msg: "",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if ("email" in params()) {
      this.formRef.current.setFieldsValue({
        email: params().email,
      });
    }
  }

  _requestEdit = (values) => {
    const { email } = values;
    let endpoint = getApiAccountInfo(email);
    let options = getPutOptions();
    this.setState({
      isLoading: true,
    });
    fetch(endpoint, options)
      .then((resp) => {
        if (resp.status !== 200) {
          console.log(resp);
          this.setState({
            modalVisible: true,
            alert_msg_title: "잘못된 요청",
            alert_msg: `고객 정보가 존재하지 않습니다.\n올바른 이메일 주소를 입력해주세요.`,
            isLoading: false,
          });
          return Promise.reject("No result");
        }
        return resp.json();
      })
      .then((result) => {
        if (result.ok) {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alert_msg_title: "링크 발송 완료",
            alert_msg: `${result.msg}`,
          });
        }
      });
  };

  render() {
    const {
      isLoading,
      alert_msg_title,
      modalVisible,
      redirect_url,
      alert_msg,
    } = this.state;
    return (
      <div>
        <div className={cx("wrapper")}>
          <NavContainer />
          <div className={cx("pageContainer")}>
            <div className={cx("leftContainer")}>
              <div className={cx("contentWrapper")}>
                <h3>비밀번호 재설정</h3>
                <div className={cx("formWrapper")}>
                  <Form
                    name="editAccountInfo"
                    onFinish={this._requestEdit}
                    onFinishFailed={this.onFinishFailed}
                    validateMessages={validateMessages}
                    ref={this.formRef}
                  >
                    <Form.Item
                      label="e-mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          type: "email",
                        },
                      ]}
                    >
                      <Input
                        onChange={() =>
                          this.setState({ checkedAccountExist: false })
                        }
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        비밀번호 변경 요청하기
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className={cx("rightContainer")}></div>
          </div>
          {modalVisible && (
            <Modal
              title={alert_msg_title}
              visible={modalVisible}
              closable={true}
              footer={[
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => {
                    this.setState({ modalVisible: false });
                    history.push("/login?next=classroom");
                  }}
                >
                  확인
                </Button>,
              ]}
            >
              <p>{alert_msg}</p>
            </Modal>
          )}
        </div>
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedAccountEditPage = connect(mapStateToProps)(AccountEditPage);
export { connectedAccountEditPage as AccountEditPage };
