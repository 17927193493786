import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { govAction } from "../../_state/actions/gov.actions";
import { NBCampAttendRecordPageTemplate } from "./NBCampAttendRecordPageTemplate";
import { RecordPageHeader } from "./RecordPageHeader";
import { RecordContent } from "./RecordContent";
import { RecordCheckBtn } from "./RecordCheckBtn";
import {
  ALLOWED_HOUR,
  calcStudiedSeconds,
  checkAllowed,
  GovAttendRecordType,
  isStudyingNow,
  setActionDisableByRecentRecord,
} from "./NBCampAttendRecordPage.logics";
import { useInterval } from "../../hooks/useInterval";
import { useTotalStudyTime } from "../../hooks/nbcamp/useGovDailyRecord";
import { getCookie } from "../../_helper";
import { VerificationModal } from "../../components/organisms/Modal/VerificationModal/VerificationModal";

/**
 * [x] 1. 0-6시 접근 불가
 * [x] 2. 매일 본인인증
 * [x] 3. 입실, 외출, 복귀, 퇴실 기록 남기기
 * [x] 4. 누적 공부 시간을 볼 수 있어야 함.
 * [x] 5. 로그인 풀리는 경우 로그인 페이지로 이동
 */

function NBCampAttendRecordPage({
  environment,
  user,
  govAttendRecords,
  getGovAttendRecords,
  postGovAttendRecords,
}) {
  const [lastRecord, setLastRecord] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [lastCheckedDatetime, setLastCheckedDatetime] = useState(null);

  const studyTime = useTotalStudyTime(user);

  function checkLoginStatus() {
    const loginStatus = getCookie("userinfo");

    if (!loginStatus) {
      alert("로그인 후 다시 시도해주세요!");
      window.location.href = "/login?next=/nbcamp/records";
      return false;
    }

    return true;
  }

  useEffect(() => {
    // 접속 기기 검증
    const { deviceType } = environment;
    const width = environment?.width || 0;
    if (width < 1000 && deviceType !== "pc") {
      alert("PC에서만 접근 가능합니다.");
      window.location.href = "/classroom";
      return;
    }

    // 접속 시간 검증
    const hour = new Date().getHours();
    if (hour < ALLOWED_HOUR) {
      alert("지금은 접속하실 수 없습니다.");
      window.location.href = "/classroom";
      return;
    }

    getGovAttendRecords();
  }, []);

  useEffect(() => {
    if (!govAttendRecords.loaded) {
      return;
    }
    const lastCheck = govAttendRecords.data
      .filter((record) => record.rtype === GovAttendRecordType.CHECK)
      .slice(-1)[0];
    setLastCheckedDatetime(lastCheck?.created_at);
    setLastRecord(govAttendRecords.data.slice(-1)[0]);
    setSeconds(calcStudiedSeconds(govAttendRecords.data));
  }, [govAttendRecords.loaded]);

  function createAttendRecord({ rtype, reason }) {
    postGovAttendRecords({ rtype, reason });
  }

  useInterval(() => {
    if (isStudyingNow(lastRecord)) {
      setSeconds(calcStudiedSeconds(govAttendRecords.data));
    }
  }, 1000);

  useInterval(() => {
    // 접속 시간 검증
    const hour = new Date().getHours();
    if (hour < ALLOWED_HOUR) {
      window.location.href = "/classroom";
    }
  }, 1000);
  const isFeatureOpened = true;

  return (
    <div>
      <VerificationModal />
      {user && govAttendRecords.loaded && (
        <>
          <NBCampAttendRecordPageTemplate
            isFeatureOpened={isFeatureOpened}
            header={
              <RecordPageHeader
                mainText={
                  <div>
                    {new Date().getMonth() + 1}월 {new Date().getDate()}일 현재{" "}
                    {SecondsToKorean(seconds)} 공부{" "}
                    {lastRecord &&
                    [
                      GovAttendRecordType.ENTER,
                      GovAttendRecordType.COMEBACK,
                      GovAttendRecordType.CHECK,
                    ].includes(lastRecord.rtype)
                      ? "중"
                      : "완료"}
                    !
                  </div>
                }
                totalTime={
                  <>누적 공부시간: {SecondsToKorean(seconds + studyTime)}</>
                }
                subText={`${user.name}님 열공 중이신가요? 오늘도 화이팅이에요!`}
              />
            }
            content={
              <RecordContent
                actions={[
                  {
                    mainText: "입실",
                    subText: "오늘 공부 시작!",
                    onClick: () => {
                      if (!checkLoginStatus()) return;
                      createAttendRecord({
                        rtype: GovAttendRecordType.ENTER,
                        reason: "",
                      });
                      alert("입실이 완료되었습니다.");
                    },
                    disable: setActionDisableByRecentRecord(
                      lastRecord,
                      GovAttendRecordType.ENTER
                    ),
                  },
                  {
                    mainText: "외출",
                    subText: "잠시 외출할게요 =)",
                    onClick: () => {
                      if (!checkLoginStatus()) return;
                      let reason;
                      do {
                        reason = window.prompt("외출 사유를 입력해주세요.");
                      } while (!reason);
                      createAttendRecord({
                        rtype: GovAttendRecordType.PAUSE,
                        reason: reason,
                      });
                      alert("외출이 완료되었습니다.");
                    },
                    disable: setActionDisableByRecentRecord(
                      lastRecord,
                      GovAttendRecordType.PAUSE
                    ),
                  },
                  {
                    mainText: "복귀",
                    subText: "돌아왔어요!",
                    onClick: () => {
                      if (!checkLoginStatus()) return;
                      createAttendRecord({
                        rtype: GovAttendRecordType.COMEBACK,
                        reason: "",
                      });
                      alert("복귀가 완료되었습니다.");
                    },
                    disable: setActionDisableByRecentRecord(
                      lastRecord,
                      GovAttendRecordType.COMEBACK
                    ),
                  },
                  {
                    mainText: "퇴실",
                    subText: "오늘 공부 종료!",
                    onClick: () => {
                      if (!checkLoginStatus()) return;
                      if (
                        !window.confirm(
                          "퇴실 후에는 공부 시간을 측정할 수 없습니다.\n정말 퇴실하시겠어요?"
                        )
                      ) {
                        return;
                      }
                      createAttendRecord({
                        rtype: GovAttendRecordType.TERMINATE,
                        reason: "",
                      });
                      alert("퇴실이 완료되었습니다.");
                    },
                    disable: setActionDisableByRecentRecord(
                      lastRecord,
                      GovAttendRecordType.TERMINATE
                    ),
                  },
                ]}
              />
            }
            checkBtn={
              <RecordCheckBtn
                btn={{
                  text: "열공 중 인증하기",
                  onClick: () => {
                    createAttendRecord({
                      rtype: GovAttendRecordType.CHECK,
                      reason: new Date().toISOString(),
                    });
                    alert("중간점검이 완료되었습니다.");
                  },
                  disable: !(
                    checkAllowed(lastCheckedDatetime) &&
                    isStudyingNow(lastRecord)
                  ),
                }}
              />
            }
          />
        </>
      )}
    </div>
  );
}

export function SecondsToKorean(seconds) {
  const HOUR = 60 * 60;
  const MINUTE = 60;
  const hours = Math.floor(seconds / HOUR);
  const minutes = Math.floor((seconds - hours * HOUR) / MINUTE);
  const secs = seconds - hours * HOUR - minutes * MINUTE;
  return (
    <span>
      <span style={{ color: "#e8344e" }}>{hours}</span>
      시간
      <span style={{ color: "#e8344e" }}>{minutes}</span>분
      <span style={{ color: "#e8344e" }}>{secs}</span>초
    </span>
  );
}

function mapStateToProps(state) {
  const { environment, authentication, hasDoneDailyAuth, govAttendRecords } =
    state;
  const { user } = authentication;
  return {
    environment,
    authentication,
    user,
    hasDoneDailyAuth,
    govAttendRecords,
  };
}

const connectedNBCampAttendRecordPage = connect(mapStateToProps, {
  getGovAttendRecords: govAction.getGovAttendRecords,
  postGovAttendRecords: govAction.postGovAttendRecords,
})(NBCampAttendRecordPage);
export { connectedNBCampAttendRecordPage as NBCampAttendRecordPage };
