import * as S from "./HomeworkFeedbackCard.style";
import { useEffect, useState } from "react";
import {
  CheckIcon,
  ConnectionIcon,
  DoneFeedbackCheckIcon,
} from "./HomeworkFeedbackCard.svg";
import { ArrowDropDownLine, ArrowDropUpLine } from "@teamsparta/design-system";
import { StatusEnum } from "../../../../_state/constants";
import { useLogFeedbackPageClick } from "../../../../hooks/homework/useLogHomeworkClick";
import { useModalAndBottomSheet } from "../../../organisms/Modal/ModalBottomSheetWrapper";
import { NavigationClose } from "@teamsparta/design-system";
import Linkify from "react-linkify";

export const HomeworkFeedbackCard = ({ homework }) => {
  const [statusName, setStatusName] = useState("미제출");
  const [Wrapper, toggle] = useModalAndBottomSheet();

  const handleAnswerClick = () => {
    if (homework.file_name !== null) {
      window.open(homework.submitted_answer, "_blank");
    } else {
      toggle();
    }
  };

  useEffect(() => {
    switch (homework.feedback_status) {
      case StatusEnum.SUBMITTED:
        setStatusName("검토 대기");
        break;
      case StatusEnum.ACCEPTED:
        setStatusName("피드백 대기");
        break;
      case StatusEnum.REJECTED:
        setStatusName("검토 반려");
        break;
      case StatusEnum.RESOLVED:
        setStatusName("피드백 완료");
        break;
    }
  }, [homework.feedback_status]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function replaceNewLinesWithBreaks(str) {
    return str.split("\n").map((text, index, array) =>
      index === array.length - 1 ? (
        text
      ) : (
        <>
          {text}
          <br />
        </>
      )
    );
  }
  const logFeedbackButtonClick = useLogFeedbackPageClick();

  return (
    <S.HomeworkContainer status={statusName}>
      <S.HomeworkDataWrapper>
        {statusName !== "미제출" && (
          <S.StatusTagWrapper>
            <S.StatusTag status={statusName}>
              {homework?.feedback_status === StatusEnum.RESOLVED && (
                <DoneFeedbackCheckIcon />
              )}
              {statusName}
            </S.StatusTag>
          </S.StatusTagWrapper>
        )}
        <S.HomeworkTitleWrapper>
          <S.HomeworkTitle>{homework.homework.title}</S.HomeworkTitle>
          {statusName !== "미제출" && (
            <S.SubmissionData>
              {homework.submitted_at.toString().slice(2, 4)}.
              {homework.submitted_at.toString().slice(5, 7)}.
              {homework.submitted_at.toString().slice(8, 10)} 제출
              <S.SubmissionContents onClick={handleAnswerClick}>
                답안 보기
              </S.SubmissionContents>
            </S.SubmissionData>
          )}
        </S.HomeworkTitleWrapper>
      </S.HomeworkDataWrapper>

      {homework?.feedback_status === StatusEnum.RESOLVED && (
        <>
          <S.Devider />
          <S.DoneFeedbackContainer>
            <S.FeedbackWrapper>
              <S.HomeworkTitle>
                <ConnectionIcon />
                튜터 피드백 작성 완료
              </S.HomeworkTitle>
              {/*튜터 피드백 작성 일자 데이터 없음*/}
              {/*<S.FeedbackDate>{homework.updated_at}</S.FeedbackDate>*/}
              <CheckIcon />
            </S.FeedbackWrapper>
            <S.DropdownIconWrapper
              onClick={() => {
                const buttonName = isDropdownOpen ? " 토글 닫기" : "토글 열기";
                logFeedbackButtonClick(buttonName);
                setIsDropdownOpen(!isDropdownOpen);
              }}
            >
              {isDropdownOpen ? (
                <ArrowDropUpLine size={16} />
              ) : (
                <ArrowDropDownLine size={16} />
              )}
            </S.DropdownIconWrapper>
          </S.DoneFeedbackContainer>
          {isDropdownOpen && (
            <S.FeedbackContent>
              {homework?.feedback !== false
                ? replaceNewLinesWithBreaks(homework?.feedback)
                : "작성된 피드백이 없습니다."}
            </S.FeedbackContent>
          )}
        </>
      )}
      <Wrapper>
        <SubmittedHomeworkAnswer
          onClickClose={() => toggle()}
          submittedAnswer={homework.submitted_answer}
        />
      </Wrapper>
    </S.HomeworkContainer>
  );
};

const SubmittedHomeworkAnswer = ({ onClickClose, submittedAnswer }) => {
  const handleToggleClick = () => {
    onClickClose();
  };

  const linkDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      style={{ color: "#0085FF" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  return (
    <S.SubmittedHomeworkContentWrapper>
      <S.SubmittedHomeworkContentCloseButton onClick={handleToggleClick}>
        <NavigationClose size={24} style={"fill"} color={"#9da7ae"} />
      </S.SubmittedHomeworkContentCloseButton>
      <S.SubmittedHomeworkContentTitle>
        제출 답안 보기
      </S.SubmittedHomeworkContentTitle>
      <S.SubmittedHomeworkContentArea>
        <Linkify componentDecorator={linkDecorator}>{submittedAnswer}</Linkify>
      </S.SubmittedHomeworkContentArea>
    </S.SubmittedHomeworkContentWrapper>
  );
};
