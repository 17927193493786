import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./CodingRacePage.module.scss";
import { connect } from "react-redux";
import { enrolledActions, UserRaceGroupActions } from "../../_state/actions";
import { roundsActions } from "../../_state/actions/rounds.actions";
import { CodingRaceSky } from "./CodingRaceSky/CodingRaceSky";
import { CodingRaceGround } from "./CodingRaceGround/CodingRaceGround";
import { Gnb } from "../../components/organisms/_layout/gnb/Gnb";
import { sendLog } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

function CodingRacePage({
  round,
  enrolled,
  setRound,
  setEnrolled,
  match,
  getUserRankGroupInfo,
  createRankGroup,
  isRankGroupCreated,
  getIsRankGroupCreated,
  getWeeksAfterStart,
  UserRaceGroupInfo,
}) {
  const { enrolled_id, round_id } = match.params;
  const isGovCredit = round.data?.is_gov_credit;
  const hasSeenOrientation = enrolled.data?.has_seen_orientation;
  const course_id = enrolled.data?.course_id;
  const needToSeeOrientation =
    isGovCredit && round.data?.has_orientation && !hasSeenOrientation;

  useEffect(() => {
    if (enrolled.loaded && round.loaded) {
      sendLog("scc_rankPage_view", { ...enrolled.data }, "");
    }
  }, [enrolled.loaded && round.loaded]);

  useEffect(() => {
    setRound(round_id);
    setEnrolled(enrolled_id);
    getIsRankGroupCreated(round_id);
    getUserRankGroupInfo(enrolled_id);
    getWeeksAfterStart(enrolled_id);
  }, [match.params.enrolled_id]);

  useEffect(() => {
    if (isRankGroupCreated.loaded && !isRankGroupCreated.data.context) {
      createRankGroup(round_id);
      window.location.reload();
    }
  }, [isRankGroupCreated.loaded]);

  const today = new Date();
  const current_hour = today.getHours();
  return (
    <>
      {enrolled.loaded &&
        round.loaded &&
        isRankGroupCreated.loaded &&
        isRankGroupCreated.data.context && (
          <>
            <Gnb />
            <div className={cx("CodingRaceMainContainer")}>
              <div className={cx("CodingRaceMainContainerBg")}>
                {current_hour >= 6 && current_hour < 18 ? (
                  <div className={cx("CodingRaceMainContainerBgSky__Day")} />
                ) : (
                  <div className={cx("CodingRaceMainContainerBgSky__Night")} />
                )}
                <div className={cx("CodingRaceMainContainerBgGround")} />
              </div>
              <div className={cx("CodingRaceMainContainerContent")}>
                <CodingRaceSky
                  enrolled_id={enrolled_id}
                  round_id={round_id}
                  current_hour={current_hour}
                  course_id={course_id}
                  needToSeeOrientation={needToSeeOrientation}
                  enrolled={enrolled}
                />
                <CodingRaceGround current_hour={current_hour} />
              </div>
            </div>
            {/*{(UserRaceGroupInfo.loaded && UserRaceGroupInfo.data.context.length > 17) ? <div className={cx('CodingRaceUnderground')}/> : <></>}*/}
          </>
        )}
    </>
  );
}

function mapStateToProps(state) {
  const { round, enrolled, isRankGroupCreated, UserRaceGroupInfo } = state;
  return {
    round,
    enrolled,
    isRankGroupCreated,
    UserRaceGroupInfo,
  };
}

const connectedCodingRacePage = connect(mapStateToProps, {
  setRound: roundsActions.setRound,
  setEnrolled: enrolledActions.setEnrolled,
  createRankGroup: UserRaceGroupActions.createRankGroup,
  getUserRankGroupInfo: UserRaceGroupActions.getUserRankGroupInfo,
  getIsRankGroupCreated: UserRaceGroupActions.getIsRankGroupCreated,
  getWeeksAfterStart: UserRaceGroupActions.getWeeksAfterStart,
})(CodingRacePage);
export { connectedCodingRacePage as CodingRacePage };
