import * as S from "./WithdrawalModal.style";
import { useState } from "react";
import { usersActions } from "../../../../_state/actions";
import { userActions } from "../../../../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../../../_helper";

export const WithdrawalModal = ({ isOpen, handleOpen }) => {
  const { user } = useSelector((state) => state.authentication);
  const pointInfo = useSelector((state) => state.pointInfo);
  const couponInfo = useSelector((state) => state.couponInfo);
  const [isSecondPage, setIsSecondPage] = useState(false);
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  // 회원탈퇴
  const handleWithdrawal = async () => {
    if (!isSecondPage) {
      setIsSecondPage(true);
      return;
    }

    const ret = await dispatch(usersActions.withdrawUser({ reason }));
    if (ret.ok) {
      alert(
        "탈퇴가 완료되었습니다.\n더 좋은 서비스로 찾아뵙겠습니다.\n확인 버튼을 누르시면 홈으로 돌아갑니다."
      );
      dispatch(userActions.logout(`${process.env.REACT_APP_SCC_MAIN_URL}`));
    } else {
      alert("오류가 발생했습니다.\n다시 시도해주세요.");
    }
  };

  return (
    <>
      {isOpen && (
        <S.ModalWrapper visible={false}>
          <S.ModalBady>
            {!isSecondPage && (
              <S.FirstPage>
                <S.CloseImage
                  src={"/assets/icons/ic_close.png"}
                  onClick={() => handleOpen(false)}
                />
                <S.ModalTitleWrapper>
                  <S.ModalTitle>{user.name}님, 잠시만요!</S.ModalTitle>
                  <S.ModalDesc>
                    지금 탈퇴하시면
                    <br />
                    {user.name}님만을 위한 혜택이 모두 사라집니다.
                  </S.ModalDesc>
                </S.ModalTitleWrapper>
                <S.ModalBenefit>
                  <S.BenefitText>
                    포인트{" "}
                    <S.BenefitTextBold>
                      {numberWithCommas(pointInfo.data.point)}원
                    </S.BenefitTextBold>
                  </S.BenefitText>
                  <S.BenefitText>
                    쿠폰
                    <S.BenefitTextBold>
                      {couponInfo.data.coupons.length}장
                    </S.BenefitTextBold>
                  </S.BenefitText>
                </S.ModalBenefit>

                <S.NoticeContainer>
                  <ul>
                    <li>* 수강 중인 강의는 더 이상 수강이 불가능합니다.</li>
                    <li>* 포인트 및 쿠폰은 복구가 불가능합니다.</li>
                    <li>* 강의 환불이 불가능합니다.</li>
                    <li>
                      * 강의 별 제공되는 혜택 및 환급 등의 필수 안내사항을
                      받아보실 수 없습니다.
                    </li>
                  </ul>
                </S.NoticeContainer>
                <S.ModalDesc>그래도 탈퇴하시겠어요?</S.ModalDesc>
              </S.FirstPage>
            )}
            {isSecondPage && (
              <S.SecondPage>
                <S.SecondPageTitle>
                  {user.name}님이 떠나는 이유를 알려주시겠어요?
                  <br />
                  돌아오실 수 있도록 더욱 노력하겠습니다.
                </S.SecondPageTitle>
                <S.ModalTextarea
                  onChange={handleReason}
                  placeholder={`남겨주신 의견은 익명으로 사내에 공유됩니다.
회원 탈퇴를 결심하신 이유, 스파르타코딩클럽에 바라는 점을 가감 없이 말씀해주세요.`}
                />
                <S.NoticeContainer>
                  <S.NoticeText>
                    탈퇴 즉시 모든 데이터가 삭제되며
                    <br />
                    복구가 불가하오니 신중하게 선택해주세요.
                    <br />
                    삭제되는 정보는 다음과 같습니다.
                  </S.NoticeText>
                  <ul>
                    <li>* 개인정보(이름, 전화번호, 이메일, 사전설문 등)</li>
                    <li>* 포인트 적립/사용 내역</li>
                    <li>* 결제/환불 내역</li>
                    <li>* 강의 수강 정보(수강등록 및 진도율 등)</li>
                  </ul>
                </S.NoticeContainer>
                <S.NoticeContainer>
                  카카오채널의 광고메시지 수신 거절을 희망하실 경우,
                  <br />
                  카카오톡에서 직접 차단해주시기 바랍니다.
                </S.NoticeContainer>
                <S.NoticeContainerImportant>
                  탈퇴하기 버튼을 누르시면
                  <br />
                  안내 사항을 모두 확인하였으며,
                  <br />
                  이에 동의한 것으로 간주합니다.
                </S.NoticeContainerImportant>
              </S.SecondPage>
            )}
            <S.ModalButtonWrapper>
              <S.ModalButton onClick={handleWithdrawal}>
                {isSecondPage ? "탈퇴하기" : "탈퇴 진행"}
              </S.ModalButton>
              <S.ModalButtonCancel onClick={() => handleOpen(false)}>
                회원 유지
              </S.ModalButtonCancel>
            </S.ModalButtonWrapper>
          </S.ModalBady>
          <S.Background visible={false} onClick={() => handleOpen(false)} />
        </S.ModalWrapper>
      )}
    </>
  );
};
