import * as S from "./nps.style";

export const SurveyHeader = ({ badgeText, headerTitle, headerDescription }) => {
  return (
    <S.NpsHeaderContainer>
      <S.HeaderBadge>{badgeText}</S.HeaderBadge>
      <S.HeaderTitle>{headerTitle}</S.HeaderTitle>
      <S.HeaderDescription>{headerDescription}</S.HeaderDescription>
    </S.NpsHeaderContainer>
  );
};
