import { useIsMobile } from "../../hooks/device";

export const RightToggleSvg = () => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={isMobile ? "16" : "20"}
      height={isMobile ? "16" : "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33337 15L13.3334 10L8.33337 5"
        stroke="#9DA7AE"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
