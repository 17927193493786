import React from "react";
import swcLogo from "../../../assets/images/signup/logo_swc.svg";
import hanghaeLogo from "../../../assets/images/signup/logo_hanghae.svg";
import changLogo from "../../../assets/images/signup/logo_chang.svg";
import ddingdongLogo from "../../../assets/images/signup/logo_ddingdong.svg";
import intellipickLogo from "../../../assets/images/signup/logo_intellipick.svg";
import nbcLogo from "../../../assets/images/signup/logo_nbc.svg";
import * as S from "./SignupHeader.style";

const SignupHeaderFrame = ({ logo, width, title, subtitle }) => {
  return (
    <S.SignupHeaderContainer>
      <S.Logo width={width} src={logo} alt={`${logo}`} />
      <S.SignupHeaderText>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subtitle}</S.SubTitle>
      </S.SignupHeaderText>
    </S.SignupHeaderContainer>
  );
};

export default function SignupHeader({ nextUrl }) {
  const { swc, hanghae, chang, ddingdong, intellipick, nbc } = nextUrl;
  const nbcTitle = "주도적인 개발자로\n거듭날 당신을 환영합니다";
  if (swc) {
    return (
      <SignupHeaderFrame
        width="176px"
        logo={swcLogo}
        title="PM으로 거듭날 당신을 환영합니다."
        subtitle="가입정보를 작성해주세요."
      />
    );
  } else if (hanghae) {
    return (
      <SignupHeaderFrame
        width="176px"
        logo={hanghaeLogo}
        title="개발자 취업을 향한 출항을 시작하세요."
        subtitle="현직 최고의 멘토진이 끝까지 함께합니다."
      />
    );
  } else if (chang) {
    return (
      <SignupHeaderFrame
        width="132px"
        logo={changLogo}
        title="‘내 일'로 내일을 준비하세요."
        subtitle="직장인 창업 부트캠프 {창}"
      />
    );
  } else if (ddingdong) {
    return (
      <SignupHeaderFrame
        width="143px"
        logo={ddingdongLogo}
        title="코딩 강의 고민은 그만,"
        subtitle="쉽고 부담없는 온라인 코딩 학습지"
      />
    );
  } else if (intellipick) {
    return (
      <SignupHeaderFrame
        width="194px"
        logo={intellipickLogo}
        title="반갑습니다"
        subtitle="개발자 채용 전문 플랫폼 인텔리픽"
      />
    );
  } else if (nbc) {
    return <SignupHeaderFrame width="170px" logo={nbcLogo} title={nbcTitle} />;
  } else return null;
}
