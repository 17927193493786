import { COURSE_RETENTION } from "../constants";
import { courseRetentionService } from "../services";

function getCourseRetention(recent_course_id) {
  function request() {
    return { type: COURSE_RETENTION.COURSE_RETENTION_REQUEST };
  }

  function success(response) {
    return { type: COURSE_RETENTION.COURSE_RETENTION_SUCCESS, response };
  }

  function failure(error) {
    return { type: COURSE_RETENTION.COURSE_RETENTION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    courseRetentionService.getCourseRetention(recent_course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const courseRetentionActions = {
  getCourseRetention,
};
