import { addDays } from "../../../_helper";

export function getPlaytime(seconds, isLecture = false) {
  const hours = parseInt(seconds / (60 * 60));
  const minutes = parseInt(seconds / 60 - hours * 60);
  if (isLecture) {
    return `${hours > 0 ? fillNumber(hours) + ":" : ""}${fillNumber(
      minutes
    )}:${fillNumber(seconds - 60 * minutes - 60 * 60 * hours)}`;
  }
  return `${hours > 0 ? hours + "시간" : ""} ${minutes}분`;
}

export function fillNumber(number) {
  return number >= 10 ? number : `0${number}`;
}

export function getISODateAsKorean(date, isEnd) {
  const DAYS = ["일", "월", "화", "수", "목", "금", "토"];
  const end_date_as_js = addDays(date, isEnd ? -1 : 0);
  return `${end_date_as_js.getMonth() + 1}월 ${end_date_as_js.getDate()}일(${
    DAYS[end_date_as_js.getDay()]
  })`;
}

export function getISODateAsKoreanWithoutDays(date, isEnd) {
  const end_date_as_js = addDays(date, isEnd ? -1 : 0);
  return `${end_date_as_js.getMonth() + 1}월 ${end_date_as_js.getDate()}일`;
}

export function getISODateAsKoreanOnlyMonth() {
  const end_date_as_js = new Date();
  return `${end_date_as_js.getMonth() + 1}월`;
}

export function getISODateAsKoreanWithYear(date, isEnd) {
  const DAYS = ["일", "월", "화", "수", "목", "금", "토"];
  const end_date_as_js = addDays(date, isEnd ? -1 : 0);
  return `${end_date_as_js.getFullYear()}.${
    end_date_as_js.getMonth() + 1
  }.${end_date_as_js.getDate()} (${DAYS[end_date_as_js.getDay()]})`;
}

export function getRemainDays(date){
  const today = new Date().getTime();
  const end_date_as_js = addDays(date,  -1 ).getTime();
  return `${Math.ceil((end_date_as_js-today)/(1000*3600*24))}`

}
