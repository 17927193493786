import React, {Component} from 'react';
import styles from './RcmdNewsFeed.module.scss'
import classNames from "classnames/bind";

const cx = classNames.bind(styles);
const emojis = ["💪", "👍", "👏", "🙌", "✌️", "✊"];

function RcmdNewsFeed(props) {
    let random_number = Math.floor(Math.random() * emojis.length);
    const {host, invited, prize, time} = props.data;
    return (
        <div className={cx('tableContainer')}>
            <table>
                <tbody>
                <tr>
                    <td>{emojis[random_number]}</td>
                    <td className={cx('desc')}>{host} 님이 {invited} 님을 초대해 <span className={cx('prize')}>{prize}</span> 을
                        받았어요!
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td className={cx('time')}>{time}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export {RcmdNewsFeed}