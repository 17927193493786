import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoundWeekDetailLecture.module.scss";
import { getPlaytime } from "./utils";
import { history } from "../../../../_helpers";
import { sendLog } from "../../../businesslogics/logging";
import { GOV_DONE_DAILY_LIMIT } from "../../../_helper";
import { enrolledActions } from "../../../_state/actions";
import { govAction } from "../../../_state/actions/gov.actions";
import { useOnlineTheme } from "../../../queries/pirates";
import { PIRATES } from "../../../../_consts";
import {
  BenefitBubbleDesktop,
  BenefitBubbleMobile,
} from "../../../components/atoms/Roadmap/Roadmap.svg";
import { useFindPreviousDetail } from "../../../hooks/roadmap/useFindPreviousDetailId";

const cx = classNames.bind(styles);

function RoundWeekDetailLecture({
  environment,
  enrolled,
  expired,
  getExpired,
  week,
  detail,
  details,
  doneEnrolledDetail,
  idx,
  isTimeVisible,
  enrolledDoneCount,
  govCompletionMilestone,
  doEnrolledDetail,
  doGovEnrolledDetail,
  enrolledProgress,
  isLecture,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [isActiveWeek, setIsActiveWeek] = useState(false);
  const [isTooltipShow, setIsTooltipShow] = useState(false);
  const [isNotAllowedNext, setIsNotAllowedNext] = useState(false);
  const [imgSize, setImgSize] = useState(23);
  const [next, setNext] = useState(null);
  const { enrolled_id } = enrolled.data;
  const findPreviousDetail = useFindPreviousDetail(enrolled_id);

  useEffect(() => {
    setImgSize(environment.deviceType === "mobile" ? 18 : 23);
  }, [environment]);

  useEffect(() => {
    setIsOpened(
      expired.data?.achv.week === week &&
        (expired.data?.achv.is_started || expired.data?.achv.week === 0)
    );
    setIsActiveWeek(
      expired.data?.achv.week === week &&
        (expired.data?.achv.is_started || expired.data?.achv.week === 0)
    );
  }, [expired.loaded]);

  useEffect(() => {
    // enrolled 는 상위 컴포넌트에서 로딩 완료되었음을 전제로,
    // 정부 과목일 경우 9개 이상 수강할 수 없다.
    const {
      rounds: { is_seen_restricted },
    } = enrolled.data;
    if (!enrolledDoneCount.loaded) {
      return;
    }
    // 진도표에서는 GOV_DONE_DAILY_LIMIT + 1, 강의화면에서는 GOV_DONE_DAILY_LIMIT
    // 강의화면에서는 '지금 보고 있는 것'까지 수강하고 있기 때문
    setIsNotAllowedNext(
      is_seen_restricted && enrolledDoneCount.data >= GOV_DONE_DAILY_LIMIT + 1
    );
  }, [enrolledDoneCount.loaded]);

  // useEffect(()=>{
  //   if (!enrolled.loaded) {
  //     return;
  //   }
  //   if (!expired.loaded){
  //     getExpired(enrolled.data.enrolled_id)
  //     return;
  //   }
  // }, [enrolled.loaded])

  useEffect(() => {
    const { enrolled_id, course_id } = enrolled.data;
    if (!doneEnrolledDetail.loaded) {
      return;
    }
    const {
      rounds: { is_gov },
    } = enrolled.data;
    const { enrolled_detail_id } = detail;

    const previous_enrolled_detail = details[idx - 1];
    if (next !== enrolled_detail_id) {
      return;
    }

    if (is_gov) {
      if (
        previous_enrolled_detail &&
        previous_enrolled_detail.seen &&
        !previous_enrolled_detail.done
      )
        doGovEnrolledDetail(
          previous_enrolled_detail.enrolled_id,
          previous_enrolled_detail.enrolled_detail_id
        );
    }
    history.push(
      `/enrolleds/${enrolled_id}/edetails/${enrolled_detail_id}?course_id=${course_id}`
    );
  }, [doneEnrolledDetail.loaded]);

  const {
    round_id,
    lecture_id,
    done,
    seen,
    title,
    playtime,
    enrolled_detail_id,
    week_order,
  } = detail;
  const playtime_m = getPlaytime(playtime, true);
  const theme = useOnlineTheme();
  const [checkColor, setCheckColor] = useState("ced4da");

  useEffect(() => {
    if (theme.isSuccess && theme.data === PIRATES) {
      if (done) {
        setCheckColor("#EEF3F6");
      } else {
        setCheckColor("rgba(238, 243, 246, 0.3)");
      }
    } else {
      if (done) {
        setCheckColor("#000000");
      } else {
        setCheckColor("#ced4da");
      }
    }
  }, [theme.isSuccess, done]);
  let is_todo = false;
  if (isActiveWeek) {
    if (idx > 0) {
      is_todo = details[idx - 1].done && !done;
    } else {
      is_todo = !done;
    }
  }
  const is_allowed = getIsAllowed();

  function getIsAllowed() {
    const isDone =
      week <= expired.data?.achv.week && (idx === 0 || details[idx - 1].done);
    const isNext =
      week === expired.data?.achv.week && idx >= 1 && details[idx - 1].seen;
    const isPossibleGoNextWeek =
      week <= expired.data?.achv.week + 1 &&
      idx === 0 &&
      expired.data?.achv.is_possible_go_next_week;
    return isDone || isNext || isPossibleGoNextWeek;
  }

  function goLecture(round_id, lecture_id, is_allowed) {
    const {
      enrolled_id,
      rounds: { is_seen_restricted, is_gov_credit },
      course_id,
    } = enrolled.data;
    const clicked_enrolled_detail = details[idx];
    const previousEnrolledDetail = findPreviousDetail(
      clicked_enrolled_detail.enrolled_detail_id
    );
    const previous_enrolled_detail = details[idx - 1];
    if (!is_allowed) {
      alert("앗, 이전 강의 수강을 먼저 마무리해주세요.");
      return;
    }

    if (!detail.seen && isNotAllowedNext) {
      // 정부과목 수강자일 경우 하루 8개 이상 불가. 대신, 다음 강의를 이미 완료한 경우 지나갈 수 있음.
      alert(`하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1}강까지 들을 수 있어요!`);
      return;
    }
    // 이전 강의를 Done 처리하지 않았을 경우.
    if (
      previousEnrolledDetail &&
      previousEnrolledDetail.seen &&
      !previousEnrolledDetail.done
    ) {
      // 정부 크레딧 과목일 경우, 로드맵 화면에서는 이전 강의가 Done 처리되어야 다음 강의로 넘어갈 수 있게 구현했다.
      if (is_gov_credit) {
        alert("앗, 이전 강의 수강을 먼저 마무리해주세요.");
        return;
      }
      if (
        is_seen_restricted &&
        enrolledDoneCount.data >= GOV_DONE_DAILY_LIMIT
      ) {
        alert(`하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1}강까지 들을 수 있어요!`);
        return;
      }
      doEnrolledDetail(
        previousEnrolledDetail.enrolled_id,
        previousEnrolledDetail.enrolled_detail_id
      );
      // 처음 강의수강완료 했을때만 보내기
      sendLog("scc_lecture_completed", {
        ...enrolled.data,
        ...previous_enrolled_detail,
        progress: enrolledProgress.data,
      });
      setNext(enrolled_detail_id);
    } else {
      // sendUserAction(USER_ACTIONS.GO_LECTURE, USER_PAGES.PAGE_ROADMAP);
      history.push(
        `/enrolleds/${enrolled_id}/edetails/${enrolled_detail_id}?course_id=${course_id}`
      );
    }
  }

  const milestone = govCompletionMilestone?.data?.context;
  const isCompletionCourse =
    milestone && week === milestone.week && week_order === milestone.week_order;

  const isFirstWeek = enrolled.data.courses.is_trial ? week === 1 : week === 0;

  return (
    <div
      className={cx(
        "round-week-detail-lecture__body__component",
        theme.data,
        `${is_todo ? "--point-out" : ""}`,
        `${done ? "--active" : ""}`,
        `${isCompletionCourse ? "--completion" : ""}`
      )}
      onClick={() => {
        sendLog(
          isLecture
            ? "scc_lecturePage_click_list"
            : "scc_roadmapPage_click_list",
          {
            ...details[idx],
            ...enrolled.data,
            button_name: "강의 목차 중 강의 버튼",
          },
          ""
        );
        goLecture(round_id, lecture_id, is_allowed);
      }}
    >
      <>
        <div className={cx("round-week-detail-lecture__body__component__num")}>
          {`${week}-${idx + 1}`}
        </div>
        {idx === 0 && !done && isFirstWeek && (
          <>
            <div
              className={cx(
                "round-week-detail-lecture__body__component__bubbledesktop"
              )}
            >
              {<BenefitBubbleDesktop />}
            </div>
            <div
              className={cx(
                "round-week-detail-lecture__body__component__bubblemobile"
              )}
            >
              <BenefitBubbleMobile />
            </div>
          </>
        )}

        <div
          className={cx(
            "round-week-detail-lecture__body__component__title",
            `${isTimeVisible ? "" : "--small-width"}`
          )}
        >
          {title}
        </div>
        {isTimeVisible && (
          <div
            className={cx(
              "round-week-detail-lecture__body__component__playtime"
            )}
          >
            {playtime_m}
          </div>
        )}
        <div className={cx("round-week-detail-lecture__body__component__seen")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={imgSize}
            height={imgSize}
            fill="none"
            viewBox="0 0 23 23"
          >
            <path
              fill={`${checkColor}`}
              d="M22.25 11.938c0 2.917-1.159 5.715-3.222 7.778-2.063 2.063-4.86 3.221-7.778 3.221-2.917 0-5.715-1.158-7.778-3.221C1.409 17.653.25 14.855.25 11.937c0-2.917 1.159-5.715 3.222-7.778C5.535 2.096 8.332.938 11.25.938c2.917 0 5.715 1.158 7.778 3.221 2.063 2.063 3.222 4.861 3.222 7.779zM16.791 7.77c-.098-.098-.215-.175-.344-.226-.128-.052-.266-.077-.405-.074-.138.003-.275.033-.401.09-.127.057-.24.139-.335.24l-4.775 6.085-2.878-2.88c-.195-.181-.454-.28-.721-.276-.267.005-.522.113-.711.302-.19.189-.297.444-.302.71-.005.268.094.527.277.722l3.638 3.64c.098.098.215.175.343.226.128.052.266.078.404.075.139-.003.275-.033.402-.09.126-.056.24-.137.334-.239l5.49-6.861c.187-.195.29-.455.287-.725-.002-.27-.11-.528-.301-.719h-.002z"
            />
          </svg>
        </div>
      </>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    expired,
    enrolled,
    enrolledDoneCount,
    environment,
    govCompletionMilestone,
    doneEnrolledDetail,
    enrolledProgress,
  } = state;
  const { user } = authentication;
  return {
    enrolled,
    authentication,
    user,
    expired,
    enrolledDoneCount,
    environment,
    doneEnrolledDetail,
    govCompletionMilestone,
    enrolledProgress,
  };
}

const connectedRoundWeekDetailLecture = connect(mapStateToProps, {
  doEnrolledDetail: enrolledActions.doEnrolledDetail,
  doGovEnrolledDetail: govAction.doGovEnrolledDetail,
  getExpired: enrolledActions.getExpired,
})(RoundWeekDetailLecture);
export { connectedRoundWeekDetailLecture as RoundWeekDetailLecture };
