import styled from "@emotion/styled";
import { DisplayResolution } from "../../../../../_styles/device";

export const QuestionAndAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 24px rgba(95, 102, 107, 0.12);
  margin: 0 auto;
  max-width: 772px;
  width: 100%;
  border-radius: 12px;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
  }
`;
