import privateInstance from "../../_helper/axios";

async function getSpalandDatesByEnrolled(enrolled_id) {
  return await privateInstance
    .get(`/enrolled_spaland/date/enrolled/${enrolled_id}`)
    .then((resp) => resp.data);
}

async function getSpalandDates() {
  return await privateInstance
    .get(`/enrolled_spaland/date/user`)
    .then((resp) => resp.data);
}

async function postSpalandDateAttendance() {
  return await privateInstance
    .post("/enrolled_spaland/date/user/attendance")
    .then((resp) => resp.data);
}

export const spalandService = {
  getSpalandDatesByEnrolled,
  getSpalandDates,
  postSpalandDateAttendance,
};
