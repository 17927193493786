import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./ClassCardBtn.module.scss";
import { connect } from "react-redux";
import { history } from "../../../../../../_helpers";
import instance from "../../../../../../_helpers/axios";
import { ClassCardSelect } from "../ClassCardSelect";
import { ClassCardBtnBottom } from "./ClassCardBtnBottom";
import { prequestionAction } from "../../../../../_state/actions";
import { useHrdLink } from "../../../../../_state/v3/hrdLink";
import { sendLog } from "../../../../../businesslogics/logging";

const cx = classNames.bind(styles);

function ClassCardBtn({
  current_type,
  course,
  environment,
  classroomCards,
  isPrequestionExist,
}) {
  const hrdLink = useHrdLink();
  const [voucherCourseId, setVoucherCourseId] = useState(course.course_id);
  const go_to_lecture = () => {
    const govIntro = classroomCards?.data?.full_enrolled_info_list?.filter(
      (card) => card.round.is_gov_intro
    );

    if (course.is_gov_trial && govIntro.some((card) => card.hasCard)) {
      if (isPrequestionExist.loaded) {
        window.location.href = hrdLink.link;
      }
    }

    const hasRankSystem = course.has_rank_system !== false;
    if (!hasRankSystem || environment.deviceType === "mobile") {
      const endpoint = `/enrolleds/${course.enrolled._id}/rounds/${course.enrolled.round_id}/roadmap`;
      history.push(endpoint);
      return;
    } else {
      // const isGoCheckin =
      //     isCheckinCreated.loaded &&
      //     isCheckinCreated.data.checkin_created;
      // const endpoint = `/enrolleds/${course.enrolled._id}/rounds/${
      //     course.enrolled.round_id
      // }/${isGoCheckin ? "checkins" : "roadmap"}`;

      let isKriss = course.round_title.includes("KRISS");
      let endpoint;
      if (course.last_week >= 3 && !course.is_forever && !isKriss) {
        endpoint = `/enrolleds/${course.enrolled._id}/rounds/${course.enrolled.round_id}/rank`;
      } else {
        endpoint = `/enrolleds/${course.enrolled._id}/rounds/${course.enrolled.round_id}/roadmap`;
      }
      history.push(endpoint);
    }
  };

  const go_to_retro = (enrolled_id) => {
    const endpoint = `/retrospectives/${enrolled_id}`;
    history.push(endpoint);
  };

  const movePage = (course) => {
    const {
      is_revision_period,
      final_review_url,
      enrolled: { course_id, round_id, _id: enrolled_id },
    } = course;
    const next_url = is_revision_period
      ? final_review_url
      : `/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`;
    window.location.assign(next_url);
  };

  const submitVoucherRequest = async () => {
    const { confirm } = window;
    const titles = [course.title];
    const voucher_id_list = [course.voucher_id];

    if (
      !confirm(
        `${titles.join(
          ", "
        )}을 사용하시겠습니까?\n수강권은 등록 후 취소할 수 없습니다.\n수강기간 먼저 확인해주세요.`
      )
    ) {
      return;
    }
    if (!course.course_id && !voucherCourseId) {
      alert("강의를 선택해주세요!");
      return;
    }
    const data = { voucher_id_list, voucher_course_id: voucherCourseId };

    await instance
      .post("/enrolleds/vouchers", data)
      .then((resp) => resp.data)
      .then((data) => {
        if (data.ok) {
          alert("사용하신 수강권이 등록되었습니다!");
          window.location.href = "/classroom";
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      {current_type === "voucher" && (
        <>
          {course.voucher_id && (
            <ClassCardSelect
              course_data={course}
              setVoucherCourseId={setVoucherCourseId}
            />
          )}
          <div
            className={cx("cta_btn", "voucher")}
            onClick={() => submitVoucherRequest()}
          >
            내 수강권 사용하기
          </div>
        </>
      )}
      {current_type === "purchased" && (
        <>
          <div
            className={cx("cta_btn", "purchased")}
            onClick={() => {
              sendLog(
                "scc_mypagePage_click_button",
                {
                  button_text: "수업 들으러 가기",
                  course_title: course.course.title,
                  enrolled_id: course.enrolled.enrolled_id,
                  round: course.enrolled.round,
                },
                "",
                true
              );
              go_to_lecture();
            }}
          >
            수업 들으러 가기
          </div>
          <ClassCardBtnBottom course={course} />
        </>
      )}
      {current_type === "done" &&
        (course.did_retro ? (
          <>
            <div
              className={cx("cta_btn", "done", "retro_true")}
              onClick={() => movePage(course)}
            >
              {course.is_revision_period ? "내 회고 수정하기" : "수강하러 가기"}
            </div>
            <ClassCardBtnBottom course={course} />
          </>
        ) : (
          <>
            {course.done && (
              <>
                <div
                  className={cx("cta_btn", "done", "retro_false")}
                  onClick={() => {
                    sendLog(
                      "scc_mypagePage_click_button",
                      {
                        button_text: "완주 회고 쓰러가기",
                        course_title: course.course.title,
                        enrolled_id: course.enrolled.enrolled_id,
                        round: course.enrolled.round,
                      },
                      "",
                      true
                    );
                    go_to_retro(course.enrolled._id);
                  }}
                >
                  완주 회고 쓰러가기
                </div>
              </>
            )}
            <ClassCardBtnBottom course={course} />
          </>
        ))}
    </>
  );
}

function mapStateToProps(state) {
  const { isPrequestionExist, isCheckinCreated, environment, classroomCards } =
    state;
  return {
    isCheckinCreated,
    isPrequestionExist,
    environment,
    classroomCards,
  };
}

const ConnectedClassCardBtn = connect(mapStateToProps, {
  getPrequestionExist: prequestionAction.getPrequestionExist,
})(ClassCardBtn);
export { ConnectedClassCardBtn as ClassCardBtn };
