import { GET_STUDY_OBJECTIVE, UPDATE_STUDY_OBJECTIVE } from "../constants";

const initState = {
  loaded: false,
};

export function studyObjective(state = initState, action) {
  switch (action.type) {
    case GET_STUDY_OBJECTIVE.GET_STUDY_OBJECTIVE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GET_STUDY_OBJECTIVE.GET_STUDY_OBJECTIVE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GET_STUDY_OBJECTIVE.GET_STUDY_OBJECTIVE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    case UPDATE_STUDY_OBJECTIVE.UPDATE_STUDY_OBJECTIVE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case UPDATE_STUDY_OBJECTIVE.UPDATE_STUDY_OBJECTIVE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case UPDATE_STUDY_OBJECTIVE.UPDATE_STUDY_OBJECTIVE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
