import { useEffect, useRef } from "react";
import videojs from "video.js";
import qualityLevels from "videojs-contrib-quality-levels";
import { useQuality } from "../stores/playerAtom";

export const useQualityControl = (videoRef, playerType) => {
  const [quality, setQuality] = useQuality();
  const playerRef = useRef(null);

  if (!videojs.getPlugin("qualityLevels")) {
    videojs.registerPlugin("qualityLevels", qualityLevels);
  }

  const qualityOptions = playerType === "V2" ? [1080, 720, 540, 360] : [1080];
  const onQualityChange = (event) => {
    if (playerType === "V1") {
      return;
    }
    const selectedQuality = event.target.value;

    if (selectedQuality === "Auto") {
      setQuality("Auto");
      localStorage.setItem("qualityV3", "Auto");
    } else {
      const parsedQuality = parseInt(selectedQuality);
      setQuality(parsedQuality);
      localStorage.setItem("qualityV3", parsedQuality);
    }
  };
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    playerRef.current = videojs(videoRef.current);
    const player = playerRef.current;

    if (player && player.qualityLevels) {
      const qualityLevels = player.qualityLevels();
      if (quality === "Auto") {
        qualityLevels.levels_.forEach((level) => {
          level.enabled = true;
        });
      } else {
        qualityLevels.levels_.forEach((level, i) => {
          level.enabled = level.height === quality;
        });
      }
    }
  }, [videoRef, quality]);

  return {
    quality,
    qualityOptions,
    onQualityChange,
  };
};
