import React, { Component, Fragment } from "react";
import styles from "./Lecture.module.scss";
import classNames from "classnames/bind";
import { config } from "../../config";
import { history, params } from "../../_helpers";
import { connect } from "react-redux";
import { Button, Input, message, Rate, Skeleton, Tooltip } from "antd";
import {
  codeSnippetActions,
  homeworkActions,
  lectureActions,
  lectureNoteActions,
  pointActions,
} from "../../_actions";
import { getHomeworkUrl, getPostAuthOptions, SLACK_URL } from "../../_urls";
import { CustomModal } from "../CustomModal";
import {
  getApiUserPointChanceAll,
  getApiUserPointChanceOnce,
} from "../../_urls/accounts.api";
import { getLectureUrl } from "../../_urls/lectures";
import { getApiFreeCourseCTALogUrl } from "../../_urls/logs.api";
import instance from "../../_helpers/axios";
// import Player from '@vimeo/player';

const cx = classNames.bind(styles);

class LectureEn extends Component {
  state = {
    isQnaTime: false,
    tooltipShow: false,
    modalVisible: false,
    boxOpened: false,
    pointDelta: 0,

    // 어렵지수, 꿀잼지수 관련 states
    modalRatingVisible: false,
    value: 5.0,
    difficulty: 0.0,
    fun: 0.0,
    cheerup: "",
    isDifficultyChecked: false,
    isFunChecked: false,

    // 강의 관련 정보
    iframeImported: false,
    go_next: false,
  };

  constructor(props) {
    super(props);
    // this._getLectureInfo();
  }

  componentDidMount() {
    const { round_id, lecture_id } = this.props.data;
    this._isSlackOn();
    this.props.getPointInfo();
    this.props.getLecture(round_id, lecture_id);

    this.setState({
      go_next: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data.lecture_id !== this.props.data.lecture_id) {
      if (this.props.nav_selected) {
        this.props.handleNavStatus(false);
      } else {
        const { round_id, lecture_id } = this.props.data;
        this.props.getLecture(round_id, lecture_id);
        this.setState({
          go_next: false,
        });
      }
    }
  }

  _isSlackOn = () => {
    let now = new Date();
    let day = now.getDay();
    let hour = now.getHours();

    let isQnaTime = false;
    if (
      (day === 2 && hour >= 19 && hour < 22) ||
      (day === 6 && hour >= 14 && hour < 17)
    ) {
      isQnaTime = true;
    }

    this.setState({
      isQnaTime,
    });
  };

  _getLectureInfo = () => {
    const { round_id, lecture_id } = this.props.data;
    // const endpoint = getApiLectureInfoUrl(course_id, lecture_id);
    // const options = getGETAuthOptions();
    // fetch(endpoint, options)
    //     .then(resp => resp.json())
    //     .then(lecture => {
    //         this.setState({
    //             lecture
    //         });
    //     })
    instance
      .get(`/en/rounds/${round_id}/lectures/${lecture_id}`)
      .then((resp) => {
        this.setState({
          lecture: resp.data,
        });
      });
  };

  _getLectureComponent = (lecture) => {
    if (lecture === undefined || lecture === {}) {
      return <div />;
    }
    return (
      <Fragment>
        {/*<h1>{lecture['title']}</h1>*/}
        <div className={cx("lectureWrapper")}>
          <div className={cx("h_iframe")}>
            {/*<img className="ratio" src="https://placehold.it/16x9"/>*/}
            {/*<iframe*/}
            {/*    src={`https://player.vimeo.com/video/${lecture['link']}`}*/}
            {/*    frameBorder="0"*/}
            {/*    allow="autoplay; fullscreen" allowFullScreen/>*/}

            <iframe
              src={`https://www.youtube.com/embed/${lecture["link"]}?rel=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />

            {/*<iframe src="https://www.youtube.com/embed/lqvrbIHFifg?rel=0"*/}
            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*        frameBorder="0"*/}
            {/*        allowFullScreen />*/}
          </div>
        </div>
      </Fragment>
    );
  };

  setVimeoLog = () => {
    let iframe = document.querySelector("iframe");
    let player = new window.Vimeo.Player(iframe);

    // const sendVideoLog = this.sendVideoLog;

    // player.on('play', function (data) {
    //     sendVideoLog(data, 'play');
    // });
    //
    // player.on('pause', function (data) {
    //     sendVideoLog(data, 'pause');
    // });
    //
    // player.on('ended', function (data) {
    //     sendVideoLog(data, 'ended');
    // });
  };

  sendVideoLog = (data, status) => {
    const {
      course_title,
      course_id,
      round_id,
      week,
      week_order,
      _id: lecture_id,
      title: lecture_title,
    } = this.props.lecture.data;
    const { enrolled_id } = this.props.lectureNav.data.lectures[1][0];
    const reqData = {
      ...data,
      course_title,
      course_id,
      round_id,
      week,
      week_order,
      lecture_id,
      lecture_title,
      enrolled_id,
      status,
    };
    // instance.post('/logs/video', reqData)
    //     .catch((error) => {
    //
    //     });
  };

  _handleDone = () => {
    const { round_id } = this.props.data;
    const { confirm } = window;
    this.setState({
      modalVisible: true,
    });
    // alert('클래스룸 완성되면 이동 넣을것.');
    // history.push(`/en/courses/${course_id}/dashboard`);
  };

  _handleMouseOver = () => {
    this.setState({
      tooltipShow: true,
    });
  };

  _handleMouseLeave = () => {
    this.setState({
      tooltipShow: false,
    });
  };

  handleModalVisible = (isVisible) => {
    this.setState({
      modalVisible: isVisible,
    });
  };

  _getImagePathTreauserBox = (isOpen) => {
    if (isOpen) {
      return "/assets/images/lecture/box_open_effects.gif";
    } else {
      return "/assets/images/lecture/treasure_box_unopened_en.png";
    }
  };

  _handleOpen = () => {
    let endpoint = getApiUserPointChanceOnce();
    let options = getPostAuthOptions({});

    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          let pointDelta =
            parseInt(result["point_after"]) - parseInt(result["point_before"]);
          this._openEffect(pointDelta);
        } else {
          alert(result.message);
        }
      });
  };

  _handleOpenAll = () => {
    let endpoint = getApiUserPointChanceAll();
    let options = getPostAuthOptions({});

    this.setState({
      boxOpened: true,
    });
    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          let pointDelta =
            parseInt(result["point_after"]) - parseInt(result["point_before"]);
          this._openAllEffect(pointDelta);
        } else {
          alert(result.message);
        }
      });
  };

  _openEffect = (pointDelta) => {
    this.setState({
      boxOpened: true,
      pointDelta: pointDelta,
    });
    setTimeout(() => {
      this.setState({
        boxOpened: false,
        pointDelta: 0,
      });
      this.props.getPointInfo();
    }, 1000);
  };

  _openAllEffect = (pointDelta) => {
    this.setState({
      boxOpened: true,
      pointDelta: pointDelta,
    });
    setTimeout(() => {
      this.setState({
        boxOpened: false,
        pointDelta: 0,
      });
      this.props.getPointInfo();
    }, 1500);
  };

  _endStudy = () => {
    const { round_id, course_id } = this.props.data;
    const { week, week_order } = this.props.lecture.data;
    history.push(`/en/rounds/${round_id}/checkouts/${week}/${week_order}`);
  };

  _continueStudy = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleModalRatingVisible = (isVisible) => {
    this.setState({
      modalRatingVisible: isVisible,
    });
  };

  handleCheerupChange = (e) => {
    this.setState({ cheerup: e.target.value });
  };

  handleDifficultyChange = (difficulty) => {
    this.setState({
      difficulty: difficulty,
      isDifficultyChecked: true,
    });
  };

  handleFunChange = (fun) => {
    this.setState({
      fun: fun,
      isFunChecked: true,
    });
  };

  _refreshComponents = (round_id, lecture_id) => {
    this.props.getLectureNavInfo(round_id);
    // this.props.getLecture(round_id, lecture_id);
    // this.props.getCodeSnippet(round_id, lecture_id);
    this.props.getPointInfo();
  };

  handleOk = (written) => {
    const { round_id, lecture_id } = this.props.data;
    const { isFunChecked } = this.state;
    if (written && isFunChecked === false) {
      alert("별점을 매겨주세요.");
      return;
    }

    let data = written
      ? {
          refresh_token: this.props.user.refresh,
          fun: this.state.fun,
          cheerup: this.state.cheerup,
        }
      : {};

    instance
      .post(`/rounds/${round_id}/lectures/${lecture_id}/ratings`, data)
      .then((resp) => {
        const { next_id, enrolled_id } = this.props.lecture.data;
        if (!next_id) {
          // alert('마지막 강의에요!\n이제 본 강의를 들으러 가볼까요?');
          alert(
            "Finally, the course is over.\nCongratulations for your completion! 🏅"
          );
          history.push(`/en/nps/${round_id}?enrolled_id=${enrolled_id}`);
          return;
        }
        let chosenUrl;
        if (next_id.includes("homework")) {
          const homework_id = next_id.split("/")[1];
          chosenUrl = "/en" + getHomeworkUrl(round_id, homework_id);
          this.props.getHomeworkItem(round_id, homework_id);
        } else {
          chosenUrl = "/en" + getLectureUrl(round_id, next_id);
          this._refreshComponents(round_id, next_id);
        }

        this._showSuccessMessage();

        history.push(chosenUrl);
        this.setState({
          modalRatingVisible: false,
          value: 5.0,
          difficulty: 0.0,
          fun: 0.0,
          cheerup: "",
          isDifficultyChecked: false,
          isFunChecked: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.message !== "skip") {
          alert("너무 빨라요!\n잠시 후 다시 시도해주세요.");
        }
      });
  };

  _goNextLecture = () => {
    if (!this.props.lecture.data) {
      return;
    }
    this.props.handleNavStatus(false);
    let { next_id, enrolled_id } = this.props.lecture.data;
    this._showModal();
  };

  _showModal = () => {
    const { round_id } = this.props.data;
    const { rating_written } = this.props.lecture.data;

    if (rating_written) {
      // 바로 다음 강의로 넘어간다.
      const { next_id, enrolled_id } = this.props.lecture.data;
      this.setState({
        modalRatingVisible: false,
        cheerup: "",
        go_next: true,
      });
      if (!next_id) {
        // alert('마지막 강의에요!\n이제 본 강의를 들으러 가볼까요?');
        alert(
          "Finally, the course is over.\nCongratulations for your completion! 🏅"
        );
        history.push(`/en/nps/${round_id}?enrolled_id=${enrolled_id}`);
        return;
      }
      if (next_id.includes("homework")) {
        const homework_id = next_id.split("/")[1];
        let chosenHomeworkUrl = getHomeworkUrl(round_id, homework_id);
        this.props.getHomeworkItem(round_id, homework_id);
        history.push("/en" + chosenHomeworkUrl);
      } else {
        this._refreshComponents(round_id, next_id);
        this.props.getLecture(round_id, next_id);
        let chosenLectureUrl = getLectureUrl(round_id, next_id);
        history.push("/en" + chosenLectureUrl);
      }
    } else {
      this.setState({
        modalRatingVisible: true,
        value: 0.0,
        difficulty: 0.0,
        fun: 0.0,
        go_next: true,
      });
    }
  };

  _showSuccessMessage = () => {
    const { points } = this.state;
    if (points === undefined || points.review_done === undefined) {
      return;
    }
    message.success({
      content: `+1 보물상자, +${points.review_done}  points 적립 완료!`,
      className: "custom-class",
      style: {
        position: "absolute",
        top: "7px",
        right: "40px",
      },
    });
  };

  goSlack = () => {
    const { round_id } = this.props.data;
    window.open(
      "https://app.slack.com/client/T01KHS02ZL6/C01K9S32JKZ",
      "_blank"
    );
  };

  render() {
    const { isQnaTime, tooltipShow, modalVisible, boxOpened } = this.state;
    // 어렵지수, 꿀잼지수 관련
    const {
      modalRatingVisible,
      value,
      difficulty,
      fun,
      cheerup,
      isDifficultyChecked,
      isFunChecked,
    } = this.state;

    const { user } = this.props;
    let lecture = this.props.lecture.data;
    if (lecture === undefined || !this.props.lecture.loaded) {
      return (
        <Skeleton.Input style={{ width: 850, height: 551 }} active={true} />
      );
    }
    let lectureComp = this._getLectureComponent(lecture);
    if (
      this.props.pointInfo.data === undefined ||
      this.props.lectureNav.data === undefined
    ) {
      return (
        <Skeleton.Input style={{ width: 850, height: 551 }} active={true} />
      );
    }
    const { pointInfo } = this.props;
    const { points } = this.props.lectureNav.data;
    const isEmpty = pointInfo.data.chance === 0;
    const customHeight = isEmpty ? 580 : 580;

    const {
      next_id,
      context: { is_free, is_forever },
    } = this.props.lecture.data;
    let next_btn_txt;
    if (is_forever) {
      next_btn_txt = "Finish Course";
    } else {
      next_btn_txt = "Continue";
    }

    const height = window.innerWidth >= 1280 ? 520 : 460;
    const treasureHeight = window.innerWidth >= 1280 ? 680 : 460;
    const width = window.innerWidth >= 1280 ? 550 : "96%";
    return (
      <div>
        {lectureComp}
        <script src="https://player.vimeo.com/api/player.js" />
        <div className={cx("buttonContainer")}>
          <div className={cx("leftButtonContainer")}>
            <Button className={cx("lectureButton")} onClick={this._handleDone}>
              Finish
            </Button>

            <Tooltip
              overlayStyle={{
                position: "relative",
                fontSize: 12,
                width: 171,
                textAlign: "center",
              }}
              // placement={"bottom"}
              title={
                <span>Press [Finish] button to open your treasure box!</span>
              }
              visible={tooltipShow}
            >
              <div
                className={cx("treasureboxWrapper")}
                onMouseOver={this._handleMouseOver}
                onMouseLeave={this._handleMouseLeave}
              >
                <img src={"/assets/icons/treasurebox_bg.png"} alt="" />
                <div className={cx("numberindicator")}>
                  {pointInfo.data.chance}
                </div>
              </div>
            </Tooltip>
          </div>
          <div className={cx("buttonGroupTop")}>
            <Button
              className={cx("qnaButton")}
              onClick={this._goNextLecture}
              style={{ float: "right" }}
            >
              {next_btn_txt}
            </Button>
            {isQnaTime ? (
              <Button
                className={cx("communityButton")}
                onClick={this.goSlack}
                style={{ float: "right" }}
              >
                Q&A
              </Button>
            ) : (
              <Button
                className={cx("communityButton")}
                onClick={this.goSlack}
                style={{ float: "right" }}
              >
                Q&A
              </Button>
            )}
            <div
              className={cx("materialContainer")}
              onClick={() => {
                window.open(this.props.lecture.data.url);
              }}
            >
              Notes &nbsp;
              <img
                className={cx("outlinkImage")}
                src={"/assets/icons/outlink.png"}
                alt=""
              />
            </div>
          </div>
        </div>
        <CustomModal
          visible={modalVisible}
          close={false}
          handleModalVisible={this.handleModalVisible}
          style={{
            width: width,
            height: treasureHeight,
            backgroundColor: "#F9FBFA",
          }}
          body={
            <div className={cx("modalBodyContainer")}>
              {/* title */}
              <div className={cx("titleContainer")}>
                {!isEmpty && (
                  <Fragment>
                    <h4>Open the treasure box!</h4>
                    <p>
                      You can collect a treasure box for every lesson.
                      <br />
                    </p>
                  </Fragment>
                )}
                {isEmpty && (
                  <Fragment>
                    <h4>No more treasure box!</h4>
                    <p>
                      You can collect a treasure box for every lesson.
                      <br />
                    </p>
                  </Fragment>
                )}
              </div>

              {/* info - 보물상자 수 */}
              <div className={cx("myinfoContainer")}>
                <ul>
                  <li>
                    <div className={cx("labelContainer")}>
                      <img src={"/assets/icons/treasurebox_small.png"} />
                      <span></span>
                    </div>
                  </li>
                  <li>
                    <span className={cx("numberContainer")}>
                      {pointInfo.data.chance}
                    </span>
                    box(es)
                  </li>
                </ul>
              </div>

              {/* info - 나의  points */}
              <div className={cx("myinfoContainer")}>
                <ul>
                  <li>
                    <div className={cx("labelContainer")}>
                      <img src={"/assets/icons/coin_small.png"} />
                      <span></span>
                    </div>
                  </li>
                  <li>
                    <span className={cx("numberContainer")}>
                      {pointInfo.data.point}
                    </span>
                    point(s)
                  </li>
                </ul>
              </div>

              {/*더해진 금액 보여주기*/}
              {!isEmpty && (
                <Fragment>
                  {boxOpened && (
                    <div className={cx("addedPointContainer")}>
                      +{this.state.pointDelta}
                    </div>
                  )}

                  {/* 상자 toggle 이미지 */}
                  {boxOpened === false && (
                    <div className={cx("treasureBoxContainer")}>
                      <img
                        onClick={this._handleOpen}
                        src={this._getImagePathTreauserBox(boxOpened)}
                        alt="Treasure Box Opening"
                      />
                    </div>
                  )}
                  {boxOpened && (
                    <div className={cx("treasureBoxContainerOpening")}>
                      <img
                        // onClick={this._handleOpen}
                        src={this._getImagePathTreauserBox(true)}
                        alt="Treasure Box Unopened"
                      />
                    </div>
                  )}
                </Fragment>
              )}
              {isEmpty && (
                <Fragment>
                  <div className={cx("emtpyboxContainer")}>
                    <img
                      onClick={this._handleOpen}
                      src={"/assets/images/lecture/empty_box.png"}
                      alt="Empty"
                    />
                  </div>
                  <div className={cx("buttonContainer")}>
                    <div className={cx("endButtonContainer")}>
                      <Button onClick={this._endStudy}>Bye Bye!</Button>
                    </div>
                    <div className={cx("continueButtonContainer")}>
                      <Button onClick={this._continueStudy}>Get More</Button>
                    </div>
                  </div>
                </Fragment>
              )}
              {/*한꺼번에 열기 버튼*/}
              {!isEmpty && boxOpened === false && (
                <div className={cx("buttonContainer")}>
                  <div className={cx("endButtonContainer")}>
                    <Button onClick={this._handleOpenAll}>Open at Once</Button>
                  </div>
                </div>
              )}
              {!isEmpty && boxOpened && (
                <div className={cx("buttonContainerInactive")}>
                  <Button>Opening...</Button>
                </div>
              )}
            </div>
          }
        />

        <CustomModal
          visible={modalRatingVisible}
          close={false}
          handleModalVisible={this.handleModalRatingVisible}
          style={{
            width: width,
            height: height,
          }}
          body={
            <div className={cx("modalRatingBodyContainer")}>
              <div className={cx("modalScoreContainer", "flexColumnContainer")}>
                <h4>How was this lecture, {user.name}</h4>
                {points && (
                  <div className={cx("reviewDonePointContainer")}>
                    {points.review_done} points
                  </div>
                )}
                <div className={cx("individualScoreContainer")}>
                  <Rate
                    allowHalf
                    defaultValue={0.0}
                    onChange={this.handleFunChange}
                    value={fun}
                  />
                </div>
                {/*<div className={cx('individualScoreContainer')}>*/}
                {/*    <img*/}
                {/*        src={('/assets/images/roadmap/ic_difficult_tears.png')}*/}
                {/*        alt=""/>*/}
                {/*    어렵지수*/}
                {/*    <Rate allowHalf defaultValue={0.0} onChange={this.handleDifficultyChange}*/}
                {/*          value={difficulty}/>*/}
                {/*    <span style={{fontSize: 16, width: 10}}>*/}
                {/*        /!*<Tooltip placement="topLeft" title="전혀 어렵지 않았다면 패스!" arrowPointAtCenter>*!/*/}
                {/*        /!*    <QuestionCircleOutlined />*!/*/}
                {/*        /!*</Tooltip>*!/*/}
                {/*    </span>*/}
                {/*    /!*<span style={{fontSize: 16, width: 10}}>{difficulty === 0 ? 'Pass' : difficulty}</span>*!/*/}
                {/*</div>*/}
              </div>
              <div
                className={cx("modalOpinionContainer", "flexColumnContainer")}
              >
                <h4>We'd like to hear from you!👂</h4>
                <p>Your opinion is a great help to course improvement.</p>
                <Input.TextArea
                  rows={4}
                  onChange={this.handleCheerupChange}
                  value={cheerup}
                  placeholder={
                    "This is optional. Feel free to give us any bug reports or suggestions."
                  }
                />
              </div>
              <div className={cx("buttonContainer")}>
                <Button
                  onClick={() => {
                    this.handleOk(true);
                  }}
                >
                  Continue to Code
                </Button>
              </div>
              <div className={cx("footer")}>
                <span
                  onClick={() => {
                    this.handleOk(false);
                  }}
                >
                  skip
                </span>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, lecture, pointInfo, lectureNav, homework } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    lecture,
    pointInfo,
    lectureNav,
    homework,
  };
}

const connectedLectureEn = connect(mapStateToProps, {
  getLecture: lectureActions.getLecture,
  getLectureNavInfo: lectureActions.getLectureNavInfo,
  getPointInfo: pointActions.getPointInfo,
  getCodeSnippet: codeSnippetActions.getCodeSnippet,
  getHomeworkItem: homeworkActions.getHomeworkItem,
})(LectureEn);
export { connectedLectureEn as LectureEn };
