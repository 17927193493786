import { useState } from "react";
import { history } from "../../../../_helpers";
import { getDateDifference } from "../../../businesslogics/common/date";
import { getProgress } from "../ClassroomCards/ClassroomCard/ClassroomCardSummary/ClassroomCardSummary";
import { getClassroomModalData } from "../_services/getClassroomModalData";

interface UseEnterCourseButtonProps {
  isStarted: boolean;
  isGovCredit: boolean;
  progressRate: number;
  isEnded: boolean;
  startDate: Date;
  enrolledId: string;
  roundId: string;
  cutOffLine: string;
  isKeptForever: boolean;
  isKeepable: boolean;
  isTargetRetry: boolean;
  extensionCourse: any;
  endDay: Date;
  user: any;
}

interface ButtonState {
  text: string;
  onEnter?: () => void;
  isModalOpen: boolean;
  modalData?: any;
  subText?: string;
}

export const useEnterCourse = ({
  isStarted,
  isGovCredit,
  progressRate,
  isEnded,
  startDate,
  enrolledId,
  roundId,
  cutOffLine,
  isKeepable,
  isKeptForever,
  isTargetRetry,
  extensionCourse,
  endDay,
  user,
}: UseEnterCourseButtonProps): ButtonState => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { retryModalData, extendModalData } = getClassroomModalData({
    cutOffLine,
    enrolledId,
    roundId,
    extensionCourse,
    user,
  });

  if (!isStarted)
    return handleNotStartedCourse({ isGovCredit, startDate }, isModalOpen);
  if (isEnded)
    return handleEndedCourse(
      { isTargetRetry, enrolledId, roundId },
      isModalOpen,
      setIsModalOpen,
      retryModalData,
      extendModalData
    );
  return handleOngoingCourse(
    { enrolledId, roundId, progressRate, isKeepable, isKeptForever, endDay },
    isModalOpen
  );
};

const handleNotStartedCourse = (
  {
    isGovCredit,
    startDate,
  }: Pick<UseEnterCourseButtonProps, "isGovCredit" | "startDate">,
  isModalOpen: boolean
): ButtonState => {
  if (!isGovCredit) {
    return {
      text: `개강일 ${getDateDifference(startDate, new Date())}일 남음`,
      isModalOpen,
    };
  }
  return {
    text: "개강 전 오리엔테이션",
    onEnter: () =>
      window.open(
        "https://teamsparta.notion.site/8ff3799cec5448f2a387a9f34d1d1afe?pvs=4",
        "blank"
      ),
    isModalOpen,
  };
};

const handleEndedCourse = (
  {
    isTargetRetry,
    enrolledId,
    roundId,
  }: Pick<
    UseEnterCourseButtonProps,
    "isTargetRetry" | "enrolledId" | "roundId"
  >,
  isModalOpen: boolean,
  setIsModalOpen: (value: boolean) => void,
  retryModalData: any,
  extendModalData: any
): ButtonState => {
  const baseState = {
    onEnter: () => setIsModalOpen(true),
    isModalOpen,
    modalData: {
      visible: isModalOpen,
      handleVisible: setIsModalOpen,
    },
  };

  if (isTargetRetry) {
    return {
      ...baseState,
      text: "재수강 시작하기",
      modalData: { ...baseState.modalData, ...retryModalData },
    };
  }
  return {
    ...baseState,
    text: "수강 기간 연장하기",
    modalData: { ...baseState.modalData, ...extendModalData },
  };
};

const handleOngoingCourse = (
  {
    enrolledId,
    roundId,
    progressRate,
    isKeepable,
    isKeptForever,
    endDay,
  }: Pick<
    UseEnterCourseButtonProps,
    | "enrolledId"
    | "roundId"
    | "progressRate"
    | "isKeepable"
    | "isKeptForever"
    | "endDay"
  >,
  isModalOpen: boolean
): ButtonState => ({
  text: "수업 들으러 가기",
  onEnter: () =>
    history.push(`enrolleds/${enrolledId}/rounds/${roundId}/roadmap`),
  subText: isKeepable
    ? getKeepableText({ progressRate, isKeptForever, endDay })
    : undefined,
  isModalOpen,
});

const getKeepableText = ({
  progressRate,
  isKeptForever,
  endDay,
}: Pick<
  UseEnterCourseButtonProps,
  "progressRate" | "isKeptForever" | "endDay"
>): string | undefined =>
  Number(getProgress(progressRate)) > 99 &&
  !isKeptForever &&
  endDay.getTime() > new Date().getTime()
    ? `${
        endDay.getMonth() + 1
      }월 ${endDay.getDate()}일에 평생 소장으로 전환됩니다.`
    : undefined;
