import Portal from "../_layout/portal/Portal";
import * as S from "./modalBottomSheetWrapper.style";
import { useModal } from "../../../hooks/common/useModal";
import PlayerPortal from "../_layout/portal/PlayerPortal";
import { useCallback, useEffect, useState } from "react";

export const ModalBottomSheetWrapper = ({ children, isModalShow, toggle }) => {
  return (
    <Portal>
      {isModalShow && (
        <S.ModalBackground onClick={() => toggle()}>
          <S.ModalWrapper>{children}</S.ModalWrapper>
        </S.ModalBackground>
      )}
    </Portal>
  );
};

export const ConfirmModal = ({
  children,
  isModalShow,
  setIsModalShow,
  onConfirm,
}) => {
  const handleConfirm = () => {
    setIsModalShow(false);
    onConfirm();
  };

  return (
    <>
      {isModalShow && (
        <Portal>
          <S.ConfirmModalBackground>
            <S.ConfirmModalContainer>
              {children}
              <S.ConfirmButtonWrapper>
                <S.ConfirmButton onClick={handleConfirm}>확인</S.ConfirmButton>
              </S.ConfirmButtonWrapper>
            </S.ConfirmModalContainer>
          </S.ConfirmModalBackground>
        </Portal>
      )}
    </>
  );
};

const useBufferedModalShow = (isModalShow) => {
  const [bufferedModalShow, setBufferedModalShow] = useState(isModalShow);
  useEffect(() => {
    let timeId;
    if (!isModalShow) {
      timeId = setTimeout(() => {
        setBufferedModalShow(isModalShow);
      }, 30000);
    } else {
      setBufferedModalShow(isModalShow);
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [isModalShow]);
  return bufferedModalShow;
};

export const useModalAndBottomSheet = () => {
  const [isModalShow, toggle] = useModal();
  const ModalBottomSheetWrapper = useCallback(
    ({ children }) => {
      return (
        <Portal>
          {isModalShow && (
            <S.ModalBackground onClick={() => toggle()}>
              <S.ModalWrapper
                onClick={(e) => e.stopPropagation()}
                isClosing={!isModalShow}
              >
                {children}
              </S.ModalWrapper>
            </S.ModalBackground>
          )}
        </Portal>
      );
    },
    [isModalShow]
  );
  return [ModalBottomSheetWrapper, toggle];
};

export const useModalAndBottomSheetOnPlayer = () => {
  const [isModalShow, toggle] = useModal();
  const ModalBottomSheetWrapper = ({ children }) => {
    return (
      <PlayerPortal>
        {isModalShow && (
          <S.ModalBackground onClick={() => toggle()}>
            <S.ModalWrapper
              onClick={(e) => e.stopPropagation()}
              isClosing={!isModalShow}
            >
              {children}
            </S.ModalWrapper>
          </S.ModalBackground>
        )}
      </PlayerPortal>
    );
  };
  return [ModalBottomSheetWrapper, toggle];
};
