import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./CheckinCard.module.scss";
import { connect } from "react-redux";
import privateInstance from "../../../../_helper/axios";
import { BubblingHeart } from "../../../../components/organisms/BubblingHeart";
import {
  sendUserAction,
  USER_ACTIONS,
  USER_PAGES,
} from "../../../../businesslogics/logging";
import { keyword2icon } from "../../../../assets/icons/keyword2icon";

const cx = classNames.bind(styles);
const keyword2icons = keyword2icon;

function CheckinCard({ checkin, isHero }) {
  const keyword = checkin.courses.keyword;
  const [liked, setLiked] = useState(checkin.liked);
  const [likes, setLikes] = useState(checkin.likes);
  const [showHeart, setShowHeart] = useState(false);
  const heart = "/assets/icons/ic_heart.svg";
  const o_heart = "/assets/icons/ic_o_heart.svg";
  const ic_idx =
    keyword in keyword2icons ? keyword2icons[keyword] : keyword2icons["web"];
  const likeCheckin = async () => {
    if (liked) return;
    sendUserAction(USER_ACTIONS.LIKE_CHECKIN, USER_PAGES.PAGE_CHECKIN);
    return await privateInstance
      .post(`/checkins/${checkin._id}`)
      .then((resp) => {
        const result = resp.data;
        if (result.ok) {
          setLikes(likes + 1);
          setLiked(true);
          setShowHeart(true);
        } else {
          alert(result.reason);
        }
      });
  };
  return (
    <div
      className={
        checkin.is_mine
          ? cx("checkinCard", "mine")
          : isHero
          ? cx("checkinCard", "hero")
          : cx("checkinCard")
      }
    >
      <div className={cx("checkinCardContent")}>
        <p>{checkin.comment}</p>
      </div>
      <div className={cx("checkinCardAuthor")}>
        {isHero && (
          <div className={cx("checkinCardAuthorCourse")}>
            <img src={`/assets/icons/course/ic_course_${ic_idx}.png`} />
            <span>{checkin.courses.title}</span>
            <span className={cx("before")}>{checkin.time}</span>
          </div>
        )}
        {!isHero && (
          <>
            <div className={cx("checkinCardAuthorDetail")}>
              <img src={`/assets/icons/rtan/rtan_thumb_${checkin.num}.png`} />
              <span className={cx("author")}>{checkin.users.name}</span>
              <span className={cx("before")}>{checkin.time}</span>
            </div>
            <div className={cx("checkinCardAuthorCourse")}>
              <img src={`/assets/icons/course/ic_course_${ic_idx}.png`} />
              <span>{checkin.courses.title}</span>
            </div>
          </>
        )}
      </div>
      <div
        className={
          liked ? cx("checkinCardHeart", "liked") : cx("checkinCardHeart")
        }
        onClick={() => {
          likeCheckin();
        }}
      >
        <img src={liked ? o_heart : heart} />
        <span>{likes}</span>
        {showHeart && <BubblingHeart count={5} />}
      </div>
      {checkin.is_mine && <BubblingHeart count={10} />}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedCheckinCard = connect(mapStateToProps, {})(CheckinCard);
export { connectedCheckinCard as CheckinCard };
