import * as S from "./Notice.style";

export const Notice = () => {
  return (
    <S.Container>
      <S.Title>이벤트 유의사항</S.Title>
      <S.ContentContainer>
        <div>
          <S.SubTitle>이벤트 참여방법</S.SubTitle>
          <S.Ul>
            <S.listItem>
              <span>로그인 후</span> 초대링크 복사 버튼을 클릭해서 공유해야만
              친구추천 혜택을 받을 수 있습니다.
            </S.listItem>
            <S.listItem>
              URL을 복사 붙여넣기 해서 공유할 경우 친구추천이 적용되지 않습니다.
            </S.listItem>
          </S.Ul>
        </div>
        <div>
          <S.SubTitle>이벤트 대상</S.SubTitle>
          <S.Ul>
            <S.listItem>
              이달의 월간코딩은 누구나 무료로 수강신청할 수 있습니다. 초대할 수
              있는 친구 수 제한이 없으니 많이 초대하고 모든 월간코딩을
              들어보세요.
            </S.listItem>
          </S.Ul>
        </div>
        <div>
          <S.SubTitle>이벤트 기간</S.SubTitle>
          <S.Ul>
            <S.listItem>
              본 이벤트 참여 기간은 당사 사정에 따라 사전 고지없이 종료될 수
              있습니다.
            </S.listItem>
          </S.Ul>
        </div>
        <div>
          <S.SubTitle>이벤트 혜택</S.SubTitle>
          <S.Ul>
            <S.listItem>
              나의 초대링크로 친구가 &apos;이달의 월간코딩&apos; 수강신청 시,
              유료 월간코딩을 무료로 수강할 수 있는 쿠폰 1매가 발급됩니다.
            </S.listItem>
            <S.listItem>
              월간코딩 무료 쿠폰의 사용기한은 마이페이지 내 쿠폰 정보를
              확인해주세요.
            </S.listItem>
          </S.Ul>
        </div>
      </S.ContentContainer>
    </S.Container>
  );
};
