import * as S from "./LastSeenLecture.style";
import { connect } from "react-redux";
import { enrolledActions, lectureActions } from "../../../_state/actions";
import { getPlaytime } from "../../../pages/RoadmapPage/RoundWeek/utils";
import useNavigateToLectureHomework from "../../../hooks/roadmap/useGetLastSeenLecture";
import { useLastSeenData } from "../../../queries/lecture";
import { useVideoUrlByLectureId } from "../../../hooks/roadmap/useVideoUrlByLectureId";
import { useEffect, useRef, useState } from "react";
import { useSetLastSeenEnrolledData } from "../../../hooks/roadmap";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useSyllabusUnit } from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

function LastSeenLecture({ rounds, enrolled, lecture_v2, getLectureV2 }) {
  const lectureTitleRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { goHomework, goLecture } = useNavigateToLectureHomework(
    rounds,
    enrolled
  );
  const [lectureToShow, setLectureToShow] = useState(null);
  const { round_id } = enrolled.data;
  const lastSeenData = useLastSeenData(enrolled.data?.enrolled_id);
  const {
    targetLectureId,
    enrolledDetailId,
    lectureWeek,
    lectureWeekOrder,
    lecturePlaytime,
    lectureTitle,
  } = useSetLastSeenEnrolledData(lastSeenData, lectureToShow);
  const videoUrl = useVideoUrlByLectureId(
    lecture_v2,
    getLectureV2,
    targetLectureId
  );
  const syllabusUnit = useSyllabusUnit(enrolled.data?.course_id);
  const logLastSeenLecture = () => {
    sendCPLog("scc_roadmapPage_click_button", {
      button_text: "이어서 수강하기",
      enrolled_id: enrolled.data?.enrolled_id,
      course_id: enrolled.data?.course_id,
      course_title: enrolled.data?.courses.title,
      lectureType: lastSeenData.data?.is_homework ? "숙제" : "강의",
    });
  };
  useEffect(() => {
    if (rounds) {
      setLectureToShow(rounds.weeks[0]?.enrolled_details[0]);
    }
  }, [rounds]);

  useEffect(() => {
    const textWidth = lectureTitleRef.current?.offsetWidth;
    const containerWidth = lectureTitleRef.current?.parentElement?.offsetWidth;

    if (textWidth > containerWidth * 0.9) {
      setShouldAnimate(true);
    }
  }, [lectureTitle]);

  if (!lastSeenData.isSuccess) {
    return <>Loading...</>;
  }
  const {
    enrolled_detail_id,
    homework_detail_id,
    homework_week,
    homework_title,
    is_homework,
  } = lastSeenData.data;

  if (is_homework) {
    return (
      <S.HomeWorkWrapper>
        <S.HomeWorkImageAndTitleWrapper>
          <S.NextHomeWorkThumbnail
            src={`/images/homework.svg`}
            alt={"숙제아이콘"}
          />
          <S.TitleContainer homeWorkWeek={homework_week}>
            <S.LectureWeek>
              {homework_week}
              {syllabusUnit} 숙제
            </S.LectureWeek>
            <S.HomeworkTitle homeWorkWeek={homework_week}>
              {homework_title}
            </S.HomeworkTitle>
          </S.TitleContainer>
        </S.HomeWorkImageAndTitleWrapper>
        <S.ButtonContainer
          onClick={() => {
            logLastSeenLecture();
            goHomework(homework_detail_id);
          }}
        >
          이어서 수강하기
          {/* Notice: 기존 '숙제 제출하기'에서 KDC 숙제 페이지가 리뉴얼 되면서 cta명이 수강생에게 혼선을 줄 수 있다고 판단되어 "이어서 수강하기"로 통일*/}
        </S.ButtonContainer>
      </S.HomeWorkWrapper>
    );
  } else {
    return (
      <S.Wrapper>
        <S.MobileWrapper>
          <S.NextLectureThumbnail>
            <video
              src={videoUrl}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                objectFit: "cover",
              }}
              muted={true}
            />
            <S.LectureTime>{getPlaytime(lecturePlaytime, true)}</S.LectureTime>
          </S.NextLectureThumbnail>
          <S.TitleContainer homeWorkWeek={lectureWeek}>
            <S.LectureWeek>
              {lectureWeek}
              {syllabusUnit} {lectureWeekOrder}강
            </S.LectureWeek>
            <S.LectureTitle shouldAnimate={shouldAnimate}>
              <S.AnimatedText
                ref={lectureTitleRef}
                shouldAnimate={shouldAnimate}
              >
                <span>{lectureTitle}</span>
                {shouldAnimate && <span>{lectureTitle}</span>}
              </S.AnimatedText>
            </S.LectureTitle>
          </S.TitleContainer>
        </S.MobileWrapper>

        <S.ButtonContainer
          onClick={() => {
            logLastSeenLecture();
            goLecture(round_id, targetLectureId, enrolledDetailId);
          }}
        >
          {enrolled_detail_id === "" && homework_detail_id === ""
            ? "수강 시작하기"
            : "이어서 수강하기"}
        </S.ButtonContainer>
      </S.Wrapper>
    );
  }
}

function mapStateToProps(state) {
  const { enrolled, lecture_v2, enrolledDoneCount } = state;
  return {
    enrolled,
    enrolledDoneCount,
    lecture_v2,
  };
}

const connectedLastSeenLecture = connect(mapStateToProps, {
  doEnrolledDetail: enrolledActions.doEnrolledDetail,
  getLectureV2: lectureActions.getLectureV2,
})(LastSeenLecture);
export { connectedLastSeenLecture as LastSeenLecture };
