import styled from "@emotion/styled";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const EnterButton = styled.button<{ retry?: boolean }>`
  width: 205px;
  height: 48px;
  background: #e8344e;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0;
  font-family: $spoqa-hans-neo;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  border: none;
  cursor: pointer;

  :disabled {
    cursor: default;
    opacity: 0.8;
  }
`;
