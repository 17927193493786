import React, { useEffect, useState } from "react";
import * as S from "./SettingBottomSheet.style";
import { useIsSettingBottomSheetOpen } from "../../stores/playerAtom";
import { usePlaybackSpeedControl } from "../../hooks/usePlaybackSpeedControl";
import { useQualityControl } from "../../hooks/useQualityControl";
import { useScreenControl } from "../../hooks/useScreenControl";
import {
  SpeedOption,
  PipOption,
  CaptionOption,
  QualityOption,
} from "./BottomSheetComponent";

export const SettingBottomSheet = React.memo(({ videoRef, playerType }) => {
  const [isSettingBottomSheetOpen, setIsSettingBottomSheetOpen] =
    useIsSettingBottomSheetOpen(false);
  const [, setIsSpeedOptionOpen] = useState(false);
  const [, setIsCaptionOptionOpen] = useState(false);
  const [, setIsPipOptionOpen] = useState(false);
  const [, setIsQualityOptionOpen] = useState(false);
  const [currentOptionTitle, setCurrentOptionTitle] = useState("영상설정");
  const [currentOptionContent, setCurrentOptionContent] = useState(null);

  const { speedOptions, playbackSpeed, onSpeedChange } =
    usePlaybackSpeedControl(videoRef);
  const { quality, qualityOptions, onQualityChange } = useQualityControl(
    videoRef,
    playerType
  );
  const { isCaptionOn, isPipMode, onPipChange, onCaptionChange } =
    useScreenControl(videoRef);

  useEffect(() => {
    if (isSettingBottomSheetOpen) {
      setIsSettingBottomSheetOpen(false);
    }
  }, [videoRef]);

  useEffect(() => {
    if (isSettingBottomSheetOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isSettingBottomSheetOpen]);

  const BackgroundOverlay = ({ onClick }) => {
    return <S.BackgroundOverlay onClick={onClick} />;
  };

  const changeTitleAndContent = (title, content) => {
    setCurrentOptionTitle(title);
    setCurrentOptionContent(content);
  };

  const renderSpeedOption = () => {
    setIsSpeedOptionOpen(false);
    return (
      <SpeedOption
        speedOptions={speedOptions}
        selectedSpeedOption={playbackSpeed}
        onSpeedChange={onSpeedChange}
        changeTitleAndContent={changeTitleAndContent}
      />
    );
  };

  const renderCaptionOption = () => {
    setIsCaptionOptionOpen(false);
    return (
      <CaptionOption
        isCaptionOn={isCaptionOn}
        onCaptionChange={onCaptionChange}
        changeTitleAndContent={changeTitleAndContent}
      />
    );
  };

  const renderPipOption = () => {
    setIsPipOptionOpen(false);
    return (
      <PipOption
        isPipeMode={isPipMode}
        onPipChange={onPipChange}
        changeTitleAndContent={changeTitleAndContent}
      />
    );
  };

  const renderQualityOption = () => {
    setIsQualityOptionOpen(false);
    return (
      <QualityOption
        qualityOptions={qualityOptions}
        selectedQualityOption={quality}
        onQualityChange={onQualityChange}
        changeTitleAndContent={changeTitleAndContent}
      />
    );
  };
  const renderBackgroundClick = () => {
    setIsSettingBottomSheetOpen(false);
  };

  return (
    <>
      {isSettingBottomSheetOpen && (
        <BackgroundOverlay onClick={renderBackgroundClick} />
      )}
      <S.BottomSheetWrapper open={isSettingBottomSheetOpen}>
        <S.BottomSheetHeader>
          <S.TitleWrapper>{currentOptionTitle}</S.TitleWrapper>
          {currentOptionTitle !== "영상설정" ? (
            <S.BackButton
              src={"/assets/closeIcon.svg"}
              onClick={() => changeTitleAndContent("영상설정", null)}
            />
          ) : (
            <S.ExitButton
              src={"/assets/closeIcon.svg"}
              onClick={() => {
                setIsSettingBottomSheetOpen(false);
              }}
            />
          )}
        </S.BottomSheetHeader>
        {currentOptionContent === null ? (
          <S.OptionsContainer>
            <S.OptionWrapper
              onClick={() => {
                setIsSpeedOptionOpen(true);
                changeTitleAndContent("재생속도", renderSpeedOption());
              }}
            >
              <S.OptionIconWrapper
                src={"/v2/assets/icons/mobile/speedOption.svg"}
                alt={"재생속도"}
              />
              <S.OptionTitleWrapper>재생속도</S.OptionTitleWrapper>
              <S.OptionTextWrapper>{playbackSpeed}배</S.OptionTextWrapper>
            </S.OptionWrapper>
            <S.OptionWrapper
              onClick={() => {
                setIsCaptionOptionOpen(true);
                changeTitleAndContent("자막", renderCaptionOption());
              }}
            >
              <S.OptionIconWrapper
                src={"/v2/assets/icons/mobile/subtitleOption.svg"}
                alt={"자막"}
              />
              <S.OptionTitleWrapper>자막</S.OptionTitleWrapper>
              <S.OptionTextWrapper>
                {isCaptionOn ? "ON" : "OFF"}
              </S.OptionTextWrapper>
            </S.OptionWrapper>
            <S.OptionWrapper
              onClick={() => {
                setIsPipOptionOpen(true);
                changeTitleAndContent("PIP 모드", renderPipOption());
              }}
            >
              <S.OptionIconWrapper
                src={"/v2/assets/icons/mobile/pipOption.svg"}
                alt={"pip모드"}
              />
              <S.OptionTitleWrapper>PIP 모드</S.OptionTitleWrapper>
              <S.OptionTextWrapper>
                {isPipMode === true ? "ON" : "OFF"}
              </S.OptionTextWrapper>
            </S.OptionWrapper>
            <S.OptionWrapper
              onClick={() => {
                setIsQualityOptionOpen(true);
                changeTitleAndContent("화질", renderQualityOption());
              }}
            >
              <S.OptionIconWrapper
                src={"/v2/assets/icons/mobile/qualityOption.svg"}
                alt={"화질"}
              />
              <S.OptionTitleWrapper>화질</S.OptionTitleWrapper>
              <S.OptionTextWrapper>
                {quality === "Auto" ? quality : quality + "p"}
              </S.OptionTextWrapper>
            </S.OptionWrapper>
          </S.OptionsContainer>
        ) : (
          <S.OptionsContainer>{currentOptionContent}</S.OptionsContainer>
        )}
      </S.BottomSheetWrapper>
    </>
  );
});
