import { atom, useAtom } from "jotai";

const isPlayingAtom = atom(false);
const volumeAtom = atom(1);
const muteAtom = atom(false);
const isFullscreenAtom = atom(false);
const durationAtom = atom(0);
const playbackTimeAtom = atom(0);
const playbackSpeedAtom = atom(1);
const isControlBarActiveAtom = atom(false);
const isCaptionOnAtom = atom(false);
const qualityAtom = atom("Auto");
const isPipAtom = atom(false);
const isAutoPlayOnAtom = atom(true);
const isSettingBottomSheetOpenAtom = atom(false);

export const useIsPlaying = () => useAtom(isPlayingAtom);
export const useVolume = () => useAtom(volumeAtom);
export const useMute = () => useAtom(muteAtom);
export const useIsFullscreen = () => useAtom(isFullscreenAtom);
export const useDuration = () => useAtom(durationAtom);
export const usePlaybackTime = () => useAtom(playbackTimeAtom);
export const usePlaybackSpeed = () => useAtom(playbackSpeedAtom);
export const useIsControlBarActive = () => useAtom(isControlBarActiveAtom);
export const useIsCaptionOn = () => useAtom(isCaptionOnAtom);
export const useQuality = () => useAtom(qualityAtom);
export const useIsPipMode = () => useAtom(isPipAtom);
export const useIsAutoPlayOn = () => useAtom(isAutoPlayOnAtom);
export const useIsSettingBottomSheetOpen = () =>
  useAtom(isSettingBottomSheetOpenAtom);

export const networkAtom = atom(null);
