import styled from "@emotion/styled";
import {
  additional,
  neutralDark,
  neutralDay,
  wBody3,
  wBody4,
  wCaption1,
  wCaption2,
} from "@teamsparta/design-system";

export const LectureSummaryWrapper = styled.div`
  width: 100%;
  z-index: 10;
`;

export const SummaryBlock = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding: 20px 16px;
  border-bottom: 1px solid ${neutralDark.gray100};
  background: ${neutralDay.white};
  gap: 10px;
  cursor: pointer;
`;

export const SummaryTitle = styled.span`
  ${wBody3};
  font-weight: 600;
  color: ${neutralDay.gray100};
`;

export const SummaryTime = styled.span`
  padding: 2px 4px;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  height: fit-content;
  gap: 10px;
  border-radius: 4px;
  margin-right: 6px;
  background: ${additional.blue10};
  ${wCaption1};
  letter-spacing: -0.4px;
  font-weight: 500;
  color: ${additional.blue100};
  cursor: pointer;
  :hover {
    background: #d3e3ff;
    & > div {
      display: flex;
    }
  }
`;

export const SummaryTimeHover = styled.div`
  display: none;
  border-radius: 6px;
  background: ${neutralDay.gray95};
  box-shadow: 0 8px 24px 0 rgba(129, 137, 143, 0.12);
  padding: 8px;
  align-items: center;
  gap: 6px;
  position: absolute;
  min-width: 82px;
  height: 32px;
  ${wCaption2};
  color: ${neutralDay.gray5};
  text-align: center;
  transform: translate(-18px, -40px);
  z-index: 3;
  &::after {
    z-index: 3;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px; // 화살표 크기 조절을 위한 값
    border-style: solid;
    border-color: ${neutralDay.gray95} transparent transparent transparent;
  }
`;

export const OpenSummaryButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummaryContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  padding: 24px 16px;
  align-self: stretch;
  background: ${neutralDay.gray0};
`;

export const SummaryContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryContentText = styled.div`
  display: flex;
  ${wBody4};
  color: ${neutralDay.gray80};
  margin-bottom: 1px;
  ::before {
    content: "•";
    margin-right: 8px;
    color: ${neutralDay.gray80};
  }
`;
