import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {broadcastActions} from "../../../_state/actions/broadcast.actions";
import {BroadcastListElem} from "../BroadcastListElem"
import classNames from "classnames/bind";
import styles from "./BroadcastList.module.scss";
import {useInterval} from "../../../hooks/useInterval";

const cx = classNames.bind(styles);

function BroadcastList({
                           isBroadListOpen,
                           setIsBroadListOpen,
                           broadcastList,
                           getBroadcastList,
                       }) {
    useEffect(() => {
        if (!broadcastList.loaded) {
            getBroadcastList();
        }
    }, [broadcastList.loaded]);

    return (
        <div>
            {isBroadListOpen &&
            <>
                <div className={cx('broadcast-list-bg')} onClick={() => {
                    setIsBroadListOpen(false)
                }}>
                </div>
                <div className={cx('broadcast-list')}>
                    {broadcastList.loaded &&
                    broadcastList.data.actions.map((data, idx) => {
                        return <BroadcastListElem data={data} key={idx} idx={idx}/>
                    })
                    }
                </div>
            </>
            }
        </div>

    )
}

function mapStateToProps(state) {
    const {authentication, broadcastList} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        broadcastList,
    }
}

const connectedBroadcastList = connect(mapStateToProps, {
    getBroadcastList: broadcastActions.getBroadcastList,
})(BroadcastList);
export {connectedBroadcastList as BroadcastList}