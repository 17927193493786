import { useEffect } from "react";
import { useAtom } from "jotai";
import { modalTypeAtom } from "../../stores/lectureModals";

export const useNbIntroModalData = (round, enrolledDetail) => {
  const [, setVisibleModal] = useAtom(modalTypeAtom);

  const modalData = {
    imgPath: "/images/lecture/modal_nb_intro.png",
    title: "국비과정 수강 규칙",
    contents: [
      <>
        강의 시간의 <span>50% 이상</span>을 학습해야{`\n`}
        지금 들은 강의의 수강 기록이 인정돼요.
      </>,
    ],
    btnText: "확인",
  };
  useEffect(() => {
    if (
      round.loaded &&
      enrolledDetail?.ok &&
      round.data.is_gov_credit &&
      enrolledDetail?.context?.order === 1 &&
      enrolledDetail?.context?.done === false
    ) {
      setVisibleModal("nbIntro");
    }
  }, [round.loaded, enrolledDetail?.ok]);

  return { modalData };
};

export const useNbSkipModalData = () => {
  const modalData = {
    imgPath: "/images/lecture/modal_nb_skip.png",
    title: "강의를 마저 듣고\n다음 강의를 수강해주세요.",
    content:
      "정부 지침에 따라, 국비지원 강의는\n50% 이상을 수강해야 수강 기록이 인정돼요.",
    btnText: "확인",
  };

  const checkSkipAvailable = (enrolledDetail) => {
    const {
      seen_date,
      seen_first_date,
      playtime: playtimesec,
    } = enrolledDetail;

    // 정부 크레딧 과목일 경우, 강의 시간의 50% 이상을 수강(체류)하지 않았을 경우 넘어가지 못함.
    // 현재시간
    const now = new Date();
    // 현재시간에서 강의를 보기 시작한 시간이 전체 강의 시간의 50% 이상인지 확인
    const time_stamp = seen_first_date
      ? new Date(seen_first_date)
      : new Date(seen_date);
    const stayedMilliseconds = now.getTime() - time_stamp.getTime();
    const stayedSeconds = Math.floor(stayedMilliseconds / 1000);
    const threshold = Math.floor(playtimesec / 2);

    return stayedSeconds >= threshold;
  };

  return { checkSkipAvailable, modalData };
};
