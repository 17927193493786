import privateInstance from "../../_helper/axios";

async function getIsSecretStoreAllowed(enrolled_id) {
  return await privateInstance
    .get(`/secretstore/enrolleds/${enrolled_id}`)
    .then((resp) => resp.data);
}

export const secretstoreService = {
  getIsSecretStoreAllowed,
};
