import styled from "@emotion/styled";
import { Device } from "../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 8px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-size: 16px;

  color: #212529;

  ${Device.Desktop} {
    font-size: 20px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 12px;
  ${Device.Desktop} {
    gap: 16px;
  }
`;

export const PkgName = styled.div`
  display: flex;
  align-items: center;

  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-size: 16px;

  ${Device.Desktop} {
    font-size: 18px;
  }

  color: #212529;
`;

export const Thumb = styled.img`
  width: 80px;
  height: 80px;
`;

export const TextContainner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  white-space: pre-line;
`;
export const Desc = styled.div`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  display: flex;
  align-items: center;

  color: #868e96;
  ${Device.Desktop} {
    font-size: 14px;
  }
`;
