import {
  ARTICLE_DATA,
  ARTICLE_HEADER,
  ARTICLE_RECOMMEND_BY_COURSE_ID,
} from "./model/recommendArticles.model";
import {
  Brand,
  COMPLETE_RECOMMEND,
  COMPLETE_RECOMMEND_BY_COURSE_ID,
} from "./model/recommendTracks.model";

interface Props {
  courseId: string;
  birthyear: number;
}

const checkIs18To34Age = (birthyear: number) => {
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthyear;

  return age >= 18 && age <= 34;
};

export const getCompleteRecommendTrackData = ({
  courseId,
  birthyear,
}: Props) => {
  if (checkIs18To34Age(birthyear)) {
    return COMPLETE_RECOMMEND_BY_COURSE_ID[courseId] || COMPLETE_RECOMMEND.kdc;
  }
  return COMPLETE_RECOMMEND.kdc;
};

export const getPopularArticleData = ({ courseId, birthyear }: Props) => {
  if (checkIs18To34Age(birthyear)) {
    return (
      ARTICLE_RECOMMEND_BY_COURSE_ID[courseId] || [
        ARTICLE_DATA.nbc,
        ARTICLE_DATA.mingyu,
      ]
    );
  }
  return [ARTICLE_DATA.nbc, ARTICLE_DATA.mingyu];
};

export const getArticleHeader = ({ brand }: { brand: Brand }) => {
  return ARTICLE_HEADER[brand];
};
