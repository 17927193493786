import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./RoundWeekDetail.module.scss";
import { connect } from "react-redux";
import { RoundWeekDetailLecture } from "./RoundWeekDetailLecture";
import { sendLog } from "../../../businesslogics/logging";
import { useOnlineTheme } from "../../../queries/pirates";
import { ToggleSvg } from "./RoundWeek.svg";

const cx = classNames.bind(styles);

function RoundWeekDetail({
  expired,
  week,
  enrolled_details,
  roundWeek,
  enrolled,
}) {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!expired.loaded) {
      return;
    }
    setIsOpened(
      expired.data.achv.week === week &&
        (expired.data.achv.is_started || expired.data.achv.week === 0)
    );
  }, [expired.loaded]);

  useEffect(() => {
    if (enrolled_details[0].round_id === "616d7b24774493437e0a7f64") {
      setIsOpened(true);
    }
  }, [enrolled_details]);

  const theme = useOnlineTheme();

  return (
    <div className={cx("round-week-detail")}>
      <div className={cx("round-week-detail__header")}>
        <div className={cx("round-week-detail__header__title", theme.data)}>
          강의 목록
        </div>
        {roundWeek.is_active ? (
          <div
            className={cx("round-week-detail__header__cta", theme.data)}
            onClick={() => {
              setIsOpened(!isOpened);
            }}
          >
            <span
              className={cx("round-week-detail__header__cta__span", theme.data)}
              onClick={() => {
                sendLog(
                  `scc_roadmapPage_click_button`,
                  {
                    ...enrolled.data,
                    button_text: isOpened ? "목록 닫기" : "목록 열기",
                  },
                  ""
                );
              }}
            >
              목록 {isOpened ? "닫기" : "열기"}
            </span>
            <ToggleSvg theme={theme.data} isOpened={isOpened} />
          </div>
        ) : (
          <div className={cx("round-week-detail__header__cta__coming_soon")}>
            <span
              className={cx(
                "round-week-detail__header__cta__coming_soon__span"
              )}
            >
              coming soon
            </span>
          </div>
        )}
      </div>
      {isOpened && roundWeek.is_active && (
        <div className={cx("round-week-detail__body")}>
          {enrolled_details.map((detail, idx) => {
            return (
              <RoundWeekDetailLecture
                key={idx}
                expired={expired}
                week={week}
                detail={detail}
                details={enrolled_details}
                idx={idx}
                isTimeVisible={true}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, expired } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    expired,
  };
}

const connectedRoundWeekDetail = connect(mapStateToProps, {})(RoundWeekDetail);
export { connectedRoundWeekDetail as RoundWeekDetail };
