import React, { useEffect } from "react";
import { connect } from "react-redux";
import { params } from "../../../_helpers";
import { enrolledSummaryActions } from "../../_state/actions";
import axios from "../../../_helpers/axios";
import { FinishCelebratePage } from "./FinishCelebratePage";

function FinishPage({ getEnrolledFinishSummary, enrolledFinishSummary }) {
  const { enrolled_id } = params();

  useEffect(() => {
    if (!enrolledFinishSummary.loaded) getEnrolledFinishSummary(enrolled_id);
  }, []);

  useEffect(() => {
    axios
      .post("/enrolleds/finish", { enrolled_id })
      .then((resp) => {})
      .catch((error) => {});
  }, []);

  return enrolledFinishSummary.loaded ? (
    <FinishCelebratePage enrolledFinishSummary={enrolledFinishSummary} />
  ) : (
    <></>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolledFinishSummary } = state;
  const { user } = authentication;

  return {
    user,
    enrolledFinishSummary,
  };
}

const connectedFinishPage = connect(mapStateToProps, {
  getEnrolledFinishSummary: enrolledSummaryActions.getEnrolledFinishSummary,
})(FinishPage);
export { connectedFinishPage as FinishPage };
