import React from "react";
import * as S from "./ErrorTypeSelector.style";

import { useIsMobile } from "../../../../hooks/device";
import { ErrorReportSelectorItem } from "./ErrorReportSelectorItem";

export const ErrorTypeSelector = ({ setReportState }) => {
  const isMobile = useIsMobile();

  const errorTypes = [
    {
      title: "강의 내용 오류",
      description: "수강 중인 강의 내용에 오류가 있어요.",
      onClick: () => {
        setReportState("CONTENTS_ERROR_REPORT");
      },
    },
    {
      title: "수강 환경 오류",
      description: "버퍼링, 영상 소리 안들림 등 수강하는데 어려움이 있어요.",
      onClick: () => {
        openInNewTab("https://spartacodingclub.channel.io/support-bots/41731");
      },
    },
  ];
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <S.ErrorReportSelectorContainer>
      <S.ErrorReportSelectorTitle>
        어떤 유형의 오류인가요?
      </S.ErrorReportSelectorTitle>
      <S.ErrorReportSelectorContentWrapper>
        {errorTypes.map((error) => (
          <ErrorReportSelectorItem
            key={error.title}
            title={error.title}
            description={error.description}
            onClick={error.onClick}
            isMobile={isMobile}
          />
        ))}
      </S.ErrorReportSelectorContentWrapper>
    </S.ErrorReportSelectorContainer>
  );
};
