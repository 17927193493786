export const ConnectionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path d="M8.5 8.79517V15.7952H15.5" stroke="#9DA7AE" />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.66553 8.29517L5.99886 11.6285L13.3322 4.29517"
        stroke="#31B32E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DoneFeedbackCheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <circle
        cx="8"
        cy="8.29517"
        r="6"
        fill="#31B32E"
        stroke="#31B32E"
        strokeWidth="1.5"
      />
      <path
        d="M5.3335 8.64667L7.00016 10.1618L10.6668 6.82849"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SpartaLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="31"
      viewBox="0 0 60 31"
      fill="none"
    >
      <path
        d="M49.6285 26.3281V26.8701H50.1581V27.411H50.6877V27.953H51.2172V28.495H50.6877V29.0375H50.1581V29.5795H49.6285V30.1214H48.5693V29.5805H49.0989V29.0385H49.6285V28.496H50.1581V27.9541H49.6285V27.411H49.0989V26.8701H48.5693V26.3281H49.6285Z"
        fill="#E8344E"
      />
      <path
        d="M47.9339 26.376H47.0114V26.8987H46.4903V27.4313H45.9962V27.9546H45.4751V28.4867H44.9815V29.0099H44.4604V29.5425H43.9668V30.0746H44.8893V29.5425H45.3829V29.0193H45.9045V28.4867H46.3976V27.9639H46.9192V27.4313H47.4123V26.9081H47.9339V26.376Z"
        fill="#E8344E"
      />
      <path
        d="M38.2889 30.1225V29.5805H37.7594V29.0385H37.2298V28.496H36.7002V27.9541H37.2298V27.411H37.7594V26.8701H38.2889V26.3281H39.3481V26.8701H38.8185V27.411H38.2889V27.953H37.7594V28.495H38.2889V29.0375H38.8185V29.5795H39.3481V30.1214L38.2889 30.1225Z"
        fill="#E8344E"
      />
      <path
        d="M51.6989 0L3.5821 9.2157L0 20.6882L8.10315 29.8103L39.9761 23.7087L41.7544 30.2951L49.8491 21.8185L56.2195 20.5988L59.8016 9.12207L51.6989 0Z"
        fill="#E8344E"
      />
      <path
        d="M6.48277 18.1287V17.0635H5.47656V15.9982H7.60072L7.65368 17.0635H10.4499V14.9309H6.48277V13.8678H5.52952V11.7352H6.48277V10.6726H11.6796V11.7378H12.6328V12.8031H10.5092V11.7378H7.6558V13.8704H11.6806V14.933H12.6339V17.0655H11.6806V18.1308L6.48277 18.1287Z"
        fill="white"
      />
      <path
        d="M22.6748 18.1287V12.8031H23.4205V11.7378H24.505V10.6726H27.7588V11.7378H28.8428V12.8031H29.589V18.1287H27.7588V15.9962H24.505V18.1287H22.6748ZM24.505 14.933H27.7588V12.8005H26.6742V11.7378H25.5896V12.8031H24.505V14.933Z"
        fill="white"
      />
      <path
        d="M47.7109 18.1287V12.8031H48.4576V11.7378H49.5417V10.6726H52.7949V11.7378H53.8795V12.8031H54.6252V18.1287H52.7949V15.9962H49.5412V18.1287H47.7109ZM49.5412 14.933H52.7949V12.8005H51.7103V11.7378H50.6289V12.8031H49.5412V14.933Z"
        fill="white"
      />
      <path
        d="M42.2157 18.1287V11.7378H39.9316V10.6726H46.4392V11.7378H44.2149V18.1287H42.2157Z"
        fill="white"
      />
      <path
        d="M20.4429 11.7378V10.6726H15.3234V11.7472H14.5508V18.1287H16.2666V14.9164H20.4429V13.8511H21.1017V11.7378H20.4429ZM19.4155 13.8511H16.2666V11.7378H19.4129L19.4155 13.8511Z"
        fill="white"
      />
      <path
        d="M37.6971 17.0635V15.9982H36.6125V14.7666H37.633V13.8433H38.4427V11.7378H37.6971V10.6726H32.4754V11.7472H31.6953V18.1287H33.4699V14.9335H34.7192V16.1241H35.6391V17.0551H36.621L36.6141 18.1287H38.7827V17.0635H37.6971ZM33.4699 13.8688V11.7363H36.6141V12.802L36.621 13.8673L33.4699 13.8688Z"
        fill="white"
      />
    </svg>
  );
};
