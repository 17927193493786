import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./PaymentVBankPage.module.scss";
import { connect } from "react-redux";
import { paymentActionsV2 } from "../../_state/actions/payment.action";
import { NavContainer } from "../../../_containers";
import { numberWithCommas } from "../../_helper";
import { history } from "../../../_helpers";
import { FooterV2 } from "../../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);

const PaymentVBankPage = ({ paymentVBank, getPaymentVBank, match }) => {
  useEffect(() => {
    const { order_no } = match.params;
    if (!paymentVBank.loaded) getPaymentVBank(order_no);
  }, []);
  const goPreq = () => {
    if (paymentVBank.loaded) {
      const { course_id, round_id } = paymentVBank.data;
      history.push(`/payment/prequestion/${course_id}/${round_id}`);
    }
  };
  return (
    <div className={cx("VBankInfo-wrapper")}>
      <NavContainer />
      <h1 className={cx("VBankInfo-title")}>
        입금하실 가상계좌번호
        <br /> 안내드립니다!
      </h1>
      <p className={cx("VBankInfo-sub")}>
        반드시 입금기한 내에 입금하셔야
        <br /> 수강신청이 완료되니 유의하시길 바랍니다.
      </p>
      <p className={cx("VBankInfo-notice")}>
        *입금을 확인까지 약 5분 정도 소요됩니다.
      </p>
      <div className={cx("VBankInfo-container")}>
        <h3 className={cx("VBankInfo-container__header")}>가상계좌 정보</h3>
        {paymentVBank.loaded && (
          <>
            <div className={cx("VBankInfo-container__row")}>
              <div className={cx("VBankInfo-container__row__header")}>
                강의명
              </div>
              <div className={cx("VBankInfo-container__row__detail")}>
                {`${paymentVBank.data.curri} ${paymentVBank.data.round}`}
              </div>
            </div>
            <div className={cx("VBankInfo-container__row")}>
              <div className={cx("VBankInfo-container__row__header")}>
                가상계좌번호
              </div>
              <div className={cx("VBankInfo-container__row__detail")}>
                {`${paymentVBank.data.bank_name} ${paymentVBank.data.bank_num}`}
              </div>
            </div>
            <div className={cx("VBankInfo-container__row")}>
              <div className={cx("VBankInfo-container__row__header")}>
                예금주
              </div>
              <div className={cx("VBankInfo-container__row__detail")}>
                (주)팀스파르타
              </div>
            </div>
            <div className={cx("VBankInfo-container__row")}>
              <div className={cx("VBankInfo-container__row__header")}>
                입금기간
              </div>
              <div className={cx("VBankInfo-container__row__detail")}>
                {`${paymentVBank.data.expired_at.substr(
                  0,
                  4
                )}-${paymentVBank.data.expired_at.substr(
                  4,
                  2
                )}-${paymentVBank.data.expired_at.substr(6, 2)} 까지`}
              </div>
            </div>
            <div className={cx("VBankInfo-container__row")}>
              <div className={cx("VBankInfo-container__row__header")}>
                입금하실금액
              </div>
              <div
                className={cx(
                  "VBankInfo-container__row__detail",
                  "--isPrimary"
                )}
              >
                {numberWithCommas(paymentVBank.data.price)}원
              </div>
            </div>
          </>
        )}
      </div>
      {paymentVBank.loaded && paymentVBank.data.status === "paid" && (
        <div className={cx("VBankInfo-footer")}>
          <p className={cx("VBankInfo-footer__title")}>입금을 완료하셨다면?</p>
          <button className={cx("VBankInfo-footer__cta")} onClick={goPreq}>
            사전설문 하러가기{" "}
            <img alt={"ic_arrow"} src={`/v2/assets/icons/arrow-forward.svg`} />
          </button>
        </div>
      )}
      <FooterV2 />
    </div>
  );
};

function mapStateToProps(state) {
  const { authentication, paymentVBank } = state;
  const { user } = authentication;
  return { authentication, user, paymentVBank };
}
const connectedPaymentVBankPage = connect(mapStateToProps, {
  getPaymentVBank: paymentActionsV2.getPaymentVBank,
})(PaymentVBankPage);
export { connectedPaymentVBankPage as PaymentVBankPage };
