import React from "react";
import classNames from "classnames/bind";
import styles from "./Modal.module.scss";
import { useLockScroll } from "@teamsparta/auth-frontend";

const cx = classNames.bind(styles);

function Modal({
  visible,
  handleVisible,
  closeBtn,
  style,
  header,
  mobileHeader,
  img_path,
  img_style,
  video_path,
  content,
  mobileContent,
  cta,
  ctaList,
  subCta,
  tailInfo,
  isContentHidden,
  isCtaHidden,
  handleCloseModal,
  isBackgroundClickable = true,
  styleName = "",
}) {
  function closeModal() {
    handleVisible(false);
    if (handleCloseModal) {
      handleCloseModal();
    }
  }

  const { checkPlatform } = window;
  const platform = checkPlatform();
  const isMobile = platform === "mobile";
  const { btn_text, btnStyleName, handleCTAClick } = cta || {};

  useLockScroll(visible);

  return (
    <>
      <div
        className={cx("bg", `${visible ? "--active" : ""}`)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return isBackgroundClickable ? closeModal() : "";
        }}
      >
        <div
          className={cx(
            `modal`,
            `${styleName}`,
            `${visible ? "--active--flex" : ""}`,
            `${styleName}`
          )}
          style={{ ...style }}
        >
          {header && !(mobileHeader && isMobile) && (
            <div className={cx("modal__header")}>{header}</div>
          )}
          {mobileHeader && isMobile && (
            <div className={cx("modal__header")}>{mobileHeader}</div>
          )}
          {img_path && (
            <img
              className={cx(`modal__img${img_style ? img_style : ""}`)}
              alt=""
              src={img_path}
            />
          )}
          {video_path && (
            <video
              className={cx("modal__video")}
              src={video_path}
              muted={true}
              loop={true}
              autoPlay={true}
            />
          )}
          {!isContentHidden &&
            (!mobileContent || (mobileContent && !isMobile)) && (
              <div className={cx("modal__content", styleName)}>{content}</div>
            )}
          {!isContentHidden && mobileContent && isMobile && (
            <div className={cx("modal__content", styleName)}>
              {mobileContent}
            </div>
          )}
          {!isCtaHidden && ctaList && (
            <div className={cx("modal__cta-container", styleName)}>
              {ctaList.map((cta) => {
                const { btn_text, btnStyleName, handleCTAClick } = cta;
                return (
                  <div
                    className={cx(
                      "modal__cta",
                      `${btnStyleName ? btnStyleName : ""}`
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCTAClick();
                      handleVisible(false);
                      e.stopPropagation();
                    }}
                  >
                    {btn_text}
                  </div>
                );
              })}
            </div>
          )}
          {!isCtaHidden && btn_text && (
            <div
              className={cx(
                "modal__cta",
                `${btnStyleName ? btnStyleName : ""}`
              )}
              onClick={(e) => {
                e.preventDefault();
                handleCTAClick();
                handleVisible(false);
                e.stopPropagation();
              }}
            >
              {btn_text}
            </div>
          )}
          {subCta && (
            <div
              className={cx(
                "modal__subCta",
                `${subCta.btnStyleName ? subCta.btnStyleName : ""}`
              )}
              onClick={(e) => {
                e.preventDefault();
                handleVisible(false);
                subCta.handleSubCTAClick();
                e.stopPropagation();
              }}
            >
              {subCta.img_path && (
                <img
                  className={cx("modal__subCta__img")}
                  src={subCta.img_path}
                  alt=""
                />
              )}
              {subCta.sub_btn_text}
            </div>
          )}
          {closeBtn && (
            <img
              alt={""}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
                e.stopPropagation();
              }}
              src={`/assets/icons/ic_modal_close_black.svg`}
            />
          )}
          {tailInfo && <div className={cx("modal__tail-info")}>{tailInfo}</div>}
        </div>
      </div>
    </>
  );
}

export { Modal };
