import React, {Component} from 'react';
import {
    getApiNPSsubmit,
    getApiRcmdViralLogUrl,
    getApiRcmdViralUrl, getGETAuthOptions,
    getHomeworkUrl,
    getPostAuthOptions
} from "../../_urls";
import {Button, Radio, Slider} from "antd";
import classNames from "classnames/bind";
import styles from "./NPS.module.scss";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import FrownOutlined from "@ant-design/icons/lib/icons/FrownOutlined";
import {connect} from "react-redux";
import {lectureActions, pointActions} from "../../_actions";
import {getLectureUrl} from "../../_urls/lectures";
import {createEventId, handleResponse, history} from "../../_helpers";
import {CustomModal} from "../CustomModal"
import {CustomModal_MyFriend} from "../CustomModal_MyFriend";
import {getApiFreeCourseCTALogUrl, getApiFreeCourseCTAExposureLogUrl} from "../../_urls/logs.api";
import instance from "../../_helpers/axios";

const cx = classNames.bind(styles);

class NPSEn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            isSending: false,
            isShown: false,
            phone1: '',
            phone2: '',
            phone3: '',
            name1: '',
            name2: '',
            name3: '',
            satisfaction: '',
            good_point: '',
            bad_point: '',
            nps_score: 0,
            sat_done: false,
            score_done: false,
            is_viral: false,
            is_free: false,
            show_rcmd: false,
            show_cta_modal: false, // 무료강의 웹종 전환 CTA
        };
    }

    componentDidMount() {

    }

    _sendLog = async (action, eventID) => {
        let ua = window.navigator.userAgent;
        ua = ua.toLowerCase();
        let matched = {};

        const {_id: crm_9_unique_id} = this.props.user;
        const referrer = '';
        const url = document.location.href;
        const {nps_score} = this.state;
        const {user} = this.props;
        let user_id = user ? user._id : '';
        const platform_match = /(ipad)/.exec(ua) || /(ipod)/.exec(ua)
            || /(windows phone)/.exec(ua) || /(iphone)/.exec(ua)
            || /(kindle)/.exec(ua) || /(silk)/.exec(ua) || /(android)/.exec(ua)
            || /(win)/.exec(ua) || /(mac)/.exec(ua) || /(linux)/.exec(ua)
            || /(cros)/.exec(ua) || /(playbook)/.exec(ua)
            || /(bb)/.exec(ua) || /(blackberry)/.exec(ua)
            || [];

        matched.platform = platform_match[0] || "";
        let data = {
            user_id,
            referrer,
            url,
            action,
            platform: matched.platform,
            nps_score,
            event_id: eventID,
        };
        if (crm_9_unique_id) {
            data.crm_9_unique_id = crm_9_unique_id;
        }
        await instance.post('/logs/friend_reco/copy', data);
    };

    _goNextPage = () => {
        const {enrolled_id, week, round_id} = this.props.nps;
        const {next_id} = this.props;
        const {is_free} = this.state;
        if (!next_id) {
            alert('Finally, the course is over.\nCongratulations for your completion! 🏅');
            history.push(`/en/finish?enrolled_id=${enrolled_id}`);
            return;
        }
        let chosenUrl;
        if (next_id.includes('lectures')) {
            const lecture_id = next_id.split('/')[1];
            // this.props.getLecture(round_id, lecture_id);
            chosenUrl = '/en' + getLectureUrl(round_id, lecture_id);
        } else {

        }
        history.push(chosenUrl);
    };

    _handleScore = (e) => {
        let value = e.target.value;
        this.setState({
            nps_score: value,
            score_done: true,
            show_rcmd: Number(value) > 8,
        })
    };

    _goToRcmdPage = async () => {
        window.open('/rcmds');
        const eventID = createEventId();
        await this._sendLog('copy_from_promotion', eventID);
    };

    _submitNPS = () => {
        const {satisfaction, good_point, bad_point, nps_score, sat_done, score_done, isShown, is_viral, is_free} = this.state;
        if (sat_done === false) {
            alert('Please choose an option');
            return;
        }
        if (score_done === false) {
            alert('Please choose an option');
            return;
        }
        let data = {
            satisfaction,
            good_point,
            bad_point,
            nps_score
        };
        const {week, round_id} = this.props.nps;
        // if (!is_viral && nps_score > 8 && !isShown) {
        //     this.showModal();
        //     return;
        // }
        const endpoint = getApiNPSsubmit(round_id, week);
        const options = getPostAuthOptions(data);
        fetch(endpoint, options)
            .then(
                resp => {
                    if (resp.ok) {
                        const {next_id} = this.props;
                        const {confirm} = window;
                        if (!next_id) {
                            this._goNextPage();
                            return;
                        }

                        if (confirm(`Submitted successfully.\nLet's go to the next chapter`)) {
                            const {next_start_date, next_week_allowed, next_id} = this.props.homework.data;
                            // next_id === null 이면 완강한 경우이기 때문에 그냥 넘어간다.
                            if (next_id && !next_week_allowed) {
                                alert(`다음 주차는 ${next_start_date}부터 수강 가능합니다.`);
                                window.location.reload();
                                return;
                            }
                            this.props._setIsNpsShow(false);
                            this._goNextPage();
                        }
                    }
                },
                error => {
                    alert('제출할 수 없습니다.\n잠시 후 시도해주세요.');
                }
            )
    };

    _handleSat = e => {
        let value = e.target.value;
        this.setState({
            satisfaction: value,
            sat_done: true,
        })
    };

    _handleGoodPoint = (e) => {
        let value = e.target.value;
        this.setState({
            good_point: value,
        })
    };

    _handleBadPoint = (e) => {
        let value = e.target.value;
        this.setState({
            bad_point: value,
        })
    };


    render() {
        const {user, nps, next_id} = this.props;
        const {modalVisible, is_free, show_cta_modal, show_rcmd} = this.state;
        const {is_nps_done, week, course_id} = nps;
        const name = user.name;
        return (
            <div className={cx('npsContainer')}>
                {/*안내문*/}
                <div className={cx('infoContainer', 'contentsContainer')}>
                    <h2>How was Chap.{week}, {user.name}?</h2>
                    <p>Your opinion is a great help to course improvement. 😊</p>
                </div>

                <hr/>

                {/*satisfaction*/}
                <div className={cx('satContainer', 'contentsContainer')}>
                    <h4>Are you satisfied with the course?</h4>
                    <Radio.Group onChange={this._handleSat} value={this.state.satisfaction}>
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                    </Radio.Group>
                </div>

                {/*good_point*/}
                <div className={cx('goodContainer', 'contentsContainer')}>
                    <h4>What part of the course did you like most?</h4>
                    <textarea id="good_point" rows="4" placeholder={"Please write down your opinion here."}
                              onChange={this._handleGoodPoint}/>
                </div>

                {/*bad_point*/}
                <div className={cx('badContainer', 'contentsContainer')}>
                    <h4>What part of the coruse can be improved?</h4>
                    <textarea id="bad_point" rows="4" placeholder={"Please write down your opinion here."}
                              onChange={this._handleBadPoint}/>
                </div>

                {/*nps_score*/}
                <div className={cx('scoreContainer', 'contentsContainer')}>
                    <h4>How likely are you to recommend us to your friend or colleague? (1-10)</h4>
                    <div className={cx('slideContainer')}>
                        <Radio.Group onChange={this._handleScore} value={this.state.nps_score}>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={5}>5</Radio>
                            <Radio value={6}>6</Radio>
                            <Radio value={7}>7</Radio>
                            <Radio value={8}>8</Radio>
                            <Radio value={9}>9</Radio>
                            <Radio value={10}>10</Radio>
                        </Radio.Group>
                    </div>
                </div>

                {/*submit button*/}
                <div className={cx('buttonContainer')}>
                    <Button onClick={() => {
                        this._submitNPS()
                    }}
                            className={cx('enterButtonContainer')}>
                        {next_id &&
                        <span
                            className={cx('enterButtonTextContainer')}>Submit and Move On</span>
                        }
                        {!next_id &&
                        <span className={cx('enterButtonTextContainer')}>Submit and Complete🥇</span>
                        }
                    </Button>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {authentication, homework} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        homework,
    }
}

const connectedNPSEn = connect(mapStateToProps, {
    getLecture: lectureActions.getLecture,
})(NPSEn);
export {connectedNPSEn as NPSEn}