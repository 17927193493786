import { SendBufferingLog, sendLog } from "../../../../businesslogics/logging";
import { jwtDecode } from "../../../../../_helpers/jwt";
import { refreshHeader } from "../../../../../_helpers";
import { config } from "../../../../../config";
import videojs from "video.js";
import privateInstance from "../../../../_helper/axios";
import { getCookie } from "../../../../_helper";

export const setRequestHeader = (LICENSE_SERVER_URL) => {
  // Requeset Header 세팅
  videojs.Vhs.xhr.beforeRequest = function (options) {
    if (options.uri === LICENSE_SERVER_URL) {
      if (options.headers === undefined) {
        options.headers = {};
      }
      const token = getCookie("token");
      const user_token = getCookie("user_token");
      options.headers["Authorization"] = token
        ? "Bearer " + token
        : "Bearer " + user_token;
    }
    return options;
  };
};
export const onBufferStart = () => {
  return new Date();
};
const matchPlatform = (ua) => {
  return (
    /(ipad)/.exec(ua) ||
    /(ipod)/.exec(ua) ||
    /(windows phone)/.exec(ua) ||
    /(iphone)/.exec(ua) ||
    /(kindle)/.exec(ua) ||
    /(silk)/.exec(ua) ||
    /(android)/.exec(ua) ||
    /(win)/.exec(ua) ||
    /(mac)/.exec(ua) ||
    /(linux)/.exec(ua) ||
    /(cros)/.exec(ua) ||
    /(playbook)/.exec(ua) ||
    /(bb)/.exec(ua) ||
    /(blackberry)/.exec(ua) ||
    []
  );
};

export const onBufferEnd = (lastBufferStarted, lecture_v2, is_mp4) => {
  const lecture_id = lecture_v2.data["lecture_id"];
  const version = lecture_v2.data["version"];
  const vod_key = lecture_v2.data["vod_key"];
  if (!lastBufferStarted) return;

  const diff = new Date() - new Date(lastBufferStarted);
  lastBufferStarted = null;
  const hackleLogKey = is_mp4
    ? "lecture_buffered_s3"
    : "lecture_buffered_s3_hls";

  const network = navigator.connection;
  const ua = navigator.userAgent;
  const platform = matchPlatform(ua);

  const data = {
    key: hackleLogKey,
    value: diff,
    lecture_id: lecture_id,
    version: version,
    vod_key: vod_key,

    network: {
      onchange: network.onchange,
      effectiveType: network.effectiveType,
      rtt: network.rtt,
      downlink: network.downlink,
      saveData: network.saveData,
      platform: platform,
    },
  };

  process.env.REACT_APP_IS_DEV === "false" &&
    SendBufferingLog(hackleLogKey, data);
};

export const getRefreshToken = (LICENSE_SERVER_URL, is_refreshed) => {
  const _token = getCookie("token")
    ? getCookie("token")
    : getCookie("user_token");
  const { exp } = jwtDecode(_token).payload;
  const currentTime = parseInt(new Date().getTime() / 1000);

  // 기한이 만료된 경우
  if (currentTime > exp) {
    const refreshHeaderOption = refreshHeader();
    const requestOptions = {
      method: "POST",
      headers: refreshHeaderOption,
      credentials: "include",
    };

    fetch(`${config.apiUrl}/v1/auth/refresh`, requestOptions).then(
      (response) => {
        response.json().then((data) => {
          if (response.ok) {
            const { token } = data.data; // 새로 만들어진 token

            // video request Header 재세팅
            videojs.Vhs.xhr.beforeRequest = function (options) {
              if (options.uri === LICENSE_SERVER_URL) {
                if (options.headers === undefined) {
                  options.headers = {};
                }
                options.headers["Authorization"] = "Bearer " + token;
              }
              return options;
            };

            // refreshed
            is_refreshed = true;
          }
        });
      }
    );
  }
};

export const convertToMp4_not_source = async (
  player,
  MP4URL,
  enrolledDetail,
  user,
  lecture_v2
) => {
  const lecture_id = lecture_v2.data["lecture_id"];
  player.src({
    src: MP4URL,
    type: "video/mp4",
  });

  /* Slack으로 영상이 변경되었다고 보고 */
  const { enrolled_id, enrolled_detail_id } = enrolledDetail.data;
  const { _id: user_id, name, email, phone } = user;
  const error_data = {
    type: "영상",
    comment:
      "m3u8 파일이 제대로 등록되어 있지 않아 mp4로 호스팅 변경되었습니다.",
    user_id: user_id,
    name: name,
    email: email,
    phone: phone,
    enrolled_id: enrolled_id,
    enrolled_detail_id: enrolled_detail_id,
    lecture_id: lecture_id,
  };
  try {
    const resp = await privateInstance.post("/report/hosting", error_data);
    const result = resp.data;
    if (result.ok) {
      console.log("리포트가 제대로 전달되었습니다.");
    }
  } catch (e) {
    console.log(e);
  }
};

export const convertToMp4_waiting = (
  player,
  MP4URL,
  lecture_v2,
  is_mp4,
  is_waiting,
  is_refreshed
) => {
  const link = lecture_v2.data["link"];
  player.src({
    src: MP4URL,
  });

  const data = {
    key: "lecture_converted",
    properties: {
      link,
    },
  };
  is_mp4 = true;
  sendLog("lecture_converted", data, null);

  setTimeout(() => {
    // convert를 했는데도 재생이 안된다면
    if (is_waiting && is_refreshed && is_mp4) {
      const data = {
        key: "lecture_converted_error",
        properties: {
          link,
        },
      };
      sendLog("lecture_converted_error", data, null);
    }
  }, 4 * 1000);
};
