import { ORDERS_CONSTANT } from "../constants";

const initState = {
  loaded: false,
};

export function orders(state = initState, action) {
  switch (action.type) {
    case ORDERS_CONSTANT.ORDERS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ORDERS_CONSTANT.ORDERS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case ORDERS_CONSTANT.ORDERS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
