import { useMemo } from "react";
import {
  PAGE_TYPE,
  QUESTION_TYPE,
} from "../../_state/constants/evaluation.constants";
import { useLocation } from "react-router-dom";

export const useGetEvaluationResultData = (evaluations) => {
  const evaluationResult = useMemo(() => {
    let correctCount = 0;
    let incorrectCount = 0;

    if (evaluations && evaluations.questionData) {
      correctCount = evaluations.questionData.reduce((acc, curr) => {
        if (curr.submittedDetails && curr.submittedDetails.evaluatedScore > 0) {
          return acc + 1;
        }
        return acc;
      }, 0);

      incorrectCount = evaluations.questionData.length - correctCount;
    }

    return {
      totalScore: evaluations?.totalScore || 0,
      correctCount: correctCount,
      incorrectCount: incorrectCount,
      week: evaluations?.week,
      isRetryable: evaluations?.isRetryable,
      done: evaluations?.done,
    };
  }, [evaluations]);

  return evaluationResult;
};

export const useGetEvaluationSummaryData = (questionData) => {
  const summaryData = useMemo(() => {
    const isCorrected = questionData?.submittedDetails?.evaluatedScore !== 0;
    const submittedAnswer = questionData?.submittedDetails?.submittedAnswer;
    const correctAnswer = questionData?.answerDetails?.correctAnswer;
    const commentary = questionData?.answerDetails?.commentary;
    const questionType =
      QUESTION_TYPE[questionData?.questionDetails?.type.toUpperCase()] || null;
    const options = questionData?.questionDetails?.options || [];

    return {
      isCorrected,
      submittedAnswer,
      correctAnswer,
      commentary,
      questionType,
      options,
    };
  }, [questionData]);

  return summaryData;
};

export const useGetEvaluationPageType = () => {
  const location = useLocation();
  const pageType = location.pathname.includes("results")
    ? PAGE_TYPE.RESULT_PAGE
    : PAGE_TYPE.TEST_PAGE;
  return pageType;
};

export const useCheckAllAnswersSubmitted = (evaluationDetails) => {
  if (evaluationDetails.length === 0) return false;
  return evaluationDetails.every(
    (detail) => detail.submittedAnswer.trim() !== ""
  );
};
