import React, {Component} from 'react';
import {
    getApiNPSsubmit,
    getApiRcmdViralLogUrl,
    getApiRcmdViralUrl, getGETAuthOptions,
    getHomeworkUrl,
    getPostAuthOptions
} from "../../_urls";
import {Button, Radio, Slider} from "antd";
import classNames from "classnames/bind";
import styles from "./NPS.module.scss";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import FrownOutlined from "@ant-design/icons/lib/icons/FrownOutlined";
import {connect} from "react-redux";
import {lectureActions, pointActions} from "../../_actions";
import {getLectureUrl} from "../../_urls/lectures";
import {createEventId, handleResponse, history} from "../../_helpers";
import {CustomModal} from "../CustomModal"
import {CustomModal_MyFriend} from "../CustomModal_MyFriend";
import {getApiFreeCourseCTALogUrl, getApiFreeCourseCTAExposureLogUrl} from "../../_urls/logs.api";
import instance from "../../_helpers/axios";

const cx = classNames.bind(styles);

class NPS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            isSending: false,
            isShown: false,
            phone1: '',
            phone2: '',
            phone3: '',
            name1: '',
            name2: '',
            name3: '',
            satisfaction: '',
            good_point: '',
            bad_point: '',
            nps_score: 0,
            sat_done: false,
            score_done: false,
            is_viral: false,
            is_free: false,
            show_rcmd: false,
            show_cta_modal: false, // 무료강의 웹종 전환 CTA
        };
    }

    componentDidMount() {
        const {course_id} = this.props.nps;
        if (course_id === '6001384a006d2727411520ea') {
            this.setState({
                is_free: true,
            })
        }
        this._checkViralLog(course_id);
    }

    _sendLog = async (action, eventID) => {
        let ua = window.navigator.userAgent;
        ua = ua.toLowerCase();
        let matched = {};

        const {_id: crm_9_unique_id} = this.props.user;
        const referrer = '';
        const url = document.location.href;
        const {nps_score} = this.state;
        const {user} = this.props;
        let user_id = user ? user._id : '';
        const platform_match = /(ipad)/.exec(ua) || /(ipod)/.exec(ua)
            || /(windows phone)/.exec(ua) || /(iphone)/.exec(ua)
            || /(kindle)/.exec(ua) || /(silk)/.exec(ua) || /(android)/.exec(ua)
            || /(win)/.exec(ua) || /(mac)/.exec(ua) || /(linux)/.exec(ua)
            || /(cros)/.exec(ua) || /(playbook)/.exec(ua)
            || /(bb)/.exec(ua) || /(blackberry)/.exec(ua)
            || [];

        matched.platform = platform_match[0] || "";
        let data = {
            user_id,
            referrer,
            url,
            action,
            platform: matched.platform,
            nps_score,
            event_id: eventID,
        };
        if (crm_9_unique_id) {
            data.crm_9_unique_id = crm_9_unique_id;
        }
        await instance.post('/logs/friend_reco/copy', data);
    };

    _goNextPage = () => {
        const {enrolled_id, week, round_id} = this.props.nps;
        const {next_id} = this.props;
        const {is_free} = this.state;
        const {enrolled_detail} = this.props.homework.data;
        if (!next_id) {
            alert('드디어 완주에 성공하셨군요!!\n완강을 진심으로 축하합니다!🏅');
            history.push(`/finish?enrolled_id=${enrolled_id}`);
            return;
        }
        let chosenUrl;
        if (next_id.includes('lectures')) {
            const lecture_id = next_id.split('/')[1];
            // this.props.getLecture(course_id, lecture_id);
            // chosenUrl = getLectureUrl(round_id, lecture_id);
            chosenUrl = `/enrolleds/${enrolled_id}/edetails/${enrolled_detail._id}`
        } else {

        }
        history.push(chosenUrl);
    };

    _handleScore = (e) => {
        let value = e.target.value;
        this.setState({
            nps_score: value,
            score_done: true,
            show_rcmd: Number(value) > 6,
        })
    };

    _goToRcmdPage = async () => {
        window.open('/rcmds');
        const eventID = createEventId();
        await this._sendLog('copy_from_promotion', eventID);
    };

    _submitNPS = () => {
        const {satisfaction, good_point, bad_point, nps_score, sat_done, score_done, isShown, is_viral, is_free} = this.state;
        if (sat_done === false) {
            alert('만족 여부를 체크해주세요.');
            return;
        }
        if (score_done === false) {
            alert('추천 지수를 매겨주세요.');
            return;
        }
        let data = {
            satisfaction,
            good_point,
            bad_point,
            nps_score
        };
        const {week, round_id} = this.props.nps;
        // if (!is_viral && nps_score > 8 && !isShown) {
        //     this.showModal();
        //     return;
        // }
        const endpoint = getApiNPSsubmit(round_id, week);
        const options = getPostAuthOptions(data);
        fetch(endpoint, options)
            .then(
                resp => {
                    if (resp.ok) {
                        const {next_id} = this.props;
                        const {confirm} = window;
                        if (!next_id) {
                            this._goNextPage();
                            return;
                        }

                        if (confirm(`성공적으로 제출되었습니다.\n다음 ${is_free ? '일' : '주'}차로 이동해볼까요?`)) {
                            const {next_start_date, next_week_allowed, next_id} = this.props.homework.data;
                            // next_id === null 이면 완강한 경우이기 때문에 그냥 넘어간다.
                            if (next_id && !next_week_allowed) {
                                alert(`다음 주차는 ${next_start_date}부터 수강 가능합니다.`);
                                window.location.reload();
                                return;
                            }
                            this.props._setIsNpsShow(false);
                            this._goNextPage();
                        }
                    }
                },
                error => {
                    alert('제출할 수 없습니다.\n잠시 후 시도해주세요.');
                }
            )
    };

    _checkViralLog = async (course_id) => {
        const endpoint = getApiRcmdViralLogUrl(course_id);
        const options = getGETAuthOptions();
        const response = await fetch(endpoint, options);
        response.json().then(data => {
            this.setState({
                is_viral: data.ok
            });
        });
    };

    _handleSat = e => {
        let value = e.target.value;
        this.setState({
            satisfaction: value,
            sat_done: true,
        })
    };

    _handleGoodPoint = (e) => {
        let value = e.target.value;
        this.setState({
            good_point: value,
        })
    };

    _handleBadPoint = (e) => {
        let value = e.target.value;
        this.setState({
            bad_point: value,
        })
    };
    /* 친구 추천 Modal */
    showModal = () => {
        let {modalVisible} = this.state;
        this.setState({modalVisible: !modalVisible, isShown: true});
    };

    handleModalRatingVisible = (isVisible) => {
        this.setState({
            modalVisible: isVisible
        });
        this._submitNPS();
    };

    handleModalFreeVisible = (isVisible) => {
        this.setState({
            show_cta_modal: isVisible
        });
        history.push('/classroom')
    };

    handlePhoneChange = (e) => {
        this.fillHyphen(e.target.value, e.target.id);
    };

    handleNameChange = (e) => {
        let id = e.target.id;
        let name = e.target.value.trim();
        if (id === 'name1') {
            this.setState({
                name1: name,
            })
        } else if (id === 'name2') {
            this.setState({
                name2: name,
            })
        } else if (id === 'name3') {
            this.setState({
                name3: name,
            })
        }
    };

    fillHyphen = (phone, id) => {
        phone = phone.split("-").join("");
        if (phone.length < 4) {
            phone = phone
        } else if (phone.length < 8) {
            phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
        } else if (phone.length < 11) {
            phone = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6, phone.length);
        } else {
            phone = phone.substr(0, 3) + "-" + phone.substr(3, 4) + "-" + phone.substr(7, phone.length);
        }
        if (id === 'phone1') {
            this.setState({
                phone1: phone,
            })
        } else if (id === 'phone2') {
            this.setState({
                phone2: phone,
            })
        } else if (id === 'phone3') {
            this.setState({
                phone3: phone,
            })
        }
    };

    validatePhone = (phone) => {
        const re = /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/;
        return re.test(phone);
    };

    sendSMS = () => {
        const {phone1, phone2, phone3, name1, name2, name3} = this.state;
        const {course_id} = this.props.nps;
        const reserve = 1;
        if (name1 === '' || name2 === '' || name3 === '') {
            alert('이름을 모두 입력해주세요.');
            return;
        }
        if (phone1 === '' || phone2 === '' || phone3 === '') {
            alert('번호를 모두 입력해주세요.');
            return;
        }
        if (!this.validatePhone(phone1) || !this.validatePhone(phone2) ||
            !this.validatePhone(phone3)) {
            alert('올바른 번호를 입력해주세요');
            return;
        }
        if (phone1 === phone2 || phone1 === phone3 || phone1 === phone3) {
            alert('올바른 번호를 입력해주세요');
            return;
        }

        this.setState({
            isSending: true,
        });

        const data = {phone1, phone2, phone3, name1, name2, name3, reserve};
        const endpoint = getApiRcmdViralUrl(course_id);
        const options = getPostAuthOptions(data);
        fetch(endpoint, options)
            .then(handleResponse)
            .then(
                resp => {
                    this.setState({
                        isSending: false,
                    });
                    if (resp.ok) {
                        alert('2만 골드 적립을 완료했습니다!');
                    }
                    this.handleModalRatingVisible(false);
                },
                error => {
                    alert(error);
                    this.setState({
                        isSending: false,
                    });
                    return {ok: false};
                })
    };

    _others = () => {
        const {course_id} = this.props.nps;
        let data = {
            'cid': course_id,
            'cta': 'others',
            'from': 'nps',
        };
        const endpoint = getApiFreeCourseCTALogUrl();
        const options = getPostAuthOptions(data);
        fetch(endpoint, options);
        alert('다른 강의에도 사용하실 수 있도록 변경해드렸어요!\n다른 강의 살펴보러 가보실까요?');
        window.open(
            'https://spartacodingclub.kr/'
        )
    };

    _web = () => {
        const {course_id} = this.props.nps;
        let data = {
            'cid': course_id,
            'cta': 'to_web',
            'from': 'nps',
        };
        const endpoint = getApiFreeCourseCTALogUrl();
        const options = getPostAuthOptions(data);
        fetch(endpoint, options);
        window.open(
            'https://spartacodingclub.kr/online/web'
        )
    };


    render() {
        const {user, nps, next_id} = this.props;
        const {modalVisible, is_free, show_cta_modal, show_rcmd} = this.state;
        const {is_nps_done, week, course_id} = nps;
        const name = user.name;
        return (
            <div className={cx('npsContainer')}>
                {/*안내문*/}
                <div className={cx('infoContainer', 'contentsContainer')}>
                    <h2>{week}{is_free ? '일' : '주'}차 수업은 어떠셨어요?</h2>
                    <p>{week}{is_free ? '일' : '주'}차 수업에 대한 {name}님의 의견을 들려주세요. {name}님의 의견은 강의 개선에 큰 도움이 됩니다.😊</p>
                </div>

                <hr/>

                {/*satisfaction*/}
                <div className={cx('satContainer', 'contentsContainer')}>
                    <h4>{week}{is_free ? '일' : '주'}차 수업은 만족하셨나요?</h4>
                    <Radio.Group onChange={this._handleSat} value={this.state.satisfaction}>
                        <Radio value={"네"}>네</Radio>
                        <Radio value={"아니오"}>아니오</Radio>
                    </Radio.Group>
                </div>

                {/*good_point*/}
                <div className={cx('goodContainer', 'contentsContainer')}>
                    <h4>어떤 점이 좋으셨어요?</h4>
                    <textarea id="good_point" rows="4" placeholder={"만족한 부분에 대 의견을 자유롭게 남겨주세요."}
                              onChange={this._handleGoodPoint}/>
                </div>

                {/*bad_point*/}
                <div className={cx('badContainer', 'contentsContainer')}>
                    <h4>어떤 점이 아쉬우셨나요?</h4>
                    <textarea id="bad_point" rows="4" placeholder={"아쉬웠던 부분에 대한 의견을 자유롭게 남겨주세요."}
                              onChange={this._handleBadPoint}/>
                </div>

                {/*nps_score*/}
                <div className={cx('scoreContainer', 'contentsContainer')}>
                    <h4>친구나 동료에게 수업을 어느 정도로 추천하시겠어요? (1-10)</h4>
                    <div className={cx('slideContainer')}>
                        <Radio.Group onChange={this._handleScore} value={this.state.nps_score}>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={5}>5</Radio>
                            <Radio value={6}>6</Radio>
                            <Radio value={7}>7</Radio>
                            <Radio value={8}>8</Radio>
                            <Radio value={9}>9</Radio>
                            <Radio value={10}>10</Radio>
                        </Radio.Group>
                        {show_rcmd &&
                        <span className={cx('rcmd')} onClick={() => this._goToRcmdPage()}>> 그렇다면! 친구 추천 이벤트 구경하러 가기🎁</span>
                        }
                        {/*<FrownOutlined/>*/}
                        {/*<Slider*/}
                        {/*    className={cx('slider')}*/}
                        {/*    style={{width: 800}}*/}
                        {/*    min={1}*/}
                        {/*    max={10}*/}
                        {/*    defaultValue={10}*/}
                        {/*    tooltipVisible*/}
                        {/*/>*/}
                        {/*<SmileOutlined/>*/}
                    </div>
                </div>

                {/*submit button*/}
                <div className={cx('buttonContainer')}>
                    <Button onClick={() => {
                        this._submitNPS()
                    }}
                            className={cx('enterButtonContainer')}>
                        {next_id &&
                        <span
                            className={cx('enterButtonTextContainer')}>제출하고 {week + 1}{is_free ? '일' : '주'}차 강의 듣기</span>
                        }
                        {!next_id &&
                        <span className={cx('enterButtonTextContainer')}>제출하고 완강하기🥇</span>
                        }
                    </Button>
                </div>
                <CustomModal
                    visible={show_cta_modal}
                    close={false}
                    handleModalVisible={this.handleModalFreeVisible}
                    style={{padding: 0, height: 575, border: 'none'}}
                    body={
                        <div style={{width: '100%', background: '#fbfbfb'}}>
                            <div className={cx('free-modal-body')}>
                                <div className={cx('header')}>
                                    <img src={('/assets/images/lecture/coin_motion_resize.gif')}/>
                                    <h3>{user.name}님, 완주를 축하해요!🎉</h3>
                                    <p>
                                        {user.name}님의 완강을 축하하며,<br/>
                                        포인트 20,000원이 발급되었어요😎<br/>
                                        <br/>
                                        이 기세를 몰아, 스파르타의 시그니처 강의,<br/>
                                        [웹개발 종합반]에서 즐코해봐요!
                                    </p>
                                </div>
                                <div className={cx('freeButtonContainer')}>
                                    <button onClick={this._web}>웹개발 종합반 살펴보기</button>
                                </div>
                                <div className={cx('subCTA')} onClick={this._others}>
                                    <span>이미 웹개발 종합반을 수강했다면?</span>
                                </div>
                                <hr/>
                                <div className={cx('footer')}>
                                    <p className={cx('footer-main')}>유의사항</p>
                                    <p>
                                        * 포인트는 [마이페이지]에서 확인하실 수 있습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                >
                </CustomModal>
                <CustomModal_MyFriend
                    visible={modalVisible}
                    close={false}
                    handleModalVisible={this.handleModalRatingVisible}
                    style={{
                        height: 580,
                    }}
                    body={
                        <div className={cx('modal-body')}>
                            <div className={cx('event-title')}>
                                <span>SPARTA</span>
                                <span>혹시, 만족하셨다면?</span>
                            </div>
                            <div className={cx('header')}>
                                <h3>친구는 <span className={cx('pink-background')}>5만 원</span> 할인받고, 나는 <span
                                    className={cx('pink-background')}>2만 포인트</span> 받기!💰</h3>
                                <p>
                                    코딩 공부가 필요한 친구 세 명의 <b>이름</b>과 <b>휴대폰 번호</b>를 적어주세요.<br/>
                                    <b>내일 점심</b>에 친구에게 할인 쿠폰이 담긴 예약 문자를 발송해드릴게요 &gt;_&lt;<br/>
                                    * 결제 시, 1포인트는 1원으로 적용되어 사용하실 수 있어요!
                                </p>
                            </div>
                            <div className={cx('body')}>
                                <div className={cx('friend')}>
                                    <div className={cx('number')}>
                                        1
                                    </div>
                                    <div className={cx('label')}>
                                        <input type="text" id="name1" placeholder={'이름'}
                                               onChange={this.handleNameChange}
                                               value={this.state.name1}/>
                                    </div>
                                    <div className={cx('phone')}>
                                        <input type="text" id="phone1" placeholder={'010-1234-1234'}
                                               onChange={this.handlePhoneChange}
                                               value={this.state.phone1}/>
                                    </div>
                                </div>
                                <div className={cx('friend')}>
                                    <div className={cx('number')}>
                                        2
                                    </div>
                                    <div className={cx('label')}>
                                        <input type="text" id="name2" placeholder={'이름'}
                                               onChange={this.handleNameChange}
                                               value={this.state.name2}/>
                                    </div>
                                    <div className={cx('phone')}>
                                        <input type="text" id="phone2" placeholder={'010-1234-1234'}
                                               onChange={this.handlePhoneChange}
                                               value={this.state.phone2}/>
                                    </div>
                                </div>
                                <div className={cx('friend')}>
                                    <div className={cx('number')}>
                                        3
                                    </div>
                                    <div className={cx('label')}>
                                        <input type="text" id="name3" placeholder={'이름'}
                                               onChange={this.handleNameChange}
                                               value={this.state.name3}/>
                                    </div>
                                    <div className={cx('phone')}>
                                        <input type="text" id="phone3" placeholder={'010-1234-1234'}
                                               onChange={this.handlePhoneChange}
                                               value={this.state.phone3}/>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('buttonContainer')}>
                                {this.state.isSending &&
                                <button className={cx('isSending')}>보내는 중</button>
                                }
                                {!this.state.isSending &&
                                <button onClick={this.sendSMS}>친구야 함께하자!</button>
                                }
                            </div>
                        </div>
                    }
                    footer={[
                        <Button key="ok" type="primary" onClick={() => this.setState({modalVisible: false})}>
                            확인
                        </Button>
                    ]}/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {authentication, homework} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        homework,
    }
}

const connectedNPS = connect(mapStateToProps, {
    getLecture: lectureActions.getLecture,
})(NPS);
export {connectedNPS as NPS}