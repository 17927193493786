import { reducer } from "../reducers";
import { action } from "../actions/action";
import privateInstance from "../../../v2/_helper/axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const initState = {
  loaded: false,
};

const LOGINED = {
  REQUEST: "LOGINED_REQUEST",
  SUCCESS: "LOGINED_SUCCESS",
  FAILURE: "LOGINED_FAILURE",
};

async function _service() {
  const result = await privateInstance.get(`/users/is_logined`);
  return result.data;
}

export function getIsLogined() {
  return action(_service, LOGINED);
}

export function logined(state = initState, action) {
  return reducer(state, action, LOGINED);
}

export const useIsLogined = () => {
  const logined = useSelector((state) => state.logined);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIsLogined());
  }, []);
  return logined;
};
