import privateInstance from "../../_helper/axios";

async function getPaymentOptions(course_id, round_id) {
  return await privateInstance
    .get(`/payment/options/${course_id}/${round_id}`)
    .then((resp) => resp.data);
}

async function getPaymentReinforced(course_id, round_id) {
  return await privateInstance
    .get(`/payment/reinforcements/${course_id}/${round_id}`)
    .then((resp) => resp.data);
}

async function getPaymentReinforcedByUser() {
  return await privateInstance
    .get(`/payment/reinforcements/users`)
    .then((resp) => resp.data);
}

async function getPaymentAlert() {
  return await privateInstance.get(`/payment/alert`).then((resp) => resp.data);
}

async function getIsPaymentCounselTarget(course_id) {
  return await privateInstance
    .get(`/payment/counsel/${course_id}`)
    .then((resp) => resp.data);
}

async function postPaymentCounsel(course_id) {
  return await privateInstance
    .post(`/payment/counsel/${course_id}`)
    .then((resp) => resp.data);
}

async function getIsNomadCouponTarget(course_id) {
  return await privateInstance
    .get(`/payment/coupon/${course_id}`)
    .then((resp) => resp.data);
}

async function postNomadCoupon(course_id) {
  return await privateInstance
    .post(`/payment/coupon/${course_id}`)
    .then((resp) => resp.data);
}

async function getPaymentReviews(course_id) {
  return await privateInstance
    .get(`/payment/reviews/${course_id}`)
    .then((resp) => resp.data);
}

async function getPaymentVBank(order_no) {
  return await privateInstance
    .get(`/payment/vbank/${order_no}`)
    .then((resp) => resp.data);
}

export const paymentService = {
  getPaymentOptions,
  getPaymentReinforced,
  getPaymentReinforcedByUser,
  getPaymentAlert,
  getIsPaymentCounselTarget,
  postPaymentCounsel,
  getIsNomadCouponTarget,
  postNomadCoupon,
  getPaymentReviews,
  getPaymentVBank,
};
