import { LIVE_USER } from "../constants";
import { liveUserService } from "../services";

function getLiveUser() {
  function request() {
    return { type: LIVE_USER.LIVE_USER_REQUEST };
  }

  function success(response) {
    return { type: LIVE_USER.LIVE_USER_SUCCESS, response };
  }

  function failure(error) {
    return { type: LIVE_USER.LIVE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    liveUserService.getLiveUsers().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetLiveUser = () => {
  return getLiveUser();
};

export const liveUserActions = {
  getLiveUser,
};
