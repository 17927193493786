import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./JindosaurusCharacter.modules.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

function JindosaurusCharacter({ weekly_width, divWidth, WeeksAfterStart }) {
  const jindo_width = divWidth / 5;
  const initial_position = jindo_width * -1;
  let x_position;

  if (WeeksAfterStart.data.context <= 1) {
    x_position = initial_position;
  } else {
    x_position =
      (WeeksAfterStart.data.context - 1) * weekly_width +
      30 -
      jindo_width * 0.7;
  }
  const style = {
    // transform: `translateX(${initial_position}px)`,
    "--initialX": `${initial_position}px`,
    "--moveX": `${x_position}px`,
    width: `${jindo_width}px`,
  };
  return (
    <>
      {WeeksAfterStart.loaded && (
        <div className={cx("JindoCharacterContainer")} style={style}>
          <img src={`/assets/images/codingrace/running_jindo.gif`} alt="" />
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { WeeksAfterStart } = state;
  return { WeeksAfterStart };
}

const connectedJindosaurusCharacter = connect(
  mapStateToProps,
  {}
)(JindosaurusCharacter);
export { connectedJindosaurusCharacter as JindosaurusCharacter };
