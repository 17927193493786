import { getCookie } from "../v2/_helper";

export const prepareHrdFrameLoaderData = (userId, round) => {
  const isDebug = process.env.NODE_ENV !== "production";
  const agtId = round?.is_gov_training ? "teamsparta1" : "teamsparta";
  const usrId = userId;

  const deviceId = getCookie("device_id");

  const sessionId = deviceId ?? ""; // 세션 ID

  return {
    isDebug,
    agtId,
    usrId,
    sessionId,
  };
};
