import styled from "styled-components";

export const ToastMsgContainer = styled.div`
  display: flex;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: visibility 0.3s ease-in-out 0s, opacity 0.3s ease-in;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  height: 41px;
  position: fixed;
  top: 32px;
  width: ${(props) => (props.isInfo ? "274px" : "232px")};
  left: ${(props) =>
    props.isInfo ? "calc(50% - 274px / 2)" : "calc(50% - 232px / 2)"};
  border-radius: 100px;
  background-color: rgba(20, 22, 23, 0.8);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
  gap: 4px;
  z-index: 9999;
`;

export const CopyMsgWrapper = styled.div`
  position: fixed;
  top: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  z-index: 9999;
`;

export const CopyMsgContainer = styled.div`
  display: flex;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: visibility 0.3s ease-in-out 0s, opacity 0.3s ease-in;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  height: 41px;
  border-radius: 100px;
  background-color: rgba(20, 22, 23, 0.8);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
  gap: 4px;
`;

export const ToastMsg = styled.div`
  color: #ffffff;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
`;

export const ToastPointIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export const ToastImportant = styled.span`
  color: #fbbc0f;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 14px;
`;
