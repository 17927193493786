import * as S from "./VerificationModal.style";
import React, { useState } from "react";
import { VerificationContent } from "./VerificationContent";
import { MOTPForm } from "./mOTPForm";
import { HrdFrameLoader } from "../../../../businesslogics/Verification/HrdFrameLoader";
import { prepareHrdFrameLoaderData } from "../../../../../_helpers/prepareHrdFrameLoaderData";
import { usePhoneVerification } from "../../../../hooks/verification/usePhoneVerification";
import { useParams } from "react-router-dom";
import {
  useEnrolled,
  useIsGovUserInfoNeeded,
  useProductName,
} from "../../../../hooks/useProductName";
import { useGetGovUsers } from "../../../../queries/auth/auth";
import { useUser } from "../../../../pages/LecturePageV3/useInitialize";

export const VerificationModal = () => {
  const { enrolled_id } = useParams();
  const [isOTP, setIsOTP] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const enrolled = useEnrolled(enrolled_id);
  const roundId = enrolled.data?.round_id;
  const rounds = enrolled.data?.rounds;
  const productType = useProductName(enrolled_id);
  const userId = useUser()?._id;
  const isGovUserInfoNeeded = useIsGovUserInfoNeeded();
  const { data: user } = useGetGovUsers(isGovUserInfoNeeded ? userId : null);
  const { isDebug, agtId, usrId, sessionId } = prepareHrdFrameLoaderData(
    userId,
    rounds
  );

  const {
    fnPopup,
    isDailyGovAuthAccessSuccess,
    phoneVerification,
    refetchDailyAuth,
    isDailyGovAuthRequired,
    isPhoneVerificationLoading,
    formRef,
  } = usePhoneVerification(roundId, user, productType);

  return (
    isGovUserInfoNeeded &&
    user && (
      <>
        <form name={"popupChk"} ref={formRef}>
          <input type={"hidden"} name={"m"} value={"checkplusService"} />
          <input
            type={"hidden"}
            name={"EncodeData"}
            value={
              !isPhoneVerificationLoading
                ? phoneVerification.context.enc_data
                : ""
            }
          />
        </form>
        {isDailyGovAuthAccessSuccess && isDailyGovAuthRequired && (
          <S.Background>
            <S.VerificationWrapper>
              {isOTP ? (
                <MOTPForm
                  user={user}
                  setIsOTP={setIsOTP}
                  productType={productType}
                  setIsMouseOver={setIsMouseOver}
                  isMouseOver={isMouseOver}
                  enrolled={enrolled.loaded && enrolled.data}
                  refetchDailyAuth={refetchDailyAuth}
                />
              ) : (
                <VerificationContent fnPopup={fnPopup} setIsOTP={setIsOTP} />
              )}
            </S.VerificationWrapper>
            <HrdFrameLoader
              isDebug={isDebug}
              agtId={agtId}
              usrId={usrId}
              sessionId={sessionId}
            />
            {isMouseOver && (
              <S.MouseOverGuide
                onMouseEnter={() => setIsMouseOver(true)}
                onMouseLeave={() => setIsMouseOver(false)}
              >
                1. 모바일 스토어에서 ‘원격훈련 mOTP’를 검색하여 앱을 설치합니다.
                <br />
                2. 앱 실행 후 이용자 정보와 비밀번호를 등록합니다.
                <br />
                3. 생성된 인증번호 6자리를 입력해 주세요.
                <br />
                4. 앱 재실행 시 등록했던 비밀번호를 입력하면 인증번호가
                표시됩니다.
              </S.MouseOverGuide>
            )}
          </S.Background>
        )}
      </>
    )
  );
};
