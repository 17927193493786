import React from "react";
import * as S from "./NBCampAttendRecordPageTemplate.style";

export function NBCampAttendRecordPageTemplate({ header, content, checkBtn, isFeatureOpened}) {
  return (
    <S.Container>
      <S.Header>{header}</S.Header>
      <S.Content>{content}</S.Content>
      <S.Check>{checkBtn}</S.Check>
        {isFeatureOpened && <S.CtaContainer>
            <S.SquareCta href={"/nbcamp/attend"} target={"_blank"}>
                출석조회
            </S.SquareCta>
            <S.SquareCta href={"/classroom"} target={"_blank"}>
                내강의실
            </S.SquareCta>
            <S.SquareCta href={"https://teamsparta.notion.site/FAQ-cafb2735d0bb4b5e97cb9077eaa97d26 "}
                         target={"_blank"}>
                FAQ
            </S.SquareCta>
        </S.CtaContainer>}
    </S.Container>
  );
}
