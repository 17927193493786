import privateInstance from "../../_helper/axios";

async function getCourseRetention(recent_course_id) {
  return await privateInstance
    .get(`/course_retention/?recent_course_id=${recent_course_id}`)
    .then((resp) => resp.data);
}

export const courseRetentionService = {
  getCourseRetention,
};
