export const ALLOWED_HOUR = 6;

export const GovAttendRecordType = {
  ENTER: "입실",
  PAUSE: "외출",
  COMEBACK: "복귀",
  TERMINATE: "퇴실",
  CHECK: "중간점검",
};

export function isStudyingNow(lastRecord) {
  return (
    lastRecord &&
    [
      GovAttendRecordType.ENTER,
      GovAttendRecordType.COMEBACK,
      GovAttendRecordType.CHECK,
    ].includes(lastRecord.rtype)
  );
}

export function setActionDisableByRecentRecord(lastRecord, actionType) {
  const isStudying = isStudyingNow(lastRecord);
  switch (actionType) {
    case GovAttendRecordType.ENTER:
      return !!lastRecord;
    case GovAttendRecordType.COMEBACK:
      return !(
        lastRecord && [GovAttendRecordType.PAUSE].includes(lastRecord.rtype)
      );
    case GovAttendRecordType.PAUSE:
    case GovAttendRecordType.TERMINATE:
    case GovAttendRecordType.CHECK:
      return !isStudying;
    default:
      return true;
  }
}

export function getSecondsBetweenDates(d1, d2) {
  return Math.floor((d1 - d2) / 1000);
}

export function calcStudiedSeconds(records) {
  if (!records.length) {
    return 0;
  }
  let seconds = 0;
  for (let i = 0; i < records.length; i++) {
    const isCalable = [
      GovAttendRecordType.PAUSE,
      GovAttendRecordType.TERMINATE,
      GovAttendRecordType.CHECK,
    ].includes(records[i].rtype);
    if (isCalable) {
      seconds += getSecondsBetweenDates(
        new Date(records[i].created_at),
        new Date(records[i - 1].created_at)
      );
    }
  }

  const lastRecord = records.slice(-1)[0];
  if (isStudyingNow(lastRecord)) {
    seconds += getSecondsBetweenDates(
      new Date(),
      new Date(lastRecord.created_at)
    );
  }
  return seconds;
}

export function secondsToKorean(seconds) {
  const HOUR = 60 * 60;
  const MINUTE = 60;
  const hours = Math.floor(seconds / HOUR);
  const minutes = Math.floor((seconds - hours * HOUR) / MINUTE);
  const secs = seconds - hours * HOUR - minutes * MINUTE;
  return `${hours}시간 ${minutes}분 ${secs}초`;
}

export function checkAllowed(lastCheckedDatetime) {
  // 중간점검을 기록한 적이 없든지, 기록했다면 1분이 지났든지.
  return (
    !lastCheckedDatetime ||
    (new Date() - new Date(lastCheckedDatetime)) / 1000 > 60
  );
}
