import React, { useEffect, useState, useRef } from "react";
import { Rate } from "antd";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./PaymentReviewContainer.module.scss";
import { paymentActionsV2 } from "../../../v2/_state/actions/payment.action";

const cx = classNames.bind(styles);

const PaymentReviewContainer = ({
  course_id,
  isMobile,
  reviewDivider,
  paymentReviews,
  getPaymentReviews,
}) => {
  useEffect(() => {
    if (!paymentReviews.loaded) {
      getPaymentReviews(course_id);
    }
  }, []);
  const [page, setPage] = useState(0);

  const lastPage =
    paymentReviews.loaded && paymentReviews.data.reviews_paginated
      ? paymentReviews.data.reviews_paginated.length - 1
      : 0;

  const leftArrowClass =
    page === 0
      ? cx("arrow", "--inverse", "--inactive")
      : cx("arrow", "--inverse");
  const rightArrowClass =
    page === lastPage ? cx("arrow", "--inactive") : cx("arrow");

  function onReviewArrowClicked(isRight) {
    if (isRight) {
      if (page < lastPage) {
        setPage(page + 1);
      }
    } else {
      if (page > 0) {
        setPage(page - 1);
      }
    }
  }
  if (!paymentReviews.loaded) return <></>;
  return (
    <div className={cx("payment_review_container")}>
      {paymentReviews.data.reviews && !paymentReviews.data.reviews_all && (
        <>
          <div className={cx("pc-divider")} />
          <section className={cx("sec", "reviews")}>
            {paymentReviews.data.reviews.map((review, idx) => {
              return (
                <div className={cx("review")} key={idx}>
                  <div className={cx("title")}>
                    <Rate className={cx("rating")} disabled defaultValue={5} />
                    <div className={cx("summary")}>{review.summary}</div>
                  </div>
                  <div className={cx("content")}>{review.content}</div>
                  <div className={cx("name")}>{review.name}</div>
                  <div className={cx("meta")}>
                    {review.job} {review.age}
                  </div>
                </div>
              );
            })}
          </section>
        </>
      )}
      {!isMobile &&
      paymentReviews.data.reviews_paginated &&
      paymentReviews.data.reviews_paginated.length ? (
        <>
          <div
            className={cx("pc-divider")}
            id={"review-divider"}
            ref={reviewDivider}
          />
          <section className={cx("sec", "reviews_b")}>
            <div className={cx("info-header")}>
              <div className={cx("info-header-text")}>
                <h2>실제 수강생들의 생생한 후기</h2>
              </div>
              <div className={cx("pagination-icon")}>
                <img
                  className={leftArrowClass}
                  src={("/v2/assets/icons/arrow_active.svg")}
                  onClick={() => {
                    onReviewArrowClicked(false);
                  }}
                />
                <img
                  className={rightArrowClass}
                  src={("/v2/assets/icons/arrow_active.svg")}
                  onClick={() => {
                    onReviewArrowClicked(true);
                  }}
                />
              </div>
            </div>
            <div className={cx("review-wrapper")}>
              {paymentReviews.data.reviews_paginated[page].map(
                (review, idx) => {
                  return (
                    <div className={cx("review")} key={idx}>
                      <div className={cx("title")}>
                        <img
                          className={cx("rtan")}
                          src={("/assets/icons/rtan/rtan_thumb_" +
                            ((idx + 1) % 30) +
                            ".png")}
                        />
                        <div className={cx("name")}>{review.name}</div>
                        <div className={cx("round")}>{review.round}</div>
                      </div>
                      <div className={cx("meta")}>
                        <div className={cx("tag")}>{review.job}</div>
                        <div className={cx("tag")}>{review.age}</div>
                      </div>
                      <div className={cx("content")}>{review.content}</div>
                    </div>
                  );
                }
              )}
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
      {isMobile && paymentReviews.data.reviews_all && (
        <section className={cx("sec", "reviews_b")}>
          <div className={cx("info-header")}>
            <div className={cx("info-header-text")}>
              <h2>실제 수강생들의 생생한 후기</h2>
            </div>
          </div>
          <div className={cx("review-wrapper")}>
            {paymentReviews.data.reviews_all.map((review, idx) => {
              return (
                <div className={cx("review")} key={idx}>
                  <div className={cx("title")}>
                    <img
                      className={cx("rtan")}
                      src={("/assets/icons/rtan/rtan_thumb_" +
                        ((idx + 1) % 30) +
                        ".png")}
                    />
                    <div className={cx("name")}>{review.name}</div>
                    <div className={cx("round")}>{review.round}</div>
                  </div>
                  <div className={cx("meta")}>
                    <div className={cx("tag")}>{review.job}</div>
                    <div className={cx("tag")}>{review.age}</div>
                  </div>
                  <div className={cx("content")}>{review.content}</div>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  const { authentication, paymentReviews } = state;
  const { user } = authentication;
  return { authentication, user, paymentReviews };
}

const connectedPaymentReviewContainer = connect(mapStateToProps, {
  getPaymentReviews: paymentActionsV2.getPaymentReviews,
})(PaymentReviewContainer);
export { connectedPaymentReviewContainer as PaymentReviewContainer };
