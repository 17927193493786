import React, { Component } from "react";
import { getApiFreeRcmdViralUrl, getPostAuthOptions } from "../../_urls";
import { handleResponse } from "../../_helpers";
import { CustomModal_MyFriend } from "../CustomModal_MyFriend";
import { Button } from "antd";
import classNames from "classnames/bind";
import styles from "./RcmdModal.module.scss";
import { connect } from "react-redux";
import { classroomActions, courseActions, userActions } from "../../_actions";

const cx = classNames.bind(styles);

class RcmdModal extends Component {
  state = {
    course_id: "5fd1cedae7b11d0865a27a76",
    rcmdVisible: true,
    rcmds: [
      {
        name: "",
        phone: "",
        country_code: "+82",
        is_typed: false,
      },
      {
        name: "",
        phone: "",
        country_code: "+82",
        is_typed: false,
      },
      {
        name: "",
        phone: "",
        country_code: "+82",
        is_typed: false,
      },
    ],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.course_loaded) {
      const { course_id } = this.state;
      this.props.getCourse(course_id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleRcmdVisible = (isVisible) => {
    this.setState({
      rcmdVisible: isVisible,
    });
  };

  handlePhoneChange = (e) => {
    this.fillHyphen(e.target.value, e.target.id);
  };

  handleNameChange = (e) => {
    let { rcmds } = this.state;
    let id = e.target.id;
    let name = e.target.value.trim();
    let idx = parseInt(id[4]) - 1;
    rcmds[idx].name = name;
    rcmds[idx].is_typed = name !== "" && rcmds[idx].phone.length === 13;
    this.setState({
      rcmds,
    });
    // if (id === 'name1') {
    //     rcmds[0].name = name;
    // } else if (id === 'name2') {
    //     rcmds[1].name = name;
    // } else if (id === 'name3') {
    //     rcmds[2].name = name;
    // }
  };

  fillHyphen = (phone, id) => {
    if (phone.includes("+") === false) {
      phone = phone.split("-").join("");
      if (phone.length < 4) {
        phone = phone;
      } else if (phone.length < 8) {
        phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
      } else if (phone.length < 11) {
        phone =
          phone.substr(0, 3) +
          "-" +
          phone.substr(3, 3) +
          "-" +
          phone.substr(6, phone.length);
      } else {
        phone =
          phone.substr(0, 3) +
          "-" +
          phone.substr(3, 4) +
          "-" +
          phone.substr(7, phone.length);
      }
    }

    let idx = parseInt(id[5]) - 1;
    let { rcmds } = this.state;
    rcmds[idx].phone = phone;
    rcmds[idx].is_typed = phone.length === 13 && rcmds[idx].name !== "";
    this.setState({
      rcmds,
    });
  };

  validatePhone = (phone) => {
    const re = /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/;
    const foreign = /^[\+][0-9]{1,5}[-][0-9]{6,20}$/im;
    return re.test(phone) || foreign.test(phone);
  };

  sendRcmdFriend = () => {
    const { rcmds, course_id } = this.state;
    if (rcmds[0].phone === "") {
      alert("올바른 번호를 입력해주세요");
      return;
    }
    if (
      (rcmds[0].phone !== "" && !this.validatePhone(rcmds[0].phone)) ||
      (rcmds[1].phone !== "" && !this.validatePhone(rcmds[1].phone)) ||
      (rcmds[2].phone !== "" && !this.validatePhone(rcmds[2].phone))
    ) {
      alert("올바른 번호를 입력해주세요");
      return;
    }
    if (rcmds[0].phone === rcmds[1].phone && rcmds[0].phone !== "") {
      alert("올바른 번호를 입력해주세요");
      return;
    }
    if (rcmds[0].phone === rcmds[2].phone && rcmds[0].phone !== "") {
      alert("올바른 번호를 입력해주세요");
      return;
    }
    if (rcmds[1].phone === rcmds[2].phone && rcmds[1].phone !== "") {
      alert("올바른 번호를 입력해주세요");
      return;
    }

    for (let i = 0; i < rcmds.length; i++) {
      if (rcmds[i].phone.includes("+")) {
        let country_code = rcmds[i].phone.split("-")[0];
        let phone = rcmds[i].phone.split("-").slice(1).join("");
        rcmds[i].phone = phone;
        rcmds[i].country_code = country_code;
      } else {
        let phone = rcmds[i].phone.split("-").join("");
        rcmds[i].phone = phone;
      }
    }

    this.setState({
      isSending: true,
    });

    let isClassroom = this.props.course_loaded === undefined;

    const data = { rcmds, isClassroom };
    const endpoint = getApiFreeRcmdViralUrl(course_id);
    const options = getPostAuthOptions(data);
    fetch(endpoint, options)
      .then(handleResponse)
      .then(
        (resp) => {
          this.setState({
            isSending: false,
          });
          if (resp.ok) {
            alert("2만 골드 적립을 완료했습니다!");
          }
          this.props.handleVisible(false);
        },
        (error) => {
          alert(error);
          this.setState({
            isSending: false,
          });
          this.props.handleVisible(false);
          return { ok: false };
        }
      );
  };

  handleClose = () => {
    this.props.handleVisible(false);
  };

  render() {
    const { checkPlatform } = window;
    const deviceType = checkPlatform();
    let { user, course, course_loaded, visible } = this.props;
    if (course_loaded) {
      course = course_loaded;
    }
    if (!course || !user) {
      return <div />;
    }
    return (
      <CustomModal_MyFriend
        visible={visible}
        close={false}
        handleModalVisible={this.props.handleVisible}
        body={
          <div className={cx("modal-body")}>
            <div className={cx("event-title")}>
              <span>SPARTA</span>
              <span>이 좋은걸! 나만 들을 순 없지.</span>
            </div>
            <div className={cx("header")}>
              {/*<h3>친구와 함께 수강하고, 나는 <span*/}
              {/*    className={cx('pink-background')}>2만 포인트</span> 받기!💰</h3>*/}
              {course_loaded && (
                <h3>
                  {user.name}님, {course.data.title} 등록이 완료되었습니다!
                </h3>
              )}
              {!course_loaded && (
                <h3>{user.name}님, 추석 무료 특강을 함께 수강해보세요!</h3>
              )}

              {deviceType === "pc" && (
                <p>
                  {/*{user.name}님, {course.data.title} 등록이 완료되었습니다!<br/>*/}
                  이번 추석, 함께 코딩공부 하고싶은 친구 이름과 휴대폰 번호를
                  적어주세요!
                  <br />
                  *(소곤소곤) 친구를 초대하면 {user.name}님께 포인트 2만원💰을
                  드려요.
                </p>
              )}
              {deviceType !== "pc" && (
                <p>
                  {/*{user.name}님, {course.data.title} 등록이 완료되었습니다!<br/>*/}
                  이번 추석, 함께 코딩공부 하고싶은 친구의
                  <br />
                  이름과 휴대폰 번호를 적어주세요!
                  <br />
                  *(소곤소곤) 친구를 초대하면
                  <br />
                  {user.name}님께 포인트 2만원💰을 드려요.
                </p>
              )}
            </div>
            <div className={cx("body")}>
              <div className={cx("friend")}>
                <div className={cx("number")}>1</div>
                <div className={cx("label")}>
                  <input
                    type="text"
                    id="name1"
                    placeholder={"이름"}
                    onChange={this.handleNameChange}
                    value={this.state.rcmds[0].name}
                  />
                </div>
                <div className={cx("phone")}>
                  <input
                    type="text"
                    id="phone1"
                    placeholder={"010-1234-1234"}
                    onChange={this.handlePhoneChange}
                    value={this.state.rcmds[0].phone}
                  />
                </div>
              </div>
              <div className={cx("friend")}>
                <div
                  className={
                    this.state.rcmds[0].is_typed
                      ? cx("number")
                      : cx("number-inactive")
                  }
                >
                  2
                </div>
                <div className={cx("label")}>
                  <input
                    type="text"
                    id="name2"
                    placeholder={"이름"}
                    className={
                      this.state.rcmds[0].is_typed ? cx("") : cx("dark")
                    }
                    onChange={this.handleNameChange}
                    value={this.state.rcmds[1].name}
                  />
                </div>
                <div className={cx("phone")}>
                  <input
                    type="text"
                    id="phone2"
                    placeholder={"010-1234-1234"}
                    className={
                      this.state.rcmds[0].is_typed ? cx("") : cx("dark")
                    }
                    onChange={this.handlePhoneChange}
                    value={this.state.rcmds[1].phone}
                  />
                </div>
              </div>
              <div className={cx("friend")}>
                <div
                  className={
                    this.state.rcmds[1].is_typed
                      ? cx("number")
                      : cx("number-inactive")
                  }
                >
                  3
                </div>
                <div className={cx("label")}>
                  <input
                    type="text"
                    id="name3"
                    placeholder={"이름"}
                    className={
                      this.state.rcmds[1].is_typed ? cx("") : cx("dark")
                    }
                    onChange={this.handleNameChange}
                    value={this.state.rcmds[2].name}
                  />
                </div>
                <div className={cx("phone")}>
                  <input
                    type="text"
                    id="phone3"
                    placeholder={"010-1234-1234"}
                    className={
                      this.state.rcmds[1].is_typed ? cx("") : cx("dark")
                    }
                    onChange={this.handlePhoneChange}
                    value={this.state.rcmds[2].phone}
                  />
                </div>
              </div>
            </div>
            <div className={cx("buttonContainer")}>
              {this.state.isSending && (
                <button className={cx("isSending")}>보내는 중</button>
              )}
              {!this.state.isSending && (
                <button onClick={this.sendRcmdFriend}>친구야 함께하자!</button>
              )}
              <div className={cx("subCTA")}>
                <span onClick={this.handleClose}>다음에 추천할래요.</span>
              </div>
            </div>
          </div>
        }
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              this.props.handleVisible(false);
            }}
          >
            확인
          </Button>,
        ]}
      />
    );
  }
}

function mapStateToProps(state) {
  const { authentication, course } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    course,
  };
}
const connectedRcmdModal = connect(mapStateToProps, {
  loginWithToken: userActions.loginWithToken,
  getCourse: courseActions.getCourse,
})(RcmdModal);
export { connectedRcmdModal as RcmdModal };
