import styled from "@emotion/styled";
import { logOnClickCourse } from "../../../../hooks/finish/recommend";
import { useSelector } from "react-redux";

const ProductThumbnail = ({ thumbnailData }) => {
  const enrolled = useSelector((state) => state.enrolled);
  return (
    <Thumbnail
      src={`/assets/thumbnails/${thumbnailData.keyword}.png`}
      onClick={() => {
        window.open(thumbnailData.url);
        logOnClickCourse(
          enrolled,
          `${thumbnailData.name} 메인페이지`,
          thumbnailData.url
        );
      }}
    />
  );
};

export default ProductThumbnail;

const Thumbnail = styled.img`
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  max-width: 498px;
  object-fit: contain;
`;
