import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  classroomActions,
  enrolledActions,
  prequestionAction,
} from "../../_state/actions";
import { coursesActions } from "../../_state/actions/courses.actions";
import CongratsWithHiddenImageDownloadComponent from "./Congrats/Congrats";
import { useGoalOptions } from "../../hooks/nps/congrats";
import { useLogNpsPageView } from "../../hooks/nps";
import Nps from "./Nps/Nps";

function FreeNpsPage({
  match,
  user,
  enrolledDetails,
  enrolled,
  courseV2,
  classroomCards,
  setEnrolled,
  getEnrolledDetails,
  getCourse,
  getPrequestion,
  getClassroomCards,
}) {
  const { enrolled_id } = match.params;
  const [prevGoal, setPrevGoal] = useState("");
  const [goalOptions, setGoalOptions] = useState([]);
  useLogNpsPageView(enrolled);
  useGoalOptions("", "", setPrevGoal, setGoalOptions);

  useEffect(() => {
    if (!classroomCards.loaded) {
      getClassroomCards();
    }
    if (enrolled_id) {
      setEnrolled(enrolled_id);
      if (!enrolled.loaded) {
        setEnrolled(enrolled_id);
      }
      if (!enrolledDetails.loaded) {
        getEnrolledDetails(enrolled_id);
      }
    }
    if (!courseV2.loaded && enrolled.loaded) {
      getCourse(enrolled.data.course_id);
    }
  }, [
    classroomCards.loaded,
    courseV2.loaded,
    enrolled.loaded,
    enrolledDetails.loaded,
    enrolled_id,
  ]);

  return (
    <div>
      {user && (
        <>
          <CongratsWithHiddenImageDownloadComponent
            user={user}
            enrolled={enrolled}
            isRepurchase={
              classroomCards.data?.full_enrolled_info_list.length > 1
            }
          />
          <Nps user={user} enrolled={enrolled} />
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    enrolled,
    courseV2,
    enrolledDetails,
    classroomCards,
  } = state;
  const { user } = authentication;
  return {
    user,
    enrolled,
    courseV2,
    enrolledDetails,
    classroomCards,
  };
}

const connectedLastNpsPage = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getCourse: coursesActions.getCourse,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getClassroomCards: classroomActions.getClassroomCards,
})(FreeNpsPage);
export { connectedLastNpsPage as LastNpsPage };
