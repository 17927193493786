import { checkinConstants, priceConstants } from "../_constants";
import { checkinService } from "../_services/checkin.service";
import { priceByNumberService } from "../_services";

function getPriceByNumber(course_id) {
  function request() {
    return { type: priceConstants.PRICE_REQUEST };
  }

  function success(response) {
    return { type: priceConstants.PRICE_SUCCESS, response };
  }

  function failure(error) {
    return { type: priceConstants.PRICE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    priceByNumberService.getPriceByNumber(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const priceByNumberActions = {
  getPriceByNumber,
};
