import React, { Component } from "react";
import { connect } from "react-redux";
import { courseActions, userActions } from "../../_actions";
import { params, history } from "../../_helpers";
import { NavContainer } from "../../_containers";
import classNames from "classnames/bind";
import styles from "./Retake.module.scss";
import instance from "../../_helpers/axios";

const cx = classNames.bind(styles);

class RetakePage extends Component {
  state = {
    enrolled_id: this.props.match.params.enrolled_id,
    isLoading: true,
    checkList: [false, false, false],
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = async () => {
    const { enrolled_id } = this.state;
    await instance
      .get(`/enrolleds/${enrolled_id}`)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({
          ...data,
          isLoading: false,
        });
      });
  };

  handleName = (e) => {
    this.setState({ accountName: e.target.value });
  };

  submit = async () => {
    const { enrolled_id } = this.state;
    const data = Object.assign({}, this.state);
    const { accountName, nxt_start_date, course_title } = data;

    if (!accountName) {
      alert("입금자명을 입력해주세요.");
      return;
    }

    if (
      window.confirm(
        `[재수강신청]\n입금자명: ${accountName}\n강의명: ${course_title}\n다음개강일:${nxt_start_date}\n\n위 사항을 다시 한 번 확인해주세요.`
      )
    ) {
      await instance
        .post(`/enrolleds/${enrolled_id}`, data)
        .then((resp) => resp.data)
        .then((data) => {
          alert(data.msg);
          if (data.ok) {
            window.location.href = "https://spartacodingclub.kr";
          }
        });
    }
  };

  render() {
    const {
      isLoading,
      name,
      cur_end_date,
      nxt_start_date,
      course_title,
      round_cur,
      round_nxt,
    } = this.state;
    return (
      <>
        {!isLoading && (
          <>
            <NavContainer />
            <div className={cx("pageContainer")}>
              <div className={cx("noticeContainer")}>
                <div className={cx("noticeBlock")}>
                  <h4>
                    {name}님,
                    <br />
                    수강 중이신 강의가 곧 종강합니다!
                  </h4>
                  <ul>
                    <li>강의명: {course_title}</li>
                    <li>기수: {round_cur}</li>
                    <li>종강일: {cur_end_date}</li>
                  </ul>
                </div>
                <div className={cx("noticeBlock")}>
                  <h4>
                    처음 다짐했던 마음으로
                    <br />
                    새롭게 수강하실 수 있어요!
                  </h4>
                  <ul>
                    <li>강의명: {course_title}</li>
                    <li>개강일: {nxt_start_date}</li>
                    <li>재수강료: 110,000원</li>
                  </ul>
                  <ul>
                    <li>
                      👉재수강 신청은 {cur_end_date} 23:59까지만 가능합니다.
                    </li>
                    <li>
                      👉재수강을 신청하신 후에도, 현재 수강중이신 강의를{" "}
                      {cur_end_date}까지 들으실 수 있습니다.
                    </li>
                  </ul>
                </div>
              </div>
              <div className={cx("howtoContainer")}>
                <h4>재수강 신청 방법</h4>
                <ul className={cx("steps")}>
                  <li>
                    1. 아래의 계좌로 송금을 완료합니다.
                    <ul className={cx("accInfo")}>
                      <li>입금해주실 수강료: 110,000원</li>
                      <li>계좌: 국민은행 581201-01-398679</li>
                      <li>예금주: 팀스파르타(주)</li>
                    </ul>
                  </li>
                  <li>2. 재수강 신청서를 제출합니다.</li>
                  <li>
                    3. 개강일에 강의실로 들어와 사전 과제를 제출하고 1주차
                    1강부터 새롭게 수강을 시작합니다!✨
                  </li>
                </ul>
              </div>
              <hr />
              <div className={cx("yourNameContainer")}>
                <input
                  onChange={this.handleName}
                  type="text"
                  placeholder={"입금자명을 적어주세요"}
                />
                <span>*일치하지 않을 경우 입금 확인이 되지 않습니다.</span>
              </div>
              <div className={cx("agree")}>
                <a href="https://www.notion.so/teamsparta/5ed2d676c4e241ddafe563369b082cd2">
                  재수강규정과 환불규정
                </a>
                에 동의하고,
              </div>
              <div className={cx("ctaContainer")} onClick={() => this.submit()}>
                재수강 신청서 제출하기
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedRetakePage = connect(mapStateToProps, {
  loginWithToken: userActions.loginWithToken,
  refreshInfo: userActions.refreshInfo,
})(RetakePage);
export { connectedRetakePage as RetakePage };
