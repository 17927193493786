import styled from "styled-components";
import { keyframes } from "styled-components";
import {
  breakpoints,
  device,
  DisplayResolution,
} from "../../../../../_styles/device";

const Rotate = keyframes`
  100% {
    transform: rotate(-360deg)
  }
`;

export const CertifiDesktopWrapper = styled.div`
  padding: 80px 16px;
  display: flex;
  justify-content: center;
  ${DisplayResolution.MiniTablet} {
    display: none;
  }
`;

export const CertifiTable = styled.table``;

export const CertifiHeaderRows = styled.tr`
  border-bottom: 2px solid #3a3e41;
`;

export const CertifiHeader = styled.th`
  padding-bottom: 20px;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  color: #3a3e41;
  padding-left: 2px;
`;

export const CertifiCaution = styled(CertifiHeader)`
  font-size: 13px;
  font-weight: 400;
  color: #81898f;
  padding-left: 16px;
`;

export const CourseInfoRow = styled.tr`
  border-bottom: 1px solid #e4ebf0;
`;

export const CourseStatusBadge = styled.div`
  border-radius: 8px;
  background-color: #fff1f4;
  align-items: center;
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  color: #e8344e;
`;

export const CourseEnrolled = styled(CourseStatusBadge)`
  width: 53px;
  margin: 0;
  @media ${device.desktop} {
    margin: 0 auto;
  }
`;

export const CourseCompleted = styled(CourseStatusBadge)`
  width: 41px;
  background-color: #edf3ff;
  color: #0085ff;
  margin: 0;
  @media ${device.desktop} {
    margin: 0 auto;
  }
`;

export const CourseEnded = styled(CourseStatusBadge)`
  width: 53px;
  background-color: #e4ebf0;
  color: #5f666b;
  margin: 0;
  @media ${device.desktop} {
    margin: 0 auto;
  }
`;

export const CourseStatusTd = styled.td`
  width: 115px;
`;

export const CourseTitleTd = styled.td`
  width: 435px;
  padding: 24px 0 23px;
`;

export const CourseTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CourseTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3a3e41;
  white-space: pre-wrap;
`;
export const CoursePeriod = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #81898f;
`;

export const Voucher = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: Pretendard;
  font-weight: 500;
  line-height: 150%;
  font-size: 14px;
  color: #81898f;
  @media ${device.desktop} {
    font-weight: 600;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #d7e0e6;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
  cursor: ${(props) => (props.isActive ? "pointer" : "default")};
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const ReceiptTd = styled.td`
  width: 84px;
`;

export const CertifiTd = styled.td`
  width: 120px;
`;

export const DownloadIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const GetLinkedinContainer = styled(DownloadContainer)`
  background-color: #0077b6;
  border: none;
`;

export const LinkedInImg = styled.img`
  width: 19.5px;
  height: 19.5px;
`;

export const CertificatesHeader = styled(CertifiHeader)`
  padding-left: 27px;
`;

export const SolvingImg = styled.img`
  width: 24px;
  height: 24px;
  animation: ${Rotate} 2s linear infinite;
  transform-origin: 50% 50%;
`;

export const CertificateMobileWrapper = styled.div`
  display: flex;
  padding: 30px 16px;
  background-color: #ffffff;
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

export const CertifiMaxwidthWrapper = styled.div`
  max-width: 328px;
  margin: 0 auto;
`;

export const CertifiMobileTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #141617;
`;

export const CertifiMobileSubTitle = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #81898f;
  margin-top: 10px;
`;

export const CertifiCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const CertifiCard = styled.div`
  width: 328px;
  padding: 24px 16px 32px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
`;

export const CertifiCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

export const CertifiCardTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #3a3e41;
  white-space: pre-wrap;
`;

export const CertifiCardSubTitle = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #81898f;
`;

export const CertifiCardReceipt = styled.div`
  display: flex;
  flex-direction: row;
  width: 296px;
  height: 60px;
  gap: 4px;
  border: 1px solid #d7e0e6;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 36px;
  justify-content: center;
  padding-top: 20px;
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
`;

export const CertifiCardReceiptTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 14px;
  color: #3a3e41;
`;

export const CertifiCardDownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 12px;
`;

export const CertifiCardDownload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 142px;
  height: 108px;
  background-color: #ffffff;
  border: 1px solid #d7e0e6;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
`;

export const CertifiCardDownloadTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 14px;
  color: #3a3e41;
`;

export const CertifiCardLinkedinContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  margin-top: 32px;
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
`;

export const CertifiCardLinkedinTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #0075b8;
`;
