import React from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../../_helpers";
import { useRecommendPageView } from "../../hooks/completeRecommend/useRecommendPageView";
import { useGetEnrolled } from "../../queries/homeworkV3";
import BackToSyllabusButton from "./BackToSyllabusButton/BackToSyllabusButton";
import * as S from "./completeRecommendPage.style";
import { PopularArticleSection } from "./PopularArticleSection/PopularArticleSection";
import { RecommendTrackSection } from "./RecommendTrackSection/RecommendTrackSection";

const CompleteRecommendPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const enrolledId = searchParams.get("enrolled_id");

  const { data: enrolledData, isLoading: isEnrolledLoading } =
    useGetEnrolled(enrolledId);

  const { round_id, courses, course_id } = enrolledData?.context || {};

  useRecommendPageView(enrolledId);

  if (isEnrolledLoading) return null;

  return (
    <S.Container>
      <RecommendTrackSection
        courseId={course_id}
        courseTitle={courses?.title}
      />
      <PopularArticleSection
        courseId={course_id}
        courseTitle={courses?.title}
      />
      <S.BackButtonWrapper>
        <BackToSyllabusButton
          onClick={() => {
            history.push(`/enrolleds/${enrolledId}/rounds/${round_id}/roadmap`);
          }}
        />
      </S.BackButtonWrapper>
    </S.Container>
  );
};

export default CompleteRecommendPage;
