import styled from "@emotion/styled";
import { neutralDay, wBody4, wTitle4, scc } from "@teamsparta/design-system";

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 18px;
  width: 282px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  margin-bottom: 16px;
`;

//내배캠용
export const Duration = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 18px;
  gap: 16px;
  width: 282px;
  height: 73px;
  background: ${neutralDay.white};
  border-radius: 24px;
  margin-bottom: 16px;
`;
//내배단용
export const DurationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  width: 282px;
  height: 25px;
  margin-bottom: 16px;
`;
export const Title = styled.div`
  ${wTitle4};
  font-weight: 700;
`;

export const DurationText = styled.div`
  ${wBody4};
  color: ${neutralDay.gray60};
`;
export const RemainProgressContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
`;
export const RemainProgressBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 119px;
  height: 100px;
  background: ${neutralDay.gray0};
  border-radius: 12px;
`;

export const SubTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: ${neutralDay.gray80};
  font-family: Pretendard;
`;
export const RemainDays = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: ${(props) =>
    props.days < 8 ? `${scc.red100}` : `${neutralDay.gray100}`};
  font-family: Pretendard;
`;
export const MainTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: ${neutralDay.gray100};
  font-family: Pretendard;
`;
