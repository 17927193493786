import { history } from "../../../_helpers";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import React, { useMemo, useState } from "react";
import { getDeviceId, sendLog } from "../../businesslogics/logging";
import {
  getGETAuthOptions,
  getStartPaymentUrl,
  getZeropayUrl,
} from "../../../_urls";
import privateInstance from "../../_helper/axios";
import { connect } from "react-redux";
import { getCookie } from "../../_helper";

const NbQuizResultPage = ({ user }) => {
  const course_id = "6115cea3e73913245c9538e0";
  const location = useLocation();
  const result = useMemo(() => location.state?.result, []);
  const hasCard = useMemo(() => location.state?.hasCard, []);
  const [isValid, setIsValid] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  if (isValid === false) {
    if (result !== true) {
      alert("잘못된 접근입니다.");
      history.replace("/mypage");
    } else {
      history.replace(location.pathname, null);
      setIsValid(true);
    }
  }

  async function doZeropay(result) {
    const { order_no } = result;
    const next_endpoint = getZeropayUrl(course_id, order_no);
    const endPoint = next_endpoint + "?has_card=" + hasCard;
    const next_options = getGETAuthOptions();
    await fetch(endPoint, next_options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          const { course_id, round_id } = result;
          history.replace(
            `/payment/prequestion/${course_id}/${round_id}${window.location.search}`
          );
          return;
        }
        alert("결제가 정상적으로 완료되지 않았습니다.");
      });
  }

  async function startPayment() {
    let data = {
      course_id,
      gold: 0,
      name: user.name,
      phone: user.phone,
      email: user.email,
      payment_method: "nicepay",
      making_target: false,
      device_id: getDeviceId(),
      unique_id: getDeviceId(),
      selectedChoices: [],
      isPaypal: false,
      coupon_id: null,
      selectedRoundClass: null,
    };
    await privateInstance
      .post(getStartPaymentUrl(course_id), data)
      .then((resp) => resp.data)
      .then((result) => {
        doZeropay(result);
        // 결제정보와 대응되는 UTM log저장
        let utm_source = getCookie("utm_source");
        let utm_medium = getCookie("utm_medium");
        let utm_campaign = getCookie("utm_campaign");
        let utm_content = getCookie("utm_content");
        let utm_term = getCookie("utm_term");
        let log_data = {
          order_no: result.order_no,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term,
        };
        sendLog("", log_data, "", "purchase_utm");
      });
  }

  return (
    <Wrapper shown={1}>
      <Header>
        <Logo src={"/v2/assets/images/logo.png"} />
        <H3>
          <b>대상자</b>
          &nbsp;조회결과
        </H3>
      </Header>
      <ResultWrapper shown={1}>
        {hasCard ? (
          <>
            <h3>{user.name} 님은 내일배움카드가</h3>
            <h3>이미 있으시군요?</h3>
          </>
        ) : (
          <>
            <h3>{user.name} 님은 내일배움카드</h3>
            <h3>발급 대상자입니다!</h3>
          </>
        )}
        <RichRtan src={"/assets/images/card_rtan.png"} />
        {hasCard ? (
          <p style={{ textAlign: "center" }}>
            내일배움카드가 이미 있으시다면,
            <br />
            가장 빠른 합류가 가능합니다!
            <br />
            바로 무료 코딩 강의들으러 가볼까요?
          </p>
        ) : (
          <p>
            지금 바로 내일배움카드 발급받고,
            <br />
            무료로 코딩 강의들으러 가볼까요?
          </p>
        )}
        <div
          style={{ all: "unset", marginTop: "36px" }}
          onClick={() => {
            setIsVisible(false);
            if (isVisible) {
              startPayment();
            }
          }}
        >
          <RedBtnR>
            {isVisible
              ? hasCard
                ? "내일배움단 합류하기"
                : "내일배움카드 발급받기"
              : "로딩 중입니다"}
            <img src={"/v2/assets/images/icon-arrow.svg"} width={20} />
          </RedBtnR>
        </div>
        <WhiteButton
          onClick={() => history.replace("/payment/6115cea3e73913245c9538e0")}
        >
          처음으로 돌아가기
        </WhiteButton>
      </ResultWrapper>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedNbQuizResultPage = connect(
  mapStateToProps,
  {}
)(NbQuizResultPage);
export { connectedNbQuizResultPage as NbQuizResultPage };

const YellowBox = styled.div`
  margin-top: 20px;
  width: 328px;
  height: 80px;
  flex-grow: 0;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff8d2;
  white-space: nowrap;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.04px;
  text-align: left;
  color: #000;
  b {
    font-weight: bold;
  }
`;

const WhiteButton = styled.div`
  cursor: pointer;
  margin-top: 20px;
  width: 328px;
  height: 59px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  border-radius: 8px;
  //border: solid 1px #eaebed;
  //background-color: #fff;

  flex-grow: 0;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000;
`;

const Img = styled.img`
  width: 211px;
  flex-grow: 0;
`;
const RichRtan = styled.img`
  width: 328px;
  height: 121px;
  flex-grow: 0;
  object-fit: contain;
  margin: 24px 0;
`;

const H3 = styled.h3`
  text-align: center;
  margin-top: 16px;
`;
const Logo = styled.img`
  width: 63px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
`;
const Wrapper = styled.div`
  ${({ shown }) =>
    shown ? `background-color: #fff;` : `background-color: #eef9d9;`}
  min-height: 100vh;
`;
const ResultWrapper = styled.div`
  margin-top: 16px;
  background-color: #eef9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 138px;
  h3 {
    flex-grow: 0;
    object-fit: contain;
    font-family: "Cafe24Ohsquare";
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
`;

const Header = styled.div`
background-color: white;
padding: 16px 0 1px;
display: flex;
flex-direction: column;
align-items: center;
    h3 {
  height: 27px;
  flex-grow: 0;
  font-family: "Pretendard";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.05px;
  text-align: center;
  color: #000;
b {
width: 67px;
  height: 22px;
  flex-grow: 0;
  font-family: "Cafe24Ohsquare";
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  vertical-align: text-bottom;
}
}
    }
`;
const BenefitWrapper = styled.div`
margin-top: 29px;
width: 333px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 32px 0 32px 0;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 21px 0 rgba(40, 82, 26, 0.17);
  background-color: #fff;
  h3 {
  margin: 0;
  flex-grow: 0;
  font-family: "Pretendard";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.04px;
  text-align: center;
  color: #000;
  
  b {
  flex-grow: 0;
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: center;
  color: #000;
}}
  }
  img {
  margin-bottom: 16px;
  }
  p {
  margin: 0;
  flex-grow: 0;
  font-family: Pretendard;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.04px;
  text-align: center;
  color: #000;
font-weight: 500;
  
  b {
  
  font-weight: bold;
  }
  }
`;

const RedBtnR = styled.div`
  cursor: pointer;
  width: 271px;
  height: 58px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  box-shadow: 0 4px 10px 0 rgba(245, 30, 30, 0.34);
  background-color: #e8344e;

  font-family: "Pretendard";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fff;

  flex-grow: unset;
`;
