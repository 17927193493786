import React from "react";
import styled from "@emotion/styled";

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: rgba(20, 22, 23, 0.2);
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20px;
`;

const Dots = ({ count }) => {
  return (
    <DotContainer>
      {Array.from({ length: count }, (_, i) => (
        <Dot key={i} />
      ))}
    </DotContainer>
  );
};

export default Dots;
