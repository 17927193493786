import { useEffect } from "react";
import { sendCustomDataLog } from "../../businesslogics/logging";

export const useLogNpsPageView = (enrolled) => {
  useEffect(() => {
    if (enrolled.loaded) {
      sendCustomDataLog("scc_postquestionPage_view", {
        course_title: enrolled.data?.courses.title,
        course_id: enrolled.data?.course_id,
      });
    }
  }, [enrolled.loaded]);
};
