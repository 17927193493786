import styled from "@emotion/styled";
import { neutralDay, mBody4 } from "@teamsparta/design-system";
import {
  linebannerMobileHeight,
  zIndexGnbMenu,
} from "../../../styles/variables";

export const Wrapper = styled.div`
  padding: 0;
  z-index: ${zIndexGnbMenu};
  width: 100%;
  height: 100dvh;
  position: fixed;

  left: ${({ show }) => (show ? 0 : "-72%")};
  overflow: scroll;
  background: white;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const OverwrapTopWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(
    50px +
      ${({ showLineBanner }) =>
        showLineBanner ? `${linebannerMobileHeight}px` : "0px"}
  );
  overflow: hidden;
  padding: 0 16px;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const LogoImg = styled.img`
  width: 100px;
  height: 34px;
`;
export const CategoriesContainer = styled.div`
  height: ${({ showSubCategory }) => (showSubCategory ? "120dvh" : "100dvh")};
  padding: 16px 0 30px 0;
`;

export const SubTitle = styled.div`
  ${mBody4};
  ${neutralDay.gray80}
  display: flex;
  padding: 8px 0;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

export const SubGroup = styled.div`
  display: flex;
  width: 50%;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const NoMarginDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${neutralDay.gray10};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${neutralDay.gray10};
  margin: 16px 0;
`;

export const ThickDivider = styled.div`
  width: 100%;
  height: 8px;
  align-self: stretch;
  background: ${neutralDay.gray10};
  margin: 16px 0;
`;

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  height: 100%;
`;
