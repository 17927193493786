import { config } from "../config";
import { authHeader, handleResponse, privateFetch } from "../_helpers";

function getClassroom() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  return privateFetch(`${config.apiUrl}/v1/classroom/`, requestOptions).then(
    handleResponse
  );
}

export const classroomService = {
  getClassroom,
};
