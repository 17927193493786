function generateKoreanNames() {
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const familyNames = [
    "김",
    "이",
    "박",
    "정",
    "강",
    "조",
    "윤",
    "장",
    "임",
    "한",
    "오",
    "서",
    "신",
    "권",
    "황",
    "안",
    "송",
    "류",
    "홍",
    "고",
    "문",
    "양",
    "손",
    "배",
    "조",
    "백",
    "허",
    "유",
    "남",
    "심",
  ];

  const givenNames = [
    "민준",
    "서준",
    "예준",
    "도윤",
    "시우",
    "주원",
    "하준",
    "지후",
    "지훈",
    "준서",
    "예진",
    "소민",
    "유진",
    "지유",
    "채원",
    "지윤",
    "은서",
    "수아",
    "다은",
    "민서",
    "예서",
    "지민",
    "수민",
    "소율",
    "예원",
    "시윤",
    "지아",
    "설아",
    "아린",
    "다현",
  ];

  const randomFamilyName = familyNames[getRandomInt(0, familyNames.length - 1)];
  const randomGivenName = givenNames[getRandomInt(0, givenNames.length - 1)];

  return `${randomFamilyName}${randomGivenName}`;
}

export function generateInClassData(inputValue, defaultObj) {
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const listLength = getRandomInt(10, 30);
  const inClassList = [defaultObj];

  for (let i = 0; i < listLength; i++) {
    inClassList.push({
      name: generateKoreanNames(),
      week: 1,
      lecture: getRandomInt(1, inputValue),
    });
  }

  inClassList.sort((a, b) => b.lecture - a.lecture);

  const maxLecture = Math.max(...inClassList.map((data) => data.lecture));

  const maxLctureCount = inClassList.filter(
    (data) => data.lecture === maxLecture
  ).length;

  return {
    inClassList,
    maxLctureCount,
  };
}
