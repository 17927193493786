import { PIRATES } from "../../../../_consts";
import { useDevice } from "../../../pages/NBCampAttendPage";

export const CategoryLogoSVG = ({ category }) => {
  switch (category) {
    case "catalogv3":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <path
              id="Vector 160"
              d="M4 13.3345H12"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <rect
              id="Rectangle 7872"
              x="2.6687"
              y="2.66553"
              width="10.6667"
              height="8"
              rx="1.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle 7873"
              d="M7 5.60142V7.73192C7 7.99373 7.28798 8.15334 7.51 8.01458L9.2144 6.94933C9.42329 6.81878 9.42329 6.51456 9.2144 6.384L7.51 5.31875C7.28798 5.17999 7 5.3396 7 5.60142Z"
              fill="#3A3E41"
            />
          </g>
        </svg>
      );
    case "nb":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#419400" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.04947 5.81899L3.84355 9.27844L7.00224 10.4262L7.00224 9.42584L4.42754 7.59591L4.60245 7.29295L2.04947 5.81899ZM2.05209 5.81908L5.94511 5.64306L8.09297 7.94954L8.00574 8.92567L4.77999 6.99009L4.60508 7.29304L2.05209 5.81908Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9505 5.8185L12.1565 9.27795L8.99776 10.4257L8.99776 9.42535L11.5725 7.59542L11.3975 7.29246L13.9505 5.8185ZM13.9479 5.81895L10.0549 5.64294L7.90703 7.94941L7.99426 8.92555L11.22 6.98996L11.3949 7.29292L13.9479 5.81895Z"
            fill="white"
          />
          <path d="M7.5 10.0001H8.5L9 14.0001H7L7.5 10.0001Z" fill="white" />
        </svg>
      );
    case "chang":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1839_22874)">
            <rect width="16" height="16" rx="8" fill="#0F16AE" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.08023 13.4908C6.12032 13.5915 6.36689 13.6101 6.9999 14.3305C8.03436 15.5079 6.9999 26 6.9999 26H7.98223H8.01757H8.9999C8.9999 26 7.96544 15.5079 8.9999 14.3305C9.63291 13.6101 9.87948 13.5915 9.91957 13.4908C9.94499 13.4269 9.88736 13.33 9.79257 13H8.01757H7.98223H6.20723C6.11244 13.33 6.05481 13.4269 6.08023 13.4908Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 3L5 7.69301L6.32 12H7.5V7.21346H8V7.21352H8.5V12H9.68L11 7.69306L8 3.00005V3Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1839_22874">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "swcamp":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <rect x="0.789062" width="16" height="16" rx="8" fill="#141617" />
          <path
            d="M4.78906 5.55196V7.21696L10.6907 10.448H6.52V9.39535H4.78906V10.448V11.058V12H12.4782V10.448H11.2913L11.2866 8.89844L6.52 6.39976V5.55196H10.7449V6.60465H12.4782V5.55196V4.94201V4H4.78906V5.55196Z"
            fill="white"
          />
        </svg>
      );
    case "intellipick":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3190_45714)">
            <rect width="16" height="16" rx="8" fill="#6E50FF" />
            <path
              d="M7.57688 7.8871C6.98613 7.17742 6.76763 6.91129 6.27399 6.32258C5.30289 6.6129 4.89827 6.73387 4 7.00806C4.78497 8.65323 4.9711 9.00806 5.90173 11H9.05781C9.72139 7.8871 13.9618 3.45161 18 3C12.5457 3 9.52717 5.32258 7.57688 7.87903V7.8871Z"
              fill="#FFCB40"
            />
          </g>
          <defs>
            <clipPath id="clip0_3190_45714">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "ddingdong":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#FF7005" />
          <path
            d="M11.262 9.0794V7.7199C11.262 6.15908 10.1624 4.85452 8.69945 4.5377V3.99257C8.69945 3.60586 8.38728 3.2937 8.00057 3.2937C7.61386 3.2937 7.3017 3.60586 7.3017 3.99257V4.5377C5.83873 4.85452 4.73916 6.15908 4.73916 7.7199V9.0794L4.13813 9.68043C3.84461 9.97396 4.04961 10.4772 4.46427 10.4772H11.5322C11.9469 10.4772 12.1565 9.97396 11.863 9.68043L11.262 9.0794Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50012 11.4771C6.66074 12.1819 7.27131 12.7064 8 12.7064C8.72869 12.7064 9.33927 12.1819 9.49988 11.4771H6.50012Z"
            fill="white"
          />
        </svg>
      );
    case "community":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 3.99886C2 3.26248 2.59695 2.66553 3.33333 2.66553H12.6667C13.403 2.66553 14 3.26248 14 3.99886V9.99886C14 10.7352 13.403 11.3322 12.6667 11.3322H10.0362C9.80631 11.3322 9.59266 11.4506 9.47083 11.6455L8 13.9989L6.52917 11.6455C6.40734 11.4506 6.1937 11.3322 5.96384 11.3322H3.33333C2.59695 11.3322 2 10.7352 2 9.99886V3.99886Z"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <rect
            x="4.58203"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill="#3A3E41"
          />
          <rect
            x="7.25"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill="#3A3E41"
          />
          <rect
            x="9.91797"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill="#3A3E41"
          />
        </svg>
      );
    case "blog":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66663 4H13.3333"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66663 8H13.3333"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66663 12H13.3333"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <circle cx="3.41663" cy="4" r="0.75" fill="#3A3E41" />
          <circle cx="3.41663" cy="8" r="0.75" fill="#3A3E41" />
          <circle cx="3.41663" cy="12" r="0.75" fill="#3A3E41" />
        </svg>
      );
    case "event":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <rect
              id="Rectangle 6370"
              x="2.66663"
              y="3.3335"
              width="10.6667"
              height="10.6667"
              rx="1.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Vector 168"
              d="M2.66663 6.6665L13.3333 6.6665"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 564"
              d="M5.33337 2V3.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 565"
              d="M10.6666 2V3.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Star 4"
              d="M7.63468 8.39867C7.78141 8.10137 8.20534 8.10137 8.35207 8.39867L8.68206 9.06731C8.74033 9.18536 8.85295 9.26719 8.98324 9.28612L9.72112 9.39334C10.0492 9.44102 10.1802 9.84421 9.9428 10.0756L9.40887 10.5961C9.31459 10.688 9.27157 10.8204 9.29383 10.9501L9.41987 11.685C9.47592 12.0118 9.13295 12.261 8.83949 12.1067L8.17951 11.7597C8.06298 11.6985 7.92377 11.6985 7.80724 11.7597L7.14725 12.1067C6.8538 12.261 6.51083 12.0118 6.56687 11.685L6.69292 10.9501C6.71518 10.8204 6.67216 10.688 6.57788 10.5961L6.04394 10.0756C5.80654 9.84421 5.93754 9.44102 6.26563 9.39334L7.00351 9.28612C7.1338 9.26719 7.24642 9.18536 7.30469 9.06731L7.63468 8.39867Z"
              fill="#3A3E41"
            />
          </g>
        </svg>
      );
    case "faq":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <rect width="16" height="16" fill="white" />
            <path
              id="Vector 1450"
              d="M13.3334 12V13.3333C13.3334 13.7015 13.0349 14 12.6667 14H9.33337"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector 1448"
              d="M2.66663 7.33333V7.33333C2.66663 4.38782 5.05444 2 7.99996 2V2C10.9455 2 13.3333 4.38782 13.3333 7.33333V7.33333"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle 19794"
              d="M1.33337 8.26651C1.33337 7.38285 2.04972 6.6665 2.93337 6.6665C3.52248 6.6665 4.00004 7.14407 4.00004 7.73317V10.9332C4.00004 11.5223 3.52248 11.9998 2.93337 11.9998C2.04972 11.9998 1.33337 11.2835 1.33337 10.3998V8.26651Z"
              fill="white"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle 19795"
              d="M12 7.73317C12 7.14407 12.4776 6.6665 13.0667 6.6665C13.9503 6.6665 14.6667 7.38285 14.6667 8.2665V10.3998C14.6667 11.2835 13.9503 11.9998 13.0667 11.9998C12.4776 11.9998 12 11.5223 12 10.9332V7.73317Z"
              fill="white"
              stroke="#3A3E41"
              strokeWidth="1.5"
            />
            <circle
              id="Ellipse 2734"
              cx="6.66667"
              cy="9.33317"
              r="0.666667"
              fill="#3A3E41"
            />
            <circle
              id="Ellipse 2735"
              cx="9.33329"
              cy="9.33317"
              r="0.666667"
              fill="#3A3E41"
            />
            <path
              id="Vector 1449"
              d="M6 6.66683C6.11111 6.55572 7 5.3335 7 5.3335C8.33333 6.3335 8.93333 6.40016 10 6.66683"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );
    case "bisness":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <path
              id="Rectangle 19796"
              d="M12.6666 7.33333V3.33333C12.6666 2.59695 12.0697 2 11.3333 2H3.99996C3.26358 2 2.66663 2.59695 2.66663 3.33333V14"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 1451"
              d="M14 14V9.33333C14 8.59695 13.403 8 12.6666 8H9.99996C9.26358 8 8.66663 8.59695 8.66663 9.33333V14"
              stroke="#3A3E41"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              id="Rectangle 19797"
              x="4.66663"
              y="4"
              width="2"
              height="2.66667"
              rx="0.666667"
              fill="#3A3E41"
            />
            <rect
              id="Rectangle 19799"
              x="4.66663"
              y="8"
              width="2"
              height="2.66667"
              rx="0.666667"
              fill="#3A3E41"
            />
            <rect
              id="Rectangle 19798"
              x="8.66663"
              y="4"
              width="2"
              height="2.66667"
              rx="0.666667"
              fill="#3A3E41"
            />
          </g>
        </svg>
      );
    case "exhibition":
      return (
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_2929_2483" fill="white">
            <rect width="14" height="10" rx="1.33" />
          </mask>
          <rect
            width="14"
            height="10"
            rx="1.33"
            stroke="#3A3E41"
            stroke-width="3"
            mask="url(#path-1-inside-1_2929_2483)"
          />
        </svg>
      );
    case "kdt":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33337 2L2.02435 12.9635C1.72899 13.4066 2.04659 14 2.57905 14H13.421C13.9535 14 14.2711 13.4066 13.9757 12.9635L6.66671 2"
            stroke="#3A3E41"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M5.33337 14.0002L7.42121 10.3464C7.67714 9.89857 8.32294 9.89857 8.57887 10.3464L10.6667 14.0002H5.33337Z"
            fill="#3A3E41"
          />
        </svg>
      );
    default:
      return <></>;
  }
};
export const PointIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_point_fill_24">
        <circle
          id="Ellipse 532"
          cx="8.75"
          cy="8.5"
          r="6"
          stroke="#3A3E41"
          strokeWidth="1.5"
        />
        <g id="Group 2598">
          <path
            id="Vector 165"
            d="M7.54956 5.96729V11.3006"
            stroke="#3A3E41"
            strokeWidth="1.2"
            strokeLinecap="round"
          />
          <path
            id="Vector 170"
            d="M9.21623 5.96729H7.54956V9.30062H9.21623C10.3273 9.30062 10.8829 8.63395 10.8829 7.63395C10.8829 6.63395 10.3273 5.96729 9.21623 5.96729Z"
            stroke="#3A3E41"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export const CouponIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <mask id="path-1-inside-1_283_19679" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.08325 4.40909C2.08325 3.90701 2.49027 3.5 2.99234 3.5H14.5075C15.0096 3.5 15.4166 3.90701 15.4166 4.40909V7.16667C14.6802 7.16667 14.0833 7.76362 14.0833 8.5C14.0833 9.23638 14.6802 9.83333 15.4166 9.83333V12.5909C15.4166 13.093 15.0096 13.5 14.5075 13.5H2.99234C2.49026 13.5 2.08325 13.093 2.08325 12.5909V9.83333C2.81963 9.83333 3.41659 9.23638 3.41659 8.5C3.41659 7.76362 2.81963 7.16667 2.08325 7.16667V4.40909Z"
        />
      </mask>
      <path
        d="M15.4166 7.16667V8.66667H16.9166V7.16667H15.4166ZM15.4166 9.83333H16.9166V8.33333H15.4166V9.83333ZM2.08325 9.83333V8.33333H0.583252V9.83333H2.08325ZM2.08325 7.16667H0.583252V8.66667H2.08325V7.16667ZM2.99234 2C1.66184 2 0.583252 3.07859 0.583252 4.40909H3.58325C3.58325 4.73544 3.31869 5 2.99234 5V2ZM14.5075 2H2.99234V5H14.5075V2ZM16.9166 4.40909C16.9166 3.07859 15.838 2 14.5075 2V5C14.1811 5 13.9166 4.73544 13.9166 4.40909H16.9166ZM16.9166 7.16667V4.40909H13.9166V7.16667H16.9166ZM15.5833 8.5C15.5833 8.59205 15.5086 8.66667 15.4166 8.66667V5.66667C13.8518 5.66667 12.5833 6.93519 12.5833 8.5H15.5833ZM15.4166 8.33333C15.5086 8.33333 15.5833 8.40795 15.5833 8.5H12.5833C12.5833 10.0648 13.8518 11.3333 15.4166 11.3333V8.33333ZM16.9166 12.5909V9.83333H13.9166V12.5909H16.9166ZM14.5075 15C15.838 15 16.9166 13.9214 16.9166 12.5909H13.9166C13.9166 12.2646 14.1811 12 14.5075 12V15ZM2.99234 15H14.5075V12H2.99234V15ZM0.583252 12.5909C0.583252 13.9214 1.66184 15 2.99234 15V12C3.31869 12 3.58325 12.2646 3.58325 12.5909H0.583252ZM0.583252 9.83333V12.5909H3.58325V9.83333H0.583252ZM1.91659 8.5C1.91659 8.40795 1.99121 8.33333 2.08325 8.33333V11.3333C3.64806 11.3333 4.91659 10.0648 4.91659 8.5H1.91659ZM2.08325 8.66667C1.9912 8.66667 1.91659 8.59205 1.91659 8.5H4.91659C4.91659 6.93519 3.64806 5.66667 2.08325 5.66667V8.66667ZM0.583252 4.40909V7.16667H3.58325V4.40909H0.583252Z"
        fill="#3A3E41"
        mask="url(#path-1-inside-1_283_19679)"
      />
      <path
        d="M10.0833 7.1665L7.41659 9.83317"
        stroke="#3A3E41"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="7.41667" cy="7.16667" r="0.666667" fill="#3A3E41" />
      <circle cx="10.0834" cy="9.83317" r="0.666667" fill="#3A3E41" />
    </svg>
  );
};

export const MyPageLogoSVG = ({ isMouseOnLogo, theme }) => {
  const color = isMouseOnLogo
    ? "#e8344e"
    : theme === PIRATES
    ? "white"
    : "#141617";
  const device = useDevice();
  const isMobile = device === "mobile";
  return (
    <svg
      width={isMobile ? "20" : "24"}
      height={isMobile ? "20" : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="8" r="4" stroke={color} strokeWidth="2" />
      <path
        d="M4.20703 19.998C5.76317 17.3079 8.67172 15.498 12.003 15.498C15.3343 15.498 18.2428 17.3079 19.799 19.998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const HamburgerSVG = ({ theme }) => {
  const color = theme === PIRATES ? "white" : "#141617";
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33203 5H16.6654"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M3.33203 10H16.6654"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M3.33203 15H16.6654"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const RightArrowSVG = ({ theme, isActive }) => {
  const getColor = () => {
    switch (theme) {
      case PIRATES:
        return isActive ? "#FF6C7A" : "#9DA7AE";
      default:
        return isActive ? "#e8344e" : "#81898f";
    }
  };
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13373 11.4697C5.84084 11.7626 5.84084 12.2374 6.13373 12.5303C6.42663 12.8232 6.9015 12.8232 7.19439 12.5303L6.13373 11.4697ZM10.6641 8L11.1944 8.53033C11.4873 8.23744 11.4873 7.76256 11.1944 7.46967L10.6641 8ZM7.19439 3.46967C6.9015 3.17678 6.42663 3.17678 6.13373 3.46967C5.84084 3.76256 5.84084 4.23744 6.13373 4.53033L7.19439 3.46967ZM7.19439 12.5303L11.1944 8.53033L10.1337 7.46967L6.13373 11.4697L7.19439 12.5303ZM11.1944 7.46967L7.19439 3.46967L6.13373 4.53033L10.1337 8.53033L11.1944 7.46967Z"
        fill={getColor()}
      />
    </svg>
  );
};

export const CloseSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#fff" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16797 4.16699L15.8346 15.8337"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
      <path
        d="M15.832 4.16699L4.16536 15.8337"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const DownArrowSVG = ({ accent = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 8.33203L10 13.332L15 8.33203"
      stroke="#9DA7AE"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ZeroWonIcon = () => {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2861_5599)">
        <g filter="url(#filter0_di_2861_5599)">
          <mask
            id="path-1-outside-1_2861_5599"
            maskUnits="userSpaceOnUse"
            x="0.686119"
            y="1.22205"
            width="18.4607"
            height="21.2257"
            fill="black"
          >
            <rect
              fill="white"
              x="0.686119"
              y="1.22205"
              width="18.4607"
              height="21.2257"
            />
            <path d="M10.9022 20.8982C6.15254 21.2576 3.07184 18.1728 2.60973 12.0661C2.14763 5.95946 4.72905 2.44623 9.47868 2.08681C14.2283 1.7274 17.309 4.81217 17.7711 10.9188C18.2332 17.0255 15.6518 20.5387 10.9022 20.8982ZM10.6277 17.2716C12.9675 17.0945 13.8592 14.8857 13.583 11.2358C13.3068 7.5858 12.0928 5.53632 9.75311 5.71338C7.41339 5.89043 6.52163 8.09924 6.79783 11.7492C7.07403 15.3992 8.28802 17.4486 10.6277 17.2716Z" />
          </mask>
          <path
            d="M10.9022 20.8982C6.15254 21.2576 3.07184 18.1728 2.60973 12.0661C2.14763 5.95946 4.72905 2.44623 9.47868 2.08681C14.2283 1.7274 17.309 4.81217 17.7711 10.9188C18.2332 17.0255 15.6518 20.5387 10.9022 20.8982ZM10.6277 17.2716C12.9675 17.0945 13.8592 14.8857 13.583 11.2358C13.3068 7.5858 12.0928 5.53632 9.75311 5.71338C7.41339 5.89043 6.52163 8.09924 6.79783 11.7492C7.07403 15.3992 8.28802 17.4486 10.6277 17.2716Z"
            fill="url(#paint0_linear_2861_5599)"
            shapeRendering="crispEdges"
          />
          <path
            d="M10.8859 20.6825C8.56479 20.8582 6.67445 20.193 5.30455 18.7576C3.92908 17.3164 3.05414 15.0733 2.82535 12.0498L2.39412 12.0824C2.62743 15.1657 3.52389 17.5182 4.9917 19.0562C6.46509 20.6 8.48992 21.2975 10.9185 21.1138L10.8859 20.6825ZM2.82535 12.0498C2.59656 9.02636 3.12398 6.6771 4.26691 5.0453C5.40521 3.42012 7.17393 2.47807 9.49499 2.30243L9.46236 1.8712C7.03379 2.05497 5.13699 3.04924 3.91269 4.7972C2.69304 6.53854 2.1608 8.99923 2.39412 12.0824L2.82535 12.0498ZM9.49499 2.30243C11.8161 2.12679 13.7064 2.79195 15.0763 4.22733C16.4518 5.66856 17.3267 7.9117 17.5555 10.9352L17.9867 10.9025C17.7534 7.8193 16.857 5.46673 15.3891 3.92875C13.9158 2.38493 11.8909 1.68742 9.46236 1.8712L9.49499 2.30243ZM17.5555 10.9352C17.7843 13.9586 17.2569 16.3079 16.1139 17.9397C14.9756 19.5648 13.2069 20.5069 10.8859 20.6825L10.9185 21.1138C13.3471 20.93 15.2439 19.9357 16.4681 18.1878C17.6878 16.4464 18.22 13.9857 17.9867 10.9025L17.5555 10.9352ZM10.6441 17.4872C11.8955 17.3925 12.77 16.7455 13.2823 15.6474C13.7864 14.5672 13.9378 13.0584 13.7986 11.2194L13.3674 11.2521C13.5044 13.0631 13.3481 14.4837 12.8904 15.4646C12.441 16.4277 11.6997 16.9736 10.6114 17.056L10.6441 17.4872ZM13.7986 11.2194C13.6595 9.38047 13.2827 7.91169 12.6218 6.91956C11.9501 5.91115 10.9882 5.40306 9.73679 5.49776L9.76942 5.92899C10.8577 5.84664 11.6727 6.27476 12.2619 7.15931C12.862 8.06014 13.2304 9.44108 13.3674 11.2521L13.7986 11.2194ZM9.73679 5.49776C8.48536 5.59246 7.61089 6.23951 7.09855 7.33752C6.59447 8.41781 6.44306 9.92655 6.58221 11.7655L7.01345 11.7329C6.8764 9.9219 7.03276 8.50125 7.49045 7.52038C7.93987 6.55723 8.68113 6.01134 9.76942 5.92899L9.73679 5.49776ZM6.58221 11.7655C6.72137 13.6045 7.09811 15.0733 7.75901 16.0654C8.43074 17.0738 9.39262 17.5819 10.6441 17.4872L10.6114 17.056C9.52314 17.1383 8.70816 16.7102 8.11892 15.8256C7.51885 14.9248 7.15049 13.5439 7.01345 11.7329L6.58221 11.7655Z"
            fill="#30725D"
            fillOpacity="0.6"
            mask="url(#path-1-outside-1_2861_5599)"
          />
        </g>
        <g filter="url(#filter1_d_2861_5599)">
          <path
            d="M31.6176 17.362L29.3676 17.1676L29.5161 15.4494C28.3743 15.6255 26.901 15.6631 25.3507 15.6391L25.2246 17.0982L23.1382 16.9179L23.0722 17.6816L31.5269 18.412L31.3478 20.4848L20.4386 19.5423L20.5658 18.0695L19.8022 18.0035L19.9695 16.0671L22.7513 16.3075L22.8161 15.5575C21.6126 15.4947 20.4535 15.3946 19.4307 15.3062L19.598 13.3698C22.8435 13.6502 26.9084 13.9877 29.6834 13.513L29.7917 12.2585L28.3463 12.1336L28.5277 10.0335L29.9732 10.1584L30.0875 8.83567L32.3375 9.03007L31.6176 17.362ZM20.2261 10.5528C20.3592 9.01182 21.669 8.16329 24.3963 8.39892C27.1373 8.63574 28.3093 9.69869 28.1762 11.2396C28.0431 12.7806 26.706 13.6267 23.9651 13.3899C21.2378 13.1543 20.093 12.0937 20.2261 10.5528ZM22.6125 10.7589C22.5665 11.2908 23.2639 11.4884 24.123 11.5626C25.0093 11.6392 25.7178 11.5493 25.7625 11.0311C25.8085 10.4993 25.1112 10.3016 24.2384 10.2262C23.3793 10.152 22.6584 10.2271 22.6125 10.7589Z"
            fill="url(#paint1_linear_2861_5599)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_2861_5599"
          x="-0.696834"
          y="-1.08162"
          width="21.7745"
          height="25.1482"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.29739" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.447059 0 0 0 0 0.364706 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2861_5599"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2861_5599"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.864928" dy="0.648696" />
          <feGaussianBlur stdDeviation="0.432464" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2861_5599"
          />
        </filter>
        <filter
          id="filter1_d_2861_5599"
          x="16.4802"
          y="5.37494"
          width="18.4521"
          height="17.7046"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.29739" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.187622 0 0 0 0 0.445833 0 0 0 0 0.364544 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2861_5599"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2861_5599"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2861_5599"
          x1="9.28318"
          y1="-3.16004"
          x2="11.7732"
          y2="29.7459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30725D" />
          <stop offset="1" stopColor="#73CFB2" stop-opacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2861_5599"
          x1="26.7539"
          y1="5.66563"
          x2="24.5817"
          y2="30.8082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30725D" />
          <stop offset="1" stopColor="#73CFB2" />
        </linearGradient>
        <clipPath id="clip0_2861_5599">
          <rect
            width="32.651"
            height="23.1368"
            fill="white"
            transform="translate(0.875 0.0467911)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
