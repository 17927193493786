import React, {Component, Fragment} from 'react';
import styles from "./TreasureBoxModal.module.scss"
import classNames from "classnames/bind"
import {config} from "../../config";
import {connect} from "react-redux";
import {Button, Input, message, Rate, Skeleton, Tooltip} from 'antd';
import {codeSnippetActions, homeworkActions, lectureActions, lectureNoteActions, pointActions} from "../../_actions";
import {history} from "../../_helpers";
import {
    getApiCreateCheerupUrl,
    getApiCreateRatingUrl,
    getApiLectureDoneUrl,
    getApiLectureInfoUrl,
    getGETAuthOptions, getHomeworkUrl,
    getPostAuthOptions,
    SLACK_URL
} from "../../_urls";
import {getApiUserPointChanceAll, getApiUserPointChanceOnce} from "../../_urls/accounts.api";
import {CustomModal} from "../CustomModal";

const cx = classNames.bind(styles);

class TreasureBoxModal extends Component {

    state = {
        modalVisible: true,
        boxOpened: false,
        pointDelta: 0,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {onPlan} = this.props;
        this.props.getPointInfo(onPlan);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {onPlan} = this.props;
        if (prevProps.onPlan === false &&
            onPlan) {
            this.props.getPointInfo(onPlan);
        }
    }

    handleModalVisible = (isVisible) => {
        this.setState({
            modalVisible: isVisible
        })
    };

    _getImagePathTreauserBox = (isOpen) => {
        if (isOpen) {
            return ("/assets/images/lecture/box_open_effects.gif");
        } else {
            return ("/assets/images/lecture/treasure_box_unopened.png");
        }
    };

    _handleOpen = () => {
        let endpoint = getApiUserPointChanceOnce();
        let options = getPostAuthOptions({});

        fetch(endpoint, options)
            .then(resp => resp.json())
            .then(result => {
                if (result.ok) {
                    let pointDelta = parseInt(result['point_after']) - parseInt(result['point_before']);
                    this._openEffect(pointDelta);
                } else {
                    alert(result.message);
                }
            })

    };

    _handleOpenAll = () => {
        let endpoint = getApiUserPointChanceAll();
        let options = getPostAuthOptions({});

        this.setState({
            boxOpened: true,
        });
        fetch(endpoint, options)
            .then(resp => resp.json())
            .then(result => {
                if (result.ok) {
                    let pointDelta = parseInt(result['point_after']) - parseInt(result['point_before']);
                    this._openAllEffect(pointDelta);
                } else {
                    alert(result.message);
                }
            })
    };

    _openEffect = (pointDelta) => {
        this.setState({
            boxOpened: true,
            pointDelta: pointDelta,
        });
        setTimeout(() => {
            this.setState({
                boxOpened: false,
                pointDelta: 0,
            });
            this.props.getPointInfo();
        }, 1000)
    };

    _openAllEffect = (pointDelta) => {
        this.setState({
            boxOpened: true,
            pointDelta: pointDelta,
        });
        setTimeout(() => {
            this.setState({
                boxOpened: false,
                pointDelta: 0,
            });
            this.props.getPointInfo();
        }, 1500)
    };

    _endStudy = () => {

    };

    _continueStudy = () => {

    };


    render() {
        const {modalVisible, boxOpened} = this.state;
        const {pointInfo, user, visible, onPlan} = this.props;
        if (pointInfo.loaded === false) {
            return <div/>
        }
        const isEmpty = pointInfo.data.chance === 0;

        return (
            <CustomModal
                visible={visible}
                close={false}
                handleModalVisible={this.handleModalVisible}
                style={{height: 680, backgroundColor: '#F9FBFA'}}
                body={
                    <div className={cx('modalBodyContainer')}>
                        {/* title */}
                        <div className={cx('titleContainer')}>
                            {!onPlan && !isEmpty &&
                            <Fragment>
                                <h4>{user.name}님의 보물상자를 열어볼까요?</h4>
                                <p>
                                    보물상자는 강의를 들을 때마다 획득할 수 있어요.<br/>
                                </p>
                            </Fragment>
                            }
                            {!onPlan && isEmpty &&
                            <Fragment>
                                <h4>{user.name}님, 보물상자가 없어요!</h4>
                                <p>
                                    보물상자는 강의를 들을 때마다 획득할 수 있어요.<br/>
                                </p>
                            </Fragment>
                            }
                            {onPlan &&
                            <Fragment>
                                <h4>{user.name}님, 약속을 지키셨군요!</h4>
                                <p>
                                    선물로 보물상자를 드립니다. 어서 열어볼까요?<br/>
                                </p>
                            </Fragment>
                            }
                        </div>

                        {/* info - 보물상자 수 */}
                        <div className={cx('myinfoContainer')}>
                            <ul>
                                <li>
                                    <div className={cx('labelContainer')}>
                                        <img src={("/assets/icons/treasurebox_small.png")}/>
                                        <span>보물상자</span>
                                    </div>
                                </li>
                                <li>
                                        <span className={cx('numberContainer')}>
                                        {pointInfo.data.chance}
                                        </span>
                                    개
                                </li>
                            </ul>
                        </div>

                        {/* info - 나의 포인트 */}
                        <div className={cx('myinfoContainer')}>
                            <ul>
                                <li>
                                    <div className={cx('labelContainer')}>
                                        <img src={("/assets/icons/coin_small.png")}/>
                                        <span>포인트</span>
                                    </div>
                                </li>
                                <li>
                                        <span className={cx('numberContainer')}>
                                        {pointInfo.data.point}
                                        </span>
                                    포인트
                                </li>
                            </ul>
                        </div>

                        {/*더해진 금액 보여주기*/}
                        {!isEmpty &&
                        <Fragment>
                            {boxOpened &&
                            <div className={cx('addedPointContainer')}>
                                +{this.state.pointDelta}
                            </div>
                            }

                            {/* 상자 toggle 이미지 */}
                            {boxOpened === false &&
                            <div className={cx('treasureBoxContainer')}>
                                <img
                                    onClick={this._handleOpen}
                                    src={this._getImagePathTreauserBox(boxOpened)}
                                    alt="Treasure Box Opening"/>
                            </div>
                            }
                            {boxOpened &&
                            <div className={cx('treasureBoxContainerOpening')}>
                                <img
                                    // onClick={this._handleOpen}
                                    src={this._getImagePathTreauserBox(true)}
                                    alt="Treasure Box Unopened"/>
                            </div>
                            }
                        </Fragment>
                        }
                        {isEmpty && !onPlan &&
                        <Fragment>
                            <div className={cx('emtpyboxContainer')}>
                                <img
                                    onClick={this._handleOpen}
                                    src={("/assets/images/lecture/empty_box.png")}
                                    alt="Empty"/>
                            </div>
                            <div className={cx('buttonContainer')}>
                                <div className={cx('endButtonContainer')}>
                                    <Button onClick={this.props.handleClose}>공부 끝내기</Button>
                                </div>
                                <div className={cx('continueButtonContainer')}>
                                    <Button onClick={this.props.handleClose}>더 받으러 가기</Button>
                                </div>
                            </div>
                        </Fragment>
                        }
                        {isEmpty && onPlan &&
                        <Fragment>
                            <div className={cx('emtpyboxContainer')}>
                                <img
                                    onClick={this._handleOpen}
                                    src={("/assets/images/lecture/empty_box.png")}
                                    alt="Empty"/>
                            </div>
                            <div className={cx('buttonContainer')}>
                                <div className={cx('continueButtonContainer')}>
                                    <Button onClick={this.props.handleClose}>공부하러 출발!</Button>
                                </div>
                            </div>
                        </Fragment>
                        }
                        {/*한꺼번에 열기 버튼*/}
                        {!isEmpty && boxOpened === false &&
                        <div className={cx('buttonContainer')}>
                            <div className={cx('endButtonContainer')}>
                                <Button onClick={this._handleOpenAll}>한꺼번에 열기</Button>
                            </div>
                        </div>
                        }
                        {!isEmpty && boxOpened &&
                        <div className={cx('buttonContainerInactive')}>
                            <Button>여는 중입니다...</Button>
                        </div>
                        }
                    </div>
                }
            />
        );
    }
}


function mapStateToProps(state) {
    const {authentication, pointInfo,} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        pointInfo,
    }
}

const connectedTreasureBoxModal = connect(mapStateToProps, {
    getPointInfo: pointActions.getPointInfo,
})(TreasureBoxModal);
export {connectedTreasureBoxModal as TreasureBoxModal}