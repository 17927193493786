import styles from './B2bNPSRadioContainer.module.scss'
import classNames from "classnames/bind";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

const cx = classNames.bind(styles);

function B2bNPSRadioContainer({question, index, handleChange}) {
  const [radioChoices, setRadioChoices] = useState([])

  useEffect(() => {
    let howManyChoices = ['1', '2', '3', '4', '5', '6'];
    setRadioChoices(howManyChoices)
  }, [])

  return (
    <div className={cx("B2bNPSRadioWrapper")}>
      <div className={cx("B2bNPSRadioNumber")}>
        Q{index + 1}
      </div>
      <div className={cx("B2bNPSRadioQuestion")}>
        {question}
      </div>
      <div className={cx("B2bNPSRadioButtons")}>
        {radioChoices.map(radioChoice => (
          <label className={cx("B2bNPSRadioButtonWrapper")}>
            <input className={cx("B2bNPSRadioButton")} name={index} value={radioChoice}
                   type={"radio"} onChange={(e) => handleChange(e, index)}/>
            <hr className={cx("B2bNPSRadioLine")}/>
          </label>
        ))}
        <input className={cx("B2bNPSRadioButton")} name={index} value={'7'}
               type={"radio"} onChange={(e) => handleChange(e, index)}/>
      </div>
      <div className={cx("B2bNPSRadioPlaceholder")}>
        <div>불만족</div>
        <div>보통</div>
        <div>만족</div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  const {authentication, enrolled} = state;
  const {user} = authentication;
  return {user, enrolled};
}

const connectedB2bNPSRadioContainer = connect(mapStateToProps, {})(B2bNPSRadioContainer);
export {connectedB2bNPSRadioContainer as B2bNPSRadioContainer};