import { ROUND_SET, ROUND_TEST } from "../constants/rounds.constant";
import { reducer } from "./reducer";

const initState = {
  loaded: false,
};

export function round(state = initState, action) {
  switch (action.type) {
    case ROUND_SET.ROUND_SET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ROUND_SET.ROUND_SET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ROUND_SET.ROUND_SET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function roundTest(state = initState, action) {
  return reducer(state, action, ROUND_TEST);
}
