import styled from "@emotion/styled";
import { neutralDay, scc } from "@teamsparta/design-system";

export const ControllerBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 58px;
  background-color: ${neutralDay.gray100};
  opacity: ${({ isHover, isPlaying }) => (isHover || !isPlaying ? "0.7" : "0")};
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 20px;
`;

export const ControllerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const PlayAndTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
`;
export const SeekButtonWrapper = styled.img`
  width: 42px;
  height: 42px;
  cursor: pointer;
`;
export const PlayButtonWrapper = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const CurrentTimeWrapper = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #fff;
`;

export const SlashWrapper = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #fff;
`;

export const TotalTimeWrapper = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #fff;
`;

export const OtherControllerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
`;

export const MuteControllerWrapper = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;
export const VolumeControllerWrapper = styled.input`
  -webkit-appearance: none;
  width: 90px;
  height: 5px;
  border-radius: 5px;
  background: ${neutralDay.white};
  outline: none;
  opacity: 1;
  background: linear-gradient(
    to right,
    ${neutralDay.white} 0%,
    ${neutralDay.white} var(--volume),
    ${neutralDay.gray70} var(--volume),
    ${neutralDay.gray70} 100%
  );
  cursor: pointer;
  pointer-events: auto;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${neutralDay.gray70};
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
`;

export const ProgressBarWrapper = styled.input`
  position: absolute;
  -webkit-appearance: none;
  top: 0;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    ${scc.red100} 0%,
    ${scc.red100} var(--progress),
    #d8d8d8 var(--progress),
    #d8d8d8 100%
  );
  outline: none;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${scc.red100};
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  &:hover::-webkit-slider-thumb {
    opacity: 1;
  }
`;

const ControllerWrapper = styled.div`
  position: relative;
  span {
    display: none;
    position: absolute;
    top: -50px;
    right: -8px;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    opacity: 0.8;
    width: auto;
    text-align: center;
    white-space: nowrap;
  }
  &:hover span {
    display: block;
  }
`;
export const PlaybackSpeedControllerWrapper = styled(ControllerWrapper)`
  select {
    width: 40px;
    height: 20px;
    border-radius: 5px;
    background-color: ${neutralDay.gray100};
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 0;
    pointer-events: auto;
    text-align: center;
  }
`;
export const PlayQualityControllerWrapper = styled(ControllerWrapper)`
  select {
    width: 40px;
    height: 20px;
    border-radius: 5px;
    background-color: ${neutralDay.gray100};
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 0;
    pointer-events: auto;
    text-align: center;
  }
`;

export const PipControllerWrapper = styled(ControllerWrapper)`
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
export const CaptionControllerWrapper = styled(ControllerWrapper)`
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
export const FullscreenButtonWrapper = styled(ControllerWrapper)`
  position: relative;
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
