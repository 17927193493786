import * as S from "./recommend.style";
import React from "react";
import { recommendTitle } from "./recommend.model";
import { logOnClickMore } from "../../../hooks/finish/recommend";
import { useSelector } from "react-redux";
import Contents from "./Contents";
const FreeFinishPageRecommend = ({nextStep}) => {
  const enrolled = useSelector((state) => state.enrolled);

  return (
    nextStep && (
      <S.Container>
        <S.Title>{recommendTitle[nextStep]}</S.Title>
        <S.Contents>{Contents[nextStep]}</S.Contents>
        <S.MoreContainer>
          마음에 드는 강의가 없으신가요?
          <S.MoreButton
            onClick={() => {
              window.open(`${process.env.REACT_APP_SCC_URL}/catalog`);
              logOnClickMore(enrolled, nextStep);
            }}
          >
            더 많은 강의 보러 가기
          </S.MoreButton>
        </S.MoreContainer>
      </S.Container>
    )
  );
};

export default FreeFinishPageRecommend;
