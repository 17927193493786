import * as S from "./ReportGNB.style";
import React from "react";
import { history } from "../../../../_helpers";
import { useOnlineTheme } from "../../../queries/pirates";
import { PIRATES } from "../../../../_consts";

export const ReportGNB = () => {
  const handleGoBack = () => {
    history.goBack();
  };
  const theme = useOnlineTheme();
  const getColor = () => {
    switch (theme.data) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  };
  return (
    <S.GNBContainer theme={theme.data}>
      <S.GNBArrowIcon onClick={() => handleGoBack()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.2929 20.7071C14.6834 21.0976 15.3166 21.0976 15.7071 20.7071C16.0976 20.3166 16.0976 19.6834 15.7071 19.2929L14.2929 20.7071ZM7 12L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L7 12ZM15.7071 4.70711C16.0976 4.31658 16.0976 3.68342 15.7071 3.29289C15.3166 2.90237 14.6834 2.90237 14.2929 3.29289L15.7071 4.70711ZM15.7071 19.2929L7.70711 11.2929L6.29289 12.7071L14.2929 20.7071L15.7071 19.2929ZM7.70711 12.7071L15.7071 4.70711L14.2929 3.29289L6.29289 11.2929L7.70711 12.7071Z"
            fill={getColor()}
          />
        </svg>
      </S.GNBArrowIcon>
    </S.GNBContainer>
  );
};
