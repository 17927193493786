import styled from "@emotion/styled";
import {
  mBody1,
  neutralDay,
  mTitle1,
  mBody2,
  wBody1,
  wBody2,
  wTitle2,
} from "@teamsparta/design-system";
import { Device } from "../../../_styles/device";

export const ModalWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0px 20px 0px 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(20, 22, 23, 0.6);

  position: absolute;
  z-index: 1;
`;

export const ModalContainer = styled.div`
  display: flex;
  width: 320px;
  padding: 24px 20px 20px 20px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  border-radius: 16px;
  background: ${neutralDay.white};
  ${Device.Desktop} {
    width: 460px;
    padding: 32px 32px 28px 32px;
    box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  ${Device.Desktop} {
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Cta = styled.div`
  display: flex;
  height: 44px;
  padding: 11.5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: ${neutralDay.gray100};
  cursor: pointer;

  ${Device.Desktop} {
    align-self: auto;
    height: 48px;
    padding: 16px 20px;
  }
`;

export const CtaText = styled.p`
  ${mBody1};
  color: ${neutralDay.white};
  text-align: center;
  margin: 0;
  ${Device.Desktop} {
    ${wBody1};
    color: ${neutralDay.white};
  }
`;

export const Title = styled.p`
  ${mTitle1};
  color: ${neutralDay.gray100};
  text-align: center;
  margin: 0;
  ${Device.Desktop} {
    ${wTitle2};
    color: ${neutralDay.gray100};
  }
`;

export const Desc = styled.p`
  ${mBody2};
  color: ${neutralDay.gray80};
  text-align: center;
  margin: 0;

  ${Device.Desktop} {
    text-align: left;
    ${wBody2};
    color: ${neutralDay.gray80};
  }
`;
