import privateInstance from "../../_helper/axios";

export async function getPhoneVerification() {
  return await privateInstance
    .get(`/phone_verification/`)
    .then((resp) => resp.data);
}

export const phoneVerificationService = {
  getPhoneVerification,
};
