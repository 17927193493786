import React from "react";
import * as S from "./completeCelebration.style";

const CompleteCelebration = ({ courseTitle, userName }) => {
  return (
    <S.Container>
      <S.CelebrateCardContainer>
        <S.CelebrateText>
          {userName}님,
          <br />
          강의 완주를 축하드려요!
        </S.CelebrateText>
        <S.CompleteLabel>
          <S.CompleteLabelTitle>완주한 강의</S.CompleteLabelTitle>
          <S.CompleteLabelContent>{courseTitle}</S.CompleteLabelContent>
        </S.CompleteLabel>
      </S.CelebrateCardContainer>
    </S.Container>
  );
};

export default CompleteCelebration;
