import { POINT } from "../constants";

const initState = {
  loaded: false,
};

export function point(state = initState, action) {
  switch (action.type) {
    case POINT.POINT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case POINT.POINT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case POINT.POINT_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
