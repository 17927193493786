import styled from "@emotion/styled";
import { wTitle2, neutralDay } from "@teamsparta/design-system";
import { device } from "../../../../../../_styles/device";
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  justify-content: space-between;
  margin-top: 23px;
`;
export const Title = styled.div`
  color: black;
  //max-width: 100%;
  width: 100%;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: ${wTitle2};
  @media ${device.desktop} {
    font-weight: 500;
  }
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.96px;
  text-align: left;
  margin: 0 0 16px 0.3px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Data = styled.div`
  width: 100%;
`;

export const Detail = styled.div`
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.84px;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const DetailWrapper = styled.div`
  display: none;
  @media ${device.desktop} {
    display: flex;
    justify-content: space-between;
    width: 360px;
  }
`;
export const Materials = styled.div`
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.96px;
  text-align: left;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 13.6px;
  padding: 10px 25px;
  border-radius: 100px;
  background-color: #f1f3f5;
  cursor: pointer;
  gap: 8px;
`;
export const ColleagueInfo = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: ${neutralDay.gray10};
  border-radius: 100px;
  width: 286px;
`;
