import {
  ThumbClicked,
  ThumbDefault,
  ThumbHover,
} from "../CurrentlyEnrolled.svg";
import * as S from "./LikeButton.style";
import { useState, useRef, useEffect } from "react";

export const LikeButton = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  isLikeClicked,
  isHover,
}) => {
  const [likes, setLikes] = useState([]);
  const cleanLike = useRef((id) => {
    setLikes((currentLikes) => currentLikes.filter((like) => like !== id));
  });

  return (
    <S.ThumbIconWrapper
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => {
        onClick();
        setLikes((prevLikes) => [...prevLikes, new Date().getTime()]);
      }}
    >
      {isLikeClicked ? (
        <ThumbClicked />
      ) : isHover ? (
        <ThumbHover />
      ) : (
        <ThumbDefault />
      )}
      {likes.map((id) => (
        <Bubble onAnimationEnd={cleanLike.current} key={id} id={id} />
      ))}
    </S.ThumbIconWrapper>
  );
};

function random(min, max) {
  return Math.random() * (max - min) + min;
}

const opacityDuration = 1;

const emojis = ["👍"];

function Bubble({ id, onAnimationEnd }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(1);
  const size = useRef(random(0.7, 1.2));

  const element = useRef();
  const emoji = useRef(0);

  const initialOptions = useRef({
    animationDuration: random(1, 3),
    element,
    onAnimationEnd,
    id,
  });

  useEffect(() => {
    const { animationDuration, element, onAnimationEnd, id } =
      initialOptions.current;

    element.current.addEventListener("transitionend", (event) => {
      if (event.propertyName === "opacity") {
        onAnimationEnd(id);
      }
    });

    setTimeout(() => {
      setPosition((prevState) => ({
        ...prevState,
        x: random(-40, 40),
        y: random(-60, -100),
      }));
    }, 5);

    setTimeout(() => {
      setOpacity(0);
    }, (animationDuration - opacityDuration) * 1000);
  }, []);

  return (
    <div
      style={{
        top: 0,
        color: "red",
        fontSize: "2em",
        left: "50%",
        opacity,
        pointerEvents: "none",
        position: "absolute",
        transform: `translate(calc(-50% + ${position.x}px), calc(-100% + ${position.y}px)) scale(${size.current})`,
        textShadow: "0 0 5px rgba(0, 0, 0, .25)",
        transition: `transform ${initialOptions.current.animationDuration}s linear, opacity ${opacityDuration}s ease-in-out`,
      }}
      ref={element}
    >
      {emojis[emoji.current]}
    </div>
  );
}
