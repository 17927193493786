import { useAtom } from "jotai";
import { isLectureListOpen } from "../../stores/homeworkPageV3Atom";
import React, { useEffect, useState } from "react";
import { useHandlePlayerCss } from "../LecturePageV3/useInitialize";
import { EvaluationGnb } from "../../components/organisms/Evalutaion/EvaluationGnb/EvaluationGnb";
import * as S from "../EvalutaionResultPage/evaluationResultPage.style";
import * as E from "./evaluationPage.style";
import { EvaluationDetails } from "../../components/organisms/Evalutaion/EvaluationDetails/EvaluationDetails";
import { EvaluationIntro } from "../../components/organisms/Evalutaion/EvaluationIntro/EvaluationIntro";
import { useParams } from "react-router-dom";
import { useMyEvaluations } from "../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { history } from "../../../_helpers";
import { useIsMobile } from "../../hooks/device";
import { SideBar } from "../LecturePageV3/SideBar/SideBar";
import { useGetEvaluationResultData } from "../../hooks/evaluation/useEvaluationData";
import { isLoadingEvaluationPageAtom } from "../../stores/enrolledEvaluationDetails";

export const EvalutaionPage = () => {
  const isMobile = useIsMobile();
  const [isOpenList] = useAtom(isLectureListOpen);
  const [, setOpenBuffer] = useState(false);
  const { enrolled_evaluation_id } = useParams();
  const { data: evaluations } = useMyEvaluations(enrolled_evaluation_id);
  const { done, isRetryable } = useGetEvaluationResultData(evaluations);
  const [isLoading, setIsLoading] = useAtom(isLoadingEvaluationPageAtom);

  useEffect(() => {
    if (done && !isRetryable) history.push(`${enrolled_evaluation_id}/results`);
  }, [evaluations]);

  useHandlePlayerCss(setOpenBuffer);

  return (
    <>
      <EvaluationGnb isOpenList={isOpenList} setIsLoading={setIsLoading} />
      <S.EvaluationResultPageContainer isOpenList={isOpenList}>
        <S.HomeworkLeftSide isOpenList={isOpenList} isMobile={isMobile}>
          <EvaluationIntro />
          <EvaluationDetails />
        </S.HomeworkLeftSide>
        {isLoading && (
          <E.LoadingContainer>
            <E.LoadingRtanContainer>
              <E.LoadingRtan
                src={"/assets/images/codingrace/running_rtanny.gif"}
                style={{ transform: "scaleX(-1)" }}
              />
              <E.LoadingTextContainer
                src={"/images/evaluation/is_evaluating.png"}
                key={"is_evaluating"}
              />
            </E.LoadingRtanContainer>
          </E.LoadingContainer>
        )}
        <SideBar />
      </S.EvaluationResultPageContainer>
    </>
  );
};
