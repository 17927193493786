import { useSelector } from "react-redux";
import { getCompleteRecommendTrackData } from "../../pages/CompleteRecommendPage/getCompleteRecommendData";

export const useRecommendTrack = (courseId) => {
  const { user } = useSelector((state) => state.authentication);

  const trackData = getCompleteRecommendTrackData({
    courseId,
    birthyear: parseInt(user.birthyear),
  });

  return trackData;
};
