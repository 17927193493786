import { useQuery } from "react-query";
import privateInstanceV3 from "../../../_helper/axiosV3";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const getAccessCheck = async (enrolledId, contentType, contentId) => {
  return await privateInstanceV3.get(
    `/progress-management/enrollments/${enrolledId}/contents/${contentType}/${contentId}/access-check`
  );
};

export const useAccessCheck = (targetContent, componentValue) => {
  const { enrolled_id } = useParams();
  const contentType = targetContent?.contentType;
  const contentId = targetContent?.contentId;

  // 'enabled'를 'false'로 설정하여 자동 실행을 방지합니다.
  return useQuery(
    ["accessCheck", contentId, componentValue],
    () => getAccessCheck(enrolled_id, contentType, contentId),
    {
      enabled: false, // 자동 실행 비활성화
      onSuccess: (data) => {
        console.log("onSuccess", data);
      },
      onError: (error) => {
        console.log("onError", error);
      },
      retry: (failureCount, error) => {
        return error?.response?.status !== 403;
      },
    }
  );
};

export const useAccessCheckOnTrigger = (
  targetContent,
  setIsSkip,
  componentValue
) => {
  const accessCheckQuery = useAccessCheck(targetContent, componentValue);
  const fetchAccessCheck = () => {
    if (targetContent && targetContent.requiresAccessCheck) {
      accessCheckQuery.refetch();
    } else {
      setIsSkip(true);
    }
  };
  return { ...accessCheckQuery, fetchAccessCheck };
};
