import { WIL } from "../constants";
import { wilService } from "../services/wil.service";

function getWIL(enrolleds_id, week) {
  return (dispatch) => {
    return wilService.getWIL(enrolleds_id, week);
  };
}

export const wilActions = {
  getWIL,
};
