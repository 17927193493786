import React from "react";
import classNames from "classnames/bind";
import styles from "./ColleagueInfo.module.scss";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function ColleagueInfo({ specific_content, colleague_info, course }) {
  const { name, im_done, never_done, num } = colleague_info;
  const nums = getNumList(num);
  const theme = useOnlineTheme();

  return (
    <div className={cx("colleague-info", theme.data)}>
      {specific_content && (
        <>
          <div className={cx("colleague-info__detail", theme.data)}>
            {specific_content}
          </div>
        </>
      )}
      {!specific_content && (
        <>
          {num > 0 && !course?.is_trial &&(
            <>
              <div className={cx("colleague-info__detail", theme.data)}>
                {name} 님 외<br />
                <span className={cx("colleague-info__detail__num", theme.data)}>
                  {num - 1}
                </span>
                명이 듣고 있어요. 우와!👏
              </div>
            </>
          )}
          {num > 0 && course.is_trial &&(
            <>
              <div className={cx("colleague-info__detail", theme.data)}>
                {name} 님 외<br />
                <span className={cx("colleague-info__detail__num", theme.data)}>
                  {num - 1}
                </span>
                명이 수강을 시작했어요!
              </div>
            </>
          )}
          {!num && never_done && (
            <>
              <div className={cx("colleague-info__detail", theme.data)}>
                아직 아무도 없어요.
                <br />
                가장 먼저 들으러 가볼까요? 😎
              </div>
            </>
          )}
          {!num && !never_done && im_done && (
            <>
              <div className={cx("colleague-info__detail", theme.data)}>
                와, 대단해요!
                <br />
                이미 다 들었어요 👏
              </div>
            </>
          )}
          {!num && !never_done && !im_done && (
            <>
              <div className={cx("colleague-info__detail", theme.data)}>
                벌써 다 들은 사람이 있네요?
                <br />
                우리도 얼른 달려가볼까요? 👩‍💻
              </div>
            </>
          )}
          {num > 0 && (
            <div className={cx("colleague-info__profiles", theme.data)}>
              {nums.map((num, idx) => {
                return (
                  <img
                    key={idx}
                    src={`/assets/images/profile/rtan_thumb_${getRandomIndex(
                      30
                    )}.png`}
                    alt=""
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function getNumList(num) {
  let ret = [];
  for (let i = 0; i < 4 && i < num; i++) {
    ret.push(i);
  }
  return ret;
}

function getRandomIndex(tot) {
  let idx = parseInt(Math.random() * tot + 1);
  return idx < 10 ? `0${idx}` : idx;
}

export { ColleagueInfo };
