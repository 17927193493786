import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Castle.module.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

function Castle({ total_weeks, WeeksAfterStart }) {
  const [imageIndex, setImageIndex] = useState(0);
  const three_week_courses = [1, 3, 5];
  const four_week_courses = [1, 3, 4, 5];
  const five_week_courses = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (WeeksAfterStart.loaded) {
      if (imageIndex < WeeksAfterStart.data.context - 1) {
        setTimeout(() => setImageIndex(imageIndex + 1), 1000);
      }
    }
  });

  return (
    <>
      {WeeksAfterStart.loaded && (
        <div className={cx("CodingRaceCastleContainer")}>
          {total_weeks === 3 && (
            <img
              className={cx("CodingRaceCastle castle-hidden")}
              src={`/assets/images/codingrace/castle-w-${three_week_courses[imageIndex]}.png`}
              alt="castle"
            />
          )}
          {total_weeks === 4 && (
            <img
              className={cx("CodingRaceCastle castle-hidden")}
              src={`/assets/images/codingrace/castle-w-${four_week_courses[imageIndex]}.png`}
              alt="castle"
            />
          )}
          {total_weeks === 5 && (
            <img
              className={cx("CodingRaceCastle castle-hidden")}
              src={`/assets/images/codingrace/castle-w-${five_week_courses[imageIndex]}.png`}
              alt="castle"
            />
          )}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { WeeksAfterStart } = state;
  return { WeeksAfterStart };
}

const connectedCastle = connect(mapStateToProps, {})(Castle);
export { connectedCastle as Castle };
