import React from "react";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import PropTypes from "prop-types";
import { createEventId, history } from "../../../_helpers";
import { sendLog } from "../../../v2/businesslogics/logging";

const cx = classNames.bind(styles);

function NudgeBannerMyPage({ subTitle, style }) {
  const _web = async () => {
    const eventID = createEventId();
    await sendLog("mypage_to_web", {}, eventID);
    window.location = "https://spartacodingclub.kr/catalog";
  };

  const _payment = async () => {
    const eventID = createEventId();
    await sendLog("mypage_to_payment", {}, eventID);
    history.push("/payment/5f0ae408765dae0006002816");
  };

  return (
    <div style={style}>
      <div className={cx("row")} style={{ marginTop: "0" }}>
        <div
          className={cx("cta")}
          onClick={() => {
            _web();
          }}
        >
          스파르타 추천 강의 보러가기
        </div>
      </div>
      <div className={cx("row")} style={{ marginTop: "16px" }}>
        <div
          className={cx("subCta")}
          onClick={() => {
            _payment();
          }}
        >
          {subTitle}
        </div>
      </div>
    </div>
  );
}

NudgeBannerMyPage.propTypes = {
  subTitle: PropTypes.string,
  style: PropTypes.object,
};

export { NudgeBannerMyPage };
