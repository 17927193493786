import styled from "@emotion/styled";
import { DisplayResolution } from "../../../../../_styles/device";
import { mBody2, neutralDay } from "@teamsparta/design-system";

export const HomeworkMarkdownRoot = styled.div`
  & strong {
    font-weight: 600;
  }
  & button {
    border: none;
    background-color: transparent;
    cursor: none;
    outline: none;
    padding: 0;
    margin: 0;
  }
  & h3 {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #5f666b;
    margin-bottom: 6px;
    ${DisplayResolution.MobileAndMiniTablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }
  & a {
    color: #146aff;
    text-decoration: underline;
    text-decoration-color: #146aff;
    font-family: Pretendard;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    ${DisplayResolution.MobileAndMiniTablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }
  & img {
    width: 600px;
    margin: 0 auto;
    ${DisplayResolution.MobileAndMiniTablet} {
      width: 100%;
    }
  }
  & pre {
    display: flex;
    padding: 16px 20px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #d7e0e6;
    background-color: #ffffff;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 6px; /* 스크롤바의 너비 설정 */
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey; /* 스크롤바 색상 설정 */
      border-radius: 10px; /* 스크롤바 모서리를 둥글게 */
    }
  }
  & pre code {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #81898f;
    background-color: #ffffff;
  }
  & code {
    padding: 2px 6px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    background-color: #eef3f6;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #ff6c7a;
    ${DisplayResolution.MobileAndMiniTablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }
  & ol {
    list-style: none; /* 기본 스타일을 없애고 */
    counter-reset: ol-counter; /* 카운터를 리셋합니다 */
    padding-left: 0; /* 왼쪽 패딩을 제거합니다 */
  }
  & ol ol {
    padding-left: 20px; /* 왼쪽 패딩을 추가합니다 */
  }

  & ol li {
    counter-increment: ol-counter; /* 각 li 요소마다 카운터를 증가시킵니다 */
    margin-bottom: 10px; /* 원하는대로 li 요소의 마진을 설정합니다 */
    font-family: Pretendard;
    font-size: 16px;
    line-height: 26px;
    color: #5f666b;
    ${DisplayResolution.MobileAndMiniTablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }

  & ol li::before {
    content: counter(ol-counter) ". "; /* 카운터 값과 점을 표시합니다 */
    color: #5f666b;
  }
  & ul {
    list-style-type: none; /* 기본 불릿 스타일을 없앱니다 */
    padding-left: 0; /* 왼쪽 패딩을 제거합니다 */
  }
  & ol ul {
    padding-left: 20px; /* 왼쪽 패딩을 추가합니다 */
    margin-top: 4px;
  }
  & ul ul {
    padding-left: 0;
  }

  & ul li {
    margin-bottom: 6px; /* 원하는대로 li 요소의 마진을 설정합니다 */
    position: relative; /* 가상 요소의 위치 기준점을 설정합니다 */
    padding-left: 20px; /* 텍스트와 불릿 사이의 공간을 조정합니다 */
    counter-increment: none;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #5f666b;
    ${DisplayResolution.MobileAndMiniTablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }

  & ul li::before {
    content: "•"; /* 사용자 정의 불릿 문자 */
    color: #5f666b;
    font-size: 26px; /* 불릿 크기를 조정합니다 */
    position: absolute; /* 가상 요소를 절대 위치로 설정합니다 */
    left: 0; /* 불릿을 li 요소의 왼쪽 끝에 위치시킵니다 */
    top: 10px;
    transform: translateY(-50%); /* 불릿을 정확한 중앙으로 조정합니다 */
  }
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #5f666b;
  & p {
    margin-bottom: 4px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #5f666b;
  }
  position: relative;
  width: 722px;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const HomeworkMarkdownWrapper = styled.div`
  width: 722px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #e4ebf0;
  padding: 20px;
  padding-bottom: ${({ isExpanded }) => (isExpanded ? "40px" : "0")};
  max-height: ${({ isExpanded }) => (isExpanded ? "none" : "308px")};
  overflow: hidden;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 16px 20px;
    padding-bottom: ${({ isExpanded }) => (isExpanded ? "40px" : "0")};
    max-height: ${({ isExpanded }) => (isExpanded ? "none" : "261px")};
  }
`;

export const HomeworkMarkdownHeader = styled.div`
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 0 8px;
  background-color: #ffffff;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: #9da7ae;
  z-index: 1;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: #9da7ae;
    line-height: 22px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  min-height: 40px;
`;

export const DownloadButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: 60px;
  padding: 9.5px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #141617;
`;

export const ButtonText = styled.div`
  display: flex;
  padding: 0 4px;
  justify-content: center;
  align-self: center;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
`;

export const ShowMoreButton = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: 100%;
  height: 40px;
`;

export const ShowMoreText = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #146aff;
  text-align: center;
  ${DisplayResolution.MobileAndMiniTablet} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const DownloadButtonMobile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;

export const DownloadButtonTextMobile = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  color: #81898f;
`;

export const HintMarkdownRoot = styled(HomeworkMarkdownRoot)`
  width: 100%;
  & body {
    background-color: ${neutralDay.gray5};
  }
  & img {
    width: 396px;
    ${DisplayResolution.MobileAndMiniTablet} {
      width: calc(100%);
    }
  }
`;
