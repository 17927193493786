import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./RaceRankContainer.modules.scss";
import { connect } from "react-redux";
import { WeeklyBreakPoint } from "./WeeklyBreakPoint/WeeklyBreakPoint";
import { RtannyCharacter } from "./RtannyCharacter/RtannyCharacter";
import { JindosaurusCharacter } from "./JindosaurusCharacter/JindosaurusCharacter";
import { SpaCharacter } from "./SpaCharacter/SpaCharacter";
import { maskName } from "../../../../_helper/utils";

const cx = classNames.bind(styles);

function RaceRankContainer({ total_weeks, UserRaceGroupInfo, divWidth }) {
  const week_gap_variation = 12.5 + 3.75 * (5 - total_weeks);
  const weekly_width = Math.round(divWidth / total_weeks) - week_gap_variation;

  const setWeeklyBreakpoints = () => {
    let content = [];
    for (let i = 1; i < total_weeks + 1; i++) {
      content.push(<WeeklyBreakPoint week={i} />);
    }
    content.push(<WeeklyBreakPoint final />);
    return content;
  };

  const setCharacters = (GroupData) => {
    let characters_contents = [];
    const {
      is_finished,
      finished_week_count,
      week_done_rate,
      course_title,
      user_name,
      at_week,
      week_done,
    } = GroupData[0];
    const myProgress = {
      is_finished: is_finished,
      finished_week_count: finished_week_count,
      week_done_rate: week_done_rate,
      course_title: course_title,
      user_name: user_name,
      week_done: week_done,
      at_week: at_week,
    };
    characters_contents.push(
      <RtannyCharacter
        progress={myProgress}
        weekly_width={weekly_width}
        weeks={total_weeks}
        divWidth={divWidth}
      />
    );

    characters_contents.push(
      <JindosaurusCharacter
        weekly_width={weekly_width}
        weeks={total_weeks}
        divWidth={divWidth}
      />
    );

    GroupData.map((groupmate_progress, index) => {
      if (index === 0) {
        return;
      }
      const y_offset = 30 * index;
      const {
        is_finished,
        finished_week_count,
        week_done_rate,
        week_done,
        course_title,
        user_name,
        at_week,
      } = groupmate_progress;
      const progress = {
        is_finished: is_finished,
        finished_week_count: finished_week_count,
        week_done_rate: week_done_rate,
        course_title: course_title,
        user_name: maskName(user_name),
        week_done: week_done,
        at_week: at_week,
      };
      characters_contents.push(
        <SpaCharacter
          progress={progress}
          weekly_width={weekly_width}
          weeks={total_weeks}
          divWidth={divWidth}
          y_offset={y_offset}
        />
      );
    });
    return characters_contents;
  };

  return (
    <>
      <div className={cx("CodingRaceLayout")}>
        {setWeeklyBreakpoints(total_weeks)}
      </div>
      {UserRaceGroupInfo.loaded && UserRaceGroupInfo.data.context && (
        <>
          <div className={cx("CharacterContainer")}>
            {setCharacters(UserRaceGroupInfo.data.context)}
          </div>
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { UserRaceGroupInfo } = state;
  return { UserRaceGroupInfo };
}

const connectedRaceRankContainer = connect(
  mapStateToProps,
  {}
)(RaceRankContainer);
export { connectedRaceRankContainer as RaceRankContainer };
