import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./PhoneVerificationDone.module.scss";
import { params } from "../../../_helper";
import { phoneVerificationActions } from "../../../_state/actions";

const cx = classNames.bind(styles);

function PhoneVerificationDone({ getPhoneVerification }) {
  useEffect(() => {
    getPhoneVerification();
    const { result, return_msg } = params();
    const ok = result === "success";
    const data = { ok };
    if (!ok) {
      alert(decodeURIComponent(return_msg));
    }
    window.opener.postMessage(JSON.stringify(data), "*");
    window.close();
  }, []);
  return (
    <>
      <h2>인증이 완료 되었습니다.</h2>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedPhoneVerificationDone = connect(mapStateToProps, {
  getPhoneVerification: phoneVerificationActions.getPhoneVerification,
})(PhoneVerificationDone);
export { connectedPhoneVerificationDone as PhoneVerificationDone };
