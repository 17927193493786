import React, {Component} from 'react';
import classNames from 'classnames/bind';
import styles from './LecturePage.module.scss';
import {CodeSnippet} from "../../_components/CodeSnippet";
import {LectureNav} from "../../_components/LectureNav";
import {Lecture} from '../../_components/Lecture';

const cx = classNames.bind(styles);

class LecturePage extends Component {

    state = {
        nav_selected: false,
    };

    handleNavStatus = (nav_selected) => {
        this.setState({
            nav_selected,
        })
    };

    render() {
        const {nav_selected} = this.state;
        const {round_id, lecture_id} = this.props.match.params;
        let data = {round_id, lecture_id};
        const {checkPlatform} = window;
        const platform = checkPlatform();
        return (
            <div className={cx('lecturePageContainer')}>
                <div className={cx('lectureWrapper')}>
                    <div className={cx('lectureNavWrapper')}>
                        <div className={cx('lectureNavContainer')}>
                            <LectureNav
                                data={data}
                                handleNavStatus={this.handleNavStatus}
                            />
                        </div>
                    </div>
                    <div className={cx('contentContainer')}>
                        <div className={cx('videoContainer')}>
                            <Lecture
                                data={data}
                                nav_selected={nav_selected}
                                handleNavStatus={this.handleNavStatus}
                            />
                        </div>
                        {
                            platform !== 'mobile' &&
                            <div className={cx('snippetContainer')}>
                                <CodeSnippet data={data}/>
                            </div>
                        }
                        {
                            platform === 'mobile' &&
                            <span className={cx('snippetOnlyPc')}>*코드스니펫은 PC에서만 제공됩니다!</span>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default LecturePage;