import * as S from "./SideBar.style";
import { useLectureSummary } from "../../../hooks/sideBar/useSummary";
import { SideBarTitleTab } from "../../../components/molecules/SideBar/SideBarTitleTab";
import { SideBarContent } from "../../../components/molecules/SideBar/SideBarContent";
import { useSelectedTab } from "../../../hooks/sideBar/useSelectedTab";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { useHandlePlayerCss } from "../useInitialize";
import { SideBarOpenButton } from "../../../components/molecules/HomeworkV3/SideBarOpenButton/SideBarOpenButton";
import { SideBarTabs } from "../../../models/SideBar/SideBarCondition";
import { isLectureListOpen } from "../../../stores/homeworkPageV3Atom";
import CampaignBanner from "../CampaignBanner/CampaignBanner";

export const SideBar = ({ courseId = "", lectureId = null }) => {
  const summary = useLectureSummary(lectureId);

  const [isOpenList] = useAtom(isLectureListOpen);
  const [selectedTab, setSelectedTab] = useSelectedTab(SideBarTabs[0].name);
  const [isOpenBuffer, setIsOpenBuffer] = useState(false);
  useHandlePlayerCss(setIsOpenBuffer);

  return (
    <S.SideBarWrapper isOpenList={isOpenList}>
      {!isOpenBuffer && <SideBarOpenButton />}
      <SideBarTitleTab
        courseId={courseId}
        summary={summary}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <SideBarContent
        courseId={courseId}
        lectureId={lectureId}
        summary={summary}
        selectedTab={selectedTab}
      />
      {selectedTab === "강의 목차" && <CampaignBanner courseId={courseId} />}
    </S.SideBarWrapper>
  );
};
