import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mTitle2,
  neutralDay,
  scc,
  wBody1,
  wBody2,
  wTitle4,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const HomeworkQuestionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px 12px 0 0;
  border-top: 1px solid ${neutralDay.gray30};
  border-right: 1px solid ${neutralDay.gray30};
  border-left: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 20px 16px;
    gap: 16px;
  }
`;

export const QuestionTag = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${scc.red15};
  ${wBody1};
  color: ${scc.red100};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${scc.red100};
  }
`;

export const QuestionTitle = styled.div`
  ${wTitle4};
  color: ${neutralDay.gray95};
  align-self: stretch;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mTitle2};
    color: ${neutralDay.gray95};
  }
`;

export const QuestionImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const QuestionImage = styled.img`
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  object-fit: contain;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
  }
`;

export const QuestionDescription = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
`;

export const QuestionDescriptionText = styled.h2`
  ${wBody2};
  flex: 1 0 0;
  color: var(--Neutral-Day-Gray80, #5f666b);
`;

export const InstructionAndAnswerWrapper = styled.div`
  display: ${({ noDescription }) => (noDescription ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const InstructionContainer = styled.div`
  position: relative;
  padding: 20px;
  margin-top: 10px;
  background: ${neutralDay.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
`;

export const InstructionTitleBox = styled.div`
  background: ${neutralDay.white};
  ${wBody1};
  color: ${neutralDay.gray60};
  padding: 0 10px;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: ${neutralDay.gray60};
  }
`;

export const QuestionContainer = styled.div`
  ${wBody2};
  color: ${neutralDay.gray80};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  height: 100%;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: ${neutralDay.gray80};
  }
`;
