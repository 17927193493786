import React, { useRef, useState } from "react";
import * as S from "./PlayerControlBarDesktop.style";
import { formatTime } from "../../hooks/utils";
import { usePlayerCoreControl } from "../../hooks/usePlayerCoreControl";
import { useVolumeControl } from "../../hooks/useVolumeControl";
import { usePlaybackTimeControl } from "../../hooks/usePlaybackTimeControl";
import { usePlaybackSpeedControl } from "../../hooks/usePlaybackSpeedControl";
import { useCSSVariable } from "../../hooks/useCSSVariable";
import { useIsControlBarActive, useIsPlaying } from "../../stores/playerAtom";
import { useQualityControl } from "../../hooks/useQualityControl";
import { useScreenControl } from "../../hooks/useScreenControl";
import { CurrentTimeTooltip } from "../CurrentTimeToolTip/CurrentTimeToolTip";

export const PlayerControlBarDesktop = React.memo(
  ({ videoRef, lecture_v2, playerType }) => {
    const [isControlBarActive] = useIsControlBarActive(false);
    let is_waiting = false;
    let is_refreshed = false;
    const { playButtonSrc, togglePlay } = usePlayerCoreControl({
      videoRef,
      lecture_v2,
      playerType,
      is_waiting,
      is_refreshed,
    });
    const {
      volume,
      volumeInputRef,
      muteButtonSrc,
      onVolumeChange,
      toggleMute,
    } = useVolumeControl(videoRef);
    const [isPlaying] = useIsPlaying(false);
    const { playbackTime, duration, handleScrub, moveBackward, moveForward } =
      usePlaybackTimeControl(videoRef);
    const { quality, qualityOptions, onQualityChange } = useQualityControl(
      videoRef,
      playerType
    );

    const { playbackSpeed, speedOptions, onSpeedChange } =
      usePlaybackSpeedControl(videoRef);
    const {
      fullScreenButtonSrc,
      fullScreenButtonTooltipText,
      captionButtonSrc,
      captionButtonTooltipText,
      pipButtonSrc,
      pipButtonTooltipText,
      isCaptionOn,
      isPipMode,
      onFullscreenChange,
      onPipChange,
      onCaptionChange,
    } = useScreenControl(videoRef);

    //head에 스타일이 실시간으로 쌓이는 문제 해결을 위한 코드
    useCSSVariable("--progress", `${(playbackTime / duration) * 100}%`);
    useCSSVariable("--volume", `${volume * 100}%`);

    const [currentTime, setCurrentTime] = useState(0);
    const progressBarRef = useRef(null);
    const [isProgressBarHovered, setIsProgressBarHovered] = useState(false);
    const handleProgressBarHover = (e) => {
      const progressBar = progressBarRef.current;
      const rect = progressBar.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = x / progressBar.clientWidth;
      const timeInSeconds = percentage * duration;
      setCurrentTime(timeInSeconds);
      setIsProgressBarHovered(true);
    };
    return (
      <S.ControllerBox
        isPlaying={isPlaying}
        isHover={isControlBarActive}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        {currentTime !== null && isProgressBarHovered && (
          <CurrentTimeTooltip
            currentTime={currentTime}
            duration={duration}
            progressBarRef={progressBarRef}
          />
        )}
        <S.ProgressBarWrapper
          ref={progressBarRef}
          className="progress-bar"
          type="range"
          min={0}
          max={1}
          step="any"
          value={playbackTime / duration}
          onChange={handleScrub}
          onMouseMove={handleProgressBarHover}
          onMouseLeave={() => setIsProgressBarHovered(false)}
        />
        <S.ControllerContainer>
          <S.PlayAndTimeWrapper>
            <S.SeekButtonWrapper
              alt={"10초 앞으로가기"}
              src={"/assets/seekbackbtn.svg"}
              onClick={() => {
                moveBackward();
              }}
            />
            <S.PlayButtonWrapper
              src={playButtonSrc}
              onMouseDown={togglePlay}
              alt={"재생/일시정지 버튼"}
            />
            <S.SeekButtonWrapper
              alt={"10초 후 버튼"}
              src={"/assets/seekforwardbtn.svg"}
              onClick={() => {
                moveForward();
              }}
            />
            <S.CurrentTimeWrapper>
              {formatTime(playbackTime)}
            </S.CurrentTimeWrapper>
            <S.SlashWrapper>/</S.SlashWrapper>
            <S.TotalTimeWrapper>{formatTime(duration)}</S.TotalTimeWrapper>
          </S.PlayAndTimeWrapper>
          <S.OtherControllerWrapper>
            <S.MuteControllerWrapper
              alt={"음소거 버튼"}
              src={muteButtonSrc}
              onClick={toggleMute}
            />
            <S.VolumeControllerWrapper
              type={"range"}
              minLength={0}
              maxLength={1}
              value={volume}
              onChange={(e) => {
                onVolumeChange(e);
              }}
              ref={volumeInputRef}
            />
            <S.PlaybackSpeedControllerWrapper>
              <select
                className={"playback-speed-controller"}
                value={playbackSpeed}
                onChange={onSpeedChange}
                disabled={false}
              >
                {speedOptions.map((rate) => (
                  <option key={rate} value={rate}>
                    {rate}x
                  </option>
                ))}
              </select>
              <span>재생 속도</span>
            </S.PlaybackSpeedControllerWrapper>
            <S.PlayQualityControllerWrapper>
              <select
                className={"play-quality-controller"}
                value={quality}
                onChange={onQualityChange}
                disabled={false}
              >
                <option value="Auto">Auto</option>
                {qualityOptions.map((rate) => (
                  <option key={rate} value={rate}>
                    {rate}p
                  </option>
                ))}
              </select>
              <span>화질 설정</span>
            </S.PlayQualityControllerWrapper>
            <S.CaptionControllerWrapper>
              <img
                alt={"자막 버튼"}
                src={captionButtonSrc}
                onClick={() => {
                  onCaptionChange(isCaptionOn);
                }}
              />
              <span>{captionButtonTooltipText}</span>
            </S.CaptionControllerWrapper>
            <S.PipControllerWrapper>
              <img
                alt={"pip 버튼"}
                src={pipButtonSrc}
                onClick={() => {
                  onPipChange(isPipMode);
                }}
              />
              <span>{pipButtonTooltipText}</span>
            </S.PipControllerWrapper>
            <S.FullscreenButtonWrapper>
              <img
                alt={"전체화면 버튼"}
                src={fullScreenButtonSrc}
                onClick={() => {
                  onFullscreenChange();
                }}
              />
              <span>{fullScreenButtonTooltipText}</span>
            </S.FullscreenButtonWrapper>
          </S.OtherControllerWrapper>
        </S.ControllerContainer>
      </S.ControllerBox>
    );
  }
);
