import React, { Component, Fragment } from "react";
import styles from "./Dashboard.module.scss";
import classNames from "classnames/bind";
import { config } from "../../../config";
import { connect } from "react-redux";
import { Button, Skeleton, Tooltip } from "antd";
import { dashboardActions, lectureActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { getApiDashboardInfoUrl, getGETAuthOptions } from "../../../_urls";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CustomModal_Ranking } from "../../CustomModal_Ranking";

const cx = classNames.bind(styles);
const weekdays = ["일", "월", "화", "수", "목", "금", "토", "일"];

function MyAchievement(props) {
  const _drawChart = () => {
    const { achv, rcmd, rankFunc, lecture } = props;
    return (
      <div className={cx("myAchiveContainer")}>
        <div className={cx("myAchieveTitle", "en")}>
          <span>Your Progress</span>
        </div>
        <div className={cx("myAchieveCircle")}>
          <CircularProgressbarWithChildren
            value={achv ? Math.round(achv * 100) / 100 : 0}
            // text={`${Math.round(achv * 100) / 100} %`}
            styles={buildStyles({
              pathColor: `rgba(19, 194, 194)`,
              trailColor: "#e9ecef",
            })}
            strokeWidth={5}
          >
            <p>
              <span className={cx("percent")}>
                {achv ? Math.round(achv * 10) / 10 : 0}
              </span>
              <span>%</span>
            </p>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  };
  return <div>{_drawChart()}</div>;
}

function MyTodoLecture(props) {
  const goLecturePage = () => {
    const round_id = props.round_id;
    const course_id = props.lecture.course_id;
    history.push(
      `/en/rounds/${round_id}/checkins/${props.lecture.week}/${props.lecture.week_order}`
    );
  };

  const _drawTodo = () => {
    if (props.lecture === undefined) {
      return <div />;
    }
    const { course_id, _id, title, week, week_order, end_date } = props.lecture;
    return (
      <div>
        <div className={cx("todoLectureWrapper")}>
          <span className={cx("weekInfo")}>
            Chap.{week}
            <span className={cx("lectureTitleInfo")}>
              {props.lecture.isHomework === false && week_order} {title}
            </span>
          </span>
          <Button
            onClick={goLecturePage}
            className={cx("lectureButton")}
            type="primary"
            danger
            size="large"
          >
            Resume
          </Button>
        </div>
      </div>
    );
  };

  return <div>{_drawTodo()}</div>;
}

const _numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class MyEtc extends Component {
  state = {
    editTextShow: false,
  };

  constructor(props) {
    super(props);
  }

  _drawEtcs = (data, title) => {
    return (
      <div className={cx("etcContainer")}>
        <div className={cx("etcImgContainer")}>
          {title === "Due" && (
            <img
              src={require("../../../assets/images/roadmap/ic_calendar.png")}
              alt=""
            />
          )}
          {title === "Points" && (
            <img
              src={require("../../../assets/images/roadmap/ic_coin.png")}
              alt=""
            />
          )}
        </div>
        <div className={cx("etcTextContainer")}>
          <span className={cx("etcTextContent")}>{data}</span>
          <span className={cx("etcTextTitle")}>{title}</span>
        </div>
      </div>
    );
  };

  _handleMouseOver = () => {
    this.setState({
      editTextShow: true,
    });
  };

  _handleMouseLeave = () => {
    this.setState({
      editTextShow: false,
    });
  };

  render() {
    const { editTextShow } = this.state;
    let weeks_remaining = Math.floor(this.props.days / 7);
    let day_remaining = this.props.days % 7;
    let data_days = `Today`;
    let title_days = "Due";

    let data_points = `${_numberWithCommas(parseInt(this.props.points))}`;
    let title_points = "Points";

    let { studyplans } = this.props;
    let data_studyplan = [];
    if (studyplans) {
      for (const plan of studyplans) {
        let day = weekdays[parseInt(plan.day)];
        data_studyplan.push(day + "요일");
      }
      data_studyplan = data_studyplan.join(", ");
    }
    let title_studyplan = "나와의 약속";
    if (isNaN(parseInt(this.props.points))) {
      return <Skeleton />;
    }
    return (
      <div className={cx("etcWrapper")}>
        <div className={cx("etcBlock")}>
          <div>{this._drawEtcs(data_days, title_days)}</div>
        </div>

        <div className={cx("etcBlock")}>
          <div>{this._drawEtcs(data_points, title_points)}</div>
        </div>
      </div>
    );
  }
}

const cheerups = [
  "오늘도 달려볼까요? 🔥",
  "코딩할 준비 되셨죠? 오늘도 완주까지 빠샤! 👊",
  "일단 여기 오신 것만으로도 잘하셨어요! 🥰",
  "딱 코딩하기 좋은 날씨에요! 🥳",
  "포기는 배추 셀 때나 쓰는 것! 포기란 없어요. 💪",
  "오늘도 유익한 수업이 기다리고 있어요! 😎",
  "오늘은 꿀잼지수 5개 각! 어서 들으러 가요 😆",
  "천리길도 Hello World 부터랍니다. 😇",
  "완주가 눈앞이에요. 나 Java봐라~ 🏃‍♀️",
  "오늘 하루는 어땠나요?",
  "이제 슬슬 재미가 붙나요?",
  "어디서 타는 냄새 안나요? 제 파이팅이 불타고 있어요!",
  "오늘이 딱 꿀잼 주차!",
  "오늘도 고고싱!",
];

class DashboardEn extends Component {
  state = {
    rankVisible: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { course_id } = this.props.data;
  }

  _getRandomCheerup = () => {
    let idx = Math.floor(Math.random() * cheerups.length);
    return cheerups[idx];
  };

  handleRankVisible = (isVisible) => {
    this.setState({
      rankVisible: isVisible,
    });
  };

  render() {
    const {
      achievement,
      lecture,
      points,
      recommended,
      remaining_days,
      studyplans,
      is_b,
      round_id,
      is_forever,
    } = this.props.dashboard.data;
    const { user } = this.props;
    let { rankVisible } = this.state;
    const { name_confirmed } = user;
    if (!this.props.dashboard.loaded) {
      return <Skeleton />;
    }
    return (
      <div className={cx("dashboardWrapper")}>
        <h2>Let's Code, {user.name.split(" ")[0]} !</h2>
        <div className={cx("dashboardContentWrapper")}>
          <div className={cx("myAchieveWrapper")}>
            <MyAchievement
              achv={achievement}
              rcmd={recommended}
              lecture={lecture}
              rankFunc={this.handleRankVisible}
            />
          </div>
          <div className={cx("myInfoWrapper")}>
            <MyTodoLecture lecture={lecture} round_id={round_id} />
            <MyEtc
              days={remaining_days}
              points={points}
              studyplans={studyplans}
              showPlan={this.props.showPlan}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, dashboard } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    dashboard,
  };
};
const connectedDashboardEn = connect(mapStateToProps, {
  getDashboardEn: dashboardActions.getDashboardEn,
})(DashboardEn);
export { connectedDashboardEn as DashboardEn };
