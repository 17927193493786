import { deepFreeze } from "../../../utils/deepFreeze";
import { Brand } from "./recommendTracks.model";

export interface Article {
  articleImgSrc: string;
  link: string;
  articleTitle: string;
  articleDescription: string;
  brand: Brand;
}

export const ARTICLE_DATA = deepFreeze<Record<string, Article>>({
  data: {
    articleImgSrc: "/assets/images/article/article_data_0.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EB%8D%B0%EC%9D%B4%ED%84%B0-%EA%B4%80%EB%A0%A8-%EC%A7%81%EB%AC%B4%EB%A5%BC-%EC%A4%80%EB%B9%84%ED%95%98%EB%8A%94-%EC%82%AC%EB%9E%8C%EB%93%A4%EC%9D%B4-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8-%EC%8B%A0%ED%95%9C%EC%B9%B4%EB%93%9C-%EB%8D%B0%EC%9D%B4%ED%84%B0-%EB%A7%88%EC%BC%80%ED%84%B0%EC%99%80%EC%9D%98-qa--4941",
    articleTitle:
      "데이터 관련 직무를 준비하는 사람들이 자주 묻는 질문[취업 준비 방법, 필요 역량, 자격증]",
    articleDescription:
      "최근 채용 공고에서 자주 보이는 '데이터 역량'. 비전공자들은 어떻게 데이터 분석 역량을 길러야 할까요?",
    brand: "nbcamp",
  },
  analyst: {
    articleImgSrc: "/assets/images/article/article_data_1.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EB%8D%B0%EC%9D%B4%ED%84%B0-%EB%B6%84%EC%84%9D%EA%B0%80-%EC%A0%84%EB%A7%9D%EC%9D%80--2789",
    articleTitle: "데이터 분석가 전망은? 취준생이라면 꼭 알아야 할 정보들",
    articleDescription:
      "데이터 분석 취업을 꿈꾸고 있나요? 데이터 분석가가 나에게 잘 맞는 직무인지 알아볼 수 있는 주요 정보만을 모았습니다.",
    brand: "nbcamp",
  },
  gidong: {
    articleImgSrc: "/assets/images/article/article_gidong.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EC%B7%A8%EC%97%85%EC%9D%98-%EC%B2%98%EC%9D%8C%EB%B6%80%ED%84%B0-%EB%81%9D%EA%B9%8C%EC%A7%80-%EB%82%B4%EC%9D%BC%EB%B0%B0%EC%9B%80%EC%BA%A0%ED%94%84%EC%97%90%EC%84%9C-%EB%8F%84%EC%99%80%EC%A3%BC%EC%85%A8%EC%8A%B5%EB%8B%88%EB%8B%A4-6193",
    articleTitle: '“취업할 때 진짜 많이 도와주시더라고요."',
    articleDescription:
      "한글과컴퓨터 자회사 한컴 프론티스의 프론트엔드 개발자로 근무하고 있는 이기동입니다.",
    brand: "nbcamp",
  },
  sangwu: {
    articleImgSrc: "/assets/images/article/article_sangwu.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%ED%98%84%EC%A7%81-9%EB%85%84%EC%B0%A8-pm%EC%9D%98-%EB%A9%98%ED%86%A0%EB%A7%81-%EB%8D%95%EB%B6%84%EC%97%90-pm-%EC%B7%A8%EC%97%85%EC%97%90-%EC%84%B1%EA%B3%B5%ED%95%A0-%EC%88%98-%EC%9E%88%EC%97%88%EC%96%B4%EC%9A%94--16993",
    articleTitle: "개발 부트캠프를 수료하고 PM 취업한 비결",
    articleDescription:
      "튜터님들이 상주하는 시스템이 마음에 들었어요. 아마 제가 1기 중 젭(ZEP)에 가장 오래 머무른 사람일 거예요.",
    brand: "nbcamp",
  },
  kakao: {
    articleImgSrc: "/assets/images/article/article_kakao.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EB%8F%85%ED%95%99%EC%9C%BC%EB%A1%9C%EB%8A%94-%EC%B1%84%EC%9A%B0%EC%A7%80-%EB%AA%BB%ED%96%88%EB%8D%98-%ED%8F%AC%ED%8A%B8%ED%8F%B4%EB%A6%AC%EC%98%A4%EC%9D%98-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EA%B2%BD%ED%97%98%EC%9D%84-%EB%82%B4%EC%9D%BC%EB%B0%B0%EC%9B%80%EC%BA%A0%ED%94%84%EC%97%90%EC%84%9C-%EC%99%84%EC%84%B1%ED%96%88%EC%96%B4%EC%9A%94-14336",
    articleTitle: "프로젝트 경험을 바탕으로 카카오 인턴에 합격하다",
    articleDescription:
      "내일배움캠프 백엔드 코스를 수료하고 카카오 개발자 인턴으로 취업에 성공한 이야기를 들어보세요.",
    brand: "nbcamp",
  },
  seungho: {
    articleImgSrc: "/assets/images/article/article_seungho.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EA%B5%AD%EB%B9%84%EC%A7%80%EC%9B%90-%EB%B6%80%ED%8A%B8%EC%BA%A0%ED%94%84%EC%97%90-%EB%8C%80%ED%95%9C-%EC%98%A4%ED%95%B4%EB%B6%80%ED%84%B0-%EC%86%94%EC%A7%81%ED%95%9C-%EC%A1%B0%EC%96%B8%EA%B9%8C%EC%A7%80-1436",
    articleTitle: "기업은행에 합격한 승호 님의 이야기",
    articleDescription:
      "압축성장할 수 있는 커리큘럼으로 기업은행에 취업한 승호 님의 솔직한 후기를 확인해보세요.",
    brand: "nbcamp",
  },
  game: {
    articleImgSrc: "/assets/images/article/article_game.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%ED%98%84%EC%A7%81-%EA%B2%8C%EC%9E%84-%EA%B0%9C%EB%B0%9C%EC%9E%90%EA%B0%80-%EC%95%8C%EB%A0%A4%EC%A3%BC%EB%8A%94-%EC%A7%81%EB%AC%B4-%EC%9D%B4%ED%95%B4-%EC%B2%AB-%EA%B1%B8%EC%9D%8C--17008",
    articleTitle: "현직 게임 개발자가 알려주는 직무 이해의 첫걸음",
    articleDescription:
      "게임 개발 세계에 처음 들어오시나요? 현직 게임 개발자 튜터님이 전하는 직무 이야기로 게임 개발 업계의 이해도를 높여보세요.",
    brand: "nbcamp",
  },
  heewon: {
    articleImgSrc: "/assets/images/article/article_heewon.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EC%B7%A8%EC%97%85%EC%97%90-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%ED%98%91%EC%97%85-%EA%B2%BD%ED%97%98%EC%9D%84-%EA%B8%B0%EB%A5%BC-%EC%88%98-%EC%9E%88%EC%97%88%EC%96%B4%EC%9A%94-%EB%82%B4%EC%9D%BC%EB%B0%B0%EC%9B%80%EC%BA%A0%ED%94%84-%EC%88%98%EA%B0%95%EC%83%9D-%EC%9D%B8%ED%84%B0%EB%B7%B0-2832",
    articleTitle: "“게임 학원과 다르게 협업 경험을 기를 수 있었어요”",
    articleDescription:
      "게임 개발 학원에서 채우지 못한 협업 경험을 얻기 위해 내일배움캠프에 합류한 희원 님의 이야기를 확인해 보세요.",
    brand: "nbcamp",
  },
  nbc: {
    articleImgSrc: "/assets/images/article/article_nbc.png",
    link: "https://spartacodingclub.kr/blog/nailbaeumdan_introduce",
    articleTitle:
      "내일배움카드로 직장인 필수 스킬 배우는 방법 : 국비지원 코딩, 데이터 강의 모음",
    articleDescription:
      "국비지원으로 들을 수 있는 스파르타코딩클럽의 인기 강의 6종을 소개합니다. 하루 30분 투자로 IT 역량 플러스!",
    brand: "kdc",
  },
  mingyu: {
    articleImgSrc: "/assets/images/article/article_mingyu.png",
    link: "https://spartacodingclub.kr/blog/coding-story09",
    articleTitle: "도망친 곳에 낙원은 있다",
    articleDescription:
      "내일배움카드로 [웹개발 종합반]과 [앱개발 종합반]을 수강한 후 새로운 커리어를 시작한 민규 님의 삶을 따라가 보자.",
    brand: "kdc",
  },
  app: {
    articleImgSrc: "/assets/images/article/article_app.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EC%95%B1-%EA%B0%9C%EB%B0%9C%EC%9E%90-%EC%B7%A8%EC%97%85-%EC%A0%84%EB%A7%9D%EC%9D%80--1425",
    articleTitle: "앱 개발자 취업 전망은?",
    articleDescription:
      "앱 개발에 관심은 있지만 취업이 걱정되나요? 이런 분들을 위해 앱 개발자에 입문하기 전 알아야 할 정보들을 담았습니다.",
    brand: "nbcamp",
  },
  kotlin: {
    articleImgSrc: "/assets/images/article/article_kotlin.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EC%95%88%EB%93%9C%EB%A1%9C%EC%9D%B4%EB%93%9C-%EC%95%B1-%EA%B0%9C%EB%B0%9C%EC%9E%90%EA%B0%80-%EC%BD%94%ED%8B%80%EB%A6%B0%EC%9D%84-%EC%84%A0%ED%83%9D%ED%95%B4%EC%95%BC-%ED%95%98%EB%8A%94-%EC%9D%B4%EC%9C%A0--17725",
    articleTitle: "안드로이드 앱 개발, 코틀린으로 시작해야 하는 이유",
    articleDescription:
      "안드로이드 앱 개발 분야에서 코틀린이 자바를 제치고 대세로 떠올랐습니다.",
    brand: "nbcamp",
  },
  sunghyun: {
    articleImgSrc: "/assets/images/article/article_sunghyun.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EA%B5%AD%EB%B9%84%EC%A7%80%EC%9B%90-%EB%B6%80%ED%8A%B8%EC%BA%A0%ED%94%84-%EC%A4%91-%EB%82%B4%EC%9D%BC%EB%B0%B0%EC%9B%80%EC%BA%A0%ED%94%84%EB%A5%BC-%EC%84%A0%ED%83%9D%ED%95%9C-%EC%9D%B4%EC%9C%A0-1432",
    articleTitle: '"국비지원 부트캠프지만 취업할 때까지 지원해주셨어요"',
    articleDescription:
      "비전공자가 국비지원 부트캠프로 취업할 수 있을까요? 개발자로 커리어를 이어나가고 있는 성현님의 후기를 확인해 보세요.",
    brand: "nbcamp",
  },
  uxui: {
    articleImgSrc: "/assets/images/article/article_uxui.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/uxui-%EB%94%94%EC%9E%90%EC%9D%B4%EB%84%88%EB%A5%BC-%EC%A4%80%EB%B9%84%ED%95%98%EB%8A%94-%EC%82%AC%EB%9E%8C%EB%93%A4%EC%9D%B4-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8-4265",
    articleTitle: "UI/UX 디자이너를 준비하는 사람들이 자주 묻는 질문",
    articleDescription:
      "디자이너의 업무, 디자이너 포트폴리오 준비 방법 등 취업 준비생이 가장 궁금해하는 질문을 현직 디자이너에게 물어봤습니다.",
    brand: "nbcamp",
  },
  bootcamp: {
    articleImgSrc: "/assets/images/article/article_bootcamp.png",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EA%B5%AD%EB%B9%84%EC%A7%80%EC%9B%90-%EB%B6%80%ED%8A%B8%EC%BA%A0%ED%94%84-%EC%A0%95%EB%A7%90-%EA%B4%9C%EC%B0%AE%EC%9D%84%EA%B9%8C-%EC%88%98%EB%A3%8C%EC%83%9D%EC%9D%98-%EB%8B%B5%EC%9D%80-%EA%B5%AD%EB%B9%84%EC%A7%80%EC%9B%90-%EC%86%94%EC%A7%81-%ED%9B%84%EA%B8%B0--4157",
    articleTitle:
      "“국비지원 부트캠프 정말 괜찮을까?” 수료생의 답은? | 국비지원 솔직 후기",
    articleDescription:
      "강사 퀄리티, 커리큘럼, 포트폴리오···. 국비지원 부트캠프를 둘러싼 수많은 논란에 대해 수료생이 직접 답했습니다.",
    brand: "nbcamp",
  },
  plusDiscount: {
    articleImgSrc: "/assets/images/article/article_plusDiscount.png",
    link: "",
    articleTitle: "등록금 20만 원 추가 할인",
    articleDescription:
      "주니어 개발자의 커리어 성장을 응원하는 마음으로, 등록금 추가 할인 혜택을 드립니다.",
    brand: "hhplus",
  },
  plusStudy: {
    articleImgSrc: "/assets/images/article/article_plusStudy.png",
    link: "",
    articleTitle: "사전 스터디 참여 기회",
    articleDescription:
      "개강 전, 동기들과 주 1회 온라인으로 만나는 ‘언어 기초 스터디’ 혹은 ‘방법론 스터디’ 중 선택하여 참여할 수 있습니다.",
    brand: "hhplus",
  },
  plusAIDiscount: {
    articleImgSrc: "/assets/images/article/article_plusDiscount.png",
    link: "",
    articleTitle: "AI 교육과정 등록금 20만 원 할인",
    articleDescription:
      "AI 역량을 통한 커리어 성장을 응원하는 마음으로, 문자를 통해 등록금 추가 할인 혜택을 안내드립니다.",
    brand: "hhplus",
  },
  plusAIStudy: {
    articleImgSrc: "/assets/images/article/article_plusStudy.png",
    link: "",
    articleTitle: "사전 스터디 참여 기회",
    articleDescription:
      "AI 교육과정 등록 시, 개강 전 동기들과 주 1회 온라인으로 만나는 사전 스터디에 참여하실 수 있습니다.",
    brand: "hhplus",
  },
  rebootReview: {
    articleImgSrc: "/assets/images/article/article_rebootReview.png",
    link: "https://hanghae99.spartacodingclub.kr/blog/%EC%84%9C%EB%A5%98-%ED%83%88%EB%9D%BD%EB%A7%8C-100%EB%B2%88-%ED%95%AD%ED%95%B4%EC%97%90%EC%84%9C-%EC%B7%A8%EC%97%85%EC%97%90-%ED%95%84%EC%9A%94%ED%95%9C-%EB%A7%88%EC%A7%80%EB%A7%89-%ED%8D%BC%EC%A6%90%EC%9D%84-%EC%B0%BE%EB%8B%A4-18230",
    articleTitle: "“서류 탈락만 100번, 항해에서 마지막 퍼즐을 찾다.”",
    articleDescription:
      "전공생에 타 부트캠프까지 들었지만 취업 리부트 코스를 선택 후 시스템 엔지니어로 취업한 수료생 후기를 살펴보세요.",
    brand: "hhreboot",
  },
  hhCodingTest: {
    articleImgSrc: "/assets/images/article/article_hhCodingtest.png",
    link: "https://hanghae99.spartacodingclub.kr/blog/%EA%B0%9C%EB%B0%9C%EC%9E%90-%EC%B7%A8%EC%A4%80%EC%83%9D%EC%9D%84-%EC%9C%84%ED%95%9C-%EC%BD%94%EB%94%A9-%ED%85%8C%EC%8A%A4%ED%8A%B8-%EC%A4%80%EB%B9%84-%EB%B0%A9%EB%B2%95-18576",
    articleTitle: "항해코딩테스트 준비하는 방법",
    articleDescription:
      "예비 개발자 대부분 취업 과정에서 치르는 코딩 테스트. 코딩 테스트는 어떤 시험이며 어떻게 준비해야 하는지 알아보세요.",
    brand: "hhreboot",
  },

  //신규 기사
  dataRecruitment: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2024-04-01T01%3A07%3A54.546Z-d4486777-1bd3-4bed-9c60-4d7558e8c330&w=1080&q=75",
    link: "https://nbcamp.spartacodingclub.kr/blog/채용공고로-알아보는-데이터-분석가-사이언티스트-엔지니어가-하는-일-18052",
    articleTitle:
      "채용공고로 알아보는 데이터 분석가, 사이언티스트, 엔지니어가 하는 일",
    articleDescription:
      "데이터 분석가, 데이터 사이언티스트, 데이터 엔지니어가 어떤 일을 하는지 이해하고, 취업에 필요한 역량을 알아보세요.",
    brand: "nbcamp",
  },

  python: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2024-04-12T06%3A07%3A26.278Z-df688e30-e54b-4580-9a8e-fbf5fa90cb3b&w=1080&q=75",
    link: "https://nbcamp.spartacodingclub.kr/blog/%ED%8C%8C%EC%9D%B4%EC%8D%AC-%EC%9B%B9-%EA%B0%9C%EB%B0%9C-%EC%9E%90%EC%A3%BC%ED%95%98%EB%8A%94-%EC%A7%88%EB%AC%B81-18675",
    articleTitle: "파이썬 웹 개발 자주 묻는 질문 (1)",
    articleDescription:
      "5년 차 개발자가 직접 들려주는 반복문, vscode autopep8, foreign key(외래키) 에러에 대한 궁금증을 해소해보세요.",
    brand: "nbcamp",
  },

  appProcess: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2024-04-29T09%3A13%3A15.705Z-36fa0a33-1744-4cce-a379-cd1b3230f041&w=1080&q=75",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EC%98%88%EB%B9%84-%EC%95%B1-%EC%B0%BD%EC%97%85%EA%B0%80%EA%B0%80-%EA%BC%AD-%EC%95%8C%EC%95%84%EC%95%BC-%ED%95%98%EB%8A%94-%EC%95%B1-%EA%B0%9C%EB%B0%9C-%ED%94%84%EB%A1%9C%EC%84%B8%EC%8A%A4-5%EB%8B%A8%EA%B3%84-19519",
    articleTitle: "예비 앱 창업가가 개발 프로세스 5단계",
    articleDescription:
      "스타트업이 앱을 개발할 때 실제 진행하는 프로세스를 확인하고, 나의 앱 창업 아이디어를 직접 구현해보세요.",
    brand: "nbcamp",
  },

  hhPlusAi: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2024-07-03T09%3A48%3A29.723Z-3d0cc69f-279d-4cba-acc7-edf1f68aa993&w=1080&q=75",
    link: "https://hanghae99.spartacodingclub.kr/blog/항해-플러스-ai-코스-후기-취업-성공-후기-19053",
    articleTitle: "항해 플러스 AI 코스 후기 취업 성공 후기",
    articleDescription: "항해 플러스 AI 코스 수료생 후기를 살펴보세요.",
    brand: "hhplus",
  },

  seunghun: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2024-04-03T05%3A19%3A17.527Z-5bd59fdf-fd61-498c-a44f-9a15a4c474e8&w=1080&q=75",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EB%B9%84%EC%A0%84%EA%B3%B5%EC%9E%90%EB%8F%84-%EA%B0%9C%EB%B0%9C%EC%9E%90%EB%A1%9C-%EC%B7%A8%EC%97%85%ED%95%A0-%EC%88%98-%EC%9E%88%EB%8A%94-%EC%B5%9C%EC%A0%81%EC%9D%98-%EC%BB%A4%EB%A6%AC%ED%81%98%EB%9F%BC%EC%9E%85%EB%8B%88%EB%8B%A4-18223",
    articleTitle:
      "비전공자도 개발자로 취업할 수 있는 최적의 커리큘럼이었습니다",
    articleDescription:
      "내일배움캠프 안드로이드 앱 개발 과정을 수료하고 1개월 만에 취업에 성공한 승훈 님의 이야기를 들어보세요.",
    brand: "nbcamp",
  },

  gameTrend: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2023-08-25T08%3A44%3A02.864Z-59d85b1e-3493-4dce-a928-d5aafcc5f293&w=1080&q=75",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EA%B2%8C%EC%9E%84-%EA%B0%9C%EB%B0%9C-%EC%A7%80%EB%A7%9D%EC%83%9D%EC%9D%84-%EC%9C%84%ED%95%9C-%EC%95%88%EB%82%B4%EC%84%9C-%EA%B2%8C%EC%9E%84-%EA%B0%9C%EB%B0%9C%EC%9E%90-%EC%A0%84%EB%A7%9D-%EA%B0%9C%EB%B0%9C-%ED%99%98%EA%B2%BD-%EB%B3%80%ED%99%94-1343",
    articleTitle:
      "게임 개발자가 되고 싶은 당신을 위한 가이드 : 게임 개발자 전망, 기술 트렌드",
    articleDescription:
      "게임 개발자의 전망을 꼼꼼하게 살펴보고 유니티, 언리얼 양대 게임 엔진의 특성을 비교해보세요.",
    brand: "nbcamp",
  },

  unityTop5: {
    articleImgSrc:
      "https://inblog.ai/_next/image?url=https%3A%2F%2Ffgobbnslcbjgothosvni.supabase.co%2Fstorage%2Fv1%2Fobject%2Fpublic%2Fimages%2Ffeatured_image%2F2023-08-25T08%3A44%3A51.161Z-6bacbe96-6b44-4ebd-a118-ecdf75bb3c93&w=1080&q=75",
    link: "https://nbcamp.spartacodingclub.kr/blog/%EA%B2%8C%EC%9E%84-%EC%97%94%EC%A7%84%EC%9D%98-%EB%8C%80%EC%84%B8%EB%A1%9C-%EA%BC%BD%ED%9E%88%EB%8A%94-%EC%9C%A0%EB%8B%88%ED%8B%B0-%EC%9E%A5%EC%A0%90-5%EA%B0%80%EC%A7%80-1445",
    articleTitle: "유니티(Unity)란? 유니티가 '대세 게임 엔진'인 이유 TOP 5",
    articleDescription:
      "게임 개발자를 꿈꾸는 분들을 위해 '게임 엔진 유니티(Unity)가 대세인 이유'를 알려드릴게요.",
    brand: "nbcamp",
  },
});

export const ARTICLE_RECOMMEND_BY_COURSE_ID = deepFreeze<
  Record<string, Article[]>
>({
  "654c451dd6aa5411421c298e": [ARTICLE_DATA.kakao, ARTICLE_DATA.seungho],
  "64b687dc6e951768d4879e70": [ARTICLE_DATA.kakao, ARTICLE_DATA.seungho],
  "648fc643f9b1fac7aced4966": [ARTICLE_DATA.kakao, ARTICLE_DATA.seungho],
  "64b0a23c417100590a97e152": [ARTICLE_DATA.kakao, ARTICLE_DATA.seungho],
  "6551b81ab4c831ca481ae7d3": [ARTICLE_DATA.kakao, ARTICLE_DATA.seungho],
  "649938ae1e7a5e8c1b9dfb37": [ARTICLE_DATA.gidong, ARTICLE_DATA.sangwu],
  "64af9cbb47cba538dd738f98": [ARTICLE_DATA.gidong, ARTICLE_DATA.sangwu],
  "64a653c7640351900b810a40": [ARTICLE_DATA.gidong, ARTICLE_DATA.sangwu],
  "66ca89b1daa18f1e0ea95a26": [ARTICLE_DATA.gidong, ARTICLE_DATA.sangwu],
  "5f0ae408765dae0006002817": [ARTICLE_DATA.app, ARTICLE_DATA.kotlin],
  "63ca26845b3bac30b29bf2a3": [ARTICLE_DATA.app, ARTICLE_DATA.kotlin],
  "652bea6b08647a82bb62eb54": [ARTICLE_DATA.app, ARTICLE_DATA.kotlin],
  "6361d2ce4239a844b30d4163": [ARTICLE_DATA.data, ARTICLE_DATA.analyst],
  "63ca5b43dfbe98f4c8ef20bf": [ARTICLE_DATA.data, ARTICLE_DATA.analyst],
  "62973372bf6e0e4f799fc76e": [ARTICLE_DATA.data, ARTICLE_DATA.analyst],
  "62f09f2af3ce1804901b3f5c": [ARTICLE_DATA.data, ARTICLE_DATA.analyst],
  "65369d38ceb87cbda112e69a": [ARTICLE_DATA.data, ARTICLE_DATA.analyst],
  "6083eaca6305e019d3e0c3b4": [ARTICLE_DATA.game, ARTICLE_DATA.heewon],
  "62f09eebfeb2d564bacb0262": [ARTICLE_DATA.game, ARTICLE_DATA.heewon],
  "66f66ef92c7d72f5785602d5": [ARTICLE_DATA.game, ARTICLE_DATA.heewon],
  "6535d721ceb87cbda112900a": [ARTICLE_DATA.uxui, ARTICLE_DATA.bootcamp],
  "6604e25bb84072071779ac26": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "65fd3e2e36ebf3dc285a0703": [
    ARTICLE_DATA.rebootReview,
    ARTICLE_DATA.hhCodingTest,
  ],

  "65d2b315a5c2b2029a103d56": [
    ARTICLE_DATA.rebootReview,
    ARTICLE_DATA.hhCodingTest,
  ],
  "6467063d187f226a50090d43": [
    ARTICLE_DATA.rebootReview,
    ARTICLE_DATA.hhCodingTest,
  ],

  "65d2b1e4a645a72c5e4a1f20": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "65d2b1111076f1d4cc074c62": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "6653f2e84a59c95dcbf75cf8": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],

  "65110da220492d270e776895": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "65bc972922e51a142eced448": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "668c96d42bbb65f282155301": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "66b5bd8dafab22923cb6156a": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "65d2b38b1076f1d4cc075361": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.analyst,
  ],
  "64956fe61e7a5e8c1b9c6e50": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.analyst,
  ],
  "65d2ae1b1076f1d4cc073fd0": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.python,
  ],
  "650abff5535c9f5382d509ee": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.python,
  ],
  "65d2ad561076f1d4cc073cb0": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.analyst,
  ],
  "65baf6587e86cf32470c1784": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.analyst,
  ],
  "65d2a90040ddbb5b4661f7b0": [ARTICLE_DATA.dataRecruitment, ARTICLE_DATA.data],
  "65b06c464b408d8c7a961a05": [ARTICLE_DATA.dataRecruitment, ARTICLE_DATA.data],
  "65d2a583af84d15811dc9aa2": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.analyst,
  ],
  "65b370c3949316d2d7a43847": [
    ARTICLE_DATA.dataRecruitment,
    ARTICLE_DATA.analyst,
  ],
  "65d2af70a5c2b2029a1030ca": [ARTICLE_DATA.kotlin, ARTICLE_DATA.app],
  "65bafc2e7e86cf32470c1dec": [ARTICLE_DATA.kotlin, ARTICLE_DATA.app],
  "65d2acd2a645a72c5e4a08ad": [ARTICLE_DATA.appProcess, ARTICLE_DATA.seunghun],
  "65b715cca5b5a5e6a309fe6a": [ARTICLE_DATA.appProcess, ARTICLE_DATA.seunghun],
  "65d2ae8f1076f1d4cc07414b": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "65b08e7f4b408d8c7a9625e9": [
    ARTICLE_DATA.plusDiscount,
    ARTICLE_DATA.plusStudy,
  ],
  "671994f16b070ca0779c2ba8": [
    ARTICLE_DATA.plusAIDiscount,
    ARTICLE_DATA.plusAIStudy,
  ],

  "65d2af11a5c2b2029a102ecb": [ARTICLE_DATA.gameTrend, ARTICLE_DATA.unityTop5],
  "65abb1f33cd250acb856433a": [ARTICLE_DATA.gameTrend, ARTICLE_DATA.unityTop5],

  "66a093132bbb65f2821ecaf1": [ARTICLE_DATA.app, ARTICLE_DATA.kotlin],
  "66a0a88fd69fa23abaa0be98": [ARTICLE_DATA.kakao, ARTICLE_DATA.seungho],
  "66b17e8f73d6338061729d63": [ARTICLE_DATA.gidong, ARTICLE_DATA.sangwu],
  "66eb8b80a80c5952010dfbf9": [ARTICLE_DATA.uxui, ARTICLE_DATA.bootcamp],
});

export const ARTICLE_HEADER: Record<Brand, { caption: string; title: string }> =
  Object.freeze({
    nbcamp: {
      caption: "인기 아티클 TOP 2",
      title: "이 강의를 완주한 분들이\n가장 관심 있어 하는 아티클이에요.",
    },
    kdc: {
      caption: "인기 아티클 TOP 2",
      title: "이 강의를 완주한 분들이\n가장 관심 있어 하는 아티클이에요.",
    },
    hhplus: {
      caption: "완주 혜택",
      title: "지금 합류 시 드리는\n얼리버드 특별 혜택",
    },
    hhreboot: {
      caption: "인기 아티클 TOP 2",
      title: "이 강의를 완주한 분들이\n가장 관심 있어 하는 아티클이에요.",
    },
  });
