import { config } from "../config";
import { authHeader, handleResponse, privateFetch } from "../_helpers";
import instance from "../_helpers/axios";
import { getGETAuthOptions } from "../_urls";

function getCourseList() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  return fetch(`${config.apiUrl}/v1/courses/`, requestOptions).then(
    handleResponse
  );
}

function getCourse(course_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  return fetch(
    `${config.apiUrl}/v1/courses/${course_id}/public`,
    requestOptions
  ).then(handleResponse);
}

function getGovCourses() {
  const requestOptions = getGETAuthOptions();
  return privateFetch(`${config.apiUrl}/v2/courses/gov`, requestOptions).then(
    handleResponse
  );
}

async function getCourseByUserId(course_id) {
  return await instance.get(`/courses/${course_id}`).then((resp) => resp.data);
}

async function getCourseByPkgId(course_id) {
  return await instance
    .get(`${config.apiUrl}/v2/courses/${course_id}/courses`)
    .then((resp) => resp.data);
}

export const courseService = {
  getCourseList,
  getCourse,
  getCourseByUserId,
  getCourseByPkgId,
  getGovCourses,
};
