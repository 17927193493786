import React, { Component, Fragment } from "react";
import { createEventId, history, params, privateFetch } from "../../_helpers";
import { config } from "../../config";
import { Button, Checkbox, Col, Modal, Radio, Row, Skeleton } from "antd";
import classNames from "classnames/bind";
import styles from "./PaymenPage.module.scss";
import { connect } from "react-redux";
import {
  getGETAuthOptions,
  getGETOptions,
  getPayFreemiumUrl,
  getPostAuthOptions,
  getStartPaymentUrl,
  getZeropayUrl,
} from "../../_urls";
import { paymentActions } from "../../_actions/payment.actions";
import {
  couponActions,
  logActions,
  priceByNumberActions,
  todaydiscountActions,
  userActions,
} from "../../_actions";
import { pointActions } from "../../_actions/point.actions";
import axios from "../../_helpers/axios";
import {
  getDeviceId,
  sendLog,
  setCountry,
} from "../../v2/businesslogics/logging";
import { POINT_REIMBURSE } from "../../_consts";
import { getCookie } from "../../v2/_helper";
import { FooterV2 } from "../../_components/Footer/FooterV2";

let is_discounted = false;
const cx = classNames.bind(styles);

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.priceRef = React.createRef();
    let paramsDict = { payMethod: "CARD" };
    let paramsString = history.location.search;
    if (paramsString.length > 0) {
      let params = paramsString.split("?")[1].split("&");
      for (const p of params) {
        let part = p.split("=");
        paramsDict[part[0]] = part[1];
      }
    }
    this.state = {
      ...paramsDict,
      price: 0,
      card: true,
      kakaopay: false,
      paypal: false,
      NicepayReserved: "",
      DirectShowOpt: "",
      todaydiscount_discount: 0,
      coupon_discount: 0,
      promo_code: "",
      gold: 0,

      name: "",
      phone: "",
      email: "",

      isPaying: false,

      coupon_applied: false,
      moid: "",
      EdiDate: "",
      MID: "",
      EncryptData: "",
      ReturnURL: "",
      TID: "",
      isForeign: false,
      isByEnrolleds: true,

      track_id: "",

      count: 0,
      counter: null,
      timerMessage: "",
      isFirstDiscount: false,
      EXCHANGE_RATIO: 1100,

      logNationUrl:
        "https://pro.ip-api.com/json/?fields=status,message,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,mobile,query&key=FVaMw6OLSQKC0LD",

      isFree: false,
      testInfo: "",
      installmentShow: false,

      isMakers: false,
      infoShow: false,
      infoText: "더보기",
      isFriendReco: false,

      pythonModalShow: false,
      coupon_title: "쿠폰을 선택해주세요.",
      coupon_id: "",
      coupon_benefit_detail: "",
      coupon_benefit_type: "",
    };
  }

  _testinitialsetforpaypal = () => {
    window.payment_method = "paypal";
    this.setState({
      card: false,
      kakaopay: false,
      paypal: true,
      isForeign: true,
    });
  };

  componentDidMount() {
    setCountry();
    const { user } = this.props;
    let token = getCookie("token");
    if (token && !user) {
      this.props.loginWithToken(token);
      if (params().is_new) {
        const eventID = createEventId();
        sendLog("complete_registration", {}, eventID);
      }
    } else {
      this.props.refreshInfo();
    }

    // this.initFirstDiscountCounter();
    // this._testinitialsetforpaypal();
    const { err, cid } = this.state;
    if (err !== undefined) {
      alert(`결제에 실패하였습니다.\n\n${decodeURI(err)}`);
    }

    // 메이커스
    if (cid === "5fa10723eae449d341128906") {
      this.setState({
        isMakers: true,
      });
    }

    // 접속 IP가 해외인지 확인하고 Paypal 결제 버전으로 세팅
    this._logNation();

    window.scrollTo(0, 0);
    let { name, email, phone } = this.state;

    this.props.getTodaydiscount(cid);
    this.props.getPaymentInfo(cid);
    this.props.getPriceByNumber(cid);

    // 기존 수강 여부 확인
    if (this.props.user) {
      this.assignUserGroup();
      this.checkEnrolled();
      this.props.getPointInfo();
      this.props.getCouponInfo();
      this._logPageView();
      // CRM 여부 확인
      this.props.getLogCrmAB(this.props.user._id);

      name = this.props.user.name;
      email = this.props.user.email;
      phone = this.props.user.phone;
    }

    let f_uid = getCookie("f_uid");
    let isFriendReco = false;
    if (f_uid && this.props.user) {
      console.log(
        f_uid !== this.props.user._id && cid !== "5fd1cedae7b11d0865a27a76"
      );
      isFriendReco =
        f_uid !== this.props.user._id && cid !== "5fd1cedae7b11d0865a27a76";
    }

    let target_courses = [
      "5f0ae408765dae0006002816",
      "5f51cb92b91e36173c722c23",
      "5f51cba0e62d08d140b3e5bd",
      "5f8bc3e83cff77cc2abeb181",
    ];

    this.setState({
      name,
      email,
      phone,
      isFriendReco,
      pythonTarget: target_courses.includes(cid),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { cid } = this.state;
    if (!prevProps.user && this.props.user) {
      const { name, phone, email } = this.props.user;
      this.props.getPointInfo();
      this.props.getCouponInfo();
      this.checkEnrolled();
      this._logPageView();
      this.assignUserGroup();
      this.props.getLogCrmAB(this.props.user._id);
      let f_uid = getCookie("f_uid");
      let isFriendReco =
        f_uid &&
        f_uid !== this.props.user._id &&
        cid !== "5fd1cedae7b11d0865a27a76";
      this.setState({
        name,
        email,
        phone,
        isFriendReco,
      });
    }

    if (this.props.paymentInfo.loaded && !prevProps.paymentInfo.loaded) {
      const { paymentInfo } = this.props;
      if (paymentInfo.data.free) {
        this.setState({
          // isFree: true,
          isFriendReco: false,
        });
      }
    }
  }

  _logPageView = async () => {
    const { log_sent } = this.state;
    if (log_sent) {
      return;
    }
    this.setState({
      log_sent: true,
    });
    const eventID = createEventId();
    await this._sendLog("pageview_payment", eventID);
  };

  checkEnrolled = () => {
    const { cid } = this.state;
    privateFetch(
      `${config.apiUrl}/v1/accounts/enrolled/${cid}`,
      getGETAuthOptions()
    )
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          alert("이미 신청하신 강의입니다!\n내 강의실로 이동합니다.");
          history.push("/classroom");
        }
      });
  };

  assignUserGroup = () => {
    const { user } = this.props;
    // 유저 그룹 할당 변경 로직
    const user_id = user._id;
    let sum = 0;
    for (let i = 0; i < user_id.length; i++) {
      sum += user_id.charCodeAt(i);
    }
    const groups = ["A", "B"];
    sum = "sum" in params() ? parseInt(params().sum) : sum;
    this.setState({
      testInfo: groups[sum % 2],
    });
    return groups[sum % 2];
  };

  assignUserGroupByUniqueId = () => {
    const unique_id = getDeviceId();
    let sum = 0;
    for (let i = 0; i < unique_id.length; i++) {
      sum += unique_id.charCodeAt(i);
    }
    const groups = ["A", "B"];
    sum = "sum" in params() ? parseInt(params().sum) : sum;
    return groups[sum % 2];
  };

  _sendLog = async (action, eventID) => {
    let ua = window.navigator.userAgent;
    ua = ua.toLowerCase();
    let matched = {};
    const device_id = getDeviceId();
    const referrer = "";
    const url = document.location.href;
    const { _id: user_id } = this.props.user;
    const group = this.assignUserGroupByUniqueId();
    let payment_data = this._getPaymentAccessData();
    const f_uid = getCookie("f_uid");
    const platform_match =
      /(ipad)/.exec(ua) ||
      /(ipod)/.exec(ua) ||
      /(windows phone)/.exec(ua) ||
      /(iphone)/.exec(ua) ||
      /(kindle)/.exec(ua) ||
      /(silk)/.exec(ua) ||
      /(android)/.exec(ua) ||
      /(win)/.exec(ua) ||
      /(mac)/.exec(ua) ||
      /(linux)/.exec(ua) ||
      /(cros)/.exec(ua) ||
      /(playbook)/.exec(ua) ||
      /(bb)/.exec(ua) ||
      /(blackberry)/.exec(ua) ||
      [];

    matched.platform = platform_match[0] || "";
    let data = {
      device_id,
      user_id,
      referrer,
      url,
      group,
      action,
      f_uid,
      platform: matched.platform,
      event_id: eventID,
      ...payment_data,
    };
    await axios.post("/logs/", data);
  };

  _getPaymentAccessData = () => {
    if (!this.props.user) {
      return {};
    }
    const { _id: user_id } = this.props.user;
    const { cid: course_id } = this.state;
    const final_price = parseInt(
      this.priceRef.current ? this.priceRef.current.value : 0
    );
    return { user_id, course_id, final_price };
  };

  _logNation = () => {
    const { logNationUrl, forcePaypal } = this.state;
    const options = getGETOptions();
    fetch(logNationUrl, options)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.country !== "South Korea" || forcePaypal) {
          window.payment_method = "paypal";
          this.setState({
            isForeign: true,
            card: false,
            kakaopay: false,
            paypal: true,
          });
        }
      })
      .catch((error) => {});
  };

  _numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  _startPayment = () => {
    let {
      isFree,
      paypal,
      isByEnrolleds,
      isMakers,
      isFirstDiscount,
      name,
      email,
      phone,
      promo_code,
      full_refund_iter,
      gold,
      track_id,
      subclass_id,
      testInfo,
      isFriendReco,
      coupon_id,
    } = this.state;
    const { nicepayStart, nicepayPayPal } = window;
    const course_id = this.state.cid;
    const f_uid = getCookie("f_uid") || "";
    const device_id = getDeviceId();
    isFirstDiscount = isFirstDiscount && !isMakers;

    if (!this.props.user) {
      if (name === "") {
        alert("이름을 입력해주세요.");
        document.getElementById("name_input").focus();
        return;
      }
      if (this.validateEmail(email) === false) {
        alert("올바른 이메일을 입력해주세요.");
        document.getElementById("email_input").focus();
        return;
      }
      if (this.validatePhone(phone) === false) {
        alert("올바른 연락처를 입력해주세요.");
        document.getElementById("phone_input").focus();
        return;
      }
    }

    if (this.props.user) {
      name = this.props.user.name;
      phone = this.props.user.phone;
      email = this.props.user.email;
    }

    // 마감 인원 검사
    const { subclass } = this.props.paymentInfo.data;
    if (subclass && !subclass_id) {
      alert("분반을 선택해주세요");
      document.getElementById("subclass").focus();
      return;
    }
    for (let i = 0; subclass && i < subclass.length; i++) {
      let sub = subclass[i];
      if (sub._id === subclass_id && sub.tot <= sub.now) {
        alert("해당 반은 마감되었습니다.");
        document.getElementById("subclass").focus();
        return;
      }
    }

    let data = {
      course_id,
      isByEnrolleds,
      isFirstDiscount,
      promo_code,
      full_refund_iter,
      gold,
      subclass_id,
      isFriendReco,
      f_uid,
      coupon_id,
      group: testInfo,
      name: name.trim(),
      phone: phone.split("-").join(""),
      email: email.trim().toLowerCase(),
      rcmd_phone: "",
      payment_method: window.payment_method,
      track_id: track_id,
      isPaypal: window.payment_method === "paypal",
      unique_id: getDeviceId(),
    };
    // 핵클 로그
    if (device_id) {
      data.device_id = device_id;
    }

    let endpoint = getStartPaymentUrl(course_id);
    if (isFree) {
      endpoint = getPayFreemiumUrl(course_id);
    }
    let options = getPostAuthOptions(data);
    this.setState({
      isPaying: true,
    });
    privateFetch(endpoint, options)
      .then(
        (resp) => resp.json(),
        (error) => {
          // console.log(error);
        }
      )
      .then((result) => {
        // 추석무료특강
        if (result.isFree) {
          window.location.href = `/payment/prequestion/${result.course_id}`;
          return;
        }

        if (result.zeropay) {
          const { order_no } = result;
          const next_endpoint = getZeropayUrl(course_id, order_no);
          const next_options = getGETAuthOptions();
          fetch(next_endpoint, next_options)
            .then((resp) => resp.json())
            .then((result) => {
              if (result.ok) {
                window.location.href = `/payment/prequestion/${result.course_id}`;
                return;
              }
              alert("결제가 정상적으로 완료되지 않았습니다.");
            });
          return;
        }

        this.setState({
          isPaying: false,
        });
        if (result.message !== undefined) {
          alert(result.message);
          return;
        }

        const { nicepayinfo, order_no, TID, price } = result;
        if (nicepayinfo === undefined) {
          this.setState({
            alert_msg_title: "오류가 발생하였습니다.",
            modalVisible: true,
            alert_msg: "오류가 발생하였습니다. 재시도하시기 바랍니다.",
          });
          return;
        }

        const { EdiDate, MID, EncryptData, ReturnURL } = nicepayinfo;

        this.setState({
          moid: order_no,
          EdiDate,
          MID,
          price,
          EncryptData,
          ReturnURL,
          TID,
        });

        if (paypal) {
          nicepayPayPal();
        } else {
          nicepayStart();
        }
      });
  };

  _setMethod = (e) => {
    window.payment_method = e.target.value;
    if (e.target.value === "kakaopay") {
      this.setState({
        card: false,
        kakaopay: true,
        paypal: false,
        NicepayReserved: "DirectKakao=Y",
        DirectShowOpt: "CARD",
      });
    } else if (e.target.value === "CARD") {
      this.setState({
        card: true,
        kakaopay: false,
        paypal: false,
        NicepayReserved: "",
        DirectShowOpt: "",
      });
    } else if (e.target.value === "paypal") {
      this.setState({
        card: false,
        kakaopay: false,
        paypal: true,
        NicepayReserved: "",
        DirectShowOpt: "",
      });
    }
  };

  _setTrack = (e) => {
    this.setState({
      track_id: e.target.value,
    });
  };

  _setPromo = (e) => {
    this.setState({
      promo_code: e.target.value.trim().toLowerCase().split("-").join(""),
    });
  };

  _applyPromo = (e) => {
    e.preventDefault();

    let { promo_code, isByEnrolleds, paypal } = this.state;
    const course_id = this.state.cid;
    if (promo_code === "") {
      alert("올바른 할인코드를 입력하세요.");
      document.getElementById("promo_code").value = "";
      this.setState({ promo_code: "" });
      return;
    }

    fetch(
      `${config.apiUrl}/v1/coupons/${promo_code}/${course_id}?isByEnrolleds=${isByEnrolleds}&isPaypal=${paypal}`
    )
      .then((resp) => resp.json())
      .then((result) => {
        let discount = parseInt(result["discount"]);
        if (discount === -1) {
          alert("올바른 할인코드를 입력하세요.");
          document.getElementById("promo_code").focus();
          document.getElementById("promo_code").value = "";
          this.setState({ promo_code: "" });
          return;
        }
        if (result.message) {
          alert(result.message);
          document.getElementById("promo_code").value = "";
          this.setState({ promo_code: "" });
          return;
        }
        is_discounted = true;
        this.setState({
          coupon_discount: discount,
          coupon_applied: true,
        });
        alert("할인코드 적용 완료! 😎");
        if (promo_code === "bkbktest") {
          this.props.setPaymentType(true);
        }
      });
  };

  showSelects = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showSelect: !prevState.showSelect,
      };
    });
  };

  setCoupon = (coupon_id, coupon_title) => {
    let { coupons } = this.props.couponInfo.data;
    if (coupon_id === "") {
      this.setState({
        coupon_id: "",
        coupon_title: coupon_title,
        coupon_benefit_detail: "",
        coupon_benefit_type: "",
        showSelect: false,
        coupon_applied: false,
      });
    } else {
      for (let i = 0; i < coupons.length; i++) {
        let coupon = coupons[i];
        if (coupon_id === coupon._id) {
          this.setState({
            coupon_id: coupon_id,
            coupon_title: coupon_title,
            coupon_benefit_detail: coupon["benefit_detail"],
            coupon_benefit_type: coupon["benefit_type"],
            showSelect: false,
            coupon_applied: true,
          });
        }
      }
    }
  };

  _getCouponDiscount = (final_price) => {
    let { coupon_benefit_type, coupon_benefit_detail, paypal, EXCHANGE_RATIO } =
      this.state;
    if (coupon_benefit_type === "percent") {
      let discount = (final_price * coupon_benefit_detail) / 100;
      return Math.floor(discount);
    } else if (coupon_benefit_type === "amount") {
      return coupon_benefit_detail;
    } else if (coupon_benefit_type === "target") {
      return final_price - coupon_benefit_detail;
    }
    return 0;
  };

  _setGold = (e) => {
    let gold = e.target.value;
    if (gold === "") {
      gold = 0;
    } else if (this.validateGold(gold) === false) {
      alert("숫자만 입력해주세요.");
      e.target.value =
        e.target.value.length > 0
          ? e.target.value.substr(0, e.target.value.length - 1)
          : 0;
      if (e.target.value === "") {
        e.target.value = 0;
      }
      gold = e.target.value;
    }
    this.setState({
      gold: parseInt(gold),
    });
  };

  _applyGold = () => {
    const { paypal, EXCHANGE_RATIO } = this.state;
    let userGold = this.props.pointInfo.loaded
      ? this.props.pointInfo.data.point
      : 0;

    const final_price = parseInt(this.priceRef.current.value);
    // 포인트 적용하기 전에 이미 0원인 경우
    if (final_price === 0) {
      return;
    }
    // 포인트 적용 금액이 결제 금액보다 큰 경우
    let isGoldOverPrice = paypal
      ? final_price < Math.ceil((userGold / EXCHANGE_RATIO) * 100)
      : final_price < userGold;
    if (isGoldOverPrice) {
      userGold = final_price;
    }

    document.getElementById("gold").value = userGold;
    this.setState({
      gold: userGold,
    });
  };

  _checkGold = (e) => {
    let gold = parseInt(e.target.value === "" ? 0 : e.target.value);
    let userGold = this.props.pointInfo.loaded
      ? this.props.pointInfo.data.point
      : 0;
    if (gold > userGold) {
      gold = 0;
      e.target.value = "";
    }
    this.setState({
      gold: gold,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handlePhoneChange = (e) => {
    this.fillHyphen(e.target.value);
  };

  fillHyphen = (phone) => {
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }

    this.setState({
      phone,
    });
  };

  validateGold = (gold) => {
    var re = /^\d+$/;
    return re.test(gold);
  };

  validatePhone = (phone) => {
    const { isForeign } = this.state;
    if (isForeign) {
      return true;
    }
    const re = /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/;
    return re.test(phone);
  };

  handleEmailChange = (e) => {
    e.target.value = e.target.value.trim().toLowerCase();
    this.setState({
      email: e.target.value,
    });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  initFirstDiscountCounter = () => {
    let time = window.localStorage.getItem("firstDiscount");

    if (time === null) {
      time = new Date();
      time.setMinutes(time.getMinutes() + 30);
    } else {
      time = new Date(time);
    }

    this.setLocalStorage("firstDiscount", time.toISOString());
    this.setState({
      time: time.toISOString(),
      counter: setInterval(this.getTimer, 1000),
    });
  };

  getLocalStorage = (key) => {
    return window.localStorage.getItem(key);
  };

  setLocalStorage = (key, val) => {
    if (window.localStorage) {
      window.localStorage.setItem(key, val);
    }
    return val;
  };

  setInstallment = () => {
    let { installmentShow } = this.state;
    this.setState({
      installmentShow: !installmentShow,
    });
  };

  getTimer = () => {
    let { time, counter, isFree } = this.state;
    if (isFree || this.props.logCrmAB.data.is_target) {
      return;
    }
    time = new Date(time);
    let now = new Date();
    if (now > time) {
      this.setState({
        // time: this.setLocalStorage('time', time - 1),
        isFirstDiscount: false,
      });
      clearInterval(counter);
      return;
    }
    this.setState({
      // time: this.setLocalStorage('time', time - 1),
      isFirstDiscount: true,
    });
    let dday = time;

    let days = (dday - now) / 1000 / 60 / 60 / 24;
    let daysRound = Math.floor(days);
    let hours = (dday - now) / 1000 / 60 / 60 - 24 * daysRound;
    let hoursRound = Math.floor(hours);
    let minutes =
      (dday - now) / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    let minutesRound = Math.floor(minutes);
    let seconds =
      (dday - now) / 1000 -
      24 * 60 * 60 * daysRound -
      60 * 60 * hoursRound -
      60 * minutesRound;
    let secondsRound = Math.round(seconds);
    this.setState({
      timerMessage: `${minutesRound}분 ${secondsRound}초`,
    });
  };

  _getTrackOptions = (tracks) => {
    let trackOptions = [];
    if (tracks) {
      let title = tracks[0].course_title;
      trackOptions.push(
        <div>
          <Radio defaultChecked={true} value="">
            {title + "(5주)"}
          </Radio>
        </div>
      );
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        let track_id = track._id;
        trackOptions.push(
          <div>
            <Radio value={track_id}>
              {title + "(5주) + " + track.title + " (" + track.week + "주)"}
            </Radio>
          </div>
        );
      }
    }
    return trackOptions;
  };

  _getTrackPrice = () => {
    const { track_id } = this.state;
    const { paymentInfo } = this.props;
    if (track_id && paymentInfo.loaded) {
      let tracks = paymentInfo.data.tracks;
      for (let i = 0; i < tracks.length; i++) {
        if (track_id === tracks[i]._id) {
          return parseInt(tracks[i].price);
        }
      }
    } else {
      return 0;
    }
  };

  _getTrackTitle = () => {
    const { track_id } = this.state;
    const { paymentInfo } = this.props;
    if (track_id && paymentInfo.loaded) {
      let tracks = paymentInfo.data.tracks;
      for (let i = 0; i < tracks.length; i++) {
        if (track_id === tracks[i]._id) {
          return tracks[i].title;
        }
      }
    } else {
      return "";
    }
  };

  selectSubclass = (e) => {
    this.setState({
      subclass_id: e.target.value,
    });
  };

  changeInfoView = () => {
    let { infoShow, infoText } = this.state;
    infoText = infoShow ? "더보기" : "닫기";
    this.setState({
      infoShow: !infoShow,
      infoText,
    });
  };

  render() {
    const { relief, maker } = params();
    const { user, priceByNumber, couponInfo } = this.props;
    let {
      alert_msg_title,
      modalVisible,
      alert_msg,
      coupon_discount,
      coupon_applied,
      paypal,
      isForeign,
      track_id,
      timerMessage,
      isFirstDiscount,
      EXCHANGE_RATIO,
      name,
      phone,
      email,
      gold,
      isFree,
      isMakers,
      coupon_id,
      isPaying,
      installmentShow,
      isFriendReco,
      pythonModalShow,
      pythonTarget,
      showSelect,
      coupon_title,
    } = this.state;
    isFriendReco = isFriendReco && !isFree;
    isFirstDiscount = isFirstDiscount && !isMakers && !isFriendReco;
    if (user) {
      name = user.name;
      email = user.email;
      phone = user.phone;
    }
    phone = phone.toString();

    let { todaydiscount, paymentInfo, pointInfo, logCrmAB } = this.props;
    if (
      paymentInfo.loaded === false ||
      todaydiscount.loaded === false ||
      priceByNumber.loaded === false ||
      couponInfo.loaded === false ||
      logCrmAB.loaded === false
    ) {
      return <Skeleton />;
    }
    let trackOptions = this._getTrackOptions(paymentInfo.data.tracks);

    let { reason, group, is_target, hackle_var } = logCrmAB.data;
    let isCrmOn = is_target;
    const { price_earlybird } = priceByNumber.data;
    todaydiscount = todaydiscount.data;
    paymentInfo = paymentInfo.data;
    let { display_price, price, reimburse, title, round, period_title } =
      paymentInfo;

    let todaydiscount_discount = todaydiscount.is_activated
      ? todaydiscount.discount
      : 0;
    let final_price = price - todaydiscount_discount;
    let diff = (parseInt(display_price) - parseInt(price)).toString();
    const isEntry = this.state.cid === "6001384a006d2727411520ea";

    // 인원 할인 로직
    diff = display_price - price_earlybird.price;

    // 메이커 트랙 선택 시, 정가 ++
    display_price = display_price + this._getTrackPrice(this.state.track_id);

    final_price = display_price - diff - todaydiscount_discount - gold;
    if (isFirstDiscount) {
      final_price -= 20000;
    }
    if (isFriendReco && !isEntry) {
      final_price -= 50000;
    }
    if (coupon_id !== "") {
      coupon_discount = this._getCouponDiscount(final_price);
      final_price -= coupon_discount;
    }

    if (paypal) {
      final_price = Math.ceil((final_price / EXCHANGE_RATIO) * 100);
    }

    reimburse = 0;
    isCrmOn = false;

    return (
      <div>
        {pythonModalShow && (
          <div className={cx("modal-background")}>
            <div className={cx("modal-container")}>
              <div className={cx("modal-sec")}>
                <img
                  className={cx("modal-close")}
                  onClick={() => {
                    this.setState({ pythonModalShow: false });
                  }}
                  src={"/assets/icons/ic_close.png"}
                  alt=""
                />
                <div className={cx("title-group")}>
                  <img
                    src={"/assets/icons/ic_python.svg"}
                    alt=""
                    className={cx("modal-python-ic")}
                  />
                  <h2>파이썬 문법 101</h2>
                </div>
                <div className={cx("desc")}>
                  파이썬 문법을 이해하고 사용에 익숙해지고 싶으신 분들을 위해,
                  <br />
                  <span className={cx("underline")}>평생소장</span>이 가능한
                  문법강의 패키지를{" "}
                  <span className={cx("underline")}>무료로</span> 제공합니다.
                </div>
              </div>
              <div className={cx("whole-hr")} />
              <div className={cx("modal-sec", "desc-group")}>
                <div className={cx("desc-sub")}>
                  <h3>파이썬 문법, 자유자재로 쓰고 싶다구요?</h3>
                  <h2>이 수업 하나면 됩니다.</h2>
                </div>
                <ul className={cx("desc-pointout")}>
                  <li>
                    <img
                      src={"/assets/icons/ic_check_gray.png"}
                      alt=""
                      className={cx("checkpoint")}
                    />
                    <div>
                      기초적인 변수, 자료형, 반복문, 조건문, 함수부터 꼭 필요한
                      제어문, 클래스, 모듈, 예외처리까지!
                    </div>
                  </li>
                  <li>
                    <img
                      src={"/assets/icons/ic_check_gray.png"}
                      alt=""
                      className={cx("checkpoint")}
                    />
                    <div>
                      20여 개의 퀴즈로, 이론과 실습을 적절히 병행하여 손에 익힐
                      수 있도록 구성하였습니다.
                    </div>
                  </li>
                </ul>
                <div className={cx("desc-sub", "desc-outro")}>
                  <h3>파이썬 기초문법이 부족해서 수업을 못 들을까?</h3>
                  <h2>이제 걱정하지 마세요!</h2>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={cx("backgroundWrapper")}>
          <div className={cx("contentWrapper")}>
            {!isFirstDiscount && !isCrmOn && !isFriendReco && (
              <div className={cx("headerWrapper")}>
                <h3>
                  {isFriendReco
                    ? `[${decodeURI(
                        getCookie("f_name")
                      )}님의 친구추천] 지금 신청하면 5만원 할인!`
                    : "수강신청"}
                </h3>
              </div>
            )}
            {isFriendReco && (
              <div className={cx("headerWrapper", "friend_reco")}>
                <h3>
                  {isFriendReco
                    ? `[${decodeURI(
                        getCookie("f_name")
                      )}님의 친구추천] 지금 신청하면 5만원 할인!`
                    : "수강신청"}
                </h3>
              </div>
            )}
            {isFirstDiscount && !isFriendReco && (
              <div className={cx("timer")}>
                <div className={cx("timerFirstRow")}>
                  <div className={cx("timerTitle")}>첫 방문 할인!</div>
                  <div className={cx("timerClock")}>
                    <img src={"/assets/images/payment/clock.png"} alt="" />
                    <span>{timerMessage}</span>
                  </div>
                </div>
                <div className={cx("timerSecondRow")}>
                  지금 바로 결제하고, 최저가에 들으세요.
                </div>
              </div>
            )}
            <div className={cx("formWrapper")}>
              <div>
                <div className={cx("titletWrapper")}>
                  <label>주문 정보</label>
                </div>
                <div className={cx("content")}>
                  <div className={cx("course-detail")}>
                    <div id="course-title" className={cx("course-title")}>
                      {title} {round["round"] ? " " + round["round"] : ""}
                    </div>
                    {!isEntry && (
                      <div id="course-date" className={cx("course-date")}>
                        {round["start_date"] + " ~ " + round["end_date"]}
                        {!paymentInfo.is_trial && !isMakers && (
                          <span className={cx("review-period")}>
                            복습기간 {parseInt(paymentInfo.days / 7)}주
                          </span>
                        )}
                      </div>
                    )}
                    {isEntry && (
                      <div id="course-date" className={cx("course-date")}>
                        결제 직후 24시간 동안 수강 가능
                      </div>
                    )}
                  </div>
                  {/*{hackle_var === 'B' && pythonTarget &&*/}
                  {/*<div className={cx('python-course')}>*/}
                  {/*    <div>*/}
                  {/*        <img*/}
                  {/*            className={cx('python-ic')}*/}
                  {/*            src={('/assets/icons/ic_python.svg')}*/}
                  {/*            alt=""*/}
                  {/*        />*/}
                  {/*    </div>*/}
                  {/*    <div className={cx('course-detail')}>*/}
                  {/*        <div*/}
                  {/*            className={cx('course-title')}>파이썬 문법 101*/}
                  {/*        </div>*/}
                  {/*        <div*/}
                  {/*            className={cx('course-date')}>평생소장*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*    <div className={cx("more")} onClick={() => {*/}
                  {/*        this.setState({pythonModalShow: true})*/}
                  {/*    }}>*/}
                  {/*        자세히 보기*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  {/*}*/}
                  {paymentInfo.subclass && (
                    <div className={cx("course-subclass")}>
                      <select
                        name=""
                        id="subclass"
                        onChange={this.selectSubclass}
                        value={this.state.subclass_id}
                      >
                        <option selected disabled>
                          -- 분반을 선택해주세요 --
                        </option>
                        {paymentInfo.subclass.map((subclass, idx) => {
                          if (subclass.now < subclass.tot) {
                            if (subclass.tot - subclass.now < 4) {
                              return (
                                <option key={idx} value={subclass._id}>
                                  {subclass.title} {subclass.tot - subclass.now}
                                  자리 남음 - 마감임박!
                                </option>
                              );
                            } else {
                              return (
                                <option key={idx} value={subclass._id}>
                                  {subclass.title} {subclass.tot - subclass.now}
                                  자리 남음!
                                </option>
                              );
                            }
                          }
                          return (
                            <option key={idx} value={subclass._id}>
                              {subclass.title} 마감!
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={cx("titletWrapper")}>
                  <label>연락처 정보</label>
                </div>
                {this.props.user && (
                  <div className={cx("content")}>
                    <div className={cx("info-label")}>이름</div>
                    <div className={cx("info-detail")}>{name}</div>
                    <div className={cx("info-label")}>연락처</div>
                    <div className={cx("info-detail")}>
                      {phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}
                    </div>
                    <div className={cx("info-label")}>이메일</div>
                    <div className={cx("info-detail")}>{email}</div>
                  </div>
                )}
                {!this.props.user && (
                  <div className={cx("content", "userinfoContainer")}>
                    <div className={cx("info-label")}>이름</div>
                    <div className={cx("info-detail")}>
                      <input
                        id="name_input"
                        type="text"
                        placeholder={"이름을 입력해주세요."}
                        className={cx("userInfo")}
                        value={name}
                        onChange={this.handleNameChange}
                      />
                    </div>
                    <div className={cx("info-label")}>연락처</div>
                    <div className={cx("info-detail")}>
                      {/*{user_phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}*/}
                      <input
                        id="phone_input"
                        type="text"
                        placeholder={"010-1234-5678"}
                        className={cx("userInfo")}
                        value={phone}
                        onChange={this.handlePhoneChange}
                      />
                    </div>
                    <div className={cx("info-label")}>이메일</div>
                    <div className={cx("info-detail")}>
                      <input
                        id="email_input"
                        type="text"
                        placeholder={"example@example.com"}
                        className={cx("userInfo")}
                        value={email}
                        onChange={this.handleEmailChange}
                      />
                    </div>
                  </div>
                )}
              </div>
              {maker && trackOptions && (
                <div>
                  <div className={cx("titletWrapper")}>
                    <label>옵션</label>
                  </div>
                  <div className={cx("content")}>
                    <Fragment>
                      <Radio.Group
                        onChange={this._setTrack}
                        value={this.state.track_id}
                      >
                        {trackOptions}
                      </Radio.Group>
                    </Fragment>
                  </div>
                </div>
              )}
              <div>
                <div className={cx("titletWrapper")}>
                  <label>결제 금액</label>
                </div>
                <div className={cx("content")}>
                  <div className={cx("featureContent")}>
                    {!isFree &&
                      !paymentInfo.subclass &&
                      !isMakers &&
                      !isEntry && (
                        <div className={cx("feature")}>
                          <img src={"/assets/icons/coin_small.png"} alt="" />
                          <span>
                            완주 시 {POINT_REIMBURSE} 포인트 추가 환급
                          </span>
                        </div>
                      )}
                  </div>
                  <table>
                    <tbody>
                      {paypal === false && (
                        <tr>
                          <td>정가</td>
                          <td
                            id="display-price"
                            style={
                              final_price !== display_price
                                ? {}
                                : { textDecoration: "initial" }
                            }
                            className={cx("display-price")}
                          >{`${this._numberWithCommas(display_price)}원`}</td>
                        </tr>
                      )}
                      {paypal && (
                        <tr>
                          <td>정가</td>
                          <td
                            id="display-price"
                            style={
                              final_price !== display_price
                                ? {}
                                : { textDecoration: "initial" }
                            }
                            className={cx("display-price")}
                          >{`${this._numberWithCommas(
                            Math.ceil((display_price / EXCHANGE_RATIO) * 100) /
                              100
                          )}달러`}</td>
                        </tr>
                      )}
                      {/*{paypal === false &&*/}
                      {/*<tr className={cx('discountContainer')}>*/}
                      {/*    <td id="price-period-title">{period_title === '정가' ? '할인' : period_title}</td>*/}
                      {/*    <td id="display-discount">{`- ${this._numberWithCommas(diff)}원`}</td>*/}
                      {/*</tr>*/}
                      {/*}*/}
                      {paypal === false &&
                        !paymentInfo.subclass &&
                        !isMakers &&
                        diff > 0 &&
                        !isEntry && (
                          <tr className={cx("discountContainer")}>
                            <td id="price-period-title">
                              {price_earlybird.title} 할인
                              <span>
                                <br />
                                {price_earlybird.seat_left}자리 남음,
                                {price_earlybird.order > 0
                                  ? `${price_earlybird.order}차는 ${price_earlybird.time} 만에 마감!`
                                  : `지금이 바로 최저가에요!`}
                              </span>
                            </td>
                            <td id="display-discount">{`- ${this._numberWithCommas(
                              diff
                            )}원`}</td>
                          </tr>
                        )}
                      {paypal &&
                        !paymentInfo.subclass &&
                        !isMakers &&
                        diff > 0 &&
                        !isEntry && (
                          <tr className={cx("discountContainer")}>
                            <td id="price-period-title">
                              {price_earlybird.title} 할인
                              <span>
                                <br />
                                {price_earlybird.seat_left}자리 남음,{" "}
                                {price_earlybird.order}차는{" "}
                                {price_earlybird.time} 만에 마감!
                              </span>
                            </td>
                            <td id="display-discount">{`- ${this._numberWithCommas(
                              Math.ceil((diff / EXCHANGE_RATIO) * 100) / 100
                            )}달러`}</td>
                          </tr>
                        )}
                      {paypal && isFriendReco && (
                        <tr className={cx("discountContainer")}>
                          <td
                            id="price-period-title"
                            className={cx("friend-reco")}
                          >
                            친구추천 할인 금액
                            <img
                              className={cx("right-now")}
                              src={"/assets/images/payment/right_now.svg"}
                              alt=""
                            />
                          </td>
                          <td
                            id="display-discount"
                            className={cx("friend-reco")}
                          >{`${this._numberWithCommas(
                            Math.ceil((50000 / EXCHANGE_RATIO) * 100) / 100
                          )}달러`}</td>
                        </tr>
                      )}
                      {paypal === false && isFriendReco && (
                        <tr className={cx("discountContainer")}>
                          <td
                            id="price-period-title"
                            className={cx("friend-reco")}
                          >
                            친구추천 할인 금액
                            <img
                              className={cx("right-now")}
                              src={"/assets/images/payment/right_now.svg"}
                              alt=""
                            />
                          </td>
                          <td
                            id="display-discount"
                            className={cx("friend-reco")}
                          >{`- ${this._numberWithCommas(50000)}원`}</td>
                        </tr>
                      )}
                      {paypal === false && gold > 0 && (
                        <tr
                          className={cx("couponContainer")}
                          id="discount-show"
                        >
                          <td>
                            포인트 할인 금액{" "}
                            {isCrmOn && (
                              <span className={cx("isCrmOn")}>
                                특별할인 오늘 만료!
                              </span>
                            )}
                          </td>
                          <td
                            id="display-coupon"
                            className={cx("coupon-highlight")}
                          >{`- ${this._numberWithCommas(gold)}원`}</td>
                        </tr>
                      )}
                      {paypal && gold > 0 && (
                        <tr
                          className={cx("couponContainer")}
                          id="discount-show"
                        >
                          <td>
                            포인트 할인 금액{" "}
                            {isCrmOn && (
                              <span className={cx("isCrmOn")}>
                                특별할인 오늘 만료!
                              </span>
                            )}
                          </td>
                          <td
                            id="display-coupon"
                            className={cx("coupon-highlight")}
                          >{`- ${this._numberWithCommas(
                            Math.ceil((gold / EXCHANGE_RATIO) * 100) / 100
                          )}달러`}</td>
                        </tr>
                      )}
                      {paypal && isFirstDiscount && (
                        <tr>
                          <td className={cx("highlight-text")}>첫 방문 할인</td>
                          <td
                            className={cx("highlight-text")}
                            id="price-with-reimburse"
                          >
                            {`${this._numberWithCommas(
                              Math.ceil((20000 / EXCHANGE_RATIO) * 100) / 100
                            )}달러`}
                          </td>
                        </tr>
                      )}
                      {paypal === false && isFirstDiscount && (
                        <tr>
                          <td className={cx("highlight-text")}>첫 방문 할인</td>
                          <td
                            className={cx("highlight-text")}
                            id="price-with-reimburse"
                          >
                            {`- ${this._numberWithCommas(20000)}원`}
                          </td>
                        </tr>
                      )}
                      {paypal === false &&
                        todaydiscount &&
                        todaydiscount.is_activated && (
                          <tr
                            id="todaydiscountContainer"
                            className={cx("todaydiscountContainer")}
                          >
                            <td id="todaydiscount-title">
                              오늘의 한정 할인({todaydiscount.total_amount}자리)
                            </td>
                            <td id="todaydiscount-discount">
                              - {this._numberWithCommas(todaydiscount_discount)}
                              원
                            </td>
                          </tr>
                        )}
                      {paypal === false && coupon_applied && (
                        <tr
                          className={cx("couponContainer")}
                          id="discount-show"
                        >
                          <td>쿠폰 할인 금액</td>
                          <td
                            id="display-coupon"
                            className={cx("coupon-highlight")}
                          >{`- ${this._numberWithCommas(
                            coupon_discount
                          )}원`}</td>
                        </tr>
                      )}
                      {paypal && coupon_applied && (
                        <tr
                          className={cx("couponContainer")}
                          id="discount-show"
                        >
                          <td>쿠폰 할인 금액</td>
                          <td
                            id="display-coupon"
                            className={cx("coupon-highlight")}
                          >{`- ${this._numberWithCommas(
                            Math.ceil(
                              (coupon_discount / EXCHANGE_RATIO) * 100
                            ) / 100
                          )}달러`}</td>
                        </tr>
                      )}
                      {/*{hackle_var === 'B' && pythonTarget &&*/}
                      {/*<tr className={cx('python-price')}>*/}
                      {/*    <td>*/}
                      {/*        <img src={('/assets/icons/ic_gift.svg')} alt=""/>*/}
                      {/*        <span>파이썬 문법 101</span>*/}
                      {/*    </td>*/}
                      {/*    <td className={cx('price')}>*/}
                      {/*        <span>{`${this._numberWithCommas(66000)}원`}</span>*/}
                      {/*        <span>0원</span>*/}
                      {/*    </td>*/}
                      {/*</tr>*/}
                      {/*}*/}
                      {!isEntry && (
                        <tr className={cx("divideContainer")}>
                          <td>
                            {paymentInfo.subclass || isMakers ? "" : ""} 6개월
                            할부액
                          </td>
                          {paypal === false && !paymentInfo.subclass && (
                            <td
                              id="divided-price"
                              className={cx("dividedPrice")}
                            >
                              {`월 ${this._numberWithCommas(
                                parseInt((final_price - reimburse) / 6)
                              )}원`}
                            </td>
                          )}
                          {paypal === false &&
                            paymentInfo.subclass &&
                            !isMakers && (
                              <td
                                id="divided-price"
                                className={cx("dividedPrice")}
                              >
                                {`월 ${this._numberWithCommas(
                                  parseInt(final_price / 6)
                                )}원`}
                              </td>
                            )}
                          {paypal && !paymentInfo.subclass && !isMakers && (
                            <td
                              id="divided-price"
                              className={cx("dividedPrice")}
                            >
                              {`월 ${this._numberWithCommas(
                                Math.ceil((final_price - reimburse) / 100 / 6)
                              )}달러`}
                            </td>
                          )}
                          {paypal && !paymentInfo.subclass && isMakers && (
                            <td
                              id="divided-price"
                              className={cx("dividedPrice")}
                            >
                              {`월 ${this._numberWithCommas(
                                Math.ceil(final_price / 100 / 6)
                              )}달러`}
                            </td>
                          )}
                        </tr>
                      )}
                      {paypal === false && (
                        <tr className={cx("finalContainer")}>
                          <td className={cx("final")}>
                            결제 가격
                            {!isFree && !isEntry && (
                              <span
                                className={cx("installment")}
                                onClick={() => this.setInstallment()}
                              >
                                무이자 할부 가능
                              </span>
                            )}
                            {installmentShow && (
                              <div
                                className={cx("installment_info")}
                                onClick={() => this.setInstallment()}
                              >
                                <img
                                  src={
                                    "/assets/images/payment/installment_info.png"
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            className={cx("final")}
                            id="price-discount-before"
                          >
                            {this._numberWithCommas(final_price)}원
                            {paymentInfo.subclass && "/8주"}
                          </td>
                        </tr>
                      )}
                      {paypal && (
                        <tr className={cx("")}>
                          <td className={cx("final")}>
                            결제 가격{" "}
                            {track_id ? `(+ ${this._getTrackTitle()})` : ""}
                          </td>
                          <td
                            className={cx("final")}
                            id="price-discount-before"
                          >
                            {final_price / 100}달러
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!isFree && !isEntry && (
                    <Fragment>
                      <table>
                        <tbody>
                          <tr>
                            <td className={cx("promo-label")}>할인 코드</td>
                            <td className={cx("promo-input")}>
                              <div className={cx("inputContainer", "coupon")}>
                                <div className={cx("coupon-select")}>
                                  <div
                                    className={cx("select-placeholder")}
                                    onClick={() => {
                                      this.showSelects();
                                    }}
                                  >
                                    <div className={cx("select-text")}>
                                      {coupon_title}
                                    </div>
                                  </div>
                                  <div
                                    className={cx("arrow-box")}
                                    onClick={() => {
                                      this.showSelects();
                                    }}
                                  >
                                    <img
                                      className={cx("ic-arrow-down")}
                                      src={"/assets/icons/ic_arrow_down.svg"}
                                      alt="ic-downarrow"
                                    />
                                  </div>
                                  {showSelect && (
                                    <div className={cx("retro-courses")}>
                                      <div
                                        key={0}
                                        className={cx("retro-course")}
                                        onClick={() => {
                                          this.setCoupon(
                                            "",
                                            "쿠폰을 선택하세요."
                                          );
                                        }}
                                      >
                                        (선택 안함)
                                      </div>
                                      {couponInfo.data.coupons &&
                                        couponInfo.data.coupons.map(
                                          (coupon, idx) => {
                                            const coupon_id = coupon._id;
                                            const title = coupon.title;
                                            const benefit_detail =
                                              coupon.benefit_detail;
                                            const benefit_type =
                                              coupon.benefit_type === "percent"
                                                ? "%"
                                                : "원";
                                            return (
                                              <div
                                                key={idx + 1}
                                                className={cx("retro-course")}
                                                onClick={() => {
                                                  this.setCoupon(
                                                    coupon_id,
                                                    title
                                                  );
                                                }}
                                              >
                                                {title} /{" "}
                                                <span>
                                                  {benefit_detail}
                                                  {benefit_type}{" "}
                                                  {coupon.benefit_type ===
                                                  "target"
                                                    ? "'으로'"
                                                    : ""}{" "}
                                                  할인
                                                </span>{" "}
                                                / {coupon.expired_at}까지
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Fragment>
                  )}
                  {this.props.user &&
                    pointInfo.loaded &&
                    pointInfo.data.point > 0 &&
                    !isFree &&
                    !isEntry && (
                      <Fragment>
                        <table>
                          <tbody>
                            <tr>
                              <td className={cx("promo-label")}>포인트 결제</td>
                              <td className={cx("promo-input")}>
                                <div className={cx("inputContainer")}>
                                  <input
                                    className={cx("myInput")}
                                    type="text"
                                    id="gold"
                                    onChange={this._setGold}
                                    onBlur={this._checkGold}
                                    // onFocusOut={this._checkGold}
                                    placeholder="0"
                                  />
                                  <a
                                    className={cx("inputImg")}
                                    href="#"
                                    onClick={this._applyGold}
                                  >
                                    전액사용
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tbody>
                            <tr>
                              <td className={cx("promo-basic")}>
                                내 잔여 포인트:{" "}
                                {pointInfo.loaded
                                  ? this._numberWithCommas(pointInfo.data.point)
                                  : 0}
                                p
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Fragment>
                    )}
                </div>
              </div>

              <div>
                {!isFree && (
                  <Fragment>
                    <div className={cx("titletWrapper")}>
                      <label>결제 방식</label>
                    </div>
                    <div className={cx("content")}>
                      <Row
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {isForeign && (
                          <Fragment>
                            <Col span={8}>
                              <Checkbox
                                onClick={this._setMethod}
                                checked={this.state.paypal}
                                id="paypal"
                                value="paypal"
                              >
                                페이팔
                              </Checkbox>
                            </Col>
                            <Col span={8}>
                              <Checkbox
                                onClick={this._setMethod}
                                checked={this.state.card}
                                id="payment_card"
                                value="CARD"
                              >
                                신용카드
                              </Checkbox>
                            </Col>
                            <Col span={8}>
                              <Checkbox
                                onClick={this._setMethod}
                                checked={this.state.kakaopay}
                                id="payment_kakaopay"
                                value="kakaopay"
                              >
                                카카오페이
                              </Checkbox>
                            </Col>
                          </Fragment>
                        )}
                        {!isForeign && (
                          <Fragment>
                            <Col span={12}>
                              <Checkbox
                                onClick={this._setMethod}
                                checked={this.state.card}
                                id="payment_card"
                                value="CARD"
                              >
                                신용카드
                              </Checkbox>
                            </Col>
                            <Col span={12}>
                              <Checkbox
                                onClick={this._setMethod}
                                checked={this.state.kakaopay}
                                id="payment_kakaopay"
                                value="kakaopay"
                              >
                                카카오페이
                              </Checkbox>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                    </div>
                  </Fragment>
                )}
                <div className={cx("bottomForm")}>
                  <div className={cx("privacy-form")}>
                    <a
                      className={cx("privacy")}
                      href="https://www.notion.so/spartacodingclub/cb0c63c1145f4b60aacf0692a888e6fd"
                      target="_blank"
                    >
                      개인정보수집,
                    </a>
                    <a
                      className={cx("privacy")}
                      href="https://www.nicepay.co.kr/cs/terms/policy1.do"
                      target="_blank"
                    >
                      전자금융거래 기본약관,
                    </a>
                    <br></br>
                    <a
                      className={cx("privacy")}
                      href="https://www.nicepay.co.kr/cs/terms/private.do"
                      target="_blank"
                    >
                      개인정보처리방침에 동의하고,
                    </a>
                    <br></br>
                  </div>
                  {isPaying === false && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={this._startPayment}
                    >
                      {isFree ? "무료 신청" : "결제하기"}
                    </Button>
                  )}
                  {isPaying && (
                    <Button
                      className={cx("paying")}
                      type="primary"
                      htmlType="submit"
                    >
                      {isFree ? "등록하는 중" : "결제하는 중"}
                    </Button>
                  )}

                  {/*{todaydiscount && todaydiscount.is_activated &&*/}
                  {/*<div className={cx('todaydiscount-tooltip')}>*/}
                  {/*    {`지금, ${name}님을 위한 한 자리를 가져가세요.👆`}*/}
                  {/*</div>*/}
                  {/*}*/}

                  {/*<div className={cx('todaydiscount-tooltip')}>*/}
                  {/*    {todaydiscount.total_amount}*/}
                  {/*    {`👆`}*/}
                  {/*</div>*/}
                </div>
              </div>
              <form
                name="payForm"
                method="post"
                action={`${config.apiUrl}/v1/payment/authReq`}
              >
                <input
                  type="hidden"
                  id="name"
                  name="BuyerName"
                  defaultValue={name}
                />
                <input
                  type="hidden"
                  id="phone"
                  name="BuyerTel"
                  defaultValue={phone}
                />
                <input
                  type="hidden"
                  id="email"
                  name="BuyerEmail"
                  defaultValue={email}
                />
                <input
                  type="hidden"
                  id="GoodsName"
                  name="GoodsName"
                  defaultValue={title}
                />
                <input
                  type="hidden"
                  id="price"
                  name="Amt"
                  value={final_price}
                  ref={this.priceRef}
                />
                <input
                  type="hidden"
                  id="course_id"
                  name="course_id"
                  defaultValue={this.state.cid}
                />
                <input
                  type="hidden"
                  id="PayMethod"
                  name="PayMethod"
                  value="CARD"
                />
                <input
                  type="hidden"
                  id="moid"
                  name="Moid"
                  value={this.state.moid}
                />
                <input
                  type="hidden"
                  id="ReturnURL"
                  name="ReturnURL"
                  value={this.state.ReturnURL}
                />
                <input
                  type="hidden"
                  id="MID"
                  name="MID"
                  value={this.state.MID}
                />
                <input type="hidden" name="VbankExpDate" defaultValue="" />
                <input type="hidden" name="NpLang" defaultValue="KO" />
                <input type="hidden" name="GoodsCl" defaultValue="1" />
                <input type="hidden" name="TransType" defaultValue="0" />
                <input type="hidden" name="CharSet" defaultValue="utf-8" />
                <input type="hidden" name="ReqReserved" defaultValue="" />
                <input
                  type="hidden"
                  id="EdiDate"
                  name="EdiDate"
                  value={this.state.EdiDate}
                />
                <input
                  type="hidden"
                  id="SignData"
                  name="SignData"
                  value={this.state.EncryptData}
                />
                <input
                  id="NicepayReserved"
                  type="hidden"
                  name="NicepayReserved"
                  value={this.state.NicepayReserved}
                />
                <input
                  id="DirectShowOpt"
                  type="hidden"
                  name="DirectShowOpt"
                  value={this.state.DirectShowOpt}
                />
              </form>

              <form
                name="payForm_paypal"
                method="post"
                style={{ display: "none" }}
                action="https://webapi.nicepay.co.kr/webapi/paypal/order.jsp"
              >
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="MID"
                  value={this.state.MID}
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="TID"
                  value={this.state.TID}
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="Moid"
                  value={this.state.moid}
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="EdiDate"
                  value={this.state.EdiDate}
                />
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="Amt"
                  value={final_price}
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="GoodsName"
                  defaultValue={title}
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="Quantity"
                  value="1"
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="BuyerName"
                  value={this.state.name}
                />
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="BuyerEmail"
                  value={this.state.email}
                />
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="ReturnURL"
                  value={this.state.ReturnURL}
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="CharSet"
                  value="euc-kr"
                />{" "}
                <br />
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="Currency"
                  value="USD"
                />{" "}
                <br />
                En:{" "}
                <input
                  type="hidden"
                  style={{ width: 550 }}
                  name="SignData"
                  value={this.state.EncryptData}
                />{" "}
                <br />
              </form>
            </div>
          </div>
        </div>
        <FooterV2 />
        <Modal
          title={alert_msg_title}
          visible={modalVisible}
          closable={true}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => this.setState({ modalVisible: false })}
            >
              확인
            </Button>,
          ]}
        >
          <p>{alert_msg}</p>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    authentication,
    todaydiscount,
    paymentInfo,
    priceByNumber,
    pointInfo,
    couponInfo,
    logCrmAB,
  } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    todaydiscount,
    paymentInfo,
    priceByNumber,
    pointInfo,
    couponInfo,
    logCrmAB,
  };
}

const connectedPaymentPage = connect(mapStateToProps, {
  loginWithToken: userActions.loginWithToken,
  setPaymentType: paymentActions.setPaymentType,
  getTodaydiscount: todaydiscountActions.getTodaydiscount,
  getPaymentInfo: paymentActions.getPaymentInfo,
  getPriceByNumber: priceByNumberActions.getPriceByNumber,
  getPointInfo: pointActions.getPointInfo,
  getCouponInfo: couponActions.getCouponInfo,
  refreshInfo: userActions.refreshInfo,
  getLogCrmAB: logActions.getLogCrmAB,
})(PaymentPage);
export { connectedPaymentPage as PaymentPage };
