import { PasswordItem } from "./PasswordItem";
import { PhoneItem } from "./PhoneItem";
import { NameItem } from "./NameItem";
import { BtnItem } from "./BtnItem";
import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { params } from "../../../../_helpers";
import { connect } from "react-redux";
import { userActions } from "../../../../_actions";
import { BirthItem } from "./BirthItem";
import { GenderItem } from "./GenderItem";
import { AgreementItem } from "./AgreementItem";
import { EmailConfirmationItem } from "./EmailConfirmationItem";
import "../../../../Pages/LoginPage/LoginPage.antdesgin.custom.css";
import { getOriginalAge } from "../../../_helper";
import { Modal } from "../Modal/Modal";
import { sendLog } from "../../../businesslogics/logging";

const SignupForm = ({
  cx,
  setState,
  userInfo,
  validateMessages,
  isLoading,
  isKkoSignup,
  errorMsg,
  serviceAgreement,
  privacyAgreement,
  marketingAgreement,
  buttonText,
  isHanium,
  _userTracking,
  signup,
  nextUrl,
}) => {
  const { next } = params();
  const isGovIntro = next?.includes("6115cea3e73913245c9538e0");
  const [track, setTrack] = useState("");
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const ref = useRef();

  const clearPhoneNumSeperator = (phone) => {
    const country_code = phone.split("-")[0];
    const phone_num = phone.split("-").slice(1).join("");
    return [country_code, phone_num];
  };

  const handleSignUpSubmit = async ({
    email,
    password,
    name,
    phone,
    country_code,
  }) => {
    _userTracking();
    setState({ isLoading: true });
    const { next, coupon_code, round_id, kko_id } = params();
    let nexturltext = nextUrlText();
    password = password || "welcomeSparta";
    email = email || userInfo.email;
    phone = phone || userInfo.phone;
    country_code = country_code || userInfo.country_code;
    name = name || userInfo.name;
    const gender = userInfo.gender;
    const birthYear = userInfo.birthYear;
    const birthDay = `${userInfo.birthMonth}${userInfo.birthDate}`;

    if (!isKkoSignup) {
      if (birthYear.length < 4 || birthDay.length < 4) {
        alert("생년월일 정보를 입력해주세요.");
        setState({ isLoading: false });
        return;
      }

      if (!isEmailConfirmed) {
        alert("이메일 인증을 완료해주세요.");
        setState({ isLoading: false });
        return;
      }
      if (
        getOriginalAge(
          userInfo.birthYear,
          userInfo.birthMonth,
          userInfo.birthDate
        ) < 14
      ) {
        setState({ isLoading: false });
        alert("만 14세 미만 회원은 가입하실 수 없습니다.");
        return;
      }
      if (!(privacyAgreement && serviceAgreement)) {
        setState({ isLoading: false });
        alert("약관에 동의해주세요.");
        return;
      }
    }

    if (phone && phone.includes("+")) {
      [country_code, phone] = clearPhoneNumSeperator(phone);
    } else if (!country_code) {
      country_code = "+82";
    }

    signup({
      name,
      email,
      password,
      phone,
      country_code,
      next,
      coupon_code,
      round_id,
      gender,
      birthYear,
      birthDay,
      marketing_agreement: marketingAgreement,
      kko_id,
      track,
      nexturltext,
    });
    return;
  };

  const onFinishFailed = (errorInfo) => {
    alert("필수 항목을 모두 입력해주세요.");
    console.log("Failed:", errorInfo);
  };

  const nextUrlText = () => {
    if (nextUrl.swc) {
      return "swc";
    } else if (nextUrl.nbc) {
      return "nbc";
    } else if (nextUrl.hanghae) {
      return "hanghae";
    } else if (nextUrl.chang) {
      return "chang";
    } else if (nextUrl.ddingdong) {
      return "ddingdong";
    } else if (nextUrl.intellipick) {
      return "intellipick";
    } else if (nextUrl.hyoneung) {
      return "hyoneung";
    } else return "";
  };

  function sendSignupPageView() {
    let serviceName = nextUrlText();
    if (next.includes("hyoneung")) {
      sendLog("scc_event_hyoneung_signup_view", {});
      return;
    }
    if (serviceName === "") {
      sendLog("scc_signupPage_view", {});
      return;
    }
    sendLog(`${serviceName}_signup_view`, {});
  }

  useEffect(() => {
    sendSignupPageView();
  }, []);

  return (
    <Form
      name="login"
      onFinish={handleSignUpSubmit}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
      ref={ref}
    >
      {/*{isOtherServiceSignup ? null : <WelcomeBenefit cx={cx} />}*/}
      {!isKkoSignup && (
        <>
          {/*<EmailItem*/}
          {/*  cx={cx}*/}
          {/*  className={cx("form-item")}*/}
          {/*  onChange={() => setState({ checkedAccountExist: false })}*/}
          {/*  email={userInfo.email}*/}
          {/*/>*/}
          <EmailConfirmationItem
            nextUrl={nextUrlText()}
            email={userInfo.email}
            isEmailConfirmed={isEmailConfirmed}
            setIsEmailConfirmed={setIsEmailConfirmed}
            setState={setState}
            onChange={() => setState({ checkedAccountExist: false })}
            cx={cx}
          />
          <PasswordItem
            cx={cx("form-item")}
            messages={validateMessages}
            unsetErrorMsg={() => setState({ errorMsg: undefined })}
            inputEmail={
              ref.current ? ref.current.getFieldValue("email") : userInfo.email
            }
          />
          <NameItem
            cx={cx}
            className={cx("form-item")}
            isGovIntro={isGovIntro}
            isHanghae={nextUrl.hanghae}
            isSwc={nextUrl.swc}
          />
          <PhoneItem
            cx={cx}
            messages={validateMessages}
            isEmailSignUp={true}
            _ref={ref}
            isHanium={isHanium}
            unsetErrorMsg={() => setState({ errorMsg: undefined })}
          />
          <BirthItem
            nextUrl={nextUrlText()}
            birthYear={userInfo.birthYear}
            birthMonth={userInfo.birthMonth}
            birthDate={userInfo.birthDate}
            setState={setState}
          />
          <GenderItem
            gender={userInfo.gender}
            setState={setState}
            nextUrl={nextUrlText()}
          />
          <AgreementItem
            setState={setState}
            serviceAgreement={serviceAgreement}
            privacyAgreement={privacyAgreement}
            marketingAgreement={marketingAgreement}
            nextUrl={nextUrlText()}
          />
        </>
      )}
      {errorMsg && <p className={cx("errorMsg")}>{errorMsg}</p>}
      <BtnItem
        buttonText={isLoading ? "진행 중입니다" : buttonText}
        nextUrl={nextUrlText()}
      />
      {modalInfo && (
        <Modal
          isBackgroundClickable={false}
          visible={true}
          handleVisible={() => {}}
          style={{}}
          header={modalInfo.header}
          img_path={modalInfo.img_path}
          content={modalInfo.content}
          cta={modalInfo.cta}
        />
      )}
    </Form>
  );
};

const connectedSignupForm = connect(() => ({}), {
  signup: userActions.signup,
})(SignupForm);

export { connectedSignupForm as SignupForm };
