import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./UserRaceInfoCard.modules.scss";

const cx = classNames.bind(styles);

function UserRaceInfoCard({course_title, user_name, at_week, week_done, is_finished}){
    return(
        <div className={cx('UserRaceInfoWrapper')}>
            <div className={cx('UserRaceInfoCourse')}>{user_name} | {is_finished ? `완강` : `${at_week}주차 ${week_done+1}강`}</div>
            <div className={cx('UserRaceInfoUser')}>{course_title}</div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const connectedUserRaceInfoCard = connect(mapStateToProps, {
})(UserRaceInfoCard);
export {connectedUserRaceInfoCard as UserRaceInfoCard}