export const GET_STUDY_OBJECTIVE = {
  GET_STUDY_OBJECTIVE_REQUEST: "GET_STUDY_OBJECTIVE_REQUEST",
  GET_STUDY_OBJECTIVE_SUCCESS: "GET_STUDY_OBJECTIVE_SUCCESS",
  GET_STUDY_OBJECTIVE_FAILURE: "GET_STUDY_OBJECTIVE_FAILURE",
};

export const UPDATE_STUDY_OBJECTIVE = {
  UPDATE_STUDY_OBJECTIVE_REQUEST: "UPDATE_STUDY_OBJECTIVE_REQUEST",
  UPDATE_STUDY_OBJECTIVE_SUCCESS: "UPDATE_STUDY_OBJECTIVE_SUCCESS",
  UPDATE_STUDY_OBJECTIVE_FAILURE: "UPDATE_STUDY_OBJECTIVE_FAILURE",
};

export const POST_STUDY_OBJECTIVE = {
  POST_STUDY_OBJECTIVE_REQUEST: "POST_STUDY_OBJECTIVE_REQUEST",
  POST_STUDY_OBJECTIVE_SUCCESS: "POST_STUDY_OBJECTIVE_SUCCESS",
  POST_STUDY_OBJECTIVE_FAILURE: "POST_STUDY_OBJECTIVE_FAILURE",
};
