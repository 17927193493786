import styled from "styled-components";
import { Device } from "../../../../_styles/device";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
`;

export const ContentTitle = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #141617;

  ${Device.Desktop} {
    font-size: 20px;
  }
`;

export const ContentDetail = styled.div`
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #5f666b;

  div {
    height: 12px;
  }

  ${Device.Desktop} {
    font-size: 16px;
  }
`;

export const ContentTail = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #9da7ae;
`;
