import { RCMD_COURSE_GENERAL, RCMD_FREE_COURSE } from "../constants";

const initState = {
  loaded: false,
};

export function rcmdFree(state = initState, action) {
  switch (action.type) {
    case RCMD_FREE_COURSE.RCMD_FREE_COURSE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case RCMD_FREE_COURSE.RCMD_FREE_COURSE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case RCMD_FREE_COURSE.RCMD_FREE_COURSE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function rcmdCourses(state = initState, action) {
  switch (action.type) {
    case RCMD_COURSE_GENERAL.RCMD_COURSE_GENERAL_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case RCMD_COURSE_GENERAL.RCMD_COURSE_GENERAL_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case RCMD_COURSE_GENERAL.RCMD_COURSE_GENERAL_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
