import styled from "@emotion/styled";
import {
  wBody2,
  wBody4,
  wTitle2,
  wTitle4,
  mTitle2,
  mBody4,
} from "@teamsparta/design-system";

export const ErrorReportSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorReportSelectorTitle = styled.div`
  ${wTitle2};
  margin-bottom: 40px;
  margin-top: 20px;
`;

export const ErrorReportSelectorContentContainer = styled.div`
  display: flex;
  padding: ${({ isMobile }) => (isMobile ? "20px 20px" : "20px 40px")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-30-day, #d7e0e6);
  background: #fff;

  width: ${({ isMobile }) => (isMobile ? "100%" : "400px")};
  height: ${({ isMobile }) => (isMobile ? "87px" : "90px")};
  &:hover {
    border-color: var(--sparta-sparta-red-100, #e8344e);
  }
`;

export const ErrorReportSelectorContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ErrorReportSelectorContentTitle = styled.div`
  ${(props) => (props.isMobile ? mTitle2 : wTitle4)};
  ${wTitle4};
`;

export const ErrorReportSelectorContentText = styled.div`
  ${(props) => (props.isMobile ? mBody4 : wBody4)};
  ${wBody4};
  color: var(--gray-gray-70-day, #81898f);
`;

export const ContentsErrorSubmissionNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const ContentsErrorSubmissionNotificationImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  margin-top: 20px;
  align-self: center;
`;

export const ContentsErrorSubmissionNotificationTitle = styled.div`
  ${wTitle2};
  text-align: center;
  margin-bottom: 12px;
  color: var(--gray-gray-100-day, #141617);
`;

export const ContentsErrorSubmissionNotificationText = styled.div`
  ${wBody2};
  color: var(--gray-gray-80-day, #5f666b);
  text-align: center;
`;
