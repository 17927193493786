import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PLAYERTYPE } from "../LecturePage/LecPlayer/LecPlayerV2/lecPlayerV2.model";
import { sendLog } from "../../businesslogics/logging";

export const useSelectPlayerType = (
  setPlayerType,
  setHasSpeedLimit,
  setIsCanSeek
) => {
  const course = useSelector((state) => state.course);
  const featureFlags = useSelector((state) => state.featureFlags);
  const VIDEO_FEATURE_KEY = 2;
  /* Player Type을 결정 */
  useEffect(() => {
    if (!course.loaded) {
      return;
    }

    if (!course.data.has_speed_limit) {
      setHasSpeedLimit(false);
    }

    if (course.data?.is_b2g2b) {
      setIsCanSeek(false);
    }

    if (featureFlags.loaded) {
      if (
        course.data.player_type == PLAYERTYPE.V2 &&
        featureFlags.data.flags[VIDEO_FEATURE_KEY]
      ) {
        setPlayerType(PLAYERTYPE.V2);
      } else {
        setPlayerType(PLAYERTYPE.V1);
      }
    }
  }, [course.loaded, featureFlags.loaded]);
};

export const usePlayerPercentageLog = (viewFlag, viewPoint) => {
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const eventName =
    viewPoint === "half"
      ? "scc_lecturePage_play_video_50%"
      : "scc_lecturePage_play_video_100%";
  useEffect(() => {
    if (viewFlag) {
      sendLog(eventName, { ...enrolled.data, ...enrolledDetail.data }, "");
    }
  }, [viewFlag]);
};
