import styled from "styled-components";
import { Device } from "../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 50px 0 45px;
  background-image: url("/assets/images/finish/bg_pirates_mo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    background-image: url("/assets/images/finish/bg_pirates_web.png");
    padding: 90px 0 80px;
    @media (min-width: 1392px) {
      background-size: contain;
      background-color: black;
    }
  }
`;

export const TitleSubText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #e4ebf0;
  margin-bottom: 4px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #e4ebf0;
  margin-bottom: 20px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    font-size: 40px;
  }
`;

export const Desc = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #c7d2d8;
  margin-bottom: ${({ isMarginLong }) => (isMarginLong ? "48px" : "20px")};
  font-family: Pretendard;
  white-space: pre-wrap;

  ${Device.WiderThanLargeMobile} {
    font-size: 16px;
    margin-bottom: ${({ isMarginLong }) => (isMarginLong ? "60px" : "20px")};
    white-space: normal;
  }
`;

export const Tags = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 28px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    margin-bottom: 60px;
  }
`;

export const Tag = styled.div`
  width: fit-content;
  margin: auto;
  padding: 0 14px;
  height: 28px;
  background: #3a3e41;
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #e4ebf0;
  display: flex;
  align-items: center;
  font-family: Pretendard;
  margin-bottom: 20px;

  ${Device.WiderThanLargeMobile} {
    height: 30px;
    font-size: 13px;
    margin-bottom: 16px;
  }
`;

// new
export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${Device.WiderThanLargeMobile} {
    flex-direction: row;
    gap: 17px;
  }
`;

export const DarkCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 20px;
  width: 328px;
  height: 413px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  margin-bottom: 20px;
  font-family: Pretendard;
  justify-content: space-between;

  ${Device.WiderThanLargeMobile} {
    width: 380px;
    height: 428px;
    padding: 36px 0;
    margin-bottom: 60px;
  }
`;

export const CardHeader = styled.div``;

export const CircleLogo = styled.img`
  width: 102px;
  height: 119px;
  margin-bottom: 60px;
`;

export const FlatLogo = styled.img`
  width: 174px;
  height: 37.34px;
  font-family: Pretendard;
`;

export const Thumbnail = styled.img`
  width: 274px;
  font-family: Pretendard;
  border-radius: 6.725px;

  ${Device.WiderThanLargeMobile} {
  }
`;

export const CouponTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #e4ebf0;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    font-size: 24px;
  }
`;

export const CouponNotice = styled.div`
  display: contents;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #b4bfc6;
  margin-top: -4px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    margin-top: -12px;
  }
`;
// end new

// old
export const DescV1 = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #c7d2d8;
  margin-bottom: 20px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    font-size: 16px;
  }
`;
export const TagV1 = styled.div`
  padding: 0 14px;
  height: 28px;
  background: #3a3e41;
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #e4ebf0;
  display: flex;
  align-items: center;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    height: 30px;
    font-size: 13px;
  }
`;
export const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 20px;
  width: 328px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  margin-bottom: 20px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    width: 486px;
    padding: 36px 0;
    gap: 28px;
    margin-bottom: 60px;
  }
`;
export const Coupon = styled.img`
  width: 274px;
  height: 120px;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    width: 274px;
    height: 120px;
  }
`;

export const CouponTitleV1 = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #e4ebf0;
  font-family: Pretendard;

  ${Device.WiderThanLargeMobile} {
    font-size: 16px;
  }
`;
// end old

export const Cta = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 312px;
  height: 56px;
  background: #e8344e;
  border-radius: 8px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
`;
