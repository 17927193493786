import styled from "styled-components";
import { zIndexGnb } from "../../../../styles/variables";

export const Wrapper = styled.div`
  @keyframes SlideIn {
    0% {
      top: -52px;
      opacity: 1;
      z-index: ${zIndexGnb + 1};
    }
    10%,
    95% {
      top: 24px;
      opacity: 1;
      z-index: ${zIndexGnb + 1};
    }
    100% {
      top: 24px;
      opacity: 0;
      z-index: ${zIndexGnb + 1};
    }
  }

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: SlideIn 5s ease-in-out;
  top: -52px;
  z-index: -1;
`;

export const Container = styled.div`
  display: flex;
  background: #000000cc;
  backdrop-filter: blur(5px);
  border-radius: 8px;
  width: calc(100vw - 40px);
  min-width: 320px;
  height: 52px;
  font-family: "Pretendard";
  font-size: 16px;
  line-height: 150%;
`;

export const Text = styled.div`
  width: 61.5625%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #ffffff;
`;

export const Cta = styled.a`
  width: 38.4375%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #ff9ea9;
`;
