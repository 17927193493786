import React from "react";
import * as S from "./MobileOverlay.style";
import {
  useIsControlBarActive,
  useIsPlaying,
  useIsSettingBottomSheetOpen,
} from "../../stores/playerAtom";
import { usePlayerCoreControl } from "../../hooks/usePlayerCoreControl";
import { usePlaybackTimeControl } from "../../hooks/usePlaybackTimeControl";

export const MobileOverlay = React.memo(
  ({ videoRef, playerType, lecture_v2 }) => {
    const [isPlaying] = useIsPlaying(false);
    const [isControlBarActive] = useIsControlBarActive(false);
    const [isSettingBottomSheetOpen, setIsSettingBottomSheetOpen] =
      useIsSettingBottomSheetOpen(false);
    const { playButtonSrc, togglePlay } = usePlayerCoreControl({
      videoRef,
      lecture_v2,
      playerType,
    });

    const { moveBackward, moveForward } = usePlaybackTimeControl(videoRef);

    return (
      <>
        {!isSettingBottomSheetOpen && (
          <S.Overlay show={!isPlaying || isControlBarActive}>
            <S.SeekBackButtonWrapper onClick={moveBackward}>
              <S.SeekButton src={"/assets/seekbackbtn.svg"} />
            </S.SeekBackButtonWrapper>
            <S.PlayButtonWrapper onClick={togglePlay}>
              <S.PlayButton isPlaying={isPlaying} src={playButtonSrc} />
            </S.PlayButtonWrapper>
            <S.SeekFrontButtonWrapper onClick={moveForward}>
              <S.SeekButton src={"/assets/seekforwardbtn.svg"} />
            </S.SeekFrontButtonWrapper>
            <S.SettingButtonWrapper
              onClick={() =>
                setIsSettingBottomSheetOpen(!isSettingBottomSheetOpen)
              }
            >
              <S.SettingButton src={"/assets/msetting.svg"} />
            </S.SettingButtonWrapper>
          </S.Overlay>
        )}
      </>
    );
  }
);
