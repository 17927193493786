import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./BubblingHeart.module.scss";
import { connect } from "react-redux";
const cx = classNames.bind(styles);

function BubblingHeart({ count }) {
  useEffect(() => {}, []);
  const heart = "/assets/icons/ic_heart.svg";
  const hearts = new Array(count).fill(heart);
  return (
    <div id="bubblingHeartWrap" className={cx("bubblingHeartWrap")}>
      {hearts.map((heart, idx) => {
        return <img className={cx(`heart${idx}`)} src={heart} />;
      })}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedBubblingHeart = connect(mapStateToProps, {})(BubblingHeart);
export { connectedBubblingHeart as BubblingHeart };
