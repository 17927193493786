import privateInstance from "../../_helper/axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const getMyHomeworkDetails = async (enrolled_homework_id) => {
  const response = await privateInstance.get(
    `/homeworks/${enrolled_homework_id}`
  );
  return response.data;
};

export const useHomeworksDetails = (enrolled_homework_id) => {
  return useQuery(
    ["homeworks", enrolled_homework_id],
    () => {
      return getMyHomeworkDetails(enrolled_homework_id);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!enrolled_homework_id,
    }
  );
};

const updateHomeworkDetails = async ({ enrolled_homework_id, updatedData }) => {
  const response = await privateInstance.post(
    `/homeworks/${enrolled_homework_id}`,
    updatedData
  );
  return response.data;
};

export const useMutationHomeworkDetails = (enrolled_homework_id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateHomeworkDetails, {
    onMutate: async ({ enrolled_homework_id, updatedData }) => {
      queryClient.setQueryData(
        ["homeworks", enrolled_homework_id],
        (prevData) => {
          // 이전 데이터를 업데이트합니다.
          return { ...prevData, ...updatedData };
        }
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["homeworks", enrolled_homework_id]);
    },
    onError: (error) => {
      console.debug("👎 [put homeworks] ", error);
    },
  });

  return mutation;
};
export const getMyFeedbackHomeworks = async (userId) => {
  return await privateInstance
    .get(`/homeworks/feedback/${userId}`)
    .then((resp) => resp.data);
};

export const useFeedbackHomeworks = (userId) => {
  return useQuery(
    ["homeworks", userId],
    () => {
      return getMyFeedbackHomeworks(userId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
    }
  );
};
