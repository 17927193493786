import { EXPIRATION_POINTS, POINTS, userConstants } from "../constants";
import { usersService } from "../services";
import { OPEN_RANDOMBOX } from "../constants/users.constant";
import { history } from "../../../_helpers";
import * as amplitude from "@amplitude/analytics-browser";

function getPoints() {
  function request() {
    return { type: POINTS.POINTS_REQUEST };
  }

  function success(response) {
    return { type: POINTS.POINTS_SUCCESS, response };
  }

  function failure(error) {
    return { type: POINTS.POINTS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.getPoints().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getExpirationPoints() {
  function request() {
    return { type: EXPIRATION_POINTS.EXPIRATION_POINTS_REQUEST };
  }

  function success(response) {
    return { type: EXPIRATION_POINTS.EXPIRATION_POINTS_SUCCESS, response };
  }

  function failure(error) {
    return { type: EXPIRATION_POINTS.EXPIRATION_POINTS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.getExpirationPoints().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function loginWithToken(token) {
  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.loginWithToken(token).then(
      (user) => {
        console.log("v2 signintoken");
        dispatch(success(user));
        logSignIn(user);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

async function logSignIn(user) {
  amplitude.setUserId(user._id);
}

function openRandombox() {
  function request() {
    return { type: OPEN_RANDOMBOX.OPEN_RANDOMBOX_REQUEST };
  }

  function success(response) {
    return { type: OPEN_RANDOMBOX.OPEN_RANDOMBOX_SUCCESS, response };
  }

  function failure(error) {
    return { type: OPEN_RANDOMBOX.OPEN_RANDOMBOX_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.openRandombox().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function withdrawUser(data) {
  function request() {
    return { type: userConstants.WITHDRAWAL_REQUEST };
  }

  function success(response) {
    return { type: userConstants.WITHDRAWAL_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.WITHDRAWAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return usersService.withdrawUser(data);
  };
}

function getManaged() {
  function request() {
    return { type: userConstants.MANAGED_REQUEST };
  }

  function success(response) {
    return { type: userConstants.MANAGED_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.MANAGED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.getManaged().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}
function sendConfirmationEmail(data) {
  function request() {
    return { type: userConstants.SEND_CONFIRMATION_REQUEST };
  }

  function success(response) {
    return { type: userConstants.SEND_CONFIRMATION_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.SEND_CONFIRMATION_FAILURE };
  }

  return (dispatch) => {
    dispatch(request());
    return usersService.sendConfirmationEmail(data).then(
      (response) => {
        dispatch(success(response));
        return response;
      },
      (error) => {
        dispatch(failure(error));
        return error;
      }
    );
  };
}

function confirmEmail(data) {
  function request() {
    return { type: userConstants.CONFIRM_EMAIL_REQUEST };
  }
  function success(response) {
    return { type: userConstants.CONFIRM_EMAIL_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.CONFIRM_EMAIL_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    return usersService.confirmEmail(data).then(
      (response) => {
        dispatch(success(response));
        return response;
      },
      (error) => {
        dispatch(failure(error));
        return error;
      }
    );
  };
}
function setManaged(data) {
  function request() {
    return { type: userConstants.MANAGED_REQUEST };
  }

  function success(response) {
    return { type: userConstants.MANAGED_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.MANAGED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.setManaged(data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getMarketingAgreement() {
  function request() {
    return { type: userConstants.MARKETING_AGREEMENT_REQUEST };
  }

  function success(response) {
    return { type: userConstants.MARKETING_AGREEMENT_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.MARKETING_AGREEMENT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.getMarketingAgreement().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function setMarketingAgreement(data) {
  function request() {
    return { type: userConstants.MARKETING_AGREEMENT_REQUEST };
  }

  function success(response) {
    return { type: userConstants.MARKETING_AGREEMENT_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.MARKETING_AGREEMENT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.setMarketingAgreement(data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getUserRegNo() {
  function request() {
    return { type: userConstants.REG_NO_REQUEST };
  }

  function success(response) {
    return { type: userConstants.REG_NO_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.REG_NO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.getUserRegNo().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postUserRegNo(data) {
  function request() {
    return { type: userConstants.REG_NO_REQUEST };
  }

  function success(response) {
    return { type: userConstants.REG_NO_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.REG_NO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.postUserRegNo(data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function validateAlpaco(email) {
  function request() {
    return { type: userConstants.VALIDATE_ALPACO_REQUEST };
  }

  function success(response) {
    return { type: userConstants.VALIDATE_ALPACO_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.VALIDATE_ALPACO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.validateAlpaco(email).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function loginAlpaco(email) {
  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    usersService.loginAlpaco(email).then(
      (user) => {
        dispatch(success(user));
        history.push("/classroom");
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const usersActions = {
  getPoints,
  getExpirationPoints,
  loginWithToken,
  openRandombox,
  withdrawUser,
  getManaged,
  setManaged,
  getMarketingAgreement,
  setMarketingAgreement,
  getUserRegNo,
  postUserRegNo,
  validateAlpaco,
  loginAlpaco,
  confirmEmail,
  sendConfirmationEmail,
};
