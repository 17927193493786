import React, { useEffect } from "react";
import { history } from "../../../_helpers";
import { enrolledService, homeworksService } from "../../_state/services";
/**
 * ***1단계 숙제 페이지를 조회하지 않은 유저의 경우 → 1단계 카드발급 안내 페이지로 연결**
 * ***1단계 숙제 페이지 조회하고 이탈한 유저의 경우 → 1단계 숙제제출 페이지로 연결**
 * */
const CreditIntroHomeworkPage = () => {
  useEffect(() => {
    enrolledService.getEnrolleds().then((resp) => {
      const enrolled = resp.context.filter(
        (enrolled) => enrolled.course_id === "6115cea3e73913245c9538e0"
      );
      if (!enrolled.length) {
        return;
      }
      const govIntroEnrolled = enrolled[0];
      enrolledService
        .getEnrolledDetails(govIntroEnrolled.enrolled_id)
        .then((resp) => {
          const firstWeekEnrolled = resp.context.weeks[0];
          const enrolledDetail = firstWeekEnrolled.enrolled_details[0];
          const homework = firstWeekEnrolled.homework;
          if (govIntroEnrolled.progress_rate > 0) {
            history.push(
              `/enrolleds/${govIntroEnrolled.enrolled_id}/ehomeworks/${homework._id}`
            );
          } else {
            history.push(
              `/enrolleds/${govIntroEnrolled.enrolled_id}/edetails/${enrolledDetail.enrolled_detail_id}`
            );
          }
        });
    });
  }, []);

  return <></>;
};

export default CreditIntroHomeworkPage;
