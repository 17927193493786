import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";

export const GNBContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#3A3E41";
        default:
          return "#c7d2d8";
      }
    }};
`;

export const GNBgoRoadmap = styled.div`
  width: 24px;
  height: 24px;
  margin: auto 16px;
  cursor: pointer;
`;

export const GNBmoreOptions = styled.div`
  width: 24px;
  height: 24px;
  margin: auto 16px;
  cursor: pointer;
`;
