import styled from "@emotion/styled";
import {
  neutralDay,
  mBody1,
  mBody2,
  mCaption2,
  MyClassLine,
  wBody1,
  wBody2,
  wCaption1,
} from "@teamsparta/design-system";
import { Device } from "../../../../../_styles/device";
import { logOnClickCourse } from "../../../../hooks/finish/recommend";
import { useSelector } from "react-redux";
import { Calendar } from "../svgs";

const CourseCard = ({ cardData }) => {
  const { keyword, title, description, weeks, months, url } = cardData;
  const enrolled = useSelector((state) => state.enrolled);
  return (
    <Container
      onClick={() => {
        window.open(url);
        logOnClickCourse(enrolled, `스파르타코딩클럽 | ${title}`, url);
      }}
    >
      <Thumbnail src={`/assets/thumbnails/${keyword}.png`} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Duration>
        {months ? (
          <p>
            <Calendar size={16} color={neutralDay.gray70} /> {months}개월 과정
          </p>
        ) : (
          <p>
            <MyClassLine size={16} color={neutralDay.gray70} /> {weeks}주과정{" "}
          </p>
        )}
      </Duration>
    </Container>
  );
};

export default CourseCard;

const Container = styled.div`
  cursor: pointer;
  width: 158px;

  ${Device.Desktop} {
    width: 237px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  margin-bottom: 16px;

  ${Device.Desktop} {
    height: 152px;
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  ${mBody1};
  color: ${neutralDay.gray100};
  margin-bottom: 4px;

  ${Device.Desktop} {
    ${wBody1};
    color: ${neutralDay.gray100};
  }
`;

const Description = styled.div`
  ${mBody2};
  color: ${neutralDay.gray80};
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;

  ${Device.Desktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
  }
`;

const Duration = styled.div`
  ${mCaption2};
  color: ${neutralDay.gray70};

  p {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  ${Device.Desktop} {
    flex-direction: row;
    ${wCaption1};
    color: ${neutralDay.gray70};
  }
`;
