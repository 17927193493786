import React, {Component} from 'react';
import classNames from "classnames/bind";
import styles from "./CheckinRead.module.scss";
import HeartOutlined from "@ant-design/icons/lib/icons/HeartOutlined";
import HeartFilled from "@ant-design/icons/lib/icons/HeartFilled";

const cx = classNames.bind(styles);

class CheckinReadEn extends Component {

    render() {
        const {checkins} = this.props;
        return (
            <div className={cx('checkinReadContainer')}>
                <div className={cx('checkinRead')}>
                    <div id="checkinList" className={cx('checkinReadBody')}>
                        {
                            checkins.filter((checkin) => {
                                return checkin.comment.length > 2;
                            }).map((checkin, idx) => {
                                let time = checkin.time
                                    .replace('일 전', ' day(s)')
                                    .replace('시간 전', ' hour(s)')
                                    .replace('분 전', ' minute(s)')
                                    .replace('초 전', ' second(s)');

                                return (
                                    <div key={idx} className={cx('checkinWrapper', 'border_radius_bottom')}>
                                        <div className={cx('contentWrapper')}>
                                            {/*코멘트*/}
                                            <div className={cx('comment')}>
                                                {checkin.comment}
                                            </div>

                                            {/*작성자, 시간정보*/}
                                            <div className={cx('whowhen')}>
                                                {checkin.name} has written {time} ago.
                                            </div>
                                        </div>

                                        {/* 좋아요 정보 */}
                                        {checkin.liked &&
                                        <div className={cx('likesWrapper', 'liked')}>
                                            <HeartFilled onClick={() => {
                                                this.props._handleLike(checkin._id)
                                            }}/>
                                            <span className={cx('spanNumber')}>{checkin.likes}</span>
                                        </div>
                                        }
                                        {!checkin.liked &&
                                        <div className={cx('likesWrapper')}>
                                            <HeartOutlined onClick={() => {
                                                this.props._handleLike(checkin._id)
                                            }}/>
                                            <span className={cx('spanNumber')}>{checkin.likes}</span>
                                        </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export {CheckinReadEn};