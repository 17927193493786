interface Props {
  courseId: string;
  courseTitle: string;
}

export const useRecommendBannerData = ({ courseId, courseTitle }: Props) => {
  const getBannerData = (
    bannerImgSrc: string,
    nextHref: string,
    bannerCourse: string
  ) => ({
    bannerImgSrc,
    nextHref,
    log: {
      title: "scc_lecturePage_click_banner",
      data: {
        course_id: courseId,
        course_title: courseTitle,
        banner_course: bannerCourse,
        banner_url: nextHref,
      },
    },
  });

  switch (courseId) {
    case "65fd3e2e36ebf3dc285a0703":
      return getBannerData(
        "/assets/images/lecture/reboot_banner.png",
        "https://hanghae99.spartacodingclub.kr/reboot",
        "항해 취업 리부트 코스"
      );
    case "6604e25bb84072071779ac26":
    case "66b5bd8dafab22923cb6156a":
    case "671994f16b070ca0779c2ba8":
      return getBannerData(
        "/assets/images/lecture/plus_banner.png",
        "https://hanghae99.spartacodingclub.kr/hhplus-hub",
        "항해 플러스"
      );
    default:
      return getBannerData(
        "/assets/images/lecture/lecture_kdt_banner.png",
        "https://nbcamp.spartacodingclub.kr/",
        "내일배움캠프"
      );
  }
};
