import React, { useState } from "react";
import { usersActions } from "../../../_state/actions";
import { connect } from "react-redux";
import useTimerRef from "../../../../hooks/useTimer";
import { params, validateEmail } from "../../../_helper";
import { history } from "../../../../_helpers";
import { config } from "../../../../config";
const EmailConfirmationItem = ({
  cx,
  email,
  setState,
  isEmailConfirmed,
  setIsEmailConfirmed,
  sendConfirmationEmail,
  confirmEmail,
  nextUrl,
}) => {
  const { next } = params();
  const onChange = (e) => {
    setState({ email: e.target.value.trim() });
  };
  const onCodeChange = (e) => {
    setCode(e.target.value.trim());
  };
  const [code, setCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const endTimeRef = useTimerRef(
    new Date(new Date().getTime() + 3 * 60000),
    [isConfirming],
    "minute",
    true
  );
  return (
    <div className={cx("email-form__container")}>
      <label className={cx("email-form__label")}>이메일</label>
      <div className={cx("email-form__wrapper")}>
        <div
          className={cx(
            "email-form__confirmation__wrapper",
            isEmailConfirmed ? "--confirmed" : "",
            isConfirming ? "--confirming" : ""
          )}
        >
          <input
            placeholder={"이메일을 입력해주세요."}
            onChange={onChange}
            readOnly={isEmailConfirmed}
            className={cx("email-form__email__input")}
            value={email}
          />
          <div
            className={cx("email-form__confirmation__button")}
            onClick={() => {
              if (validateEmail(email)) {
                if (isConfirming) {
                  setIsConfirming(false);
                  sendConfirmationEmail({ email }).then((resp) => {
                    if (resp.ok) {
                      setIsConfirming(true);
                      setCode("");
                      alert("인증메일이 재발송 되었습니다.");
                    } else {
                      alert(resp.message);
                      if (nextUrl === "intellipick") {
                        window.location.href = `${config.intellipickUrl}/login`;
                      } else if (nextUrl === "hanghae") {
                        window.location.href = `${config.hanghaeUrl}/v2/login`;
                      } else {
                        history.push(`/login${next ? "?next=" + next : ""}`);
                      }
                    }
                  });
                } else {
                  sendConfirmationEmail({ email }).then((resp) => {
                    if (resp.ok) {
                      setIsConfirming(true);
                    } else {
                      alert(resp.message);
                      if (nextUrl === "intellipick") {
                        window.location.href = `${config.intellipickUrl}/login`;
                      } else {
                        history.push(`/login${next ? "?next=" + next : ""}`);
                      }
                    }
                  });
                }
              } else {
                alert("이메일 양식을 확인해주세요.");
              }
            }}
          >
            {isEmailConfirmed
              ? "인증 완료"
              : isConfirming
              ? "인증 재발송"
              : "인증 요청"}
          </div>
        </div>
        {isConfirming && (
          <div className={cx("email-form__confirmation__wrapper")}>
            <div className={cx("email-form__confirmation__input__container")}>
              <input
                placeholder={"인증번호"}
                onChange={onCodeChange}
                className={cx("email-form__confirmation__input")}
                value={code}
              />
              <span
                className={cx("email-form__confirmation__remaining")}
                ref={endTimeRef}
              ></span>
            </div>
            <div
              className={cx("email-form__validation__button")}
              onClick={() => {
                confirmEmail({
                  email,
                  code,
                }).then((resp) => {
                  if (resp.ok) {
                    if (resp.context.confirmed) {
                      setIsEmailConfirmed(true);
                      setIsConfirming(false);
                    } else {
                      alert("인증번호를 다시 확인해주세요.");
                    }
                  }
                });
              }}
            >
              확인
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
const connectedEmailConfirmationItem = connect(mapStateToProps, {
  sendConfirmationEmail: usersActions.sendConfirmationEmail,
  confirmEmail: usersActions.confirmEmail,
})(EmailConfirmationItem);

export { connectedEmailConfirmationItem as EmailConfirmationItem };
