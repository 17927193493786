import { useMutation } from "react-query";
import { govService } from "../../../_state/services";

export const useDoGovEnrolledHomework = () => {
  return useMutation(
    async ({ enrolledId, enrolledHomeworkId }) => {
      await govService.doGovEnrolledHomework(enrolledId, enrolledHomeworkId);
    },
    {
      onSuccess: () => {
        console.log("onSuccess on DoGovEnrolledHomework");
      },
      onError: () => {
        console.log("onError on DoGovEnrolledHomework");
      },
    }
  );
};
