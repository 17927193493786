import * as S from "./HomeworkGnbMobile.style";
import {
  ActionMessage,
  ArrowChevronLeft,
  neutralDay,
} from "@teamsparta/design-system";
import { history } from "../../../../../../_helpers";
import { LectureMobileFloating } from "../../../../../pages/LecturePageV3/LectureMobileFloating/LectureMobileFloating";
import React from "react";
import { useHomeworkData } from "../../../../../hooks/homeworkV3/useHomeworkData";
import { useParams } from "react-router-dom";
import { useNavigateToRoadmap } from "../../../../../hooks/useNavigateToRoadmap";

export const HomeworkGnbMobile = ({
  isInit,
  qnaLink,
  courseTitle,
  syllabusUnit,
  isFeedbackRequired,
  canGoNextLecture,
  canUpdateHomework,
  handleSubmitHomework,
}) => {
  const { enrolled_homework_id } = useParams();
  const { week } = useHomeworkData(enrolled_homework_id);
  const navigateToRoadmap = useNavigateToRoadmap();

  return (
    <>
      <S.GnbMobileWrapper>
        <S.IconContainer
          onClick={() => {
            navigateToRoadmap();
          }}
        >
          <ArrowChevronLeft size={24} color={neutralDay.gray90} />
        </S.IconContainer>
        <S.TitleWrapper>
          <S.Title>{courseTitle}</S.Title>
          <S.SubTitle>
            {week}
            {syllabusUnit}
          </S.SubTitle>
        </S.TitleWrapper>
        <S.IconContainer
          onClick={() => {
            window.open(qnaLink);
          }}
        >
          <ActionMessage
            size={24}
            style={"line"}
            color={`${neutralDay.gray100}`}
          />
        </S.IconContainer>
      </S.GnbMobileWrapper>
      {/*숙제 제출하기/다음 강의 버튼*/}
      <LectureMobileFloating
        isInitialized={isInit}
        isHomeworkPage={true}
        isFeedbackRequired={isFeedbackRequired}
        canGoNextLecture={canGoNextLecture}
        canUpdateHomework={canUpdateHomework}
        handleSubmitHomework={handleSubmitHomework}
      />
    </>
  );
};
