import React, { Component } from "react";
import { createEventId, history, params } from "../../_helpers";
import { Skeleton } from "antd";
import classNames from "classnames/bind";
import styles from "./RcmdPage.module.scss";
import { connect } from "react-redux";
import { friendRecoActions, userActions } from "../../_actions";
import "react-toastify/dist/ReactToastify.css";
import { sendLog } from "../../v2/businesslogics/logging";
import { KakaoShare } from "../../v2/components/organisms/KakaoShare";
import { Gnb } from "../../v2/components/organisms/_layout/gnb/Gnb";
import * as S from "./RcmdFreePage.style";
import { Hero } from "../../v2/components/organisms/RcmdFreePage/Hero/Hero";
import { Participate } from "../../v2/components/organisms/RcmdFreePage/Participate/Participate";
import { Notice } from "../../v2/components/organisms/RcmdFreePage/Notice/Notice";
import { SharedIcon } from "./DashBoard.svg";
import { UpperRcmd } from "./UpperRcmd";
import { getCookie } from "../../v2/_helper";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);

class RcmdPage extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { user } = this.props;
    window.scrollTo(0, 0);
    if (params().logined && !user) {
      let token = getCookie("token");
      if (token) {
        this.props.loginWithToken(token);
      }
    }
    sendLog("scc_rcmdFriendPage_view", { user_id: user._id }, {}, true);

    if (this.props.user) {
      this.props.getFriendRecoInfo();
    }
    this.props.getFriendRecoChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.user && this.props.user) {
      this.props.getFriendRecoInfo();
    }
  }

  _goToLogin = () => {
    history.push("/login?next=%2Frcmds");
  };

  _numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  _blurName = (x) => {
    let firstPart = x.substr(0, 1);
    let lastPart = x.substr(2);
    return firstPart + "*" + lastPart;
  };

  _getMyDashboard() {
    const { user, friendRecoInfo, friendRecoChart } = this.props;
    let tableTitle, friendRowName, friendRowValue, moneyRowName, moneyRowValue;
    if (user && friendRecoInfo.loaded) {
      tableTitle = "내 친구 초대 현황";
      friendRowName = "초대한 친구 수";
      friendRowValue = `${friendRecoInfo.data.my_info}명`;
      moneyRowName = "총 누적 혜택";
      moneyRowValue = `${this._numberWithCommas(
        friendRecoInfo.data.my_info * 50000
      )}원`;
    } else {
      const { avg_benefit, avg_rcmd_per_user } =
        friendRecoChart.data.friend_avg;
      tableTitle = "평균 친구 초대 현황";
      friendRowName = "초대한 친구 수";
      friendRowValue = `${avg_rcmd_per_user}명`;
      moneyRowName = "평균 누적 혜택";
      moneyRowValue = `${this._numberWithCommas(avg_benefit)}원`;
    }
    const isB2BKoreaInvest = this.props.location?.state?.isB2BKoreaInvest;

    return (
      <>
        {!isB2BKoreaInvest && (
          <S.Wrapper>
            <S.BtnBoxContainer>
              <S.TextContainer>
                <S.BoxTitle>나의 초대 현황</S.BoxTitle>
                <S.InnerTextContainer>
                  <S.BoxText>
                    내가 초대한 친구
                    <S.BoxTextBlack>
                      {friendRecoInfo.data?.my_info}명
                    </S.BoxTextBlack>
                  </S.BoxText>
                </S.InnerTextContainer>
              </S.TextContainer>
              <S.BtnWapper>
                <S.BtnRed onClick={this.copyToClipboard}>
                  <S.BtnText>
                    <SharedIcon />
                    초대 링크 복사하기
                  </S.BtnText>
                </S.BtnRed>
                <KakaoShare
                  name={user.name}
                  _id={user._id}
                  btnLog={this.sendBtnClickLog}
                />
              </S.BtnWapper>
            </S.BtnBoxContainer>
          </S.Wrapper>
        )}
      </>
    );
  }

  getFriendRecoRank() {
    const { friendRecoChart } = this.props;
    if (friendRecoChart.loaded) {
      const { top_5_rank } = friendRecoChart.data;
      let top_5_rank_list = [];
      for (let i = 0; i < 5; i++) {
        let addClass = i <= 2 ? "star" : "no_star";
        top_5_rank_list.push(
          <tr key={i}>
            <td
              className={cx("rank", addClass)}
              style={
                i <= 2
                  ? {
                      backgroundImage: `url(${`/assets/images/rcmd/friend_reco_${
                        i + 1
                      }.svg`}`,
                    }
                  : {}
              }
            >
              {i + 1}
            </td>
            <td className={cx("name")}>
              {this._blurName(top_5_rank[i]._id.name)}님
            </td>
            <td className={cx("refer")}>{top_5_rank[i].count}명</td>
            <td className={cx("amount")}>
              {this._numberWithCommas(top_5_rank[i].count * 50000)}원
            </td>
          </tr>
        );
      }
      return top_5_rank_list;
    } else {
      return <div />;
    }
  }

  sendBtnClickLog = async (btnText) => {
    const { user } = this.props;
    const eventID = createEventId();
    await sendLog(
      "scc_rcmdFriendPage_click_button",
      {
        user_id: user._id,
        button_text: btnText,
      },
      eventID,
      true
    );
  };

  copyToClipboard = async () => {
    try {
      const { user } = this.props;
      let url = `https://spartacodingclub.kr/?f_name=${user.name}&f_uid=${user._id}`;
      await navigator.clipboard.writeText(encodeURI(url));
      await this.sendBtnClickLog("친구 초대 링크 복사하기");
      alert("내용이 복사되었으니, 지금 바로 카톡방에 공유해보세요!");
    } catch (err) {
      console.log(err);
    }
  };

  scrollToRcmd = () => {
    let rcmd = document.querySelector("#rcmd");
    window.scrollTo({
      top: rcmd.getBoundingClientRect().top,
      behavior: "smooth",
    });
  };

  render() {
    const { checkPlatform } = window;
    const payment = this.props.location?.state?.payment;
    const period = this.props.location?.state?.period;
    const course_id = this.props.location?.state?.course_id;
    const isStoryPossible = this.props.location?.state?.storyPossible;
    const isB2BKoreaInvest = this.props.location?.state?.isB2BKoreaInvest;
    const round_id = this.props.location?.state?.round_id;
    const enrolled_id = this.props.location?.state?.enrolled_id;
    const platform = checkPlatform();
    const { user, friendRecoInfo, friendRecoChart } = this.props;
    if (!friendRecoChart.loaded) {
      return <Skeleton />;
    }
    return (
      <div>
        <Gnb />
        <div className={cx("contentWrapper")}>
          {payment === "done" && (
            <>
              {isB2BKoreaInvest && (
                <div className={cx("korea-invest-container")}>
                  <div className={cx("header")}>
                    <div className={cx("korea-invest")}>한국투자금융그룹</div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                        fill="#D4D4D4"
                      />
                    </svg>
                    <div className={cx("sparta")}>스파르타코딩클럽</div>
                  </div>
                  <div className={cx("title")}>수강신청이 완료되었습니다!</div>
                  <div className={cx("content")}>
                    <div className={cx("content-title")}>
                      수료기준을 확인하세요!
                    </div>
                    <div className={cx("content-body")}>
                      <div className={cx("content-row")}>
                        5주차 강의의 과제까지 제출한 뒤{" "}
                        <br className={cx("mobile")} />
                        만족도 조사를 제출하면 끝!
                      </div>
                      <div className={cx("content-row")}>
                        개발일지는 선택과제라서 <br className={cx("mobile")} />
                        제출하지 않으셔도 수료됩니다.
                      </div>
                      <div className={cx("content-row")}>
                        한국투자금융그룹 내부 공지도{" "}
                        <br className={cx("mobile")} />꼭 확인해주세요!
                      </div>
                    </div>
                  </div>
                  <div
                    className={cx("btn")}
                    onClick={() => history.push("/classroom")}
                  >
                    강의실 입장하기
                  </div>
                </div>
              )}
              <UpperRcmd
                scrollToRcmd={this.scrollToRcmd}
                course_id={course_id}
                isB2BKoreaInvest={isB2BKoreaInvest}
                isStoryPossible={isStoryPossible}
                enrolled_id={enrolled_id}
                period={period}
                platform={platform}
                round_id={round_id}
              />
            </>
          )}
          <S.Container>
            {!isB2BKoreaInvest && (
              <div>
                <Hero />
                <Participate />
                <Notice />
              </div>
            )}
            {platform === "pc" && this._getMyDashboard()}
          </S.Container>
        </div>
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, friendRecoInfo, friendRecoChart } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    friendRecoInfo,
    friendRecoChart,
  };
}

const connectedRcmdPage = connect(mapStateToProps, {
  login: userActions.login,
  loginWithToken: userActions.loginWithToken,
  getFriendRecoInfo: friendRecoActions.getFriendRecoInfo,
  getFriendRecoChart: friendRecoActions.getFriendRecoChart,
})(RcmdPage);
export { connectedRcmdPage as RcmdPage };
