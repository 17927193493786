import React from "react";
import { sendLog } from "../../../businesslogics/logging";
import { Banner, Cta, LeftColumn } from "./style";

function BottomBanner({ title, cta, thumbnail }) {
  return (
    <Banner bg={thumbnail}>
      <LeftColumn>{title}</LeftColumn>
      <Cta
        onClick={() => {
          sendLog(
            "scc_classroomPage_click_button",
            {
              button_text: cta.text,
              next_url: cta.href,
            },
            "",
            true
          );
          window.location.href = cta.href;
        }}
      >
        {cta.text}
      </Cta>
    </Banner>
  );
}

export default BottomBanner;
