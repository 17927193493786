import * as S from "./checkBeforePayment.style";

export const CheckBeforePayment = () => {
  return (
    <S.Container>
      <S.CheckBeforeTitleContainer>
        <InfoCircle />
        <S.CheckBeforeTitle>결제 전 꼭 확인해주세요!</S.CheckBeforeTitle>
      </S.CheckBeforeTitleContainer>
      <S.CheckBeforeContent>
        <div>
          <b>• 스파르타코딩클럽 회원 정보와 결제한 카드 명의 정보가 동일</b>해야
          합니다. 타인 명의 카드로 결제 시 수강 취소 및 환불 처리됩니다.
        </div>

        <div>
          <b>• [내일배움카드]</b>가 아닌 다른 카드로 결제 시 강의를 수강할 수
          없으며 수강 취소 및 환불 처리됩니다.
        </div>

        <div>
          <b>
            • 결제 후 고용24 홈페이지에 동일한 강의의 [수강신청]을 완료해야 수강
            등록이 완료
          </b>
          됩니다.
        </div>
      </S.CheckBeforeContent>
    </S.Container>
  );
};

export const CheckBeforePaymentMobile = () => {
  return (
    <S.MobileOnly>
      <CheckBeforePayment />
    </S.MobileOnly>
  );
};

export const CheckBeforePaymentDesktop = () => {
  return (
    <S.DesktopOnly>
      <CheckBeforePayment />
    </S.DesktopOnly>
  );
};

const InfoCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM10.8333 6.45842C10.8333 6.91865 10.4602 7.29175 9.99992 7.29175C9.53968 7.29175 9.16659 6.91865 9.16659 6.45841C9.16659 5.99818 9.53968 5.62508 9.99992 5.62508C10.4602 5.62508 10.8333 5.99818 10.8333 6.45842ZM9.25 13.5833C9.25 13.9975 9.58579 14.3333 10 14.3333C10.4142 14.3333 10.75 13.9975 10.75 13.5833L10.75 8.86658C10.75 8.45237 10.4142 8.11658 10 8.11658C9.58579 8.11658 9.25 8.45237 9.25 8.86658L9.25 13.5833Z"
        fill="#FF7005"
      />
    </svg>
  );
};
