import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";

export const LecContentContainer = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 19px;

  width: 328px;
  height: 64px;
  
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#3A3E41";
      case "faq":
        return "#5F666B";
      default:
        return "#ffffff";
    }
  }};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => {
        switch (theme) {
          case PIRATES:
            return "#5F666B";
          case "faq":
            return "#141617";
          default:
            return "#c7d2d8";
        }
      }};
    }
  }
`;

export const LecContentText = styled.div`
  height: 24px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      case 'faq':
        return "#FFFFFF";
      default:
        return "#141617";
    }
  }};
`;

export const WrapLogo = styled.div`
  width: 24px;
  height: 24px;
`;
