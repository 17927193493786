import React from "react";
import classNames from "classnames/bind";
import styles from "./ClassroomPageEn.module.scss";
import { NavContainerEn } from "../../_containers";
import { userActions } from "../../_actions/user.actions";
import { classroomActions } from "../../_actions/classroom.actions";
import { Button, Skeleton } from "antd";
import { connect } from "react-redux";
import { createEventId, history, params } from "../../_helpers";
import { sendLog, setCountry } from "../../v2/businesslogics/logging";
import { getEnAMPMHour, getEnMonthName, isSameDay } from "../../_helpers/date";
import { certActions } from "../../_actions/certs.actions";
import { getCookie } from "../../v2/_helper";

const cx = classNames.bind(styles);

class ClassroomPageEn extends React.Component {
  state = {
    isQnaTime: false,
    tooltipShow: false,
    isMe: 0,
    rcmdVisible: false,
  };

  componentDidMount() {
    setCountry();
    const { user } = this.props;
    if (params().logined) {
      let token = getCookie("token");
      if (token) {
        this.props.loginWithToken(token);
        if (params().is_new) {
          const eventID = createEventId();
          sendLog("complete_registration", {}, eventID);
        }
      }
    } else {
      if (!user) {
        alert("Login is required.\nGo to the login page.");
        history.push("/en/login?next=classroom");
      }
    }
    if (user) {
      this.props.getClassroom();
      this.props.getCertInfo();
    }

    window.scrollTo(0, 0);
    this._isSlackOn();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.user && this.props.user) {
      this.props.getClassroom();
      this.props.getCertInfo();
    }
  }

  _enterProgressMapPage = (round_id) => {
    history.push(`/en/rounds/${round_id}/dashboard`);
  };

  _enterWeekzeroPage = async (weekzero_url, course_id) => {
    const eventID = createEventId();
    await sendLog("prehwk_start", { course_id }, eventID);
    window.open(
      weekzero_url,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  _checkPlatform = () => {
    let ua = window.navigator.userAgent;
    ua = ua.toLowerCase();
    var platform = {};
    var matched = {};
    var userPlatform = "pc";
    var platform_match =
      /(ipad)/.exec(ua) ||
      /(ipod)/.exec(ua) ||
      /(windows phone)/.exec(ua) ||
      /(iphone)/.exec(ua) ||
      /(kindle)/.exec(ua) ||
      /(silk)/.exec(ua) ||
      /(android)/.exec(ua) ||
      /(win)/.exec(ua) ||
      /(mac)/.exec(ua) ||
      /(linux)/.exec(ua) ||
      /(cros)/.exec(ua) ||
      /(playbook)/.exec(ua) ||
      /(bb)/.exec(ua) ||
      /(blackberry)/.exec(ua) ||
      [];

    matched.platform = platform_match[0] || "";

    if (matched.platform) {
      platform[matched.platform] = true;
    }

    if (
      platform.android ||
      platform.bb ||
      platform.blackberry ||
      platform.ipad ||
      platform.iphone ||
      platform.ipod ||
      platform.kindle ||
      platform.playbook ||
      platform.silk ||
      platform["windows phone"]
    ) {
      userPlatform = "mobile";
    }

    if (platform.cros || platform.mac || platform.linux || platform.win) {
      userPlatform = "pc";
    }

    return userPlatform;
  };

  _getClassroomComponent = (classroom, user) => {
    if (classroom.data === undefined || classroom.data === {}) {
      return (
        <div className={cx("mainContainer")}>
          <Skeleton />
        </div>
      );
    } else if (classroom.data.full_enrolled_info_list.length === 0 && user) {
      return (
        <div className={cx("mainContainer")}>
          <div className={cx("greetingContainer")}>
            <div className={cx("nameContainer")}>
              Hello <b>{user.name}</b>!
            </div>
            <div className={cx("numberContainer")}>
              Course information does not exist yet.
              <br />
              <br />
              <a href="https://spartacodingclub.kr">
                <span role="img" aria-label="pinpoint">
                  👉
                </span>
                Explore Online Courses
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      const { certs } = this.props;
      const enrolled_list = classroom.data.full_enrolled_info_list;
      let cardComp = this._getCardComponent(enrolled_list);
      return (
        <div className={cx("mainContainer")}>
          <div className={cx("greetingContainer")}>
            <div className={cx("nameContainer")}>
              Hello, <b>{user.name}</b>!
            </div>
            <div className={cx("numberContainer")}>
              <div>
                You are enrolled in <b>{enrolled_list.length}</b> course(s).
              </div>
            </div>
          </div>
          {certs.loaded && certs.data.cert && certs.data.cert.file_link && (
            <div className={cx("certs")}>
              <ul>
                <li>
                  🎓 Download your Certificate of Completion 👉{" "}
                  <span
                    onClick={() => {
                      this.handleCertClick("download");
                    }}
                  >
                    (Click)
                  </span>
                </li>
                <li>
                  🗞️ Add your Certificate of Completion to LinkedIn 👉{" "}
                  <span
                    onClick={() => {
                      this.handleCertClick("linkedin");
                    }}
                  >
                    (Click)
                  </span>
                </li>
                <li>
                  💻 Register our official 5 week bootcamp [Full-Stack Web
                  Development] course 👉{" "}
                  <a href="https://bit.ly/3snSGjt" target="_blank">
                    (Click)
                  </a>
                </li>
              </ul>
            </div>
          )}
          {cardComp}
        </div>
      );
    }
  };

  handleCertClick = (action) => {
    const { certs } = this.props;
    if (!certs.loaded || !certs.data.cert) {
      return;
    }
    const { course_title, cert_id, file_link } = certs.data.cert;
    if (action === "download") {
      window.open(`${file_link}`, "_blank");
    } else if (action === "linkedin") {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      window.open(
        `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
          course_title
        )}&organizationId=30234270&issueYear=${year}&issueMonth=${month}&certUrl=${file_link}&certId=${cert_id}`
      );
    }
  };

  handleRcmdVisible = (isVisible) => {
    this.setState({
      rcmdVisible: isVisible,
    });
  };

  _isSlackOn = () => {
    let now = new Date();
    let day = now.getDay();
    let hour = now.getHours();

    let isQnaTime = false;
    if (
      (day === 1 && hour >= 19 && hour < 22) ||
      (day === 6 && hour >= 14 && hour < 17)
    ) {
      isQnaTime = true;
    }

    this.setState({
      isQnaTime,
    });
  };

  _handleMouseOver = (e) => {
    console.log(e);
    let buttonId = e.target.id;
    this.setState({
      tooltipShow: true,
      isMe: parseInt(buttonId.split("-")[1]),
    });
  };

  _handleMouseLeave = (e) => {
    e.stopPropagation();
    this.setState({
      tooltipShow: false,
    });
  };

  _handleSpanMouseOver = (e) => {
    e.stopPropagation();
    let buttonId = e.target.parentElement.id;
    this.setState({
      tooltipShow: true,
      isMe: parseInt(buttonId.split("-")[1]),
    });
  };

  _handleSpanMouseLeave = (e) => {
    e.stopPropagation();
  };

  _getCardComponent = (enrolled_list) => {
    let cards = [];
    const now = new Date();
    for (let i = 0; i < enrolled_list.length; i++) {
      let enrolled = enrolled_list[i];
      const enrolledEndDate = new Date(enrolled["enrolled_end_date"]);
      let classroom_thumbnail = this._getClassroomThumbnailImagePath(
        enrolled["classroom_thumbnail"],
        enrolled["track_id"]
      );
      cards.push(
        <div
          key={i}
          className={cx("cardContainer")}
          onClick={this._alertMobile}
        >
          <div className={cx("imageContainer")}>
            <div
              className={cx("imageDisplayContainer")}
              style={{
                "--bg-image": `url(${classroom_thumbnail}`,
              }}
            ></div>
          </div>
          <div className={cx("contentContainer")}>
            <div className={cx("courseContainer")}>
              <div className={cx("courseNameContainer")}>
                {enrolled.course_name} {enrolled.round}
                {Math.round(enrolled.progress_rate * 10) / 10 === 100 && (
                  <span role="img" aria-label="finish_emoji">
                    🥇
                  </span>
                )}
              </div>
            </div>
            <div className={cx("progressContainer")}>
              {!enrolled.round_forever && (
                <>
                  <div className={cx("progressTitleContainer")}>Progress</div>
                  <div className={cx("progressRateContainer")}>
                    {Math.round(enrolled.progress_rate * 10) / 10}%
                  </div>
                </>
              )}
            </div>
            <div className={cx("remainingContainer")}>
              <div className={cx("remainingTitleContainer")}>Due</div>
              {isSameDay(now, enrolledEndDate) ? (
                <div className={cx("remainingDaysContainer")}>
                  Today {getEnAMPMHour(enrolledEndDate)}
                </div>
              ) : (
                <div className={cx("remainingDaysContainer")}>
                  Due {enrolledEndDate.getDate()}{" "}
                  {getEnMonthName(enrolledEndDate)}{" "}
                  {getEnAMPMHour(enrolledEndDate)}
                </div>
              )}
            </div>
            <div className={cx("")}>
              <a
                href={
                  "https://www.notion.so/teamsparta/SpartaCodingClub-Guide-to-join-Slack-community-025c85d095d54fd0bf377ea0e891f1f9"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  id={`button-${i}`}
                  onMouseOver={this._handleMouseOver}
                  onMouseLeave={this._handleMouseLeave}
                  className={cx("qnaButtonContainer")}
                >
                  <span
                    onMouseOver={this._handleSpanMouseOver}
                    onMouseLeave={this._handleSpanMouseLeave}
                    className={cx("qnaButtonTextContainer")}
                  >
                    Join Slack Community
                  </span>
                </Button>
              </a>
            </div>
          </div>
          {enrolled["is_started"] && (
            <div className={cx("buttonContainer")}>
              <Button
                onClick={() => this._enterProgressMapPage(enrolled.round_id)}
                className={cx("enterButtonContainer")}
              >
                <span className={cx("enterButtonTextContainer")}>Start</span>
              </Button>
            </div>
          )}
          {!enrolled["is_started"] && (
            <div className={cx("buttonContainer")}>
              <Button
                onClick={() => {
                  alert(`Coming soon!`);
                }}
                className={cx("enterButtonContainer")}
              >
                <span className={cx("enterButtonTextContainer")}>
                  Coming soon!
                </span>
              </Button>
            </div>
          )}
        </div>
      );
    }
    return cards;
  };

  _getClassroomThumbnailImagePath = (name, track_id) => {
    if (track_id) {
      return `/assets/images/classroom/web_classroom_thumbnail_maker.png`;
    } else {
      return `/assets/images/classroom/${name}.png`;
    }
  };

  goLinkedIn = () => {
    const { certs } = this.props;
    if (!certs.loaded || !this.props.certs.data) {
      return;
    }
    const {
      course_title,
      _id: cert_id,
      file_link,
    } = this.props.certs.data.data;
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    window.open(
      `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
        course_title
      )}&organizationId=30234270&issueYear=${year}&issueMonth=${month}&certUrl=${file_link}&certId=${cert_id}`
    );
  };

  render() {
    let { classroom, user } = this.props;
    let classroomComp = this._getClassroomComponent(classroom, user);

    return (
      <div>
        <NavContainerEn />
        <div className={cx("container")}>{classroomComp}</div>
        {/*<Footer/>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, classroom, certs } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    classroom,
    certs,
  };
}

const connectedClassroomPageEn = connect(mapStateToProps, {
  loginWithToken: userActions.loginWithToken,
  getClassroom: classroomActions.getClassroom,
  getCertInfo: certActions.getCertInfo,
})(ClassroomPageEn);
export { connectedClassroomPageEn as ClassroomPageEn };
