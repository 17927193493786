import * as S from "./LectureNavBar.style";
import { BackIconSvg } from "../Icons";
import React from "react";
import { DesktopNextBtn } from "../LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import { sendLog } from "../../../businesslogics/logging";
import { useSelector } from "react-redux";
import { useOnlineTheme } from "../../../queries/pirates";
import { useLogGoBackClick } from "../../../hooks/useVisibilityLog";
import { useNavigateToRoadmap } from "../../../hooks/useNavigateToRoadmap";
import {
  useNavigateToNextContent,
  useNextContentCtaText,
} from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { FeedbackCta } from "../../../components/molecules/FeedbackCta/FeedbackCta";

export const LectureNavBar = ({ courseTitle, week }) => {
  const goRoadmapPage = useNavigateToRoadmap();
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const theme = useOnlineTheme();
  const logClickGoRoadMap = useLogGoBackClick();
  const navigateToNext = useNavigateToNextContent();
  const ctaText = useNextContentCtaText(false, true, "다음 강의");
  return (
    <S.LectureNavBar theme={theme.data}>
      <S.NavLeft>
        <S.NavBack
          onClick={() => {
            sendLog(
              "scc_lecturePage_click_button",
              {
                ...enrolled.data,
                ...enrolledDetail.data,
                button_text: "",
                button_name: "내 진도표 가기",
                next_page: "진도표 페이지",
              },
              "",
              true
            );
            logClickGoRoadMap();
            goRoadmapPage();
          }}
        >
          <BackIconSvg theme={theme.data} />
        </S.NavBack>
        <S.NavCourseTitleContainer>
          <S.NavCourseTitle theme={theme.data}>{courseTitle}</S.NavCourseTitle>
          <S.NavDivider theme={theme.data} />
          <S.NavWeekTitle theme={theme.data}>{week}</S.NavWeekTitle>
        </S.NavCourseTitleContainer>
      </S.NavLeft>
      <S.NavRight>
        <FeedbackCta />
        <DesktopNextBtn
          text={ctaText}
          isActive={true}
          onClick={() => navigateToNext()}
          isClickable={true}
        />
      </S.NavRight>
    </S.LectureNavBar>
  );
};
