export const confirm = (question, onTrue, onFalse) => {
  const answer = window.confirm(question);
  if (answer) {
    return onTrue();
  }
  onFalse();
};

export const getLastUrl = () => {
  const url = window.location.pathname;
  if (url === "/") return url;

  const urlArr = url.split("/");
  if (urlArr.length >= 2) {
    const location = urlArr[urlArr.length - 1];
    const locationArr = location.split("?");
    if (locationArr.length >= 2) {
      return locationArr[0];
    }
    return location;
  } else {
    const locationArr = urlArr[0].split("?");
    return locationArr[0] ? locationArr[0] : false;
  }
};
