import styled from "styled-components";
import { device } from "../../../_styles/device";
import { PIRATES } from "../../../_consts";

export const Page = styled.div`
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#E9ECEF";
    }
  }};
  padding: 0 16px 120px;
  @media ${device.desktop} {
    padding-bottom: 80px;
  }
`;

export const Contents = styled.div`
  margin-top: 26px;
  padding: 0 4px;
  @media ${device.desktop} {
    margin: 85px auto 0;
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
  }
`;

export const Instruction = styled.div`
  margin: 0 0 58px;
  @media ${device.desktop} {
    width: 360px;
  }
`;

export const InstructionTitle = styled.div`
  font-family: GmarketSans;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.95px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#000000";
    }
  }};
  margin-bottom: 8px;
  @media ${device.desktop} {
    font-size: 32px;
    margin-bottom: 12px;
  }
`;

export const InstructionDesc = styled.div`
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  word-break: keep-all;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#9DA7AE";
      default:
        return "#5f666b";
    }
  }};
  margin-bottom: 16px;
  @media ${device.desktop} {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const ExampleHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExampleHeader = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  margin-bottom: 16px;
  /* identical to box height, or 24px */
  /* Gray/Gray80 [day] */
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#9DA7AE";
      default:
        return "#5f666b";
    }
  }};
  @media ${device.desktop} {
    font-size: 16px;
  }
`;

export const InstructionCta = styled.a`
  padding: 13.5px 20px;
  width: fit-content;
  background: #e8344e;
  border-radius: 8px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#ffffff";
    }
  }};
  @media ${device.desktop} {
    padding: 16px 20px;
    font-size: 16px;
  }

  &:hover {
    color: white;
  }
`;

export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  @media ${device.desktop} {
    max-width: 690px;
    gap: 16px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.a`
  padding: 20px 16px;
  width: 100%;
  height: fit-content;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#3A3E41";
        default:
          return "#d4d4d4";
      }
    }};
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  @media ${device.desktop} {
    padding: 40px;
  }
`;

export const Form = styled.div`
  padding: 20px 16px;
  width: 100%;
  height: fit-content;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#3A3E41";
        default:
          return "#d4d4d4";
      }
    }};
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  @media ${device.desktop} {
    padding: 40px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;
`;

export const Rtan = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #adb5bd;
`;

export const HeaderContent = styled.div``;

export const Name = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#1c1d1e";
    }
  }};
`;

export const Date = styled.div`
  font-family: Pretendard;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#9DA7AE";
      default:
        return "#838689";
    }
  }};
`;

export const CardTitle = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#1c1d1e";
    }
  }};
  margin-bottom: 8px;
  @media ${device.desktop} {
    font-size: 16px;
    margin-bottom: 4px;
  }
`;

export const CardDetail = styled.div`
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#B4BFC6";
      default:
        return "#505254";
    }
  }};
  margin-bottom: 6px;
  max-height: 42px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CardBoard = styled.div`
  font-family: Pretendard;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#5F666B";
      default:
        return "#a4a6a9";
    }
  }};
`;

export const UrlTitle = styled(CardTitle)`
  font-size: 16px;
  @media ${device.desktop} {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const UrlContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 56px;
  &:hover {
    color: #505254;
  }
  @media ${device.desktop} {
    gap: 12px;
  }
`;

export const UrlInput = styled.input`
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#eaebed";
      }
    }};
  border-radius: 8px;
  padding: 0 20px;
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#505254";
    }
  }};

  &::placeholder {
    color: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#9DA7AE";
        default:
          return "#c7c9cb";
      }
    }};
  }

  &:focus {
    background: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#2B2D2E";
        default:
          return "#ffffff";
      }
    }};
    border: 1px solid
      ${({ theme }) => {
        switch (theme) {
          case PIRATES:
            return "#C7D2D8";
          default:
            return "#a4a6a9";
        }
      }};
    border-radius: 8px;
  }
`;

export const UrlCta = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  height: 100%;
  padding: 0 20px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  border-radius: 8px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#ffffff";
    }
  }};
`;

export const ShowMore = styled.a`
  display: flex;
  align-items: center;
  margin-top: 14px;
  font-family: Pretendard;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#81898F";
      default:
        return "#9da7ae";
    }
  }};

  @media ${device.desktop} {
    line-height: 20px;
    font-size: 16px;
    margin-top: 32px;
  }

  &:hover {
    color: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "#9da7ae";
      }
    }};
  }
`;
