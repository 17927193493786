export const applyDeltaToDate = (date, daysDelta) => {
  date.setDate(date.getDate() + daysDelta);
  return date;
};

const getYesterday = () => {
  return applyDeltaToDate(new Date(), -1);
};

export const getThreeDayAfter = () => {
  const now = new Date();
  const standardDate = new Date(now.setHours(23, 59, 59, 59));
  const standardDay = standardDate.getDate();
  const threeDaysAfter = new Date(standardDate.setDate(standardDay));
  return threeDaysAfter;
};

export const getVbankDueDate = () => {
  const date = getThreeDayAfter();
  const yyyy = date.getFullYear().toString();
  const MM = addZeroToDateString(date.getMonth() + 1, 2);
  const dd = addZeroToDateString(date.getDate(), 2);
  const hh = addZeroToDateString(date.getHours(), 2);
  const mm = addZeroToDateString(date.getMinutes(), 2);
  const ss = addZeroToDateString(date.getSeconds(), 2);
  return yyyy + MM + dd + hh + mm + ss;
};

export const addZeroToDateString = (number, length) => {
  let stringNum = "" + number;
  while (stringNum.length < length) {
    stringNum = "0" + stringNum;
  }
  return stringNum;
};
