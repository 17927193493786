import React from "react";
import { Form, Input } from "antd";

export const PasswordItem = ({
  className,
  messages,
  unsetErrorMsg,
  inputEmail,
}) => {
  const changeTrim = (e) => {
    return e.target.value.trim();
  };

  const validator = (_, value) => {
    if (!value) {
      return Promise.reject(messages.required);
    }
    if (inputEmail && inputEmail.includes("@koreainvestment.com"))
      return Promise.resolve();

    if (value.length < 6) {
      return Promise.reject(messages.types.password);
    }
    if (/[a-z]/.test(value) === false) {
      return Promise.reject(messages.types.password);
    }
    return Promise.resolve();
  };
  return (
    <Form.Item
      label="비밀번호"
      name="password"
      className={className}
      rules={[() => ({ validator })]}
      onChange={changeTrim}
      getValueFromEvent={changeTrim}
    >
      <Input
        type="password"
        placeholder={"6자 이상, 숫자와 영문자 조합"}
        autoComplete="new-password"
        onChange={unsetErrorMsg}
      />
    </Form.Item>
  );
};
