export const workersClassCards = [
  {
    course_id: "649938ae1e7a5e8c1b9dfb37",
    title: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
    tags: ["실습", "웹개발 기초"],
    img: "web_basic",
    url: "web_basic",
    desc: "코딩의 ‘ㅋ’자도 모르는 왕초보를 위한 입문 강의입니다. 웹 사이트를 만들어보며 코딩의 기본기를 익힙니다.",
    isBest: false,
  },
  {
    course_id: "5f0ae408765dae0006002817",
    title: "앱개발 종합반",
    tags: ["안드로이드 앱", "부수입"],
    img: "app",
    url: "app",
    desc: "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
    isBest: false,
  },
  {
    course_id: "5f51cba7d76b11f9244d1111",
    title: "엑셀보다 쉬운 SQL",
    tags: ["데이터 드리븐", "업무 효율"],
    img: "sql",
    url: "sql",
    desc: "당장 내 업무에 필요한 데이터를 추출하고, 분석할 수 있는 힘을 기릅니다.",
    isBest: false,
  },
];

export const gptClassCards = [
  {
    course_id: "64269c728a9def2d676781b5",
    title: "손쉽게 따라하는\nChatGPT 클론코딩",
    tags: ["입문 추천", "코딩 기초"],
    img: "gpt_clone",
    url: "payment/64269c728a9def2d676781b5",
    desc: "자주 사용하는 인기 3종 앱서비스를 chatGPT로 뚝딱 만들어봐요!",
    isBest: true,
  },
  {
    course_id: "6426a044e09a454370af1d3c",
    title: "21세기 일잘러! chatGPT를 활용한 업무자동화",
    tags: ["데이터 드리븐", "업무 효율"],
    img: "gpt_automation",
    url: "payment/6426a044e09a454370af1d3c",
    desc: `"Hey GPT, 나 대신 일해줘" chatGPT를 가장 현명하게 쓰는 법!`,
    isBest: false,
  },
  {
    course_id: "6426a0fe8a9def2d67678479",
    title: "주식 초보 탈출! ChatGPT를 활용한 주식 데이터 꿀팁",
    tags: ["주식 데이터", "부수입"],
    img: "gpt_stock",
    url: "payment/6426a0fe8a9def2d67678479",
    desc: "요즘 핫한 chatGPT로 주식을 입문하고 수익화 꿀팁까지 배울 수 있어요!",
    isBest: false,
  },
];

export const nocodeRcmdCourse = [
  {
    course_id: "6412734baa727445ce9374b0",
    title: "노코드로 완성도 있는 웹사이트 만들기",
    tags: ["코딩 기초", "No-code"],
    img: "webflow",
    url: "webflow",
    desc: "페이지 제작, 데이터베이스, 자동화까지 할 수 있는 노코드 올인원 클래스.",
    isBest: true,
    isPirates: false,
  },
  {
    course_id: "63d8ec592ad417e6257ffef8",
    title: "노코드로 빠르게 웹 서비스 만들기",
    tags: ["의사결정 능력", "아이디어 실현"],
    img: "nocode",
    url: "nocode",
    desc: "전문적인 개발 지식 없이, 웹서비스를 빠르게 만들 수 있는 강의입니다. 노션, 우피, 재피어 등 노코드 툴을 활용하여 코딩 없이 홈페이지를 완성해 봅니다.",
    isBest: false,
  },
  {
    course_id: "649938ae1e7a5e8c1b9dfb37",
    title: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
    tags: ["실습", "웹개발 기초"],
    img: "web_basic",
    url: "web_basic",
    desc: "코딩의 ‘ㅋ’자도 모르는 왕초보를 위한 입문 강의입니다. 웹 사이트를 만들어보며 코딩의 기본기를 익힙니다.",
    isBest: false,
  },
];

export const workersClassCardsNb = [
  {
    course_id: "63ca26845b3bac30b29bf2a3",
    title: "[왕초보] 플러터(Flutter)로\n시작하는 앱개발 종합반",
    tags: ["Flutter", "부수입 창출"],
    img: "app",
    url: "nb/app",
    desc: "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
    isBest: false,
  },
  {
    course_id: "616d4349460cf1cf6f9579ec",
    title: "[왕초보] 엑셀보다 쉬운 SQL                      ",
    tags: ["SQLD 대비반 혜택", "데이터"],
    img: "sql",
    url: "nb/sql",
    desc: "당장 내 업무에 필요한 데이터를 추출하고, 분석할 수 있는 힘을 기릅니다.",
    isBest: false,
  },
  {
    course_id: "63ca5b43dfbe98f4c8ef20bf",
    title: "[왕초보] 마케터, 기획자를 위한\n실전 데이터 분석",
    tags: ["Python", "데이터"],
    img: "data_v2",
    url: "nb/python",
    desc: "취업에 필요한 데이터 분석 역량과 풍부한 실습 경험까지 한번에 얻어가세요.",
    isBest: false,
  },
];

// 내배단 강의를 걸러내기 위한 데이터
export const nbClassFilterData = {
  /* 내배단 : 온라인 */
  "616d4349460cf1cf6f9579ec": "5f51cba7d76b11f9244d1111", //엑셀보다 쉬운 SQL
  "60a5ee42bf272bef32a58fb9": "649938ae1e7a5e8c1b9dfb37", //  코딩이 처음이어도 쉽게 배우는 웹개발 A to Z
  "616d434a460cf1cf6f957a3d": "5f0ae408765dae0006002817", //  앱개발 종합반
  "616d4348460cf1cf6f957994": "62973372bf6e0e4f799fc76e", // 금융인을 위한 파이썬 업무자동화
};

export const AllClassCards = {
  /* 무료강의 */
  "6420ea5f7e6e4c8f5d0af5d0": {
    title: "ChatGPT로 10분만에 웹사이트 만들기",
    tags: ["chatGPT", "입문 추천", "실습"],
    img: "chatgpt",
    url: "special/chatgpt",
    desc: "chatGPT를 활용해 아주 빠르게 간단한 웹사이트를 만들어봅니다.",
    isBest: false,
    isFree: true,
  },
  "642540848a9def2d6766b27a": {
    title: "내일배움카드 발급부터 활용의 모든 것",
    tags: ["내일배움카드", "국비지원", "무료"],
    img: "nbfree",
    url: "online/special/nbfree",
    desc: "놓치면 안 되는 국비지원 혜택을 30분 만에 알려드립니다.",
    isBest: false,
    isFree: true,
  },
  "6334259bd6566f2cb23ec7f7": {
    title: "중고등학생 코딩교육 준비 전략",
    tags: ["코딩 교육 노하우", "입시"],
    img: "teenager",
    url: "special/teenager",
    desc: "어떻게 코딩교육을 시작해야 할까요? 아이를 위한 코딩을 모두 알려드립니다.",
    isBest: false,
    isFree: true,
  },
  "632ad50b2b63868e4030eb5b": {
    title: "교육 담당자를 위한 DT교육 설계 로드맵",
    tags: ["HR", "강의 무료체험 제공"],
    img: "hr",
    url: "special/hr",
    desc: "우리 회사에 딱 맞는 DT 교육 로드맵을 그려봅니다.",
    isBest: false,
    isFree: true,
  },
  "625963fa9d5b4ee7f14b61d9": {
    title: "개발자 취업 준비의 모든 것",
    tags: ["주니어 개발자", "취업 정보"],
    img: "developer101",
    url: "developer101",
    desc: "개발자 취업 시장을 살펴보고, 취업 로드맵을 그립니다.",
    isBest: false,
    isFree: true,
  },
  "632bec62293ed67329e02c62": {
    title: "IT 창업, 실패 확률 줄이는 방법",
    tags: ["창업", "예비 창업가"],
    img: "chang",
    url: "special/chang",
    desc: "아이템 선정과 팀빌딩, 그리고 방법론까지 알려드립니다.",
    isBest: false,
    isFree: true,
  },
  "6178b82938d078df2971b63f": {
    title: "1시간만에 끝내는 직장인 코딩 용어 해설",
    tags: ["코딩 기초", "입문 추천"],
    img: "coding101",
    url: "coding101",
    desc: "일하면서 꼭 필요한 코딩용어를 1시간만에 끝낼 수 있습니다.",
    isBest: false,
    isFree: true,
  },
  "62a739bfc826b9902f9d9ad1": {
    title: "나만의 프로필 링크 페이지 만들기",
    tags: ["템플릿 증정", "실습"],
    img: "myprofile",
    url: "special/myprofile",
    desc: "포트폴리오와 이력서를 담은 페이지를 직접 만들어보세요.",
    isBest: false,
    isFree: true,
    monthly: {
      year: 2022,
      month: 10,
    },
  },
  "6358e2f6d5e61148f86b3267": {
    title: "나만의 동기부여 홈화면 만들기",
    tags: ["코딩 왕초보", "1시간 실습"],
    img: "momentum",
    url: "special/momentum",
    desc: "휴대폰 브라우저 첫 화면을 나만의 동기부여 페이지로 만들어보세요!",
    isBest: false,
    isFree: true,
    monthly: {
      year: 2022,
      month: 11,
    },
  },
  "637dc32b33db1e53a14a6d29": {
    title: "2023 나만의 버킷리스트 만들기",
    tags: ["목표관리 서비스", "코딩 실습"],
    img: "bucketlist",
    url: "special/bucketlist",
    desc: "40분만에 코딩을 배워 작심365일 특별한 버킷리스트를 만들어 보세요.",
    isBest: false,
    isFree: true,
    monthly: {
      year: 2022,
      month: 12,
    },
  },
  "63d5e477263dfb150c1c3c94": {
    title: "인생사진 쏙쏙 코딩네컷",
    tags: ["웹페이지 기초", "추억기록"],
    img: "photos",
    url: "special/photos",
    desc: "1시간 만에 코딩을 무료로 배워 인생사진을 모아 추억을 기록하는 네컷사진으로 만들어보세요.",
    isBest: false,
    isFree: true,
    monthly: {
      year: 2023,
      month: 1,
    },
  },
  "642c2dfff6dfefee6dc47bfb": {
    title: "코드 없이 AI로 웹사이트 하나가 뚝딱",
    tags: ["No-code", "AI"],
    img: "wf_free",
    url: "special/wf_free",
    desc: "chatGPT와 웹플로우를 활용하여 코딩 없이 웹사이트를 만들어봅니다.",
    isBest: false,
    isFree: true,
  },

  /** 유료강의 */
  "5f0ae408765dae0006002816": {
    title: "웹개발 종합반",
    tags: ["입문 추천", "코딩 기초"],
    img: "web",
    url: "web",
    desc: "코딩이 처음이라면, 기본이 되는 웹개발을 시작으로 코딩을 만나보세요.",
    isBest: true,
  },
  "5f0ae408765dae0006002817": {
    title: "앱개발 종합반",
    tags: ["안드로이드 앱", "부수입"],
    img: "app",
    url: "app",
    desc: "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
    isBest: false,
  },
  "5f51cba7d76b11f9244d1111": {
    title: "엑셀보다 쉬운 SQL",
    tags: ["데이터 드리븐", "업무 효율"],
    img: "sql",
    url: "sql",
    desc: "당장 내 업무에 필요한 데이터를 추출하고, 분석할 수 있는 힘을 기릅니다.",
    isBest: false,
  },
  "62973372bf6e0e4f799fc76e": {
    title: "금융인을 위한 파이썬 업무자동화",
    tags: ["업무생산성", "주가 분석"],
    img: "automation",
    url: "finance_automation",
    desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
    isBest: false,
  },
  "5f8315459328d9a2909acb52": {
    title: "웹개발의 봄 Spring",
    tags: ["Spring", "백엔드"],
    img: "spring",
    url: "spring",
    desc: "백엔드의 봄, 스프링을 배웁니다. 나만의 웹서비스를 만들고 배포해봅니다.",
    isBest: false,
  },
  "5f8bc3e83cff77cc2abeb181": {
    title: "알고보면 알기쉬운 알고리즘",
    tags: ["알고리즘", "코딩테스트"],
    img: "algo",
    url: "algo",
    desc: "코딩테스트 100전 100승! 알고리즘 구현부터 기출문제까지 풀어봅니다.",
    isBest: false,
  },
  "602a022f5b5001847b616df9": {
    title: "가장 쉽게 배우는 머신러닝",
    tags: ["머신러닝", "문제 실습"],
    img: "ml_basic",
    url: "ml_basic",
    desc: "수학과 복잡한 그래프에 겁먹지 마세요. 가장 쉽게 머신러닝을 배웁니다.",
    isBest: false,
  },
  "5feffb89f85b7ed4b4bdc7bd": {
    title: "파이썬 문법 뽀개기",
    tags: ["Python", "문법 기초"],
    img: "python",
    url: "lang/python",
    desc: "가장 많이 배우는 파이썬 문법 A to Z를 다루는 수업입니다.",
    isBest: false,
  },
  "606bfa74c11b70df6da82cfd": {
    title: "자바 스크립트 문법 뽀개기",
    tags: ["Java Script", "문법 기초"],
    img: "js",
    url: "js",
    desc: "웹을 움직이는 핵심! 활용도 높은 자바스크립트의 기초 문법을 다뤄봅니다.",
    isBest: false,
  },
  "606bfb39c11b70df6da82d51": {
    title: "자바 문법 뽀개기",
    tags: ["Java", "문법 기초"],
    img: "java",
    url: "lang/java",
    desc: "객체지향의 종가! 실무에서 자주 쓰는 코드 위주로 자바 기초를 다져봅니다.",
    isBest: false,
  },
  "606bfc0fc11b70df6da82da5": {
    title: "C++ 문법 뽀개기",
    tags: ["C++", "문법 기초"],
    img: "cpp",
    url: "lang/cpp",
    desc: "현대 코딩의 뿌리! C++기초를 다지고 간단한 틱택토 게임을 만들어봅니다.",
    isBest: false,
  },
  "6083eaca6305e019d3e0c3b4": {
    title: "게임개발 종합반",
    tags: ["모바일 캐주얼 게임", "부수입"],
    img: "game",
    url: "game",
    desc: "모바일 캐주얼 게임을 내 손으로 만들어보고, 수익화도 함께 해봅니다.",
    isBest: true,
  },
  "6295890f8240a11ae2511cab": {
    title: "제페토 게임개발",
    tags: ["네이버 제페토", "메타버스"],
    img: "zepeto",
    url: "zepeto",
    desc: "네이버 제페토에서 메타버스 개발을 통해 나만의 아이디어를 구현해봅니다.",
    isBest: false,
  },
  "62e3a1b9761f411652826c05": {
    title: "Zep으로 메타버스 개발 맛보기",
    tags: ["Zep", "게임 개발"],
    img: "zep",
    url: "https://zepxsparta.oopy.io/",
    desc: "웹 메타버스 플랫폼 ZEP으로 메타버스 게임 개발에 입문해봅니다.",
    isBest: false,
  },
  "6361d2ce4239a844b30d4163": {
    title: "직장인을 위한 실전 데이터 분석",
    tags: ["데이터 드리븐", "시각화"],
    img: "data_v2",
    url: "data_v2",
    desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
    isBest: false,
  },
  "63d8ec592ad417e6257ffef8": {
    title: "노코드로 빠르게 웹 서비스 만들기",
    tags: ["의사결정 능력", "아이디어 실현"],
    img: "nocode",
    url: "nocode",
    desc: "전문적인 개발 지식 없이, 웹서비스를 빠르게 만들 수 있는 강의입니다. 노션, 우피, 재피어 등 노코드 툴을 활용하여 코딩 없이 홈페이지를 완성해 봅니다.",
    isBest: false,
  },
  "63d8cb5e2ad417e6257ffd44": {
    title: "컴퓨터를 몰라도 할 수 있는 코딩 첫걸음",
    tags: ["HTML*CSS", "코딩 지식 이해"],
    img: "firststep",
    url: "firststep",
    desc: "컴퓨터가 익숙하지 않은 왕초보를 위한 입문 수업입니다. 누구나 쉽게 배울 수 있는 강의를 통해 컴퓨터와 코딩이 무엇인지부터 활용까지 배워보세요!",
    isBest: false,
  },
  "63ecaf08db5700fa7699d239": {
    title: "웹트랙 Lv.1 모바일 반응형 웹",
    tags: ["", ""],
    img: "pirates",
    url: "pirates/web_mobile",
    desc: "우리에게 더 친숙한 모바일 레이아웃에 맞추어 반응하는 웹페이지를 만들어보세요.",
    isBest: false,
    isPirates: true,
  },
  "63ecafeadb5700fa7699d2ba": {
    title: "웹트랙 Lv.2 캘린더 투두리스트",
    tags: ["", ""],
    img: "pirates",
    url: "pirates/web_calendar",
    desc: "자유롭게 등록, 추가, 삭제가 가능한 투두리스트를 캘린더의 형태로 구현해봅니다.",
    isBest: false,
    isPirates: true,
  },
  "63ed7d4098324043c5ee360a": {
    title: "앱트랙 Lv.1 앱개발 입문",
    tags: ["", ""],
    img: "pirates",
    url: "pirates/app_intro",
    desc: "Flutter를 활용해 앱개발의 기초를 배워봅니다.",
    isBest: false,
    isPirates: true,
  },
  "6412734baa727445ce9374b0": {
    title: "노코드로 완성도 있는 웹사이트 만들기",
    tags: ["", ""],
    img: "webflow",
    url: "webflow",
    desc: "페이지 제작, 데이터베이스, 자동화까지 할 수 있는 노코드 올인원 클래스.",
    isBest: false,
    isPirates: false,
  },
  "642108fb5ca424f842d26759": {
    title: "Java 문법 종합반",
    tags: ["문법", "실습"],
    img: "java_main",
    url: "java",
    desc: "Java 언어의 기초 개념부터 심화까지, 실습을 통해 확실하게 마스터해요.",
    isBest: false,
    isPirates: false,
  },
  "641bfa2198c9ad6cbd2cbf89": {
    title: "JavaScript 문법 종합반",
    tags: ["문법", "실습"],
    img: "javascript_main",
    url: "javascript",
    desc: "개발 스펙의 초석이 되는\nJavaScript의 A to Z를 학습합니다.",
    isBest: false,
    isPirates: false,
  },
  "64671aa77509c0fec824b08f": {
    title: "GA4로 한시간만에 노코드 데이터 정복하기",
    tags: ["실습", "노코드", "데이터 분석"],
    img: "ga4",
    url: "ga4",
    desc: "구글의 무료 웹 로그 분석 툴 GA,\n설치부터 분석 보고서 활용까지 한 번에!",
    isBest: false,
    isPirates: false,
  },
  "648fc643f9b1fac7aced4966": {
    title: "웹개발의 봄 Spring",
    tags: ["실습", "자바", "백엔드"],
    img: "spring_v2",
    url: "spring_v2",
    desc: "Spring의 기초부터 JPA, 인증/세션 관리, Spring Security, 테스트 코드까지! 올인원으로 담았어요.",
    isBest: false,
    isPirates: false,
  },
  "6467063d187f226a50090d43": {
    title: "한번 들으면 평생 써먹는 알고리즘, 취업부터 실무까지!",
    tags: ["알고리즘", "코딩테스트"],
    img: "algorithm",
    url: "algorithm",
    desc: "알고리즘이 막막한 왕초보도 시작할 수 있습니다. 문제 풀이 실전 역량부터 기술 면접 대비까지 한번에 준비하세요.",
    isBest: false,
  },
  "6476d7cd41baaebbdf24176b": {
    title: "TyprScript 문법 종합반",
    tags: ["실습", "TS 기초"],
    img: "typescript",
    url: "typescript",
    desc: "Typescript 기초부터 고급 개념까지 실습과 함께 마스터합니다.",
    isBest: false,
  },
  "646ecce1c2b1d12303b401e6": {
    title: "Kotlin 문법 종합반",
    tags: ["실습", "Kotlin 기초"],
    img: "kotlin",
    url: "kotlin",
    desc: "실전 예제로 Kotlin 문법부터 고급 기능까지 마스터합니다.",
    isBest: false,
  },
  "649adfc45d0330dcf1a41240": {
    title: "PPT보다 쉬운 피그마",
    tags: ["실습", "피그마 활용"],
    img: "figma",
    url: "figma",
    desc: "코딩의 ‘ㅋ’자도 모르는 왕초보를 위한 입문 강의입니다. ",
    isBest: false,
  },
  "64956fe61e7a5e8c1b9c6e50": {
    title: "엑셀보다 쉽고 빠른 SQL",
    tags: ["데이터 드리븐", "업무 효율"],
    img: "sql_2023",
    url: "sql_2023",
    desc: "",
    isBest: false,
  },
  "649938ae1e7a5e8c1b9dfb37": {
    title: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
    tags: ["실습", "웹개발 기초"],
    img: "web_basic",
    url: "web_basic",
    desc: "코딩의 ‘ㅋ’자도 모르는 왕초보를 위한 입문 강의입니다. 웹 사이트를 만들어보며 코딩의 기본기를 익힙니다.",
    isBest: false,
  },
  "64a653c7640351900b810a40": {
    title: "ChatGPT로 웹개발 완전 정복",
    tags: [],
    img: "chatgpt_web",
    url: "ChatGPT-web",
    desc: "",
    isBest: false,
  },
  "64c2859f9e284e546b64d2e8": {
    title: "비개발자도 할 수 있는 GPT 활용 파이썬 업무자동화",
    tags: ["ChatGPT", "실습"],
    img: "automation_python",
    url: "automation_python",
    desc: "500+명의 대기업 임직원들이 업무 자동화를 위해 선택한 ChatGPTx파이썬 강의!",
    isBest: false,
  },
  "64c28536360d083f8a4d5716": {
    title: "생성형AI와 함께 노코드로 시작하는 업무효율화",
    tags: ["ChatGPT", "실습"],
    img: "automation_genai",
    url: "automation_genai",
    desc: "ChatGPT와 생성형 AI를 활용해 3분 만에 PPT 발표 자료를 제작해보세요!",
    isBest: false,
  },
};
/**
 * 1. 웹개발 종합반
 * 2. 앱개발 종합반
 * 3. 엑셀보다 쉬운 SQL
 * 4. 직장인을 위한 실전 데이터분석
 * 5. 금융인을 위한 업무자동화
 * 6. 웹개발의 봄, 스프링
 * 7. 게임개발 종합반
 * 8. 알고보니 알기쉬운 알고리즘
 * 9. 가장 쉽게 배우는 머신러닝
 * 10. 제페토 게임개발
 */
export const priorityClassCard = [
  {
    course_id: "649938ae1e7a5e8c1b9dfb37",
    title: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
    tags: ["실습", "웹개발 기초"],
    img: "web_basic",
    url: "web_basic",
    desc: "코딩의 ‘ㅋ’자도 모르는 왕초보를 위한 입문 강의입니다. 웹 사이트를 만들어보며 코딩의 기본기를 익힙니다.",
    isBest: false,
  },
  {
    course_id: "5f0ae408765dae0006002817",
    title: "앱개발 종합반",
    tags: ["안드로이드 앱", "부수입"],
    img: "app",
    url: "app",
    desc: "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
    isBest: false,
  },
  {
    course_id: "5f51cba7d76b11f9244d1111",
    title: "엑셀보다 쉬운 SQL",
    tags: ["데이터 드리븐", "업무 효율"],
    img: "sql",
    url: "sql",
    desc: "당장 내 업무에 필요한 데이터를 추출하고, 분석할 수 있는 힘을 기릅니다.",
    isBest: false,
  },
  {
    course_id: "6361d2ce4239a844b30d4163",
    title: "직장인을 위한 실전 데이터 분석",
    tags: ["데이터 드리븐", "시각화"],
    img: "data_v2",
    url: "data_v2",
    desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
    isBest: false,
  },
  {
    course_id: "62973372bf6e0e4f799fc76e",
    title: "금융인을 위한 파이썬 업무자동화",
    tags: ["업무생산성", "주가 분석"],
    img: "automation",
    url: "finance_automation",
    desc: "데이터를 필요한 형태로 조합하고, 원하는 모양으로 시각화할 수 있습니다.",
    isBest: false,
  },
  {
    course_id: "648fc643f9b1fac7aced4966",
    title: "웹개발의 봄 Spring",
    tags: ["실습", "자바", "백엔드"],
    img: "spring_v2",
    url: "spring_v2",
    isBest: false,
    desc: "Spring의 기초부터 JPA, 인증/세션 관리, Spring Security, 테스트 코드까지! 올인원으로 담았어요.",
  },
  {
    course_id: "6083eaca6305e019d3e0c3b4",
    title: "게임개발 종합반",
    tags: ["모바일 캐주얼 게임", "부수입"],
    img: "game",
    url: "game",
    desc: "모바일 캐주얼 게임을 내 손으로 만들어보고, 수익화도 함께 해봅니다.",
    isBest: true,
  },
  {
    course_id: "5f8bc3e83cff77cc2abeb181",
    title: "알고보면 알기쉬운 알고리즘",
    tags: ["알고리즘", "코딩테스트"],
    img: "algo",
    url: "algo",
    desc: "코딩테스트 100전 100승! 알고리즘 구현부터 기출문제까지 풀어봅니다.",
    isBest: false,
  },
  {
    course_id: "602a022f5b5001847b616df9",
    title: "가장 쉽게 배우는 머신러닝",
    tags: ["머신러닝", "문제 실습"],
    img: "ml_basic",
    url: "ml_basic",
    desc: "수학과 복잡한 그래프에 겁먹지 마세요. 가장 쉽게 머신러닝을 배웁니다.",
    isBest: false,
  },
  {
    course_id: "6295890f8240a11ae2511cab",
    title: "제페토 게임개발",
    tags: ["네이버 제페토", "메타버스"],
    img: "zepeto",
    url: "zepeto",
    desc: "네이버 제페토에서 메타버스 개발을 통해 나만의 아이디어를 구현해봅니다.",
    isBest: false,
  },
];
