import styled from "@emotion/styled";
import { Device } from "../../../../../_styles/device";
import { neutralDay, mBody2 } from "@teamsparta/design-system";

export const Container = styled.textarea`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 16px;
  width: 100%;
  height: 120px;
  background: ${neutralDay.white};
  border: 1px solid ${neutralDay.gray30};
  border-radius: 8px;
  ${mBody2};
  color: ${neutralDay.gray90};
  resize: none;
  ::placeholder {
    ${mBody2};
    color: ${neutralDay.gray50};
  }
  ${Device.Desktop} {
  }
`;
