import { SECRETSTORE_CONSTANT } from "../constants";
import { secretstoreService } from "../services";

function getIsSecretStoreAllowed(enrolled_id) {
  function request() {
    return { type: SECRETSTORE_CONSTANT.IS_ENROLLED_ALLOWED_REQUEST };
  }

  function success(response) {
    return { type: SECRETSTORE_CONSTANT.IS_ENROLLED_ALLOWED_SUCCESS, response };
  }

  function failure(error) {
    return { type: SECRETSTORE_CONSTANT.IS_ENROLLED_ALLOWED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    secretstoreService.getIsSecretStoreAllowed(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const secretstoreActions = {
  getIsSecretStoreAllowed,
};
