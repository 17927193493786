import styled from "@emotion/styled";
import { additional, neutralDay, wBody1 } from "@teamsparta/design-system";
import { keyframes } from "@emotion/css";

export const AISpinner = () => {
  return (
    <SpinnerWrapper>
      <SpinnerBackground>
        <AISVG />
        <SpinnerSVG width="60" height="60" viewBox="0 0 60 60">
          <CircleBackground cx="30" cy="30" r="25" />
          <CircleProgress cx="30" cy="30" r="25" />
        </SpinnerSVG>
      </SpinnerBackground>
      <Title>AI가 강의 요약 중...</Title>
    </SpinnerWrapper>
  );
};

export const Title = styled.div`
  ${wBody1};
  color: ${additional.blue100};
  text-align: justify;
  padding-top: 32px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 100px 16px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-bottom: 1px solid ${neutralDay.gray100};
  background: ${neutralDay.gray0};
`;

export const SpinnerBackground = styled.div`
  display: flex;
  width: 43.333px;
  height: 43.333px;
  padding: 4.333px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 153.214px;
  background: ${additional.blue100};
  position: relative;
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const CircleBackground = styled.circle`
  fill: none;
  stroke: #146aff;
  stroke-width: 4;
`;

export const CircleProgress = styled.circle`
  fill: none;
  stroke: #d7e0e6;
  stroke-width: 4;
  stroke-dasharray: ${(2 * Math.PI * 25) / 3};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${rotateAnimation} 2s linear infinite;
`;

const SpinnerSVG = styled.svg`
  position: absolute;
  width: 70px;
  height: 70px;
  animation: ${rotateAnimation} 2s linear infinite; // 회전 애니메이션을 적용합니다.
`;

export const AISVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_1021_7393)">
        <g filter="url(#filter0_d_1021_7393)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.111 5.00002C15.111 4.20227 15.7577 3.55557 16.5554 3.55557H19.4443C20.2421 3.55557 20.8888 4.20227 20.8888 5.00002V6.44446C20.8888 7.24221 20.2421 7.88891 19.4443 7.88891H16.5554C15.7577 7.88891 15.111 7.24221 15.111 6.44446V5.00002ZM16.5554 9.33336H19.4443V10.7778H23.7776C26.1708 10.7778 28.1109 12.7179 28.1109 15.1111V25.2222C28.1109 27.6155 26.1708 29.5556 23.7776 29.5556H12.222C9.82881 29.5556 7.88871 27.6155 7.88871 25.2222V15.1111C7.88871 12.7179 9.82881 10.7778 12.222 10.7778H16.5554V9.33336ZM12.2221 19.4444H15.111V20.8889H16.5554V22.3333H19.4443V20.8889H20.8888V19.4444H23.7776V26.6667H12.2221V19.4444ZM14.0606 29.5556V26.6667H21.9394V29.5556H14.0606ZM13.6666 22.7273C13.6666 22.5098 13.843 22.3334 14.0606 22.3334H14.7171C14.9347 22.3334 15.1111 22.5098 15.1111 22.7273V24.8284C15.1111 25.0459 14.9347 25.2223 14.7171 25.2223H14.0606C13.843 25.2223 13.6666 25.0459 13.6666 24.8284V22.7273ZM21.2826 22.3334C21.065 22.3334 20.8887 22.5098 20.8887 22.7273V24.8284C20.8887 25.0459 21.065 25.2223 21.2826 25.2223H21.9392C22.1567 25.2223 22.3331 25.0459 22.3331 24.8284V22.7273C22.3331 22.5098 22.1567 22.3334 21.9392 22.3334H21.2826ZM3.55536 19.4444C3.55536 17.849 4.84876 16.5556 6.44425 16.5556V25.2222C4.84876 25.2222 3.55536 23.9288 3.55536 22.3333V19.4444ZM32.4444 19.4444C32.4444 17.849 31.151 16.5556 29.5556 16.5556V25.2222C31.151 25.2222 32.4444 23.9288 32.4444 22.3333V19.4444Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1021_7393"
          x="0.403844"
          y="3.55557"
          width="35.1921"
          height="32.303"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.15152" />
          <feGaussianBlur stdDeviation="1.57576" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.395941 0 0 0 0 0.427381 0 0 0 0 0.45096 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1021_7393"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1021_7393"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1021_7393">
          <rect
            width="34.6667"
            height="34.6667"
            fill="white"
            transform="translate(0.666687 0.666687)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
