import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RoadmapAsideBannerContent } from "./RoadmapAsideBannerContent";
import { banners, getBannerProducts } from "./model";

const RoadmapAsideBanner = ({ round, enrolled }) => {
  const [bannerArray, setBannerArray] = useState([]);

  useEffect(() => {
    const { course_id, is_gov } = round.data;
    setBannerArray(
      getBannerProducts(is_gov, course_id, round.data).map(
        (product) => banners[product]
      )
    );
  }, [enrolled.loaded, round.loaded]);

  return (
    <>
      {enrolled.data.done}
      {bannerArray.map((banner, idx) => {
        return <RoadmapAsideBannerContent key={idx} banner={banner} />;
      })}
    </>
  );
};

function mapStateToProps(state) {
  const { round, enrolled } = state;
  return { round, enrolled };
}

const connectedRoadmapAsideBanner = connect(
  mapStateToProps,
  {}
)(RoadmapAsideBanner);
export { connectedRoadmapAsideBanner as RoadmapAsideBanner };
