import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./CourseSummary.module.scss";
import { connect } from "react-redux";
import { addDays, toIsoString } from "../../../v2/_helper";
import { getISODateAsKoreanWithYear } from "../../../../src/v2/pages/RoadmapPage/RoundWeek/utils";

const cx = classNames.bind(styles);

function CourseSummary({
  paymentInfoByCourseId,
  paymentOption,
  selectedChoices,
}) {
  const [isStarted, setIsStarted] = useState(false);
  const [roundEndDate, setRoundEndDate] = useState(null);
  useEffect(() => {
    if (!paymentInfoByCourseId.loaded) {
      return;
    }
    setIsStarted(
      new Date(paymentInfoByCourseId.data.context.course.start_date) <
        new Date()
    );
    setRoundEndDate(
      new Date(
        toIsoString(
          addDays(paymentInfoByCourseId.data.context.course.end_date, -1)
        )
      )
    );
  }, [paymentInfoByCourseId.loaded]);

  const payContext = paymentInfoByCourseId.loaded
    ? paymentInfoByCourseId.data.context
    : {};

  const getPaymentOptionDesc = () => {
    if (paymentOption.loaded) {
      for (const option of paymentOption.data) {
        for (const choice of option.choices) {
          if (selectedChoices.includes(choice._id)) {
            return choice.description;
          }
        }
      }
    }
  };

  let payment_src = `/assets/images/payment/thumb/${payContext.course.keyword}.png`;

  const handleImageError = (e) => {
    e.target.src = `https://video.spartacodingclub.kr/payment/thumb/${payContext.course.keyword}.png`;
  };

  const Thumbnail = () => {
    return (
      <img
        className={cx("course-img")}
        src={`${payment_src}`}
        alt="course_thumb"
        onError={handleImageError}
      />
    );
  };

  const Title = () => {
    const keyword = payContext.course.keyword;
    // 스파르톤
    if (keyword === "sparthon") {
      return (
        <div className={cx("course-info-pretendard")}>
          <span className={cx("course-title")}>
            제{payContext.course.round} {payContext.course.title}
          </span>
        </div>
      );
    } else {
      return (
        <div className={cx("course-info")}>
          <span className={cx("course-title")}>{payContext.course.title}</span>
        </div>
      );
    }
  };

  const Duration = () => {
    const {
      course: {
        weeks,
        is_grammer: isGrammar,
        is_gov_trial: isGovIntro,
        title,
        is_monthly_trial: isMonthlyTrial,
      },
    } = payContext;
    // 자유 수강권 이벤트로 추가
    if (payContext.course.keyword === "free_pass") {
      return (
        <>
          <div className={cx("course-info")}>
            <span className={cx("course-others")}>
              수강권 사용기간: ~{" "}
              {getISODateAsKoreanWithYear(addDays(new Date(), 367), true)}
            </span>
          </div>
          <div className={cx("course-info")}>
            <span className={cx("course-others")}>
              {/* *문법 강의 수강권 특별 증정 */}
            </span>
          </div>
        </>
      );
    } else if (payContext.course.keyword === "sparta_free_pass") {
      return (
        <>
          <div className={cx("course-info")}>
            <span className={cx("course-others")}>
              수강권 사용기간: ~ {/* next 1 year */}
              {getISODateAsKoreanWithYear(addDays(new Date(), 367), true)}
            </span>
          </div>
          <div className={cx("course-info")}>
            <span className={cx("course-others")}>
              {/* *문법 강의 수강권 특별 증정 */}
            </span>
          </div>
        </>
      );
    } else {
      const isRegularCourse = weeks > 0 && !isGrammar;
      return (
        <div className={cx("course-info")}>
          <span className={cx("course-others")}>
            <span>
              {!isMonthlyTrial &&
                payContext.course.days < 7 &&
                `수강 기간 ${payContext.course.days}일`}
              {isRegularCourse &&
                (payContext.course.keyword === "extend"
                  ? `오늘부터 ${
                      payContext.course.weeks ? payContext.course.weeks : "5"
                    }주 연장!`
                  : selectedChoices.length > 0
                  ? getPaymentOptionDesc()
                  : `수강 기간 ${payContext.course.weeks}주`)}
              {isGrammar || isMonthlyTrial
                ? isGovIntro
                  ? "지금 바로 수강 가능"
                  : "수강기간 평생!"
                : ""}
            </span>
          </span>
        </div>
      );
    }
  };

  const StartDate = () => {
    const {
      course: { is_gov_trial: isGovIntro },
    } = payContext;
    const keyword = payContext.course.keyword;
    // 자유 수강권 이벤트로 추가
    if (keyword === "sparthon" || keyword === "free_pass") {
      return null;
    } else {
      return (
        <div className={cx("course-info")}>
          {isStarted && (
            <span className={cx("course-others")}>
              {isGovIntro
                ? "국민내일배움카드 발급부터 강의신청까지 가장 쉽고 빠른 합류 안내"
                : payContext.course.keyword === "sparta_free_pass"
                ? ""
                : "지금 바로 수강 가능"}
            </span>
          )}
          {!isStarted && (
            <span className={cx("course-others")}>
              수강 시작 {new Date(payContext.course.start_date).getMonth() + 1}
              월 {new Date(payContext.course.start_date).getDate()}일(
              {getDayOfWeek(new Date(payContext.course.start_date))})
            </span>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {/*패키지 판매가 아닌 경우*/}
      {payContext.course && !payContext.course.is_temp_package && (
        <div className={cx("course")}>
          <Thumbnail />
          <div className={cx("course-body")}>
            <Title />
            <Duration />
            <StartDate />
          </div>
        </div>
      )}
      {/*패키지 판매인 경우*/}
      {payContext.course && payContext.course.is_temp_package && (
        <>
          <div className={cx("course", "gift")}>
            <img
              className={cx("course-img")}
              src={`/assets/images/payment/thumb/package_signature.png`}
              alt="course_thumb"
            />
            <div className={cx("course-body")}>
              <div className={cx("course-info")}>
                <span className={cx("course-title")}>
                  {payContext.course.title}
                </span>
              </div>
              <div className={cx("course-info")}>
                <span className={cx("course-others")}>
                  사용기간: 1년 내 언제든 사용 가능
                </span>
              </div>
              <div className={cx("course-info")}>
                <span className={cx("course-others")}>
                  {payContext.course.package_courses}
                </span>
              </div>
            </div>
          </div>
          <div className={cx("course", "gift")}>
            <img
              className={cx("course-img")}
              src={`/assets/images/payment/thumb/python_grammer.png`}
              alt="course_thumb"
            />
            <div className={cx("course-body")}>
              <div className={cx("course-info")}>
                <span className={cx("course-title")}>파이썬 문법 101</span>
              </div>
              <div className={cx("course-info")}>
                <span className={cx("course-others")}>평생소장</span>
              </div>
              <div className={cx("course-info")}>
                <img src={"/assets/icons/ic_gift.svg"} alt="icon for gift" />
                <span className={cx("course-others", "gift")}>
                  특별 증정 이벤트
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, paymentInfoByCourseId, paymentOption } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    paymentInfoByCourseId,
    paymentOption,
  };
}

const connectedCourseSummary = connect(mapStateToProps, {})(CourseSummary);
export { connectedCourseSummary as CourseSummary };

function getDayOfWeek(date) {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek)
    ? null
    : ["일", "월", "화", "수", "목", "금", "토"][dayOfWeek];
}
