export const ROUND_SET = {
  ROUND_SET_REQUEST: "ROUND_SET_REQUEST",
  ROUND_SET_SUCCESS: "ROUND_SET_SUCCESS",
  ROUND_SET_FAILURE: "ROUND_SET_FAILURE",
};

export const ROUND_TEST = {
  REQUEST: "ROUND_TEST_REQUEST",
  SUCCESS: "ROUND_TEST_SUCCESS",
  FAILURE: "ROUND_TEST_FAILURE",
};
