import styled from "@emotion/styled";
import { mBody1 } from "@teamsparta/design-system";

export const Container = styled.div`
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  opacity: ${(props) => (props.isAllAnswered ? "1" : "0.3")};
  background: #e8344e;
`;

export const Text = styled.div`
  ${mBody1};
  color: var(--gray-white-day, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
`;
