import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100vw;

  margin-bottom: 60px;
  ${Device.Desktop} {
    width: fit-content;
    padding-top: 60px;
  }
`;

export const BtnBoxContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 30px;

  width: 328px;
  height: 310px;

  background: #ffffff;

  border: 1px solid #eef3f6;

  box-shadow: 0px 8px 24px rgba(95, 102, 107, 0.12);
  border-radius: 12px;

  ${Device.Desktop} {
    justify-content: space-between;
    width: 388px;
    height: 332px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  width: 100%;
`;

export const BoxTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  display: flex;
  align-items: center;

  color: #141617;

  ${Device.Desktop} {
    font-size: 20px;
  }
`;

export const InnerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const BoxText = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  color: #5f666b;

  ${Device.Desktop} {
    font-size: 16px;
  }
`;

export const BoxTextBlack = styled.span`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  color: #141617;
`;

export const BtnWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
`;

export const BtnRed = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 268px;
  height: 60px;

  background: #e8344e;
  border-radius: 8px;

  cursor: pointer;

  ${Device.Desktop} {
    width: 328px;
  }
`;

export const BtnYellow = styled(BtnRed)`
  background: #fae301;
`;

export const BtnText = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;

  ${Device.Desktop} {
  }
`;

export const BtnTextColor = styled(BtnText)`
  color: #141617;
`;
