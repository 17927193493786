import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions, liveUserActions } from "../../../_state/actions";
import { BroadcastList } from "../BroadcastList/BroadcastList";
import classNames from "classnames/bind";
import styles from "./LearnTimeContainer.module.scss";
import CountUp from "react-countup";

const cx = classNames.bind(styles);

function LearnTimeContainer({
  user,
  liveUser,
  enrolled,
  enrolledPlaytime,
  getEnrolledPlaytime,
}) {
  useEffect(() => {
    if (enrolled.loaded) {
      getEnrolledPlaytime(enrolled.data.enrolled_id);
    }
  }, [enrolled]);
  return (
    <span className={cx("learntime-container")}>
      {(enrolledPlaytime.loaded && enrolledPlaytime.data.today_playtime) || 0}분
      공부중
      <img src={`/assets/images/characters/rtan_ongoing.png`} alt="" />
    </span>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled, enrolledPlaytime } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledPlaytime,
  };
}

const connectedLearnTimeContainer = connect(mapStateToProps, {
  getEnrolledPlaytime: enrolledActions.getEnrolledPlaytime,
})(LearnTimeContainer);

export { connectedLearnTimeContainer as LearnTimeContainer };
