import * as S from "./textArea.style";

const TextArea = ({ placeholder, answer, setAnswer, questionId }) => {
  return (
    <S.Container
      placeholder={placeholder}
      value={answer}
      onChange={(e) => {
        setAnswer(questionId, e.target.value);
      }}
    ></S.Container>
  );
};

export default TextArea;
