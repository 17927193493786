import styled from "@emotion/styled";
import {
  additional,
  mBody1,
  mBody3,
  neutralDay,
  wBody1,
  wTitle1,
  wTitle4,
  wBody4,
  mBody4,
  mHeader2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../_styles/device";

export const PageContainer = styled.div`
  background-color: ${neutralDay.gray0};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const HomeworkContainer = styled.div`
  width: 956px;
  padding: 66px 120px 100px 120px;
  background-color: ${neutralDay.gray0};
  ${DisplayResolution.Mobile} {
    max-width: 100%;
    padding: 74px 16px;
    background-color: ${neutralDay.gray0};
  }
`;

export const HomeworkFeedbackTitle = styled.div`
  ${wTitle1};
  color: ${neutralDay.gray100};
  margin: 20px 0;
  ${DisplayResolution.Mobile} {
    ${mHeader2};
    margin: 16px 0;
  }
`;

export const Explanation = styled.div`
  ${wBody4};
  color: ${neutralDay.gray80};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  margin-top: 40px;

  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
`;

export const SubjectTitle = styled.div`
  ${wTitle4};
  color: ${neutralDay.gray100};
  margin-top: 32px;
  ${DisplayResolution.Mobile} {
    max-width: 300px;
  }
  cursor: pointer;
`;

export const NoHomework = styled.div`
  display: flex;
  padding: 40px 32px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: ${neutralDay.gray10};
  margin: 32px 0;
  ${DisplayResolution.Mobile} {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin: 32px 0;
  }
`;

export const NoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NoticeSubtitle = styled.div`
  ${wBody1};
  color: ${neutralDay.gray80};
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: ${({ isList }) => (isList ? "20px" : "0")};
  ${DisplayResolution.Mobile} {
    ${mBody1};
    color: ${neutralDay.gray80};
    align-items: flex-start;
  }
`;

export const NoticeContent = styled.div`
  ${wBody4};
  color: ${neutralDay.gray80};
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  ${DisplayResolution.Mobile} {
    ${mBody4};
    color: ${neutralDay.gray80};
    padding-left: 20px;
  }
`;

export const ToCatalog = styled.div`
  ${wBody1};
  color: ${additional.blue100};
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
  ${DisplayResolution.Mobile} {
    ${mBody3};
    color: ${additional.blue100};
    padding-left: 20px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  ${DisplayResolution.Mobile} {
    width: 12px;
  }
`;

export const FootnoteText = styled.div`
  ${wBody4};
  font-size: 14px;
  color: ${neutralDay.gray70};
  ${DisplayResolution.Mobile} {
    ${mBody4};
    color: ${neutralDay.gray70};
    font-size: 13px;
  }
`;
