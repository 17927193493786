import React, {useEffect, useState} from 'react'
import classNames from "classnames/bind";
import styles from "./CourseRecommendation.module.scss";
import {connect} from "react-redux";
import {history} from "../../../../_helpers";
import {courseRetentionActions} from "../../../_state/actions";

const cx = classNames.bind(styles);

export const ImportFrom = {
    SECRET_STORE: 'SECRET_STORE',
    CLASSROOM: 'CLASSROOM',
};

function CourseRecommendation({
                                  type,
                                  courseRetention,
                                  getCourseRetention,

                                  classroomCards,

                                  // 비밀의상점
                                  course_id,
                                  header,
                              }) {
    /**
     * type
     * - classroom: 내강의실에서 임포트하는 경우
     *   추천과목으로 classroomCards 이용
     * - secretstore: 비밀의상점에서 임포트하는 경우
     *   course_id, header 커스텀
     */
    const [mainCopy, setMainCopy] = useState('');
    useEffect(() => {
        if (classroomCards.loaded) {
            const enrolled_list = classroomCards.data.full_enrolled_info_list;
            const recent_course_id = enrolled_list.length > 0 ? enrolled_list[0]['course_id'] : '';
            getCourseRetention(recent_course_id);
            setMainCopy('최적의 수업을\n찾는 중입니다.');
        } else if (course_id) {
            getCourseRetention(course_id);
        }
    }, [classroomCards.loaded]);

    useEffect(() => {
        if (courseRetention.loaded) {
            if (classroomCards.loaded) {
                if (classroomCards.data.full_enrolled_info_list.length > 0) {
                    setMainCopy(`${classroomCards.data.full_enrolled_info_list[0]['title']}을 들은 사람들이\n다음으로 선택한 강의`);
                } else {
                    setMainCopy('처음 듣는 사람들이\n많이 선택한 강의');
                }
            }
        }
    }, [courseRetention.loaded]);

    return (
        <div>
            {(classroomCards.loaded || course_id) && courseRetention.loaded && courseRetention.data.total > 10 &&
            <div>
                {type === ImportFrom.CLASSROOM &&
                <div className={cx('dotted')}/>
                }
                <div className={cx('course-reco-wrap')}>
                    <h3 className={cx('course-reco-title')}>
                        {type === ImportFrom.CLASSROOM && mainCopy}
                        {type === ImportFrom.SECRET_STORE && header}
                    </h3>
                    <div className={cx('course-reco-body')}>
                        <div className={cx('course-reco-card')}>
                            <div className={cx('course-reco-main')}>
                                <p>
                                    <b>
                                        {courseRetention.data.primary.percent}
                                    </b>%
                                </p>
                            </div>
                            <div className={cx('course-reco-desc')}>
                                의 사람들이<br/>
                                '{courseRetention.data.primary.title}'를 듣고 있어요!
                            </div>
                            <div className={cx('course-reco-cta')}>
                                <button className={cx('course-reco-main-cta')} onClick={() => {
                                    window.location.href = `https://spartacodingclub.kr/online/${courseRetention.data.primary.keyword}`;
                                }}>자세히 보러가기
                                </button>
                                <p className={cx('course-reco-sub-cta')} onClick={() => {
                                    history.push(`/payment/${courseRetention.data.primary.course_id}`)
                                }}>👉&nbsp;내 포인트로 바로 신청하기</p>
                            </div>
                        </div>
                        <div className={cx('course-reco-card')}>
                            <div className={cx('course-reco-main')}>
                                <p>
                                    <b>
                                        {courseRetention.data.secondary.percent}
                                    </b>
                                    %
                                </p>
                            </div>
                            <div className={cx('course-reco-desc')}>
                                의 사람들이<br/>
                                '{courseRetention.data.secondary.title}'를 듣고 있어요!
                            </div>
                            <div className={cx('course-reco-cta')}>
                                <button className={cx('course-reco-main-cta')} onClick={() => {
                                    window.location.href = `https://spartacodingclub.kr/online/${courseRetention.data.secondary.keyword}`;
                                }}>자세히 보러가기
                                </button>
                                <p className={cx('course-reco-sub-cta')} onClick={() => {
                                    history.push(`/payment/${courseRetention.data.secondary.course_id}`)
                                }}>👉&nbsp;내 포인트로 바로 신청하기</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

function mapStateToProps(state) {
    const {authentication, courseRetention, classroomCards} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        courseRetention,
        classroomCards
    }
}

const connectedCourseRecommendation = connect(mapStateToProps, {
    getCourseRetention: courseRetentionActions.getCourseRetention
})(CourseRecommendation);
export {connectedCourseRecommendation as CourseRecommendation}