import { useRef, useCallback } from "react";

function useThrottle(callback, limit) {
  const lastCall = useRef(0);
  const pending = useRef(null);
  const lastResult = useRef(null);

  const throttledCallback = useCallback(
    (...args) => {
      const now = Date.now();
      clearTimeout(pending.current);

      if (now - lastCall.current >= limit) {
        lastResult.current = callback(...args);
        lastCall.current = now;
      } else {
        pending.current = setTimeout(() => {
          lastResult.current = callback(...args);
          lastCall.current = now;
        }, limit - (now - lastCall.current));
      }
    },
    [callback, limit]
  );

  const cancel = useCallback(() => {
    clearTimeout(pending.current);
    pending.current = null;
  }, []);

  return [throttledCallback, cancel, () => lastResult.current];
}

export default useThrottle;
