import { LecPlayerDesktopAndMobileV3 } from "./desktop";
import { useSelector } from "react-redux";
import { useSelectPlayerType } from "../../../LecturePageV3/useSelectPlayerType";
import { useState } from "react";
import { useAtom } from "jotai";
import { readyToGoNextLectureAtom } from "../../../../stores/mobileLectureNextButton";
import { useDoEnrolledDetailOnce } from "../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { StudyCheckpoint } from "../../../../businesslogics/OrderedEnrollmentContents/model/StudyCheckpoint";
export const LecPlayerV3 = ({ playerType, setPlayerType }) => {
  const [, setIsCanSeek] = useState(true);
  const [, setHasSpeedLimit] = useState(true);
  const lecture_v2 = useSelector((state) => state.lecture_v2);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const [, setIsMobileNextButtonShow] = useAtom(readyToGoNextLectureAtom);
  useSelectPlayerType(setPlayerType, setHasSpeedLimit, setIsCanSeek);
  const doEnrolledDetail = useDoEnrolledDetailOnce();

  return (
    <LecPlayerDesktopAndMobileV3
      playerType={playerType}
      lecture_v2={lecture_v2}
      enrolledDetail={enrolledDetail}
      onTimeUpdate={(timeUpdatePercentage, isPlaying) => {
        if (timeUpdatePercentage > 0.9) {
          setIsMobileNextButtonShow(true);
        }
        if (timeUpdatePercentage > 0.5) {
          if (isPlaying) {
            doEnrolledDetail(
              enrolledDetail.data?.enrolled_id,
              enrolledDetail.data?.enrolled_detail_id,
              StudyCheckpoint.PlayHalfOver
            );
          }
        }
      }}
      onPlay={() => {
        doEnrolledDetail(
          enrolledDetail.data?.enrolled_id,
          enrolledDetail.data?.enrolled_detail_id,
          StudyCheckpoint.PlayButtonClick
        );
      }}
    />
  );
};
