import { useEffect } from "react";
import { connect } from "react-redux";
import { usersActions } from "../../_state/actions";

const RcmdFreePage = ({ user, loginWithToken }) => {
  useEffect(() => {
    window.location.href = "https://spartacodingclub.kr/rcmdsfree"
  }, []);

  return (
    <></>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedRcmdFreePage = connect(mapStateToProps, {
  loginWithToken: usersActions.loginWithToken,
})(RcmdFreePage);
export { connectedRcmdFreePage as RcmdFreePage };
