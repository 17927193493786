import { LecMenuBottomSheet } from "../../LecturePage/LecMenu/LecMenuBottomSheet/LecMenuBottomSheet";
import * as S from "../../LecturePage/LecMenu/LecMenuGNB/LecMenuGNB.style";
import React, { useState } from "react";
import { sendLog } from "../../../businesslogics/logging";
import { history } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { ArrowBlackLeftSvg, MoreOptionsSvg } from "../Icons";
import { useGoRoadmapPage, useInitializeMaterial } from "../useInitialize";
import { useOnlineTheme } from "../../../queries/pirates";
import { useParams } from "react-router-dom";

const optionWithFaqArray = [
  { label: "오류 제보", value: "오류 제보" },
  { label: "FAQ", value: "FAQ" },
];

const optionWithoutFaq = [{ label: "오류 제보", value: "오류 제보" }];

export const LectureMobileNavBar = () => {
  const { enrolled_id } = useParams();
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  const [isFaqExist, setIsFaqExist] = useState(false);
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const material = useInitializeMaterial(
    enrolled_id,
    enrolledDetail.data?.enrolled_detail_id,
    setIsFaqExist
  );
  const goRoadmap = useGoRoadmapPage(enrolled_id);
  const selectMoreOptions = (target) => {
    if (target.innerText === "오류 제보") {
      sendLog(
        "scc_lecturePage_click_button",
        {
          ...enrolled.data,
          ...enrolledDetail.data,
          button_text: "오류 제보",
          button_name: "오류 제보 버튼",
          next_page: "오류 제보 페이지",
        },
        null
      );
      history.push(
        `/m/lecture_report/enrolleds/${enrolled.data.enrolled_id}/edetails/${enrolledDetail.data.enrolled_detail_id}`
      );
    } else if (target.innerText === "FAQ") {
      sendLog(
        "scc_lecturePage_click_button",
        {
          ...enrolled.data,
          ...enrolledDetail.data,
          button_text: "FAQ",
          button_name: "FAQ 버튼",
          next_page: "faq 자료 링크 페이지",
        },
        null
      );
      window.open(material.data.faq_url, "_blank");
      return;
    }
  };

  const openMoreOptions = () => {
    if (!isOpenBottomSheet) setIsOpenBottomSheet(true);
    else setIsOpenBottomSheet(false);
  };
  const theme = useOnlineTheme();
  return (
    <>
      {isOpenBottomSheet && (
        <LecMenuBottomSheet
          defaultValue={"더보기"}
          optionList={isFaqExist ? optionWithFaqArray : optionWithoutFaq}
          openFlag={isOpenBottomSheet}
          handleOpenFunction={openMoreOptions}
          handleSelectFunction={selectMoreOptions}
        />
      )}
      <S.GNBContainer theme={theme.data}>
        <S.GNBgoRoadmap
          onClick={() => {
            sendLog(
              "scc_lecturePage_click_button",
              {
                ...enrolled.data,
                ...enrolledDetail.data,
                button_text: "",
                button_name: "진도표 가기 버튼",
                next_href: `/enrolleds/${enrolled.data?.enrolled_id}/rounds/${enrolled.data?.round_id}/roadmap`,
                next_page: "진도표 페이지",
              },
              ""
            );
            goRoadmap();
          }}
        >
          <ArrowBlackLeftSvg theme={theme.data} />
        </S.GNBgoRoadmap>
        <S.GNBmoreOptions
          onClick={() => {
            sendLog(
              "scc_lecturePage_click_button",
              {
                ...enrolled.data,
                ...enrolledDetail.data,
                button_text: "",
                button_name: "더보기 버튼",
                next_page: "",
              },
              ""
            );
            openMoreOptions();
          }}
        >
          <MoreOptionsSvg theme={theme.data} />
        </S.GNBmoreOptions>
      </S.GNBContainer>
    </>
  );
};
