import React from "react";
import * as S from "./SparthonPaymentDonte.style";

const SparthonPaymentDone = ({ name }) => {
  return (
    <S.Container>
      <S.Row>
        <S.ApplyDone>
          {name} 님, <br />
          참가 신청이 완료되었어요!
        </S.ApplyDone>
        <S.CouponDesc>
          할인 쿠폰은 마이페이지에서 확인할 수 있습니다.
        </S.CouponDesc>
      </S.Row>
      <S.Row>
        <S.CouponImgContainer>
          <S.CouponImg
            src={("/v2/assets/images/sparthon/coupon.svg")}
          />
        </S.CouponImgContainer>
        <S.CouponInfo>
          * 2022년 9월 10일 자정까지 사용 가능
          <br />* 쿠폰 사용 시, 스파르톤 참여 필수
        </S.CouponInfo>
      </S.Row>
      <S.DivideContainer>
        <S.DivideLine />
        <S.DivideContent>쿠폰 적용 과목</S.DivideContent>
      </S.DivideContainer>
      <S.Row>
        <S.CoursesContainer>
          <S.Course
            href={"https://spartacodingclub.kr/online/web"}
            target={"_blank"}
          >
            <S.CourseImg
              src={("/v2/assets/images/sparthon/web.png")}
            />
          </S.Course>
          <S.Course
            href={"https://spartacodingclub.kr/online/sql"}
            target={"_blank"}
          >
            <S.CourseImg
              src={("/v2/assets/images/sparthon/sql.png")}
            />
          </S.Course>
          <S.Course
            href={"https://spartacodingclub.kr/online/finance_automation"}
            target={"_blank"}
          >
            <S.CourseImg
              src={("/v2/assets/images/sparthon/finance_automation.png")}
            />
          </S.Course>
        </S.CoursesContainer>
      </S.Row>
      <S.Row>
        <S.CtaContainer
          href={"https://spartacodingclub.kr/catalog/featured/summary"}
          target={"_blank"}
        >
          <S.Cta>수강신청하러 가기</S.Cta>
        </S.CtaContainer>
      </S.Row>
    </S.Container>
  );
};

export default SparthonPaymentDone;
