import styled from "@emotion/styled";
import {
  neutralDay,
  scc,
  wBody4,
  wCaption1,
  wTitle1,
} from "@teamsparta/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 18px;
  gap: 16px;

  width: 282px;
  height: 345px;

  background: #ffffff;
  border-radius: 24px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 246px;
  height: 130px;
`;

export const UseInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  height: 20px;
`;

export const UserName = styled.div`
  ${wCaption1};
  color: ${neutralDay.gray70};
  font-weight: 500;
`;

export const DivierColum = styled.div`
  width: 1px;
  flex-shrink: 0;
  height: 10px;
  background: #c7d2d8;
`;

export const UserDone = styled.div`
  ${wCaption1};
  color: ${neutralDay.gray90};
`;

export const RateText = styled.div`
  ${wTitle1};
`;

export const RtanImage = styled.img`
  width: 246px;
`;

export const StateBoardWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px 12px 10px;
  gap: 12px;

  width: 246px;
  height: 159px;

  background: #f6f9fa;

  border: 1px solid #eef3f6;
  border-radius: 8px;
`;

export const StateBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 228px;
  height: 135px;
`;

export const StateItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 10px;

  width: 228px;
  height: 21px;
`;

export const StateItemTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
`;

export const StateItemRateText = styled.span`
  ${wBody4};
  font-weight: 500;
`;

export const StateItemText = styled.span`
  ${wCaption1};
  color: ${(props) =>
    props.step > 3 ? `${scc.red75}` : `${neutralDay.gray80}`};
  font-weight: 500;
`;

export const DivierRow = styled.div`
  width: 216px;
  height: 1px;

  background: #eef3f6;
  flex-shrink: 0;
`;
