import * as S from "../HomeworkAnswer.style";
import {
  ActionPlus,
  ContentsLink,
  NavigationClose,
  neutralDay,
} from "@teamsparta/design-system";
import React, { useCallback } from "react";
import { useFileUpload } from "../../../../../hooks/homeworkV3/useFileUpload";
import { useIsMobile } from "../../../../../hooks/device";
import {
  useCurrentHomeworkDetail,
  useHandleEnrolledHomeworkDetails,
} from "../../../../../hooks/homeworkV3/useHomeworkData";

export const FileUploadAnswer = ({
  status,
  homeworkIndex,
  isFeedbackRequired,
}) => {
  const isMobile = useIsMobile();
  const { hiddenFileInput, handleChange, handleFileUploadButtonClick } =
    useFileUpload({
      homeworkIndex,
    });

  const { isAnswered, fileName } = useCurrentHomeworkDetail(homeworkIndex);
  const { resetEnrolledHomeworkDetail } = useHandleEnrolledHomeworkDetails();

  const handleCancelSubmit = () => {
    resetEnrolledHomeworkDetail(homeworkIndex);
  };

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        handleChange(e);
      }
    },
    [handleChange]
  );

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <>
      <S.AnswerInstructionBox>
        <S.AnswerTitle>
          아래 안내를 따라 숙제 파일을 업로드 해주세요.
        </S.AnswerTitle>
        <S.AnswerInstructionContainer>
          1. 실습 지시사항을 따라 실습을 진행해 주세요.
          <br />
          2. ‘강의제목_이름.zip’ 형식으로 실습한 폴더를 압축 후 제출해 주세요.
        </S.AnswerInstructionContainer>
      </S.AnswerInstructionBox>
      {isAnswered ? (
        <S.SubmitDoneContainer>
          <S.SubmitDoneTitleWrapper>
            <ContentsLink size={20} color={neutralDay.gray70} />
            <S.SubmitDoneFileTitle>{fileName}</S.SubmitDoneFileTitle>
          </S.SubmitDoneTitleWrapper>
          {/*ACCEPTED, RESOLVED 상태에서는 취소되지 않도록 함*/}
          <S.CancelSubmitButton
            onClick={handleCancelSubmit}
            status={status}
            isFeedbackRequired={isFeedbackRequired}
          >
            <NavigationClose
              size={isMobile ? 16 : 20}
              color={neutralDay.gray70}
            />
          </S.CancelSubmitButton>
        </S.SubmitDoneContainer>
      ) : (
        <S.SubmitFileContainer onDrop={handleDrop} onDragOver={handleDragOver}>
          <S.SubmitFileTitleWrapper>
            <S.SubmitFileTitle>제출할 파일 선택하기</S.SubmitFileTitle>
            <S.SubmitFileSubtitle>
              zip 압축파일 1개의 형태만 제출 가능 (최대 300MB)
            </S.SubmitFileSubtitle>
          </S.SubmitFileTitleWrapper>
          <S.SubmitFileButton onClick={handleFileUploadButtonClick}>
            <ActionPlus size={16} color={neutralDay.gray90} />
          </S.SubmitFileButton>
          <S.FileInput
            type={"file"}
            ref={hiddenFileInput}
            onChange={handleChange}
          />
        </S.SubmitFileContainer>
      )}
    </>
  );
};
