import * as S from "./nps.style";
import { QUESTION_TYPES, questions } from "./nps.model";
import ListSelector from "./ListSelector/ListSelector";
import Question from "./Question/Question";
import ScoreSelector from "./ScoreSelector/ScoreSelector";
import TextArea from "./TextArea/TextArea";
import { useEffect, useRef, useState } from "react";
import { useOnClickSubmitFreeNps } from "../../../businesslogics/nps/free";
import { useDefaultNextStep } from "../../../queries/nps";
import { devCareerPrepCoursesList } from "../../FreeFinishPage/variables";

const Nps = ({ user, enrolled }) => {
  const [answers, setAnswers] = useState({}); // { [questionId]: answer }
  const [isAnswerValid, setIsAnswerValid] = useState(false);
  const [intersectingStatus, setIntersectingStatus] = useState({
    nps: false,
    cta: false,
  });
  const [isCtaFloating, setIsCtaFloating] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [questionsToShow, setQuestionsToShow] = useState(questions);
  const defaultNextStep = useDefaultNextStep(enrolled.data?.course_id);
  const npsRef = useRef(null);
  const ctaRef = useRef(null);

  const onClickCta = useOnClickSubmitFreeNps(
    questionsToShow,
    answers,
    enrolled,
    isPosting,
    setIsPosting
  );

  const onSetAnswer = (id, answer) => {
    setAnswers((prev) => {
      let copy = { ...prev };
      copy[id] = answer;
      return copy;
    });
  };

  useEffect(() => {
    if ((defaultNextStep.isSuccess && !!defaultNextStep.data)|| devCareerPrepCoursesList.includes(enrolled.data?.course_id)) {
      setQuestionsToShow(
        questions.filter((question) => question.id !== "goal")
      );
      onSetAnswer("goal", defaultNextStep.data);
    }
  }, [defaultNextStep.isSuccess]);

  useEffect(() => {
    setIsAnswerValid(
      questionsToShow
        .filter((question) => question.isNecessary)
        .every(
          (question) =>
            answers[question.id] !== undefined && answers[question.id] !== ""
        )
    );
  }, [answers]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      if (typeof window === "undefined") return;
      entries.forEach((entry) => {
        if (entry.target === npsRef.current) {
          setIntersectingStatus((prevStatus) => ({
            ...prevStatus,
            nps: entry.isIntersecting,
          }));
        }
        if (entry.target === ctaRef.current) {
          setIntersectingStatus((prevStatus) => ({
            ...prevStatus,
            cta: entry.isIntersecting,
          }));
        }
      });
    });

    if (npsRef.current && ctaRef.current) {
      observer.observe(npsRef.current);
      observer.observe(ctaRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!intersectingStatus.nps) setIsCtaFloating(false);
    else setIsCtaFloating(!intersectingStatus.cta);
  }, [intersectingStatus]);

  return (
    <S.Wrapper ref={npsRef}>
      <S.Container>
        <S.Title>
          {user.name}님, <br />
          완주하신 소감을 들려주세요.
        </S.Title>
        <S.Desc>
          남겨주신 만족도 및 후기는 통계적 목적 또는 마케팅 목적으로 활용될 수 있습니다.
          <br />
          활용 시 개인정보는 보호됩니다(ex. 수강생명: 김**).
        </S.Desc>
        <S.Questions>
          {defaultNextStep.isSuccess &&
            questionsToShow.map((question, index) => {
              const params = {
                answer: answers[question.id],
                setAnswer: onSetAnswer,
                questionId: question.id,
              };
              return (
                <S.Question key={question.id}>
                  <Question index={index} title={question.title} />
                  {
                    {
                      [QUESTION_TYPES.LIST]: (
                        <ListSelector options={question.options} {...params} />
                      ),
                      [QUESTION_TYPES.SCORE]: <ScoreSelector {...params} />,
                      [QUESTION_TYPES.TEXT]: (
                        <TextArea
                          placeholder={question.placeholder}
                          {...params}
                        />
                      ),
                    }[question.type]
                  }
                </S.Question>
              );
            })}
        </S.Questions>
        <S.Cta ref={ctaRef} isActive={isAnswerValid} onClick={onClickCta}>
          {isPosting ? "잠시만 기다려주세요" : "제출하고 선물 받기"}
        </S.Cta>
        <S.FloatingWrapper isShow={isCtaFloating}>
          <S.Cta isActive={isAnswerValid} onClick={onClickCta}>
            {isPosting ? "잠시만 기다려주세요" : "제출하고 선물 받기"}
          </S.Cta>
        </S.FloatingWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default Nps;
