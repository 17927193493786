export function reducer(state, action, constants) {
  switch (action.type) {
    case constants.REQUEST:
      return { ...state, loaded: false };
    case constants.SUCCESS:
      return { ...state, loaded: true, data: action.response };
    case constants.FAILURE:
      return { ...state, loaded: false };
    default:
      return state;
  }
}
