import * as S from "./feedbackCta.style";
import {
  useModalAndBottomSheet,
  useModalAndBottomSheetOnPlayer,
} from "../../organisms/Modal/ModalBottomSheetWrapper";
import { userFeedbackDataModels } from "../../../models/UserFeedback";
import React, { useEffect, useRef, useState } from "react";
import { usePostFeedback } from "../../../pages/LecturePageV3/usePostFeedback";
import videojs from "video.js";
import {
  useCheckCurrentPlayerTime,
  useCheckCurrentPlayerTimeWithEnrolledDetail,
  handleFeedbackTooltipSeenCount,
} from "../../../businesslogics/LecturePageFeedback";
import { useUser } from "../../../pages/LecturePageV3/useInitialize";
import { SELECT_BUFFER, TOOLTIP_SEEN_LIMIT } from "./constants";
import {NavigationClose} from "@teamsparta/design-system";
const FeedbackTooltip = React.memo(({ isTooltipShow, setIsTooltipShow }) => {
  const [feedbackTooltipSeenCount, setFeedbackTooltipSeenCount] =
    handleFeedbackTooltipSeenCount();
  useEffect(() => {
    if (
      feedbackTooltipSeenCount &&
      feedbackTooltipSeenCount < TOOLTIP_SEEN_LIMIT
    ) {
      setIsTooltipShow(true);
      setFeedbackTooltipSeenCount(feedbackTooltipSeenCount + 1);
    }
    if (!feedbackTooltipSeenCount) {
      setIsTooltipShow(true);
      setFeedbackTooltipSeenCount(1);
    }
  }, []);

  const closeTooltip = () => {
    setFeedbackTooltipSeenCount(TOOLTIP_SEEN_LIMIT)
    setIsTooltipShow(false);
  }
  return (
    <S.FeedbackTooltipContainer isTooltipShow={isTooltipShow}>
      <S.CloseButtonWrapper onClick={closeTooltip}>
        <NavigationClose size={16} color={"#B4B6B8"}/>
      </S.CloseButtonWrapper>
      <S.BackgroundImg
        src={"/v2/assets/icons/feedback/user-feedback-tooltip.png"}
      />
    </S.FeedbackTooltipContainer>
  );
});

export const FeedbackCta = React.memo(() => {
  const [Wrapper, toggle] = useModalAndBottomSheet();
  const [currentTime, getLectureProgress] = useCheckCurrentPlayerTime();
  const [isTooltipShow, setIsTooltipShow] = useState(false);

  const [feedbackTooltipSeenCount, setFeedbackTooltipSeenCount] =
    handleFeedbackTooltipSeenCount();
  return (
    <S.FeedbackWrapper>
      <S.FeedbackCtaWrapper
        onClick={() => {
          toggle();
          getLectureProgress();
          setIsTooltipShow(false);
          setFeedbackTooltipSeenCount(
            feedbackTooltipSeenCount + TOOLTIP_SEEN_LIMIT
          );
        }}
      >
        <S.FeedbackCtaText>의견 남기기</S.FeedbackCtaText>
      </S.FeedbackCtaWrapper>
      <FeedbackTooltip
        isTooltipShow={isTooltipShow}
        setIsTooltipShow={setIsTooltipShow}
      />
      <Wrapper>
        <FeedbackSelects toggle={toggle} currentTime={currentTime} />
      </Wrapper>
    </S.FeedbackWrapper>
  );
});

export const FeedbackCtaOnPlayer = React.memo(
  ({ videoRef, enrolledDetail }) => {
    const [Wrapper, toggle] = useModalAndBottomSheetOnPlayer();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [bufferedIsFull, setBufferedIsFull] = useState(false);
    const [currentTime, getLectureProgress] =
      useCheckCurrentPlayerTimeWithEnrolledDetail(enrolledDetail);
    const playerRef = useRef(null);
    useEffect(() => {
      if (!videoRef.current) {
        return;
      }
      const player = videojs(videoRef.current);
      playerRef.current = player;
      player.on("fullscreenchange", () => {
        setIsFullScreen(player.isFullscreen());
      });
    }, [videoRef]);
    useEffect(() => {
      if (isFullScreen) {
        setTimeout(() => {
          setBufferedIsFull(isFullScreen);
        }, 700);
      } else {
        setBufferedIsFull(isFullScreen);
      }
    }, [isFullScreen]);
    const openFeedback = () => {
      toggle();
      getLectureProgress();
      if (playerRef.current) {
        playerRef.current.pause();
      }
    };
    const closeFeedback = () => {
      toggle();
      if (playerRef.current) {
        playerRef.current.play();
      }
    };
    return (
      <>
        <S.FeedbackCtaOnPlayerWrapper isFull={bufferedIsFull}>
          <S.FeedbackCtaOnPlayerText>강의 수강 중</S.FeedbackCtaOnPlayerText>
          <S.FeedbackCtaOnPlayer onClick={openFeedback}>
            의견 남기기
          </S.FeedbackCtaOnPlayer>
        </S.FeedbackCtaOnPlayerWrapper>

        <Wrapper>
          <FeedbackSelects toggle={closeFeedback} currentTime={currentTime} />
        </Wrapper>
      </>
    );
  }
);

const FeedbackButtons = ({
  isSelected,
  setIsSelected,
  setSelectedFeedback,
}) => {
  const postFeedback = usePostFeedback(setIsSelected);

  return (
    <S.FeedbackSelectsButtonWrapper isSelected={isSelected}>
      {userFeedbackDataModels.map((feedbackModel) => (
        <S.FeedbackSelectsButton
          key={feedbackModel.id}
          onClick={() => {
            setSelectedFeedback(feedbackModel);
            postFeedback.mutate({
              feedbackValue: feedbackModel.value,
              feedbackComment: "",
            });
          }}
        >
          <S.FeedbackSelectsEmoji src={feedbackModel.src} />
          <S.FeedbackSelectsEmojiText>
            {feedbackModel.text}
          </S.FeedbackSelectsEmojiText>
        </S.FeedbackSelectsButton>
      ))}
      <S.AnotherFeedbackButton
        onClick={() => {
          setSelectedFeedback({
            value: "etc",
          });
        }}
      >
        <S.AnotherFeedbackButtonText>
          다른 의견 남기기
        </S.AnotherFeedbackButtonText>
      </S.AnotherFeedbackButton>
    </S.FeedbackSelectsButtonWrapper>
  );
};

const FeedbackComments = ({ setSelectedFeedback, setIsSelected }) => {
  const postFeedback = usePostFeedback(setIsSelected);
  const [comment, setComment] = useState("");
  const isCommentEdited = comment.length > 0;
  const handleSubmit = () => {
    if (isCommentEdited) {
      setIsSelected(true);
      setSelectedFeedback({
        value: "etc-completed",
      });
      postFeedback.mutate({
        feedbackValue: "etc",
        feedbackComment: comment,
      });
    }
  };
  const handleTextChange = (e) => {
    setComment(e.target.value);
  };
  return (
    <S.FeedbackCommentsWrapper>
      <S.FeedbackCommentsTextArea
        placeholder={"의견을 작성해 주세요."}
        value={comment}
        onChange={handleTextChange}
      />
      <S.FeedbackCommentSubmitButton
        onClick={handleSubmit}
        isDisabled={!isCommentEdited}
      >
        제출하기
      </S.FeedbackCommentSubmitButton>
    </S.FeedbackCommentsWrapper>
  );
};

const FeedbackSelectsOptions = ({
  isSelected,
  setIsSelected,
  selectedFeedback,
  setSelectedFeedback,
}) => {
  if (selectedFeedback && selectedFeedback.value === "etc") {
    return (
      <FeedbackComments
        setSelectedFeedback={setSelectedFeedback}
        setIsSelected={setIsSelected}
      />
    );
  }
  return (
    <FeedbackButtons
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      setSelectedFeedback={setSelectedFeedback}
    />
  );
};

const FeedbackResults = ({ selectedFeedback }) => {
  const [mountFlag, setMountFlag] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMountFlag(false);
    }, 10);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <S.FeedbackResultImage
      src={`/v2/assets/icons/feedback/user-feedback-result-${selectedFeedback?.value}.png`}
      isTrans={mountFlag}
    />
  );
};

export const FeedbackSelects = ({ toggle, currentTime }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [bufferedSelected, setBufferedSelected] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedTitles, setSelectedTitles] = useState({
    title: `${currentTime} 강의 내용은 어떤가요?`,
    subTitle: "개선이 필요할 때에 언제든 남겨주세요.",
  });
  const [wrapperHeight, setWrapperHeight] = useState("483px");
  const user = useUser();
  const userName = user?.name;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setBufferedSelected(isSelected);
    }, SELECT_BUFFER);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isSelected]);
  useEffect(() => {
    let timeoutId = null;
    if (bufferedSelected) {
      timeoutId = setTimeout(() => {
        toggle();
      }, SELECT_BUFFER + 2500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [bufferedSelected]);
  useEffect(() => {
    if (selectedFeedback) {
      switch (selectedFeedback.value) {
        case "good":
          const convertedText = selectedFeedback.resultText.replace(
            "{userName}",
            userName
          );
          setSelectedTitles({
            title: selectedFeedback.resultTitle,
            subTitle: convertedText,
          });
          setWrapperHeight("337px");
          break;
        case "etc":
          setSelectedTitles({
            title: "다른 의견 남기기",
            subTitle: "",
          });
          break;
        case "etc-completed":
          setSelectedTitles({
            title: "소중한 의견 감사해요.",
            subTitle: "확인 후 빠르게 반영하도록 할게요.",
          });
          setWrapperHeight("317px");
          break;
        default:
          setSelectedTitles({
            title: selectedFeedback.resultTitle,
            subTitle: selectedFeedback.resultText,
          });
          setWrapperHeight("337px");
          break;
      }
    }
  }, [selectedFeedback]);
  return (
    <S.FeedbackSelectsWrapper wrapperHeight={wrapperHeight}>
      <S.FeedbackSelectsTitleContainer>
        <S.FeedbackSelectsTitle>{selectedTitles.title}</S.FeedbackSelectsTitle>
        <S.FeedbackSelectsSubTitle>
          {selectedTitles.subTitle}
        </S.FeedbackSelectsSubTitle>
      </S.FeedbackSelectsTitleContainer>
      {bufferedSelected ? (
        <FeedbackResults selectedFeedback={selectedFeedback} />
      ) : (
        <FeedbackSelectsOptions
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          selectedFeedback={selectedFeedback}
          setSelectedFeedback={setSelectedFeedback}
        />
      )}
    </S.FeedbackSelectsWrapper>
  );
};
