import { objectiveService } from "../services";
import {
  GET_STUDY_OBJECTIVE,
  POST_STUDY_OBJECTIVE,
  UPDATE_STUDY_OBJECTIVE,
} from "../constants";

function getStudyObjective(enrolled_id) {
  function request() {
    return { type: GET_STUDY_OBJECTIVE.GET_STUDY_OBJECTIVE_REQUEST };
  }

  function success(response) {
    return { type: GET_STUDY_OBJECTIVE.GET_STUDY_OBJECTIVE_SUCCESS, response };
  }

  function failure(error) {
    return { type: GET_STUDY_OBJECTIVE.GET_STUDY_OBJECTIVE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    objectiveService.getStudyObjective(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function updateStudyObjective(enrolled_id, data) {
  function request() {
    return { type: UPDATE_STUDY_OBJECTIVE.UPDATE_STUDY_OBJECTIVE_REQUEST };
  }

  function success(response) {
    return {
      type: UPDATE_STUDY_OBJECTIVE.UPDATE_STUDY_OBJECTIVE_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return {
      type: UPDATE_STUDY_OBJECTIVE.UPDATE_STUDY_OBJECTIVE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    objectiveService.updateStudyObjective(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postStudyObjective(enrolled_id, data) {
  function request() {
    return { type: POST_STUDY_OBJECTIVE.POST_STUDY_OBJECTIVE_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return objectiveService.postStudyObjective(enrolled_id, data);
  };
}

export const objectiveActions = {
  getStudyObjective,
  postStudyObjective,
  updateStudyObjective,
};
