import styled from "styled-components";

export const SignupHeaderContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0 0 24px 0;

  @media (max-width: 1024px) {
    margin: 48px 0 24px 0;
  }
`;

export const SignupHeaderText = styled.p`
  text-align: center;
  margin: 0;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  white-space: pre-wrap;
`;

export const SubTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 24px;
`;
