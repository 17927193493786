import { createBrowserHistory } from "history";

export function params() {
  let paramsDict = {};
  let paramsString = createBrowserHistory().location.search;
  if (paramsString.length > 0) {
    let paramsList = paramsString.split("?")[1].split("&");
    for (const p of paramsList) {
      let part = p.split("=");
      paramsDict[part[0]] = part[1];
    }
  }
  return { ...paramsDict };
}
