import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./BroadcastList.style";
import { useGetLiveUser } from "../../../../_state/actions";
import { useGetBroadcastList } from "../../../../_state/actions/broadcast.actions";
import { sendLog } from "../../../../businesslogics/logging";
import { useOnlineTheme } from "../../../../queries/pirates";

export const BroadcastList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const liveUser = useSelector((state) => state.liveUser);
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const getLiveUser = useGetLiveUser();
  const broadcastList = useSelector((state) => state.broadcastList);
  const getBroadcastList = useGetBroadcastList();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!liveUser.loaded) {
      getLiveUser(dispatch);
    }
  }, [liveUser.loaded]);
  useEffect(() => {
    if (!broadcastList.loaded) {
      getBroadcastList(dispatch);
    }
  }, [broadcastList.loaded]);
  const theme = useOnlineTheme();
  // broadcastcast list 만드는 부분 진행 중이였음
  return (
    <S.BroadcastListContainer>
      <S.BroadcastListInnerContainer
        theme={theme.data}
        onClick={() => {
          sendLog(
            "scc_lecturePage_click_button",
            {
              ...enrolled.data,
              ...enrolledDetail.data,
              button_text: "live user",
              button_name: "라이브 유저 버튼",
              next_page: "",
            },
            "",
            true
          );
          setIsOpen(!isOpen);
        }}
      >
        <S.IconImg src="/assets/tapmenu/liveuser_icons.png" alt="" />
        <S.Live theme={theme.data}>
          Live <div />
        </S.Live>
        {liveUser.data?.count}명 수강 중
        {/* <S.ArrowContainer>
          {isOpen ? (
            <ArrowIcon direction={"up"} theme={theme.data} />
          ) : (
            <ArrowIcon direction={"down"} theme={theme.data} />
          )} 
        </S.ArrowContainer> */}
      </S.BroadcastListInnerContainer>
      {/* {isOpen && (
        <S.BroadcastContainerWrapper theme={theme.data}>
          {broadcastList.data?.actions.map((data, idx) => {
            return (
              <BroadcastListElem
                data={data}
                idx={`broadcastListElem-${idx}`}
                key={idx}
              />
            );
          })}
        </S.BroadcastContainerWrapper>
      )} */}
    </S.BroadcastListContainer>
  );
};
