const Skill = {
  businessRoad: {
    skillName: "사업가의 길",
    imagePath: "assets/images/finish/skill/business_road.png",
  },
  cShop: {
    skillName: "C#",
    imagePath: "assets/images/finish/skill/c_shop.png",
  },
  codingKnowledge: {
    skillName: "코딩지식",
    imagePath: "assets/images/finish/skill/coding_knowledge.png",
  },
  codingTest: {
    skillName: "코딩 테스트",
    imagePath: "assets/images/finish/skill/coding_test.png",
  },
  aiUtilization: {
    skillName: "AI 활용",
    imagePath: "assets/images/finish/skill/ai_utilization.png",
  },
  colab: {
    skillName: "Colab",
    imagePath: "assets/images/finish/skill/colab.png",
  },
  cpp: {
    skillName: "C++",
    imagePath: "assets/images/finish/skill/cpp.png",
  },
  decisionMakingAbility: {
    skillName: "의사 결정 능력",
    imagePath: "assets/images/finish/skill/decision_making_ability.png",
  },
  flutter: {
    skillName: "Flutter",
    imagePath: "assets/images/finish/skill/flutter.png",
  },
  htmlCss: {
    skillName: "HTML CSS",
    imagePath: "assets/images/finish/skill/html_css.png",
  },
  ideasRealization: {
    skillName: "아이디어 실현",
    imagePath: "assets/images/finish/skill/ideas_realization.png",
  },
  javaScript: {
    skillName: "Java Script",
    imagePath: "assets/images/finish/skill/java_script.png",
  },
  java: {
    skillName: "Java",
    imagePath: "assets/images/finish/skill/java.png",
  },
  preDeveloper: {
    skillName: "예비 개발자",
    imagePath: "assets/images/finish/skill/preDeveloper.png",
  },
  python: {
    skillName: "Python",
    imagePath: "assets/images/finish/skill/python.png",
  },
  spring: {
    skillName: "Spring",
    imagePath: "assets/images/finish/skill/spring.png",
  },
  sql: {
    skillName: "sql",
    imagePath: "assets/images/finish/skill/sql.png",
  },
  termsMaster: {
    skillName: "용어 마스터",
    imagePath: "assets/images/finish/skill/terms_master.png",
  },
  understandingCodingEducation: {
    skillName: "코딩 이해 교육",
    imagePath: "assets/images/finish/skill/understanding_coding_education.png",
  },
  understandingItMarket: {
    skillName: "It시장 이해",
    imagePath: "assets/images/finish/skill/understanding_it_market.png",
  },
  unity: {
    skillName: "Unity",
    imagePath: "assets/images/finish/skill/unity.png",
  },
  zep: {
    skillName: "Zep",
    imagePath: "assets/images/finish/skill/zep.png",
  },
  zepeto: {
    skillName: "제페토",
    imagePath: "assets/images/finish/skill/zepeto.png",
  },
  webflow: {
    skillName: "Webflow",
    imagePath: "assets/images/finish/skill/webflow.png",
  },
  zapier: {
    skillName: "Zapier",
    imagePath: "assets/images/finish/skill/zapier.png",
  },
  airtable: {
    skillName: "Airtable",
    imagePath: "assets/images/finish/skill/airtable.png",
  },
  gptBasic: {
    skillName: "GPT 입문",
    imagePath: "assets/images/finish/skill/gptBasic.png",
  },
  aiBasic: {
    skillName: "AI 입문",
    imagePath: "assets/images/finish/skill/aiBasic.png",
  },
  ga4: {
    skillName: "GA4",
    imagePath: "assets/images/finish/skill/ga4.png",
  },
  mysql: {
    skillName: "MySQL",
    imagePath: "assets/images/finish/skill/mysql.png",
  },
  figma: {
    skillName: "Figma",
    imagePath: "assets/images/finish/skill/figma.png",
  },
  kotlin: {
    skillName: "Kotlin",
    imagePath: "assets/images/finish/skill/kotlin.png",
  },
  typescript: {
    skillName: "TypeScript",
    imagePath: "assets/images/finish/skill/typescript.png",
  },
  plan: {
    skillName: "plan",
    imagePath: "assets/images/finish/skill/plan.png",
  },
  chatGPT: {
    skillName: "Chat GPT",
    imagePath: "assets/images/finish/skill/chatGPT.png",
  },
  excel: {
    skillName: "Excel",
    imagePath: "assets/images/finish/skill/excel.png",
  },
  powerpoint: {
    skillName: "PowerPoint",
    imagePath: "assets/images/finish/skill/powerpoint.png",
  },
};

export const courseSkills = {
  /* 무료강의 */
  "6334259bd6566f2cb23ec7f7": [
    Skill.understandingCodingEducation,
    Skill.codingKnowledge,
  ], // 부모 코딩
  "632ad50b2b63868e4030eb5b": [
    Skill.understandingCodingEducation,
    Skill.understandingItMarket,
    Skill.codingKnowledge,
  ], // DT
  "625963fa9d5b4ee7f14b61d9": [Skill.preDeveloper, Skill.understandingItMarket], // 개발자 취업
  "632bec62293ed67329e02c62": [Skill.businessRoad, Skill.understandingItMarket], // IT 창업
  "6178b82938d078df2971b63f": [Skill.termsMaster, Skill.codingKnowledge], // 코딩 용어 해설
  "62a739bfc826b9902f9d9ad1": [Skill.htmlCss, Skill.codingKnowledge], // 나만의 프로필
  "6358e2f6d5e61148f86b3267": [Skill.htmlCss, Skill.codingKnowledge], // 나만의 동기부여 홈화면 만들기
  "6420ea5f7e6e4c8f5d0af5d0": [Skill.gptBasic, Skill.codingKnowledge], // chatGPT를 사용한 웹사이트 만들기
  "642540848a9def2d6766b27a": [Skill.codingKnowledge], // 내일배움카드 발급부터 활용의 모든 것
  "642c2dfff6dfefee6dc47bfb": [Skill.aiBasic, Skill.codingKnowledge], // 코드 없이 AI로 웹사이트 하나가 뚝딱

  /** 유료강의 */
  "5f0ae408765dae0006002816": [Skill.htmlCss, Skill.python, Skill.javaScript], // 웹개발 종합반
  "5f0ae408765dae0006002817": [Skill.flutter, Skill.ideasRealization], // 앱개발 종합반
  "5f51cba7d76b11f9244d1111": [Skill.sql, Skill.decisionMakingAbility], // SQL
  "62973372bf6e0e4f799fc76e": [Skill.python, Skill.decisionMakingAbility], // 파이썬 엄무자동화
  "5f8315459328d9a2909acb52": [Skill.spring, Skill.codingKnowledge], // 스프링
  "5f8bc3e83cff77cc2abeb181": [Skill.python, Skill.codingTest], // 알고리즘
  "602a022f5b5001847b616df9": [Skill.colab, Skill.codingKnowledge], // 머신러닝
  "5feffb89f85b7ed4b4bdc7bd": [Skill.python, Skill.codingKnowledge], // 파이썬 문법 뽀개
  "606bfa74c11b70df6da82cfd": [Skill.javaScript, Skill.codingKnowledge], // 자바스크립트 문법
  "606bfb39c11b70df6da82d51": [Skill.java, Skill.codingKnowledge], // 자바 문법
  "606bfc0fc11b70df6da82da5": [Skill.cpp, Skill.codingKnowledge], // c++ 문법
  "6083eaca6305e019d3e0c3b4": [
    Skill.unity,
    Skill.cShop,
    Skill.ideasRealization,
  ], // 게임개발 종합반
  "6295890f8240a11ae2511cab": [Skill.unity, Skill.zepeto], // 제페토 게임 개발
  "62e3a1b9761f411652826c05": [Skill.zep, Skill.javaScript], // zep
  "6361d2ce4239a844b30d4163": [Skill.python, Skill.decisionMakingAbility], // 직장인을 위한 실전데이터분석
  "": [Skill.htmlCss, Skill.python], // 띵동코딩 유스
  "63d8ec592ad417e6257ffef8": [
    Skill.decisionMakingAbility,
    Skill.ideasRealization,
  ], //노코드로 빠르게 웹 서비스 만들기,
  "63d8cb5e2ad417e6257ffd44": [Skill.htmlCss, Skill.codingKnowledge], //컴퓨터를 몰라도 할 수 있는 코딩 첫걸음
  "6467063d187f226a50090d43": [Skill.python, Skill.codingTest], // 한번 들으면 평생 써먹는 알고리즘
  "6476d7cd41baaebbdf24176b": [Skill.typescript, Skill.codingKnowledge], // TyprScript 문법 종합반
  "646ecce1c2b1d12303b401e6": [Skill.kotlin, Skill.codingKnowledge], // Kotlin 문법 종합반
  "649adfc45d0330dcf1a41240": [Skill.figma, Skill.plan], // PPT보다 쉬운 피그마
  "64956fe61e7a5e8c1b9c6e50": [Skill.sql, Skill.decisionMakingAbility], // 엑셀보다 쉽고 빠른 SQL
  "649938ae1e7a5e8c1b9dfb37": [Skill.htmlCss, Skill.javaScript], // 코딩이 처음이어도 쉽게 배우는 웹개발 A to Z
  "64a653c7640351900b810a40": [
    Skill.htmlCss,
    Skill.javaScript,
    Skill.aiUtilization,
  ], // ChatGPT로 웹개발 완전정복
  "64c2859f9e284e546b64d2e8": [Skill.python, Skill.chatGPT, Skill.excel], // 비개발자도 할 수 있는 GPT 활용 파이썬 업무자동화
  "64c28536360d083f8a4d5716": [Skill.chatGPT, Skill.excel, Skill.powerpoint], // 생성형AI와 함께 노코드로 시작하는 업무효율화

  /** 내배단 */
  "60a5ee42bf272bef32a58fb9": [Skill.htmlCss, Skill.python, Skill.javaScript], // 웹개발 종합반
  "616d434a460cf1cf6f957a3d": [Skill.flutter, Skill.ideasRealization], // 앱개발 종합반
  "616d4349460cf1cf6f9579ec": [Skill.sql, Skill.decisionMakingAbility], // 엑셀보다 SQL
  "62f09eebfeb2d564bacb0262": [
    Skill.unity,
    Skill.cShop,
    Skill.ideasRealization,
  ], // 게임개발 종합반
  "616d4348460cf1cf6f957994": [Skill.python, Skill.decisionMakingAbility], // 파이썬 데이터 분석
  "6412734baa727445ce9374b0": [Skill.webflow, Skill.zapier, Skill.airtable], // 노코드로 완성도 있는 웹사이트 만들기

  "64671aa77509c0fec824b08f": [Skill.webflow, Skill.ga4], // ga4
  "648fc643f9b1fac7aced4966": [Skill.spring, Skill.mysql], // 스프링
  // default
  default: [Skill.codingKnowledge, Skill.ideasRealization],
};
