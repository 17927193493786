import React, { Component, Fragment } from "react";
import classNames from "classnames/bind";
import styles from "./CustomModal_LectureExpire.module.scss";
import TextArea from "antd/lib/input/TextArea";
import { getApiRescueUrl } from "../../_urls/dashboard.api";
import { getPostAuthOptions } from "../../_urls";
import { handleResponse } from "../../_helpers";

const cx = classNames.bind(styles);

class CustomModal_LectureExpire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  _handleCloseModal = () => {
    this.props.handleModalVisible(false);
  };

  submitRescue = () => {
    let data = {
      reason: this.state.reason,
      user_id: this.props.user_id,
    };
    const endpoint = getApiRescueUrl(this.props.round_id);
    const options = getPostAuthOptions(data);
    fetch(endpoint, options)
      .then(handleResponse)
      .then((lecture) => {
        this.setState({
          submitted: true,
        });
      })
      .catch((error) => {
        alert(error);
        window.location.href = "/classroom";
      });
  };

  handleChange(event) {
    this.setState({ reason: event.target.value });
  }

  render() {
    const { visible, name } = this.props;
    const { style } = this.props;
    const { submitted } = this.state;
    return (
      <div>
        <div className={visible ? cx("modal", "active") : cx("modal")}></div>
        <div
          className={
            visible
              ? submitted
                ? cx("modal-background", "active", "submitted")
                : cx("modal-background", "active")
              : cx("modal-background")
          }
          style={{ ...style }}
        >
          <div className={cx("modal-content")}>
            {submitted && (
              <div className={cx("modal-body")}>
                <div className={cx("header")}>
                  <h3>강의가 다시 열렸어요!</h3>
                  <img src={"/assets/images/roadmap/love_rtanny.png"} />
                </div>
                <div className={cx("body")}>
                  <p>
                    다음 약속일에는 강의실에서 꼭 만나요!
                    <br />
                  </p>
                </div>
                <div className={cx("buttonContainer")}>
                  <button onClick={this._handleCloseModal}>
                    공부하러 가기
                  </button>
                </div>
              </div>
            )}
            {!submitted && (
              <div className={cx("modal-body")}>
                <div className={cx("header")}>
                  <h3>앗! 이번 주 강의가 닫혀버렸어요</h3>
                  <img src={"/assets/images/roadmap/sad_rtanny.png"} />
                </div>
                <div className={cx("body")}>
                  <p>
                    지난 주에 급하게 바쁜 일이 있으셨던 거죠..? 그런 거죠?
                    <br />
                    <br />
                    {name}님이 못 오신 이유를 르탄이에게 알려주시면,
                    <br />
                    담당 매니저가 24시간 내로 연락드릴게요.
                  </p>
                  <textarea
                    value={this.state.reason}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className={cx("buttonContainer")}>
                  <button onClick={this.submitRescue}>
                    르탄이에게 알려주기
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { CustomModal_LectureExpire };
