import React from "react";
import { PIRATES } from "../../../_consts";

export const BackIconSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M19.6569 8.98995C20.2037 8.44322 20.2037 7.55678 19.6569 7.01005C19.1102 6.46332 18.2238 6.46332 17.677 7.01005L19.6569 8.98995ZM10.667 16L9.67704 15.0101C9.13031 15.5568 9.13031 16.4432 9.67704 16.99L10.667 16ZM17.677 24.99C18.2238 25.5367 19.1102 25.5367 19.6569 24.99C20.2037 24.4432 20.2037 23.5568 19.6569 23.0101L17.677 24.99ZM17.677 7.01005L9.67704 15.0101L11.6569 16.99L19.6569 8.98995L17.677 7.01005ZM9.67704 16.99L17.677 24.99L19.6569 23.0101L11.6569 15.0101L9.67704 16.99Z"
        fill={color}
      />
    </svg>
  );
};

export const CheckSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.66602 8L5.99935 11.3333L13.3327 4"
        stroke="#FF6C7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckNotDoneSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "#141617";
  const opacity = theme === PIRATES ? "1" : "0.3";
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66553 8.5L5.99886 11.8333L13.3322 4.5"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const OnPlaySvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "white";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M6 4.8737V12.1263C6 12.286 6.17803 12.3813 6.31094 12.2927L11.7504 8.66641C11.8691 8.58725 11.8691 8.41275 11.7504 8.33359L6.31094 4.70729C6.17803 4.61869 6 4.71396 6 4.8737Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ArrowLeftSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.2929 20.7071C14.6834 21.0976 15.3166 21.0976 15.7071 20.7071C16.0976 20.3166 16.0976 19.6834 15.7071 19.2929L14.2929 20.7071ZM7 12L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L7 12ZM15.7071 4.70711C16.0976 4.31658 16.0976 3.68342 15.7071 3.29289C15.3166 2.90237 14.6834 2.90237 14.2929 3.29289L15.7071 4.70711ZM15.7071 19.2929L7.70711 11.2929L6.29289 12.7071L14.2929 20.7071L15.7071 19.2929ZM7.70711 12.7071L15.7071 4.70711L14.2929 3.29289L6.29289 11.2929L7.70711 12.7071Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowBlackLeftSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.2929 20.7071C14.6834 21.0976 15.3166 21.0976 15.7071 20.7071C16.0976 20.3166 16.0976 19.6834 15.7071 19.2929L14.2929 20.7071ZM7 12L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L7 12ZM15.7071 4.70711C16.0976 4.31658 16.0976 3.68342 15.7071 3.29289C15.3166 2.90237 14.6834 2.90237 14.2929 3.29289L15.7071 4.70711ZM15.7071 19.2929L7.70711 11.2929L6.29289 12.7071L14.2929 20.7071L15.7071 19.2929ZM7.70711 12.7071L15.7071 4.70711L14.2929 3.29289L6.29289 11.2929L7.70711 12.7071Z"
        fill={color}
      />
    </svg>
  );
};

export const MoreOptionsSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect x="10.5" y="4.5" width="3" height="3" rx="1.5" fill={color} />
      <rect x="10.5" y="10.5" width="3" height="3" rx="1.5" fill={color} />
      <rect x="10.5" y="16.5" width="3" height="3" rx="1.5" fill={color} />
    </svg>
  );
};

export const ShareSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <circle
        cx="4.83333"
        cy="10.4167"
        r="2.08333"
        stroke={color}
        strokeWidth="1.75"
      />
      <circle
        cx="14.8333"
        cy="4.58333"
        r="2.08333"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M7.33331 8.95832L9.83331 7.49999L12.3333 6.04166M7.33331 11.6667L9.83331 12.9167L12.3333 14.1667"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <circle
        cx="14.8333"
        cy="15.4167"
        r="2.08333"
        stroke={color}
        strokeWidth="1.75"
      />
    </svg>
  );
};

export const ShareSmallSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#EEF3F6" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle
        cx="3.66667"
        cy="8.33229"
        r="1.66667"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="11.6667"
        cy="3.66667"
        r="1.66667"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M5.66895 7.16771L7.66895 6.00105L9.66895 4.83438M5.66895 9.33438L7.66895 10.3344L9.66895 11.3344"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="11.6667"
        cy="12.3323"
        r="1.66667"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ArrowRightSmallSvg = ({ theme, isABT }) => {
  const color = theme === PIRATES ? "#EEF3F6" : isABT ? "#FFF" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M7.96466 14.3813C7.62295 14.723 7.62295 15.277 7.96466 15.6187C8.30636 15.9604 8.86038 15.9604 9.20209 15.6187L7.96466 14.3813ZM13.5834 10L14.2021 10.6187C14.5438 10.277 14.5438 9.72299 14.2021 9.38128L13.5834 10ZM9.20209 4.38128C8.86038 4.03957 8.30636 4.03957 7.96466 4.38128C7.62295 4.72299 7.62295 5.27701 7.96466 5.61872L9.20209 4.38128ZM9.20209 15.6187L14.2021 10.6187L12.9647 9.38128L7.96466 14.3813L9.20209 15.6187ZM14.2021 9.38128L9.20209 4.38128L7.96466 5.61872L12.9647 10.6187L14.2021 9.38128Z"
        fill={color}
      />
    </svg>
  );
};
