import * as S from "./scoreSelector.style";
import { getRangeOfNumber } from "../../../../../_helpers";

const ScoreSelector = ({ answer, setAnswer, questionId, n = 10 }) => {
  return (
    <S.ScrollWrapper>
      <S.ScrollContainer>
        <S.ScoreContainer>
          {getRangeOfNumber(n).map((score, i) => {
            return (
              <S.Score
                key={`score-${score}`}
                isActive={answer === score}
                onClick={() => {
                  setAnswer(questionId, score);
                }}
              >
                {score}
              </S.Score>
            );
          })}
        </S.ScoreContainer>
        <S.DescriptionContainer>
          <S.Description>매우 만족함</S.Description>
          <S.Description>매우 불만족함</S.Description>
        </S.DescriptionContainer>
      </S.ScrollContainer>
    </S.ScrollWrapper>
  );
};

export default ScoreSelector;
