import * as S from "./SettingBottomSheet.style";

export const SpeedOption = ({
  speedOptions,
  selectedSpeedOption,
  onSpeedChange,
  changeTitleAndContent,
}) => {
  return speedOptions.map((option) => {
    return (
      <S.OptionWrapper
        key={option}
        isOn={option === selectedSpeedOption}
        onClick={() => {
          onSpeedChange({ target: { value: option } });
          changeTitleAndContent("영상설정", null);
        }}
      >
        <S.OptionTitleWrapper>{option}배</S.OptionTitleWrapper>
      </S.OptionWrapper>
    );
  });
};

export const CaptionOption = ({
  isCaptionOn,
  onCaptionChange,
  changeTitleAndContent,
}) => {
  return (
    <S.OptionsContainer onClick={() => changeTitleAndContent("영상설정", null)}>
      <S.OptionWrapper
        isOn={!!isCaptionOn}
        onClick={() => {
          onCaptionChange(false);
        }}
      >
        ON
      </S.OptionWrapper>
      <S.OptionWrapper
        isOn={!isCaptionOn}
        onClick={() => onCaptionChange(true)}
      >
        OFF
      </S.OptionWrapper>
    </S.OptionsContainer>
  );
};

export const PipOption = ({
  isPipeMode,
  onPipChange,
  changeTitleAndContent,
}) => {
  return (
    <S.OptionsContainer onClick={() => changeTitleAndContent("영상설정", null)}>
      <S.OptionWrapper
        isOn={!!isPipeMode}
        onClick={() => {
          onPipChange(false);
        }}
      >
        ON
      </S.OptionWrapper>
      <S.OptionWrapper isOn={!isPipeMode} onClick={() => onPipChange(true)}>
        OFF
      </S.OptionWrapper>
    </S.OptionsContainer>
  );
};

export const QualityOption = ({
  qualityOptions,
  selectedQualityOption,
  onQualityChange,
  changeTitleAndContent,
}) => {
  return (
    <>
      <S.OptionWrapper
        key={"Auto"}
        isOn={"Auto" === selectedQualityOption}
        onClick={() => {
          onQualityChange({ target: { value: "Auto" } });
          changeTitleAndContent("영상설정", null);
        }}
      >
        Auto
      </S.OptionWrapper>

      {qualityOptions.map((option) => {
        return (
          <S.OptionWrapper
            key={option}
            isOn={option === selectedQualityOption}
            onClick={() => {
              onQualityChange({ target: { value: option } });
              changeTitleAndContent("영상설정", null);
            }}
          >
            <S.OptionTitleWrapper>{option}p</S.OptionTitleWrapper>
          </S.OptionWrapper>
        );
      })}
    </>
  );
};
