import React from 'react';
import classNames from "classnames/bind";
import {connect} from "react-redux";
import {history} from "../../../../_helpers";
import styles from "./HomeworkAnswerPreviousBtn.module.scss";

const cx = classNames.bind(styles);

function HomeworkAnswerPreviousBtn({
                                       enrolled_id,
                                       enrolled_homework_id,
                                   }) {

    async function goPrevious() {
        // 숙제 제출페이지로 되돌아가기
        history.push(`/enrolleds/${enrolled_id}/ehomeworks/${enrolled_homework_id}`)
    }

    return (
        <div className={cx('homework-answer-previous-btn')} onClick={goPrevious}>
            숙제 제출하기
            <svg className={cx('homework-answer-previous-btn__img')} xmlns="http://www.w3.org/2000/svg" width="14"
                 height="9" fill="none" viewBox="0 0 14 9">
                <path fill="#fff" d="M1.623 0l5.285 5.342L12.192 0l1.623 1.653-6.907 6.982L0 1.653 1.623 0z"/>
            </svg>
        </div>
    )
}

function mapStateToProps(state) {
    const {enrolledDetails} = state;
    return {enrolledDetails}
}

const connectedHomeworkPreviousBtn = connect(mapStateToProps, {})(HomeworkAnswerPreviousBtn);
export {connectedHomeworkPreviousBtn as HomeworkAnswerPreviousBtn}