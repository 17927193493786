import privateInstance from "../../_helper/axios";
import { useQuery } from "react-query";

const getRoundWeeks = async (enrolled_id) => {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/weeks`)
    .then((resp) => resp.data);
};

export const useGetRoundWeeks = (enrolled_id) => {
  return useQuery(
    ["roundWeeks", enrolled_id],
    async () => await getRoundWeeks(enrolled_id),
    {
      enabled: !!enrolled_id,
      staleTime: Infinity,
      refetchOnMount: true,
    }
  );
};
