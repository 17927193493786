import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mBody4,
  mHeader1,
  mTitle1,
  mTitle2,
  neutralDay,
  scc,
  wBody1,
  wBody2,
  wBody4,
  wHeader2,
  wTitle1,
  wTitle5,
} from "@teamsparta/design-system";
import { Device } from "../../../../_styles/device";
import { keyframes } from "@emotion/css";
import { fontPretendard } from "../../../styles/font";

const slideInFromTop = keyframes`
  0% {
    transform: translateY(5%); /* 초기 상태에서 위로 이동하여 보이지 않음 */
    opacity: 0; /* 초기 상태에서 투명도 0 (요소가 보이지 않음) */
  }
  100% {
    transform: translateY(0); /* 나타난 상태로 아래로 이동하여 보이도록 함 */
    opacity: 1; /* 나타난 상태로 투명도 1 (요소가 완전히 보임) */
  }
`;

export const Container = styled.div`
  background: ${neutralDay.gray100};
  padding: 60px 16px;
  ${Device.Desktop} {
    padding: 100px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Toast = styled.div`
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  z-index: 1;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 41px;
  background: rgba(58, 62, 65, 0.8);
  box-shadow: 0 8px 24px rgba(129, 137, 143, 0.12);
  border-radius: 100px;
  font-family: ${fontPretendard};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${neutralDay.white};
`;

export const Title = styled.div`
  ${mHeader1};
  color: ${neutralDay.gray0};
  margin-bottom: 40px;
  ${Device.Desktop} {
    text-align: center;
    ${wHeader2};
    color: ${neutralDay.gray0};
    margin-bottom: 50px;
  }
`;

export const SubTitle = styled.div`
  ${mBody2};
  color: ${neutralDay.gray80};
  margin-bottom: 40px;
  ${Device.Desktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
    margin-bottom: 60px;
  }
`;

export const RecommendCourseBox = styled.div`
  cursor: pointer;
  background: rgba(58, 62, 65, 0.5);
  border: 1px solid #3a3e41;
  box-shadow: 0px 0px 100px rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  ${Device.Desktop} {
    min-width: 600px;
    padding: 50px;
  }

  animation: ${slideInFromTop} 0.5s ease-in-out;

  &:hover {
    transition: transform 0.5s;
    transform: translateY(-8px);
  }

  &:not(:hover) {
    transition: transform 0.5s;
  }
`;
export const RecommendCourseBoxTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const CourseDescript = styled.div`
  ${mBody2};
  color: ${neutralDay.gray50};
  ${Device.Desktop} {
    ${wTitle5};
    color: ${neutralDay.gray50};
  }
`;

export const CourseName = styled.div`
  ${mTitle1};
  color: ${neutralDay.gray0};
  ${Device.Desktop} {
    ${wTitle1};
    color: ${neutralDay.gray0};
  }
`;
export const CourseOverview = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  & > :last-child {
    margin-bottom: 0; /* 가장 아래 줄의 컴포넌트에는 margin을 적용하지 않음 */
  }
  margin-bottom: 40px;
  ${Device.Desktop} {
    & > * {
      margin-bottom: 0px; /* 각 줄 사이에 24px 간격 추가 */
    }
    margin-bottom: 60px;
    gap: 40px;
  }
`;
export const CourseOverviewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${Device.Desktop} {
    gap: 4px;
  }
`;
export const CourseOverviewCategory = styled.div`
  ${mBody4};
  color: ${neutralDay.gray50};
  ${Device.Desktop} {
    ${wBody4};
    color: ${neutralDay.gray50};
  }
`;
export const CourseOverviewValue = styled.div`
  ${mBody1};
  white-space: pre-wrap;
  color: ${neutralDay.gray0};
  ${Device.Desktop} {
    ${wBody1};
    white-space: nowrap;
    color: ${neutralDay.gray0};
  }
`;
export const CourseOverviewDetail = styled.div`
  ${mBody4};
  color: ${neutralDay.gray50};
  ${Device.Desktop} {
    ${wBody4};
    color: ${neutralDay.gray50};
  }
`;
export const RecommendCourseBoxTitleImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0px 10px 12px rgba(20, 22, 23, 0.24);
  ${Device.Desktop} {
    width: 100px;
    height: 100px;
  }
`;
export const RecommendCourseBoxTitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 60px;
  padding-left: 20px;
  ${Device.Desktop} {
    justify-content: space-between;
    height: 69px;
    padding-left: 32px;
  }
`;

export const Cta = styled.div`
  animation: ${slideInFromTop} 1s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${scc.red100};
  border-radius: 8px;
  ${mTitle2};
  color: ${neutralDay.white};
  padding: 0 20px;
  width: 100%;
  height: 54px;
  ${Device.Desktop} {
    width: 100%;
    ${wBody1};
    color: ${neutralDay.white};
  }
`;

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0px;
  background-color: ${neutralDay.gray90};
  ${Device.Desktop} {
    margin: 50px 0px;
  }
`;
