import styled from "@emotion/styled";

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0);
`;

export const LoadingRtan = styled.img`
  width: 59px;
  height: 72px;
  object-fit: contain;
`;

export const LoadingText = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #505254;
  margin-top: 12px;
`;
