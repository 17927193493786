import { useSelector } from "react-redux";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMyHomeworks } from "../../businesslogics/OrderedEnrollmentContents/repository/useMyHomeworks";

const useHomeworkObject = () => {
  const { enrolled_homework_id } = useParams();
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const homework = useMyHomeworks(enrolled_homework_id).data?.context.homework;

  return {
    enrolled_id: enrolled.data?.enrolled_id,
    course_id: enrolled.data?.course_id,
    course_title: enrolled.data?.courses?.title,
    lecture_id: enrolledDetail.data?.lecture_id,
    page_url: window.location.href,
    tutor: enrolled.data?.tutors?.name,
    week_total: enrolled.data?.courses?.days / 7,
    week: enrolled_homework_id ? homework?.week : enrolledDetail.data?.week,
    week_order: enrolled_homework_id
      ? homework?.order
      : enrolledDetail.data?.week_order,
    homework_id: homework?._id,
  };
};

const useLogClick = (logType) => {
  const homeworkObj = useHomeworkObject();
  return (buttonText) => {
    const data = {
      ...homeworkObj,
      button_name: buttonText,
    };
    sendCPLog(logType, data);
  };
};

const useLogClickHint = (logType) => {
  const homeworkObj = useHomeworkObject();
  return (hintOrder, isOpened, homeworkDetailOrder) => {
    const data = {
      ...homeworkObj,
      hint_order: hintOrder,
      is_opened: isOpened,
      homework_detail_order: homeworkDetailOrder,
    };
    sendCPLog(logType, data);
  };
};

export const useLogHomeworkPageView = () => {
  const homeworkObj = useHomeworkObject();
  useEffect(() => {
    if (homeworkObj.enrolled_id) {
      sendCPLog("scc_homeworkPage_view", homeworkObj);
    }
  }, [homeworkObj.enrolled_id]);
};

const useLogClickSummary = (logType, timeStamp) => {
  const homeworkObj = useHomeworkObject();
  return (buttonName, summary) => {
    const data = {
      ...homeworkObj,
      button_name: buttonName,
      summary: summary,
      time_stamp: timeStamp,
    };
    sendCPLog(logType, data);
  };
};

const useLogClickLectureList = (logType) => {
  const homeworkObj = useHomeworkObject();
  return (buttonName, buttonText, etc) => {
    const data = {
      ...homeworkObj,
      button_name: buttonName,
      button_text: buttonText,
      button_status: etc.buttonStatus,
      next_week: etc.nextWeek,
      next_week_order: etc.nextWeekOrder,
    };
    sendCPLog(logType, data);
  };
};

export const useLogHomeworkClickHint = () =>
  useLogClickHint("scc_homeworkPage_click_hint");

export const useLogHomeworkClick = () =>
  useLogClick("scc_homeworkPage_click_button");
export const useLogWilPageClick = () => useLogClick("scc_wilPage_click_button");
export const useLogNpsPageClick = () => useLogClick("scc_npsPage_click_button");

export const useLogLectureSidebarClick = () =>
  useLogClick("scc_sideBar_click_button");

export const useLogSummaryClick = (timeStamp) =>
  useLogClickSummary("scc_lectureSummary_click_list", timeStamp);

export const useLogLectureListClick = () =>
  useLogClickLectureList("scc_lectureList_click_list");

export const useLogFeedbackPageView = () => {
  useEffect(() => {
    sendCPLog("scc_feedbackPage_view", {});
  }, []);
};

export const useLogFeedbackPageClick = () => {
  return (buttonName) => {
    sendCPLog("scc_feedbackPage_click_button", { button_name: buttonName });
  };
};
