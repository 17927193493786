import React from "react";
import * as S from "./completeRecommendTrack.style";
import { useRecommendTrack } from "../../../hooks/completeRecommend/useRecommendTrack";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

const CompleteRecommendTrack = ({ courseId, courseTitle }) => {
  const {
    promoteCaption,
    promoteText,
    trackCaption,
    trackTitle,
    trackCaution,
    trackImgSrc,
    cardCaption,
    link,
    brand,
  } = useRecommendTrack(courseId);

  const handleDetailButtonClick = () => {
    window.open(link, "_blank");
    sendCPLog("scc_trackRcmdPage_click_button", {
      course_id: courseId,
      course_title: courseTitle,
      button_text: "더 알아보기",
      next_href: link,
      brand: brand,
    });
  };

  return (
    <S.Container>
      <S.PromoteTextContainer>
        <S.PromoteTitle>{promoteCaption}</S.PromoteTitle>
        <S.PromoteContent>{promoteText}</S.PromoteContent>
        {cardCaption && <S.PromoteCaution>{trackCaution}</S.PromoteCaution>}
      </S.PromoteTextContainer>
      <S.PromoteCard>
        <S.PromoteTrackImgWrapper>
          {cardCaption && <S.CardCaption>{cardCaption}</S.CardCaption>}
          <S.PromoteTrackImg src={trackImgSrc} alt="promote_track_img" />
        </S.PromoteTrackImgWrapper>
        <S.PromoteTrackTextContainer>
          <S.PromoteTrackCaption>{trackCaption}</S.PromoteTrackCaption>
          <S.PromoteTrackTitle>{trackTitle}</S.PromoteTrackTitle>
        </S.PromoteTrackTextContainer>
        <S.MoreAboutBootcampButton onClick={handleDetailButtonClick}>
          더 알아보기
        </S.MoreAboutBootcampButton>
      </S.PromoteCard>
    </S.Container>
  );
};

export default CompleteRecommendTrack;
