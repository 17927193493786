import * as S from "./Gnb.desktop.style";
import {
  mainAnchor,
  topAnchors,
  topRightAnchors,
} from "../models/gnbV3.models";
import { GnbMyPage } from "../molecules/GnbMyPage";
import {
  GnbAnchorDesktop,
  GnbTopAnchorDesktop,
} from "../atoms/GnbAnchor.desktop";
import { BizDropdown } from "../molecules/GnbDropDown";
import { Fragment, useEffect, useRef, useState } from "react";
import { LoginButton } from "../molecules/LoginButton";
import { GnbMyPageLogo } from "../atoms/GnbMyPageLogo";
import { useGoMain } from "../../../businesslogics/_layout/gnb/v2";
import { connect } from "react-redux";
import { RemoteSpartaLogo } from "../../../../_components/_atoms/RemoteSpartaLogo/RemoteSpartaLogo";

const GnbDesktopV3 = ({ isLogin, user }) => {
  const goMain = useGoMain();
  const DROPDOWN_COMPONENTS = {
    bisness: BizDropdown,
  };

  const [dropdownOpen, setDropdownOpen] = useState(null); // 현재 열린 드롭다운을 관리
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };
  //외부 클릭시 드롭다운 닫기
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const toggleDropdown = (key, e) => {
    if (dropdownOpen === key) {
      setDropdownOpen(null); // 이미 열려있는 드롭다운을 닫음
    } else {
      setDropdownOpen(key); // 드롭다운을 연다
    }
    e.stopPropagation();
  };
  return (
    <>
      <S.TopWrapper
        ref={dropdownRef}
        onClick={() => dropdownOpen !== null && setDropdownOpen(null)}
      >
        <S.InnerContainer>
          <S.ColumnContainer>
            {topAnchors.map((anchor, index) => {
              const DropdownComponent = DROPDOWN_COMPONENTS[anchor.key];
              return (
                <Fragment key={`gnb-desktop-category${anchor.key}`}>
                  <S.CursorWrapper>
                    <GnbTopAnchorDesktop
                      key={`gnb-desktop-category${anchor.key}`}
                      anchor={anchor}
                      isCategory={true}
                      isDropdownOpen={dropdownOpen === anchor.key}
                      onClickDropdown={(e) => toggleDropdown(anchor.key, e)}
                    />
                  </S.CursorWrapper>
                  {index !== topAnchors.length - 1 && <S.Divider />}
                  {dropdownOpen === anchor.key && <DropdownComponent />}
                </Fragment>
              );
            })}
          </S.ColumnContainer>
          <S.ColumnContainer>
            {topRightAnchors.map((anchor, index) => {
              const DropdownComponent = DROPDOWN_COMPONENTS[anchor.key];
              return (
                <Fragment key={`gnb-desktop-category${anchor.key}`}>
                  <S.CursorWrapper>
                    <GnbTopAnchorDesktop
                      key={`gnb-desktop-category${anchor.key}`}
                      anchor={anchor}
                      isCategory={true}
                      isDropdownOpen={dropdownOpen === anchor.key}
                      onClickDropdown={(e) => toggleDropdown(anchor.key, e)}
                    />
                  </S.CursorWrapper>
                  {index !== topRightAnchors.length - 1 && <S.Divider />}
                  {dropdownOpen === anchor.key && <DropdownComponent />}
                </Fragment>
              );
            })}
          </S.ColumnContainer>
        </S.InnerContainer>
      </S.TopWrapper>
      <S.MainWrapper
        onClick={() => dropdownOpen !== null && setDropdownOpen(null)}
      >
        <S.InnerContainerBottom>
          <S.ColumnContainer>
            <S.LogoWrapper onClick={goMain}>
              <RemoteSpartaLogo />
            </S.LogoWrapper>
            {mainAnchor.map((anchor) => (
              <GnbAnchorDesktop
                key={`gnb-desktop-category${anchor.key}`}
                anchor={anchor}
                isCategory={true}
              />
            ))}
          </S.ColumnContainer>
          <S.ColumnContainer>
            {isLogin ? (
              <>
                <GnbMyPageLogo />
                <GnbMyPage user={user} />
              </>
            ) : (
              <LoginButton />
            )}
          </S.ColumnContainer>
        </S.InnerContainerBottom>
      </S.MainWrapper>
    </>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { authentication, user };
}

const connectedGnbMobile = connect(mapStateToProps, {})(GnbDesktopV3);
export { connectedGnbMobile as GnbDesktopV3 };
