import { lectureConstants } from "../_constants";
import { lectureService } from "../_services";

function getLecture(round_id, lecture_id) {
  function request() {
    return { type: lectureConstants.LECTURE_REQUEST };
  }

  function success(response) {
    return { type: lectureConstants.LECTURE_SUCCESS, response };
  }

  function failure(error) {
    return { type: lectureConstants.LECTURE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    lectureService.getLecture(round_id, lecture_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getLectureNavInfo(round_id) {
  function request() {
    return { type: lectureConstants.LECTURE_NAV_REQUEST };
  }

  function success(response) {
    return { type: lectureConstants.LECTURE_NAV_SUCCESS, response };
  }

  function failure(error) {
    return { type: lectureConstants.LECTURE_NAV_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    lectureService.getLectureNavInfo(round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const lectureActions = {
  getLecture,
  getLectureNavInfo,
};
