import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 16px;
  gap: 20px;

  width: 100vw;
  height: 786px;

  background: #ffffff;

  ${Device.Desktop} {
    align-items: flex-start;
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #141617;

  ${Device.Desktop} {
    font-size: 20px;
  }
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;

  padding-left: 25px;
`;

export const listItem = styled.li`
  display: list-item;
  list-style-type: disc;

  span {
    color: #e8344e;
  }
`;

export const InnerListItem = styled.div`
  padding-left: 25px;
`;

export const SubTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 160%;

  display: flex;
  align-items: center;

  color: #5f666b;
  margin-bottom: 8px;

  ${Device.Desktop} {
    font-size: 16px;
  }
`;

export const Desc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  display: flex;
  align-items: center;

  color: #5f666b;
  ${Device.Desktop} {
    font-size: 16px;
  }
`;
