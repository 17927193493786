import React from "react";
import classNames from "classnames/bind";
import styles from "./KakaoShare.scss";

const cx = classNames.bind(styles);

export const MONTHLY_SPECIAL = (() => {
  switch (`${new Date().getFullYear()}/${new Date().getMonth() + 1}`) {
    case "2023/2":
      return "photos";
    default:
      return "photos";
  }
})();

export const duration = (url) => {
  switch (url) {
    case "photos":
      return "1시간";
    default:
      return "1시간";
  }
};

export const KakaoShare = ({ name, _id, btnLog }) => {
  // useEffect(() => {
  //     window.Kakao.Link.createCustomButton({
  //         container: '#kakao_share_m',
  //         templateId: 53815,
  //         templateArgs: {name, _id},
  //     })
  // }, [])

  const shareKko = async () => {
    window.Kakao.Link.sendCustom({
      templateId: 88356,
      templateArgs: {
        monthly_special: MONTHLY_SPECIAL,
        _id: _id,
        name: name,
        duration: duration(MONTHLY_SPECIAL),
        THU: `https://static.spartacodingclub.kr/meta/og-rcmd-free.png`,
      },
    });
    await btnLog("카카오톡으로 공유하기");
  };

  return (
    <div className={cx("step-cta", "kakao")} onClick={() => shareKko()}>
      <img src={"/assets/images/rcmd/kakao_icon.png"} />
      카카오톡으로 공유하기
    </div>
  );
};
