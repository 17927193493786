import { createBrowserHistory } from "history";

export function params() {
  let paramsString = decodeURI(createBrowserHistory().location.search);
  let paramsDict = getParams(paramsString);
  // 메인에서 url 신버전으로 마이그레이션 할 때까지만 보존
  let next = paramsDict.next;
  if (next === "payment" && paramsDict.cid) {
    next = paramsDict.round_id
      ? `${next}/${paramsDict.cid}?round_id=${paramsDict.round_id}`
      : `${next}/${paramsDict.cid}`;
  }

  return { ...paramsDict, next };
}

function getParams(paramsString) {
  let paramsDict = {};
  if (paramsString.length > 0) {
    let paramsList = paramsString.split("?")[1].split("&");
    for (const p of paramsList) {
      let part = p.split("=");
      paramsDict[part[0]] = part[1];
    }
  }
  return paramsDict;
}
