import React from "react";
import * as S from "./ErrorTypeSelector.style";

export const ErrorReportSelectorItem = ({
  title,
  description,
  onClick,
  isMobile,
}) => (
  <S.ErrorReportSelectorContentContainer isMobile={isMobile} onClick={onClick}>
    <S.ErrorReportSelectorContentTitle isMobile={isMobile}>
      {title}
    </S.ErrorReportSelectorContentTitle>
    <S.ErrorReportSelectorContentText isMobile={isMobile}>
      {description}
    </S.ErrorReportSelectorContentText>
  </S.ErrorReportSelectorContentContainer>
);
