import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { liveUserActions } from "../../../_state/actions/live_user.actions";
import styles from "./WelcomeMessage.module.scss";

const cx = classNames.bind(styles);

function WelcomeMessage({ user, liveUser, getLiveUser }) {
  useEffect(() => {
    if (user) {
      getLiveUser();
    }
  }, [user]);
  return (
    <h2 className={cx("main-copy")}>{"스파르타월드에 온 걸 환영합니다!"}</h2>
  );
}

function mapStateToProps(state) {
  const { authentication, liveUser } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    liveUser,
  };
}

const connectedWelcomeMessage = connect(mapStateToProps, {
  getLiveUser: liveUserActions.getLiveUser,
})(WelcomeMessage);
export { connectedWelcomeMessage as WelcomeMessage };
