import styled from "@emotion/styled";
import { Device } from "../../../../../_styles/device";
import { neutralDay, scc } from "@teamsparta/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  ${Device.Desktop} {
  }
`;

export const Option = styled.div`
  cursor: pointer;
  padding: 16px;
  gap: 10px;
  width: 100%;
  height: 56px;
  background: ${({ isActive }) => (isActive ? scc.red10 : neutralDay.white)};
  border: 1px solid
    ${({ isActive }) => (isActive ? scc.red50 : neutralDay.gray30)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  ${Device.Desktop} {
  }
`;
