import { sendCustomDataLog } from "../../../businesslogics/logging";

export const logOnClickIssueCoupon = (enrolled, couponSet) => {
  sendCustomDataLog("scc_finalRcmdPage_coupon_click", {
    course_title: enrolled.data?.courses.title,
    course_id: enrolled.data?.courses._id,
    coupon_ids: couponSet.couponIds,
    coupon_price: couponSet.amount,
  });
};
