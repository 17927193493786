import privateInstance from "../../_helper/axios";

async function getABtestByEnrolled(enrolled_id) {
  return await privateInstance
    .get(`/abtest/enrolleds/${enrolled_id}`)
    .then((resp) => resp.data);
}

async function getABTestPaymentByCourse(course_id, variation) {
  return await privateInstance
    .get(`/abtest/payment/courses/${course_id}?variation=${variation}`)
    .then((resp) => resp.data);
}

async function getABTestKkoSignup(kko_id, variation) {
  return await privateInstance
    .get(`/abtest/signup_kko?kko_id=${kko_id}&variation=${variation}`)
    .then((resp) => resp.data);
}

async function getABTestCourseRcmd(variation) {
  return await privateInstance
    .get(`/abtest/courseRcmd?variation=${variation}`)
    .then((resp) => resp.data);
}

async function sendHackleLog(data) {
  return await privateInstance
    .post(`/abtest/courseRcmd/sendLog`, data)
    .then((resp) => resp.data);
}

export const abtestService = {
  getABtestByEnrolled,
  getABTestPaymentByCourse,
  getABTestKkoSignup,
  getABTestCourseRcmd,
  sendHackleLog,
};
