export let config ={
    initialMessages : [
    ],
    profileConfig : {
        name : "척척석사 르탄봇",
        status : "online",
        description : "바로 응답",
        profileImage : "/assets/images/rtan/rtan_profile.png",
    },

}
