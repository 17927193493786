export const SelectorSvg = ({ isActive }) => {
  return isActive ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9" stroke="#E8344E" strokeWidth="2" />
      <circle
        cx="12"
        cy="12"
        r="4"
        fill="#E8344E"
        stroke="#E8344E"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9" stroke="#C7D2D8" strokeWidth="2" />
    </svg>
  );
};
