import axios from "axios";
import { config } from "../config";
import { API_VERSION } from "../_urls";
import { authHeader } from "./auth-header";

const baseURL = `${config.apiUrl}/${API_VERSION}`;
axios.defaults.baseURL = baseURL;
// axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL,
  timeout: 100000,
  headers: Object.assign({ "Content-Type": "application/json" }, authHeader()),
});

let requestCache = {};

instance.interceptors.request.use(
  function (config) {
    if (config.headers.Authorization) {
      config.headers = Object.assign(config.headers, authHeader());
    } else {
      config.headers = Object.assign(
        { "Content-Type": "application/json" },
        authHeader()
      );
    }
    if (JSON.stringify(config) === JSON.stringify(requestCache)) {
      const skipXHRError = new Error("skip");
      skipXHRError.isSkipXHR = true;
      skipXHRError.request = config;
      throw skipXHRError;
    } else {
      requestCache = Object.assign({}, config);
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
