import { handleResponse, authHeader } from "../_helpers";
import { getApiCodeSnippetInfoUrl } from "../_urls";

function getCodeSnippet(round_id, lecture_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  const endpoint = getApiCodeSnippetInfoUrl(round_id, lecture_id);
  return fetch(endpoint, requestOptions).then(handleResponse);
}

export const codeSnippetService = {
  getCodeSnippet,
};
