import React from "react";
import { PopularArticleContainer } from "../PopularArticleContainer/PopularArticleContainer";

import { useArticleHeader } from "../../../hooks/completeRecommend/useArticleHeader";
import * as S from "./popularArticleSection.style";

export const PopularArticleSection = ({ courseId, courseTitle }) => {
  const { caption, title } = useArticleHeader({ courseId });

  return (
    <S.Container>
      <S.ArticleContainer>
        <S.ArticleHeader>
          <S.ArticleHeaderCaption>{caption}</S.ArticleHeaderCaption>
          <S.ArticleHeaderTitle>{title}</S.ArticleHeaderTitle>
        </S.ArticleHeader>
        <S.PopularArticlesContainer>
          <PopularArticleContainer
            courseId={courseId}
            courseTitle={courseTitle}
          />
        </S.PopularArticlesContainer>
      </S.ArticleContainer>
    </S.Container>
  );
};
