import { handleResponse, privateFetch } from "../_helpers";
import { getApiPrequestionInfoUrl, getGETAuthOptions } from "../_urls";

function isPreqSubmitted(course_id, round_id) {
  const requestOptions = getGETAuthOptions();
  const endpoint = getApiPrequestionInfoUrl(course_id, round_id);
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

export const prequestionService = {
  isPreqSubmitted,
};
