import React from "react";
import * as S from "./PlayerControlBarMobile.style";
import { formatTime } from "../../hooks/utils";
import { useVolumeControl } from "../../hooks/useVolumeControl";
import { usePlaybackTimeControl } from "../../hooks/usePlaybackTimeControl";
import { useCSSVariable } from "../../hooks/useCSSVariable";
import {
  useIsControlBarActive,
  useIsPlaying,
  useIsSettingBottomSheetOpen,
} from "../../stores/playerAtom";
import { useScreenControl } from "../../hooks/useScreenControl";

export const PlayerControlBarMobile = React.memo(({ videoRef, playerType }) => {
  const [isControlBarActive] = useIsControlBarActive(false);
  const [isSettingBottomSheetOpen] = useIsSettingBottomSheetOpen(false);
  const [isPlaying] = useIsPlaying(videoRef);
  const { volume, muteButtonSrc, toggleMute } = useVolumeControl(videoRef);
  const { playbackTime, duration, handleScrub } =
    usePlaybackTimeControl(videoRef);
  const {
    fullScreenButtonSrc,
    isPipMode,
    pipButtonSrc,
    onFullscreenChange,
    onPipChange,
  } = useScreenControl(videoRef);

  //head에 스타일이 실시간으로 쌓이는 문제 해결을 위한 코드
  useCSSVariable("--progress", `${(playbackTime / duration) * 100}%`);
  useCSSVariable("--volume", `${volume * 100}%`);

  return (
    <>
      {!isSettingBottomSheetOpen && (
        <S.ControllerBox isVisible={isControlBarActive} isPlaying={isPlaying}>
          <S.ProgressBarWrapper
            className="progress-bar"
            type="range"
            min={0}
            max={1}
            step="any"
            value={playbackTime / duration}
            onChange={handleScrub}
          />
          <S.ControllerContainer>
            <S.PlayAndTimeWrapper>
              <S.CurrentTimeWrapper>
                {formatTime(playbackTime)}
              </S.CurrentTimeWrapper>
              <S.SlashWrapper>/</S.SlashWrapper>
              <S.TotalTimeWrapper>{formatTime(duration)}</S.TotalTimeWrapper>
            </S.PlayAndTimeWrapper>
            <S.OtherControllerWrapper>
              <S.MuteControllerWrapper
                alt={"음소거 버튼"}
                src={muteButtonSrc}
                onClick={toggleMute}
              />
              <S.PipControllerWrapper
                alt={"pip 버튼"}
                src={pipButtonSrc}
                onClick={() => onPipChange(isPipMode)}
              />
              <S.FullscreenButtonWrapper
                alt={"전체화면 버튼"}
                src={fullScreenButtonSrc}
                onClick={onFullscreenChange}
              />
            </S.OtherControllerWrapper>
          </S.ControllerContainer>
        </S.ControllerBox>
      )}
    </>
  );
});
