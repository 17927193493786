import styled from "@emotion/styled";
import { neutralDay } from "@teamsparta/design-system";
import { DisplayResolution } from "../../../_styles/device";
import { validStatusValues } from "../../_state/constants";
export const EvaluationResultPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  width: ${({ isOpenList }) => (isOpenList ? `75%` : "100%")};
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 0;
  }
`;

export const HomeworkRightSide = styled.div`
  display: ${({ isMobile }) => (isMobile ? "none" : "flex")};
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? "0" : "25%")};
  right: ${({ isOpenList }) => (isOpenList ? 0 : "-25%")};
  position: fixed;
  transition: all 0.2s ease-in-out;
  ${DisplayResolution.MobileAndMiniTablet} {
    display: none;
  }
`;

export const HomeworkLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  gap: 60px;
  transition: all 0.2s ease-in-out;
  position: relative;
  padding: ${({ status, isFeedbackRequired }) =>
    validStatusValues.includes(status) && isFeedbackRequired
      ? "60px 24px 120px 24px"
      : "120px 24px 120px 24px"};
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 20px 16px 120px 16px;
    gap: 40px;
  }
`;

export const HelpButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin: 0 auto;
  max-width: 772px;
  width: 100%;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
  }
`;
