import { history } from "../../../_helpers";

function useNavigateToLectureHomework(rounds, enrolled) {
  const { enrolled_id, course_id } = enrolled.data;

  function goLecture(round_id, lecture_id, enrolled_detail_id) {
    history.push(
      `/enrolleds/${enrolled_id}/edetails/${enrolled_detail_id}?course_id=${course_id}`
    );
  }

  function goHomework(homeworkDetailId) {
    history.push(`/enrolleds/${enrolled_id}/ehomeworks/${homeworkDetailId}`);
  }

  return {
    goHomework,
    goLecture,
  };
}

export default useNavigateToLectureHomework;
