import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./RoundWeek.module.scss";
import { connect } from "react-redux";
import { RoundWeekHeader } from "./RoundWeekHeader";
import { RoundWeekDetail } from "./RoundWeekDetail";
import { RoundWeekHomework } from "./RoundWeekHomework";
import { RoundWeekWIL } from "./RoundWeekWIL";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function RoundWeek({
  user,
  round,
  enrolled,
  roundWeek,
  environment,
  enrolledPeriod,
}) {
  const { enrolled_details, homework, week, wil } = roundWeek;
  const { is_forever, is_gov_intro, is_skip_payment } = round.data;
  const [chapter_title, setChapterTitle] = useState("");
  const [course, setCourse] = useState({});
  const [leftTime, setLeftTime] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const getTimer = (endDate) => {
    window.setTimeout(() => {
      let now = new Date();
      let dday = new Date(endDate);

      let days = (dday - now) / 1000 / 60 / 60 / 24;
      let daysRound = Math.floor(days);
      let hours = (dday - now) / 1000 / 60 / 60 - 24 * daysRound;
      let hoursRound = Math.floor(hours);
      let minutes =
        (dday - now) / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      let minutesRound = Math.floor(minutes);
      let seconds =
        (dday - now) / 1000 -
        24 * 60 * 60 * daysRound -
        60 * 60 * hoursRound -
        60 * minutesRound;
      let secondsRound = Math.round(seconds);
      if (daysRound < 1)
        setLeftTime(`${hoursRound}:${minutesRound}:${secondsRound}`);
      else {
        setLeftTime(
          `${daysRound}일 ${hoursRound}:${minutesRound}:${secondsRound}`
        );
      }
    }, 1000);
  };

  useEffect(() => {
    getTimer(enrolled.data.end_date);
  }, [enrolled, leftTime]);

  useEffect(() => {
    setIsMobile(environment.deviceType === "mobile");
  }, []);

  useEffect(() => {
    const { courses: course } = enrolled.data;
    setChapterTitle(getChapterTitle(course, round, week));
    setCourse(course);
  }, [enrolled]);

  const theme = useOnlineTheme();

  return (
    <div className={cx("round-week")}>
      <div className={cx("round-week__lectures", theme.data)}>
        {!is_gov_intro && (
          <RoundWeekHeader
            course={course}
            round={round}
            roundWeek={roundWeek}
            chapter_title={chapter_title}
            enrolled={enrolled}
          />
        )}
        {/* {course.is_trial && isMobile && (
          <div className={cx("__timer-box")}>
            {enrolledPeriod.data.is_forever ? (
              <div className={cx("__timer-content")}>
                🎉 강의 평생소장 지급 완료!
              </div>
            ) : leftTime ? (
              <div className={cx("__timer-content")}>
                🔥<span className={cx("leftTimeText")}>{leftTime}</span> 안에
                완주시 평생소장!
              </div>
            ) : (
              <br />
            )}
          </div>
        )} */}
        {!is_gov_intro && <div className={cx("__hr--dotted", theme.data)} />}
        <RoundWeekDetail
          roundWeek={roundWeek}
          week={week}
          enrolled_details={enrolled_details}
          enrolled={enrolled}
        />
      </div>
      {homework && (
        <div
          className={cx(
            "round-week__homeworks",
            week !== 0 ? "" : "round-week__zero"
          )}
        >
          {homework.map((hwk, idx) => {
            return (
              <RoundWeekHomework
                week={week}
                key={idx}
                homework={hwk}
                enrolled_details={enrolled_details}
                chapter_title={chapter_title}
              />
            );
          })}
        </div>
      )}
      {enrolled.data.courses.is_monthly_trial && is_skip_payment && (
        <div className={cx("__monthly_free_coupon")}>
          <div className={cx("__text")}>
            <img src="/v2/assets/images/monthly_free_coupon.png" />
            완주하면<span> 월간코딩 무료 쿠폰</span> 1장 지급!
          </div>
        </div>
      )}
      {/* 0주차인 경우, 만들기 주차인 경우 WIL 안보여줌 */}
      {!is_gov_intro &&
        !is_forever &&
        !enrolled.data.courses.is_trial &&
        wil &&
        week !== 0 &&
        homework &&
        enrolled.data.courses.days / 7 >= week && (
          <div className={cx("round-week__wil")}>
            <RoundWeekWIL
              week={week}
              homework={homework}
              wil={wil}
              enrolled_details={enrolled_details}
              chapter_title={chapter_title}
            />
          </div>
        )}
    </div>
  );
}

export function getChapterTitle(course, round, week) {
  // is_seasonal, is_trial 의 경우 주차 단위로 구분되지 않음.
  return isDailyCourse(course, round) || isNoWeek(round)
    ? ""
    : week > 5
    ? `${week}주차`
    : `${week}주차`;
}

function isNoWeek(round) {
  const isGovIntro = round?.data?.is_gov_intro;
  return isGovIntro;
}

function isDailyCourse(course, round) {
  const { is_seasonal, is_trial } = course;
  return is_trial || is_seasonal;
}

function mapStateToProps(state) {
  const { authentication, enrolled, round, environment, enrolledPeriod } =
    state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    round,
    environment,
    enrolledPeriod,
  };
}

const connectedRoundWeek = connect(mapStateToProps, {})(RoundWeek);
export { connectedRoundWeek as RoundWeek };
