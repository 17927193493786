export const CLASSROOM_CARDS = {
  CLASSROOM_CARDS_REQUEST: "CLASSROOM_CARDS_REQUEST",
  CLASSROOM_CARDS_SUCCESS: "CLASSROOM_CARDS_SUCCESS",
  CLASSROOM_CARDS_FAILURE: "CLASSROOM_CARDS_FAILURE",
};

export const EXPIRED_CLASSROOM_CARDS = {
  EXPIRED_CLASSROOM_CARDS_REQUEST: "EXPIRED_CLASSROOM_CARDS_REQUEST",
  EXPIRED_CLASSROOM_CARDS_SUCCESS: "EXPIRED_CLASSROOM_CARDS_SUCCESS",
  EXPIRED_CLASSROOM_CARDS_FAILURE: "EXPIRED_CLASSROOM_CARDS_FAILURE",
};
