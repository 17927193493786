export const tempGptCourses = [
  "64269c728a9def2d676781b5",
  "6426a044e09a454370af1d3c",
  "6426a0fe8a9def2d67678479",
];

export const fundingCourses = [
  "642aa813dba75779377de9a4", // 컴활 1급
  "642aa754dba75779377de829", // 실무 피그마 프로젝트
  "642aa658c17d972aa91a14f7", // SQLD 자격증 대비반
  "642aa526dba75779377de638", // 파이썬 엑셀 업무자동화
  // "6433b6aab8d11cc10a00b294", // SQLD 자격증 대비반 2기
];

export const blockingCourseIds = [...tempGptCourses, ...fundingCourses];

export const earlybirdExcludedCourses = [
  "64671aa77509c0fec824b08f", // ga4
  "64c28536360d083f8a4d5716", // automation genai
  "64c2859f9e284e546b64d2e8", //automation python
];
