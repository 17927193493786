import React, { useEffect } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./VideoPage.module.scss";
import {
  enrolledActions,
  homeworksActions,
  lectureActions,
  spalandActions,
} from "../../_state/actions";
import { useLocation } from "react-router-dom";
import useConstructor from "../../../hooks/useConstructor";
import { prequestionActions } from "../../../_actions/prequestion.actions";

const cx = classNames.bind(styles);

function VideoPage({
  material,
  title,
  exitLink,
  videoSrcLink,
  homework_snippets,
  text,
  postHasSeenOrientation,
  isPreqSubmitted,
}) {
  const location = useLocation();
  const enrolledId = location.state.enrolledId;
  const roundId = location.state.roundId;
  const courseId = location.state.courseId;
  const prequestionSubmittedNow = location.state.prequestionSubmittedNow;

  useConstructor(
    () => prequestionSubmittedNow || isPreqSubmitted(courseId, roundId)
  );

  material = {
    data: {},
    loaded: false,
  };

  homework_snippets = [];
  videoSrcLink = "673506819?h=763b6294bb";
  title = "내일배움단 오리엔테이션";
  text = "OT 영상 시청 완료";
  exitLink = `https://teamsparta.notion.site/8ff3799cec5448f2a387a9f34d1d1afe?pvs=4`;

  useEffect(() => {
    if (enrolledId) {
      postHasSeenOrientation(enrolledId);
      window.location.href = exitLink;
    }
  }, [enrolledId]);

  return <div className={cx("homework-answer-page")}></div>;
}

function mapStateToProps(state) {
  const { homeworkAnswer, spalandDates, prequestionSubmitted } = state;
  return {
    homeworkAnswer,
    spalandDates,
    prequestionSubmitted,
  };
}

const connectedVideoPage = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getHomeworkAnswer: homeworksActions.getHomeworkAnswer,
  getMaterialByEnrolled: lectureActions.getMaterialByEnrolled,
  postHasSeenOrientation: enrolledActions.postHasSeenOrientation,
  getSpalandDatesByEnrolled: spalandActions.getSpalandDatesByEnrolled,
  isPreqSubmitted: prequestionActions.isPreqSubmitted,
})(VideoPage);

export { connectedVideoPage as VideoPage };
