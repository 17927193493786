import styled from "@emotion/styled";
import { neutralDay, mBody2 } from "@teamsparta/design-system";
import { Device } from "../../../../../_styles/device";

const Description = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Description;

const Container = styled.div`
  ${mBody2};
  color: ${neutralDay.gray70};
  ${Device.Desktop} {
    margin-top: 24px;
    white-space: pre-wrap;
  }
`;
