import React from "react";
import classNames from "classnames/bind";
import styles from "./PaymentDiscountSection.module.scss";
import { connect } from "react-redux";
import { numberWithCommas } from "../../../v2/_helper";

const cx = classNames.bind(styles);

const PaymentDiscountSection = ({
  isFullDiscount,
  onClickFullDiscount,
  onClickCancelFullDiscount,
  showCouponSection,
  showPointSection,
  toggleOptions,
  discountCoupon,
  couponName,
  isShowCouponOption,
  setIsFullDiscount,
  onClickCoupon,
  coupons,
  point,
  pointInput,
  onChangePoint,
  currencyUnit,
  parsePrice,
}) => {
  return (
    <>
      <section className={cx("sec", "discount")}>
        <div className={cx("info-header")}>
          <div className={cx("info-header-text")}>
            <h2>쿠폰{showPointSection && "/ 포인트"}</h2>
          </div>
          <div className={cx("info-header-icon")}>
            {!isFullDiscount ? (
              <button onClick={onClickFullDiscount}>최대할인 적용</button>
            ) : (
              <button onClick={onClickCancelFullDiscount}>할인적용 취소</button>
            )}
          </div>
        </div>
        <div className={cx("info-components")}>
          {showCouponSection && (
            <div className={cx("info-component")}>
              <div className={cx("info-key")}>쿠폰</div>
              <div className={cx("info-value")}>
                <div
                  data-testid="coupon-select"
                  className={cx("component-select")}
                  onClick={() => {
                    toggleOptions("couponOptions");
                  }}
                >
                  <div className={cx("selected-value")}>
                    <span className={discountCoupon ? cx("active") : cx("")}>
                      {couponName}
                    </span>
                  </div>
                  <div className={cx("dropdown")}>
                    <img
                      className={cx("ic-dropdown")}
                      src={`/assets/icons/ic_arrow_right.svg`}
                      alt=""
                    />
                  </div>
                  {isShowCouponOption && (
                    <div
                      data-testid="coupon-select-option"
                      className={cx("option-container")}
                    >
                      <div
                        className={cx("option")}
                        onClick={() => {
                          setIsFullDiscount(false);
                          onClickCoupon();
                        }}
                      >
                        선택안함
                      </div>
                      {coupons.map((coupon, idx) => {
                        let coupon_unit = "%";
                        if (coupon.benefit_type === "amount") {
                          coupon_unit = currencyUnit;
                        } else if (coupon.benefit_type === "target") {
                          coupon_unit = currencyUnit + "으로";
                        }
                        return (
                          <div
                            className={cx("option")}
                            key={idx}
                            onClick={() => {
                              setIsFullDiscount(false);
                              onClickCoupon(coupon, true);
                            }}
                          >
                            {coupon.title} /{" "}
                            <span className={cx("benefit")}>
                              {parsePrice(coupon.benefit_detail)}
                              {coupon_unit} 할인{" "}
                            </span>
                            / {coupon.expired_at} 까지
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {showPointSection && (
            <div className={cx("info-component")}>
              <div className={cx("info-key")}>포인트</div>
              <div className={cx("info-value")}>
                <div className={cx("component-input")}>
                  <div className={cx("input")}>
                    <input
                      data-testid="point-input"
                      type="text"
                      ref={pointInput}
                      placeholder={"내 포인트 " + numberWithCommas(point) + "P"}
                      onKeyUp={() => {
                        setIsFullDiscount(false);
                        onChangePoint(false);
                      }}
                    />
                  </div>
                  <div
                    className={cx("cta")}
                    onClick={() => {
                      setIsFullDiscount(false);
                      onChangePoint(true);
                    }}
                  >
                    전액사용
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

const connectedPaymentDiscountSection = connect(
  mapStateToProps,
  {}
)(PaymentDiscountSection);
export { connectedPaymentDiscountSection as PaymentDiscountSection };
