import React, { useEffect, useState, useRef, createRef } from "react";
import classNames from "classnames/bind";
import styles from "./CodingRaceGround.scss";
import { connect } from "react-redux";
import { RaceRankContainer } from "./RankContainer/RaceRankContainer";
import { useRefForDimWidth } from "../../../hooks/useRefForDimWidth";
import { Castle } from "./Castle/Castle";

const cx = classNames.bind(styles);

function CodingRaceGround({ enrolled, UserRaceGroupInfo, current_hour }) {
  const divRef = createRef();
  const width = useRefForDimWidth(divRef);

  return (
    <div className={cx("CodingRaceBot")}>
      <div className={cx("CodingRaceFieldContainer")}>
        {current_hour >= 6 && current_hour < 18 ? (
          <img
            className={cx("CodingRaceField")}
            src={`/assets/images/codingrace/field_bg.png`}
            alt="field"
          />
        ) : (
          <img
            className={cx("CodingRaceField")}
            src={`/assets/images/codingrace/field_night_v2@3x-min.png`}
            alt="field_night"
          />
        )}
        {/*<img className={cx('CodingRaceField')}*/}
        {/*     src={(`/assets/images/codingrace/field_night_v2@3x-min.png`)}*/}
        {/*     alt="field_night"/>*/}
      </div>
      <div className={cx("CodingRaceRank")}>
        <div className={cx("CodingRaceWrapper")}>
          {enrolled.loaded && UserRaceGroupInfo && (
            <>
              <div />
              <div className={cx("CodingRace")} ref={divRef}>
                <RaceRankContainer
                  total_weeks={enrolled.data.courses.days / 7}
                  divWidth={width}
                />
              </div>
              <Castle total_weeks={enrolled.data.courses.days / 7} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { enrolled, UserRaceGroupInfo } = state;
  return {
    enrolled,
    UserRaceGroupInfo,
  };
}

const connectedCodingRaceGround = connect(
  mapStateToProps,
  {}
)(CodingRaceGround);
export { connectedCodingRaceGround as CodingRaceGround };
