import { checkinConstants, logsCrmAbConstants } from "../_constants";
import { checkinService } from "../_services/checkin.service";
import { logService } from "../_services/logs.service";

function getLogCrmAB(user_id, exp_no) {
  function request() {
    return { type: logsCrmAbConstants.LOG_CRM_AB_REQUEST };
  }
  function success(response) {
    return { type: logsCrmAbConstants.LOG_CRM_AB_SUCCESS, response };
  }

  function failure(error) {
    return { type: logsCrmAbConstants.LOG_CRM_AB_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    logService.getLogCrmAB(user_id, exp_no).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const logActions = {
  getLogCrmAB,
};
