import { useState } from "react";
import * as S from "./NPSPage.style";
import { useOnlineTheme } from "../../queries/pirates";

export const SatisfactionQuestion = ({
  questionTitle,
  questionNumber,
  size,
  onChangeScore,
  onChangeText,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const handleClickQuestion = (selectScore) => {
    setSelectedOption(selectScore);
    onChangeScore(selectScore);
  };
  const theme = useOnlineTheme();
  return (
    <S.QuestionBox theme={theme.data}>
      <S.QuestionNumber>Q{questionNumber}.</S.QuestionNumber>
      <S.QuestionTitle theme={theme.data}>{questionTitle}</S.QuestionTitle>
      <S.QuestionSelectBoxContainer>
        <S.QuestionLine />
        {[...Array(size).keys()].map((score, idx) => {
          return (
            <S.QuestionSelectContainer>
              <S.QuestionSelectBox
                theme={theme.data}
                key={"강의 콘텐츠 만족도" + idx}
                isChecked={selectedOption === score + 1}
                onClick={() => handleClickQuestion(score + 1)}
              >
                {score + 1}
              </S.QuestionSelectBox>
              {score + 1 === 1 && (
                <S.QuestionSelectDescription theme={theme.data}>
                  매우 불만족
                </S.QuestionSelectDescription>
              )}
              {score + 1 === size && (
                <S.QuestionSelectDescription theme={theme.data}>
                  매우 만족
                </S.QuestionSelectDescription>
              )}
            </S.QuestionSelectContainer>
          );
        })}
      </S.QuestionSelectBoxContainer>
      {selectedOption !== 0 && (
        <S.SubQuestionTextArea
          theme={theme.data}
          placeholder="이유는 무엇인가요?"
          onChange={(e) => onChangeText(e.target.value)}
        />
      )}
    </S.QuestionBox>
  );
};

export const RcmdQuestion = ({
  questionTitle,
  questionSubTitle1,
  questionSubTitle2,
  size,
  onChange,
  name,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const handleClickQuestion = (selectScore) => {
    setSelectedOption(selectScore);
    onChange(selectScore);
  };
  const theme = useOnlineTheme();
  return (
    <S.RcmdQuestionContainer theme={theme.data}>
      <S.RcmdHeadings theme={theme.data}>설문조사</S.RcmdHeadings>
      <S.RcmdQuestionTitle theme={theme.data}>
        {name}님,
        <br />
        {questionTitle}
      </S.RcmdQuestionTitle>
      <S.RcmdQuestionSubTitle theme={theme.data}>
        {questionSubTitle1} <br />
        {questionSubTitle2}
      </S.RcmdQuestionSubTitle>

      <S.RcmdSelectBoxContainer>
        {[...Array(size).keys()].map((score, idx) => {
          return (
            <S.RcmdQuestionSelectContainer key={"친구 추천 의향" + idx}>
              <S.RcmdSelectBox
                theme={theme.data}
                index={size - idx - 1}
                size={size}
                isChecked={selectedOption === size - idx - 1}
                onClick={() => handleClickQuestion(size - idx - 1)}
              >
                {size - idx - 1}
              </S.RcmdSelectBox>
              {size - idx - 1 === 0 && (
                <S.RcmdSelectDescFirst theme={theme.data}>
                  전혀 추천하지 않음
                </S.RcmdSelectDescFirst>
              )}
              {size - idx - 1 === size - 1 && (
                <S.RcmdSelectDescEnd theme={theme.data}>
                  매우 추천함
                </S.RcmdSelectDescEnd>
              )}
            </S.RcmdQuestionSelectContainer>
          );
        })}
      </S.RcmdSelectBoxContainer>
    </S.RcmdQuestionContainer>
  );
};
