import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./ClassCardSelect.module.scss";
import { connect } from "react-redux";
import { Select } from "../../../../molecules/Select";
import { getISODateAsKoreanWithYear } from "../../../../../pages/RoadmapPage/RoundWeek/utils";

const cx = classNames.bind(styles);

function ClassCardSelect({ course_data, setVoucherCourseId }) {
  const isFixed = !!course_data.course_id;
  const [selected, setSelected] = useState();

  const getOptionFromTitle = (title) => {
    for (let i = 0; i < course_data.candidates.length; i++) {
      if (course_data.candidates[i].title === title) {
        return course_data.candidates[i];
      }
    }
  };

  const setOptionSelected = (value) => {
    setSelected(value);
    setVoucherCourseId(getOptionFromTitle(value).course_id);
  };

  return (
    <div className={cx("classCardSelect-wrapper")}>
      {!isFixed && (
        <div className={cx("classCardSelect-selectWrapper")}>
          <Select
            defaultValue="::강의를 선택하세요::"
            handleOptionSelected={setOptionSelected}
            options={course_data.candidates.map((course, idx) => {
              return {
                name: course.title,
                value: course.title,
              };
            })}
          />{" "}
        </div>
      )}
      <h2 className={cx("classCardSelect__title")}>
        {isFixed
          ? `${course_data.course?.title} ${course_data?.round_title}`
          : selected
          ? selected
          : ""}
      </h2>
      <span className={cx("classCardSelect__detail")}>
        {isFixed
          ? !course_data.course?.is_grammer
            ? `개강일: ${getISODateAsKoreanWithYear(
                course_data.enrolled.start_date,
                false
              )} / 수강기간: ${course_data.weeks}주`
            : "개강일: 지금 바로! / 수강기간: 평생!"
          : selected
          ? course_data?.type === "course_all" ||
            course_data?.type === "enterprise_all" ||
            course_data?.type === "web_all" ||
            course_data?.type === "ai_all"
            ? `개강일: ${getISODateAsKoreanWithYear(
                getOptionFromTitle(selected).start_date,
                false
              )}  / 수강기간: ${getOptionFromTitle(selected).weeks}주`
            : "개강일: 지금 바로! / 수강기간: 평생!"
          : ""}
      </span>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedClassCardSelect = connect(mapStateToProps, {})(ClassCardSelect);
export { ConnectedClassCardSelect as ClassCardSelect };
