import styled from "styled-components";
import { Device } from "../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 16px;
  gap: 8px;

  width: 360px;
  height: 100%;

  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 12px;

  ${Device.Desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 18px;
    gap: 16px;

    width: 292px;
    height: 289px;
    border-radius: 24px;
    margin-bottom: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
`;

export const TitleText = styled.div`
  display: flex;
  gap: 4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  display: flex;
  align-items: center;

  color: #141617;

  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const TitleTextRed = styled(TitleText)`
  color: #e8344e;
`;

export const TitleCaption = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #81898f;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 7px;

  width: 100%;
  height: 200px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  width: 100%;
`;

export const AntennaIconWrapper = styled.div`
  position: relative;
  top: 2px;
  ${Device.Desktop} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const HeaderTitleCaption = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #81898f;

  ${Device.Desktop} {
    font-size: 13px;
  }
`;

export const HeaderTitleText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  color: #3a3e41;
  ${Device.Desktop} {
    font-size: 16px;
  }
`;

export const HeaderTitleGray = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  color: #5f666b;
  ${Device.Desktop} {
    font-size: 14px;
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 8px 0px 16px;
  gap: 8px;

  width: 100%;
  height: 136px;

  background: #f6f9fa;

  border: 1px solid #e4ebf0;
  border-radius: 8px;

  ${Device.Desktop} {
  }
`;

export const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  padding-right: 12px;
  padding-bottom: 12px;

  width: 100%;

  //세로 스크롤바
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #81898f;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 10px;
  }
`;

export const UserItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 100%;
  height: 20.48px;
`;

export const UserProfile = styled.img`
  width: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background: #e4ebf0;
  flex-shrink: 0;
`;

export const UserItemTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
`;

export const UserItemText = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;

  display: flex;
  align-items: center;
  color: #141617;
`;

export const UserItemTextGray = styled.div`
  display: flex;
  gap: 4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #5f666b;
`;

export const UserItemMe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 5px;
  gap: 10px;

  width: 19px;
  height: 19px;

  background: #d7e0e6;
  border-radius: 22px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;

  color: #81898f;
`;

export const TrophyIconWrapper = styled.div`
  ${Device.Desktop} {
    svg {
      width: 52px;
      height: 52px;
    }
  }
`;

export const DropArrowIconWrapper = styled.div`
  position: relative;
  top: 2px;
`;
