import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import { history } from "../../../_helpers";
import styles from "./RoadmapHomework.module.scss";
import { Button, Row, Col } from "antd";
import { CheckOutlined, LockFilled } from "@ant-design/icons";

const cx = classNames.bind(styles);

class RoadmapHomework extends Component {
  componentDidMount() {}

  _goToHomework(homework_uri) {
    const round_id = this.props.round_id;
    history.push(`/rounds/${round_id}/${homework_uri}`);
  }

  render() {
    const { data, is_homework_allowed, week, is_free, is_seasonal } =
      this.props;
    const homeworkType = !is_homework_allowed
      ? "forbid"
      : data.done
      ? "done"
      : "todo";

    return (
      <div
        className={
          is_homework_allowed
            ? cx("RoadmapHomeworkWrapper")
            : cx("RoadmapHomeworkWrapper", "disabled")
        }
      >
        <Row className={cx("RoadmapHomeworkRow")} align="middle">
          <Col className={cx("RoadmapHomeworkCol")} span={1}>
            <img
              src={require("../../../assets/images/roadmap/ic_homework.png")}
              alt=""
            />
          </Col>
          <Col
            className={cx("RoadmapHomeworkCol", "title")}
            span={19}
            offset={1}
          >
            <span style={{ cursor: "pointer" }}>
              {week} {is_free || is_seasonal ? "일" : "주"}차 숙제 -{" "}
              {data.title}
            </span>
          </Col>
          <Col className={cx("RoadmapHomeworkCol", "button")} span={3}>
            {homeworkType === "forbid" && (
              <LockFilled style={{}} className={cx("lock")} />
            )}
            {homeworkType === "done" && (
              <Button
                className={cx("done")}
                onClick={() => this._goToHomework(data._id)}
              >
                제출됨 <CheckOutlined className={cx("checked")} />
              </Button>
            )}
            {homeworkType === "todo" && (
              <Button onClick={() => this._goToHomework(data._id)}>
                제출하기
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedRoadmapHomework = connect(mapStateToProps, {})(RoadmapHomework);
export { connectedRoadmapHomework as RoadmapHomework };
