import { reducer } from "../reducers";
import { action } from "../actions/action";
import privateInstance from "../../../v2/_helper/axios";
import { useDispatch, useSelector } from "react-redux";

const initState = {
  loaded: false,
};

const ENROLLED_GRADING = {
  REQUEST: "ENROLLED_GRADING_REQUEST",
  SUCCESS: "ENROLLED_GRADING_SUCCESS",
  FAILURE: "ENROLLED_GRADING_FAILURE",
};

async function _service(enrolledId, selections) {
  const res = await privateInstance.post(`enrolleds/${enrolledId}/grade`, {
    selections,
  });
  return res.data?.context;
}

export function getEnrolledGrading(enrolledId, selections) {
  return action(_service, ENROLLED_GRADING, [enrolledId, selections]);
}

export function enrolledGrading(state = initState, action) {
  return reducer(state, action, ENROLLED_GRADING);
}

export const useEnrolledGrading = () => {
  const enrolledGrading = useSelector((state) => state.enrolledGrading);
  const dispatch = useDispatch();
  return [
    enrolledGrading,
    (enrolledId, selections) =>
      dispatch(getEnrolledGrading(enrolledId, selections)),
  ];
};
