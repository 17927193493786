import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as S from "./CourseRcmdSwCamp.style";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

function CourseRcmdSwCamp({ user }) {
  const [isMobile, setIsMobile] = useState(false);
  const enrolled = useSelector((state) => state.enrolled);

  useEffect(() => {
    if (window.checkPlatform() === "mobile") {
      setIsMobile(true);
    }
  }, []);

  return (
    <S.Container>
      <S.Flame
        src={`/v2/assets/images/swcamp-flame-${isMobile ? "mo" : "web"}.png`}
      />
      <S.Title>
        PM으로 커리어 전환을 꿈꾸는
        <br />
        {user.name} 님을 위한 추천 코스
      </S.Title>
      <S.Logo src={"/v2/assets/icons/swcamp-logo.png"} />
      <S.BigText>PM 커리어 전환 SW캠프</S.BigText>
      <S.TagsContainer>
        <S.Tag>서비스기획</S.Tag>
        <S.Tag>런칭</S.Tag>
        <S.Tag>데이터분석</S.Tag>
      </S.TagsContainer>
      <S.SmallText>
        성장의 방향키를 스스로 움직이는
        <br />
        IT업계 PM으로 성장하세요.
      </S.SmallText>
      <S.Ul>
        <S.Li>
          <S.Dot />
          직장을 다니면서 평일저녁과 주말에 수강
        </S.Li>
        <S.Li>
          <S.Dot />
          3개월동안 준비하는 PM 커리어 전환
        </S.Li>
        <S.Li>
          <S.Dot />
          개발과 데이터 강점을 가진 PM 커리큘럼
        </S.Li>
      </S.Ul>
      <S.Cta
        onClick={() => {
          sendLogActionRcmdPage(
            "swcamp",
            "swcamp",
            "cta",
            "SW캠프 둘러보기",
            "https://swcamp.spartacodingclub.kr",
            "mainPage",
            enrolled.data
          );
          window.open("https://swcamp.spartacodingclub.kr");
        }}
      >
        SW캠프 둘러보기
      </S.Cta>
    </S.Container>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdSwCamp = connect(
  mapStateToProps,
  {}
)(CourseRcmdSwCamp);
export { connectedCourseRcmdSwCamp as CourseRcmdSwCamp };
