import styled from "@emotion/styled";
import { gnbMobileHeight, zIndexGnb } from "../../../styles/variables";

export const Wrapper = styled.div`
  z-index: ${zIndexGnb};
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  ${({ fixed }) => fixed && "position: fixed"};
  width: 100%;
  box-shadow: inset 0px -1px 0px #eef3f6;

  ${({ isTabOpened }) => isTabOpened && `position: fixed;`}
  // 기존 페이지의 style_old.css override 방지용
  * {
    word-break: normal;
    white-space: nowrap;
    box-sizing: border-box;

    &:before,
    &:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
`;

export const TopMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${gnbMobileHeight}px;
  padding: 0 16px;
  z-index: ${zIndexGnb};
  box-shadow: inset 0px -1px 0px #eef3f6;
  background-color: white;

  flex-shrink: 0;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageName = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #141617;
`;

export const LogoWrapper = styled.div``;
export const LogoImg = styled.img`
  width: 100px;
  height: 34px;
`;
export const MypageBtn = styled.div``;
