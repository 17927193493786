import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ColleagueInfo } from "./ColleagueInfo";
import { getISODateAsKorean, getPlaytime } from "./utils";
import classNames from "classnames/bind";
import styles from "./RoundWeekHeader.module.scss";
import { enrolledActions } from "../../../_state/actions";
import { sendLog } from "../../../businesslogics/logging";
import { useOnlineTheme } from "../../../queries/pirates";
import { ClockSvg, DueDateSvg, OutlineSvg } from "./RoundWeek.svg";

const cx = classNames.bind(styles);

function RoundWeekHeader({
  course,
  roundWeek,
  chapter_title,
  getMaker,
  enrolledMaker,
  enrolled,
}) {
  const {
    colleague_info,
    end_date,
    material,
    sum_of_playtime,
    is_active,
    week,
    enrolled_details,
  } = roundWeek;
  const [weekTitle, setWeekTitle] = useState(null);
  const [weekLabel, setWeekLabel] = useState(null);
  const [colleagueComment, setColleagueComment] = useState(null);

  useEffect(() => {
    if (!enrolledMaker.loaded) {
      getMaker(enrolled_details[0].enrolled_id);
    }
  }, []);

  useEffect(() => {
    let week_title, week_label;
    const isMakingWeek = week > 5;
    week_title = isMakingWeek
      ? `${course.title} ${chapter_title}`
      : `${course.title} ${chapter_title}`;
    week_label = isMakingWeek ? "배우기" : "배우기";

    setWeekTitle(week_title);
    setWeekLabel(week_label);
  }, [course]);

  const playtime = getPlaytime(sum_of_playtime);
  const end_day =
    enrolledMaker.loaded && enrolledMaker.data.maker_end_date && week > 5
      ? getISODateAsKorean(enrolledMaker.data.maker_end_date, true)
      : getISODateAsKorean(end_date, true);

  const theme = useOnlineTheme();

  return (
    <div className={cx("round-week-header")}>
      <div className={cx("round-week-header__summary")}>
        <div className={cx("round-week-header__summary__title", theme.data)}>
          <div className={cx("round-week-header__summary__title__text")}>
            {weekTitle}
          </div>
        </div>
        <div className={cx("round-week-header__summary__detail")}>
          <div
            className={cx(
              "round-week-header__summary__detail__obj",
              theme.data
            )}
          >
            <ClockSvg theme={theme.data} />
            {playtime}
          </div>
          <div
            className={cx(
              "round-week-header__summary__detail__obj",
              theme.data
            )}
          >
            <DueDateSvg theme={theme.data} />
            {end_day}
          </div>
          <div
            className={cx(
              "round-week-header__summary__detail__material",
              theme.data
            )}
          >
            {is_active && (
              <a
                href={material?.materials.link}
                target="_blank"
                onClick={() => {
                  sendLog(
                    "scc_roadmapPage_click_list",
                    {
                      ...enrolled.data,
                      title: "강의 자료",
                      button_name: `${chapter_title} 강의 자료`,
                    },
                    ""
                  );
                }}
              >
                강의자료
                <OutlineSvg theme={theme.data} />
              </a>
            )}
            {!is_active && (
              <a href="#" style={{ background: "none", margin: "10px" }}></a>
            )}
          </div>
        </div>
      </div>
      <div className={cx("round-week-header__colleague")}>
        <ColleagueInfo
          colleague_info={colleague_info}
          specific_content={colleagueComment}
          course={course}
        />
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  const { enrolledMaker } = state;
  return { enrolledMaker };
}
const connectedRoundWeekHeader = connect(mapStateToProps, {
  getMaker: enrolledActions.getMaker,
})(RoundWeekHeader);
export { connectedRoundWeekHeader as RoundWeekHeader };
