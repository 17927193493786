import * as S from "./Participate.style";

export const Participate = () => {
  return (
    <S.Container>
      <S.Title>참여 방법</S.Title>
      <S.ContentContainer>
        <S.Content>
          <S.ContentText>
            <S.Index>1</S.Index> 이달의 무료 월간코딩 초대 링크를 <S.BrMobile />
            친구에게 보내요.
          </S.ContentText>
          <S.ClassCardImage
            index={1}
            onClick={() => {
              window.open("https://spartacodingclub.kr/online/special/photos");
            }}
          />
        </S.Content>
        <S.Divide />
        <S.Content>
          <S.ContentText>
            <S.Index>2</S.Index>초대링크로 친구가 수강신청을 완료하면
          </S.ContentText>
          <S.ClassCardImage index={2} />
        </S.Content>
        <S.Divide />
        <S.Content>
          <S.ContentText>
            <S.Index>3</S.Index> 내 계정으로 유료 월간코딩을 <S.BrMobile />
            무료로 들을 수 있는 <S.BrDesktop />
            쿠폰이 1장 발급돼요!
          </S.ContentText>
          <S.ClassCardImage index={3} />
        </S.Content>
        <S.NoticeWrapper>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10.5"
              r="7.5"
              stroke="#81898F"
              strokeWidth="1.75"
            />
            <rect
              x="9.16675"
              y="13.2083"
              width="1.66667"
              height="1.66667"
              rx="0.833333"
              fill="#81898F"
            />
            <path
              d="M10 6.91602V11.6327"
              stroke="#81898F"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          친구 추천을 통한 무료쿠폰 발급은 무제한으로 가능해요.
        </S.NoticeWrapper>
      </S.ContentContainer>
    </S.Container>
  );
};
