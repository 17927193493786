import styled, { keyframes } from "styled-components";
import { PIRATES } from "../../../../_consts";
import { scc } from "@teamsparta/design-system";

export const FloatingContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 16px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#3A3E41";
      default:
        return "transparent";
    }
  }};
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: ${({ isShowFloating }) =>
    isShowFloating ? "translateY(0)" : "translateY(100%)"};
  transition: transform 0.6s ease-in-out;
`;

export const ShareCta = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "#d7e0e6";
      }
    }};
  height: 52px;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 6px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    //min-width: 155px;
    font-size: 14px;
    padding: 0 10px;
  }

  @media (min-width: 1024px) {
    min-width: 155px;
    font-size: 14px;
    padding: 0 0;
  }
`;

export const ShareIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export const CtaTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: .50;
    //transform-origin: 50% 50%;
    transform: rotate(10deg) scale(5);
    transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
`;

export const BackgroundStamp = styled.img`
  height: 40px;
  width: 40px;
  z-index: 9;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 27%;
  transform: translate(-50%, -50%);
  animation: ${fadeOutAnimation} 0.3s ease-in-out 2s forwards;
`;

export const MiniStamp = styled.img`
  height: 40px;
  width: 40px;
  z-index: 13;
  border-radius: 5px;
  position: absolute;
  top: 26%;
  left: 22.3%;
  animation: ${pulseAnimation} 0.3s 0.7s forwards,
    ${fadeOutAnimation} 0.3s ease-in-out 2s forwards;
  opacity: 0;
`;

export const DoneCtaText = styled.div`
  color: #141617;
  text-align: center;
  font-size: 14px;
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const NavShareCtaText = styled.div`
  opacity: 0;
  animation: ${fadeInAnimation} 0.3s ease-in-out 2.5s forwards;
  color: #141617;
  text-align: center;
  font-size: 14px;
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
export const CountSeen = styled.span`
  color: #81898f;
  text-align: center;
  font-size: 13px;
  font-family: "Pretendard";
  font-weight: 600;
  line-height: 160%;
`;

export const MobileNextButtonSkeleton = styled.div`
  width: 100%;
  height: 54px;
  background-color: ${scc.red100};
  box-shadow: 0px 20px 24px 0px rgba(20, 22, 23, 0.24);
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const LoadingSpinner = styled.img`
  @keyframes Rotate {
    100% {
      transform: rotate(-360deg);
    }
  }
  width: 24px;
  height: 24px;
  margin: auto;
  animation: Rotate 2s linear infinite;
  transform-origin: 50% 50%;
`;
