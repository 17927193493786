import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import styles from "./CreditIntro.module.scss";
import { connect } from "react-redux";
import { roundsActions } from "../../../_state/actions/rounds.actions";
import {
  abtestActions,
  enrolledActions,
  objectiveActions,
  UserRaceGroupActions,
} from "../../../_state/actions";
import { Navbar } from "../../../components/organisms/Navbar";
import { govAction } from "../../../_state/actions/gov.actions";
import { history } from "../../../../_helpers";
import { govActions } from "../../../../_actions/gov.actions";
import { NavContainer } from "../../../../_containers";
import { useHrdLink } from "../../../_state/v3/hrdLink";
import { useGetHrdLink } from "../../../_state/v3/hrdLink2";
import { applyDeltaToDate } from "../../../utils/date";
import { Modal } from "../../../components/organisms/Modal/Modal";

const cx = classNames.bind(styles);

const confirm = (question, onTrue, onFalse) => {
  const answer = window.confirm(question);
  if (answer) {
    return onTrue();
  }
  onFalse();
};

const Header = ({ title, message }) => {
  return (
    <div className={cx("header")}>
      <div className={cx("title")}>{title}</div>
      <div className={cx("msg")}>{message}</div>
    </div>
  );
};

const Lecture = ({
  category,
  lectures,
  myRef,
  isActive,
  message,
  enrolledDetails,
  onClick,
}) => {
  const Card = ({ children, onClick, className }) => {
    return (
      <div className={[cx("card-wrapper"), className].join(" ")} ref={myRef}>
        <div className={cx("lecture-card")} onClick={onClick}>
          {children}
        </div>
      </div>
    );
  };

  const LectureCard = ({ lecture, enrolledDetails }) => {
    useEffect(() => {
      // if (enrolledDetails.loaded) {
      //   if (
      //     lecture.title === "국민내일배움카드 신청하기" &&
      //     isActive === "active"
      //   ) {
      //     history.push(lecture.url);
      //   }
      // }
    }, [enrolledDetails]);
    return (
      <Card
        onClick={async () => {
          if (isActive !== "inactive") {
            await onClick();
            if (lecture.title === "국민내일배움카드 신청하기") {
              history.push(lecture.url);
            }
          }
        }}
      >
        <div className={cx("left")}>
          <img src={"/v2/assets/icons/play.png"} alt="spaland-rtan" />
          <div className={cx("lecture-title")}>{lecture.title}</div>
        </div>
        <div className={cx("right")}>
          <div className={cx("playtime")}>{lecture.playtime}</div>
          <img src={"/v2/assets/icons/check.png"} alt="spaland-rtan" />
        </div>
      </Card>
    );
  };
  return (
    <div className={[cx("lecture-wrapper"), cx(isActive)].join(" ")}>
      <div className={cx("title")}>
        <div className={cx("point")}></div>
        <div className={cx("text")}>{category}</div>
      </div>
      {lectures &&
        lectures.map((lecture) => (
          <LectureCard
            key={lecture.title}
            lecture={lecture}
            enrolledDetails={enrolledDetails}
          />
        ))}
      {message && (
        <Card className={cx("alert")}>
          <img src={"/v2/assets/icons/info.png"} alt="spaland-rtan" />
          {message}
        </Card>
      )}
    </div>
  );
};

const Rtan = () => {
  return (
    <div className={cx("rtan")}>
      <img
        src={"/assets/images/codingrace/running_rtanny.gif"}
        alt="spaland-rtan"
        className={cx("rtan-image")}
      />
    </div>
  );
};

const Open = ({ startDate, text }) => {
  return (
    <div className={cx("open")}>
      <div className={cx("date")}>{startDate}</div>
      <div className={cx("postfix")}>{text}</div>
      <img src={"/v2/assets/icons/arrow_down_mobile.png"} alt="spaland-rtan" />
    </div>
  );
};

const CreditIntro = ({
  getGovRoundStartSoon,
  govRoundStartSoon,
  enrolled,
  enrolledDetails,
  getEnrolledDetails,
  getGfsIsCardReceived,
  gfsIsCardReceived,
  doEnrolledDetail,
}) => {
  useGetHrdLink();
  const hrdlink = useHrdLink();
  const title = "내일배움단 합류하기";
  const firstLectureRef = useRef();
  const lastLectureRef = useRef();
  const [height, setHeight] = useState(0);
  const status = gfsIsCardReceived.data?.status;
  let isCardReceived = gfsIsCardReceived.data?.is_card_received;

  const [isNoCardModalVisible, setIsNoCardModalVisible] = useState(false);
  const statusList = [
    "카드 발급",
    "카드 수령 대기",
    "수강 신청",
    "강의 결제",
    "최종 합류",
  ];

  const link = useHrdLink();

  useEffect(() => {
    getGfsIsCardReceived();
    getGovRoundStartSoon("credit");
  }, []);

  useEffect(() => {
    if (!enrolledDetails.loaded) {
      return;
    }
  }, [enrolledDetails]);

  useEffect(() => {
    if (!enrolled.loaded) {
      return;
    }
    getEnrolledDetails(enrolled.data.enrolled_id);
  }, [enrolled]);

  const createLectureInfoList = () => {
    const {
      data: { weeks },
    } = enrolledDetails;
    let previousActive = "done";
    return [0, -1, 1].map((idx) => {
      if (idx === -1) {
        if (previousActive === "done" && !isCardReceived) {
          previousActive = "readyForCard";
          return {
            isActive: "active",
            message: "카드 수령 후, 마지막 단계를 완료해주세요👇",
          };
        } else {
          return {
            isActive: "done",
            message: "듣고싶은 2과목 신청하세요!👇",
          };
        }
      }

      const week = weeks[idx];
      //playtime 정리, url 추가, done여부, enrolled_detail_id 밖으로,
      if (!week) {
        return {};
      }
      let lecture = week.enrolled_details[0];
      const homework = week.homework;
      let isActive;

      if (idx === 0) {
        if (isCardReceived === false) {
          isActive = "ready";
        } else if (isCardReceived !== "no_card") {
          isActive = "done";
        } else {
          isActive = previousActive === "done" ? "active" : "inactive";
        }
      }

      if (idx === 1) {
        if (isCardReceived === false) {
          isActive = "inactive";
        } else if (status === "hrd_reg_fin" || status === "reg_fin") {
          isActive = "done";
        } else {
          isActive = "active";
        }
      }

      previousActive = isActive;

      let playtime = lecture.playtime;
      const sec = playtime % 60;
      const min = parseInt((playtime - sec) / 60);
      playtime = `${min > 9 ? min : `0${min}`}:${sec > 9 ? sec : `0${sec}`}`;
      const url =
        idx === 1
          ? link.link
          : `/v2/enrolleds/${lecture.enrolled_id}/edetails/${lecture.enrolled_detail_id}`;

      return {
        isActive: isActive,
        lectures: [
          {
            title: lecture.title,
            playtime: playtime,
            url: url,
          },
        ],
      };
    });
  };

  let lectureContentList = [];
  if (enrolledDetails.loaded) {
    lectureContentList = createLectureInfoList();
  }

  const lectureInfoList = [
    {
      category: "카드 발급",
      ...lectureContentList[0],
      ref: firstLectureRef,
    },
    {
      category: "카드 수령",
      ...lectureContentList[1],
    },
    {
      category: "수강 신청",
      ...lectureContentList[2],
      ref: lastLectureRef,
    },
    // {
    //   category: "최종 합류",
    //   ...lectureContentList[3],
    // },
  ];

  useEffect(() => {
    if (lastLectureRef.current && firstLectureRef.current) {
      setHeight(
        lastLectureRef.current.getBoundingClientRect().top -
          firstLectureRef.current.getBoundingClientRect().top
      );
    }
  }, [lectureInfoList]);

  const getActiveIndex = (status) => {
    if (status === "no_card") {
      return 0;
    } else if (status === "hrd_reg_fin") {
      return 3;
    } else if (status === "reg_fin") {
      return 4;
    } else {
      return 2;
    }
  };

  const activeIndex = getActiveIndex(status);

  const message = [
    "내일배움단 합류 시작 🔥",
    "코딩 배울 생각에 두근두근 💖",
    "카드가 곧 내 손에! 😍",
    "최종 합류가 코 앞 🚩",
  ][activeIndex];

  const getStatus = (index) =>
    index < activeIndex ? "done" : index > activeIndex ? "inactive" : "active";

  const getStartDate = () => {
    let startDate = govRoundStartSoon.data?.start_date;
    startDate = startDate ? new Date(startDate) : new Date();
    return `${startDate.getMonth() + 1}/${startDate.getDate()}`;
  };

  const getPayfinDate = () => {
    let startDate = govRoundStartSoon.data?.start_date;
    startDate = startDate ? new Date(startDate) : new Date();

    let threeDaysB4 = applyDeltaToDate(startDate, -3);
    return `${threeDaysB4.getMonth() + 1}/${threeDaysB4.getDate()} 22시`;
  };

  const rooms =
    status === "reg_fin"
      ? [
          <div className={cx("1")} />,
          <div className={cx("2")} />,
          <div className={cx("3")} />,
          <div className={cx("4")} />,
          <Open
            className={cx("5")}
            startDate={getStartDate()}
            text={"개강 예정"}
          />,
        ]
      : [
          <div className={cx("1")} />,
          <div className={cx("2")} />,
          <div className={cx("3")} />,
          <Open
            className={cx("4")}
            startDate={getPayfinDate()}
            text={"결제 마감"}
          />,
          <Open
            className={cx("5")}
            startDate={getStartDate()}
            text={"개강 예정"}
          />,
        ];
  rooms[activeIndex] = <Rtan className={cx("6")} />;
  console.log(activeIndex);
  const { checkPlatform } = window;
  const platform = checkPlatform();
  const isMobile = platform === "mobile";

  return (
    <div className={cx("bg")}>
      {isMobile ? <NavContainer className={"gray"} /> : <Navbar />}
      <div className={cx("wrapper")}>
        <Header title={title} message={message} />
        <div className={cx("status-window")}>
          <div className={cx("rooms")}>
            {rooms.map((c) => (
              <div key={c.props.className} className={cx("room")}>
                {c}
              </div>
            ))}
          </div>
          <div className={cx("status-container")}>
            <div className={cx("line")}>
              <div
                className={cx("done")}
                style={{ width: `${activeIndex * 25}%` }}
              />
            </div>
            {statusList.map((status, index) => (
              <div
                className={[cx(`status`), cx(getStatus(index))].join(" ")}
                key={index}
              >
                <div className={cx("label")}>{index + 1}</div>
                <div className={cx("value")}>{status}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={cx("lecture-container")}>
          <div className={cx("vertical-line")} style={{ height: height }} />
          {lectureInfoList.map((lectureInfo, index) => (
            <Lecture
              key={lectureInfo.category}
              category={`${index + 1}. ${lectureInfo.category}`}
              lectures={lectureInfo.lectures}
              myRef={lectureInfo.ref}
              message={lectureInfo.message}
              isActive={lectureInfo.isActive}
              enrolledDetails={enrolledDetails}
              onClick={
                index === 2
                  ? async () => {
                      window.location.replace(link.link);
                    }
                  : () => {}
              }
            />
          ))}
        </div>
      </div>
      {isNoCardModalVisible && (
        <Modal
          visible={true}
          handleVisible={() => {}}
          header={`카드 수령 후에 수강 신청해주세요!`}
          content={
            <div>
              <div className={cx("modal-no-card-received")}>
                <div className={cx("box-inner")}>
                  ✅ 아직 카드 신청 전이라면?
                </div>
                <span className={cx("gray-text")}>
                  &nbsp;&nbsp;・ [국민내일배움카드 신청하기]를 눌러 카드발급
                  신청부터 진행해주세요!
                </span>
              </div>
              <div className={cx("modal-no-card-received")}>
                <div className={cx("box-inner")}>
                  ✅ 카드 수령을 기다리고 있다면?
                </div>
                <div className={cx("gray-text")}>
                  &nbsp;&nbsp;・ <span>방문 수령</span> : 발급확인서를 들고 카드
                  발급이 가능한 신한/농협 은행을 방문해 카드를 수령해주세요.
                  <br />
                  &nbsp;&nbsp;・ <span>우편 수령</span> : 신한/농협 카드사
                  홈페이지에서 카드를 신청하고 기다려주세요.
                  <br />
                  <br />
                  &nbsp;&nbsp;👉 상세한 가이드를 내일배움단 알림톡으로
                  보내드렸으니 확인해보세요!
                </div>
              </div>
            </div>
          }
          isCtaHidden={false}
          styleName={"white"}
          cta={{
            btn_text: "확인",
            btnStyleName: "white-square",
            handleCTAClick: () => {
              setIsNoCardModalVisible(false);
            },
          }}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    environment,
    authentication,
    round,
    enrolled,
    abtestEnrolled,
    hasDoneDailyAuth,
    isGovEnrolledBlocked,
    expired,
    studyObjective,
    enrolledMaker,
    enrolledDetails,
    gfsIsCardReceived,
    govRoundStartSoon,
  } = state;
  const { user } = authentication;
  return {
    environment,
    authentication,
    user,
    round,
    enrolled,
    expired,
    abtestEnrolled,
    hasDoneDailyAuth,
    isGovEnrolledBlocked,
    studyObjective,
    enrolledMaker,
    enrolledDetails,
    gfsIsCardReceived,
    govRoundStartSoon,
  };
}

const connectedCreditIntro = connect(mapStateToProps, {
  setRound: roundsActions.setRound,
  setEnrolled: enrolledActions.setEnrolled,
  getExpired: enrolledActions.getExpired,
  getABtestByEnrolled: abtestActions.getABtestByEnrolled,
  getEnrolledDoneCount: enrolledActions.getEnrolledDoneCount,
  getWeeksAfterStart: UserRaceGroupActions.getWeeksAfterStart,
  getIsGovEnrolledBlocked: govAction.getIsGovEnrolledBlocked,
  getStudyObjective: objectiveActions.getStudyObjective,
  getMaker: enrolledActions.getMaker,
  getGovCompletionMilestone: govActions.getGovCompletionMilestone,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getGfsIsCardReceived: govActions.getGfsIsCardReceived,
  getGovRoundStartSoon: govAction.getGovRoundStartSoon,
  doEnrolledDetail: enrolledActions.doEnrolledDetail,
})(CreditIntro);
export { connectedCreditIntro as CreditIntroPage };
