import styled from "@emotion/styled";
export const WILBanner = styled.div`
  padding: 16px 42px;
  background-color: #ffffff;
  border-radius: 24px;
  color: black;
  width: 100%;
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Noto Sans KR", "Source Sans Pro", sans-serif;
  box-sizing: inherit;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
`;
