import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./B2bPage.module.scss";
import { connect } from "react-redux";
import { externalActions } from "../../_state/actions";
import { validatePhone } from "../../_helper";

const cx = classNames.bind(styles);

function B2bPageV2({ postB2bApply }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [round, setRound] = useState("1기");
  const [companyName, setCompanyName] = useState("한국투자밸류자산운용");
  const [companyId, setCompanyId] = useState("");
  const [course, setCourse] = useState("pre-step");
  const [isDone, setIsDone] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      <div className={cx("B2bForm")}>
        <div className={cx("B2bForm__wrapper")}>
          ç
          <img
            className={cx("B2bForm__banner")}
            src={`/assets/images/b2b/korea_investment_holdings.png`}
            alt={"banner"}
          />
          <div className={cx("B2bForm__wrapper__pad")}></div>
          <div className={cx("B2bForm__wrapper__top")}>
            <h1 className={cx("B2bForm__header")}>
              금융인을 위한 코딩입문 교육신청서
            </h1>
            <hr />
            <p className={cx("B2bForm__desc")}>
              한국투자밸류자산운용 X 스파르타코딩클럽 금융인을 위한 코딩입문
              교육에 오신 여러분 환영합니다.
              <br />
              진짜 중요한 핵심업무에 집중할 수 있도록, 스파르타와 함께
              업무효율을 개선을 시작하세요!
              <br />
              <br />
              👉 실무에 꼭 필요한 자동화를 만드는 [금융인을 위한 업무자동화]
              <br />
            </p>
          </div>
          {isDone ? (
            <div className={cx("B2bForm__done")}>제출이 완료되었습니다!</div>
          ) : (
            <div className={cx("B2bForm__form")}>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  이름<b>*</b>
                </h3>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder={"내 답변"}
                  type={"text"}
                />
              </div>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  휴대폰 번호<b>*</b>
                </h3>
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  type={"text"}
                  placeholder={"ex) 010-0000-0000"}
                />
              </div>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  사번<b>*</b>
                </h3>
                <input
                  type={"text"}
                  onChange={(e) => setCompanyId(e.target.value)}
                  value={companyId}
                  placeholder={"내 답변"}
                />
              </div>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  업무용 이메일<b>*</b>
                </h3>
                <input
                  type={"text"}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={"000000@koreainvestment.com"}
                />
              </div>

              <button
                onClick={(e) => {
                  if (
                    name === "" ||
                    phone === "" ||
                    companyId === "" ||
                    course === "" ||
                    email === ""
                  ) {
                    alert("입력란을 확인해주세요");
                    e.preventDefault();
                    return;
                  }

                  if (!validatePhone(phone)) {
                    alert("휴대폰 번호 형식을 확인해주세요.");
                    e.preventDefault();
                    return;
                  }

                  postB2bApply({
                    round,
                    companyName,
                    name,
                    phone,
                    companyId,
                    course,
                    email,
                  }).then((resp) => {
                    if (resp.ok) {
                      setIsDone(true);
                    } else {
                      alert(resp.message);
                    }
                  });
                }}
                className={cx("B2bForm__form__cta")}
              >
                제출하기
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedB2bPageV2 = connect(mapStateToProps, {
  postB2bApply: externalActions.postB2bApply,
})(B2bPageV2);
export { connectedB2bPageV2 as B2bPageV2 };
