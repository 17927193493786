import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const MainText = styled.h2`
  font-size: 36px;
  font-weight: bold;
  font-family: "Pretendard";
`;

export const SubText = styled.h4`
  color: #434343;
  font-size: 26px;
  font-weight: 500;
`;

export const TotalTime = styled.div`
  margin-top: 12px;
  font-size: 22px;
  font-weight: bold;
`;
