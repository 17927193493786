import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./PhoneVerification.module.scss";
import { phoneVerificationActions } from "../../../_state/actions";
import { Modal } from "../Modal/Modal";

const cx = classNames.bind(styles);

function PhoneVerification({ phone_verification, getPhoneVerification }) {
  const [modalInfo, setModalInfo] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    // fnPopup();
    if (!phone_verification.loaded) {
      getPhoneVerification();
      return;
    }
    if (phone_verification.loaded) {
      setIsVerified(phone_verification.data.is_verified);
      if (!phone_verification.data.is_verified) {
        window.addEventListener("message", receiveMessage, false);
        fnPopup();
      }
      setModalInfo({
        handleVisible: () => {},
        header: "휴대폰 인증을 완료해주세요!",
        content: (
          <div className={cx("modal-content")}>
            <div className={cx("modal-content__text")}>
              오늘 하루동안 휴대폰 인증을 진행하신 내역이 없네요! <br />
              휴대폰 인증을 완료하시고, 강의를 들어주세요.
            </div>
            <div className={cx("modal-content__agree")}>
              <a
                className={cx("modal-content__agree__a")}
                href="https://www.notion.so/teamsparta/3-f2e359d2a710447db3036ff2a3156869"
                target="_blank"
              >
                개인정보처리방침 및 제3자 제공
              </a>
              에 동의하고
            </div>
          </div>
        ),
        cta: {
          btn_text: "인증하기",
          handleCTAClick: () => {
            fnPopup();
          },
        },
      });
    }
  }, [phone_verification.loaded]);

  async function receiveMessage(event) {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (ex) {
      return;
    }
    if ("ok" in data) {
      if (data.ok) {
        setIsVerified(true);
        alert("휴대폰 본인 인증에 성공하셨습니다.");
        await sleep(1000);
        getPhoneVerification();
      } else {
        alert("휴대폰 본인 인증에 실패했습니다.\n다시 시도해주세요.");
      }
    }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <>
      <form name={"form_chk"}>
        <input type={"hidden"} name={"m"} value={"checkplusService"} />
        <input
          type={"hidden"}
          name={"EncodeData"}
          value={
            phone_verification.loaded ? phone_verification.data.enc_data : ""
          }
        />
      </form>
      {phone_verification.loaded && modalInfo && (
        <Modal
          visible={phone_verification.loaded && !isVerified}
          handleVisible={modalInfo.handleVisible}
          style={{}}
          header={modalInfo.header}
          img_path={modalInfo.img_path}
          content={modalInfo.content}
          cta={modalInfo.cta}
        />
      )}
    </>
  );
}

function fnPopup() {
  window.open(
    "",
    "popupChk",
    "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
  );
  document.form_chk.action =
    "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
  document.form_chk.target = "popupChk";
  document.form_chk.submit();
}

function mapStateToProps(state) {
  const { authentication, phone_verification } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    phone_verification,
  };
}

const connectedPhoneVerification = connect(mapStateToProps, {
  getPhoneVerification: phoneVerificationActions.getPhoneVerification,
})(PhoneVerification);
export { connectedPhoneVerification as PhoneVerification };
