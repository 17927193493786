import { PRODUCT_NAMES } from "../../../../_constants/productNames.constants";

interface GetAfterFinishUrlProps {
  productName: (typeof PRODUCT_NAMES)[keyof typeof PRODUCT_NAMES];
  enrolled_id: string;
}

export const getAfterFinishUrl = ({
  productName,
  enrolled_id,
}: GetAfterFinishUrlProps) => {
  switch (productName) {
    case PRODUCT_NAMES.B2B:
      return `/enrolleds/${enrolled_id}/b2b_admin_nps`;
    case PRODUCT_NAMES.KDT:
    case PRODUCT_NAMES.HANGHAE:
      return `/classroom`;
    default:
      return `/complete_recommend?enrolled_id=${enrolled_id}`;
  }
};
