import { rcmdService } from "../services";
import {
  RCMD_COURSE_GENERAL,
  RCMD_FREE_COURSE,
  RCMD_FREE_COURSE_POST,
  RCMD_SURVEY,
} from "../constants";

function getRcmdFreeCourse() {
  function request() {
    return { type: RCMD_FREE_COURSE.RCMD_FREE_COURSE_REQUEST };
  }

  function success(response) {
    return { type: RCMD_FREE_COURSE.RCMD_FREE_COURSE_SUCCESS, response };
  }

  function failure(error) {
    return { type: RCMD_FREE_COURSE.RCMD_FREE_COURSE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    rcmdService.getRcmdFreeCourse().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getRcmdCourses(enrolled_id) {
  function request() {
    return { type: RCMD_COURSE_GENERAL.RCMD_COURSE_GENERAL_REQUEST };
  }

  function success(response) {
    return { type: RCMD_COURSE_GENERAL.RCMD_COURSE_GENERAL_SUCCESS, response };
  }

  function failure(error) {
    return { type: RCMD_COURSE_GENERAL.RCMD_COURSE_GENERAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    rcmdService.getRcmdCourses(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postRcmdFreeCourse(data) {
  function request() {
    return { type: RCMD_FREE_COURSE_POST.RCMD_FREE_COURSE_POST_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return rcmdService.postRcmdFreeCourse(data);
  };
}

function postRcmdSurvey(data) {
  function request() {
    return { type: RCMD_SURVEY.RCMD_SURVEY_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return rcmdService.postRcmdSurvey(data);
  };
}

export const rcmdActions = {
  getRcmdFreeCourse,
  postRcmdFreeCourse,
  getRcmdCourses,
  postRcmdSurvey,
};
