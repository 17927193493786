import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./WelcomeMessage.module.scss";
import { connect } from "react-redux";
import { liveUserActions } from "../../../_state/actions/live_user.actions";
import CountUp from "react-countup";

const cx = classNames.bind(styles);

function WelcomeMessage({ user, liveUser, getLiveUser }) {
  useEffect(() => {
    if (user) {
      getLiveUser();
    }
  }, [user]);
  return (
    <div className={cx("main-copy-wrapper")}>
      <h2 className={cx("main-copy")}>
        <span className={cx("bold_span")}>
          {"공부를 시작하기 전,"}
          <br></br>
        </span>
        함께 달리고 있는{" "}
        {liveUser.loaded ? (
          <CountUp
            start={0}
            end={liveUser.data.count}
            separator={","}
            duration={2.5}
          />
        ) : (
          0
        )}
        명의 동료들과 응원을 나눠 보아요!
        <img src={`/assets/icons/ic_handsup.png`} />
      </h2>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, liveUser } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    liveUser,
  };
}

const connectedWelcomeMessage = connect(mapStateToProps, {
  getLiveUser: liveUserActions.getLiveUser,
})(WelcomeMessage);
export { connectedWelcomeMessage as WelcomeMessage };
