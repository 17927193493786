import { handleResponse } from "../_helpers";
import { getApiTodaydiscountInfoUrl } from "../_urls";

function getTodaydiscount(course_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  const endpoint = getApiTodaydiscountInfoUrl(course_id);
  return fetch(endpoint, requestOptions).then(handleResponse);
}

export const todaydiscountService = {
  getTodaydiscount,
};
