import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";

export const BroadcastListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: relative;
`;

export const BroadcastListInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Pretendard;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  width: 224px;
  height: 40px;
  padding: 10px 14px;

  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#3A3E41";
      default:
        return "#eef3f6";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "#d7e0e6";
      }
    }};
  border-radius: 8px;
  box-sizing: border-box;
`;

export const IconImg = styled.img`
  width: 64px;
`;

export const Live = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#FF6C7A";
      default:
        return "#E8344E";
    }
  }};
  display: flex;
  gap: 4px;

  div {
    margin-top: 6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#FF6C7A";
        default:
          return "#E8344E";
      }
    }};
  }
`;

export const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const BroadcastContainerWrapper = styled.div`
  position: absolute;
  top: 55px;
  width: 320px;
  height: 222px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2b2d2e";
      default:
        return "#ffffff";
    }
  }};
  z-index: 2;
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5f666b";
        default:
          return "#c7d2d8";
      }
    }};
  box-shadow: 0 8px 24px rgba(129, 137, 143, 0.12);
  border-radius: 8px;
  overflow-y: scroll;
`;
