import * as S from "./B2bAdminNPSLongNextBtn.style";
import { useEffect, useState } from "react";
import { useCreateB2bAdminNpsResults } from "../../../queries/b2bAdminNps";
import { checkIsAllAnswered } from "../../../businesslogics/b2bAdminNps";
import { history } from "../../../../_helpers";

export const B2bAdminNPSLongNextBtn = ({ npsResultData, enrolled }) => {
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const { mutate: mutateCreateResult } =
    useCreateB2bAdminNpsResults(npsResultData);

  useEffect(() => {
    setIsAllAnswered(checkIsAllAnswered(npsResultData));
  }, [npsResultData]);

  const onClickSubmit = () => {
    if (!isAllAnswered) {
      alert("모든 설문을 완료해주세요.");
      return;
    }
    mutateCreateResult(npsResultData, {
      onSuccess: (data) => {
        alert("설문이 제출되었습니다.");
        history.push(
          `/enrolleds/${enrolled?.data?.enrolled_id}/rounds/${enrolled?.data?.round_id}/roadmap`
        );
      },
    });
  };

  return (
    <S.Container onClick={() => onClickSubmit()} isAllAnswered={isAllAnswered}>
      <S.Text>강의 설문 제출하기</S.Text>
    </S.Container>
  );
};
