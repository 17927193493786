export const devCareerPrepCoursesList = [
  "64e32bb1fd878f27f5acae5f", // 1시간 만에 정복하는 코딩테스트 합격법
  "625963fa9d5b4ee7f14b61d9", // 개발자 취업 준비의 모든 것
  "652bea6b08647a82bb62eb54", // 앱 개발의 모든 것, 안드로이드 vs iOS
  "65369d38ceb87cbda112e69a", // 취업, 이직을 위한 데이터분석의 모든 것
  "6535d721ceb87cbda112900a", // 디자이너 취업을 위한 포트폴리오의 모든 것
  "6551b81ab4c831ca481ae7d3", // 개발자 취업시장에서 살아남기 : 웹 개발자 편
  "65b31ccd173c32f5c61bdf29", // ai 무료특강.
  "65fd3e2e36ebf3dc285a0703", // 2024년 실패 없는 개발자 취업 전략
  "6604e25bb84072071779ac26", // 2024년 웹 개발자 이직의 모든 것
  "66149cf7da23f3296a9573a5", // 취준진담 : 취업 나만 힘들어?
];

export const pmCareerPrepCoursesList = [
  "65726d4c67601cb2def59170", // 주니어 PM을 위한 성장 바이블
];

export const recommendRebootCourseList = [
  "65fd3e2e36ebf3dc285a0703", // 2024년 실패 없는 개발자 취업 전략
];

export const recommendSkillUpCourseList = [
  "6604e25bb84072071779ac26", // 2024년 웹 개발자 이직의 모든 것
];

export const licenseCoursesList = [
  "6573ee78a6f2f06b38506f37", // [격파르타] ADSP 자격증 챌린지
  "6572dfed4ede01ffe8b0daaf", // [격파르타] SQLD 자격증 챌린지
];
