import styled from "@emotion/styled";

import {
  neutralDay,
  mBody1,
  mBody2,
  mBody4,
  mHeader1,
  mTitle1,
  mTitle2,
  scc,
  wBody1,
  wHeader2,
  wTitle1,
  wTitle5,
  wBody4,
} from "@teamsparta/design-system";
import { keyframes } from "@emotion/css";
import { Device } from "../../../../_styles/device";

const slideInFromTop = keyframes`
  0% {
    transform: translateY(5%); /* 초기 상태에서 위로 이동하여 보이지 않음 */
    opacity: 0; /* 초기 상태에서 투명도 0 (요소가 보이지 않음) */
  }
  100% {
    transform: translateY(0); /* 나타난 상태로 아래로 이동하여 보이도록 함 */
    opacity: 1; /* 나타난 상태로 투명도 1 (요소가 완전히 보임) */
  }
`;

export const Container = styled.div`
  background: ${neutralDay.gray100};
  padding: 60px 16px;
  ${Device.Desktop} {
    padding: 100px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CourseBox = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: rgba(58, 62, 65, 0.5);
  box-shadow: 0px 0px 100px 0px rgba(255, 255, 255, 0.1);
  border: 1px solid #3a3e41;
  padding: 32px;

  ${Device.Desktop} {
    gap: 60px;
    padding: 50px;
    width: 600px;
  }
`;

export const Title = styled.div`
  ${mHeader1};
  color: ${neutralDay.gray0};
  margin-bottom: 40px;

  ${Device.Desktop} {
    ${wHeader2};
    text-align: center;
    color: ${neutralDay.gray0};
    margin-bottom: 50px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  align-items: center;

  ${Device.Desktop} {
    gap: 50px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`;

export const Hr = styled.div`
  height: 1px;
  align-self: stretch;
  background: #3a3e41;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
`;

export const CourseOverviewCategory = styled.div`
  ${mBody4};
  color: ${neutralDay.gray50};
  ${Device.Desktop} {
    ${wBody4};
    color: ${neutralDay.gray50};
  }
`;
export const CourseOverviewValue = styled.div`
  ${mBody1};
  color: ${neutralDay.gray0};
  ${Device.Desktop} {
    ${wBody1};
    color: ${neutralDay.gray0};
  }
`;
export const CourseOverviewDetail = styled.div`
  ${mBody4};
  color: ${neutralDay.gray50};
  ${Device.Desktop} {
    ${wBody4};
    color: ${neutralDay.gray50};
  }
`;

export const RecommendCourseBoxTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CourseDescription = styled.div`
  ${mBody2};
  color: ${neutralDay.gray50};
  ${Device.Desktop} {
    ${wTitle5};
    color: ${neutralDay.gray50};
  }
`;

export const CourseName = styled.div`
  ${mTitle1};
  color: ${neutralDay.gray0};
  ${Device.Desktop} {
    ${wTitle1};
    color: ${neutralDay.gray0};
  }
`;

export const CourseOverviewContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  ${Device.Desktop} {
    gap: 4px;
    width: 140px;
  }
`;

export const Cta = styled.div`
  ${mTitle2};
  cursor: pointer;
  animation: ${slideInFromTop} 1s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${scc.red100};
  border-radius: 8px;
  padding: 16px 20px;
  width: 100%;
  height: 60px;
  color: ${neutralDay.white};
  ${Device.Desktop} {
    ${wBody1};
    width: 100%;
    color: ${neutralDay.white};
  }
`;

export const RecommendCourseBoxTitleImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0px 10px 12px rgba(20, 22, 23, 0.24);
  ${Device.Desktop} {
    width: 100px;
    height: 100px;
  }
`;
