import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 170px;
  height: 138px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Bubble = styled.div`
  background-image: url("/v2/assets/images/red-bubble.png");
  width: 170px;
  height: fit-content;
  padding: 14px 0 21px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: #3a3e41;
  gap: 4px;
`;

export const Thumbnail = styled.img`
  margin-top: 13px;
  width: 59px;
  height: 72px;
  object-fit: contain;
`;
