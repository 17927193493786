import React, { Fragment } from "react";
import { Form, Input } from "antd";
import { validatePhoneWCountryCode } from "../../../_helper";

export const PhoneItem = ({
  cx,
  messages,
  isEmailSignUp,
  isHanium,
  unsetErrorMsg,
  _ref: ref,
}) => {
  const fillHyphen = (e) => {
    let phone = e.target.value;
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }
    ref.current.setFieldsValue({
      phone: phone,
    });
  };

  const changePhone = (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value[0] === "+") {
      ref.current.setFieldsValue({
        phone: e.target.value,
      });
    } else {
      return fillHyphen(e);
    }
  };

  const validator = (_, value) => {
    if (!value) {
      return Promise.reject(messages.required);
    }
    if (!validatePhoneWCountryCode(value)) {
      return Promise.reject(messages.types.phone);
    }
    return Promise.resolve();
  };

  return (
    <Fragment>
      <Form.Item
        label="연락처"
        name="phone"
        className={cx("form-item")}
        rules={[() => ({ validator })]}
        onChange={changePhone}
        getValueFromEvent={changePhone}
      >
        <Input placeholder={"010-1234-5678"} onChange={unsetErrorMsg} />
      </Form.Item>
      {!isHanium && isEmailSignUp && (
        <span className={cx("phoneInfo")} style={{ fontSize: 13 }}>
          * 해외 거주 시 국가번호 뒤에 하이픈(-)을 넣어주세요.
          <br />
          ex) +1-1231231234
        </span>
      )}
    </Fragment>
  );
};
