import React from "react";
import * as S from "../../../../pages/HomeworkPageV3/HomeworkPageV3.style";
import { FeedbackNotice } from "../../../../pages/HomeworkPage/FeedbackNotice/FeedbackNotice";
import { QuestionAndAnswer } from "../QuestionAndAnswer/QuestionAndAnswer";
import { HomeworkHelpButton } from "../../../molecules/HomeworkV3/HomeworkHelpButton/HomeworkHelpButton";
import { useHomeworkData } from "../../../../hooks/homeworkV3/useHomeworkData";
import { useGetMaterialByEnrolled } from "../../../../queries/homeworkV3";
import { useIsMobile } from "../../../../hooks/device";
import { useParams } from "react-router-dom";

export const HomeworkContents = ({ isOpenList }) => {
  const { enrolled_homework_id } = useParams();
  const {
    homeworkDetails,
    homeworkTitle,
    enrolledId,
    homeworkId,
    isFeedbackRequired,
    week,
    status,
    isHaveVideoLink,
  } = useHomeworkData(enrolled_homework_id);
  const { data: materialData } = useGetMaterialByEnrolled(enrolledId, week);
  const homeworkAnswerVideoLink = `/enrolleds/${enrolledId}/answer/${homeworkId}/ehomeworks/${enrolled_homework_id}`;
  const materialLink = materialData?.context?.material_url;
  const isMobile = useIsMobile();
  return (
    <S.HomeworkContents
      isMobile={isMobile}
      isOpenList={isOpenList}
      status={status}
    >
      <S.FeedbackRequiredNoticeWrapper
        status={status}
        isFeedbackRequired={isFeedbackRequired}
      >
        <FeedbackNotice status={status} />
      </S.FeedbackRequiredNoticeWrapper>
      <S.PageTitleContainer>
        <S.PageSubtitle>숙제</S.PageSubtitle>
        <S.PageTitle>{homeworkTitle}</S.PageTitle>
      </S.PageTitleContainer>
      {homeworkDetails?.map((homeworkDetail, index) => (
        <QuestionAndAnswer
          key={index}
          homeworkIndex={index}
          homeworkDetail={homeworkDetail}
        />
      ))}
      <S.HomeworkHelpButtonWrapper>
        <HomeworkHelpButton
          type={"video"}
          href={isHaveVideoLink ? homeworkAnswerVideoLink : null}
        />
        <HomeworkHelpButton type={"material"} href={materialLink} />
      </S.HomeworkHelpButtonWrapper>
    </S.HomeworkContents>
  );
};
