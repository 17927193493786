import styled from "styled-components";

export const Container = styled.div``;

export const CheckBtn = styled.button`
  display: ${(props) => (props.disable ? "none" : "block")};

  cursor: pointer;
  border: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 22px;
  width: 260px;
  height: 82px;
  border-radius: 10px;
  background-color: #e8344e;
`;
