import React from "react";
import styled from "@emotion/styled";
import {additional, neutralDay, mBody4} from "@teamsparta/design-system";

const Ul = styled.ul`
  ${mBody4};
  color : ${neutralDay.gray80};
  margin-right: 10px;
  list-style: disc;
  
  .li {
    list-style-type: disc;
  }
`
const Li = styled.li`
  //list-style-type: disc;
  :before {
    content: "•";
    color: ${additional.blue500};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  padding-left : 16px;
`
export const StartMessage = ()=> {
    return (
        <>
            안녕하세요!<br/>척척석사 르탄봇이에요.
            <Ul>
                <Li>강의 내용 중 궁금한 것이 있다면 아래 질문을 남겨주세요.</Li>
                <Li>내 코딩 실력을 점검하고 싶다면, 르탄봇의 코딩 퀴즈 풀기에 도전해보세요!</Li>
            </Ul>
        </>
    );
}
