import { LectureListItem } from "../../pages/LecturePageV3/LectureListItem/LectureListItem";
import { AiChat } from "../../components/AiChat";
import { LectureSummary } from "../../pages/LecturePageV3/LectureSummary/LectureSummary";
import { useGetEnrolledDetail } from "../../queries/homeworkV3";
import { useLectureSummary } from "../../hooks/sideBar/useSummary";
import { useParams } from "react-router-dom";
import { useCourseId } from "../../hooks/useProductName";
import { useIsChatbotCourse } from "../../businesslogics/AiChat/useChatbotCourses";

export const SideBarTabs = [
  { name: "강의 목차", component: LectureListItem },
  {
    name: "AI 챗봇",
    component: AiChat,
  },
  {
    name: "요약 노트",
    component: LectureSummary,
  },
];

export const useCanRenderSideBarTabs = () => {
  const courseId = useCourseId(); //숙제 페이지일 때는 detail_id가 없어서 course_id 얻지 못하기 때문에 해당 훅 사용
  const {
    enrolled_id: enrolledId,
    detail_id: detailId,
    enrolled_evaluation_id: enrolledEvaluationId,
    enrolled_homework_id: enrolledHomeworkId,
  } = useParams();
  const { data: enrolledDetail } = useGetEnrolledDetail(enrolledId, detailId);
  const { lecture_id: lectureId } = enrolledDetail?.context || {};
  const summary = useLectureSummary(lectureId);
  const isChatbotCourse = useIsChatbotCourse(courseId);
  /** TODO: 숙제페이지에서 챗봇 임시로 제거. KDC 감사 대비. 24.08.01 까지
   *  해당 코드가 남아있을 시 박현우 또는 박지희를 찾을 것.
   */
  const isHomeworkPage = !!enrolledHomeworkId;

  return (tabName) => {
    switch (tabName) {
      case "강의 목차":
        return true;
      case "AI 챗봇":
        return !enrolledEvaluationId && !isHomeworkPage && isChatbotCourse;
      case "요약 노트":
        return summary;
      default:
        return false;
    }
  };
};
