import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./B2bPage.module.scss";
import { connect } from "react-redux";
import { externalActions } from "../../_state/actions";
import { validatePhone } from "../../_helper";

const cx = classNames.bind(styles);

function B2bPage({ postB2bApply }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [course, setCourse] = useState("pre-step");
  const [isDone, setIsDone] = useState(false);
  const [companyName, setCompanyName] = useState("한국투자밸류자산운용");
  const [round, setRound] = useState("2기");

  return (
    <>
      <div className={cx("B2bForm")}>
        <div className={cx("B2bForm__wrapper")}>
          <img
            className={cx("B2bForm__banner")}
            src={(`/assets/images/b2b/banner_only_sparta.png`)}
            alt={"banner"}
          />
          <div className={cx("B2bForm__wrapper__pad")}></div>
          <div className={cx("B2bForm__wrapper__top")}>
            <h1 className={cx("B2bForm__header")}>
              금융인을 위한 코딩입문 교육신청서
            </h1>
            <hr />
            <p className={cx("B2bForm__desc")}>
              {companyName} X 스파르타코딩클럽 금융인을 위한 코딩입문 교육에 오신
              여러분 환영합니다. 진짜 중요한 핵심업무에 집중할 수 있도록,
              스파르타와 함께 업무효율을 개선을 시작하세요!
              <br />
              <br />
              👉 실무에 꼭 필요한 자동화를 만드는 [금융인을 위한 업무자동화]
              <br />
              👉 엑셀 노가다 없이 데이터를 뽑아내는 [SQL 기초]
              <br />
              <br />
              🔥 모집마감 : 9월 13일 (화) 까지 🔥
            </p>
          </div>
          {isDone ? (
            <div className={cx("B2bForm__done")}>제출이 완료되었습니다!</div>
          ) : (
            <div className={cx("B2bForm__form")}>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  이름<b>*</b>
                </h3>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder={"내 답변"}
                  type={"text"}
                />
              </div>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  휴대폰 번호<b>*</b>
                </h3>
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  type={"text"}
                  placeholder={"ex) 010-0000-0000"}
                />
              </div>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  사번<b>*</b>
                </h3>
                <input
                  type={"text"}
                  onChange={(e) => setCompanyId(e.target.value)}
                  value={companyId}
                  placeholder={"내 답변"}
                />
              </div>

              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  교육과목 신청 (1개 선택)<b>*</b>
                </h3>
                <p>
                  ※ 한 기수당 1개의 Step을 선택하여 수강하실 수 있습니다!
                  (Pre-step -> Step1 순으로 수강하시기를 권장드립니다.)
                  <br />※ 이전 기수에 기수료한 Step은 신청이 불가능합니다.
                </p>
                <div className={cx("B2bForm__form__row__radio")}>
                  <input
                    onChange={(e) => {
                      setCourse(e.target.value);
                    }}
                    name="course"
                    type={"radio"}
                    value={"pre-step"}
                    checked={course === "pre-step"}
                  />
                  <label htmlFor={""}>
                    Pre-step. [금융인을 위한 업무자동화]
                  </label>
                </div>
                <div className={cx("B2bForm__form__row__radio")}>
                  <input
                    onChange={(e) => {
                      setCourse(e.target.value);
                    }}
                    name="course"
                    type={"radio"}
                    value={"step1"}
                    checked={course === "step1"}
                  />
                  <label htmlFor={""}>STEP1. [SQL기초]</label>
                </div>
              </div>
              <button
                onClick={(e) => {
                  if (
                    name === "" ||
                    phone === "" ||
                    companyId === "" ||
                    course === ""
                  ) {
                    alert("입력란을 확인해주세요");
                    e.preventDefault();
                    return;
                  }

                  if (!validatePhone(phone)) {
                    alert("휴대폰 번호 형식을 확인해주세요.");
                    e.preventDefault();
                    return;
                  }

                  postB2bApply({
                    round,
                    companyName,
                    name,
                    phone,
                    companyId,
                    course,
                  }).then((resp) => {
                    if (resp.ok) {
                      setIsDone(true);
                    } else {
                      alert(resp.message);
                    }
                  });
                }}
                className={cx("B2bForm__form__cta")}
              >
                제출하기
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedB2bPage = connect(mapStateToProps, {
  postB2bApply: externalActions.postB2bApply,
})(B2bPage);
export { connectedB2bPage as B2bPage };
