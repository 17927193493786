import styled from "@emotion/styled";
import {
  neutralDay,
  mBody3,
  wBody4,
  wBody3,
  wTitle4,
  mTitle2,
  mBody4,
  scc,
  wCaption2,
} from "@teamsparta/design-system";
import { Device } from "../../../../../_styles/device";

const BenefitCard = ({ idx, cardData }) => {
  const { keyword, title, description, subText } = cardData;
  return (
    <Container>
      <Thumbnail src={`/assets/thumbnails/${keyword}.png`} />
      <SubTitle>혜택 {idx}</SubTitle>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {subText && <SubText>{subText}</SubText>}
    </Container>
  );
};

export default BenefitCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-bottom: 40px;
  ${Device.Desktop} {
    gap: 8px;
    margin-bottom: 0px;
    width: 244px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  aspect-ratio: 244 / 140;
  ${Device.Desktop} {
    height: 142px;
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  ${mTitle2};
  color: ${neutralDay.gray100};
  ${Device.Desktop} {
    ${wTitle4};
    color: ${neutralDay.gray100};
  }
`;

const SubTitle = styled.div`
  ${mBody3};
  color: ${scc.red100};
  font-weight: bold;
  ${Device.Desktop} {
    ${wBody3};
    color: ${scc.red100};
    font-weight: bold;
  }
`;

const Description = styled.div`
  ${mBody4};
  color: ${neutralDay.gray80};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;

  ${Device.Desktop} {
    ${wBody4};
    color: ${neutralDay.gray80};
  }
`;

const SubText = styled.p`
  ${wCaption2}
  color: var(--Neutral-Day-Gray60, #9DA7AE);
  color: ${neutralDay.gray60};
`;
