import * as S from "./benefitOverview.style";
import React from "react";
import { benefitsByCourseMap } from "./benefitOverview.model";
import BenefitCards from "./templates/BenefitCards";
import BenefitCard from "./atoms/BenefitCard";
import { useSelector } from "react-redux";
import { history } from "../../../../_helpers";

const Benefits = ({ benefits }) => {
  return (
    <BenefitCards>
      {benefits.map((benefit, index) => (
        <BenefitCard idx={index + 1} cardData={benefit} />
      ))}
    </BenefitCards>
  );
};

const BenefitsOverview = ({ course }) => {
  const enrolled = useSelector((state) => state.enrolled);
  const enrolled_id = enrolled?.data?.enrolled_id;
  const round_id = enrolled?.data?.round_id;
  const benefits = benefitsByCourseMap[course];

  const handleClickMore = () => {
    history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
  };

  if (!benefits) {
    return null;
  }

  return (
    <S.Container>
      <S.Contents>
        <S.Title>
          지금 합류 시 드리는 <br />
          얼리버드 혜택
        </S.Title>
        <Benefits benefits={benefits} />
      </S.Contents>
      <S.MoreContainer onClick={handleClickMore}>
        <S.MoreButton>강의 목차로 돌아가기</S.MoreButton>
      </S.MoreContainer>
    </S.Container>
  );
};

export default BenefitsOverview;
