import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  font-family: "Pretendard", "Nanum Gothic", serif;
  gap: 30px;
`;
export const Row = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
`;

export const ApplyDone = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #141617;
  @media ${`(min-width: 768px)`} {
    font-size: 24px;
  }
`;

export const CouponDesc = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #81898f;
  @media ${`(min-width: 768px)`} {
    font-size: 18px;
  }
`;

export const CouponImgContainer = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  margin-bottom: 10px;
  transform: translateX(8px);
`;

export const CouponImg = styled.img`
  width: 291px;
`;

export const CouponInfo = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #81898f;
  width: 291px;
  @media ${`(min-width: 768px)`} {
    font-size: 14px;
  }
`;

export const DivideContainer = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  position: relative;
  margin: 20px auto 0 auto;
  padding: 0 30px;
  width: 100%;
`;

export const DivideLine = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  position: relative;
  width: 100%;
  height: 2px;
  background: #e4ebf0;
  @media ${`(min-width: 768px)`} {
    margin: auto;
    width: 484px;
  }
`;

export const DivideContent = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 120px;
  height: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #3a3e41;
  border: 2px solid #e4ebf0;
  border-radius: 100px;
  background-color: #fff;
`;

export const CoursesContainer = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 9px;
`;

export const Course = styled.a`
  cursor: pointer;
`;

export const CourseImg = styled.img`
  width: 155px;
  height: 116px;
  border-radius: 8px;
`;

export const CtaContainer = styled.a`
  cursor: pointer;
`;

export const Cta = styled.button`
  width: 320px;
  height: 56px;
  display: flex;
  justify-content: center;
  font-family: "Pretendard", "Nanum Gothic", serif;
  align-items: center;
  background: #e8344e;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  @media ${`(min-width: 768px)`} {
    margin-top: 20px;
  }
`;
