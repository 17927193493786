import styled from "@emotion/styled";
import { Device } from "../../../../../_styles/device";
import { neutralDay, mTitle1, wTitle1 } from "@teamsparta/design-system";

const CategoryNameWithIcon = ({ icon, children }) => {
  return (
    <Container>
      {icon}
      <Name>{children}</Name>
    </Container>
  );
};

export default CategoryNameWithIcon;

const Container = styled.div``;

const Name = styled.div`
  margin-top: 10px;
  ${mTitle1};
  color: ${neutralDay.gray100};
  ${Device.Desktop} {
    margin-top: 12px;
    ${wTitle1};
    color: ${neutralDay.gray100};
    width: 268px;
  }
`;
