import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./NPSNextBtn.module.scss";
import { npsActions } from "../../../_state/actions/nps.actions";
import { history } from "../../../../_helpers";
import { sendLog } from "../../../businesslogics/logging";
import { courseRcmdBrand } from "../prequestionOptions";
import { useOnlineTheme } from "../../../queries/pirates";
import { TooltipBg } from "../../LecturePage/LecNextBtn/LecNextBtn.svg";
import { useLogNpsPageClick } from "../../../hooks/homework/useLogHomeworkClick";
import {
  useCurrentContentByPathname,
  useIsLastOfOEC,
  useNavigateToNextContent,
  useSyllabusUnit,
} from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

const cx = classNames.bind(styles);

function NPSNextBtn({
  enrolledId,
  enrolled,
  roundId,
  courseId,
  week,
  satisfaction,
  goodPoint,
  badPoint,
  goal,
  npsScore,
  postNPS,
  roundWeeks,
  user,
  isEvent,
  isNado,
  isTrial,
  hasCard,
  enrolledDetails,
  isLastWeekBefore,
  rcmdScore,
  contentsScore,
  contentsText,
  specialCareScore,
  specialCareText,
  qnaScore,
  qnaText,
  webpageScore,
  webpageText,
}) {
  const [isNextWeek, setIsNextWeek] = useState(null);
  const [nextUrl, setNextUrl] = useState(false);
  const [isDoubleChecked, setIsDoubleChecked] = useState(false);
  const [isTooltipShow, setIsTooltipShow] = useState(false);
  const theme = useOnlineTheme();
  const [isNextLoding, setIsNextLoding] = useState(false);
  const navigateToNext = useNavigateToNextContent();
  const currentContent = useCurrentContentByPathname();
  const isLast = useIsLastOfOEC(currentContent);
  const syllabusUnit = useSyllabusUnit(enrolled.data?.course_id);
  const buttonText = isLast
    ? "제출하고 완강하기"
    : `제출하고 ${Number(week) + 1}${syllabusUnit} 강의 듣기`;

  function isCheckedMainNPS() {
    return (
      rcmdScore !== null &&
      contentsScore !== null &&
      specialCareScore !== null &&
      qnaScore !== null &&
      webpageScore !== null
    );
  }

  async function goNext() {
    if (!roundWeeks.loaded) {
      console.log("roundWeeks not loaded");
      window.alert("다시 시도해주세요!");
      window.location.reload();
    }

    const nextSub = roundWeeks.data.weeks.filter(
      (w) => w.week === Number(week) + 1
    );

    const isLastWeek = nextSub.length === 0;

    let currentGoodPoint = goodPoint;
    let currentBadPoint = badPoint;
    if (!isLastWeekBefore) {
      if (!isTrial && satisfaction === null) {
        alert("이번 주차 수업 만족여부를 선택해주세요!");
        return;
      }

      if (npsScore === null) {
        alert("추천 점수를 선택해주세요!");
        return;
      }

      if (isLastWeek && goal === "") {
        alert("목표를 선택해주세요!");
        return;
      }

      if (isTrial && goal === null) {
        alert("목표를 선택해주세요!");
        return;
      }

      if (npsScore >= 7 && !isEvent && !isDoubleChecked) {
        setIsDoubleChecked(true);
      }

      if (
        npsScore <= 6 &&
        npsScore != null &&
        !isEvent &&
        !isDoubleChecked &&
        (currentBadPoint === null || currentBadPoint.length <= 5)
      ) {
        currentBadPoint += window.prompt(
          `${user.name}님께서 아쉬웠던 점에 대해 좀 더 자세히 알 수 있을까요?\n강의 개선에 적극 참고하겠습니다! 🙏`
        );
        setIsDoubleChecked(true);
      }
    } else {
      if (!isCheckedMainNPS()) {
        alert("만족도 점수를 선택해주세요!");
        return;
      }
    }

    // NPS 등록

    const nps = {
      satisfaction,
      goodPoint: currentGoodPoint,
      badPoint: currentBadPoint,
      npsScore,
      isLastWeek,
      goal,
      hasCard,
      rcmdScore,
      contentsScore,
      contentsText,
      specialCareScore,
      specialCareText,
      qnaScore,
      qnaText,
      webpageScore,
      webpageText,
      isLastWeekBefore,
    };

    setIsNextLoding(true);
    if (isNextLoding) return;

    try {
      const response = await postNPS(enrolledId, week, nps);
      if (response.ok) {
        sendLog("scc_nps_completed", {
          course_title: enrolled.data.courses.title,
          course_id: enrolled.data.courseId,
          brand: courseRcmdBrand[goal],
          tags: enrolled.data.courses.tags,
          nps_score: nps.npsScore,
          good_point: nps.goodPoint,
          bad_point: nps.badPoint,
          goal: nps.goal,
        });
        navigateToNext();
        // history.push(nextUrl);
        if (isLastWeek && !isTrial) {
          const logData = {
            ...enrolled.data,
            ...enrolledDetails.data,
            brand: courseRcmdBrand[goal],
            button_text: goal,
          };
          sendLog("scc_rcmdSurvey_completed", logData);
        }
      }
    } catch (error) {
      console.debug("👎", error);
    }
  }

  function isAllAnswered() {
    if (enrolled.data.courses.is_trial)
      return goal !== null && goal !== "" && npsScore !== null;
    if (isLastWeekBefore) return isCheckedMainNPS();
    return satisfaction !== null && npsScore !== null;
  }
  const logClickNpsPageButton = useLogNpsPageClick();

  return (
    <>
      <div
        className={cx(
          "nps-next-btn",
          theme.data,
          `${isAllAnswered() ? "--active" : ""}`
        )}
        onClick={() => {
          logClickNpsPageButton(buttonText, "lecturePage");
          goNext();
        }}
      >
        {buttonText}

        <svg
          className={cx("nps-next-btn__img", theme.data)}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="9"
          fill="none"
          viewBox="0 0 14 9"
        >
          <path
            fill={isAllAnswered() ? "#fff" : "#141617"}
            d="M1.623 0l5.285 5.342L12.192 0l1.623 1.653-6.907 6.982L0 1.653 1.623 0z"
          />
        </svg>

        {isTooltipShow && (
          <div className={cx("wrapper")}>
            <div
              className={cx("nps-next-btn__tooltip", theme.data)}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={cx("nps-next-btn__tooltip__bg-wrapper")}>
                <TooltipBg theme={theme.data} />
              </div>
              <img
                className={cx("nextCheck")}
                src={`/assets/images/lecture/next_tooltip_check.png`}
                alt=""
              />
              <div>
                어서 설문을 제출하고,
                <br /> 완주 축하를 확인해보세요!
              </div>

              <img
                className={cx("nextClose")}
                src={`/assets/images/lecture/nex_tooltip_close.png`}
                onClick={() => setIsTooltipShow(false)}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled } = state;
  const { user } = authentication;
  return { user, enrolled };
}

const connectedNPSNextBtn = connect(mapStateToProps, {
  postNPS: npsActions.postNPS,
})(NPSNextBtn);
export { connectedNPSNextBtn as NPSNextBtn };
