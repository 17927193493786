import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./HomeworkFormSelection.module.scss";
import { useOnlineTheme } from "../../../../queries/pirates";

const cx = classNames.bind(styles);

export const SelectionInput = ({ selectionList, onChange }) => {
  const [selected, setSelected] = useState();
  const theme = useOnlineTheme();
  const SelectionContainer = ({}) => {
    return selectionList.map((selection) => {
      const { value, text } = selection;
      return (
        <div
          className={cx(
            "course_rcmd__question",
            theme.data,
            `${text === selected ? "--selected" : ""}`
          )}
          onClick={() => {
            setSelected(text);
            onChange(text);
          }}
        >
          <svg
            className={cx("course_rcmd__question__icon")}
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 11a11 11 0 1 1-22 0 11 11 0 0 1 22 0zm-5.459-4.166a1.03 1.03 0 0 0-1.485.03l-4.775 6.084-2.878-2.879a1.031 1.031 0 0 0-1.457 1.458l3.638 3.64a1.03 1.03 0 0 0 1.483-.028l5.49-6.861a1.031 1.031 0 0 0-.014-1.444h-.002z"
              fill="#fff"
            />
          </svg>
          <span className={cx("course_rcmd__desc", theme.data)}>{text}</span>
        </div>
      );
    });
  };

  return <SelectionContainer />;
};

const HomeworkFormSelection = ({
  selectionType,
  answer,
  setAnswer,
  onUpdateSubmittedAnswer,
  order,
  hasCheckIcon,
}) => {
  const selectionList = selectionDict[selectionType];
  const theme = useOnlineTheme();
  const SelectionContainer = ({}) => {
    return selectionList.map((selection) => {
      const { value, text } = selection;
      return (
        <div
          className={cx(
            "course_rcmd__question",
            theme.data,
            `${value === answer ? "--selected" : ""}`,
            hasCheckIcon ? "" : "--no-icon"
          )}
          onClick={() => {
            setAnswer(value);
            onUpdateSubmittedAnswer(value);
          }}
        >
          {hasCheckIcon && (
            <svg
              className={cx("course_rcmd__question__icon", theme.data)}
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 11a11 11 0 1 1-22 0 11 11 0 0 1 22 0zm-5.459-4.166a1.03 1.03 0 0 0-1.485.03l-4.775 6.084-2.878-2.879a1.031 1.031 0 0 0-1.457 1.458l3.638 3.64a1.03 1.03 0 0 0 1.483-.028l5.49-6.861a1.031 1.031 0 0 0-.014-1.444h-.002z"
                fill="#fff"
              />
            </svg>
          )}
          <span className={cx("course_rcmd__desc", theme.data)}>{text}</span>
        </div>
      );
    });
  };

  return <SelectionContainer />;
};

const selectionDict = {
  check: [
    {
      text: "네",
      value: "true",
    },
    {
      text: "아니요",
      value: "false",
    },
    {
      text: "대상자가 아닙니다.",
      value: "unfit",
    },
  ],
  credit_card: [
    {
      text: "네, 신청했어요!",
      value: "waiting_card",
    },
    {
      text: "이미 가지고 있어요",
      value: "has_card",
    },
    {
      text: "대상자가 아니래요 😭",
      value: "unfit",
    },
  ],
  credit_hrd: [
    {
      text: "네, 신청했어요!",
      value: "hrd_app_fin",
    },
    {
      text: "대상자가 아니래요 😭",
      value: "unfit",
    },
  ],
  week0_check: [
    {
      text: "네",
      value: true,
    },
    {
      text: "아니오",
      value: false,
    },
  ],
  nb_free: [
    {
      text: "네, 강의 보면서 신청했어요",
      value: "waiting_card",
    },
    {
      text: "아니요, 원래 카드 갖고 있었어요",
      value: "has_card",
    },
    {
      text: "아니요, 대상자가 아니래요 😭",
      value: "unfit",
    },
  ],
};

export default HomeworkFormSelection;
