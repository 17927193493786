import { urgConstants } from "../constants";

const initState = {
  loaded: false,
};

export function isRankGroupCreated(state = initState, action) {
  switch (action.type) {
    case urgConstants.GET_URG_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case urgConstants.GET_URG_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case urgConstants.GET_URG_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function createRoundRankGroup(state = initState, action) {
  switch (action.type) {
    case urgConstants.CREATE_URG_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case urgConstants.CREATE_URG_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case urgConstants.CREATE_URG_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function UserRaceGroupInfo(state = initState, action) {
  switch (action.type) {
    case urgConstants.GET_USER_GROUP_INFO_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case urgConstants.GET_USER_GROUP_INFO_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case urgConstants.GET_USER_GROUP_INFO_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function WeeksAfterStart(state = initState, action) {
  switch (action.type) {
    case urgConstants.GET_WEEKS_AFTER_START_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case urgConstants.GET_WEEKS_AFTER_START_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case urgConstants.GET_WEEKS_AFTER_START_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
