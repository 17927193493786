import React from "react";
import styled from "@emotion/styled";

const Banner = styled.img`
  width: 100%;
  height: Auto;
  border-radius : 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;
export const RtanBanner = ()=> {
    return (
        <Banner src={"/assets/images/rtan/rtan_banner.png"}/>
    );
}
