import { useEffect } from "react";
import { useAtom } from "jotai";
import { lectureListAtom } from "../../stores/homeworkPageV3Atom";

export const useSelectedTab = (defaultTab) => {
  const [selectedTab, setSelectedTab] = useAtom(lectureListAtom);

  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  return [selectedTab, setSelectedTab];
};
