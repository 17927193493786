export const checkIsAllAnswered = (npsResultData) => {
  return npsResultData.every((result) => {
    return (
      result &&
      (result.subjectiveAnswer !== "" ||
        result.objectiveAnswer !== "" ||
        result.score !== null)
    );
  });
};
