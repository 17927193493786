import { authHeader } from "../_helpers";

export const SLACK_URL = "https://spartacodingclub.slack.com";
export const API_VERSION = "v1";
export const getPostAuthOptions = (data) => {
  return {
    method: "post",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(data),
  };
};
export const getPostOptions = (data) => {
  return {
    method: "post",
    headers: Object.assign({ "Content-Type": "application/json" }),
    body: JSON.stringify(data),
  };
};
export const getGETAuthOptions = () => {
  return {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
};
export const getPutOptions = () => {
  return {
    method: "PUT",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
};
export const getPutDataOptions = (data) => {
  return {
    method: "PUT",
    headers: Object.assign({ "Content-Type": "application/json" }),
    body: JSON.stringify(data),
  };
};
export const getGETOptions = () => {
  return {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
};
