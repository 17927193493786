export const Check = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11a11 11 0 1 1-22 0 11 11 0 0 1 22 0zm-5.459-4.166a1.03 1.03 0 0 0-1.485.03l-4.775 6.084-2.878-2.879a1.031 1.031 0 0 0-1.457 1.458l3.638 3.64a1.03 1.03 0 0 0 1.483-.028l5.49-6.861a1.031 1.031 0 0 0-.014-1.444h-.002z"
        fill="#fff"
      />
    </svg>
  );
};

export const NumIndex = ({ n }) => {
  switch (n) {
    case 1:
      return (
        <svg
          width="38"
          height="39"
          viewBox="0 0 38 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_184_12739)">
            <path
              d="M36.75 19.5C36.75 9.69695 28.8031 1.75 19 1.75C9.19695 1.75 1.25 9.69695 1.25 19.5C1.25 29.3031 9.19695 37.25 19 37.25C28.8031 37.25 36.75 29.3031 36.75 19.5Z"
              fill="rgba(0,0,0,0)"
            />
            <path
              d="M17.952 17.0301H16.412V14.7061H20.696V25.2621H17.952V17.0301Z"
              fill="#FF6C7A"
            />
            <path
              d="M36.75 19.5C36.75 9.69695 28.8031 1.75 19 1.75C9.19695 1.75 1.25 9.69695 1.25 19.5C1.25 29.3031 9.19695 37.25 19 37.25C28.8031 37.25 36.75 29.3031 36.75 19.5Z"
              stroke="#FF6C7A"
              strokeWidth="2.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_184_12739">
              <rect
                width="38"
                height="38"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 2:
      return (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_252_12637)">
            <path
              d="M36.75 19C36.75 9.19695 28.8031 1.25 19 1.25C9.19695 1.25 1.25 9.19695 1.25 19C1.25 28.8031 9.19695 36.75 19 36.75C28.8031 36.75 36.75 28.8031 36.75 19Z"
              fill="rgba(0,0,0,0)"
            />
            <path
              d="M23.132 23.9999H14.634L17.252 21.2839C17.942 20.5649 18.484 19.9589 18.876 19.4639C19.268 18.9599 19.557 18.5349 19.744 18.1899C19.94 17.8449 20.061 17.5649 20.108 17.3499C20.155 17.1349 20.178 16.9529 20.178 16.8039C20.178 16.6449 20.15 16.4909 20.094 16.3419C20.047 16.1919 19.973 16.0619 19.87 15.9499C19.767 15.8289 19.641 15.7349 19.492 15.6699C19.352 15.5949 19.184 15.5579 18.988 15.5579C18.615 15.5579 18.321 15.6789 18.106 15.9219C17.901 16.1649 17.798 16.4679 17.798 16.8319C17.798 16.9349 17.812 17.0839 17.84 17.2799H14.844C14.844 16.6639 14.942 16.1039 15.138 15.5999C15.344 15.0959 15.628 14.6619 15.992 14.2979C16.356 13.9339 16.785 13.6539 17.28 13.4579C17.784 13.2619 18.335 13.1639 18.932 13.1639C19.52 13.1639 20.062 13.2569 20.556 13.4439C21.06 13.6309 21.494 13.8919 21.858 14.2279C22.222 14.5639 22.507 14.9649 22.712 15.4319C22.918 15.8889 23.02 16.3889 23.02 16.9299C23.02 17.5089 22.894 18.0449 22.642 18.5399C22.4 19.0249 22.008 19.5249 21.466 20.0379L19.716 21.6759H23.132V23.9999Z"
              fill="#FF6C7A"
            />
            <path
              d="M36.75 19C36.75 9.19695 28.8031 1.25 19 1.25C9.19695 1.25 1.25 9.19695 1.25 19C1.25 28.8031 9.19695 36.75 19 36.75C28.8031 36.75 36.75 28.8031 36.75 19Z"
              stroke="#FF6C7A"
              strokeWidth="2.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_252_12637">
              <rect width="38" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 3:
      return (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_252_12641)">
            <path
              d="M36.75 19C36.75 9.19695 28.8031 1.25 19 1.25C9.19695 1.25 1.25 9.19695 1.25 19C1.25 28.8031 9.19695 36.75 19 36.75C28.8031 36.75 36.75 28.8031 36.75 19Z"
              fill="rgba(0,0,0,0)"
            />
            <path
              d="M18.428 18.322C18.503 18.332 18.563 18.336 18.61 18.336H18.75C19.095 18.336 19.375 18.233 19.59 18.028C19.805 17.823 19.912 17.557 19.912 17.23C19.912 16.913 19.809 16.656 19.604 16.46C19.408 16.264 19.151 16.166 18.834 16.166C18.498 16.166 18.227 16.278 18.022 16.502C17.817 16.726 17.714 17.015 17.714 17.37H15.18C15.245 16.82 15.38 16.33 15.586 15.9C15.8 15.47 16.071 15.111 16.398 14.822C16.724 14.523 17.108 14.299 17.546 14.15C17.985 13.991 18.47 13.912 19.002 13.912C19.525 13.912 19.996 13.987 20.416 14.136C20.846 14.276 21.209 14.481 21.508 14.752C21.816 15.013 22.049 15.335 22.208 15.718C22.376 16.091 22.46 16.511 22.46 16.978C22.46 17.482 22.362 17.898 22.166 18.224C21.979 18.541 21.709 18.817 21.354 19.05C21.895 19.218 22.315 19.521 22.614 19.96C22.922 20.39 23.076 20.921 23.076 21.556C23.076 22.079 22.973 22.555 22.768 22.984C22.562 23.414 22.273 23.782 21.9 24.09C21.536 24.398 21.097 24.636 20.584 24.804C20.08 24.972 19.524 25.056 18.918 25.056C18.106 25.056 17.373 24.897 16.72 24.58C16.067 24.263 15.544 23.759 15.152 23.068C14.975 22.76 14.853 22.452 14.788 22.144C14.732 21.836 14.704 21.524 14.704 21.206H17.42C17.438 21.701 17.583 22.079 17.854 22.34C18.124 22.592 18.488 22.718 18.946 22.718C19.431 22.718 19.786 22.588 20.01 22.326C20.234 22.065 20.346 21.743 20.346 21.36C20.346 20.903 20.21 20.557 19.94 20.324C19.67 20.091 19.347 19.974 18.974 19.974C18.881 19.974 18.792 19.984 18.708 20.002C18.624 20.012 18.531 20.025 18.428 20.044V18.322Z"
              fill="#FF6C7A"
            />
            <path
              d="M36.75 19C36.75 9.19695 28.8031 1.25 19 1.25C9.19695 1.25 1.25 9.19695 1.25 19C1.25 28.8031 9.19695 36.75 19 36.75C28.8031 36.75 36.75 28.8031 36.75 19Z"
              stroke="#FF6C7A"
              strokeWidth="2.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_252_12641">
              <rect width="38" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 4:
      return (
        <svg
          width="38"
          height="39"
          viewBox="0 0 38 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_252_12645)">
            <path
              d="M36.75 19.5C36.75 9.69695 28.8031 1.75 19 1.75C9.19695 1.75 1.25 9.69695 1.25 19.5C1.25 29.3031 9.19695 37.25 19 37.25C28.8031 37.25 36.75 29.3031 36.75 19.5Z"
              fill="rgba(0,0,0,0)"
            />
            <path
              d="M19.534 20.23V16.072L17.112 20.23H19.534ZM22.082 20.23H23.356V22.33H22.082V24.5H19.534V22.33H14.438V20.566L18.47 13.944H22.082V20.23Z"
              fill="#FF6C7A"
            />
            <path
              d="M36.75 19.5C36.75 9.69695 28.8031 1.75 19 1.75C9.19695 1.75 1.25 9.69695 1.25 19.5C1.25 29.3031 9.19695 37.25 19 37.25C28.8031 37.25 36.75 29.3031 36.75 19.5Z"
              stroke="#FF6C7A"
              strokeWidth="2.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_252_12645">
              <rect
                width="38"
                height="38"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    case 5:
      return (
        <svg
          width="38"
          height="39"
          viewBox="0 0 38 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_259_13415)">
            <path
              d="M36.75 19.5C36.75 9.69695 28.8031 1.75 19 1.75C9.19695 1.75 1.25 9.69695 1.25 19.5C1.25 29.3031 9.19695 37.25 19 37.25C28.8031 37.25 36.75 29.3031 36.75 19.5Z"
              fill="rgba(0,0,0,0)"
            />
            <path
              d="M22.0823 16.2681H18.4283L18.2463 17.4301C18.4143 17.4114 18.5683 17.3974 18.7083 17.3881C18.8483 17.3694 18.993 17.3601 19.1423 17.3601C19.6743 17.3601 20.169 17.4534 20.6263 17.6401C21.0836 17.8268 21.4756 18.0834 21.8023 18.4101C22.129 18.7368 22.3856 19.1288 22.5723 19.5861C22.759 20.0434 22.8523 20.5428 22.8523 21.0841C22.8523 21.6534 22.745 22.1668 22.5303 22.6241C22.325 23.0814 22.031 23.4734 21.6483 23.8001C21.2656 24.1174 20.799 24.3601 20.2483 24.5281C19.6976 24.7054 19.0863 24.7941 18.4143 24.7941C17.7796 24.7941 17.173 24.7148 16.5943 24.5561C16.025 24.4068 15.4883 24.1734 14.9843 23.8561L15.3903 21.2801C15.857 21.6814 16.333 21.9894 16.8183 22.2041C17.3036 22.4094 17.7656 22.5121 18.2043 22.5121C18.7643 22.5121 19.2076 22.3628 19.5343 22.0641C19.861 21.7561 20.0243 21.3781 20.0243 20.9301C20.0243 20.4074 19.805 19.9874 19.3663 19.6701C18.9276 19.3434 18.3536 19.1801 17.6443 19.1801C17.327 19.1801 17.0003 19.2174 16.6643 19.2921C16.3283 19.3574 15.997 19.4648 15.6703 19.6141L16.5523 13.9441H22.0823V16.2681Z"
              fill="#FF6C7A"
            />
            <path
              d="M36.75 19.5C36.75 9.69695 28.8031 1.75 19 1.75C9.19695 1.75 1.25 9.69695 1.25 19.5C1.25 29.3031 9.19695 37.25 19 37.25C28.8031 37.25 36.75 29.3031 36.75 19.5Z"
              stroke="#FF6C7A"
              strokeWidth="2.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_259_13415">
              <rect
                width="38"
                height="38"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    default:
      return <></>;
  }
};
