import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./ClassCard.module.scss";
import { ClassCardBtn } from "./ClassCardBtn/ClassCardBtn";
import { connect } from "react-redux";
import { keyword2icon } from "../../../../assets/icons/keyword2icon";
import { history } from "../../../../../_helpers";
import {
  getISODateAsKorean,
  getISODateAsKoreanWithYear,
} from "../../../../pages/RoadmapPage/RoundWeek/utils";
import {
  classroomActions,
  UserRaceGroupActions,
} from "../../../../_state/actions";
import { course } from "../../../../../_reducers/course.reducer";
import { ClassCardSelect } from "./ClassCardSelect";

const cx = classNames.bind(styles);
const keyword2icons = keyword2icon;

function ClassCard({ course_data, current_type, environment }) {
  const isSparthon = course_data?.course?._id === "60d427c0f15b251027b0e7b9";
  
  return (
    <div className={cx("classRow")}>
      <div className={cx("card_container")}>
        <div className={cx("courseIcon")}>
          {!course_data.voucher_id ? (
            <>
              {course_data.course?.keyword in keyword2icons ? (
                <img
                  src={`/assets/icons/course/ic_course_${
                    keyword2icons[course_data.course.keyword]
                  }.png`}
                  alt={`/assets/icons/course/ic_course_web.png`}
                />
              ) : (
                <img src={`/assets/icons/course/ic_course_web.png`} alt="" />
              )}
            </>
          ) : (
            <div className={cx("coursePadding")} />
          )}
        </div>
        <div className={cx("card")}>
          <div className={cx("course_title")}>
            {course_data.voucher_id
              ? course_data.package_title
              : `${course_data.course?.title} ${course_data?.round_title}`}
          </div>
          <div className={cx("divider")} />
          <div className={cx("course_duration")}>
            <div className={cx("course_weeks")}>
              {isSparthon
                ? "1일"
                : course_data.voucher_id
                ? "사용기간"
                : course_data?.is_forever
                ? "평생"
                : course_data?.course_title === "추억소환 코딩 패키지"
                ? "1주"
                : `${course_data?.last_week}주`}
            </div>
            <div className={cx("course_days", current_type)}>
              {isSparthon
                ? "9/17 ~ 9/18"
                : course_data.voucher_id
                ? `${getISODateAsKoreanWithYear(
                    course_data.created_at
                  )} ~ ${getISODateAsKoreanWithYear(
                    course_data.expire_date,
                    true
                  )}`
                : course_data.is_forever
                ? "평생 수강 가능"
                : `${getISODateAsKorean(
                    course_data.enrolled.start_date,
                    false
                  )} ~ ${getISODateAsKorean(course_data.end_day, true)}`}
            </div>
          </div>

          {!isSparthon && (
            <div className={cx("bottom_cta")}>
              <ClassCardBtn current_type={current_type} course={course_data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { environment } = state;
  return {};
}

const connectedClassCard = connect(mapStateToProps, {})(ClassCard);
export { connectedClassCard as ClassCard };
