import { COURSE_RETENTION } from "../constants";

const initState = {
  loaded: false,
};

export function courseRetention(state = initState, action) {
  switch (action.type) {
    case COURSE_RETENTION.COURSE_RETENTION_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case COURSE_RETENTION.COURSE_RETENTION_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case COURSE_RETENTION.COURSE_RETENTION_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
