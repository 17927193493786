import {
  gfsDeadlineConstants,
  gfsIsCardReceivedConstants,
  govCompletionMilestoneConstants,
} from "../_constants/gov.constants";
import { govService } from "../_services/gov.service";

function getGovCompletionMilestone(courseId) {
  return _action(
    govService.getGovCompletionMilestone,
    govCompletionMilestoneConstants,
    [courseId]
  );
}

function getGfsDeadline() {
  return _action(govService.getGfsDeadline, gfsDeadlineConstants);
}

function getGfsIsCardReceived() {
  return _action(govService.getGfsIsCardReceived, gfsIsCardReceivedConstants);
}

function _action(service, constants, args) {
  function request() {
    return { type: constants.REQUEST };
  }

  function success(response) {
    return { type: constants.SUCCESS, response };
  }

  function failure(error) {
    return { type: constants.FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    service.apply(null, args).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const govActions = {
  getGfsDeadline,
  getGovCompletionMilestone,
  getGfsIsCardReceived,
};
