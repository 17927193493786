import { reducer } from "../reducers";
import { action } from "../actions/action";
import privateInstance from "../../../v2/_helper/axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import useConstructor from "../../../hooks/useConstructor";
import { useDevice } from "../../pages/NBCampAttendPage";

const initState = {
  loaded: false,
};

const HRD_LINK = {
  REQUEST: "HRD_LINK_REQUEST",
  SUCCESS: "HRD_LINK_SUCCESS",
  FAILURE: "HRD_LINK_FAILURE",
};

async function _service() {
  const courseId = "6115cea3e73913245c9538e0";
  const roundId = "616d7b24774493437e0a7f64";
  const result = await privateInstance.get(
    `/prequestion/${courseId}/${roundId}`
  );
  return result.data;
}

export function getHrdLink() {
  return action(_service, HRD_LINK);
}

export function hrdLink(state = initState, action) {
  return reducer(state, action, HRD_LINK);
}

export const useGetHrdLink = () => {
  const dispatch = useDispatch();
  useConstructor(() => {
    dispatch(getHrdLink());
  });
};

export const useHrdLink2 = () => {
  const device = useDevice();
  const isDesktop = device === "pc" || device === "tablet";
  const hrdLink = useSelector((state) => state.hrdLink);
  const getLink = () => {
    return {
      isLoaded: hrdLink.loaded,
      link: isDesktop
        ? "https://nb-enroll.oopy.io/e2a07bd4-24e4-440b-9680-1b122ae2ea4f"
        : "https://nb-enroll.oopy.io/",
    };
  };
  const [link, setLink] = useState(getLink());

  useEffect(() => {
    setLink(getLink());
  }, [hrdLink]);

  return link;
};
