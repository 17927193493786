import { BEST_CHECKINS, CHECKINS } from "../constants";

const initState = {
  loaded: false,
};

export function bestCheckins(state = initState, action) {
  switch (action.type) {
    case BEST_CHECKINS.BEST_CHECKINS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case BEST_CHECKINS.BEST_CHECKINS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case BEST_CHECKINS.BEST_CHECKINS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function allCheckins(state = initState, action) {
  switch (action.type) {
    case CHECKINS.CHECKINS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case CHECKINS.CHECKINS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case CHECKINS.CHECKINS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function isCheckinCreated(state = initState, action) {
  switch (action.type) {
    case CHECKINS.CHECKINS_CREATED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case CHECKINS.CHECKINS_CREATED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case CHECKINS.CHECKINS_CREATED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
