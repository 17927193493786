import React from "react";
import { bizDropdownList } from "../models/gnbV3.models";
import * as S from "./GnbDropDown.style";
import { sendLog } from "../../../businesslogics/logging";
import { history } from "../../../../_helpers";
import {
  getLogEventName,
  getOutLinkTarget,
} from "../../../businesslogics/_layout/gnb/v2";

export const BizDropdown = () => {
  return (
    <S.DropdownContainer>
      {bizDropdownList.map((item, index) => {
        return (
          <>
            <S.DropdownItem
              key={item.key}
              onClick={() => {
                if (item.href) {
                  const eventName = getLogEventName(item.key);
                  // outlink 인 경우에만 새창에서 띄우고, 같은 도메인인 경우 router 로 이동
                  sendLog(eventName, {
                    band: item?.brand ?? "",
                    page_url: window.location.href,
                    page_title: document.title,
                    button_text: item.name,
                    button_href: item.href,
                  });
                  if (getOutLinkTarget(item.key)) {
                    window.open(item.href);
                  } else if (item.href.includes("://")) {
                    window.location.href = item.href;
                  } else {
                    history.push(item.href);
                  }
                }
              }}
            >
              {item.name}
            </S.DropdownItem>
            {index !== bizDropdownList.length - 1 && <S.Divder />}
          </>
        );
      })}
    </S.DropdownContainer>
  );
};
