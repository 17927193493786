import * as S from "../../../HomeworkV3/HomeworkAnswer/HomeworkAnswer.style";
import { RadioStyle, SelectBox } from "./evaluationMultiChoice.style";
import { useGetEvaluationSummaryData } from "../../../../../hooks/evaluation/useEvaluationData";

export const EvaluationMultiChoice = ({ questionData }) => {
  const { submittedAnswer, correctAnswer } =
    useGetEvaluationSummaryData(questionData);
  const determineStatus = (optionIndexString) => {
    if (
      correctAnswer === submittedAnswer &&
      optionIndexString === correctAnswer
    ) {
      return "correct";
    } else if (optionIndexString === submittedAnswer) {
      return "incorrect";
    }
    return "unselected";
  };

  return (
    <S.SelectAnswerWrapper>
      {questionData?.questionDetails.options.map((option, index) => {
        const optionIndexString = (index + 1).toString();
        const isCorrectOption = optionIndexString === correctAnswer;
        const status = determineStatus(optionIndexString);

        return (
          <SelectBox key={index} status={status}>
            <RadioStyle
              type="radio"
              value={option}
              checked={optionIndexString === submittedAnswer}
              readOnly
              isCorrect={isCorrectOption}
            />
            {option}
          </SelectBox>
        );
      })}
    </S.SelectAnswerWrapper>
  );
};
