import { useDispatch, useSelector } from "react-redux";
import {
  getGovDailyRecord,
  getTotalStudyTime,
} from "../../_state/actions/gov.actions";
import {useEffect} from "react";

export const useDailyRecord = (userId) => {
  const govDailyRecord = useSelector((state) => state.govDailyRecord);
  const dispatch = useDispatch();

  return [
    govDailyRecord?.data?.context,
    (start, end) => {
      if (!userId) return;
      dispatch(getGovDailyRecord(userId, start, end))
    },
  ];
};

export const useTotalStudyTime = (user) => {
  const govTotalStudyTime = useSelector((state) => state.govTotalStudyTime);
  const dispatch = useDispatch();
  useEffect(() => {
    if (govTotalStudyTime.isLoaded || govTotalStudyTime.isLoading) {
      return;
    }

    if (!user) {
      return;
    }

    dispatch(getTotalStudyTime(user._id));
  }, []);
  return govTotalStudyTime?.data?.context || 0;
};
