import styled from "styled-components";

export const GovIntroBanner = styled.div`
  width: "100%";
  max-width: "828px";
  position: "relative";
  height: "auto";
`;

export const CtaPosition = styled.a`
  top: "76%";
  left: "55.5%";
  height: "11%";
  width: "11%";
  display: "block";
  position: "absolute";
`;

export const Title = styled.span`
  white-space: nowrap;
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
    2px 2px 0 #fff;
  font-family: "GmarketSans";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.04px;
  text-align: left;
  color: #1c1010;
`;

export const Caption = styled.span`
  text-shadow: -1.6px -1.6px 0 #fff, 1.6px -1.6px 0 #fff, -1.6px 1.6px 0 #fff,
    1.6px 1.6px 0 #fff;
  font-family: "GmarketSans";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.04px;
  text-align: left;
  color: #c20000;
`;

export const FlexSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexSpaceGap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => `${gap}px`};
`;

export const OneLineTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 19px;
  line-height: 130%;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #141617;

  span {
    color: #e8344e;
  }
`;

export const SquareCta = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-weight: 700;
  gap: 8px;
  height: 48px;
  border: 1px solid ${({ isAccent }) => (isAccent ? "#E8344E" : "#B4BFC6")};
  color: ${({ isAccent }) => (isAccent ? "#E8344E" : "#5F666B")};
  border-radius: 6px;
`;
