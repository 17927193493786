import { friendRecoConstants } from "../_constants";

const initState = {
  loaded: false,
};

export function friendRecoInfo(state = initState, action) {
  switch (action.type) {
    case friendRecoConstants.FRIEND_RECO_INFO_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case friendRecoConstants.FRIEND_RECO_INFO_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case friendRecoConstants.FRIEND_RECO_INFO_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
export function friendRecoChart(state = initState, action) {
  switch (action.type) {
    case friendRecoConstants.FRIEND_RECO_TOP_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case friendRecoConstants.FRIEND_RECO_TOP_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case friendRecoConstants.FRIEND_RECO_TOP_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function friendRecoFreeChart(state = initState, action) {
  switch (action.type) {
    case friendRecoConstants.FRIEND_RECO_FREE_CART_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case friendRecoConstants.FRIEND_RECO_FREE_CART_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case friendRecoConstants.FRIEND_RECO_FREE_CART_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
