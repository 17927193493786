import { Modal } from "../../../components/organisms/Modal/Modal";
import { useHistory } from "react-router-dom";

import React, { useEffect } from "react";

import * as S from "./GptModal.style";
import { ContentTail } from "./GptModal.style";

const GptModal = ({
  handleVisible,
  data,
  nextUrl,
  handleCloseModal,
  setMarketingAgreement,
  sendLog,
}) => {
  const { imgPath, title, content, btnText } = data;
  const history = useHistory();
  return (
    <Modal
      styleName={"gpt"}
      visible={true}
      handleVisible={handleVisible}
      style={{}}
      img_path={imgPath}
      img_style={"__fit-gpt"}
      content={
        <S.ContentContainer>
          <S.ContentTitle>{title}</S.ContentTitle>
        </S.ContentContainer>
      }
      ctaList={[
        {
          btn_text: "닫기",
          btnStyleName: "gpt_false",
          handleCTAClick: () => {
            handleVisible(false);
            sendLog(
              "scc_prequestionDonePage",
              {
                button_text: "닫기",
              },
              ""
            );
            handleCloseModal(false);
          },
        },
        {
          btn_text: "네, 받을래요",
          btnStyleName: "gpt_true",
          handleCTAClick: () => {
            handleVisible(false);
            sendLog(
              "scc_prequestionDonePage",
              {
                button_text: "받을래요",
              },
              ""
            );
            handleCloseModal(true);
            setMarketingAgreement({ marketingAgreement: true });
          },
        },
      ]}
      tailInfo={
        <S.ContentTail>
          ‘네, 받을래요' 선택 시 광고성 정보 수신 동의 처리 됩니다.
          <br />
          [마이페이지>계정]에서 언제든지 철회 가능해요.
        </S.ContentTail>
      }
      handleCloseModal={handleCloseModal}
    />
  );
};

export const MarketingSecondModal = ({
  handleVisible,
  data,
  handleCloseModal,
  setMarketingAgreement,
}) => {
  const { imgPath, title } = data;
  return (
    <Modal
      styleName={"gpt"}
      visible={true}
      handleVisible={handleVisible}
      style={{}}
      img_path={imgPath}
      img_style={"__fit-gpt"}
      content={
        <S.ContentContainer>
          <S.ContentTitle>{title}</S.ContentTitle>
        </S.ContentContainer>
      }
      ctaList={[
        {
          btn_text: "아니요",
          btnStyleName: "gpt_false",
          handleCTAClick: () => {
            handleVisible(false);
            handleCloseModal();
          },
        },
        {
          btn_text: "네, 좋아요",
          btnStyleName: "gpt_true",
          handleCTAClick: () => {
            handleVisible(false);
            handleCloseModal();
            setMarketingAgreement({ marketingAgreement: true });
          },
        },
      ]}
      tailInfo={
        <S.ContentTail>
          ‘네, 좋아요' 선택 시 광고성 정보 수신 동의 처리 됩니다.
          <br />
          [마이페이지>계정]에서 언제든지 철회 가능해요.
        </S.ContentTail>
      }
      handleCloseModal={handleCloseModal}
    />
  );
};

export default GptModal;
