import { usePostNps } from "../../../queries/nps";

const validateAndAlert = (questions, answers) => {
  return questions
    .filter((question) => question.isNecessary)
    .every((question) => {
      if (answers[question.id] !== undefined && answers[question.id] !== "") {
        return true;
      } else {
        alert(question.alert);
        return false;
      }
    });
};

export const useOnClickSubmitFreeNps = (
  questions,
  answers,
  enrolled,
  isLoading,
  setIsLoading
) => {
  const postNps = usePostNps(enrolled, answers);

  return () => {
    if (!validateAndAlert(questions, answers)) return;
    if (isLoading) return;
    setIsLoading(true);
    postNps.mutate(1);
  };
};
