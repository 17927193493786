import { SatisfactionQuestion } from "./SatisfactionQuestion";
import { QuickQuestion } from "./QuickQuestion";
import React, { useEffect } from "react";
import { SurveyHeader } from "./SurveyHeader";
import * as S from "./nps.style";
import { firstWeekQuestions, lastWeekQuestions } from "./question-model";
import { useUser } from "../LecturePageV3/useInitialize";
import { useCurrentQuestion } from "./useCurrentQuestion";
import { useQuestionState } from "./useQuestionState";
import { useParams } from "react-router-dom";
import { useMutateSurvey } from "./useMutationSurvey";
import { SurveyGnb } from "./SurveyGnb";
import { useNavigateToRoadmap } from "../../hooks/useNavigateToRoadmap";
import {
  useNavigateToNextContent,
  useNextContentCtaText,
} from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { DesktopSubmitCta } from "./DesktopSubmitCta";
import { MobileNextWithNoShadow } from "../LecturePageV3/LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import { useEnrolled } from "../../hooks/useProductName";

export default function NpsPageV3() {
  const user = useUser();
  const { enrolled_id, week } = useParams();

  const userNameParser = (title) => {
    if (title.includes("user_name")) {
      const frontText = title.split(`#{user_name}`)[0];
      const backText = title.split("#{user_name}")[1];
      return `${frontText}${user.name}${backText}`;
    }
    return title;
  };
  const handleTextAnswer = (text, index) => {
    setQuestionsState((prev) => {
      const newState = [...prev];
      newState[index].additionalAnswer = text;
      return newState;
    });
  };
  const questions = useCurrentQuestion(parseInt(week, 10));
  const enrolled = useEnrolled(enrolled_id);
  const courseTitle = enrolled?.data?.courses?.title;
  const [questionsState, setQuestionsState] = useQuestionState(questions);
  const surveyMutation = useMutateSurvey(questionsState, week, enrolled_id);
  const navigateToRoadmap = useNavigateToRoadmap();
  const navigateToNext = useNavigateToNextContent();
  const ctaText = useNextContentCtaText(false, true, "제출하기");
  const checkValidation = () => {
    const isAllSelected = questionsState.every((question) => {
      return question.selectedIndex !== null;
    });
    return isAllSelected;
  };
  const isAllSelected = checkValidation();
  const handleSubmit = async () => {
    await surveyMutation.mutateAsync();
    await navigateToNext();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SurveyGnb
        courseTitle={courseTitle}
        weekTitle={`${week}주차`}
        ctaText={ctaText}
        onClickBack={() => navigateToRoadmap()}
        onClickNext={handleSubmit}
        isClickable={isAllSelected}
      />
      <S.PageWrapper>
        <S.WidthContainer>
          <SurveyHeader
            badgeText={"설문조사"}
            headerTitle={"이번 주차 수업에 대한\n의견을 들려주세요."}
            headerDescription={
              "들려주신 소중한 의견은 강의 개선에 큰 도움이 될거예요!"
            }
          />
          <S.QuestionListContainer>
            {questions.map((question, index) => {
              if (question.type === "satisfaction") {
                return (
                  <SatisfactionQuestion
                    questionNumber={index + 1}
                    questionTitle={userNameParser(question.title)}
                    leftText={question.options[0]}
                    centerText={question.options[1]}
                    rightText={question.options[2]}
                    placeholder={"(선택) 위와 같이 답한 이유는 무엇인가요?"}
                    onSelectPoint={(point) => {
                      setQuestionsState((prev) => {
                        const newState = [...prev];
                        newState[index].selectedIndex = point;
                        return newState;
                      });
                    }}
                    selectedPoint={questionsState[index]?.selectedIndex}
                    onChangeAdditionalAnswer={(text) => {
                      handleTextAnswer(text, index);
                    }}
                  />
                );
              }
              return (
                <QuickQuestion
                  questionNumber={index + 1}
                  questionTitle={userNameParser(question.title)}
                  placeholder={
                    question.additionalQuestion ??
                    "(선택) 위와 같이 답한 이유는 무엇인가요?"
                  }
                  questionOptions={question.options}
                  selectedIdx={questionsState[index]?.selectedIndex}
                  onSelectOption={(selectIndex) => {
                    setQuestionsState((prev) => {
                      const newState = [...prev];
                      newState[index].selectedIndex = selectIndex;
                      return newState;
                    });
                  }}
                  onChangeAdditionalAnswer={(text) => {
                    handleTextAnswer(text, index);
                  }}
                  isSubQuestionActive={
                    question.title ===
                    "수강 시작 상태에 해당되는 항목을 선택해 주세요."
                      ? questionsState[index]?.selectedIndex === 1
                      : questionsState[index]?.selectedIndex !== null
                  }
                />
              );
            })}
            <DesktopSubmitCta
              onClickCta={handleSubmit}
              ctaText={ctaText}
              isClickable={isAllSelected}
            />
          </S.QuestionListContainer>
        </S.WidthContainer>
        <S.MobileSubmitCtaWrapper>
          <MobileNextWithNoShadow
            text={ctaText}
            onClick={handleSubmit}
            isActive={isAllSelected}
          />
        </S.MobileSubmitCtaWrapper>
      </S.PageWrapper>
    </>
  );
}
