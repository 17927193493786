export const GovConstants = {
  GOV_DAILY_AUTH_REQUEST: "GOV_DAILY_AUTH_REQUEST",
  GOV_DAILY_AUTH_SUCCESS: "GOV_DAILY_AUTH_SUCCESS",
  GOV_DAILY_AUTH_FAILURE: "GOV_DAILY_AUTH_FAILURE",

  AUTHORIZE_USER_REQUEST: "AUTHORIZE_USER_REQUEST",
  AUTHORIZE_USER_SUCCESS: "AUTHORIZE_USER_SUCCESS",
  AUTHORIZE_USER_FAILURE: "AUTHORIZE_USER_FAILURE",

  DO_DETAIL_REQUEST: "DO_DETAIL_REQUEST",
  DO_DETAIL_SUCCESS: "DO_DETAIL_SUCCESS",
  DO_DETAIL_FAILURE: "DO_DETAIL_FAILURE",
  DO_DETAIL_INIT: "DO_DETAIL_INIT",
  DO_HOMEWORK_REQUEST: "DO_HOMEWORK_REQUEST",
  DO_HOMEWORK_SUCCESS: "DO_HOMEWORK_SUCCESS",
  DO_HOMEWORK_FAILURE: "DO_HOMEWORK_FAILURE",

  IS_GOV_ENROLLED_BLOCKED_REQUEST: "IS_GOV_ENROLLED_BLOCKED_REQUEST",
  IS_GOV_ENROLLED_BLOCKED_SUCCESS: "IS_GOV_ENROLLED_BLOCKED_SUCCESS",
  IS_GOV_ENROLLED_BLOCKED_FAILURE: "IS_GOV_ENROLLED_BLOCKED_FAILURE",

  GOV_ROUND_START_SOON_REQUEST: "GOV_ROUND_START_SOON_REQUEST",
  GOV_ROUND_START_SOON_SUCCESS: "GOV_ROUND_START_SOON_SUCCESS",
  GOV_ROUND_START_SOON_FAILURE: "GOV_ROUND_START_SOON_FAILURE",

  GOV_ATTEND_RECORD_REQUEST: "GOV_ATTEND_RECORD_REQUEST",
  GOV_ATTEND_RECORD_SUCCESS: "GOV_ATTEND_RECORD_SUCCESS",
  GOV_ATTEND_RECORD_FAILURE: "GOV_ATTEND_RECORD_FAILURE",
};

export const govDailyRecordConstants = {
  REQUEST: "GOV_MILESTONE_REQUEST",
  SUCCESS: "GOV_MILESTONE_SUCCESS",
  FAILURE: "GOV_MILESTONE_FAILURE",
};

export const govTotalStudyTimeConstants = {
  REQUEST: "GOV_TOTAL_STUDY_REQUEST",
  SUCCESS: "GOV_TOTAL_STUDY_SUCCESS",
  FAILURE: "GOV_TOTAL_STUDY_FAILURE",
};

export const govEnrollableRoundsConstants = {
  REQUEST: "GOV_ENROLLABLE_ROUNDS_REQUEST",
  SUCCESS: "GOV_ENROLLABLE_ROUNDS_SUCCESS",
  FAILURE: "GOV_ENROLLABLE_ROUNDS_FAILURE",
};
