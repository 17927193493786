import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoundWeekHomework.module.scss";
import { history } from "../../../../_helpers";
import { GOV_DONE_DAILY_LIMIT } from "../../../_helper";
import { sendLog } from "../../../businesslogics/logging";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function RoundWeekHomework({
  week,
  enrolled,
  expired,
  chapter_title,
  enrolled_details,
  homework,
  round,
  isSmall,
  enrolledDoneCount,
  isLecture,
}) {
  const status = (() => (homework.done ? "제출됨" : "제출전"))();
  const all_lecture_done = enrolled_details.reduce((acc, cur, i) => {
    return acc && cur.done;
  }, true);

  const [isActiveWeek, setIsActiveWeek] = useState(false);
  const [isNotAllowedNext, setIsNotAllowedNext] = useState(false);

  useEffect(() => {
    if (!expired.loaded) {
      return;
    }
    setIsActiveWeek(expired.data.achv.week === week);
  }, [expired.loaded]);

  useEffect(() => {
    // enrolled 는 상위 컴포넌트에서 로딩 완료되었음을 전제로,
    // 정부 과목일 경우 9개 이상 수강할 수 없다.
    const {
      rounds: { is_seen_restricted },
    } = enrolled.data;
    if (!enrolledDoneCount.loaded) {
      return;
    }
    // 진도표에서는 GOV_DONE_DAILY_LIMIT + 1, 강의화면에서는 GOV_DONE_DAILY_LIMIT
    // 강의화면에서는 '지금 보고 있는 것'까지 수강하고 있기 때문
    setIsNotAllowedNext(
      is_seen_restricted && enrolledDoneCount.data >= GOV_DONE_DAILY_LIMIT + 1
    );
  }, [enrolledDoneCount.loaded]);

  function goHomework() {
    const { enrolled_id, round_id } = enrolled.data;
    if (!all_lecture_done) {
      alert("이번 주차의 모든 강의를 수강해주세요!");
      return;
    }
    // 정부과목 수강자일 경우 하루 8개 이상 불가. 대신, 다음 강의를 이미 완료한 경우 지나갈 수 있음.
    if (isNotAllowedNext && !homework.done) {
      alert(`하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1}강까지 들을 수 있어요!`);
      return;
    }
    // 모바일 숙제 불가 -> 모바일 가능으로 변경
    // const { checkPlatform } = window;
    // if (checkPlatform() === "mobile") {
    //   alert("숙제는 PC에서만 하실 수 있습니다.");
    //   return;
    // }
    history.push(`/enrolleds/${enrolled_id}/ehomeworks/${homework["_id"]}`);
  }

  const getChapterTitle = () => {
    const prefixMap = {
      "내일배움카드 신청하기": "[1단계]",
      "HRD 신청하기": "[2단계]",
    };
    const prefix = prefixMap[homework.title] || chapter_title;
    return `${prefix} 숙제`;
  };
  const theme = useOnlineTheme();

  return (
    <div
      onClick={() => {
        sendLog(
          isLecture
            ? "scc_lecturePage_click_list"
            : "scc_roadmapPage_click_list",
          {
            ...homework,
            ...enrolled.data,
            button_name: "강의 목차 중 숙제 리스트 버튼",
          },
          ""
        );
        goHomework();
      }}
      className={cx(
        "round-week-homework",
        theme.data,
        `${isSmall ? "--small-padding" : ""}`
      )}
    >
      <div
        className={cx(
          "round-week-homework__title",
          theme.data,
          `${isSmall ? "--small-width" : ""}`
        )}
      >
        {getChapterTitle()}
      </div>
      <div
        className={cx(
          "round-week-homework__status",
          theme.data,
          `${homework.done ? "--done" : ""}`
        )}
      >
        {status}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled, expired, enrolledDoneCount } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    expired,
    enrolledDoneCount,
  };
}

const connectedRoundWeekHomework = connect(
  mapStateToProps,
  {}
)(RoundWeekHomework);
export { connectedRoundWeekHomework as RoundWeekHomework };
