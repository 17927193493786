import React from "react";
import { useOnlineTheme } from "../../../queries/pirates";
import { MobileNextBtn } from "../../../pages/LecturePageV3/LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import { FloatingContainer } from "../../../pages/LecturePageV3/LectureMobileFloating/LectureMobileFloating.style";

interface EvaluationMobileFloatingProps {
  handleClickCta: () => void;
  isActive: boolean;
  ctaText: string;
}

export const EvaluationMobileFloating = ({
  handleClickCta,
  isActive,
  ctaText,
}: EvaluationMobileFloatingProps) => {
  const theme = useOnlineTheme();

  return (
    <FloatingContainer theme={theme.data}>
      <MobileNextBtn
        text={ctaText}
        isActive={isActive}
        onClick={handleClickCta}
      />
    </FloatingContainer>
  );
};
