import styled from "@emotion/styled";

export const Overlay = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  display: ${({ show }) => (show ? "flex" : "none")};
`;
export const PlayButtonWrapper = styled.div`
  width: 100px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5); // 반투명한 검정색 배경
  border-radius: 50%; // 원형 배경을 만듭니다.
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background: rgba(232, 52, 78, 0.68);
  }
`;

export const PlayButton = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${({ isPlaying }) => (isPlaying ? "0" : "8px")};
  width: ${({ isPlaying }) => (isPlaying ? "30px" : "40px")};
  object-fit: contain;
`;

export const SeekBackButtonWrapper = styled.div`
  width: 50%;
  height: 80px;
  top: 50%;
  left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
`;

export const SeekButton = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeekFrontButtonWrapper = styled.div`
  width: 50%;
  top: 80px;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
`;

export const SettingButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
`;

export const SettingButton = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;
