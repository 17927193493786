import React, { Component, Fragment } from "react";
import styles from "./Dashboard.module.scss";
import classNames from "classnames/bind";
import { config } from "../../../config";
import { connect } from "react-redux";
import { Button, Skeleton, Tooltip } from "antd";
import { dashboardActions, lectureActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { getApiDashboardInfoUrl, getGETAuthOptions } from "../../../_urls";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CustomModal_Ranking } from "../../CustomModal_Ranking";

const cx = classNames.bind(styles);
const weekdays = ["일", "월", "화", "수", "목", "금", "토", "일"];

function MyAchievement(props) {
  const _drawChart = () => {
    const { achv, rcmd, rankFunc, lecture, course } = props;
    return (
      <div className={cx("myAchiveContainer")}>
        <div className={cx("myAchieveTitle")}>
          <span>내 진도율</span>
        </div>
        <div className={cx("myAchieveCircle")}>
          <CircularProgressbarWithChildren
            value={achv ? Math.round(achv * 100) / 100 : 0}
            // text={`${Math.round(achv * 100) / 100} %`}
            styles={buildStyles({
              pathColor: `rgba(19, 194, 194)`,
              trailColor: "#e9ecef",
            })}
            strokeWidth={5}
          >
            <p>
              <span className={cx("percent")}>
                {achv ? Math.round(achv * 10) / 10 : 0}
              </span>
              <span>%</span>
            </p>
          </CircularProgressbarWithChildren>
        </div>
        <div className={cx("myAchiveRank")}>
          {course &&
            (course.is_seasonal || course.is_entry ? (
              <Button className={cx("empty_rank")}>&nbsp;</Button>
            ) : (
              <Button
                onClick={() => {
                  rankFunc(true);
                }}
              >
                랭킹 보러가기
              </Button>
            ))}
        </div>
      </div>
    );
  };
  return <div>{_drawChart()}</div>;
}

function MyTodoLecture(props) {
  const goLecturePage = () => {
    const round_id = props.round_id;
    const lecture_id = props.lecture._id;
    const isHomework = props.lecture.isHomework;
    const { is_next_allowed, is_b2b, next_start_date } = props.lecture;
    if (is_b2b && !is_next_allowed) {
      alert(`${next_start_date}일부터 수강 가능합니다.`);
      return;
    }
    history.push(
      `/rounds/${round_id}/checkins/${props.lecture.week}/${props.lecture.week_order}`
    );

    // if (isHomework) {
    //     history.push(`/courses/${course_id}/homeworks/${lecture_id}`);
    // } else {
    //     history.push(`/courses/${course_id}/lectures/${lecture_id}`);
    // }
  };

  const _drawTodo = () => {
    if (props.lecture === undefined) {
      return <div />;
    }
    const { course_id, _id, title, week, week_order, end_date } = props.lecture;
    return (
      <div>
        <div className={cx("todoLectureWrapper")}>
          <span className={cx("weekInfo")}>
            {week}
            {props.is_seasonal || course_id === "6001384a006d2727411520ea"
              ? "일"
              : "주"}
            차
            <span className={cx("lectureTitleInfo")}>
              {props.lecture.isHomework === false && week_order} {title}
            </span>
          </span>
          <Button
            onClick={goLecturePage}
            className={cx("lectureButton")}
            type="primary"
            danger
            size="large"
          >
            강의실 입장
          </Button>
        </div>
      </div>
    );
  };

  return <div>{_drawTodo()}</div>;
}

const _numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class MyEtc extends Component {
  state = {
    editTextShow: false,
  };

  constructor(props) {
    super(props);
  }

  _drawEtcs = (data, title) => {
    return (
      <div className={cx("etcContainer")}>
        <div className={cx("etcImgContainer")}>
          {title === "남은 기간" && (
            <img
              src={require("../../../assets/images/roadmap/ic_calendar.png")}
              alt=""
            />
          )}
          {title === "내 포인트" && (
            <img
              src={require("../../../assets/images/roadmap/ic_coin.png")}
              alt=""
            />
          )}
          {title === "나와의 약속" && (
            <img
              src={require("../../../assets/images/roadmap/ic_studyplan.png")}
              alt=""
            />
          )}
        </div>
        <div className={cx("etcTextContainer")}>
          <span className={cx("etcTextContent")}>{data}</span>
          <span className={cx("etcTextTitle")}>{title}</span>
        </div>
      </div>
    );
  };

  _handleMouseOver = () => {
    this.setState({
      editTextShow: true,
    });
  };

  _handleMouseLeave = () => {
    this.setState({
      editTextShow: false,
    });
  };

  render() {
    const { editTextShow } = this.state;
    let weeks_remaining = Math.floor(this.props.days / 7);
    let day_remaining = this.props.days % 7;
    let data_days = `${weeks_remaining} 주 + ${day_remaining} 일`;
    let title_days = "남은 기간";

    let data_points = `${_numberWithCommas(parseInt(this.props.points))}포인트`;
    let title_points = "내 포인트";

    let { studyplans } = this.props;
    let data_studyplan = [];
    if (studyplans) {
      for (const plan of studyplans) {
        let day = weekdays[parseInt(plan.day)];
        data_studyplan.push(day + "요일");
      }
      data_studyplan = data_studyplan.join(", ");
    }
    let title_studyplan = "나와의 약속";
    if (isNaN(parseInt(this.props.points))) {
      return <Skeleton />;
    }
    return (
      <div className={cx("etcWrapper")}>
        <div className={cx("etcBlock")}>
          <div>{this._drawEtcs(data_days, title_days)}</div>
        </div>

        <div className={cx("etcBlock")}>
          <div>{this._drawEtcs(data_points, title_points)}</div>
        </div>

        <div className={cx("etcBlock")}>
          <div>
            {this._drawEtcs(data_studyplan, title_studyplan)}
            <div
              className={cx("studyplan_edit")}
              style={{
                "--bg-image": `url(${"/assets/images/roadmap/ic_edit.png"}`,
              }}
              onMouseOver={this._handleMouseOver}
              onMouseLeave={this._handleMouseLeave}
              onClick={this.props.showPlan}
            ></div>
            {editTextShow && <div className={cx("edit_text")}>변경하기</div>}
          </div>
        </div>
      </div>
    );
  }
}

const cheerups = [
  "오늘도 달려볼까요? 🔥",
  "코딩할 준비 되셨죠? 오늘도 완주까지 빠샤! 👊",
  "일단 여기 오신 것만으로도 잘하셨어요! 🥰",
  "딱 코딩하기 좋은 날씨에요! 🥳",
  "포기는 배추 셀 때나 쓰는 것! 포기란 없어요. 💪",
  "오늘도 유익한 수업이 기다리고 있어요! 😎",
  "오늘은 꿀잼지수 5개 각! 어서 들으러 가요 😆",
  "천리길도 Hello World 부터랍니다. 😇",
  "완주가 눈앞이에요. 나 Java봐라~ 🏃‍♀️",
  "오늘 하루는 어땠나요?",
  "이제 슬슬 재미가 붙나요?",
  "어디서 타는 냄새 안나요? 제 파이팅이 불타고 있어요!",
  "오늘이 딱 꿀잼 주차!",
  "오늘도 고고싱!",
];

class Dashboard extends Component {
  state = {
    rankVisible: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { course_id } = this.props.data;
  }

  _getRandomCheerup = () => {
    let idx = Math.floor(Math.random() * cheerups.length);
    return cheerups[idx];
  };

  handleRankVisible = (isVisible) => {
    this.setState({
      rankVisible: isVisible,
    });
  };

  render() {
    const {
      is_seasonal,
      achievement,
      course,
      lecture,
      points,
      recommended,
      remaining_days,
      studyplans,
      is_b,
      round_id,
      is_forever,
    } = this.props.dashboard.data;
    const { user } = this.props;
    let { rankVisible } = this.state;
    const { name_confirmed } = user;
    if (!this.props.dashboard.loaded) {
      return <Skeleton />;
    }
    return (
      <div className={cx("dashboardWrapper")}>
        <h2>
          {user.name}님, {this._getRandomCheerup()}
        </h2>
        {!is_forever && (
          <div className={cx("dashboardContentWrapper")}>
            <div className={cx("myAchieveWrapper")}>
              <MyAchievement
                achv={achievement}
                rcmd={recommended}
                lecture={lecture}
                course={course}
                rankFunc={this.handleRankVisible}
              />
            </div>
            <div className={cx("myInfoWrapper")}>
              <MyTodoLecture
                lecture={lecture}
                round_id={round_id}
                is_seasonal={is_seasonal}
              />
              <MyEtc
                days={remaining_days}
                points={points}
                studyplans={studyplans}
                showPlan={this.props.showPlan}
              />
            </div>
          </div>
        )}
        {rankVisible && (
          <CustomModal_Ranking
            rid={round_id}
            visible={rankVisible}
            handleModalVisible={this.handleRankVisible}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, dashboard } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    dashboard,
  };
};
const connectedDashboard = connect(mapStateToProps, {
  getDashboard: dashboardActions.getDashboard,
})(Dashboard);
export { connectedDashboard as Dashboard };
