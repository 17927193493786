import { connect } from "react-redux";
import * as S from "./RoadmapDuration.style";
import { Flame } from "../../../components/atoms/Roadmap/Roadmap.svg";

const RoadmapDuration = ({ start_date, end_date }) => {
  const getISODateAsKorean = (date) => {
    const day = ["일", "월", "화", "수", "목", "금", "토"];
    const dateObj = new Date(date);
    dateObj.setSeconds(dateObj.getSeconds() - 1);

    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const dayOfWeek = day[dateObj.getDay()];
    const dayOfMonth = dateObj.getDate().toString().padStart(2, "0");
    return `${month}.${dayOfMonth} (${dayOfWeek})`;
  };

  const getDuration = (end_date) => {
    const start = new Date();
    const end = new Date(end_date);
    end.setSeconds(end.getSeconds() - 1);
    const startUTC = Date.UTC(
      start.getFullYear(),
      start.getMonth(),
      start.getDate()
    );
    const endUTC = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
    const diffDays = Math.abs((endUTC - startUTC) / (1000 * 60 * 60 * 24));

    return diffDays !== 0 ? diffDays : "DAY!";
  };

  const dday = getDuration(end_date);
  const isForever = new Date(end_date) > new Date(2099, 1, 1);

  return (
    <S.Container dday={dday}>
      <S.ContentContainer>
        {isForever ? (
          <S.ContentText>
            <Flame /> 수강 기간
            <S.RedText>평생</S.RedText>
          </S.ContentText>
        ) : (
          <>
            <S.ContentText>
              <Flame /> 수강 종료{" "}
              <S.RedText>D-{dday !== 0 ? dday : "DAY!"}</S.RedText>
            </S.ContentText>
            <S.DateText>{getISODateAsKorean(end_date)}</S.DateText>
          </>
        )}
      </S.ContentContainer>
    </S.Container>
  );
};

function mapStateToProps(state) {
  const { authentication, enrolled, round, enrolledRanks } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    round,
    enrolledRanks,
  };
}

const connectedRoadmapDuration = connect(mapStateToProps, {})(RoadmapDuration);
export { connectedRoadmapDuration as RoadmapDuration };
