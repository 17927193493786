import React from "react";

export const courseCard = {
  ChatGPT300: {
    keyword: "chatgpt_300",
    title: "ChatGPT 300% 활용하기",
    description:
      "업무 자동화, 주식 데이터 분석 등 다양한 예제와 실습으로 ChatGPT를 익히고, 실무에 바로 활용하는 법을 배웁니다.",
    weeks: 6,
    url: "https://spartacodingclub.kr/online/gpt_300_online",
  },
  web: {
    keyword: "web",
    title: "웹개발 종합반",
    description:
      "코딩이 처음이라면, 기본이 되는 웹개발을 시작으로 넓고 얕게 코딩을 만나보세요.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/web`,
  },
  webBasic: {
    keyword: "web_basic",
    title: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
    description:
      "코딩의 ‘ㅋ’자도 모르는 왕초보를 위한 입문 강의입니다. 웹 사이트를 만들어보며 코딩의 기본기를 익힙니다.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/web_basic`,
  },
  spring: {
    keyword: "spring",
    title: "웹개발의 봄, 스프링",
    description:
      "백엔드의 봄, 스프링을 배웁니다. 나만의 웹서비스를 만들고 배포해봅니다.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/spring`,
  },
  algo: {
    keyword: "algorithm",
    title: "한번 들으면 평생 써먹는 알고리즘, 취업부터 실무까지!",
    description:
      "알고리즘이 막막한 왕초보도 시작할 수 있습니다. 문제 풀이 실전 역량부터 기술 면접 대비까지 한번에 준비하세요.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/algorithm`,
  },
  python: {
    keyword: "python",
    title: "파이썬 문법 뽀개기",
    description: "가장 많이 배우는 파이썬 문법 A to Z를 다루는 수업입니다.",
    weeks: 1,
    url: `${process.env.REACT_APP_SCC_URL}/online/lang/python`,
  },
  app: {
    keyword: "app",
    title: "앱개발 종합반",
    description:
      "내가 만든 앱을 세상에 선보일 수 있습니다. 무엇이든 만들 수 있는 사람이 되세요.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/app`,
  },
  game: {
    keyword: "game",
    title: "게임개발 종합반",
    description:
      "모바일 캐주얼 게임을 내 손으로 만들어보고, 수익화도 함께 해봅니다.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/game`,
  },
  pkgNocode: {
    keyword: "pkgNocode",
    title: "[패키지] 개발 걱정 NO! 노코드 올인원",
    description:
      "노코드 툴로 간단한 웹사이트부터 고퀄리티 서비스까지 자유롭게 구현해요.",
    weeks: 5,
    url: "https://sparta-package-nocode.oopy.io/",
  },
  sql: {
    keyword: "sql",
    title: "엑셀보다 쉽고 빠른 SQL",
    description: "SQL 쿼리 한 줄로 필요한 데이터를 편하게 확인해보세요.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/sql`,
  },
  data_v2: {
    keyword: "data_v2",
    title: "직장인을 위한 실전 데이터분석",
    description:
      "데이터 기반 사고와 코딩을 통해 분석 결과를 세상에 내어놓는 하드스킬까지 한번에 갖출 수 있습니다.",
    weeks: 5,
    url: `${process.env.REACT_APP_SCC_URL}/online/data_v2`,
  },
  nbc_game: {
    keyword: "nbc_game",
    title: "게임 개발 부트캠프",
    description:
      "선착순 100명 모집! 내일배움캠프에서 게임 개발자로 취업하세요.",
    months: 4,
    url: `https://nbcamp.spartacodingclub.kr/game`,
  },
  nbc_ai: {
    keyword: "nbc_ai",
    title: "AI 개발 부트캠프",
    description:
      "선착순 60명 모집! 내일배움캠프에서 AI 웹 개발자로 취업하세요.",
    months: 4,
    url: `https://nbcamp.spartacodingclub.kr/ai`,
  },

  nbc_web_frontend: {
    keyword: "nbc_web_frontend",
    title: "프론트엔드 웹 개발 부트캠프",
    description:
      "결과물이 즉시 웹 사이트에 반영되는 개발자로 바로 취업하고 싶다면? ",
    months: 5,
    url: `https://nbcamp.spartacodingclub.kr/web`,
  },
  nbc_web_backend: {
    keyword: "nbc_web_backend",
    title: "백엔드 웹 개발 부트캠프",
    description:
      "빠른 처리 속도와 안정적인 운영을 가능한 개발자로 바로 취업하고 싶다면?",
    months: 5,
    url: `https://nbcamp.spartacodingclub.kr/web`,
  },
};

export const productThumbnails = {
  hanghae: {
    keyword: "hanghae",
    url: "https://hanghae99.spartacodingclub.kr",
    name: "항해99",
    description:
      new Date() >= new Date(2023, 6, 1)
        ? "실무 기반 커리큘럼으로, 10년 후에도 인정받는 탁월한개발자를 양성합니다."
        : "올해 상반기 중으로, 10년 후에도 살아남는 개발자로 거듭나세요. 온라인 강의 수강생 한정 등록금 50만원 할인 중 (~6/30)",
  },
  chang: {
    keyword: "chang",
    url: "https://chang.spartacodingclub.kr",
    name: "{창}",
    description:
      "단 3개월, 예비 창업가로 거듭날 수 있습니다. 회사를 다니면서 창업팀을 만들고, 시드 투차 유치의 기회도 누리세요.",
  },
  swcamp: {
    // 기존 swcamp가 hanghae99_pm 코스로 통합됨
    // TODO: swcamp 관련 코드 정리
    keyword: "hanghae_pm",
    url: "https://swcamp.spartacodingclub.kr",
    name: "hanghae_pm",
    description:
      "PM이 일하는 방식 그대로를 담은 커리큘럼으로 포트폴리오를 완성하고 IT업계가 원하는 PM으로 거듭나세요.",
  },
  nb: {
    keyword: "nb",
    url: "https://spartacodingclub.kr/nb",
    name: "내일배움단",
    description:
      "스파르타코딩클럽의 인기 강의를 국비지원으로 수강하세요. 듣고 싶은 강의를 최대 2개까지 수강할 수 있어요.",
  },
  nbc: {
    keyword: "nbc_banner",
    url: "https://nbcamp.spartacodingclub.kr/",
    name: "내일배움캠프",
    description:
      "내일배움캠프는 선착순 100명만 모집하며\n" +
      "교육비 전액 지원이 가능한 캠프입니다.",
  },
  b2b: {
    keyword: "b2b",
    url: "https://spartacodingclub.kr/b2b",
    name: "b2b",
    description:
      "IT 교육부터 데이터베이스 컨설팅까지 사내 디지털 전환에 필요한 서비스를 제공합니다.",
  },
  ddingdong: {
    keyword: "ddingdong",
    url: "https://ddingdong.spartacodingclub.kr",
    name: "띵동코딩",
    description:
      "8주 학습으로 코딩 포트폴리오까지 완성해요. 우리 아이의 코딩 결과물을 담은 웹사이트 포트폴리오를 제작합니다.",
  },
};

export const recommendTitle = {
  anything: "구체적인 계획이 없다면 \n이런 강의는 어떠세요?",
  online: "코딩을 취미로 배우고 싶다면 \n이런 강의는 어떠세요?",
  // hanghae: "개발자로 취업하고 싶다면 \n이런 강의는 어떠세요?", // 내배캠 부스트를 위해 무료 강의에서 항해로 넘기던 모수를 내배캠으로 넘길 수 있도록 작업  (종료 후 항해로 원복 / 종료 시점은 미정)
  hanghae: "개발자로 취업하고 싶다면 \n내일배움캠프는 어떠세요?",
  swcamp: "코딩을 커리어에 활용하고 싶다면 \n이런 강의는 어떠세요?",
  chang: "코딩을 취미로 배우고 싶다면 \n이런 강의는 어떠세요?",
  nb: "내일배움카드 신청 완료했다면\n국비지원 가능한 강의 둘러보세요.",
  b2b: "회사의 DT 성공을 이끌고\n디지털 기반 조직으로 변화하세요.",
  ddingdong: "코딩으로 우리 아이에게\n남다른 경쟁력을 만들어주세요.",
};

export const recommendCourses = {
  anything: [courseCard.ChatGPT300.title, courseCard.web.title],
  online: [courseCard.web.title, courseCard.app.title, courseCard.game.title],
  hanghae: [
    courseCard.spring.title,
    courseCard.algo.title,
    productThumbnails.hanghae.name,
  ],
  swcamp: [
    courseCard.sql.title,
    courseCard.data_v2.title,
    productThumbnails.swcamp.name,
  ],
  chang: [courseCard.pkgNocode.title, productThumbnails.chang.name],
  nb: [productThumbnails.nb.name],
  b2b: [productThumbnails.b2b.name],
  ddingdong: [productThumbnails.ddingdong.name],
};
