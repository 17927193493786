import styled from "@emotion/styled";

import {
  ArrowDropDownLine,
  ArrowDropUpLine,
  neutralDay,
  wBody1,
} from "@teamsparta/design-system";
import {
  useClickAnchor,
  useIsTargetPage,
} from "../../../businesslogics/_layout/gnb/v2";

export const GnbAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor(anchor);
  const isMatch = useIsTargetPage(anchor.href);

  return (
    <GnbAnchor
      isLighter={!isCategory}
      isActive={isMatch}
      onClick={onClickAnchor}
    >
      {anchor.name}
      {anchor.tag && <Tag>{anchor.tag}</Tag>}
    </GnbAnchor>
  );
};

export const GnbTopAnchorDesktop = ({
  anchor,
  isCategory = false,
  isDropdownOpen,
  onClickDropdown,
}) => {
  const onClickAnchor = useClickAnchor(anchor);
  const isMatch = useIsTargetPage(anchor.href);
  return (
    <GnbTopAnchor
      isLighter={!isCategory}
      isActive={isMatch}
      onClick={anchor.onClick ? (e) => onClickDropdown(e) : onClickAnchor}
    >
      {anchor.name}
      {anchor.onClick &&
        (isDropdownOpen ? (
          <ArrowDropUpLine size={16} color={"#B4BFC6"} />
        ) : (
          <ArrowDropDownLine size={16} color={"#B4BFC6"} />
        ))}
    </GnbTopAnchor>
  );
};

const GnbAnchor = styled.div`
  ${wBody1};
  padding: 9px 10px 8px;
  color: ${neutralDay.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  :hover {
    display: inline-flex;
    padding: 9px 10px 8px;
    align-items: center;
    border-radius: 6px;
    background: ${neutralDay.gray5};
  }
`;

const GnbTopAnchor = styled.div`
  color: ${neutralDay.gray70};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Tag = styled.div`
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${neutralDay.gray10};
  color: ${neutralDay.gray70};
  cursor: pointer;
  color: ${neutralDay.gray10}
  font-feature-settings: "clig" off, "liga" off;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 15px */
`;
