import React, { ComponentProps } from "react";
import * as S from "./popularArticleCard.style";

interface Props extends ComponentProps<"div"> {
  articleImgSrc: string;
  articleTitle: string;
  articleDescription: string;
  link: string;
  onArticleClick: () => void;
}

const PopularArticleCard = ({
  articleImgSrc,
  articleTitle,
  articleDescription,
  onArticleClick,
  link,
}: Props) => {
  return (
    <S.Container link={link} onClick={onArticleClick}>
      <S.ArticleImageWrapper>
        <S.ArticleImage src={articleImgSrc} alt="article_img" />
      </S.ArticleImageWrapper>
      <div>
        <S.ArticleTitle>{articleTitle}</S.ArticleTitle>
        <S.ArticleDescription>{articleDescription}</S.ArticleDescription>
      </div>
    </S.Container>
  );
};

export default PopularArticleCard;
