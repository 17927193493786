import React, { useEffect } from "react";
import { connect } from "react-redux";
import { history, params } from "../../../_helpers";
import classNames from "classnames/bind";
import styles from "./CourseRcmdFree.module.scss";
import { NavContainer } from "../../../_containers";
import { rcmdActions } from "../../_state/actions";
import { sendLog } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

const CourseRcmdFree = ({ user, postRcmdSurvey }) => {
  const { enrolled_id } = params();
  const { checkPlatform } = window;
  const platform = checkPlatform();

  const clickBtn = () => {
    sendLog("spc_courseRcmdCounsel_click_btn", {}, null);
    postRcmdSurvey({ enrolled_id }).then((resp) => {
      if (resp.ok) {
        history.push(`/course/rcmd/freeapply`);
      }
    });
  };
  useEffect(() => {
    sendLog("spc_courseRcmdCounsel_view_page", {}, null);
  }, []);

  return (
    <>
      <NavContainer />
      <div className={cx("wrapper")}>
        <div className={cx("header")}>
          <div className={cx("chip")}>
            <span className={cx("chip__name")}>3월 무료상담 이벤트</span>
          </div>
          <span className={cx("title")}>
            완강하신 {user.name}님을 위한 <br />
            1:1 맞춤 코스를 알려드릴게요.
          </span>
          <p>지금 페이지에서만 만날 수 있는 특별 할인 혜택까지!</p>
        </div>
        {platform == "pc" ? (
          <img
            className={cx("first_img")}
            src={"/assets/images/finish/course_rcmd_free.png"}
            alt={"course_rcmd_free"}
          />
        ) : (
          <img
            className={cx("first_img_mobile")}
            src={"/assets/images/finish/course_rcmd_free_mobile.png"}
            alt={"course_rcmd_free"}
          />
        )}
        <button onClick={() => clickBtn()}>1:1 맞춤 상담 신청</button>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdFree = connect(mapStateToProps, {
  postRcmdSurvey: rcmdActions.postRcmdSurvey,
})(CourseRcmdFree);
export { connectedCourseRcmdFree as CourseRcmdFree };
