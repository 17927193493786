import { todaydiscountConstants } from "../_constants";
import { lectureNoteService, todaydiscountService } from "../_services";

function getTodaydiscount(course_id) {
  function request() {
    return { type: todaydiscountConstants.TODAY_DISCOUNT_REQUEST };
  }

  function success(response) {
    return { type: todaydiscountConstants.TODAY_DISCOUNT_SUCCESS, response };
  }

  function failure(error) {
    return { type: todaydiscountConstants.TODAY_DISCOUNT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    todaydiscountService.getTodaydiscount(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const todaydiscountActions = {
  getTodaydiscount,
};
