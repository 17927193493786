import React from "react";
import classNames from "classnames/bind";
import styles from "./ClassCard.module.scss";
import { connect } from "react-redux";
import {
  getISODateAsKoreanWithYear,
} from "../../../../pages/RoadmapPage/RoundWeek/utils";

const cx = classNames.bind(styles);

function ClassCardMembership({ membership_start_from, membership_end_at, current_type }) {
  return (
    <div className={cx("classRow")}>
      <div className={cx("card_container")}>
        <div className={cx("courseIcon")}>
          <div className={cx("coursePadding")} />
        </div>
        <div className={cx("card")}>
          <div className={cx("course_title")}>스파르타 자유수강권</div>
          <div className={cx("divider")} />
          <div className={cx("course_duration")}>
            <div className={cx("course_weeks")}>사용기간</div>
            <div className={cx("course_days", current_type)}>
              {getISODateAsKoreanWithYear(membership_start_from)} ~{" "}
              {getISODateAsKoreanWithYear(membership_end_at, true)}
            </div>
          </div>
          <div className={cx("bottom_cta")}>
            <button
              onClick={() => {
                window.open("https://spartacodingclub.kr/catalog");
              }}
              className={cx("catalog_button")}
            >
              수업 탐색하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { environment } = state;
  return {};
}

const connectedClassCard = connect(mapStateToProps, {})(ClassCardMembership);
export { connectedClassCard as ClassCardMembership };
