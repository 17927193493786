import styled from "@emotion/styled";
import {
  mBody1,
  mCaption2,
  neutralDay,
  wBody1,
  wCaption1,
  wCaption2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: #fff;
`;

export const CheckBeforeTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CheckBeforeTitle = styled.div`
  ${mBody1};
  color: ${neutralDay.gray80};
  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    color: ${neutralDay.gray80};
  }
`;

export const CheckBeforeContent = styled.div`
  div {
    ${mCaption2};
    color: ${neutralDay.gray70};
    margin-left: 5px;

    ${DisplayResolution.TabletAndDesktop} {
      ${wCaption1};
      color: ${neutralDay.gray70};
    }

    /* &::before {
      content: "•";
      color: ${neutralDay.gray90};
      position: absolute;
      left: 30px;

      ${DisplayResolution.TabletAndDesktop} {
        left: 22px;
      }
    } */

    b {
      ${mCaption2};
      color: ${neutralDay.gray90};

      ${DisplayResolution.TabletAndDesktop} {
        ${wCaption1};
        color: ${neutralDay.gray90};
      }
    }
  }

  ${DisplayResolution.TabletAndDesktop} {
    ${wCaption2};
    color: ${neutralDay.gray90};

    b {
      color: ${neutralDay.gray70};
    }
  }
`;

export const MobileOnly = styled.div`
  display: block;
  padding: 32px 0 20px 0;
  border-top: 1px solid ${neutralDay.gray20};
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    border-top: 1px solid ${neutralDay.gray20};
    padding: 32px 20px 100px 20px;
    display: block;
  }
`;
