import { ABTEST_CONSTANT } from "../constants";

const initState = {
  loaded: false,
};

export function abtestEnrolled(state = initState, action) {
  switch (action.type) {
    case ABTEST_CONSTANT.ENROLLED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ABTEST_CONSTANT.ENROLLED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ABTEST_CONSTANT.ENROLLED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function abtestPayment(state = initState, action) {
  switch (action.type) {
    case ABTEST_CONSTANT.PAYMENT_PAGE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ABTEST_CONSTANT.PAYMENT_PAGE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ABTEST_CONSTANT.PAYMENT_PAGE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function abtestKkoSignup(state = initState, action) {
  switch (action.type) {
    case ABTEST_CONSTANT.KKO_SIGNUP_PAGE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ABTEST_CONSTANT.KKO_SIGNUP_PAGE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ABTEST_CONSTANT.KKO_SIGNUP_PAGE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function abtestCourseRcmd(state = initState, action) {
  switch (action.type) {
    case ABTEST_CONSTANT.COURSERCMD_PAGE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case ABTEST_CONSTANT.COURSERCMD_PAGE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case ABTEST_CONSTANT.COURSERCMD_PAGE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
