import { CLASSROOM_CARDS, EXPIRED_CLASSROOM_CARDS } from "../constants";
import { classroomService } from "../services";

function getClassroomCards() {
  function request() {
    return { type: CLASSROOM_CARDS.CLASSROOM_CARDS_REQUEST };
  }

  function success(response) {
    return { type: CLASSROOM_CARDS.CLASSROOM_CARDS_SUCCESS, response };
  }

  function failure(error) {
    return { type: CLASSROOM_CARDS.CLASSROOM_CARDS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    classroomService.getClassroomCards().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}
function getExpiredClassroomCards() {
  function request() {
    return { type: EXPIRED_CLASSROOM_CARDS.EXPIRED_CLASSROOM_CARDS_REQUEST };
  }

  function success(response) {
    return {
      type: EXPIRED_CLASSROOM_CARDS.EXPIRED_CLASSROOM_CARDS_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return {
      type: EXPIRED_CLASSROOM_CARDS.EXPIRED_CLASSROOM_CARDS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    classroomService.getExpiredClassroomCards().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const classroomActions = {
  getClassroomCards,
  getExpiredClassroomCards,
};
