export const userFeedbackDataModels = [
  {
    id: 1,
    text: "설명이\n만족스러워요",
    value: "good",
    src: "/v2/assets/icons/feedback/user-feedback-good.png",
    resultTitle: "설명이 만족스러웠군요!",
    resultText: "르탄이도 {userName}님이\n더 만족할 수 있도록 노력할게요.",
  },
  {
    id: 2,
    text: "설명이\n부족해요",
    value: "bad",
    src: "/v2/assets/icons/feedback/user-feedback-bad.png",
    resultTitle: "설명이 부족했군요",
    resultText: "설명을 보완하도록 할게요.\n소중한 의견 감사해요.",
  },
  {
    id: 3,
    text: "어려워요",
    value: "hard",
    src: "/v2/assets/icons/feedback/user-feedback-hard.png",
    resultTitle: "강의가 어려웠군요.",
    resultText:
      "좀 더 쉽게 설명할 수 있도록 노력할게요.\n소중한 의견 감사해요.",
  },
  {
    id: 4,
    text: "재미없어요",
    value: "boring",
    src: "/v2/assets/icons/feedback/user-feedback-boring.png",
    resultTitle: "강의가 재미없었군요.",
    resultText: "나는 재미있는 사람인 줄 알았는데...\n소중한 의견 감사해요.",
  },
];
