import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "../Hrd/HrdLinkContainer.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { govAction } from "../../../_state/actions/gov.actions";

const cx = classNames.bind(styles);

export const HrdLinkContainer = ({ week }) => {
  const govEnrollableRounds = useSelector((state) => state.govEnrollableRounds);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!govEnrollableRounds.loaded) {
      dispatch(govAction.getGovRoundCanEnroll());
    }
  }, []);
  const urls = govEnrollableRounds.data?.context
    .reverse()
    .reduce((a, round) => {
      return {
        ...a,
        [round.course_id]: round.round.replace("회차", ""),
      };
    }, {});
  if (week === 3) {
    return (
      <div className={cx("link-wrapper")}>
        <div className={cx("hrd-container", "hrd-container2")}>
          <div className={cx("container")}>
            <div className={cx("button-row")}>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(`https://hrd.go.kr`, "_blank");
                }}
              >
                고용24 바로가기
              </div>
            </div>
            <div className={cx("button-row")}>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(`https://sparta-nb-card.oopy.io/`, "_blank");
                }}
              >
                혹시 실물카드가 아직 안왔다면?
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (week === 2) {
    return (
      <div className={cx("link-wrapper")}>
        <div className={cx("hrd-container")}>
          <div className={cx("title")}>수강신청 바로가기</div>
          <div className={cx("container")}>
            <div className={cx("button-row")}>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(
                    `https://www.hrd.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=AVA20213000760646&tracseTme=${urls["60a5ee42bf272bef32a58fb9"]}&crseTracseSe=C0105&trainstCstmrId=500039286716`,
                    "_blank"
                  );
                }}
              >
                웹개발 종합반
              </div>
            </div>
            <div className={cx("button-row")}>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(
                    `https://www.hrd.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=AIG20210000328751&tracseTme=${urls["616d434a460cf1cf6f957a3d"]}&crseTracseSe=C0105&trainstCstmrId=500039286716`,
                    "_blank"
                  );
                }}
              >
                엑셀보다 쉬운 SQL
              </div>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(
                    `https://www.hrd.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=AIG20220000409502&tracseTme=${urls["62f09f2af3ce1804901b3f5c"]}&crseTracseSe=C0105&trainstCstmrId=500039286716`,
                    "_blank"
                  );
                }}
              >
                주식 데이터를 활용한 파이썬
              </div>
            </div>
            <div className={cx("button-row")}>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(
                    `https://www.hrd.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=AIG20210000328755&tracseTme=${urls["616d434a460cf1cf6f957a3d"]}&crseTracseSe=C0105&trainstCstmrId=500039286716`,
                    "_blank"
                  );
                }}
              >
                앱개발 종합반
              </div>
              <div
                className={cx("button")}
                onClick={() => {
                  window.open(
                    `https://www.hrd.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=AIG20220000409493&tracseTme=${urls["62f09eebfeb2d564bacb0262"]}&crseTracseSe=C0105&trainstCstmrId=500039286716`,
                    "_blank"
                  );
                }}
              >
                게임개발 종합반
              </div>
            </div>
          </div>
        </div>
        <div className={cx("hrd-wrapper")}>
          <div className={cx("title")}>
            🎁&nbsp;&nbsp;&nbsp;디지털기초역량훈련 과정이 처음이시라면?
          </div>
          <div className={cx("content")}>
            2과목 추천 SET를 영상에서 직접 확인해보세요!
          </div>
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }
  return (
    <div className={cx("link-wrapper")}>
      <div className={cx("hrd-container", "hrd-container2")}>
        <div className={cx("container")}>
          <div className={cx("button-row")}>
            <div
              className={cx("button")}
              onClick={() => {
                window.open(`https://hrd.go.kr`, "_blank");
              }}
            >
              고용24 바로가기
            </div>
          </div>
          <div className={cx("button-row")}>
            <div
              className={cx("button")}
              onClick={() => {
                window.open(
                  `https://play.google.com/store/apps/details?id=kr.go.hrd.app&hl=ko&gl=US`,
                  "_blank"
                );
              }}
            >
              [안드로이드] 구글플레이 다운받기
            </div>
          </div>
          <div className={cx("button-row")}>
            <div
              className={cx("button")}
              onClick={() => {
                window.open(
                  `https://apps.apple.com/kr/app/%EA%B3%A0%EC%9A%A9%EB%85%B8%EB%8F%99%EB%B6%80-hrd-net-%EB%B0%8F-%EC%B6%9C%EA%B2%B0%EA%B4%80%EB%A6%AC/id588283412`,
                  "_blank"
                );
              }}
            >
              [iOS] 앱스토어 다운받기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
