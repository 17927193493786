import styled from "styled-components";
import { device } from "../../../_styles/device";

export const Container = styled.div`
  background: #141617;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 50px 0 45px;
  @media ${device.desktop} {
    padding: 90px 0 80px;
  }
`;

export const Flame = styled.img`
  position: absolute;
  top: 0;
  z-index: -1;
  height: 317px;
  object-fit: contain;
  @media ${device.desktop} {
    height: 253px;
  }
`;

export const Title = styled.div`
  font-family: GmarketSans;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.002em;
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 76px;
  @media ${device.desktop} {
    font-size: 30px;
    margin-bottom: 64px;
  }
`;

export const Logo = styled.img`
  width: 260px;
  height: 75px;
  object-fit: contain;
  margin-bottom: 36px;
`;

export const BigText = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 34px;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 34px;
`;

export const Tag = styled.span`
  padding: 5px 14px;
  height: 28px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  font-family: Pretendard;
  font-weight: 700;
  line-height: 140%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  @media ${device.desktop} {
    font-size: 13px;
  }
`;

export const SmallText = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const Ul = styled.ul`
  font-family: Pretendard;
  font-weight: 500;
  line-height: 150%;
  color: #c7d2d8;
  font-size: 14px;
  @media ${device.desktop} {
    font-size: 16px;
  }
`;

export const Li = styled.li`
  line-height: 180%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const Dot = styled.span`
  width: 3px;
  height: 3px;
  border-radius: 2px;
  background: #c7d2d8;
`;

export const Cta = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 312px;
  height: 56px;
  background: #2958ff;
  border-radius: 8px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 72px;
  @media ${device.desktop} {
    margin-top: 60px;
  }
`;
