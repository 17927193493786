import * as S from "./WeekContents.style";
import { Content } from "./Content";
import { useEffect, useState } from "react";

export const WeekContents = ({ contents }) => {
  const [isContentsOpen, setIsContentOpen] = useState(false);
  const toggleContents = () => {
    isContentsOpen ? setIsContentOpen(false) : setIsContentOpen(true);
  };

  useEffect(() => {
    if (contents.some((item) => item.isNowPlayingIndex === true))
      setIsContentOpen(true);
  }, [contents]);
  return (
    <>
      <ContentToggle isOpen={isContentsOpen} toggleFunction={toggleContents} />
      {isContentsOpen &&
        contents?.map((content, idx) => {
          return <Content key={idx} content={content} />;
        })}
    </>
  );
};

const ContentToggle = ({ isOpen, toggleFunction }) => {
  return (
    <S.ContentToggle>
      <S.ToggleTitle>강의 목록</S.ToggleTitle>
      <S.ToggleBtn data-testid="list-toggle" onClick={toggleFunction}>
        {isOpen ? "목록 닫기" : "목록 열기"}
        <S.ToggleSvg />
      </S.ToggleBtn>
    </S.ContentToggle>
  );
};
