import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions";
import { getChapterTitle } from "../RoundWeek";
import classNames from "classnames/bind";
import styles from "./RoadmapDashboard.module.scss";
import { addDays } from "../../../_helper";
import { useOnlineTheme } from "../../../queries/pirates";
import { PIRATES } from "../../../../_consts";
import { useParams } from "react-router-dom";

const cx = classNames.bind(styles);

function RoadmapDashboard({
  user,
  round,
  enrolled,
  expired,
  setExpired,
  enrolledReimbursed,
  getEnrolledReimbursed,
  isMobile,
}) {
  const textarea = useRef();

  const [course, setCourse] = useState({});
  const [desc, setDesc] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [rtanPosition, setRtanPosition] = useState(0);
  const [isChatLeft, setIsChatLeft] = useState(true);
  const [isFirstWeek, setIsFirstWeek] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [isReasonSubmitted, setIsReasonSubmitted] = useState(false);
  const [reason, setReason] = useState(null);
  const BAR_FULL_LENGTH = 975;
  const MOBILE_BAR_RATIO = 0.25;
  const [isKRISS, setIsKRISS] = useState(false);
  const [isRevision, setIsRevision] = useState(false);
  const [isReimbursed, setIsReimbursed] = useState(false);
  const [isMaker, setIsMaker] = useState(false);
  const theme = useOnlineTheme();
  const isTrial = enrolled.data.courses.is_trial;
  const { enrolled_id, round_id } = useParams();

  useEffect(() => {
    const { enrolled_id, has_maker_choice, courses: course } = enrolled.data;
    setIsMaker(has_maker_choice);
    setCourse(course);
    getEnrolledReimbursed(enrolled_id);
    setIsRevision(
      isRevisionPeriod(enrolled.data.start_date, enrolled.data.courses.days)
    );
  }, [enrolled]);

  useEffect(() => {
    if (!round.loaded) {
      return;
    }
    setIsKRISS(round.data.round.includes("KRISS"));
  }, [round.loaded]);

  useEffect(() => {
    if (!enrolledReimbursed.loaded) {
      return;
    }
    setIsReimbursed(enrolledReimbursed.data.is_reimbursed);
  }, [enrolledReimbursed.loaded]);
  useEffect(() => {
    if (!expired.loaded) {
      return;
    }
    const { is_finished } = expired.data.achv;
    const new_position_multiple =
      expired.data.achv.week_done_rate / 100 +
      expired.data.achv.finished_week_count;
    let new_position = is_finished
      ? BAR_FULL_LENGTH
      : (BAR_FULL_LENGTH / (expired.data.deadlines.length - 1)) *
        new_position_multiple;
    if (isMobile) {
      new_position = new_position * MOBILE_BAR_RATIO;
    }
    setRtanPosition(new_position);
    setIsChatLeft(new_position < BAR_FULL_LENGTH / 2);
    setIsFirstWeek(expired.data.rcmd.rcmded_week === 0);
    setIsStarted(expired.data.achv.is_started);

    const chapter_title = getChapterTitle(
      course,
      round,
      expired.data.rcmd.rcmded_week
    );
    let deadline;
    for (let i = 0; i < expired.data.deadlines.length; i++) {
      if (expired.data.deadlines[i].week === expired.data.rcmd.rcmded_week) {
        let dead_date = addDays(expired.data.deadlines[i].deadline, -1);
        deadline = `${dead_date.getMonth() + 1}월 ${dead_date.getDate()}일`;
        break;
      }
    }

    const { is_reimbursable, extended } = round.data;
    setModalInfo({
      handleVisible: () => {},
      header: "앗! 진도사우르스에게 잡혀버렸어요",
      img_path: "/assets/images/roadmap/dino_kill.png",
      img_style: "__fit",
      content: (
        <div className={cx("modal-content")}>
          <div className={cx("modal-content__text")}>
            {chapter_title} 강의 권장 기간은 {deadline}까지였어요.
            <br />
            지난주에 바쁜 일이 있으셨더라도, 괜찮아요!
            <br />
            이번주에 다시 힘을 내서 달린다면
            <br />
            {!extended && is_reimbursable === undefined ? ( // is_reimbursable 은 undefined 가 true
              <>
                진도도 따라잡고{" "}
                <b>
                  <u>5만원 환급 혜택</u>
                </b>
                도 누릴 수 있어요!
              </>
            ) : (
              "진도를 따라잡을 수 있어요!"
            )}
            <br />
            진도사우르스에게 풀어달라 하고 강의 들으러 가볼까요?
          </div>
          <textarea
            className={cx("modal-content__textarea")}
            ref={textarea}
            placeholder={
              "이번 주에는 회식도 많고 바쁘게 살다 보니 그냥 지나가 버렸네요.. 한 주만 더 연장되면 퇴근 후에, 혹은 주말에 열심히 해서 따라잡을게요!!"
            }
          />
        </div>
      ),
      cta: {
        btn_text: "강의 이어듣기",
        handleCTAClick: () => {
          if (!textarea.current) {
            return;
          }
          const reason = textarea.current.value;
          if (!reason) {
            alert("못 오신 이유를 알려주세요!");
            return;
          }
          const data = {
            reason,
            course_id: enrolled.data.course_id,
            user_id: user._id,
          };
          setReason(data);
          setIsReasonSubmitted(true);
        },
      },
    });
  }, [expired.loaded]);

  useEffect(() => {
    if (isReasonSubmitted) {
      setModalInfo({
        handleVisible: () => {},
        header: "야호! 풀려났어요!",
        img_path: "/assets/images/roadmap/rtan_expired_free.png",
        img_style: "__fit",
        content: (
          <div className={cx("modal-content")}>
            <div className={cx("modal-content__text")}>
              진도사우르스야 고마워!
              <br />
              이번 주에는 꼭 다 들을게!
            </div>
          </div>
        ),
        cta: {
          btn_text: "공부하러 가기",
          handleCTAClick: () => {
            const { enrolled_id } = enrolled.data;
            setExpired(enrolled_id, reason);
          },
        },
      });
    }
  }, [isReasonSubmitted]);

  function onComponentMouse(visible, date, is_last, deadline_week) {
    const { is_reimbursable, extended } = round.data;
    const { days } = enrolled.data.courses;

    setIsVisible(visible);
    let next_desc;
    if (is_last) {
      const goal_date = addDays(enrolled.data.end_date, -1);
      next_desc =
        is_reimbursable && !extended
          ? `${
              goal_date.getMonth() + 1
            }월 ${goal_date.getDate()}까지 완강하면 르탄이가 무사히 탈출할 수 있어요.`
          : `얼른 완강하러 가볼까요?`;
    } else {
      const dead_date = addDays(date, -1);
      if (dead_date < new Date()) {
        next_desc = `진도사우르스가 이미 완료한 강의에요. 얼른 따라잡아보아요!`;
      } else {
        if (deadline_week === 0) {
          next_desc =
            "르탄이가 준비 운동을 완료했대요. 레이스를 얼른 시작해주세요!";
        } else if (deadline_week === parseInt(days / 7)) {
          next_desc =
            "성까지 얼마 남지 않았어요. 르탄이를 꼭 성에 데려다주세요!";
        } else {
          next_desc = `${
            dead_date.getMonth() + 1
          }월 ${dead_date.getDate()}일까지 완강하면 새로운 에피소드가 열려요!`;
        }
      }
    }
    setDesc(next_desc);
  }

  function isRevisionPeriod(startDate, courseDays) {
    return Date.now() >= addDays(startDate, courseDays);
  }

  const hasProgressPromoteSystem =
    round.data.has_progress_promote_system !== false;
  return (
    <>
      {expired.loaded && !round.data.is_forever && hasProgressPromoteSystem && (
        <>
          {theme.data !== PIRATES ? (
            <div
              className={cx("roadmap-dashboard")}
              style={{
                backgroundImage: isMobile
                  ? `url(${"/assets/images/roadmap/mobilebg_roadmap_race.png"})`
                  : `url(${"/assets/images/roadmap/bg_roadmap_race.png"})`,
              }}
            >
              <div className={cx("roadmap-dashboard__chars")}>
                {!isTrial && (
                  <a
                    href={`/enrolleds/${enrolled_id}/rounds/${round_id}/rank`}
                    className={cx("go-roadmap-button")}
                  >
                    동료들은 어디쯤 왔을까?
                    <img
                      className={cx("go-roadmap-button__icon")}
                      src={`/images/roadmap/next_icon_white.svg`}
                      alt={"레이스 페이지"}
                    />
                  </a>
                )}
              </div>

              <div className={cx("roadmap-dashboard__weeks")}>
                <div
                  className={cx("roadmap-dashboard__weeks__plain-bar")}
                  style={{
                    "--full-length": isMobile
                      ? `${BAR_FULL_LENGTH * MOBILE_BAR_RATIO}px`
                      : `${BAR_FULL_LENGTH}px`,
                  }}
                >
                  <div
                    className={cx(
                      "roadmap-dashboard__weeks__plain-bar--active"
                    )}
                    style={{
                      "--length": isMobile
                        ? `${rtanPosition}px`
                        : `${rtanPosition}px`,
                    }}
                  ></div>
                </div>
                <div
                  className={cx(
                    "roadmap-dashboard__weeks__plain-bar--active__rtan"
                  )}
                  style={{
                    "--length": `${rtanPosition - 7}px`,
                  }}
                >
                  <img
                    className={cx(
                      "roadmap-dashboard__weeks__plain-bar--active__rtan__img",
                      `${expired.data.achv.is_finished ? "--finished" : ""}`
                    )}
                    src={`/assets/images/${
                      expired.data.achv.is_expired
                        ? "characters/rtan_dead.png"
                        : expired.data.achv.is_finished
                        ? "codingrace/happy_rtan.gif"
                        : `characters/rtan_ongoing_0.png`
                    }`}
                    alt="img_rtan"
                  />
                </div>
                {isMobile &&
                  expired.data.achv.finished_week_count !== 0 &&
                  !expired.data.achv.is_finished && (
                    <div
                      className={cx(
                        "roadmap-dashboard__weeks__plain-bar--active__pass"
                      )}
                      style={{
                        "--length": `${rtanPosition}px`,
                      }}
                    >
                      <img
                        className={cx(
                          "roadmap-dashboard__weeks__plain-bar--active__pass__week"
                        )}
                        src={`/assets/images/codingrace/mobile_pass_week_${expired.data.achv.finished_week_count}.svg`}
                        alt="img_pass_week"
                      />
                    </div>
                  )}

                {expired.data.deadlines.map((deadline, idx) => {
                  const { week: deadline_week } = deadline;
                  const is_last = deadline_week < 0;
                  return (
                    <div
                      key={idx}
                      className={cx("roadmap-dashboard__weeks__week")}
                      onMouseOver={() => {
                        onComponentMouse(
                          true,
                          deadline.deadline,
                          is_last,
                          deadline_week
                        );
                      }}
                      onMouseLeave={() => {
                        onComponentMouse(
                          false,
                          deadline.deadline,
                          is_last,
                          deadline_week
                        );
                      }}
                    >
                      {expired.data.rcmd.rcmded_week === deadline_week &&
                        isStarted && (
                          <div
                            className={cx(
                              "roadmap-dashboard__weeks__week__dino"
                            )}
                          >
                            <img
                              className={cx(
                                "roadmap-dashboard__weeks__week__dino__img"
                              )}
                              src={"/assets/images/characters/monster.png"}
                              alt="img_dino"
                            />
                          </div>
                        )}
                      {isMobile ? (
                        <img
                          className={cx(
                            `roadmap-dashboard__weeks__week__img${
                              is_last ? "__last" : idx === 0 ? "__first" : ""
                            }`
                          )}
                          src={`/assets/images/codingrace/wood_week_${
                            is_last
                              ? "mobilefinal"
                              : idx === 0
                              ? "mobile0"
                              : idx <= expired.data.achv.finished_week_count
                              ? "mobile_red"
                              : "mobile"
                          }.svg`}
                          alt="img_milestone"
                        />
                      ) : (
                        <>
                          <img
                            className={cx(
                              `roadmap-dashboard__weeks__week__img${
                                is_last ? "__last" : ""
                              }`
                            )}
                            src={`/assets/images/codingrace/wood_week_${
                              is_last
                                ? isMaker
                                  ? "final_with_maker"
                                  : "final"
                                : idx
                            }.svg`}
                            alt="img_milestone"
                          />
                        </>
                      )}
                    </div>
                  );
                })}
                {isTrial && (
                  <img
                    className={cx(`roadmap-dashboard__weeks__benefit`)}
                    src="/v2/assets/images/codingrace/complete_benefit_bubble.svg"
                    alt=""
                  />
                )}

                {isVisible && (
                  <div className={cx("roadmap-dashboard__weeks__desc")}>
                    {desc}
                  </div>
                )}
              </div>

              {/*{expired.loaded && modalInfo && (*/}
              {/*  <Modal*/}
              {/*    visible={expired.data.achv.is_expired && !isKRISS}*/}
              {/*    handleVisible={modalInfo.handleVisible}*/}
              {/*    style={{}}*/}
              {/*    header={modalInfo.header}*/}
              {/*    img_path={modalInfo.img_path}*/}
              {/*    img_style={modalInfo.img_style}*/}
              {/*    content={modalInfo.content}*/}
              {/*    cta={modalInfo.cta}*/}
              {/*  />*/}
              {/*)}*/}
            </div>
          ) : (
            <div
              className={cx("roadmap-dashboard")}
              style={{
                backgroundImage: `url(${"/assets/images/roadmap/bg_roadmap_pirates.png"})`,
              }}
            ></div>
          )}
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, round, enrolled, expired, enrolledReimbursed } =
    state;
  const { user } = authentication;
  return {
    authentication,
    user,
    round,
    enrolled,
    expired,
    enrolledReimbursed,
  };
}

const connectedRoadmapDashboard = connect(mapStateToProps, {
  getExpired: enrolledActions.getExpired,
  setExpired: enrolledActions.setExpired,
  getEnrolledReimbursed: enrolledActions.getEnrolledReimbursed,
})(RoadmapDashboard);
export { connectedRoadmapDashboard as RoadmapDashboard };
