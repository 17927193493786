import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";

export const TapMenuBarContainer = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
  border-bottom: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#3A3E41";
        default:
          return "#eef3f6";
      }
    }};
`;

export const TapMenuAnchor = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  gap: 6px;
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  font-size: 14px;
  line-height: 1;
  font-family: "Pretendard";
  color: ${({ isActive, theme }) => {
    switch (theme) {
      case PIRATES:
        return isActive ? "#FF6C7A" : "#9DA7AE";
      default:
        return isActive ? "#e8344e" : "#9DA7AE";
    }
  }};
  height: 52px;

  border-bottom: ${({ isActive }) => (isActive ? "2px solid #e8344e" : "0px")};

  svg {
    position: relative;
    bottom: 0px;
  }

  //:hover {
  //  color: #e8344e;
  //
  //  border-bottom: 2px solid #e8344e;
  //  box-sizing: border-box;
  //  svg path {
  //    stroke: #e8344e;
  //  }
  //}
`;

export const OutlinkLogo = ({ theme }) => {
  const color = theme === PIRATES ? "#81898F" : "#9DA7AE";
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 9.83333V12.1667C11.9998 12.8083 11.4748 13.3333 10.8332 13.3333H3.83317C3.1915 13.3333 2.6665 12.8083 2.6665 12.1667V5.16667C2.6665 4.525 3.1915 4 3.83317 4H6.1665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.3335 2.6665H13.3335V6.6665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 2.6665L8 7.99984"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
