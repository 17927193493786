import { useEffect } from "react";
import { useGetCourse } from "../../v2/queries/homeworkV3";

const getCourseStatus = (courseId) => {
  switch (courseId) {
    case "6573ee78a6f2f06b38506f37":
    case "6572dfed4ede01ffe8b0daaf":
    case "665d37d44e44bdc5d003c04d":
    case "661dc56402b3366cb3682626":
      return {
        isOnSale: false,
        notice: "리뉴얼중인 강의로 현재 결제가 어렵습니다.",
        redirect: `${process.env.REACT_APP_SCC_MAIN_URL}`,
      };

    //aws_cloud강의 일반강의는 격파르타 aws로 Redirect
    case "668c96d42bbb65f282155301":
      return {
        isOnSale: false,
        redirect: "/payment/668cc24f561f34dc16f8635d",
      };
    case "5f51cba7d76b11f9244d1111":
      return {
        isOnSale: false,
        notice:
          "커리큘럼 리뉴얼로 본 강의의 판매가 중단되었습니다. \n새로운 강의 페이지로 이동합니다.",
        redirect: `${process.env.REACT_APP_SCC_MAIN_URL}/online/sql_2023`,
      };
    case "6433b6aab8d11cc10a00b294":
      return {
        isOnSale: false,
        notice:
          "커리큘럼 리뉴얼로 본 강의의 판매가 중단되었습니다. \n새로운 강의 페이지로 이동합니다.",
        redirect: `${process.env.REACT_APP_SCC_MAIN_URL}/online/SQLD-scc`,
      };
    case "650ab32920492d270e767be1":
    case "643e10c8ae797ba22b4a8c22":
      return {
        isOnSale: false,
        notice:
          "본 강의는 판매 중단되었습니다. \n리뉴얼된 환급형 SQLD 강의 상세페이지로 이동합니다.",
        redirect: `https://certificate-sqld.oopy.io/`,
      };
    case "643e10c8ae797ba22b4a8c2b":
      return {
        isOnSale: false,
        notice:
          "이 강의는 패키지 상품에서 종합반 강의로 리뉴얼되었습니다.\n종합반 강의로 이동합니다.",
        redirect: `${process.env.REACT_APP_SCC_MAIN_URL}/online/gpt_300_online`,
      };
    case "632ad50b2b63868e4030eb5b":
    case "632bec62293ed67329e02c62":
      return {
        isOnSale: false,
        notice:
          "본 강의는 판매 중단되었습니다.\n전체 강의 페이지로 이동합니다.",
      };
    case "642aa658c17d972aa91a14f7": // sqld 펀딩
    case "642aa754dba75779377de829": // figma 펀딩
    case "642aa813dba75779377de9a4": // 컴활 펀딩
    case "642aa526dba75779377de638": // 파이썬 펀딩
    case "6437aedd6d88f11f04a685a1":
    case "6437afa9915a0b020aff8686":
    case "6437af48915a0b020aff85af":
      return {
        isOnSale: false,
        notice: "본 강의의 펀딩은 종료되었습니다.",
      };
    case "5f0ae408765dae0006002816":
    case "6165446a91ba89271c12a0f4":
    case "6165446b91ba89271c12a131":
    case "6165446b91ba89271c12a124":
    case "5faa208089ddcab5ed1a47cd":
    case "60fa74cc3f9cb1a2ca68ad6d":
    case "606bccf0c11b70df6da82a9d":
    case "6083ebb16305e019d3e0c552":
    case "60fa74443f9cb1a2ca68abc7":
    case "5f51cba0e62d08d140b3e5bd":
    case "60fa748c3f9cb1a2ca68ac9a":
    case "6049dc8de987c144932211b3":
    case "6049dc3ee987c1449322114a":
    case "60fa73bb3f9cb1a2ca68aaf3":
    case "6049dc03e987c144932210e1":
    case "606bf88ec11b70df6da82b60":
    case "606bf913c11b70df6da82bc9":
    case "606bee36c11b70df6da82afe":
    case "606bf98bc11b70df6da82c32":
    case "602a02345104e5fd0517dd95":
    case "6049dbbbe987c14493221078":
    case "5f83153e410f5ac76429585d":
    case "6083eb1f6305e019d3e0c483":
    case "6165446a91ba89271c12a117":
    case "6020f0e35580bacb5f2678c5":
    case "606bfa74c11b70df6da82cfd":
    case "606bfb39c11b70df6da82d51":
    case "6295890f8240a11ae2511cab": //제페토
    case "62e3a1b9761f411652826c05": //젭
    case "65c091d522e51a142ed0aedc": //[격파르타] 컴활 1급
    case "651294ddda3f908daf61d875": //컴활 1급 필기
    case "6512945b535c9f5382d66d57": //컴활 1급 실기
      return {
        isOnSale: false,
        notice: "본 강의는 모집이 종료되었습니다.",
      };
    default:
      return {
        isOnSale: true,
      };
  }
};

export const useCourseStatus = (courseId) => {
  useEffect(() => {
    if (courseId) {
      const { isOnSale, notice, redirect } = getCourseStatus(courseId);
      if (!isOnSale) {
        if (notice) {
          alert(notice);
        }
        window.location.href =
          redirect ?? `${process.env.REACT_APP_SCC_MAIN_URL}/catalog`;
      }
    }
  }, [courseId]);
};

export const useCourseStatusApi = (courseId) => {
  const courseQuery = useGetCourse(courseId);
  const { isOnSale, notice, redirect } = getCourseStatus(courseId);
  const course = courseQuery.data?.context;
  useEffect(() => {
    if (courseQuery.isSuccess) {
      if (!isOnSale) {
        if (notice) {
          alert(notice);
        }
        window.location.href =
          redirect ?? `${process.env.REACT_APP_SCC_MAIN_URL}/catalog`;
      } else {
        if (course && course.status === "out_of_sale") {
          alert("리뉴얼중인 강의로 현재 결제가 어렵습니다.");
          window.location.href = `${process.env.REACT_APP_SCC_MAIN_URL}/catalog`;
        }
      }
    }
  }, [courseQuery.isSuccess]);
};
