import {
  CODE_SNIPPETS,
  MATERIALS,
  LECTURE,
  COMMON_LECTURE,
  LECTURE_V2,
} from "../constants";

const initState = {
  loaded: false,
};

export function commonLecture(state = initState, action) {
  switch (action.type) {
    case COMMON_LECTURE.COMMON_LECTURE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case COMMON_LECTURE.COMMON_LECTURE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case COMMON_LECTURE.COMMON_LECTURE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function codeSnippets(state = initState, action) {
  switch (action.type) {
    case CODE_SNIPPETS.CODE_SNIPPETS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case CODE_SNIPPETS.CODE_SNIPPETS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case CODE_SNIPPETS.CODE_SNIPPETS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function material(state = initState, action) {
  switch (action.type) {
    case MATERIALS.MATERIALS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case MATERIALS.MATERIALS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case MATERIALS.MATERIALS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function lecture_v2(state = initState, action) {
  switch (action.type) {
    case LECTURE_V2.LECTURE_V2_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case LECTURE_V2.LECTURE_V2_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case LECTURE_V2.LECTURE_V2_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
