import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 16px;
  width: 100vw;

  background: #fff6f8;
  ${Device.Desktop} {
    margin-top: 50px;
    padding: 50px;
    width: 744px;
    border-radius: 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  ${Device.Desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    gap: 40px;

    border-radius: 12px;
  }
`;

export const TitleContainert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: 16px;
  ${Device.Desktop} {
    align-items: flex-start;
    text-align: initial;
  }
`;

export const Title = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;

  color: #141617;
`;

export const SubTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #5f666b;
  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const DesktopOnlyBr = styled.br`
  display: none;
  ${Device.Desktop} {
    display: block;
  }
`;

export const MobileOnlyBr = styled.br`
  display: block;
  ${Device.Desktop} {
    display: none;
  }
`;
