import styled from "styled-components";
import { PIRATES } from "../../../../_consts";

export const GNBContainer = styled.div`
  width: 100%;
  height: 56px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
  padding: 16px 0 16px 16px;
`;

export const GNBArrowIcon = styled.div`
  width: 24px;
  height: 24px;
`;
