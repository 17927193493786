import { useLoadableRemoteConfig } from "@hackler/react-sdk";

export const useCustomRemoteConfig = (key, type, defaultValue) => {
  const { isLoading, remoteConfig } = useLoadableRemoteConfig();

  switch (type) {
    case "string":
      return remoteConfig.get(key, defaultValue || "");
    case "number":
      return remoteConfig.get(key, defaultValue ?? 0);
    case "boolean":
      return remoteConfig.get(key, defaultValue ?? false);
    case "json":
      const parameterValue = remoteConfig.get(key, "defaultValue");

      if (isLoading || parameterValue === "defaultValue") {
        return null;
      }

      return JSON.parse(parameterValue);
  }
};
