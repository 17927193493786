import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { PlayerControlBarDesktop } from "../components/PlayerControlBarDesktop/PlayerControlBarDesktop";
import { useIsMobile } from "../../../../../hooks/device";
import { PlayerControlBarMobile } from "../components/PlayerControlBarMobile/PlayerControlBarMobile";
import { MobileOverlay } from "../components/MobileOverlay/MobileOverlay";
import { SettingBottomSheet } from "../components/SettingBottomSheet/SettingBottomSheet";
import { DesktopOverlay } from "../components/MobileOverlay/DesktopOverlay";
import { Provider } from "react-redux";
import { store } from "../../../../../../_helpers";
import { FeedbackCtaOnPlayer } from "../../../../../components/molecules/FeedbackCta/FeedbackCta";
import { QueryClient, QueryClientProvider } from "react-query";

export const useRenderPlayerControlBar = (
  user,
  videoRef,
  playerType,
  lecture_v2,
  enrolledDetail,
  onTimeUpdate
) => {
  let controller;
  const isMobile = useIsMobile();
  useEffect(() => {
    const playerControllerContainer = document.createElement("div");
    playerControllerContainer.id = "player-controller-container";
    document
      .getElementById("vjs-player")
      ?.appendChild(playerControllerContainer);

    const container = document.getElementById("player-controller-container");
    const root = ReactDOM.createRoot(container);
    const queryClient = new QueryClient();

    controller = (
      /**TODO: PlayerController 부에서 redux-state 에 접근하기 위한 provider
       * 추후 플레이어 로직 개선 시 삭제 요망*/
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {isMobile ? (
            <>
              <MobileOverlay
                videoRef={videoRef}
                playerType={playerType}
                lecture_v2={lecture_v2}
              />
              <PlayerControlBarMobile
                videoRef={videoRef}
                playerType={playerType}
                lecture_v2={lecture_v2}
                onTimeUpdate={onTimeUpdate}
              />
              <SettingBottomSheet videoRef={videoRef} playerType={playerType} />
            </>
          ) : (
            <>
              <DesktopOverlay
                videoRef={videoRef}
                playerType={playerType}
                lecture_v2={lecture_v2}
              />
              {/*<FeedbackCtaOnPlayer*/}
              {/*  videoRef={videoRef}*/}
              {/*  enrolledDetail={enrolledDetail}*/}
              {/*/>*/}
              <PlayerControlBarDesktop
                videoRef={videoRef}
                playerType={playerType}
                lecture_v2={lecture_v2}
                enrolledDetail={enrolledDetail}
                user={user}
                onTimeUpdate={onTimeUpdate}
              />
            </>
          )}
        </QueryClientProvider>
      </Provider>
    );

    root.render(controller);

    return () => {
      // root.unmount();
    };
  }, [videoRef, playerType, lecture_v2]);

  return controller;
};
