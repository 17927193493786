import React from "react";
import * as S from "./TapMenuContentBtn.style";
import PropTypes from "prop-types";
import { useToastMessage } from "../../../LecturePage/LecLNB/Toast/Toast";
import { useSelector } from "react-redux";
import { sendLog } from "../../../../businesslogics/logging";
import { useOnlineTheme } from "../../../../queries/pirates";
import { PIRATES } from "../../../../../_consts";

export const TapMenuContentBtn = ({ title, content, isMaterial = false }) => {
  const toastAlert = useToastMessage();
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const copyToClipboard = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      toastAlert("복사가 완료되었어요!");
    } catch (e) {
      alert(e);
    }
  };
  const theme = useOnlineTheme();

  return (
    <S.LecContentContainer
      theme={theme.data}
      onClick={() => {
        sendLog(
          "scc_lecturePage_click_button",
          {
            ...enrolled.data,
            ...enrolledDetail.data,
            title: title,
            button_text: title,
            button_name: isMaterial ? "강의자료 버튼" : "코드 스니펫 버튼",
            next_page: isMaterial ? content : "",
          },
          ""
        );
        isMaterial ? window.open(content) : copyToClipboard(content);
      }}
    >
      <S.LecContentText theme={theme.data}>{title}</S.LecContentText>
      <S.WrapLogo>
        {isMaterial ? (
          <OutlinkLogo theme={theme.data} />
        ) : (
          <CopyLogo theme={theme.data} />
        )}
      </S.WrapLogo>
    </S.LecContentContainer>
  );
};

TapMenuContentBtn.prototype = {
  title: PropTypes.string,
  content: PropTypes.string,
  isMaterial: PropTypes.bool,
};

const CopyLogo = ({ theme }) => {
  const color = theme === PIRATES ? "#9DA7AE" : "#141617";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6V5C8 3.89543 8.89543 3 10 3H18C19.1046 3 20 3.89543 20 5V15C20 16.1046 19.1046 17 18 17H17"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="4"
        y="7"
        width="12"
        height="14"
        rx="2"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

const OutlinkLogo = ({ theme }) => {
  const color = theme === PIRATES ? "#9DA7AE" : "#141617";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 14.75V18.25C18 19.2125 17.2125 20 16.25 20H5.75C4.7875 20 4 19.2125 4 18.25V7.75C4 6.7875 4.7875 6 5.75 6H9.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4H20V10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 4L12 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
