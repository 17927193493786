import styled from "styled-components";
import { device } from "../../../../../_styles/device";

export const LeftMenuWrapper = styled.div`
  display: none;
  flex-direction: column;
  @media ${device.desktop} {
    display: flex;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-height: 550px;
  width: 306px;
`;

export const MenuDiv = styled.div`
  padding: 20px 16px 20px;
  border-bottom: 1px solid #dee2e6;
  &:last-child {
    border-bottom: none;
  }
  cursor: pointer;
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MenuTitle = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 16px;
  color: #212529;
`;

export const MenuInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MenuInfo = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 16px;
  color: #81898f;
`;

export const MenuGoIcon = styled.div`
  width: 20px;
  height: 20px;
  margin: auto 0;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
`;

export const UserInfo = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  color: #868e96;
`;

export const FriendRecommend = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: #f1f3f5;
  height: 52px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const FriendBoxIcon = styled.div`
  width: 23px;
  height: 22px;
`;
export const FriendTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 16px;
  color: #212529;
  span {
    color: #e8344e;
  }
`;

export const LogoutCta = styled.div`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 16px;
  color: #8b8b8b;
  text-align: center;
  margin-top: 28px;
  cursor: pointer;
`;
