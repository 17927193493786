import { useEffect, useRef, useState } from "react";

export const ReactWebpImage = ({ fallback, src, styles }) => {
  const pictureRef = useRef();
  const [fallbackUrl, setFallbackUrl] = useState("");
  useEffect(() => {
    if (pictureRef.current) {
      const sourceType = pictureRef.current.children[0].type;
      if (sourceType !== "image/webp") setFallbackUrl(fallback);
      else setFallbackUrl("");
    }
  }, []);
  return (
    <picture ref={pictureRef}>
      <source type="image/webp" srcSet={src} />
      <img style={styles} alt={"course_thumb"} src={fallbackUrl} />
    </picture>
  );
};
