import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 24px;
  padding: 22px 18px;
`;

export const Title = styled.div`
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.6px;
  text-align: left;
  color: #000000;
`;

export const Cta = styled.div`
  width: 100%;
  background-color: #e8344e;
  color: #fff;
  text-align: center;
  border-radius: 100px;
  padding: 14px;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.96px;
  cursor: pointer;
`;
