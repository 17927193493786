import * as S from "./MyPoint.style";
import { useState, useEffect } from "react";
import { numberWithCommas } from "../../../../_helper";
import { NudgeBannerMyPage } from "../../../../../_components/NudgeBanner/NudgeBannerMyPage";
import { getISODateAsKoreanOnlyMonth } from "../../../../pages/RoadmapPage/RoundWeek/utils";
import { useSelector } from "react-redux";

export const MyPoint = () => {
  const pointInfo = useSelector((state) => state.pointInfo);
  const expirationPoints = useSelector((state) => state.expirationPoints);
  const [showNoticeDetail, setShowNoticeDetail] = useState(false);

  return (
    <S.MyPointWrapper>
      <S.PointContainer>
        <S.PointTextContainer>
          {numberWithCommas(pointInfo.data.point)}
          &nbsp;
          <S.Unit>원</S.Unit>
          <S.ExpirationPointContainer>
            <S.ExpirationDetail>
              {getISODateAsKoreanOnlyMonth()} 소멸 예정 :{" "}
              {numberWithCommas(expirationPoints.data)}원
            </S.ExpirationDetail>
            <S.ExpirationNotice>
              *소멸 기준 : 발행 후 1년 경과
            </S.ExpirationNotice>
          </S.ExpirationPointContainer>
        </S.PointTextContainer>
      </S.PointContainer>
      <S.PointNotice
        onClick={() => {
          setShowNoticeDetail(!showNoticeDetail);
        }}
        text="• 포인트로는 강의를 결제할 수 있어요.(1p=1원)"
      >
        포인트 사용 안내
      </S.PointNotice>
      {showNoticeDetail && (
        <S.PointNoticeDetail>
          • 포인트로는 강의를 결제할 수 있어요.(1p=1원)
        </S.PointNoticeDetail>
      )}
      <NudgeBannerMyPage style={{ marginTop: "16px" }} subTitle={""} />
    </S.MyPointWrapper>
  );
};
