import * as S from "./LectureList.style";
import { LectureListItem } from "../LectureListItem/LectureListItem";
import { useOnlineTheme } from "../../../queries/pirates";
import { useSyllabus } from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useRecommendBannerData } from "../../../hooks/sideBar/useRecommendBannerData";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { useParams } from "react-router-dom";
import {
  useGetEnrolledDetail,
  useGetEnrolled,
} from "../../../queries/homeworkV3";

export const MobileLectureList = () => {
  const { enrolled_id: enrolledId, detail_id } = useParams();
  const { data: enrolledDetail } = useGetEnrolledDetail(enrolledId, detail_id);
  const { data: enrolledData } = useGetEnrolled(enrolledId);

  const { bannerImgSrc, nextHref, log } = useRecommendBannerData({
    courseId: enrolledDetail?.context.course_id,
    courseTitle: enrolledData?.context?.courses.title,
  });
  const theme = useOnlineTheme();
  const syllabus = useSyllabus();

  const handleBannerClick = () => {
    sendCPLog(log.title, log.data);
    window.open(nextHref);
  };

  if (!enrolledDetail || !enrolledData) return null;

  return (
    <S.ListWrapper theme={theme.data}>
      <S.ListWithoutGnbWrapper>
        {syllabus?.map((week) => (
          <LectureListItem enrolledWeek={week} key={week.week} />
        ))}
        <S.CSRCampaignBannerImage
          onClick={handleBannerClick}
          target="_blank"
          rel="noopener noreferrer"
          src={bannerImgSrc}
        />
      </S.ListWithoutGnbWrapper>
    </S.ListWrapper>
  );
};
