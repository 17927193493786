import React from "react";
import * as S from "./RecordPageHeader.style";

export function RecordPageHeader({ mainText, totalTime, subText }) {
  return (
    <S.Container>
      <S.MainText>{mainText}</S.MainText>
      <S.SubText>{subText}</S.SubText>
      <S.TotalTime>{totalTime}</S.TotalTime>
    </S.Container>
  );
}
