import styled from "@emotion/styled";
import { neutralDark } from "@teamsparta/design-system";

export const Container = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${neutralDark.white};
  z-index: 2;
  position: relative;
`;

export const Confetti = styled.div`
  position: absolute;
  background-image: url("https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcjl0amx0Z2g3aW0yd2trbXlvMGdiYWxta3R0eGF1NTJwY29qdHMybyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/5CQd0LtouVSZsfC9x5/giphy.gif");
  width: 100%;
  height: 100%;
  max-width: 480px;
  max-height: 480px;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;
