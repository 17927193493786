import { environmentConstants } from "../_constants";
const BREAKPOINT_TABLE_MOBILE = 758;
const BREAKPOINT_TABLE_TABLET = 1240;

const initialState = {
  height: 0,
  width: 0,
  deviceType:
    window.screen.width < BREAKPOINT_TABLE_MOBILE
      ? "mobile"
      : window.screen.width < BREAKPOINT_TABLE_TABLET
      ? "tablet"
      : "pc",
};

const environment = (state = initialState, action) => {
  switch (action.type) {
    case environmentConstants.WINDOW_RESIZE:
      return {
        ...state,
        height: action.height,
        width: action.width,
        deviceType:
          window.screen.width < BREAKPOINT_TABLE_MOBILE
            ? "mobile"
            : window.screen.width < BREAKPOINT_TABLE_TABLET
            ? "tablet"
            : "pc",
      };

    default:
      return state;
  }
};

export { environment };
