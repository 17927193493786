import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1230px;
  margin: 50px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
`;

export const Header = styled.section``;

export const Content = styled.section``;

export const Check = styled.section``;

export const CtaContainer = styled.div`
  display: flex;
  width: 700px;
  align-items: center;
  justify-content: space-between;
`;

export const SquareCta = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 86px;
  background-color: #f1c40f;
  font-weight: bold;
  border-radius: 10px;
  color: #000;
  font-size: 22px;
`;
