import styled from "styled-components";
import { device } from "../../../../../_styles/device";

export const HelloContainer = styled.div`
  display: none;
  flex-direction: row;
  max-width: 1192px;
  margin: 48px auto 40px;
  @media ${device.desktop} {
    display: flex;
    width: 100%;
    max-width: 1220px;
    padding-left: 20px;
  }
`;

export const SayHelloTitle = styled.div`
  width: 306px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 24px;
  color: #141617;
  flex-shrink: 0;
`;

export const ClassroomCtaWrapper1 = styled.div`
  margin-left: 56px;
  width: 894px;
`;

export const ClassroomCtaWrapper2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  width: 100%;
`;

export const ClassroomCta = styled.div`
  width: 200px;
  height: 56px;
  border-radius: 8px;
  background-color: #e8344e;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

export const ClassroomTitle = styled.div`
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
`;

export const ClassroomIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export const GiftTooltip = styled.div`
  cursor: default;
  position: absolute;
  background-image: url("/v2/assets/images/bg-tooltip.png");
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
  left: -95%;
  width: 170px;
  height: 32px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
  filter: drop-shadow(0px 8px 24px rgba(129, 137, 143, 0.12));
`;

export const GiftTooltipText = styled.div`
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #e8344e;
`;
