import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const CouponWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #d7e0e6;
  border-radius: 8px;
  margin-top: 16px;
  ${Device.Desktop} {
    width: 411px;
    &:last-child {
      margin-bottom: 120px;
    }
  }

  &:last-child {
    margin-bottom: 40px;
  }
`;

export const Coupon = styled.div`
  width: 100%;
  padding: 20px 24px 16px 20px;
`;

export const CouponName = styled.div`
  font-size: 16px;
  font-family: Pretendard;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  color: #141617;
  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const CouponBenefit = styled.div`
  font-size: 16px;
  font-family: Pretendard;
  font-weight: 700;
  color: #141617;
  line-height: 1.4;
  margin-top: 2px;
  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const CouponExpired = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #9da7ae;
  b {
    font-family: "Pretendard";
    color: #e8344e;
  }
  ${Device.Desktop} {
    font-size: 13px;
  }
`;

export const CouponCtaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  box-sizing: border-box;
  position: relative;
  border-top: 1px dashed #e4ebf0;
`;

export const Cta = styled.span`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.5;
  color: #141617;
  display: flex;
  align-items: center;
  margin-top: 2px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CouponMinimumCondition = styled.div`
  margin-top: 8px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #9da7ae;
`;
