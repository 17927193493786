import privateInstance from "../../_helper/axios";
import { useEffect, useState } from "react";

export const getUserMetaData = async (userId) => {
  return await privateInstance
    .get(`/users/meta?user_id=${userId}`)
    .then((resp) => resp.data);
};

export const useUserMetaData = (userId) => {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    getUserMetaData(userId).then((resp) => {
      if (resp.ok) {
        setUserData(resp.context);
      }
    });
  }, []);
  return userData;
};
