import * as S from "../HomeworkAnswer.style";
export const SelectAnswer = ({
  answer,
  setAnswer,
  onUpdateSubmittedAnswer,
}) => {
  const handleRadioChange = (event) => {
    setAnswer(event.target.value);
    onUpdateSubmittedAnswer(event.target.value);
  };
  return (
    <S.SelectAnswerWrapper>
      <S.SelectBox>
        <S.SelectRadio
          type={"radio"}
          value={"네"}
          checked={answer === "네"}
          onChange={handleRadioChange}
        />
        네
      </S.SelectBox>
      <S.SelectBox>
        <S.SelectRadio
          type={"radio"}
          value={"아니오"}
          checked={answer === "아니오"}
          onChange={handleRadioChange}
        />
        아니오
      </S.SelectBox>
    </S.SelectAnswerWrapper>
  );
};
