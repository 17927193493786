import React from "react";
import styled from "@emotion/styled";
import {additional, neutralDay, mBody1} from "@teamsparta/design-system";
import {useSelector} from "react-redux";
import {useSendLogChatbotClickButton} from "../../../businesslogics/AiChat/useSendAiChatLog";

const Button = styled.div`
  background-color : ${neutralDay.gray10};
  padding : 11px 14px;
  ${mBody1};
  font-weight:600;
  border-radius: 9px;
  margin-right: 10px;
  cursor: pointer;
  
  &:hover {
    background-color : ${neutralDay.gray30};
  }
`
export const EndMessage = (text)=> {
    const course = useSelector((state) => state.course);
    const sendLogClickButton = useSendLogChatbotClickButton();
    const openFastQna = () => {
        sendLogClickButton("질문", "학습 질문 바로가기")
        window.open(
            `https://spartacodingclub.kr/community/fastqna/${course.data?.keyword}`
        );
    }
    return (
        <>
            더 상세한 답변이 필요하다면 튜터님에게 질문해 보세요! 척척박사 르탄봇이 될 수 있도록 더 열심히 공부할게요 🧐
            <Button onClick={openFastQna}>학습 질문 바로가기</Button>
        </>
    );
}
