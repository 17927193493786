import * as S from "./GnbMyPage.style.js";
import { useAtom } from "jotai";
import { GnbRowMobile } from "../atoms/GnbRow.mobile.jsx";
import { useEffect, useRef, useState } from "react";
import {
  couponAnchor,
  logoutAnchor,
  mobileMyPageAnchors,
  pointAnchor,
} from "../models/gnbV3.models";
import { isMyPageOpenAtom } from "../../../stores/mobileMyPageTabOpen";
import { useIsLoggedInV2 } from "../../../hooks/useSetAmplitudeUserId";
import {
  useClickAnchor,
  useOnOutsideClick,
} from "../../../businesslogics/_layout/gnb/v2";
import { CouponIcon, PointIcon, ZeroWonIcon } from "./GnbMyPage.svg";
import { useUserMetaData } from "../../../queries/gnb";
import { numberWithCommas } from "../../../_helper";
import { ArrowChevronRightSmall } from "@teamsparta/design-system";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export const GnbMyPage = ({ user }) => {
  const [show, setIsMyPageOpenAtom] = useAtom(isMyPageOpenAtom);
  const isLoggedIn = useIsLoggedInV2();
  const userName = user?.name;
  const userEmail = user?.email;
  const userId = user?._id;
  const userMetaData = useUserMetaData(userId);
  const onClickPointAnchor = useClickAnchor(pointAnchor);
  const onClickCouponAnchor = useClickAnchor(couponAnchor);
  const myPageRef = useRef(null);
  useOnOutsideClick(myPageRef, () => setIsMyPageOpenAtom(false));
  //userMetaData를 바로 받아오지 못해 재시도 로직 추가
  const [retryCount, setRetryCount] = useState(0); // 재시도 횟수 관리
  const MAX_RETRIES = 3; // 최대 재시도 횟수
  const RETRY_INTERVAL = 100;

  useEffect(() => {
    if (!userMetaData && retryCount < MAX_RETRIES) {
      const timer = setTimeout(() => {
        setRetryCount((prevCount) => prevCount + 1);
      }, RETRY_INTERVAL);

      return () => clearTimeout(timer); // 컴포넌트 unmount 시 타이머 클리어
    }
  }, [userMetaData, retryCount]);

  return (
    <S.ToolTipBox show={show} isShort={!isLoggedIn} ref={myPageRef}>
      <S.MyPageContainer>
        <S.GreetingContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              marginLeft: "30px",
            }}
          >
            <S.GreetingProfileIcon src={"/v2/assets/rtan/developer.png"} />
            <S.ProfileContainer>
              <S.UserName>{userName}</S.UserName>
              <S.EmailContainer>{userEmail}</S.EmailContainer>
            </S.ProfileContainer>
          </div>
        </S.GreetingContainer>
        <S.PointCouponContainer>
          <S.PointCouponWrapper onClick={onClickPointAnchor}>
            <S.TitleWrapper>
              <PointIcon />
              <S.PointCouponTitle>{pointAnchor?.name}</S.PointCouponTitle>
            </S.TitleWrapper>
            <S.PointCouponContent>
              {userMetaData?.point_amount >= 0
                ? `${numberWithCommas(userMetaData?.point_amount || 0)}원`
                : "로딩중"}
            </S.PointCouponContent>
          </S.PointCouponWrapper>
          <S.ReversDivider />
          <S.PointCouponWrapper onClick={onClickCouponAnchor}>
            <S.TitleWrapper>
              <CouponIcon />
              <S.PointCouponTitle>{couponAnchor?.name}</S.PointCouponTitle>
            </S.TitleWrapper>
            <S.PointCouponContent>
              {userMetaData?.active_coupon_count >= 0
                ? userMetaData?.active_coupon_count > 0
                  ? `${numberWithCommas(
                      userMetaData?.active_coupon_count || 0
                    )}장`
                  : "없음"
                : "로딩중"}
            </S.PointCouponContent>
          </S.PointCouponWrapper>
        </S.PointCouponContainer>
        <S.RowsContainer>
          <S.Divider />
          <S.Group>
            {mobileMyPageAnchors.map((anchor) => (
              <div key={`gnb-mypage-mobile-${anchor.key}`}>
                {(isLoggedIn || !anchor.needLogin) && (
                  <div key={`gnb-mypage-mobile-${anchor.key}`}>
                    <GnbRowMobile anchor={anchor} />
                  </div>
                )}
              </div>
            ))}
            {/* 
            <a
              href={`${process.env.REACT_APP_SCC_MAIN_URL}/nb/guide`}
              onClick={() => {
                sendCPLog("scc_myPage_click", {
                  button_text: "국비지원 신청 가이드",
                });
              }}
            >
              <S.MyPageBanner>
                <S.BanenrContainer>
                  <S.BannerIconWrapper>
                    <ZeroWonIcon />
                  </S.BannerIconWrapper>
                  <S.BannerTextContainer>
                    <S.BannerSubText>50만원 강의를 0원으로?</S.BannerSubText>
                    <S.BannerText>국비지원 신청 가이드 보기</S.BannerText>
                  </S.BannerTextContainer>
                </S.BanenrContainer>
                <ArrowChevronRightSmall size={16} color={"#9da7ae"} />
              </S.MyPageBanner>
            </a> */}
          </S.Group>
          <S.Divider />
          <S.Group>
            <GnbRowMobile anchor={logoutAnchor} />
          </S.Group>
        </S.RowsContainer>
      </S.MyPageContainer>
    </S.ToolTipBox>
  );
};
