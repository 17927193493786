import styled from "@emotion/styled";
import React from "react";
import { neutralDay } from "@teamsparta/design-system";

export const Title = styled.div`
  width: 70%;
  font-size: 16px;
`;

export const ContentToggle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const ToggleTitle = styled.div`
  padding: 10px;
  font-family: "Noto Sans KR", "Source Sans Pro", sans-serif;

  font-size: 18px;
  color: black;
`;

export const ToggleBtn = styled.div`
  cursor: pointer;
  background-color: ${neutralDay.gray10};
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  width: 130px;
  padding: 0 24px 0 24px;
  font-family: "Noto Sans KR", "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: black;
`;

export const ToggleSvg = ({ theme }) => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77172 0.174561L7.05603 5.51585L12.3403 0.174563L13.9636 1.82699L7.05603 8.80906L0.148437 1.82698L1.77172 0.174561Z"
        fill="black"
      />
    </svg>
  );
};
