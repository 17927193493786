import { homeworkConstants } from "../_constants";

const initState = {
  loaded: false,
  data: [],
};

export function homework(state = initState, action) {
  switch (action.type) {
    case homeworkConstants.GET_HOMEWORK_ITEM_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case homeworkConstants.GET_HOMEWORK_ITEM_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.homework,
        isDone: action.response.is_done,
        nps: action.response.nps,
      });
    case homeworkConstants.GET_HOMEWORK_ITEM_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
        error: action.error,
      });
    default:
      return state;
  }
}
