import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./NPSPage.module.scss";
import { LecMenuComponent } from "../LecturePage/LecMenu/LecMenuComponent";
import { lectureMenuComponents } from "../LecturePage/LecMenu/constants";
import { BroadcastContainer } from "../LecturePage/BroadcastContainer";
import { LecNav } from "../LecturePage/LecNav";
import { NPSNextBtn } from "./NPSNextBtn";
import "./NPSPage.antdesign.custom.css";
import { NPSScroller } from "./NPSScroller";
import { sendLog } from "../../businesslogics/logging";
import {
  changQuestions,
  courseRcmdQuestions,
  hrQuestions,
  nbFreeQuestions,
  normalQuestions,
  youthQuestions,
} from "./prequestionOptions";
import { Check, NumIndex } from "./NPSPage.svg";
import { RcmdQuestion, SatisfactionQuestion } from "./QuestionBox";
import { MainNPSFooter } from "./NPSPage.style";
import { useDevice } from "../NBCampAttendPage";
import { useOnlineTheme } from "../../queries/pirates";
import { connect } from "react-redux";
import { enrolledActions, prequestionAction } from "../../_state/actions";
import { coursesActions } from "../../_state/actions/courses.actions";
import { useSyllabusUnit } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

const cx = classNames.bind(styles);

function NPSPage({
  match,
  user,
  roundWeeks,
  enrolled,
  enrolledDetails,
  getRoundWeeks,
  setEnrolled,
  courseV2,
  getCourse,
  prequestionOfEnrolled,
  getPrequestion,
}) {
  const { enrolled_id, week } = match.params;
  const [isDual, setIsDual] = useState(false);
  const [satisfaction, setSatisfaction] = useState(null);
  const [goodPoint, setGoodPoint] = useState("");
  const [badPoint, setBadPoint] = useState("");
  const [goal, setGoal] = useState("");
  const [npsScore, setNpsScore] = useState(null);
  const [isRcmdVisible, setIsRcmdVisible] = useState(false);
  const isTrial = courseV2.data?.is_trial;
  const [showGoalTooltip, setShowGoalTooltip] = useState(true);
  const [prevPrequestions, setPrevPrequestions] = useState([]);
  const [hasCard, setHasCard] = useState(null);

  /* 마지막 주차 직전 주일 경우, 강의의 전반적인 만족도에 대해 조사하는 항목들 */
  const device = useDevice();
  const isDesktop = device === "pc";
  const [rcmdScore, setRcmdScore] = useState(null);
  const [contentsScore, setcontentsScore] = useState(null);
  const [contentsText, setcontentsText] = useState("");
  const [specialCareScore, setSpecialCareScore] = useState(null);
  const [specialCareText, setSpecialCareText] = useState("");
  const [qnaScore, setQnaScore] = useState(null);
  const [qnaText, setQnaText] = useState("");
  const [webpageScore, setWebpageScore] = useState(null);
  const [webpageText, setWebpageText] = useState("");
  const theme = useOnlineTheme();

  const isGov = enrolled?.data?.courses?.is_gov;
  const filteredQuestions = isGov
    ? courseRcmdQuestions.filter((question) => question.isRequired === true)
    : courseRcmdQuestions;
  useEffect(() => {
    if (isTrial && !prequestionOfEnrolled.loaded) {
      getPrequestion(enrolled_id);
    }
  }, [isTrial, enrolled_id]);

  useEffect(() => {
    if (prequestionOfEnrolled.loaded) {
      setGoal(prequestionOfEnrolled.data.goal);
      if (
        prequestionOfEnrolled.data.course_title ===
        "교육 담당자를 위한 DT교육 설계 로드맵"
      ) {
        setPrevPrequestions(hrQuestions);
      } else if (
        prequestionOfEnrolled.data.course_title ===
        "중고등학생 코딩교육 준비 전략"
      ) {
        setPrevPrequestions(youthQuestions);
      } else if (
        prequestionOfEnrolled.data.course_title ===
        "내일배움카드 발급부터 활용의 모든 것"
      ) {
        setPrevPrequestions(nbFreeQuestions);
      } else {
        setPrevPrequestions(normalQuestions);
      }
    }
  }, [prequestionOfEnrolled.loaded]);

  useEffect(() => {
    setEnrolled(enrolled_id);
  }, [enrolled_id, week]);

  useEffect(() => {
    if (!courseV2.loaded && enrolled.loaded) getCourse(enrolled.data.course_id);
  }, [enrolled.loaded]);

  useEffect(() => {
    if (!roundWeeks.loaded) {
      getRoundWeeks(enrolled_id);
    }
  }, [roundWeeks.loaded]);

  useEffect(() => {
    if (enrolled.loaded) {
      sendLog("scc_npsPage_view", {});
    }
  }, [enrolled.loaded]);

  const nextSub = roundWeeks.data?.weeks.filter(
    (w) => w.week === Number(week) + 1
  );

  const secondNextSub = roundWeeks.data?.weeks.filter(
    (w) => w.week === Number(week) + 2
  );

  const isLastWeek = nextSub?.length === 0;
  const isLastWeekBefore = secondNextSub?.length === 0 && !isLastWeek;

  useEffect(() => {
    if (enrolled.loaded && enrolledDetails.loaded && isLastWeek) {
      sendLog("scc_rcmdSurveyPage_view", {
        ...enrolled.data,
        ...enrolledDetails.data,
      });
    }
  }, [enrolled.loaded, enrolledDetails.loaded]);

  const isEvent =
    enrolled.data?.rounds.round === "힙한취미코딩 이벤트" ||
    enrolled.data?.course_id === "61ee5729ce80bc31d521c82a";

  const syllabusUnit = useSyllabusUnit(enrolled.data?.course_id);

  return (
    <div>
      {user && (
        <div>
          <div
            className={cx(
              "nps-page",
              theme.data,
              `${isLastWeekBefore ? "__mainNPS" : ""}`
            )}
          >
            <div className={cx("nps-page__nav", `${isDual ? "dual" : ""}`)}>
              <div className={cx("nps-page__nav__bg", theme.data)} />
              <LecMenuComponent
                component={lectureMenuComponents[0]}
                idx={0}
                isDual={isDual}
                isNav={true}
              />
              <BroadcastContainer />
              {/*<LearnTimeContainer />*/}
              <LecNav
                isDual={isDual}
                title={`${week}${syllabusUnit} 강의 설문 제출하기`}
                clickable={false}
              />
              {enrolled.loaded &&
                courseV2.loaded &&
                (isLastWeekBefore ? (
                  isDesktop && (
                    <NPSNextBtn
                      isLastWeekBefore={isLastWeekBefore}
                      week={week}
                      roundWeeks={roundWeeks}
                      courseId={enrolled.data.course_id}
                      roundId={enrolled.data.round_id}
                      isNado={courseV2.data.is_nado}
                      enrolledId={enrolled_id}
                      isEvent={isEvent}
                      isTrial={isTrial}
                      rcmdScore={rcmdScore}
                      contentsScore={contentsScore}
                      contentsText={contentsText}
                      specialCareScore={specialCareScore}
                      specialCareText={specialCareText}
                      qnaScore={qnaScore}
                      qnaText={qnaText}
                      webpageScore={webpageScore}
                      webpageText={webpageText}
                    />
                  )
                ) : (
                  <NPSNextBtn
                    isLastWeekBefore={isLastWeekBefore}
                    week={week}
                    satisfaction={satisfaction}
                    goodPoint={goodPoint}
                    badPoint={badPoint}
                    goal={goal}
                    hasCard={hasCard}
                    npsScore={npsScore}
                    roundWeeks={roundWeeks}
                    courseId={enrolled.data.course_id}
                    roundId={enrolled.data.round_id}
                    isNado={courseV2.data.is_nado}
                    enrolledId={enrolled_id}
                    isEvent={isEvent}
                    isTrial={isTrial}
                  />
                ))}
            </div>
            <div
              className={cx(
                `${isLastWeekBefore ? "nps-page__mainNPS__background" : ""}`,
                theme.data
              )}
            >
              <div
                className={cx(
                  "nps-page__content",
                  `${isLastWeekBefore ? "__mainNPS" : ""}`
                )}
              >
                {isEvent && isLastWeek ? (
                  <section className={cx("nps-page__content__header")}>
                    <h1
                      className={cx(
                        "nps-page__content__header__title",
                        theme.data
                      )}
                    >
                      {user.name}님, 코딩 미션은 어떠셨어요?
                    </h1>
                    <div
                      className={cx(
                        "nps-page__content__header__subtitle",
                        theme.data
                      )}
                    >
                      설문까지 제출해야 미션이 최종 완료되어요! {user.name}
                      님의 의견은 강의 개선에 큰 도움이 됩니다.😊
                    </div>
                  </section>
                ) : !isLastWeekBefore ? (
                  <section className={cx("nps-page__content__header")}>
                    <h1
                      className={cx(
                        "nps-page__content__header__title",
                        theme.data
                      )}
                    >
                      {user.name}님,
                      <br />{" "}
                      {courseV2.loaded &&
                        (!isTrial
                          ? `이번 주 수업은 어떠셨어요?`
                          : `<${courseV2.data?.title}>\n 강의는 어떠셨어요?`)}
                    </h1>
                    <div
                      className={cx(
                        "nps-page__content__header__subtitle",
                        theme.data
                      )}
                    >
                      {courseV2.loaded &&
                        '남겨주신 만족도 및 후기는 통계적 목적 또는 마케팅 목적으로 활용될 수 있습니다.\n활용 시 개인정보는 보호됩니다(ex. 수강생명: 김**).'}
                    </div>
                  </section>
                ) : (
                  <RcmdQuestion
                    name={user.name}
                    questionTitle={`이 수업을 친구나 동료에게 \n얼마나 추천하고 싶으신가요?`}
                    questionSubTitle1={`수업에 대한 ${user.name}님의 의견을 들려주세요.\n강의 개선에 큰 도움이 됩니다.😊`}
                    size={11}
                    onChange={setRcmdScore}
                  />
                )}
                {!isLastWeekBefore && (
                  <hr className={cx("nps-page__content__hr", theme.data)} />
                )}
                {courseV2.loaded &&
                  (!isTrial ? (
                    <section
                      className={cx(
                        "nps-page__content__body",
                        `${isLastWeekBefore ? "__mainNPS" : ""}`
                      )}
                    >
                      {!isLastWeekBefore ? (
                        <>
                          <NumIndex n={1} />
                          <div
                            className={cx(
                              "nps-page__content__body__question",
                              theme.data
                            )}
                          >
                            <div
                              className={cx(
                                "nps-page__content__body__question__title"
                              )}
                            >
                              이번 주차 수업은 만족하셨나요?
                            </div>
                            <div
                              className={cx(
                                "nps-page__content__body__question__rcmd__wrapper",
                                theme.data
                              )}
                            >
                              {[
                                { value: "네", text: "네" },
                                { value: "아니오", text: "아니오" },
                              ].map((selection) => {
                                const { value, text } = selection;
                                return (
                                  <div
                                    className={cx(
                                      "course_rcmd__question",
                                      theme.data,
                                      `${
                                        value === satisfaction
                                          ? "--selected"
                                          : ""
                                      }`
                                    )}
                                    onClick={() => {
                                      setSatisfaction(value);
                                    }}
                                  >
                                    <Check
                                      className={cx(
                                        "course_rcmd__question__icon",
                                        theme.data
                                      )}
                                    />
                                    <span
                                      className={cx(
                                        "course_rcmd__desc",
                                        theme.data
                                      )}
                                    >
                                      {text}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <NumIndex n={2} />
                          <div
                            className={cx(
                              "nps-page__content__body__question",
                              theme.data
                            )}
                          >
                            <div
                              className={cx(
                                "nps-page__content__body__question__title"
                              )}
                            >
                              어떤 점이 좋으셨어요?
                            </div>
                            <textarea
                              className={cx(
                                "nps-page__content__body__question__textarea",
                                theme.data
                              )}
                              onChange={(e) => setGoodPoint(e.target.value)}
                              placeholder={
                                "만족한 부분에 대한 의견을 자유롭게 남겨주세요."
                              }
                              value={goodPoint}
                            />
                          </div>
                          <NumIndex n={3} />
                          <div
                            className={cx(
                              "nps-page__content__body__question",
                              theme.data
                            )}
                          >
                            <div
                              className={cx(
                                "nps-page__content__body__question__title"
                              )}
                            >
                              어떤 점이 아쉬우셨나요?
                            </div>
                            <textarea
                              className={cx(
                                "nps-page__content__body__question__textarea",
                                theme.data
                              )}
                              onChange={(e) => setBadPoint(e.target.value)}
                              placeholder={
                                "아쉬웠던 부분에 대한 의견을 자유롭게 남겨주세요."
                              }
                              value={badPoint}
                            />
                          </div>
                          <NumIndex n={4} />
                          <div
                            className={cx(
                              "nps-page__content__body__question",
                              theme.data,
                              "--isRadioGroup"
                            )}
                          >
                            <div
                              className={cx(
                                "nps-page__content__body__question__title"
                              )}
                            >
                              친구나 동료에게 수업을 어느 정도로 추천하시겠어요?
                              (1-10)
                            </div>
                            <NPSScroller
                              setNpsScore={setNpsScore}
                              setIsRcmdVisible={setIsRcmdVisible}
                            />
                          </div>
                        </>
                      ) : (
                        <div className={cx("satisfaction-container")}>
                          <SatisfactionQuestion
                            questionNumber={1}
                            questionTitle={
                              "수강하신 강의 콘텐츠는 얼마나 만족하셨나요?"
                            }
                            size={5}
                            onChangeScore={setcontentsScore}
                            onChangeText={setcontentsText}
                          />
                          <SatisfactionQuestion
                            questionNumber={2}
                            questionTitle={
                              "학습 관리 서비스(찐한 관리, 문자 알림 등)는 얼마나 만족하셨나요?"
                            }
                            size={5}
                            onChangeScore={setSpecialCareScore}
                            onChangeText={setSpecialCareText}
                          />
                          <SatisfactionQuestion
                            questionNumber={3}
                            questionTitle={
                              "학습질문 서비스에 얼마나 만족하셨나요?"
                            }
                            size={5}
                            onChangeScore={setQnaScore}
                            onChangeText={setQnaText}
                          />
                          <SatisfactionQuestion
                            questionNumber={4}
                            questionTitle={
                              "수강 중 이용하신 웹페이지는 얼마나 만족하셨나요?"
                            }
                            size={5}
                            onChangeScore={setWebpageScore}
                            onChangeText={setWebpageText}
                          />
                        </div>
                      )}

                      {isLastWeek && (
                        <>
                          <NumIndex n={5} />
                          <div
                            className={cx(
                              "nps-page__content__body__question",
                              theme.data
                            )}
                          >
                            <div
                              className={cx(
                                "nps-page__content__body__question__title"
                              )}
                            >
                              모든 강의를 마친 지금, 어떤 다음 목표를 가지고
                              계신지 궁금해요!
                            </div>
                            <div
                              className={cx(
                                "nps-page__content__body__question__rcmd__wrapper",
                                theme.data
                              )}
                            >
                              {filteredQuestions.map(({ value, text }) => {
                                return (
                                  <div
                                    className={cx(
                                      "course_rcmd__question",
                                      theme.data,
                                      `${value === goal ? "--selected" : ""}`
                                    )}
                                    onClick={() => {
                                      setGoal(value);
                                      setShowGoalTooltip(false);
                                    }}
                                  >
                                    <Check
                                      className={cx(
                                        "course_rcmd__question__icon",
                                        theme.data
                                      )}
                                    />
                                    <span
                                      className={cx(
                                        "course_rcmd__desc",
                                        theme.data,
                                        "trial"
                                      )}
                                    >
                                      {text}
                                    </span>
                                    <div
                                      className={cx(
                                        "course_rcmd__question__tooltip__wrapper",
                                        `${
                                          showGoalTooltip &&
                                          value ===
                                            prequestionOfEnrolled.data?.goal
                                            ? "--active"
                                            : ""
                                        }`
                                      )}
                                    ></div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      )}

                      {enrolled.loaded &&
                        courseV2.loaded &&
                        isLastWeekBefore && (
                          <MainNPSFooter
                            isDesktop={isDesktop}
                            theme={theme.data}
                          >
                            <NPSNextBtn
                              isLastWeekBefore={isLastWeekBefore}
                              week={week}
                              roundWeeks={roundWeeks}
                              courseId={enrolled.data.course_id}
                              roundId={enrolled.data.round_id}
                              isNado={courseV2.data.is_nado}
                              enrolledId={enrolled_id}
                              isEvent={isEvent}
                              isTrial={isTrial}
                              rcmdScore={rcmdScore}
                              contentsScore={contentsScore}
                              contentsText={contentsText}
                              specialCareScore={specialCareScore}
                              specialCareText={specialCareText}
                              qnaScore={qnaScore}
                              qnaText={qnaText}
                              webpageScore={webpageScore}
                              webpageText={webpageText}
                            />
                          </MainNPSFooter>
                        )}
                    </section>
                  ) : (
                    <section className={cx("nps-page__content__body")}>
                      <img
                        className={cx("nps-page__content__body__num")}
                        alt={"num_circle"}
                        src={`/assets/icons/ic_circle_1.svg`}
                      />
                      <div
                        className={cx(
                          "nps-page__content__body__question",
                          "--isRadioGroup"
                        )}
                      >
                        <div
                          className={cx(
                            "nps-page__content__body__question__title",
                            "trial"
                          )}
                        >
                          이 수업을 친구나 동료에게 <br />
                          추천할 의향이 얼마나 되시나요?
                        </div>
                        <NPSScroller
                          setNpsScore={setNpsScore}
                          setIsRcmdVisible={setIsRcmdVisible}
                        />
                      </div>
                      <img
                        className={cx("nps-page__content__body__num")}
                        alt={"num_circle"}
                        src={`/assets/icons/ic_circle_2.svg`}
                      />
                      <div className={cx("nps-page__content__body__question")}>
                        <div
                          className={cx(
                            "nps-page__content__body__question__title",
                            "trial"
                          )}
                        >
                          어떤 점이 좋으셨어요?
                        </div>
                        <textarea
                          className={cx(
                            "nps-page__content__body__question__textarea"
                          )}
                          onChange={(e) => setGoodPoint(e.target.value)}
                          placeholder={
                            "만족한 부분에 대한 의견을 자유롭게 남겨주세요."
                          }
                          value={goodPoint}
                        />
                      </div>

                      <img
                        className={cx("nps-page__content__body__num")}
                        alt={"num_circle"}
                        src={`/assets/icons/ic_circle_3.svg`}
                      />
                      <div className={cx("nps-page__content__body__question")}>
                        <div
                          className={cx(
                            "nps-page__content__body__question__title",
                            "trial"
                          )}
                        >
                          어떤 점이 아쉬우셨나요?
                        </div>
                        <textarea
                          className={cx(
                            "nps-page__content__body__question__textarea"
                          )}
                          onChange={(e) => setBadPoint(e.target.value)}
                          placeholder={
                            "아쉬웠던 부분에 대한 의견을 자유롭게 남겨주세요."
                          }
                          value={badPoint}
                        />
                      </div>
                      <img
                        className={cx("nps-page__content__body__num")}
                        alt={"num_circle"}
                        src={`/assets/icons/ic_circle_4.svg`}
                      />
                      <div className={cx("nps-page__content__body__question")}>
                        <div
                          className={cx(
                            "nps-page__content__body__question__title",
                            "trial"
                          )}
                        >
                          수강 전 세웠던 목표는 <br />
                          여전히 동일하신가요?
                        </div>
                        <div
                          className={cx(
                            "nps-page__content__body__question__title__sub",
                            "trial"
                          )}
                        >
                          혹시 목표가 달라졌다면 선택해주세요.
                          <br />
                          목표에 따른 코딩 학습 루트를 추천해드릴게요.
                        </div>
                        <div
                          className={cx(
                            "nps-page__content__body__question__rcmd__wrapper"
                          )}
                        >
                          {prevPrequestions.map((selection) => {
                            const { value, text } = selection;
                            return (
                              <div
                                className={cx(
                                  "course_rcmd__question",
                                  `${value === goal ? "--selected" : ""}`
                                )}
                                onClick={() => {
                                  setGoal(value);
                                  setShowGoalTooltip(false);
                                }}
                              >
                                <Check
                                  className={cx("course_rcmd__question__icon")}
                                />
                                <span
                                  className={cx("course_rcmd__desc", "trial")}
                                >
                                  {text}
                                </span>
                                <div
                                  className={cx(
                                    "course_rcmd__question__tooltip__wrapper",
                                    `${
                                      showGoalTooltip &&
                                      value === prequestionOfEnrolled.data?.goal
                                        ? "--active"
                                        : ""
                                    }`
                                  )}
                                >
                                  <div
                                    className={cx(
                                      "course_rcmd__question__tooltip"
                                    )}
                                  >
                                    <img
                                      src={"/images/NPSPage/bg_tooltip.svg"}
                                    />
                                    <div
                                      className={cx(
                                        "course_rcmd__question__tooltip__text"
                                      )}
                                    >
                                      수강 전 선택했던 목표
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    roundWeeks,
    enrolled,
    courseV2,
    prequestionOfEnrolled,
    enrolledDetails,
  } = state;
  const { user } = authentication;
  return {
    user,
    roundWeeks,
    enrolled,
    courseV2,
    prequestionOfEnrolled,
    enrolledDetails,
  };
}

const connectedNPSPage = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getCourse: coursesActions.getCourse,
  getPrequestion: prequestionAction.getPreqOfEnrolled,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
})(NPSPage);
export { connectedNPSPage as NPSPage };
