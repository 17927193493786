import React from "react";
import {B2bNPSCheckContainer} from "../B2bNPSCheckContainer/B2bNPSCheckContainer";
import {B2bNPSTextContainer} from "../B2bNPSTextContainer/B2bNPSTextContainer";
import {B2bNPSRadioContainer} from "../B2bNPSRadioContainer/B2bNPSRadioContainer";

function B2bNpsQuestion({
                          index,
                          question,
                          handleAnswerChanged
                        }) {
  const {type, content, placeholder} = question
  return (<>{{
    radio: <B2bNPSRadioContainer question={content} index={index} handleChange={handleAnswerChanged}/>,
    check: <B2bNPSCheckContainer question={content} index={index} handleChange={handleAnswerChanged}/>,
    text: <B2bNPSTextContainer question={content} index={index} placeholder={placeholder} handleChange={handleAnswerChanged}/>,
  }[type]}</>)
}

export default B2bNpsQuestion