import { badgeService } from "../services/badge.service";
import { BADGE_CONSTANT } from "../constants/badge.constants";

function getBadgesForRound(round_id) {
  function request() {
    return { type: BADGE_CONSTANT.BADGES_ROUND_REQUEST };
  }

  function success(response) {
    return { type: BADGE_CONSTANT.BADGES_ROUND_SUCCESS, response };
  }

  function failure(error) {
    return { type: BADGE_CONSTANT.BADGES_ROUND_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    badgeService.getBadgesForRound(round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const badgeActions = {
  getBadgesForRound,
};
