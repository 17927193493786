import React, { useState } from "react";
import * as S from "./ErrorTypeSelector.style";

export const ContentsErrorSubmissionNotification = () => {
  return (
    <S.ContentsErrorSubmissionNotificationContainer>
      <S.ContentsErrorSubmissionNotificationImage src="/v2/assets/rtan/rtan_bow.png" />
      <S.ContentsErrorSubmissionNotificationTitle>
        오류 제보 완료!
      </S.ContentsErrorSubmissionNotificationTitle>
      <S.ContentsErrorSubmissionNotificationText>
        소중한 제보 감사합니다. <br />
        빠른 시일 내로 해결하겠습니다.
      </S.ContentsErrorSubmissionNotificationText>
    </S.ContentsErrorSubmissionNotificationContainer>
  );
};
