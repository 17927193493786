import React, {useState} from 'react';
import {connect} from "react-redux";
import classNames from "classnames/bind";
import styles from "./NavComponent.module.scss";


const cx = classNames.bind(styles);

function NavComponent({
                          summary,
                          header,
                          content,
                          cta,
                          pos,
                      }) {
    const [isShowDetail, setIsShowDetail] = useState(false);
    const {summary_icon, summary_text} = summary;
    const {header_title, is_hidden: is_header_hidden} = header;
    const {btn_text, handleCTAClick, is_hidden: is_cta_hidden} = cta;
    const posClass = `nav-component-detail--${pos}`;
    return (
        <div className={cx('nav-component-container')}
             onMouseOver={() => {
                 setIsShowDetail(true)
             }}
             onMouseLeave={() => {
                 setIsShowDetail(false)
             }}
        >
            <div className={cx('nav-component')}>
                <img className={cx('nav-component__img')} src={summary_icon}
                     alt=""/>
                <span className={cx('nav-component__text')}>{summary_text}</span>
            </div>
            {isShowDetail &&
            <div className={cx('nav-component-detail', posClass)}>
                {!is_header_hidden &&
                <div className={cx('nav-component-detail__header')}>
                    {header_title}
                </div>
                }
                <div className={cx('nav-component-detail__content')}>
                    {content}
                </div>
                {!is_cta_hidden &&
                <div className={cx('nav-component-detail__cta')}
                     onClick={handleCTAClick}>
                    {btn_text}
                </div>
                }
            </div>
            }
        </div>
    )
}

function mapStateToProps(state) {
    const {authentication, points, studyplans, enrolled, enrolleds,} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        points,
        studyplans,
        enrolled,
        enrolleds,
    }
}

const connectedNavComponent = connect(mapStateToProps, {
    // getEnrolleds: enrolledActions.getEnrolleds,
})(NavComponent);
export {connectedNavComponent as NavComponent}