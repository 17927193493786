import React from 'react'
import { connect } from 'react-redux'
import {Nav, NavEn} from '../_components'
import {
  getIsAuthenticated
} from '../_selectors'

const NavContainerEn = props => <NavEn {...props} />;

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { user } = authentication || {};

  return {
    user,
    isAuthenticated: getIsAuthenticated(state)
  }
};

const connectedNavContainerEn = connect(mapStateToProps)(NavContainerEn);
export { connectedNavContainerEn as NavContainerEn }
