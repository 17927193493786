import { alertConstants } from "../_constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR, task: "back" };
}

function warning(message) {
  return { type: alertConstants.WARNING, message };
}

export const alertActions = {
  success,
  error,
  clear,
  warning,
};

export const useAlert = (showTime) => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    const timer = setTimeout(clear(), showTime);
    return () => {
      clear();
      clearTimeout(timer);
    };
  }, [alert]);
  return {
    info() {
      dispatch(success());
    },
  };
};
