import { addDays } from "./cookie";
import { validateBirth } from "@teamsparta/auth-frontend";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function maskName(name) {
  if (!name || name.length <= 1) {
    return name;
  }
  if (name.length === 2) {
    return name[0] + "*";
  }
  return name[0] + "*".repeat(name.length - 2) + name[name.length - 1];
}

export const validateNameAndAlert = (name) => {
  if (name.length === 0 || name.match(/^ *$/) !== null) {
    alert("앗, 이름에 한글 또는 영어가 1글자 이상 포함되어야 합니다!");
    return false;
  } else if (
    name.match(/[~!@#$%^&*()_+|<>?:{}]/) ||
    name.match(
      /([^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\u0041-\u005A\u0061-\u007A])/g
    )
  ) {
    alert("앗, 이름에 특수문자는 포함할 수 없어요!");
    return false;
  }
  return true;
};

export const validateBirthAndAlert = (birth) => {
  const validateRes = validateBirth(birth);
  if (validateRes.ok) {
    return true;
  } else {
    alert(validateRes.msg);
    return false;
  }
};

export function validateEmail(email) {
  const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) && !korean.test(String(email));
}

export function validatePhone(phone) {
  const re = /^[0-9]{3}[-]+[0-9]{4}[-]+[0-9]{4,6}$/;
  return re.test(phone);
}

export function validatePhoneWCountryCode(phone) {
  const local = /^[0-9]{3}[-]+[0-9]{4}[-]+[0-9]{4,6}$/;
  const wCountyCode = /^[\+][0-9]{1,5}[-][0-9]{6,20}$/im;
  return local.test(phone) || wCountyCode.test(phone);
}

export function validateObjectId(objectId) {
  const re = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
  return re.test(objectId);
}

export function fillHyphen(_phone) {
  let phone = _phone.split("-").join("");
  if (phone.length < 4) {
  } else if (phone.length < 8) {
    phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
  } else if (phone.length < 11) {
    phone =
      phone.substr(0, 3) +
      "-" +
      phone.substr(3, 3) +
      "-" +
      phone.substr(6, phone.length);
  } else {
    phone =
      phone.substr(0, 3) +
      "-" +
      phone.substr(3, 4) +
      "-" +
      phone.substr(7, phone.length);
  }
  return phone;
}

export const getOriginalAge = (birthYear, birthMonth, birthDate) => {
  if (birthYear.length < 4 || birthMonth.length < 2 || birthDate.length < 2) {
    return Infinity;
  }
  const today = new Date();
  const birthDay = new Date(birthYear, birthMonth, birthDate);
  let age = today.getFullYear() - birthDay.getFullYear();
  let mon = today.getMonth() + 1 - birthDay.getMonth();
  if (mon < 0 || (mon === 0 && today.getDate() < birthDay.getDate())) {
    return age - 1; //생일이 안지났을 경우 1을 빼줍니다.
  } else {
    return age;
  }
};

export const getRef = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("ref");
};

export const getParamByKey = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const parseDate = (datetimeVar, days) => {
  let date_var = addDays(datetimeVar, days);
  let mon = date_var.getMonth() + 1;
  let day = date_var.getDate();
  return `${mon}월 ${day}일`;
};

export const largeTimeConvertor = (timeOfSeconds) => {
  let hour = Math.floor(timeOfSeconds / (60 * 60));
  let minute = Math.floor((timeOfSeconds - hour * 3600) / 60);
  if (hour === 0) return `총 ${minute}분`;
  if (hour >= 1) return `총 ${hour}시간 ${minute}분`;
};

export const lectureTimeConvertor = (playtime) => {
  let hours = Math.floor(playtime / 3600);
  let minutes = Math.floor((playtime - hours * 3600) / 60);
  let seconds = playtime - hours * 3600 - minutes * 60;

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  if (hours === "00") {
    return minutes + ":" + seconds;
  } else {
    return hours + ":" + minutes + ":" + seconds;
  }
};
