import { connect } from "react-redux";
import { userActions } from "../../../../../_actions";
import {
  copyToClipboard,
  shareKko,
} from "../../../../businesslogics/RcmdFreePage";
import { KakaoIcon, SharedIcon } from "../DashBoard/DashBoard.svg";
import * as S from "./Floating.style";

const Floating = ({ user }) => {
  return (
    <S.Container>
      <S.BtnContainer>
        <S.Btn>
          <S.BtnText
            onClick={() => {
              copyToClipboard(user, "친구 초대 링크 복사하기");
            }}
          >
            <SharedIcon />
            초대 링크 복사
          </S.BtnText>
        </S.Btn>
        <S.BtnYellow
          onClick={() => {
            shareKko(user, "카카오톡으로 공유하기");
          }}
        >
          <S.BtnTextColor>
            <KakaoIcon />
            카카오톡 공유
          </S.BtnTextColor>
        </S.BtnYellow>
      </S.BtnContainer>
    </S.Container>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedFloating = connect(mapStateToProps, {
  login: userActions.login,
  loginWithToken: userActions.loginWithToken,
})(Floating);

export { connectedFloating as Floating };
