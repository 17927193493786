import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./Timeclock.module.scss";
import { useInterval } from "../../../hooks/useInterval";

const cx = classNames.bind(styles);

function Timeclock({ style, endDate, type }) {
  const [day, setDay] = useState("00");
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const [second, setSecond] = useState("00");

  useEffect(() => {
    setDeadline();
  }, []);

  useInterval(setDeadline, 1000);

  function setDeadline() {
    const now = new Date();
    const deadline = new Date(endDate);
    const remainingTime = deadline - now;

    if (now >= deadline) {
      setDay(0);
      setHour(0);
      setMinute(0);
      setSecond(0);
      return;
    }

    const calculatedDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const calculatedHours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
    const calculatedMinutes =
      Math.floor((remainingTime / (1000 * 60)) % 60) || 0;
    const calculatedSeconds = Math.floor((remainingTime / 1000) % 60) || 0;

    setDay(fillNumber(calculatedDays));
    setHour(fillNumber(calculatedHours));
    setMinute(fillNumber(calculatedMinutes));
    setSecond(fillNumber(calculatedSeconds));
  }

  function fillNumber(num) {
    return num > 9 ? num : `0${num}`;
  }

  return (
    <>
      {type === "challenge" && (
        <div style={style}>
          {day}일 {hour}시간 {minute}분 {second}초
        </div>
      )}
      {type === "time" && (
        <div style={style}>
          {hour}시간 {minute}분 {second}초
        </div>
      )}
      {type === "clock" && (
        <div style={style}>
          {hour}:{minute}:{second}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedTimeclock = connect(mapStateToProps, {})(Timeclock);
export { connectedTimeclock as Timeclock };
