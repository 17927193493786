import { homeworkConstants } from "../_constants";
import { homeworkService } from "../_services";

function getHomeworkItem(roundId, homeworkId) {
  function request() {
    return { type: homeworkConstants.GET_HOMEWORK_ITEM_REQUEST };
  }

  function success(response) {
    return { type: homeworkConstants.GET_HOMEWORK_ITEM_SUCCESS, response };
  }

  function failure(error) {
    return { type: homeworkConstants.GET_HOMEWORK_ITEM_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    homeworkService.getHomeworkItem(roundId, homeworkId).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postHomeworkItem(courseId, homeworkId, details, final_review_url) {
  function request() {
    return { type: homeworkConstants.POST_HOMEWORK_ITEM_REQUEST };
  }

  function success(response) {
    return { type: homeworkConstants.POST_HOMEWORK_ITEM_SUCCESS, response };
  }

  function failure(error) {
    return { type: homeworkConstants.POST_HOMEWORK_ITEM_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    homeworkService
      .postHomeworkItem(courseId, homeworkId, details, final_review_url)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
}

export const homeworkActions = {
  getHomeworkItem,
  postHomeworkItem,
};
