import * as S from "./RecommendHanghaePM.style";
import { useSelector } from "react-redux";
import { logOnClickCourse } from "../../../hooks/finish/recommend";

const CourseOverviewContent = ({ category, value, detail }) => {
  return (
    <S.CourseOverviewContent>
      <S.CourseOverviewCategory>{category}</S.CourseOverviewCategory>
      <S.CourseOverviewValue>{value}</S.CourseOverviewValue>
      <S.CourseOverviewDetail>{detail}</S.CourseOverviewDetail>
    </S.CourseOverviewContent>
  );
};
const RecommendCourseBox = () => {
  const enrolled = useSelector((state) => state.enrolled);

  return (
    <S.RecommendCourseBox
      onClick={() => {
        const url = "https://hanghae99.spartacodingclub.kr/pm";
        window.open(url, "__blank");
        logOnClickCourse(enrolled, `항해 PM코스 랜딩`, url);
      }}
    >
      <S.RecommendCourseBoxTitle>
        <S.RecommendCourseBoxTitleImg
          src={`/assets/thumbnails/hanghae_title.png`}
        />
        <S.RecommendCourseBoxTitleText>
          <S.CourseDescript>직장, 학업과 병행하며 PM 취업!</S.CourseDescript>
          <S.CourseName>항해99 PM 코스</S.CourseName>
        </S.RecommendCourseBoxTitleText>
      </S.RecommendCourseBoxTitle>
      <S.Hr />
      <S.CourseOverview>
        <CourseOverviewContent
          category="모집 대상"
          value="PM 취업/이직 희망자"
        />
        <CourseOverviewContent category="진행 방식" value="온라인 (메타버스)" />
      </S.CourseOverview>
      <S.Cta>자세히 보기</S.Cta>
    </S.RecommendCourseBox>
  );
};

const RecommendHanghaePM = () => {
  const { user } = useSelector((state) => state.authentication);

  return (
    <S.Container>
      <S.Title>
        {user.name} 님께 추천드리는
        <br />
        PM 취업 코스
      </S.Title>
      <RecommendCourseBox />
    </S.Container>
  );
};

export default RecommendHanghaePM;
