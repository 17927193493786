/*
 *  Case에 break가 없는 것들은 그 다음 properties들까지 같이 가져가야 되기 때문
 *  예) 'scc_homework_completed' 는 'scc_homeworkPage_view' properties까지 수집하기 때문에 break문이 없음
 * */

export const RefineData = (action, data, extra) => {
  let refinedData = extra;
  // 대다수가 사용하는 기본 properties 삽입.
  insert_pageview_properties(refinedData, data);
  // console.log("refinedData2 : ", refinedData);
  switch (action) {
    case "scc_lecturePage_report_completed":
    case "scc_roadmapPage_click_button":
    case "scc_classroomPage_click_button":
    case "scc_rankPage_click_button": {
      Object.assign(refinedData, {
        button_text: data.button_text,
        button_name: data.button_name,
        next_page: data.next_page,
        details: data.details,
        type: data.type,
      });
      break;
    }

    case "scc_lecturePage_click_list": {
      Object.assign(refinedData, {
        button_text: data.title,
        button_name: data.button_name,
        button_status: data.button_status,
        next_week: data.next_week,
        next_week_order: data.next_week_order,
        is_playing: data.is_playing,
      });
      break;
    }

    case "scc_lecturePage_click_button": {
      Object.assign(refinedData, {
        button_name: data.button_name,
        button_text: data.button_text,
      });
      break;
    }

    case "scc_roadmapPage_click_list": {
      Object.assign(refinedData, {
        button_text: data.title,
        button_name: data.button_name,
      });
      break;
    }

    case "spc_paymentPage_view": {
      Object.assign(refinedData, {
        course_id: data.course_id,
        course_title: data.course?.title,
        course_created_date: data.course?.course_created_date,
        tags: data.course?.tags,
        level: data.course?.level,
        tutor: data.course?.tutor,
        round: data.course?.round,
        is_free: data.course?.is_trial,
        round_start_date: data.course?.enroll_start_date,
        round_end_date: data.course?.enroll_end_date,
        week_total: data.course?.weeks,
        price_display: data.price?.display_price,
        earlybird_title: data.price?.earlybird_title,
        earlybird_discount: data.price?.earlybird_discounted, // 얼마나 할인됐는지
      });
      break;
    }

    case "scc_paymentPage_view": {
      Object.assign(refinedData, {
        type: data.type,
        course_id: data.course_id,
        course_title: data.course?.title,
        course_created_date: data.course?.course_created_date,
        tags: data.course?.tags,
        level: data.course?.level,
        tutor: data.course?.tutor,
        round: data.course?.round,
        is_free: data.course?.is_trial,
        round_start_date: data.course?.enroll_start_date,
        round_end_date: data.course?.enroll_end_date,
        week_total: data.course?.weeks,
        display_price: data.price?.display_price,
        earlybird_title: data.price?.earlybird_title,
        earlybird_discount: data.price?.earlybird_discounted, // 얼마나 할인됐는지
      });
      break;
    }

    case "scc_paymentPage_startPay_click": {
      Object.assign(refinedData, data);
      break;
    }

    case "nb_purchase_completed":
    case "scc_purchase_completed":
      Object.assign(refinedData, data);
      break;

    case "scc_prequestionPage_view": {
      let {
        course: {
          _id: course_id,
          title: course_title,
          created_at: course_created_date,
          days,
          tags,
          level,
        },
        round: {
          round,
          start_date: round_start_date,
          end_date: round_end_date,
          created_at,
          display_price,
        },
        order: { status },
        others: { tutor },
      } = data;
      Object.assign(refinedData, {
        course_id,
        course_title,
        tags,
        tutor,
        level,
        status,
        course_created_date,
        round,
        round_start_date,
        round_end_date,
        week_total: days / 7,
        order: null, // 밑에서 추가해주는 order은 course내 강의 순서 이기때문에 여기선 뺴주자
      });
      break;
    }

    case "scc_rcmdSurveyPage_view": {
      Object.assign(refinedData, {
        enrolled_id: data?.enrolled_id,
        course_id: data?.course_id,
        course_title: data?.courses?.title,
        round: data?.rounds?.round,
        round_id: data?.round_id,
        tags: data?.courses?.tags,
        tutor: data?.tutors?.name,
        week: data?.courses.days / 7,
      });
      break;
    }

    case "nb_prequestion_completed":
    case "scc_prequestion_completed": {
      Object.assign(refinedData, {
        ...data,
      });
      break;
    }

    case "scc_lecture_completed": {
      Object.assign(refinedData, {
        progress: data.progress,
        subtitle_status: data.subtitle_status,
        speed_value: data.speed_value,
        ...get_progress_ontrack(
          data.start_date,
          data.courses?.days,
          data.progress
        ),
        done: true,
        done_week: get_done_week(data.start_date, new Date()),
      });
      break;
    }

    case "scc_homework_completed": {
      let submitted_answer = data.submitted_answer?.[0]?.submitted_answer;
      Object.assign(refinedData, {
        progress: data.progress,
        ...get_progress_ontrack(
          data.start_date,
          data.courses?.days,
          data.progress
        ),
        done: true,
        done_date: data.enrolleds_homework?.done_first_date,
        //수업이 시작하고 몇주차 때 숙제 했는지, 0주차 부터 시작
        done_week: get_done_week(
          data.start_date,
          data.enrolleds_homework?.done_first_date
        ),
        submitted_answer: submitted_answer,
      });
    }

    case "scc_homeworkPage_view": {
      Object.assign(refinedData, {
        week: data.homework?.week,
        homework_id: data.homework?._id,
        homework_title: data.homework?.title,
        homework_order: data.homework?.order,
      });
      break;
    }

    case "scc_wil_completed": {
      Object.assign(refinedData, {
        progress: data.progress,
        ...get_progress_ontrack(
          data.start_date,
          data.courses?.days,
          data.progress
        ),
        submitted_wil: data.url,
        done: data.done,
        done_date: data.done_first_date,
        done_week: get_done_week(data.start_date, data.done_first_date),
      });
      break;
    }

    case "scc_nps_completed": {
      Object.assign(refinedData, { ...data });
      break;
    }

    case "scc_course_completed": {
      let hw_done_date = data.weeks?.at(-1)?.homework?.done_first_date;
      let lecture_done_date = data.weeks
        ?.at(-1)
        ?.enrolled_details?.at(-1)?.done_date;
      let done_date = hw_done_date ? hw_done_date : lecture_done_date;

      Object.assign(refinedData, {
        done: true,
        done_date: done_date,
        done_week: get_done_week(data.start_date, done_date),
      });
      break;
    }
    //
    case "scc_rcmdSurvey_completed": {
      let {
        enrolledSurvey: { first_vote, vote_log },
      } = data;
      if (vote_log) vote_log.push(data.selectedValue);
      Object.assign(refinedData, {
        first_vote: first_vote || data.selectedValue,
        vote_log: vote_log || [data.selectedValue],
      });
      break;
    }

    case "scc_roadmapPage_click_banner": {
      Object.assign(refinedData, data);
      break;
    }

    case "scc_courseRcmdPage_click_button": {
      Object.assign(refinedData, data);
      break;
    }

    case "scc_courseRcmdPage_view": {
      Object.assign(refinedData, data);
      break;
    }

    case "scc_prequestionPage_completed": {
      Object.assign(refinedData, data);
      break;
    }

    case "hh_orientation_scc": {
      Object.assign(refinedData, {
        online_user_id: data.online_user_id,
        name: data.name,
        email: data.email,
        phone: data.phone,
      });
      break;
    }
    default:
      break;
  }
  return refinedData;
};

const insert_pageview_properties = (refineData, inputData) => {
  Object.assign(refineData, {
    enrolled_id: inputData.enrolled_id,
    course_id: inputData.course_id,
    course_title: inputData.courses?.title,
    round: inputData.rounds?.round,
    round_id: inputData.round_id,
    tags: inputData.courses?.tags,
    tutor: inputData.tutors?.name,
    week: inputData.week, // 주차
    week_order: inputData.week_order, // 주차 내 강의 순서
    // week_total: inputData.courses?.days && inputData.courses?.days / 7, // 총 몇주
    brand: get_brand(inputData), // 온라인, 내일배움단, 항해, 창

    // lecture page
    order: inputData.order, // course 내 강의 순서
    playtime: inputData.playtime, // 영상 길이
    // 수업이 시작하고 몇주차 때 해당 강의를 처음 들었는지, 0주차 부터 시작
    done_week: get_done_week(inputData.start_date, inputData.done_date),

    // cta click
    button_text: inputData.button_text,

    // login, signup
    method: inputData.method,
  });
  return;
};

// Util 함수들
// Brand (온라인, 내일배움단, 항해, 창)
const get_brand = (inputData) => {
  if (!inputData.courses || !inputData.rounds) return;
  if (inputData.courses?.is_trial) return "trial";
  if (inputData.courses?.is_gov || inputData.courses?.is_gov_trial)
    return "gov";
  if (inputData.rounds?.is_b2b) return "b2b";
  if (inputData.rounds?.is_chang || inputData.rounds?.is_chang_lite)
    return "chang";
  if (inputData.rounds?.is_hanghae) return "hanghae";
  return "online";
};

// 사전설문 페이지에서 생년월일 961118 이렇게 데이터 받아오는거 처리
const get_prequestion_age = (birth_date) => {
  if (birth_date == null) return;
  if (birth_date.length != 6) return; // input이 년/월/일 이렇게 6자리가 아니라면 안함

  let year = parseInt(birth_date.slice(0, 2));
  let month = parseInt(birth_date.slice(2, 4));
  let day = parseInt(birth_date.slice(4, 6));
  let current = new Date();
  if (current.getFullYear() % 100 < year) year += 1900;
  // 1996 년생
  else year += 2000; // 2006 년생
  // Date 함수 month는 0 ~ 11까지
  return Math.floor(
    (current - new Date(year, month - 1, day)) / (1000 * 3600 * 24 * 365)
  );
};

// 강의 시청, 숙제, wil 을 처음 완료한 주차
const get_done_week = (round_start_date, done_first_date) => {
  if (!done_first_date) return;
  return (
    1 +
    Math.floor(
      (new Date(done_first_date) - new Date(round_start_date)) /
        (1000 * 3600 * 24 * 7)
    )
  );
};

// 권장 진도율 구하기   (강의 시작하고 지난 날짜 / 강의 일 수) * 100 (%)
const get_progress_ontrack = (
  round_start_date,
  course_total_days,
  current_progress
) => {
  if (!round_start_date || !course_total_days) return;

  let days_passed =
    (new Date() - new Date(round_start_date)) / (1000 * 3600 * 24);
  let progress_ontrack = Math.floor((100 * days_passed) / course_total_days);
  let progress_status = "";
  if (current_progress - progress_ontrack > 10) progress_status = "fast";
  else if (current_progress - progress_ontrack < -10) progress_status = "slow";
  else progress_status = "ontrack";
  return {
    progress_ontrack: Math.max(progress_ontrack, 0), // 0주차 일때는 권장 진도율 0으로
    progress_status,
  };
};
