import { rcmdDashboardConstants } from "../_constants";
const initState = {
  loaded: false,
  data: [],
};
export function rcmdDashboards(state = initState, action) {
  switch (action.type) {
    case rcmdDashboardConstants.RCMD_DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case rcmdDashboardConstants.RCMD_DASHBOARD_SUCCESS:
      console.log(action);
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.dashboard_data,
      });
    case rcmdDashboardConstants.RCMD_DASHBOARD_FAILURE:
      alert(action.error);
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
