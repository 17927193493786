import { friendRecoConstants } from "../_constants";
import { friendRecoService } from "../_services";

function getFriendRecoInfo() {
  function request() {
    return { type: friendRecoConstants.FRIEND_RECO_INFO_REQUEST };
  }

  function success(response) {
    return { type: friendRecoConstants.FRIEND_RECO_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: friendRecoConstants.FRIEND_RECO_INFO_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    friendRecoService.getFriendRecoInfo().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getFriendRecoChart() {
  function request() {
    return { type: friendRecoConstants.FRIEND_RECO_TOP_REQUEST };
  }

  function success(response) {
    return { type: friendRecoConstants.FRIEND_RECO_TOP_SUCCESS, response };
  }

  function failure(error) {
    return { type: friendRecoConstants.FRIEND_RECO_TOP_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    friendRecoService.getFriendRecoChart().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getFriendRecoFreeChart() {
  function request() {
    return { type: friendRecoConstants.FRIEND_RECO_FREE_CART_REQUEST };
  }

  function success(response) {
    return {
      type: friendRecoConstants.FRIEND_RECO_FREE_CART_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return { type: friendRecoConstants.FRIEND_RECO_FREE_CART_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    friendRecoService.getFriendRecoFreeChart().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const friendRecoActions = {
  getFriendRecoInfo,
  getFriendRecoChart,
  getFriendRecoFreeChart,
};
