import * as S from "../HomeworkQuestion.style";
import React from "react";

export const ShortFormQuestion = ({ description }) => {
  const noDescription = description === "" || description === null;
  return (
    <S.InstructionAndAnswerWrapper noDescription={noDescription}>
      <S.InstructionContainer>
        <S.InstructionTitleBox>[ 지시사항 ]</S.InstructionTitleBox>
        <S.QuestionContainer>{description}</S.QuestionContainer>
      </S.InstructionContainer>
    </S.InstructionAndAnswerWrapper>
  );
};
