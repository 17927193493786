import * as S from "./KDCHomeworkNoticeToastMessage.style";
import { useEffect, useState } from "react";
import { NavigationClose, neutralDay } from "@teamsparta/design-system";

export const KDCHomeworkNoticeToastMessage = ({
  isOpenList,
  isFeedbackRequired,
}) => {
  const [count, setCount] = useState(10);

  useEffect(() => {
    if (count === 0) return;

    const timer = setTimeout(() => {
      setCount(count - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [count]);

  return (
    <S.ToastWrapper
      isOpenList={isOpenList}
      isShow={isFeedbackRequired && count !== 0}
    >
      <S.CountDownWrapper>
        <S.CircleContainer>
          <svg width="24" height="24">
            <S.CircleBackground cx="12" cy="12" r="11" />
            <S.CircleProgress cx="12" cy="12" r="11" percentage={count} />
            <S.CountDownText x="12" y="12">
              {count}
            </S.CountDownText>
          </svg>
        </S.CircleContainer>
        <S.ToastMessage>
          숙제 피드백 시스템이 적용되는 과목은{" "}
          <span>
            관련 없는 내용으로 제출하거나 불성실하게 제출 할 경우 숙제가 반려
          </span>{" "}
          될 수 있습니다.
        </S.ToastMessage>
      </S.CountDownWrapper>
      <S.CloseButton onClick={() => setCount(0)}>
        <NavigationClose size={16} color={`${neutralDay.gray70}`} />
      </S.CloseButton>
    </S.ToastWrapper>
  );
};
