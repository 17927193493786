import React, { useEffect, useState } from "react";
import { params } from "../../../_helpers";
import {
  postEnrolledFinish,
  useEnrolledFinishSummary,
} from "../../queries/nps";
import * as S from "./freeFinishPage.style";
import FreeFinishPagePresent from "./Present/Present";
import FreeFinishPageRecommend from "./Recommend/Recommend";
import { useDispatch, useSelector } from "react-redux";
import { useGetEnrolled } from "../../_state/actions";
import { useLogFinishPageView } from "../../hooks/finish";
import RecommendNBCamp from "./RecommendNBCamp/RecommendNBCamp";
import BenefitsOverview from "./BenefitsOverview/BenefitsOverview";
import {
  devCareerPrepCoursesList,
  recommendRebootCourseList,
  recommendSkillUpCourseList,
} from "./variables";
import RecommendHanghaePM from "./RecommendHanghaePM/RecommendHanghaePM";
import FinishRecommend from "./Recommend/FinishRecommend";

export const RecommendNBCCourse = () => {
  return (
    <S.Container>
      <RecommendNBCamp />
      <BenefitsOverview course="NBC_DEVELOPER" />
    </S.Container>
  );
};

export const RecommendPmCourse = () => {
  return (
    <S.Container>
      <RecommendHanghaePM />
      <BenefitsOverview course="HANGHAE_PM" />
    </S.Container>
  );
};

export const RebootCourse = () => {
  return (
    <S.Container>
      <FinishRecommend course="REBOOT" />
      <BenefitsOverview course="REBOOT" />
    </S.Container>
  );
};

export const SkillUpCourse = () => {
  return (
    <S.Container>
      <FinishRecommend course="SKILL_UP" />
      <BenefitsOverview course="SKILL_UP" />
    </S.Container>
  );
};

export const FinishPresent = ({ nextStep }) => {
  return (
    <S.Container>
      <FreeFinishPagePresent />
      <FreeFinishPageRecommend nextStep={nextStep} />
    </S.Container>
  );
};
const FreeFinishPage = () => {
  const { enrolled_id } = params();
  const enrolled = useSelector((state) => state.enrolled);
  const getEnrolled = useGetEnrolled(enrolled_id);
  const dispatch = useDispatch();
  const course_id = enrolled?.data?.course_id;

  const finishSummary = useEnrolledFinishSummary(enrolled_id);
  const [nextStep, setNextStep] = useState(null);
  const [showFinishPage, setShowFinishPage] = useState("default");

  useLogFinishPageView(enrolled_id);
  // scroll up to top when first mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!enrolled.loaded) {
      try {
        getEnrolled(dispatch);
      } catch (error) {}
    }
  }, [enrolled.loaded]);

  useEffect(() => {
    if (finishSummary.isSuccess) {
      setNextStep(finishSummary.data?.next_step);
      checkShowFinishPage();
    } else {
      checkShowFinishPage();
    }
  }, [finishSummary]);

  useEffect(() => {
    if (enrolled_id) {
      postEnrolledFinish(enrolled_id);
    }
  }, [enrolled_id]);

  const checkShowFinishPage = () => {
    const showRecommendCourseConditions = [
      devCareerPrepCoursesList.includes(course_id),
      nextStep === "hanghae",
    ];
    if (recommendRebootCourseList.includes(course_id)) {
      return setShowFinishPage("recommendReboot");
    }

    if (recommendSkillUpCourseList.includes(course_id)) {
      return setShowFinishPage("recommendSkillUp");
    }

    if (showRecommendCourseConditions.some(Boolean)) {
      return setShowFinishPage("recommendNBCCourse");
    }

    setShowFinishPage("FinishPresent");
  };

  switch (showFinishPage) {
    case "FinishPresent":
      return <FinishPresent nextStep={nextStep} />;
    case "recommendNBCCourse":
      return <RecommendNBCCourse />;
    case "recommendPmCourse":
      return <RecommendPmCourse />;
    case "recommendReboot":
      return <RebootCourse />;
    case "recommendSkillUp":
      return <SkillUpCourse />;
    default:
      return <div />;
  }
};

export default FreeFinishPage;
