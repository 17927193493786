import { PAYMENT_CONSTANT } from "../constants";

const initState = {
  loaded: false,
};

export function paymentOption(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.OPTION_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.OPTION_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.OPTION_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentReinforced(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.REINFORCED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.REINFORCED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.REINFORCED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentReinforcedByUser(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.REINFORCED_BY_USER_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.REINFORCED_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.REINFORCED_BY_USER_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentAlert(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.ALERT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.ALERT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.ALERT_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function isPaymentCounselTarget(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.GET_COUNSEL_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.GET_COUNSEL_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.GET_COUNSEL_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentCounselResult(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.POST_COUNSEL_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.POST_COUNSEL_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.POST_COUNSEL_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function isNomadCouponTarget(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.GET_COUPON_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.GET_COUPON_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.GET_COUPON_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function nomadCouponResult(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.POST_COUPON_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.POST_COUPON_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.POST_COUPON_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentReviews(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.GET_REVIEWS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.GET_REVIEWS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.GET_REVIEWS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentVBank(state = initState, action) {
  switch (action.type) {
    case PAYMENT_CONSTANT.GET_VBANK_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PAYMENT_CONSTANT.GET_VBANK_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PAYMENT_CONSTANT.GET_VBANK_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
