import privateInstance from "../../_helper/axios";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
import { getDuration } from "../../hooks/useVisibilityLog";
import { useSelector } from "react-redux";
import axios from "axios";
import { config } from "../../../config";

export const getLastSeenDetailId = async (enrolledId) => {
  return await privateInstance
    .get(`/enrolleds/${enrolledId}/lastseen`)
    .then((resp) => resp.data.context);
};

export const useLastSeenData = (enrolledId) => {
  return useQuery(
    ["LastSeenDetailId", enrolledId],
    () => {
      return getLastSeenDetailId(enrolledId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!enrolledId,
    }
  );
};

export const usePostLastSeenDetailId = (
  enrolledId,
  detailId,
  userId,
  isHomework
) => {
  return useMutation(
    `postLastSeenDetailId`,
    () => {
      return privateInstance.post(`/enrolleds/${enrolledId}/lastseen`, {
        detail_id: detailId,
        is_homework: isHomework,
        user_id: userId,
        current_time: new Date().toISOString(),
      });
    },
    {
      onSuccess: (data) => {},
      onError: (error) => {
        console.debug("👎 [post last seen detail id] ", error);
      },
    }
  );
};

export const useCheckExpiredEnrolled = (match) => {
  const enrolledIdFromUrl = match.params.enrolled_id;
  useEffect(() => {
    if (enrolledIdFromUrl) {
      privateInstance
        .get(`/enrolleds/${enrolledIdFromUrl}/expiration`)
        .then((resp) => {
          const expiration = resp.data.context;
          if (expiration) {
            alert("수강 기간이 만료되었습니다.");
            window.location.href = `/`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [enrolledIdFromUrl]);
};

/** 이어서 수강하기 버튼을 위한 로그를 일정한 간격으로 날리기 위한 커스텀 훅.
 * LecturePage, HomeworkPage 에서 사용한다.*/
export const useTrackLastSeenLecture = (
  match,
  user,
  pageType,
  startTimeRef
) => {
  const enrolled = useSelector((state) => state.enrolled);

  const { enrolled_id, detail_id, enrolled_homework_id } = match.params;
  const isHomework = pageType === "homework";
  const userId = user._id;
  const postLastSeenDetailIdMutation = usePostLastSeenDetailId(
    enrolled_id,
    isHomework ? enrolled_homework_id : detail_id,
    userId,
    isHomework
  );
  const recordEnterpriseAttendanceDuration =
    useRecordEnterpriseAttendanceDuration();

  /** Initial logging on page mount*/
  useEffect(() => {
    if (user._id && match.params?.enrolled_id) {
      postLastSeenDetailIdMutation.mutate();
    }
  }, [match, user]);
  /** Periodic logging on page */
  useEffect(() => {
    const interval = setInterval(() => {
      postLastSeenDetailIdMutation.mutate();
      recordEnterpriseAttendanceDuration.mutate({
        roundId: enrolled.data?.round_id,
        onlineUserId: userId,
        duration: getDuration(startTimeRef),
      });
    }, 30000);

    return () => clearInterval(interval);
  }, [detail_id, enrolled_id]);
};

const recordEnterpriseAttendanceDuration = async (
  roundId,
  onlineUserId,
  duration
) => {
  const response = await axios.post(
    `${config.enterpriseApiUrl}/attendances/duration`,
    {
      roundId: roundId,
      onlineUserId: onlineUserId,
      duration: duration,
    }
  );
  return response.data;
};

export const useRecordEnterpriseAttendanceDuration = () => {
  const enrolled = useSelector((state) => state.enrolled);
  const isB2B = enrolled?.data?.rounds["is_b2b"];

  return useMutation(
    "recordEnterpriseAttendanceDuration",
    ({ roundId, onlineUserId, duration }) => {
      if (isB2B) {
        return recordEnterpriseAttendanceDuration(
          roundId,
          onlineUserId,
          duration
        );
      }
      return Promise.resolve();
    },
    {
      onSuccess: (data) => {},
      onError: (error) => {
        console.debug("👎 [recordEnterpriseAttendanceDuration] ", error);
      },
      retry: (failureCount, error) => {
        const hostname = window.location.hostname;
        if (hostname === "localhost" || hostname === "127.0.0.1") {
          return false;
        }
        return failureCount < 3;
      },
    }
  );
};

const getLectureV2 = async (lecture_id) => {
  return await privateInstance
    .get(`/lecture/v2/${lecture_id}`)
    .then((resp) => resp.data);
};

export const useGetLectureV2 = (lectureId) => {
  return useQuery(["lecture", lectureId], () => getLectureV2(lectureId), {
    enabled: !!lectureId,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
