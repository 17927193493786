import styled from "@emotion/styled";
import { mBody4, wBody4 } from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const SubText = styled.div`
  ${mBody4};
  font-size: 14px;
  font-weight: 500;

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody4};
  }
`;
