import { useEffect, useState, useRef } from "react";
import { DashBoard } from "../DashBoard/DashBoard";
import { Floating } from "../Floating/Floating";
import * as S from "./Hero.style";

export const Hero = () => {
  const [isShowFloating, setIsShowFloating] = useState(false);
  const ObserveRef = useRef(null);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        setIsShowFloating(false);
      } else {
        setIsShowFloating(true);
      }
    });
    observer.observe(ObserveRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <S.Container>
      <S.HeroHeadContainer>
        <S.TitleContainer>
          <S.SubTitle>친구초대 이벤트</S.SubTitle>
          <S.Title>
            친구랑 무료 코딩강의 듣고
            <br /> 월간코딩 쿠폰 받아가세요!
          </S.Title>
        </S.TitleContainer>
        <S.HeroImage src="/v2/assets/images/rcmd/rtan_coupon.png" />
      </S.HeroHeadContainer>
      <S.DashboardMobileWrapper ref={ObserveRef}>
        <DashBoard />
      </S.DashboardMobileWrapper>
      {isShowFloating && <Floating />}
    </S.Container>
  );
};
