import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mHeader1,
  neutralDay,
  scc,
  wBody2,
  wHeader2,
} from "@teamsparta/design-system";
import { Device } from "../../../../_styles/device";
import { keyframes } from "@emotion/css";
import { fontPretendard } from "../../../styles/font";

const popOutAnimation = keyframes`
  0% {
    transform: scale(0.85);
  }
    100% {
    transform: scale(1);
    }
`;

const shineMove = keyframes`
    0% {
      right: -20%;
    }
    100% {
        right: 120%;
    }
`;

export const Container = styled.div`
  background: ${scc.red10};
  padding: 60px 16px;
  ${Device.Desktop} {
    padding: 100px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Toast = styled.div`
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  z-index: 1;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 41px;
  background: rgba(58, 62, 65, 0.8);
  box-shadow: 0 8px 24px rgba(129, 137, 143, 0.12);
  border-radius: 100px;
  font-family: ${fontPretendard};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${neutralDay.white};
`;

export const Title = styled.div`
  ${mHeader1};
  color: ${neutralDay.gray100};
  margin-bottom: 12px;
  ${Device.Desktop} {
    text-align: center;
    ${wHeader2};
    color: ${neutralDay.gray100};
    margin-bottom: 16px;
  }
`;

export const SubTitle = styled.div`
  ${mBody2};
  color: ${neutralDay.gray80};
  margin-bottom: 40px;
  ${Device.Desktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
    margin-bottom: 60px;
  }
`;

export const Coupons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: fit-content;
  margin: auto;
  ${Device.Desktop} {
    flex-direction: row;
    justify-content: center;
    width: 670px;
    flex-wrap: wrap;
  }
`;

export const Rtan = styled.img`
  position: absolute;
  height: 68px;
  right: 0;
  top: -68px;
  ${Device.Desktop} {
  }
`;

export const Coupon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  width: 328px;
  height: 158px;
  background: ${neutralDay.white};
  border: 1px solid ${scc.red25};
  box-shadow: 0 8px 24px rgba(95, 102, 107, 0.12);
  border-radius: 12px;
  ${Device.Desktop} {
  }
`;

export const SpecialCoupon = styled(Coupon)`
  justify-content: space-between;
  position: relative;
  border-radius: 12px;
  border: 1px solid rgba(20, 22, 23, 0.2);
  //background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 0px 60px 0px rgba(187, 190, 0, 0.6);
  background-image: url("/v2/assets/images/rcmd/special_coupon_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 60px auto 20px;
  animation: ${popOutAnimation} 0.5s ease-in-out;
`;

export const SpecialCouponWrapper = styled.div`
  width: auto;
  ${Device.Desktop} {
    width: 670px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CouponInfoContainer = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${Device.Desktop} {
  }
`;

export const CouponContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${Device.Desktop} {
  }
`;

export const CouponName = styled.div`
  ${mBody1};
  color: ${scc.red100};
  white-space: nowrap;
  ${Device.Desktop} {
  }
`;

export const SpecialCouponName = styled(CouponName)`
  color: ${neutralDay.white};
`;

export const CouponAmount = styled.div`
  font-family: ${fontPretendard};
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
  color: ${scc.red100};
  ${Device.Desktop} {
  }
`;

export const SpecialCouponAmount = styled(CouponAmount)`
  color: ${neutralDay.white};
`;

export const CouponNotices = styled.div`
  ${Device.Desktop} {
  }
`;

export const CouponNotice = styled.div`
  font-family: ${fontPretendard};
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${neutralDay.gray70};
  ${Device.Desktop} {
  }
`;

export const SpecialCouponNotice = styled(CouponNotice)`
  color: ${neutralDay.white};
`;

export const CouponCutLine = styled.hr`
  height: 100%;
  border-left: 1px dashed ${scc.red25};
  margin: 0 24px 0 40px;
  ${Device.Desktop} {
  }
`;

export const SpecialCouponCutLine = styled(CouponCutLine)`
  background: rgba(20, 22, 23, 0.3);
  height: 150px;
  position: absolute;
  margin: 0;
  right: 95px;
  top: 0;
`;

export const CouponButton = styled.div`
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${scc.red100};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  ${Device.Desktop} {
  }
`;

export const CouponRightBlur = styled.div`
  position: absolute;
  width: 95px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  right: 0;
  top: 0;
  border-radius: 0 12px 12px 0;
`;

export const SpecialCouponBadge = styled.img`
  position: absolute;
  width: 80px;
  right: 80px;
  top: -14px;
  z-index: 1;
`;

export const SpecialCouponTooltip = styled.img`
  position: absolute;
  width: 240px;
  right: 50%;
  transform: translateX(50%);
  top: -64px;
`;

export const SpecialCouponShine = styled.div`
  position: absolute;
  width: 37px;
  height: 222px;
  transform: rotate(-20deg);
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.4);
  filter: blur(15px);
  right: 0;
  animation: ${shineMove} 3s infinite;
`;

export const ShineWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;
