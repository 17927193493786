import { retrospectiveService } from "../_services/retrospective.service";
import { retrospectiveConstants } from "../_constants/retrospective.constants";

function getRetrospective(enrolled_id) {
  function request() {
    return { type: retrospectiveConstants.RETROSPECTIVE_REQUEST };
  }

  function success(response) {
    return { type: retrospectiveConstants.RETROSPECTIVE_SUCCESS, response };
  }

  function failure(error) {
    return { type: retrospectiveConstants.RETROSPECTIVE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    retrospectiveService.getRetrospective(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const retrospectiveActions = {
  getRetrospective,
};
