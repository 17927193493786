import privateInstance from "../../_helper/axios";

async function getStudyObjective(enrolled_id) {
  return await privateInstance
    .get(`/objective/${enrolled_id}`)
    .then((resp) => resp.data);
}

async function updateStudyObjective(enrolled_id, data) {
  return await privateInstance
    .post(`/objective/${enrolled_id}/update`, data)
    .then((resp) => resp.data);
}

async function postStudyObjective(enrolled_id, data) {
  return await privateInstance
    .post(`/objective/${enrolled_id}`, data)
    .then((resp) => resp.data);
}
export const objectiveService = {
  getStudyObjective,
  postStudyObjective,
  updateStudyObjective,
};
