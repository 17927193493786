import instance from "../../../_helpers/axios";
import privateInstance from "../../_helper/axios";
import { cookieToObject, getCookie, setCookie } from "../../_helper";
import { config } from "../../../config";
import { RefineData } from "./refineData";
import axios from "axios";
import * as CPL from "@teamsparta/cross-platform-logger";

const FLOUNDER_KEY = config.flounder.cognitoKey;
const AMPLITUDE_KEY = config.amplitude.amplitude_key;
// const MIXPANEL_TOKEN = config.mixpanel.mixpanel_token;
const HACKLE_SDK_KEY = config.hackle.key;
const IS_DEV = !config.production;

CPL.initCPLog(FLOUNDER_KEY, AMPLITUDE_KEY, HACKLE_SDK_KEY, IS_DEV);

const DRM_URI = "https://drm.spartacodingclub.kr";
const bufferingLogInstance = axios.create({
  baseURL: DRM_URI,
  timeout: 100000,
  headers: Object.assign({ "Content-Type": "application/json" }),
});

const URL_NATION =
  "https://pro.ip-api.com/json/?fields=status,message,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,mobile,query&key=FVaMw6OLSQKC0LD";
export const URL_LOG =
  "https://3o88d0p0tj.execute-api.ap-northeast-2.amazonaws.com/api_online_user_log";

export const USER_ACTIONS = {
  // 체크인
  CREATE_CHECKIN: "CREATE_CHECKIN",
  LIKE_CHECKIN: "LIKE_CHECKIN",

  // 다음 강의로 가기
  GO_LECTURE: "GO_LECTURE",

  // 숙제 제출
  SUBMIT_HOMEWORK: "SUBMIT_HOMEWORK",

  // 강의 완강!
  FINISH_COURSE: "FINISH_COURSE",
};

export const USER_PAGES = {
  PAGE_CHECKIN: "PAGE_CHECKIN",
  PAGE_ROADMAP: "PAGE_ROADMAP",
  PAGE_LECTURE: "PAGE_LECTURE",
  PAGE_LECTURENAV: "PAGE_LECTURENAV",
  PAGE_HOMEWORK: "PAGE_HOMEWORK",
  PAGE_FINISH: "PAGE_FINISH",
  PAGE_MYPAGE: "PAGE_MYPAGE",
  PAGE_NPS: "PAGE_NPS",
};

export const NBCAMP_COURSE_IDS = [
  "652bea6b08647a82bb62eb54", // 앱 개발의 모든 것, 안드로이드 vs iOS
  "64e32bb1fd878f27f5acae5f", // [무료] 1시간 만에 정복하는 코딩테스트 합격법
  "6535d721ceb87cbda112900a", // 디자이너 취업 포트폴리오의 모든 것
  "65369d38ceb87cbda112e69a", // 취업, 이직을 위한 데이터분석의 모든 것
  "625963fa9d5b4ee7f14b61d9", // 개발자 취업 준비의 모든 것
  "6551b81ab4c831ca481ae7d3", // 개발자 취업시장에서 살아남기 : 웹 개발자 편
];

export const SendBufferingLog = (action, data) => {
  if (
    action === "lecture_buffered_s3_hls" ||
    action === "lecture_buffered_s3"
  ) {
    bufferingLogInstance.post("/logs", data).catch((error) => {
      console.log(error);
    });
  }
};

const getUser = () => {
  return cookieToObject(getCookie("userinfo"));
};

export const setCountry = async () => {
  if (getCookie("country")) {
    return;
  }

  await instance.get(URL_NATION).then((resp) => {
    setCookie("country", resp.data.country, 30);
  });
};

const throttlePool = {};
/**
 * eventID 항목은 유명무실하기 때문에 빈 문자열만 넣어도 괜찮습니다.
 * TODO: 빈 문자열도 안넣어도 되도록 감싸야함.*/
export const sendLog = async (
  action,
  data,
  eventID = "",
  noRefine = false,
  extra = {},
  is_gtm = false
) => {
  if (!throttlePool[action]) {
    throttlePool[action] = true;
    let refinedData = RefineData(action, data, extra);
    CPL.sendCPLog(action, refinedData, is_gtm);
    setTimeout(() => {
      throttlePool[action] = false;
    }, 100);
  } else {
    console.log(`%c[DEBUG] duplicate send log`, `color: #AAAAAA`);
  }
};

export const sendCustomDataLog = async (action, data) => {
  if (!throttlePool[action]) {
    throttlePool[action] = true;
    CPL.sendCPLog(action, data);
    setTimeout(() => {
      throttlePool[action] = false;
    }, 100);
  } else {
    console.log(`%c[DEBUG] duplicate send log`, `color: #AAAAAA`);
  }
};

export const sendUserAction = async (
  action,
  page,
  coll = "actions_after_payments"
) => {
  let ua = window.navigator.userAgent;
  ua = ua.toLowerCase();
  let matched = {};

  const device_id = getCookie("device_id");
  const country = getCookie("country");
  const referrer = "";
  const url = document.location.href;
  if (!url.includes("online.spartacodingclub.kr")) {
    return;
  }
  const user_id = getUser() ? getUser()._id : "";
  const platform_match =
    /(ipad)/.exec(ua) ||
    /(ipod)/.exec(ua) ||
    /(windows phone)/.exec(ua) ||
    /(iphone)/.exec(ua) ||
    /(kindle)/.exec(ua) ||
    /(silk)/.exec(ua) ||
    /(android)/.exec(ua) ||
    /(win)/.exec(ua) ||
    /(mac)/.exec(ua) ||
    /(linux)/.exec(ua) ||
    /(cros)/.exec(ua) ||
    /(playbook)/.exec(ua) ||
    /(bb)/.exec(ua) ||
    /(blackberry)/.exec(ua) ||
    [];

  matched.platform = platform_match[0] || "";
  let reqData = Object.assign(
    {
      user_id,
      device_id,
      referrer,
      country,
      url,
      page,
      action,
      coll,
      platform: matched.platform,
    },
    {}
  );
  // console.log(`user-action, %c${action}`, "color:red; font-size:20px")
  await privateInstance.post(URL_LOG, reqData);
};

export const getDeviceId = () => {
  let device_id = getCookie("device_id");
  if (device_id && device_id !== "") {
    return device_id;
  }
  device_id = genDeviceId();
  setCookie("device_id", device_id, 365);
  return device_id;
};

export const genDeviceId = (length = 20) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const sendLogActionRcmdPage = (
  target_brand,
  target_course_title,
  button_name,
  button_text,
  next_href,
  next_page,
  enrolled = {}
) => {
  sendLog(
    "scc_courseRcmdPage_click_button",
    {
      target_brand: target_brand,
      target_course_title: target_course_title,
      button_name: button_name,
      button_text: button_text,
      next_href: next_href,
      next_page: next_page,
      ...enrolled,
    },
    "",
    false
  );
};
