import axios from "../_helpers/axios";
import { sendLog } from "../v2/businesslogics/logging";

export const getConfirmAndCerti = async (
  request_type,
  isWaiting,
  setIsWaiting,
  enrolled
) => {
  //TODO: enrolled.file_link에 수료증이 있는 경우 수강증을 눌러도 수료증이 다운로드 됨.
  // enrolled.file_link를 초기화하는 방법으로 수정함. 이렇게 해도 수료증은 기존 데이터를 불러오는데 수강증은 버전을 새로 만들어서 s3에 데이터가 쌓임.
  // 수료증은 certificates 콜렉션에, 수강증은 enrolleds 콜렉션에 file_link가 저장됨.

  enrolled.file_link = "";
  const fileLink = enrolled.file_link;
  const enrolled_id = enrolled.enrolled._id;
  const isInPeriod = enrolled.is_in_period;
  const done = enrolled.done;
  if (!done && (request_type === "nb_cert" || request_type === "new_cert"))
    return;
  if (
    isInPeriod &&
    (request_type === "nb_cert" || request_type === "new_cert") &&
    !enrolled.course.is_trial
  ) {
    sendLog(
      "scc_mypagePage_click_certificate",
      {
        certificate_type: "수료증",
        course_title: enrolled.course_title,
        course_id: enrolled.course._id,
        round: enrolled.round_title,
        round_id: enrolled.enrolled.round_id,
        is_success: false,
      },
      "",
      true
    );
    alert("수료증 다운로드는 기본 수강기간이 종료된 다음날부터 가능합니다.");
    return;
  }
  // fileLink에는 "cert" 가 포함인데 request_type에는 "confirm"이 포함 경우로 체크하는 것은?
  // => 반대의 경우도 생각해줘야하므로 file_link 초기화로 결정
  if (fileLink === "") {
    sendLog(
      "scc_mypagePage_click_certificate",
      {
        certificate_type:
          request_type === "nb_cert" || request_type === "cert"
            ? "수료증"
            : "수강증",
        course_title: enrolled.course_title,
        course_id: enrolled.course._id,
        round: enrolled.round_title,
        round_id: enrolled.enrolled.round_id,
        is_success: true,
      },
      "",
      true
    );
    if (!isWaiting) {
      setIsWaiting(true);
      await axios
        .post(
          "/api/cert",
          {
            enrolled_id,
            request_type,
          },
          {
            timeout: 3 * 60 * 1000,
            baseURL: "https://op.spartacodingclub.kr",
          }
        )
        .then((resp) => {
          window.open(resp.data.file_link);
        })
        .catch(
          // 수료증 생성 과정에서 오류가 발생하더라도, 일단 축하 환급 신청 모달을 보여준다.
          (error) => {
            // this.setState({
            //     cert_created: true,
            // })
          }
        )
        .finally(() => {
          setIsWaiting(false);
        });
    }
  }
};
