import { useState, useEffect } from "react";

export const useGetStepByProgressRate = (progressRate) => {
  const [step, setStep] = useState(0);

  const determineStep = (progress) => {
    progress = Number(progress);
    
    if (progress === 0) {
      return 0;
    } else if (progress >= 1 && progress < 20) {
      return 1;
    } else if (progress >= 20 && progress < 50) {
      return 2;
    } else if (progress >= 50 && progress < 80) {
      return 3;
    } else if (progress >= 80 && progress < 100) {
      return 4;
    } else if (progress === 100) {
      return 5;
    }
  }

  useEffect(() => {
    setStep(determineStep(progressRate));
  }, [progressRate]);

  return step
}

export const useGetRtanImageByStep = (step) => {
  const [rtanImageSrc, setRtanImageSrc] = useState("/assets/images/roadmap/rtans/question_rtan.png");

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const determineRtanImage = (step) => {
    switch (step) {
      case 0:
        return "/assets/images/roadmap/rtans/question_rtan.png";
      case 1:
        return "/assets/images/roadmap/rtans/rtan.png";
      case 2:
        return "/assets/images/roadmap/rtans/sprout_rtan.png";
      case 3:
        return `/assets/images/roadmap/rtans/random_rtan_${getRandomInt(0, 7)}.png`;
      case 4:
        return "/assets/images/roadmap/rtans/refund_rtan.png";
      case 5:
        return "/assets/images/roadmap/rtans/complete_rtan.png";
      default:
        return "/assets/images/roadmap/rtans/rtan.png";
    }
  }

  useEffect(() => {
    setRtanImageSrc(determineRtanImage(step))
  }, [step])

  return rtanImageSrc;
}

export const useGetDoneLectureNumber = (roundWeeks) => {
  const [week, setWeek] = useState(0);
  const [weekOrder, setWeekOrder] = useState(0);

  useEffect(() => {
    if (roundWeeks.loaded) {
      for (let week = 0; week < roundWeeks.data.weeks.length; week++) {
        
        for (let week_order = 0; week_order < roundWeeks.data.weeks[week].enrolled_details.length; week_order++) {
          if (roundWeeks.data.weeks[week].enrolled_details[week_order].done) {
            setWeek(week);
            setWeekOrder(week_order+1);
          }
        }
      }
    }
  }, [roundWeeks.loaded]);

  return {week, weekOrder};
}