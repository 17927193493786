import React, {Component} from "react";
import {Button, Collapse, Input, message, Radio,} from "antd";
import {history} from "../../_helpers";
import {languages} from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-markup'
import {getLectureUrl} from "../../_urls/lectures";
import {getApiNPSsubmit, getHomeworkUrl, getPostAuthOptions} from "../../_urls";
import classNames from "classnames/bind";
import styles from "./NPS.module.scss";
import {connect} from "react-redux";
import {homeworkActions, lectureActions} from "../../_actions";

const cx = classNames.bind(styles);

const languageList = {
    python: languages.python,
    css: languages.css,
    js: languages.js,
    markup: languages.markup
};

class NPSPageEn extends Component {
    constructor(props) {
        super(props);
        let paramsDict = {};
        let paramsString = history.location.search;
        if (paramsString.length > 0) {
            let params = paramsString.split('?')[1].split('&');
            for (const p of params) {
                let part = p.split('=');
                paramsDict[part[0]] = part[1];
            }
        }
        this.state = {
            ...paramsDict,
            round_id: this.props.match.params.round_id,
            homework_id: this.props.match.params.homework_id,
            details: [],
            isDone: false,
            isLoading: false,
            goToNextModalVisible: false,
            editing: false,
            isSubmitButtonPressed: false,
            isNpsShow: false,
            final_review_url: '',
            nav_selected: false,
            nps: {
                'is_nps_done': false,
                'week': 2,
                'course_id': '600e78ff11c6577d867f3b5e',
                'round_id': this.props.match.params.round_id,
                'enrolled_id': this.props.match.params.enrolled_id,
            },
            sat_done: false,
            score_done: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {round_id} = this.state;
    }

    componentDidUpdate(prevProps) {
        if (
            !prevProps.homework.loaded &&
            this.props.homework.loaded
        ) {
            this.setState({
                isLoading: false,
                final_review_url: this.props.homework.data.final_review_url,
            });

            if (this.props.homework.error) {
                alert(this.props.homework.error);
            } else if (this.props.homework && this.state.details.length === 0) {
                this.setState({
                    details: this.props.homework.data.details,
                    isDone: this.props.homework.isDone
                })
            }

            const {is_nps_done} = this.props.homework.nps;
            if (this.props.homework.isSubmitted) {
                if (is_nps_done === false) {
                    this.handleOk();
                } else {
                    this.setState({
                        goToNextModalVisible: true
                    })
                }
            }
        }
    }

    onEditorChange(order, code) {
        this.setState({
            editing: true,
            details: this.state.details.map(
                item => {
                    if (order === item.order) {
                        let new_item = JSON.parse(JSON.stringify(item));
                        new_item.answer = code;
                        return {...item, ...new_item}
                    } else {
                        return item
                    }
                }
            )
        })
    }

    onFinalReviewChange = (final_review_url) => {
        this.setState({final_review_url})
    };

    _showSuccessMessage = () => {
        const {point} = this.props.homework.data;
        message.success({
            content: `+${point} points!`,
            className: 'custom-class',
            style: {
                position: 'absolute',
                top: '7px',
                right: '40px',
            },
        });
    };

    submitHomework() {
        const {round_id, homework_id, details, final_review_url} = this.state;
        for (let i = 0; i < details.length; i++) {
            if (details[i].answer === '') {
                alert(`Type in your HW code.`);
                return;
            }
        }
        this.setState({
            isLoading: true,
            isSubmitButtonPressed: true,
            goToNextModalVisible: true,
        });
        this.props.postHomeworkItem(round_id, homework_id, details, final_review_url);
    }

    _setIsNpsShow = (value) => {
        this.setState({
            isNpsShow: value,
        });
    };

    handleNavStatus = (nav_selected) => {
        this.setState({
            nav_selected,
        })
    };
    _handleScore = (e) => {
        let value = e.target.value;
        this.setState({
            nps_score: value,
            score_done: true,
            show_rcmd: Number(value) > 8,
        })
    };

    handleOk = () => {
        if (this.props.homework.isSubmitted) {
            const {isDone} = this.state;
            if (isDone === false) {
                this._showSuccessMessage();
            }

            // NPS 제출 여부에 따라 분기
            const {nps} = this.props.homework;
            if (nps.is_nps_done === false) {
                this.setState({
                    isNpsShow: true,
                });
                return;
            }

            // NPS 를 이미 제출했기 때문에 다음 내용으로 이동
            this.setState({
                goToNextModalVisible: false,
                isNpsShow: false,
            });
            const {round_id} = this.state;
            const {next_id, enrolled_id, next_week_allowed, next_start_date} = this.props.homework.data;
            if (!next_id) {
                alert('Finally, the course is over.\nCongratulations for your completion! 🏅');
                history.push(`/en/finish?enrolled_id=${enrolled_id}`);
                return;
            }
            if (!next_week_allowed) {
                alert(`다음 주차는 ${next_start_date}부터 수강 가능합니다.`);
                return;
            }
            let chosenUrl;
            if (next_id.includes('lectures')) {
                const lecture_id = next_id.split('/')[1];
                // this.props.getLecture(round_id, lecture_id);
                chosenUrl = getLectureUrl(round_id, lecture_id);
            } else {
                chosenUrl = getHomeworkUrl(round_id, next_id);
                this.props.getHomeworkItem(round_id, next_id);
            }
            history.push('/en' + chosenUrl);
        }
    };

    handleCancel = () => {
        this.setState({
            goToNextModalVisible: false,
        })
    };


    _handleSat = e => {
        let value = e.target.value;
        this.setState({
            satisfaction: value,
            sat_done: true,
        })
    };

    _handleGoodPoint = (e) => {
        let value = e.target.value;
        this.setState({
            good_point: value,
        })
    };

    _handleBadPoint = (e) => {
        let value = e.target.value;
        this.setState({
            bad_point: value,
        })
    };
    _submitNPS = () => {
        const {satisfaction, good_point, bad_point, nps_score, sat_done, score_done, enrolled_id,} = this.state;
        if (sat_done === false) {
            alert('Please choose an option');
            return;
        }
        if (score_done === false) {
            alert('Please choose an option');
            return;
        }
        let data = {
            satisfaction,
            good_point,
            bad_point,
            nps_score
        };
        const {week, round_id} = this.state.nps;
        const endpoint = getApiNPSsubmit(round_id, week);
        const options = getPostAuthOptions(data);
        fetch(endpoint, options)
            .then(
                resp => {
                    if (resp.ok) {
                        alert('Finally, the course is over.\nCongratulations for your completion! 🏅');
                        console.log(enrolled_id);
                        history.push(`/en/finish?enrolled_id=${enrolled_id}`);
                    }
                },
                error => {
                    alert('Error.\nPlease report.');
                }
            )
    };

    render() {
        const {user} = this.props;
        return (
            <div className={cx('npsContainer')}>
                {/*안내문*/}
                <div className={cx('infoContainer', 'contentsContainer')}>
                    <h2>How was Chap.2, {user.name}?</h2>
                    <p>Your opinion is a great help to course improvement. 😊</p>
                </div>

                <hr/>

                {/*satisfaction*/}
                <div className={cx('satContainer', 'contentsContainer')}>
                    <h4>Are you satisfied with the course?</h4>
                    <Radio.Group onChange={this._handleSat} value={this.state.satisfaction}>
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                    </Radio.Group>
                </div>

                {/*good_point*/}
                <div className={cx('goodContainer', 'contentsContainer')}>
                    <h4>What part of the course did you like most?</h4>
                    <textarea id="good_point" rows="4" placeholder={"Please write down your opinion here."}
                              onChange={this._handleGoodPoint}/>
                </div>

                {/*bad_point*/}
                <div className={cx('badContainer', 'contentsContainer')}>
                    <h4>What part of the coruse can be improved?</h4>
                    <textarea id="bad_point" rows="4" placeholder={"Please write down your opinion here."}
                              onChange={this._handleBadPoint}/>
                </div>

                {/*nps_score*/}
                <div className={cx('scoreContainer', 'contentsContainer')}>
                    <h4>How likely are you to recommend us to your friend or colleague? (1-10)</h4>
                    <div className={cx('slideContainer')}>
                        <Radio.Group onChange={this._handleScore} value={this.state.nps_score}>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={5}>5</Radio>
                            <Radio value={6}>6</Radio>
                            <Radio value={7}>7</Radio>
                            <Radio value={8}>8</Radio>
                            <Radio value={9}>9</Radio>
                            <Radio value={10}>10</Radio>
                        </Radio.Group>
                    </div>
                </div>

                {/*submit button*/}
                <div className={cx('buttonContainer')}>
                    <Button onClick={() => {
                        this._submitNPS()
                    }}
                            className={cx('enterButtonContainer')}>
                        <span className={cx('enterButtonTextContainer')}>Submit and Complete🥇</span>
                    </Button>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const {authentication, homework} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        homework,
    }
}

const connectedNPSPageEn = connect(mapStateToProps, {
    getHomeworkItem: homeworkActions.getHomeworkItem,
    postHomeworkItem: homeworkActions.postHomeworkItem,
    getLecture: lectureActions.getLecture,
})(NPSPageEn);
export {connectedNPSPageEn as NPSPageEn};
