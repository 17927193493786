import React, { useEffect } from "react";
import { connect } from "react-redux";
import { params } from "../../../_helpers";
import {
  classroomActions,
  enrolledActions,
  enrolledSummaryActions,
  roundsActions,
} from "../../_state/actions";
import { sendLog } from "../../businesslogics/logging";
import { CourseRcmdDdingdong } from "../CourseRcmdPage/CourseRcmdDdingdong";
import { CourseRcmdChang } from "../CourseRcmdPage/CourseRcmdChang";
import { CourseRcmdB2B } from "../CourseRcmdPage/CourseRcmdB2B";
import { CourseRcmdOnline } from "../CourseRcmdPage/CourseRcmdOnline";
import Congrats from "./Congrats";
import { CourseRcmdSwCamp } from "../CourseRcmdPage/CourseRcmdSwCamp";
import { CourseRcmdNb } from "../CourseRcmdPage/CourseRcmdNb";
import { CourseRcmdPirates } from "../CourseRcmdPage/CourseRcmdPirates";
import { CourseRcmdHanghae } from "../CourseRcmdPage/CourseRcmdHanghae";
import { CourseRcmdNBcampVer2 } from "../CourseRcmdPage/CourseRcmdNBcampVer2";
import { RecommendNBCCourse } from "../FreeFinishPage/FreeFinishPage";

function FinishCelebratePage({
  user,
  setRound,
  enrolledFinishSummary,
  enrolled,
  enrolledDetails,
  setEnrolled,
  getEnrolledDetails,
  enrolledPlaytime,
  getEnrolledPlaytime,
  getClassroomCards,
  classroomCards,
}) {
  const { enrolled_id } = params();
  const { course_type } = params();

  const getNextStepPage = (nextStep) => {
    switch (nextStep) {
      case "chang":
        return (
          <CourseRcmdChang isEmbed={true} course={enrolled.data?.courses} />
        );
      case "nbcamp":
        // return <CourseRcmdNBcamp course={enrolled.data?.courses} />;
        if (course_type === "nb") return <RecommendNBCCourse />;
        else return <CourseRcmdNBcampVer2 course={enrolled.data?.courses} />;
      case "hanghae":
        return <CourseRcmdHanghae course={enrolled.data?.courses} />;
      case "ddingdong":
        return (
          <CourseRcmdDdingdong isEmbed={true} course={enrolled.data?.courses} />
        );
      case "b2b":
        return <CourseRcmdB2B />;
      case "nb":
        return <CourseRcmdNb course={enrolled.data?.courses} />;
      case "swcamp":
        return <CourseRcmdSwCamp />;
      case "pirates":
        return (
          <CourseRcmdPirates isRoot={!enrolled.data?.courses.is_pirates} />
        );
      default:
        return (
          <CourseRcmdOnline
            enrolledFinishSummary={enrolledFinishSummary}
            course={enrolled.data?.courses}
            classroomCards={classroomCards}
          />
        );
    }
  };

  useEffect(() => {
    if (!enrolled.loaded) setEnrolled(enrolled_id);
    if (!enrolledDetails.loaded) getEnrolledDetails(enrolled_id);
    if (!enrolledPlaytime.loaded) getEnrolledPlaytime(enrolled_id);
    if (!classroomCards.loaded) getClassroomCards();
  }, [
    classroomCards.loaded,
    enrolled.loaded,
    enrolledDetails.loaded,
    enrolledPlaytime.loaded,
  ]);

  useEffect(() => {
    if (
      enrolled.loaded &&
      enrolledDetails.loaded &&
      enrolledFinishSummary.loaded
    ) {
      sendLog(
        "scc_courseRcmdPage_view",
        {
          ...enrolled.data,
          target_brand: enrolledFinishSummary.data?.next_step,
        },
        "",
        false
      );
      setRound(enrolled.data.round_id);
    }
  }, [enrolled, enrolledDetails, enrolledFinishSummary]);

  return (
    <>
      <div>
        <Congrats
          user={user}
          enrolled={enrolled}
          enrolledPlaytime={enrolledPlaytime}
          nextStep={enrolledFinishSummary.data?.next_step}
          isRepurchase={classroomCards.data?.full_enrolled_info_list.length > 1}
        />

        <div>
          {enrolledFinishSummary.loaded &&
            enrolled.loaded &&
            getNextStepPage(enrolledFinishSummary.data?.next_step)}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    enrolledSummary,
    enrolled,
    round,
    enrolledDetails,
    enrolledPlaytime,
    classroomCards,
  } = state;
  const { user } = authentication;
  return {
    user,
    enrolledSummary,
    enrolled,
    round,
    enrolledDetails,
    enrolledPlaytime,
    classroomCards,
  };
}

const connectedFinishPage = connect(mapStateToProps, {
  getEnrolledSummary: enrolledSummaryActions.getEnrolledSummary,
  setRound: roundsActions.setRound,
  setEnrolled: enrolledActions.setEnrolled,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getEnrolledPlaytime: enrolledActions.getEnrolledPlaytime,
  getClassroomCards: classroomActions.getClassroomCards,
})(FinishCelebratePage);
export { connectedFinishPage as FinishCelebratePage };
