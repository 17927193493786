import { commingsoonConstants } from "../_constants";
import { commingsoonService } from "../_services";

function getCommingsoonList() {
  function request() {
    return { type: commingsoonConstants.COMMINGSOON_LIST_REQUEST };
  }

  function success(response) {
    return { type: commingsoonConstants.COMMINGSOON_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: commingsoonConstants.COMMINGSOON_LIST_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    commingsoonService.getCommingsoonList().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const commingsoonActions = {
  getCommingsoonList,
};
