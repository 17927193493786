import styles from "./B2bNpsCta.module.scss";
import classNames from "classnames/bind";
import React from "react";
import {connect} from "react-redux";
import {npsActions} from "../../../_state/actions/nps.actions";

const cx = classNames.bind(styles);

function B2bNpsCta({
                     result,
                     enrolled_id,
                     postB2bSurvey,
                   }) {
  const isAllAnswered = () => {
    if (!!result.filter((question) => (
      !question.isOptional && !question.answer
    )).length) {
      alert("필수 입력 항목을 채워주세요!");
      return false
    } else
      return true
  };

  async function handleSubmit() {
    if (isAllAnswered()) {
      let surveyResult = []
      result.map((qna, index) => {
        surveyResult.push({QuestionNum: `Q${index+1}`, Question: qna.content, Answer: qna.answer})
      })
      await postB2bSurvey(enrolled_id, surveyResult).then(
        (res) => {
          if (res.ok) {
            alert("제출에 성공하였습니다");
            window.location.replace("/classroom");
          }
        },
        (err) => {
          console.debug("👎", err);
        }
      );
    }
  }

  return (
    <div className={cx("B2bNPSFooterWrapper")}>
      <div className={cx("B2bNPSFooterContainer")}>
        <div className={cx("B2bNPSFooterBody")}>
          꼼꼼하게 답변해주셔서 정말 감사해요!
        </div>
        <div className={cx("B2bNPSFooterContent")}>
          교육이 종료되었습니다!
          <br/> 지난 강의기간 동안 너무 고생많으셨어요 <br/>
          수료를 축하드립니다!!!
        </div>
        <div>
          <img
            className={cx("B2bNPSFooterIcon")}
            src={`/assets/images/profile/rtan_heart.png`}
            alt=""
          />
        </div>
      </div>
      <div className={cx("B2bNPSButtonWrapper")}>
        <button
          className={cx("B2bNPSFooterSubmitButton")}
          onClick={handleSubmit}
        >
          설문 제출하기
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const {authentication, enrolled} = state;
  const {user} = authentication;
  return {user, enrolled};
}

const connectedB2bNPSFooter = connect(mapStateToProps, {
  postB2bSurvey: npsActions.postB2bSurvey,
})(B2bNpsCta);
export {connectedB2bNPSFooter as B2bNPSFooter};
