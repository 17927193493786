import React, { Component } from "react";
import classNames from "classnames/bind";
import styles from "./FinishPage.module.scss";
import { connect } from "react-redux";
import { mypageActions, userActions } from "../../_actions";
import { createEventId, params } from "../../_helpers";
import "./FinishPage.module.scss";
import { CustomModal_Reimburse } from "../../_components/CustomModal_Reimburse";
import axios from "../../_helpers/axios";
import { sendLog } from "../../v2/businesslogics/logging";

const cx = classNames.bind(styles);
//TODO: 사용안하는 것 확인
class FinishPage extends Component {
  state = {
    visible: false,
    enrolled_id: params().enrolled_id,
    cert_created: false,
    isMobile: true,
  };

  componentDidMount() {
    this.createCertReq();
    const { deviceType } = this.props.environment;

    const eventID = createEventId();
    sendLog(
      "finish_course",
      {
        // course_id, round_id 추가 필요
        enrolled_id: this.state.enrolled_id,
      },
      eventID
    );

    this.setState({
      isMobile: deviceType === "mobile",
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.cert_created && this.state.cert_created) {
      this.setModal();
    }
  }

  createCertReq = async () => {
    const { enrolled_id } = this.state;
    await axios
      .post(
        "/api/cert",
        { enrolled_id, request_type: "new_cert" },
        {
          timeout: 3 * 60 * 1000,
          baseURL: "https://op.spartacodingclub.kr",
        }
      )
      .then((resp) => {
        this.setState({
          cert_created: true,
        });
      })
      .catch(
        // 수료증 생성 과정에서 오류가 발생하더라도, 일단 축하 환급 신청 모달을 보여준다.
        (error) => {
          // this.setState({
          //     cert_created: true,
          // })
        }
      );
  };

  setModal = () => {
    this.setState({
      visible: true,
    });
  };

  _getImagePathTreauserBox = (isOpen) => {
    if (isOpen) {
      return "/assets/images/lecture/box_open_effects.gif";
    } else {
      return "/assets/images/lecture/treasure_box_unopened.png";
    }
  };

  handleModalVisible = () => {};

  render() {
    const { visible, enrolled_id, isMobile } = this.state;
    return (
      <div>
        <div
          className={cx("fireworks")}
          style={{
            "--bg-image": `url(${"/assets/images/finish/fireworks.gif"}`,
          }}
          alt={"fireworks_bg"}
        >
          {/*<div className={cx("treasure")}>*/}
          {/*  <img src={this._getImagePathTreauserBox(true)} alt="treasure_box" />*/}
          {/*  <h2>*/}
          {/*    {this.props.user.name}님, 완주를 축하합니다!🎉*/}
          {/*    <br />*/}
          {/*    수료증을 생성하고 있어요*/}
          {/*  </h2>*/}
          {/*  <h4>최대 2분 정도 소요될 수 있습니다.</h4>*/}
          {/*</div>*/}
          <div className={cx("story")}>
            <div className={cx("story__intro")}>
              <h2>{this.props.user.name}님, 완주를 축하합니다!🎉</h2>
              <p>그동안 정말 수고하셨어요!</p>
            </div>
            <img
              className={cx("story__letter")}
              style={{ width: "100%" }}
              src={`/assets/images/finish/finish_characters${
                isMobile ? "_mobile" : ""
              }.png`}
              alt={"characters"}
            />
            <div className={cx("story__loading")}>
              <img
                className={cx("story__loading__icon")}
                src={"/assets/images/finish/ic_alert.svg"}
              />
              <div className={cx("story__loading__text")}>
                <p>수료증을 생성하고 있어요.</p>
                <span>최대 2분정도 소요될 수 있어요.</span>
              </div>
            </div>
          </div>
        </div>
        <CustomModal_Reimburse
          visible={visible}
          enrolled_id={enrolled_id}
          handleModalVisible={this.handleModalVisible}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, enrolledDone, enrolledStatus, environment } = state;
  const { user } = state.authentication;
  return {
    authentication,
    user,
    enrolledDone,
    enrolledStatus,
    environment,
  };
}

const connectedFinishPage = connect(mapStateToProps, {
  refreshInfo: userActions.refreshInfo,
  logout: userActions.logout,
  loginWithToken: userActions.loginWithToken,
  getMypage: mypageActions.getMypage,
})(FinishPage);
export { connectedFinishPage as FinishPage };
