import styled from "styled-components";
import { PIRATES } from "../../../_consts";

export const MobileReportPage = styled.div`
  height: calc(100vh - 56px);
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
`;
