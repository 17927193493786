import styles from "./B2bNPSBanner.module.scss";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";

const cx = classNames.bind(styles);

export function B2bNPSBanner({ user }) {
  return (
    <div className={cx("B2bNPSBannerWrapper")}>
      <div className={cx("B2bNPSBannerContainer")}>
        <div className={cx("B2bNPSBannerTitle")}>
          {user.name}님 만족도 조사를 작성해주세요! 🥳
        </div>
        <div className={cx("B2bNPSBannerBody")}>
          딱 3분! <br />
          설문조사를 제출하면 수료가 완료됩니다. 🙌
        </div>
        <div className={cx("B2bNPSBannerContent")}>
          {user.name}님의 소중한 피드백이 강의개선에 큰 도움이 돼요!
          <br />본 만족도 조사는 이후 더 나은 교육을 제공하기 위한 자료로
          사용됩니다.
        </div>
        <div>
          <img
            className={cx("B2bNPSBannerIcon")}
            src={`/assets/images/profile/rtan_friend_hurray1.png`}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
