import {
  fundingCourses,
  tempGptCourses,
} from "../../v2/utils/blockingCourseIds";
import { history } from "../../_helpers";
import React from "react";
import styles from "./RcmdPage.module.scss";
import classNames from "classnames/bind";
import { useGetFundingCourseIds } from "../../v2/queries/funding";
const cx = classNames.bind(styles);

export const UpperRcmd = ({
  isB2BKoreaInvest,
  isStoryPossible,
  period,
  platform,
  scrollToRcmd,
  enrolled_id,
  round_id,
  course_id,
}) => {
  const fundingCourseIds = useGetFundingCourseIds();
  const isFundingCourse = fundingCourseIds.includes(course_id);
  return (
    <div className={cx("rcmd-paid-after")}>
      {!isB2BKoreaInvest && (
        <span>
          <img
            src={"/assets/images/rcmd/icon_check_black.png"}
            alt="ic_check_black"
          />
          &nbsp; 수강신청이 완료되었습니다 🎉
        </span>
      )}
      {isStoryPossible && (
        <>
          <div className={cx("rcmd-paid-after__characters")}>
            <img
              className={cx("rcmd-paid-after__characters__img")}
              alt={"character_intro"}
              src={`/assets/images/rcmd/intro_characters${
                platform === "mobile" ? "_mobile" : ""
              }.png`}
            />
            <p className={cx("rcmd-paid-after__characters__intro")}>
              앞으로 {period} 간의 <br />
              코딩레이스가 펼쳐집니다!
            </p>
          </div>
          {!isB2BKoreaInvest && (
            <div className={cx("rcmd-paid-after__bottom_cta")}>
              <img
                onClick={() => {
                  scrollToRcmd();
                }}
                className={cx("rcmd-paid-after__bottom_cta__icon")}
                src={"/assets/images/rcmd/ic-pagedown.svg"}
                alt={"ic-pagedown"}
              />
              {isFundingCourse && (
                <p
                  className={cx("rcmd-paid-after__bottom_cta__detail")}
                  onClick={() => {}}
                >
                  강의를 더욱 빨리 만나보고 싶다면?
                </p>
              )}

              <div
                className={cx("myClassButton")}
                onClick={() => {
                  if (isFundingCourse) {
                    window.location.href = `https://spartacodingclub.kr/community/freeboard/all`;
                    return;
                  }
                  history.push(
                    `/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`
                  );
                }}
              >
                {isFundingCourse ? "커뮤니티에서 모집하기" : "수업 들으러 가기"}
              </div>

              {!isFundingCourse && (
                <p
                  className={cx("rcmd-paid-after__bottom_cta__detail")}
                  onClick={() => {
                    scrollToRcmd();
                  }}
                >
                  새로운 코딩 메이트를 추가하고 싶다면?
                </p>
              )}
            </div>
          )}
        </>
      )}
      {!isStoryPossible && (
        <div className={cx("rcmd-paid-after__no_characters")}>
          <p>
            코딩할 생각에 벌써부터 설렌다구요?!
            <br />
            (두근두근)
          </p>
          <h3 className={cx("rcmd-paid-after__no_characters__header")}>
            지금 바로 시작해보세요!
          </h3>
          <button
            onClick={() => {
              history.push(`/classroom`);
            }}
          >
            내 강의실 가기{" "}
            <img alt={"ic_arrow"} src={`/v2/assets/icons/arrow-forward.svg`} />
          </button>
          <div className={cx("rcmd-paid-after__no_characters__bar")} />
        </div>
      )}
    </div>
  );
};
