import * as S from "../../../HomeworkV3/HomeworkGnb/Desktop/HomeworkGnbDesktop.style";
import { BackIconSvg } from "../../../../../pages/LecturePageV3/Icons";
import { DesktopNextBtn } from "../../../../../pages/LecturePageV3/LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import { useNavigateToRoadmap } from "../../../../../hooks/useNavigateToRoadmap";
import {
  useNavigateToNextContent,
  useNextContentCtaText,
  useSyllabusUnit,
} from "../../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useParams } from "react-router-dom";
import { useEnrolled } from "../../../../../hooks/useProductName";
import { useAtom } from "jotai";
import {
  enrolledEvaluationDetailsAtom,
  isLoadingEvaluationPageAtom,
} from "../../../../../stores/enrolledEvaluationDetails";
import { usePostMyEvaluations } from "../../../../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { PAGE_TYPE } from "../../../../../_state/constants/evaluation.constants";
import { useCheckAllAnswersSubmitted } from "../../../../../hooks/evaluation/useEvaluationData";

export const EvaluationGnbDesktop = ({
  isOpenList,
  week,
  pageType,
  setIsLoading,
}) => {
  const navigateToRoadmap = useNavigateToRoadmap();
  const ctaText = useNextContentCtaText(
    false,
    true,
    pageType === PAGE_TYPE.RESULT_PAGE ? "다음으로" : "채점하기"
  );
  const navigateToNext = useNavigateToNextContent();
  const { enrolled_id, enrolled_evaluation_id } = useParams();
  const [enrolledEvaluationDetails] = useAtom(enrolledEvaluationDetailsAtom);
  const enrolled = useEnrolled(enrolled_id);
  const { data: enrolledData } = enrolled;
  const postMyEvaluations = usePostMyEvaluations(
    enrolled_evaluation_id,
    enrolled_id
  );

  const areAllAnswersSubmitted = useCheckAllAnswersSubmitted(
    enrolledEvaluationDetails
  );

  const handleSubmitEvaluation = async () => {
    if (pageType === PAGE_TYPE.RESULT_PAGE) {
      navigateToNext();
    } else {
      setIsLoading(true);
      setTimeout(() => {
        postMyEvaluations.mutateAsync(enrolledEvaluationDetails);
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <S.HomeworkGnbWrapper isOpenList={isOpenList}>
      <S.HomeworkGnbLeftSide>
        <S.GoPrevPageBtn
          onClick={() => {
            navigateToRoadmap();
          }}
        >
          <BackIconSvg />
        </S.GoPrevPageBtn>
        <S.LectureTitleAndWeekWrapper>
          <S.LectureTitle>{enrolledData?.courses?.title}</S.LectureTitle>
          <S.LectureTitleAndWeekDivider />
          <S.LectureWeek>{week}주차</S.LectureWeek>
        </S.LectureTitleAndWeekWrapper>
      </S.HomeworkGnbLeftSide>
      <S.HomeworkGnbRightSide>
        <DesktopNextBtn
          text={ctaText}
          onClick={handleSubmitEvaluation}
          isActive={true}
          isClickable={
            areAllAnswersSubmitted || pageType === PAGE_TYPE.RESULT_PAGE
          }
        />
      </S.HomeworkGnbRightSide>
    </S.HomeworkGnbWrapper>
  );
};
