export const SECRET_STORE = "secretStore";

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return decodeURIComponent(parts.pop().split(";").shift());
};

export const setCookie = (name, value, days) => {
  var expires = "";
  const domain = document.location.hostname.includes("spartacodingclub")
    ? ".spartacodingclub.kr"
    : document.location.hostname;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; domain=${domain}; expires=` + date.toUTCString();
  } else {
    expires = `; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
  document.cookie =
    name + "=" + encodeURIComponent(value || "") + expires + "; path=/";
};

export const genUniqueId = (length = 20) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const toIsoString = (date) => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const cookieToObject = (cookie) => {
  const decodedCookie = decodeURIComponent(cookie);
  const pairs = decodedCookie.split("&");

  const result = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    result[key] = value;
  });

  return result;
};
