import * as S from "../../../pages/LecturePageV3/LectureTapMenu/ErrorReport/ErrorReport.style";
import { ErrorReportInput } from "../../../pages/LecturePageV3/LectureTapMenu/ErrorReport/ErrorReport.style";
import {
  handleTimeChange,
  formatNumber,
} from "../../../utils/ErrorReport/errorReport";
import React from "react";
import { useIsMobile } from "../../../hooks/device";

export const ErrorMoment = ({
  type,
  theme,
  errorMinute,
  errorSecond,
  enrolledDetail,
  setErrorMinute,
  setErrorSecond,
}) => {
  const isMobile = useIsMobile();
  return (
    <>
      {type === "영상 오류" && (
        <>
          <S.ErrorReportSubTitle theme={theme.data} isMobile={isMobile}>
            오류 지점(영상에서 오류가 발생한 시간을 입력해주세요.)
          </S.ErrorReportSubTitle>
          <S.ErrorReportInputWrapper isMobile={isMobile}>
            <ErrorReportInput
              isMobile={isMobile}
              type={"number"}
              pattern={"^[0-9]{1,2}$"}
              defaultValue={formatNumber(parseInt(errorMinute))}
              onFocus={(e) => {
                e.target.value = "";
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.value = "00";
                }
                const regex = /^[0-9]{1,2}$/;
                if (!regex.test(e.target.value)) {
                  alert("두 자 이내의 숫자만 입력 가능합니다.");
                  e.target.value = formatNumber(parseInt(errorMinute)); // 원래 값으로 복원
                } else {
                  handleTimeChange(
                    "minutes",
                    e,
                    enrolledDetail,
                    errorMinute,
                    setErrorMinute,
                    setErrorSecond
                  );
                }
              }}
            />
            :
            <ErrorReportInput
              isMobile={isMobile}
              type={"number"}
              pattern={"^[0-9]{1,2}$"}
              defaultValue={formatNumber(parseInt(errorSecond))}
              onFocus={(e) => {
                e.target.value = "";
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.value = "00";
                }
                const regex = /^[0-9]{1,2}$/;
                if (!regex.test(e.target.value)) {
                  alert("두 자 이내의 숫자만 입력 가능합니다.");
                  e.target.value = formatNumber(parseInt(errorSecond)); // 원래 값으로 복원
                } else {
                  handleTimeChange(
                    "second",
                    e,
                    enrolledDetail,
                    errorMinute,
                    setErrorMinute,
                    setErrorSecond
                  );
                }
              }}
            />
          </S.ErrorReportInputWrapper>
        </>
      )}
    </>
  );
};
