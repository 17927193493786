import { classroomConstants } from "../_constants/classroom.constants";
import { classroomService } from "../_services/classroom.service";

function getClassroom() {
  function request() {
    return { type: classroomConstants.CLASSROOM_REQUEST };
  }

  function success(response) {
    return { type: classroomConstants.CLASSROOM_SUCCESS, response };
  }

  function failure(error) {
    return { type: classroomConstants.CLASSROOM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    classroomService.getClassroom().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const classroomActions = {
  getClassroom,
};
