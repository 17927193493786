import styled from "styled-components";
import { DisplayResolution, device } from "../../_styles/device";
import { mBody1, neutralDay, wBody1 } from "@teamsparta/design-system";

export const NewChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 328px;
  height: 47px;
  margin-top: 16px;
`;

export const NewChangeFirstLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NewChangeImg = styled.img`
  width: 45px;
  height: 22px;
  margin-right: 10px;
`;

export const NewChangeContent = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  color: #1d5b22;
`;

export const NewChangeSecondLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 55px;
`;

export const NewChangeSecondContent = styled.div`
  color: #1d5b22;
  font-size: 13px;
  font-family: Pretendard;
  font-weight: 600;
  opacity: 0.7;
  margin-right: 4px;
`;

export const NewChangeSecondSub = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
  color: #1d5b22;
  opacity: 0.3;
`;

export const GrayBtn = styled.div`
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 320px;
  height: 60px;

  background: ${neutralDay.gray10};
  border: 1px solid ${neutralDay.gray40};
  border-radius: 8px;
  margin-bottom: 12px;
  &:hover {
    background: ${neutralDay.gray30};
  }
`;
export const Logo = styled.img`
  width: 34px;
  height: 18px;
  flex-grow: 0;
  object-fit: contain;
  margin-bottom: 4px;
  margin-top: 18px;
`;

export const Leaf = styled(Logo)`
  width: 20px;
  height: 18px;
  margin-top: 32px;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  top: 32px;

  @media ${device.desktop} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 320px;
`;

export const GrayTitle = styled.h3`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #81898f;
`;

export const MainTitle = styled.h2`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  color: #000000;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 0px;
  margin-top: 80px;
  margin-bottom: 32px;
`;

export const InfoRtanLogo = styled.img`
  display: flex;
  width: 58px;
  height: 82px;
`;

export const InfoQuoteWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const InfoQuoteBox = styled.img`
  width: 320px;
  height: 61px;
`;

export const InfoText = styled.div`
  position: absolute;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  text-align: center;
  width: 320px;

  color: #5f666b;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

export const ButtonUpperTextBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
`;

export const ButtonUpperSVG = styled.img`
  width: 16px;
  height: 16px;
`;

export const ButtonUpperText = styled.h3`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #141617;
`;

export const ButtonUnderText = styled.h3`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #5f666b;
`;

export const H3 = styled.h3`
  margin-bottom: 16px;
  flex-grow: 0;
  font-family: GmarketSans;
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: #263628;
`;

export const P = styled.div`
  margin-top: 16px;
  flex-grow: 0;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: #263628;
`;

export const Caption = styled.div`
  margin: 0 0 20px;
  flex-grow: 0;
  font-family: "Pretendard";
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: #6b6e72;
`;
export const CardRtan = styled.img`
  width: 248px;
  flex-grow: 0;
  object-fit: contain;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  gap: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ visible }) => (visible ? `` : `display: none;`)}
`;

export const LoadingText = styled.h3`
  display: flex;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  text-align: center;

  color: #3a3e41;
  @media ${device.desktop} {
    font-size: 20px;
  }
`;

export const LoadingRtan = styled.img`
  display: flex;
  width: 62px;
  height: 64px;
`;

export const Title = styled.div`
  font-family: Cafe24Ohsquare;
  font-style: normal;
  font-weight: bold;
  font-size: 26.4448px;
  line-height: 31px;
  text-align: center;
  color: #1d5b22;
  margin-bottom: 32px;
`;
