import {
  enrolledDoneConstants,
  enrolledStatusConstants,
  enrolledVoucher,
  enrolledOngoingConstants,
} from "../_constants/enrolleds.constants";

const initState = {
  loaded: false,
};

export function enrolledOngoing(state = initState, action) {
  switch (action.type) {
    case enrolledOngoingConstants.ENROLLED_ONGOING_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case enrolledOngoingConstants.ENROLLED_ONGOING_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case enrolledOngoingConstants.ENROLLED_ONGOING_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledDone(state = initState, action) {
  switch (action.type) {
    case enrolledDoneConstants.ENROLLED_DONE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case enrolledDoneConstants.ENROLLED_DONE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case enrolledDoneConstants.ENROLLED_DONE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function enrolledStatus(state = initState, action) {
  switch (action.type) {
    case enrolledStatusConstants.ENROLLED_STATUS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case enrolledStatusConstants.ENROLLED_STATUS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case enrolledStatusConstants.ENROLLED_STATUS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function vouchers(state = initState, action) {
  switch (action.type) {
    case enrolledVoucher.ENROLLED_VOUCHER_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case enrolledVoucher.ENROLLED_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case enrolledVoucher.ENROLLED_VOUCHER_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
