import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styles from "./RoadmapMakerDashboard.module.scss";
import { enrolledActions } from "../../../_state/actions";

const cx = classNames.bind(styles);

function RoadmapMakerDashboard({
  user,
  enrolled,
  expired,
  getExpired,
  getMaker,
  enrolledMaker,
}) {
  const BAR_FULL_LENGTH = 975;
  const TOTAL_MAKER_WEEKS_COUNT = 3;
  const [rtanPosition, setRtanPosition] = useState(0);
  const [dinoWeek, setDinoWeek] = useState(0);

  useEffect(() => {
    if (!enrolledMaker.loaded) {
      getMaker(enrolled.data.enrolled_id);
    }
  }, []);

  useEffect(() => {
    if (enrolledMaker.loaded) {
      const { maker_rcmd_week, maker_progress_rate } = enrolledMaker.data;
      setRtanPosition(BAR_FULL_LENGTH * maker_progress_rate);
      setDinoWeek(maker_rcmd_week);
    }
  }, [enrolledMaker]);
  const getMileStones = () => {
    const mileStoneLIst = [];
    for (let i = 0; i < TOTAL_MAKER_WEEKS_COUNT + 1; i++) {
      mileStoneLIst.push(
        <div className={cx("roadmap-maker__weeks__week")}>
          {dinoWeek === i && (
            <div className={cx("roadmap-maker__weeks__week__dino")}>
              <img
                className={cx("roadmap-maker__weeks__week__dino__img")}
                src={"/assets/images/characters/monster.png"}
                alt="img_dino"
              />
            </div>
          )}
          <img
            className={cx(`roadmap-maker__weeks__week__img`)}
            src={`/assets/images/codingrace/wood_week_${
              i === TOTAL_MAKER_WEEKS_COUNT ? "maker_done" : i + 6
            }.svg`}
            alt={"ic_milestones"}
          />
        </div>
      );
    }
    return mileStoneLIst;
  };
  useEffect(() => {
    if (enrolled.loaded && !expired.loaded) {
      getExpired(enrolled.data.enrolled_id);
    }
  }, [enrolled]);

  return (
    <div
      className={cx("roadmap-maker-dashboard-container")}
      style={{
        backgroundImage: `url(${"/assets/images/roadmap/bg_roadmap_maker_dashboard.png"})`,
      }}
    >
      <div className={cx("roadmap-maker__chars")}></div>
      <div className={cx("roadmap-maker__weeks")}>
        <div
          className={cx("roadmap-maker__weeks__plain-bar")}
          style={{ "--full-length": `${BAR_FULL_LENGTH}px` }}
        >
          <div
            className={cx("roadmap-maker__weeks__plain-bar--active")}
            style={{ "--length": `${rtanPosition}px` }}
          ></div>
        </div>
        <div
          className={cx("roadmap-maker__weeks__plain-bar--active__rtan")}
          style={{
            "--length": `${rtanPosition - 7}px`,
          }}
        >
          <img
            className={cx("roadmap-maker__weeks__plain-bar--active__rtan__img")}
            src={`/assets/images/characters/rtan_tuxedo.png`}
            alt="img_rtan"
          />
        </div>
        {getMileStones()}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, expired, enrolled, enrolledMaker } = state;
  const { user } = authentication;
  return { user, authentication, expired, enrolled, enrolledMaker };
}

const connectedRoadmapMakerDashboard = connect(mapStateToProps, {
  getExpired: enrolledActions.getExpired,
  getMaker: enrolledActions.getMaker,
})(RoadmapMakerDashboard);
export { connectedRoadmapMakerDashboard as RoadmapMakerDashboard };
