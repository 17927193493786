import * as S from "./TapMenuBarDesktop.style";
import { desktopAnchors } from "./TapMenuAnchor.medels";
import { BroadcastList } from "../BroadcastList/BroadcastList";
import { useOnlineTheme } from "../../../../queries/pirates";

export const TapMenuBarDesktop = ({
  menuHandler,
  currentMenu,
  isFastQnaAvailable,
}) => {
  const theme = useOnlineTheme();
  return (
    <S.TapMenuBarContainer theme={theme.data}>
      <S.TapMenuContainer>
        {desktopAnchors.map((anchor, idx) => {
          if (anchor.key === "fastQna" && !isFastQnaAvailable) {
            return;
          }
          return (
            <S.TapMenuAnchor
              theme={theme.data}
              isActive={currentMenu === anchor.key}
              onClick={() => menuHandler(anchor)}
              key={anchor.key}
            >
              {anchor.name}
              {anchor.type === "outlink" && (
                <S.OutlinkLogo isActive={currentMenu === anchor.key} />
              )}
            </S.TapMenuAnchor>
          );
        })}
      </S.TapMenuContainer>
      <BroadcastList />
    </S.TapMenuBarContainer>
  );
};
