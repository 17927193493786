import privateInstance from "../../_helper/axios";

async function postB2bApply(data) {
  return await privateInstance
    .post(`/external/kinvestment/record`, data)
    .then((resp) => resp.data);
}
export const externalService = {
  postB2bApply,
};
