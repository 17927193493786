import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./WeeklyBreakPoint.modules.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

function WeeklyBreakPoint({ week, final }) {
  return (
    <div className={cx("WeeklyBPWrapper")}>
      <div className={cx("hrContainer")}>
        <hr />
      </div>
      {final ? (
        <img src={`/assets/images/codingrace/wood_week_final.svg`} alt="" />
      ) : (
        <img src={`/assets/images/codingrace/wood_week_${week}.svg`} alt="" />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedWeeklyBreakPoint = connect(
  mapStateToProps,
  {}
)(WeeklyBreakPoint);
export { connectedWeeklyBreakPoint as WeeklyBreakPoint };
