import * as S from "./Coupon.style";
import { numberWithCommas } from "../../../../_helper";
import { ArrowRightSmall } from "../../../organisms/Mypage/Icons/Icons";

export const Coupon = ({ couponData }) => {
  return (
    <S.CouponWrapper>
      <S.Coupon>
        <S.CouponName>{couponData.title}</S.CouponName>
        <S.CouponBenefit>
          {numberWithCommas(couponData.benefit_detail)}
          {couponData.benefit_type === "percent" ? "% 할인" : ""}
          {couponData.benefit_type === "target" ? "원 구매" : ""}
          {couponData.benefit_type === "amount" ? "원 할인" : ""}
        </S.CouponBenefit>
        <S.CouponMinimumCondition>
          -{" "}
          {couponData.minimum_condition > 0
            ? `${numberWithCommas(couponData.minimum_condition)}` +
              `원 이상 결제시`
            : "구매금액 제한 없음"}
        </S.CouponMinimumCondition>
        <S.CouponExpired>
          - {couponData.expired_at}까지 <b>D-{couponData.due}</b>
        </S.CouponExpired>
      </S.Coupon>
      <S.CouponCtaContainer>
        <S.Cta
          onClick={() => {
            if (couponData.title.indexOf("해적단") > -1) {
              window.location = `https://spartacodingclub.kr/catalog?category=%ED%95%B4%EC%A0%81%EB%8B%A8%20%EC%BD%94%EC%8A%A4`;
            } else {
              window.location =
                couponData.course_url ?? "https://spartacodingclub.kr/catalog";
            }
          }}
        >
          쿠폰 사용하기 <ArrowRightSmall color={"#141617"} />
        </S.Cta>
      </S.CouponCtaContainer>
    </S.CouponWrapper>
  );
};
