import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  useGetEnrolled,
  useGetEnrolledDetail,
  useGetEnrolledProgress,
} from "../_state/actions";
import { useAtom } from "jotai";
import { productNameAtom } from "../stores/productName";
import { PRODUCT_NAMES } from "../../_constants/productNames.constants";

export const useEnrolled = (enrolledId) => {
  const enrolled = useSelector((state) => state.enrolled);
  const getEnrolled = useGetEnrolled(enrolledId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!enrolled.loaded) {
      getEnrolled(dispatch);
    }
  }, [enrolled.loaded]);
  return enrolled;
};

export const useEnrolledProgress = (enrolledId) => {
  const enrolledProgress = useSelector((state) => state.enrolledProgress);
  const getEnrolledProgress = useGetEnrolledProgress(enrolledId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!enrolledProgress.loaded) {
      getEnrolledProgress(dispatch);
    }
  }, [enrolledProgress.loaded]);
  return enrolledProgress;
};

export const useEnrolledDetail = (enrolledId, detailId) => {
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const getEnrolledDetail = useGetEnrolledDetail(enrolledId, detailId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!enrolledDetail.loaded) {
      getEnrolledDetail(dispatch);
    }
  }, [enrolledDetail.loaded]);
  return enrolledDetail;
};

export const useCourseId = () => {
  const { enrolled_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const { data: enrolledData } = enrolled;
  const [courseId, setCourseId] = useState(null);
  useEffect(() => {
    if (enrolled.loaded && enrolledData) {
      setCourseId(enrolledData.course_id);
    }
  }, [enrolled.loaded, enrolledData]);
  return courseId;
};

export const useCurrentProduct = () => {
  const { enrolled_id } = useParams();
  const productName = useProductName(enrolled_id);
  return productName;
};

export const useProductName = (enrolledId) => {
  const enrolled = useEnrolled(enrolledId);
  const { data: enrolledData } = enrolled;
  const [productName, setProductName] = useAtom(productNameAtom);
  useEffect(() => {
    if (enrolled.loaded && enrolledData) {
      const rounds = enrolledData.rounds;
      if (rounds.is_gov_credit) {
        setProductName(PRODUCT_NAMES.KDC);
      } else if (rounds.is_hanghae) {
        setProductName(PRODUCT_NAMES.HANGHAE);
      } else if (rounds.is_chang) {
        setProductName(PRODUCT_NAMES.CHANG);
      } else if (rounds.is_chang_lite) {
        setProductName(PRODUCT_NAMES.CHANG_LITE);
      } else if (rounds.is_b2b) {
        setProductName(PRODUCT_NAMES.B2B);
      } else if (rounds.is_gov_training) {
        setProductName(PRODUCT_NAMES.KDT);
      } else {
        setProductName(PRODUCT_NAMES.SCC);
      }
    }
  }, [enrolled.loaded]);
  return productName;
};

export const useIsKDC = () => {
  const productName = useCurrentProduct();
  return productName === PRODUCT_NAMES.KDC;
};

export const useIsGovUserInfoNeeded = () => {
  const productName = useCurrentProduct();
  return productName === PRODUCT_NAMES.KDC || productName === PRODUCT_NAMES.KDT;
};
