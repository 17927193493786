import styled from "@emotion/styled";
import { Device } from "../../../../_styles/device";
import {
  neutralDay,
  mBody3,
  mHeader1,
  wBody3,
  wHeader2,
  scc,
} from "@teamsparta/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${scc.red10};
  ${Device.Desktop} {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.div`
  width: 328px;
  ${mHeader1};
  color: ${neutralDay.gray100};
  margin-bottom: 40px;
  text-align: left;
  ${Device.Desktop} {
    margin-bottom: 0px;
    height: 90px;
    ${wHeader2};
    text-align: center;
    color: ${neutralDay.gray100};
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 60px 16px;
  ${Device.Desktop} {
    align-items: center;
    width: 796px;
    padding: 100px 0px;
    gap: 60px;
  }
`;

export const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, ${scc.red10}, #ffffff);
  ${mBody3};
  width: 100%;
  height: 360px;
  color: ${neutralDay.gray60};
  ${Device.Desktop} {
    height: 342px;
    ${wBody3};
    color: ${neutralDay.gray60};
  }
`;

export const MoreButton = styled.p`
  cursor: pointer;
  font-weight: normal;
`;
