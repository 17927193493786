import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./ClassroomPage.module.scss";
import { connect } from "react-redux";
import { ClassroomCards } from "./ClassroomCards";
import { WelcomeMessage } from "./WelcomeMessage/WelcomeMessage";
import { EnrolledSummary } from "../../components/organisms/EnrolledSummary";
import { enrolledActions, usersActions } from "../../_state/actions";
import { Gnb } from "../../components/organisms/_layout/gnb/Gnb";
import { useLocation } from "react-router-dom";
import { ClassroomBanner } from "./ClassroomBanner/ClassroomBanner";
import { WelcomeModal } from "@teamsparta/auth-frontend";
import * as CPL from "@teamsparta/cross-platform-logger";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { FooterV2 } from "../../../_components/Footer/FooterV2";
import { useIsMobile } from "../../hooks/device";
import BottomBanner from "./BottomBanner/BottomBanner";
import { Banners } from "./BottomBanner/style";

const cx = classNames.bind(styles);

function ClassroomPageV2({
  user,
  activeEnrolleds,
  environment,
  getActiveEnrolleds,
}) {
  const [bannerData, setBannerData] = useState(null);
  const search = useLocation().search;
  useEffect(() => {
    const next = new URLSearchParams(search).get("next", "");
    if (!!next && next.length > 0) {
      window.location.href = decodeURIComponent(next);
    }
  }, [search]);

  useEffect(() => {
    sendCPLog("scc_classroomPage_view", {
      page_url: window.location.href,
    });
    return function cleanup() {
      // classroom을 나갈 때 channel talk 숨기기
      const { ChannelIO } = window;
      if (ChannelIO) {
        window.ChannelIO("shutdown");
      }
    };
  }, []);

  useEffect(() => {
    if (user && !activeEnrolleds.loaded) {
      getActiveEnrolleds();
    }
  }, [user]);

  const isMobile = useIsMobile();
  const bannerStyle = {
    backgroundImage: `url(${
      isMobile
        ? "/assets/images/rcmd_free/classroom_banner_mobile.png"
        : "/assets/images/rcmd_free/classroom_banner.png"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <div>
        {user && (
          <>
            {/*<Channeltalk />*/}
            <Gnb />
            <div className={cx("mainContainer")}>
              <WelcomeMessage />
              {bannerData && (
                <ClassroomBanner
                  href={bannerData.href}
                  thumbnail={bannerData.thumbnail}
                />
              )}
              <ClassroomCards />
              <EnrolledSummary />
              <Banners>
                <BottomBanner
                  title={"국비지원으로 시작하는 개발자 취업"}
                  cta={{
                    text: "더 알아보기",
                    href: "https://nbcamp.spartacodingclub.kr/",
                  }}
                  thumbnail={{
                    mobile:
                      "/assets/images/rcmd_free/classroom_banner_mobile.png",
                    desktop: "/assets/images/rcmd_free/classroom_banner.png",
                  }}
                />
              </Banners>
            </div>
            <FooterV2 />
          </>
        )}
      </div>
      <WelcomeModal CPLogger={CPL} />
      {/*<MarketingAgreementModal CPLogger={CPL} />*/}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, activeEnrolleds, environment } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    activeEnrolleds,
    environment,
  };
}

const connectedClassroomPageV2 = connect(mapStateToProps, {
  loginWithToken: usersActions.loginWithToken,
  getActiveEnrolleds: enrolledActions.getActiveEnrolleds,
})(ClassroomPageV2);
export { connectedClassroomPageV2 as ClassroomPageV2 };
