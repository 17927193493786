export const getDateDifference = (date1: Date, date2: Date): number => {
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();

  const difference = Math.abs(timestamp2 - timestamp1);

  const daysDifference = Math.floor(difference / (24 * 60 * 60 * 1000));

  return daysDifference;
};
