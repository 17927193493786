import * as S from "./FeedbackNotice.style";
import { FeedbackStatusTag } from "./FeedbackStatusTag/FeedbackStatusTag";
import { additional, ArrowRightLine } from "@teamsparta/design-system";
import { useUser } from "../../LecturePageV3/useInitialize";
import Dots from "../../../components/atoms/Dots/Dots";
import {
  KDCHomeworkFeedbackStatusModel,
  StatusEnum,
} from "../../../_state/constants";
import { history } from "../../../../_helpers";

export const FeedbackNotice = ({ status }) => {
  const statusModel = KDCHomeworkFeedbackStatusModel(status);
  const user = useUser();

  return (
    <S.FeedbackNoticeContainer status={status}>
      <S.StatusContainer status={status}>
        <FeedbackStatusTag
          type={"숙제 검토"}
          isThisStatus={status === StatusEnum.SUBMITTED}
        />
        <Dots count={3} />
        <FeedbackStatusTag
          type={"피드백 작성 중"}
          isThisStatus={status === StatusEnum.ACCEPTED}
        />
        <Dots count={3} />
        <FeedbackStatusTag
          type={"피드백 완료"}
          isThisStatus={status === StatusEnum.RESOLVED}
        />
      </S.StatusContainer>
      <S.StatusExplanation>
        <S.StatusExplanationTitle>{statusModel.title}</S.StatusExplanationTitle>
        <S.StatusExplanationContent>
          {statusModel.content}
          <S.GoToFeedbackPageCta
            isShow={status !== StatusEnum.SUBMITTED}
            onClick={() => {
              history.push(`/homework/feedback/${user._id}`);
            }}
          >
            피드백 페이지 보러가기
            <ArrowRightLine size={16} color={additional.blue100} />
          </S.GoToFeedbackPageCta>
        </S.StatusExplanationContent>
      </S.StatusExplanation>
    </S.FeedbackNoticeContainer>
  );
};
