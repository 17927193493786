import classNames from "classnames/bind";
import styles from "./CheckoutPage.module.scss";
import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getApiCheckinInfoUrl,
    getApiCheckinLikeUrl, getApiCheckinSkipUrl,
    getApiCheckoutInfoUrl, getApiCheckoutLikeUrl, getApiCheckoutSkipUrl,
    getGETAuthOptions,
    getPostAuthOptions
} from "../../_urls";
import {CheckinRead, CheckinReadEn} from "../../_components/CheckinRead";
import {history} from "../../_helpers";
import {message} from "antd";

const cx = classNames.bind(styles);

class CheckoutPageEn extends Component {
    state = {
        // 읽기
        offset: 1,
        num: NUM,
        checkouts: [],
        lecture: null,
        points: {checkin_done: 200},

        // 쓰기
        comment: null,
        round_id: this.props.match.params.round_id,
        week: parseInt(this.props.match.params.week),
        week_order: parseInt(this.props.match.params.week_order),
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const rjson = this._getCheckouts();
        rjson.then(result => {
            if (result.ok) {
                const {checkouts, lecture, points} = result;
                this.setState({
                    checkouts, lecture, points,
                })
            }
        })
    }

    _showSuccessMessage = () => {
        const {points} = this.state;
        if (points === undefined || points.checkout_done === undefined) {
            return;
        }
        message.success({
            content: `+${points.checkout_done} 포인트 적립 완료!`,
            className: 'custom-class',
            style: {
                position: 'absolute',
                top: '7px',
                right: '40px',
            },
        });
    };

    handleScroll = (e) => {
        if (document.getElementById('checkinList') === null) {
            return;
        }
        let offsetHeight = document.getElementById('checkinList').offsetHeight;
        let loadTrigger = offsetHeight - (e.target.body.offsetHeight + window.pageYOffset);
        if (loadTrigger < -100) {
            this._getMoreCheckouts();
        }
    };

    _getCheckouts = async () => {
        const {round_id, offset, num} = this.state;
        const endpoint = getApiCheckoutInfoUrl(round_id, offset, num);
        const options = getGETAuthOptions();
        try {
            const {offset} = this.state;
            this.setState({
                offset: offset + 1,
            });
            const response = await fetch(endpoint, options);
            return response.json();
        } catch (e) {
            alert('조회에 실패하였습니다.\n잠시 후 다시 시도해주세요.');
        }
    };

    _createCheckout = async () => {
        const {round_id, comment, week, week_order} = this.state;
        const {isHomework} = this.state.lecture;
        const data = {comment, week, week_order, isHomework};
        const endpoint = getApiCheckoutInfoUrl(round_id);
        const options = getPostAuthOptions(data);
        try {
            const response = await fetch(endpoint, options);
            return response.json();
        } catch (e) {
            alert('기록에 실패했습니다.\n잠시 후 다시 시도해주세요.');
        }
    };

    _likeCheckout = async (checkout_id) => {
        const {round_id} = this.state;
        const endpoint = getApiCheckoutLikeUrl(round_id, checkout_id);
        const options = getPostAuthOptions({});
        try {
            const response = await fetch(endpoint, options);
            return response.json();
        } catch (e) {
            alert('좋아요 누르기에 실패했습니다.\n잠시 후 다시 시도해주세요.');
        }
    };

    _createComment = () => {
        const {comment} = this.state;
        if (comment === null) {
            alert('내용을 입력해주세요.');
            document.getElementById('comment').focus();
            return;
        }

        const rjson = this._createCheckout();
        rjson.then(result => {
            if (result.ok) {

                this._showSuccessMessage();

                const {lecture} = this.state;
                const {round_id, _id, isHomework} = lecture;
                history.push(`/rounds/${round_id}/dashboard`, {fromCheckout: true});
            }
        });
    };

    _logSkip = async () => {
        const {round_id, week, week_order} = this.state;
        const {isHomework} = this.state.lecture;
        const data = {week, week_order, isHomework};
        const endpoint = getApiCheckoutSkipUrl(round_id);
        const options = getPostAuthOptions(data);
        try {
            const response = await fetch(endpoint, options);
            return response.json();
        } catch (e) {
            alert('기록에 실패했습니다.\n잠시 후 다시 시도해주세요.');
        }
    };

    _skipToLecture = () => {
        const rjson = this._logSkip();
        rjson.then(result => {
            if (result.ok) {
                const {lecture} = this.state;
                const {round_id} = lecture;
                history.push(`/en/rounds/${round_id}/dashboard`, {fromCheckout: true});
            }
        });
    };

    _getMoreCheckouts = () => {
        const rjson = this._getCheckouts();
        const checkouts_before = this.state.checkouts;
        rjson.then(result => {
            if (result.ok) {
                const {checkouts: checkouts} = result;
                this.setState({
                    checkouts: [...checkouts_before, ...checkouts],
                })
            }
        })
    };

    _handleInputChange = () => {
        let comment = document.getElementById('comment').value;
        this.setState({
            comment,
        })
    };

    _handleLike = (_id) => {
        const rjson = this._likeCheckout(_id);
        rjson.then(result => {
            if (result.ok) {
                let {checkout} = result;
                let {checkouts} = this.state;
                for (let i = 0; i < checkouts.length; i++) {
                    if (checkouts[i]._id === _id) {
                        checkouts[i].likes = checkout.likes;
                        checkouts[i].liked = true;
                    }
                }

                this.setState({
                    checkouts: [...checkouts],
                })
            } else {
                alert(result.reason);
            }
        })
    };

    render() {
        const {checkouts, points} = this.state;
        const {user} = this.props;
        return (
            <div className={cx('checkinPageContainer')}>
                <div className={cx('checkinPageContentsContainer')}>
                    {/*고정영역*/}
                    <div className={cx('checkinFixedContainer')}>
                        <div className={cx('checkinLeftContainer')}>
                            {/* 체크인 create */}
                            <div className={cx('checkinCreateContainer')}>
                                {/* 체크인 create Header */}
                                <div className={cx('checkinHeaderWrapper')}>
                                    <h2>Good work today, {user.name}! ☺️️</h2>
                                    <img src={('/assets/images/checkout/checkout.png')}
                                         alt="checkout imagge"/>
                                    {/*<h6><CaretRightOutlined style={{verticalAlign: "-0.125em"}}/>&nbsp;체크인이 무엇인가요?</h6>*/}
                                    <div className={cx('desc')}>
                                        Great! What about writing how you feel and what you learned! 😊
                                    </div>
                                </div>

                                {/* 체크인 입력*/}
                                <div className={cx('checkinInputWrapper')}>
                            <textarea onChange={this._handleInputChange} id="comment" cols="30"
                                      placeholder={`ex. I am so proud of myself! It was so much fun to see my codes were actually WORKING. I'm looking forward to the next step!!! :)`}/>
                                </div>

                                {/* 체크인 올리기, skip 버튼 */}
                                <div className={cx('checkinButtonWrapper')}>
                                    <button onClick={this._createComment}>
                                        Submit
                                    </button>

                                    <div onClick={this._skipToLecture} className={cx('skipWrapper')}>
                                        skip
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={cx('checkinRightContainer')}>
                            <div className={cx('checkinReadHeader')}>
                                <h4>Words of Your Peers 🤗</h4>
                            </div>
                        </div>
                    </div>
                    {/* 체크인 read */}
                    <div className={cx('checkinReadContainer')}>
                        <div className={cx('cover')}>

                        </div>
                        <CheckinReadEn checkins={checkouts} _handleLike={this._handleLike}/>
                        {/*<button onClick={this._getMoreCheckins}>더 가져오기</button>*/}
                    </div>
                </div>
            </div>
        )
    }
}

const NUM = 20;

function mapStateToProps(state) {
    const {authentication} = state;
    const {user} = authentication;
    return {
        authentication,
        user
    }
}

const connectedCheckoutPageEn = connect(mapStateToProps)(CheckoutPageEn);
export {connectedCheckoutPageEn as CheckoutPageEn}