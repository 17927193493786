import { pointConstants } from "../_constants";

const initState = {
  loaded: false,
};

export function pointInfo(state = initState, action) {
  switch (action.type) {
    case pointConstants.POINT_INFO_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case pointConstants.POINT_INFO_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case pointConstants.POINT_INFO_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
