import { BADGE_CONSTANT } from "../constants/badge.constants";

const initState = {
  loaded: false,
};

export function badgesRound(state = initState, action) {
  switch (action.type) {
    case BADGE_CONSTANT.BADGES_ROUND_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case BADGE_CONSTANT.BADGES_ROUND_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case BADGE_CONSTANT.BADGES_ROUND_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
