import { css } from "styled-components";

export const H1Web = css`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  /* identical to box height, or 56px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const H2Web = css`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 56px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const H3Web = css`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 56px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const Subtitle1Web = css`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 56px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const Body1Web = css`
  /* Web/Header/Header1_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 56px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const Caption1Web = css`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 56px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const H0Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const H1Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const H2Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const H3Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const Subtitle1Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const Body1Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const Caption1Mobile = css`
  /* Mobile/Header/Header0_700 */

  font-family: Pretendard;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 140%;
  /* or 39px */

  /* Gray/Gray100 */

  color: #141617;
`;

export const fontPretendard =
  'Pretendard, -apple-system, “system-ui”, "Malgun Gothic", "맑은 고딕", sans-serif;';
