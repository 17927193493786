import { useMutation, useQueryClient } from "react-query";
import { homeworksService } from "../../../_state/services";

export const usePostMyHomeworks = (
  enrolledsHomeworksId,
  enrolledId,
  homeworkId
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (enrolledHomeworkDetails) => {
      return await homeworksService.postMyHomeworks(
        enrolledsHomeworksId,
        enrolledId,
        homeworkId,
        enrolledHomeworkDetails
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["homeworks", enrolledsHomeworksId]);
        queryClient.invalidateQueries([
          "orderedEnrollmentContents",
          enrolledId,
        ]);
      },
      onError: (error) => {
        console.log("onError", error);
      },
    }
  );
};
