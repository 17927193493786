import { reducer } from "../reducers";
import { action } from "../actions/action";
import privateInstance from "../../../v2/_helper/axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const initState = {
  loaded: false,
};

const ROUND_TEST = {
  REQUEST: "ROUND_TEST_REQUEST",
  SUCCESS: "ROUND_TEST_SUCCESS",
  FAILURE: "ROUND_TEST_FAILURE",
};

async function _service(roundId) {
  const res = await privateInstance.get(`rounds/${roundId}/test`);
  return res.data?.context;
}

export function getRoundTest(roundId) {
  return action(_service, ROUND_TEST, [roundId]);
}

export function roundTest(state = initState, action) {
  return reducer(state, action, ROUND_TEST);
}

export const useTest = (roundId) => {
  const roundTest = useSelector((state) => state.roundTest);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoundTest(roundId));
  }, []);
  return roundTest;
};
