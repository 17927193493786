import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { history } from "../../../../_helpers";
import { useNavigateToRoadmap } from "../../../hooks/useNavigateToRoadmap";
import {
  useCourseId,
  useEnrolled,
  useEnrolledDetail,
  useIsKDC,
  useProductName,
} from "../../../hooks/useProductName";
import { CONTENT_TYPE } from "../model/LectureTypes";
import {
  ETC_PASS_CHECKPOINTS,
  KDC_PASS_CHECKPOINTS,
} from "../model/StudyCheckpoint";
import { useAccessCheckOnTrigger } from "../repository/useAccessCheck";
import { useDoEnrolledDetail } from "../repository/useDoEnrolledDetail";
import { useOrderedEnrollmentContents } from "../repository/useOrderedEnrollmentContents";
import { useSetPassed } from "../repository/useSetPassed";

import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useGetCourse } from "../../../../_actions";
import { useLogLectureCompleted } from "../../../hooks/useLoadLogData";
import useThrottle from "../../../hooks/useThrottle";
import {
  devCareerPrepCoursesList,
  licenseCoursesList,
} from "../../../pages/FreeFinishPage/variables";
import { getAfterFinishUrl } from "../utils/getAfterFinishUrl";

export const usePathDependentStateRef = () => {
  const isApiCalledRef = useRef(new Map());
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    isApiCalledRef.current.clear();
  }, [pathname]);

  const setCalled = (key) => {
    isApiCalledRef.current.set(key, true);
  };

  const isCalled = (key) => {
    return isApiCalledRef.current.get(key) || false;
  };

  return { setCalled, isCalled };
};

export const useSetPassedCallOnce = (currentCheckpoint) => {
  const { setCalled, isCalled } = usePathDependentStateRef();
  const setPass = useSetPassed();
  const isKdc = useIsKDC();

  const shouldCallSetPass = useCallback(() => {
    const funnelConditions = isKdc
      ? KDC_PASS_CHECKPOINTS
      : ETC_PASS_CHECKPOINTS;

    return funnelConditions.includes(currentCheckpoint);
  }, [isKdc, currentCheckpoint]);

  const callSetPass = useCallback(() => {
    if (!isCalled("setPass") && shouldCallSetPass()) {
      setCalled("setPass");
      // setPass.mutate();
    }
  }, [isCalled, setCalled, shouldCallSetPass]);

  return callSetPass;
};

const checkSkipAvailable = (enrolledDetail) => {
  const { seen_date, seen_first_date, playtime: playtimesec } = enrolledDetail;
  const limitPercent = 0.4;

  // 정부 크레딧 과목일 경우, 강의 시간의 50% 이상을 수강(체류)하지 않았을 경우 넘어가지 못함.
  // 현재시간
  const now = new Date();
  // 현재시간에서 강의를 보기 시작한 시간이 전체 강의 시간의 50% 이상인지 확인
  const time_stamp = seen_first_date
    ? new Date(seen_first_date)
    : new Date(seen_date);
  const stayedMilliseconds = now.getTime() - time_stamp.getTime();
  const stayedSeconds = Math.floor(stayedMilliseconds / 1000);
  const threshold = Math.floor(playtimesec * limitPercent);

  // return stayedSeconds >= threshold;
  return true;
};

export const useDoEnrolledDetailOnce = () => {
  const { setCalled, isCalled } = usePathDependentStateRef();
  const doEnrolledDetail = useDoEnrolledDetail();
  const { enrolled_id, detail_id } = useParams();
  const enrolledDetail = useEnrolledDetail(enrolled_id, detail_id);
  const [throttledCheckSkipAvailable, cancelThrottle, getLastResult] =
    useThrottle(checkSkipAvailable, 3000);

  const isKdc = useIsKDC();

  const logLectureCompleted = useLogLectureCompleted();

  const shouldCallDoDetail = useCallback(
    (currentCheckPoint) => {
      const funnelConditions = isKdc
        ? KDC_PASS_CHECKPOINTS
        : ETC_PASS_CHECKPOINTS;

      if (isKdc) {
        throttledCheckSkipAvailable(enrolledDetail.data);
      }

      return isKdc
        ? getLastResult() && funnelConditions.includes(currentCheckPoint)
        : funnelConditions.includes(currentCheckPoint);
    },
    [isKdc, enrolledDetail.loaded]
  );

  const callDoEnrolledDetail = useCallback(
    (enrolledId, enrolledDetailId, currentCheckPoint) => {
      if (
        !isCalled("doEnrolledDetail") &&
        shouldCallDoDetail(currentCheckPoint)
      ) {
        setCalled("doEnrolledDetail");
        doEnrolledDetail.mutate({ enrolledId, enrolledDetailId });
        logLectureCompleted();
      }
    },
    [isCalled, setCalled, shouldCallDoDetail, isKdc, logLectureCompleted]
  );

  return callDoEnrolledDetail;
};

const compareContents = (content, targetContent) => {
  if (content.contentType === CONTENT_TYPE.NPS) {
    return (
      content.week === targetContent.week &&
      targetContent.contentType === CONTENT_TYPE.NPS
    );
  } else if (content.contentType === CONTENT_TYPE.EVALUATION) {
    return (
      content.week === targetContent.week &&
      targetContent.contentType === CONTENT_TYPE.EVALUATION
    );
  } else {
    return content.contentId === targetContent.contentId;
  }
};

export const useIsLastOfOEC = (content) => {
  const [isLast, setIsLast] = useState(false);

  const nextContent = useNextEnrollmentContent(content);
  useEffect(() => {
    if (nextContent && nextContent.contentType === CONTENT_TYPE.NIL) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }
  }, [nextContent]);
  return isLast;
};

export const useCurrentOrderedEnrollmentContents = () => {
  const { enrolled_id } = useParams();
  const currentOEC = useOrderedEnrollmentContents(enrolled_id);
  return currentOEC;
};

export const useCurrentContentByType = (contentType) => {
  const [contentId, setContentId] = useState(null);
  const [week, setWeek] = useState(null);
  const params = useParams();

  const npsContent = useFindNpsContentByWeek(week);
  const enrollmentContent = useFindEnrollmentContentQueryById(contentId);
  const updateStateBasedOnContentType = () => {
    if (contentType === CONTENT_TYPE.NPS) {
      setWeek(+params.week);
    } else if (contentType === CONTENT_TYPE.LECTURE) {
      setContentId(params.detail_id);
    } else if (contentType === CONTENT_TYPE.HOMEWORK) {
      setContentId(params.enrolled_homework_id);
    } else if (contentType === CONTENT_TYPE.EVALUATION) {
      setContentId(params.enrolled_evaluation_id);
    }
  };

  useEffect(() => {
    updateStateBasedOnContentType();
  }, [contentType, params]);

  const getContentBasedOnType = () => {
    if (contentType === CONTENT_TYPE.NPS) {
      return npsContent;
    }
    return enrollmentContent.content;
  };

  return getContentBasedOnType();
};

export const useFindEnrollmentContentQueryById = (contentId) => {
  const OECQuery = useCurrentOrderedEnrollmentContents();
  const [content, setContent] = useState(null);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    if (OECQuery.isSuccess) {
      const OEC = OECQuery.data?.data;
      const foundIndex = OEC.findIndex(
        (enrollmentContent) => enrollmentContent.contentId === contentId
      );
      if (foundIndex !== -1) {
        setIndex(foundIndex);
        setContent(OEC[foundIndex]);
      } else {
        setIndex(-1);
        setContent(null);
      }
    }
  }, [contentId, OECQuery.isSuccess]);

  return { content, index };
};

/**TODO: 위의 커스텀훅과 비교하여 중복 로직 제거.*/
export const useFindEnrollmentContentByContent = (findingContent) => {
  const OECQuery = useCurrentOrderedEnrollmentContents();
  const [content, setContent] = useState(null);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    if (findingContent && OECQuery.isSuccess) {
      const OEC = OECQuery.data?.data;
      const foundIndex = OEC.findIndex((enrollmentContent) =>
        compareContents(findingContent, enrollmentContent)
      );
      if (foundIndex !== -1) {
        setIndex(foundIndex);
        setContent(OEC[foundIndex]);
      } else {
        setIndex(-1);
        setContent(null);
      }
    }
  }, [findingContent, OECQuery.isSuccess]);

  return { content, index };
};

export const useFindNpsContentByWeek = (week) => {
  const OECQuery = useCurrentOrderedEnrollmentContents();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (OECQuery.isSuccess) {
      const OEC = OECQuery.data?.data;
      const foundIndex = OEC.findIndex(
        (enrollmentContent) =>
          enrollmentContent.week === week &&
          enrollmentContent.contentType === CONTENT_TYPE.NPS
      );
      if (foundIndex !== -1) {
        setContent(OEC[foundIndex]);
      } else {
        setContent(null);
      }
    }
  }, [week, OECQuery.isSuccess]);

  return content;
};

export const useFindEnrollmentContentByIndex = (index) => {
  const OECQuery = useCurrentOrderedEnrollmentContents();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (OECQuery.isSuccess) {
      const OEC = OECQuery.data?.data;
      if (index !== -1) {
        setContent(OEC[index]);
      } else {
        setContent(null);
      }
    }
  }, [index, OECQuery.isSuccess]);

  return content;
};

export const useLastContentOfOEC = () => {
  const OECQuery = useCurrentOrderedEnrollmentContents();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (OECQuery.isSuccess) {
      const OEC = OECQuery.data?.data;
      /** 마지막 컨텐츠는 NIL 이기 때문에 NIL 직전이 실질적인 마지막이다.*/
      const lastContent = OEC[OEC.length - 2];
      setContent(lastContent);
    }
  }, [OECQuery.isSuccess]);

  return content;
};

export const useIsFirstOfOEC = (contentId) => {
  const [isFirst, setIsFirst] = useState(false);
  return isFirst;
};

export const useNextEnrollmentContent = (content) => {
  const [contentId, setContentId] = useState(null);
  useEffect(() => {
    if (content) {
      setContentId(content.contentId);
    }
  }, [content]);
  const currentContentQuery = useFindEnrollmentContentByContent(content);
  const nextContentIndex = currentContentQuery.index + 1;
  const nextContent = useFindEnrollmentContentByIndex(nextContentIndex);
  return nextContent;
};

export const usePrevEnrollmentContentId = (contentId) => {
  const [prevContentId] = useState(null);
  return prevContentId;
};

export const useIsCareerPrepCourse = (enrolledId) => {
  const enrolled = useEnrolled(enrolledId);
  const { data: enrolledData } = enrolled;
  const courseId = enrolledData?.course_id;
  const [isCareerPrepCourse, setIsCareerPrepCourse] = useState(false);
  useEffect(() => {
    if (enrolledData && courseId) {
      /**TODO: 결국엔 아래와 같은 로직이 되어야 함. 23.12 현재는 하드코딩*/
      // const courseType = enrolledData?.courseType;
      // if (courseType === COURSE_TYPE.CAREER_PREP) {
      //     setIsCareerPrepCourse(true);
      // }
      if (devCareerPrepCoursesList.includes(courseId)) {
        setIsCareerPrepCourse(true);
      }
    }
  }, [enrolledData, courseId]);
  return isCareerPrepCourse;
};

export const useIsLicenseCourses = (courseId) => {
  const isLicenseCourses = useMemo(() => {
    return courseId ? licenseCoursesList.includes(courseId) : false;
  }, [courseId]);

  return isLicenseCourses;
};

const useMakeFinishRouter = () => {
  const { enrolled_id } = useParams();
  const productName = useProductName(enrolled_id);

  const finishUrl = getAfterFinishUrl({ productName, enrolled_id });

  return finishUrl;
};

const useMakeContentRouter = (content) => {
  const contentType = content?.contentType;
  const contentId = content?.contentId;
  const { enrolled_id } = useParams();
  const courseId = useCourseId();
  const [contentRouter, setContentRouter] = useState(null);
  const endUrl = useMakeFinishRouter();
  useEffect(() => {
    if (courseId && contentType) {
      if (contentId) {
        if (contentType === CONTENT_TYPE.LECTURE) {
          setContentRouter(
            `/enrolleds/${enrolled_id}/edetails/${contentId}?course_id=${courseId}`
          );
        } else if (contentType === CONTENT_TYPE.HOMEWORK) {
          setContentRouter(`/enrolleds/${enrolled_id}/ehomeworks/${contentId}`);
        } else if (contentType === CONTENT_TYPE.NIL) {
          /** NIL 은 OEC 가 끝났다는 것을 의미한다. */

          setContentRouter(endUrl);
        } else if (contentType === CONTENT_TYPE.EVALUATION) {
          setContentRouter(
            `/enrolleds/${enrolled_id}/evaluations/${contentId}/results`
          );
        }
      }
      if (contentType === CONTENT_TYPE.NPS) {
        setContentRouter(`/enrolleds/${enrolled_id}/npsV3/${content.week}`);
      }
    }
  }, [contentType, contentId, enrolled_id, courseId, endUrl]);
  return contentRouter;
};

export const useCurrentContentByPathname = () => {
  const { pathname } = useLocation();
  const [contentType, setContentType] = useState(null);
  const currentContent = useCurrentContentByType(contentType);
  useEffect(() => {
    if (pathname.includes("nps")) {
      setContentType(CONTENT_TYPE.NPS);
    } else if (pathname.includes("ehomeworks")) {
      setContentType(CONTENT_TYPE.HOMEWORK);
    } else if (pathname.includes("evaluations")) {
      setContentType(CONTENT_TYPE.EVALUATION);
    } else {
      setContentType(CONTENT_TYPE.LECTURE);
    }
  }, [pathname]);
  return currentContent;
};

const errorMessageConverter = (error) => {
  const message = error.response?.data?.message;
  switch (message) {
    case "PREVIOUS_LECTURE_NOT_PASSED":
      return "강의 재생 후 다시 시도해주세요!";
    case "PREVIOUS_KDC_LECTURE_NOT_PASSED":
      return "국비 과정 운영 방침 상, 총 학습 시간이 영상 길이의 50%를 넘어야 다음 강의로 넘어갈 수 있습니다.\n\nex) 영상 길이가 10분인 경우, 5분 이상 강의에 머물러야 합니다.";
    case "PREVIOUS_HOMEWORK_NOT_PASSED":
      return "숙제를 제출한 뒤 다시 시도해주세요!";
    case "PREVIOUS_FEEDBACK_NOT_PASSED":
      return "숙제 제출 및 피드백이 완료되어야 다음강의로 넘어갈 수 있습니다. \n 숙제는 제출 후 24시간 이내 검토하여 카카오톡으로 안내드릴 예정입니다.";
    case "NOT_YET_OPENED":
      return "아직 수강할 수 없는 강의입니다!";
    case "DAILY_LIMIT_EXCEEDED":
      return "일일 수강 횟수를 초과하였습니다!";
    case "PREVIOUS_EVALUATION_NOT_PASSED":
      return "테스트를 제출한 뒤 다시 시도해주세요!";
    default:
      return "오류가 발생하였습니다. 새로고침 후 다시 시도해주세요!";
  }
};

export const useNavigateToTargetContent = (targetContent, componentValue) => {
  const [isSkip, setIsSkip] = useState(false);
  const accessCheckQuery = useAccessCheckOnTrigger(
    targetContent,
    setIsSkip,
    componentValue
  );
  const contentId = targetContent?.contentId;
  const contentRouter = useMakeContentRouter(targetContent);
  const navigateToRoadmap = useNavigateToRoadmap();
  const queryClient = useQueryClient();
  const enrolledId = useParams().enrolled_id;

  useEffect(() => {
    if (accessCheckQuery.isError) {
      const errorMessage = errorMessageConverter(accessCheckQuery.error);
      alert(errorMessage);
      if (errorMessage === "알 수 없는 오류가 발생하였습니다!") {
        window.location.reload();
      } else if (
        errorMessage === "아직 수강할 수 없는 강의입니다!" ||
        errorMessage === "일일 수강 횟수를 초과하였습니다!"
      ) {
        navigateToRoadmap();
      }
    }
    return () => {
      queryClient.resetQueries("accessCheck");
    };
  }, [accessCheckQuery.isError]);
  useEffect(() => {
    /** 이동에 사용되는 로직. 매번 넘어가도 되는지 여부를 서버에서 받아오기 위해
     * 움직이고 난뒤에 초기화 과정을 거친다. */
    if (contentRouter && accessCheckQuery.isSuccess && accessCheckQuery.data) {
      history.push(contentRouter);
      queryClient.resetQueries(["accessCheck", contentId]);
      queryClient.resetQueries(["orderedEnrollmentContents", enrolledId]);
    }
  }, [accessCheckQuery.isSuccess]);
  /**TODO: nps 로 넘어가기 위한 로직. 가독성 개선 필요. */
  useEffect(() => {
    if (isSkip) {
      history.push(contentRouter);
    }
    return () => {
      setIsSkip(false);
    };
  }, [isSkip]);
  const navigateToContent = async () => {
    await accessCheckQuery.fetchAccessCheck();
  };

  return navigateToContent;
};

export const useNavigateToNextContent = () => {
  const currentContent = useCurrentContentByPathname();
  const nextContent = useNextEnrollmentContent(currentContent);
  const navigateToTargetContent = useNavigateToTargetContent(
    nextContent,
    "nextButton"
  );
  return navigateToTargetContent;
};

const groupByWeek = (list) => {
  let currentWeek = null;
  let index = 0;
  let isCheckActive = false;
  return list?.reduce((acc, item) => {
    // nil은 syllabus에서 제외
    if (item.contentType === "NIL") return acc;

    const week = item.week;

    if (week !== currentWeek) {
      currentWeek = week;
      index = 1;
    } else {
      index++;
    }

    if (!acc[week]) {
      acc[week] = [];
    }

    const content = { ...item, order: index };

    if (!isCheckActive && !item.passed && item.contentType !== "nps") {
      content["isNowPlayingIndex"] = true;
      isCheckActive = true;
    } else content["isNowPlayingIndex"] = false;

    acc[week].push(content);

    return acc;
  }, {});
};
export const useSyllabus = () => {
  // OEC로 weeks 리스트 만들기
  const OECQuery = useCurrentOrderedEnrollmentContents();

  const [syllabus, setSyllabus] = useState(null);

  useEffect(() => {
    if (OECQuery.isSuccess) {
      const OEC = OECQuery.data?.data;

      const weeks = groupByWeek(OEC);
      const data = Object.entries(weeks).map(([week, contents]) => {
        const totalplaytime = contents?.reduce((acc, { playtime }) => {
          return acc + (playtime !== undefined ? playtime : 0);
        }, 0);

        return {
          week: week,
          totalPlaytime: totalplaytime,
          contents: contents,
        };
      });
      setSyllabus(data);
    }
  }, [OECQuery.isSuccess]);

  return syllabus;
};
export const useCourseData = () => {
  const courseId = useCourseId();
  const course = useSelector((state) => state.course);
  const getCourse = useGetCourse(courseId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (courseId) {
      getCourse(dispatch);
    }
  }, [courseId]);

  return course;
};

export const useSyllabusUnit = (courseId) => {
  const weekUnit = "주차";
  const dayUnit = "일차";
  const isLicenseCourse = useIsLicenseCourses(courseId);
  return isLicenseCourse ? dayUnit : weekUnit;
};

export const useIsCodingRaceShow = () => {
  const lastContent = useLastContentOfOEC();
  const lastWeek = lastContent?.week;
  const isCodingRaceShow = lastWeek < 6;
  return isCodingRaceShow;
};

export const useNextContentCtaText = (
  isFeedbackRequired,
  isPossibleGoNext,
  defaultText
) => {
  const currentContent = useCurrentContentByPathname();
  const isLastContent = useIsLastOfOEC(currentContent);
  if (isLastContent) {
    if (isFeedbackRequired && !isPossibleGoNext) {
      return "숙제 제출하기";
    } else {
      return "완주하기";
    }
  } else if (isFeedbackRequired && isPossibleGoNext) {
    return "다음 강의";
  }
  return defaultText;
};
