import styled from "styled-components";
import { Device } from "../../../../../_styles/device";
import { neutralDay, scc } from "@teamsparta/design-system";
import { fontPretendard } from "../../../../styles/font";

export const BasicInfoWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 25px 0px;

  ${Device.Desktop} {
    //margin: 0 auto 25px auto;
    margin-bottom: 25px;
    width: 100%;
    //width: 690px;
    //max-width: 690px;
    //padding: 28px 40px;
    padding: 0;
    &.wide {
      border: none;
      box-shadow: none;
    }
  }
`;

export const BasicInfoHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 12px auto;
  ${Device.Desktop} {
    margin: 0;
    margin-bottom: 12px;
  }
`;

export const BasicInfoTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #212529;
  font-family: ${fontPretendard};

  ${Device.Desktop} {
    font-size: 20px;
    line-height: 140%;
  }
`;

export const SaveButton = styled.div`
  cursor: ${(props) => (props.isActive ? "pointer" : "default")};
  width: 72px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${scc.red100};

  font-family: ${fontPretendard};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  color: ${scc.red100};
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};

  ${Device.Desktop} {
    width: 80px;
    height: 48px;

    font-size: 16px;
    :hover {
      transition: 0.3s;
      background-color: ${(props) => (props.isActive ? scc.red15 : "")};
    }
  }
`;

export const InfoInputContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto 40px auto;
  ${Device.Desktop} {
    border: 1px solid ${neutralDay.gray20};
    border-radius: 12px;
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
    padding: 40px;
  }
`;

export const Divider = styled.div`
  height: 8px;
  background: ${neutralDay.gray0};
  margin: 40px 0;
  display: block;
  ${Device.Desktop} {
    display: none;
  }
`;

export const MarketingContainer = styled.div`
  width: 90%;
  color: #000000;
  margin: 0 auto;

  ${Device.Desktop} {
    border: 1px solid ${neutralDay.gray20};
    border-radius: 12px;
    width: 100%;
    margin: 0;
    padding: 40px;
    margin-bottom: 24px;
  }
`;

export const MarketingInnerContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  line-height: 130%;
  position: relative;

  ${Device.Desktop} {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const MarketingTitle = styled.span`
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  margin-right: 35px;
  font-family: ${fontPretendard};

  ${Device.Desktop} {
    font-size: 20px;
    margin-right: 0;
  }
`;

export const MarketingCaption = styled.span`
  font-size: 12px;
  font-family: ${fontPretendard};
  color: ${neutralDay.gray70};
  font-weight: 500;

  ${Device.Desktop} {
    font-size: 14px;
  }
`;

export const BasicinfoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  ${Device.Desktop} {
    justify-content: flex-start;
  }
`;

export const BasicInfoText = styled.span`
  padding: 12px 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: ${neutralDay.gray60};
`;
