import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { enrolledHomeworkDetailsAtom } from "../../../stores/enrolledHomeworkDetails";
import { useMyHomeworks } from "../repository/useMyHomeworks";
import { validateEmail } from "../../../_helper";

/**TODO: 렌더링 최적화 신경써야함.*/
export const useMakeEnrolledHomeworkDetails = (
  enrolledId,
  enrolledHomeworkId
) => {
  const [enrolledHomeworkDetails, setEnrolledHomeworkDetails] = useAtom(
    enrolledHomeworkDetailsAtom
  );
  const homeworks = useMyHomeworks(enrolledHomeworkId);
  useEffect(() => {
    if (homeworks.isSuccess && homeworks.data) {
      const homeworkDetails =
        homeworks.data.context?.enrolleds_homework_details;
      if (homeworkDetails) {
        setEnrolledHomeworkDetails(homeworkDetails);
      }
    }
  }, [homeworks.isSuccess, homeworks.data]);
  return enrolledHomeworkDetails;
};

export const useIsAllHomeworkSubmitted = (enrolledHomeworkDetails) => {
  const [isAllHomeworkSubmitted, setIsAllHomeworkSubmitted] = useState(false);
  useEffect(() => {
    if (enrolledHomeworkDetails.length > 0) {
      setIsAllHomeworkSubmitted(
        enrolledHomeworkDetails.every(
          (enrolledHomeworkDetail) =>
            enrolledHomeworkDetail.submitted_answer !== "" &&
            !(
              enrolledHomeworkDetail.language === "slack_email" &&
              !validateEmail(enrolledHomeworkDetail.submitted_answer)
            )
        )
      );
    }
  }, [enrolledHomeworkDetails]);
  return isAllHomeworkSubmitted;
};
