import styled from "@emotion/styled";
export const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity as per your requirement */
  z-index: 9; /* Make sure the overlay appears above other elements */
`;
export const BottomSheetWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  min-height: calc(100vh - 320px);
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(100%)")};
  z-index: 10;
  pointer-events: auto;
`;

export const BottomSheetHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.16px;
  line-height: 1.44;
  margin: 20px;
  color: #141617;
`;

export const ExitButton = styled.img`
  display: flex;
  justify-content: flex-end;
  margin: 16px;
  color: #141617;
`;

export const BackButton = styled.img`
  display: flex;
  justify-content: flex-end;
  margin: 16px;
  color: #141617;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  margin: 16px;
  color: #141617;
`;

export const OptionWrapper = styled.div`
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 18px;
  margin-left: 16px;
  color: ${({ isOn }) => (isOn ? "#e83442" : "#141617")};
`;

export const OptionIconWrapper = styled.img``;
export const OptionTitleWrapper = styled.div``;
export const OptionTextWrapper = styled.div`
  color: #8b8b8b;
`;
