import React, { useState } from "react";
import * as S from "./ErrorTypeSelector.style";
import { ErrorTypeSelector } from "./ErrorTypeSelector";
import { ErrorReport } from "./ErrorReport";
import { ContentsErrorSubmissionNotification } from "./ContentsErrorSubmissionNotification";

export const ErrorReportTab = () => {
  const [reportState, setReportState] = useState("SELECT_TYPE"); // 'SELECT_TYPE', 'CONTENTS_ERROR_REPORT', 'CONTENTS_ERROR_REPORT_SUBMITTED'

  const ReportComponents = {
    SELECT_TYPE: () => <ErrorTypeSelector setReportState={setReportState} />,
    CONTENTS_ERROR_REPORT: () => (
      <ErrorReport setReportState={setReportState} />
    ),
    CONTENTS_ERROR_REPORT_SUBMITTED: () => (
      <ContentsErrorSubmissionNotification />
    ),
  };

  return <>{ReportComponents[reportState]()}</>;
};
