export const urgConstants = {
  CREATE_URG_REQUEST: "CREATE_URG_REQUEST",
  CREATE_URG_SUCCESS: "CREATE_URG_SUCCESS",
  CREATE_URG_FAILURE: "CREATE_URG_FAILURE",

  GET_URG_REQUEST: "GET_URG_REQUEST",
  GET_URG_SUCCESS: "GET_URG_SUCCESS",
  GET_URG_FAILURE: "GET_URG_FAILURE",

  GET_USER_GROUP_INFO_REQUEST: "GET_USER_GROUP_INFO_REQUEST",
  GET_USER_GROUP_INFO_SUCCESS: "GET_USER_GROUP_INFO_SUCCESS",
  GET_USER_GROUP_INFO_FAILURE: "GET_USER_GROUP_INFO_FAILURE",

  GET_WEEKS_AFTER_START_REQUEST: "GET_WEEKS_AFTER_START_REQUEST",
  GET_WEEKS_AFTER_START_SUCCESS: "GET_WEEKS_AFTER_START_SUCCESS",
  GET_WEEKS_AFTER_START_FAILURE: "GET_WEEKS_AFTER_START_FAILURE",
};
