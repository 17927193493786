export const parseUserAgent = (ua) => {
  const userAgent = ua.toLowerCase();

  let os = "unknown";
  let browser = "unknown";

  let osVersion = "";
  let match;
  if ((match = /mac os x ([0-9_]+)/.exec(userAgent))) {
    osVersion = match[1].replace(/_/g, "."); // Replace '_' with '.'
    os = "mac " + osVersion;
  } else if (userAgent.includes("windows")) {
    os = "windows";
  } else if (userAgent.includes("linux")) {
    os = "linux";
  } else if ((match = /iphone os ([0-9_]+)/.exec(userAgent))) {
    osVersion = match[1].replace(/_/g, ".");
    os = "ios|iphone " + osVersion;
  } else if ((match = /cpu os ([0-9_]+)/.exec(userAgent))) {
    osVersion = match[1].replace(/_/g, ".");
    os = "ios|ipad " + osVersion;
  } else if ((match = /android ([0-9.]+)/.exec(userAgent))) {
    osVersion = match[1];
    os = "android " + osVersion;
  }

  // Browser Version extraction
  let browserVersion = "";
  if ((match = /chrome\/([0-9.]+)/.exec(userAgent))) {
    browserVersion = match[1];
    browser = "chrome " + browserVersion;
  } else if ((match = /safari\/([0-9.]+)/.exec(userAgent))) {
    browserVersion = match[1];
    browser = "safari " + browserVersion;
  } else if ((match = /firefox\/([0-9.]+)/.exec(userAgent))) {
    browserVersion = match[1];
    browser = "firefox " + browserVersion;
  } else if ((match = /edg\/([0-9.]+)/.exec(userAgent))) {
    browserVersion = match[1];
    browser = "edge " + browserVersion;
  } else if ((match = /opr\/([0-9.]+)/.exec(userAgent))) {
    browserVersion = match[1];
    browser = "opera " + browserVersion;
  }

  return {
    os: os,
    browser: browser,
  };
};
