import { abtestService } from "../services";
import { ABTEST_CONSTANT } from "../constants";

function getABtestByEnrolled(enrolled_id) {
  function request() {
    return { type: ABTEST_CONSTANT.ENROLLED_REQUEST };
  }

  function success(response) {
    return { type: ABTEST_CONSTANT.ENROLLED_SUCCESS, response };
  }

  function failure(error) {
    return { type: ABTEST_CONSTANT.ENROLLED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    abtestService.getABtestByEnrolled(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getABTestPaymentByCourse(course_id, variation) {
  function request() {
    return { type: ABTEST_CONSTANT.PAYMENT_PAGE_REQUEST };
  }

  function success(response) {
    return { type: ABTEST_CONSTANT.PAYMENT_PAGE_SUCCESS, response };
  }

  function failure(error) {
    return { type: ABTEST_CONSTANT.PAYMENT_PAGE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    abtestService.getABTestPaymentByCourse(course_id, variation).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getABTestKkoSignup(kko_id, variation) {
  function request() {
    return { type: ABTEST_CONSTANT.KKO_SIGNUP_PAGE_REQUEST };
  }

  function success(response) {
    return { type: ABTEST_CONSTANT.KKO_SIGNUP_PAGE_SUCCESS, response };
  }

  function failure(error) {
    return { type: ABTEST_CONSTANT.KKO_SIGNUP_PAGE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    abtestService.getABTestKkoSignup(kko_id, variation).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getABTestCourseRcmd(variation) {
  function request() {
    return { type: ABTEST_CONSTANT.COURSERCMD_PAGE_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return abtestService.getABTestCourseRcmd(variation);
  };
}

function sendHackleLog(data) {
  function request() {
    return { type: ABTEST_CONSTANT.COURSERCMD_LOG_REQUEST };
  }
  return (dispatch) => {
    dispatch(request());
    return abtestService.sendHackleLog(data);
  };
}

export const abtestActions = {
  getABtestByEnrolled,
  getABTestPaymentByCourse,
  getABTestKkoSignup,
  getABTestCourseRcmd,
  sendHackleLog,
};
