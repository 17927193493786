import React, { Fragment } from "react";
import classNames from "classnames/bind";
import styles from "./CoursePage.module.scss";
import { sendLog } from "../../v2/businesslogics/logging";
import { NavContainer } from "../../_containers";
import {
  commingsoonActions,
  courseActions,
  initEnvironment,
  priceByNumberActions,
  todaydiscountActions,
} from "../../_actions";
import {
  authHeader,
  createEventId,
  handleResponse,
  history,
} from "../../_helpers";
import { config } from "../../config";
import { Button, Collapse, Menu, Skeleton, Tooltip } from "antd";
import { connect } from "react-redux";
import { Sticky, StickyContainer } from "react-sticky";
import { Channeltalk } from "../../_components/Channeltalk";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/lib/icons/UpOutlined";
import { getGETOptions, getPostOptions } from "../../_urls";
import { getApiLogUrl } from "../../_urls/logs.api";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);
const { Panel } = Collapse;

class CoursePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      course_id: this.props.match.params.course_id,
      isRegisterd: false,
      reviewToShow: false,
      currentMenu: [],
      isTracked: false,
      prob: Math.random(),
      position: "commercialContainer-opacity-top",
      emoji: "😎",
      livelyReview: "",
      logNationUrl:
        "https://pro.ip-api.com/json/?fields=status,message,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,mobile,query&key=FVaMw6OLSQKC0LD",
    };
  }

  componentDidMount() {
    const { _initEnvironment } = this.props;
    const { course_id } = this.state;
    _initEnvironment();

    // this._logNation();

    this.props.getCourse(course_id);
    this.props.getPriceByNumber(course_id);
    this.props.getCommingsoonList();
    this.props.getTodaydiscount(course_id);
    window.scrollTo(0, 0);
    document.addEventListener("scroll", this.trackScrolling);

    const requestOptions = {
      method: "GET",
      headers: Object.assign(
        { "Content-Type": "application/json" },
        authHeader()
      ),
    };
    fetch(`${config.apiUrl}/v1/accounts/enrolled/${course_id}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        if (data.ok) {
          this.setState({
            isRegisterd: true,
          });
        }
      });

    document.addEventListener("scroll", () => {
      let deviceType = window.checkPlatform();
      let b1 = deviceType === "mobile" ? 10 : 1;
      let b2 = deviceType === "mobile" ? 710 : 810;
      let position = "commercialContainer-opacity-top";
      if (window.scrollY > b1 && window.scrollY < b2) {
        position = "commercialContainer-opacity";
      } else if (window.scrollY > b2) {
        position = "commercialContainer";
      }
      if (position !== this.state.position) {
        this.setState({ position });
      }
    });

    setInterval(this.getReview, 2000);
    setInterval(this.getEmoji, 1000);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  _logNation = () => {
    const { logNationUrl } = this.state;
    const options = getGETOptions();
    fetch(logNationUrl, options)
      .then((resp) => resp.json())
      .then((data) => {
        data["page"] = "curriculum";
        data["course_id"] = this.state.course_id;
        const endpoint = getApiLogUrl();
        const logOptions = getPostOptions(data);
        return fetch(endpoint, logOptions);
      })
      .catch((error) => {});
  };

  trackScrolling = () => {
    function _isInWindow(el) {
      const menuHeight = 53;
      return (
        el.getBoundingClientRect().top < menuHeight &&
        el.getBoundingClientRect().bottom >= menuHeight
      );
    }

    if (_isInWindow(document.getElementById("intro"))) {
      this.setState({ currentMenu: ["intro"] });
    } else if (_isInWindow(document.getElementById("tutorinfo"))) {
      this.setState({ currentMenu: ["tutorinfo"] });
    } else if (_isInWindow(document.getElementById("review"))) {
      this.setState({ currentMenu: ["review"] });
    } else if (_isInWindow(document.getElementById("curriculum"))) {
      this.setState({ currentMenu: ["curriculum"] });
    } else {
      this.setState({ currentMenu: [] });
    }
  };

  componentDidUpdate(prevProps) {}

  onClickMenu(item) {
    const key = item.key;
    console.log(item);
    const element = document.getElementById(key);
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y,
      behavior: "smooth",
    });
  }

  onClickApply = () => {
    const { user } = this.props;
    const { isRegisterd } = this.state;
    const course_id = this.props.match.params.course_id;
    const eventID = createEventId();
    sendLog("add_to_cart", {}, eventID);

    if (user === undefined) {
      // history.push(`/login?cid=${course_id}`);
    } else if (!isRegisterd) {
      history.push(`/payment?cid=${course_id}`);
    } else {
      history.push(`/classroom`);
    }
  };

  onClickApply_b = () => {
    const course_id = this.props.match.params.course_id;
    history.push(`/payment?cid=${course_id}`);
  };

  _userTracking = () => {
    if (this.state.isTracked) {
      return;
    }

    this.setState({
      isTracked: true,
    });
  };

  _numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  _getVideoComponent = (videoid) => {
    if (videoid === undefined || videoid === {}) {
      return <div />;
    }
    return (
      <Fragment>
        <div className={cx("videoWrapper")}>
          <iframe
            src={`https://player.vimeo.com/video/${videoid}`}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          />
        </div>
      </Fragment>
    );
  };

  _getHeaderImagePath = (name) => {
    const { deviceType } = this.props.environment;
    switch (name) {
      case "web":
        if (deviceType === "mobile") {
          return "/assets/images/course/web_thumbnail.png";
        } else {
          return "/assets/images/curriculum/web_header_pc.png";
        }
      case "app":
        return "/assets/images/curriculum/app_header_mobile.png";
    }
  };

  _getIntroImagePath = (name) => {
    switch (name) {
      case "web":
        return "/assets/images/curriculum/web_intro_mobile.png";
      case "app":
        return "/assets/images/curriculum/app_intro_mobile.png";
    }
  };

  _getIntroImagePartPath = (name, part) => {
    switch (name) {
      case "web":
        return `/assets/images/curriculum/web_intro_mobile_${part}.png`;
      case "app":
        return `/assets/images/curriculum/app_intro_mobile_${part}.png`;
    }
  };

  _getIntroGIFImagePartPath = () => {
    return `/assets/images/curriculum/experience.gif`;
  };

  _getTutorImagePath = (name) => {
    switch (name) {
      case "web":
        return "/assets/images/curriculum/web_diff_mobile.png";
      case "app":
        return "/assets/images/curriculum/app_tutor_mobile.png";
    }
  };

  _getReviewPath = (name) => {
    switch (name) {
      case "show":
        return "/assets/images/curriculum/review_to_show.png";
      case "hide":
        return "/assets/images/curriculum/review_to_hide.png";
    }
  };

  _getCurriculumImagePath = (name) => {
    switch (name) {
      case "web":
        return "/assets/images/curriculum/web_curri_mobile.png";
      case "app":
        return "/assets/images/curriculum/app_curri_mobile.png";
    }
  };

  getTimer = () => {
    window.setTimeout(() => {
      let { deadline } = window;
      let now = new Date();
      let dday = new Date(deadline);

      let days = (dday - now) / 1000 / 60 / 60 / 24;
      let daysRound = Math.floor(days);
      let hours = (dday - now) / 1000 / 60 / 60 - 24 * daysRound;
      let hoursRound = Math.floor(hours);
      let minutes =
        (dday - now) / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      let minutesRound = Math.floor(minutes);
      let seconds =
        (dday - now) / 1000 -
        24 * 60 * 60 * daysRound -
        60 * 60 * hoursRound -
        60 * minutesRound;
      let secondsRound = Math.round(seconds);
      this.setState({
        timeclock: `${daysRound}일 ${hoursRound}시간 ${minutesRound}분 ${secondsRound}초`,
      });
    }, 1000);
  };

  getEmoji = () => {
    const emojis = [
      "🤩",
      "😎",
      "🤓",
      "😍",
      "🥰",
      "😸",
      "😽",
      "😇",
      "😊",
      "😀",
      "😃",
      "😄",
      "😁",
      "🙈",
    ];
    let idx = parseInt(Math.random() * emojis.length);
    this.setState({
      emoji: emojis[idx],
    });
  };

  getReview = () => {
    const livelyReviews = [
      {
        name: "이*봉",
        content:
          "이번주는 공부를 제대로 못했네요..이전꺼 까먹어서 다시 복습...ㅜ 역시 꾸준히해야 덜 고통스럽..",
        time: "41분 전",
      },
      {
        name: "조*윤",
        content:
          "시험 끝났어요 본격적으로 할수있겠네용 화이팅! 오늘은 머리에 확실히 집어넣어야겠네요",
        time: "9시간 전",
      },
      {
        name: "유*진",
        content:
          "재미있어요 주말에 회사와서 공부. 오늘 1주차 끝내고 숙제도 끝내려구요 ㅎㅎ 화이팅 나자신",
        time: "13일 전",
      },
      {
        name: "최*규",
        content:
          "완강하였습니다 이제 복습해서 하는부분만 다듬으면 저도 안보고 서버를 열수있을수있을것같습니다",
        time: "9시간 전",
      },
      {
        name: "유*원",
        content:
          "어떤 API를 만들고 싶은지 뼈대를 대략 생각해보고 서버, 클라이언트 왔다갔다하면서 구성하기!",
        time: "1일 전",
      },
      {
        name: "정*민",
        content:
          "아구 해야할게많은데 코로나 때문에 카페도 편히 못가는 나날들이네요ㅠ 3주가 지나면 엄청나게 성장해있겠죠~!~!",
        time: "2일 전",
      },
      {
        name: "유*원",
        content:
          "한 번 듣기 시작하면 논스톱으로 쭉 듣게 됩니다. 그만큼 강의가 재미있어요!ㅋㅋ 기대이상임",
        time: "2일 전",
      },
      {
        name: "유*영",
        content:
          "4주차 시작했다! 드디어 4주차.... 크롤링부분 너무 재밌었다 생각보다 쉬웠다 빨리 5주차로 가야지!!",
        time: "2일 전",
      },
      {
        name: "김*헌",
        content:
          "집중 집중 집중... 집중하자.. 흐아 20분이라도 해야지 ㅠㅠ 아마존 기대중 나도 할수 있겠지?!",
        time: "2일 전",
      },
      {
        name: "김*예",
        content:
          "코딩하면서 나오는 결과물을 바로바로 보니까 너무 재밌어요! 이 기세로 완강을 달리구싶습니다",
        time: "12일 전",
      },
      {
        name: "황*은",
        content:
          "퀴즈가 어려워서 몇번 반복했지만 그래도 하나씩 풀려나가는걸 보니 너무 재밌어요 :)",
        time: "11일 전",
      },
      {
        name: "박*효",
        content:
          "다들 너무 열심히 사는거 아닙니까? 너무 재밌네요 인강 듣기 고민했는데 정말 잘한 선택같아요!",
        time: "1일 전",
      },
      {
        name: "배*화",
        content:
          "새벽에 잠도 오지 않는데 유튜브와 SNS를 들락거리다 큰 맘 먹고 불을 키고 책상 앞에 앉았다. 장족의 발전 장하다!",
        time: "1일 전",
      },
      {
        name: "김*형",
        content:
          "내가 이기나 너가 이기나 함 해보자 멈추지 말고 한발짝씩 나아가 보자! 할 수 있다...!",
        time: "2일 전",
      },
      {
        name: "김*빈",
        content:
          "다시 시작! 오늘 2주차 강의 끝까지 들을거예요. 혼자 코딩할 거리를 주시니 이제야 너무 재밋네여",
        time: "4일 전",
      },
      {
        name: "오*원",
        content:
          "많이 밀렸는데, 차근차근 따라가고 있습니다. 이제 조만간 주말이 다가오니 스퍼트 내야져!",
        time: "1일 전",
      },
      {
        name: "박*원",
        content:
          "직장다니면서 힘들겠지만 꾸준히 해서 5주보다 더빨리 강의를 다 듣는게 목표! 최선을 다하자",
        time: "14일 전",
      },
      {
        name: "임*",
        content:
          "그래 하나만 듣고 가기는 아쉽지... 2주차... 끝내자... 휴일인데......화이팅!!!!",
        time: "15일 전",
      },
    ];
    let idx = parseInt(Math.random() * livelyReviews.length);
    this.setState({
      livelyReview: livelyReviews[idx],
    });
  };

  _getCommercialNumber = (courseData) => {
    const { commingsoons } = this.props;
    if (this.props.commingsoons.loaded === false) {
      return 0;
    }
    let count = courseData["pageview"];
    for (const c of commingsoons.data) {
      count += c["likes"];
    }
    return count;
  };

  _toggleReviewShow = () => {
    const { reviewToShow } = this.state;
    this.setState({
      reviewToShow: !reviewToShow,
    });
  };

  _handleSoldOut = () => {
    window.open("http://pf.kakao.com/_cXXYj/friend", "_blank");
  };

  _getNumbersInString = (text) => {
    let r = /\d+/;
    return text.match(r);
  };

  render() {
    const { todaydiscount, priceByNumber } = this.props;
    const { checkPlatform } = window;
    let deviceType = checkPlatform();

    const { isRegisterd, currentMenu, prob, reviewToShow } = this.state;
    const buttonText = !isRegisterd ? "수강신청하기" : "강의실입장";

    const { course } = this.props;
    if (
      course.loaded === false ||
      todaydiscount.loaded === false ||
      priceByNumber.loaded === false
    ) {
      return <Skeleton />;
    }
    const { price_earlybird } = priceByNumber.data;
    let commercialNumber = this._getCommercialNumber(course.data);

    window.deadline = course.data.price_end_date_timeclock;
    // this.getTimer(course.data.price_end_date_timeclock);
    this._userTracking();

    // 리뷰 데이터는 하드코딩할 계획입니다. TODO 2기 이후에 이 코드 사용 예정.
    const reviewData = [
      {
        name: `이름`,
        avatar:
          "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
        order: "기수",
        content: "내용",
      },
      {
        name: `이름`,
        avatar:
          "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
        order: "기수",
        content: "내용",
      },
      {
        name: `이름`,
        avatar:
          "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
        order: "기수",
        content: "내용",
      },
    ];

    const category = course.data.title.includes("앱") ? "app" : "web";
    const soldOutList = ["앱"];
    let soldOut = false;
    for (let i = 0; i < soldOutList.length; i++) {
      if (course.data.title.includes(soldOutList[i])) {
        soldOut = true;
        break;
      }
    }

    const { livelyReview } = this.state;
    // soldOut = true;

    return (
      <Fragment>
        <NavContainer />
        <div className={cx("wrapper")}>
          <StickyContainer>
            <div className={cx("contentWrapper")}>
              <div className={cx("headerContainer")}>
                {this._getVideoComponent(course.data.trailer)}
                {/*<img className={cx(`${category}`)} src={`${this._getHeaderImagePath(category)}`}/>*/}
                <div className={cx("titleContainer")}>
                  <h1>
                    웹개발 종합반 {course.data.round}{" "}
                    <span className={cx("titleRound")}>
                      ({course.data.start_date_diff} 시작)
                    </span>
                  </h1>
                  <p>5주만에 코딩 왕초보 탈출!</p>
                </div>
                {/*{deviceType === 'mobile' &&*/}
                <div className={cx("summaryWrapper")}>
                  <img
                    src={"/assets/images/curriculum/web_summary.png"}
                    alt=""
                  />
                </div>
                {/*}*/}
                <div className={cx("shortedInfoWrapper")}>
                  {/*/!*제목, 할인기간, 수강기간, *!/*/}
                  {/*<ul className={cx('rowFlexStartContainer')}>*/}
                  {/*    <li>*/}
                  {/*        <div className={cx('reimburseTitle')}>*/}
                  {/*            {course.data.title} {course.data.round}*/}
                  {/*        </div>*/}
                  {/*        {soldOut &&*/}
                  {/*        <div className={cx('shutdownContainer')}>*/}
                  {/*            {soldOut ? "마감" : "마감임박"}*/}
                  {/*        </div>*/}
                  {/*        }*/}
                  {/*    </li>*/}
                  {/*</ul>*/}
                  <ul>
                    <li>
                      <div className={cx("displayPriceTitle")}>수강 기간</div>
                      <div className={cx("displayPrice")}>
                        {course.data.start_date} ~ {course.data.end_date}
                      </div>
                    </li>
                    <li>
                      <div className={cx("finalPriceTitle")}>
                        {price_earlybird.title} 할인
                      </div>
                      <div
                        className={cx(
                          "headCountContainer",
                          "highlightContainer"
                        )}
                      >
                        10자리 중 {price_earlybird.seat_left}자리 남음
                      </div>
                    </li>
                    <li>
                      <div className={cx("finalPriceTitle")}>
                        {parseInt(
                          this._getNumbersInString(price_earlybird.title)[0]
                        ) - 1}
                        차 얼리버드는 {price_earlybird.time} 만에 마감됐어요!
                        {this.state.emoji}
                      </div>
                    </li>
                    {/*/!*period_title, price_end_date*!/*/}
                    {/*{(course.data.period_title).includes("정가") === false &&*/}
                    {/*<li>*/}
                    {/*    <div className={cx('finalPriceTitle')}>{course.data.period_title} 마감까지</div>*/}
                    {/*    /!*<div*!/*/}
                    {/*    /!*    className={cx('discountRate')}>{parseInt(((course.data.display_price - course.data.price + course.data.reimburse) / course.data.display_price) * 100)}%*!/*/}
                    {/*    /!*    할인*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <div className={cx('finalPrice')} style={{*/}
                    {/*        color: '#e8334e',*/}
                    {/*        fontWeight: 700*/}
                    {/*    }}>*/}
                    {/*        /!*{course.data.price_end_date} 자정 가격 인상*!/*/}
                    {/*        {this.state.timeclock}*/}
                    {/*    </div>*/}
                    {/*</li>}*/}
                  </ul>
                </div>
                <div className={cx("priceWrapper")}>
                  <ul>
                    <li>
                      <div className={cx("displayPriceTitle")}>정가</div>
                      <div className={cx("displayPrice")}>
                        {course.data.period_title.includes("정가") === false ? (
                          <span className={cx("cancelLine")}>
                            {this._numberWithCommas(course.data.display_price)}
                          </span>
                        ) : (
                          this._numberWithCommas(course.data.display_price)
                        )}
                        원
                      </div>
                    </li>
                    {course.data.period_title.includes("정가") === false && (
                      <li>
                        <div className={cx("")}>
                          {price_earlybird.title} 할인가
                        </div>
                        <div className={cx("")}>
                          {this._numberWithCommas(price_earlybird.price)}원
                        </div>
                      </li>
                    )}
                    <ul>
                      <li>
                        <div className={cx("")}>완주시 환급액</div>
                        <div className={cx("highlightContainer")}>
                          -{this._numberWithCommas(course.data.reimburse)}원
                        </div>
                      </li>
                    </ul>
                    {/*{todaydiscount.loaded && todaydiscount.data.is_activated &&*/}
                    {/*<li>*/}
                    {/*    <div className={cx('')}>오늘의 한정수량 특가🤫</div>*/}
                    {/*    <div className={cx('highlightContainer')}>*/}
                    {/*        - {this._numberWithCommas(todaydiscount.data.discount)}원*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*}*/}
                    <li className={cx("billContainer")}>
                      <div className={cx("reimburseTitle")}>6개월 할부 시</div>
                      {deviceType !== "mobile" && (
                        <div className={cx("discountRate")}>
                          {todaydiscount.loaded &&
                          todaydiscount.data.is_activated
                            ? parseInt(
                                ((course.data.display_price -
                                  (price_earlybird.price -
                                    course.data.reimburse -
                                    todaydiscount.data.discount)) /
                                  course.data.display_price) *
                                  100
                              )
                            : parseInt(
                                ((course.data.display_price -
                                  (price_earlybird.price -
                                    course.data.reimburse)) /
                                  course.data.display_price) *
                                  100
                              )}
                          % 할인
                        </div>
                      )}
                      <div className={cx("reimburseNumber")}>
                        월 &nbsp;
                        {todaydiscount.loaded && todaydiscount.data.is_activated
                          ? this._numberWithCommas(
                              parseInt(
                                (price_earlybird.price -
                                  course.data.reimburse -
                                  todaydiscount.data.discount) /
                                  6
                              )
                            )
                          : this._numberWithCommas(
                              parseInt(
                                (price_earlybird.price -
                                  course.data.reimburse) /
                                  6
                              )
                            )}
                        원
                      </div>
                    </li>
                    {/*<li>*/}
                    {/*    <div className={cx('displayPriceTitle')}>*6개월 무이자 할부 가능</div>*/}
                    {/*    <div*/}
                    {/*        className={cx('displayPrice')}>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>

              <Sticky topOffset={700}>
                {({ style }) => (
                  <Menu
                    style={{ ...style }}
                    selectedKeys={currentMenu}
                    className={cx("menuWrapper")}
                    onClick={(item) => this.onClickMenu(item)}
                    mode="horizontal"
                  >
                    <Menu.Item key="intro">
                      <a>강의소개</a>
                    </Menu.Item>
                    <Menu.Item key="tutorinfo">
                      <a>차별점</a>
                    </Menu.Item>
                    <Menu.Item key="review">
                      <a>후기</a>
                    </Menu.Item>
                    <Menu.Item key="curriculum">
                      <a>커리큘럼</a>
                    </Menu.Item>
                  </Menu>
                )}
              </Sticky>
              {/*<div className={cx("containerHeader")}></div>*/}

              <div
                id="intro"
                className={cx("containerIntro", "containerContent")}
                style={{ paddingTop: 20 }}
              >
                {/*{this._getVideoComponent(course.data.trailer)}*/}
                {/*<div style={{*/}
                {/*    textAlign: 'center',*/}
                {/*    marginBottom: 20*/}
                {/*}}>*/}
                {/*    {course.data.title} {course.data.tutor} 튜터*/}
                {/*</div>*/}
                {/*{deviceType !== 'mobile' &&*/}
                {/*<div className={cx('summaryWrapper')}>*/}
                {/*    <img src={('/assets/images/curriculum/web_summary.png')} alt=""/>*/}
                {/*</div>*/}
                {/*}*/}
                <img src={`${this._getIntroImagePartPath(category, 0)}`} />
                <img src={`${this._getIntroImagePartPath(category, 1)}`} />
                <div className={cx("gifContainer")}>
                  <img
                    className={cx("gifContainer")}
                    src={`${this._getIntroGIFImagePartPath()}`}
                  />
                </div>
                <img src={`${this._getIntroImagePartPath(category, 2)}`} />
              </div>

              <div
                id="tutorinfo"
                className={cx("containerTutorinfo", "containerContent")}
              >
                {/*<h3>튜터</h3>*/}

                <div className={cx("tutorProfile")}>
                  <img src={`${this._getTutorImagePath(category)}`} />
                </div>

                {/* <Descriptions
                                    className={cx('descriptionWrapper')}
                                    title="이력">
                                    <Descriptions.Item>
                                        ~~~~~~~~~
                                        ~~~~~~~~~
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions
                                    className={cx('descriptionWrapper')}
                                    title="하고 싶은 말">
                                    <Descriptions.Item>
                                        ~~~~~~~~~
                                        ~~~~~~~~~
                                    </Descriptions.Item>
                                </Descriptions> */}
              </div>

              <div
                id="review"
                className={cx("containerReview", "containerContent")}
              >
                <h3>후기</h3>
                <div className={cx("videoContents")}>
                  <div className={cx("videoContainer")}>
                    {this._getVideoComponent("441937135")}
                  </div>
                  {/*<div className={cx('videoContainer')}>*/}
                  {/*    {this._getVideoComponent("437019403")}*/}
                  {/*    <img src={`${this._getReviewPath('song')}`}/>*/}
                  {/*</div>*/}
                </div>

                <div className={cx("livelyReviewWrapper")}>
                  <ul>
                    <li>
                      <div className={cx("headline")}>
                        2기 수강생들의 실시간 다짐🔥
                      </div>
                    </li>
                    <li>
                      <div className={cx("content")}>
                        {livelyReview.content}
                      </div>
                    </li>
                    <li>
                      <div className={cx("footer")}>
                        {livelyReview.name}님이 {livelyReview.time}에
                        작성했어요.
                      </div>
                    </li>
                  </ul>
                </div>
                {/* 기본으로 보여줄 리뷰들 */}
                <div className={cx("reviewToShowContainer")}>
                  <img src={`${this._getReviewPath("show")}`} />
                  {reviewToShow === false && (
                    <div onClick={this._toggleReviewShow}>
                      <span className={cx("reviewTitleContainer")}>
                        후기 더 보기
                      </span>
                      &nbsp;&nbsp;
                      <span className={cx("reviewIconContainer")}>
                        <DownOutlined />
                      </span>
                    </div>
                  )}
                  {reviewToShow && (
                    <div onClick={this._toggleReviewShow}>
                      <span className={cx("reviewTitleContainer")}>
                        후기 접기
                      </span>{" "}
                      &nbsp;&nbsp;
                      <span className={cx("reviewIconContainer")}>
                        <UpOutlined />
                      </span>
                    </div>
                  )}
                </div>
                {/* 기본으로 숨기는 리뷰들 */}
                {reviewToShow && (
                  <div className={cx("reviewToShowContainer")}>
                    <img src={`${this._getReviewPath("hide")}`} />
                  </div>
                )}
                {/*// 리뷰 데이터는 하드코딩할 계획입니다. TODO 2기 이후에 이 코드 사용 예정.*/}
                {/*<List*/}
                {/*    itemLayout="vertical"*/}
                {/*    size="large"*/}
                {/*    dataSource={reviewData}*/}
                {/*    renderItem={(item) => (*/}
                {/*        <List.Item key={item.title} className={cx('reviewListItem')}>*/}
                {/*            <List.Item.Meta*/}
                {/*                avatar={<Avatar src={item.avatar}/>}*/}
                {/*                title={item.name}*/}
                {/*                description={item.order}*/}
                {/*            />*/}
                {/*            {item.content}*/}
                {/*        </List.Item>*/}
                {/*    )}*/}
                {/*/>*/}
              </div>

              <div
                id="curriculum"
                className={cx("containerCurriculum", "containerContent")}
              >
                <img src={`${this._getCurriculumImagePath(category)}`} />
                {/*<div className={cx('curriculumWrapper')}>*/}
                {/*</div>*/}
              </div>

              <div className={cx("containerFAQ", "containerContent")}>
                <h3>FAQ</h3>
                <div className={cx("faqWrapper")}>
                  <Collapse className={cx("faqCollapse")}>
                    <Panel
                      className={cx("faqPanel")}
                      header="정말 왕초보도 수강할 수 있나요?"
                      key="1"
                    >
                      <p>
                        스파르타 오프라인 누적 수강생 1,500분과 함께 하면서
                        어떻게 하면 조금 더 쉽게, 잘 가르칠 수 있을지 끊임없이
                        고민하고 강의 내용을 발전시켜왔습니다.
                      </p>
                      <p>
                        왕초보도 이해하는 강의 내용과 더불어, 즉문즉답을 통해
                        어려움을 겪는 지점을 튜터와 함께 바로바로 해결하실 수
                        있습니다.
                      </p>
                    </Panel>
                    <Panel
                      className={cx("faqPanel")}
                      header="즉문즉답 Q&A는 어떻게 이루어지나요?"
                      key="2"
                    >
                      <p>
                        화요일 저녁 7시 ~ 10시, 토요일 2시 ~ 5시에 슬랙slack을
                        통해 질문하실 경우, 튜터가 즉시 답변을 드립니다.
                      </p>
                      <p>
                        수업 진행에 필수적인 설치를 하다 막혔는데 도저히 해결이
                        힘들다거나, 하는 긴급한 문제는 '<b>긴급 질문</b>'으로
                        남겨주시면 즉시 튜터가 함께 해결해드려요.
                      </p>
                    </Panel>
                    <Panel
                      className={cx("faqPanel")}
                      header="일주일에 얼마나 시간을 써야 하나요?"
                      key="3"
                    >
                      <p>
                        매주 수강에 2시간 반, 복습하며 소화하는 시간 2시간 반씩
                        할당하여 5시간이면 따라오실 수 있도록 준비하였습니다.
                      </p>
                    </Panel>
                    <Panel
                      className={cx("faqPanel")}
                      header="환불 규정이 어떻게 되나요?"
                      key="4"
                    >
                      <p>
                        환불규정은 아래와 같습니다. 기간제 수업이므로 총 기간을
                        기준으로 산정됩니다. <br />
                        - 시작일 이전 : 이미 납부한 수강료 전액
                        <br />
                        - 1/3 경과 전 : 수강료의 2/3
                        <br />
                        - 1/2 경과 전 : 수강료의 1/2
                        <br />- 1/2 경과 후 : 반환하지 아니함
                      </p>
                    </Panel>
                    <Panel
                      className={cx("faqPanel")}
                      header="완주시 환급은 어떤 기준으로 받게 되나요?"
                      key="5"
                    >
                      <p>
                        수강기간 내에 마지막 숙제제출까지 완료한 경우 환급을
                        받으실 수 있습니다. 횐급조건 달성 후 정해진 양식의
                        환급신청서 (입금계좌 등)를 작성해 주셔야 하며, 환급은
                        접수일 기준 10 영업일 이내로 이뤄집니다
                      </p>
                    </Panel>
                    {course.data.title.includes("웹") && (
                      <Panel
                        className={cx("faqPanel")}
                        header="학습에 필요한 노트북 사양은 무엇인가요?"
                        key="6"
                      >
                        <p>
                          윈도우의 경우 7 또는 10 (64비트), 맥의 경우 OS X 10.10
                          요세미티 이상을 권장드립니다.
                        </p>
                      </Panel>
                    )}
                    {course.data.title.includes("앱") && (
                      <Panel
                        className={cx("faqPanel")}
                        header="학습에 필요한 노트북 사양은 무엇인가요?"
                        key="6"
                      >
                        <p>
                          원활한 진행을 위해 최소 i5이상의 CPU와 8GB 이상의
                          RAM이 필요하며, 사용하시는 컴퓨터의 사양이 이보다 낮을
                          경우 렉이 걸리는 등 진행에 어려움이 다소 있을 수
                          있습니다.
                        </p>
                      </Panel>
                    )}
                    {course.data.title.includes("앱") && (
                      <Panel
                        className={cx("faqPanel")}
                        header="아이폰 앱 개발을 하고 싶은데, 맥북이 꼭 있어야 하나요?"
                        key="7"
                      >
                        <p>
                          아니요, 맥북이 없어도 아이폰 앱개발이 가능합니다.
                          Expo라는 기술이 매력적이고 이 강의에서 Expo를 사용하는
                          이유인데요, Expo를 이용하여 앱개발을 하게 되면 휴대폰
                          (종류 무관)에 Expo 클라이언트 앱을 다운로드 해서 지금
                          개발하고 있는 앱을 확인할 수 있습니다.
                        </p>
                      </Panel>
                    )}
                    {course.data.title.includes("앱") && (
                      <Panel
                        className={cx("faqPanel")}
                        header="별도로 필요한 기기가 있나요?"
                        key="8"
                      >
                        <p>
                          스마트폰이 필요합니다만, 지금 사용하시는 그 핸드폰이면
                          됩니다! (안드로이드/아이폰 상관없이 요새 휴대폰이면
                          무엇이든 가능합니다.)
                        </p>
                      </Panel>
                    )}
                  </Collapse>
                </div>
              </div>
            </div>
          </StickyContainer>
          <div className={cx("buttonContainer")}>
            {deviceType === "mobile" && (
              <div className={cx("chitos")}>
                {`10자리 중 ${
                  price_earlybird.seat_left
                }자리 남음 - 오늘 ${this._getCommercialNumber(
                  course.data
                )}명이 보았어요!`}
              </div>
            )}
            <div className={cx("buttonWrapper")}>
              {/*<Button size="large" className={cx("buttonAsk")}>*/}
              {/*  문의*/}
              {/*</Button>*/}
              {soldOut === false &&
                this.props.user !== undefined &&
                (isRegisterd ? (
                  <Tooltip
                    overlayStyle={{ position: "fixed" }}
                    placement="top"
                    title="이미 신청이 완료된 강의에요."
                    visible={isRegisterd}
                  >
                    <Button
                      id="button_a_ined"
                      type="primary"
                      size="large"
                      className={cx("buttonApply")}
                      onClick={this.onClickApply}
                    >
                      {buttonText}
                    </Button>
                  </Tooltip>
                ) : (
                  <div>
                    <Button
                      id="button_a_logined"
                      type="primary"
                      size="large"
                      className={cx("buttonApply")}
                      onClick={this.onClickApply}
                    >
                      {buttonText}
                    </Button>

                    {/*<div className={cx('tooltipContainer')}>*/}
                    {/*    <div className={cx('tooltip')}*/}
                    {/*         style={{"--bg-image": `url(${('/assets/images/curriculum/tooltip.png')}`}}*/}
                    {/*    >*/}
                    {/*        지금, {this._getCommercialNumber(course.data)}명이 수강을 고민하고 있어요!*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {deviceType !== "mobile" && (
                      <span className={cx("count")}>
                        <em>{`10자리 중 ${
                          price_earlybird.seat_left
                        }자리 남음 - 오늘 ${this._getCommercialNumber(
                          course.data
                        )}명이 보았어요!`}</em>
                      </span>
                    )}
                  </div>
                ))}
              {soldOut === false && this.props.user === undefined && (
                <div
                  style={
                    {
                      // display: 'none',
                    }
                  }
                >
                  <Button
                    id="button_b"
                    type="primary"
                    size="large"
                    className={cx("buttonApply")}
                    onClick={this.onClickApply_b}
                  >
                    {buttonText}
                  </Button>

                  {/*<div className={cx('tooltipContainer')}>*/}
                  {/*    <div className={cx('tooltip')}*/}
                  {/*         style={{"--bg-image": `url(${('/assets/images/curriculum/tooltip.png')}`}}*/}
                  {/*    >*/}
                  {/*        지금, {this._getCommercialNumber(course.data)}명이 수강을 고민하고 있어요!*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  {deviceType !== "mobile" && (
                    <span className={cx("count")}>
                      <em>{`10자리 중 ${
                        price_earlybird.seat_left
                      }자리 남음 - 오늘 ${this._getCommercialNumber(
                        course.data
                      )}명이 보았어요!`}</em>
                    </span>
                  )}
                </div>
              )}
              {soldOut && (
                <div>
                  <Button
                    id="button_soldout"
                    type="primary"
                    size="large"
                    className={cx("buttonApply")}
                    onClick={this._handleSoldOut}
                  >
                    {/*웹개발 종합반 보러가기 <span style={{fontSize: 20}}><RightOutlined /></span>*/}
                    마감! 플친 추가하고 알림 받기
                  </Button>

                  <div className={cx("tooltipContainer")}>
                    <div
                      className={cx("tooltip")}
                      style={{
                        "--bg-image": `url(${"/assets/images/curriculum/tooltip.png"}`,
                      }}
                    >
                      {course.data.title.includes("웹") &&
                        "2기 90명도 조기마감! 3기 모집 D-2🔥"}
                      {course.data.title.includes("앱") &&
                        "1기 150명, 2주 만에 조기마감🔥"}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*<div id="commercial"*/}
        {/*     className={cx(`${this.state.position}`)}>*/}
        {/*    지금, {commercialNumber}명이 수강을 고려하고 있어요!*/}
        {/*</div>*/}
        <FooterV2 />
        <Channeltalk />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { course, environment, todaydiscount, commingsoons, priceByNumber } =
    state;
  const { loggingIn, user } = state.authentication;
  return {
    course,
    loggingIn,
    user,
    environment,
    todaydiscount,
    commingsoons,
    priceByNumber,
  };
}

const connectedCoursePage = connect(mapStateToProps, {
  getCourse: courseActions.getCourse,
  _initEnvironment: initEnvironment,
  getTodaydiscount: todaydiscountActions.getTodaydiscount,
  getCommingsoonList: commingsoonActions.getCommingsoonList,
  getPriceByNumber: priceByNumberActions.getPriceByNumber,
})(CoursePage);
export { connectedCoursePage as CoursePage };
