import styled from "@emotion/styled";
import {
  mHeader2,
  mTitle2,
  neutralDay,
  wTitle1,
  wTitle4,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../_styles/device";
import { validStatusValues } from "../../_state/constants";

export const HomeworkContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ isOpenList }) => (isOpenList ? `75%` : "100%")};
  transition: all 0.2s ease-in-out;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 0;
  }
`;

export const FeedbackRequiredNoticeWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: ${({ status, isFeedbackRequired }) =>
    validStatusValues.includes(status) && isFeedbackRequired ? "flex" : "none"};
  padding: 0;
  gap: 60px;
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 0;
  }
`;

export const HomeworkContents = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  gap: 60px;
  transition: all 0.2s ease-in-out;

  padding: ${({ status, isFeedbackRequired }) =>
    validStatusValues.includes(status) && isFeedbackRequired
      ? "60px 24px 120px 24px"
      : "120px 24px 120px 24px"};
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    padding: 20px 16px 120px 16px;
    gap: 40px;
  }
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  max-width: 772px;
  margin: 0 auto;
  ${DisplayResolution.MobileAndMiniTablet} {
    align-items: flex-start;
    width: 100%;
  }
`;

export const PageTitle = styled.div`
  ${wTitle1};
  color: ${neutralDay.gray100};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    color: ${neutralDay.gray100};
  }
`;

export const PageSubtitle = styled.div`
  ${wTitle4};
  color: ${neutralDay.gray70};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mTitle2};
    color: ${neutralDay.gray70};
  }
`;

export const HomeworkHelpButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 772px;
  margin: 0 auto;
  ${DisplayResolution.Mobile} {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`;
