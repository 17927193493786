import * as S from "./evaluationAnswer.style";
import { Icon } from "../../../organisms/Evalutaion/EvaluationSummary/evaluationSummary.style";
import { EvaluationMultiChoice } from "./EvaluationMultiChoice/EvaluationMultiChoice";
import { useGetEvaluationSummaryData } from "../../../../hooks/evaluation/useEvaluationData";
import { QUESTION_TYPE } from "../../../../_state/constants/evaluation.constants";
import { AnswerContainer } from "../EvaluationResponse/evaluationResponse.style";

export const EvaluationAnswer = ({ questionData }) => {
  const {
    isCorrected,
    submittedAnswer,
    correctAnswer,
    commentary,
    questionType,
    options,
  } = useGetEvaluationSummaryData(questionData);
  const renderAnswerByType = () => {
    switch (questionType) {
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        return (
          <EvaluationMultiChoice
            questionData={questionData}
            isCorrected={isCorrected}
          />
        );
      case QUESTION_TYPE.SHORT:
        return <S.SubmittedAnswer>{submittedAnswer}</S.SubmittedAnswer>;
      case QUESTION_TYPE.CODE:
        // CODE 타입에 해당하는 컴포넌트 렌더링 로직 - 추후 추가 예정
        return <S.SubmittedAnswer>{submittedAnswer}</S.SubmittedAnswer>;
      default:
        return <div></div>;
    }
  };

  const renderCorrectAnswer = (questionType, correctAnswer, options) => {
    if (questionType === QUESTION_TYPE.MULTIPLE_CHOICE) {
      const index = parseInt(correctAnswer, 10) - 1;
      return options[index];
    } else {
      return correctAnswer;
    }
  };

  return (
    <AnswerContainer>
      <S.EvaluationAnswerTitleBox>
        <S.EvaluationAnswerTag isCorrected={isCorrected}>
          답변
        </S.EvaluationAnswerTag>
        {isCorrected ? (
          <Icon src="/images/evaluation/correct.png" />
        ) : (
          <Icon src="/images/evaluation/incorrect.png" />
        )}
      </S.EvaluationAnswerTitleBox>
      {renderAnswerByType()}
      <S.CorrectAnswerContainer>
        <S.CorrectAnswerText>
          정답 : {renderCorrectAnswer(questionType, correctAnswer, options)}
        </S.CorrectAnswerText>
        <S.CorrectAnswerCommentary>{commentary}</S.CorrectAnswerCommentary>
      </S.CorrectAnswerContainer>
    </AnswerContainer>
  );
};
