import { codeSnippetConstants } from "../_constants";
import { codeSnippetService } from "../_services/codeSnippet.service";

function getCodeSnippet(round_id, lecture_id) {
  function request() {
    return { type: codeSnippetConstants.SNIPPET_REQUEST };
  }

  function success(response) {
    return { type: codeSnippetConstants.SNIPPET_SUCCESS, response };
  }

  function failure(error) {
    return { type: codeSnippetConstants.SNIPPET_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    codeSnippetService.getCodeSnippet(round_id, lecture_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetCodeSnippet = (round_id, lecture_id) => {
  return getCodeSnippet(round_id, lecture_id);
};

export const codeSnippetActions = {
  getCodeSnippet,
};
