import * as S from "./LectureTapMenu.style";
import React, { useEffect, useState } from "react";
import { TapMenuContentBtn } from "./TapMenuContentBtn/TapMenuContentBtn";
import { TapMenuBarDesktop } from "./TapMenuBar/TapMenuBarDesktop";
import { TapMenuBarMobile } from "./TapMenuBar/TapMenuBarMobile";
import {
  useInitializeCodeSnippets,
  useInitializeMaterial,
  useIsDesktop,
  useIsTablet,
  useModifyCodeSnippets,
  useModifyMaterials,
} from "../useInitialize";
import { useSelector } from "react-redux";
import { MobileLectureList } from "../LectureList/LectureList";
import { sendLog } from "../../../businesslogics/logging";
import { useOnlineTheme } from "../../../queries/pirates";
import { useAtom } from "jotai";
import {
  lectureTapMenuStatusAtom,
  lectureTapMenuStatusAtomMobile,
} from "../../../stores/lectureTapMenuStatus";
import { ErrorReportTab } from "./ErrorReport/ErrorReportTab";
import { useParams } from "react-router-dom";

const ItemContainer = ({ items, menuStatus }) => {
  const getRandomIndex = (tot) => {
    let idx = parseInt(Math.random() * tot);
    return idx;
  };
  const [rtanIndex, setRtanIndex] = useState(0);
  useEffect(() => {
    setRtanIndex(getRandomIndex(10));
  }, [menuStatus]);
  const isTablet = useIsTablet();
  const isEvenItems = items && items.length % 2 === 0;
  const theme = useOnlineTheme();

  return (
    <S.LectureTapMenuBodyWrapper theme={theme.data}>
      {items && items.length !== 0 ? (
        <S.LectureTapMenuBodyItemContainer>
          {items.map((snippets, index) => {
            return (
              <TapMenuContentBtn
                title={snippets.title}
                content={snippets.content}
                isMaterial={menuStatus === "material"}
                key={index}
              />
            );
          })}
          {isTablet && !isEvenItems && <S.DummyItem></S.DummyItem>}
        </S.LectureTapMenuBodyItemContainer>
      ) : (
        <S.LectureTapMenuNoSnippets>
          <S.NoSnippetsText theme={theme.data}>
            이번 강의는 코드스니펫이 없어요!
          </S.NoSnippetsText>
          <S.LandomImage
            src={`/assets/icons/rtan/rtan_random_${rtanIndex}.png`}
          />
        </S.LectureTapMenuNoSnippets>
      )}
    </S.LectureTapMenuBodyWrapper>
  );
};

const TabMenuItems = ({ items, menuStatus }) => {
  const theme = useOnlineTheme();

  const renderContent = () => {
    switch (menuStatus) {
      case "report":
        return (
          <S.ErrorReportWrapper theme={theme.data}>
            <ErrorReportTab />
          </S.ErrorReportWrapper>
        );
      case "lectureList":
        return <MobileLectureList />;
      default:
        return (
          <>
            <ItemContainer items={items} menuStatus={menuStatus} />
          </>
        );
    }
  };

  return <S.ItemWrapper theme={theme.data}>{renderContent()}</S.ItemWrapper>;
};

export const LectureTapMenu = () => {
  const { enrolled_id, detail_id } = useParams();
  const isDesktop = useIsDesktop();
  const [menuStatus, setMenuStatus] = useAtom(
    isDesktop ? lectureTapMenuStatusAtom : lectureTapMenuStatusAtomMobile
  );
  const codeSnippets = useInitializeCodeSnippets(enrolled_id, detail_id);
  const material = useInitializeMaterial(enrolled_id, detail_id, () => {});
  const roundWeeks = useSelector((state) => state.roundWeeks);
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const course = useSelector((state) => state.course);
  const modifiedMaterials = useModifyMaterials(roundWeeks.data?.weeks);
  const modifiedCodeSnippets = useModifyCodeSnippets(codeSnippets.data);
  const getModifiedByStatus = (status) => {
    if (status === "code") return modifiedCodeSnippets;
    if (status === "material") return modifiedMaterials;
    else return [];
  };
  const menuHandler = (anchor) => {
    if (anchor.key === "fastQna") {
      window.open(material.data?.qna_url);
      return;
    }
    sendLog(
      "scc_lecturePage_click_button",
      {
        ...enrolled.data,
        ...enrolledDetail.data,
        button_text: anchor.name,
        button_name: anchor.name,
        next_page: "",
      },
      ""
    );
    setMenuStatus(anchor.key);
  };

  const isFastQnaAvailable =
    course.data.hasOwnProperty("fastqna_policy") &&
    course.data?.fastqna_policy !== "";

  return (
    <S.LectureTapMenuWrapper>
      {isDesktop ? (
        <TapMenuBarDesktop
          menuHandler={menuHandler}
          currentMenu={menuStatus}
          isFastQnaAvailable={isFastQnaAvailable}
        />
      ) : (
        <TapMenuBarMobile
          currentMenu={menuStatus}
          menuHandler={menuHandler}
          isFastQnaAvailable={isFastQnaAvailable}
        />
      )}
      <TabMenuItems
        items={getModifiedByStatus(menuStatus)}
        menuStatus={menuStatus}
      />
    </S.LectureTapMenuWrapper>
  );
};
