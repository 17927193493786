import styled from "styled-components";
import React, {useState} from "react"
import {useHistory, useLocation} from "react-router-dom";

export const NbQuizGiftPage = () => {
    const login = () => {
        history.push(`/mypage?coupon_code=spcsdc`);
    }
    const history = useHistory()
    const location = useLocation();
    const result = location.state?.result;
    const [isValid, setIsValid] = useState(false);

    if (isValid === false) {
        if (result !== true) {
            alert("잘못된 접근입니다.")
            history.replace("/mypage")
        } else {
            history.replace(location.pathname, null)
            setIsValid(true)
        }
    }

    return (
        <Wrapper>
            <Section1>
                <Header>
                    <Logo src={("/v2/assets/images/logo.png")}/>
                    <P>
                        앗! 국비지원 대상자는 아니시지만, <br/>
                        <b>스파르타가 지원해드릴게요!</b>
                    </P>
                </Header>
                <Card>
                    <Img src={("/v2/assets/images/card.png")}/>
                    <Desc>
                        여기까지 오신 분들께만 <br/>
                        <b>무려 10만원 할인 쿠폰을 드립니다!</b>
                    </Desc>
                    <Cta onClick={login}>
                        쿠폰 확인하러 가기
                        <img src={("/v2/assets/images/icon-arrow.svg")} width={20}/>
                    </Cta>
                </Card>
            </Section1>
            <Section2>
                <Ask>
                    혹시 코딩으로 <br/>
                    <b>더 큰 꿈을 꾸고 계신가요?</b>
                </Ask>
                <a href={"https://chang.spartacodingclub.kr"} target={"_blank"}>
                    <Btn src={("/v2/assets/images/banner-chang.png")}/>
                </a>
                <a href={"https://hanghae99.spartacodingclub.kr"}
                         target={"_blank"}>
                    <Btn src={("/v2/assets/images/banner-hanghae.png")}/>
                </a>
                <WhiteButton onClick={()=>history.replace("/payment/6115cea3e73913245c9538e0")}>
                    처음으로 돌아가기
                </WhiteButton>
            </Section2>
        </Wrapper>
    )
}

const Wrapper = styled.div`
width: 375px;
margin: auto;
`;

const Section1 = styled.div`
width: 375px;
height: 581px;
flex-grow: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 32px;
padding: 32px 24px 40px;
background-image: linear-gradient(to bottom, #fff 0%, #fff9d9 24%, #fff8d2 97%);
`;

const Header = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const Logo = styled.img`
margin: 0 0 32px 0;
width: 63px;
height: 32px;
flex-grow: 0;
object-fit: contain;
`
const P = styled.div`
flex-grow: 0;
font-family: Pretendard;
font-size: 18px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #000;

b {
  font-size: 24px;
  font-weight: bold;
}
`;

const Card = styled.div`
width: 328px;
height: 356px;
flex-grow: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
padding: 40px 33px 32px;
object-fit: contain;
border-radius: 12px;
box-shadow: 0 4px 21px 0 rgba(172, 137, 14, 0.17);
border: solid 2px #f1f1f1;
background-color: #fff;
`;

const Img = styled.img`
width: 277.7px;
height: 115px;
flex-grow: 0;
object-fit: contain;
`;

const Desc = styled.div`
width: 283px;
height: 54px;
flex-grow: 0;
font-family: Pretendard;
font-size: 18px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0.05px;
text-align: center;
color: #000;
`;

const Cta = styled.div`
cursor: pointer;
width: 211px;
height: 52px;
flex-grow: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 100px;
box-shadow: 0 4px 10px 0 rgba(80, 77, 77, 0.34);
background-color: #000;

font-family: "Pretendard";
font-size: 18px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
color: #fff;

flex-grow: unset;
`;

const Section2 = styled.div`
width: 375px;
height: 467px;
flex-grow: 0;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
gap: 24px;
padding: 40px 24px;
`;

const Ask = styled.div`
height: 52px;
flex-grow: 0;
font-family: Pretendard;
font-size: 21px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #000;
`;

const Btn = styled.img`
cursor: pointer;
width: 328px;
  height: 106px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 12px;
  background-color: #f1e5e5;
`;

const WhiteButton = styled.div`
cursor: pointer;
margin-top: 20px;
width: 328px;
height: 59px;
flex-grow: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;
padding: 20px 0;
border-radius: 8px;
border: solid 1px #eaebed;
background-color: #fff;

flex-grow: 0;
font-family: Pretendard;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
color: rgba(38, 54, 40, 0.6);
`