class Selection {
  constructor(text, nextIndex, descTitle, descList, value) {
    this.text = text;
    this.nextIndex = nextIndex;
    this.descTitle = descTitle;
    this.descList = descList;
    this.value = value;
  }
}

const junior = new Selection("미성년자", "1");
const student = new Selection("대학(원) 재학생 (휴학생 포함)", "3");
const jobSeeker = new Selection("실업자/구직자", "14");
const employee = new Selection("직장인", "7");
const selfEmployed = new Selection("자영업자, 사업자", "10");
const publicOfficial = new Selection(
  "공무원, 사학연금수령자, 사립교직원",
  "gift"
);
const special = new Selection(
  "특수고용형태종사자, 프리랜서",
  "16",
  "특수고용형태종사자란?",
  [
    "계약 형태와 관계없이 노무를 제공하여 근로하고 있는 경우",
    "포함 업종: 학습지교사, 학원 강사, 방문판매원, 보험설계사, 북큐레이터, 번역가, 택배기사, 골프장 캐디, 퀵서비스 기사, 대리운전기사, 건설기계를 직접 운전하는 사람 등",
  ]
);

const underSecondGrade = new Selection("고등학교 2학년 이하", "gift");
const overSecondGrade = new Selection("고등학교 2학년 이상", "2");

const univCiber = new Selection("방송통신대학 및 사이버대학 등 원격대학", "2");
const univTwoYear = new Selection("2년제 대학", "2");
const univThreeYear = new Selection("3년제 대학", "4");
const univFourYear = new Selection("4년제 대학", "5");

const univThreeUnder = new Selection("1학년 2학기 이전", "gift");
const univThreeOver = new Selection("1학년 2학기 종료 (예정 포함) ", "2");

const univFourUnder = new Selection("2학년 2학기 이전", "gift");
const univFourOver = new Selection("2학년 2학기 종료 (예정 포함) ", "2");

const old34Under = new Selection("만 75세 미만", "2");
const old34Over = new Selection("75세 이상", "gift");

const old75Under = new Selection("만 75세 미만", "2");
const old75Over = new Selection("75세 이상", "gift");

const jobBig = new Selection("대규모 기업", "8");
const jobSmall = new Selection("중소/중견 기업", "6");

const old45Under = new Selection("만 45세 미만", "9");
const old45Over = new Selection("45세 ~ 만 75세", "2");

const earn300Under = new Selection("월 임금 300만원 미만", "2");
const earn300Over = new Selection("월 임금 300만원 이상", "gift");

const earn15UnderFree = new Selection("연 매출 1억 5천만원 미만", "2");
const earn15Under = new Selection("연 매출 1억 5천만원 미만", "15");
const earn15Over = new Selection("연 매출 1억 5천만원 이상", "gift");

const found1YearUnder = new Selection("사업 기간 1년 미만 ", "gift");
const found1YearOver = new Selection("사업 기간 1년 이상", "2");
const wasJoined = new Selection("국민취업제도 참여 중", "gift");
const wasNotJoined = new Selection("국민취업제도 미참여", "2");

const iDonKnow = new Selection(
  "해당 없음 / 모르겠음",
  "result",
  null,
  null,
  "3"
);
const nonRegular = new Selection("비정규직 근로자", "result", null, null, "4");
const priorWorker = new Selection(
  "우선지원대상기업, 특별고용지원업종 근로자",
  "result",
  null,
  null,
  "4"
);
const dangerLocation = new Selection("고용위기지역", "result", null, null, "4");
const middleEarning = new Selection(
  "당해연도 중위소득의 50% 초과 60% 이하",
  "result",
  "21년도 기준 (1인가구 기준)",
  [
    "중위소득: 1,827,832원",
    "50%: 913,916원",
    "60%: 1,096,699원",
    "(22년도 중위소득: 1,944,812원)",
  ],
  "4"
);
const underEarning = new Selection(
  "당해연도 기준 중위소득의 50% 이하",
  "result",
  "중위소득이란,",
  [
    "국민의 총 가구의 소득에 따라 오름차순으로 배열하여 정확히 중앙에 있는 값을 말합니다! 본인의 가구 수와 소득에 따라 계산됩니다 :)",
  ],
  "5"
);

export const QuestionList = [
  {
    question: "현재 나는,",
    type: "selection",
    selections: [
      student,
      jobSeeker,
      employee,
      selfEmployed,
      publicOfficial,
      special,
      junior,
    ],
    btnSize: "short",
    idx: 0,
    depth: 1,
  },
  {
    question: "현재 나의 학년은",
    type: "selection",
    selections: [underSecondGrade, overSecondGrade],
    idx: 1,
    depth: 2,
  },
  {
    question: "추가 사항 체크",
    type: "check",
    selections: [
      iDonKnow,
      nonRegular,
      priorWorker,
      dangerLocation,
      underEarning,
      middleEarning,
    ],
    isLast: true,
    idx: 2,
    depth: 3,
  },
  {
    question: "재학중인 대학(원)은?",
    type: "selection",
    selections: [univCiber, univTwoYear, univThreeYear, univFourYear],
    idx: 3,
    depth: 2,
  },
  {
    question: "현재 학기는?",
    type: "selection",
    selections: [univThreeUnder, univThreeOver],
    idx: 4,
    depth: 2.5,
  },
  {
    question: "현재 학기는?",
    type: "selection",
    selections: [univFourUnder, univFourOver],
    idx: 5,
    depth: 2.5,
  },
  {
    question: "현재 나의 나이는?",
    type: "selection",
    selections: [old34Under, old34Over],
    idx: 6,
    depth: 2,
  },
  {
    question: "현재 나의 직장은?",
    type: "selection",
    selections: [jobBig, jobSmall],
    idx: 7,
    depth: 1.5,
  },
  {
    question: "현재 나의 나이는?",
    type: "selection",
    selections: [old45Under, old45Over],
    idx: 8,
    depth: 2,
  },
  {
    question: "현재 나의 임금은?",
    type: "selection",
    selections: [earn300Under, earn300Over],
    idx: 9,
    depth: 2.5,
  },
  {
    question: "현재 나의 매출액은?",
    type: "selection",
    selections: [earn15Under, earn15Over],
    idx: 10,
    depth: 1.5,
  },
  {
    question: "현재 나의 소득은?",
    type: "selection",
    selections: [earn15Under, earn15Over],
    idx: 11,
    depth: 2,
  },
  {
    question: "국민취업제도",
    type: "selection",
    selections: [wasJoined, wasNotJoined],
    idx: 12,
    depth: 2.5,
  },
  {
    question: "현재 나의 매출액은?",
    type: "selection",
    selections: [earn15Under, earn15Over],
    idx: 13,
    depth: 2,
  },
  {
    question: "현재 나의 나이는?",
    type: "selection",
    selections: [old75Under, old75Over],
    idx: 14,
    depth: 2,
  },
  {
    question: "현재 나의 사업 기간은?",
    type: "selection",
    selections: [found1YearOver, found1YearUnder],
    idx: 15,
    depth: 2,
  },
  {
    question: "현재 나의 매출액은?",
    type: "selection",
    selections: [earn15UnderFree, earn15Over],
    idx: 16,
    depth: 2,
  },
];
