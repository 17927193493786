import styled from "@emotion/styled";
import {
  mBody1,
  mBody3,
  mTitle1, 
  neutralDay,
  scc,
  wBody1,
  wBody3,
  wBody4,
} from "@teamsparta/design-system";
import React from "react";

export const FeedbackWrapper = styled.div`
  display: flex;
  position: relative;
`;
export const FeedbackTooltipContainer = styled.div`
  position: absolute;
  top: 56px;
  right: -36px;
  width: 236px;
  z-index: 10;
  opacity: ${({ isTooltipShow }) => (isTooltipShow ? "1" : "0")};
  transition: ${({ isTooltipShow }) =>
      isTooltipShow ? "opacity 0.6s ease-in" : "opacity 0.3s ease-out"};
`;
export const CloseButtonWrapper = styled.div`
  position: absolute;
  top : 15px;
  right: 10px;
  width : 16px;
  height : 16px;
  cursor: pointer;  
`;

export const BackgroundImg = styled.img`
  width: 100%;
  height : 100%;
`;

export const FeedbackCtaWrapper = styled.div`
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${scc.red100};
  background-color: ${neutralDay.white};
  cursor: pointer;
  width: 143px;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: ${scc.red10};
  }
`;

export const FeedbackCtaOnPlayerWrapper = styled.div`
  display: inline-flex;
  padding: 12px 12px 12px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 70px;
  background-color: rgba(20, 22, 23, 0.9);
  position: absolute;
  top: 20px;
  right: 96px;
  z-index: 1;
  opacity: ${({ isFull }) => (isFull ? "1" : "0")};
  transition: opacity 0.3s ease-in;
  cursor: default;
`;

export const FeedbackCtaOnPlayerText = styled.span`
  ${wBody3};
  color: ${neutralDay.white};
  line-height: 22px;
`;

export const FeedbackCtaOnPlayer = styled.div`
  display: flex;
  min-width: 74px;
  padding: 9.5px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background-color: ${scc.red100};
  ${mBody3};
  color: ${neutralDay.white};
  line-height: 21px;
  cursor: pointer;
  text-align: center;
`;

export const FeedbackCtaText = styled.span`
  ${wBody1};
  color: ${scc.red100};
  line-height: 160%;
`;

export const FeedbackSelectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 32px 20px;
  align-items: center;
  width: 320px;
  border-radius: 16px;
  background-color: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  height: ${({ wrapperHeight }) => wrapperHeight};
  transition: height 0.3s ease-out;
  overflow: hidden;
`;

export const FeedbackSelectsTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

export const FeedbackSelectsTitle = styled.span`
  ${mTitle1};
  color: ${neutralDay.gray100};
  text-align: center;
  line-height: 25px;
  cursor: default;
`;

export const FeedbackSelectsSubTitle = styled.span`
  ${wBody4};
  color: ${neutralDay.gray70};
  line-height: 150%;
  text-align: center;
  white-space: pre-wrap;
  cursor: default;
`;

export const FeedbackSelectsButtonWrapper = styled.div`
  display: flex;
  width: 280px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  opacity: ${({ isSelected }) => (isSelected ? "0" : "1")};
  transition: opacity 0.3s ease-out;
`;

export const FeedbackCommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 280px;
`;

export const FeedbackCommentsTextArea = styled.textarea`
  width: 100%;
  height: 290px;
  padding: 14px 16px;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray30};
  background-color: ${neutralDay.white};
  resize: none;
  &:focus {
    outline: none;
  }
  ${wBody4};
  color: ${neutralDay.gray90};
  line-height: 22px;
  &::placeholder {
    ${wBody4};
    color: ${neutralDay.gray50};
    line-height: 22px;
  }
`;

export const FeedbackCommentSubmitButton = styled.div`
  display: flex;
  min-width: 100px;
  padding: 13px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${scc.red100};
  ${wBody1};
  color: ${neutralDay.white};
  line-height: 26px;
  text-align: center;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.3" : "1")};
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;

export const FeedbackSelectsButton = styled.div`
  display: flex;
  width: 134px;
  height: 136px;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  background-color: ${neutralDay.gray10};
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: ${scc.red15};
  }
`;

export const FeedbackSelectsEmoji = styled.img`
  width: 32px;
  height: 32px;
`;

export const FeedbackSelectsEmojiText = styled.span`
  ${mBody1};
  color: ${neutralDay.gray90};
  line-height: 22px;
  white-space: pre-wrap;
  text-align: center;
`;

export const AnotherFeedbackButton = styled.div`
  display: flex;
  width: 280px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray30};
  background-color: ${neutralDay.white};
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: ${scc.red10};
  }
`;

export const AnotherFeedbackButtonText = styled.span`
  ${mBody1};
  color: ${neutralDay.gray90};
  line-height: 22px;
  text-align: center;
  cursor: pointer;
`;

export const FeedbackResultImage = styled.img`
  width: 256px;
  opacity: ${({ isTrans }) => (isTrans ? "0" : "1")};
  transition: opacity 0.5s ease-in;
`;
