import * as S from "./loadingRtan.style";

export const LoadingRtan = ({ loadingText }) => {
  return (
    <S.LoadingWrapper>
      <S.LoadingRtan
        src={"/assets/images/codingrace/running_rtanny.gif"}
        style={{ transform: "scaleX(-1)" }}
      />
      <S.LoadingText>{loadingText}</S.LoadingText>
    </S.LoadingWrapper>
  );
};
