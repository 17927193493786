import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiLogUrl = () => {
  return `${baseUrl}/logs/access`;
};

export const getApiPaymentAccessLogUrl = () => {
  return `${baseUrl}/logs/payment/access`;
};

export const getApiFreeCourseCTAExposureLogUrl = () => {
  return `${baseUrl}/logs/free_course/exposure`;
};

export const getApiFreeCourseCTALogUrl = () => {
  return `${baseUrl}/logs/free_course/cta`;
};

export const postLogUrl = () => {
  return `${baseUrl}/logs/`;
};

export const getLogCrmABUrl = (user_id, device_id, exp_no) => {
  return `${baseUrl}/logs/crm?user_id=${user_id}&device_id=${device_id}&exp_no=${exp_no}`;
};
