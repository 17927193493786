import { config } from "../config";
import { handleResponse, authHeader, privateFetch } from "../_helpers";

function getCouponInfo(onPlan) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/accounts/coupon`;
  if (onPlan) {
    endpoint += "?onPlan=y";
  }
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

export const couponService = {
  getCouponInfo,
};
