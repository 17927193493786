import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import styles from "./RegNoPage.scss";
import { connect } from "react-redux";
import { paymentActionsV2 } from "../../_state/actions/payment.action";
import { govAction } from "../../_state/actions/gov.actions";
import { createEventId, history, params } from "../../../_helpers";
import { usersActions } from "../../_state/actions";
import { NavContainer } from "../../../_containers";
import { sendLog } from "../../businesslogics/logging";
import { userActions } from "../../../_actions";
import { getCookie } from "../../_helper";
import { FooterV2 } from "../../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);

function RegNoPage({
  user,
  paymentReinforcedByUser,
  getPaymentReinforcedByUser,

  userRegNo,
  getUserRegNo,
  postUserRegNo,
  loginWithToken,
}) {
  /**
   * 정부사업대상자에게 주민번호 입력받는 페이지.
   * 1. 주민번호 작성한 이력이 있는지 검사하고, 있으면 알맞게 리다이렉트
   *  - 정부과목: reinforcement && gov_user 등록 완료 시 정부과목 결제페이지로!
   * 2. 주민번호 작성 완료하면 DB에 저장하고, 콜백 받아서 Phone Verification 진행
   *  - 본인인증 마친 후 정부과목 수강자 -> reinforcement && gov_user 등록 완료 시 정부과목 결제페이지로!
   */
  const regnoRef = useRef();
  const [isAlreadyReg, setIsAlreadyReg] = useState(true);

  useEffect(() => {
    if (params().logined && !user) {
      let token = getCookie("token");
      if (token) {
        loginWithToken(token);
        if (params().is_new) {
          const eventID = createEventId();
          sendLog("complete_registration", {}, eventID);
        }
      }
    } else if (!params().logined && !user) {
      alert("로그인 해주시기 바랍니다.");
      history.push("/login?next=%2fregno");
      return;
    }
    if (user) {
      getUserRegNo();
      getPaymentReinforcedByUser();
    }
  }, [user]);

  useEffect(() => {
    if (!paymentReinforcedByUser.loaded) {
      return;
    }
    if (userRegNo.loaded && userRegNo.data) {
      const msg = `${
        isAlreadyReg
          ? "이미 본인 인증하셨습니다."
          : "본인 인증이 완료되었습니다."
      }${
        paymentReinforcedByUser.loaded &&
        paymentReinforcedByUser.data &&
        paymentReinforcedByUser.data.length > 0
          ? ""
          : "\n결제 링크가 등록되지 않았습니다.\n매니저에게 문의해주세요."
      }`;
      alert(msg);

      if (
        !(
          paymentReinforcedByUser.loaded &&
          paymentReinforcedByUser.data &&
          paymentReinforcedByUser.data.length > 0
        )
      ) {
        // 결제 커스텀 데이터가 없는 경우 메인 redirect
        history.push("/");
        return;
      }

      const verification_requireds = paymentReinforcedByUser.data.filter(
        (data) => data.is_phone_verifictaion_needed
      );
      if (verification_requireds.length > 0) {
        const { course_id, round_id } = verification_requireds[0];
        history.push(`/payment/${course_id}?round_id=${round_id}`);
      }
    }
  }, [userRegNo.loaded, paymentReinforcedByUser.loaded]);

  function fillHyphen(e) {
    let regno = e.target.value;
    regno = regno.split("-").join("");
    const birthday = regno.substr(0, 6);
    const distinct = regno.length > 6 ? `-${regno.substr(6, 7)}` : "";
    regno = birthday + distinct;
    if (regnoRef.current) {
      regnoRef.current.value = regno;
    }
  }

  function validateRegNo(regno) {
    return !!regno.match(/^[0-9]{6}[-]+[0-9]{7}$/);
  }

  function submitRegNo() {
    setIsAlreadyReg(false);
    if (!regnoRef.current) return;
    const reg_no = regnoRef.current.value;
    if (!validateRegNo(reg_no)) {
      alert("올바른 주민등록번호를 입력해주세요.");
      return;
    }

    if (
      !window.confirm(
        `주민등록번호: ${reg_no}\n위 정보가 맞는지 확인해주세요.\n신청자 정보가 일치하지 않을 경우 불이익이 발생할 수 있습니다.`
      )
    ) {
      return;
    }

    postUserRegNo({ reg_no });
  }

  return (
    <>
      {user && (
        <>
          <NavContainer />
          <div className={cx("reg_no")}>
            <h2 className={cx("reg_no__h2")}>
              서비스 제공을 위한 개인정보 입력
            </h2>
            <div className={cx("reg_no__content")}>
              <p className={cx("reg_no__content__p")}>
                정확한 정보를 입력해주세요!
                <br />
                오탈자 등으로 인해,
                <br />
                고용24 신청자 정보 확인이 되지 않을 경우
                <br />
                <b>불이익</b>이 발생할 수 있습니다.
              </p>
              <div className={cx("reg_no__content__input_container")}>
                <input
                  ref={regnoRef}
                  className={cx("reg_no__content__input_container__input")}
                  type="text"
                  placeholder="예시)970101-1111111"
                  onKeyUp={fillHyphen}
                />
              </div>
              <div className={cx("reg_no__content__cta_group")}>
                <div className={cx("reg_no__content__cta_group__agree")}>
                  <a
                    href="https://www.notion.so/teamsparta/3-f2e359d2a710447db3036ff2a3156869"
                    target="_blank"
                  >
                    개인정보처리방침 및 제3자 제공
                  </a>
                  에 동의하고
                </div>
                <div
                  className={cx("reg_no__content__cta_group__cta")}
                  onClick={() => {
                    submitRegNo();
                  }}
                >
                  제출하고 본인인증하기
                </div>
              </div>
            </div>
          </div>
          <FooterV2 />
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    hasGovAuthorized,
    userRegNo,
    paymentReinforcedByUser,
  } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    hasGovAuthorized,
    userRegNo,
    paymentReinforcedByUser,
  };
}

const connectedRegNoPage = connect(mapStateToProps, {
  loginWithToken: userActions.loginWithToken,
  getPaymentReinforcedByUser: paymentActionsV2.getPaymentReinforcedByUser,
  getHasGovAuthorized: govAction.getHasGovAuthorized,
  getUserRegNo: usersActions.getUserRegNo,
  postUserRegNo: usersActions.postUserRegNo,
})(RegNoPage);
export { connectedRegNoPage as RegNoPage };
