import * as S from "./CourseRcmdPirates.style";
import React from "react";
import { connect, useSelector } from "react-redux";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

const CourseRcmdPirates = ({ user, isRoot, fName, fId }) => {
  const enrolled = useSelector((state) => state.enrolled);
  const isRcmd = fName && fId;
  const cta =
    isRoot || isRcmd
      ? {
          text: "다음 수업 보러 가기",
          href: `https://spartacodingclub.kr/online/pirates/web_mobile${
            isRcmd ? `?f_name=${fName}&f_uid=${fId}` : ""
          }`,
          page: "해적단 웹 lv1. 강의상세 페이지",
        }
      : {
          text: "다음 레벨 확인하기",
          href: `https://spartacodingclub.kr/catalog?category=${encodeURIComponent(
            "해적단 코스"
          )}`,
          page: "수업 탐색 해적단 탭",
        };
  return (
    <S.Container>
      {!isRcmd ? (
        isRoot ? (
          <>
            <S.Title>
              웹개발 종합반으로 시작했다면
              <br />
              해적단 코스로 레벨업!
            </S.Title>
            <S.Desc isMarginLong={true}>
              웹개발 기초를 끝낸 {user.name}님, {`\n`}아직 완벽하지 않아도
              괜찮아요.
              <br />
              해적단 코스에서는 새로운 기능을 배워보며
              <br />
              업그레이드된 실습 예제를 구현해봅니다.
            </S.Desc>
            <S.CardsContainer>
              <S.DarkCard>
                <S.CardHeader>
                  <S.Tag>{user.name}님의 다음 레벨</S.Tag>
                  <S.CouponTitle>모바일 반응형 웹</S.CouponTitle>
                </S.CardHeader>
                <S.Thumbnail src={"/assets/images/finish/pirates_web_1.png"} />
                <S.CouponNotice>
                  내가 만든 팬명록, 모바일에서도 보고 싶다면? <br />
                  반응형으로 업그레이드된 웹사이트를 만들어보세요!
                </S.CouponNotice>
              </S.DarkCard>
              <S.DarkCard>
                <S.CardHeader>
                  <S.Tag>웹개발 종합반 완주 혜택</S.Tag>
                  <S.CouponTitle>해적단 코스 20% 할인 쿠폰</S.CouponTitle>
                </S.CardHeader>
                <S.Thumbnail src={"/assets/images/finish/coupon_pirates.png"} />
                <S.CouponNotice>
                  * 발급 시점으로부터 7일간 유효한 쿠폰입니다.
                  <br />* 본 쿠폰은 마이페이지>쿠폰에서 확인할 수 있습니다.
                  <br />
                </S.CouponNotice>
              </S.DarkCard>
            </S.CardsContainer>
          </>
        ) : (
          <>
            <S.TitleSubText>단계별로 성장하는</S.TitleSubText>
            <S.Title>스파르타 해적단 코스</S.Title>
            <S.Desc>
              다음 레벨 정복을 위한 쿠폰이 발급되었어요.
              <br />
              지금 바로 확인하고, 더욱 멋진 기능들을 배워보세요.
            </S.Desc>
            <S.Tags>
              <S.Tag>취미 코딩</S.Tag>
              <S.Tag>웹개발</S.Tag>
              <S.Tag>심화 코스</S.Tag>
            </S.Tags>
            <S.CouponContainer>
              <S.FlatLogo src={"/assets/images/finish/logo_pirates.png"} />
              <S.Coupon src={"/assets/images/finish/coupon_pirates.png"} />
              <S.CouponTitle>해적단 코스 전용 할인 쿠폰 발급</S.CouponTitle>
              <S.CouponNotice>
                * 발급 시점으로부터 2주간 유효한 쿠폰입니다.
                <br />* 본 쿠폰은 마이페이지>쿠폰에서 확인할 수 있습니다.
              </S.CouponNotice>
            </S.CouponContainer>
          </>
        )
      ) : (
        <>
          <S.Title>
            스파르타 해적단 코스를
            <br />
            {fName} 님이 추천해요
          </S.Title>
          <S.Desc>
            단계별로 성장하는 스파르타 해적단 코스.
            <br />
            스파르타 해적단의 동료가 되어 지금 출항하세요!
          </S.Desc>
          <S.Tags>
            <S.Tag>취미 코딩</S.Tag>
            <S.Tag>웹개발</S.Tag>
            <S.Tag>심화 코스</S.Tag>
          </S.Tags>
          <S.CircleLogo src={"/assets/images/finish/logo_pirates_circle.png"} />
        </>
      )}

      <S.Cta
        onClick={() => {
          sendLogActionRcmdPage(
            "pirates",
            "pirates",
            "cta",
            cta.text,
            cta.href,
            cta.page,
            enrolled.data
          );
          window.open(cta.href);
        }}
      >
        {cta.text}
      </S.Cta>
    </S.Container>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdPirates = connect(
  mapStateToProps,
  {}
)(CourseRcmdPirates);
export { connectedCourseRcmdPirates as CourseRcmdPirates };
