import { authHeader } from "../../../_helpers";
import { config } from "../../../config";

async function postHanghaeOrientationInvitation(name, phone, email, ot_date) {
  const dateParsing = ot_date.split(" ");
  const otYear = new Date().getFullYear();
  const otMonth = parseInt(dateParsing[0][0]);
  const otDay = parseInt(dateParsing[1].split("일")[0]);
  const otTime = parseInt(dateParsing[4].split("시")[0]);
  const otDate = new Date(
    otYear,
    otMonth - 1,
    otDay + 1,
    otTime - 3,
    0,
    0
  ).toISOString();
  const otDateString = `${otMonth}월 ${otDay}일 오후 ${otTime}시`;
  const token = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${token.Authorization}`,
  };

  return await fetch(`${config.hanghaeUrl}/orientation/invitation`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      name: name,
      phone: phone,
      email: email,
      ot_date: otDate,
      ot_date_str: otDateString,
    }),
  }).then((res) => {
    return res;
  });
}

export const hanghaeOrientationInvitationService = {
  postHanghaeOrientationInvitation,
};
