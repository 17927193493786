import React, { useRef, useState } from "react";
import classNames from "classnames/bind";
import styles from "./FinishPage.module.scss";
import {
  getCourseSkills,
  getCustomUserNameTitle,
  getMedalImagePath,
  getStudyTimes,
  getUsernameTitle,
  isCoustomUserNameTitle,
  onClickDownArrow,
  onClickShareKko,
  onClickShareLink,
  sendLogAction,
} from "../../businesslogics/MedalImage";
import { FacebookIcon, FacebookShareButton } from "react-share";
import Lottie from "lottie-react";
import gptScrollDown from "./gptScrollDown.json";
import arrow from "./down-arrows.json";
import { ImageBanner } from "./ImageBanner";
import {
  logCtaClick,
  onClickSaveImage,
  useArrow,
  useShareUrl,
} from "../../hooks/nps/congrats";
import { useIsMobile } from "../../hooks/device";

const cx = classNames.bind(styles);

const Congrats = ({
  user,
  enrolled,
  isRepurchase,
  enrolledPlaytime,
  nextStep,
}) => {
  const [showArrow, setShowArrow] = useState(true);
  const isMobile = useIsMobile();
  const downloadRef = useRef(null);
  const realRef = useRef(null);
  const shareUrl = useShareUrl(
    enrolled,
    isRepurchase,
    user,
    enrolledPlaytime,
    nextStep
  );
  useArrow(setShowArrow);

  return (
    <>
      {/* 다운로드할 이미지를 그려내는 고정 크기 hidden 컨테이너 */}
      <div className={cx("imageDown__container")}>
        <div
          ref={downloadRef}
          className={cx("finish_page__container")}
          style={{
            "--bg-image": `url(https://media4.giphy.com/media/rGg9aM4uTvUgKGVkaC/giphy.gif?cid=790b7611037869f7373db44f7a40443a017dbb1f8603f5fb&rid=giphy.gif&ct=s)`,
          }}
        >
          <div className={cx("finish_page__wrapper")}>
            <div className={cx("finish_page__header")}>
              <div>
                <span>완주를 축하합니다!</span>
                <h1>
                  {getUsernameTitle(
                    isRepurchase,
                    enrolled.data?.courses.is_pirates
                      ? enrolled.data?.courses.title
                      : null
                  )}
                  <br />
                  {user.name} 님
                </h1>
              </div>
              <img
                src={getMedalImagePath(
                  isRepurchase,
                  enrolled.data?.courses.is_trial
                )}
              />
            </div>

            <div className={cx("finish_page_medal__container")}>
              <div className={cx("finish_page_medal__box")}>
                <div className={cx("finish_page_medal__box-innerbox")}>
                  <p className={cx("finish_page_medal__box-title")}>공부시간</p>
                  <div>
                    {getStudyTimes(enrolledPlaytime.data?.total_playtime)}
                  </div>
                </div>

                <div className={cx("finish_page_medal__box-innerbox")}>
                  <p className={cx("finish_page_medal__box-title")}>
                    배운 스킬
                  </p>
                  <div>{getCourseSkills(enrolled.data?.courses._id)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 유저가 실제로 인터렉션하는 진짜 컨테이너 */}
      <div className={cx("finish_page__container")} ref={realRef}>
        <div
          className={cx("finish_page__wrapper")}
          style={{
            "--bg-image": `url(https://media4.giphy.com/media/rGg9aM4uTvUgKGVkaC/giphy.gif?cid=790b7611037869f7373db44f7a40443a017dbb1f8603f5fb&rid=giphy.gif&ct=s)`,
          }}
        >
          <div className={cx("finish_page__header")}>
            <div>
              <span>완주를 축하합니다!</span>
              {isCoustomUserNameTitle(enrolled.data?.courses._id) ? (
                <>
                  {getCustomUserNameTitle(
                    enrolled.data?.courses._id,
                    user.name
                  )}
                </>
              ) : (
                <h1>
                  {getUsernameTitle(
                    isRepurchase,
                    enrolled.data?.courses.is_pirates
                      ? enrolled.data?.courses.title
                      : null
                  )}
                  <br />
                  {user.name} 님
                </h1>
              )}
            </div>
            <img
              src={getMedalImagePath(
                isRepurchase,
                enrolled.data?.courses.is_trial
              )}
            />
          </div>

          <div className={cx("finish_page_medal__container")}>
            <div className={cx("finish_page_medal__box")}>
              <div className={cx("finish_page_medal__box-innerbox")}>
                <p className={cx("finish_page_medal__box-title")}>공부시간</p>
                <div>
                  {getStudyTimes(enrolledPlaytime.data?.total_playtime)}
                </div>
              </div>

              <div className={cx("finish_page_medal__box-innerbox")}>
                <p className={cx("finish_page_medal__box-title")}>배운 스킬</p>
                <span>{getCourseSkills(enrolled.data?.courses._id)}</span>
              </div>
            </div>
          </div>

          <div
            className={cx("finish_page__medal-sns")}
            data-html2canvas-ignore="true"
          >
            <div
              onClick={() =>
                onClickShareKko(shareUrl.href, user.name, enrolled)
              }
              style={{ cursor: "pointer" }}
            >
              <img
                width={"46.5px"}
                src={"/assets/icons/ic_share_kakao.png"}
                alt=""
              />
            </div>

            <FacebookShareButton
              url={shareUrl.href}
              style={{ display: "block!important" }}
              onClick={() => {
                sendLogAction("페이스북", enrolled);
              }}
            >
              <FacebookIcon size={48} round={true} borderRadius={24} />
            </FacebookShareButton>

            {isMobile && (
              <div
                onClick={() =>
                  onClickShareLink(user.name, shareUrl.href, enrolled)
                }
              >
                <img width={"46.5px"} src="/assets/icons/ic_share.png" alt="" />
              </div>
            )}
          </div>

          <div
            className={cx("finish_page__medal-cta")}
            onClick={() => {
              onClickSaveImage(downloadRef, enrolled);
              logCtaClick("메달 사진 저장하기");
            }}
          >
            <img
              src="/assets/images/finish/download_arrow.svg"
              alt="download"
            />
            메달 사진 저장하기
          </div>

          {isCoustomUserNameTitle(enrolled.data?.courses._id) ? (
            <Lottie
              animationData={gptScrollDown}
              className={cx("finish_page__scrollDown")}
              onClick={() => onClickDownArrow(realRef)}
            />
          ) : (
            showArrow && (
              <Lottie
                className={cx("finish_page__arrow")}
                animationData={arrow}
              />
            )
          )}
          <ImageBanner course_id={enrolled.data?.courses._id} />
        </div>
      </div>
    </>
  );
};

export default Congrats;
