import { useIsMobile } from "../../../../hooks/device";

export const VoucherSvg = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66797 5.66683C1.66797 5.20659 2.04106 4.8335 2.5013 4.8335H17.5013C17.9615 4.8335 18.3346 5.20659 18.3346 5.66683V8.2324C17.3383 8.76441 16.668 9.75879 16.668 10.8977C16.668 12.0366 17.3383 13.031 18.3346 13.563V15.5002C18.3346 15.9604 17.9615 16.3335 17.5013 16.3335H2.5013C2.04106 16.3335 1.66797 15.9604 1.66797 15.5002V13.563C2.6643 13.031 3.33464 12.0366 3.33464 10.8977C3.33464 9.75879 2.6643 8.76441 1.66797 8.2324V5.66683Z"
        fill="#81898F"
      />
      <path
        d="M18.3346 8.2324L18.7272 8.9675L19.168 8.73212V8.2324H18.3346ZM18.3346 13.563H19.168V13.0632L18.7272 12.8279L18.3346 13.563ZM1.66797 13.563L1.27545 12.8279L0.834635 13.0633V13.563H1.66797ZM1.66797 8.2324H0.834635V8.73212L1.27545 8.9675L1.66797 8.2324ZM2.5013 4.00016C1.58083 4.00016 0.834635 4.74635 0.834635 5.66683H2.5013V5.66683V4.00016ZM17.5013 4.00016H2.5013V5.66683H17.5013V4.00016ZM19.168 5.66683C19.168 4.74636 18.4218 4.00016 17.5013 4.00016V5.66683H17.5013H19.168ZM19.168 8.2324V5.66683H17.5013V8.2324H19.168ZM17.5013 10.8977C17.5013 10.102 17.9693 9.37219 18.7272 8.9675L17.9421 7.4973C16.7073 8.15664 15.8346 9.41558 15.8346 10.8977H17.5013ZM18.7272 12.8279C17.9693 12.4232 17.5013 11.6934 17.5013 10.8977H15.8346C15.8346 12.3798 16.7073 13.6387 17.9421 14.2981L18.7272 12.8279ZM19.168 15.5002V13.563H17.5013V15.5002H19.168ZM17.5013 17.1668C18.4218 17.1668 19.168 16.4206 19.168 15.5002H17.5013V17.1668ZM2.5013 17.1668H17.5013V15.5002H2.5013V17.1668ZM0.834635 15.5002C0.834635 16.4206 1.58082 17.1668 2.5013 17.1668V15.5002H2.5013H0.834635ZM0.834635 13.563V15.5002H2.5013V13.563H0.834635ZM2.5013 10.8977C2.5013 11.6934 2.03334 12.4232 1.27545 12.8279L2.06049 14.2981C3.29527 13.6387 4.16797 12.3798 4.16797 10.8977H2.5013ZM1.27545 8.9675C2.03334 9.37219 2.5013 10.102 2.5013 10.8977H4.16797C4.16797 9.41558 3.29527 8.15664 2.06049 7.4973L1.27545 8.9675ZM0.834635 5.66683V8.2324H2.5013V5.66683H0.834635Z"
        fill="#81898F"
      />
      <path
        d="M11.667 9L8.33366 12.3333"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.33333" cy="8.99984" r="0.833333" fill="white" />
      <circle
        cx="11.6663"
        cy="12.3333"
        r="0.416667"
        fill="#81898F"
        stroke="white"
        strokeWidth="0.833333"
      />
    </svg>
  );
};
