import { prequestionService } from "../services";
import { PrequestionConstant } from "../constants";

function getPrequestionExist(course_id, round_id) {
  function request() {
    return { type: PrequestionConstant.GET_PREQUESTION_REQUEST };
  }

  function success(response) {
    return { type: PrequestionConstant.GET_PREQUESTION_SUCCESS, response };
  }

  function failure(error) {
    return { type: PrequestionConstant.GET_PREQUESTION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    prequestionService.getPrequestionExist(course_id, round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getPreqOfEnrolled(enrolled_id) {
  function request() {
    return { type: PrequestionConstant.PREQUESTION_OF_ENROLLED_REQUEST };
  }

  function success(response) {
    return {
      type: PrequestionConstant.PREQUESTION_OF_ENROLLED_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return { type: PrequestionConstant.PREQUESTION_OF_ENROLLED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    prequestionService.getPreqOfEnrolled(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const prequestionAction = {
  getPrequestionExist,
  getPreqOfEnrolled,
};
