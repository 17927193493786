import { getArticleHeader } from "../../pages/CompleteRecommendPage/getCompleteRecommendData";
import { usePopularArticles } from "./usePopularArticles";

export const useArticleHeader = ({ courseId }) => {
  const articles = usePopularArticles(courseId);

  const brand = articles[0].brand;

  const { caption, title } = getArticleHeader({ brand });

  return { caption, title };
};
