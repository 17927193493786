import React, {useEffect, useState} from 'react'
import classNames from 'classnames/bind';
import styles from './RtannyGround.module.scss';
import {connect} from 'react-redux';
import {Rtanny} from "./Rtanny";
import {checkinActions} from "../../../_state/actions/checkin.actions"

const cx = classNames.bind(styles);

function RtannyGround({
                          bestCheckins
}) {
    return (
        <div className={cx('RtannyGroundContainer')}>
            {bestCheckins && bestCheckins.loaded &&
            bestCheckins.data.checkins.map((checkin, idx) => {
                return (
                    <Rtanny checkin={checkin} idx={idx + 1}/>
                )
            })
            }
            <div className={cx('dotted')}></div>
        </div>
    )
}

function mapStateToProps(state) {
    const {authentication, bestCheckins} = state;
    const {user} = authentication;
    return {
        bestCheckins,
        authentication,
        user,
    }
}

const connectedRtannyGround = connect(mapStateToProps, {})(RtannyGround);
export {connectedRtannyGround as RtannyGround};