import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  EnrolledsEvaluationDetails,
  evaluationService as evaluationsService,
} from "../../../_state/services/evaluation.serveice";
import { history } from "../../../../_helpers";

export const useMyEvaluations = (enrolledsEvaluationsId: string) => {
  return useQuery(
    ["myEvaluations", enrolledsEvaluationsId],
    async () => {
      return await evaluationsService.getMyEvaluations(enrolledsEvaluationsId);
    },
    {
      enabled: !!enrolledsEvaluationsId,
    }
  );
};

export const usePostMyEvaluations = (
  enrolledsEvaluationId: string,
  enrolledId: string
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (evaluation: EnrolledsEvaluationDetails[]) => {
      return evaluationsService.postMyEvaluations(
        enrolledId,
        enrolledsEvaluationId,
        evaluation
      );
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "orderedEnrollmentContents",
          enrolledId,
        ]);
        await queryClient.invalidateQueries([
          "myEvaluations",
          enrolledsEvaluationId,
        ]);
        history.push(`${enrolledsEvaluationId}/results`);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};
