import React, { useState } from "react";
import * as S from "./ErrorReport.style";
import { useSelector } from "react-redux";
import { useUser } from "../../useInitialize";
import privateInstance from "../../../../_helper/axios";
import { useToastMessage } from "../../../LecturePage/LecLNB/Toast/Toast";
import { sendLog } from "../../../../businesslogics/logging";
import { useOnlineTheme } from "../../../../queries/pirates";
import { parseUserAgent } from "../../../../utils/ErrorReport/userAgent";
import { formattedErrorTime } from "../../../../utils/ErrorReport/errorReport";
import { ErrorMoment } from "../../../../components/organisms/ErrorReport/ErrorMoment";

export const ErrorReport = ({ setReportState }) => {
  const [type, setType] = useState("default");
  const [score, setScore] = useState("default");
  const [comment, setComment] = useState("");
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const lecture_v2 = useSelector((state) => state.lecture_v2);
  const enrolled = useSelector((state) => state.enrolled);
  const course = useSelector((state) => state.course);
  const user = useUser();
  const toastAlert = useToastMessage();
  const initialMinute =
    parseInt(
      localStorage.getItem(`videoProgress_${lecture_v2.data.lecture_id}`) || 0
    ) / 60;
  const initialSecond =
    parseInt(
      localStorage.getItem(`videoProgress_${lecture_v2.data.lecture_id}`) || 0
    ) % 60;
  const [errorMinute, setErrorMinute] = useState(initialMinute);
  const [errorSecond, setErrorSecond] = useState(initialSecond);
  const handleSelect = (e) => {
    setType(e.target.value);
  };
  const handleScore = (e) => {
    setScore(e.target.value);
  };

  const handleSubmitReport = () => {
    if (type === "" || comment === "" || score === "") {
      return;
    }

    const userAgent = parseUserAgent(window.navigator.userAgent);
    const { enrolled_id, enrolled_detail_id, lecture_id } = enrolledDetail.data;
    const { _id: user_id, name, email, phone } = user;
    const data = {
      is_gov: course.data.is_gov,
      type: type,
      score: parseInt(score.match(/\d+/)[0]),
      score_string: score,
      comment: comment,
      errorTime: formattedErrorTime(type, errorMinute, errorSecond),
      user_id: user_id,
      os: userAgent.os,
      browser: userAgent.browser,
      name: name,
      email: email,
      phone: phone,
      enrolled_id: enrolled_id,
      enrolled_detail_id: enrolled_detail_id,
      lecture_id: lecture_id,
    };
    return privateInstance.post(`/report/lectures`, data).then((resp) => {
      const result = resp.data;
      if (result.ok) {
        sendLog("scc_lecturePage_report_completed", {
          ...enrolled.data,
          ...enrolledDetail.data,
          enrolled_id: enrolled_id,
          course_title: course.data?.title,
          week: enrolledDetail.data?.week,
          week_order: enrolledDetail.data?.week_order,
          type: type,
        });
        setReportState("CONTENTS_ERROR_REPORT_SUBMITTED");
        setTimeout(() => {
          setReportState("SELECT_TYPE");
        }, 5000);
        toastAlert("제보해 주셔서 감사합니다. 빠르게 개선할게요!");
      } else {
        toastAlert("오류 제보에 실패하였습니다! 새로고침 후 다시 시도해주세요");
      }
    });
  };
  const theme = useOnlineTheme();

  return (
    <S.ErrorReportContainer>
      <S.ErrorReportTitle theme={theme.data}>
        강의 내용 오류 제보
      </S.ErrorReportTitle>
      <S.ErrorReportSubTitle theme={theme.data}>종류</S.ErrorReportSubTitle>

      <S.ErrorReportSelectBox
        theme={theme.data}
        onChange={handleSelect}
        defaultValue={"default"}
        isDefault={type === "default"}
      >
        <option value="default" disabled hidden>
          종류를 선택해주세요.
        </option>
        <option value="영상 오류">영상 내용 오류</option>
        <option value="강의자료 오류">강의 자료 오류</option>
        <option value="코드스니펫 오류">코드스니펫 오류</option>
        <option value="기타">기타 (제안 등)</option>
      </S.ErrorReportSelectBox>
      <ErrorMoment
        type={type}
        theme={theme}
        errorMinute={errorMinute}
        errorSecond={errorSecond}
        enrolledDetail={enrolledDetail}
        setErrorMinute={setErrorMinute}
        setErrorSecond={setErrorSecond}
      />
      <S.ErrorReportSubTitle theme={theme.data}>점수</S.ErrorReportSubTitle>
      <S.ErrorReportSelectBox
        theme={theme.data}
        onChange={handleScore}
        defaultValue={"default"}
        isDefault={score === "default"}
      >
        <option value="default" disabled hidden>
          어느 정도의 불편함을 겪고 계신가요?
        </option>
        <option value="1점- 있으면 좋을 정도의 기능 제안">
          1점- 있으면 좋을 정도의 기능 제안
        </option>
        <option value="2점- 강의 진행에는 불편함이 없는 옥의 티">
          2점- 강의 진행에는 불편함이 없는 옥의 티
        </option>
        <option value="3점- 강의 진행이 가능하나 약간 불편함">
          3점- 강의 진행이 가능하나 약간 불편함
        </option>
        <option value="4점- 강의 진행이 거의 불가할 정도로 불편함">
          4점- 강의 진행이 거의 불가할 정도로 불편함
        </option>
        <option value="5점- 강의 진행이 완전히 불가함">
          5점- 강의 진행이 완전히 불가함
        </option>
      </S.ErrorReportSelectBox>
      <S.ErrorReportSubTitle theme={theme.data}>내용</S.ErrorReportSubTitle>
      <S.ErrorReportTextarea
        theme={theme.data}
        id="lecturePageErrorTextarea"
        placeholder="어떤 오류가 있는지 자세하게 작성해 주시면 빠른 시일 내로 해결하겠습니다."
        onChange={(e) => setComment(e.target.value)}
      ></S.ErrorReportTextarea>
      <S.ErrorReportSubmitWrapper>
        <S.BackToErrorTypeSelectorButton
          onClick={() => setReportState("SELECT_TYPE")}
        >
          이전으로
        </S.BackToErrorTypeSelectorButton>
        <S.ErrorReportSubmitBtn
          theme={theme.data}
          onClick={() => handleSubmitReport()}
          isActive={type !== "default" && comment !== "" && score !== "default"}
        >
          제보하기
        </S.ErrorReportSubmitBtn>
      </S.ErrorReportSubmitWrapper>
    </S.ErrorReportContainer>
  );
};
