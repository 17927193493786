import React from "react";
import { TextAnswerContainer } from "../../../molecules/HomeworkV3/HomeworkAnswer/HomeworkAnswer.style";
import * as E from "../../../molecules/HomeworkV3/HomeworkAnswer/HomeworkAnswer.style";
import { EvaluationData } from "../../../../businesslogics/OrderedEnrollmentContents/model/evaluation.dto";

interface MultipleChoiceTypeProps {
  questionData: EvaluationData;
  options: string[];
  answer: string;
  setAnswer: (answer: string) => void;
}

export const MultipleChoiceType = ({
  questionData,
  options,
  answer,
  setAnswer,
}: MultipleChoiceTypeProps) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOptionIndex = options.indexOf(event.target.value) + 1;
    setAnswer(String(selectedOptionIndex));
  };
  return (
    <E.SelectAnswerWrapper>
      {options.map((option, index) => (
        <E.SelectBox key={index}>
          <E.SelectRadio
            type="radio"
            name={`multipleChoice-${questionData.evaluationsDetailId}`}
            value={option}
            checked={answer === String(index + 1)}
            onChange={handleRadioChange}
          />
          {option}
        </E.SelectBox>
      ))}
    </E.SelectAnswerWrapper>
  );
};
