import { useQuery } from "react-query";
import privateInstanceV3 from "../../../_helper/axiosV3";

const oecCacheTime = 1000 * 5 * 60; // 5 minutes

const getOrderedEnrollmentContents = async (enrolledId) => {
  return await privateInstanceV3.get(
    `/learning-resources/enrollments/${enrolledId}/contents`
  );
};

export const useOrderedEnrollmentContents = (enrolledId) => {
  return useQuery(
    ["orderedEnrollmentContents", enrolledId],
    async () => {
      return await getOrderedEnrollmentContents(enrolledId);
    },
    {
      onSuccess: (data) => {
        console.log("onSuccess");
      },
      onError: (error) => {
        console.log("onError");
      },
      enabled: !!enrolledId,
      staleTime: oecCacheTime,
    }
  );
};
