import privateInstance from "../../_helper/axios";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export const getIsPirates = async (roundId) => {
  return await privateInstance
    .get(`/pirates/round/${roundId}`)
    .then((resp) => resp.data.context);
};

export const getIsPiratesRootCourse = async (courseId) => {
  return await privateInstance
    .get(`/pirates/root/course/${courseId}`)
    .then((resp) => resp.data.context);
};

export const getIsPiratesRootRound = async (roundId) => {
  return await privateInstance
    .get(`/pirates/root/round/${roundId}`)
    .then((resp) => resp.data.context);
};

export const getOnlineTheme = async (enrolledId) => {
  return await privateInstance
    .get(`/pirates/theme/${enrolledId}`)
    .then((resp) => resp.data.context);
};

export const getIsGoalQuestionShow = async (enrolledId) => {
  return await privateInstance
    .get(`/pirates/last_week_question/${enrolledId}`)
    .then((resp) => resp.data.context);
};

export const getIsPiratesPaymentPossible = async (courseId) => {
  return await privateInstance
    .get(`/pirates/payment/${courseId}`)
    .then((resp) => resp.data.context);
};

export const useIsPirates = (roundId) => {
  return useQuery(
    ["isPirates", roundId],
    () => {
      if (roundId) {
        return getIsPirates(roundId);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useIsPiratesRootCourse = (courseId) => {
  return useQuery(
    ["isPiratesRoot", courseId],
    () => {
      if (courseId) {
        return getIsPiratesRootCourse(courseId);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useIsPiratesRootRound = (roundId) => {
  return useQuery(
    ["isPiratesRoot", roundId],
    () => {
      if (roundId) {
        return getIsPiratesRootRound(roundId);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useOnlineTheme = () => {
  const { pathname } = useLocation();
  const enrolled_id =
    (
      pathname.split("/enrolleds/")[1] ||
      pathname.split("/m/lecture_report/enrolleds/")[1]
    )?.split("/")[0] || null;

  return useQuery(
    ["theme", enrolled_id],
    () => {
      if (enrolled_id) {
        return getOnlineTheme(enrolled_id);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useIsGoalQuestionShow = () => {
  const { pathname } = useLocation();
  const enrolled_id =
    pathname.split("/enrolleds/")[1]?.split("/nps/")[0] || null;

  return useQuery(
    ["lastWeekQuestion", enrolled_id],
    () => {
      if (enrolled_id) {
        return getIsGoalQuestionShow(enrolled_id);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useIsPiratesPaymentPossible = (courseId) => {
  return useQuery(
    ["isPiratesPaymentPossible", courseId],
    () => {
      if (courseId) {
        return getIsPiratesPaymentPossible(courseId);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};
