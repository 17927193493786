import privateInstance from "../../_helper/axios";

async function getRoundWeeks(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/weeks`)
    .then((resp) => resp.data);
}

async function setEnrolled(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}`)
    .then((resp) => resp.data);
}

async function getEnrolleds() {
  return await privateInstance.get(`/enrolleds/`).then((resp) => resp.data);
}

async function getActiveEnrolleds() {
  return await privateInstance
    .get(`/enrolleds/active`)
    .then((resp) => resp.data);
}

async function getStudyplans(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/studyplans`)
    .then((resp) => resp.data);
}

async function setStudyplans(enrolled_id, data) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/studyplans`, data)
    .then((resp) => resp.data);
}

async function getExpired(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/expired`)
    .then((resp) => resp.data);
}

async function setExpired(enrolled_id, data) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/expired`, data)
    .then((resp) => resp.data);
}

async function getPlaytime(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/playtime`)
    .then((resp) => resp.data);
}

async function getEnrolledReimbursed(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/is_reimbursed`)
    .then((resp) => resp.data);
}

async function getProgress(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/progress`)
    .then((resp) => resp.data);
}

async function getPeriod(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/periods`)
    .then((resp) => resp.data);
}

async function getRank(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/ranks`)
    .then((resp) => resp.data);
}

async function getEnrolledDetail(enrolled_id, detail_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/details/${detail_id}`)
    .then((resp) => resp.data);
}

async function getEnrolledDetails(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/details`)
    .then((resp) => resp.data);
}

async function doEnrolledDetail(enrolled_id, detail_id) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/details/${detail_id}`)
    .then((resp) => resp.data);
}

async function submitDetailReview(enrolled_id, detail_id, data) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/details/${detail_id}/reviews`, data)
    .then((resp) => resp.data);
}

async function getEnrolledBadges(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/badges`)
    .then((resp) => resp.data);
}

async function getDetailObstacle(enrolled_id, detail_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/details/${detail_id}/obstacles`)
    .then((resp) => resp.data);
}

async function getDetailPresent(enrolled_id, detail_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/details/${detail_id}/presents`)
    .then((resp) => resp.data);
}

async function postDetailPresent(enrolled_id, detail_id, data) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/details/${detail_id}/presents`, data)
    .then((resp) => resp.data);
}

async function getSalecall(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/secretstore/salescall`)
    .then((resp) => resp.data);
}

async function postSalescall(enrolled_id, data) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/secretstore/salescall`, data)
    .then((resp) => resp.data);
}

async function getEnrolledDoneCount(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/dones/today`)
    .then((resp) => resp.data);
}

async function getMaker(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/maker`)
    .then((resp) => resp.data);
}

async function postHasSeenOrientation(enrolled_id) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/has_seen_orientation`)
    .then((resp) => resp.data);
}

async function getEnrolledFinishSurvey(enrolled_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/survey`)
    .then((resp) => resp.data);
}

async function postErnolledFinishSurvey(enrolled_id, data) {
  return await privateInstance
    .post(`/enrolleds/${enrolled_id}/survey`, data)
    .then((resp) => resp.data);
}

export const enrolledService = {
  postHasSeenOrientation,
  getRoundWeeks,
  setEnrolled,
  getEnrolleds,
  getActiveEnrolleds,
  getStudyplans,
  setStudyplans,
  setExpired,
  getExpired,
  getPlaytime,
  getEnrolledReimbursed,
  getProgress,
  getPeriod,
  getRank,
  getMaker,
  getEnrolledDetail,
  getEnrolledDetails,
  doEnrolledDetail,
  submitDetailReview,
  getEnrolledBadges,
  getDetailObstacle,
  getDetailPresent,
  postDetailPresent,
  getSalecall,
  postSalescall,
  getEnrolledFinishSurvey,
  postErnolledFinishSurvey,
  getEnrolledDoneCount,
};
