import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export const HrdFrameLoader = ({ isDebug, agtId, usrId, sessionId }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    const scriptSrc =
      "https://fds.hrdkorea.or.kr/fdsService/hrdAPI/hrdFrameLoader.js";
    let script = document.querySelector(`script[src="${scriptSrc}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = scriptSrc;
      script.onload = () => {
        setScriptLoaded(true);
        if (window.hrdFrame) {
          window.hrdFrame.loadHrdFrame(isDebug);
          window.hrdFrame.loadFDS(agtId, usrId, sessionId);
        }
      };
      script.onerror = (error) => {
        Sentry.captureException(error);
      };
      document.body.appendChild(script);
    } else {
      if (scriptLoaded) {
        window.hrdFrame.loadHrdFrame(isDebug);
        window.hrdFrame.loadFDS(agtId, usrId, sessionId);
      }
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [isDebug, agtId, usrId, sessionId, scriptLoaded]);

  return null;
};
