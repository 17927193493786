import { config } from "../../../../config";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { sendCustomDataLog } from "../../../businesslogics/logging";
import {
  changQuestions,
  hrQuestions,
  nbFreeQuestions,
  normalQuestions,
  youthQuestions,
} from "../../../pages/NPSPage/prequestionOptions";
import { useQuery } from "react-query";
import { authHeader, handleResponse, privateFetch } from "../../../../_helpers";

export const useArrow = (setShowArrow) => {
  const scrollHandler = () => {
    if (window.scrollY > window.innerHeight / 3) {
      setShowArrow(false);
    }
  };

  window.addEventListener("scroll", scrollHandler);

  return () => {
    window.removeEventListener("scroll", scrollHandler);
  };
};

export const useShareUrl = (
  enrolled,
  isRepurchase,
  user,
  enrolledPlaytime,
  nextStep
) => {
  const ORIGIN = new URL(`${config.appUrl}/share`);
  const [shareUrl, setShareUrl] = useState(ORIGIN);
  useEffect(() => {
    if (!enrolledPlaytime?.loaded || !enrolled.loaded || !user) return;
    setShareUrl((prev) => {
      let copy = new URL(prev);
      copy.searchParams.set("coursesId", enrolled.data?.courses._id);
      copy.searchParams.set("time", enrolledPlaytime?.data?.total_playtime);
      copy.searchParams.set("repurchase", isRepurchase);
      copy.searchParams.set("free", enrolled.data?.courses.is_trial);
      copy.searchParams.set("user", user.name);
      copy.searchParams.set("uid", user._id);
      nextStep
        ? copy.searchParams.set("nextStep", nextStep)
        : copy.searchParams.delete("nextStep");
      return copy;
    });
  }, [enrolled.loaded, enrolledPlaytime?.loaded, isRepurchase, user]);
  return shareUrl;
};

export const onClickSaveImage = (downloadRef, enrolled) => {
  const onSaveAs = (url, filename) => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };

  const width = downloadRef.current.clientWidth;
  const height = downloadRef.current.clientHeight;
  html2canvas(downloadRef.current, {
    useCORS: true,
    width,
    height,
    scale: 1,
  })
    .then((canvas) => {
      onSaveAs(canvas.toDataURL("image/png"), "image-download.png");
    })
    .catch((error) => {
      console.log("=>(index.jsx:79)", error);
    });
};

export const useGoalOptions = (
  courseTitle,
  goalFromPreq,
  setGoalFromPreq,
  setGoalOptions
) => {
  useEffect(() => {
    if (goalFromPreq) {
      setGoalFromPreq(goalFromPreq);
    }
  }, [goalFromPreq]);

  useEffect(() => {
    if (courseTitle) {
      if (courseTitle === "교육 담당자를 위한 DT교육 설계 로드맵") {
        setGoalOptions(hrQuestions);
      } else if (courseTitle === "중고등학생 코딩교육 준비 전략") {
        setGoalOptions(youthQuestions);
      } else if (courseTitle === "내일배움카드 발급부터 활용의 모든 것") {
        setGoalOptions(nbFreeQuestions);
      } else {
        setGoalOptions(normalQuestions);
      }
    }
  }, [courseTitle]);
};

export const useEnrolledDone = () => {
  return useQuery("enrolledDone", () => {
    const requestOptions = {
      method: "GET",
      headers: Object.assign(
        { "Content-Type": "application/json" },
        authHeader()
      ),
    };
    let endpoint = `${config.apiUrl}/v1/enrolleds/done`;
    return privateFetch(endpoint, requestOptions).then(handleResponse);
  });
};

export const logCtaClick = (buttonText) => {
  sendCustomDataLog("scc_postquestionPage_download_click", {
    button_text: buttonText,
  });
};
