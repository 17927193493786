import { EvaluationGnbMobile } from "./Mobile/EvaluationGnbMobile";
import { EvaluationGnbDesktop } from "./Desktop/EvaluationGnbDesktop";
import { useParams } from "react-router-dom";
import { totalQnALink } from "../../../../_state/constants";
import { useMyEvaluations } from "../../../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { useAtom } from "jotai";
import { enrolledEvaluationDetailsAtom } from "../../../../stores/enrolledEvaluationDetails";
import { useGetEvaluationPageType } from "../../../../hooks/evaluation/useEvaluationData";
import { useIsMobile } from "../../../../hooks/device";

export const EvaluationGnb = ({ isOpenList, setIsLoading }) => {
  const { enrolled_evaluation_id } = useParams();
  const isMobile = useIsMobile();
  const { data: evaluations } = useMyEvaluations(enrolled_evaluation_id);
  const pageType = useGetEvaluationPageType(evaluations);

  const week = evaluations?.week;

  return isMobile ? (
    <EvaluationGnbMobile
      qnaLink={totalQnALink}
      week={week}
      pageType={pageType}
      setIsLoading={setIsLoading}
    />
  ) : (
    <EvaluationGnbDesktop
      isOpenList={isOpenList}
      week={week}
      pageType={pageType}
      setIsLoading={setIsLoading}
    />
  );
};
