import React, { useEffect } from "react";
import * as S from "./MyVoucher.style";
import { ClassCards } from "../../ClassCards/ClassCards";
import { useSelector } from "react-redux";
import { ClassCardMembership } from "../../ClassCards/ClassCard/ClassCardMembership";

export const MyVoucher = () => {
  const vouchers = useSelector((state) => state.vouchers);

  useEffect(() => {
    if (!vouchers.data?.context) window.location.reload();
  }, [vouchers]);

  return (
    <S.MyVoucherWrapper>
      {vouchers.data?.context.membership_data.is_membership_on && (
        <ClassCardMembership
          membership_start_from={
            vouchers.data?.context.membership_data.membership_start_from
          }
          membership_end_at={
            vouchers.data?.context.membership_data.membership_end_at
          }
          current_type={"voucher"}
        />
      )}
      <ClassCards
        courses={vouchers.data?.context.vouchers}
        current_type={"voucher"}
      />
    </S.MyVoucherWrapper>
  );
};
