import styled from "@emotion/styled";
import {
  additional,
  hanghae,
  neutralDay,
  scc,
  wBody2,
} from "@teamsparta/design-system";

const getBorderColor = (status) => {
  switch (status) {
    case "correct":
      return additional.green100;
    case "incorrect":
      return hanghae.red100;
    default:
      return "#ccc";
  }
};

const getBackgroundColor = (status) => {
  switch (status) {
    case "correct":
      return additional.green10;
    case "incorrect":
      return scc.red15;
    default:
      return neutralDay.white;
  }
};

export const SelectBox = styled.label`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${(props) => getBorderColor(props.status)};
  background: ${(props) => getBackgroundColor(props.status)};
  box-shadow: 0 8px 24px 0 rgba(129, 137, 143, 0.12);
  ${wBody2};
  color: ${neutralDay.gray90};
`;

export const RadioStyle = styled.input`
  &[type="radio"] {
    accent-color: ${(props) =>
      props.isCorrect ? `${scc.green100}` : `${hanghae.red100}`};
  }
`;
