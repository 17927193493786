import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions, liveUserActions } from "../../../_state/actions";
import { BroadcastList } from "../BroadcastList/BroadcastList";
import classNames from "classnames/bind";
import styles from "./BroadcastContainer.module.scss";
import CountUp from "react-countup";
import { sendLog } from "../../../businesslogics/logging";
import { useOnlineTheme } from "../../../queries/pirates";

const cx = classNames.bind(styles);

function BroadcastContainer({
  user,
  liveUser,
  getLiveUser,
  enrolled,
  enrolledDetail,
}) {
  const [isBroadListOpen, setIsBroadListOpen] = useState(false);
  const theme = useOnlineTheme();

  useEffect(() => {
    if (user) {
      getLiveUser();
    }
  }, [user]);

  return (
    <div className={cx("broadcast-container")}>
      <div className={cx("broadcast-container__header")}>
        <div className={cx("broadcast-container__header__img-div")}>
          <img
            className={cx("broadcast-container__header__img-div__img")}
            src={`/assets/images/profile/rtan_thumb_11.png`}
            alt=""
          />
          <img
            className={cx("broadcast-container__header__img-div__img")}
            src={`/assets/images/profile/rtan_thumb_22.png`}
            alt=""
          />
          <img
            className={cx("broadcast-container__header__img-div__img")}
            src={`/assets/images/profile/rtan_thumb_01.png`}
            alt=""
          />
        </div>
        <div
          className={cx("broadcast-container__header__content", theme.data)}
          onClick={() => {
            enrolled.loaded &&
              enrolledDetail.loaded &&
              sendLog(
                `scc_lecturePage_click_button`,
                {
                  ...enrolled.data,
                  ...enrolledDetail.data,
                  button_text: "Live",
                  button_name: "Live",
                },
                ""
              );
          }}
        >
          <div className={cx("broadcast-container__header__content__text")}>
            Live
            <div
              className={cx(
                "broadcast-container__header__content__text__arrow"
              )}
            ></div>
          </div>
          <div className={cx("broadcast-container__header__content__number")}>
            <span
              className={cx(
                "broadcast-container__header__content__number__span"
              )}
            >
              {liveUser.loaded ? (
                <CountUp
                  start={0}
                  end={liveUser.data.count}
                  separator={","}
                  duration={2.5}
                />
              ) : (
                0
              )}
            </span>
            <div
              className={cx(
                "broadcast-container__header__content__number__bg",
                `${isBroadListOpen ? "--rotate" : ""}`
              )}
            ></div>
          </div>
        </div>
      </div>
      <div className={cx("broadcast-container__content")}>
        <BroadcastList
          isBroadListOpen={isBroadListOpen}
          setIsBroadListOpen={setIsBroadListOpen}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, liveUser, enrolled, enrolledDetail } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    liveUser,
    enrolled,
    enrolledDetail,
  };
}

const connectedBroadcastContainer = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getExpired: enrolledActions.getExpired,
  getLiveUser: liveUserActions.getLiveUser,
})(BroadcastContainer);
export { connectedBroadcastContainer as BroadcastContainer };
