import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiDashboardInfoUrl = (round_id) => {
  return `${baseUrl}/rounds/${round_id}/dashboard`;
};

export const getApiRescueUrl = (round_id) => {
  return `${baseUrl}/rounds/${round_id}/rescue`;
};
