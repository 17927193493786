import styled from "styled-components";

export const LectureWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VideoLoadingWrapper = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.show ? `38.5%` : "55.5%")};
  background-color: #3a3e41;
  position: relative;
`;

export const LectureLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.show ? `75%` : "100%")};
  transition: all 0.2s ease-in-out;
  position: relative;
`;
