import * as S from "./CurrentlyEnrolled.style";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Antenna,
  ArrowDwon,
  ArrowUp,
  CenterPoint,
  Trophy,
} from "./CurrentlyEnrolled.svg";
import { useIsMobile } from "../../../hooks/device";
import { useSelector } from "react-redux";
import { generateInClassData } from "../../../businesslogics/Roadmap/currntlyEnrolled";
import { sendLog } from "../../../businesslogics/logging";
import { LikeButton } from "./LikeButton/LikeButton";
import { useGetRoundWeeks } from "../../../queries/roadmap";
import { useSyllabusUnit } from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { maskName } from "../../../_helper/utils";

export const CurrentlyEnrolled = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const isMobile = useIsMobile();
  const [name, setName] = useState("");
  const [week, setWeek] = useState("");
  const [lecture, setLecture] = useState(0);
  const { user } = useSelector((state) => state.authentication);
  const enrolled = useSelector((state) => state.enrolled);
  const round = useSelector((state) => state.round);
  const hasFunctionExecuted = useRef(false);
  const { data: roundWeeks } = useGetRoundWeeks(enrolled.data?.enrolled_id);

  useEffect(() => {
    if (!isMobile) setIsDropdownOpen(true);
  }, [isMobile]);

  useEffect(() => {
    if (roundWeeks?.ok) {
      setName(user.name);
      setWeek(roundWeeks.weeks[0].week);
      roundWeeks.weeks[0]?.enrolled_details.map((item, idx) => {
        if (!item.done && item.seen) {
          setLecture(idx + 1);
        }
        if (
          item.done &&
          item.seen &&
          idx === roundWeeks.weeks[0]?.enrolled_details.length - 1
        ) {
          setLecture(idx + 1);
        }
      });
    }
  }, [roundWeeks?.ok, user?.name]);

  const syllabusUnit = useSyllabusUnit(enrolled.data?.course_id);
  const { inClassList, maxLctureCount } = useMemo(
    () =>
      generateInClassData(roundWeeks?.weeks[0]?.enrolled_details.length, {
        name,
        week,
        lecture,
        isMe: true,
      }),
    [name, week, lecture, roundWeeks?.ok]
  );

  const onClickLike = () => {
    setIsLikeClicked(true);
    sendLog("scc_roadmapPage_click_banner", {
      course_title: enrolled?.data.courses.title,
      course_id: enrolled?.data.course_id,
      round_id: round?.data._id,
      round: round?.data.round,
      button_name: "좋아요 버튼",
    });
  };

  return (
    <S.Container>
      <S.TitleWrapper
        onClick={() => {
          if (isMobile) {
            setIsDropdownOpen(!isDropdownOpen);
          }
        }}
      >
        <S.TitleContainer>
          <S.AntennaIconWrapper>
            <Antenna />
          </S.AntennaIconWrapper>
          <S.TitleTextContainer>
            <S.TitleText>
              지금 <S.TitleTextRed>{inClassList.length}명</S.TitleTextRed> 수강
              중!
            </S.TitleText>
            {isMobile && (
              <S.TitleCaption>
                누가 가장 진도가 빠른지 확인해보세요
              </S.TitleCaption>
            )}
          </S.TitleTextContainer>
        </S.TitleContainer>
        {isMobile && (
          <S.DropArrowIconWrapper>
            {isDropdownOpen ? <ArrowUp /> : <ArrowDwon />}
          </S.DropArrowIconWrapper>
        )}
      </S.TitleWrapper>
      {isDropdownOpen && (
        <S.ContentContainer>
          <S.ContentHeader>
            <S.HeaderTitleContainer>
              <S.TrophyIconWrapper>
                <Trophy />
              </S.TrophyIconWrapper>
              <S.HeaderTitle>
                <S.HeaderTitleCaption>
                  가장 진도가 빨라요 ({maxLctureCount}명)
                </S.HeaderTitleCaption>
                <S.HeaderTitleText>
                  {maskName(inClassList[0].name)}
                  <S.HeaderTitleGray>
                    <CenterPoint /> {inClassList[0].week}
                    {syllabusUnit} {inClassList[0].lecture}강
                  </S.HeaderTitleGray>
                </S.HeaderTitleText>
              </S.HeaderTitle>
            </S.HeaderTitleContainer>
            <LikeButton
              onMouseEnter={() => {
                setIsHover(true);
              }}
              onMouseLeave={() => setIsHover(false)}
              onClick={() => {
                onClickLike();
              }}
              isLikeClicked={isLikeClicked}
              isHover={isHover}
            />
          </S.ContentHeader>

          <S.ContentBody>
            <S.ScrollContent
              onScroll={() => {
                if (!hasFunctionExecuted.current) {
                  sendLog("scc_roadmapPage_scroll_banner", {
                    course_title: enrolled?.data.courses.title,
                    course_id: enrolled?.data.course_id,
                    round_id: round?.data._id,
                    round: round?.data.round,
                  });
                  hasFunctionExecuted.current = true;
                }
              }}
            >
              {inClassList.map((item, idx) => {
                return (
                  <>
                    <S.UserItem key={idx}>
                      <S.UserItemTextContainer>
                        <S.UserProfile
                          src={
                            "/assets/icons/rtan/rtan_thumb_" +
                            ((idx + 1) % 30) +
                            ".png"
                          }
                        />
                        <S.UserItemText>{maskName(item.name)}</S.UserItemText>
                        <S.UserItemTextGray>
                          <CenterPoint /> {item.week}주차 {item.lecture}강
                        </S.UserItemTextGray>
                      </S.UserItemTextContainer>
                      {item?.isMe && <S.UserItemMe>나</S.UserItemMe>}
                    </S.UserItem>
                    {inClassList.length - 1 !== idx && <S.Divider />}
                  </>
                );
              })}
            </S.ScrollContent>
          </S.ContentBody>
        </S.ContentContainer>
      )}
    </S.Container>
  );
};
