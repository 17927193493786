import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";
import { neutralDay, scc } from "@teamsparta/design-system";

export const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: ${(props) => (props.isClickable ? "pointer" : "not-allowed")};
  background-color: ${scc.red100};
  opacity: ${(props) => (props.isClickable ? "1" : "0.3")};
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const ButtonTitle = styled.div`
  font-family: Pretendard;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;

  color: #ffffff;
`;

export const ButtonIcon = styled.div`
  width: 16px;
  height: 16px;
  line-height: 16px;
`;

export const MobileWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

export const MobileCta = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 8px;
  background: ${scc.red100};
  box-shadow: 0 20px 24px 0 rgba(20, 22, 23, 0.24);
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "none";
      }
    }};
  height: 54px;
  justify-content: center;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.3")};
  align-items: center;
  min-width: 120px;
  cursor: ${({ isActive }) => (isActive ? "pointer" : "not-allowed")};
`;

export const MobileCtaNoShadow = styled(MobileCta)`
  box-shadow: none;
`;

export const MobileArrow = styled.div`
  width: 20px;
  height: 20px;
`;

export const MobileCtaTitle = styled.div`
  font-family: Pretendard;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#ffffff";
    }
  }};
  line-height: 150%;
`;

export const LoadingSpinner = styled.img`
  @keyframes Rotate {
    100% {
      transform: rotate(-360deg);
    }
  }
  width: 24px;
  height: 24px;
  margin: auto;
  animation: Rotate 2s linear infinite;
  transform-origin: 50% 50%;
`;
