import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import useThrottle from "./useThrottle";

function msToHumanReadableTime(duration) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor(duration / (1000 * 60 * 60));

  const format = (num) => (num < 10 ? `0${num}` : num);

  if (hours > 0) {
    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
  }
  return `${format(minutes)}:${format(seconds)}`;
}

export const useVisibilityLog = (sendLogOn, sendLogOff, startTime) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [visibleState, setVisibleState] = useState("initial");
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const [throttledSendLogsOn, cancelThrottle] = useThrottle(sendLogOn, 1000);
  const [throttledSendLogsOff, cancelThrottleOff] = useThrottle(
    sendLogOff,
    1000
  );
  useEffect(() => {
    throttledSendLogsOn();
    const handleVisibilityChange = () => {
      setVisibleState(document.visibilityState);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (enrolledDetail.loaded) {
      setVisibleState("visible");
      setIsInitialized(true);
    }
  }, [enrolledDetail.loaded]);
  useEffect(() => {
    if (visibleState === "visible") {
      const currentTime = new Date().toISOString();
      startTime.current = currentTime;
    } else if (visibleState === "hidden" && isInitialized) {
      throttledSendLogsOff();
      startTime.current = null;
    }
  }, [visibleState, enrolledDetail.loaded, isInitialized]);
};

export const getDuration = (startTimeRef) => {
  const endTime = new Date().toISOString();
  let duration;
  if (startTimeRef && startTimeRef.current) {
    duration = new Date(endTime) - new Date(startTimeRef?.current);
  } else {
    duration = 0;
  }
  return duration;
};

export const useLectureStartAndEndLog = () => {
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const enrolled = useSelector((state) => state.enrolled);
  const startTime = useRef(null);
  useLectureEndLogOnUnmount(startTime);
  useVisibilityLog(
    () => {
      sendCPLog("scc_lecture_start", {
        enrolled_id: enrolledDetail.data?.enrolled_id,
        course_id: enrolledDetail.data?.course_id,
        course_title: enrolled.data?.courses.title,
        round: enrolled.data?.rounds.round,
        round_id: enrolled.data?.round_id,
        start_time: startTime.current,
        tags: enrolled.data?.courses.tags,
        week: enrolledDetail.data?.week,
        week_order: enrolledDetail.data?.week_order,
        week_total: enrolled.data?.courses?.days / 7,
        order: enrolledDetail.data?.order,
        playtime: enrolledDetail.data?.playtime,
        tutor: enrolled.data?.tutors.name,
        page_url: window.location.href,
      });
    },
    () => {
      const endTime = new Date().toISOString();
      const duration = getDuration(startTime);
      sendCPLog("scc_lecture_end", {
        enrolled_id: enrolledDetail.data?.enrolled_id,
        course_id: enrolledDetail.data?.course_id,
        course_title: enrolled.data?.courses.title,
        round_id: enrolled.data?.round_id,
        round: enrolled.data?.rounds.round,
        end_time: endTime,
        lecture_time: duration / 1000,
        tags: enrolled.data?.courses.tags,
        tutor: enrolled.data?.tutors.name,
        end_type: "다른 탭 이동",
        week_total: enrolled.data?.courses?.days / 7,
        week: enrolledDetail.data?.week,
        week_order: enrolledDetail.data?.week_order,
        order: enrolledDetail.data?.order,
        playtime: enrolledDetail.data?.playtime,
        page_url: window.location.href,
      });
    },
    startTime
  );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const endTime = new Date().toISOString();
      const duration = getDuration(startTime);
      sendCPLog("scc_lecture_end", {
        enrolled_id: enrolledDetail.data?.enrolled_id,
        course_id: enrolledDetail.data?.course_id,
        course_title: enrolled.data?.courses.title,
        round_id: enrolled.data?.round_id,
        round: enrolled.data?.rounds.round,
        end_time: endTime,
        lecture_time: duration / 1000,
        tags: enrolled.data?.courses.tags,
        tutor: enrolled.data?.tutors.name,
        end_type: "탭 닫기",
        week_total: enrolled.data.courses?.days / 7,
        week: enrolledDetail.data?.week,
        week_order: enrolledDetail.data?.week_order,
        order: enrolledDetail.data?.order,
        playtime: enrolledDetail.data?.playtime,
        page_url: window.location.href,
      });
    };
    if (enrolledDetail.loaded) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enrolledDetail.loaded]);
};

export const useLectureEndLogOnUnmount = (startTimeRef) => {
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const enrolled = useSelector((state) => state.enrolled);
  const sendEndLog = () => {
    const duration = getDuration(startTimeRef);
    sendCPLog("scc_lecture_end", {
      enrolled_id: enrolledDetail.data?.enrolled_id,
      course_id: enrolledDetail.data?.course_id,
      course_title: enrolled.data?.courses.title,
      round_id: enrolled.data?.round_id,
      round: enrolled.data?.rounds.round,
      end_time: new Date().toISOString(),
      lecture_time: duration / 1000,
      tags: enrolled.data?.courses.tags,
      tutor: enrolled.data?.tutors.name,
      end_type: "뒤로가기",
      week_total: enrolled.data?.courses?.days / 7,
      week: enrolledDetail.data?.week,
      week_order: enrolledDetail.data?.week_order,
      order: enrolledDetail.data?.order,
      playtime: enrolledDetail.data?.playtime,
      page_url: window.location.href,
    });
  };
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "POP" && enrolledDetail.loaded) {
        // 브라우저의 뒤로 가기 버튼이 눌렸을 때 원하는 로직을 수행합니다.
        sendEndLog();
      }
    });

    return () => {
      unlisten();
    };
  }, [history, enrolledDetail.loaded]);
};

export const useLogGoBackClick = () => {
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const enrolled = useSelector((state) => state.enrolled);
  const sendEndLog = () => {
    sendCPLog("scc_lecture_end", {
      enrolled_id: enrolledDetail.data?.enrolled_id,
      course_id: enrolledDetail.data?.course_id,
      course_title: enrolled.data?.courses.title,
      round_id: enrolled.data?.round_id,
      round: enrolled.data?.rounds.round,
      end_time: new Date(),
      lecture_time: 0,
      tags: enrolled.data?.courses.tags,
      tutor: enrolled.data?.tutors.name,
      end_type: "뒤로가기",
      week_total: enrolled.data?.courses?.days / 7,
      week: enrolledDetail.data?.week,
      week_order: enrolledDetail.data?.week_order,
      order: enrolledDetail.data?.order,
      playtime: enrolledDetail.data?.playtime,
      page_url: window.location.href,
    });
  };

  return () => {
    sendEndLog();
  };
};
