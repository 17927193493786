import { courseConstants } from "../_constants";
import { courseService } from "../_services";

function getCourseList() {
  function request() {
    return { type: courseConstants.COURSE_LIST_REQUEST };
  }

  function success(response) {
    return { type: courseConstants.COURSE_LIST_SUCCESS, response };
  }

  function failure(error) {
    return { type: courseConstants.COURSE_LIST_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    courseService.getCourseList().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getCourse(course_id) {
  function request() {
    return { type: courseConstants.COURSE_REQUEST };
  }

  function success(response) {
    return { type: courseConstants.COURSE_SUCCESS, response };
  }

  function failure(error) {
    return { type: courseConstants.COURSE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    courseService.getCourse(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetCourse = (course_id) => {
  return getCourse(course_id);
};

function getGovCourses() {
  function request() {
    return { type: courseConstants.GOV_COURSE_REQUEST };
  }

  function success(response) {
    return { type: courseConstants.GOV_COURSE_SUCCESS, response };
  }

  function failure(error) {
    return { type: courseConstants.GOV_COURSE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    courseService.getGovCourses().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getCourseByUserId(course_id) {
  function request() {
    return { type: courseConstants.COURSE_BY_USERID_REQUEST };
  }

  function success(response) {
    return { type: courseConstants.COURSE_BY_USERID_SUCCESS, response };
  }

  function failure(error) {
    return { type: courseConstants.COURSE_BY_USERID_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    courseService.getCourseByUserId(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const courseActions = {
  getCourseList,
  getCourse,
  getCourseByUserId,
  getGovCourses,
};
