import * as S from "./InfoInput.style";

export const InfoInput = ({
  label,
  value,
  onChange,
  placeholder = "",
  type = "text",
}) => {
  return (
    <S.InfoInputContainer>
      <S.InfoLabel>{label}</S.InfoLabel>
      <S.InfoInputWrapper>
        <S.InfoInput
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </S.InfoInputWrapper>
    </S.InfoInputContainer>
  );
};

export const InfoInputDisabled = ({ label, value, onClickChangeButton }) => {
  return (
    <S.InfoInputContainer>
      <S.InfoLabel>{label}</S.InfoLabel>
      <S.InfoInputWrapper>
        <S.InfoInput type="text" value={value} disabled />
        <S.InfoChangeButton onClick={onClickChangeButton}>
          <span>변경</span>
        </S.InfoChangeButton>
      </S.InfoInputWrapper>
    </S.InfoInputContainer>
  );
};

export const InfoChooseButtons = ({ label, options, value, setValue }) => {
  return (
    <S.InfoInputContainer>
      <S.InfoLabel>{label}</S.InfoLabel>
      <S.InfoInputWrapper>
        <S.InfoButtonsContainer>
          {options.map((option) => (
            <S.InfoOptionButton
              key={option.key}
              isActive={value === option.key}
              onClick={() => setValue(option.key)}
            >
              {option.label}
            </S.InfoOptionButton>
          ))}
        </S.InfoButtonsContainer>
      </S.InfoInputWrapper>
    </S.InfoInputContainer>
  );
};
