import classNames from "classnames/bind";
import styles from "./Prehomework.module.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import {
  getApiPreHomeworkInfoeUrl,
  getGETAuthOptions,
  getPostAuthOptions,
} from "../../_urls";
import { createEventId, privateFetch } from "../../_helpers";

const cx = classNames.bind(styles);

class PrehomeworkPage extends Component {
  state = {
    // 읽기
    title: "",
    url: "",
    email1: "",
    email2: "",
    questions: [],
    course_id: this.props.match.params.course_id,
    course: {},
    isLoading: true,

    // 쓰기
    isAnswered: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { checkPlatform } = window;
    const platform = checkPlatform();
    if (platform === "mobile") {
      alert("사전과제 제출은 PC에서만 가능합니다.");
      window.location.href = "https://spartacodingclub.kr";
      return;
    }
    this._setQuestions();
  }

  _setQuestions = () => {
    const rjson = this._getPreHomeworkInfo();
    if (rjson === undefined) {
      return;
    }
    rjson.then((result) => {
      if (result.ok) {
        const { course, title, questions } = result;
        let isAnswered = [];
        for (let i = 0; i < questions.length; i++) {
          isAnswered.push(false);
        }
        this.setState({
          course,
          title,
          questions,
          isAnswered,
          isLoading: false,
        });
      }
    });
  };

  _getPreHomeworkInfo = async () => {
    const { course_id } = this.state;
    const endpoint = getApiPreHomeworkInfoeUrl(course_id);
    const options = getGETAuthOptions();
    const response = await privateFetch(endpoint, options);
    if (response.ok) {
      return response.json();
    } else {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        alert(data.message);
        window.location.href = "/classroom";
      });
    }
  };

  _handleClick = (idx) => {
    let { isAnswered } = this.state;
    isAnswered[idx] = true;
    this.setState({
      isAnswered,
    });
  };

  _submitAnswer = () => {
    const { email1, email2 } = this.state;
    if (this._isAllAnswered() === false) {
      alert("모든 문항에 답변해주세요!");
      return;
    }
    if (email1 !== email2) {
      alert("이메일이 일치하지 않습니다.");
      return;
    }

    if (!this.validateEmail(email1)) {
      alert("올바른 이메일을 입력해주세요.");
      return;
    }

    const rjson = this._setPreHomeworkInfo();
    rjson.then(async (result) => {
      if (!result) {
        return;
      }
      if (result.ok) {
        const eventID = createEventId();
        // await sendLog('prehwk_done', {course_id: this.state.course_id}, eventID);
        alert("제출이 완료되었습니다.\n개강일부터 힘차게 달려볼까요? 😎");
        window.location.href = "/classroom";
      }
    });
  };

  _isAllAnswered = () => {
    const { isAnswered, email1, email2 } = this.state;
    if (email1 === "" || email2 === "") return false;
    return isAnswered.reduce((acc, cur, i) => {
      return acc && cur;
    });
  };

  _setPreHomeworkInfo = async () => {
    const { course_id, url, email1 } = this.state;
    let data = { answered: true, email: email1 };
    if (url.length > 0) {
      if (url.includes("http://") || url.includes("https://")) {
        data["url"] = url;
      } else {
        alert("올바른 주소를 입력해주세요.");
        return;
      }
    }
    const endpoint = getApiPreHomeworkInfoeUrl(course_id);
    const options = getPostAuthOptions(data);
    try {
      const response = await privateFetch(endpoint, options);
      return response.json();
    } catch (e) {
      alert("사전과제 제출에 실패하였습니다.\n잠시 후 다시 시도해주세요.");
    }
  };

  handleUrl = (e) => {
    e.target.value = e.target.value.trim();
    this.setState({
      url: e.target.value,
    });
  };

  handleEmailChange = (e) => {
    e.target.value = e.target.value.trim();
    this.setState({
      email1: e.target.value,
    });
  };

  handleEmailChange2 = (e) => {
    e.target.value = e.target.value.trim();
    this.setState({
      email2: e.target.value,
    });
    const { email1, email2 } = this.state;
    if (email1 !== email2) {
      document.getElementById("email2").focus();
    }
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    const { course, title, questions, isLoading } = this.state;
    if (isLoading) {
      return <Skeleton />;
    }
    const prehwk = title.includes("크리스마스") ? "사전설치" : "사전과제";
    return (
      <div className={cx("pageContainer")}>
        <div className={cx("pageContentContainer")}>
          {/*사전과제 전체 제목*/}
          <div className={cx("pageTitleContainer")}>
            <h1>{title}</h1>
            <img src={"/assets/images/prehwk/img_taken.png"} alt="" />
            <div className={cx("pageTitleDesc")}>
              {prehwk}를 모두 완료하셨나요? 만약 아직 안하셨다면, 30분만 더
              시간내서 완성해주세요!{" "}
              <span className={cx("underline")}>
                요거 안하시면 연락갑니다~!
              </span>
            </div>
          </div>
          {/*사전과제 질문들*/}
          <div className={cx("pageQuestionContainer")}>
            {questions.map((q, idx) => {
              return (
                // 개별 문항
                <div key={idx} className={cx("questionContainer")}>
                  {/*문항별 제목 및 설명*/}
                  <div className={cx("questionTitleWrapper")}>
                    <h4>Q.&nbsp; {q.question}</h4>
                    <p>{q.desc}</p>
                  </div>
                  {/*답변*/}
                  <div className={cx("questionAnswerWrapper")}>
                    <input
                      type="radio"
                      id={`yes${idx}`}
                      value={`yes${idx}`}
                      onClick={() => {
                        this._handleClick(idx);
                      }}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={`yes${idx}`}>네</label>
                  </div>
                </div>
              );
            })}
            {/*<div className={cx('questionContainer')}>*/}
            {/*    /!*문항별 제목 및 설명*!/*/}
            {/*    <div className={cx('questionTitleWrapper')}>*/}
            {/*        <h4>[선택] 자주 방문하시는 커뮤니티에도 추석특집 무료강의를 소개해 주세요!</h4>*/}
            {/*        <p>정성스럽게 올려주신 모든 분께 포인트 2만원을 드립니다. (10/5 지급 예정)</p>*/}
            {/*    </div>*/}
            {/*    /!*답변*!/*/}
            {/*    <div className={cx('questionAnswerWrapper')}>*/}
            {/*        <input type="text" value={this.state.url} onChange={this.handleUrl} placeholder="커뮤니티에 올려주신 소개글 URL을 여기에 입력해주세요."/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={cx("questionContainer")}>
              <div className={cx("questionTitleWrapper")}>
                <h4>Q.&nbsp; Slack 가입에 사용한 이메일을 적어주세요!</h4>
                <p>수업 진행 채널에 초대해 드리겠습니다!</p>
              </div>
              <div className={cx("questionAnswerWrapper")}>
                <input
                  className={cx("email1")}
                  type="text"
                  onChange={this.handleEmailChange}
                  placeholder="ex) rtanny@teamsparta.co"
                  value={this.state.email1}
                />
              </div>
              <div className={cx("questionAnswerWrapper")}>
                <input
                  id="email2"
                  className={cx("email2")}
                  type="text"
                  onChange={this.handleEmailChange2}
                  placeholder="한 번 더!"
                  value={this.state.email2}
                />
              </div>
            </div>
          </div>
          {/*제출 버튼*/}
          <div className={cx("buttonContainer")}>
            <button onClick={this._submitAnswer}>
              제출하고 개강 준비 끝내기
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedPrehomeworkPage = connect(mapStateToProps)(PrehomeworkPage);
export { connectedPrehomeworkPage as PrehomeworkPage };
