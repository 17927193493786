import { externalService } from "../services";
import { B2B_APPLY } from "../constants";

function postB2bApply(data) {
  function request() {
    return { type: B2B_APPLY.B2B_APPLY_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    return externalService.postB2bApply(data);
  };
}
export const externalActions = {
  postB2bApply,
};
