import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const ModalWrapper = styled.div`
  z-index: 22;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.6;
`;

export const FirstPage = styled.div``;

export const ModalBady = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px 30px;
  z-index: 23;
  height: fit-content;
  width: 360px;
  background-color: white;

  position: relative;

  border-radius: 10px;
  overflow: scroll;
  ${Device.Desktop} {
    height: fit-content;
  }
`;

export const CloseImage = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  object-fit: contain;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #212529;
`;

export const ModalDesc = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 500;

  margin-bottom: 20px;
  color: #212529;

  ${Device.Desktop} {
  }
`;

export const ModalBenefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BenefitText = styled.div`
  font-family: Spoqa Han Sans Neo, "sans-serif";
  color: #212529;
  font-size: 14px;
`;

export const BenefitTextBold = styled.span`
  color: #e8344e;
  font-weight: 600;
  margin-left: 10px;
`;

export const NoticeContainer = styled.div`
  width: 100%;
  background: #f2f6f8;
  text-align: left;
  padding: 16px;
  margin: 20px 0;
  border-radius: 8px;
  box-sizing: border-box;

  font-size: 13px;
  font-weight: 500;
  color: #212529;
`;

export const NoticeContainerImportant = styled(NoticeContainer)`
  background: #fff6f8;
  color: #e8344e;
  text-align: center;
`;

export const NoticeText = styled.div`
  color: #212529;
  font-size: 14px;
  margin: 10px 0 20px;
  white-space: pre-line;
  text-align: center;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  gap: 26px;

  padding-top: 14px;
`;

export const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: 1px solid #e8344e;
  color: #e8344e;

  width: 125px;
  padding: 10px 0;

  cursor: pointer;
  border-radius: 24px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
`;

export const ModalButtonCancel = styled(ModalButton)`
  background-color: #e8344e;
  color: #fff;
`;

export const SecondPage = styled.div``;

export const SecondPageTitle = styled.div`
  font-family: Spoqa Han Sans Neo, "sans-serif";
  font-size: 16px;
  font-weight: 500;

  text-align: center;
  color: #212529;
`;

export const ModalTextarea = styled.textarea`
  border-radius: 10px;
  color: #212529;
  font-family: Spoqa Han Sans Neo, "sans-serif";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 93px;
  letter-spacing: -0.42px;
  line-height: 1.5;
  margin: 10px 0;
  padding: 15px;
  resize: none;
  text-align: left;
  width: 300px;

  &::placeholder {
    color: #adb5bd;
    line-height: 1.5;
  }
`;
