import React, { useEffect, useRef } from "react";
import * as S from "./LecPlayerDesktopAndMobileV3.style";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useIsDesktop, useUser } from "../../../../LecturePageV3/useInitialize";
import { usePlayerCoreControl } from "../hooks/usePlayerCoreControl";
import { useRenderPlayerControlBar } from "../hooks/useRenderPlayerControlBar";
import { useIsControlBarActive, useIsPlaying } from "../stores/playerAtom";
import {
  makeFullLogData,
  useVideoMonitoring,
} from "../hooks/useVideoMonitoring";
import { useSelector } from "react-redux";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import "./videojs.css";

export const LecPlayerDesktopAndMobileV3 = ({
  playerType,
  lecture_v2,
  enrolledDetail,
  onTimeUpdate,
  onPlay,
}) => {
  const user = useUser();
  const enrolled = useSelector((state) => state.enrolled);
  const isDesktop = useIsDesktop();
  const videoRef = useRef(null);
  const [isPlaying] = useIsPlaying(false);
  const [isControlBarActive, setIsControlBarActive] =
    useIsControlBarActive(false);
  const { togglePlay, vttSource } = usePlayerCoreControl({
    videoRef,
    lecture_v2,
    playerType,
    onTimeUpdate,
    onPlay,
  });

  const handleTouchStart = () => {
    setIsControlBarActive(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsControlBarActive(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isControlBarActive]);

  useEffect(() => {
    videoRef?.current?.focus();
  }, [isPlaying]);

  useEffect(() => {
    const videoElement = videoRef.current;
    const timer = setTimeout(() => setIsControlBarActive(false), 3000);
    return () => {
      if (videoElement) {
        const player = videojs(videoElement);
        player.dispose();
        videoRef.current = null;
      }
      clearTimeout(timer);
    };
  }, []);

  const playerControlBar = useRenderPlayerControlBar(
    user,
    videoRef,
    playerType,
    lecture_v2,
    enrolledDetail,
    onTimeUpdate
  );

  useVideoMonitoring(videoRef, (videoData) => {
    const fullLogData = makeFullLogData(
      videoData,
      enrolledDetail,
      user?._id,
      playerType,
      enrolled
    );
    sendCPLog("scc_lecturePage_player_buffered", fullLogData);
  });

  useEffect(() => {
    const changeTimeHandler = (e) => {
      const { timeInSeconds } = e.detail;
      if (videoRef.current) {
        videoRef.current.currentTime = timeInSeconds;
      }
    };

    window.addEventListener("changeVideoTime", changeTimeHandler);

    return () => {
      window.removeEventListener("changeVideoTime", changeTimeHandler);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedMetadata = () => {
        const textTracks = videoElement.textTracks;
        for (let i = 0; i < textTracks.length; i++) {
          textTracks[i].mode = "showing";
        }
      };
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, [videoRef]);

  return (
    <>
      <S.TotalWrapper isDesktop={isDesktop} onTouchStart={handleTouchStart}>
        <S.PlayerWrapper>
          <video
            id="vjs-player"
            ref={videoRef}
            playsInline={true}
            className="video-js"
            autoFocus={true}
            onClick={togglePlay}
          >
            <track
              label="한국어(자막)"
              kind="captions"
              srcLang="ko"
              src={vttSource}
              default={localStorage.getItem("captionV3") === "true"}
            />
          </video>
        </S.PlayerWrapper>
      </S.TotalWrapper>
      {playerControlBar}
    </>
  );
};
