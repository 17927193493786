export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  RESET_PASSWORD_REQUEST: "USERS_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USERS_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USERS_RESET_PASSWORD_FAILURE",
  SET_TOKENS: "USERS_SET_TOKENS",

  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  REFRESH_REQUEST: "USERS_REFRESH_REQUEST",
  REFRESH_SUCCESS: "USERS_REFRESH_SUCCESS",
  REFRESH_FAILURE: "USERS_REFRESH_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  INFO_REFRESH_REQUEST: "USERS_INFO_REFRESH_REQUEST",
  INFO_REFRESH_SUCCESS: "USERS_INFO_REFRESH_SUCCESS",
  INFO_REFRESH_FAILURE: "USERS_INFO_REFRESH_FAILURE",
};
