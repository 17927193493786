import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const getCurrentUrl = () =>
  window.location.pathname + window.location.search;

export const getNextUrl = () => {
  return encodeURIComponent(window.location.pathname + window.location.search);
};

export const includesLoginPage = (url) => url.includes("login");
