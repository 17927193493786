import axios from "axios";
import { useQuery } from "react-query";

export const getAllcategoriesV2 = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_SCC_API}/category`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const useAllCategoriesV2 = () => {
  return useQuery("categoryV2", getAllcategoriesV2);
};
