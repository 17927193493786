import styled from "@emotion/styled";
import {
  mBody3,
  mHeader1,
  neutralDay,
  wBody1,
  wBody2,
  wHeader2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CelebrateCardContainer = styled.div`
  display: flex;
  width: 772px;
  padding: 80px 0px 60px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const CelebrateText = styled.h1`
  ${mHeader1};
  color: ${neutralDay.white};
  white-space: break-spaces;
  text-align: center;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wHeader2};
    color: ${neutralDay.white};
  }
`;

export const CompleteLabel = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: ${neutralDay.gray90};
`;

export const CompleteLabelTitle = styled.p`
  display: flex;
  align-items: center;
  ${mBody3};
  margin: 0;
  color: ${neutralDay.gray0};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wBody2};
    color: ${neutralDay.gray0};
  }
  &::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 20px;
    vertical-align: middle;
    background: ${neutralDay.gray80};
    margin-left: 10px;
  }
`;

export const CompleteLabelContent = styled.p`
  display: flex;
  align-items: center;
  ${mBody3};
  margin: 0;
  color: ${neutralDay.gray0};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wBody1};
    color: ${neutralDay.gray0};
  }
`;
