import { config } from "../config";
import { authHeader, handleResponse, privateFetch } from "../_helpers";
import instance from "../_helpers/axios";

function getEnrolledDone() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/enrolleds/done`;
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

async function getEnrolledOngoing() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/enrolleds/ongoing`;
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

async function getEnrolledStatus(enrolled_id) {
  return await instance
    .get(`/enrolleds/done/${enrolled_id}`)
    .then((resp) => resp.data);
}

async function getVouchers() {
  return await instance
    .get(`/enrolleds/vouchers`)
    .then((resp) => resp.data)
    .catch((e) => console.log(e));
}

export const enrolledService = {
  getEnrolledDone: getEnrolledDone,
  getEnrolledStatus: getEnrolledStatus,
  getVouchers: getVouchers,
  getEnrolledOngoing: getEnrolledOngoing,
};
