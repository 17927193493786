import { LoginProcess, useLockScroll } from "@teamsparta/auth-frontend";
import { useEffect, useState } from "react";
import { useSetAmplitudeUserId } from "../../hooks/useSetAmplitudeUserId";
import { useLocation } from "react-router-dom";
import { consoleLogForDev } from "../../utils/consoleLog";
import * as CPL from "@teamsparta/cross-platform-logger";

const LoginPageV2 = ({ isSignupPage }) => {
  const [isShowLoginModal, setIsShowLoginModal] = useState(true);
  const nextPage = window.location.search.split("next=")[1]?.split("&")[0];
  const [isAborted, setIsAborted] = useState(false);
  const location = useLocation();

  useLockScroll(isShowLoginModal);
  useSetAmplitudeUserId(isShowLoginModal);

  useEffect(() => {
    if (isAborted) window.location.replace(process.env.REACT_APP_SCC_MAIN_URL);
  }, [isAborted]);

  consoleLogForDev("🪐🪐🪐 nextPage 🪐🪐🪐", nextPage);

  return (
    <>
      {location.pathname === "/signup_kko" ? (
        <LoginProcess
          isShow={isShowLoginModal}
          setIsShow={setIsShowLoginModal}
          CPLogger={CPL}
          nextPage={"/classroom"}
          designatedInitialTask={"signupKakao"}
          setIsAborted={setIsAborted}
        />
      ) : (
        <LoginProcess
          isShow={isShowLoginModal}
          setIsShow={setIsShowLoginModal}
          CPLogger={CPL}
          nextPage={nextPage}
          designatedInitialTask={isSignupPage ? "signupEmail" : "initial"}
          setIsAborted={setIsAborted}
        />
      )}
    </>
  );
};

export default LoginPageV2;
