import React, { Component } from "react";
import { getApiRcmdReadCodeUrl, getGETOptions } from "../../_urls";
import { Button } from "antd";
import classNames from "classnames/bind";
import styles from "./RcmdDashboardPage.module.scss";
import { connect } from "react-redux";
import { rcmdDashboardActions } from "../../_actions/rcmdDashboard.actions";
import { RcmdNewsFeed } from "../../_components/RcmdNewsFeed";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);

class RcmdDashboardPage extends Component {
  state = {
    isLoading: true,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._readRcmd();
    this.props.getRcmdDashboardInfo();
  }

  _readRcmd = () => {
    const { code } = this.props.match.params;
    let endpoint = getApiRcmdReadCodeUrl(code);
    let options = getGETOptions();
    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.message !== undefined) {
          alert(result.message);
          window.location.href = "/";
          return;
        }
        const { name, count, hook_message } = result;
        this.setState({
          hook_message,
          name,
          count,
          isLoading: false,
        });
      });
  };

  _copyHookMessage = async () => {
    const { hook_message } = this.state;
    try {
      await navigator.clipboard.writeText(hook_message);
    } catch (err) {
      console.log(err);
    }
    alert("복사가 완료되었습니다.");
  };

  render() {
    const { data } = this.props.rcmdDashboards;
    const { isLoading, name } = this.state;
    let { count } = this.state;
    count = count > 5 ? 5 : count;

    return (
      <div>
        {/*<NavContainer/>*/}
        {isLoading && <div />}
        {!isLoading && (
          <div className={cx("pageContainer")}>
            <div className={cx("pageTitleContainer")}>
              {count < 5 && (
                <div>
                  <span className={cx("maintitle")}>{name} 님,</span>
                  <span className={cx("maintitle")}>
                    <div className={cx("underline")}>현황</div>을 확인해보세요!
                  </span>
                </div>
              )}
              {count >= 5 && (
                <div>
                  <span className={cx("maintitle")}>
                    🎉{name} 님,
                    <br />
                    축하합니다!🎉
                  </span>
                </div>
              )}
            </div>
            <div
              className={cx("copyToClipboardContainer")}
              id="copyToClipboardContainer"
            >
              <p>
                1. 아래 버튼을 눌러 준비된 멘트를 복사해요.
                <br />
                2. 친구와 단톡방에 쓰윽 공유해요.
                <br />
                3. 나만의 할인코드로 5명이 결제하면 <b>
                  100% 에어팟 프로 GET!
                </b>{" "}
                😉
              </p>
              <div className={cx("buttonContainer")}>
                <Button type="primary" onClick={this._copyHookMessage}>
                  복사하기
                </Button>
              </div>
            </div>
            <div className={cx("numberContainer")}>
              {count > 0 && count < 5 && (
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  {name}님, <b>{count}명</b>을 달성하셨네요!
                  <br />
                  조금만 더 달려볼까요? 🔥
                </p>
              )}
            </div>
            <div
              className={cx("rtanImageContainer")}
              style={{
                "--bg-image": `url('${require(`../../assets/images/rcmd/rtan_${count}.png`)}')`,
              }}
            ></div>
            <div className={cx("eventDescContainer")}>
              <b>상품 지급일</b> 8월 10일
            </div>
            <div className={cx("rcmdDashboardContainer")}>
              {data.map((data, idx) => {
                return <RcmdNewsFeed key={idx} data={data} />;
              })}
            </div>
          </div>
        )}
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, rcmdDashboards } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    rcmdDashboards,
  };
}

const connectedRcmdDashboardPage = connect(mapStateToProps, {
  getRcmdDashboardInfo: rcmdDashboardActions.getRcmdDashboardInfo,
})(RcmdDashboardPage);
export { connectedRcmdDashboardPage as RcmdDashboardPage };
