import { PHONE_VERIFICATION } from "../constants";
import { phoneVerificationService } from "../services";

function getPhoneVerification() {
  function request() {
    return { type: PHONE_VERIFICATION.PHONE_VERIFICATION_REQUEST };
  }

  function success(response) {
    return { type: PHONE_VERIFICATION.PHONE_VERIFICATION_SUCCESS, response };
  }

  function failure(error) {
    return { type: PHONE_VERIFICATION.PHONE_VERIFICATION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    phoneVerificationService.getPhoneVerification().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const phoneVerificationActions = {
  getPhoneVerification,
};
