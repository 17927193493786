import React, { useState } from "react";
import styles from "../../../../Pages/LoginPage/GenderItem.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export const GenderItem = ({ gender, setState, nextUrl }) => {
  return (
    <div className={cx("genderInfo__container")}>
      <label className={cx("genderInfo__label")}>
        성별<span>(선택)</span>
      </label>
      <div className={cx("genderInfo__wrapper")}>
        <div
          onClick={() => {
            setState({ gender: gender === "male" ? "" : "male" });
          }}
          className={cx(
            "genderInfo__option",
            gender === "male" ? `--selected--${nextUrl}` : ""
          )}
        >
          남자
        </div>
        <div
          onClick={() => {
            setState({ gender: gender === "female" ? "" : "female" });
          }}
          className={cx(
            "genderInfo__option",
            gender === "female" ? `--selected--${nextUrl}` : ""
          )}
        >
          여자
        </div>
      </div>
    </div>
  );
};
