import * as S from "./VerificationModal.style";
import React from "react";
import { useAtom } from "jotai";
import { isNeedRestMOTP } from "../../../../stores/varificationReset";

export const VerificationContent = ({ fnPopup, setIsOTP }) => {
  const [isNeedReset] = useAtom(isNeedRestMOTP);

  return (
    <>
      <S.TitleWrapper>
        <S.Title>
          국비지원 교육 방침으로
          <br />
          1일 1회 본인 인증이 필수입니다.
        </S.Title>
        <S.SubTitle>
          {isNeedReset
            ? "mOTP 인증 5회 실패로 휴대폰 인증이 필요합니다."
            : "본인 인증 방법을 선택해 주세요."}
        </S.SubTitle>
      </S.TitleWrapper>
      <S.ButtonWrapper>
        {!isNeedReset && (
          <S.Button
            onClick={() => {
              setIsOTP(true);
            }}
          >
            mOTP 앱 인증
          </S.Button>
        )}
        <S.PhoneButton
          onClick={() => {
            fnPopup();
          }}
        >
          휴대폰 인증
        </S.PhoneButton>
      </S.ButtonWrapper>
      <S.NoticeOfPrivacyPolicy>
        <span>
          본인 인증 시&nbsp;
          <S.UnderLine>
            <a
              href={
                "https://teamsparta.notion.site/3-f2e359d2a710447db3036ff2a3156869?pvs=4"
              }
              target={"_blank"}
              rel="noreferrer"
            >{`개인정보처리방침 및 제3자 제공`}</a>
          </S.UnderLine>
          에&nbsp;
        </span>
        동의하는 것으로 간주됩니다.
      </S.NoticeOfPrivacyPolicy>
    </>
  );
};
