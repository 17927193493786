import * as S from "./FooterV2.style.js";
import { BusinessInfo } from "./BusinessInfo.jsx";
import React from "react";
import { FooterSNS } from "./FooterSNS.jsx";

export const PrivacyPolicy = ({
  footerSNSItems,
  footerInfoSecondLineItems,
  infoDropDownText,
  infoDropDownIconImg,
  businessInfoItems,
  copyRightText,
}) => {
  return (
    <>
      <S.Contacts>
        <S.ContactsLine>
          <S.ContactsLineItemContainer>
            {footerInfoSecondLineItems.map((item) => (
              <S.ContactsLineItem key={item.href}>
                {item.text === "개인정보처리방침" ? (
                  <S.FooterNavItemBold
                    target="_blank"
                    href={item.href}
                    onClick={() => {
                      // logLinkClick(item.text);
                    }}
                  >
                    {item.text}
                  </S.FooterNavItemBold>
                ) : (
                  <S.FooterNavItem
                    target="_blank"
                    href={item.href}
                    onClick={() => {
                      // logLinkClick(item.text);
                    }}
                  >
                    {item.text}
                  </S.FooterNavItem>
                )}
              </S.ContactsLineItem>
            ))}
          </S.ContactsLineItemContainer>
          <FooterSNS footerSNSItems={footerSNSItems} />
        </S.ContactsLine>
      </S.Contacts>
      <S.BottomSectionWrapper>
        <BusinessInfo
          infoDropDownText={infoDropDownText}
          infoDropDownIconImg={infoDropDownIconImg}
          businessInfoItems={businessInfoItems}
          footerSNSItems={footerSNSItems}
        />
        <S.CopyRight>{copyRightText}</S.CopyRight>
      </S.BottomSectionWrapper>
    </>
  );
};
