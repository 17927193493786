/* eslint-disable */

const port = window.location.port;
const config = {
  appName: "Sparta Online",
  apiUrl: process.env.REACT_APP_API_URL || process.env.REACT_APP_VERCEL_API_URL,
  enterpriseApiUrl:
    process.env.REACT_APP_ENTERPRISE_API_URL ||
    process.env.REACT_APP_VERCEL_ENTERPRISE_API_URL,
  apiV3Url: process.env.REACT_APP_ONLINE_V3_API_URL,
  aiApiUrl: process.env.REACT_APP_AI_API_URL ,
  swcUrl:
    "https://swcamp.spartacodingclub.kr" ||
    "https://testswcamp.spartacodingclub.kr",
  hanghaeUrl: "https://hanghae99.spartacodingclub.kr",
  ddingdongUrl: "https://ddingdong.spartacodingclub.kr",
  intellipickUrl: "https://intellipick.spartacodingclub.kr",
  paymentUrl:
    process.env.REACT_APP_PAYMENT_API_URL ||
    process.env.REACT_APP_VERCEL_PAYMENT_API_URL,
  imageUrl:
    process.env.REACT_APP_API_URL || process.env.REACT_APP_VERCEL_API_URL,
  debug: false,
  production: true,
  hackle: {
    auto_track_page_view: true,
    auto_open_dev_tool: process.env.REACT_APP_IS_DEV === "true",
    debug: true,
    key:
      process.env.REACT_APP_HACKLE_KEY ||
      process.env.REACT_APP_VERCEL_HACKLE_KEY,
  },
  flounder: {
    region:
      process.env.REACT_APP_VERCEL_FLOUNDER_REGION ||
      process.env.REACT_APP_FLOUNDER_REGION,
    cognitoKey:
      process.env.REACT_APP_VERCEL_FLOUNDER_KEY ||
      process.env.REACT_APP_FLOUNDER_KEY,
    flounderDebug: false,
  },
  mixpanel: {
    mixpanel_token:
      process.env.REACT_APP_VERCEL_MIXPANEL_KEY ||
      process.env.REACT_APP_MIXPANEL_KEY,
    mixpanel_debug: true,
  },
  amplitude: {
    amplitude_key: process.env.REACT_APP_AMPLITUDE_KEY,
  },
  appUrl: process.env.REACT_APP_URL || process.env.REACT_APP_VERCEL_URL,
};
export { config };
