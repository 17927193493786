export const SharedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 13L19.5 11.5C21.433 9.567 21.433 6.433 19.5 4.5V4.5C17.567 2.567 14.433 2.567 12.5 4.5L11 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 11L4.5 12.5C2.567 14.433 2.567 17.567 4.5 19.5V19.5C6.433 21.433 9.567 21.433 11.5 19.5L13 18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9 15L15 9"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const KakaoIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0.385284C6.01754 0.385284 0.357422 4.85994 0.357422 10.3799C0.357422 13.973 2.75587 17.1213 6.35485 18.8832C6.09024 19.8708 5.3973 22.4599 5.25845 23.014C5.08751 23.7017 5.51061 23.6925 5.78765 23.5072C6.0051 23.3624 9.25237 21.1552 10.6533 20.2023C11.4137 20.3149 12.1977 20.3745 13 20.3745C19.9825 20.3745 25.6426 15.8992 25.6426 10.3799C25.6426 4.86059 19.9818 0.385284 13 0.385284Z"
        fill="#391B1B"
      />
      <path
        d="M7.91216 7.43652H4.21494C3.86716 7.43652 3.58618 7.71815 3.58618 8.06528C3.58618 8.41241 3.86781 8.69404 4.21494 8.69404H5.41678V13.1228C5.41678 13.284 5.48228 13.4425 5.59755 13.5571C5.7102 13.671 5.86804 13.7365 6.02982 13.7365H6.16408C6.3252 13.7365 6.48239 13.671 6.5957 13.5571C6.71097 13.4425 6.77647 13.2846 6.77647 13.1228V8.69404H7.9115C8.25929 8.69404 8.54092 8.41241 8.54092 8.06528C8.54092 7.71815 8.25929 7.43652 7.9115 7.43652H7.91216Z"
        fill="#FAE300"
      />
      <path
        d="M16.7977 12.5537H15.0785V8.03061C15.0785 7.65335 14.7732 7.34814 14.3967 7.34814C14.0201 7.34814 13.7148 7.65335 13.7148 8.03061V12.9061C13.7148 12.9441 13.7194 12.9794 13.7253 13.0155C13.7188 13.0508 13.7148 13.0875 13.7148 13.1242C13.7148 13.4399 13.9696 13.6953 14.2853 13.6953H16.7977C17.1141 13.6953 17.3682 13.4399 17.3682 13.1242C17.3682 12.8085 17.1134 12.5537 16.7977 12.5537Z"
        fill="#FAE300"
      />
      <path
        d="M22.2917 12.7613L20.376 10.245L22.107 8.51331C22.3415 8.27949 22.3415 7.89896 22.107 7.66449C21.8725 7.43001 21.4927 7.43001 21.2575 7.66449L19.1145 9.8075V8.03061C19.1145 7.65335 18.8093 7.34814 18.4327 7.34814C18.0561 7.34814 17.7502 7.65335 17.7502 8.03061V13.0561C17.7502 13.4333 18.0555 13.7385 18.4327 13.7385C18.81 13.7385 19.1145 13.434 19.1145 13.0561V11.5058L19.5042 11.1168L21.3165 13.4949C21.5182 13.7641 21.9013 13.8198 22.1705 13.6161C22.4397 13.4137 22.4941 13.0312 22.2917 12.7613Z"
        fill="#FAE300"
      />
      <path
        d="M13.2031 12.9242L11.3607 7.8837C11.3607 7.8837 11.3561 7.87977 11.3548 7.8778C11.2369 7.57063 10.897 7.34729 10.4916 7.34729C10.0416 7.34729 9.66961 7.62237 9.5956 7.98194C9.58316 8.00879 9.57071 8.03565 9.56023 8.06447L7.74863 12.9242C7.63663 13.2432 7.8043 13.5916 8.12195 13.7036C8.44026 13.8156 8.78935 13.648 8.90135 13.3297L9.21507 12.435H11.7366L12.0497 13.3297C12.1617 13.648 12.5108 13.8156 12.8291 13.7036C13.1474 13.5916 13.3151 13.2432 13.2031 12.9242ZM9.61525 11.2921L10.4628 8.87399C10.4726 8.87399 10.4804 8.87661 10.4903 8.87661L11.3371 11.2921H9.6159H9.61525Z"
        fill="#FAE300"
      />
    </svg>
  );
};
