import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RoundWeekDetailLecture } from "../../RoadmapPage/RoundWeek/RoundWeekDetailLecture";
import { getChapterTitle } from "../../RoadmapPage/RoundWeek";
import { RoundWeekHomework } from "../../RoadmapPage/RoundWeek/RoundWeekHomework";
import { RoundWeekWIL } from "../../RoadmapPage/RoundWeek/RoundWeekWIL";
import classNames from "classnames/bind";
import styles from "./LecNavListWeek.module.scss";
import { useOnlineTheme } from "../../../queries/pirates";
import { PIRATES } from "../../../../_consts";

const cx = classNames.bind(styles);

function LecNavListWeek({ environment, expired, enrolled, details_week }) {
  const [chapter_title, setChapterTitle] = useState("");
  const [course, setCourse] = useState({});
  const [isActiveWeek, setIsActiveWeek] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const BAR_FULL_LENGTH = 100;
  const { is_active: isActive } = details_week;
  useEffect(() => {
    const { courses: course } = enrolled.data;
    setChapterTitle(getChapterTitle(course, null, details_week.week));
    setCourse(course);
    setIsMobile(environment.deviceType === "mobile");
  }, [enrolled]);

  useEffect(() => {
    if (!expired.loaded) {
      return;
    }
    setIsActiveWeek(
      expired.data.achv.week >= details_week.week &&
        isActive &&
        (expired.data.achv.is_started || expired.data.achv.week === 0)
    );
    setIsListOpen(
      expired.data.achv.week === details_week.week &&
        isActive &&
        (expired.data.achv.is_started || expired.data.achv.week === 0)
    );
  }, [expired.loaded, details_week.is_active]);
  const theme = useOnlineTheme();
  const getSvgColor = () => {
    switch (theme.data) {
      case PIRATES:
        return isActiveWeek ? "#EEF3F6" : "#eef3f64d";
      default:
        return isActiveWeek ? "#000" : "#868e96";
    }
  };
  return (
    <div className={cx("lec-nav-list-week")}>
      <div className={cx("lec-nav-list-week__header")}>
        <div className={cx("lec-nav-list-week__header__main")}>
          {details_week.is_active && (
            <div
              className={cx("lec-nav-list-week__header__main__toggle-img")}
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <svg
                className={cx(
                  "lec-nav-list-week__header__main__toggle-img__img",
                  `${isListOpen ? "--rotate" : ""}`
                )}
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="14"
                fill="none"
                viewBox="0 0 9 14"
              >
                <path fill={getSvgColor()} d="M0 0l9 7-9 7V0z" />
              </svg>
            </div>
          )}
          {!details_week.is_active && (
            <div
              className={cx("lec-nav-list-week__header__main__toggle-img")}
              style={{ padding: "7px 5px" }}
            />
          )}
          <div
            className={cx(
              "lec-nav-list-week__header__main__title",
              theme.data,
              `${isActiveWeek ? "--active" : ""}`
            )}
          >
            {course.title} {chapter_title}
          </div>
        </div>
        {!isMobile && details_week.is_active && (
          <div className={cx("lec-nav-list-week__header__progress")}>
            <span
              className={cx(
                "lec-nav-list-week__header__progress__text",
                theme.data,
                `${isActiveWeek ? "--active" : ""}`
              )}
            >
              {parseInt(details_week.progress)}%
            </span>
            <div
              className={cx("lec-nav-list-week__header__progress__bg")}
              style={{
                "--full-length": `${BAR_FULL_LENGTH}px`,
              }}
            >
              <div
                className={cx("lec-nav-list-week__header__progress__bg__done")}
                style={{
                  "--length": `${details_week.progress}px`,
                }}
              ></div>
            </div>
          </div>
        )}
        {!isMobile && !details_week.is_active && (
          <div className={cx("lec-nav-list-week__header__progress")}>
            <span
              className={cx(
                "lec-nav-list-week__header__progress__coming_soon_text"
              )}
            >
              coming soon
            </span>
          </div>
        )}
      </div>
      {isListOpen && (
        <div className={cx("lec-nav-list-week__lists")}>
          <div className={cx("lec-nav-list-week__lists__lectures")}>
            {details_week.enrolled_details.map((detail, idx) => {
              return (
                <RoundWeekDetailLecture
                  key={idx}
                  expired={expired}
                  week={detail.week}
                  detail={detail}
                  details={details_week.enrolled_details}
                  idx={idx}
                  isTimeVisible={false}
                  isLecture={true}
                />
              );
            })}
          </div>
          {details_week.homework && (
            <div className={cx("lec-nav-list-week__lists__homeworks")}>
              <RoundWeekHomework
                week={details_week.week}
                homework={details_week.homework}
                enrolled_details={details_week.enrolled_details}
                chapter_title={chapter_title}
                isSmall={true}
                isLecture={true}
              />
            </div>
          )}
          {details_week.wil &&
            details_week.homework &&
            details_week.week !== 0 &&
            details_week.week < 6 && (
              <div className={cx("lec-nav-list-week__lists__homeworks")}>
                <RoundWeekWIL
                  week={details_week.week}
                  homework={details_week.homework}
                  wil={details_week.wil}
                  enrolled_details={details_week.enrolled_details}
                  chapter_title={chapter_title}
                  isSmall={true}
                  isLecture={true}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    environment,
    authentication,
    enrolledDetails,
    enrolledDetail,
    enrolled,
    expired,
  } = state;
  const { user } = authentication;
  return {
    environment,
    authentication,
    user,
    enrolled,
    expired,
    enrolledDetail,
    enrolledDetails,
  };
}

const connectedLecNavListWeek = connect(mapStateToProps, {})(LecNavListWeek);
export { connectedLecNavListWeek as LecNavListWeek };
