import React, { useEffect, useState } from "react";

const useConstructor = (fn) => {
  const [isRendered, setIsRendered] = useState(false);
  if (!isRendered) {
    fn();
  }
  useEffect(() => {
    setIsRendered(true);
  }, []);
};

export default useConstructor;
