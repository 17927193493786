export const ArrowRightSmall = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.71459 14.3813C7.37289 14.723 7.37289 15.277 7.71459 15.6187C8.0563 15.9604 8.61032 15.9604 8.95203 15.6187L7.71459 14.3813ZM13.3333 10L13.952 10.6187C14.2937 10.277 14.2937 9.72299 13.952 9.38128L13.3333 10ZM8.95203 4.38128C8.61032 4.03957 8.0563 4.03957 7.71459 4.38128C7.37289 4.72299 7.37289 5.27701 7.71459 5.61872L8.95203 4.38128ZM8.95203 15.6187L13.952 10.6187L12.7146 9.38128L7.71459 14.3813L8.95203 15.6187ZM13.952 9.38128L8.95203 4.38128L7.71459 5.61872L12.7146 10.6187L13.952 9.38128Z"
        fill={color}
      />
    </svg>
  );
};

export const Gift = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <path
        d="M22.0397 22.9266H4.03488C3.32661 22.9266 2.76746 22.3674 2.76746 21.6591V11.818C2.76746 11.1097 3.32661 10.5505 4.03488 10.5505H22.0397C22.748 10.5505 23.3072 11.1097 23.3072 11.818V21.6591C23.3072 22.3674 22.748 22.9266 22.0397 22.9266Z"
        stroke="#212529"
        strokeWidth="1.16807"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6053 10.5496H2.39465C1.91005 10.5496 1.5 10.1395 1.5 9.65491V5.96448C1.5 5.47987 1.91005 5.06982 2.39465 5.06982H23.6053C24.09 5.06982 24.5 5.47987 24.5 5.96448V9.65491C24.4627 10.1768 24.09 10.5496 23.6053 10.5496Z"
        stroke="#212529"
        strokeWidth="1.16807"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9813 22.6645V5.10693"
        stroke="#212529"
        strokeWidth="1.16807"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8323 5.07055L8.50815 1.56649C7.65078 0.858229 6.34608 0.895506 5.63781 1.79016C5.4887 1.97654 5.37687 2.20021 5.26504 2.42387C4.92955 3.39308 5.30232 4.39956 6.15969 4.92144C6.38336 5.07055 6.6443 5.10782 6.90524 5.10782L12.8323 5.07055Z"
        stroke="#212529"
        strokeWidth="1.16807"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1305 5.07035L17.4174 1.5663C18.312 0.858037 19.6167 0.932591 20.325 1.78997C20.4741 1.97635 20.5859 2.20001 20.6978 2.42368C21.0333 3.39288 20.6605 4.39937 19.8031 4.92125C19.5794 5.07035 19.3185 5.10763 19.0576 5.10763L13.1305 5.07035Z"
        stroke="#212529"
        strokeWidth="1.16807"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Receipt = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="3.33325"
        y="2.5"
        width="13.3333"
        height="15"
        rx="1.66667"
        stroke="#3A3E41"
        strokeWidth="1.75"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 6.6665L13.3334 6.6665"
        stroke="#3A3E41"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.66675 10L13.3334 10"
        stroke="#3A3E41"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.66675 13.3335L10.8334 13.3335"
        stroke="#3A3E41"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 13.3333L9.38136 13.9521C9.72307 14.2938 10.2771 14.2938 10.6188 13.9521L10.0001 13.3333ZM7.28547 9.38128C6.94376 9.03957 6.38974 9.03957 6.04803 9.38128C5.70632 9.72299 5.70632 10.277 6.04803 10.6187L7.28547 9.38128ZM13.9521 10.6187C14.2938 10.277 14.2938 9.72299 13.9521 9.38128C13.6104 9.03957 13.0564 9.03957 12.7147 9.38128L13.9521 10.6187ZM6.04803 10.6187L9.38136 13.9521L10.6188 12.7146L7.28547 9.38128L6.04803 10.6187ZM10.6188 13.9521L13.9521 10.6187L12.7147 9.38128L9.38136 12.7146L10.6188 13.9521Z"
        fill="#3A3E41"
      />
      <path
        d="M10 13.3335V3.3335"
        stroke="#3A3E41"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M16.6666 14.1665V15.8332C16.6666 16.2934 16.2935 16.6665 15.8333 16.6665H4.16659C3.70635 16.6665 3.33325 16.2934 3.33325 15.8332V14.1665"
        stroke="#3A3E41"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ShareBlueSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <circle
        cx="4.58333"
        cy="10.9166"
        r="2.08333"
        stroke="#0075B8"
        strokeWidth="1.75"
      />
      <circle
        cx="14.5833"
        cy="5.08333"
        r="2.08333"
        stroke="#0075B8"
        strokeWidth="1.75"
      />
      <path
        d="M7.0835 9.45841L9.5835 8.00008L12.0835 6.54175M7.0835 12.1667L9.5835 13.4167L12.0835 14.6667"
        stroke="#0075B8"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <circle
        cx="14.5833"
        cy="15.9166"
        r="2.08333"
        stroke="#0075B8"
        strokeWidth="1.75"
      />
    </svg>
  );
};
