const SPEED_CONTROL = "speedControl";

const initialState = {
  currentSpeed: 1,
};

export const changePlayerSpeed = (speed) => ({
  type: SPEED_CONTROL,
  speed,
});

export function changeSpeed(state = initialState, action) {
  switch (action.type) {
    case SPEED_CONTROL:
      return {
        currentSpeed: action.speed,
      };
    default:
      return state;
  }
}
