import React from "react";
import { Button, Form } from "antd";

export const BtnItem = ({ buttonText, nextUrl }) => {
  const bgColor = {
    swc: "#3A19FF",
    nbc: "#1F8648",
    hanghae: "#F50000",
    change: "#000000",
    ddingdong: "#FF773C",
    intellipick: "#6E50FF",
  };
  return (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        style={{ backgroundColor: bgColor[nextUrl] }}
      >
        {buttonText}
      </Button>
    </Form.Item>
  );
};
