import styled from "@emotion/styled";
import { neutralDay, wBody1, wBody4 } from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../_styles/device";

export const Container = styled.div<{ link: string }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  cursor: ${({ link }) => (link ? "pointer" : "default")};
`;

export const ArticleImageWrapper = styled.div`
  width: 100%;
  height: auto;
  border-radius: 11px;
  overflow: hidden;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    width: 356px;
    border-radius: 12px;
  }
`;

export const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export const ArticleTitle = styled.h3`
  ${wBody1}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${neutralDay.gray90};
`;

export const ArticleDescription = styled.p`
  ${wBody4};
  color: ${neutralDay.gray70};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
`;
