
import styled from "@emotion/styled";

export const Container = styled.div`
    height: 100%;
`;

export const GradationSpan = styled.span`
  background-image: linear-gradient(to right, #146AFF, #189FEC);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`