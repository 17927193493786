import privateInstance from "../../_helper/axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useUser } from "../../pages/LecturePageV3/useInitialize";
import { useParams } from "react-router-dom";
import { useCourseId, useEnrolled } from "../../hooks/useProductName";
import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../../_helper";
import { useCourseData } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

const getAllCourseRoadmaps = async () => {
  return await privateInstance.get("/courses/course_roadmap");
};

export const useGetAllCourseRoadmaps = () => {
  return useQuery("getAllCourseRoadmaps", getAllCourseRoadmaps);
};

export const useFindCourseRoadmapByCourseId = (courseId) => {
  const courseRoadmaps = useGetAllCourseRoadmaps();
  if (courseId === undefined) {
    return;
  }
  const recommendCourseRoadmap = courseRoadmaps.data?.data?.context?.find(
    (courseRoadmap) => courseRoadmap.target_course_id === courseId
  );
  return recommendCourseRoadmap;
};

const checkRecommendCourseInEnrolled = async (recommendCourseId, userId) => {
  const user_id = userId;
  const course_id = recommendCourseId;
  return privateInstance.get(
    `/enrolleds/users/${user_id}/courses/${course_id}/check_enrollment`
  );
};

const useCheckRecommendCourseInEnrolled = (recommendCourseId) => {
  const user = useUser();
  const userId = user?._id;
  return useQuery(
    ["checkRecommendCourseInEnrolled", recommendCourseId],
    () => checkRecommendCourseInEnrolled(recommendCourseId, userId),
    {
      enabled: recommendCourseId !== undefined && userId !== undefined,
    }
  );
};

export const useCheckRecommendCourse = (currentCourseId) => {
  const recommendCourseRoadmap =
    useFindCourseRoadmapByCourseId(currentCourseId);
  const recommendCourseId = recommendCourseRoadmap?.recommend_course_id;
  const checkRecommendCourseInEnrolled =
    useCheckRecommendCourseInEnrolled(recommendCourseId);
  if (!recommendCourseRoadmap) {
    return { data: { data: { is_enrolled: true } } };
  }
  return checkRecommendCourseInEnrolled;
};

export const useExceedProgress = () => {
  const { enrolled_id } = useParams();
  const enrolled = useEnrolled(enrolled_id);
  const progressRate = enrolled?.data?.progress_rate;
  return progressRate >= 80;
};

const postRoadmapRecommend = async (
  userId,
  submittedAnswer,
  targetCourseId,
  recommendCourseId
) => {
  const postData = {
    user_id: userId,
    submitted_answer: submittedAnswer,
    target_course_id: targetCourseId,
    recommend_course_id: recommendCourseId,
  };
  return privateInstance.post(`/courses/roadmap_recommend`, postData);
};

export const usePostRoadmapRecommend = () => {
  const user = useUser();
  const userId = user?._id;
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      postRoadmapRecommend(
        userId,
        data.submittedAnswer,
        data.targetCourseId,
        data.recommendCourseId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getRoadmapRecommend");
      },
    }
  );
};

const getRoadmapRecommend = async (userId, targetCourseId) => {
  return privateInstance.get(
    `/courses/roadmap_recommend/${userId}/${targetCourseId}`
  );
};

export const useGetRoadmapRecommend = () => {
  const user = useUser();
  const courseId = useCourseId();
  const userId = user?._id;
  return useQuery(
    ["getRoadmapRecommend", userId, courseId],
    () => getRoadmapRecommend(userId, courseId),
    {
      enabled: courseId !== undefined && userId !== undefined,
    }
  );
};

export const useLogRcmdModalView = (modalTitle) => {
  const user = useUser();
  const userId = user?._id;
  const courseId = useCourseId();
  const courseData = useCourseData();
  const courseTitle = courseData?.data?.title;
  const { enrolled_id } = useParams();
  const logObject = {
    user_id: userId,
    course_id: courseId,
    course_title: courseTitle,
    modal_title: modalTitle,
    enrolled_id: enrolled_id,
  };
  useEffect(() => {
    sendCPLog("scc_roadmapPage_rcmdModal_view", logObject);
  }, []);
};

export const useLogClickRcmdModalButton = () => {
  const user = useUser();
  const userId = user?._id;
  const { enrolled_id } = useParams();
  const courseId = useCourseId();
  const courseData = useCourseData();
  const courseTitle = courseData?.data?.title;
  const logObject = {
    user_id: userId,
    course_id: courseId,
    course_title: courseTitle,
    enrolled_id: enrolled_id,
  };
  const logClickRcmdModalButton = (buttonName) => {
    sendCPLog("scc_roadmapPage_rcmdModal_click_button", {
      ...logObject,
      button_name: buttonName,
    });
  };
  return logClickRcmdModalButton;
};
