import { useEffect, useMemo, useRef } from "react";
import videojs from "video.js";
import "videojs-hotkeys";
import { useMute, useVolume } from "../stores/playerAtom";

export const useVolumeControl = (videoRef) => {
  const volumeInputRef = useRef(null);
  const [volume, setVolume] = useVolume();
  const [isMuted, setIsMuted] = useMute(false);

  const playerRef = useRef(null);
  const onVolumeChange = () => {
    if (volumeInputRef.current) {
      const newVolume = parseFloat(
        (volumeInputRef.current.value / 100).toFixed(1)
      );

      if (newVolume === 0) {
        playerRef.current.muted(true);
        setIsMuted(true);
        localStorage.setItem("isMutedV3", "true");
      } else {
        playerRef.current.volume(newVolume);
        localStorage.setItem("volumeV3", newVolume);
        playerRef.current.muted(false);
        setIsMuted(false);
        localStorage.setItem("isMutedV3", "false");
      }

      setVolume(newVolume);
    }
  };
  const toggleMute = () => {
    if (playerRef.current) {
      const newMuted = !playerRef.current.muted();
      playerRef.current.muted(newMuted);
      setIsMuted(newMuted);
      if (newMuted) {
        setVolume(0);
      } else {
        setVolume(playerRef.current.volume());
      }
      localStorage.setItem("isMutedV3", newMuted.toString());
    }
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    playerRef.current = videojs(videoRef.current);
    const player = playerRef.current;

    player.ready(() => {
      const localIsMuted = localStorage.getItem("isMutedV3");
      const localVolume = localStorage.getItem("volumeV3") || 1;
      if (localIsMuted === "true") {
        setIsMuted(true);
        playerRef.current.muted(localIsMuted);
      } else {
        setVolume(localVolume);
        playerRef.current.volume(localVolume);
      }
    });

    return () => {
      player.dispose();
    };
  }, [videoRef]);

  const muteButtonSrc = useMemo(() => {
    if (isMuted) {
      return "../../../assets/volumeoff.svg";
    } else {
      return "../../../assets/volumeon.svg";
    }
  }, [isMuted]);

  return {
    volume,
    volumeInputRef,
    muteButtonSrc,
    toggleMute,
    onVolumeChange,
  };
};
