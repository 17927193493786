const nbcDeveloperBenefits = [
  {
    keyword: "free_dev_course",
    title: "40만원 상당 개발 기초 강의 제공",
    description:
      "총 2개의 웹사이트를 직접 만들어보며 개발과 DB 구조에 대한 기본기를 다집니다.",
  },
  {
    keyword: "resume_and_portfolio",
    title: "개발자 이력서, 포트폴리오 제공",
    description:
      "토스, 네이버, 배달의 민족 현직 개발자의 취업 자료를 제공해 드립니다.",
  },
  {
    keyword: "career_session",
    title: "현직 개발자 커리어 세션",
    description:
      "현업에서 활약하고 있는 개발자와의 온라인 세션에서 무엇이든 물어보세요!",
  },
];

const hanghaePmBenefits = [
  {
    keyword: "free_sql_course",
    title: "50만원 상당 SQL 강의 제공",
    description:
      "PM에게 필수! 개발자 요청 없이 데이터를 뽑을 수 있도록 SQL의 기본, 응용을 다룹니다.",
  },
  {
    keyword: "resume_and_portfolio",
    title: "PM 이력서, 포트폴리오 제공",
    description:
      "토스, 네이버, 배달의 민족 등 유명 IT 기업의 현직 PM 취업 자료를 제공해 드립니다.",
  },
  {
    keyword: "pm_career_session",
    title: "현직 PM 멘토 세션",
    description:
      "현업에서 활약하고 있는 PM 멘토에게 온라인 세션에서 무엇이든 물어보세요!",
  },
];

const rebootBenefits = [
  {
    keyword: "free_python",
    title: "50만원 상당 문법 강의 제공",
    description:
      "코딩 테스트에 특화된 언어, ‘파이썬’의 기초 문법 강의를 제공합니다.",
  },
  {
    keyword: "resume_full_package",
    title: "이력서 풀 패키지 제공",
    description:
      "채용 담당자의 눈에 띌 이력서를 완성하기 위한 세션부터 가이드까지 모두 제공합니다.",
  },
  {
    keyword: "developer_session",
    title: "현직 개발자 취업 세션 초대",
    description:
      "현업에서 활약하고 있는 개발자에게 온라인 세션에서 무엇이든 물어보세요!",
  },
];

const skillUpBenefits = [
  {
    keyword: "additional_discount",
    title: "등록금 20만 원 추가 할인",
    description:
      "주니어 개발자의 커리어 성장을 응원하는 마음으로, 등록금 추가 할인 혜택을 드립니다.",
    subText: "*지인소개/팀스파르타 수료생 할인과 중복 적용 불가",
  },
  {
    keyword: "basic_lecture",
    title: "언어 기초 강의 무료 제공",
    description:
      "본 과정을 잘 시작할 수 있도록, 참고 자료로 언어 기초 강의를 제공 드립니다.",
  },
  {
    keyword: "pre_study",
    title: "사전 스터디 참여 기회",
    description:
      "문법 강의 완강을 목표로 하는 ‘언어 기초 스터디’와 여러 방법론에 대한 깊은 토론을 하는 ‘방법론 스터디’에 참여할 수 있습니다.",
  },
];

export const benefitsByCourseMap = {
  NBC_DEVELOPER: nbcDeveloperBenefits,
  HANGHAE_PM: hanghaePmBenefits,
  REBOOT: rebootBenefits,
  SKILL_UP: skillUpBenefits,
};
