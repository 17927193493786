import styled from "@emotion/styled";
import React from "react";
import { mBody3, mTitle3, neutralDay } from "@teamsparta/design-system";
import { device, DisplayResolution } from "../../../../../../../_styles/device";

export const ContentContainer = styled.div`
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 30px;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
  line-height: 1;
  color: #000000;
  position: relative;
  font-weight: 500;
  box-shadow: ${(props) =>
    props.isFocused ? "0 4px 12px 0 rgba(0, 0, 0, 0.08)" : "none"};
  border: ${(props) =>
    props.isFocused ? "2px solid #e8344e" : "2px solid white"};
  background-color: ${(props) => (props.isFocused ? "#fff5f5" : "#FFFFFF")};
  padding: ${(props) => (props.isFocused ? "14px" : "10px")};
  ${DisplayResolution.TabletAndDesktop} {
    padding: 13.9px 30.5px;
  }
  :hover {
    box-shadow: ${(props) =>
      props.isFocused
        ? "0 4px 12px 0 rgba(0, 0, 0, 0.08)"
        : "0 4px 4px 0 rgba(0, 0, 0, 0.08)"};
    border: ${(props) =>
      props.isFocused ? "2px solid #e8344e" : "2px solid #f8f9fa"};
    background-color: ${(props) => (props.isFocused ? "#fff5f5" : " #f8f9fa")};
  }
`;
export const Week = styled.div`
  width: 20%;
  font-size: 16px;

  @media ${device.desktop} {
    font-size: ${mTitle3};
  }
`;

export const TitleBox = styled.div`
  width: 55%;
  display: inline-flex;
  align-items: center;
  gap: 12px;
`;

export const TitleForEvaluation = styled.div`
  white-space: nowrap;
  font-style: ${mTitle3};
  font-size: 16px;
  overflow: hidden;
  max-width: 60px;
  text-overflow: ellipsis;
  ${DisplayResolution.TabletAndDesktop} {
    font-size: ${mTitle3};
    max-width: none;
  }
`;
export const Title = styled.div`
  width: 55%;
  white-space: nowrap;
  font-style: ${mTitle3};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${DisplayResolution.TabletAndDesktop} {
    font-size: ${mTitle3};
  }
`;

export const TitleSideDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

export const TitleSideDescription = styled.h3`
  ${mBody3};
  color: var(--Neutral-Day-Gray70, #81898f);
  text-align: center;
`;

export const Playtime = styled.div`
  width: 15%;
  font-style: ${mTitle3};
  font-size: 16px;
`;

const RoundWeekDetailLectureBodyComponentSeen = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
`;

const CheckmarkSVG = styled.svg`
  /* Add your styles here */
  width: 23px; // 모바일은 18px;
  height: 23px;
`;

const CheckmarkPath = styled.path`
  width: 23px;
  height: 23px;
  /* Add your styles here */
`;

export const CheckIcon = ({ checked }) => (
  <RoundWeekDetailLectureBodyComponentSeen>
    <CheckmarkSVG
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 23 23"
    >
      <CheckmarkPath
        fill={checked ? "#000000" : "#ced4da"}
        d="M22.25 11.938c0 2.917-1.159 5.715-3.222 7.778-2.063 2.063-4.86 3.221-7.778 3.221-2.917 0-5.715-1.158-7.778-3.221C1.409 17.653.25 14.855.25 11.937c0-2.917 1.159-5.715 3.222-7.778C5.535 2.096 8.332.938 11.25.938c2.917 0 5.715 1.158 7.778 3.221 2.063 2.063 3.222 4.861 3.222 7.779zM16.791 7.77c-.098-.098-.215-.175-.344-.226-.128-.052-.266-.077-.405-.074-.138.003-.275.033-.401.09-.127.057-.24.139-.335.24l-4.775 6.085-2.878-2.88c-.195-.181-.454-.28-.721-.276-.267.005-.522.113-.711.302-.19.189-.297.444-.302.71-.005.268.094.527.277.722l3.638 3.64c.098.098.215.175.343.226.128.052.266.078.404.075.139-.003.275-.033.402-.09.126-.056.24-.137.334-.239l5.49-6.861c.187-.195.29-.455.287-.725-.002-.27-.11-.528-.301-.719h-.002z"
      />
    </CheckmarkSVG>
  </RoundWeekDetailLectureBodyComponentSeen>
);
