export const stempData = [
  {
    goalNumber: 1,
    isGiveAway: true,
    giveAwayCaption: "선착순 14명",
    giveAwayName: "오롤리 다이어리",

    deskTopLeft: 56,
    top: 18,
    left: 30,

    bubbleTop: -23,
    bubbleLeft: -34,
  },
  {
    goalNumber: 2,
    isGiveAway: false,

    deskTopLeft: 160,
    top: 18,
    left: 124,
  },
  {
    goalNumber: 3,
    isGiveAway: true,
    giveAwayCaption: "100% 증정",
    giveAwayName: "스타벅스 기프티콘",

    deskTopLeft: 264,
    top: 18,
    left: 218,

    bubbleTop: -22,
    bubbleLeft: 42,
  },
  {
    goalNumber: 4,
    isGiveAway: false,

    deskTopLeft: 212,
    top: 154,
    left: 171,
  },
  {
    goalNumber: 5,
    isGiveAway: true,
    giveAwayCaption: "선착순 1000명",
    giveAwayName: "배달의민족 1만원 상품권",

    deskTopLeft: 87,
    top: 154,
    left: 52,
    bubbleTop: -23,
    bubbleLeft: 58,
  },
  {
    goalNumber: 6,
    isGiveAway: false,
    deskTopLeft: 56,
    top: 290,
    left: 30,
  },
  {
    goalNumber: 7,
    isGiveAway: false,
    deskTopLeft: 160,
    top: 290,
    left: 124,
  },
  {
    goalNumber: 8,
    isGiveAway: false,
    deskTopLeft: 264,
    top: 290,
    left: 218,
  },
  {
    goalNumber: 9,
    isGiveAway: false,
    deskTopLeft: 222,
    top: 426,
    left: 181,
  },
  {
    goalNumber: 10,
    isGiveAway: true,
    giveAwayCaption: "추첨을 통해",
    giveAwayName: "아이패드 Air 증정",

    deskTopLeft: 99,
    top: 418,
    left: 67,

    bubbleTop: -19,
    bubbleLeft: 36,
  },
];
