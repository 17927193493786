import React, { useRef, useState } from "react";
import {
  getGETAuthOptions,
  getStartPaymentUrl,
  getZeropayUrl,
} from "../../../_urls";
import { history, params } from "../../../_helpers";
import { getDeviceId, sendLog } from "../../../v2/businesslogics/logging";
import privateInstance from "../../../v2/_helper/axios";
import * as S from "../PaymentPage_v2.style";
import { getCookie } from "../../../v2/_helper";
import * as LS from "./CreditQuizLanding.style";

const CreditQuizLanding = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedHasCard, setSelectedHasCard] = useState(false);
  const clickable = useRef(true);
  const course_id = "6115cea3e73913245c9538e0";

  function doZeropay(result, hasCard) {
    const { order_no } = result;
    const endPoint =
      getZeropayUrl(course_id, order_no) + "?has_card=" + hasCard;
    const next_options = getGETAuthOptions();

    fetch(endPoint, next_options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          const { course_id, round_id } = result;

          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete("hrd");

          history.replace(
            `/payment/prequestion/${course_id}/${round_id}?${urlParams.toString()}`
          );
          return;
        }
        alert("결제가 정상적으로 완료되지 않았습니다.");
      });
  }

  async function startPayment(hasCard) {
    let data = {
      course_id,
      gold: 0,
      name: user.name,
      phone: user.phone,
      email: user.email,
      payment_method: "nicepay",
      making_target: false,
      device_id: getDeviceId(),
      unique_id: getDeviceId(),
      selectedChoices: [],
      isPaypal: false,
      coupon_id: null,
      selectedRoundClass: null,
    };
    await privateInstance
      .post(getStartPaymentUrl(course_id), data)
      .then((resp) => resp.data)
      .then((result) => {
        doZeropay(result, hasCard);
        // 결제정보와 대응되는 UTM log저장
        let utm_source = getCookie("utm_source");
        let utm_medium = getCookie("utm_medium");
        let utm_campaign = getCookie("utm_campaign");
        let utm_content = getCookie("utm_content");
        let utm_term = getCookie("utm_term");
        let log_data = {
          order_no: result.order_no,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term,
        };
        sendLog("", log_data, "", "purchase_utm");
      });
  }

  const onClick = (hasCard) => {
    if (clickable.current) {
      setShowModal(true);
      setSelectedHasCard(hasCard);
      clickable.current = false;
      // startPayment(hasCard);
    }
  };
  let { has_card } = params();

  const onClickModalCta = () => {
    startPayment(selectedHasCard);
  };

  if (has_card) {
    startPayment(has_card);
  } else {
    return (
      <>
        {showModal && (
          <LS.ModalWrapper>
            <LS.ModalContainer>
              <LS.TextContainer>
                <LS.Title>국비지원 안내 신청 완료</LS.Title>
                <LS.Desc>
                  가입 시 입력한 연락처로 국비지원 신청을 위한
                  <br />
                  안내사항을 보내 드려요.
                </LS.Desc>
              </LS.TextContainer>
              <LS.Cta
                onClick={() => {
                  onClickModalCta();
                }}
              >
                <LS.CtaText>확인</LS.CtaText>
              </LS.Cta>
            </LS.ModalContainer>
          </LS.ModalWrapper>
        )}

        <S.Wrapper className={"home"}>
          <S.TitleContainer>
            <S.GrayTitle>국비지원 안내 신청</S.GrayTitle>
            <S.MainTitle>
              내일배움카드 소지 여부
              <br />
              응답하면 신청 완료!
            </S.MainTitle>
          </S.TitleContainer>
          <S.InfoContainer>
            <S.InfoRtanLogo
              src={"/assets/images/rtan/card_in_hand.png"}
              alt={""}
            />
            <S.InfoQuoteWrapper>
              <S.InfoQuoteBox
                src={"/v2/assets/images/nb-quote-box.png"}
                alt={""}
              />
              <S.InfoText>
                카드 소지 여부에 따라 정확하게 안내해 드릴게요!
              </S.InfoText>
            </S.InfoQuoteWrapper>
          </S.InfoContainer>

          <S.GrayBtn onClick={() => onClick(true)} className={"btn"}>
            <S.ButtonTextWrapper>
              <S.ButtonUpperTextBox>
                <S.ButtonUpperSVG
                  src={"/v2/assets/images/has_card_icon.png"}
                  alt={""}
                />
                <S.ButtonUpperText>카드 있어요</S.ButtonUpperText>
              </S.ButtonUpperTextBox>
              <S.ButtonUnderText>바로 수강신청하기</S.ButtonUnderText>
            </S.ButtonTextWrapper>
          </S.GrayBtn>
          <S.GrayBtn onClick={() => onClick(false)} className={"btn"}>
            <S.ButtonTextWrapper>
              <S.ButtonUpperTextBox>
                <S.ButtonUpperSVG
                  src={"/v2/assets/images/no_card_icon.png"}
                  alt={""}
                />
                <S.ButtonUpperText>카드 없어요</S.ButtonUpperText>
              </S.ButtonUpperTextBox>
              <S.ButtonUnderText>카드신청 방법 보기</S.ButtonUnderText>
            </S.ButtonTextWrapper>
          </S.GrayBtn>
        </S.Wrapper>
      </>
    );
  }
};

export default CreditQuizLanding;
