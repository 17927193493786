import React from "react";
import { TextAnswerContainer } from "../../../molecules/HomeworkV3/HomeworkAnswer/HomeworkAnswer.style"; // React 임포트

interface ShortResponseProps {
  answer: string;
  setAnswer: (answer: string) => void;
}

export const ShortResponse = ({ answer, setAnswer }: ShortResponseProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newAnswer = event.target.value;
    setAnswer(newAnswer);
  };

  return (
    <TextAnswerContainer
      value={answer}
      onChange={handleChange}
      placeholder="답안을 여기에 작성해 주세요."
    />
  );
};
