import React, { useState } from "react";
import { ErrorTypeSelector } from "../../LecturePageV3/LectureTapMenu/ErrorReport/ErrorTypeSelector";
import * as S from "./MobileLecReportTab.style";
import { ContentsErrorSubmissionNotification } from "../../LecturePageV3/LectureTapMenu/ErrorReport/ContentsErrorSubmissionNotification";
import { MobileLecReport } from "./MobileLecReport";
import { MobileLecReportTabContainer } from "./MobileLecReportTab.style";

export const MobileLecReportTab = ({ enrolledDetail, user }) => {
  const [reportState, setReportState] = useState("SELECT_TYPE"); // 'SELECT_TYPE', 'CONTENTS_ERROR_REPORT', 'CONTENTS_ERROR_REPORT_SUBMITTED'
  const ReportComponents = {
    SELECT_TYPE: () => (
      <S.MobileLecReportTabContainer>
        <ErrorTypeSelector setReportState={setReportState} />
      </S.MobileLecReportTabContainer>
    ),
    CONTENTS_ERROR_REPORT: () => (
      <MobileLecReport
        enrolledDetail={enrolledDetail}
        user={user}
        setReportState={setReportState}
      />
    ),
  };
  return <>{ReportComponents[reportState]()}</>;
};
