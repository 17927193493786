import { authHeader, handleResponse, privateFetch } from "../_helpers";
import { config } from "../config";

const baseUrl = `${config.apiUrl}/v2`;

function getGovCompletionMilestone(courseId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };
  return privateFetch(
    `${baseUrl}/courses/gov/${courseId}/completion`,
    requestOptions
  ).then(handleResponse);
}

function getGfsDeadline() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };
  return privateFetch(`${baseUrl}/gov-status/deadline`, requestOptions).then(
    handleResponse
  );
}

function getGfsIsCardReceived() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };
  return privateFetch(
    `${baseUrl}/gov-status/card_received`,
    requestOptions
  ).then(handleResponse);
}

export const govService = {
  getGfsDeadline,
  getGovCompletionMilestone,
  getGfsIsCardReceived,
};
