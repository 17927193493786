import { certConstants } from "../_constants/certs.constants";

const initState = {
  loaded: false,
};

export function certs(state = initState, action) {
  switch (action.type) {
    case certConstants.CERT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case certConstants.CERT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.data,
      });
    case certConstants.CERT_FAILURE:
      console.log(action.error);
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
