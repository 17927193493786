import React, { Component } from "react";
import { connect } from "react-redux";

class Channeltalk extends Component {
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      (function () {
        var w = window;
        if (w.ChannelIO) {
          return (window.console.error || window.console.log || function () {})(
            "ChannelIO script included twice."
          );
        }
        var ch = function () {
          ch.c(arguments);
        };
        ch.q = [];
        ch.c = function (args) {
          ch.q.push(args);
        };
        w.ChannelIO = ch;

        function l() {
          if (w.ChannelIOInitialized) {
            return;
          }
          w.ChannelIOInitialized = true;
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
          s.charset = "UTF-8";
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        }

        if (document.readyState === "complete") {
          l();
        } else if (window.attachEvent) {
          window.attachEvent("onload", l);
        } else {
          window.addEventListener("DOMContentLoaded", l, false);
          window.addEventListener("load", l, false);
        }
      })();
      let { ChannelIO } = window;
      ChannelIO("boot", {
        pluginKey: "74fefd0d-c880-4ec7-b06f-d09eeba19b43",
        customLauncherSelector: "#ch-button",
        hideChannelButtonOnBoot: true,
        memberId: user._id,
        unsubscribed: !user.marketing,
        profile: {
          name: user.name,
          mobileNumber: user.phone,
          email: user.email,
          slack_email: user.slack_email,
          marketing: user.marketing,
        },
      });
    }
  }

  render() {
    return <div />;
  }
}
function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedChanneltalk = connect(mapStateToProps, {})(Channeltalk);
export { connectedChanneltalk as Channeltalk };
