import { func } from "prop-types";
import privateInstance from "../../_helper/axios";
import privateInstanceV3 from "../../_helper/axiosV3";

async function getCodeSnippets(enrolled_id, detail_id) {
  return await privateInstanceV3
    .get(`/learning-resources/${detail_id}/code-snippets`)
    .then((resp) => resp.data);
}

export async function getCodeExamples(detail_id) {
  return await privateInstanceV3
    .get(`/learning-resources/${detail_id}/code-examples`)
    .then((resp) => resp.data);
}

async function getMaterial(enrolled_id, detail_id) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/details/${detail_id}/material`)
    .then((resp) => resp.data);
}

async function getMaterialByEnrolled(enrolled_id, week) {
  return await privateInstance
    .get(`/enrolleds/${enrolled_id}/week/${week}/material`)
    .then((resp) => resp.data);
}

async function getCommonLecture(common_lecture_id) {
  return await privateInstance
    .get(`lecture/common/${common_lecture_id}`)
    .then((resp) => resp.data);
}

export async function getLectureV2(lecture_id) {
  return await privateInstance
    .get(`/lecture/v2/${lecture_id}`)
    .then((resp) => resp.data);
}

export const lectureService = {
  getCodeSnippets,
  getMaterial,
  getMaterialByEnrolled,
  getLectureV2,
  getCommonLecture,
};
