import { COURSE_CONSTANTS } from "../constants/courses.constant";
import { coursesService } from "../services/courses.service";
import { courseConstants } from "../../../_constants";
import { courseService } from "../../../_services";

function getCourse(course_id) {
  function request() {
    return { type: COURSE_CONSTANTS.COURSE_GET_REQUEST };
  }
  function success(response) {
    return { type: COURSE_CONSTANTS.COURSE_GET_SUCCESS, response };
  }
  function failure(error) {
    return { type: COURSE_CONSTANTS.COURSE_GET_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    coursesService.getCourse(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getCourseByPkgId(course_id) {
  function request() {
    return { type: courseConstants.COURSE_BY_PKG_ID_REQUEST };
  }

  function success(response) {
    return { type: courseConstants.COURSE_BY_PKG_ID_SUCCESS, response };
  }

  function failure(error) {
    return { type: courseConstants.COURSE_BY_PKG_ID_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    courseService.getCourseByPkgId(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const coursesActions = {
  getCourse,
  getCourseByPkgId,
};
