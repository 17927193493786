import { createPortal } from "react-dom";
import { useEffect, useRef } from "react";

const Portal = ({ children }) => {
  // const modalRoot =
  //   typeof window !== "undefined" && document.getElementById("modal");
  const modalRoot =
    typeof window !== "undefined" && document.getElementById("modal");
  const el = typeof window !== "undefined" && document.createElement("div");
  const modal = useRef(null);

  if (!modal.current) {
    modal.current = el;
  }

  useEffect(() => {
    modalRoot?.appendChild(modal.current);
    return () => {
      modalRoot?.removeChild(modal.current);
    };
  }, [modalRoot, modal]);

  if (modal.current) {
    return createPortal(children, modal.current);
  }
  return null;
};

export default Portal;
