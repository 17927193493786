import styled from "@emotion/styled";
import { neutralDay } from "@teamsparta/design-system";

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const LoadingRtanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const LoadingRtan = styled.img`
  width: 59px;
  height: 72px;
  object-fit: contain;
`;

export const LoadingTextContainer = styled.img`
  display: flex;
  align-items: center;
  height: 26px;
`;
