import privateInstance from "../../_helper/axios";

async function getNPS(enrolleds_id, week) {
  return await privateInstance
    .get(`/nps/${enrolleds_id}/${week}`)
    .then((resp) => resp.data);
}

async function postNPS(enrolleds_id, week, nps) {
  /**
   * nps
   * {
   *  nps_score,
   *  satisfaction,
   *  good_point,
   *  bad_point
   * }
   */
  return await privateInstance
    .post(`/nps/${enrolleds_id}/${week}`, nps)
    .then((resp) => resp.data);
}

async function postB2bSurvey(enrolled_id, data) {
  return await privateInstance
    .post(`/survey/b2b/${enrolled_id}`, data)
    .then((resp) => resp.data);
}

export const npsService = {
  getNPS,
  postNPS,
  postB2bSurvey,
};
