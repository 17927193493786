import { breakpoints, DisplayResolution } from "../../../../_styles/device";
import { LECTURE_GNB_HEIGHT } from "../variables";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";
import { additional, wBody3 } from "@teamsparta/design-system";
import { css } from "@emotion/react";

export const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  right: ${({ isOpenList }) => (isOpenList ? 0 : "-25%")};
  position: fixed;
  transition: all 0.2s ease-in;
  background-color: #eef3f6;
  overflow-y: scroll;
  height: 100vh;
  border-left: 1px solid #c7d2d8;
  z-index: 2;
  @media (min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.miniDesktop -
    1}px) {
    display: none;
  }
`;

export const SideBarContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;

export const SideBarTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 17px;
  width: 100%;
  height: ${LECTURE_GNB_HEIGHT}px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #c7d2d8;
  z-index: 2;
  ${DisplayResolution.MiniDesktop} {
    padding: 0 8px;
  }
  ${DisplayResolution.Tablet} {
    padding: 0 8px;
  }
`;
export const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  ${DisplayResolution.MiniDesktop} {
    gap: 12px;
  }
  ${DisplayResolution.Tablet} {
    gap: 8px;
  }
`;

export const textColorChange = keyframes`
  0% {
    color: #9DA7AE;
  }
  50% {
    color: ${additional.blue100};
  }
  100% {
    color: #9DA7AE;
  }`;

export const bounce = keyframes`
  0%, 100% {
    transform: translateY(0px);
    animation-timing-function: cubic-bezier(0, 0, 0.1, 0.1);
  }
  50% {
    transform: translateY(10px);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 0.5);
  }
`;

export const TabTitle = styled.div`
  padding: 22px 0;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  height: 100%;
  font-size: 18px;
  font-weight: 600;
  color: ${({ focus }) => (focus ? "#3A3E41" : "#9DA7AE")};
  box-shadow: ${({ focus }) => (focus ? "0px 2px 0px #3A3E41" : "none")};
  cursor: pointer;
  //TODO: 2주 후 삭제
  animation: ${({ isAIChat, focus }) =>
    isAIChat && !focus && `${textColorChange} 2s infinite`};
  ${DisplayResolution.MiniDesktop} {
    font-size: 16px;
  }
  ${DisplayResolution.Tablet} {
    font-size: 14px;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// 페이드아웃 애니메이션 정의
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// 애니메이션 적용 조건에 따라 다른 스타일을 적용
const animation = ({ show, startAnimation }) => {
  if (!show) {
    return css`
      display: none;
    `;
  } else if (show && startAnimation) {
    return css`
      animation: ${fadeIn} 1s forwards, ${bounce} 1s infinite;
    `;
  } else {
    return css`
      animation: ${fadeOut} 0.5s forwards;
    `;
  }
};
export const ChatBotToolTip = styled.div`
  cursor: pointer;
  width: 360px;
  height: 160px;
  position: absolute;
  left: calc(100% - 28%);
  top: 60px;
  background-image: url("/v2/assets/images/Tooltip.png");
  background-size: cover;
  z-index: 2;
  animation: ${bounce} 1s infinite;
  ${({ show, startAnimation }) => animation({ show, startAnimation })}
  display: ${({ show }) => (show ? "block" : "none")};
  ${DisplayResolution.MiniDesktop} {
    left: calc(100% - 30%);
  }
  ${DisplayResolution.Tablet} {
    left: calc(100% - 33%);
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    display: none;
  }
`;

export const ToolTipText = styled.div`
  ${wBody3};
  color: #fff;
  display: flex;
  width: 120px;
  height: 44px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 49%;
  left: 52%;
  transform: translate(-49%, -50%);
`;

export const Tag = styled.div`
  background-color: #146aff;
  color: white;
  font-size: 11px;
  border-radius: 4px;
  padding: 1px 4px;
  margin-left: 6px;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    font-size: 9px;
  }
  ${DisplayResolution.Tablet} {
    font-size: 8px;
  }
`;
export const SideBarClose = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
