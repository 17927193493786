import instance from "../_helpers/axios";

function getDashboard(round_id) {
  return instance
    .get(`/rounds/${round_id}/dashboard`)
    .then((resp) => resp.data);
}

export const dashboardService = {
  getDashboard,
};
