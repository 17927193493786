export const PAYMENT_CONSTANT = {
  OPTION_REQUEST: "OPTION_REQUEST",
  OPTION_SUCCESS: "OPTION_SUCCESS",
  OPTION_FAILURE: "OPTION_FAILURE",

  REINFORCED_REQUEST: "REINFORCED_REQUEST",
  REINFORCED_SUCCESS: "REINFORCED_SUCCESS",
  REINFORCED_FAILURE: "REINFORCED_FAILURE",

  REINFORCED_BY_USER_REQUEST: "REINFORCED_BY_USER_REQUEST",
  REINFORCED_BY_USER_SUCCESS: "REINFORCED_BY_USER_SUCCESS",
  REINFORCED_BY_USER_FAILURE: "REINFORCED_BY_USER_FAILURE",

  ALERT_REQUEST: "ALERT_REQUEST",
  ALERT_SUCCESS: "ALERT_SUCCESS",
  ALERT_FAILURE: "ALERT_FAILURE",

  GET_COUNSEL_REQUEST: "GET_COUNSEL_REQUEST",
  GET_COUNSEL_SUCCESS: "GET_COUNSEL_SUCCESS",
  GET_COUNSEL_FAILURE: "GET_COUNSEL_FAILURE",

  POST_COUNSEL_REQUEST: "POST_COUNSEL_REQUEST",
  POST_COUNSEL_SUCCESS: "POST_COUNSEL_SUCCESS",
  POST_COUNSEL_FAILURE: "POST_COUNSEL_FAILURE",

  GET_COUPON_REQUEST: "GET_COUPON_REQUEST",
  GET_COUPON_SUCCESS: "GET_COUPON_SUCCESS",
  GET_COUPON_FAILURE: "GET_COUPON_FAILURE",

  POST_COUPON_REQUEST: "POST_COUPON_REQUEST",
  POST_COUPON_SUCCESS: "POST_COUPON_SUCCESS",
  POST_COUPON_FAILURE: "POST_COUPON_FAILURE",

  GET_REVIEWS_REQUEST: "GET_REVIEWS_REQUEST",
  GET_REVIEWS_SUCCESS: "GET_REVIEWS_SUCCESS",
  GET_REVIEWS_FAILURE: "GET_REVIEWS_FAILURE",

  GET_VBANK_REQUEST: "GET_VBANK_REQUEST",
  GET_VBANK_SUCCESS: "GET_VBANK_SUCCESS",
  GET_VBANK_FAILURE: "GET_VBANK_FAILURE",
};

// SQLD 과목 ID
export const SQLD_COURSE_ID = "6433b6aab8d11cc10a00b294";

// 2024 하반기 KDC 심사 과목 ID 목록
export const KDC_QUALIFICATION_COURSE_IDS_2024_2H = [
  "669a151f2bbb65f2821ca0e5",
  "6695d6b8c4cbc41f84fbb61c",
  "6695d0d2561f34dc16fba8c1",
  "669884aac4cbc41f84feb3e6",
  "66a734212bbb65f2822457be",
  "668cd0a3561f34dc16f873e4",
  "669f16bf2bbb65f2821db6bd",
  "669ef0f0c4cbc41f84005843",
  "6699ff44c4cbc41f84ff60d5",
  "669a161b2bbb65f2821ca7fc",
  "6699cf61c4cbc41f84ff5434",
];

// 2024 하반기 KDC 심사 과목 할인 섹션 비표시 종료 날짜
export const KDC_QUALIFICATION_END_DATE_2024_2H = new Date(
  "2024-08-09T23:59:59+09:00"
);
