import * as S from "./DesktopNextBtn.style";
import { useOnlineTheme } from "../../../../queries/pirates";
import { ArrowRightLine } from "@teamsparta/design-system";

export const DesktopNextBtn = ({ text, isActive, onClick, isClickable }) => {
  return (
    <>
      <S.ButtonContainer
        data-testid="desktop-next-btn"
        onClick={
          isClickable
            ? onClick
            : (e) => {
                e.stopPropagation();
              }
        }
        isClickable={isClickable}
      >
        {isActive ? (
          <S.ButtonContentContainer>
            <S.ButtonTitle>{text}</S.ButtonTitle>
            <ArrowRightLine size={16} color={"#ffffff"} />
          </S.ButtonContentContainer>
        ) : (
          <S.LoadingSpinner src={"/assets/icons/lecture/ic_loading.png"} />
        )}
      </S.ButtonContainer>
    </>
  );
};

export const MobileNextBtn = ({ text, isActive, onClick }) => {
  const theme = useOnlineTheme();
  return (
    <S.MobileWrapper>
      <S.MobileCta
        isActive={isActive}
        theme={theme.data}
        onClick={
          isActive
            ? onClick
            : (e) => {
                e.stopPropagation();
              }
        }
      >
        <S.MobileCtaTitle theme={theme.data}>{text}</S.MobileCtaTitle>
        <ArrowRightLine size={16} color={"#ffffff"} />
      </S.MobileCta>
    </S.MobileWrapper>
  );
};

export const MobileNextWithNoShadow = ({ text, isActive, onClick }) => {
  const theme = useOnlineTheme();
  return (
    <S.MobileWrapper>
      <S.MobileCtaNoShadow
        isActive={isActive}
        theme={theme.data}
        onClick={
          isActive
            ? onClick
            : (e) => {
                e.stopPropagation();
              }
        }
      >
        <S.MobileCtaTitle theme={theme.data}>{text}</S.MobileCtaTitle>
        <ArrowRightLine size={16} color={"#ffffff"} />
      </S.MobileCtaNoShadow>
    </S.MobileWrapper>
  );
};
