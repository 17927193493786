import styled from "styled-components";
import { PIRATES } from "../../../_consts";

export const QuestionNumber = styled.div`
  height: 21px;
  font-family: Pretendard;
  font-style: normal;
  color: #e8344e;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  justify-content: start;
`;

export const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 100%;
  left: 16px;
  top: 525px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#f6f9fa";
    }
  }};

  @media (min-width: 500px) {
    padding: 30px 16px;
    background: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#222425";
        default:
          return "#f6f9fa";
      }
    }};
  }
`;
export const QuestionTitle = styled.div`
  height: 21px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  margin-bottom: 32px;
`;

export const MainNPSFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;

  width: 100%;
  position: fixed;
  z-index: 99;
  bottom: 0;

  background: ${({ isDesktop, theme }) => {
    switch (theme) {
      case PIRATES:
        return isDesktop ? null : "#222425";
      default:
        return isDesktop ? null : "#F6F9FA";
    }
  }};
  box-shadow: ${(props) =>
    !props.isDesktop ? "0px -4px 20px rgba(20, 22, 23, 0.08)" : null};
  @media (min-width: 500px) {
    position: unset;
    padding: 0 16px;
    margin-top: 30px;
    margin-bottom: 120px;
  }
`;

export const QuestionSelectBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 326px;
  justify-content: space-between;
  padding: 0px 16px;
  position: relative;
  align-items: flex-start;
  margin: 0 auto;
`;
export const RcmdSelectBoxContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 56px;
  height: 100%;
  overflow-x: scroll;
`;

export const QuestionSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const RcmdQuestionSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.index === 0 ? "47px" : "48px")};
  height: 100%;
`;

export const QuestionSelectDescription = styled.div`
  text-align: center;
  font-family: "Pretendard";
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#B4BFC6";
      default:
        return "#81898f";
    }
  }};
  padding-top: 6px;
`;
export const RcmdSelectDescFirst = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: "Pretendard";
  width: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#B4BFC6";
      default:
        return "#81898f";
    }
  }};
  margin-left: 1px;
  padding-top: 6px;
  white-space: nowrap;
`;
export const RcmdSelectDescEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  font-family: "Pretendard";
  width: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  padding-top: 6px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#B4BFC6";
      default:
        return "#81898f";
    }
  }};
  white-space: nowrap;
`;

export const QuestionSelectBox = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 48px;
  line-height: 46px;
  height: 46px;
  left: 16px;
  top: 0px;
  background: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return isChecked ? "#EEF3F6" : "#2B2D2E";
      default:
        return isChecked ? "#E8344E" : "#FFFFFF";
    }
  }};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return "#9DA7AE";
      default:
        return isChecked ? "#E8344E" : "#D7E0E6";
    }
  }};
  z-index: 1;

  :hover {
    background: ${({ isChecked, theme }) => {
      switch (theme) {
        case PIRATES:
          return isChecked ? null : "#5F666B";
        default:
          return isChecked ? null : "#F2F6F8";
      }
    }};
  }

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return isChecked ? "#2B2D2E" : "#EEF3F6";
      default:
        return isChecked ? "#FFFFFF" : "#3A3E41";
    }
  }};
`;

export const RcmdSelectBox = styled.div`
  cursor: pointer;
  line-height: 46px;
  width: 48px;
  height: 46px;
  border-width: ${(props) =>
    props.index === 0 ? "1px 1px 1px 1px" : "1px 1px 1px 0px"};
  border-radius: ${(props) =>
    props.index === 0
      ? "8px 0px 0px 8px"
      : props.index === props.size - 1
      ? "0px 8px 8px 0px"
      : null};
  border-style: solid;
  border-color: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return "#9DA7AE";
      default:
        return isChecked ? "#E8344E" : "#D7E0E6";
    }
  }};
  text-align: center;
  background: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return isChecked ? "#EEF3F6" : "#2B2D2E";
      default:
        return isChecked ? "#E8344E" : "#FFFFFF";
    }
  }};

  :hover {
    background: ${({ isChecked, theme }) => {
      switch (theme) {
        case PIRATES:
          return isChecked ? null : "#5F666B";
        default:
          return isChecked ? null : "#F2F6F8";
      }
    }};
  }

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ isChecked, theme }) => {
    switch (theme) {
      case PIRATES:
        return isChecked ? "#2B2D2E" : "#EEF3F6";
      default:
        return isChecked ? "#FFFFFF" : "#3A3E41";
    }
  }};
`;
export const QuestionLine = styled.div`
  width: 270px;
  height: 1px;
  left: 25px;
  top: 22px;
  background: #d7e0e6;
  position: absolute;
`;
export const SubQuestionTextArea = styled.textarea`
  padding: 18px 16px;
  resize: none;
  gap: 12px;
  height: 120px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#d7e0e6";
      }
    }};
  border-radius: 8px;
  margin-top: 16px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#eef3f6";
      default:
        return "rgba(0,0,0,.65)";
    }
  }};

  &::placeholder {
    color: #c7c9cb;

    &.pirates {
      color: #81898f;
    }
  }
`;

export const RcmdQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 30px 44px;
  width: 100%;
  height: 445px;
  left: 0px;
  top: 110px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 15px;
  @media (min-width: 500px) {
    align-items: center;
    width: 588px;
    border-radius: 16px;
  }
`;

export const RcmdHeadings = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 10px 5px;
  width: 65px;
  height: 29px;

  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  border-radius: 40px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
`;

export const RcmdQuestionTitle = styled.div`
  width: 340px;
  height: 102px;
  margin-top: 16px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  white-space: pre-wrap;

  @media (min-width: 500px) {
    br {
      display: none;
    }

    text-align: center;
    width: 100%;
  }
`;

export const RcmdQuestionSubTitle = styled.div`
  width: 328px;
  height: 42px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#C7D2D8";
      default:
        return "#81898f";
    }
  }};
  margin-top: 16px;
  white-space: pre-wrap;
  @media (min-width: 500px) {
    text-align: center;
    width: 100%;
  }
`;
