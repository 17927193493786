import styled from "@emotion/styled";
import { Device } from "../../../../../_styles/device";
import {
  neutralDay,
  mBody1,
  mTitle2,
  scc,
  wBody1,
} from "@teamsparta/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${Device.Desktop} {
  }
`;

export const Index = styled.div`
  ${mBody1};
  color: ${scc.red100};
  ${Device.Desktop} {
    ${wBody1};
    color: ${scc.red100};
  }
`;
export const Title = styled.div`
  ${mTitle2};
  color: ${neutralDay.gray100};
  ${Device.Desktop} {
    ${wBody1};
    color: ${neutralDay.gray100};
  }
`;
