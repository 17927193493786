import * as S from "./evaluationIntro.style";
import {
  ContentsEdit,
  ContentsNote,
  InfoVerified,
  neutralDay,
} from "@teamsparta/design-system";
import { useMyEvaluations } from "../../../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { useParams } from "react-router-dom";
export const EvaluationIntro = () => {
  const { enrolled_evaluation_id } = useParams();
  const { data: evaluations } = useMyEvaluations(enrolled_evaluation_id);
  const numberOfQuestions = evaluations?.questionData.length;
  const week = evaluations?.week;
  return (
    <S.EvaluationIntroContainer>
      <S.EvaluationIntroMainTitle>{week}주차 테스트</S.EvaluationIntroMainTitle>
      <S.EvaluationIntroDescription>
        {
          "튜터님이 엄선한 문제로 이번 주 학습 내용을 점검해보세요. \n채점 후에는 답변을 수정할 수 없습니다."
        }
      </S.EvaluationIntroDescription>
      <S.EvaluationIntroContentContainer>
        <S.EvaluationIntroContentBox>
          <ContentsEdit
            size={16}
            style={"line"}
            color={`${neutralDay.gray50}`}
          />
          <S.EvaluationIntroContentText>
            {numberOfQuestions}문제
          </S.EvaluationIntroContentText>
        </S.EvaluationIntroContentBox>
        <S.GrayRectangle />
        <S.EvaluationIntroContentBox>
          <InfoVerified
            size={16}
            style={"line"}
            color={`${neutralDay.gray50}`}
          />
          <S.EvaluationIntroContentText>
            100점 만점
          </S.EvaluationIntroContentText>
        </S.EvaluationIntroContentBox>
        <S.GrayRectangle />
        <S.EvaluationIntroContentBox>
          <ContentsNote
            size={16}
            style={"line"}
            color={`${neutralDay.gray50}`}
          />
          <S.EvaluationIntroContentText>
            객관식, 주관식 혼합
          </S.EvaluationIntroContentText>
        </S.EvaluationIntroContentBox>
      </S.EvaluationIntroContentContainer>
    </S.EvaluationIntroContainer>
  );
};
