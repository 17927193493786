import React, { useState } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../../_state/actions";
import classNames from "classnames/bind";
import styles from "./LecMenuComponent.module.scss";
import { Tooltip } from "antd";
import { history } from "../../../../../_helpers";
import { sendLog } from "../../../../businesslogics/logging";

const cx = classNames.bind(styles);

function LecMenuComponent({
  style,
  component,
  idx,
  setSelectedMenu,
  selectedMenu,
  lecture,
  setReportVisible,
  setSettingVisible,
  material,
  isDual,
  enrolled,
  enrolledDetail,
  course,
  isNav,
  goRoadmap,
}) {
  const [isHover, setIsHover] = useState(false);
  const isSelected = idx === selectedMenu;
  const hover_img_src = `/assets/icons/lecture/${component.src}_hover.png`;
  const img_src = `/assets/icons/lecture/${component.src}.png`;
  const handleMouseOver = (e) => {
    setIsHover(true);
  };
  const handleMouseLeave = (e) => {
    setIsHover(false);
  };
  const goSlack = () => {
    const { qna_url } = material.data;
    window.open(qna_url);
  };
  const goFAQ = () => {
    sendLog("scc_lecturePage_click_FAQBtn", {
      course_keyword: course.data?.keyword,
      week: enrolledDetail.data?.week,
      week_order: enrolledDetail.data?.week_order,
    });
    const { faq_url } = material.data;
    window.open(faq_url);
  };
  const goMaterial = () => {
    const { material_url } = material.data;
    window.open(material_url);
  };
  const showSetting = () => {
    setSettingVisible(true);
  };
  const showReport = () => {
    setReportVisible(true);
  };
  const doNothing = () => {};
  goRoadmap ||
    (goRoadmap = () => {
      const { enrolled_id, round_id } = enrolled.data;
      history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
    });
  const openChannelTalk = () => {
    window.open("https://spartacodingclub.channel.io/support-bots/49070");
  };
  const isTrialWithoutPractice =
    [
      "6178b82938d078df2971b63f", //1시간만에 끝내는 직장인 코딩 용어 해설
      "625963fa9d5b4ee7f14b61d9", //[무료 패키지] 개발자 취업 준비의 모든 것
      "632bec62293ed67329e02c62", //IT 창업, 실패 확률 줄이는 방법
      "632ad50b2b63868e4030eb5b", //교육 담당자를 위한 DT교육 설계 로드맵
      "6334259bd6566f2cb23ec7f7", //중고등학생 코딩교육 준비 전략
    ].indexOf(enrolled.data?.course_id) >= 0;
  const clickActionWrapper = {
    0: goRoadmap,
    1: doNothing,
    2: goMaterial,
    3: !isTrialWithoutPractice ? goSlack : openChannelTalk,
    4: showReport,
    5: goFAQ,
    6: isDual ? doNothing : showSetting,
  };
  return (
    <>
      <div
        style={style}
        className={cx(
          "LecMenuComponentContainer",
          `${isDual ? "dual" : ""}`,
          `${isSelected ? "selected" : ""}`,
          `${isNav ? "isNav" : ""}`
        )}
      >
        <Tooltip
          visible={isHover && !isSelected}
          title={component.title}
          placement={!isDual ? "bottom" : "right"}
        >
          <img
            onMouseOver={(e) => {
              handleMouseOver(e);
            }}
            onMouseLeave={(e) => {
              handleMouseLeave(e);
            }}
            onClick={() => {
              sendLog(
                "scc_lecturePage_click_button",
                {
                  ...enrolled.data,
                  ...enrolledDetail.data,
                  button_text: component.title,
                  button_name: component.title,
                },
                ""
              );
              clickActionWrapper[idx]();
              if (isDual) setSelectedMenu(idx);
            }}
            src={isHover ? hover_img_src : img_src}
          />
        </Tooltip>
        {isSelected && !isDual && <span>{component.title}</span>}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolledDetails, enrolledDetail, enrolled, course } =
    state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledDetail,
    enrolledDetails,
    course,
  };
}

const connectedLecMenuComponent = connect(mapStateToProps, {
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
})(LecMenuComponent);
export { connectedLecMenuComponent as LecMenuComponent };
