import * as S from "./nps.style";
import {
  ArrowChevronLeft,
  ArrowLeft,
  ArrowLeftSmallLine,
} from "@teamsparta/design-system";
import { DesktopNextBtn } from "../LecturePageV3/LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import React from "react";
import { BackIconSvg } from "../LecturePageV3/Icons";
import { useNextContentCtaText } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

export const SurveyGnb = ({
  courseTitle,
  weekTitle,
  onClickBack,
  onClickNext,
  ctaText,
  isClickable,
}) => {
  return (
    <>
      <S.GnbMobileWrapper>
        <div onClick={onClickBack} style={{ cursor: "pointer" }}>
          <ArrowChevronLeft size={24} />
        </div>
        <S.GnbTitleContainer>
          <S.GnbCourseTitle>{courseTitle}</S.GnbCourseTitle>
          <S.GnbWeekTitle>{weekTitle}</S.GnbWeekTitle>
        </S.GnbTitleContainer>
        <S.GnbEmpty />
      </S.GnbMobileWrapper>
      <S.GnbDesktopWrapper>
        <S.GnbDesktopLeftContainer>
          <div onClick={onClickBack} style={{ cursor: "pointer" }}>
            <BackIconSvg />
          </div>
          <S.GnbDesktopTitleContainer>
            <S.GnbDesktopCourseTitle>{courseTitle}</S.GnbDesktopCourseTitle>
            <S.GnbDesktopDivider />
            <S.GnbDesktopWeekTitle>{weekTitle}</S.GnbDesktopWeekTitle>
          </S.GnbDesktopTitleContainer>
        </S.GnbDesktopLeftContainer>
        <DesktopNextBtn
          text={ctaText}
          isActive={true}
          onClick={onClickNext}
          isClickable={isClickable}
        />
      </S.GnbDesktopWrapper>
    </>
  );
};
