import { ActionMessage, neutralDay } from "@teamsparta/design-system";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions, roundsActions } from "../../../../_state/actions";
import { sendLog } from "../../../../businesslogics/logging";
import { EnterCourseButton } from "../../EnterCourseButton/EnterCourseButton";
import { useEnterCourse } from "../../_hooks/useEnterCourse";
import styles from "./ClassroomCard.module.scss";
import { ClassroomCardSummary } from "./ClassroomCardSummary";
import { SubText } from "./ClassroomCard.style";

const cx = classNames.bind(styles);

function ClassroomCard({ user, isExpired, classroomCard, enrolled }) {
  const [thumbSrc, setThumbSrc] = useState("");

  const {
    enrolled: { _id: enrolled_id },
    course: { title, fastqna_policy },
    extension_course,
    round: {
      round: round_title,
      _id: round_id,
      is_forever: round_forever,
      is_b2b,
      is_gov_intro: isGovIntro,
      is_gov_credit: isGovCredit,
    },
    end_day: endDay,
    progress_rate,
    maker_progress_rate,
    remaining_days,
    is_revision_period,
    is_maker_period,
    qna_url,
    start_date,
    is_started,
    classroom_thumbnail,
    is_target_retry,
    is_kept_forever,
    cut_off_line,
    is_keepable,
  } = classroomCard;

  const { text, subText, onEnter, modalData, isModalOpen } = useEnterCourse({
    isStarted: is_started,
    isGovCredit: isGovCredit,
    progressRate: progress_rate,
    isEnded: isExpired,
    enrolledId: enrolled_id,
    roundId: round_id,
    startDate: new Date(start_date),
    extensionCourse: extension_course,
    isKeepable: is_keepable,
    cutOffLine: cut_off_line,
    endDay: new Date(endDay),
    isTargetRetry: is_target_retry,
    user,
    isKeptForever: is_kept_forever,
  });

  const determineThumbSrc = async () => {
    const basicURL = `https://video.spartacodingclub.kr/classroom/thumb/${classroom_thumbnail}.png`;
    const basicRes = await fetch(basicURL);

    if (basicRes.status < 400) {
      setThumbSrc(basicURL);
      return;
    }

    if (title === "앱개발 종합반" && round_title.includes("기")) {
      let round_num = parseInt(round_title.split("기")[0]);
      if (round_num > 88) {
        const appDevURL = `https://video.spartacodingclub.kr/classroom/thumb/${classroom_thumbnail}_v2.png`;
        const appDevRes = await fetch(appDevURL);
        if (appDevRes.status < 400) {
          setThumbSrc(appDevURL);
          return;
        }
      }
    }

    if (title === "앱개발 종합반" && round_title.includes("복습강의")) {
      const appDevReviewURL = `https://video.spartacodingclub.kr/classroom/thumb/${classroom_thumbnail}_v2.png`;
      const appDevReviewRes = await fetch(appDevReviewURL);
      if (appDevReviewRes.status < 400) {
        setThumbSrc(appDevReviewURL);
        return;
      }
    }
    // local asset
    if (title === "앱개발 종합반" && round_title.includes("기")) {
      let round_num = parseInt(round_title.split("기")[0]);
      if (round_num > 88) {
        setThumbSrc(`/asset/images/classroom/${classroom_thumbnail}_v2.png`);
        return;
      }
    } else if (title === "앱개발 종합반" && round_title.includes("복습강의")) {
      setThumbSrc(`/asset/images/classroom/${classroom_thumbnail}_v2.png`);
      return;
    }

    if (title === "AWS") {
      if (round_id >= "639986813bc0dca7d610647b") {
        setThumbSrc(`/asset/images/classroom/${classroom_thumbnail}_v2.png`);
        return;
      }
    }

    if (title === "파이썬 장고 실무 심화") {
      if (round_id >= "630b3f9caf63a223bd9b61c6") {
        setThumbSrc(`/asset/images/classroom/${classroom_thumbnail}_v2.png`);
        return;
      }
    }

    if (title === "도커 실무") {
      if (round_id >= "639986813bc0dca7d610649c") {
        setThumbSrc(`/asset/images/classroom/${classroom_thumbnail}_v2.png`);
        return;
      }
    }

    setThumbSrc(`/assets/images/classroom/${classroom_thumbnail}.png`);
  };

  useEffect(() => {
    if (classroom_thumbnail) {
      determineThumbSrc();
    }
  }, [classroom_thumbnail]);

  return (
    <>
      <div className={cx("card-wrap")}>
        <div className={cx("course-thumb")}>
          {thumbSrc && <img alt={"classroom_thumb"} src={thumbSrc} />}
        </div>
        <div className={cx("course-info")}>
          <div className={cx("course-title")}>
            <span className={cx("course-title-info")}>
              {!isGovIntro ? (
                <span>
                  {" "}
                  {title} {round_title}
                </span>
              ) : (
                "스파르타 강의 0원으로 수강하기"
              )}
            </span>
          </div>
          <ClassroomCardSummary
            is_target_retry={is_target_retry}
            is_maker_period={is_maker_period}
            round_forever={round_forever}
            is_revision_period={is_revision_period}
            isGovIntro={isGovIntro}
            isExpired={isExpired}
            remaining_days={remaining_days}
            maker_progress_rate={maker_progress_rate}
            progress_rate={progress_rate}
            round_title={round_title}
            subText={subText}
          />
          {subText && <SubText>{subText}</SubText>}
        </div>
        <div className={cx("course-action")}>
          {fastqna_policy && (
            <div
              className={cx("enter-qa")}
              onClick={() => {
                sendLog(
                  "scc_classroomPage_click_button",
                  {
                    ...enrolled.data,
                    button_text: is_b2b ? "문의하기" : "학습 질문",
                    next_page: is_b2b ? "b2bPage" : "communityPage",
                    details: "",
                  },
                  ""
                );
                window.open(qna_url);
              }}
            >
              <ActionMessage
                size={24}
                style={"line"}
                color={`${neutralDay.gray80}`}
              />
            </div>
          )}
          <EnterCourseButton
            text={text}
            onEnter={onEnter}
            isModalOpen={isModalOpen}
            modalData={modalData}
          />
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, environment, enrolled } = state;
  const { user } = authentication;
  return {
    environment,
    authentication,
    user,
    enrolled,
  };
}

const connectedClassroomCard = connect(mapStateToProps, {
  setRound: roundsActions.setRound,
  setEnrolled: enrolledActions.setEnrolled,
})(ClassroomCard);
export { connectedClassroomCard as ClassroomCard };
