import * as S from "../../../molecules/HomeworkV3/HomeworkQuestion/HomeworkQuestion.style";
import React, { useEffect, useState } from "react";
import { QuestionAndAnswerWrapper } from "../../HomeworkV3/QuestionAndAnswer/QuestionAndAnswer.style";
import { EvaluationAnswer } from "../../../molecules/Evaluation/EvaluationAnswers/EvaluationAnswer";
import { useMyEvaluations } from "../../../../businesslogics/OrderedEnrollmentContents/repository/useMyEvaluations";
import { useParams } from "react-router-dom";
import { EvaluationResponse } from "../../../molecules/Evaluation/EvaluationResponse/EvaluationResponse";
import { PAGE_TYPE } from "../../../../_state/constants/evaluation.constants";
import { useAtom } from "jotai";
import { enrolledEvaluationDetailsAtom } from "../../../../stores/enrolledEvaluationDetails";
import { useGetEvaluationPageType } from "../../../../hooks/evaluation/useEvaluationData";
const QuestionComponent = ({ questionImage, questionDescription }) => {
  return (
    <>
      {(questionImage || questionDescription) && (
        <S.QuestionDescription>
          {questionImage && (
            <S.QuestionImageContainer>
              <S.QuestionImage src={questionImage} />
            </S.QuestionImageContainer>
          )}
          {questionDescription && (
            <S.QuestionDescriptionText>
              {questionDescription}
            </S.QuestionDescriptionText>
          )}
        </S.QuestionDescription>
      )}
    </>
  );
};
export const EvaluationDetails = () => {
  const { enrolled_evaluation_id } = useParams();
  const { data: evaluations } = useMyEvaluations(enrolled_evaluation_id);
  const [answers, setAnswers] = useState([]);
  const [, setEnrolledEvaluationDetail] = useAtom(
    enrolledEvaluationDetailsAtom
  );
  const pageType = useGetEvaluationPageType();

  useEffect(() => {
    if (evaluations) {
      const initialAnswers = evaluations.questionData.map((question) => ({
        evaluationsDetailId: question.evaluationsDetailId,
        submittedAnswer: "",
      }));
      setAnswers(initialAnswers);
    }
  }, [evaluations]);

  const handleSetAnswer = (index, answer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].submittedAnswer = answer;
    setAnswers(updatedAnswers);
    setEnrolledEvaluationDetail(updatedAnswers);
  };

  return (
    <React.Fragment>
      {evaluations?.questionData?.map((questionData, index) => {
        // 현재 기획 상 image는 문제당 하나로 이루어져 있음. 추후에 여러 이미지가 생긴다면 로직 변경 필요
        const questionImage = questionData?.questionDetails?.imageUrls[0];
        return (
          <QuestionAndAnswerWrapper key={index}>
            <S.HomeworkQuestionContainer>
              <S.QuestionTag>문제 {index + 1}</S.QuestionTag>
              <S.QuestionTitle>
                {questionData.questionDetails.title} (
                {questionData.correctScore}점)
              </S.QuestionTitle>
              <QuestionComponent
                questionImage={questionImage}
                questionDescription={questionData.questionDetails.description}
              />
            </S.HomeworkQuestionContainer>
            {pageType === PAGE_TYPE.TEST_PAGE && (
              <EvaluationResponse
                questionData={questionData}
                answer={answers[index]?.submittedAnswer}
                setAnswer={(answer) => handleSetAnswer(index, answer)}
              />
            )}
            {pageType === PAGE_TYPE.RESULT_PAGE && (
              <>
                <EvaluationAnswer questionData={questionData} />
              </>
            )}
          </QuestionAndAnswerWrapper>
        );
      })}
    </React.Fragment>
  );
};
