import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RoadmapBasicComponent } from "../RoadmapBasicComponent";
import classNames from "classnames/bind";
import styles from "./RoadmapDetail.module.scss";
import { badgeActions, enrolledActions } from "../../../_state/actions";
import { RoadmapRank } from "../RoadmapRank";
import { getISODateAsKorean, getRemainDays } from "../RoundWeek/utils";
import { govAction } from "../../../_state/actions/gov.actions";
import { RoadmapDuration } from "../RoadmapDuration";
import { CurrentlyEnrolled } from "../CurrentlyEnrolled/CurrentlyEnrolled";
import { ProgressEncourage } from "../../../components/organisms/ProgressEncourage/ProgressEncourage";
import * as S from "./RoadmapDetail.style";
import { MissionAndTip } from "../../../components/molecules/RoundWeek/MissionAndTip";
import { KDCHomeworkNoticeMessage } from "../../HomeworkPage/KDCHomeworkNoticeMessage/KDCHomeworkNoticeMessage";

const cx = classNames.bind(styles);

function RoadmapDetail({
  round,
  enrolled,
  enrolledPlaytime,
  enrolledPeriod,
  enrolledProgress,
  badgesRound,
  enrolledBadges,
  expired,
  govRoundStartSoon,
  govCompletionMilestone,

  getEnrolledPlaytime,
  getEnrolledProgress,
  getEnrolledPeriod,
  getBadgesForRound,
  getEnrolledBadges,
  getGovRoundStartSoon,
}) {
  const [isKRISS, setIsKRISS] = useState(false);
  const [roundStartDate, setRoundStartDate] = useState(new Date());
  const [roundEndDate, setRoundEndDate] = useState(new Date());

  useEffect(() => {
    const { enrolled_id } = enrolled.data;
    getEnrolledPlaytime(enrolled_id);
    getEnrolledProgress(enrolled_id);
    getEnrolledPeriod(enrolled_id);
    getEnrolledBadges(enrolled_id);

    const { _id: round_id } = round.data;
    getBadgesForRound(round_id);
  }, [enrolled]);

  useEffect(() => {
    if (!round.loaded) {
      return;
    }
    if (round.data.is_gov_intro) {
      getGovRoundStartSoon("credit");
    }
    setIsKRISS(round.data.round.includes("KRISS"));

    setRoundStartDate(getISODateAsKorean(round.data.start_date, false));
    setRoundEndDate(getISODateAsKorean(round.data.end_date, true));
  }, [round.loaded]);

  const rankVisible =
    round.data.has_rank_system !== false &&
    !round.data.is_gov_intro &&
    !enrolled.data.courses.is_trial;
  const isTrial = enrolled.data.courses.is_trial;
  const isGovCredit = round.data.is_gov_credit;
  const isFreeTip =
    enrolled.data.course_id !== "606bfc0fc11b70df6da82da5" &&
    enrolled.data.course_id !== "5feffb89f85b7ed4b4bdc7bd";
  const progressRate = enrolled.data.progress_rate;
  const remainDays = getRemainDays(round.data.end_date);
  const remainWeekDays = `${Math.floor(remainDays / 7)}주 ${Math.floor(
    remainDays % 7
  )}일`;

  return (
    <div className={cx("roadmap-details")}>
      {round.loaded && round.data.is_gov && !isGovCredit && (
        <S.Duration>
          <S.Title>수강기간</S.Title>
          <S.DurationText>{`${roundEndDate} 23:59까지`}</S.DurationText>
        </S.Duration>
      )}
      {round.loaded && isFreeTip && !isTrial && (
        <MissionAndTip
          enrolled={enrolled}
          course_id={enrolled.data.course_id}
          isGovCredit={isGovCredit}
          isFreeTip={isFreeTip}
        />
      )}
      {round.loaded && isGovCredit && (
        <>
          <S.ProgressContainer>
            <S.DurationContainer>
              <S.Title>수강 기간</S.Title>
              <S.DurationText>{`${roundEndDate} 23:59까지`}</S.DurationText>
            </S.DurationContainer>
            <S.RemainProgressContainer>
              <S.RemainProgressBox>
                <S.SubTitle>이번주 공부 시간</S.SubTitle>
                <S.RemainDays days={remainDays}>
                  {enrolledPlaytime?.data?.week_playtime} 분
                </S.RemainDays>
              </S.RemainProgressBox>
              <S.RemainProgressBox>
                <S.SubTitle>나의 진도율</S.SubTitle>
                <S.MainTitle>{enrolledProgress.data}%</S.MainTitle>
              </S.RemainProgressBox>
            </S.RemainProgressContainer>
          </S.ProgressContainer>
        </>
      )}
      {round.data.is_gov_intro && govRoundStartSoon.loaded && (
        <div className={cx("roadmap-details__el", "--bg")}>
          <div className={cx("roadmap-details__el__header")}>등록 기수</div>
          <div className={cx("roadmap-details__el__content", "--flex-column")}>
            <RoadmapBasicComponent
              title={""}
              type={"plain"}
              data={{
                content: (
                  <>
                    <span style={{ color: "#e8344e" }}>
                      합류 예정일{" "}
                      {getISODateAsKorean(govRoundStartSoon.data.start_date)}{" "}
                      개강반
                    </span>
                    <br />
                    <br />
                    <small>
                      *본 내일배움단 합류 강의는 "최종합류" 후,
                      <br /> &nbsp;내 강의실에서 사라집니다!
                    </small>
                  </>
                ),
              }}
            />
          </div>
          <div className={cx("roadmap-details__el__header")}></div>
        </div>
      )}

      <div
        className={cx(
          "roadmap-details__el",
          `${
            round.loaded &&
            (round.data.is_forever || round.data.round.includes("항해"))
              ? ""
              : "--first"
          }`
        )}
      >
        {/* {govCompletionMilestone.loaded && (
          <div className={cx("roadmap-details__el", "--bg")}>
            <div className={cx("roadmap-details__el__header")}>수료기준</div>
            <div className={cx("roadmap-details__el__middle")}>
              {govCompletionMilestone.data.context.week} 주차{" "}
              {govCompletionMilestone.data.context.week_order} 강 (진도율 80%)
            </div>
            <div className={cx("roadmap-details__el__small")}>
              현재 나는 {enrolledProgress.data}% 달리는 중
            </div>
            <div className={cx("roadmap-details__el__header")}></div>
          </div>
        )} */}
        {isGovCredit && <ProgressEncourage progressRate={progressRate} />}
        {rankVisible && enrolledProgress.loaded && !isKRISS && (
          <RoadmapRank progress={enrolledProgress.data} />
        )}
        {isTrial && (
          <>
            <RoadmapDuration
              start_date={enrolled.data.start_date}
              end_date={enrolled.data.end_date}
            />
            <CurrentlyEnrolled />
          </>
        )}
      </div>
      {round.loaded &&
        ((!round.data.is_gov_intro && round.data.is_forever) ||
          round.data.round.includes("항해")) && (
          <div className={cx("roadmap-details__el", "--bg")}>
            {enrolledPlaytime.loaded && (
              <>
                <div className={cx("roadmap-details__el__header")}>시간</div>
                <div className={cx("roadmap-details__el__content")}>
                  <RoadmapBasicComponent
                    title={"총 누적시간"}
                    type={"percent"}
                    data={{
                      percent: enrolledPlaytime.data.total_playtime,
                      units: { percent: "분" },
                    }}
                  />
                  <div
                    className={cx("roadmap-details__el__content__hr-vertical")}
                  />
                  <RoadmapBasicComponent
                    title={"이번 주 공부시간"}
                    type={"percent"}
                    data={{
                      percent: enrolledPlaytime.data.week_playtime,
                      units: { percent: "분" },
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    enrolled,
    enrolledPlaytime,
    enrolledPeriod,
    enrolledProgress,
    round,
    badgesRound,
    enrolledBadges,
    expired,
    govRoundStartSoon,
    govCompletionMilestone,
  } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    enrolledPlaytime,
    enrolledPeriod,
    enrolledProgress,
    round,
    badgesRound,
    enrolledBadges,
    expired,
    govRoundStartSoon,
    govCompletionMilestone,
  };
}

const connectedRoadmapDetail = connect(mapStateToProps, {
  getEnrolledPlaytime: enrolledActions.getEnrolledPlaytime,
  getEnrolledProgress: enrolledActions.getEnrolledProgress,
  getEnrolledPeriod: enrolledActions.getEnrolledPeriod,
  getBadgesForRound: badgeActions.getBadgesForRound,
  getEnrolledBadges: enrolledActions.getEnrolledBadges,
  getGovRoundStartSoon: govAction.getGovRoundStartSoon,
})(RoadmapDetail);
export { connectedRoadmapDetail as RoadmapDetail };
