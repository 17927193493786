import * as S from "./WeekHeader.style";
import {
  ClockSvg,
  DueDateSvg,
  OutlineSvg,
} from "../../../RoundWeek/RoundWeek.svg";
import { ColleagueInfo } from "./ColleagueInfo";
import { useMaterials } from "../../../../../businesslogics/OrderedEnrollmentContents/repository/useMaterials";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getISODateAsKorean,
  getISODateAsKoreanWithoutDays,
} from "../../../RoundWeek/utils";
import {
  useIsLicenseCourses,
  useSyllabusUnit,
} from "../../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

export const WeekHeader = (weekData) => {
  const course = useSelector((state) => state.course);
  const round = useSelector((state) => state.round);
  const startDate = new Date(round.data.start_date);

  const courseTitle = course.data.title;
  const { week, totalPlaytime } = weekData;

  const { enrolled_id } = useParams();
  const materials = useMaterials(enrolled_id, week);

  const endDay = getEndDate(startDate, week);
  const materialButtonClickHandler = () => {
    window.open(materials?.data[0]);
  };
  const syllabusUnit = useSyllabusUnit(course?.data?.course_id);
  const isLicenseCourse = useIsLicenseCourses(course?.data?.course_id);
  return (
    <S.HeaderContainer>
      <S.Data>
        <S.Title>{`${courseTitle} ${week}${syllabusUnit}`}</S.Title>
        <S.DetailWrapper>
          <S.Detail>
            <ClockSvg />
            <div> {secondsToHoursMinutes(totalPlaytime)}</div>
          </S.Detail>
          {!isLicenseCourse && (
            <S.Detail>
              <DueDateSvg />
              <div> {endDay}</div>
            </S.Detail>
          )}
          <S.Materials onClick={materialButtonClickHandler}>
            강의 자료
            <OutlineSvg />
          </S.Materials>
        </S.DetailWrapper>
      </S.Data>
    </S.HeaderContainer>
  );
};
const secondsToHoursMinutes = (seconds) => {
  if (seconds === 0) {
    return "0분";
  }

  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = hours > 0 ? `${hours}시간 ` : "";
  const formattedMinutes =
    remainingMinutes > 0 ? `${remainingMinutes}분` : "0분";

  return formattedHours + formattedMinutes;
};

function getEndDate(startDate, week) {
  if (week == 0) return `${getISODateAsKoreanWithoutDays(startDate)}까지`;

  let endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + week * 7);
  return getISODateAsKoreanWithoutDays(endDate);
}
