import privateInstance from "../../_helper/axios";

async function getBadgesForRound(round_id) {
  return await privateInstance
    .get(`/badges/rounds/${round_id}`)
    .then((resp) => resp.data);
}

export const badgeService = {
  getBadgesForRound,
};
