import * as S from "../HomeworkAnswer.style";
import React from "react";
import { canGoNextLectureStatusValues } from "../../../../../_state/constants";

export const TextAnswer = ({
  status,
  answer,
  setAnswer,
  onUpdateSubmittedAnswer,
}) => {
  const isPossibleToUpdate = !canGoNextLectureStatusValues.includes(status);
  return (
    <S.TextAnswerContainer
      data-testid="text-answer-input"
      value={answer}
      onChange={(e) => {
        if (isPossibleToUpdate) {
          setAnswer(e.target.value);
          onUpdateSubmittedAnswer(e.target.value);
        } else {
          alert("검토가 완료된 숙제는 수정할 수 없습니다.");
        }
      }}
      placeholder={"숙제 답안을 여기에 작성해 주세요."}
      padding={10}
      tabSize={4}
    />
  );
};
