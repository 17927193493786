import styled from "@emotion/styled";
import { Device } from "../../../../_styles/device";
import {
  neutralDay,
  mBody2,
  mHeader1,
  mTitle2,
  scc,
  wBody1,
  wBody2,
  wHeader2,
} from "@teamsparta/design-system";

export const Wrapper = styled.div`
  background: ${neutralDay.gray0};
  padding: 60px 16px;
  ${Device.Desktop} {
    padding: 100px 16px;
  }
`;

export const Container = styled.div`
  overflow: visible;
  margin: auto;
  ${Device.Desktop} {
    width: 528px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.div`
  ${mHeader1};
  color: ${neutralDay.gray100};
  margin-bottom: 12px;
  ${Device.Desktop} {
    ${wHeader2};
    color: ${neutralDay.gray100};
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Desc = styled.div`
  ${mBody2};
  color: ${neutralDay.gray80};
  margin-bottom: 40px;
  ${Device.Desktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
    text-align: center;
    margin-bottom: 60px;
  }
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;
  ${Device.Desktop} {
    gap: 60px;
    margin-bottom: 60px;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${Device.Desktop} {
    gap: 40px;
  }
`;

export const Cta = styled.div`
  width: 100%;
  height: 54px;
  background: ${scc.red100};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mTitle2};
  color: ${neutralDay.white};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
  cursor: ${({ isActive }) => (isActive ? "pointer" : "default")};

  ${Device.Desktop} {
    ${wBody1};
    color: ${neutralDay.white};
    max-width: 528px;
  }
`;

export const FloatingWrapper = styled.div`
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity 0.1s;
  z-index: ${({ isShow }) => (isShow ? 1 : -1)};
  position: fixed;
  padding: 10px 16px;
  width: 100vw;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
  display: flex;
  justify-content: center;
`;
