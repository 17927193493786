import classNames from "classnames/bind";
import styles from "./CheckinPage.module.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { checkinActions } from "../../_actions";
import {
  getApiCheckinInfoUrl,
  getApiCheckinLikeUrl,
  getApiCheckinSkipUrl,
  getGETAuthOptions,
  getPostAuthOptions,
} from "../../_urls";
import { CheckinRead } from "../../_components/CheckinRead";
import { history } from "../../_helpers";
import { message } from "antd";

const cx = classNames.bind(styles);

class CheckinPage extends Component {
  state = {
    // 읽기
    offset: 1,
    num: NUM,
    checkins: [],
    lecture: null,
    points: { checkin_done: 200 },
    tip: "",
    round_id: this.props.match.params.round_id,
    week: parseInt(this.props.match.params.week),
    week_order: parseInt(this.props.match.params.week_order),

    // 쓰기
    comment: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const rjson = this._getCheckins();
    rjson.then((result) => {
      if (result.ok) {
        const { checkins, lecture, points, tip } = result;
        this.setState({
          checkins,
          lecture,
          points,
          tip,
        });
      }
    });
  }

  _showSuccessMessage = () => {
    const { points } = this.state;
    if (points === undefined || points.checkin_done === undefined) {
      return;
    }
    message.success({
      content: `+${points.checkin_done} 포인트 적립 완료!`,
      className: "custom-class",
      style: {
        position: "absolute",
        top: "7px",
        right: "40px",
      },
    });
  };

  handleScroll = (e) => {
    if (document.getElementById("checkinList") === null) {
      return;
    }
    let offsetHeight = document.getElementById("checkinList").offsetHeight;
    let loadTrigger =
      offsetHeight - (e.target.body.offsetHeight + window.pageYOffset);
    if (loadTrigger < 30) {
      this._getMoreCheckins();
    }
  };

  _getCheckins = async () => {
    const { round_id, offset, num } = this.state;
    const endpoint = getApiCheckinInfoUrl(round_id, offset, num);
    const options = getGETAuthOptions();
    try {
      const { offset } = this.state;
      this.setState({
        offset: offset + 1,
      });
      const response = await fetch(endpoint, options);
      return response.json();
    } catch (e) {
      alert("조회에 실패하였습니다.\n잠시 후 다시 시도해주세요.");
    }
  };

  _createCheckin = async () => {
    const { round_id, comment, week, week_order } = this.state;
    const { isHomework } = this.state.lecture;
    const data = { comment, week, week_order, isHomework };
    const endpoint = getApiCheckinInfoUrl(round_id);
    const options = getPostAuthOptions(data);
    try {
      const response = await fetch(endpoint, options);
      return response.json();
    } catch (e) {
      alert("기록에 실패했습니다.\n잠시 후 다시 시도해주세요.");
    }
  };

  _likeCheckin = async (checkin_id) => {
    const { round_id } = this.state;
    const endpoint = getApiCheckinLikeUrl(round_id, checkin_id);
    const options = getPostAuthOptions({});
    try {
      const response = await fetch(endpoint, options);
      return response.json();
    } catch (e) {
      alert("좋아요 누르기에 실패했습니다.\n잠시 후 다시 시도해주세요.");
    }
  };

  _createComment = () => {
    const { comment } = this.state;
    if (comment === null) {
      alert("내용을 입력해주세요.");
      document.getElementById("comment").focus();
      return;
    }

    const rjson = this._createCheckin();
    rjson.then((result) => {
      if (result.ok) {
        const { lecture, round_id } = this.state;
        const { _id, isHomework } = lecture;
        if (result.point_possible) {
          this._showSuccessMessage();
        }
        if (isHomework) {
          history.push(`/rounds/${round_id}/homeworks/${_id}`);
        } else {
          history.push(`/rounds/${round_id}/lectures/${_id}`);
        }
      }
    });
  };

  _logSkip = async () => {
    const { round_id, week, week_order } = this.state;
    const { isHomework } = this.state.lecture;
    const data = { week, week_order, isHomework };
    const endpoint = getApiCheckinSkipUrl(round_id);
    const options = getPostAuthOptions(data);
    try {
      const response = await fetch(endpoint, options);
      return response.json();
    } catch (e) {
      alert("기록에 실패했습니다.\n잠시 후 다시 시도해주세요.");
    }
  };

  _skipToLecture = () => {
    const rjson = this._logSkip();
    rjson.then((result) => {
      if (result.ok) {
        const { lecture, round_id } = this.state;
        const { _id, isHomework } = lecture;

        if (isHomework) {
          history.push(`/rounds/${round_id}/homeworks/${_id}`);
        } else {
          history.push(`/rounds/${round_id}/lectures/${_id}`);
        }
      }
    });
  };

  _getMoreCheckins = () => {
    const rjson = this._getCheckins();
    const checkins_before = this.state.checkins;
    rjson.then((result) => {
      if (result.ok) {
        const { checkins } = result;
        this.setState({
          checkins: [...checkins_before, ...checkins],
        });
      }
    });
  };

  _handleInputChange = () => {
    let comment = document.getElementById("comment").value;
    this.setState({
      comment,
    });
  };

  _handleLike = (_id) => {
    const rjson = this._likeCheckin(_id);
    rjson.then((result) => {
      if (result.ok) {
        let { checkin } = result;
        let { checkins } = this.state;
        for (let i = 0; i < checkins.length; i++) {
          if (checkins[i]._id === _id) {
            checkins[i].likes = checkin.likes;
            checkins[i].liked = true;
          }
        }
        this.setState({
          checkins: [...checkins],
        });
      } else {
        alert(result.reason);
      }
    });
  };

  render() {
    const isDesktop = window.innerWidth >= 1240;
    const { checkins, points, tip } = this.state;
    return (
      <div className={cx("checkinPageContainer")}>
        <div className={cx("checkinPageContentsContainer")}>
          {/*고정영역*/}
          <div className={cx("checkinFixedContainer")}>
            <div className={cx("checkinLeftContainer")}>
              {/* 체크인 create */}
              <div className={cx("checkinCreateContainer")}>
                {/* 체크인 create Header */}
                <div className={cx("checkinHeaderWrapper")}>
                  <h2>오늘의 다짐 남기기🖋</h2>
                  <img
                    src={"/assets/images/checkin/checkin.png"}
                    alt="checkin imagge"
                  />
                  {/*<h6><CaretRightOutlined style={{verticalAlign: "-0.125em"}}/>&nbsp;체크인이 무엇인가요?</h6>*/}
                  <div className={cx("desc")}>
                    공부를 시작하기 전, 나의 감정 상태와 다짐을 간략히
                    적어볼까요?
                    <br />
                    {/*<span style={{textDecoration: 'underline', fontWeight: 'bold'}}>5주 후에 내가 남긴 글들을 모아서 이메일로 보내드려요</span>😊*/}
                  </div>
                </div>

                {/* 체크인 입력*/}
                <div className={cx("checkinInputWrapper")}>
                  <textarea
                    onChange={this._handleInputChange}
                    id="comment"
                    cols="30"
                    placeholder="ex. 와 1주차 시작인데 정말 기대돼요. 이번 주에 2주차까지 끝내는게 목표입니다. 오늘 빡세게 코딩하고 즐거운 마음으로 마라탕 먹으러 가려구요."
                  />
                </div>

                {/* 체크인 올리기, skip 버튼 */}
                <div className={cx("checkinButtonWrapper")}>
                  <button onClick={this._createComment}>
                    남기고 강의 들으러 가기
                  </button>

                  <div
                    onClick={this._skipToLecture}
                    className={cx("skipWrapper")}
                  >
                    오늘은 넘어갈래요
                  </div>

                  {/* 오늘의 tip*/}
                  {isDesktop && (
                    <div className={cx("checkinTipsWrapper")}>
                      <div className={cx("calloutIconWrapper")}>💡</div>
                      <div className={cx("calloutContentWrapper")}>{tip}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* 포인트 */}
              {/*<div className={cx('goldinfoContainer')}>*/}
              {/*    {points.checkin_done} 포인트*/}
              {/*</div>*/}
            </div>
          </div>

          {/* 체크인 read */}
          <div className={cx("checkinRightContainer")}>
            <div className={cx("checkinReadHeader")}>
              <h4>지금 함께 달리는 동료들의 다짐🔥</h4>
            </div>
          </div>

          <div className={cx("checkinReadContainer")}>
            <div className={cx("cover")}></div>
            <CheckinRead checkins={checkins} _handleLike={this._handleLike} />
            {/*<button onClick={this._getMoreCheckins}>더 가져오기</button>*/}
          </div>
        </div>
      </div>
    );
  }
}

const NUM = 20;

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedCheckinPage = connect(mapStateToProps)(CheckinPage);
export { connectedCheckinPage as CheckinPage };
