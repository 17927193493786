import { dashboardConstants } from "../_constants";

const initState = {
  loaded: false,
  data: [],
};

export function dashboard(state = initState, action) {
  switch (action.type) {
    case dashboardConstants.DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case dashboardConstants.DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case dashboardConstants.DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
