import { PrequestionConstant } from "../constants";

const initState = {
  loaded: false,
};

export function isPrequestionExist(state = initState, action) {
  switch (action.type) {
    case PrequestionConstant.GET_PREQUESTION_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PrequestionConstant.GET_PREQUESTION_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PrequestionConstant.GET_PREQUESTION_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function prequestionOfEnrolled(state = initState, action) {
  switch (action.type) {
    case PrequestionConstant.PREQUESTION_OF_ENROLLED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case PrequestionConstant.PREQUESTION_OF_ENROLLED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case PrequestionConstant.PREQUESTION_OF_ENROLLED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
