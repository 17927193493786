import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./RoadmapPage.module.scss";
import { connect } from "react-redux";
import { roundsActions } from "../../_state/actions/rounds.actions";
import {
  abtestActions,
  enrolledActions,
  UserRaceGroupActions,
  usersActions,
} from "../../_state/actions";
import { RoadmapDashboard } from "./RoadmapDashboard";
import { RoadmapHeader } from "./RoadmapHeader";
import { RoadmapDetail } from "./RoadmapDetail";
import { RoadmapBanner } from "./RoadmapBanner";
import { RoadmapAsideBanner } from "./RoadmapAsideBanner";
import { govAction } from "../../_state/actions/gov.actions";
import { createEventId, history } from "../../../_helpers";
import { RoadmapMonsterDashboard } from "./RoadmapMonsterDashboard";
import { RoadmapMakerDashboard } from "./RoadmapMakerDashboard";
import { govActions } from "../../../_actions/gov.actions";
import { CreditIntroPage } from "./Credit";
import { sendLog } from "../../businesslogics/logging";
import * as RoadmapAside from "../../../_components/_molecules/RoadmapAside/RoadmapAsideComponent";
import { Gnb } from "../../components/organisms/_layout/gnb/Gnb";
import { useOnlineTheme } from "../../queries/pirates";
import { RoadmapDuration } from "./RoadmapDuration";
import { CurrentlyEnrolled } from "./CurrentlyEnrolled/CurrentlyEnrolled";
import { getCookie, setCookie } from "../../_helper";
import { modalData } from "../../businesslogics/Roadmap/modalData";
import GptModal from "./GptModal/GptModal";
import { marketingModalCourseIds } from "../../utils/marketingModalCourseIds";
import { SectionTitle } from "../../components/molecules/RoundWeek/LastSeenLecture.style";
import { LastSeenLecture } from "../../components/molecules/RoundWeek/LastSeenLecture";
import { Syllabus } from "./Syllabus";
import { useIsCodingRaceShow } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { VerificationModal } from "../../components/organisms/Modal/VerificationModal/VerificationModal";
import { useGetRoundWeeks } from "../../queries/roadmap";
import { useParams } from "react-router-dom";
import { RoadmapRecommendBanner } from "../../components/RoadmapRecommend/RoadmapRecommend";
import { MessageManager } from "@teamsparta/chat-ui";

const cx = classNames.bind(styles);

function RoadmapPage({
  environment,
  user,
  match,
  round,
  enrolled,
  expired,
  enrolledMaker,
  setRound,
  setEnrolled,
  getABtestByEnrolled,
  getExpired,
  getEnrolledDoneCount,
  getWeeksAfterStart,
  getIsGovEnrolledBlocked,
  getMaker,
  getGovCompletionMilestone,
  activeEnrolleds,
  getActiveEnrolleds,
  marketingAgreement,
  getMarketingAgreement,
  setMarketingAgreement,
}) {
  const [roundType, setRoundType] = useState("inital");
  const { enrolled_id, round_id } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [isTrial, setIsTrial] = useState(null);
  const theme = useOnlineTheme();
  const [firstEnrolledDetailId, setFirstEnrolledDetailId] = useState(undefined);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalDataInfo, setModalDataInfo] = useState(undefined);
  const { data: roundWeeks } = useGetRoundWeeks(enrolled_id);
  const messageManager = MessageManager.getInstance();
  /**TODO: networkSpeed url 유실로 인한 주석처리*/
  // useStoreNetworkSpeed();
  useEffect(() => {
    if (enrolled_id) {
      messageManager.cleanMessage();
    }
  }, [enrolled_id]);
  useEffect(() => {
    let copyModalData = { ...modalData };
    copyModalData.title = `${user.name}${copyModalData.title}`;
    setModalDataInfo(copyModalData);
    if (!marketingAgreement.loaded) {
      getMarketingAgreement();
    }
  }, []);

  useEffect(() => {
    if (!round.loaded) {
      return;
    }
    if (round.data.is_gov_intro) {
      setRoundType("creditIntro");
    } else if (round.data.is_gov_credit) {
      getGovCompletionMilestone(round.data.course_id);
      setRoundType("normal");
    } else {
      setRoundType("normal");
    }
  }, [round.loaded]);

  useEffect(() => {
    if (roundWeeks?.ok && round.loaded && marketingAgreement.loaded) {
      setIsShowModal(
        marketingModalCourseIds.includes(round.data.course_id) &&
          !getCookie("isGptModalShow") &&
          !marketingAgreement.data.marketingAgreement
      );
      setFirstEnrolledDetailId(
        roundWeeks.weeks[0].enrolled_details[0].enrolled_detail_id
      );
    }
  }, [roundWeeks?.ok, round.loaded, marketingAgreement.loaded]);

  useEffect(() => {
    setIsMobile(environment.deviceType === "mobile");
    setRound(round_id);
    setEnrolled(enrolled_id);
    getExpired(enrolled_id);
    getABtestByEnrolled(enrolled_id);
    getWeeksAfterStart(enrolled_id);
    getMaker(enrolled_id);

    // 오늘 완료한 강의+숙제 개수 불러온다. 정부일 경우 9개 이상 불가.
    getEnrolledDoneCount(enrolled_id);
  }, [match.params.enrolled_id]);

  useEffect(() => {
    if (!activeEnrolleds.loaded) {
      getActiveEnrolleds();
    }
  }, []);

  // 정부 과목 && 개강일 이전인데 접속한 경우 내강의실로 redirect
  useEffect(() => {
    getIsGovEnrolledBlocked(enrolled_id).then((resp) => {
      if (resp.ok && resp.context) {
        alert("개강일 이전이므로 수강하실 수 없습니다.");
        history.push("/classroom");
        return;
      }
    });
  }, [enrolled_id]);

  useEffect(() => {
    if (enrolled.loaded && !expired.loaded) {
      getExpired(enrolled.data.enrolled_id);
    }
  }, [enrolled]);

  useEffect(() => {
    if (enrolled.loaded) {
      sendLog("scc_roadmapPage_view", enrolled.data, createEventId());
      setIsTrial(enrolled.data.courses.is_trial);
    }
  }, [enrolled.loaded]);

  function handleModalClose(isAgree) {
    setCookie("isGptModalShow", "true", 99);
    if (isAgree === false) {
      setCookie("isNeededSecondModal", "true", 99);
    }
  }

  const hasProgressSystem = round?.data?.has_progress_promote_system !== false;
  const hasTestSystem = enrolled.data?.courses.is_b2g2b;
  const hasNeedToTest = hasTestSystem && enrolled.data?.progress_rate >= 80;
  const isCodingRaceShow = useIsCodingRaceShow();
  switch (roundType) {
    case "normal":
      return (
        <div>
          <VerificationModal />
          {enrolled.loaded && round.loaded && (
            <>
              <Gnb />
              <div className={cx("roadmap-page-bg", theme.data)}>
                <div className={cx("roadmap-page", theme.data)}>
                  <div className={cx("roadmap-page__header")}>
                    <RoadmapHeader />
                    {/*진도사우르즈 배너 및 기타*/}
                    {hasProgressSystem &&
                      !round.data.is_gov_intro &&
                      !round.data.round.includes("항해") &&
                      expired.data &&
                      (expired.data.achv.week <
                      expired.data.rcmd.rcmded_week ? (
                        <RoadmapMonsterDashboard />
                      ) : enrolledMaker.loaded &&
                        enrolledMaker.data.maker_start_date ? (
                        <RoadmapMakerDashboard />
                      ) : !isCodingRaceShow ? null : (
                        <RoadmapDashboard isMobile={isMobile} />
                      ))}
                  </div>
                  {isMobile && isTrial && <CurrentlyEnrolled />}

                  <div className={cx("roadmap-page__content")}>
                    <div className={cx("roadmap-page__content__main")}>
                      {/*찐한관리 배너 및 기타*/}
                      <RoadmapBanner isMobile={isMobile} />
                      {/*내배단인 경우에만 보여주기, 완주한 경우 숨기기*/}
                      {/*{enrolled.data.progress_rate < 100 && ( //이 데이터가 100%가 아닌데 100프로로 지급되는 항해 강의가 존재..!*/}
                      <>
                        <SectionTitle>수강 중인 강의</SectionTitle>
                        <LastSeenLecture rounds={roundWeeks} />
                        <RoadmapRecommendBanner />
                        <SectionTitle>전체 강의</SectionTitle>
                      </>
                      {/*)}*/}
                      {/*<RoundWeeks />*/}
                      <Syllabus />
                    </div>
                    {!isMobile && (
                      <div>
                        <div className={cx("roadmap-page__content__sub")}>
                          {hasNeedToTest && (
                            <RoadmapAside.Wrapper
                              style={{ marginBottom: "24px" }}
                            >
                              <RoadmapAside.Title
                                style={{ marginBottom: "24px" }}
                              >
                                수료 시험
                              </RoadmapAside.Title>
                              <RoadmapAside.Cta
                                onClick={() => {
                                  history.push({
                                    pathname: "/enrolleds/test",
                                    state: { enrolled: enrolled },
                                  });
                                }}
                              >
                                시험치기
                              </RoadmapAside.Cta>
                            </RoadmapAside.Wrapper>
                          )}
                        </div>
                        <div
                          className={cx(
                            "roadmap-page__content__container",
                            enrolled.data.course_id ===
                              "5f0ae408765dae0006002816"
                              ? "--reverse"
                              : ""
                          )}
                        >
                          <div className={cx("roadmap-page__content__sub")}>
                            <RoadmapDetail />
                          </div>
                          <div className={cx("roadmap-page__content__sub")}>
                            <RoadmapAsideBanner />
                          </div>
                        </div>
                      </div>
                    )}
                    {isMobile && isTrial && (
                      <RoadmapDuration
                        start_date={enrolled.data.start_date}
                        end_date={enrolled.data.end_date}
                      />
                    )}
                  </div>
                </div>
              </div>
              {user &&
                firstEnrolledDetailId &&
                isShowModal &&
                modalDataInfo && (
                  <GptModal
                    handleVisible={setIsShowModal}
                    handleCloseModal={handleModalClose}
                    data={modalDataInfo}
                    nextUrl={`/enrolleds/${enrolled_id}/edetails/${firstEnrolledDetailId}?course_id=${round.data.course_id}`}
                    setMarketingAgreement={setMarketingAgreement}
                    sendLog={sendLog}
                  />
                )}
            </>
          )}
        </div>
      );
    case "creditIntro":
      return <CreditIntroPage match={match} />;
    default:
      return <div />;
  }
}

function mapStateToProps(state) {
  const {
    environment,
    authentication,
    round,
    enrolled,
    abtestEnrolled,
    hasDoneDailyAuth,
    isGovEnrolledBlocked,
    expired,
    enrolledMaker,
    activeEnrolleds,
    marketingAgreement,
  } = state;
  const { user } = authentication;
  return {
    environment,
    authentication,
    user,
    round,
    enrolled,
    expired,
    abtestEnrolled,
    hasDoneDailyAuth,
    isGovEnrolledBlocked,
    enrolledMaker,
    activeEnrolleds,
    marketingAgreement,
  };
}

const connectedRoadmapPage = connect(mapStateToProps, {
  setRound: roundsActions.setRound,
  setEnrolled: enrolledActions.setEnrolled,
  getExpired: enrolledActions.getExpired,
  getABtestByEnrolled: abtestActions.getABtestByEnrolled,
  getEnrolledDoneCount: enrolledActions.getEnrolledDoneCount,
  getWeeksAfterStart: UserRaceGroupActions.getWeeksAfterStart,
  getIsGovEnrolledBlocked: govAction.getIsGovEnrolledBlocked,
  getMaker: enrolledActions.getMaker,
  getGovCompletionMilestone: govActions.getGovCompletionMilestone,
  getActiveEnrolleds: enrolledActions.getActiveEnrolleds,
  getMarketingAgreement: usersActions.getMarketingAgreement,
  setMarketingAgreement: usersActions.setMarketingAgreement,
})(RoadmapPage);
export { connectedRoadmapPage as RoadmapPageV2 };
