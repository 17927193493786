import React from "react";
import { Form, Input } from "antd";

export const NameItem = ({ cx, className, isGovIntro, isHanghae, isSwc }) => {
  const changeTrim = (e) => {
    return e.target.value.trim();
  };
  return (
    <>
      <Form.Item
        label="이름"
        name="name"
        className={className}
        rules={[
          {
            required: true,
          },
        ]}
        onChange={changeTrim}
        getValueFromEvent={changeTrim}
      >
        <Input
          placeholder={
            isGovIntro
              ? "성을 포함한 실명을 입력해주세요."
              : isHanghae
              ? "김항해"
              : "김르탄"
          }
        />

      </Form.Item>
        {isSwc &&
            <span className={cx("nameInfo")} style={{ fontSize: 13 }}>
                *이름은 지원자를 식별하는 정보이므로 정확히 입력해 주세요.
            </span>}
      {isGovIntro && (
        <span>
          <br />
          *(내일배움단)최종훈련생 등록을 위해서는 반드시 실명으로 입력해주셔야
          합니다.{" "}
        </span>
      )}
    </>
  );
};
