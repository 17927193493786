import * as S from "./LectureListItem.style";
import {
  ArrowIcon,
  PlayIcon,
  PlayTimeIcon,
} from "../../LecturePage/LecLNB/Arrows/ArrowIcon";
import React, { useEffect, useState } from "react";
import { largeTimeConvertor, lectureTimeConvertor } from "../../../_helper";
import { CheckNotDoneSvg, CheckSvg, OnPlaySvg } from "../Icons";
import { useIsDesktop } from "../useInitialize";
import { useSelector } from "react-redux";
import { useOnlineTheme } from "../../../queries/pirates";
import {
  useNavigateToTargetContent,
  useSyllabusUnit,
} from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useParams } from "react-router-dom";
import { useLogLectureListClick } from "../../../hooks/homework/useLogHomeworkClick";

const InfoCard = ({ weekDetail }) => {
  const logLectureListClick = useLogLectureListClick();
  const navigateToTargetContents = useNavigateToTargetContent(
    weekDetail,
    "lectureList"
  );
  const { detail_id, enrolled_homework_id, enrolled_evaluation_id } =
    useParams();
  const currentId = detail_id || enrolled_homework_id || enrolled_evaluation_id;
  const isNowPlayingIndex = currentId === weekDetail.contentId;

  const lectureOrder =
    weekDetail.contentType === "lecture"
      ? `${weekDetail.week}-${weekDetail.order}`
      : weekDetail.contentType === "homework"
      ? "숙제"
      : weekDetail.contentType === "nps"
      ? "마무리"
      : weekDetail.contentType === "evaluation"
      ? "테스트"
      : null;
  const convertLectureOrderToWeekOrder = (lectureOrder) => {
    if (lectureOrder === "숙제") return "";
    const weekOrder = lectureOrder.split("-")[1];
    return +weekOrder;
  };
  const theme = useOnlineTheme();
  return (
    <S.InfoCard
      data-testid="lecture-list-item"
      done={weekDetail.passed}
      seen={weekDetail.passed}
      nowPlay={isNowPlayingIndex}
      onClick={async () => {
        logLectureListClick(
          lectureOrder === "숙제"
            ? "목차 리스트 숙제 이동 버튼"
            : "목차 리스트 강의 이동 버튼",
          "",
          {
            nextWeek: weekDetail?.week,
            nextWeekOrder: convertLectureOrderToWeekOrder(lectureOrder),
          }
        );
        navigateToTargetContents();
      }}
      theme={theme.data}
    >
      <S.InfoTitleContainer>
        <S.InfoOrder nowPlay={isNowPlayingIndex} theme={theme.data}>
          {lectureOrder}
        </S.InfoOrder>
        <S.InfoTitle nowPlay={isNowPlayingIndex} theme={theme.data}>
          {weekDetail.title}
        </S.InfoTitle>
      </S.InfoTitleContainer>
      <S.InfoTimeContainer>
        <S.InfoTime nowPlay={isNowPlayingIndex} theme={theme.data}>
          {weekDetail.playtime && lectureTimeConvertor(weekDetail.playtime)}
        </S.InfoTime>
        {isNowPlayingIndex ? (
          <S.InfoOnPlay>
            <OnPlaySvg theme={theme.data} />
          </S.InfoOnPlay>
        ) : weekDetail.contentType !== "nps" ? (
          <S.InfoCheck>
            {weekDetail.passed ? (
              <CheckSvg />
            ) : (
              <CheckNotDoneSvg theme={theme.data} />
            )}
          </S.InfoCheck>
        ) : null}
      </S.InfoTimeContainer>
    </S.InfoCard>
  );
};

export const LectureListItem = ({ enrolledWeek }) => {
  const [isOpen, setIsOpen] = useState(false);
  const course = useSelector((state) => state.course);
  const courseTitle = course?.data?.title;
  const logLectureListClick = useLogLectureListClick();

  const lectureLength = "총 " + enrolledWeek?.contents.length + "강";
  const syllabusUnit = useSyllabusUnit(course?.data?.course_id);
  const weekOrder = enrolledWeek?.week + syllabusUnit;
  const sumPlayTime = enrolledWeek?.totalPlaytime;
  const weekDetails = enrolledWeek?.contents;
  const nowPlayingIndex = -1;

  const isDesktop = useIsDesktop();
  // const isStarted = new Date(enrolled.data.start_date) < new Date();
  const isStarted = true;
  const isOpenPossible =
    isStarted || (!isStarted && weekDetails[0].lectureWeek === 0);

  /**
   * 현재 듣고 있는 강의가 있는 목차 펼쳐질 수 있도록*/
  const { detail_id, enrolled_homework_id } = useParams();
  const detailId = detail_id ?? enrolled_homework_id;

  useEffect(() => {
    if (weekDetails?.some((item) => item.contentId === detailId))
      setIsOpen(true);
  }, []);

  const theme = useOnlineTheme();
  return (
    <>
      <S.LecListWeekContainer
        theme={theme.data}
        onClick={() => {
          logLectureListClick("주차별 목차 버튼", courseTitle, {
            buttonStatus: isOpen ? "close" : "open",
          });
          setIsOpen(!isOpen);
        }}
      >
        <S.LecListInfoContainer>
          <S.LecListTitle theme={theme.data}>
            {courseTitle} {weekOrder}
          </S.LecListTitle>
          <S.LecListSubInfoContainer>
            <S.LecListSubInfo theme={theme.data}>
              <PlayIcon theme={theme.data} />
              {lectureLength}
            </S.LecListSubInfo>
            <S.LecListSubInfo theme={theme.data}>
              <PlayTimeIcon theme={theme.data} />
              {largeTimeConvertor(sumPlayTime)}
            </S.LecListSubInfo>
          </S.LecListSubInfoContainer>
        </S.LecListInfoContainer>
        <S.LecListOpenBtn isFocus={isOpen}>
          {isOpenPossible && isDesktop && (
            <ArrowIcon direction={"down"} theme={theme.data} />
          )}
          {isOpenPossible && !isDesktop && (
            <ArrowIcon direction={isOpen ? "up" : "down"} theme={theme.data} />
          )}
        </S.LecListOpenBtn>
      </S.LecListWeekContainer>
      {isOpenPossible && isOpen && (
        <S.InfoCardWrapper theme={theme.data}>
          {weekDetails.map((weekDetail, index) => (
            <div key={index}>
              <InfoCard
                weekDetail={weekDetail}
                nowPlayingIndex={nowPlayingIndex}
                index={index}
                weekDetails={weekDetails}
              />
              {index !== weekDetails.length - 1 && (
                <S.ListDivider theme={theme.data} />
              )}
            </div>
          ))}
        </S.InfoCardWrapper>
      )}
    </>
  );
};
