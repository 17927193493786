import { config } from "../config";
import { handleResponse, authHeader } from "../_helpers";

function getHomeworkItem(roundId, homeworkId) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  return fetch(
    `${config.apiUrl}/v1/rounds/${roundId}/homeworks/${homeworkId}`,
    requestOptions
  ).then(handleResponse);
}

function postHomeworkItem(roundId, homeworkId, details, final_review_url) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({
      details: details,
      final_review_url: final_review_url,
    }),
  };
  return fetch(
    `${config.apiUrl}/v1/rounds/${roundId}/homeworks/${homeworkId}`,
    requestOptions
  ).then(handleResponse);
}

export const homeworkService = {
  getHomeworkItem,
  postHomeworkItem,
};
