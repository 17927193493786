import * as S from "./Content.style";
import {
  useNavigateToTargetContent,
  useSyllabusUnit,
} from "../../../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useCourseId } from "../../../../../../hooks/useProductName";
import {
  ArrowChevronRight,
  ArrowNewRightLine,
  neutralDay,
} from "@teamsparta/design-system";

export const Content = ({ content }) => {
  const {
    contentType,
    week,
    order,
    title,
    playtime,
    passed,
    isNowPlayingIndex,
  } = content;

  const navigateToContent = useNavigateToTargetContent(content, "roadmapList");

  return (
    <div
      onClick={() => {
        navigateToContent();
      }}
    >
      {contentType === "lecture" && (
        <Lecture
          week={week}
          title={title}
          order={order}
          playtime={playtime}
          passed={passed}
          isNowPlayingIndex={isNowPlayingIndex}
        />
      )}
      {contentType === "homework" && (
        <Homework
          week={week}
          title={title}
          passed={passed}
          isNowPlayingIndex={isNowPlayingIndex}
        />
      )}
      {contentType === "nps" && (
        <Nps
          week={week}
          passed={passed}
          isNowPlayingIndex={isNowPlayingIndex}
        />
      )}
      {contentType === "evaluation" && (
        <Evaluation week={week} passed={passed} playtime={playtime} />
      )}
    </div>
  );
};

const Lecture = ({
  week,
  order,
  title,
  playtime,
  passed,
  isNowPlayingIndex,
}) => {
  return (
    <S.ContentContainer
      data-testid="lecture-item"
      isFocused={isNowPlayingIndex}
      isPassed={passed}
    >
      <S.Week>
        {week}-{order}
      </S.Week>
      <S.Title>{title}</S.Title>
      <S.Playtime>{secondToMinute(playtime)}</S.Playtime>
      <S.CheckIcon checked={passed} />
    </S.ContentContainer>
  );
};
const Homework = ({ title, playtime, passed, isNowPlayingIndex }) => {
  return (
    <S.ContentContainer isFocused={isNowPlayingIndex} isPassed={passed}>
      <S.Week>숙제</S.Week>
      <S.Title>{title}</S.Title>
      <S.Playtime>{playtime}</S.Playtime>
      <S.CheckIcon checked={passed} />
    </S.ContentContainer>
  );
};
const Nps = ({ week, playtime }) => {
  const courseId = useCourseId();
  const syllabusUnit = useSyllabusUnit(courseId);
  return (
    <S.ContentContainer isPassed={true}>
      <S.Week>마무리</S.Week>
      <S.Title>
        {week}
        {syllabusUnit} 설문조사 제출하기
      </S.Title>
      <S.Playtime>{playtime}</S.Playtime>
    </S.ContentContainer>
  );
};

const Evaluation = ({ week, playtime, passed }) => {
  return (
    <S.ContentContainer isPassed={true}>
      <S.Week>테스트</S.Week>
      {passed ? (
        <S.TitleBox>
          <S.TitleForEvaluation>{week}주차 테스트</S.TitleForEvaluation>
          <S.TitleSideDescriptionContainer>
            <S.TitleSideDescription>채점 결과</S.TitleSideDescription>
            <ArrowChevronRight size={16} color={`${neutralDay.gray70}`} />
          </S.TitleSideDescriptionContainer>
        </S.TitleBox>
      ) : (
        <S.Title>{week}주차 테스트</S.Title>
      )}
      <S.Playtime>{playtime}</S.Playtime>
      <S.CheckIcon checked={passed} />
    </S.ContentContainer>
  );
};

const padZero = (value) => (value < 10 ? `0${value}` : value);
const secondToMinute = (second) => {
  const minute = Math.floor(second / 60);
  const leftSecond = second % 60;
  const paddedMinute = padZero(minute);
  const paddedSecond = padZero(leftSecond);
  return `${paddedMinute}:${paddedSecond}`;
};
