import privateInstance from "../../_helper/axios";

async function getClassroomCards() {
  return await privateInstance.get(`/classroom/`).then((resp) => resp.data);
}

async function getExpiredClassroomCards() {
  return await privateInstance.get(`/classroom/expired`);
}

export const classroomService = {
  getClassroomCards,
  getExpiredClassroomCards,
};
