import * as S from "./HomeworkFeedbackPage.style";
import { Gnb } from "../../components/organisms/_layout/gnb/Gnb";
import { FooterV2 } from "../../../_components/Footer/FooterV2";
import { HomeworkFeedback } from "../../components/molecules/HomeworkFeedback/HomeworkFeedback";
import { useFeedbackHomeworks } from "../../queries/homework";
import { useParams } from "react-router-dom";
import { useLogFeedbackPageView } from "../../hooks/homework/useLogHomeworkClick";
import { SpartaLogo } from "../../components/molecules/HomeworkFeedback/HomeworkFeedbackCard/HomeworkFeedbackCard.svg";

export const HomeworkFeedbackPage = () => {
  const { user_id } = useParams();
  const { data: homeworksData } = useFeedbackHomeworks(user_id);
  useLogFeedbackPageView();
  return (
    <S.PageContainer>
      <Gnb />
      <S.HomeworkContainer>
        <SpartaLogo />
        <S.HomeworkFeedbackTitle>강의 숙제 1:1 피드백</S.HomeworkFeedbackTitle>
        <HomeworkFeedback data={homeworksData?.feedback_homeworks} />
        {(!homeworksData?.feedback_homeworks ||
          homeworksData.feedback_homeworks.length === 0) && (
          <S.FootnoteText>
            ※ 국비지원 강의 중 아래 2개 강의는 운영 정책의 상이함으로 인해 1:1
            피드백 기능을 지원하지 않습니다. <br />
            • [왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반 <br />• [왕초보]
            마케터, 기획자를 위한 실전 데이터 분석
          </S.FootnoteText>
        )}
      </S.HomeworkContainer>
    </S.PageContainer>
  );
};
