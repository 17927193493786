import styled from "@emotion/styled";
import { PIRATES } from "../../../../_consts";
import { wBody1 } from "@teamsparta/design-system";

export const MobileReportPage = styled.div`
  backgorund: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "#ffffff";
    }
  }};
`;

export const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  align-items: flex-start;
`;

export const ReportTitle = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#141617";
    }
  }};
  margin-bottom: 24px;
`;

export const ReportSubTitle = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#3a3e41";
    }
  }};
  margin-bottom: 12px;
`;

export const ReportSelectBox = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#dbdde0";
      }
    }};
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ReportSelectedValue = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isActive, theme }) => {
    switch (theme) {
      case PIRATES:
        return isActive ? "#EEF3F6" : "#81898F";
      default:
        return isActive ? "#141617" : "#c7d2d8";
    }
  }};
`;

export const ReportContentTextarea = styled.textarea`
  height: 120px;
  width: 100%;
  border-radius: 8px;
  resize: none;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  padding: 18px 20px;
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "inherit";
    }
  }};
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#d7e0e6";
      }
    }};

  &::placeholder {
    color: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#81898F";
        default:
          return "#b4bfc6";
      }
    }};
  }

  &:focus-within {
    border-color: #9da7ae;
  }
`;

export const ReportSubmitButtonWrapper = styled.div`
  position: fixed;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  gap: 10px;
  width: 100%;
`;

export const BackToErrorTypeSelectorButton = styled.div`
  width: 30%;
  height: 56px;
  background-color: #e8344e;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 56px;
  color: var(--gray-gray-100-day, #141617);
  border-radius: 8px;
  background: var(--gray-gray-30-day, #d7e0e6);
`;

export const ReportSubmitButton = styled.div`
  width: 70%;
  height: 56px;
  background-color: #141617;
  border-radius: 8px;
  color: #ffffff;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 56px;
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
`;

export const ErrorReportInputWrapper = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#2B2D2E";
      default:
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#5F666B";
        default:
          return "#dbdde0";
      }
    }};
  padding: 16px 20px;
  display: flex;
  flex-direction: row;

  margin-bottom: 24px;
`;
export const ErrorReportInput = styled.input`
  box-sizing: border-box;
  display: flex;
  border: none;
  text-align: center;
  width: 30px;
  padding: 0;
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 14px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
