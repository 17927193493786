import styled from "@emotion/styled";
import { neutralDay, scc, wBody1 } from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../../_styles/device";
import { LECTURE_GNB_HEIGHT } from "../../../../../pages/LecturePageV3/variables";

export const HomeworkGnbWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: ${({ isOpenList }) => (isOpenList ? `75%` : "100%")};
  height: ${LECTURE_GNB_HEIGHT}px;
  padding: 6px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${neutralDay.gray40};
  background: ${neutralDay.white};
  z-index: 10;
  ${DisplayResolution.MobileAndMiniTablet} {
    display: none;
  }
`;

export const HomeworkGnbLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const GoPrevPageBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LectureTitleAndWeekWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

export const LectureTitle = styled.div`
  ${wBody1};
  color: ${neutralDay.gray100};
  text-align: center;
`;

//분리선
export const LectureTitleAndWeekDivider = styled.div`
  width: 1px;
  height: 10px;
  background: ${neutralDay.gray50};
`;

export const LectureWeek = styled.div`
  ${wBody1};
  color: ${scc.red100};
  text-align: center;
`;

export const HomeworkGnbRightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
