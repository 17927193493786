import { useOnlineTheme } from "../../queries/pirates";
import * as S from "./nps.style";
import { useState } from "react";
import * as N from "../NPSPage/NPSPage.style";

const PointSelector = ({ isSelected, onSelect, selectorOption }) => {
  return (
    <S.Point isSelected={isSelected} onClick={() => onSelect()}>
      <S.PointText isSelected={isSelected}>{selectorOption}</S.PointText>
    </S.Point>
  );
};

export const SatisfactionQuestion = ({
  questionTitle,
  questionNumber,
  leftText,
  centerText,
  rightText,
  placeholder,
  onSelectPoint,
  selectedPoint,
  onChangeAdditionalAnswer,
}) => {
  return (
    <S.QuestionWrapper>
      <S.QuestionBox>
        <S.QuestionTitleBox>
          <S.QuestionNumber>Q{questionNumber}.</S.QuestionNumber>
          <S.QuestionTitle>{questionTitle}</S.QuestionTitle>
        </S.QuestionTitleBox>
        <S.QuestionSatisfactionContainer>
          <S.FivePointsContainer>
            <S.FivePoints>
              {[1, 2, 3, 4, 5].map((point) => (
                <PointSelector
                  key={point}
                  isSelected={selectedPoint === point}
                  onSelect={() => {
                    onSelectPoint(point);
                  }}
                  selectorOption={point}
                />
              ))}
            </S.FivePoints>
            <S.FivePointsLine />
            <S.PointLeftText>{leftText}</S.PointLeftText>
            <S.PointCenterText>{centerText}</S.PointCenterText>
            <S.PointRightText>{rightText}</S.PointRightText>
          </S.FivePointsContainer>
        </S.QuestionSatisfactionContainer>
      </S.QuestionBox>
      {selectedPoint !== null && (
        <N.SubQuestionTextArea
          placeholder={placeholder}
          onChange={(e) => {
            onChangeAdditionalAnswer(e.target.value);
          }}
        />
      )}
    </S.QuestionWrapper>
  );
};
