import { useCallback, useEffect, useRef } from "react";
import videojs from "video.js";
import { usePlaybackTime, useDuration } from "../stores/playerAtom";

export const usePlaybackTimeControl = (videoRef) => {
  const [playbackTime, setPlaybackTime] = usePlaybackTime();
  const [duration, setDuration] = useDuration();
  const playerRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    playerRef.current = videojs(videoRef.current);
    const player = playerRef.current;
    player.ready(() => {
      player.on("loadedmetadata", () => {
        setDuration(player.duration());
        setPlaybackTime(player.currentTime());
      });

      player.on("timeupdate", () => {
        setPlaybackTime(player.currentTime());
      });
    });

    return () => {
      player.dispose();
    };
  }, [videoRef]);

  const onScrub = (e) => {
    const newTime = e.target.value * playerRef.current.duration();
    playerRef.current.currentTime(newTime);
  };

  const moveBackward = () => {
    const newTime = playerRef.current.currentTime() - 10;
    playerRef.current.currentTime(newTime > 0 ? newTime : 0);
    if (playerRef.current?.paused()) {
      playerRef.current.play();
    }
  };

  const moveForward = () => {
    const newTime = playerRef.current.currentTime() + 10;
    playerRef.current.currentTime(
      newTime < playerRef.current.duration()
        ? newTime
        : playerRef.current.duration()
    );
    if (playerRef.current?.paused()) {
      playerRef.current.play();
    }
  };

  const handleScrub = useCallback(
    (e) => {
      onScrub(e);
    },
    [onScrub]
  );

  return {
    playbackTime,
    duration,
    handleScrub,
    moveBackward,
    moveForward,
  };
};
