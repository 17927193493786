import styled from "@emotion/styled";
import {
  additional,
  neutralDay,
  scc,
  wBody1,
  wBody2,
  wBody3,
  wBody4,
} from "@teamsparta/design-system";

export const EvaluationAnswerTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-right: 1px;
`;

export const EvaluationAnswerTag = styled.div`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${neutralDay.white};
  ${wBody1};
  color: ${neutralDay.gray80};
`;

export const SubmittedAnswer = styled.h2`
  ${wBody2};
  align-self: stretch;
  color: var(--Neutral-Day-Gray90, #3a3e41);
  text-align: justify;
`;

export const CorrectAnswerContainer = styled.div`
  display: flex;
  padding: 14px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Day-Gray30, #d7e0e6);
  background: var(--Neutral-Day-Gray20, #e4ebf0);
`;

export const CorrectAnswerText = styled.h3`
  ${wBody3};
  align-self: stretch;
  color: var(--Neutral-Day-Gray95, #25282a);
  text-align: justify;
`;

export const CorrectAnswerCommentary = styled.h4`
  ${wBody4};
  align-self: stretch;
  color: var(--Neutral-Day-Gray80, #5f666b);
  text-align: justify;
`;
