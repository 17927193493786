export const POINTS = {
  POINTS_REQUEST: "POINTS_REQUEST",
  POINTS_SUCCESS: "POINTS_SUCCESS",
  POINTS_FAILURE: "POINTS_FAILURE",
};

export const EXPIRATION_POINTS = {
  EXPIRATION_POINTS_REQUEST: "EXPIRATION_POINTS_REQUEST",
  EXPIRATION_POINTS_SUCCESS: "EXPIRATION_POINTS_SUCCESS",
  EXPIRATION_POINTS_FAILURE: "EXPIRATION_POINTS_FAILURE",
};

export const OPEN_RANDOMBOX = {
  OPEN_RANDOMBOX_REQUEST: "OPEN_RANDOMBOX_REQUEST",
  OPEN_RANDOMBOX_SUCCESS: "OPEN_RANDOMBOX_SUCCESS",
  OPEN_RANDOMBOX_FAILURE: "OPEN_RANDOMBOX_FAILURE",
};

export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  RESET_PASSWORD_REQUEST: "USERS_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USERS_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USERS_RESET_PASSWORD_FAILURE",
  SET_TOKENS: "USERS_SET_TOKENS",

  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  REFRESH_REQUEST: "USERS_REFRESH_REQUEST",
  REFRESH_SUCCESS: "USERS_REFRESH_SUCCESS",
  REFRESH_FAILURE: "USERS_REFRESH_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  INFO_REFRESH_REQUEST: "USERS_INFO_REFRESH_REQUEST",
  INFO_REFRESH_SUCCESS: "USERS_INFO_REFRESH_SUCCESS",
  INFO_REFRESH_FAILURE: "USERS_INFO_REFRESH_FAILURE",

  WITHDRAWAL_REQUEST: "USERS_WITHDRAWAL_REQUEST",
  WITHDRAWAL_SUCCESS: "USERS_WITHDRAWAL_SUCCESS",
  WITHDRAWAL_FAILURE: "USERS_WITHDRAWAL_FAILURE",

  MANAGED_REQUEST: "USERS_MANAGED_REQUEST",
  MANAGED_SUCCESS: "USERS_MANAGED_SUCCESS",
  MANAGED_FAILURE: "USERS_MANAGED_FAILURE",

  MARKETING_AGREEMENT_REQUEST: "USERS_MARKETING_AGREEMENT_REQUEST",
  MARKETING_AGREEMENT_SUCCESS: "USERS_MARKETING_AGREEMENT_SUCCESS",
  MARKETING_AGREEMENT_FAILURE: "USERS_MARKETING_AGREEMENT_FAILURE",

  REG_NO_REQUEST: "USERS_REG_NO_REQUEST",
  REG_NO_SUCCESS: "USERS_REG_NO_SUCCESS",
  REG_NO_FAILURE: "USERS_REG_NO_FAILURE",

  VALIDATE_ALPACO_REQUEST: "USERS_VALIDATE_ALPACO_REQUEST",
  VALIDATE_ALPACO_SUCCESS: "USERS_VALIDATE_ALPACO_SUCCESS",
  VALIDATE_ALPACO_FAILURE: "USERS_VALIDATE_ALPACO_FAILURE",

  SEND_CONFIRMATION_REQUEST: "SEND_CONFIRMATION_REQUEST",
  SEND_CONFIRMATION_SUCCESS: "SEND_CONFIRMATION_SUCCESS",
  SEND_CONFIRMATION_FAILURE: "SEND_CONFIRMATION_FAILURE",

  CONFIRM_EMAIL_REQUEST: "CONFIRM_EMAIL_REQUEST",
  CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAILURE: "CONFIRM_EMAIL_FAILURE",
};
