import { todaydiscountConstants } from "../_constants";
const initState = {
  loaded: false,
};
export function todaydiscount(state = initState, action) {
  switch (action.type) {
    case todaydiscountConstants.TODAY_DISCOUNT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case todaydiscountConstants.TODAY_DISCOUNT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case todaydiscountConstants.TODAY_DISCOUNT_FAILURE:
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
