import React, { Component, useState, useEffect, useMemo } from "react";
import styles from "./SpalandCheckinPage.module.scss";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { spalandActions, usersActions } from "../../_state/actions";
import { history } from "../../../_helpers";
import { getCookie, params } from "../../_helper";
import { SpalandCheckinPageStamp } from "./SpalandCheckinPageStamp";

const cx = classNames.bind(styles);

const getStartDateOfThisWeek = () => {
  let curr = new Date(); // get current date
  // 한주의 시작을 월요일로 하기 위한 처리(일요일을 맨 뒤로 밈) 일요일 인덱스인 0을 받으면, 7로 처리한다
  let currDay = curr.getDay() === 0 ? 7 : curr.getDay();
  let firstDateOfThisWeek = curr.getDate() - currDay;
  let listOfWeekDay = [];
  for (let i = 1; i < 8; i++) {
    let base = new Date();
    listOfWeekDay.push(
      new Date(base.setDate(firstDateOfThisWeek + i)).toUTCString()
    );
  }
  return listOfWeekDay;
};

const goToClassroom = () => {
  history.push(`/classroom`);
};

const SpalandCheckinPage = ({
  user,
  loginWithToken,
  spalandDates,
  getSpalandDates,
}) => {
  useEffect(() => {
    if (params().logined) {
      let token = getCookie("token");
      if (token) {
        loginWithToken(token);
      }
    } else {
      if (!user) {
        alert("로그인이 필요합니다.\n로그인 페이지로 이동합니다.");
        history.push("/login?next=spaland/checkin");
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      getSpalandDates();
    }
  }, [user]);

  const thisWeekdays = getStartDateOfThisWeek();
  const days = ["월", "화", "수", "목", "금", "토", "일"];
  return (
    <>
      {user && (
        <>
          <div className={cx("container")}>
            <div className={cx("header")}>
              <img
                src={("/v2/assets/rtan/rtan_sheep_hair.png")}
                alt="spaland-rtan"
                className={cx("rtan-image")}
              />
              <div className={cx("header-text-container")}>
                <span className={cx("welcome-message")}>
                  {user.name}님, 어서오세요!
                </span>
                <span className="welcome-description">
                  버튼을 눌러 출석 체크 해주세요.
                </span>
              </div>
            </div>
            {spalandDates.loaded && (
              <>
                <div className={cx("week-calendar")}>
                  <div className={cx("weekdays-day")}>
                    {days.map((day) => {
                      return (
                        <div className={cx("weekdays-day__container")}>
                          {day}
                        </div>
                      );
                    })}
                  </div>
                  <div className={cx("weekdays-date")}>
                    {thisWeekdays.map((dateOfday) => {
                      return (
                        <SpalandCheckinPageStamp
                          key={dateOfday}
                          dateOfday={dateOfday}
                          spalandDates={spalandDates.data}
                        />
                      );
                    })}
                  </div>
                </div>
                <div
                  className={cx("go-to-classroom-btn")}
                  onClick={goToClassroom}
                >
                  내 강의실 가기{" "}
                  <img
                    alt={"ic_chevron_right"}
                    src={("/v2/assets/icons/icon_chevron_right.svg")}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { authentication, spalandDates } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    spalandDates,
  };
}

const connectedSpalandCheckinPage = connect(mapStateToProps, {
  loginWithToken: usersActions.loginWithToken,
  getSpalandDates: spalandActions.getSpalandDates,
})(SpalandCheckinPage);
export { connectedSpalandCheckinPage as SpalandCheckinPage };
