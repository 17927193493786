import React from "react";
import * as S from "./RecordCheckBtn.style";

export function RecordCheckBtn({ btn }) {
  const { onClick, text, disable } = btn;
  return (
    <S.Container>
      <S.CheckBtn onClick={onClick} disable={disable}>
        {text}
      </S.CheckBtn>
    </S.Container>
  );
}
