import styled from "@emotion/styled";
import {
  additional,
  mBody3,
  mHeader1,
  mTitle2,
  neutralDay,
  scc,
  wHeader2,
  wTitle4,
} from "@teamsparta/design-system";
import { Device } from "../../../../_styles/device";

export const Container = styled.div`
  background: ${neutralDay.gray100};

  padding: 100px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  text-align: center;
  ${wHeader2};
  color: ${neutralDay.gray0};
  margin-bottom: 50px;
`;

export const RecommendWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const RecommendImage = styled.img`
  width: 772px;
`;

export const BannerWrapper = styled(RecommendWrapper)``;

export const RecommendBanner = styled.img`
  width: 772px;
  margin-top: 60px;
`;

export const LeftButtonWrapper = styled.div`
  position: absolute;
  left: 56px;
  bottom: 417px;
`;

export const RightButtonWrapper = styled.div`
  position: absolute;
  right: 56px;
  bottom: 417px;
`;

export const Button = styled.div`
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  width: 289.5px;
  height: 54px;
  border-radius: 8px;
  background: ${scc.red100};
  line-height: 54px;
  ${mTitle2};
  color: ${neutralDay.white};
  cursor: pointer;
`;

export const SmallButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 24px;
`;

export const SmallButton = styled.div`
  width: 160px;
  height: 36px;
  padding: 7.5px 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2px;
  border-radius: 7px;
  background: ${additional.yellow80};
  cursor: pointer;
`;

export const SmallButtonText = styled.div`
  ${mBody3};
  color: ${neutralDay.gray100};
  line-height: 21px;
  text-align: center;
`;

export const RoadmapButton = styled.div`
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  ${wTitle4};
  color: ${neutralDay.gray70};
  line-height: 29px;
  margin-top: 100px;
  cursor: pointer;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 16px;
  gap: 40px;
  justify-content: center;
  align-items: center;
  background: ${neutralDay.gray100};
`;

export const MobileTitle = styled.div`
  ${mHeader1};
  color: ${neutralDay.white};
  text-align: center;
`;

export const MobileRecommendWrapper = styled(RecommendWrapper)``;

export const MobileRecommendImage = styled.img`
  width: 328px;
`;

export const MobileBannerWrapper = styled(RecommendWrapper)``;

export const MobileBannerImage = styled(MobileRecommendImage)``;

export const MobileRoadmapButton = styled(RoadmapButton)`
  margin-top: 20px;
  padding: 16px 20px;
`;

export const MobileTopButtonWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 442px;
`;

export const MobileBottomButtonWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 526px;
`;

export const MobileButton = styled.div`
  display: flex;
  height: 54px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${scc.red100};
  ${mTitle2};
  color: ${neutralDay.white};
  line-height: 140%;
  cursor: pointer;
  width: 288px;
`;

export const MobileSmallButtonWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

export const MobileSmallButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 7.5px 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 7px;
  background: ${additional.yellow80};
  width: 288px;
  height: 36px;
  cursor: pointer;
`;

export const MobileSmallButtonText = styled.div`
  ${mBody3};
  color: ${neutralDay.gray100};
`;
