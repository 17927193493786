import { checkinConstants, logsCrmAbConstants } from "../_constants";

const initState = {
  loaded: false,
};

export function logCrmAB(state = initState, action) {
  switch (action.type) {
    case logsCrmAbConstants.LOG_CRM_AB_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case logsCrmAbConstants.LOG_CRM_AB_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case logsCrmAbConstants.LOG_CRM_AB_FAILURE:
      if (!action.error.contains("JSON")) {
        alert(action.error);
      }
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
