import { config } from "../config";
import { handleResponse } from "../_helpers";

function getCommingsoonList() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  return fetch(`${config.apiUrl}/v1/courses/commingsoons`, requestOptions).then(
    handleResponse
  );
}

export const commingsoonService = {
  getCommingsoonList,
};
