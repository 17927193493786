import { priceConstants } from "../_constants";

const initState = {
  loaded: false,
};

export function priceByNumber(state = initState, action) {
  switch (action.type) {
    case priceConstants.PRICE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case priceConstants.PRICE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case priceConstants.PRICE_FAILURE:
      alert(action.error);
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
