import React, {Component, Fragment} from 'react';
import classNames from "classnames/bind";
import styles from "./CustomModal_Ranking.module.scss";
import TextArea from "antd/lib/input/TextArea";
import {getApiRescueUrl} from "../../_urls/dashboard.api";
import {getPostAuthOptions} from "../../_urls";
import {connect} from "react-redux";
import {rankActions} from "../../_actions";
import {Skeleton} from "antd";
import CountUp, {useCountUp} from 'react-countup';


const cx = classNames.bind(styles);


class CustomModal_Ranking extends Component {

    state = {
        showMyrank: false,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const rid = this.props.rid;
        if (rid) {
            this.props.getRankInfo(rid);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.visible && this.props.rankInfo.loaded) {
            const my_rank = this.props.rankInfo.data.my_rank.rank;
            setInterval(() => {this._setRank(my_rank)}, 2500)
        }
    }

    _setRank = (my_rank) => {
        const {showMyrank} = this.state;
        if (showMyrank) {
            return;
        }
        this.setState({
            showMyrank: true,
        });
        {this.rankbody && this.rankbody.scrollTo({
            top: my_rank > 8 ? 30 * (my_rank - 9) : 0,
            behavior: 'smooth'
        })}
    };

    _handleCloseModal = () => {
        this.props.handleModalVisible(false);
    };

    getMilestones() {
        const {rankInfo} = this.props;
        let milestone_list = [];
        let milestone_length = rankInfo.data.week_infos.length;
        let week_infos = rankInfo.data.week_infos;
        for (let i = 0; i < week_infos.length; i++) {
            milestone_list.push(
                <div key={i} className={cx('milestone')} style={{'marginLeft': `${week_infos[i]}%`}}>
                    <span>{milestone_length === i + 1 ? '완주' : `${i + 1}주`}</span></div>
            )
        }
        return milestone_list;
    }

    getRanks() {
        const {rankInfo} = this.props;
        const rank_info_list = rankInfo.data.rank_info_list;
        const my_rank = rankInfo.data.my_rank.rank;
        let rank_list = []
        for (let i = 0; i < rank_info_list.length; i++) {
            let rank_data = rank_info_list[i];
            let len_pg_bar = 550 * rank_data.pg_rate;
            let is_my = rank_data.user_id === this.props.user._id;
            rank_list.push(
                <div key={i} className={i === 0 ? cx('rank_row', 'top') : is_my ? cx('rank_row', 'my') : cx('rank_row')}>
                    {is_my && <div className={cx('my_rank_wrap')}></div>}
                    <div className={cx('rank_name')}>{rank_data.name}</div>
                    <div className={cx('rank_pg_rate')}>
                        <div className={cx('pg-bar')} style={{'width': `${len_pg_bar}px`}}>
                            <div className={len_pg_bar > 70 ? cx('pg-bar-rank') : cx('pg-bar-rank', 'low')}>
                                {i === 0 ? '1등' : i === my_rank - 1 ? `${i + 1}등` : rank_data.lec}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return rank_list
    }

    render() {
        const {visible, user, rid} = this.props;
        const {style, rankInfo} = this.props;
        if (!rankInfo.loaded) {
            return <div/>
        }
        // let my_pg_rate = 648 * rankInfo.data.my_rank.pg_rate + 32;
        let my_rank_info = rankInfo.data.my_rank;
        let top_rank_info = rankInfo.data.top_rank;
        let my_rank = my_rank_info.rank;
        let total = rankInfo.data.rank_info_list.length;
        let my_pg_rate = 648 * my_rank_info.pg_rate + 32;
        let top_pg_rate = 648 * rankInfo.data.top_rank.pg_rate + 32;
        return (
            <div>
                <div className={
                    visible ? cx("modal", "active") : cx("modal")
                } onClick={this._handleCloseModal}>

                </div>
                <div className={
                    visible ? cx("modal-background", "active") : cx("modal-background")
                } style={{...style}}>
                    <div className={cx("modal-content")}>
                        <div className={cx('modal-body')}>
                            <div className={cx('header')}>
                                <h3>
                                    {my_rank !== 1 ? `조금만 더 달려 볼까요? 파이팅!` : `우와! 정말 멋져요!`}
                                </h3>
                                <p>{user.name}님은 {total}명 중에서 <b><CountUp start={1}
                                                                                                        end={my_rank_info.rank}
                                                                                                        duration={2}/></b>등이에요!
                                </p>
                            </div>
                            <div className={cx('summary')}>
                                <span>권장 진도
                                    <span>{rankInfo.data.rcmded}</span>
                                </span>
                                <div className={cx('pg-bar')}>
                                    <div className={cx('player', 'my-milestone')} style={{'left': `${my_pg_rate}px`}}>
                                        <div className={cx('current_lecture')}
                                             style={{'backgroundImage': `url(${('/assets/icons/sign_g.png')})`}}
                                        >{
                                            my_rank_info.lec.split(' ')[0]}<br/>{my_rank_info.lec.split(' ')[1]
                                        }</div>
                                        <div className={cx('node')}>나</div>
                                    </div>
                                    {
                                        my_rank !== 1 &&
                                        <div className={cx('player', 'top-milestone')}
                                             style={{'left': `${top_pg_rate}px`}}>
                                            <div className={cx('current_lecture')}
                                                 style={{'backgroundImage': `url(${('/assets/icons/sign_red.png')})`}}
                                            >{top_rank_info.lec.split(' ')[0]}<br/>{top_rank_info.lec.split(' ')[1]}
                                            </div>
                                            <div className={cx('node')}>1등</div>
                                        </div>
                                    }
                                    <div className={cx('bg-pg-bar')}>
                                        <div className={cx('my-pg-bar')}
                                             style={{'width': `${my_pg_rate - 32}px`}}></div>
                                        <div className={cx('milestone')}><span>0주</span></div>
                                        {this.getMilestones()}
                                    </div>
                                </div>
                            </div>
                            <div className={cx('body')} ref={ref => { this.rankbody = ref }}>
                                {this.getRanks()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {authentication, rankInfo} = state;
    const {user} = authentication;
    return {
        authentication,
        user,
        rankInfo
    }
}

const connectedCustomModal_Ranking = connect(mapStateToProps, {
    getRankInfo: rankActions.getRankInfo
})(CustomModal_Ranking);

export {connectedCustomModal_Ranking as CustomModal_Ranking};