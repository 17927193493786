import * as S from "./VerificationModal.style";
import { InfoCircle, neutralDay } from "@teamsparta/design-system";
import { isMOTPInputValid } from "../../../../../_helpers";
import { validateMOTP } from "../../../../businesslogics/Verification/validateMOTP";
import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import { isNeedRestMOTP } from "../../../../stores/varificationReset";
import { useGetUserIP } from "../../../../queries/auth/auth";

export const MOTPForm = ({
  user,
  setIsOTP,
  productType,
  setIsMouseOver,
  isMouseOver,
  enrolled,
  refetchDailyAuth,
}) => {
  const [inputOTP, setInputOTP] = useState("");
  const [, setIsNeedReset] = useAtom(isNeedRestMOTP);
  const inputRef = useRef(null);
  const { isSuccess: isUserIPSuccess, data: userIp } = useGetUserIP();
  const { course_id: courseId, round_id: roundId } = enrolled;

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <S.TitleWrapper>
        <S.Title>
          원격훈련 mOTP 앱에서 생성한
          <br />
          6자리 인증 번호를 입력하세요
        </S.Title>
      </S.TitleWrapper>
      <S.OTPInput
        ref={inputRef}
        type="text"
        placeholder="000000"
        value={inputOTP}
        maxLength={6}
        onChange={(e) => setInputOTP(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && isMOTPInputValid(inputOTP)) {
            validateMOTP();
          }
        }}
      />
      <S.GuideWrapper>
        <S.VerificationGuideWrapper
          onClick={() => {
            setIsMouseOver(!isMouseOver);
          }}
          onMouseEnter={() => {
            setIsMouseOver(true);
          }}
          onMouseLeave={() => {
            setIsMouseOver(false);
          }}
        >
          <S.VerificationGuide>mOTP 앱 인증 방법</S.VerificationGuide>
          <S.MouseOver>
            <InfoCircle size={16} style={"line"} color={neutralDay.gray70} />
          </S.MouseOver>
        </S.VerificationGuideWrapper>

        <S.DetailGuide>
          <a
            target={"_blank"}
            rel={"noreferrer"}
            href={
              "https://teamsparta.notion.site/mOTP-47dca054ad8244b3989b720385eedeb7?pvs=4"
            }
          >
            상세 가이드 보기
          </a>
        </S.DetailGuide>
      </S.GuideWrapper>
      <S.VerificationButtonWrapper>
        <S.BeforeButton onClick={() => setIsOTP(false)}>
          이전으로
        </S.BeforeButton>
        <S.VerificationButton
          onClick={() => {
            validateMOTP({
              courseId,
              roundId,
              productType,
              user,
              isUserIPSuccess,
              userIp,
              inputOTP,
              setInputOTP,
              setIsNeedReset,
              setIsOTP,
              refetchDailyAuth,
            });
          }}
          enabled={isMOTPInputValid(inputOTP)}
        >
          인증하기
        </S.VerificationButton>
      </S.VerificationButtonWrapper>
    </>
  );
};
