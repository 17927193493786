import { dashboardConstants } from "../_constants";
import { dashboardService } from "../_services";

function getDashboard(course_id) {
  function request() {
    return { type: dashboardConstants.DASHBOARD_REQUEST };
  }

  function success(response) {
    return { type: dashboardConstants.DASHBOARD_SUCCESS, response };
  }

  function failure(error) {
    return { type: dashboardConstants.DASHBOARD_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    dashboardService.getDashboard(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const dashboardActions = {
  getDashboard,
};
