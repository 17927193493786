export const QUESTION_TYPE = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  SHORT: "SHORT",
  CODE: "CODE",
};

export const PAGE_TYPE = {
  TEST_PAGE: "TEST_PAGE",
  RESULT_PAGE: "RESULT_PAGE",
};
