import React from "react";
import styled from "@emotion/styled";
import { usePlayerCoreControl } from "../../hooks/usePlayerCoreControl";
export const DesktopOverlay = React.memo(
  ({ videoRef, lecture_v2, playerType }) => {
    const { togglePlay } = usePlayerCoreControl({
      videoRef,
      lecture_v2,
      playerType,
    });
    return <ReadyOverlay onClick={togglePlay} />;
  }
);

const ReadyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;
