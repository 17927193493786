import { paymentConstants } from "../_constants";
import { paymentService } from "../_services";

function setPaymentType(isTest) {
  function general() {
    return { type: paymentConstants.PAYMENT_TYPE_GENERAL };
  }

  function test() {
    return { type: paymentConstants.PAYMENT_TYPE_TEST };
  }
  return (dispatch) => {
    if (isTest) {
      dispatch(test());
    } else {
      dispatch(general());
    }
  };
}

function getPaymentInfo(course_id) {
  function request() {
    return { type: paymentConstants.PAYMENT_INFO_REQUEST };
  }

  function success(response) {
    return { type: paymentConstants.PAYMENT_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: paymentConstants.PAYMENT_INFO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentInfo(course_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getPaymentInfoByCourseId(course_id, params) {
  function request() {
    return { type: paymentConstants.PAYMENT_COURSE_REQUEST };
  }

  function success(response) {
    return { type: paymentConstants.PAYMENT_COURSE_SUCCESS, response };
  }

  function failure(error) {
    return { type: paymentConstants.PAYMENT_COURSE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    paymentService.getPaymentInfoByCourseId(course_id, params).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const paymentActions = {
  setPaymentType,
  getPaymentInfo,
  getPaymentInfoByCourseId,
};
