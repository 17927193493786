import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./AttendanceMark.module.scss";

const cx = classNames.bind(styles);

export function AttendanceMark({}) {
  const today = new Date();
  return (
    <div className={cx("AttendanceMarkContainer")}>
      <img src={`/assets/images/checkin/attendance.svg`} />
      <div className={cx("date")}>
        {today.getMonth() + 1}월 {today.getDate()}일
      </div>
    </div>
  );
}
