export const friendRecoConstants = {
  FRIEND_RECO_INFO_REQUEST: "FRIEND_RECO_INFO_REQUEST",
  FRIEND_RECO_INFO_SUCCESS: "FRIEND_RECO_INFO_SUCCESS",
  FRIEND_RECO_INFO_FAILURE: "FRIEND_RECO_INFO_FAILURE",

  FRIEND_RECO_TOP_REQUEST: "FRIEND_RECO_TOP_REQUEST",
  FRIEND_RECO_TOP_SUCCESS: "FRIEND_RECO_TOP_SUCCESS",
  FRIEND_RECO_TOP_FAILURE: "FRIEND_RECO_TOP_FAILURE",

  FRIEND_RECO_FREE_CART_REQUEST: "FRIEND_RECO_FREE_CART_REQUEST",
  FRIEND_RECO_FREE_CART_SUCCESS: "FRIEND_RECO_FREE_CART_SUCCESS",
  FRIEND_RECO_FREE_CART_FAILURE: "FRIEND_RECO_FREE_CART_FAILURE",
};
