import * as S from "./evaluationResponse.style";
import React, { useState } from "react";
import { ShortResponse } from "../../../atoms/Evaluation/ResponseType/Short";
import { MultipleChoiceType } from "../../../atoms/Evaluation/ResponseType/MultipleChoice";
import { EvaluationData } from "../../../../businesslogics/OrderedEnrollmentContents/model/evaluation.dto";
import { useGetEvaluationSummaryData } from "../../../../hooks/evaluation/useEvaluationData";
interface EvaluationResponseProps {
  questionData: EvaluationData;
  answer: string;
  setAnswer: (newAnswer: string) => void;
}
export const EvaluationResponse = ({
  questionData,
  answer,
  setAnswer,
}: EvaluationResponseProps) => {
  const { questionType } = useGetEvaluationSummaryData(questionData);

  const renderResponseByType = () => {
    switch (questionType) {
      case "MULTIPLE_CHOICE":
        return (
          <MultipleChoiceType
            questionData={questionData}
            options={questionData.questionDetails.options}
            answer={answer}
            setAnswer={setAnswer}
          />
        );

      case "SHORT":
        return <ShortResponse answer={answer} setAnswer={setAnswer} />;

      case "CODE":
        // CODE 타입에 해당하는 컴포넌트 렌더링 로직 추가되어야함.
        return <ShortResponse answer={answer} setAnswer={setAnswer} />;

      default:
        return <div>Unsupported question type</div>;
    }
  };

  return (
    <S.AnswerContainer>
      <S.AnswerTag>답변</S.AnswerTag>
      {renderResponseByType()}
    </S.AnswerContainer>
  );
};
