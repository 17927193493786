import privateInstance from "../../_helper/axios";
import privateInstanceV3 from "../../_helper/axiosV3";

async function getMyHomeworks(enrolleds_homeworks_id) {
  return await privateInstanceV3
    .get(`/learning-resources/${enrolleds_homeworks_id}/homework_details`)
    .then((resp) => resp.data);
}

async function getHomeworkAnswer(enrolled_homework_id) {
  return await privateInstance
    .get(`/homeworks/answer/${enrolled_homework_id}`)
    .then((resp) => resp.data);
}

async function postMyHomeworks(
  enrolleds_homeworks_id,
  enrolled_id,
  homework_id,
  enrolleds_homework_details,
  slackEmail
) {
  const data = {
    enrolled_id,
    homework_id,
    enrolleds_homework_details,
    slackEmail,
  };
  return await privateInstance
    .post(`/homeworks/${enrolleds_homeworks_id}`, data)
    .then((resp) => resp.data);
}

export const homeworksService = {
  getMyHomeworks,
  getHomeworkAnswer,
  postMyHomeworks,
};
