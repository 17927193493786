import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { alert } from "./alert.reducer";
import { environment } from "./environment.reducer";
import {
  courses,
  course,
  courseByUserId,
  govCourses,
  coursesByPkgId,
} from "./course.reducer";
import { lecture, lectureNav } from "./lecture.reducer";
import { lectureNote } from "./lectureNote.reducer";
import { codeSnippet } from "./codeSnippet.reducer";
import { homework } from "./homework.reducer";
import { classroom } from "./classroom.reducer";
import { mypage } from "./mypage.reducer";
import { rankInfo } from "./rank.reducer";
import { dashboard } from "./dashboard.reducer";
import { commingsoons } from "./commingsoon.reducer";
import { couponInfo } from "./coupon.reducer";
import { rcmdDashboards } from "./rcmdDashboard.reducer";
import {
  isTestPaid,
  paymentInfo,
  paymentInfoByCourseId,
} from "./payment.reducer";
import { todaydiscount } from "./todaydiscount.reducer";
import { pointInfo } from "./point.reducer";
import {
  friendRecoChart,
  friendRecoInfo,
  friendRecoFreeChart,
} from "./friendReco.reducer";
import { checkins } from "./checkin.reducer";
import { priceByNumber } from "./priceByNumber.reducer";
import { prequestionSubmitted } from "./prequestion.reducer";
import {
  enrolledDone,
  enrolledStatus,
  vouchers,
  enrolledOngoing,
} from "./enrolled.reducer";
import { logCrmAB } from "./log.reducer";
import { retrospective } from "./retrospective.reducer";
import { certs } from "./cert.reducer";
import {
  classroomCards,
  expiredClassroomCards,
} from "../v2/_state/reducers/classroom.reducer";
import { liveUser } from "../v2/_state/reducers/live_user.reducer";
import { point } from "../v2/_state/reducers/point.reducer";
import {
  enrolledFinishSummary,
  enrolledSummary,
} from "../v2/_state/reducers/enrolled_summary.reducer";
import { courseRetention } from "../v2/_state/reducers/course_retention.service";
import {
  enrolledDoneCount,
  enrolledMaker,
  enrolleds,
  enrolledSurvey,
  hasSeenOrientation,
  roundWeeks,
  studyplans,
} from "../v2/_state/reducers/enrolled.reducer";
import {
  points,
  expirationPoints,
  authentication2,
  marketingAgreement,
  userRegNo,
  isValidAlpaco,
} from "../v2/_state/reducers/users.reducer";
import {
  abtestPayment,
  abtestKkoSignup,
  abtestCourseRcmd,
  broadcastList,
  doneEnrolledDetail,
  enrolled,
  activeEnrolleds,
  enrolledBadges,
  enrolledDetail,
  enrolledDetailObstacle,
  enrolledDetails,
  enrolledPeriod,
  enrolledPlaytime,
  enrolledReimbursed,
  enrolledProgress,
  enrolledRanks,
  expired,
  isDetailPresent,
  isPrequestionExist,
  isSecretStoreAllowed,
  managed,
  paymentReinforced,
  pointDelta,
  round,
  salesCall,
  paymentAlert,
  paymentReinforcedByUser,
  isPaymentCounselTarget,
  paymentCounselResult,
  isNomadCouponTarget,
  nomadCouponResult,
  paymentOption,
  paymentReviews,
  paymentVBank,
  orders,
  govAttendRecords,
  govDailyRecord,
  govEnrollableRounds,
  govTotalStudyTime,
  prequestionOfEnrolled,
} from "../v2/_state/reducers";
import {
  allCheckins,
  bestCheckins,
  isCheckinCreated,
} from "../v2/_state/reducers/checkin.reducer";
import {
  codeSnippets,
  material,
  lecture_v2,
  commonLecture,
} from "../v2/_state/reducers/lecture.reducer";
import { settings } from "../v2/_state/reducers/settings.reducer";
import {
  homeworks,
  homeworkAnswer,
} from "../v2/_state/reducers/homeworks.reducer";
import { badgesRound } from "../v2/_state/reducers/badge.reducer";
import { abtestEnrolled } from "../v2/_state/reducers/abtest.reducer";
import { courseV2 } from "../v2/_state/reducers/courses.reducer";
import { phone_verification } from "../v2/_state/reducers/phone_verification.reducer";
import {
  govRoundStartSoon,
  hasDoneDailyAuth,
  hasGovAuthorized,
  isGovEnrolledBlocked,
  isWrittenGovRecord,
} from "../v2/_state/reducers/gov.reducer";
import {
  isRankGroupCreated,
  createRoundRankGroup,
  UserRaceGroupInfo,
  WeeksAfterStart,
} from "../v2/_state/reducers";
import { spalandDates } from "../v2/_state/reducers/spaland.reducer";
import { studyObjective } from "../v2/_state/reducers/objective.reducer";
import {
  gfsDeadline,
  gfsIsCardReceived,
  govCompletionMilestone,
} from "./gov.reducer";
import { rcmdCourses, rcmdFree } from "../v2/_state/reducers/rcmd.reducer";
import { featureFlags } from "../v2/_state/reducers/features.reducer";
import { changeSpeed } from "../v2/_state/actions/player.action";
import * as v3 from "../v2/_state/v3";
import { hrdLink } from "../v2/_state/v3/hrdLink2";
const rootReducer = combineReducers({
  authentication,
  alert,
  environment,
  courses,
  course,
  rankInfo,
  lecture,
  couponInfo,
  lectureNote,
  codeSnippet,
  homework,
  classroom,
  mypage,
  dashboard,
  commingsoons,
  rcmdDashboards,
  isTestPaid,
  todaydiscount,
  paymentInfo,
  pointInfo,
  lectureNav,
  checkins,
  priceByNumber,
  prequestionSubmitted,
  enrolledDone,
  enrolledOngoing,
  enrolledStatus,
  logCrmAB,
  retrospective,
  paymentInfoByCourseId,
  courseByUserId,
  govCourses,
  coursesByPkgId,
  vouchers,
  friendRecoInfo,
  certs,
  friendRecoChart,
  friendRecoFreeChart,

  // v2
  roundWeeks,
  classroomCards,
  liveUser,
  point,
  enrolledSurvey,
  enrolledSummary,
  courseRetention,
  studyplans,
  points,
  expirationPoints,
  enrolled,
  enrolleds,
  activeEnrolleds,
  round,
  orders,
  authentication2,
  isValidAlpaco,
  expired,
  expiredClassroomCards,
  enrolledPlaytime,
  enrolledReimbursed,
  enrolledPeriod,
  enrolledProgress,
  enrolledRanks,
  enrolledMaker,
  hasSeenOrientation,
  allCheckins,
  bestCheckins,
  enrolledDetail,
  enrolledDetails,
  codeSnippets,
  material,
  broadcastList,
  settings,
  pointDelta,
  homeworks,
  homeworkAnswer,
  isCheckinCreated,
  managed,
  marketingAgreement,
  doneEnrolledDetail,
  badgesRound,
  enrolledBadges,
  abtestEnrolled,
  enrolledDetailObstacle,
  isDetailPresent,
  isSecretStoreAllowed,
  courseV2,
  salesCall,
  isPrequestionExist,
  prequestionOfEnrolled,
  phone_verification,
  paymentReinforced,
  hasDoneDailyAuth,
  hasGovAuthorized,
  isPaymentCounselTarget,
  paymentCounselResult,
  isNomadCouponTarget,
  nomadCouponResult,
  abtestPayment,
  abtestKkoSignup,
  paymentAlert,
  isRankGroupCreated,
  createRoundRankGroup,
  UserRaceGroupInfo,
  WeeksAfterStart,
  userRegNo,
  paymentReinforcedByUser,
  enrolledDoneCount,
  isGovEnrolledBlocked,
  spalandDates,
  studyObjective,
  isWrittenGovRecord,
  paymentOption,
  govRoundStartSoon,
  gfsDeadline,
  govCompletionMilestone,
  govDailyRecord,
  govEnrollableRounds,
  govTotalStudyTime,
  gfsIsCardReceived,
  paymentReviews,
  paymentVBank,
  govAttendRecords,
  rcmdFree,
  enrolledFinishSummary,
  rcmdCourses,
  featureFlags,
  changeSpeed,
  abtestCourseRcmd,
  lecture_v2,
  commonLecture,
  ...v3,
  hrdLink,
});

export default rootReducer;
