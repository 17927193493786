import React, {Component, Fragment} from 'react';
import classNames from "classnames/bind";
import styles from "./CustomModal_MyFriend.module.scss";

const cx = classNames.bind(styles);

class CustomModal_MyFriend extends Component {

    constructor(props) {
        super(props);
    }

    _handleCloseModal = () => {
        this.props.handleModalVisible(false);
    };

    render() {
        const {visible} = this.props;
        const {style} = this.props;
        return (
            <div>
                <div className={
                    visible ?
                        cx("modal", "active") :
                        cx("modal")
                } onClick={this._handleCloseModal}>

                </div>
                <div className={
                    visible ?
                        cx("modal-background", "active") :
                        cx("modal-background")
                } style={{...style}}>
                    <div className={cx("modal-content")}>
                        {this.props.body}
                    </div>
                </div>
            </div>
        );
    }
}

export {CustomModal_MyFriend};