import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./EnrolledSummary.module.scss";
import { connect } from "react-redux";
import CountUp from "react-countup";
import { enrolledSummaryActions, pointActions } from "../../../_state/actions";

const cx = classNames.bind(styles);

function EnrolledSummary({
  user,
  enrolledSummary,
  getEnrolledSummary,
  point,
  getPoint,
}) {
  useEffect(() => {
    if (user) {
      getEnrolledSummary();
      getPoint();
    }
  }, [user]);
  return (
    <div className={cx("summary-wrap")}>
      <div className={cx("summary-card", "total-study")}>
        <div className={cx("thumb")}>
          <img
            src={("/assets/icons/ic_clock.jpg")}
            alt={"icon"}
          />
        </div>
        <div className={cx("summary-detail")}>
          <p className={cx("summary-main")}>
            총{" "}
            <b>
              {enrolledSummary.loaded ? (
                <CountUp
                  start={0}
                  end={enrolledSummary.data.study}
                  duration={2}
                />
              ) : (
                0
              )}
            </b>
            분
          </p>
          <p className={cx("summary-sub")}>총 누적시간</p>
        </div>
      </div>
      <div className={cx("summary-card", "finished-courses")}>
        <div className={cx("thumb")}>
          <img
            src={("/assets/icons/ic_clap.jpg")}
            alt={"icon"}
          />
        </div>
        <div className={cx("summary-detail")}>
          <p className={cx("summary-main")}>
            <b>
              {enrolledSummary.loaded ? (
                <CountUp
                  start={0}
                  end={enrolledSummary.data.finished}
                  duration={2}
                />
              ) : (
                0
              )}
            </b>
            개
          </p>
          <p className={cx("summary-sub")}>완주한 강의</p>
        </div>
      </div>
      <div className={cx("summary-card", "point")}>
        <div className={cx("thumb")}>
          <img
            src={("/assets/icons/ic_moneybag.jpg")}
            alt={"icon"}
          />
        </div>
        <div className={cx("summary-detail")}>
          <p className={cx("summary-main")}>
            <b>
              {point.loaded ? (
                <CountUp
                  start={0}
                  separator={","}
                  end={point.data.amount}
                  duration={2}
                />
              ) : (
                0
              )}
            </b>
            원
          </p>
          <p className={cx("summary-sub")}>내 포인트</p>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, point, enrolledSummary } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    point,
    enrolledSummary,
  };
}

const connectedEnrolledSummary = connect(mapStateToProps, {
  getEnrolledSummary: enrolledSummaryActions.getEnrolledSummary,
  getPoint: pointActions.getPoint,
})(EnrolledSummary);
export { connectedEnrolledSummary as EnrolledSummary };
