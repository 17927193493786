import { useState, useEffect } from "react";
import { useGetLectureV2 } from "../../queries/lecture";

export const useLectureSummary = (lectureId) => {
  const [summary, setSummary] = useState(null);
  const { data: lectureData, isLoading } = useGetLectureV2(lectureId, {
    retry: 3,
  });

  useEffect(() => {
    if (!isLoading && lectureData?.context?.summary) {
      setSummary(lectureData.context.summary);
    }
  }, [lectureData, isLoading]);

  return summary;
};
