import styled from "@emotion/styled";
import {
  mHeader2,
  mTitle1,
  mTitle2,
  neutralDay,
  scc,
  wTitle1,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../_styles/device";

export const Container = styled.article`
  width: 100%;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    width: 772px;
  }
`;

export const ArticleContainer = styled.div`
  display: flex;
  padding: 60px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  width: 100%;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    width: 772px;
    padding: 80px 0px;
    gap: 80px;
  }
`;

export const ArticleHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    gap: 8px;
  }
`;

export const ArticleHeaderCaption = styled.h3`
  width: 100%;
  ${mTitle2}
  color: ${scc.red75};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${mTitle1}
    color: ${scc.red75};
  }
`;

export const ArticleHeaderTitle = styled.h2`
  width: 100%;
  white-space: pre-line;
  ${mHeader2};
  color: ${neutralDay.gray100};
  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wTitle1}
    color: ${neutralDay.gray100};
  }
`;

export const PopularArticlesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  ${DisplayResolution.MiniDesktopAndDesktop} {
    flex-direction: row;
  }
`;
