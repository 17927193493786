export const PrequestionConstant = {
  GET_PREQUESTION_REQUEST: "GET_PREQUESTION_REQUEST",
  GET_PREQUESTION_SUCCESS: "GET_PREQUESTION_SUCCESS",
  GET_PREQUESTION_FAILURE: "GET_PREQUESTION_FAILURE",

  // enrolled 사전 질문 답변 데이터
  PREQUESTION_OF_ENROLLED_REQUEST: "PREQUESTION_OF_ENROLLED_REQUEST",
  PREQUESTION_OF_ENROLLED_SUCCESS: "PREQUESTION_OF_ENROLLED_SUCCESS",
  PREQUESTION_OF_ENROLLED_FAILURE: "PREQUESTION_OF_ENROLLED_FAILURE",
};
