import privateInstance from "../../_helper/axios";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { MAX_FILE_SIZE_MB, StatusEnum } from "../../_state/constants";
import { useAtom } from "jotai";
import { enrolledHomeworkDetailsAtom } from "../../stores/enrolledHomeworkDetails";
import {
  useHandleEnrolledHomeworkDetails,
  useHomeworkData,
} from "./useHomeworkData";
import { fileInfoAtom } from "../../stores/homeworkPageV3Atom";
import { useParams } from "react-router-dom";
import { usePostMyHomeworks } from "../../businesslogics/OrderedEnrollmentContents/repository/usePostMyHomeworks";
import * as Sentry from "@sentry/react";

export const useFileUpload = ({ homeworkIndex = 0 }) => {
  const [fileInfo, setFileInfo] = useAtom(fileInfoAtom);
  const [uploaded, setUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enrolledHomeworkDetails, setEnrolledHomeworkDetails] = useAtom(
    enrolledHomeworkDetailsAtom
  );
  const { enrolled_homework_id } = useParams();
  const { enrolledId, homeworkId, type } =
    useHomeworkData(enrolled_homework_id);
  const { updateEnrolledHomeworkDetail } = useHandleEnrolledHomeworkDetails();
  const hiddenFileInput = useRef(null);
  const postMyHomeworks = usePostMyHomeworks(
    enrolled_homework_id,
    enrolledId,
    homeworkId
  );

  useEffect(() => {
    if (postMyHomeworks.isLoading !== isLoading) {
      setIsLoading(postMyHomeworks.isLoading);
    }
  }, [postMyHomeworks.isLoading]);

  const handleChange = (event) => {
    const KB = 1024;
    const fileUploaded =
      event.target.files?.length > 0
        ? event.target.files[0]
        : event.dataTransfer.files[0];
    if (fileUploaded && fileUploaded.size > MAX_FILE_SIZE_MB * KB * KB) {
      alert(`파일 크기는 ${MAX_FILE_SIZE_MB}MB 이하여야 합니다.`);
      return;
    }

    if (fileUploaded && fileUploaded.type !== "") {
      setFileInfo((prevFileInfo) => {
        const newFileInfo = [...prevFileInfo];
        newFileInfo[homeworkIndex] = fileUploaded;
        return newFileInfo;
      });

      setUploaded(true);
      const newEnrolledHomeworkDetail = {
        ...enrolledHomeworkDetails[homeworkIndex],
        language: "file",
        submitted_answer: fileUploaded.name,
        file_name: fileUploaded.name,
      };
      updateEnrolledHomeworkDetail(homeworkIndex, newEnrolledHomeworkDetail);
    } else {
      alert("파일 업로드에 실패했습니다. 다시 시도해주세요.");
    }
  };

  function checkFileBeforeUpload() {
    if (
      enrolledHomeworkDetails?.[homeworkIndex]?.feedback_status === "SUBMITTED"
    ) {
      alert(
        "숙제가 이미 제출되었습니다! 올바른 형식으로 숙제를 제출하셨는지 24시간 이내 검토 후, 카카오톡으로 안내드릴 예정입니다."
      );
    } else {
      if (!fileInfo[0] || fileInfo[0]?.size < 100) {
        //보통 100바이트 미만인 경우 메타데이터로 들어오기 때문에 해당 조건 추가
        alert(
          "100B 미만의 파일은 업로드할 수 없습니다. 다시 한 번 시도해주세요!"
        );
        window.location.reload();
        return;
      }
      return new Promise((resolve, reject) => {
        // 파일 타입 검증
        //TODO: 0716 제출이 된 파일이 없다면..!

        if (!(fileInfo[0] instanceof Blob || fileInfo[0] instanceof File)) {
          alert("파일 제출 오류가 발생했습니다. 다시 한 번 시도해주세요!");
          window.location.reload();
          reject(new TypeError("Provided value is not of type 'Blob'."));
          return;
        }

        const reader = new FileReader();
        reader.onload = () => resolve(true); // 파일 읽기 성공 시
        reader.onerror = () => reject(false); // 파일 읽기 실패 시

        reader.readAsArrayBuffer(fileInfo[0]);
      });
    }
  }
  const handleUpload = async () => {
    const updatedDetails = [...enrolledHomeworkDetails];
    try {
      const urlsPromises = fileInfo.map(async (fileInfoItem) => {
        const { name } = fileInfoItem;
        const resp = await privateInstance.get(
          `/aws/s3/presignedurl?file_name=${name}`,
          Object.assign({ skipDuplicatedCheck: true }, privateInstance.config)
        );
        return resp.data;
      });
      const resp = await Promise.all(urlsPromises);

      const presignedUrls = resp.map((item) => item.context.url);

      const fileIndexes = [];
      type.forEach((item, index) => {
        if (item === "file") {
          fileIndexes.push(index);
        }
      });
      await Promise.all(
        fileIndexes.map(async (fileIndex) => {
          const presignedUrl = presignedUrls[fileIndex];
          const fileInfoItem = fileInfo[fileIndex];
          if (resp[fileIndex].context.content_type === "") {
            alert("업로드에 실패했습니다. 다시 시도해주세요.");
            return;
          }
          const options = {
            headers: {
              "Content-Type": resp[fileIndex].context.content_type,
            },
          };

          await axios.put(presignedUrl, fileInfoItem, options);
          updatedDetails[fileIndex] = {
            ...enrolledHomeworkDetails[fileIndex],
            file_name: fileInfoItem.name,
            submitted_answer: presignedUrl,
            feedback_status: StatusEnum.SUBMITTED,
          };
        })
      );
      setEnrolledHomeworkDetails(updatedDetails);
      setFileInfo([]);
      await postMyHomeworks.mutateAsync(updatedDetails); //함수 내에서 바로 업로드하도록 수정
    } catch (error) {
      console.error("파일 업로드에 실패했습니다.", error);
      alert("파일 업로드에 실패했습니다.");
      Sentry.captureException("KDC 과목 숙제", error);
    }
  };

  const handleFileUploadButtonClick = () => {
    hiddenFileInput.current.click();
  };
  return {
    isLoading,
    uploaded,
    fileInfo,
    hiddenFileInput,
    handleChange,
    handleUpload,
    checkFileBeforeUpload,
    handleFileUploadButtonClick,
  };
};
