import styled from "@emotion/styled";
import {
  additional,
  mBody2,
  mBody3,
  mHeader2,
  mTitle2,
  mTitle3,
  neutralDay,
  wBody1,
  wBody2,
  wTitle1,
  wTitle2,
  wTitle3,
  wTitle4,
  wTitle5,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";
export const EvaluationSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  margin: 0 auto;
  max-width: 772px;
  width: 100%;
  ${DisplayResolution.MobileAndMiniTablet} {
    gap: 16px;
    align-items: flex-start;
    width: 100%;
  }
`;

export const EvaluationSummaryTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  ${DisplayResolution.TabletAndDesktop} {
    align-items: center;
    gap: 12px;
  }
`;

export const EvaluationRetryableContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

export const EvaluationRetryableText = styled.h1`
  ${mBody3};
  color: ${additional.blue100};
  text-align: center;
  cursor: pointer;
  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    color: ${additional.blue100};
  }
`;

export const EvaluationSummaryUpperTitle = styled.div`
  ${mTitle2};
  color: ${(props) => (props.color ? props.color : neutralDay.gray70)};
  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle2};
    color: ${(props) => (props.color ? props.color : neutralDay.gray70)};
  }
`;

export const EvaluationSummaryText = styled.div`
  ${mTitle3};
  color: ${(props) => (props.color ? props.color : neutralDay.gray100)};
  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle3};
    color: ${(props) => (props.color ? props.color : neutralDay.gray100)};
  }
`;

export const EvaluationSummaryMiddleTitle = styled.div`
  ${mHeader2};
  align-self: stretch;
  color: ${neutralDay.gray100};
  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle1};
    color: ${neutralDay.gray100};
    align-self: center;
  }
`;

export const EvaluationSummaryUnderTitle = styled.div`
  ${mBody2};
  color: ${(props) => (props.color ? props.color : neutralDay.gray70)};
  ${DisplayResolution.TabletAndDesktop} {
    ${wBody2};
    color: ${(props) => (props.color ? props.color : neutralDay.gray70)};
  }
`;

export const EvaluationSummaryBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  ${DisplayResolution.TabletAndDesktop} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
`;

export const EvaluationSummaryBoard = styled.div`
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  ${DisplayResolution.TabletAndDesktop} {
    width: 100%;
  }
`;

export const EvaluationSummaryBoardWrapper = styled.div`
  ${DisplayResolution.MobileAndMiniTablet} {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
