export const enrolledDoneConstants = {
  ENROLLED_DONE_REQUEST: "ENROLLED_DONE_REQUEST",
  ENROLLED_DONE_SUCCESS: "ENROLLED_DONE_SUCCESS",
  ENROLLED_DONE_FAILURE: "ENROLLED_DONE_FAILURE",
};

export const enrolledStatusConstants = {
  ENROLLED_STATUS_REQUEST: "ENROLLED_STATUS_REQUEST",
  ENROLLED_STATUS_SUCCESS: "ENROLLED_STATUS_SUCCESS",
  ENROLLED_STATUS_FAILURE: "ENROLLED_STATUS_FAILURE",
};

export const enrolledVoucher = {
  ENROLLED_VOUCHER_REQUEST: "ENROLLED_VOUCHER_REQUEST",
  ENROLLED_VOUCHER_SUCCESS: "ENROLLED_VOUCHER_SUCCESS",
  ENROLLED_VOUCHER_FAILURE: "ENROLLED_VOUCHER_FAILURE",
};

export const enrolledOngoingConstants = {
  ENROLLED_ONGOING_REQUEST: "ENROLLED_ONGOING_REQUEST",
  ENROLLED_ONGOING_SUCCESS: "ENROLLED_ONGOING_SUCCESS",
  ENROLLED_ONGOING_FAILURE: "ENROLLED_ONGOING_FAILURE",
};
