import React from "react";
import classNames from "classnames/bind";
import styles from "./ClassCards.module.scss";
import { ClassCardNone } from "./ClassCard/ClassCardNone/ClassCardNone";
import { connect } from "react-redux";
import { ClassCard } from "./ClassCard/ClassCard";

const cx = classNames.bind(styles);

function ClassCards({ courses, current_type, environment }) {
  return (
    <>
      {current_type === "purchased" &&
        courses.map((course_type) => {
          const isNotFinished = courses.indexOf(course_type) === 0;
          return (
            <>
              <div className={cx("section_title")}>
                <div>{isNotFinished ? "수강 중 강의" : "지난 강의"}</div>
              </div>
              {course_type?.length === 0 && (
                <ClassCardNone
                  current_type={isNotFinished ? "purchased" : "done"}
                />
              )}
              <div className={cx("card_wrapper")}>
                {course_type.map((c) => {
                  const {
                    enrolled: { end_date },
                  } = c;
                  return (
                    <div>
                      <ClassCard
                        course_data={c}
                        key={courses.indexOf(c)}
                        current_type={
                          courses.indexOf(course_type) === 0
                            ? "purchased"
                            : "done"
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      {current_type === "voucher" && courses?.length > 0 && (
        <>
          <div className={cx("card_wrapper")}>
            {courses.map((c) => {
              return (
                <div>
                  <ClassCard
                    course_data={c}
                    key={courses.indexOf(c)}
                    current_type={current_type}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
      {current_type === "voucher" && courses?.length === 0 && (
        <ClassCardNone current_type={current_type} />
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { environment } = state;
  return { environment };
}

const connectedClassCards = connect(mapStateToProps, {})(ClassCards);
export { connectedClassCards as ClassCards };
