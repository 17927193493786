import { WeekHeader } from "./WeekHeader";
import { WeekContents } from "./WeekContents";
import * as S from "./Week.style";

export const Week = ({ weekData }) => {
  const { totalPlaytime, week } = weekData;

  return (
    <S.Background>
      <WeekHeader totalPlaytime={totalPlaytime} week={week} />
      <S.DottedHr />
      <WeekContents contents={weekData.contents} />
    </S.Background>
  );
};
