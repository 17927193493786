import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions";
import {
  BeforeCredit,
  BeforeSons,
  Credit,
  ImgIncludeCta,
  Pirates,
  Sons,
  Timer,
} from "./RoadmapBannerContent";

function RoadmapBanner({ round, enrolled, WeeksAfterStart, isMobile }) {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    if (!enrolled.loaded || !round.loaded) {
      return;
    }

    const {
      is_forever,
      is_b2b,
      is_hanghae,
      is_gov_training,
      is_gov_intro,
      is_not_under_spaland,
      start_date,
    } = round.data;

    const { is_trial } = enrolled.data.courses;

    // 문법강의, 평생무료강의, b2b, 항해, 트레이닝은 배너 노출을 하지 않는다.
    // 평생무료강의 중에서 비즈니스 요구에 따라 보여주어야 하는 경우
    let isHidden =
      is_trial ||
      (is_forever && !is_gov_intro) ||
      is_b2b ||
      is_hanghae ||
      is_gov_training ||
      is_not_under_spaland;

    setIsBannerVisible(!isHidden);
  }, [enrolled.loaded, round.loaded]);

  const isTrial = enrolled.data?.courses.is_trial;
  const isGovIntro = round.data?.is_gov_intro;
  const isGovCredit = round.data?.is_gov;
  const isEvent = round.data?.is_event;
  const isPirates = round.data?.is_pirates;
  const { course_id } = enrolled.data;
  const isSqlCourse = (course_id) => {
    return course_id === "6433b6aab8d11cc10a00b294";
  };

  const bannerType = (() => {
    if (
      !isGovIntro &&
      !isGovCredit &&
      isBannerVisible &&
      !isEvent &&
      !isPirates &&
      !isSqlCourse(course_id)
    ) {
      return "sons";
    } else if (isGovCredit) {
      return "credit";
    } else if (isEvent) {
      return "timer";
    } else if (isGovIntro) {
      return "img-include-cta";
    }
    // else if (isPirates) {
    //   return "pirates";
    // }
  })();

  const Banner = {
    // sons: <Sons course_id={course_id} enrolled={enrolled.data} />,
    timer: <Timer enrolled={enrolled} />,
    "img-include-cta": (
      <ImgIncludeCta
        isGov={isGovCredit}
        isGovIntro={isGovIntro}
        isTrial={isTrial}
        isMobile={isMobile}
      />
    ),
    pirates: <Pirates />,
  };

  return <>{!isMobile && Banner[bannerType]}</>;
}

function mapStateToProps(state) {
  const { authentication, round, enrolled, WeeksAfterStart, activeEnrolleds } =
    state;
  const { user } = authentication;
  return {
    authentication,
    user,
    round,
    enrolled,
    WeeksAfterStart,
    activeEnrolleds,
  };
}

const connectedRoadmapBanner = connect(mapStateToProps, {
  getActiveEnrolleds: enrolledActions.getActiveEnrolleds,
})(RoadmapBanner);
export { connectedRoadmapBanner as RoadmapBanner };
