import classNames from "classnames/bind";
import styles from "../../../pages/NPSPage/NPSNextBtn/NPSNextBtn.module.scss";
import { useOnlineTheme } from "../../../queries/pirates";
import React, { useEffect, useState } from "react";
import { TooltipBg } from "../../../pages/LecturePage/LecNextBtn/LecNextBtn.svg";
import { useCreateB2bAdminNpsResults } from "../../../queries/b2bAdminNps";
import { checkIsAllAnswered } from "../../../businesslogics/b2bAdminNps";
import { history } from "../../../../_helpers";

const cx = classNames.bind(styles);

export const B2bAdminNPSNextBtn = ({ npsResultData, enrolled }) => {
  const [isTooltipShow, setIsTooltipShow] = useState(false);
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const theme = useOnlineTheme();
  const { mutate: mutateCreateResult } =
    useCreateB2bAdminNpsResults(npsResultData);

  useEffect(() => {
    setIsAllAnswered(checkIsAllAnswered(npsResultData));
  }, [npsResultData]);

  const onClickSubmit = () => {
    if (!isAllAnswered) {
      alert("모든 설문을 완료해주세요.");
      return;
    }

    mutateCreateResult(npsResultData, {
      onSuccess: (data) => {
        alert("설문이 제출되었습니다.");
        history.push(
          `/enrolleds/${enrolled?.data?.enrolled_id}/rounds/${enrolled?.data?.round_id}/roadmap`
        );
      },
    });
  };

  return (
    <>
      <div
        className={cx(
          "nps-next-btn",
          theme.data,
          isAllAnswered ? "--active" : ""
        )}
        onClick={() => onClickSubmit()}
      >
        {"제출하고 완강하기"}
        <svg
          className={cx("nps-next-btn__img", theme.data)}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="9"
          fill="none"
          viewBox="0 0 14 9"
        >
          <path
            fill={isAllAnswered ? "#fff" : "#141617"}
            d="M1.623 0l5.285 5.342L12.192 0l1.623 1.653-6.907 6.982L0 1.653 1.623 0z"
          />
        </svg>
        {isTooltipShow && (
          <div className={cx("wrapper")}>
            <div
              className={cx("nps-next-btn__tooltip", theme.data)}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={cx("nps-next-btn__tooltip__bg-wrapper")}>
                <TooltipBg theme={theme.data} />
              </div>
              <img
                className={cx("nextCheck")}
                src={`/assets/images/lecture/next_tooltip_check.png`}
                alt=""
              />
              <div>
                어서 설문을 제출하고,
                <br /> 완주 축하를 확인해보세요!
              </div>

              <img
                className={cx("nextClose")}
                src={`/assets/images/lecture/nex_tooltip_close.png`}
                onClick={() => setIsTooltipShow(false)}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
