import { config } from "../config";
import { handleResponse, authHeader, privateFetch } from "../_helpers";

function getFriendRecoInfo() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/rcmds/friends`;
  return privateFetch(endpoint, requestOptions).then(handleResponse);
}

function getFriendRecoChart() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  let endpoint = `${config.apiUrl}/v1/rcmds/friends/list`;
  return fetch(endpoint, requestOptions).then(handleResponse);
}

function getFriendRecoFreeChart() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  let endpoint = `${config.apiUrl}/v1/rcmds/friends/free/list`;
  return fetch(endpoint, requestOptions).then(handleResponse);
}

export const friendRecoService = {
  getFriendRecoInfo,
  getFriendRecoChart,
  getFriendRecoFreeChart,
};
