import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { ConfirmModal } from "../ModalBottomSheetWrapper";
import * as S from "./kdcModal.style";

export const KdcModal = ({ isModalShow, setIsModalShow }) => {
  return (
    <ConfirmModal
      isModalShow={isModalShow}
      setIsModalShow={setIsModalShow}
      onConfirm={() => {
        sendCPLog("scc_paymentPage_modal_click", {
          button_text: "확인",
          context: "내일배움카드로 결제해 주세요",
        });
      }}
    >
      <S.ContentContainer>
        <S.NbCardImg src={"/assets/images/payment/nb_card.png"} alt="NB 카드" />
        <S.TextContainer>
          <S.Title>
            <b>내일배움카드</b>로 결제해 주세요.
          </S.Title>
          <S.SubTitle>
            국비지원 강의는 국민내일배움카드로만 결제 및 신청 가능하며, 일반
            카드로는 결제할 수 없습니다.
          </S.SubTitle>
        </S.TextContainer>
      </S.ContentContainer>
    </ConfirmModal>
  );
};
