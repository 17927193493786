import styled from "@emotion/styled";
import {
  neutralDay,
  mCaption2,
  wBody3,
  mBody3,
  wTitle1,
  mHeader2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const HomeworkContainer = styled.div`
  display: flex;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
  opacity: ${({ status }) => (status === "미제출" ? "0.5" : "1")};
  ${DisplayResolution.Mobile} {
    padding: 20px;
  }
`;

export const HomeworkDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${DisplayResolution.Mobile} {
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StatusTagWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
`;
export const StatusTag = styled.div`
  display: flex;
  padding: 4px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  white-space: nowrap;
  color: ${({ status }) =>
    (status === "검토 대기" && "#FF7005") ||
    (status === "피드백 대기" && "#FF7005") ||
    (status === "검토 반려" && "#FF3E3E") ||
    (status === "미제출" && "#5F666B") ||
    (status === "피드백 완료" && "#31B32E")};
  background: ${({ status }) =>
    (status === "검토 대기" && "#FFF0E5") ||
    (status === "피드백 대기" && "#FFF0E5") ||
    (status === "검토 반려" && "#FFEBEB") ||
    (status === "미제출" && "#5F666B1A") ||
    (status === "피드백 완료" && "#31b32e1a")};
  ${DisplayResolution.Mobile} {
    font-size: 12px;
  }
`;

export const HomeworkTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${DisplayResolution.Mobile} {
    flex-direction: column;
    align-items: flex-start;
    //padding-left: 5px;
  }
`;

export const HomeworkTitle = styled.div`
  ${wBody3};
  color: ${neutralDay.gray90};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SubmissionData = styled.div`
  display: flex;
  ${mBody3};
  font-weight: 500;
  align-items: center;
  color: #81898f;
  ${DisplayResolution.Mobile} {
    margin-top: 16px;
    font-weight: 500;
    width: 100%;
  }
`;

export const SubmissionContents = styled.div`
  display: flex;
  justify-content: center;
  text-decoration: none;
  ${mBody3};
  color: #0085ff;
  margin-left: 6px;
  cursor: pointer;

  &:hover {
    color: #0085ff; /* 호버 시 색 변경하지 않도록 현재 색상 유지 */
  }
`;
export const DownloadIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`;

export const Devider = styled.div`
  width: 100%;
  height: 1px;
  background: ${neutralDay.gray20};
  ${DisplayResolution.Mobile} {
    margin: 12px 0;
  }
`;

export const DoneFeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const FeedbackWrapper = styled.div`
  ${wBody3};
  color: ${neutralDay.gray90};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const FeedbackDate = styled.div`
  ${mCaption2};
  color: #31b32e;
`;

export const FeedbackContent = styled.div`
  align-self: stretch;
  color: ${neutralDay.gray80};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  padding: 0 28px 16px 28px;
  ${DisplayResolution.Mobile} {
    padding: 0 0 12px 26px;
  }
`;

export const DropdownIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 32px;
  cursor: pointer;
`;

export const SubmittedHomeworkContentWrapper = styled.div`
  display: flex;
  width: 460px;
  height: 600px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  position: relative;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    height: 80vh;
    padding: 28px 20px;
  }
`;

export const SubmittedHomeworkContentCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px;
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    top: 28px;
    right: 20px;
    padding: 0;
  }
`;

export const SubmittedHomeworkContentTitle = styled.div`
  ${wTitle1};
  color: ${neutralDay.gray100};
  line-height: 34px;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mHeader2};
    color: ${neutralDay.gray100};
    line-height: 28px;
  }
`;

export const SubmittedHomeworkContentArea = styled.div`
  display: flex;
  width: 460px;
  height: 502px;
  padding: 32px;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 0;
  word-wrap: break-word;
  word-break: break-all;
  right: 0;
  border-top: 1px solid ${neutralDay.gray20};
  background-color: ${neutralDay.gray5};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: auto;
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: calc(80vh - 88px);
  }
`;

export const SubmittedHomeworkContentMarkdownRoot = styled.div`
  width: 100%;
  & body {
    background-color: ${neutralDay.gray5};
  }
  & img {
    width: 396px;
    ${DisplayResolution.MobileAndMiniTablet} {
      width: calc(100%);
    }
  }
`;
