import styled from "@emotion/styled";
import { DisplayResolution } from "../../../_styles/device";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${DisplayResolution.MiniDesktopAndDesktop} {
    width: 772px;
  }
`;
