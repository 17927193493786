import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./B2bNPSPage.module.scss";
import { LecMenuComponent } from "../LecturePage/LecMenu/LecMenuComponent";
import { lectureMenuComponents } from "../LecturePage/LecMenu/constants";
import { BroadcastContainer } from "../LecturePage/BroadcastContainer";
import { B2bNPSBanner } from "./B2bNPSBanner/B2bNPSBanner";
import { enrolledActions } from "../../_state/actions";
import { coursesActions } from "../../_state/actions/courses.actions";
import { sendLog } from "../../businesslogics/logging";
import { B2bNPSFooter } from "./B2bNpsCta/B2bNpsCta";
import { getQuestions } from "./B2bNPSPage.model";
import B2bNpsQuestion from "./B2bNpsQuestion/B2bNpsQuestion";

const cx = classNames.bind(styles);

function B2bNPSPage({
  match,
  user,
  roundWeeks,
  enrolled,
  getRoundWeeks,
  setEnrolled,
  courseV2,
  getCourse,
  postB2bSurvey,
}) {
  const { enrolled_id, week } = match.params;
  const [isDual, setIsDual] = useState(false);
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    setEnrolled(enrolled_id);
  }, [enrolled_id, week]);

  useEffect(() => {
    if (!enrolled.loaded) return;
    if (!courseV2.loaded) getCourse(enrolled.data.course_id);
    setQuestions(getQuestions(enrolled.data.round_id));
  }, [enrolled.loaded]);

  useEffect(() => {
    if (!roundWeeks.loaded) {
      getRoundWeeks(enrolled_id);
    }
  }, [roundWeeks.loaded]);

  useEffect(() => {
    if (enrolled.loaded) {
      sendLog("scc_npsPage_view", { week, ...enrolled.data });
    }
  }, [enrolled.loaded]);

  const handleAnswerChanged = (e, index) => {
    setQuestions((prevState) => {
      let temp = [...prevState];
      temp[index]["answer"] = e.target.value;
      return temp;
    });
  };

  return (
    <div>
      {user && (
        <div>
          <div className={cx("nps-page__nav", `${isDual ? "dual" : ""}`)}>
            <LecMenuComponent
              component={lectureMenuComponents[0]}
              idx={0}
              isDual={isDual}
              isNav={true}
            />
            <BroadcastContainer />
          </div>
          <B2bNPSBanner user={user} />
          <div className={cx("nps-page__content")}>
            {questions && (
              <section>
                {questions.map((question, index) =>
                  B2bNpsQuestion({ index, question, handleAnswerChanged })
                )}
              </section>
            )}
          </div>
          <B2bNPSFooter
            enrolled_id={enrolled_id}
            questions={questions}
            result={questions}
          />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, roundWeeks, enrolled, courseV2 } = state;
  const { user } = authentication;
  return { user, roundWeeks, enrolled, courseV2 };
}

const connectedB2bNPSPage = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getRoundWeeks: enrolledActions.getRoundWeeks,
  getCourse: coursesActions.getCourse,
})(B2bNPSPage);
export { connectedB2bNPSPage as B2bNPSPage };
