import { rcmdDashboardConstants } from "../_constants";
import { rcmdDashboardService } from "../_services";

function getRcmdDashboardInfo() {
  function request() {
    return { type: rcmdDashboardConstants.RCMD_DASHBOARD_REQUEST };
  }

  function success(response) {
    return { type: rcmdDashboardConstants.RCMD_DASHBOARD_SUCCESS, response };
  }

  function failure(error) {
    return { type: rcmdDashboardConstants.RCMD_DASHBOARD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    rcmdDashboardService.getRcmdDashboard().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const rcmdDashboardActions = {
  getRcmdDashboardInfo,
};
