import React, { useState } from "react";
import { LectureMobileNavBar } from "../LectureMobileNavBar/LectureMobileNavBar";
import { LectureMobileFloating } from "../LectureMobileFloating/LectureMobileFloating";
import { LectureTapMenu } from "../LectureTapMenu/LectureTapMenu";
import * as S from "./LectureMobile.style";
import { LecPlayerV3 } from "../../LecturePage/LecPlayer/LecPlayerV3/LecPlayerV3";

export const LectureMobile = ({ isInit }) => {
  const isInitialized = isInit;
  const [playerType, setPlayerType] = useState("V2");

  return (
    <>
      <S.MobileWrapper>
        <LectureMobileNavBar />
        <S.VideoLoadingWrapper />
        {/* 영상 */}
        {isInit && (
          <LecPlayerV3 playerType={playerType} setPlayerType={setPlayerType} />
        )}
        <LectureTapMenu />
        <LectureMobileFloating isInitialized={isInitialized} />
      </S.MobileWrapper>
    </>
  );
};
