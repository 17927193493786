import styled from "@emotion/styled";
import { scc } from "@teamsparta/design-system";

export const ControllerBox = styled.div`
  position: absolute;
  pointer-events: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background-color: transparent;
  opacity: ${({ isVisible, isPlaying }) =>
    !isPlaying || isVisible ? "0.7" : "0"};
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 20px;
`;

export const ControllerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-bottom: 15px;
`;

export const PlayAndTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
`;

export const CurrentTimeWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #fff;
`;

export const SlashWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #fff;
`;

export const TotalTimeWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #fff;
`;

export const OtherControllerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
`;

export const MuteControllerWrapper = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const ProgressBarWrapper = styled.input`
  position: absolute;
  -webkit-appearance: none;
  bottom: 0;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    ${scc.red100} 0%,
    ${scc.red100} var(--progress),
    #d8d8d8 var(--progress),
    #d8d8d8 100%
  );
  outline: none;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${scc.red100};
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  &:hover::-webkit-slider-thumb {
    opacity: 1;
  }
`;

export const PipControllerWrapper = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FullscreenButtonWrapper = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
