import React from "react";
import classNames from "classnames/bind";
import { connect, useSelector } from "react-redux";
import styles from "./CourseRcmdChang.module.scss";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

function CourseRcmdChang({ user, isEmbed, course }) {
  const enrolled = useSelector((state) => state.enrolled);
  return (
    <div
      className={cx("course_rcmd__wrapper", `${isEmbed ? "--isEmbed" : ""}`)}
      style={{
        "--bg-image": `url(${"/assets/images/finish/bg_chang.png"}`,
      }}
    >
      <div className={cx("course_rcmd__header")}>
        <h1>
          내 아이디어로 창업하고 싶은
          <br />
          {user.name}님을 위한 추천 코스
        </h1>
      </div>
      <div className={cx("course_rcmd__body")}>
        <img src={"/assets/images/finish/logo_chang.png"} alt={"logo_chang"} />
        <h2 className={cx("course_rcmd__title")}>
          직장인 창업 부트캠프 {"{"}창{"}"}
        </h2>
        <div className={cx("course_rcmd__label")}>
          <span>직장인 부트캠프 앱 런칭률 95%</span>
          <span>강의 완주율 83%</span>
        </div>
        <div className={cx("course_rcmd__detail")}>
          <p>
            평일에는 직장인, 주말에는 창업가로&nbsp;
            <br />제 2의 삶에 도전하세요.
          </p>
          <ul>
            <li>퇴사하고 창업하고 싶은데 엄두가 나지 않는 직장인</li>
            <li>사업 아이디어는 있지만 개발이 막막해 포기하신 분</li>
            <li>앱 서비스로 부수적인 수입을 만들고 싶은 N잡러</li>
          </ul>
        </div>
      </div>
      <div className={cx("course_rcmd__footer")}>
        <button
          onClick={() => {
            sendLogActionRcmdPage(
              "chang",
              "chang",
              "cta",
              "자세히 알아보기",
              "https://chang.spartacodingclub.kr",
              "mainPage",
              enrolled.data
            );
            window.open("https://chang.spartacodingclub.kr");
          }}
        >
          자세히 알아보기
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdChang = connect(mapStateToProps, {})(CourseRcmdChang);
export { connectedCourseRcmdChang as CourseRcmdChang };
