import React, { useMemo, useState } from "react";
import { history } from "../../../_helpers";
import { useLocation } from "react-router-dom";
import { useLoginRequired } from "../../hooks/auth";
import { useTest } from "../../_state/v3/roundTest";
import { SelectionInput } from "../HomeworkPage/HomeworkForm/HomeworkFormSelection/HomeworkFormSelection";
import styled from "styled-components";
import { Modal } from "../../components/organisms/Modal/Modal";
import { useEnrolledGrading } from "../../_state/v3/enrolledGrading";
import useTimerRef from "../../../hooks/useTimer";

export const TestPage = () => {
  const login = useLoginRequired();

  const location = useLocation();
  const enrolled = location.state?.enrolled;
  const roundId = enrolled?.data?.round_id;
  const test = useTest(roundId);

  const [grade, getGrade] = useEnrolledGrading();
  const isGradingFinished = grade?.loaded;
  const isTestPassed = grade?.data?.is_passed;
  const score = grade?.data?.score;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selections, setSelections] = useState(["X", "X", "X", "X", "X"]);
  const hasTestSystem = enrolled?.data?.courses.is_b2g2b;
  const hasNeedToTest =
    hasTestSystem &&
    enrolled?.data?.progress_rate >= 80 &&
    !enrolled?.data?.has_passed_test;
  const aMinute = 60000;
  const aHour = 60 * aMinute;
  const endTime = useMemo(() => new Date(new Date().getTime() + aHour), []);
  const endTimeRef = useTimerRef(
    endTime,
    [isSubmitted],
    "minute",
    false,
    "#000",
    () => {
      if (endTime < new Date()) {
        if (!isSubmitted) {
          alert("시험 시간을 초과하셨습니다.");
          getGrade(enrolled?.data?.enrolled_id, selections);
          setIsSubmitted(true);
        }
      }
    }
  );

  if (!hasNeedToTest) {
    alert("시험 대상자가 아닙니다.");
    history.replace("/classroom");
  }

  if (!login) {
    return <></>;
  }

  return (
    <Bg>
      <Modal
        visible={isSubmitted}
        handleVisible={() => {}}
        style={{}}
        header={isGradingFinished ? "채점 완료" : "채점 중"}
        content={
          isGradingFinished ? (
            isTestPassed ? (
              <>
                시험 채점 결과: {score} / 100 <br />
                축하합니다. 강의를 수료하셨습니다.
              </>
            ) : (
              <>
                시험 채점 결과: {score} / 100 <br />
                아쉽게도 강의수료를 하시지 못했습니다.
              </>
            )
          ) : (
            "채점 중"
          )
        }
        cta={
          isGradingFinished
            ? {
                btn_text: "내 강의실로 돌아가기",
                handleCTAClick: () => {
                  history.replace("/classroom");
                },
              }
            : null
        }
      />
      <Nav>
        <Logo src={("/v2/assets/images/logo.png")} />
        <Title>수료 시험</Title>
        <Cta
          onClick={() => {
            if (
              window.confirm(
                "5문제 중 3문제 이상 맞춰야 수료 가능합니다. 정말 제출하시나요?"
              )
            ) {
              getGrade(enrolled?.data?.enrolled_id, selections);
              setIsSubmitted(true);
            }
          }}
        >
          답안 제출하기
        </Cta>
      </Nav>
      <Main>
        <Header>
          남은 시험시간:{" "}
          {isSubmitted ? <span>종료</span> : <span ref={endTimeRef}></span>}
          <hr style={{ border: "dashed 1px black", margin: "32px 0" }} />
        </Header>
        {test.loaded && (
          <QuestionContainer>
            {test.data.questions.map((question, idx) => (
              <Question>
                <QTitle>{question.title}</QTitle>
                <QSelections>
                  <SelectionInput
                    selectionList={question.selections}
                    onChange={(value) => {
                      setSelections((prevState) => {
                        const next = [...prevState];
                        next.splice(idx, 1, value);
                        return next;
                      });
                    }}
                  />
                </QSelections>
              </Question>
            ))}
          </QuestionContainer>
        )}
      </Main>
    </Bg>
  );
};

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

const Question = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
`;

const QTitle = styled.div`
  padding: 16px;
  background-color: #f1f1f1;
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 16px;
  white-space: pre-wrap;
`;

const QSelections = styled.div`
  padding: 24px 14px;
`;

const Bg = styled.div`
  background-color: #f1f3f5;
  min-height: 100vh;
`;

const Header = styled.div`
  margin-top: 57px;
  font-family: "GmarketSans";
  font-weight: 500;
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.96px;
  color: #000;
`;

const Main = styled.div`
  max-width: 850px;
  margin: auto;
`;

const Nav = styled.div`
  max-width: 850px;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;

const Logo = styled.img`
  height: 20px;
`;

const Cta = styled.div`
  cursor: pointer;
  text-align: center;

  background-color: #000;
  color: #fff;
  border-radius: 100px;

  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -1.08px;
  padding: 14px 20px;
`;

const Title = styled.div`
  text-align: center;
  width: 100%;
  max-width: 410px;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -1.08px;
  color: #000;

  background-color: #fff;
  padding: 14px 20px;

  border-radius: 100px;
`;

export default TestPage;
