import React from "react";
import DaumPostcode from "react-daum-postcode";
import * as S from "./SearchPost.style";

function SearchPost({ setDeliveryInfo, setIsOpenPost }) {
  const handleComplete = (data) => {
    setDeliveryInfo((prev) => ({
      ...prev,
      postCode: data.zonecode,
      address: data.address,
    }));
    setIsOpenPost(false);
    document.body.style.overflow = "auto";
  };
  const postCodeStyle = {
    display: "block",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    padding: "7px",
  };
  return (
    <S.Bg onClick={() => setIsOpenPost(false)}>
      <DaumPostcode style={postCodeStyle} onComplete={handleComplete} />
    </S.Bg>
  );
}

export { SearchPost };
