import {
  CODE_SNIPPETS,
  MATERIALS,
  COMMON_LECTURE,
  LECTURE_V2,
} from "../constants";
import { lectureService } from "../services";

function getCommonLecture(common_lecture_id) {
  function request() {
    return { type: COMMON_LECTURE.COMMON_LECTURE_REQUEST };
  }

  function success(response) {
    return { type: COMMON_LECTURE.COMMON_LECTURE_SUCCESS, response };
  }

  function failure(error) {
    return { type: COMMON_LECTURE.COMMON_LECTURE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    lectureService.getCommonLecture(common_lecture_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getLectureV2(lecture_id) {
  function request() {
    return { type: LECTURE_V2.LECTURE_V2_REQUEST };
  }

  function success(response) {
    return { type: LECTURE_V2.LECTURE_V2_SUCCESS, response };
  }

  function failure(error) {
    return { type: LECTURE_V2.LECTURE_V2_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    lectureService.getLectureV2(lecture_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetLectureV2 = (lecture_id) => {
  return getLectureV2(lecture_id);
};

function getCodeSnippets(enrolled_id, detail_id) {
  function request() {
    return { type: CODE_SNIPPETS.CODE_SNIPPETS_REQUEST };
  }

  function success(response) {
    return { type: CODE_SNIPPETS.CODE_SNIPPETS_SUCCESS, response };
  }

  function failure(error) {
    return { type: CODE_SNIPPETS.CODE_SNIPPETS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    lectureService.getCodeSnippets(enrolled_id, detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetCodeSnippets = (enrolled_id, detail_id) => {
  return getCodeSnippets(enrolled_id, detail_id);
};

function getMaterial(enrolled_id, detail_id) {
  function request() {
    return { type: MATERIALS.MATERIALS_REQUEST };
  }

  function success(response) {
    return { type: MATERIALS.MATERIALS_SUCCESS, response };
  }

  function failure(error) {
    return { type: MATERIALS.MATERIALS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    lectureService.getMaterial(enrolled_id, detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetMaterial = (enrolled_id, detail_id) => {
  return getMaterial(enrolled_id, detail_id);
};

function getMaterialByEnrolled(enrolled_id, week) {
  return (dispatch) => {
    return lectureService.getMaterialByEnrolled(enrolled_id, week);
  };
}

export const lectureActions = {
  getCodeSnippets,
  getMaterial,
  getMaterialByEnrolled,
  getLectureV2,
};
