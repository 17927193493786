import { lectureConstants } from "../_constants";
import { history } from "../_helpers";
const initState = {
  loaded: false,
};

let count = 0;

export function lecture(state = initState, action) {
  switch (action.type) {
    case lectureConstants.LECTURE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case lectureConstants.LECTURE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case lectureConstants.LECTURE_FAILURE:
      if (action.error === "기한이 지나 수강하실 수 없습니다.") {
        count++;
        if (count < 1) {
          window.location.reload();
        }
      }
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function lectureNav(state = initState, action) {
  switch (action.type) {
    case lectureConstants.LECTURE_NAV_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case lectureConstants.LECTURE_NAV_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case lectureConstants.LECTURE_NAV_FAILURE:
      // alert(action.error);
      history.goBack();
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}
