import * as S from "./LectureSummary.style";
import {
  ArrowDropDownLine,
  ArrowDropUpLine,
  neutralDay,
} from "@teamsparta/design-system";
import React, { useEffect, useState } from "react";
import { AISpinner } from "./AISpinner";
import { useLogSummaryClick } from "../../../hooks/homework/useLogHomeworkClick";
import { summaryTimeUtil } from "../../../utils/time";
import { useGetLectureV2 } from "../../../queries/lecture";

export const LectureSummary = ({ summary, lectureId }) => {
  const [openSummaries, setOpenSummaries] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const lecture = useGetLectureV2(lectureId, {
    retry: 3,
  });
  const logSummaryClick = useLogSummaryClick();
  const onSummaryTimeClick = (timeInSeconds) => {
    const event = new CustomEvent("changeVideoTime", {
      detail: { timeInSeconds },
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (lecture?.isSuccess) {
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [lecture?.isSuccess]);

  const handleSummaryOpen = (index) => {
    setOpenSummaries((prevOpenSummaries) => ({
      ...prevOpenSummaries,
      [index]: !prevOpenSummaries[index],
    }));
  };

  if (showSpinner) return <AISpinner />;
  return (
    <S.LectureSummaryWrapper>
      {summary?.map((item, index) => {
        const { startTime, title, content } = item;
        const isOpen = openSummaries[index];
        return (
          <React.Fragment key={startTime}>
            <S.SummaryBlock
              onClick={() => {
                isOpen &&
                  logSummaryClick("요약 노트 열기", summaryTimeUtil(startTime));
                handleSummaryOpen(index);
              }}
            >
              <S.SummaryTitle>
                <S.SummaryTime
                  onClick={(e) => {
                    e.stopPropagation();
                    logSummaryClick(
                      "타임스탬프 클릭",
                      summaryTimeUtil(startTime)
                    );

                    onSummaryTimeClick(startTime);
                  }}
                >
                  <S.SummaryTimeHover>여기로 이동하기</S.SummaryTimeHover>
                  {summaryTimeUtil(startTime)}
                </S.SummaryTime>
                {title}
              </S.SummaryTitle>
              <S.OpenSummaryButton>
                {isOpen ? (
                  <ArrowDropUpLine size={20} color={neutralDay.gray70} />
                ) : (
                  <ArrowDropDownLine size={20} color={neutralDay.gray70} />
                )}
              </S.OpenSummaryButton>
            </S.SummaryBlock>
            <S.SummaryContent isOpen={isOpen}>
              {content.map((item, i) => {
                return (
                  <S.SummaryContentTextWrapper key={i}>
                    <S.SummaryContentText>{item}</S.SummaryContentText>
                  </S.SummaryContentTextWrapper>
                );
              })}
            </S.SummaryContent>
          </React.Fragment>
        );
      })}
    </S.LectureSummaryWrapper>
  );
};
