import { pointConstants } from "../_constants";
import { pointService } from "../_services";

function getPointInfo(onPlan = false) {
  function request() {
    return { type: pointConstants.POINT_INFO_REQUEST };
  }

  function success(response) {
    return { type: pointConstants.POINT_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: pointConstants.POINT_INFO_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    pointService.getPointInfo(onPlan).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const pointActions = {
  getPointInfo,
};
