import privateInstance from "../../_helper/axios";

async function getRcmdFreeCourse() {
  return await privateInstance.get(`/rcmd/free`).then((resp) => resp.data);
}

async function postRcmdFreeCourse(data) {
  return await privateInstance
    .post(`/rcmd/free`, data)
    .then((resp) => resp.data);
}

async function postRcmdSurvey(data) {
  return await privateInstance
    .post(`/rcmd/survey`, data)
    .then((resp) => resp.data);
}

async function getRcmdCourses(enrolled_id) {
  return await privateInstance
    .get(`/rcmd/finish/${enrolled_id}`)
    .then((resp) => resp.data);
}

export const rcmdService = {
  getRcmdFreeCourse,
  postRcmdFreeCourse,
  getRcmdCourses,
  postRcmdSurvey,
};
