import privateInstance from "../../_helper/axios";

async function getBestCheckins() {
  return await privateInstance.get(`/checkins/best`).then((resp) => resp.data);
}

async function getCheckins(offset) {
  return await privateInstance
    .get(`/checkins/?offset=${offset}`)
    .then((resp) => resp.data);
}

async function getIsCheckinCreated() {
  return await privateInstance
    .get(`/checkins/created`)
    .then((resp) => resp.data);
}

export const checkinService = {
  getBestCheckins,
  getCheckins,
  getIsCheckinCreated,
};
