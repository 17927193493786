import styled from "@emotion/styled";
import {
  additional,
  mBody1,
  mBody2,
  mBody3,
  mBody4,
  mCaption2,
  mTitle1,
  neutralDay,
  wBody1,
  wBody2,
  wBody4,
  wCaption1,
  wTitle2,
  wTitle5,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 32px;
`;

export const NoticeOfPrivacyPolicy = styled.span`
  display: flex;
  ${wCaption1};
  color: ${neutralDay.gray70};
  align-self: stretch;
  white-space: nowrap;
  span {
    ${wCaption1};
    color: ${neutralDay.gray70};
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mCaption2};
    color: ${neutralDay.gray70};
    white-space: pre-wrap;
    flex-direction: column;
    span {
      ${mCaption2};
      color: ${neutralDay.gray70};
    }
  }
`;

export const UnderLine = styled.span`
  ${wCaption1};
  color: ${neutralDay.gray70};
  text-decoration: underline;
  white-space: nowrap;
  a {
    ${wCaption1};
    color: ${neutralDay.gray70};
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mCaption2};
    color: ${neutralDay.gray70};
    a {
      ${mCaption2};
      color: ${neutralDay.gray70};
    }
  }
`;

export const VerificationWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 460px;
  padding: 32px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${neutralDay.white};
  box-shadow: 0 8px 24px 0 rgba(95, 102, 107, 0.12);
  ${DisplayResolution.MobileAndMiniTablet} {
    width: 320px;
    padding: 24px 20px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
`;

export const TitleWrapper = styled.div`
  gap: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${wTitle2};
  color: ${neutralDay.gray100};
  align-self: stretch;
  white-space: pre-wrap;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mTitle1};
    color: ${neutralDay.gray100};
  }
`;

export const SubTitle = styled.div`
  ${wBody2};
  color: ${neutralDay.gray80};
  align-self: stretch;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody2};
    color: ${neutralDay.gray80};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-self: stretch;
`;

export const Button = styled.div`
  display: flex;
  height: 48px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: ${neutralDay.gray10};
  ${wBody1};
  color: ${neutralDay.gray100};
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${neutralDay.gray100};
  }
`;

export const PhoneButton = styled(Button)`
  background: ${neutralDay.gray100};
  color: ${neutralDay.white};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${neutralDay.white};
  }
`;

export const OTPInput = styled.input`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray30};
  ${wTitle5};
  color: ${neutralDay.gray90};
  text-align: center;
  ::placeholder {
    color: ${neutralDay.gray50};
  }
`;

export const GuideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-self: stretch;
  justify-content: space-between;
`;

export const VerificationGuideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;
export const VerificationGuide = styled.div`
  ${wBody4};
  color: ${neutralDay.gray70};
  align-self: stretch;
  cursor: default;
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody4};
    color: ${neutralDay.gray70};
  }
`;

export const MouseOver = styled.div`
  cursor: help;
  display: flex;
  align-items: center;
  position: relative;
`;

export const MouseOverGuide = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${neutralDay.gray90};
  z-index: 100;
  ${mCaption2};
  color: #fff;
  position: relative;
  top: 100px;
  right: 69px;
  ${DisplayResolution.MobileAndMiniTablet} {
    top: 80px;
    left: -18px;
    width: 269px;
  }
  &::after {
    border-color: ${neutralDay.gray90} transparent;
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: "";
    display: block;
    right: 50%;
    position: absolute;
    top: -7px;
    width: 0;
    z-index: 1;
  }
  &::before {
    border-color: ${neutralDay.gray90} transparent;
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: "";
    display: block;
    right: 50%;
    position: absolute;
    top: -8px;
    width: 0;
    z-index: 0;
  }
`;

export const DetailGuide = styled.div`
  ${mBody1};
  color: ${additional.blue100};
  text-align: center;
  cursor: pointer;
  a {
    &:visited {
      color: ${additional.blue100};
    }
  }
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody3};
    color: ${additional.blue100};
  }
`;

export const VerificationButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  ${DisplayResolution.MobileAndMiniTablet} {
    align-self: stretch;
  }
`;
export const VerificationButton = styled.div`
  display: flex;
  height: 48px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${neutralDay.gray100};
  ${wBody1};
  cursor: ${({ enabled }) => (enabled ? "pointer" : "not-allowed")};
  color: ${neutralDay.white};
  text-align: center;
  opacity: ${({ enabled }) => (enabled ? "1" : "0.3")};
  pointer-events: ${({ enabled }) => (enabled ? "auto" : "none")};

  ${DisplayResolution.MobileAndMiniTablet} {
    height: 44px;
    align-self: stretch;
    width: 100%;
    ${mBody1};
    color: ${neutralDay.white};
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  }
`;

export const BeforeButton = styled.div`
  background: transparent;
  display: flex;
  height: 48px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  ${wBody1};
  color: ${neutralDay.gray100};
  cursor: pointer;
  ${DisplayResolution.MobileAndMiniTablet} {
    height: 44px;
    align-self: stretch;
    white-space: nowrap;
    flex: 1;
    ${mBody1};
    color: ${neutralDay.gray100};
  }
`;
