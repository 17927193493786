import { useMutation } from "react-query";
import privateInstance from "../../_helper/axios";

const postSurvey = async (surveyData) => {
  return await privateInstance.post(`/nps/customer_survey`, surveyData);
};

const determineSelectedAnswer = (selectedIndex, questionType, options) => {
  if (questionType === "quick") {
    return options[selectedIndex];
  }
  return selectedIndex;
};

const constructAnswerFormat = (questionState) => {
  let answers = [];
  questionState.forEach((question) => {
    answers.push({
      question_title: question.title,
      selected_answer: determineSelectedAnswer(
        question.selectedIndex,
        question.type,
        question.options
      ),
      additional_answer: question.additionalAnswer,
    });
  });
  return answers;
};

export const useMutateSurvey = (questionState, week, enrolledId) => {
  const answerForm = constructAnswerFormat(questionState);
  return useMutation(
    "survey_questions",
    () => {
      return postSurvey({
        answers: answerForm,
        enrolled_id: enrolledId,
        week: week,
      });
    },
    {
      onSuccess: (result) => {
        if (result?.data?.ok === false) {
          alert("만족도 조사 제출에 실패했습니다. 다시 시도해주세요!");
          window.location.reload();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};
