import * as S from "./nps.style";
import * as N from "../NPSPage/NPSPage.style";

export const QuickQuestion = ({
  questionNumber,
  questionTitle,
  questionOptions,
  placeholder,
  selectedIdx,
  onSelectOption,
  onChangeAdditionalAnswer,
  isSubQuestionActive,
}) => {
  return (
    <S.QuestionWrapper>
      <S.QuestionBox>
        <S.QuestionTitleBox>
          <S.QuestionNumber>Q{questionNumber}.</S.QuestionNumber>
          <S.QuestionTitle>{questionTitle}</S.QuestionTitle>
        </S.QuestionTitleBox>
        <S.SelectionListBox>
          {questionOptions.map((questionOption, index) => {
            return (
              <S.SelectionItem
                key={index}
                isSelected={selectedIdx === index}
                onClick={() => {
                  onSelectOption(index);
                }}
              >
                <S.SelectionText isSelected={selectedIdx === index}>
                  {questionOption}
                </S.SelectionText>
              </S.SelectionItem>
            );
          })}
        </S.SelectionListBox>
      </S.QuestionBox>
      {isSubQuestionActive && (
        <N.SubQuestionTextArea
          placeholder={placeholder}
          onChange={(e) => {
            onChangeAdditionalAnswer(e.target.value);
          }}
        />
      )}
    </S.QuestionWrapper>
  );
};
