import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../../_state/actions";
import classNames from "classnames/bind";
import styles from "./RoadmapRank.module.scss";
import { useOnlineTheme } from "../../../queries/pirates";
import { maskName } from "../../../_helper/utils";

const cx = classNames.bind(styles);

function RoadmapRank({
  user,
  round,
  enrolled,
  enrolledRanks,
  progress,
  getEnrolledRank,
}) {
  const [cheerup, setCheerup] = useState("");
  const [is_me_index, setIsMeIndex] = useState(-1);
  useEffect(() => {
    const { enrolled_id } = enrolled.data;
    getEnrolledRank(enrolled_id);
  }, [enrolled]);

  useEffect(() => {
    if (!enrolledRanks.loaded || !round.loaded) {
      return;
    }
    if (round.data.is_gov_intro) {
      setCheerup(`${user.name} 님과 함께 할\n예비 동료들이에요!`);
      return;
    }
    const { ranks } = enrolledRanks.data;
    // let next_cheerup = "얼른 수강을 시작하세요!";
    let next_cheerup;
    let myIndex = -1;
    for (let i = 0; i < ranks.length; i++) {
      if (!ranks[i].is_me) {
        continue;
      }
      myIndex = i;
      setIsMeIndex(i);
      if (progress === 100) {
        next_cheerup = `${user.name}님 최고에요!\n완주를 축하드려요!`;
      } else {
        next_cheerup = i
          ? ranks[i - 1].count - ranks[i].count
            ? `지금 ${
                ranks[i - 1].count - ranks[i].count
              }강만 더 들으면\n랭킹이 올라요!`
            : `동점! 조금만 더 들으면\n랭킹이 올라요!`
          : `${user.name} 님 축하해요!\n 지금 1등이에요! 최고!`;
      }
      setCheerup(next_cheerup);
    }
    if (myIndex === -1) {
      next_cheerup = "얼른 수강을 시작하세요!";
    }
    setCheerup(next_cheerup);
  }, [enrolledRanks]);
  const theme = useOnlineTheme();

  return (
    <>
      {enrolledRanks.loaded && (
        <div className={cx("roadmap-rank-container", theme.data)}>
          <div className={cx("roadmap-rank-container__header")}>
            <div
              className={cx(
                "roadmap-rank-container__header__title",
                theme.data
              )}
            >
              랭킹
            </div>
            <div
              className={cx(
                "roadmap-rank-container__header__detail",
                theme.data
              )}
            >
              전체 {enrolledRanks.data.total}명
            </div>
          </div>
          <div className={cx("roadmap-rank-container__header-sub", theme.data)}>
            <img
              className={cx("roadmap-rank-container__header-sub__img")}
              src={"/assets/icons/roadmap/ic_trophy.png"}
              alt=""
            />
            <div
              className={cx(
                "roadmap-rank-container__header-sub__text",
                theme.data
              )}
            >
              {cheerup}
            </div>
          </div>
          {is_me_index > -1 && (
            <div className={cx("roadmap-rank-container__contents")}>
              {enrolledRanks.data.ranks.map((rank, idx) => {
                const { is_me, name, order } = rank;
                const __me = is_me ? "--me" : "";
                return (
                  <div
                    key={idx}
                    className={cx(
                      "roadmap-rank-container__contents__content",
                      __me,
                      theme.data
                    )}
                  >
                    <div
                      className={cx(
                        "roadmap-rank-container__contents__content__order",
                        theme.data
                      )}
                    >
                      {order}등
                    </div>
                    <div
                      className={cx(
                        "roadmap-rank-container__contents__content__profile"
                      )}
                    >
                      <img
                        className={cx(
                          "roadmap-rank-container__contents__content__profile__img"
                        )}
                        src={`/assets/images/profile/rtan_thumb_0${
                          idx + 1
                        }.png`}
                        alt=""
                      />
                      <span
                        className={cx(
                          "roadmap-rank-container__contents__content__profile__name",
                          theme.data
                        )}
                      >
                        {maskName(name)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, enrolled, round, enrolledRanks } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    enrolled,
    round,
    enrolledRanks,
  };
}

const connectedRoadmapRank = connect(mapStateToProps, {
  getEnrolledRank: enrolledActions.getEnrolledRank,
})(RoadmapRank);
export { connectedRoadmapRank as RoadmapRank };
