import { recordMOTPResult } from "../../queries/auth/auth";

export async function handleMOTPResponse(
  respData,
  mOtpAuthRequestData,
  userId,
  productType,
  setInputOTP,
  setIsNeedReset,
  setIsOTP,
  refetchDailyAuth
) {
  switch (respData.code) {
    case "200":
      // mOTP 성공 응답을 online 서버에 전달
      await recordMOTPResult({
        userAgentPk: userId,
        motpAuthRequest: mOtpAuthRequestData,
        motpAuthResponse: respData,

        productType,
      });
      refetchDailyAuth();
      alert(`mOTP 인증이 완료되었습니다.`);
      break;

    case "AP001":
      alert("mOTP 인증이 실패했습니다. 인증번호를 다시 확인해주세요.");
      setInputOTP("");
      break;

    case "AP005":
      alert(
        "훈련생 등록이 되어있지 않습니다. 훈련생 등록 후 다시 시도해주세요."
      );
      setInputOTP("");
      break;

    case "AP009":
      setIsNeedReset(true);
      alert(
        "OTP 번호 오류 5회 이상으로 인증이 종료되었습니다. 휴대폰 인증을 시도해주세요."
      );
      setIsOTP(false);
      setInputOTP("");
      break;

    default:
      alert("mOTP 인증에 실패했습니다. 다른 인증 수단을 시도해주세요.");
      setInputOTP("");
      break;
  }
}
