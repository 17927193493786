import { getCookie, params, setCookie } from "../../_helper";
import { useEffect, useState } from "react";
import { marketingModalCourseIds } from "../../utils/marketingModalCourseIds";

export const useMarketingModalData = (enrolledDetail, name) => {
  const { course_id } = params();
  const [showMarketingModal, setShowMarketingModal] = useState(false);
  const marketingModalData = {
    imgPath: "/images/lecture/marketingAgreementSecondModal.png",
    title: `${name}님, 무료특강 완주 후에\n로드맵 추천을 받아보시겠어요?`,
  };

  useEffect(() => {
    if (enrolledDetail?.ok) {
      setShowMarketingModal(
        marketingModalCourseIds.includes(course_id) &&
          getCookie("isNeededSecondModal") === "true" &&
          enrolledDetail?.context?.order === 3
      );
    }
  }, [enrolledDetail?.ok]);

  const handleModalClose = () => {
    setCookie("isNeededSecondModal", "false", 99);
  };

  return {
    showMarketingModal,
    setShowMarketingModal,
    marketingModalData,
    handleModalClose,
  };
};
