import styled from "styled-components";
import { PIRATES } from "../../../_consts";

export const MainNPSFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;

  width: 100%;
  position: fixed;
  z-index: 99;
  bottom: 0;

  background: ${({ isDesktop, theme }) => {
    switch (theme) {
      case PIRATES:
        return isDesktop ? null : "#222425";
      default:
        return isDesktop ? null : "#F6F9FA";
    }
  }};
  box-shadow: ${(props) =>
    !props.isDesktop ? "0px -4px 20px rgba(20, 22, 23, 0.08)" : null};
  @media (min-width: 500px) {
    position: unset;
    padding: 0 0px;
    margin-top: 30px;
    margin-bottom: 120px;
  }
`;
