import styled from "@emotion/styled";
import {
  neutralDay,
  mBody2,
  mHeader1,
  mTitle2,
  scc,
  wBody1,
  wBody2,
  wHeader2,
} from "@teamsparta/design-system";
import { Device } from "../../../../_styles/device";
import { keyframes } from "@emotion/css";

export const HiddenWrapper = styled.div`
  height: 0;
  overflow: hidden;
`;
export const Wrapper = styled.div`
  width: ${({ isHidden }) => (isHidden ? "360px" : "100%")};
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${neutralDay.white};
  text-align: center;
  background: radial-gradient(
    72.14% 60.81% at 50% 49.92%,
    #260e01 0%,
    #141617 92.66%
  );
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("https://media4.giphy.com/media/rGg9aM4uTvUgKGVkaC/giphy.gif?cid=790b7611037869f7373db44f7a40443a017dbb1f8603f5fb&rid=giphy.gif&ct=s");
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 16px;

  ${Device.Desktop} {
    padding: ${({ isHidden }) => (isHidden ? 60 : 100)}px 16px;
  }
`;

export const Title = styled.div`
  ${mHeader1};
  color: ${neutralDay.white};
  ${Device.Desktop} {
    ${({ isHidden }) => (isHidden ? mHeader1 : wHeader2)};
    color: ${neutralDay.white};
  }
`;

export const Desc = styled.div`
  ${mBody2};
  color: rgba(255, 255, 255, 0.7);
  ${Device.Desktop} {
    ${wBody2};
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const MedalImage = styled.img`
  width: 360px;
`;

export const InfoBox = styled.div`
  ${Device.Desktop} {
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 328px;
  flex-direction: column;
  margin: 0 auto 40px;
  ${Device.Desktop} {
    width: fit-content;
    margin: 50px auto;
    max-width: none;
    flex-direction: row;
  }
`;

export const Cta = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ isMain }) => (isMain ? scc.red100 : neutralDay.white)};
  border-radius: 8px;
  ${mTitle2};
  color: ${({ isMain }) => (isMain ? neutralDay.white : scc.red100)};
  padding: 0 20px;
  width: 220px;
  height: 54px;
  ${Device.Desktop} {
    width: 280px;
    ${wBody1};
    color: ${({ isMain }) => (isMain ? neutralDay.white : scc.red100)};
  }
`;

const Rotate = keyframes`
  100% {
    transform: rotate(-360deg)
  }
`;

export const SolvingImg = styled.img`
  width: 24px;
  height: 24px;
  animation: ${Rotate} 2s linear infinite;
  transform-origin: 50% 50%;
`;
