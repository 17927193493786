import styled from "styled-components";
import { Device } from "../../../../_styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  width: 360px;
  height: 45px;

  position: fixed;
  margin-bottom: 16px;
  box-shadow: 0px 8px 24px rgba(129, 137, 143, 0.12);
  border-radius: 8px;

  background: ${({ dday }) => {
    switch (dday) {
      case 1:
        return "#FFF6F8";
      case 0:
        return "#FFF1F4";
      default:
        return "#ffffff";
    }
  }};

  border: ${({ dday }) =>
    dday > 1 ? "1px solid #E4EBF0" : "1px solid ##FFD0DA"};

  bottom: 12px;

  ${Device.Desktop} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 18px;
    gap: 16px;

    width: 292px;
    height: 73px;

    border: ${({ dday }) => (dday > 1 ? "none" : "1px solid ##FFD0DA")};

    position: static;
    border-radius: 24px;
    box-shadow: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 100%;
`;

export const ContentText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  color: #141617;
  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const RedText = styled.span`
  position: relative;
  top: -1px;
  color: #e8344e;
`;

export const DateText = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #5f666b;
  ${Device.Desktop} {
    font-size: 14px;
  }
`;
