import { COURSE_CONSTANTS } from "../constants/courses.constant";

const initState = {
  loaded: false,
};

export function courseV2(state = initState, action) {
  switch (action.type) {
    case COURSE_CONSTANTS.COURSE_GET_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case COURSE_CONSTANTS.COURSE_GET_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case COURSE_CONSTANTS.COURSE_GET_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
