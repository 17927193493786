import { useQuery } from "react-query";
import { authHeader, handleResponse } from "../_helpers";
import { baseUrl, getApiPaymentInfoUrl } from "../_urls";
import {
  KDC_QUALIFICATION_COURSE_IDS_2024_2H,
  KDC_QUALIFICATION_END_DATE_2024_2H,
  SQLD_COURSE_ID,
} from "../v2/_state/constants";

function getPaymentInfo(course_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({ "Content-Type": "application/json" }),
  };
  const endpoint = getApiPaymentInfoUrl(course_id);
  return fetch(endpoint, requestOptions).then(handleResponse);
}

async function getPaymentInfoByCourseId(course_id, params) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  const endpoint = `${baseUrl}/payment/courses/${course_id}` + params;
  return await fetch(endpoint, requestOptions).then(handleResponse);
}

export const useGetPaymentInfoByCourseId = (course_id, params = "") => {
  return useQuery({
    queryKey: ["paymentInfo", course_id],
    queryFn: () => getPaymentInfoByCourseId(course_id, params),
    refetchOnWindowFocus: true,
  });
};

export const paymentService = {
  getPaymentInfo,
  getPaymentInfoByCourseId,
};

export const shouldNotShowDiscountSection = (courseId) => {
  const currentDate = new Date();

  if (courseId === SQLD_COURSE_ID) {
    return true; // SQLD 과목은 항상 할인 섹션을 표시하지 않음
  }

  if (
    KDC_QUALIFICATION_COURSE_IDS_2024_2H.includes(courseId) &&
    currentDate < KDC_QUALIFICATION_END_DATE_2024_2H
  ) {
    return true; //2024 하반기 KDC 과목은 2024-08-09까지만 할인 섹션을 표시하지 않음
  }

  return false;
};
