import * as S from "./SideBarOpenButton.style";
import { useAtom } from "jotai";
import { isLectureListOpen } from "../../../../stores/homeworkPageV3Atom";
import { NavigationIndex, scc } from "@teamsparta/design-system";
import { useLogLectureSidebarClick } from "../../../../hooks/homework/useLogHomeworkClick";

export const SideBarOpenButton = () => {
  const [, setIsOpenList] = useAtom(isLectureListOpen);
  const logLectureSideBarClick = useLogLectureSidebarClick();

  return (
    <S.OpenContainer
      onClick={() => {
        logLectureSideBarClick("사이드바 열기");
        setIsOpenList(true);
      }}
    >
      <S.IndexIcon>
        <NavigationIndex size={24} color={`${scc.red100}`} />
      </S.IndexIcon>
      <S.OpenTitle>메뉴</S.OpenTitle>
    </S.OpenContainer>
  );
};
