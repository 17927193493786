import * as amplitude from "@amplitude/analytics-browser";
import { webAttributionPlugin } from "@amplitude/plugin-web-attribution-browser";

export const initAmplitude = () => {
  try {
    window.amplitude.getDeviceId();
  } catch (e) {
    amplitude.add(webAttributionPlugin());
    amplitude.init(process.env.AMPLITUDE_KEY, undefined, {
      attribution: {
        excludeReferrers: [
          "kauth.kakao.com",
          "spartacodingclub.kr",
          "logins.daum.net",
        ],
      },
    });
    window["amplitude"] = amplitude;
  }
};

export const getAmplitudeDeviceId = () => {
  try {
    return window.amplitude.getDeviceId();
  } catch (e) {
    console.log("failed to get Amplitude DeviceId: ", e);
    return undefined;
  }
};
