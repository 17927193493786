export const handleTimeChange = (
  type,
  event,
  enrolledDetail,
  errorMinute,
  errorSecond,
  setErrorMinute,
  setErrorSecond
) => {
  const intValue = parseInt(event.target.value);
  if (type === "minutes") {
    if (
      intValue < 0 ||
      intValue > parseInt(enrolledDetail?.data.playtime / 60)
    ) {
      alert("재생 시간 내의 숫자를 입력해주세요.");
      event.target.value = "00";
      return;
    }
    setErrorMinute(intValue);
  }
  if (type === "second") {
    const maxMinutes = parseInt(enrolledDetail?.data.playtime / 60);
    const maxSeconds = parseInt(enrolledDetail?.data.playtime % 60);
    if (errorMinute === maxMinutes && (intValue < 0 || intValue > maxSeconds)) {
      alert("재생 시간 내의 숫자를 입력해주세요.");
      event.target.value = "00";
      return;
    }
    setErrorSecond(intValue);
  }
};

export const formatNumber = (num) => {
  return num < 10 ? `0${num}` : num.toString();
};

export const formattedErrorTime = (type, errorMinute, errorSecond) => {
  return type === "영상 오류"
    ? `${String(parseInt(errorMinute)).padStart(2, "0")} : ${String(
        parseInt(errorSecond)
      ).padStart(2, "0")}`
    : "";
};
