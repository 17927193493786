import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./B2bPage.module.scss";
import { connect } from "react-redux";
import { externalActions } from "../../_state/actions";
import { validatePhone } from "../../_helper";
import { useEffect } from "react";

const cx = classNames.bind(styles);

function B2bPageV3({ postB2bApply }) {
  const [team, setTeam] = useState("");
  const [teamNumber, setTeamNumber] = useState(1);
  const [beforeNumber, setBeforeNumber] = useState(1);
  const [departmentList, setDepartmentList] = useState(null);
  const [nameList, setNameList] = useState(null);
  const [companyIdList, setCompanyIdList] = useState(null);
  const [phoneList, setPhoneList] = useState(null);
  const [keyList, setKeyList] = useState(null);
  const [emailList, setEmailList] = useState(null);
  //   const [companyName, setCompanyName] = useState("한국투자밸류자산운용");

  const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const departments = [
    "한국투자금융지주",
    "한국투자프라이빗에쿼티",
    "한국투자밸류자산운용",
    "한국투자신탁운용",
    "한국투자증권",
  ];

  const [isDone, setIsDone] = useState(false);

  const onChangeTeamNumber = (e) => {
    setTeamNumber(parseInt(e.target.value));
  };

  const updateUseStateArray = (index, stateList, setStateList) => (e) => {
    let newArr = [...stateList];
    newArr[index] = e.target.value;
    setStateList(newArr);
  };

  const companyIdValidate = (companyId) => {
    if (companyId.length != 6) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (teamNumber > 0 && beforeNumber == teamNumber) {
      let tmp = [];
      for (let i = 0; i < teamNumber; i++) {
        tmp.push(i);
      }
      setKeyList(tmp);

      setDepartmentList(new Array(teamNumber));
      setNameList(new Array(teamNumber));
      setCompanyIdList(new Array(teamNumber));
      setPhoneList(new Array(teamNumber));
      setEmailList(new Array(teamNumber));
    } else if (teamNumber > 0 && beforeNumber != teamNumber) {
      let tmp = [];
      for (let i = 0; i < teamNumber; i++) {
        tmp.push(i);
      }
      setKeyList(tmp);

      const listObj = {
        departmentList,
        nameList,
        companyIdList,
        phoneList,
        emailList,
      };
      const setFunctionObj = {
        departmentList: setDepartmentList,
        nameList: setNameList,
        companyIdList: setCompanyIdList,
        phoneList: setPhoneList,
        emailList: setEmailList,
      };
      for (let obj in listObj) {
        let newArr = [];
        if (beforeNumber >= teamNumber) {
          for (let i = 0; i < teamNumber; i++) {
            newArr.push(listObj[obj][i]);
          }
        } else {
          newArr = [...listObj[obj]];
          for (let i = beforeNumber; i < teamNumber; i++) {
            newArr.push(undefined);
          }
        }
        setFunctionObj[obj](newArr);
      }
    }
    setBeforeNumber(teamNumber);
  }, [teamNumber]);

  return (
    <>
      <div className={cx("B2bForm")}>
        <div className={cx("B2bForm__wrapper")}>
          ç
          <img
            className={cx("B2bForm__banner")}
            src={`/assets/images/b2b/korea_investment_holdings.png`}
            alt={"banner"}
          />
          <div className={cx("B2bForm__wrapper__pad")}></div>
          <div className={cx("B2bForm__wrapper__top")}>
            <h1 className={cx("B2bForm__header")}>
              금융인을 위한 코딩 실습 프로젝트 참가 신청서
            </h1>
            <hr />
            <p className={cx("B2bForm__desc")}>
              한국투자증권 임직원 여러분! 그 동안 공부 하시느라 고생
              많으셨습니다!
              <br />
              그간 힘들게 배워온 것들을 쏟아내실 수 있도록, 저희 튜터들이 물심
              앙면 돕겠습니다.
              <br />
              업무효율을 개선 할 아이디어를 마구마구 쏟아주세요!
            </p>
          </div>
          {isDone ? (
            <div className={cx("B2bForm__done")}>제출이 완료되었습니다!</div>
          ) : (
            <div className={cx("B2bForm__form")}>
              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  팀 이름<b>*</b>
                </h3>
                <input
                  onChange={(e) => setTeam(e.target.value)}
                  value={team}
                  placeholder={"팀 이름을 적어주세요"}
                  type={"text"}
                />
              </div>

              <div className={cx("B2bForm__form__row")}>
                <h3 className={cx("B2bForm__form__title")}>
                  팀 인원수<b>*</b>
                </h3>
                <select onChange={onChangeTeamNumber}>
                  {numberArray.map((elem) => {
                    return (
                      <option value={elem} key={elem}>
                        {elem}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                {teamNumber > 0 &&
                  keyList &&
                  keyList.map((key) => {
                    return (
                      <div key={key}>
                        <div className={cx("B2bForm__form__row")}>
                          <h3 className={cx("B2bForm__form__title")}>
                            소속부서<b>*</b>
                          </h3>
                          <input
                            className={cx("B2bForm__form__inner_input")}
                            onChange={updateUseStateArray(
                              key,
                              departmentList,
                              setDepartmentList
                            )}
                            value={departmentList[key]}
                            placeholder={"소속부서"}
                            type={"text"}
                          />

                          {/* <select 
                          onChange={updateUseStateArray(key, departmentList,setDepartmentList)}
                          className={cx("B2bForm__form__inner_input")}>
                          {
                            departments.map((department)=>{
                              return(
                                <option className={cx("B2bForm__form__inner_input")}
                                  value={department} 
                                  key={department}>
                                    {department}
                                </option>
                              )
                            })
                          }
                        </select> */}

                          <h3 className={cx("B2bForm__form__title")}>
                            이름<b>*</b>
                          </h3>
                          <input
                            className={cx("B2bForm__form__inner_input")}
                            onChange={updateUseStateArray(
                              key,
                              nameList,
                              setNameList
                            )}
                            value={nameList[key]}
                            placeholder={"이름"}
                            type={"text"}
                          />

                          <h3 className={cx("B2bForm__form__title")}>
                            사번<b>*</b>
                          </h3>
                          <input
                            className={cx("B2bForm__form__inner_input")}
                            onChange={updateUseStateArray(
                              key,
                              companyIdList,
                              setCompanyIdList
                            )}
                            value={companyIdList[key]}
                            placeholder={"사번"}
                            type={"text"}
                          />

                          <h3 className={cx("B2bForm__form__title")}>
                            휴대폰 번호<b>*</b>
                          </h3>
                          <input
                            className={cx("B2bForm__form__inner_input")}
                            onChange={updateUseStateArray(
                              key,
                              phoneList,
                              setPhoneList
                            )}
                            value={phoneList[key]}
                            placeholder={"010-0000-0000"}
                            type={"text"}
                          />

                          <h3 className={cx("B2bForm__form__title")}>
                            이메일<b>*</b>
                          </h3>
                          <input
                            className={cx("B2bForm__form__inner_input")}
                            onChange={updateUseStateArray(
                              key,
                              emailList,
                              setEmailList
                            )}
                            value={emailList[key]}
                            placeholder={"000000@koreainvestment.com"}
                            type={"text"}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              <button
                onClick={(e) => {
                  /** 입력란 검사 **/
                  let flag = false;
                  if (team === "" || teamNumber === "") {
                    alert("입력란을 확인해주세요");
                    flag = true;
                  }

                  const listObject = {
                    departmentList,
                    nameList,
                    companyIdList,
                    phoneList,
                    emailList,
                  };

                  for (let obj in listObject) {
                    listObject[obj].forEach((elem) => {
                      if (elem === "") {
                        alert("입력란을 확인해주세요!");
                        flag = true;
                        return;
                      }
                    });

                    if (obj === "phoneList" && !flag) {
                      for (let i = 0; i < teamNumber; i++) {
                        if (!validatePhone(phoneList[i])) {
                          flag = true;
                          alert("휴대폰 입력양식을 확인해주세요");
                          return;
                        }
                        if (flag) break;
                      }
                    }

                    if (obj === "companyIdList" && !flag) {
                      for (let i = 0; i < teamNumber; i++) {
                        if (!companyIdValidate(companyIdList[i])) {
                          flag = true;
                          alert("사번 6자리를 확인해주세요");
                          return;
                        }
                        if (flag) break;
                      }
                    }

                    if (flag) {
                      break;
                    }
                  }

                  if (flag) {
                    return;
                  }

                  /** POST DATA **/
                  const postData = {
                    type: "team",
                    team,
                    teamNumber,
                    departmentList,
                    nameList,
                    companyIdList,
                    phoneList,
                    emailList,
                  };

                  postB2bApply(postData).then((resp) => {
                    if (resp.ok) {
                      setIsDone(true);
                    } else {
                      alert(resp.message);
                    }
                  });
                }}
                className={cx("B2bForm__form__cta")}
              >
                제출하기
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedB2bPageV3 = connect(mapStateToProps, {
  postB2bApply: externalActions.postB2bApply,
})(B2bPageV3);
export { connectedB2bPageV3 as B2bPageV3 };
