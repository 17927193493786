import {
  commonQuestions,
  firstWeekQuestions,
  lastWeekQuestions,
} from "./question-model";
import { useLastContentOfOEC } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

export const useCurrentQuestion = (week) => {
  const lastContent = useLastContentOfOEC();
  const lastWeek = lastContent?.week;
  const isLastWeek = week === lastWeek;
  const isFirstWeek = week === 1;
  if (isFirstWeek) {
    return firstWeekQuestions;
  } else if (isLastWeek) {
    return lastWeekQuestions;
  } else {
    return commonQuestions;
  }
};
