import { rankConstants } from "../_constants";
import { rankService } from "../_services";

function getRankInfo(round_id) {
  function request() {
    return { type: rankConstants.RANK_INFO_REQUEST };
  }

  function success(response) {
    return { type: rankConstants.RANK_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: rankConstants.RANK_INFO_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    rankService.getRankInfo(round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const rankActions = {
  getRankInfo,
};
