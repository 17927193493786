import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiRcmdCreateUrl = () => {
  return `${baseUrl}/rcmds/`;
};

export const getApiRcmdReadCodeUrl = (code) => {
  return `${baseUrl}/rcmds/${code}`;
};

export const getApiRcmdViralUrl = (course_id) => {
  return `${baseUrl}/rcmds/${course_id}/finish`;
};

export const getApiFreeRcmdViralUrl = (course_id) => {
  return `${baseUrl}/rcmds/${course_id}/free`;
};

export const getApiRcmdViralLogUrl = (course_id) => {
  return `${baseUrl}/rcmds/${course_id}`;
};
