import { LIVE_USER } from "../constants";

const initState = {
  loaded: false,
};

export function liveUser(state = initState, action) {
  switch (action.type) {
    case LIVE_USER.LIVE_USER_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case LIVE_USER.LIVE_USER_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case LIVE_USER.LIVE_USER_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
