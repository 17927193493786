import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./HoverGuide.module.scss";

const cx = classNames.bind(styles);

export function HoverGuide() {
  return (
    <>
      <div className={cx("text_wrapper")}>
        <img
          src={`/assets/icons/picker_down.svg`}
          className={cx("picker_down")}
        />
        <span>르탄이 위에 마우스를 올려보세요</span>
        <img
          src={`/assets/icons/pointer_down.png`}
          className={cx("fingers_down")}
        />
      </div>
    </>
  );
}
