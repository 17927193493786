import styled from "styled-components";
import { device } from "../../../../_styles/device";
import { PIRATES } from "../../../../_consts";

export const LectureTapMenuWrapper = styled.div`
  width: 100%;
`;

export const LectureTapMenuHeadContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

export const ItemWrapper = styled.div`
  min-height: calc(100vh - 320px);
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#eef3f6";
    }
  }};
  padding-bottom: 80px;
  @media ${device.desktop} {
    padding-bottom: 0;
  }
`;

export const ErrorReportWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#eef3f6";
    }
  }};
  padding: 40px 32px;
  overflow: scroll;
  justify-content: center;
`;

export const LectureTapMenuBodyWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#eef3f6";
    }
  }};
  padding: 24px 0 60px;
  overflow: scroll;
  justify-content: center;
  @media ${device.desktop} {
    padding: 40px 32px;
    justify-content: start;
  }
`;

export const LectureTapMenuBodyItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  gap: 16px;
  justify-content: center;
  @media ${device.desktop} {
    justify-content: start;
  }
`;

export const DummyItem = styled.div`
  width: 328px;
`;

export const LectureTapMenuItemContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const LectureTapMenuNoSnippets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;
`;

export const NoSnippetsText = styled.div`
  color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#EEF3F6";
      default:
        return "#5f666b";
    }
  }};
  font-family: "Pretendard";

  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  margin-bottom: 16px;
`;

export const LandomImage = styled.img``;
