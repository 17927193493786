import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./CheckinFormContainer.module.scss";
import { connect } from "react-redux";
import { CheckinCard } from "../CheckinCards/CheckinCard";
import privateInstance from "../../../_helper/axios";
import { checkinActions } from "../../../_state/actions/checkin.actions";
import { history } from "../../../../_helpers";
import {
  sendUserAction,
  USER_ACTIONS,
  USER_PAGES,
} from "../../../businesslogics/logging";

const cx = classNames.bind(styles);

function CheckinFormContainer({
  user,
  round_id,
  enrolled_id,
  bestCheckins,
  getBestCheckins,
  getCheckins,
  getIsCheckinCreated,
}) {
  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    getBestCheckins();
  }, []);
  useEffect(() => {
    if (isFetching) {
      getCheckins(1);
    }
  }, [isFetching]);
  const enterRoadmap = async () => {
    const data = {
      round_id: round_id,
      enrolled_id: enrolled_id,
    };
    sendUserAction(USER_ACTIONS.CREATE_CHECKIN, USER_PAGES.PAGE_CHECKIN);
    return await privateInstance.post(`/checkin_skip/`, data).then((resp) => {
      const result = resp.data;
      getIsCheckinCreated();
      if (result.ok) {
        setIsFetching(true);
        history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
      } else {
        alert(result.reason);
      }
    });
  };

  const createComment = async () => {
    if (comment === null || comment === "") {
      alert("메세지를 입력해주세요! 혹은 skip 버튼을 눌러주세요.");
      document.getElementById("comment").focus();
      return;
    }
    const data = {
      comment: comment,
      round_id: round_id,
      enrolled_id: enrolled_id,
    };
    sendUserAction(USER_ACTIONS.CREATE_CHECKIN, USER_PAGES.PAGE_CHECKIN);
    return await privateInstance.post(`/checkins/`, data).then((resp) => {
      const result = resp.data;
      getIsCheckinCreated();
      if (result.ok) {
        setIsFetching(true);
        setIsSubmitted(true);
        alert("고마워요! 이제 공부하러 가 볼까요?");
        history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
      } else {
        alert(result.reason);
      }
    });
  };
  return (
    <>
      {!bestCheckins.loaded && (
        <div className={cx("checkinFormMainCopy-container")}>
          <h3 className={cx("checkinFormMainCopy")}>
            안녕하세요! {user.name}님! 동료들의 응원 메세지를 불러오고 있어요!
          </h3>
        </div>
      )}
      {!isSubmitted && bestCheckins.loaded && (
        <div className={cx("checkinFormContainer")}>
          <div className={cx("checkinContainer", "left")}>
            <div className={cx("checkinFormUser")}>
              <img
                src={`/assets/icons/rtan/rtan_thumb_${bestCheckins.data.checkins[0].num}.png`}
              />
              <span className="">
                {bestCheckins.data.checkins[0].users.name}
              </span>
            </div>
            <CheckinCard
              checkin={bestCheckins.data.checkins[0]}
              isHero={true}
            />
          </div>
          <div className={cx("checkinContainer", "right")}>
            <div className={cx("checkinFormInputContainer")}>
              <textarea
                id="comment"
                rows={4}
                className={cx("checkinFormInputContainerTextarea")}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                placeholder={"여기에 동료들에게 응원의 메시지를 남겨보세요!"}
              >
                {comment}
              </textarea>
              <div className={cx("button-wrapper")}>
                <button
                  className={cx("checkinFormSkipBtn")}
                  onClick={() => enterRoadmap()}
                >
                  Skip
                </button>
                <button
                  onClick={() => createComment()}
                  className={cx("checkinFormInputContainerCTA")}
                >
                  응원 보내고 강의실 입장하기 >
                </button>
              </div>
            </div>
            <div className={cx("checkinFormUser")}>
              <img src={`/assets/images/profile/rtan_01.png`} />
              <span className="">나</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, bestCheckins, allCheckins } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    bestCheckins,
    allCheckins,
  };
}

const connectedCheckinFormContainer = connect(mapStateToProps, {
  getBestCheckins: checkinActions.getBestCheckins,
  getCheckins: checkinActions.getCheckins,
  getIsCheckinCreated: checkinActions.getIsCheckinCreated,
})(CheckinFormContainer);
export { connectedCheckinFormContainer as CheckinFormContainer };
