import React from "react";
import * as S from "./backToSyllabusButton.style";

const BackToSyllabusButton = ({ ...props }) => {
  return (
    <S.BackButtonButton {...props}>강의목차로 돌아가기</S.BackButtonButton>
  );
};

export default BackToSyllabusButton;
