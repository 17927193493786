import { Modal } from "../../../components/organisms/Modal/Modal";
import React from "react";
import * as S from "./NbIntroModal.style";

const NbIntroModal = ({ handleVisible, data }) => {
  const { imgPath, title, contents, btnText } = data;
  return (
    <Modal
      styleName={"nb"}
      visible={true}
      handleVisible={handleVisible}
      style={{}}
      img_path={imgPath}
      img_style={"__fit-nb"}
      isBackgroundClickable={false}
      content={
        <S.ContentContainer>
          <S.ContentTitle>{title}</S.ContentTitle>
          <S.ContentDetail>
            {contents.map((content, index) => {
              return (
                <S.ContentDetailItem key={index}>{content}</S.ContentDetailItem>
              );
            })}
          </S.ContentDetail>
        </S.ContentContainer>
      }
      ctaList={[
        {
          btn_text: btnText,
          btnStyleName: "nb",
          handleCTAClick: () => handleVisible(false),
        },
      ]}
    />
  );
};

export default NbIntroModal;
