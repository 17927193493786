import * as S from "./LecMenuBottomSheet.style";
import React, { useEffect, useRef } from "react";
import { useOnlineTheme } from "../../../../queries/pirates";

export const LecMenuBottomSheet = ({
  defaultValue,
  selectTypeInfo,
  optionList,
  openFlag,
  handleOpenFunction,
  handleSelectFunction,
}) => {
  const selectSheetRef = useRef();
  const handleClickOutside = (e) => {
    if (
      selectSheetRef.current &&
      openFlag &&
      !selectSheetRef.current.contains(e.target)
    ) {
      handleOpenFunction(false);
    }
  };
  const handleSelectItem = (e, target) => {
    handleSelectFunction(target);
    handleOpenFunction(false);
    e.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("click", (e) => handleClickOutside(e));
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openFlag]);
  const theme = useOnlineTheme();

  return (
    <S.selectWrapper>
      <S.selectBottomSheet ref={selectSheetRef} theme={theme.data}>
        <S.mySelectLabel theme={theme.data}>{defaultValue}</S.mySelectLabel>
        <S.myOptionList theme={theme.data}>
          {optionList.map((option, idx) => {
            return (
              <S.myOptionItem
                theme={theme.data}
                key={option.label}
                value={option.value}
                onClick={(e) => handleSelectItem(e, e.target)}
              >
                {option.label}
              </S.myOptionItem>
            );
          })}
        </S.myOptionList>
      </S.selectBottomSheet>
    </S.selectWrapper>
  );
};
