import { courseConstants } from "../_constants";

const initState = {
  loaded: false,
  data: [],
};

export function courses(state = initState, action) {
  switch (action.type) {
    case courseConstants.COURSE_LIST_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case courseConstants.COURSE_LIST_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.courses,
      });
    case courseConstants.COURSE_LIST_FAILURE:
      if (!action.error.contains("JSON")) {
        alert(action.error);
      }
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}

export function govCourses(state = initState, action) {
  switch (action.type) {
    case courseConstants.GOV_COURSE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case courseConstants.GOV_COURSE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case courseConstants.GOV_COURSE_FAILURE:
      if (!action.error.contains("JSON")) {
        alert(action.error);
      }
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}

export function course(state = initState, action) {
  switch (action.type) {
    case courseConstants.COURSE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case courseConstants.COURSE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.course,
      });
    case courseConstants.COURSE_FAILURE:
      if (!action.error.contains("JSON")) {
        alert(action.error);
      }
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: true,
      });
    default:
      return state;
  }
}

export function courseByUserId(state = initState, action) {
  switch (action.type) {
    case courseConstants.COURSE_BY_USERID_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case courseConstants.COURSE_BY_USERID_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case courseConstants.COURSE_BY_USERID_FAILURE:
      alert(action);
      alert(action.error);
      window.location.href = "/";
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function coursesByPkgId(state = initState, action) {
  switch (action.type) {
    case courseConstants.COURSE_BY_PKG_ID_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case courseConstants.COURSE_BY_PKG_ID_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case courseConstants.COURSE_BY_PKG_ID_FAILURE:
      // alert(action);
      // alert(action.error);
      // window.location.href = "/";
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
