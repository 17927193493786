import styled from "@emotion/styled";
import { wBody3 } from "@teamsparta/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 11px;
  align-self: stretch;
  margin-bottom: 24px;
`;

export const KdcFinalPrice = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.25px;
  color: var(--Gray-Gray-9, #212529);
  text-align: right;
  font-family: "Spoqa Han Sans Neo";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1px;

  > b {
    color: var(--Gray-Gray-9, #212529);
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    letter-spacing: -0.8px;
    margin-left: 4px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const TagText = styled.p`
  ${wBody3};
  background: linear-gradient(90deg, #31b32e 0%, #2ec4b6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;
