import * as S from "./DashBoard.style";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { friendRecoActions, userActions } from "../../../../../_actions";
import { KakaoIcon, SharedIcon } from "./DashBoard.svg";
import { stempData } from "../../../../models/RcmdFreePage";
import {
  copyToClipboard,
  shareKko,
} from "../../../../businesslogics/RcmdFreePage";

const DashBoard = ({ getFriendRecoFreeChart, friendRecoFreeChart, user }) => {
  const [friendNumber, setFriendNumber] = useState(0);
  const [nextGiveAwayNumber, setNextGiveAwayNumber] = useState(null);
  useEffect(() => {
    if (!friendRecoFreeChart.loaded) getFriendRecoFreeChart();
    else {
      setFriendNumber(friendRecoFreeChart.data.friend_list.length);
    }
  }, [friendRecoFreeChart.loaded]);

  useEffect(() => {
    if (friendNumber >= 10) {
      setNextGiveAwayNumber(0);
      return;
    }
    for (let stemp of stempData) {
      if (stemp.isGiveAway && stemp.goalNumber > friendNumber) {
        setNextGiveAwayNumber(stemp.goalNumber - friendNumber);
        return;
      }
    }
  }, [friendNumber]);

  return (
    <S.Wrapper>
      <S.BtnBoxContainer>
        <S.TextContainer>
          <S.BoxTitle>나의 초대 현황</S.BoxTitle>
          <S.InnerTextContainer>
            <S.BoxText>
              내가 초대한 친구
              <S.BoxTextBlack>{friendNumber}명</S.BoxTextBlack>
            </S.BoxText>
          </S.InnerTextContainer>
        </S.TextContainer>
        <S.BtnWapper>
          <S.BtnRed
            onClick={() => {
              copyToClipboard(user, "친구 초대 링크 복사하기");
            }}
          >
            <S.BtnText>
              <SharedIcon />
              초대 링크 복사하기
            </S.BtnText>
          </S.BtnRed>
          <S.BtnYellow
            onClick={() => {
              shareKko(user, "카카오톡으로 공유하기");
            }}
          >
            <S.BtnTextColor>
              <KakaoIcon /> 카카오톡으로 공유하기
            </S.BtnTextColor>
          </S.BtnYellow>
        </S.BtnWapper>
      </S.BtnBoxContainer>
    </S.Wrapper>
  );
};

function mapStateToProps(state) {
  const { authentication, friendRecoFreeChart } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    friendRecoFreeChart,
  };
}

const connectedDashBoard = connect(mapStateToProps, {
  login: userActions.login,
  loginWithToken: userActions.loginWithToken,
  getFriendRecoFreeChart: friendRecoActions.getFriendRecoFreeChart,
})(DashBoard);

export { connectedDashBoard as DashBoard };
