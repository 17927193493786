import React, { useEffect, useState } from "react";
import * as S from "./CourseRcmdB2B.style";
import { QuoteSvg } from "./CourseRcmdB2B.style.svg";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";
import { useSelector } from "react-redux";

const Reviews = [
  {
    title: "임직원들의 DX 역량 강화를 위한 초석",
    contents:
      "전사 임직원들의 DX 역량 강화를 위한 교육 도입에 고민이 많았는데, 팀스파르타에 초보자들을 대상으로 한 다양한 콘텐츠가 많아 비개발 직군 임직원들도 부담 없이 선택하여 수강할 수 있었습니다. 수강 관리도 꼼꼼하게 해주셔서 임직원들의 DX 역량 강화를 위한 초석을 다질 수 있었습니다.",
    bio: {
      logo: "/images/CourseRcmdB2B/한국투자증권.png",
      team: "한국투자증권 채용교육부",
      name: "박**",
    },
  },
  {
    title: "신입 사원들의 교육 만족도도 \n매우 높은 프로그램",
    contents:
      "코로나 영향으로 신입사원 교육을 비대면으로 진행하면서, 오프라인만큼 교육 효과를 높일 수 있는 업체를 찾고 있었습니다. 스파르타는 커리큘럼도 실습 위주로 구성되어 있고 교육 후에, 실습 프로젝트를 별도로 진행할 수 있어서 신입 사원들의 흥미와 참여도를 높일 수 있었습니다. 작품 전시회를 통해 교육 효과를 확인할 수 있었고 신입 사원들의 교육 만족도도 매우 높은 프로그램이었습니다.",
    bio: {
      logo: "/images/CourseRcmdB2B/신세계.png",
      team: "신세계I&C 인사팀",
      name: "유**",
    },
  },
];

export const CourseRcmdB2B = () => {
  const [isMobile, setIsMobile] = useState(true);
  const enrolled = useSelector((state) => state.enrolled);
  useEffect(() => {
    const { checkPlatform } = window;
    setIsMobile(checkPlatform() === "mobile");
  }, []);

  return (
    <>
      <S.HeroDiv>
        <S.HeroContainer>
          <S.HeroTextDiv>
            <S.LargeHeader>
              임직원 DX 교육
              <br />
              스파르타와 함께 하세요.
            </S.LargeHeader>
            <S.MediumHeader>
              IT 교육부터 데이터베이스 컨설팅까지
              <br />
              사내 디지털 전환에 필요한 서비스를 제공합니다.
            </S.MediumHeader>
            {!isMobile && (
              <S.ButtonDiv>
                <S.B2bButton
                  href={"https://spartacodingclub.kr/b2b?finish=true"}
                >
                  상담 신청하기
                </S.B2bButton>
              </S.ButtonDiv>
            )}
          </S.HeroTextDiv>
          <S.HeroVideoWrapper>
            <S.HeroVideo muted autoPlay loop playsInline>
              <source
                src={
                  "https://static.spartacodingclub.kr/media/trailer/b2b_hero.mp4"
                }
                type="video/mp4"
              />
              <strong>Your browser does not support the video tag</strong>
            </S.HeroVideo>
          </S.HeroVideoWrapper>
          {isMobile && <S.B2bButton>상담 신청하기</S.B2bButton>}
        </S.HeroContainer>
      </S.HeroDiv>
      <S.Banner>
        <S.BannerThumbnail
          src={`/images/CourseRcmdB2B/banner-${isMobile ? "mo" : "web"}.png`}
        />
        <S.BannerText>
          <S.BannerSubTitle>
            기업교육 상담을 신청하는 HRD상담자님께
          </S.BannerSubTitle>
          <S.BannerTitle>
            스파르타코딩클럽 강의 <br />
            무료체험 서비스 제공!
          </S.BannerTitle>
        </S.BannerText>
      </S.Banner>
      <S.Section>
        <S.Header>
          <S.Title>
            이미 많은 기업에서
            <br />
            스파르타를 선택해 주셨습니다.
          </S.Title>
        </S.Header>
        <S.LogosContainer>
          <S.Logos src={"/images/CourseRcmdB2B/logos.png"} />
        </S.LogosContainer>
        <S.Reviews>
          {Reviews.map((review) => (
            <S.Review key={`review-${review.bio.team}`}>
              <S.ReviewUpperRow>
                <QuoteSvg />
                <S.ReviewTitle>{review.title}</S.ReviewTitle>
                <S.ReviewContents>{review.contents}</S.ReviewContents>
              </S.ReviewUpperRow>
              <S.ReviewAuthor>
                <S.ReviewLogo
                  src={review.bio.logo}
                  mobileWidth={48}
                  mobileHeight={48}
                  tabletWidth={58}
                  tabletHeight={58}
                />
                <S.ReviewBio>
                  {review.bio.team}
                  <br />
                  {review.bio.name}
                </S.ReviewBio>
              </S.ReviewAuthor>
            </S.Review>
          ))}
        </S.Reviews>
      </S.Section>
      <S.Wrapper>
        <S.FloagingDiv>
          <S.FloatingDivText>
            <S.FloatingDivTextSpan>
              {isMobile
                ? `상담 신청하고 \n무료 체험하세요!`
                : `더 자세히 알고 싶다면 상담 신청하고 무료 체험하세요!`}
            </S.FloatingDivTextSpan>
            <br />
            {!isMobile && `기업교육 담당자가 24시간 내로 연락드립니다.`}
          </S.FloatingDivText>
          <S.FloatingBtnContainer>
            <S.FloatingBtn
              onClick={() => {
                sendLogActionRcmdPage(
                  "b2b",
                  "b2b",
                  "cta",
                  "상담 신청하기",
                  "https://spartacodingclub.kr/b2b?finish=true",
                  "mainPage",
                  enrolled.data
                );
                window.open("https://spartacodingclub.kr/b2b?finish=true");
              }}
            >
              상담 신청하기
            </S.FloatingBtn>
          </S.FloatingBtnContainer>
        </S.FloagingDiv>
      </S.Wrapper>
    </>
  );
};
