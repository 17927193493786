import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./HomeworkAnswerPage.module.scss";
import { LecMenuComponent } from "../LecturePage/LecMenu/LecMenuComponent";
import { lectureMenuComponents } from "../LecturePage/LecMenu/constants";
import { BroadcastContainer } from "../LecturePage/BroadcastContainer";
import { LecNav } from "../LecturePage/LecNav";
import { LecPlayer } from "../LecturePage/LecPlayer";
import { LecMenu } from "../LecturePage/LecMenu";
import {
  enrolledActions,
  homeworksActions,
  lectureActions,
} from "../../_state/actions";
import { HomeworkAnswerPreviousBtn } from "./HomeworkAnswerPreviousBtn";
import { LearnTimeContainer } from "../LecturePage/LearnTimeContainer";

const cx = classNames.bind(styles);

function HomeworkAnswerPage({
  match,
  getMaterialByEnrolled,
  homeworkAnswer,
  lecture_v2,
  getHomeworkAnswer,
  setEnrolled,
}) {
  const { enrolled_id, enrolled_homework_id, homework_id } = match.params;
  const [isDual, setIsDual] = useState(false);
  const [material, setMaterial] = useState({ loaded: false });

  useEffect(() => {
    setEnrolled(enrolled_id);
    getHomeworkAnswer(enrolled_homework_id);
  }, [enrolled_id, homework_id]);

  useEffect(() => {
    if (homeworkAnswer.loaded) {
      getMaterialByEnrolled(
        enrolled_id,
        homeworkAnswer.data.homework.week
      ).then((response) => {
        if (response.ok) {
          setMaterial({ loaded: true, data: response.context });
        }
      });
    }
  }, [homeworkAnswer.loaded]);

  if (homeworkAnswer.loaded) {
    const { homework, homework_answer, homework_snippets } =
      homeworkAnswer.data;
    return (
      <div className={cx("homework-answer-page")}>
        {/*네비게이션*/}
        <div
          className={cx("homework-answer-page__nav", `${isDual ? "dual" : ""}`)}
        >
          <LecMenuComponent
            component={lectureMenuComponents[0]}
            idx={0}
            isDual={isDual}
            isNav={true}
          />
          <BroadcastContainer />
          {/*<LearnTimeContainer />*/}
          {
            <LecNav
              isDual={isDual}
              title={`${homework.title} 해설`}
              clickable={false}
            />
          }
          <HomeworkAnswerPreviousBtn
            enrolled_id={enrolled_id}
            enrolled_homework_id={enrolled_homework_id}
          />
        </div>

        {/*영상*/}
        <LecPlayer
          isDual={isDual}
          link={homework_answer["video_link"]}
          lecture_v2={lecture_v2}
          isHomeWork={true}
          enrolled_homework_id={enrolled_homework_id}
        />

        {/*메뉴바*/}
        <LecMenu
          isDual={isDual}
          setIsDual={setIsDual}
          material={material}
          codeSnippets={{ data: homework_snippets, loaded: true }}
        />
      </div>
    );
  } else {
    return <></>;
  }
}

function mapStateToProps(state) {
  const { homeworkAnswer, lecture_v2 } = state;
  return {
    homeworkAnswer,
    lecture_v2,
  };
}

const connectedHomeworkAnswerPage = connect(mapStateToProps, {
  setEnrolled: enrolledActions.setEnrolled,
  getHomeworkAnswer: homeworksActions.getHomeworkAnswer,
  getMaterialByEnrolled: lectureActions.getMaterialByEnrolled,
})(HomeworkAnswerPage);
export { connectedHomeworkAnswerPage as HomeworkAnswerPage };
