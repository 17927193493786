import { config } from "../config";
import { API_VERSION } from "./utils";

const baseUrl = `${config.apiUrl}/${API_VERSION}`;

export const getApiVerifyToken = () => {
  return `${baseUrl}/accounts/verify/token`;
};

export const getApiResetPassword = () => {
  return `${baseUrl}/accounts/reset_jwt`;
};

export const getApiAccountInfo = (email) => {
  return `${baseUrl}/accounts/${email}`;
};

export const getApiAccountInfoByPhone = (phone, country_code) => {
  return `${baseUrl}/accounts/${phone}/${country_code}`;
};

export const getApiUserPointChanceOnce = () => {
  return `${baseUrl}/accounts/chance`;
};

export const getApiUserPointChanceAll = () => {
  return `${baseUrl}/accounts/chance/all`;
};

export const getApiStudyplan = (round_id) => {
  return `${baseUrl}/accounts/studyplan/${round_id}`;
};

export const getApiAccount = () => {
  return `${baseUrl}/accounts/`;
};
