import {
  govTotalStudyTimeConstants,
  govDailyRecordConstants,
  GovConstants,
  govEnrollableRoundsConstants,
} from "../constants";
import { reducer } from "./reducer";

const initState = {
  loaded: false,
};

export function hasDoneDailyAuth(state = initState, action) {
  switch (action.type) {
    case GovConstants.GOV_DAILY_AUTH_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GovConstants.GOV_DAILY_AUTH_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GovConstants.GOV_DAILY_AUTH_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function hasGovAuthorized(state = initState, action) {
  switch (action.type) {
    case GovConstants.AUTHORIZE_USER_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GovConstants.AUTHORIZE_USER_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GovConstants.AUTHORIZE_USER_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function isGovEnrolledBlocked(state = initState, action) {
  switch (action.type) {
    case GovConstants.IS_GOV_ENROLLED_BLOCKED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GovConstants.IS_GOV_ENROLLED_BLOCKED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GovConstants.IS_GOV_ENROLLED_BLOCKED_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function isWrittenGovRecord(state = initState, action) {
  switch (action.type) {
    case GovConstants.DO_DETAIL_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GovConstants.DO_DETAIL_INIT:
      return Object.assign({}, initState, {
        loaded: false,
      });
    case GovConstants.DO_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GovConstants.DO_DETAIL_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function govRoundStartSoon(state = initState, action) {
  switch (action.type) {
    case GovConstants.GOV_ROUND_START_SOON_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GovConstants.GOV_ROUND_START_SOON_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GovConstants.GOV_ROUND_START_SOON_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function govAttendRecords(state = initState, action) {
  switch (action.type) {
    case GovConstants.GOV_ATTEND_RECORD_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case GovConstants.GOV_ATTEND_RECORD_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case GovConstants.GOV_ATTEND_RECORD_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function govEnrollableRounds(state = initState, action) {
  return reducer(state, action, govEnrollableRoundsConstants);
}

export function govTotalStudyTime(state = initState, action) {
  return reducer(state, action, govTotalStudyTimeConstants);
}

export function govDailyRecord(state = initState, action) {
  return reducer(state, action, govDailyRecordConstants);
}
