import { useSelector } from "react-redux";
import * as S from "./FinishRecommend.style";
import FinishRecommendContent from "./FinishRecommendContent";
import { useEffect } from "react";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { recommendCourses } from "./finishRecommend.model";

const FinishRecommend = ({ course }) => {
  const recommendData = recommendCourses[course];

  const {
    imgSrc,
    pageTitle,
    pageUrl,
    recommendTitle,
    productDescription,
    productTitle,
    contentList,
    nextHref,
  } = recommendData;

  const enrolled = useSelector((state) => state.enrolled);

  useEffect(() => {
    sendCPLog("scc_courseRcmdPage_view", {
      course_title: enrolled.data?.courses.title,
      course_id: enrolled.data?.courses._id,
      url: "https://online.spartacodingclub.kr/free_finish?enrolled_id=",
    });
  }, []);

  const { user } = useSelector((state) => state.authentication);

  const handleClickDetail = () => {
    sendCPLog("scc_courseRcmdPage_click_button", {
      course_title: enrolled.data?.courses.title,
      course_id: enrolled.data?.courses._id,
      page_title: pageTitle,
      page_url: pageUrl,
      url: nextHref,
    });
    window.open(nextHref, "_blank");
  };

  return (
    <S.Container>
      <S.Title>
        {user.name} 님께 추천드리는
        <br />
        {recommendTitle}
      </S.Title>
      <S.CourseBox>
        <S.Content>
          <S.Header>
            <S.RecommendCourseBoxTitleImg src={imgSrc} />
            <S.ContentText>
              <S.CourseDescription>{productDescription}</S.CourseDescription>
              <S.CourseName>{productTitle}</S.CourseName>
            </S.ContentText>
          </S.Header>
          <S.Hr />
          <S.Footer>
            {contentList &&
              contentList.map(({ category, value, detail }) => (
                <FinishRecommendContent
                  category={category}
                  value={value}
                  detail={detail}
                />
              ))}
          </S.Footer>
        </S.Content>
        <S.Cta onClick={handleClickDetail}>자세히 보기</S.Cta>
      </S.CourseBox>
    </S.Container>
  );
};

export default FinishRecommend;
