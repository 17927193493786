import styled from "@emotion/styled";
import { neutralDay, wTitle4 } from "@teamsparta/design-system";

export const BackButtonButton = styled.div`
  ${wTitle4};
  color: ${neutralDay.gray70};
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  cursor: pointer;
`;
