import styled from "styled-components";
import { Device } from "../../../../../_styles/device";
import { neutralDay } from "@teamsparta/design-system";
import { fontPretendard } from "../../../../styles/font";

export const InfoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  width: 100%;

  position: relative;

  ${Device.Desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const InfoLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${neutralDay.gray60};
  font-family: ${fontPretendard};

  ${Device.Desktop} {
    width: 100px;
    flex-shrink: 0;
    font-size: 16px;
  }
`;

export const InfoInputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;

  ${Device.Desktop} {
    width: 100%;
    max-width: 500px;
  }
`;

export const InfoInput = styled.input`
  font-size: 14px;
  font-weight: 700;

  width: 100%;
  border-radius: 12px;
  border: none;
  background: ${neutralDay.gray0};
  box-sizing: border-box;

  padding: 20px;
  font-family: ${fontPretendard};
  color: ${neutralDay.gray100};

  ${Device.Desktop} {
    width: 100%;
  }

  &:disabled {
    background-color: rgba(246, 249, 250, 0.6);
    color: rgba(180, 191, 198, 0.6);
  }
  &::placeholder {
    color: ${neutralDay.gray50};
  }

  // number type 화살표 제거
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const InfoLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.96px;
  padding: 20px;
  box-sizing: border-box;

  font-family: ${fontPretendard};
  color: ${neutralDay.gray90};
`;

export const InfoButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 7px 0;
`;

export const InfoOptionButton = styled.div`
  height: 42px;
  padding: 0 20px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fontPretendard};
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  cursor: pointer;
  border: 1px solid
    ${({ isActive }) => (isActive ? neutralDay.gray100 : neutralDay.gray20)};
  color: ${({ isActive }) => (isActive ? neutralDay.white : neutralDay.gray90)};
  background: ${({ isActive }) =>
    isActive ? neutralDay.gray100 : neutralDay.white};
`;

export const InfoChangeButton = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: flex;
  height: 36px;
  width: fit-content;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 34px;
  background: ${neutralDay.gray100};
  span {
    width: fit-content;
    color: white;
    text-align: center;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
  }
`;
