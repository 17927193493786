import {
  useCourseData,
  useSyllabus,
} from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { Week } from "./Week";
import * as S from "./Syllabus.style";
import { useParams } from "react-router-dom";

import { useEffect } from "react";
import { validateObjectId } from "../../../_helper";
import { useDispatch } from "react-redux";

export const Syllabus = () => {
  const syllabus = useSyllabus();
  const { enrolled_id } = useParams();
  useCourseData();

  const WILButtonClickHandler = () => {
    window.location.href = `/enrolleds/${enrolled_id}/ewil`;
  };
  return (
    <>
      {syllabus?.map((weekData, idx) => {
        return <Week weekData={weekData} key={idx} />;
      })}
      <S.WILBanner onClick={WILButtonClickHandler}>
        개발일지 쓰러가기
      </S.WILBanner>
    </>
  );
};
