import { handleHotkeys } from "./handleHotkeys";
import videojs from "video.js";
import hlsQualitySelector from "videojs-hls-quality-selector";
import { setRequestHeader } from "../playerV3";
import { isIOS } from "mobile-device-detect";

export const getPlayerV3Options = ({
  lecture_v2,
  playerType,
  playerRef,
  setIsFullscreen,
  setIsPlaying,
  setVolume,
  setIsMuted,
  setPlaybackSpeedRate,
}) => {
  const classification = lecture_v2?.data?.["classification"];
  const vodKey = lecture_v2?.data?.["vod_key"];
  const version = lecture_v2?.data?.["version"];
  const lecture_id = lecture_v2?.data?.["lecture_id"];
  const vttVersion = lecture_v2?.data?.["vtt_version"];

  if (!videojs.getPlugin("hlsQualitySelector")) {
    videojs.registerPlugin("hlsQualitySelector", hlsQualitySelector);
  }
  setRequestHeader(
    lecture_v2?.loaded ? "https://drm.spartacodingclub.kr/license/" : undefined
  );
  const videoSource = generateUrl(
    "lectures",
    classification,
    vodKey,
    version,
    lecture_id,
    playerType === "V2" ? "m3u8" : "mp4"
  );

  const MP4URL = generateUrl(
    "lectures",
    classification,
    vodKey,
    version,
    lecture_id,
    "mp4"
  );

  const vttSource = generateVttUrl(
    "subtitles",
    classification,
    vodKey,
    version,
    lecture_id,
    "vtt",
    vttVersion
  );

  const thumbnail = generateUrl(
    "thumbs",
    classification,
    vodKey,
    version,
    lecture_id,
    "png"
  );

  const videoJsOptions = {
    autoplay: false,
    controls: false,
    inactivityTimeout: 1000,
    crossOrigin: "anonymous",
    userActions: {
      hotkeys: (event) =>
        handleHotkeys(
          event,
          playerRef,
          setIsFullscreen,
          setIsPlaying,
          setVolume,
          setIsMuted,
          setPlaybackSpeedRate
        ),
    },

    // overrideNative Options
    html5: {
      vhs: {
        overrideNative: true,
        cacheEncryptionKeys: true,
        experimentalBufferBasedABR: true,
      },
      hls: {
        overrideNative: true, // 네이티브 HLS 재생을 사용하지 않음
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
    poster: thumbnail,
    sources: {
      src: videoSource,
      type: playerType === "V2" ? "application/x-mpegURL" : "video/mp4",
    },
    isLoaded: true,
    // tracks: [
    //   {
    //     kind: "captions",
    //     src: vttSource,
    //     srclang: "kr",
    //     label: "Korean",
    //     default: localStorage.getItem("captionV3") === "true",
    //   },
    // ],
    plugins: {
      hlsQualitySelector: {
        displayCurrentQuality: true,
      },
    },
  };
  return { videoJsOptions, MP4URL, vttSource };
};

export function generateVttUrl(
  basePath,
  classification,
  vodKey,
  version,
  lecture_id,
  fileType,
  vttVersion
) {
  if (!vttVersion) {
    return `https://video.spartacodingclub.kr/vod/${classification}/${vodKey}/${version}/${basePath}/${lecture_id}.${fileType}`;
  }
  return `https://video.spartacodingclub.kr/vod/${classification}/${vodKey}/${vttVersion}/${basePath}/${lecture_id}.${fileType}`;
}

export function generateUrl(
  basePath,
  classification,
  vodKey,
  version,
  lecture_id,
  fileType
) {
  return `https://video.spartacodingclub.kr/vod/${classification}/${vodKey}/${version}/${basePath}/${lecture_id}.${fileType}`;
}
