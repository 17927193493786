import privateInstance from "../../_helper/axios";

async function getPrequestionExist(course_id, round_id) {
  return await privateInstance
    .get(`/prequestion/${course_id}/${round_id}`)
    .then((resp) => resp.data);
}

async function getPreqOfEnrolled(enrolled_id) {
  return await privateInstance
    .get(`/prequestion/enrolled/${enrolled_id}`)
    .then((resp) => resp.data);
}

export const prequestionService = {
  getPrequestionExist,
  getPreqOfEnrolled,
};
