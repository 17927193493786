import { urgConstants } from "../constants";
import { UserRaceGroupService } from "../services";

function getIsRankGroupCreated(round_id) {
  function request() {
    return { type: urgConstants.GET_URG_REQUEST };
  }

  function success(response) {
    return { type: urgConstants.GET_URG_SUCCESS, response };
  }

  function failure(error) {
    return { type: urgConstants.GET_URG_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserRaceGroupService.getRoundUrg(round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function createRankGroup(round_id) {
  function request() {
    return { type: urgConstants.CREATE_URG_REQUEST };
  }

  function success(response) {
    return { type: urgConstants.CREATE_URG_SUCCESS, response };
  }

  function failure(error) {
    return { type: urgConstants.CREATE_URG_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    UserRaceGroupService.createRoundUrg(round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getUserRankGroupInfo(enrolled_id) {
  function request() {
    return { type: urgConstants.GET_USER_GROUP_INFO_REQUEST };
  }

  function success(response) {
    return { type: urgConstants.GET_USER_GROUP_INFO_SUCCESS, response };
  }

  function failure(error) {
    return { type: urgConstants.GET_USER_GROUP_INFO_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    UserRaceGroupService.getUserRankGroupInfo(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getWeeksAfterStart(enrolled_id) {
  function request() {
    return { type: urgConstants.GET_WEEKS_AFTER_START_REQUEST };
  }

  function success(response) {
    return { type: urgConstants.GET_WEEKS_AFTER_START_SUCCESS, response };
  }

  function failure(error) {
    return { type: urgConstants.GET_WEEKS_AFTER_START_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserRaceGroupService.getWeeksAfterStart(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const UserRaceGroupActions = {
  getIsRankGroupCreated,
  createRankGroup,
  getUserRankGroupInfo,
  getWeeksAfterStart,
};
