import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { connect, useSelector } from "react-redux";
import styles from "./CourseRcmdDdingdong.module.scss";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

function CourseRcmdDdingdong({ user }) {
  const [isMobile, setIsMobile] = useState(false);
  const enrolled = useSelector((state) => state.enrolled);

  useEffect(() => {
    if (window.checkPlatform() === "mobile") {
      setIsMobile(true);
    }
  }, []);

  return (
    <div
      className={cx("course_rcmd__wrapper")}
      style={{
        "--bg-image": `url(${`/assets/images/finish/bg_ddingdong${
          isMobile ? "_mo" : ""
        }.png`}`,
      }}
    >
      <div className={cx("course_rcmd__header")}>
        <h1>
          중고등학생 코딩을 시작하는
          <br />
          {user.name}님을 위한 추천 코스
        </h1>
      </div>
      <div className={cx("course_rcmd__body")}>
        <img
          className={cx("course_rcmd__body__img")}
          src={"/assets/images/finish/logo_ddingdong.svg"}
          alt={"logo_chang"}
        />
        <div className={cx("course_rcmd__body__container")}>
          <div className={cx("course_rcmd__card")}>
            <img
              className={cx("course_rcmd__card__thumb")}
              src={"/assets/images/finish/ic_dd_1.png"}
            />
            <p className={cx("course_rcmd__card__detail")}>
              일주일에 40분,&nbsp;
              <br />
              부담 없는 학습분량
            </p>
          </div>
          <div className={cx("course_rcmd__card")}>
            <img
              className={cx("course_rcmd__card__thumb")}
              src={"/assets/images/finish/ic_dd_2.png"}
            />
            <p className={cx("course_rcmd__card__detail")}>
              결과물을 완성하며 배우는&nbsp;
              <br />
              진짜 코딩
            </p>
          </div>
          <div className={cx("course_rcmd__card")}>
            <img
              className={cx("course_rcmd__card__thumb")}
              src={"/assets/images/finish/ic_dd_3.png"}
            />
            <p className={cx("course_rcmd__card__detail")}>
              영어마을에서 영어를 배우듯&nbsp;
              <br />
              실전처럼 배우는 코딩
            </p>
          </div>
        </div>
      </div>
      <div className={cx("course_rcmd__footer")}>
        {/* <img src={"/assets/images/finish/dd_coupon.png"} /> */}
        <p>
          중고등학생도 일주일에 딱 40분,
          <br />
          6개월만에 코딩 포트폴리오가 완성됩니다.
        </p>
        <button
          onClick={() => {
            sendLogActionRcmdPage(
              "ddingdong",
              "ddingdong",
              "cta",
              "띵동코딩 보러가기",
              "https://ddingdong.spartacodingclub.kr/",
              "mainPage",
              enrolled.data
            );
            window.open("https://ddingdong.spartacodingclub.kr/");
          }}
        >
          띵동코딩 보러가기
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdDdingdong = connect(
  mapStateToProps,
  {}
)(CourseRcmdDdingdong);
export { connectedCourseRcmdDdingdong as CourseRcmdDdingdong };
