import privateInstance from "../../_helper/axios";
import { config } from "../../../config";
import { handleResponse } from "../../../_helpers";
import { getDeviceId } from "../../businesslogics/logging";

async function getPoints() {
  return await privateInstance.get(`/users/points`).then((resp) => resp.data);
}

async function getExpirationPoints() {
  return await privateInstance
    .get(`/users/points/expiration`)
    .then((resp) => resp.data);
}

async function openRandombox() {
  return await privateInstance.post(`/users/chance`).then((resp) => resp.data);
}

function loginWithToken(token) {
  const device_id = getDeviceId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ device_id }),
    credentials: "include",
  };
  return fetch(`${config.apiUrl}/v1/auth/login/token`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // login successful if there's a jwt token in the response
      // server response includes 'Set-Cookie' headers
      window.enhanced_conversion_data = {
        email: response.user?.email,
        phone: response.user?.phone,
      };
      return response.user;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function withdrawUser(data) {
  return await privateInstance
    .post(`/users/withdrawal`, data)
    .then((resp) => resp.data);
}

async function getManaged() {
  return await privateInstance.get(`/users/managed`).then((resp) => resp.data);
}

async function setManaged(data) {
  return await privateInstance
    .post(`/users/managed`, data)
    .then((resp) => resp.data);
}

async function getMarketingAgreement() {
  return await privateInstance
    .get(`/users/marketing_agreement`)
    .then((resp) => resp.data);
}

async function setMarketingAgreement(data) {
  return await privateInstance
    .post(`/users/marketing_agreement`, data)
    .then((resp) => resp.data);
}

async function getUserRegNo() {
  return await privateInstance.get(`/users/regno`).then((resp) => resp.data);
}

async function postUserRegNo(data) {
  return await privateInstance
    .post(`/users/regno`, data)
    .then((resp) => resp.data);
}
async function confirmEmail(data) {
  return await privateInstance
    .put(
      `/users/email_confirm`,
      data,
      Object.assign({ skipDuplicatedCheck: true }, privateInstance.config)
    )
    .then((resp) => resp.data);
}

async function sendConfirmationEmail(data) {
  return await privateInstance
    .patch(
      `/users/email_confirm`,
      data,
      Object.assign({ skipDuplicatedCheck: true }, privateInstance.config)
    )
    .then((resp) => resp.data);
}

async function validateAlpaco(email) {
  return await privateInstance
    .get(`/users/login/alpaco?email=${email}`)
    .then((resp) => resp.data);
}

const setMetaTag = (user_id) => {
  const meta = document.createElement("meta");
  meta.name = "user_id";
  meta.content = user_id;
  document.getElementsByTagName("head")[0].appendChild(meta);
};

async function loginAlpaco(data) {
  return await privateInstance
    .post(`/users/login/alpaco`, data, {
      withCredentials: true,
    })
    .then((resp) => {
      // server response includes 'Set-Cookie' headers
      setMetaTag(resp.data.user._id);
      return resp.data.user;
    });
}

export const usersService = {
  getPoints,
  getExpirationPoints,
  loginWithToken,
  openRandombox,
  withdrawUser,
  getManaged,
  setManaged,
  getMarketingAgreement,
  setMarketingAgreement,
  getUserRegNo,
  postUserRegNo,
  validateAlpaco,
  loginAlpaco,
  sendConfirmationEmail,
  confirmEmail,
};
