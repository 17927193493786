import { FEATURES } from "../constants";
import { featuresService } from "../services";

function getFeatureFlags() {
  function request() {
    return { type: FEATURES.GET_FEATURES_FAILURE };
  }

  function success(response) {
    return { type: FEATURES.GET_FEATURES_SUCCESS, response };
  }

  function failure(error) {
    return { type: FEATURES.GET_FEATURES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    featuresService.getFeatureFlags().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const useGetFeatureFlags = () => {
  return getFeatureFlags();
};
export const featureActions = {
  getFeatureFlags,
};
