import privateInstance from "../../_helper/axios";
import { useMutation, useQuery } from "react-query";
import { history } from "../../../_helpers";
import axios from "../../../_helpers/axios";

export const usePostNps = (enrolled, nps) => {
  const enrolledId = enrolled.data?.enrolled_id;
  const courseId = enrolled.data?.course_id;
  return useMutation(
    `postNps`,
    (week) => {
      return privateInstance.post(`/nps/${enrolledId}/${week}`, {
        ...nps,
        isLastWeek: true,
      });
    },
    {
      onSuccess: (result) => {
        if (result.data.ok) {
            history.push(`/free_finish?enrolled_id=${enrolledId}`);
        } else if (result.data.msg) {
          alert(result.data.msg);
        }
      },
      onError: (error) => {
        console.debug("👎 [post nps] ", error);
      },
    }
  );
};

export const getDefaultNextStep = async (courseId) => {
  if (!courseId) return false;
  return await privateInstance
    .get(`/nps/default_next_step/${courseId}`)
    .then((resp) => {
      return resp.data.context;
    });
};

export const useDefaultNextStep = (courseId) => {
  return useQuery(
    ["GoalQuestion", courseId],
    () => {
      if (courseId) {
        return getDefaultNextStep(courseId);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const postEnrolledFinish = async (enrolledId) => {
  if (!enrolledId) return false;
  return await axios
    .post(`/enrolleds/finish`, { enrolled_id: enrolledId })
    .then((resp) => {})
    .catch((error) => {});
};

export const getEnrolledFinishSummary = async (enrolledId) => {
  if (!enrolledId) return false;
  return await privateInstance
    .get(`/enrolleds/${enrolledId}/summary`)
    .then((resp) => resp.data.context);
};

export const useEnrolledFinishSummary = (enrolledId) => {
  return useQuery(
    ["EnrolledFinishSummary", enrolledId],
    () => {
      if (enrolledId) {
        return getEnrolledFinishSummary(enrolledId);
      }
    },
    {
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
    }
  );
};
