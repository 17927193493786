import { blockingCourseIds} from "../../utils/blockingCourseIds";

export const addRcmd = (url, fName, fUid) =>
  `${url}${fName && fUid && `?f_name=${fName}&f_uid=${fUid}`}`;

export const rcmdClassCardNextUrl = (title, courseUrl, course_id) => {
  
  if (blockingCourseIds.includes(course_id)) {
    return `https://online.spartacodingclub.kr/payment/${course_id}`;
  }

  if (title === "Zep으로 메타버스 개발 맛보기") {
    return courseUrl;
  } else if (courseUrl.includes("nb")) {
    return `https://spartacodingclub.kr/${courseUrl}`;
  } else {
    return `https://spartacodingclub.kr/online/${courseUrl}`;
  }
};
