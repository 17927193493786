import React from "react";
import { useSelector } from "react-redux";
import CompleteCelebration from "../CompleteCelebration/CompleteCelebration";
import CompleteRecommendTrack from "../CompleteRecommendTrack/CompleteRecommendTrack";
import * as S from "./recommendTrackSection.style";

export const RecommendTrackSection = ({ courseId, courseTitle }) => {
  const { user } = useSelector((state) => state.authentication);

  return (
    <S.Container>
      <S.Confetti />
      <CompleteCelebration courseTitle={courseTitle} userName={user.name} />
      <CompleteRecommendTrack courseId={courseId} courseTitle={courseTitle} />
    </S.Container>
  );
};
