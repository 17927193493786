import { useSelector } from "react-redux";
import { parseUserAgent } from "../../utils/ErrorReport/userAgent";
import { useUser } from "./useInitialize";
import privateInstance from "../../_helper/axios";
import { useMutation } from "react-query";

export const usePostFeedback = (setIsSelected) => {
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const course = useSelector((state) => state.course);
  const user = useUser();
  const userAgent = parseUserAgent(window.navigator.userAgent);
  const { enrolled_id, enrolled_detail_id, lecture_id } = enrolledDetail.data;
  const { _id: user_id, name, email, phone } = user;
  const formedData = {
    is_gov: course.data.is_gov,
    type: "실시간 피드백",
    score: 0,
    score_string: "0",
    errorTime: new Date(),
    user_id: user_id,
    os: userAgent.os,
    browser: userAgent.browser,
    name: name,
    email: email,
    phone: phone,
    enrolled_id: enrolled_id,
    enrolled_detail_id: enrolled_detail_id,
    lecture_id: lecture_id,
  };
  return useMutation(
    async ({ feedbackValue, feedbackComment }) => {
      const data = {
        ...formedData,
        score_string: feedbackValue,
        comment: feedbackComment,
      };
      const response = await privateInstance.post("/report/lectures", data);
      return response.data;
    },
    {
      onSuccess: () => {
        setIsSelected(true);
        console.log("onSuccess");
      },
      onError: () => {
        console.log("onError");
      },
    }
  );
};
