import {
  govDailyRecordConstants,
  GovConstants,
  govEnrollableRoundsConstants,
  govTotalStudyTimeConstants,
} from "../constants";
import { govService } from "../services";
import { action } from "./action";

function getHasGovAuthorized() {
  function request() {
    return { type: GovConstants.AUTHORIZE_USER_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.AUTHORIZE_USER_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.AUTHORIZE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    govService.getHasGovAuthorized().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function initGovEnrolledDetail() {
  function request() {
    return { type: GovConstants.DO_DETAIL_INIT };
  }
  return (dispatch) => {
    dispatch(request());
  };
}

function doGovEnrolledDetail(enrolled_id, enrolled_detail_id) {
  function request() {
    return { type: GovConstants.DO_DETAIL_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.DO_DETAIL_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.DO_DETAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    govService.doGovEnrolledDetail(enrolled_id, enrolled_detail_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function doGovEnrolledHomework(enrolled_id, enrolled_homework_id) {
  function request() {
    return { type: GovConstants.DO_HOMEWORK_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.DO_HOMEWORK_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.DO_HOMEWORK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    govService.doGovEnrolledHomework(enrolled_id, enrolled_homework_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getIsGovEnrolledBlocked(enrolled_id) {
  function request() {
    return { type: GovConstants.IS_GOV_ENROLLED_BLOCKED_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.IS_GOV_ENROLLED_BLOCKED_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.IS_GOV_ENROLLED_BLOCKED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return govService.getIsGovEnrolledBlocked(enrolled_id);
  };
}

function getGovRoundStartSoon(category) {
  function request() {
    return { type: GovConstants.GOV_ROUND_START_SOON_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.GOV_ROUND_START_SOON_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.GOV_ROUND_START_SOON_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return govService.getGovRoundStartSoon(category).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getGovAttendRecords() {
  function request() {
    return { type: GovConstants.GOV_ATTEND_RECORD_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.GOV_ATTEND_RECORD_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.GOV_ATTEND_RECORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return govService.getGovAttendRecords().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function postGovAttendRecords({ rtype, reason }) {
  function request() {
    return { type: GovConstants.GOV_ATTEND_RECORD_REQUEST };
  }

  function success(response) {
    return { type: GovConstants.GOV_ATTEND_RECORD_SUCCESS, response };
  }

  function failure(error) {
    return { type: GovConstants.GOV_ATTEND_RECORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return govService.postGovAttendRecords({ rtype, reason }).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export function getGovRoundCanEnroll() {
  return action(govService.getGovRoundCanEnroll, govEnrollableRoundsConstants);
}

export function getGovDailyRecord(userId, startDate, endDate) {
  return action(govService.getGovDailyRecord, govDailyRecordConstants, [
    userId,
    startDate,
    endDate,
  ]);
}

export function getTotalStudyTime(userId) {
  return action(govService.getTotalStudyTime, govTotalStudyTimeConstants, [
    userId,
  ]);
}

export const govAction = {
  initGovEnrolledDetail,
  getHasGovAuthorized,
  doGovEnrolledDetail,
  doGovEnrolledHomework,
  getIsGovEnrolledBlocked,
  getGovRoundStartSoon,
  getGovAttendRecords,
  postGovAttendRecords,
  getGovRoundCanEnroll,
};
