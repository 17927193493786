export function action(service, constants, args) {
  function request() {
    return { type: constants.REQUEST, isLoaded: false, isLoading: true };
  }

  function success(response) {
    return {
      type: constants.SUCCESS,
      response,
      isLoaded: true,
      isLoading: false,
    };
  }

  function failure(error) {
    return {
      type: constants.FAILURE,
      error,
      isLoaded: false,
      isLoading: false,
    };
  }
  return (dispatch) => {
    dispatch(request());
    service.apply(null, args).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}
