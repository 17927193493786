import * as S from "./packageDetail.style";

export const PackageDetail = ({ course_id }) => {
  const packageDetailData = {
    "643e10c8ae797ba22b4a8c3f": {
      thumb: "pkg_webapp_v2",
      name: "[패키지] 웹 + 앱 코딩 메이커",
      desc: `[코딩이 처음이어도 쉽게 배우는 웹개발 A to Z / ChatGPT로 웹개발 완전정복] 중 택 1,
      앱개발 종합반
      *6개월 내 원할 때 수강 가능`,
    },
    "643e10c8ae797ba22b4a8c22": {
      thumb: "pkg_sqld",
      name: "[패키지] SQL + SQLD 완전 정복",
      desc: `엑셀보다 쉽고 빠른 SQL,
      실전 SQLD 자격증 대비반
      *6개월 내 원할 때 수강 가능`,
    },
    "643e10c8ae797ba22b4a8c2b": {
      thumb: "pkg_gpt",
      name: "[패키지] ChatGPT 300% 활용 모음집",
      desc: `ChatGPT를 활용한 클론코딩,
      ChatGPT를 활용한 업무자동화,
      ChatGPT를 활용한 데이터 분석
      *6개월 내 원할 때 수강 가능`,
    },
    "643e10c8ae797ba22b4a8c36": {
      thumb: "pkg_nocode",
      name: "[패키지] 개발 걱정 NO! 노코드 올인원",
      desc: `노코드로 빠르게 웹 서비스 만들기,
      노코드로 완성도 있는 웹사이트 만들기
      *6개월 내 원할 때 수강 가능`,
    },
    "649e631c89ddea656ee54fa0": {
      thumb: "pkg_webflowga4",
      name: "[패키지] 노코드로 웹사이트 만들고 분석하기",
      desc: `- 노코드로 완성도 있는 웹사이트 만들기
      - GA4로 한시간만에 노코드 데이터 완전정복하기
      *6개월 내에 자유롭게 수강 시작`,
    },
    "651393f7f736e71c7ece2f61": {
      thumb: "pkg_util",
      name: "[패키지] 컴퓨터활용능력 1급 자격증 필기 + 실기",
      desc: `컴퓨터활용능력 1급 자격증 대비반 필기 + 실기
      *6개월 내에 자유롭게 수강 시작`,
    },
    "6513b1e7f736e71c7ece2f62": {
      thumb: "pkg_worker",
      name: "[패키지] 직장인 필수 종합반",
      desc: `- AI 종합반 + 업무자동화 종합반 
      *6개월 내에 자유롭게 수강 시작`,
    },
    "65c091d522e51a142ed0aedc": {
      thumb: "package_computer",
      name: "[격파르타] 컴활 1급 자격증 챌린지",
      desc: `컴퓨터활용능력 1급 필기 + 실기 패키지  
      *합격 시 자격증 수강료 100% 환급! `,
    },
    "65c31b6e1df3d7d24e14d521": {
      thumb: "package_computer_ipad",
      name: "[컴활 1급 환급 챌린지 + iPad]",
      desc: `컴활 1급 필기+실기 패키지 + iPad
      *합격 시 자격증 수강료 100% 환급!`,
    },
  };

  return (
    packageDetailData[course_id] && (
      <S.Container>
        <S.Title>강의 정보</S.Title>
        <S.InfoContainer>
          <S.Thumb
            src={`/assets/images/payment/thumb/${packageDetailData[course_id].thumb}.png`}
          />
          <S.TextContainner>
            <S.PkgName>{packageDetailData[course_id].name}</S.PkgName>
            <S.Desc>{packageDetailData[course_id].desc}</S.Desc>
          </S.TextContainner>
        </S.InfoContainer>
      </S.Container>
    )
  );
};
