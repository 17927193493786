import * as S from "./LectureMobileFloating.style";
import { useOnlineTheme } from "../../../queries/pirates";
import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { readyToGoNextLectureAtom } from "../../../stores/mobileLectureNextButton";
import { MobileNextBtn } from "../LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import {
  useNavigateToNextContent,
  useNextContentCtaText,
} from "../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

export const LectureMobileFloating = ({
  isInitialized,
  isHomeworkPage = false,
  canGoNextLecture = false,
  canUpdateHomework = false,
  isFeedbackRequired = false,
  handleSubmitHomework = () => {},
}) => {
  const theme = useOnlineTheme();
  const navigateToNext = useNavigateToNextContent();
  const isHomeworkActive = canGoNextLecture ? true : canUpdateHomework;
  const [isMobileNextButtonShow, setIsMobileNextButtonShow] = useAtom(
    readyToGoNextLectureAtom
  );
  const ctaText = useNextContentCtaText(
    isFeedbackRequired,
    canGoNextLecture,
    isHomeworkPage ? "숙제 제출하기" : "다음 강의"
  );
  useEffect(() => {
    if (!isInitialized) {
      setIsMobileNextButtonShow(false);
    }
  }, [isInitialized]);

  return (
    <S.FloatingContainer
      theme={theme.data}
      isShowFloating={isHomeworkPage ? true : isMobileNextButtonShow}
    >
      <MobileNextBtn
        text={ctaText}
        isActive={isHomeworkActive ? isHomeworkActive : isMobileNextButtonShow}
        onClick={isHomeworkPage ? handleSubmitHomework : navigateToNext}
      />
    </S.FloatingContainer>
  );
};
