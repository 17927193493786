import { useEffect, useState } from "react";
import { getCookie } from "../../_helper";
import { useFetchIsLoggedIn } from "@teamsparta/auth-frontend";
import * as amplitude from "@amplitude/analytics-browser";

export const useIsLoggedInV2 = (isLoginModalShow) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const query = useFetchIsLoggedIn();
  const { isLoading, isSuccess, data } = query;
  const userToken = getCookie("user_token");

  useEffect(() => {
    if (!isLoading) {
      setIsAuthenticated(isSuccess && data?.is_logined);
    } else if (userToken) {
      setIsAuthenticated(true);
    }
  }, [isSuccess, data?.is_logined, isLoginModalShow]);

  return isAuthenticated;
};
export const useSetAmplitudeUserId = (isLoginModalShow) => {
  const isLoggedInV2 = useIsLoggedInV2();
  useEffect(() => {
    const user_id = getCookie("user_id");
    if (isLoggedInV2 && !isLoginModalShow && !!user_id) {
      amplitude.setUserId(user_id);
    }
  }, [isLoggedInV2, isLoginModalShow]);
};
