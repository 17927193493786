import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./CourseRcmdOnline.module.scss";
import { RcmdClassCard } from "../../components/organisms/RcmdClassCard/RcmdClassCard";
import {
  gptClassCards,
  nbClassFilterData,
  nocodeRcmdCourse,
  priorityClassCard,
  workersClassCards,
} from "../../components/organisms/RcmdClassCard/RcmdClassCardData";
import { sendLogActionRcmdPage } from "../../businesslogics/logging";

const cx = classNames.bind(styles);

function CourseRcmdOnline({
  user,
  enrolledFinishSummary,
  course,
  classroomCards,
}) {
  const [classCard, setClassCard] = useState(null);
  const [isGpt, setIsGpt] = useState(false);
  const [isNocdeFree, setIsNocdeFree] = useState(false);

  useEffect(() => {
    if (course) {
      setIsGpt(course._id === "6420ea5f7e6e4c8f5d0af5d0");
      setIsNocdeFree(course._id === "642c2dfff6dfefee6dc47bfb");
    }
  }, [course]);

  useEffect(() => {
    if (!classroomCards.loaded) return;

    const enrolledList = classroomCards.data?.full_enrolled_info_list;

    const nbClassList = [];
    for (let { course } of enrolledList) {
      if (nbClassFilterData[course._id]) {
        nbClassList.push(nbClassFilterData[course._id]);
      }
    }

    const notTakenClassCards = priorityClassCard.filter((classCard) => {
      let isExist = false;
      for (let { course } of enrolledList) {
        if (
          classCard.course_id === course._id ||
          nbClassList.includes(classCard.course_id)
        ) {
          isExist = true;
          break;
        }
      }
      return !isExist;
    });

    if (isGpt) {
      setClassCard(gptClassCards);
      return;
    }
    if (isNocdeFree) {
      setClassCard(nocodeRcmdCourse);
      return;
    }

    setClassCard(
      notTakenClassCards.length === 0
        ? workersClassCards
        : notTakenClassCards.length > 3
        ? notTakenClassCards.slice(0, 3)
        : notTakenClassCards
    );
  }, [classroomCards, isGpt, nocodeRcmdCourse]);

  if (classCard === null) return;

  const getHeaderTitle = () => {
    const isTrial = enrolledFinishSummary?.data.is_trial;

    return isGpt ? (
      <>
        ChatGPT로 이런 것까지
        <br />할 수 있어요.
      </>
    ) : isTrial ? (
      <>
        {enrolledFinishSummary.data?.goal}
        <br />
        {user.name} 님을 위한 추천
      </>
    ) : (
      <>
        {course.title} 수강생이
        <br />
        함께 구매한 과목
      </>
    );
  };

  const getHeaderDesc = () => {
    const isTrial = enrolledFinishSummary?.data.is_trial;

    return isGpt ? (
      <>
        {user.name}님이 ChatGPT를 제대로 활용하실 수 있도록
        <br />
        필요한 강의만 쏙쏙 준비했어요!
      </>
    ) : isTrial ? (
      <>
        {user.name} 님의 끊임없는 성장을 위해
        <br />
        추천과목 전용 할인쿠폰을 발급해 드렸어요!
      </>
    ) : (
      <>
        업무 효율을 200% 높여줄
        <br />
        일잘러 맞춤 코딩 강의들을 만나보세요.
      </>
    );
  };

  const goCatalog = () => {
    sendLogActionRcmdPage(
      "online",
      "online",
      "cta",
      "자세히 알아보기",
      "https://spartacodingclub.kr/catalog/featured/summary",
      "mainPage"
    );
    window.open("https://spartacodingclub.kr/catalog/featured/summary");
  };

  return (
    <>
      <div className={cx("course_rcmd_online__wrapper")}>
        <div className={cx("course_rcmd_online___header")}>
          <h1 className={cx("course_rcmd_online__header-title")}>
            {getHeaderTitle()}
          </h1>

          <p className={cx("course_rcmd__header__desc")}>{getHeaderDesc()}</p>

          <div className={cx("course_rcmd_online__card__wrapper")}>
            {classCard.map((classCard, idx) => {
              return (
                <RcmdClassCard
                  classCard={classCard}
                  isShared={false}
                  userName={user.name}
                  uid={user._id}
                  coursesId={course._id}
                  key={classCard.course_id}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={cx("course_rcmd_online_coupon__wrapper")}>
        <div className={cx("course_rcmd_online_coupon__header")}>
          <p>완주 축하 혜택!</p>
          <h1>시크릿 3만원 할인쿠폰</h1>
        </div>
        <img
          src="assets/images/finish/course_rcomd_online_coupon.png"
          alt="coupon"
        />

        <p className={cx("course_rcmd__notice")}>
          * 발급 시점으로부터 3일간 유효한 쿠폰입니다.
        </p>
        <button onClick={goCatalog}>쿠폰 받고 수업 둘러보기</button>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const connectedCourseRcmdB = connect(mapStateToProps, {})(CourseRcmdOnline);
export { connectedCourseRcmdB as CourseRcmdOnline };
