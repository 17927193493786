import axios from "axios";
import { config } from "../../../config";
import { useMutation, useQuery } from "react-query";

const getB2bAdminNpsQuestions = async (roundId) => {
  const response = await axios.get(
    `${config.enterpriseApiUrl}/nps/questions?roundId=${roundId}`
  );

  return response.data?.data;
};

export const useGetB2bAdminNpsQuestions = (roundId) => {
  return useQuery(
    ["B2bAdminNpsQuestions", roundId],
    () => {
      if (roundId) {
        return getB2bAdminNpsQuestions(roundId);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

const createB2bAdminNpsResults = async (data) => {
  const response = await axios.post(
    `${config.enterpriseApiUrl}/nps/results/many`,
    data
  );

  return response.data?.data;
};

export const useCreateB2bAdminNpsResults = (data) => {
  return useMutation(
    ["createB2bAdminNpsResults"],
    () => {
      if (data) {
        return createB2bAdminNpsResults(data);
      }
    },
    {
      onSuccess: (data) => {},
      onError: (error) => {
        console.debug("👎 [createB2bAdminNpsResults] ", error);
      },
    }
  );
};
