import { enrolledStatusConstants, pointConstants } from "../_constants";
import { enrolledService, pointService } from "../_services";
import {
  enrolledDoneConstants,
  enrolledVoucher,
  enrolledOngoingConstants,
} from "../_constants/enrolleds.constants";

function getEnrolledDone() {
  function request() {
    return { type: enrolledDoneConstants.ENROLLED_DONE_REQUEST };
  }

  function success(response) {
    return { type: enrolledDoneConstants.ENROLLED_DONE_SUCCESS, response };
  }

  function failure(error) {
    return { type: enrolledDoneConstants.ENROLLED_DONE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledDone().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledOngoing() {
  function request() {
    return { type: enrolledOngoingConstants.ENROLLED_ONGOING_REQUEST };
  }

  function success(response) {
    return {
      type: enrolledOngoingConstants.ENROLLED_ONGOING_SUCCESS,
      response,
    };
  }
  function failure(error) {
    return {
      type: enrolledOngoingConstants.ENROLLED_ONGOING_FAILURE,
      error,
    };
  }
  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledOngoing().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getEnrolledStatus(enrolled_id) {
  function request() {
    return { type: enrolledStatusConstants.ENROLLED_STATUS_REQUEST };
  }

  function success(response) {
    return {
      type: enrolledStatusConstants.ENROLLED_STATUS_SUCCESS,
      response,
    };
  }

  function failure(error) {
    return { type: enrolledStatusConstants.ENROLLED_STATUS_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    enrolledService.getEnrolledStatus(enrolled_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function getVouchers() {
  function request() {
    return { type: enrolledVoucher.ENROLLED_VOUCHER_REQUEST };
  }

  function success(response) {
    return { type: enrolledVoucher.ENROLLED_VOUCHER_SUCCESS, response };
  }

  function failure(error) {
    return { type: enrolledVoucher.ENROLLED_VOUCHER_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    enrolledService.getVouchers().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const enrolledActions = {
  getEnrolledDone,
  getEnrolledStatus,
  getVouchers,
  getEnrolledOngoing,
};
