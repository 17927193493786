import styled from "styled-components";
import { Device, DisplayResolution, device } from "../../../../_styles/device";
import { PIRATES } from "../../../../_consts";

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#141617";
      default:
        return "#eef3f6";
    }
  }};
  @media ${device.desktop} {
    overflow-y: scroll;
    height: 100vh;
    border-left: 1px solid
      ${({ theme }) => {
        switch (theme) {
          case PIRATES:
            return "#2B2D2E";
          default:
            return "#c7d2d8";
        }
      }};
  }
`;

export const ListWithoutGnbWrapper = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;

export const CSRCampaignBannerImage = styled.img`
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  padding: 16px;
  display: none;
  ${DisplayResolution.MobileAndMiniTablet} {
    display: block;
  }
`;
