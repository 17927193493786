import { useGetHomeworksDetails } from "../../queries/homeworkV3";
import { useHomeworkAnswer } from "../../businesslogics/OrderedEnrollmentContents/repository/useMyHomeworks";
import { useAtom } from "jotai";
import { enrolledHomeworkDetailsAtom } from "../../stores/enrolledHomeworkDetails";
import { fileInfoAtom } from "../../stores/homeworkPageV3Atom";
import { useEffect } from "react";
import { StatusEnum } from "../../_state/constants";

export const useHomeworkData = (enrolled_homework_id) => {
  const { data: homeworkData, isLoading: homeworkDataLoading } =
    useGetHomeworksDetails(enrolled_homework_id);
  const { data: homeworkAnswerData, isLoading: homeworkAnswerLoading } =
    useHomeworkAnswer(enrolled_homework_id);

  if (homeworkDataLoading || homeworkAnswerLoading) {
    return <div>Loading...</div>;
  }

  const {
    context: {
      homework = {},
      enrolleds_homework = {},
      enrolleds_homework_details: enrolledsHomeworkDetails = {},
      homework_details: homeworkDetails = {},
    },
  } = homeworkData;

  const {
    title: homeworkTitle,
    week,
    course_id: courseId,
    _id: homeworkId,
  } = homework;
  const { enrolled_id: enrolledId, feedback_required: isFeedbackRequired } = enrolleds_homework;
  const { feedback_status: status } = enrolledsHomeworkDetails[0];
  const type = homeworkDetails.map((item) => item.language);
  const submittedFileName = enrolledsHomeworkDetails.map(
    (item) => item.file_name
  );
  const submittedAnswers = enrolledsHomeworkDetails.map(
    (item) => item.submitted_answer
  );
  //격파르타 등 최신 강의는 데이터가 전혀없어서 homeworkAnswerData.ok 가 false임
  let isHaveVideoLink = false;
  if (homeworkAnswerData && homeworkAnswerData.ok) {
    const {
      context: { homework_answer: homeworkAnswer = {} },
    } = homeworkAnswerData;

    isHaveVideoLink = !!homeworkAnswer.video_link;
  }
  return {
    homeworkDetails,
    homeworkTitle,
    enrolledId,
    homeworkId,
    type,
    week,
    status,
    courseId,
    submittedAnswers,
    isHaveVideoLink,
    submittedFileName,
    isFeedbackRequired,
    enrolledsHomeworkDetails,
  };
};

export const useInitializedFileInfo = () => {
  const [enrolledHomeworkDetails] = useAtom(enrolledHomeworkDetailsAtom);
  const [fileInfo, setFileInfo] = useAtom(fileInfoAtom);

  useEffect(() => {
    const newFileInfo = enrolledHomeworkDetails.map((detail) => ({
      name: detail.file_name,
      type: "",
    }));
    if (!Array.isArray(newFileInfo)) {
      console.error("fileInfo is not an array");
      return;
    }
    setFileInfo(newFileInfo);
  }, []);
  return [fileInfo, setFileInfo];
};

export const useIsHomeworkValid = (homeworkIndex) => {
  const [enrolledHomeworkDetails] = useAtom(enrolledHomeworkDetailsAtom);
  const enrolledHomeworkDetail = enrolledHomeworkDetails[homeworkIndex];
  const { feedback_status } = enrolledHomeworkDetail || {};
  return (
    feedback_status === StatusEnum.ACCEPTED ||
    feedback_status === StatusEnum.SUBMITTED ||
    feedback_status === StatusEnum.RESOLVED
  );
};

export const useCurrentHomeworkDetail = (homeworkIndex) => {
  const [enrolledHomeworkDetails] = useAtom(enrolledHomeworkDetailsAtom);
  const enrolledHomeworkDetail = enrolledHomeworkDetails[homeworkIndex];
  const isAnswered = !!enrolledHomeworkDetail?.submitted_answer;
  const { file_name: fileName, submitted_answer: submittedAnswer } =
    enrolledHomeworkDetail || {};
  return { enrolledHomeworkDetail, isAnswered, fileName, submittedAnswer };
};

export const useHandleEnrolledHomeworkDetails = () => {
  const [enrolledHomeworkDetails, setEnrolledHomeworkDetails] = useAtom(
    enrolledHomeworkDetailsAtom
  );
  const deleteEnrolledHomeworkDetail = (index) => {
    const newEnrolledHomeworkDetails = enrolledHomeworkDetails.filter(
      (_, i) => i !== index
    );
    setEnrolledHomeworkDetails(newEnrolledHomeworkDetails);
  };
  const updateEnrolledHomeworkDetail = (index, newEnrolledHomeworkDetail) => {
    setEnrolledHomeworkDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails[index] = newEnrolledHomeworkDetail;
      return newDetails;
    });
  };
  const resetEnrolledHomeworkDetail = (index) => {
    const targetEnrolledHomeworkDetail = enrolledHomeworkDetails[index];
    const newEnrolledHomeworkDetail = {
      ...targetEnrolledHomeworkDetail,
      file_name: "",
      submitted_answer: "",
    };
    updateEnrolledHomeworkDetail(index, newEnrolledHomeworkDetail);
  };
  const addEnrolledHomeworkDetail = (newEnrolledHomeworkDetail) => {
    setEnrolledHomeworkDetails([
      ...enrolledHomeworkDetails,
      newEnrolledHomeworkDetail,
    ]);
  };
  return {
    deleteEnrolledHomeworkDetail,
    updateEnrolledHomeworkDetail,
    addEnrolledHomeworkDetail,
    resetEnrolledHomeworkDetail,
  };
};
