import * as S from "./Certificates.style";
import { DownloadIcon, Receipt, ShareBlueSvg } from "../Icons/Icons";
import { getReceipt, go_linkedIn, parsePeriod } from "./Certificates";
import { getConfirmAndCerti } from "../../../../../hooks/useDownloadCerti";
import { useState } from "react";
import { VoucherSvg } from "./Certificates.svg";
import { addDays, toIsoString } from "../../../../_helper";

export const CertificateCardMobile = ({ enrolled }) => {
  const [isConfirmWait, setIsConfirmWait] = useState(false);
  const [isCertWait, setIsCertWait] = useState(false);
  return (
    <S.CertifiCard>
      {enrolled.done ? (
        <S.CourseCompleted>수료</S.CourseCompleted>
      ) : enrolled.is_in_period ? (
        <S.CourseEnrolled>수강중</S.CourseEnrolled>
      ) : (
        <S.CourseEnded>미수료</S.CourseEnded>
      )}
      <S.CertifiCardTitleContainer>
        <S.CertifiCardTitle>
          {`${enrolled.course_title} ${enrolled.round_title}`}
        </S.CertifiCardTitle>
        <S.CertifiCardSubTitle>
          {parsePeriod(
            enrolled.start_date,
            toIsoString(addDays(enrolled.end_day, -1))
          )}
        </S.CertifiCardSubTitle>
        {enrolled.voucher_title && (
          <S.Voucher>
            <VoucherSvg />
            {enrolled.course_title.includes(
              enrolled.voucher_title.split(" ")[0]
            ) || enrolled.voucher_title.includes("뽀개기")
              ? "수강권"
              : enrolled.voucher_title}{" "}
            수강 과목
          </S.Voucher>
        )}
        <S.CertifiCardReceipt
          onClick={() => getReceipt(enrolled)}
          isActive={!!enrolled.tid?.length || !!enrolled.receipt_url?.length}
        >
          <S.IconWrapper>
            <Receipt />
          </S.IconWrapper>
          <S.CertifiCardReceiptTitle>영수증 발급</S.CertifiCardReceiptTitle>
        </S.CertifiCardReceipt>
        <S.CertifiCardDownloadContainer>
          <S.CertifiCardDownload
            isActive={true}
            onClick={() =>
              getConfirmAndCerti(
                enrolled.is_gov ? "nb_confirm" : "confirm",
                isConfirmWait,
                setIsConfirmWait,
                enrolled
              )
            }
          >
            {isConfirmWait ? (
              <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
            ) : (
              <S.IconWrapper>
                <DownloadIcon />
              </S.IconWrapper>
            )}
            <S.CertifiCardDownloadTitle>
              {isConfirmWait ? "수강증 발급 중..." : "수강증"}
            </S.CertifiCardDownloadTitle>
          </S.CertifiCardDownload>
          <S.CertifiCardDownload
            isActive={enrolled.done}
            onClick={() =>
              getConfirmAndCerti(
                enrolled.is_gov ? "nb_cert" : "new_cert",
                isCertWait,
                setIsCertWait,
                enrolled
              )
            }
          >
            {isCertWait ? (
              <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
            ) : (
              <S.IconWrapper>
                <DownloadIcon />
              </S.IconWrapper>
            )}
            <S.CertifiCardDownloadTitle>
              {isCertWait ? "수료증 발급 중..." : "수료증"}
            </S.CertifiCardDownloadTitle>
          </S.CertifiCardDownload>
        </S.CertifiCardDownloadContainer>
        <S.CertifiCardLinkedinContainer
          onClick={() => {
            go_linkedIn(enrolled);
          }}
          isActive={enrolled.done}
        >
          <S.IconWrapper>
            <ShareBlueSvg />
          </S.IconWrapper>
          <S.CertifiCardLinkedinTitle>
            수료증 링크드인 공유하기
          </S.CertifiCardLinkedinTitle>
        </S.CertifiCardLinkedinContainer>
      </S.CertifiCardTitleContainer>
    </S.CertifiCard>
  );
};
