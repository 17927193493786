import styled from "styled-components";
import { Device } from "../../../../../_styles/device";

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 16px;
  gap: 31px;

  width: 100vw;
  height: auto;

  background: #f6f9fa;

  ${Device.Desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    gap: 31px;

    width: 744px;
    height: auto;
    border-radius: 12px;
  }
`;

export const GuidanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 14px 16px 16px;
  gap: 10px;
  margin-top: -20px;
  width: 328px;
  height: 74px;

  background: #fff1f4;
  border-radius: 8px;

  word-break: keep-all;

  ${Device.Desktop} {
    gap: 10px;
    width: 437px;
    padding: 16px;
    margin-top: -20px;
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const GuidanceText = styled.div`
  /* Web/Body4_400 */
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #e8344e;

  ${Device.Desktop} {
    font-size: 14px;
  }
`;

export const Title = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;

  color: #141617;
`;

export const Divide = styled.div`
  width: 100%;
  height: 1px;

  background: #d7e0e6;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;

  ${Device.Desktop} {
    gap: 50px;
  }
`;

export const ClassCardImage = styled.img`
  width: 328px;
  margin-top: 25px;
  content: url(${({ index }) =>
    `/v2/assets/images/rcmd/guide_${index}_mobile.png`});

  ${({ onClick }) => (onClick ? `&:hover { cursor: pointer; }` : "")};

  ${Device.Desktop} {
    width: 437px;
    content: url(${({ index }) =>
      `/v2/assets/images/rcmd/guide_${index}_web.png`});
  }
`;

export const NoticeWrapper = styled.div`
  display: flex;
  padding: 16px;
  gap: 10px;
  width: 100%;
  background: #e4ebf0;
  border-radius: 8px;
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #5f666b;

  ${Device.Desktop} {
    font-size: 14px;
    color: #5f666b;
  }
`;

export const StempImage = styled.img`
  width: 328px;
  margin-top: 25px;

  ${Device.Desktop} {
    width: 437px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 328px;

  ${Device.Desktop} {
    width: 437px;
  }
`;

export const ContentText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #3a3e41;

  ${Device.Desktop} {
    font-size: 18px;
  }
`;

export const BrDesktop = styled.br`
  ${Device.LessThanDesktop} {
    display: none;
  }
`;

export const BrMobile = styled.br`
  ${Device.Desktop} {
    display: none;
  }
`;

export const ContentTextAlignStart = styled(ContentText)`
  align-items: flex-start;
`;

export const Index = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 24px;
  height: 24px;

  background: #e4ebf0;
  border-radius: 8px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #81898f;

  ${Device.Desktop} {
    font-weight: 700;
    font-size: 16px;

    width: 28px;
    height: 28px;
  }
`;
