import { classroomConstants } from "../_constants";
const initState = {
  loaded: false,
};
export function classroom(state = initState, action) {
  switch (action.type) {
    case classroomConstants.CLASSROOM_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case classroomConstants.CLASSROOM_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case classroomConstants.CLASSROOM_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
