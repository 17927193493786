import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetEnrolledDetails } from "../../_state/actions";

/**TODO: Kill Me....2023.12 에도 살아있다면 박현우에게 말씀해주세요 */
function getPreviousEnrolledDetail(data, targetEnrolledDetailId) {
  // 결과를 저장할 변수 초기화
  let previousEnrolledDetail = null;

  // data.weeks 배열을 순회
  for (const week of data.weeks) {
    for (const enrolledDetail of week.enrolled_details) {
      if (enrolledDetail.enrolled_detail_id === targetEnrolledDetailId) {
        // 현재 enrolledDetail의 id가 targetEnrolledDetailId와 일치하면
        // 이전에 저장된 enrolledDetail을 반환
        return previousEnrolledDetail;
      }
      // 현재 enrolledDetail을 previousEnrolledDetail로 저장
      previousEnrolledDetail = enrolledDetail;
    }
  }

  // 일치하는 enrolled_detail_id가 없거나 첫 번째 enrolledDetail이 target이면 null 반환
  return null;
}

export const useFindPreviousDetail = (enrolledId) => {
  const enrolledDetails = useSelector((state) => state.enrolledDetails);
  const getEnrolledDetails = useGetEnrolledDetails(enrolledId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!enrolledDetails.loaded && enrolledId) {
      getEnrolledDetails(dispatch);
    }
  }, [enrolledDetails.loaded, enrolledId]);
  return (currentDetailId) => {
    if (enrolledDetails.loaded) {
      return getPreviousEnrolledDetail(enrolledDetails.data, currentDetailId);
    }
    return null;
  };
};
