import styled from "@emotion/styled";
import {
  mBody1,
  neutralDark,
  neutralDay,
  wBody1,
} from "@teamsparta/design-system";
import { DisplayResolution } from "../../../../../_styles/device";

export const AnswerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 0 0 12px 12px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDark.gray100};
  ${DisplayResolution.MobileAndMiniTablet} {
    padding: 20px 16px;
  }
`;

export const AnswerTag = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${neutralDay.white};
  ${wBody1};
  color: ${neutralDay.gray80};
  ${DisplayResolution.MobileAndMiniTablet} {
    ${mBody1};
    color: ${neutralDay.gray80};
  }
`;
