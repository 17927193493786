import React, { Component } from "react";
import classNames from "classnames/bind";
import {
  Dashboard,
  RoadmapLecture,
  RoadmapHomework,
} from "../../_components/Roadmaps";
import { NavContainer } from "../../_containers";
import styles from "./RoadmapPage.module.scss";
import { connect } from "react-redux";
import { Button, Skeleton } from "antd";
import { dashboardActions, userActions } from "../../_actions";
import { CustomModal } from "../../_components/CustomModal";
import { CustomModal_LectureExpire } from "../../_components/CustomModal_LectureExpire";
import { handleResponse, history, privateFetch } from "../../_helpers";
import { getApiAccount, getApiStudyplan } from "../../_urls/accounts.api";
import { TreasureBoxModal } from "../../_components/TreasureBoxModal";
import { getPostAuthOptions } from "../../_urls";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);
const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

class RoadmapPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round_id: this.props.match.params.round_id,
      weeks: [],
      modalVisible: false,
      isSending: false,
      phone1: "",
      phone2: "",
      phone3: "",
      name1: "",
      name2: "",
      name3: "",
      edit_plan: false,
      visible_studyplan: true,
      studyplan_done: false,
      studyplan: {
        0: {
          time: null,
          checked: false,
        },
        1: {
          time: null,
          checked: false,
        },
        2: {
          time: null,
          checked: false,
        },
        3: {
          time: null,
          checked: false,
        },
        4: {
          time: null,
          checked: false,
        },
        5: {
          time: null,
          checked: false,
        },
        6: {
          time: null,
          checked: false,
        },
        cnt: 0,
      },
      expDetailShow: false,
      treasureModalVisible: false,
      name_confirmed: false,
      lEModalVisible: true,
      subsetVisible: true,
    };
  }

  componentDidMount() {
    const round_id = this.props.match.params.round_id;
    this.props.getDashboard(round_id);
    this.props.refreshInfo();

    const { checkPlatform } = window;
    const isMobile = checkPlatform() === "mobile";
    this.setState({
      isMobile,
      forever_height: isMobile ? 363 : 384,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.authentication.isFetching &&
      !this.props.authentication.isFetching &&
      this.props.authentication.loggedIn
    ) {
      this.setState({
        name_confirmed: this.props.authentication.user.name_confirmed,
        new_name: this.props.authentication.user.name,
      });
    }

    if (prevProps.dashboard.loaded === false && this.props.dashboard.loaded) {
      const { studyplans, studyplan_prize_done } = this.props.dashboard.data;
      if (!studyplans) {
        return;
      }

      // 기존에 설정된 나와의 약속이 있으면 state를 업데이트한다.
      let { studyplan } = this.state;
      studyplan.cnt = 0;
      for (const plan of studyplans) {
        studyplan[plan.day].checked = true;
        studyplan.cnt += 1;
        if (document.getElementById(days[plan.day])) {
          document.getElementById(days[plan.day]).checked = true;
        }
      }

      this.setState({
        studyplan,
      });

      if (studyplan_prize_done) {
        return;
      }

      // 당일이면 보물상자가 뜬다.
      const today = new Date().getDay();
      for (const plan of studyplans) {
        if (plan.day === today) {
          this.setState({
            treasureModalVisible: true,
          });
        }
      }
    }
  }

  showModal = () => {
    let { modalVisible } = this.state;
    this.setState({ modalVisible: !modalVisible });
  };

  handleModalRatingVisible = (isVisible) => {
    this.setState({
      modalVisible: isVisible,
    });
  };

  handleLEModalVisible = (isVisible) => {
    this.setState({
      lEModalVisible: isVisible,
    });
  };

  handlePhoneChange = (e) => {
    this.fillHyphen(e.target.value, e.target.id);
  };

  handleNameChange = (e) => {
    let id = e.target.id;
    let name = e.target.value.trim();
    if (id === "name1") {
      this.setState({
        name1: name,
      });
    } else if (id === "name2") {
      this.setState({
        name2: name,
      });
    } else if (id === "name3") {
      this.setState({
        name3: name,
      });
    }
  };

  _numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  fillHyphen = (phone, id) => {
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }
    if (id === "phone1") {
      this.setState({
        phone1: phone,
      });
    } else if (id === "phone2") {
      this.setState({
        phone2: phone,
      });
    } else if (id === "phone3") {
      this.setState({
        phone3: phone,
      });
    }
  };

  validatePhone = (phone) => {
    const re = /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/;
    return re.test(phone);
  };

  comfirmName = () => {
    const { name } = this.props.user;
    const { new_name } = this.state;
    if (
      (!new_name || new_name === "") &&
      document.getElementById("input-confirm").value !== name
    ) {
      alert("실명을 입력해주세요.");
      return;
    }

    this.setState({
      isSending: true,
    });
    const endpoint = getApiAccount();
    const options = getPostAuthOptions({ new_name });
    privateFetch(endpoint, options)
      .then(handleResponse)
      .then((resp) => {
        if (resp.ok) {
          this.props.refreshInfo();
          this.setState({
            isSending: false,
          });
        }
      });
  };

  createStudyplan = () => {
    let { studyplan, studyplan_done, round_id } = this.state;
    if (studyplan_done === false) {
      alert("공부할 날짜 2일을 선택해주세요!");
      return;
    }
    let studyplans = [];
    for (const [k, v] of Object.entries(studyplan)) {
      if (v.checked) {
        studyplans.push({
          day: parseInt(k),
        });
      }
    }

    this.setState({
      isSending: true,
    });

    const data = { studyplans };
    const endpoint = getApiStudyplan(round_id);
    const options = getPostAuthOptions(data);
    fetch(endpoint, options)
      .then(handleResponse)
      .then(
        (resp) => {
          this.setState({
            isSending: false,
            visible_studyplan: false,
            edit_plan: false,
          });
          if (resp.ok) {
            alert("'나와의 약속' 설정이 완료되었습니다!");
            this.props.getDashboard(this.state.round_id);
          }
        },
        (error) => {
          alert(error);
          this.setState({
            isSending: false,
            visible_studyplan: false,
          });
          return { ok: false };
        }
      );
  };

  checkStudyDay = (e) => {
    let day = e.target.value;
    let checked = e.target.checked;
    let { studyplan } = this.state;
    let { cnt } = studyplan;
    let studyplan_done = false;
    studyplan[day].checked = checked;
    studyplan.cnt = checked ? cnt + 1 : cnt - 1;

    if (studyplan.cnt > 2) {
      studyplan.cnt = 2;
      studyplan[day].checked = false;
      e.target.checked = false;
      alert("2일까지만 선택할 수 있어요!");
      return;
    } else if (studyplan.cnt == 2) {
      studyplan_done = true;
    }

    this.setState({
      studyplan,
      studyplan_done,
    });
  };

  _setName = (e) => {
    this.setState({
      new_name: e.target.value,
    });
  };

  closeTreasureBox = () => {
    this.setState({
      treasureModalVisible: false,
    });
  };

  showPlan = () => {
    this.setState({
      edit_plan: true,
    });
  };

  handleSubsetVisible = () => {
    this.setState((prevState) => {
      return {
        subsetVisible: !prevState.subsetVisible,
      };
    });
  };

  render() {
    const { dashboard } = this.props;

    const {
      modalVisible,
      edit_plan,
      studyplan_done,
      visible_studyplan,
      expDetailShow,
      treasureModalVisible,
      name_confirmed,
      subsetVisible,
      isMobile,
      forever_height,
    } = this.state;
    if (!dashboard.loaded || dashboard.data === undefined) {
      return <Skeleton />;
    }
    if (!this.props.user) {
      return <Skeleton />;
    }
    const {
      studyplans,
      lecture,
      round_id,
      is_free,
      is_seasonal,
      is_visible_seasonal_fast_finish_banner,
      is_forever,
      subset,
    } = dashboard.data;
    const weeks = dashboard.data["lecture_details"];
    const course_id = lecture.course_id;
    let data = { course_id, studyplans };

    return (
      <div className={cx("wrapper")}>
        <NavContainer />
        <div className={cx("roadmapPageContainer")}>
          <Dashboard data={data} showPlan={this.showPlan} />
          {weeks &&
            weeks.map((week, idx) => {
              // 이전 주차 수업 수강 모두 완료 && 이전 주차 숙제 완료
              const isWeekAllowed =
                weeks.slice(0, idx).reduce((acc, cur, i) => {
                  return acc && cur.lecture.info.is_done;
                }, true) &&
                (idx === 0 ||
                  weeks[idx - 1].homeworks.reduce((acc, cur, i) => {
                    return acc & cur.done;
                  }, true)) &&
                week.lecture.info.is_allowed;
              return (
                <div key={idx} className={cx("weekContentWrapper")}>
                  <RoadmapLecture
                    data={week.lecture}
                    course_id={course_id}
                    is_free={is_free}
                    is_forever={is_forever}
                    round_id={round_id}
                    is_seasonal={is_seasonal}
                    is_week_allowed={isWeekAllowed}
                  />
                  {week.homeworks.map((homework, homework_idx) => {
                    const isHomeworkAllowed =
                      week.lecture.info.is_done &&
                      week.lecture.info.is_allowed &&
                      week.homeworks
                        .slice(0, homework_idx)
                        .reduce((acc, cur, i) => {
                          return acc & cur.done;
                        }, true);
                    return (
                      <RoadmapHomework
                        week={idx + 1}
                        key={homework_idx}
                        data={homework}
                        course_id={course_id}
                        is_free={is_free}
                        round_id={round_id}
                        is_seasonal={is_seasonal}
                        is_homework_allowed={isHomeworkAllowed}
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
        <FooterV2 />
        {dashboard.data.lecture && dashboard.data.lecture.is_expired && (
          <CustomModal_LectureExpire
            visible={this.state.lEModalVisible}
            close={false}
            course_id={course_id}
            round_id={round_id}
            user_id={this.props.user._id}
            handleModalVisible={this.handleLEModalVisible}
            name={this.props.user.name}
          />
        )}
        {/*실명 설정하기*/}
        <CustomModal
          visible={!name_confirmed && !is_forever}
          close={false}
          handleModalVisible={this.handleModalRatingVisible}
          style={{
            height: 484,
            padding: 0,
            border: "none",
            top: "calc(50% - 242px)",
          }}
          body={
            <div className={cx("name-modal-body")}>
              {/*헤더*/}
              <div className={cx("header")}>
                <h2>반가워요 {this.props.user.name}님✋</h2>
                <h4>
                  시작에 앞서, <br />
                  현재 이름을 확인해주세요.
                </h4>
              </div>
              {/*본문*/}
              <div className={cx("desc")}>
                <p>
                  {this.props.user.name}님의 이름은 반드시 <b>실명</b>이어야
                  해요.
                  <br />
                  만약 실명이 아닐 경우, 꼭 변경해주세요.
                </p>
              </div>
              {/*요일선택*/}
              <div className={cx("body")}>
                <div className={cx("inputContainer")}>
                  <input
                    id="input-confirm"
                    type="text"
                    defaultValue={this.props.user.name}
                    onChange={this._setName}
                  />
                </div>
                <div className={cx("descContainer")}>
                  <span>
                    이미 실명으로 되어 있다면, 시작하기를 눌러 주세요!
                  </span>
                </div>
              </div>
              {/*버튼*/}
              <div className={cx("buttonContainer")}>
                <span
                  id="agree"
                  onClick={() => {
                    window.open(
                      "https://www.notion.so/teamsparta/cb0c63c1145f4b60aacf0692a888e6fd"
                    );
                  }}
                >
                  개인정보수집에 동의하고,
                </span>
                {/*제출 중*/}
                {this.state.isSending && (
                  <button className={cx("inactive")}>보내는 중</button>
                )}
                {/*입력 완료 || 선택 중*/}
                {!this.state.isSending && (
                  <button className={cx("active")} onClick={this.comfirmName}>
                    시작하기
                  </button>
                )}
              </div>
              {/*지금 꼭 확정해야 하나요?*/}
              <div className={cx("explanation")}>
                <div className={cx("exptitleWrapper")}>
                  <span
                    className={cx("underline", "exptitle")}
                    onClick={() => {
                      let { expDetailShow } = this.state;
                      this.setState({
                        expDetailShow: !expDetailShow,
                      });
                    }}
                  >
                    반드시 실명이어야 하나요?
                  </span>
                </div>
                {expDetailShow && (
                  <div className={cx("expDetailWrapper")}>
                    완주시 5만원 환급은 관련 법령에 의하여
                    <br />
                    주민등록 상 실명으로만 받을 수 있습니다.
                  </div>
                )}
              </div>
            </div>
          }
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => this.setState({ modalVisible: false })}
            >
              확인
            </Button>,
          ]}
        />
        <CustomModal
          visible={
            (false &&
              name_confirmed &&
              visible_studyplan &&
              studyplans === null &&
              !is_forever) ||
            edit_plan
          }
          close={false}
          handleModalVisible={this.handleModalRatingVisible}
          style={{
            height: 657,
            padding: 0,
            border: "none",
            top: "calc(50% - 303px)",
          }}
          body={
            <div className={cx("modal-body")}>
              <div
                className={cx("close")}
                onClick={() => {
                  if (!edit_plan) {
                    return;
                  }
                  this.setState({
                    visible_studyplan: false,
                    edit_plan: false,
                  });
                }}
              >
                X
              </div>
              {/*헤더*/}
              <div className={cx("header")}>
                <h2>반가워요 {this.props.user.name}님✋</h2>
                {!edit_plan && <h4>시작에 앞서,나와의 약속을 해볼까요?</h4>}
                {edit_plan && (
                  <h4>더 좋은 습관을 위해, 약속을 변경해볼까요?</h4>
                )}
              </div>
              {/*본문*/}
              <div className={cx("desc")}>
                <p>
                  일주일 중에 공부할 요일 <b>이틀</b>을 선택하세요.
                </p>
                <p>
                  {this.props.user.name}님이 선택하신 요일에 알람을 제공해
                  <br />
                  공부를 시작하고 지속할 수 있도록 도와드릴게요.
                </p>
              </div>
              {/*요일선택*/}
              <div className={cx("weekdays")}>
                <div className={cx("weekday")}>
                  <label htmlFor="monday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>월요일</span>
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="monday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={1}
                      />
                    </div>
                  </label>
                </div>
                <div className={cx("weekday")}>
                  <label htmlFor="tuesday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>화요일</span>
                      <span className={cx("qnanow")}>즉문즉답</span>
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="tuesday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={2}
                      />
                    </div>
                  </label>
                </div>
                <div className={cx("weekday")}>
                  <label htmlFor="wednesday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>수요일</span>
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="wednesday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={3}
                      />
                    </div>
                  </label>
                </div>
                <div className={cx("weekday")}>
                  <label htmlFor="thursday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>목요일</span>
                      {round_id === "603f2b030774bbfc230c4c17" && (
                        <span className={cx("qnanow")}>즉문즉답</span>
                      )}
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="thursday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={4}
                      />
                    </div>
                  </label>
                </div>
                <div className={cx("weekday")}>
                  <label htmlFor="friday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>금요일</span>
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="friday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={5}
                      />
                    </div>
                  </label>
                </div>
                <div className={cx("weekday")}>
                  <label htmlFor="saturday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>토요일</span>
                      {round_id !== "603f2b030774bbfc230c4c17" && (
                        <span className={cx("qnanow")}>즉문즉답</span>
                      )}
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="saturday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={6}
                      />
                    </div>
                  </label>
                </div>
                <div className={cx("weekday")}>
                  <label htmlFor="sunday">
                    <div className={cx("left")}>
                      <span className={cx("dayname")}>일요일</span>
                    </div>
                    <div className={cx("right")}>
                      <input
                        id="sunday"
                        onClick={this.checkStudyDay}
                        type="checkbox"
                        value={0}
                      />
                    </div>
                  </label>
                </div>
              </div>
              {/*버튼*/}
              <div className={cx("buttonContainer")}>
                {/*제출 중*/}
                {this.state.isSending && (
                  <button className={cx("inactive")}>보내는 중</button>
                )}
                {/*선택 중*/}
                {!this.state.isSending && !studyplan_done && (
                  <button className={cx("inactive")}>나와의 약속 만들기</button>
                )}
                {/*선택 완료 || 선택 중*/}
                {!this.state.isSending && studyplan_done && (
                  <button
                    className={cx("active")}
                    onClick={this.createStudyplan}
                  >
                    나와의 약속 만들기
                  </button>
                )}
              </div>
              {/*지금 꼭 확정해야 하나요?*/}
              <div className={cx("explanation")}>
                <div className={cx("exptitleWrapper")}>
                  <span
                    className={cx("underline", "exptitle")}
                    onClick={() => {
                      let { expDetailShow } = this.state;
                      this.setState({
                        expDetailShow: !expDetailShow,
                      });
                    }}
                  >
                    지금 꼭 확정해야 하나요?
                  </span>
                </div>
                {expDetailShow && (
                  <div className={cx("expDetailWrapper")}>
                    코딩은 습관을 들이는 것이 가장 중요하기 때문에,
                    <br />
                    스스로 공부할 요일을 정하고 시작하는 것이 좋습니다.
                  </div>
                )}
              </div>
            </div>
          }
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => this.setState({ modalVisible: false })}
            >
              확인
            </Button>,
          ]}
        />
        {subset && (
          <CustomModal
            visible={subsetVisible}
            close={true}
            handleModalVisible={this.handleModalRatingVisible}
            style={{
              height: forever_height,
              padding: 0,
              border: "none",
              top: "calc(50% - 303px)",
              backgroundColor: "#2897fb",
            }}
            body={
              <div className={cx("modal-body", "forever")}>
                {/*헤더*/}
                <div className={cx("header")}>
                  <h2>
                    {this.props.user.name}님, {!isMobile && <br />}이 강의는{" "}
                    {isMobile && <br />} 평생 소장할 수 있어요!
                  </h2>
                </div>
                {/*본문*/}
                <div className={cx("desc")}>
                  <p>
                    {subset.subset_title}은 정해진 기간만 들을 수 있으니,
                    <br />
                    먼저 들어보는게 어떨까요?
                  </p>
                  <img
                    className={cx("ic-python-forever")}
                    src={"/assets/images/roadmap/ic_python_forever.svg"}
                    alt="ic_python_forever"
                  />
                </div>
                {/*버튼*/}
                <div className={cx("buttonContainer")}>
                  <button
                    className={cx("active")}
                    onClick={this.handleSubsetVisible}
                  >
                    확인했어요!
                  </button>
                </div>
              </div>
            }
            footer={[
              <Button
                key="ok"
                type="primary"
                onClick={() => this.setState({ modalVisible: false })}
              >
                확인
              </Button>,
            ]}
          />
        )}
        <TreasureBoxModal
          onPlan={treasureModalVisible}
          visible={treasureModalVisible}
          handleClose={this.closeTreasureBox}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, dashboard } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    dashboard,
  };
};

const connectedRoadmapPage = connect(mapStateToProps, {
  getDashboard: dashboardActions.getDashboard,
  refreshInfo: userActions.refreshInfo,
})(RoadmapPage);
export { connectedRoadmapPage as RoadmapPage };
