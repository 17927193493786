import React, { Component } from "react";
import classNames from "classnames/bind";
import styles from "./CustomModal_Reimburse.module.scss";
import { createEventId, history } from "../../_helpers";
import axios from "../../_helpers/axios";
import { connect } from "react-redux";
import { enrolledActions, mypageActions, userActions } from "../../_actions";
import { sendLog } from "../../v2/businesslogics/logging";
import { getCookie, setCookie } from "../../v2/_helper";

const cx = classNames.bind(styles);

class CustomModal_Reimburse extends Component {
  /**
   * 필수 props
   * @param props
   * style
   * handleModalVisible
   * body
   * visible
   */
  state = {
    forCash: false,
    // 무료강의에서 쿠폰 받고나면 true로 변경되고,
    // true인 경우 시그니처 패키지 및 후기쓰러가기 버튼을 보여줌
    showReviewAndNudge: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { enrolled_id } = this.props;
    if (!enrolled_id) {
      return;
    }
    this.props.getEnrolledStatus(enrolled_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.enrolled_id && this.props.enrolled_id) {
      this.props.getEnrolledStatus(this.props.enrolled_id);
    }
  }

  _handleCloseModal = () => {
    this.props.handleModalVisible(false);
  };

  _forCash = async () => {
    const { enrolled_id } = this.props;
    const { forCash, isMypage } = this.state;
    if (forCash) {
      window.open("https://forms.gle/BMKheCUYw1akmm3D7");
      if (!isMypage) {
        history.push(`/retrospectives/${enrolled_id}`);
      }
      const eventID = createEventId();
      this.getPoint(true);
      await this._sendLog("cash_btn", forCash, eventID);
    } else {
      const eventID = createEventId();
      await this._sendLog("cash_btn", forCash, eventID);
      this.setState({
        forCash: !forCash,
      });
    }
  };

  getPoint = (isCash = false) => {
    const { enrolled_id, isMypage } = this.props;
    if (!enrolled_id) {
      alert("유효한 수강 과목이 아닙니다. 관리자에게 문의해주시기 바랍니다.");
      return;
    }

    axios
      .post("/enrolleds/finish", { enrolled_id, isCash })
      .then((resp) => {
        const { data } = resp;
        alert(data.msg);
        if (isMypage) {
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };

  goMypage = async () => {
    const { forCash } = this.state;
    const { isMypage, enrolled_id, enrolledStatus } = this.props;
    const { isEvent } = enrolledStatus;
    const eventID = createEventId();
    this.getPoint(false);
    await this._sendLog("point_btn", forCash, eventID);
    if (!isMypage) {
      const { is_seasonal } = enrolledStatus.data;
      if (is_seasonal) {
        this.setState({
          ...this.state,
          // showReviewAndNudge: true // 무료특강인 경우에만 리뷰 & 시그니처 패키지 넛지 화면 보여줌
        });
        history.push(`/retrospectives/${enrolled_id}`);
      } else if (!isEvent) {
        history.push(`/retrospectives/${enrolled_id}`);
      } else {
        history.push(`/mypage`);
      }
    }
  };

  goReview = () => {
    const { enrolled_id } = this.props;
    sendLog("go_review_nudge", {}, null);
    history.push(`/retrospectives/${enrolled_id}`);
  };

  goSigniturePack = () => {
    sendLog("go_pack_sig_nudge", {}, null);
    window.location.assign("https://spartacodingclub.kr/catalog/pkg/summary");
  };

  _getUniqueId = () => {
    let unique_id = getCookie("unique_id");
    if (unique_id && unique_id !== "") {
      return unique_id;
    }
    unique_id = this._genUniqueId();
    setCookie("unique_id", unique_id, 365);
    return unique_id;
  };

  _genUniqueId = (length = 20) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  _sendLog = async (action, forCash = false, eventID) => {
    let ua = window.navigator.userAgent;
    ua = ua.toLowerCase();
    let matched = {};
    const unique_id = this._getUniqueId();
    const referrer = "";
    const url = document.location.href;
    const { user } = this.props;
    let user_id = user ? user._id : "";
    const platform_match =
      /(ipad)/.exec(ua) ||
      /(ipod)/.exec(ua) ||
      /(windows phone)/.exec(ua) ||
      /(iphone)/.exec(ua) ||
      /(kindle)/.exec(ua) ||
      /(silk)/.exec(ua) ||
      /(android)/.exec(ua) ||
      /(win)/.exec(ua) ||
      /(mac)/.exec(ua) ||
      /(linux)/.exec(ua) ||
      /(cros)/.exec(ua) ||
      /(playbook)/.exec(ua) ||
      /(bb)/.exec(ua) ||
      /(blackberry)/.exec(ua) ||
      [];
    const page = forCash ? 1 : 0;

    matched.platform = platform_match[0] || "";
    const data = {
      unique_id,
      user_id,
      referrer,
      url,
      action,
      page,
      platform: matched.platform,
      event_id: eventID,
    };
    await axios.post("/logs/", data);
  };

  render() {
    let { visible, style, enrolledStatus, user } = this.props;
    const { forCash, showReviewAndNudge } = this.state;
    if (!enrolledStatus.loaded) {
      return <div />;
    }
    // 수료 여부 최종 확인
    const {
      done,
      reimbursable,
      is_seasonal,
      is_fast_finish,
      is_cash_reimbursable,
      is_event,
    } = enrolledStatus.data;
    visible = done ? visible : false;

    // impression
    if (visible && !forCash) {
      const eventID = createEventId();
      this._sendLog("pageview_payback", false, eventID);
    }

    return (
      <div>
        <div
          className={visible ? cx("modal", "active") : cx("modal")}
          onClick={this._handleCloseModal}
        ></div>
        <div
          className={
            visible
              ? is_fast_finish
                ? cx("modal-background", "active", "long")
                : cx("modal-background", "active")
              : cx("modal-background")
          }
          style={{ ...style }}
        >
          {/* 수료 O 완주 O */}
          {(reimbursable || is_event) && (
            <div className={cx("modal-content")}>
              {/*환급신청하기*/}
              <div className={cx("reimburse-main")}>
                <div className={cx("reimburse-body")}>
                  <img
                    className={cx("body-image")}
                    src={`/assets/images/characters/rtan_${
                      is_event ? "rainbow" : "cow"
                    }.png`}
                    alt=""
                  />
                  {!showReviewAndNudge ? (
                    <h2>
                      {is_event
                        ? reimbursable
                          ? `[코딩 미션 성공!]\n코딩으로 더 힙해진 걸 축하해요!`
                          : `[특강 완주 성공!]\n힙-한 코딩 강의를 완주했군요!`
                        : `완주 축하 ${
                            is_seasonal ? "쿠폰 받기" : "환급 신청하기"
                          }`}
                    </h2>
                  ) : (
                    <h2>완주 축하 쿠폰 지급완료</h2>
                  )}
                  <p>
                    {is_event
                      ? reimbursable
                        ? `우와아- 48시간 내에 멋진 작품을 만들어 냈어요!\n\n개발일지에 완성한 작품 이미지를 함께 올리면\n초.특.급 경품 당첨 확률이 높아질거에요 (소근소근)\n\n즐거우셨다면, 좋아하시는 커뮤니티에 맘껏 "퍼가요~" 해주세요! 🥰`
                        : `우와아- 코딩으로 더욱 힙해진 ${user.name}님이 더욱 빛나보여요!\n\n48시간이 지나 미션 이벤트에는 응모하지 못했지만,\n더 힙해진 ${user.name}님을 위해 깜짝 비밀의 상점을 열어드렸어요!\n\n즐거우셨다면, 좋아하시는 커뮤니티에도 경험을 맘껏! 공유해주세요 🥰`
                      : `정말 수고하셨고, 완주를 진심으로 축하드려요!\n\n코딩은 습관이 제일 중요하기 때문에,\n앞으로 스파르타와 함께\n매주 코딩하는 습관 쭉- 이어나가 보아요!`}
                  </p>
                  {is_seasonal && is_fast_finish && !showReviewAndNudge && (
                    <p>
                      🎁 쿠폰 더블 퀘스트 달성 🎁
                      <br />
                      48시간 내 수강완료 퀘스트를 달성하신
                      <br />
                      튜티님을 위해 저희가 준비한 선물이에요.
                      <br />
                      이번 기회 놓치지 말고 바로 두 배 쿠폰 받으러 가볼까요?
                    </p>
                  )}
                  {is_seasonal && showReviewAndNudge && (
                    <p>
                      🎁 시그니처 패키지 이벤트 🎁
                      <br />
                      스파르타 시그니처 패키지 이벤트가 진행중이에요!
                      <br />
                      시그니처 패키지 커리큘럼을 보러 가볼까요?
                    </p>
                  )}
                </div>
              </div>
              {/*버튼*/}
              <div className={cx("reimburse-buttons")}>
                {!is_seasonal && (
                  <button className={cx("cta")} onClick={this.goMypage}>
                    5만 포인트 받기
                  </button>
                )}
                {is_seasonal && !is_fast_finish && !showReviewAndNudge && (
                  <button className={cx("cta")} onClick={this.goMypage}>
                    3만 원 쿠폰 받기
                  </button>
                )}
                {is_seasonal && is_fast_finish && !showReviewAndNudge && (
                  <button className={cx("cta")} onClick={this.goMypage}>
                    4만 원 쿠폰 받기
                  </button>
                )}
                {is_seasonal && showReviewAndNudge && (
                  <div className={cx("review-and-nudge")}>
                    <button
                      className={cx("cta")}
                      onClick={this.goSigniturePack}
                    >
                      시그니처 패키지 보러가기
                    </button>
                    <button className={cx("sub-cta")} onClick={this.goReview}>
                      리뷰 쓰러가기
                    </button>
                  </div>
                )}
              </div>
              {/*유의사항*/}
              <div className={cx("footer")}>
                <div className={cx("footer-body")}>
                  <div>유의사항</div>
                  <ul>
                    <li>포인트는 [ 마이페이지 ]에서 확인할 수 있습니다.</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {/* 수료 O 완주 X */}
          {!reimbursable && !is_event && (
            <div className={cx("modal-content")}>
              {/*환급신청하기*/}
              <div className={cx("reimburse-main")}>
                <div className={cx("reimburse-body")}>
                  <img
                    className={cx("body-image")}
                    src={`/assets/images/characters/rtan_finish.png`}
                    alt=""
                  />
                  <h2>수료증 발급 완료!</h2>
                  <p>
                    정말 수고하셨습니다. 수료를 진심으로 축하드려요!
                    <br />
                    <br />
                    코딩은 습관이 제일 중요하기 때문에,
                    <br />
                    앞으로 스파르타와 함께
                    <br />
                    매주 코딩하는 습관 쭉- 이어나가 보아요!
                  </p>
                </div>
              </div>
              {/*버튼*/}
              <div className={cx("reimburse-buttons")}>
                <button className={cx("cta")} onClick={this.goMypage}>
                  수료증 확인하기
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, enrolledStatus, enrolled } = state;
  const { user } = state.authentication;
  return {
    authentication,
    user,
    enrolledStatus,
    enrolled,
  };
}

const connectedCustomModal_Reimburse = connect(mapStateToProps, {
  refreshInfo: userActions.refreshInfo,
  logout: userActions.logout,
  loginWithToken: userActions.loginWithToken,
  getMypage: mypageActions.getMypage,
  getEnrolledStatus: enrolledActions.getEnrolledStatus,
})(CustomModal_Reimburse);
export { connectedCustomModal_Reimburse as CustomModal_Reimburse };
