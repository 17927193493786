import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./CodingRaceSky.module.scss";
import { connect } from "react-redux";
import { liveUserActions } from "../../../_state/actions";
import CountUp from "react-countup";
import { history } from "../../../../_helpers";
import { sendLog } from "../../../businesslogics/logging";

const cx = classNames.bind(styles);

function CodingRaceSky({
  liveUser,
  getLiveUser,
  user,
  enrolled_id,
  round_id,
  current_hour,
  course_id,
  enrolled,
}) {
  useEffect(() => {
    if (user) {
      getLiveUser();
    }
  }, [user]);

  function go_to_roadmap() {
    sendLog(
      "scc_rankPage_click_button",
      {
        ...enrolled.data,
        button_text: "진도표 입장하기",
        next_page: "roadmapPage",
        details: "",
      },
      ""
    );
    history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
  }

  return (
    <div className={cx("CodingRaceTop")}>
      <div className={cx("CodingRaceSkyContainer")}>
        {current_hour >= 6 && current_hour < 18 ? (
          <img
            className={cx("CodingRaceSky")}
            src={`/assets/images/codingrace/sky.svg`}
            alt="sky"
          />
        ) : (
          <img
            className={cx("CodingRaceSky")}
            src={`/assets/images/codingrace/sky_night_v2@3x-min.png`}
            alt="sky_night"
          />
        )}
        {/*<img className={cx('CodingRaceSky')} src={(`/assets/images/codingrace/sky_night_v2@3x-min.png`)}*/}
        {/*     alt="sky_night"/>*/}
      </div>
      <div className={cx("CodingRaceMessageContainer")}>
        <div className={cx("CodingRaceMessageText")}>
          <p>
            지금{" "}
            {liveUser.loaded ? (
              <span>
                <CountUp
                  start={0}
                  end={liveUser.data.count}
                  separator={","}
                  duration={2.5}
                />
              </span>
            ) : (
              0
            )}
            명의 동료들이 함께 달리고 있어요!
          </p>
        </div>
        <button
          className={cx("CodingRaceMessageCTA")}
          onClick={() => go_to_roadmap()}
        >
          돌아가기
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, liveUser } = state;
  const { user } = authentication;
  return {
    authentication,
    liveUser,
    user,
  };
}

const connectedCodingRaceSky = connect(mapStateToProps, {
  getLiveUser: liveUserActions.getLiveUser,
})(CodingRaceSky);
export { connectedCodingRaceSky as CodingRaceSky };
