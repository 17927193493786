import { prequestionConstants } from "../_constants";
import { prequestionService } from "../_services/prequestion.service";

function isPreqSubmitted(course_id, round_id) {
  function request() {
    return { type: prequestionConstants.PREQUESTION_REQUEST };
  }

  function success(response) {
    return { type: prequestionConstants.PREQUESTION_SUCCESS, response };
  }

  function failure(error) {
    return { type: prequestionConstants.PREQUESTION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    prequestionService.isPreqSubmitted(course_id, round_id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

export const prequestionActions = {
  isPreqSubmitted,
};
