import styled from "@emotion/styled";
import { neutralDay } from "@teamsparta/design-system";
import { device } from "../../../../../_styles/device";

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 24px;
  margin-bottom: 16px;
  padding: 30px 16px 30px 16px;
  width: 360px;
  @media ${device.desktop} {
    width: 100%;
    padding: 16px 42px 16px 42px;
  }
`;

export const DottedHr = styled.div`
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    black 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 10px 2px;
  background-repeat: repeat-x;
  margin: 22px auto 25px;
`;

export const Content = styled.div`
  background-color: ${neutralDay.gray10};
`;
