import React, { useEffect } from "react";
import { connect } from "react-redux";
import { enrolledActions } from "../../_state/actions";
import * as S from "./MobileLecReportPage.style";
import { ReportGNB } from "./ReportGNB/ReportGNB";
import { MobileLecReport } from "./MobileLecReport/MobileLecReport";
import { useOnlineTheme } from "../../queries/pirates";
import { MobileLecReportTab } from "./MobileLecReport/MobileLecReportTab";

const MobileLecReportPage = ({
  authentication,
  enrolledDetail,
  user,
  match,
  getEnrolledDetail,
}) => {
  const { enrolled_id, detail_id } = match.params;
  const theme = useOnlineTheme();
  useEffect(() => {
    if (enrolledDetail.data === undefined) {
      getEnrolledDetail(enrolled_id, detail_id);
    }
  }, [enrolledDetail.loaded]);
  return (
    <>
      <S.MobileReportPage theme={theme.data}>
        <ReportGNB />
        <MobileLecReportTab enrolledDetail={enrolledDetail} user={user} />
      </S.MobileReportPage>
    </>
  );
};

function mapStateToProps(state) {
  const { authentication, enrolledDetail } = state;
  const { user } = authentication;
  return {
    authentication,
    enrolledDetail,
    user,
  };
}

const connectedMobileLecReport = connect(mapStateToProps, {
  getEnrolledDetail: enrolledActions.getEnrolledDetail,
})(MobileLecReportPage);
export { connectedMobileLecReport as MobileLecReportPage };
