import * as S from "./GiftSnackBar.style";

const GiftSnackBar = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Text>무료 강의가 지급되었어요!</S.Text>
        <S.Cta href={"/classroom"}>내 강의실 가기</S.Cta>
      </S.Container>
    </S.Wrapper>
  );
};

export default GiftSnackBar;
