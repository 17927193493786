import {sendCPLog} from "@teamsparta/cross-platform-logger";
import {useSelector} from "react-redux";
import {useMyHomeworks} from "../OrderedEnrollmentContents/repository/useMyHomeworks";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";


export const useSendLogChatbotPost = () => {
    const {enrolled_homework_id}  = useParams();
    const enrolledDetail = useSelector((state) => state.enrolledDetail);
    const enrolled = useSelector((state) => state.enrolled);
    const homeworks = useMyHomeworks(enrolled_homework_id);
    const lecture_v2 = useSelector((state) => state.lecture_v2);
    const [homeworkId, setHomeworkId] = useState(null);
    useEffect(() => {
        if (homeworks.isSuccess) {
            const homework = homeworks.data.context?.homework;
            if (homework) {
                setHomeworkId(homework._id);
            }
        }
    }, [homeworks.isSuccess]);

    return (chat_type) => {
        const logData = {
            enrolled_id: enrolledDetail?.data?.enrolled_id,
            course_id: enrolledDetail?.data?.course_id,
            course_title: enrolled?.data?.courses?.title,
            round: enrolled?.data?.rounds?.round,
            round_id: enrolled?.data?.round_id,
            week: enrolledDetail?.data?.week,
            week_order: enrolledDetail?.data?.week_order,
            chat_type: chat_type,
        };
        if (homeworkId) {
            logData.homework_id = homeworkId;
        } else if (lecture_v2?.data?.lecture_id) {
            logData.lecture_id = lecture_v2?.data?.lecture_id;
        } else {
            logData.lecture_id = null;
        }
        sendCPLog("scc_aiChatbot_post", logData);
    }
}

export const useSendLogChatbotClickButton = (chat_type) => {
    const {enrolled_homework_id} = useParams();
    const enrolledDetail = useSelector((state) => state.enrolledDetail);
    const enrolled = useSelector((state) => state.enrolled);
    const homeworks = useMyHomeworks(enrolled_homework_id);
    const lecture_v2 = useSelector((state) => state.lecture_v2);
    const [homeworkId, setHomeworkId] = useState(null);
    useEffect(() => {
        if (homeworks.isSuccess) {
            const homework = homeworks.data.context?.homework;
            if (homework) {
                setHomeworkId(homework._id);
            }
        }
    }, [homeworks.isSuccess]);

    return (chat_type, button_name) => {
        const logData = {
            enrolled_id: enrolledDetail?.data?.enrolled_id,
            course_id: enrolledDetail?.data?.course_id,
            course_title: enrolled?.data?.courses?.title,
            round: enrolled?.data?.rounds?.round,
            round_id: enrolled?.data?.round_id,
            week: enrolledDetail?.data?.week,
            week_order: enrolledDetail?.data?.week_order,
            chat_type: chat_type,
            button_name: button_name,
        };
        if (homeworkId) {
            logData.homework_id = homeworkId;
        } else if (lecture_v2?.data?.lecture_id) {
            logData.lecture_id = lecture_v2?.data?.lecture_id;
        } else {
            logData.lecture_id = null;
        }
        sendCPLog("scc_aiChatbot_click_button", logData);
    }
}