import React, { Component } from "react";
import { NavContainer } from "../../_containers";
import { createEventId, history, params } from "../../_helpers";
import { config } from "../../config";
import { Skeleton } from "antd";
import classNames from "classnames/bind";
import styles from "./PaymenDonePage.module.scss";
import { connect } from "react-redux";
import { getGETAuthOptions, getGETOptions } from "../../_urls";
import { courseActions, userActions } from "../../_actions";
import "react-toastify/dist/ReactToastify.css";
import { getDeviceId, sendLog } from "../../v2/businesslogics/logging";
import { getCookie, setCookie } from "../../v2/_helper";
import SparthonPaymentDone from "../../v2/components/organisms/SparthonPaymentDone/SparthonPaymentDone";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);

class PaymentDonePage extends Component {
  state = {
    course_id: this.props.match.params.course_id,
  };

  constructor(props) {
    super(props);
    this.clockTime = React.createRef();
    this.clockTimeCta = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const course_id = this.props.match.params.course_id;
    this.props.refreshInfo();
    this.props.getCourseByUserId(course_id);

    // 사전질문 페이지 진입했을 때 이미 보내서 일단 주석
    // this._getStartMaterial(course_id);

    // 친구 추천 링크를 타고 들어왔다면 쿠키 만료 처리
    const f_uid = getCookie("f_uid");
    if (f_uid) {
      setCookie("f_uid", "", 0);
      setCookie("f_name", "", 0);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.courseByUserId.loaded && this.props.courseByUserId.loaded) {
      this.setState({
        counter: setInterval(this.getTimer, 1000),
      });
      const eventID = createEventId();
      sendLog("pageview_payment_done_seasonal", {}, eventID);
    }
  }

  getTimer = () => {
    const {
      course: { is_free, is_seasonal, is_trial, round, title },
      enrolled: { start_date: start_date_iso, end_date: end_date_iso },
    } = this.props.courseByUserId.data;
    if (!is_seasonal) {
      return;
    }
    const eventEndDate = this.addDays(start_date_iso, 2);
    let { counter } = this.state;
    let now = new Date();
    if (now > eventEndDate) {
      clearInterval(counter);
      return;
    }
    let days = (eventEndDate - now) / 1000 / 60 / 60 / 24;
    let daysRound = Math.floor(days);
    let hours = (eventEndDate - now) / 1000 / 60 / 60 - 24 * daysRound;
    let hoursRound = Math.floor(hours);
    let hoursRoundMore24 =
      daysRound >= 1 ? Math.floor(hours) + 24 : Math.floor(hours);
    let minutes =
      (eventEndDate - now) / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    let minutesRound = Math.floor(minutes);
    let seconds =
      (eventEndDate - now) / 1000 -
      24 * 60 * 60 * daysRound -
      60 * 60 * hoursRound -
      60 * minutesRound;
    let secondsRound = Math.round(seconds);

    if (this.clockTime.current && this.clockTimeCta.current) {
      this.clockTime.current.innerText = `${hoursRoundMore24}시간 ${minutesRound}분 ${secondsRound}초`;
      this.clockTimeCta.current.innerText = `${hoursRoundMore24}시간 ${minutesRound}분 ${secondsRound}초`;
    }
  };

  copyToClipboard = async (isFriendRcmd = true) => {
    const {
      course: { is_seasonal },
    } = this.props.courseByUserId.data;
    const { user } = this.props;
    try {
      if (is_seasonal) {
        const unique_id = getDeviceId();
        const eventID = createEventId();
        const location = isFriendRcmd
          ? `?f_name=${user.name}&f_uid=${user._id}`
          : `/online/seasonal?n_c=${unique_id}`;
        let text = `https://spartacodingclub.kr${location}`;
        await navigator.clipboard.writeText(text);
        alert("내용이 복사되었으니, 지금 바로 카톡방에 공유해보세요!");
        await sendLog("copy_xmas_link", {}, eventID);
      } else {
        const eventID = createEventId();
        let url = `https://spartacodingclub.kr/?f_name=${user.name}&f_uid=${user._id}`;
        await navigator.clipboard.writeText(encodeURI(url));
        alert("내용이 복사되었으니, 지금 바로 카톡방에 공유해보세요!");
        await sendLog("copy_from_done", {}, eventID);
      }
    } catch (err) {
      console.log(err);
    }
  };

  _getStartMaterial = async (course_id) => {
    const uid = "uid" in params() ? params().uid : undefined;
    let endpoint = `${config.apiUrl}/v1/materials/start?course_id=${course_id}`;
    let options = getGETAuthOptions();
    if (uid !== undefined) {
      endpoint = `${config.apiUrl}/v1/materials/start/abtest/b?course_id=${course_id}&user_id=${uid}`;
      options = getGETOptions();
    }
    await fetch(endpoint, options)
      .then(
        (resp) => {
          return resp.json();
        },
        (error) => {}
      )
      .then((result) => {
        if (!result) {
          return;
        }
        let link = result["link"];
        this.setState({
          link,
        });
      });
  };

  addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  goTutorial = async () => {
    const {
      enrolled: {
        is_class_started,
        todo: { round_id, lecture_id },
      },
    } = this.props.courseByUserId.data;
    if (!is_class_started) {
      alert("아직 수업이 시작하지 않았어요! 지금 바로 내 강의실로 가볼까요?");
      history.push(`/classroom`);
      return;
    }
    const eventID = createEventId();
    await sendLog("seasonal_direct_start", {}, eventID);
    history.push(`/rounds/${round_id}/lectures/${lecture_id}`);
  };

  render() {
    const { user, courseByUserId } = this.props;
    if (!courseByUserId.loaded || !user) {
      return <Skeleton />;
    }

    const {
      course: { days, is_free, is_seasonal, is_trial, round, title },
      enrolled: { start_date: start_date_iso, end_date: end_date_iso },
    } = courseByUserId.data;

    const startDate = new Date(start_date_iso);
    const endDate = new Date(end_date_iso);
    const eventEndDate = this.addDays(start_date_iso, 2);
    const now = new Date();
    const isClassStarted = startDate < now;
    const isEventAvailable = eventEndDate > now;
    const noticeDate = isClassStarted
      ? `지금 바로`
      : `${startDate.getMonth() + 1}월 ${startDate.getDate()}일에 `;
    console.log(this.props);
    if (title === "스파르톤") {
      return (
        <div>
          <NavContainer />
          <SparthonPaymentDone name={user.name} />
          <FooterV2 />
        </div>
      );
    }
    return (
      <div>
        <NavContainer />
        <div className={cx("contentWrapper")}>
          <div className={cx("formWrapper")}>
            {/*{component}*/}
            <div className={cx("done-header")}>
              <h3>
                {user.name} 님,
                <br />
                {noticeDate} <span className={cx("")}>내 강의실</span>에서
                만나요!
              </h3>
              {!is_seasonal && (
                <>
                  <div className={cx("desc")}>
                    혼자보다 여럿이 들어야 더 든든하죠!
                    <br />
                    친구들을 초대해 함께 수강하면 어떨까요?
                  </div>
                  <div className={cx("desc")}>
                    코딩이 낯설더라도, 같이 해낼 수 있을 거에요!
                  </div>
                </>
              )}
            </div>
            {is_seasonal && isEventAvailable && (
              <>
                <div
                  className={cx("seasonal-event-container")}
                  style={{
                    "--bg-image": `url(${"/assets/images/seasonal/seasonal_bg.png"}`,
                  }}
                >
                  <div className={cx("seasonal-event-main")}>
                    <div className={cx("event-desc")}>
                      48시간 완주 이벤트 종료까지
                    </div>
                    <div className={cx("timeclock")}>
                      <span ref={this.clockTime} />
                    </div>
                    <div className={cx("event-desc")}>남았어요!</div>
                  </div>
                </div>
                <div className={cx("seasonal-event-text")}>
                  재미있는 무료특강을 친구와 함께 즐겨보는 건 어떨까요?
                  <br />
                  지금 링크를 복사해 <b>친구와 공유</b>하세요!
                  <br />
                  <br />
                  함꼐 하면 코딩의 재미가 2배가 될 거에요!
                </div>
              </>
            )}
            <div className={cx("bg-gray")}>
              <div className={cx("rcmd-content")}>
                {is_seasonal && (
                  <>
                    <div className={cx("rcmd-title")}>
                      스파르타 온라인 강의 혜택
                    </div>
                    <div className={cx("rcmd-outro")}>유료강의 한정</div>
                  </>
                )}

                <div className={cx("rcmd-intro")}>친구 초대할 때마다</div>
                <div className={cx("rcmd-title")}>
                  친구도 나도 5만 원을 드립니다!
                </div>
                <div className={cx("rcmd-outro")}>
                  친구에게 5만 포인트, 나는 5만 원 현금 환급!
                </div>
                <img
                  className={cx("ic-rcmd")}
                  src={"/assets/images/prequestion/ic_rcmd.svg"}
                  alt=""
                />
              </div>
            </div>
            <div className={cx("howto")}>
              <h3>참여 방법</h3>
              <div className={cx("steps")}>
                <div className={cx("step")}>
                  <div className={cx("step-number")}>1</div>
                  <div className={cx("step-title")}>
                    나만의 할인 링크 복사하고
                    <br />
                    후기에 올리기
                  </div>
                  <div className={cx("step-desc")}>
                    카카오톡, SNS, 블로그 등으로
                    <br />
                    공유해보아요
                  </div>
                  <div
                    className={cx("step-cta")}
                    onClick={this.copyToClipboard}
                  >
                    나만의 할인 링크 복사하기
                  </div>
                </div>
                <div className={cx("step")}>
                  <div className={cx("step-number")}>2</div>
                  <div className={cx("step-title", "el")}>
                    공유한 링크를 통해
                    <br />
                    친구가 결제하면?
                  </div>
                  <div className={cx("step-title", "el")}>
                    친구들은{" "}
                    <span className={cx("highlight")}>5만 원 할인</span>,<br />
                    나도 친구들이 수강할 때마다
                    <br />
                    <span className={cx("highlight")}>5만 원 현금</span> get!
                  </div>
                </div>
              </div>
            </div>
            <div className={cx("rcmd-outro")}>
              친구는 <span className={cx("highlight")}>무제한 추천</span>할 수
              있으니
              <br />
              부지런히 추천하고 무료로 수강해보세요!
            </div>
            <div className={cx("hrdiv")}></div>
            <div className={cx("caution")}>
              <p className={cx("notice")}>유의사항</p>
              <p>
                • 해당 이벤트는 [마이페이지]에서 다시 참여할 수 있습니다.
                <br />
                • 단, '특강'은 본 이벤트 적용 대상 수업에서 제외됩니다.
                <br />
                • 친구가 수강신청한 강의가 개강한 다음 달에 [추천한 친구의 수 *
                5만 원]이 일괄 지급됩니다.
                <br />• 친구 추천을 통해 신청한 강의가 환불된 경우, 지급 대상에
                포함되지 않습니다.
              </p>
            </div>
          </div>
        </div>
        {is_seasonal && (
          <div className={cx("cta-bg")}>
            <div className={cx("cta-group")}>
              <div className={cx("cta-summary")}>
                <div className={cx("cta-summary-text")}>
                  48시간 완주 이벤트 종료까지
                </div>
                <div className={cx("cta-summary-text")}>
                  <span ref={this.clockTimeCta} />
                  &nbsp;남았어요!
                </div>
              </div>

              <div className={cx("ctas")}>
                <div
                  className={cx("sub-cta")}
                  onClick={() => {
                    this.copyToClipboard(false);
                  }}
                >
                  링크 공유하기
                  <img src={"/assets/icons/ic_share_primary.png"} alt="" />
                </div>
                <div className={cx("main-cta")} onClick={this.goTutorial}>
                  바로 수강하기
                </div>
              </div>
            </div>
          </div>
        )}
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, isTestPaid, courseByUserId } = state;
  const { user } = authentication;
  return {
    courseByUserId,
    authentication,
    user,
    isTestPaid,
  };
}

const connectedPaymentDonePage = connect(mapStateToProps, {
  login: userActions.login,
  getCourseByUserId: courseActions.getCourseByUserId,
  refreshInfo: userActions.refreshInfo,
})(PaymentDonePage);
export { connectedPaymentDonePage as PaymentDonePage };
