import { paymentConstants } from "../_constants";

const initState = {
  isTest: false,
};

const payinfoInitState = {
  loaded: false,
};

export function isTestPaid(state = initState, action) {
  switch (action.type) {
    case paymentConstants.PAYMENT_TYPE_GENERAL:
      return Object.assign({}, state, {
        isTest: false,
      });
    case paymentConstants.PAYMENT_TYPE_TEST:
      return Object.assign({}, state, {
        isTest: true,
      });
    default:
      return state;
  }
}

export function paymentInfo(state = payinfoInitState, action) {
  switch (action.type) {
    case paymentConstants.PAYMENT_INFO_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case paymentConstants.PAYMENT_INFO_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case paymentConstants.PAYMENT_INFO_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function paymentInfoByCourseId(state = payinfoInitState, action) {
  switch (action.type) {
    case paymentConstants.PAYMENT_COURSE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case paymentConstants.PAYMENT_COURSE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response,
      });
    case paymentConstants.PAYMENT_COURSE_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
