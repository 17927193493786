import { BROADCAST_LIST } from "../constants/broadcast.constants";

const initState = {
  loaded: false,
};

export function broadcastList(state = initState, action) {
  switch (action.type) {
    case BROADCAST_LIST.BROADCAST_LIST_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case BROADCAST_LIST.BROADCAST_LIST_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case BROADCAST_LIST.BROADCAST_LIST_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
