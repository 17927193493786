import { authHeader, handleResponse } from "../_helpers";
import { getMypageUrl } from "../_urls";

function getMypage() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  const endpoint = getMypageUrl();
  return fetch(endpoint, requestOptions).then(handleResponse);
}

export const mypageService = {
  getMypage,
};
