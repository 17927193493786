import { useMutation } from "react-query";
import { enrolledService } from "../../../_state/services";

export const useDoEnrolledDetail = () => {
  return useMutation(
    async ({ enrolledId, enrolledDetailId }) => {
      await enrolledService.doEnrolledDetail(enrolledId, enrolledDetailId);
    },
    {
      onSuccess: () => {
        console.log("onSuccess");
      },
      onError: () => {
        console.log("onError");
      },
    }
  );
};
