import styled from "styled-components";
import { PIRATES } from "../../../../../_consts";

export const TapMenuBarContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background: ${({ theme }) => {
    switch (theme) {
      case PIRATES:
        return "#222425";
      default:
        return "white";
    }
  }};
`;

export const TapMenuContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const TapMenuAnchor = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: ${({ isActive }) => (isActive ? "600" : "500")};
  font-size: 16px;
  line-height: 1;
  font-family: Pretendard;
  color: ${({ isActive, theme }) => {
    switch (theme) {
      case PIRATES:
        return isActive ? "#FF6C7A" : "#9DA7AE";
      default:
        return isActive ? "#e8344e" : "#9DA7AE";
    }
  }};
  height: 56px;
  padding-top: ${({ isActive }) => (isActive ? "2px" : "0")};
  border-bottom: ${({ isActive, theme }) =>
    isActive
      ? `2px solid 
    ${theme === PIRATES ? "#FF6C7A" : "#e8344e"}`
      : "0px"};
  position: relative;
  svg {
    position: relative;
    bottom: 1px;
  }

  :hover {
    color: ${({ theme }) => {
      switch (theme) {
        case PIRATES:
          return "#FF6C7A";
        default:
          return "#e8344e";
      }
    }};

    //border-bottom: 2px solid #e8344e;
    box-sizing: border-box;

    svg path {
      stroke: ${({ theme }) => {
        switch (theme) {
          case PIRATES:
            return "#FF6C7A";
          default:
            return "#e8344e";
        }
      }};
    }
  }
`;

export const OutlinkLogo = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 9.83333V12.1667C11.9998 12.8083 11.4748 13.3333 10.8332 13.3333H3.83317C3.1915 13.3333 2.6665 12.8083 2.6665 12.1667V5.16667C2.6665 4.525 3.1915 4 3.83317 4H6.1665"
        stroke="#9DA7AE"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.3335 2.6665H13.3335V6.6665"
        stroke="#9DA7AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 2.6665L8 7.99984"
        stroke="#9DA7AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
